import { RefObject, useEffect, useState } from "react";
import {
  map as leafletMap,
  Map as LeafletMap,
  FeatureGroup,
  LeafletMouseEvent,
  Polyline,
  circleMarker,
  LatLng,
  Symbol,
  polylineDecorator,
  CircleMarker,
  tileLayer,
} from "leaflet";
import { useStore } from "../store";
import { Path, GPS, VideoSegment } from "../types";
import { color, RED } from "../util/color";
import "leaflet-polylinedecorator";

const ZOOM_TO_ACTIVE_SEGMENT = true;
const NEAREST_LINE_WINS = false;
const SHOW_DIRECTION = true;
const MAX_MOUSE_MOVE_DISTANCE = 300;
const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoiYW5kcmV3cG1ja2VuemllIiwiYSI6ImNqOG9zYjlrNjA3OTgycW50bmRmanpiYTcifQ.tm2OaYKpI2xQ1l8eie6QAg";

class LatLngWithMeta extends LatLng {
  path: Path;
  video: VideoSegment;
  gps: GPS;
  constructor(path: Path, video: VideoSegment, gps: GPS) {
    super(gps.lat, gps.lng);
    this.path = path;
    this.video = video;
    this.gps = gps;
  }
}

class PolylineWithMeta extends Polyline {
  path: Path;
  video: VideoSegment;
  constructor(path: Path, video: VideoSegment) {
    super(
      video.gps.map((gps) => new LatLngWithMeta(path, video, gps)),
      {
        color: color(path),
        smoothFactor: path.pathType === "lift" ? 10 : 3,
        weight: 10,
        dashArray:
          path.pathType === "trail" && path.isCatTrack ? "5 10" : undefined,
      }
    );
    this.path = path;
    this.video = video;
  }
}

const closestToPoint = (
  line: PolylineWithMeta,
  point: LatLng
): LatLngWithMeta | null => {
  const latLngs: Array<any> = line.getLatLngs();
  const closestPoint = latLngs.reduce<null | LatLngWithMeta>((m, l) => {
    if (!m) {
      return l;
    }
    return m.distanceTo(point) < l.distanceTo(point) ? m : l;
  }, null);
  return closestPoint ?? null;
};

export const useMap = (ref: RefObject<HTMLDivElement>) => {
  const {
    state: {
      activeGps,
      activeVideoSegment,
      activePath,
      directory,
      curiousPath,
    },
    setCuriousPath,
    unsetCuriousPath,
    selectPath,
  } = useStore();

  const [locationMarker, setLocationMarker] = useState<null | CircleMarker>(
    null
  );
  const [lines, setLines] = useState<PolylineWithMeta[]>([]);
  const [map, setMap] = useState<LeafletMap | null>(null);
  const [group, setGroup] = useState<FeatureGroup | null>(null);

  // Create map
  useEffect(() => {
    if (!ref.current || ref.current.classList.contains("leaflet-container")) {
      return;
    }

    const lines = [...directory.paths]
      .sort((a) => (a.pathType === "lift" ? 1 : -1))
      .map((path) =>
        path.videos.map((video) => {
          const line = new PolylineWithMeta(path, video);

          const polyline = polylineDecorator(line, {
            patterns: [
              {
                offset: 0,
                repeat: 50,
                symbol: Symbol.arrowHead({
                  pixelSize: 6,
                  pathOptions: { color: "rgba(255, 255, 255, 0.2)" },
                }),
              },
            ],
          });
          return { line, polyline };
        })
      )
      .flat();
    setLines(lines.map(({ line }) => line));

    const group = new FeatureGroup(
      lines.flatMap(({ line, polyline }) =>
        SHOW_DIRECTION ? [line, polyline] : [line]
      )
    );
    setGroup(group);
    const bounds = group.getBounds().pad(0.02);

    const map = leafletMap(ref.current, {
      zoomControl: false,
      attributionControl: false,
      layers: [group],
      maxBounds: bounds,
      maxBoundsViscosity: 0.9,
      center: bounds.getCenter(),
    });
    map.fitBounds(bounds);
    map.setMinZoom(map.getZoom());
    setMap(map);

    const tiles = tileLayer(
      `https://api.mapbox.com/styles/v1/andrewpmckenzie/ckkw6ccu62ljz17qznsjs5ufc/tiles/256/{z}/{x}/{y}@2x?access_token=${MAPBOX_ACCESS_TOKEN}`,
      {
        tileSize: 512,
        zoomOffset: -1,
        opacity: 0.5,
      }
    );
    tiles.addTo(map);

    const marker = circleMarker([0.1, 0.2], {
      radius: 10,
      color: RED,
    });
    marker.addTo(map);
    setLocationMarker(marker);

    return () => {
      map.remove();
    };
  }, [ref, selectPath, setCuriousPath, unsetCuriousPath]);

  // Map hover
  useEffect(() => {
    if (!map || !lines || !activeVideoSegment) {
      return;
    }

    const lineForActiveVideoSegment = lines.find(
      (l) => l.video.id === activeVideoSegment.id
    );

    if (!lineForActiveVideoSegment) {
      return;
    }

    const listener = (e: LeafletMouseEvent) => {
      const point = closestToPoint(lineForActiveVideoSegment, e.latlng);
      if (!point || point.distanceTo(e.latlng) > MAX_MOUSE_MOVE_DISTANCE) {
        return;
      }
      selectPath(point.path, point.video, point.gps);
    };
    map.addEventListener("mousemove", listener);
    return () => {
      map.removeEventListener("mousemove", listener);
    };
  }, [map, lines, activeVideoSegment]);

  // Curious path + click line
  useEffect(() => {
    for (const line of lines) {
      line.addEventListener("mouseout", () => {
        unsetCuriousPath(line.path);
      });

      line.addEventListener("mouseover", (e: LeafletMouseEvent) => {
        setCuriousPath(line.path);

        if (NEAREST_LINE_WINS) {
          const point = closestToPoint(line, e.latlng);
          if (!point) {
            return;
          }
          selectPath(point.path, point.video, point.gps);
        }
      });

      line.addEventListener("click", (e: LeafletMouseEvent) => {
        const point = closestToPoint(line, e.latlng);
        selectPath(line.path, point?.video, point?.gps);
      });
    }
  }, [lines]);

  // Location marker
  useEffect(() => {
    if (!locationMarker) {
      return;
    }

    if (activeGps) {
      locationMarker
        .setLatLng([activeGps.lat, activeGps.lng])
        .setStyle({ opacity: 1 });
    } else {
      locationMarker.setStyle({ opacity: 0 });
    }
  }, [locationMarker, activeGps]);

  // line opacity
  useEffect(() => {
    const opacity = (segment: VideoSegment) => {
      // TODO: treat activeVideoSegment separately

      if (activePath) {
        if (segment.pathId === activePath.id) {
          return 1;
        }

        if (segment.pathId === curiousPath?.id) {
          return 0.8;
        }

        return 0.25;
      }

      if (curiousPath) {
        if (segment.pathId === curiousPath?.id) {
          return 1;
        }

        return 0.25;
      }

      return 1;
    };

    for (const line of lines) {
      line.setStyle({ opacity: opacity(line.video) });
    }
  }, [curiousPath, activePath, lines]);

  // map resizing + zoom to path
  useEffect(() => {
    map?.invalidateSize();

    if (!activeVideoSegment) {
      return;
    }

    for (const line of lines) {
      const isActive = line.video === activeVideoSegment;
      if (isActive && ZOOM_TO_ACTIVE_SEGMENT) {
        map?.fitBounds(line.getBounds());
      }
    }
  }, [activeVideoSegment]);
};
