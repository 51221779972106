import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { useStore } from "../store";
import { BasePath, VideoSegment } from "../types";
import { loadImage } from "../util/color";
import { videoTypes } from "../util/videoTypes";
import { useVideoSrc } from "./useVideoSrc";

const UnstyledTrailVideo: React.FC<{
  className?: string;
  timeMs: number;
  move: (frames: number) => void;
  src: string;
}> = ({ className, timeMs, move, src }) => {
  const [canPlay, setCanPlay] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (!videoRef.current || !canPlay) {
      return;
    }
    videoRef.current.currentTime = timeMs / 1000;
  }, [timeMs, videoRef.current, canPlay]);

  return (
    <>
      <video
        ref={videoRef}
        onCanPlay={() => setCanPlay(true)}
        className={className}
        playsInline
        src={src}
        onWheel={(e) => {
          if (e.deltaY > 0) {
            move(1);
          } else if (e.deltaY < 0) {
            move(-1);
          }
        }}
      />
    </>
  );
};

const UnstyledTrailVideoSwitch: React.FC<{
  path: BasePath | null;
  className?: string;
  videoSegment: VideoSegment;
  timeMs: number;
  move: (frames: number) => void;
}> = ({ videoSegment, ...props }) => {
  const lowResSrc = useVideoSrc(videoSegment, "low");
  const highResSrc = useVideoSrc(videoSegment, "high");

  if (highResSrc) {
    return <UnstyledTrailVideo {...props} src={highResSrc} key="low" />;
  }

  if (lowResSrc) {
    return <UnstyledTrailVideo {...props} src={lowResSrc} key="high" />;
  }

  return (
    <div
      style={{
        background: `url(${loadImage(props.path)}) no-repeat center center`,
      }}
    />
  );
};

const UnstyledTrailVideoOrBlank: React.FC<{ className?: string }> = ({
  className,
}) => {
  const {
    state: { activeGps, activeVideoSegment, activePath },
    dispatch,
  } = useStore();

  if (!activeVideoSegment) {
    return (
      <div className={className}>Hover a trail on the map to see video</div>
    );
  }

  const onMove = (direction: number) => {
    if (!activeGps || !activeVideoSegment) {
      return;
    }

    const index = activeVideoSegment.gps.indexOf(activeGps);
    const newIndex = index + direction;
    if (newIndex < 0 || newIndex >= activeVideoSegment.gps.length) {
      return;
    }
    const newGps = activeVideoSegment.gps[newIndex];
    dispatch({ type: "update-state", state: { activeGps: newGps } });
  };

  return (
    <UnstyledTrailVideoSwitch
      key={activeVideoSegment.videoId}
      path={activePath}
      move={onMove}
      videoSegment={activeVideoSegment}
      timeMs={activeGps?.msFromStart ?? 0}
      className={className}
    />
  );
};

export const TrailVideo = styled(UnstyledTrailVideoOrBlank)`
  align-items: center;
  display: flex;
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  font-size: 30px;
  height: 100%;
  justify-content: center;
  object-fit: cover;
  overflow: scroll;
  width: 100%;
`;
