import React from "react";
import { Reset } from "styled-reset";
import { PreloadVideo } from "./components/PreloadVideo";
import { TrailPage } from "./pages/TrailPage";
import { ProvideStore } from "./store";

function App() {
  return (
    <ProvideStore>
      <PreloadVideo />
      <Reset />
      <TrailPage />
    </ProvideStore>
  );
}

export default App;
