import { useEffect, useMemo, useState } from "react";
import { VideoSegment } from "../types";
import { videoTypes } from "../util/videoTypes";

export const useVideoSrc = (
  videoSegment: VideoSegment,
  res: "low" | "high"
): string | null => {
  const [value, setValue] = useState<null | string>(null);

  useEffect(() => {
    let isStale = false;

    const video = videoTypes(videoSegment.videoId).find((t) => t.res === res);
    if (!video) {
      throw new Error("Video not found");
    }

    (async () => {
      const res = await fetch(video.src);
      const blob = await res.blob();

      if (isStale) {
        return;
      }

      const url = URL.createObjectURL(blob);
      setValue(url);
    })();

    return () => {
      isStale = true;
    };
  }, [videoSegment, res]);

  return value;
};
