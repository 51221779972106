import React, { useMemo } from "react";
import { useStore } from "../store";
import { videoTypes } from "../util/videoTypes";

export const PreloadVideo: React.FC = () => {
  const {
    state: {
      directory: { videos },
    },
  } = useStore();
  const types = useMemo(
    () =>
      videos.flatMap(({ id }) =>
        videoTypes(id).filter(({ preload }) => preload)
      ),
    [videos]
  );

  return (
    <>
      {types.map(({ src, type }) => (
        <link rel="preload" href={src} type={type} as="fetch" key={src} />
      ))}
    </>
  );
};
