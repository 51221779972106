import React from "react";
import styled, { css } from "styled-components";
import { TrailIncline } from "../components/TrailIncline";
import { TrailInfo } from "../components/TrailInfo";
import { TrailList } from "../components/TrailList";
import { TrailMap } from "../components/TrailMap";
import { TrailVideo } from "../components/TrailVideo";
import { useStore } from "../store";

const stylesWithoutActivePath = css`
  grid-template:
    [row1-start] "map trail-list" 100vh [row1-end]
    / 80vw 20vw;
`;

const stylesWithActivePath = css`
  grid-template:
    [row1-start] "video info" 50vh [row1-end]
    [row2-start] "incline map" 50vh [row2-end]
    / 50vw 50vw;

  @media (orientation: portrait) {
    grid-template:
      [row1-start] "info" 10vh [row1-end]
      [row2-start] "incline" 20vh [row2-end]
      [row3-start] "map" 40vh [row3-end]
      [row4-start] "video" 30vh [row4-end]
      / 100vw;
  }
`;

const TrailPageContainer = styled.div<{ hasActivePath: boolean }>`
  display: grid;
  column-gap: 0;
  row-gap: 0;
  height: 100vh;

  ${({ hasActivePath }) => !hasActivePath && stylesWithoutActivePath}
  ${({ hasActivePath }) => hasActivePath && stylesWithActivePath}

  ${TrailMap} {
    grid-area: map;
  }

  ${TrailVideo} {
    grid-area: video;
  }

  ${TrailInfo} {
    grid-area: info;
  }

  ${TrailIncline} {
    grid-area: incline;
  }

  ${TrailList} {
    grid-area: trail-list;
  }
`;

export const TrailPage: React.FC = () => {
  const {
    state: { activePath },
    clearSelected,
  } = useStore();
  return (
    <TrailPageContainer
      hasActivePath={activePath !== null}
      onKeyDown={(e) => {
        if (e.key === "Escape") {
          clearSelected();
        }
      }}
    >
      <TrailList />
      <TrailMap />
      {activePath && <TrailVideo />}
      {activePath && <TrailInfo />}
      {activePath && <TrailIncline />}
    </TrailPageContainer>
  );
};
