// Generated by yarn generate-directory DO NOT MODIFY
import { Directory } from '../types';
export const directory: Directory = {
  "paths": [
    {
      "id": "lift-morning-star-express",
      "pathType": "lift",
      "name": "Morning Star Express",
      "speed": "fast",
      "seats": 4,
      "videos": [
        {
          "videoId": "GH010039",
          "pathId": "lift-morning-star-express",
          "startSec": 1,
          "endSec": 190,
          "id": "video-segment-8",
          "gps": [
            {
              "lat": 43.763901700000005,
              "lng": -116.10152235555557,
              "alt": 1883.3527777777776,
              "speed2d": 0.5912222222222222,
              "speed3d": 0.6599999999999999,
              "msFromStart": 1250.9553703703702
            },
            {
              "lat": 43.7639003,
              "lng": -116.10152013333335,
              "alt": 1883.422777777778,
              "speed2d": 0.6255555555555555,
              "speed3d": 0.71,
              "msFromStart": 1744.8226666666665
            },
            {
              "lat": 43.76390647777778,
              "lng": -116.1015220888889,
              "alt": 1883.6877777777777,
              "speed2d": 0.2877777777777778,
              "speed3d": 0.3855555555555556,
              "msFromStart": 2238.468037037037
            },
            {
              "lat": 43.763919411111104,
              "lng": -116.10153041111113,
              "alt": 1883.8455555555554,
              "speed2d": 1.1133333333333333,
              "speed3d": 1.0522222222222222,
              "msFromStart": 2731.6285555555546
            },
            {
              "lat": 43.76392627777778,
              "lng": -116.1015352111111,
              "alt": 1883.907444444444,
              "speed2d": 1.232222222222222,
              "speed3d": 1.2633333333333332,
              "msFromStart": 3225.5059512670555
            },
            {
              "lat": 43.76393153,
              "lng": -116.10153433,
              "alt": 1883.9646,
              "speed2d": 1.1152,
              "speed3d": 1.1400000000000001,
              "msFromStart": 3748.9538947368414
            },
            {
              "lat": 43.76393932222223,
              "lng": -116.1015344,
              "alt": 1883.7240000000002,
              "speed2d": 1.3178888888888889,
              "speed3d": 1.2911111111111113,
              "msFromStart": 4272.435424951266
            },
            {
              "lat": 43.76395028888889,
              "lng": -116.10153405555556,
              "alt": 1883.1764444444443,
              "speed2d": 1.851222222222222,
              "speed3d": 1.8122222222222222,
              "msFromStart": 4768.1647777777725
            },
            {
              "lat": 43.76396546666666,
              "lng": -116.10153361111114,
              "alt": 1882.6038888888888,
              "speed2d": 2.681333333333334,
              "speed3d": 2.623333333333333,
              "msFromStart": 5263.570074074072
            },
            {
              "lat": 43.763981400000006,
              "lng": -116.10153230000002,
              "alt": 1882.0259999999998,
              "speed2d": 3.1432222222222217,
              "speed3d": 3.152222222222222,
              "msFromStart": 5758.123888888889
            },
            {
              "lat": 43.76399734444444,
              "lng": -116.10153151111112,
              "alt": 1881.4868888888884,
              "speed2d": 3.349777777777778,
              "speed3d": 3.4,
              "msFromStart": 6252.979518518519
            },
            {
              "lat": 43.764010722222224,
              "lng": -116.10152912222223,
              "alt": 1881.0351111111113,
              "speed2d": 3.1526666666666663,
              "speed3d": 3.218888888888889,
              "msFromStart": 6748.776000000002
            },
            {
              "lat": 43.76402702222222,
              "lng": -116.10152505555554,
              "alt": 1880.8348888888888,
              "speed2d": 3.459888888888889,
              "speed3d": 3.453333333333334,
              "msFromStart": 7244.4004629629635
            },
            {
              "lat": 43.764045222222215,
              "lng": -116.10151628888889,
              "alt": 1880.6214444444445,
              "speed2d": 3.9440000000000004,
              "speed3d": 3.957777777777778,
              "msFromStart": 7739.423611111115
            },
            {
              "lat": 43.76406345555556,
              "lng": -116.10150514444445,
              "alt": 1880.3962222222224,
              "speed2d": 4.130333333333334,
              "speed3d": 4.173333333333333,
              "msFromStart": 8234.750592592594
            },
            {
              "lat": 43.76408154444445,
              "lng": -116.10149634444444,
              "alt": 1880.234,
              "speed2d": 4.048333333333334,
              "speed3d": 4.095555555555555,
              "msFromStart": 8730.988166666666
            },
            {
              "lat": 43.76409883333333,
              "lng": -116.10149082222222,
              "alt": 1880.4078888888891,
              "speed2d": 3.900777777777778,
              "speed3d": 3.9755555555555557,
              "msFromStart": 9227.105195906432
            },
            {
              "lat": 43.76411502,
              "lng": -116.10149077999999,
              "alt": 1881.2477,
              "speed2d": 3.4954,
              "speed3d": 3.5709999999999997,
              "msFromStart": 9750.300263157886
            },
            {
              "lat": 43.76411722222222,
              "lng": -116.10148492222221,
              "alt": 1880.939222222222,
              "speed2d": 2.6285555555555558,
              "speed3d": 2.743333333333333,
              "msFromStart": 10273.627289473683
            },
            {
              "lat": 43.76412294444444,
              "lng": -116.1014763111111,
              "alt": 1880.5912222222223,
              "speed2d": 2.4211111111111103,
              "speed3d": 2.4233333333333333,
              "msFromStart": 10769.842500000008
            },
            {
              "lat": 43.76413387777778,
              "lng": -116.10146826666669,
              "alt": 1880.7111111111112,
              "speed2d": 2.4958888888888886,
              "speed3d": 2.5233333333333334,
              "msFromStart": 11265.820870370371
            },
            {
              "lat": 43.76415618888889,
              "lng": -116.10147157777777,
              "alt": 1881.4734444444446,
              "speed2d": 2.948666666666667,
              "speed3d": 2.8911111111111114,
              "msFromStart": 11761.095888888882
            },
            {
              "lat": 43.76417753333334,
              "lng": -116.10147477777778,
              "alt": 1881.4731111111112,
              "speed2d": 3.570555555555556,
              "speed3d": 3.5266666666666664,
              "msFromStart": 12256.22357407407
            },
            {
              "lat": 43.76419863333333,
              "lng": -116.1014736888889,
              "alt": 1881.742,
              "speed2d": 3.763777777777778,
              "speed3d": 3.7622222222222224,
              "msFromStart": 12751.051833333328
            },
            {
              "lat": 43.76421871111111,
              "lng": -116.10147125555557,
              "alt": 1882.3868888888887,
              "speed2d": 3.6977777777777776,
              "speed3d": 3.7377777777777776,
              "msFromStart": 13245.852407407407
            },
            {
              "lat": 43.76423921111111,
              "lng": -116.10146664444444,
              "alt": 1883.677888888889,
              "speed2d": 3.7170000000000005,
              "speed3d": 3.6788888888888884,
              "msFromStart": 13740.620611111119
            },
            {
              "lat": 43.76425811111111,
              "lng": -116.10146181111111,
              "alt": 1884.1462222222226,
              "speed2d": 3.967777777777777,
              "speed3d": 3.9588888888888887,
              "msFromStart": 14235.550069200783
            },
            {
              "lat": 43.76427683333333,
              "lng": -116.10145665555555,
              "alt": 1884.6691111111113,
              "speed2d": 3.9385555555555554,
              "speed3d": 3.948888888888889,
              "msFromStart": 14730.921842105263
            },
            {
              "lat": 43.764296755555556,
              "lng": -116.1014520111111,
              "alt": 1885.3486666666665,
              "speed2d": 3.9486666666666665,
              "speed3d": 3.951111111111111,
              "msFromStart": 15226.343644249513
            },
            {
              "lat": 43.764319269999994,
              "lng": -116.10144844,
              "alt": 1886.3953999999999,
              "speed2d": 3.9391,
              "speed3d": 3.9480000000000004,
              "msFromStart": 15749.402416666675
            },
            {
              "lat": 43.76434979999999,
              "lng": -116.10144950000002,
              "alt": 1888.6252222222222,
              "speed2d": 4.166888888888889,
              "speed3d": 4.161111111111111,
              "msFromStart": 16272.107500000002
            },
            {
              "lat": 43.76437652222223,
              "lng": -116.1014495888889,
              "alt": 1889.895222222222,
              "speed2d": 4.430444444444444,
              "speed3d": 4.458888888888889,
              "msFromStart": 16766.293555555556
            },
            {
              "lat": 43.764401766666666,
              "lng": -116.10144763333334,
              "alt": 1890.646666666667,
              "speed2d": 4.6963333333333335,
              "speed3d": 4.69,
              "msFromStart": 17260.89577777778
            },
            {
              "lat": 43.76442748888889,
              "lng": -116.10144467777778,
              "alt": 1891.4956666666667,
              "speed2d": 4.919,
              "speed3d": 4.930000000000001,
              "msFromStart": 17756.768888888895
            },
            {
              "lat": 43.76445407777777,
              "lng": -116.10144266666668,
              "alt": 1892.2992222222224,
              "speed2d": 5.113555555555555,
              "speed3d": 5.131111111111111,
              "msFromStart": 18252.17274074074
            },
            {
              "lat": 43.764479533333336,
              "lng": -116.10144051111111,
              "alt": 1892.9988888888888,
              "speed2d": 5.0008888888888885,
              "speed3d": 5.054444444444444,
              "msFromStart": 18746.121333333333
            },
            {
              "lat": 43.76450063333333,
              "lng": -116.10143730000001,
              "alt": 1893.2245555555555,
              "speed2d": 4.81211111111111,
              "speed3d": 4.8566666666666665,
              "msFromStart": 19240.417740740737
            },
            {
              "lat": 43.7645192,
              "lng": -116.10143335555556,
              "alt": 1893.190111111111,
              "speed2d": 4.764666666666667,
              "speed3d": 4.788888888888889,
              "msFromStart": 19735.866111111103
            },
            {
              "lat": 43.76453785555556,
              "lng": -116.10142973333332,
              "alt": 1893.4345555555556,
              "speed2d": 4.674666666666667,
              "speed3d": 4.703333333333333,
              "msFromStart": 20231.018411306042
            },
            {
              "lat": 43.76455846000001,
              "lng": -116.10142565,
              "alt": 1893.6537,
              "speed2d": 4.7473,
              "speed3d": 4.763000000000001,
              "msFromStart": 20752.65436842106
            },
            {
              "lat": 43.7645798,
              "lng": -116.10142105555556,
              "alt": 1893.7198888888888,
              "speed2d": 4.920888888888889,
              "speed3d": 4.937777777777778,
              "msFromStart": 21274.299475633532
            },
            {
              "lat": 43.76460021111111,
              "lng": -116.10141742222223,
              "alt": 1893.7315555555554,
              "speed2d": 4.969444444444445,
              "speed3d": 4.99,
              "msFromStart": 21768.655888888905
            },
            {
              "lat": 43.764620455555544,
              "lng": -116.1014141,
              "alt": 1893.8917777777779,
              "speed2d": 4.830555555555556,
              "speed3d": 4.8822222222222225,
              "msFromStart": 22263.184481481483
            },
            {
              "lat": 43.7646393,
              "lng": -116.10141093333333,
              "alt": 1893.9717777777776,
              "speed2d": 4.5681111111111115,
              "speed3d": 4.635555555555555,
              "msFromStart": 22758.157111111097
            },
            {
              "lat": 43.76465576666667,
              "lng": -116.10140741111111,
              "alt": 1893.773111111111,
              "speed2d": 4.322888888888889,
              "speed3d": 4.375555555555556,
              "msFromStart": 23253.11966666666
            },
            {
              "lat": 43.764680077777776,
              "lng": -116.10140492222223,
              "alt": 1894.5895555555555,
              "speed2d": 4.536777777777778,
              "speed3d": 4.498888888888889,
              "msFromStart": 23747.991444444437
            },
            {
              "lat": 43.764708166666665,
              "lng": -116.10140263333335,
              "alt": 1895.7876666666666,
              "speed2d": 4.888888888888888,
              "speed3d": 4.898888888888889,
              "msFromStart": 24242.74948732943
            },
            {
              "lat": 43.76472768888888,
              "lng": -116.1013985111111,
              "alt": 1895.8828888888893,
              "speed2d": 4.752000000000001,
              "speed3d": 4.821111111111112,
              "msFromStart": 24737.21178947368
            },
            {
              "lat": 43.764745044444446,
              "lng": -116.10139419999999,
              "alt": 1895.634111111111,
              "speed2d": 4.645666666666666,
              "speed3d": 4.674444444444444,
              "msFromStart": 25232.132834307988
            },
            {
              "lat": 43.76476834444445,
              "lng": -116.10139094444443,
              "alt": 1896.1788888888887,
              "speed2d": 4.761444444444445,
              "speed3d": 4.774444444444444,
              "msFromStart": 25728.951111111106
            },
            {
              "lat": 43.764797055555555,
              "lng": -116.10138926666666,
              "alt": 1897.5002222222222,
              "speed2d": 4.924333333333334,
              "speed3d": 4.952222222222222,
              "msFromStart": 26225.42262962963
            },
            {
              "lat": 43.76482557999999,
              "lng": -116.10138744,
              "alt": 1898.7854,
              "speed2d": 4.786099999999999,
              "speed3d": 4.857000000000001,
              "msFromStart": 26747.198833333343
            },
            {
              "lat": 43.76484801111111,
              "lng": -116.10138356666667,
              "alt": 1899.199111111111,
              "speed2d": 4.580666666666667,
              "speed3d": 4.6722222222222225,
              "msFromStart": 27269.27040740741
            },
            {
              "lat": 43.764861844444454,
              "lng": -116.10137876666668,
              "alt": 1898.468111111111,
              "speed2d": 4.3485555555555555,
              "speed3d": 4.397777777777778,
              "msFromStart": 27765.275555555552
            },
            {
              "lat": 43.76487566666666,
              "lng": -116.10137342222222,
              "alt": 1897.7247777777777,
              "speed2d": 4.269555555555556,
              "speed3d": 4.278888888888889,
              "msFromStart": 28261.78574074074
            },
            {
              "lat": 43.76489794444445,
              "lng": -116.1013694,
              "alt": 1898.3242222222225,
              "speed2d": 4.489000000000001,
              "speed3d": 4.502222222222223,
              "msFromStart": 28759.469
            },
            {
              "lat": 43.76492017777778,
              "lng": -116.10136555555555,
              "alt": 1899.0191111111112,
              "speed2d": 4.463111111111111,
              "speed3d": 4.4944444444444445,
              "msFromStart": 29255.709
            },
            {
              "lat": 43.76493811111111,
              "lng": -116.10136142222221,
              "alt": 1899.0502222222224,
              "speed2d": 4.303333333333333,
              "speed3d": 4.37,
              "msFromStart": 29747.863999999994
            },
            {
              "lat": 43.76495373333333,
              "lng": -116.10135738888889,
              "alt": 1898.793777777778,
              "speed2d": 4.192111111111111,
              "speed3d": 4.222222222222222,
              "msFromStart": 30240.25362962963
            },
            {
              "lat": 43.76497115555555,
              "lng": -116.10135354444445,
              "alt": 1898.9633333333334,
              "speed2d": 4.076222222222222,
              "speed3d": 4.122222222222223,
              "msFromStart": 30734.441777777796
            },
            {
              "lat": 43.76499483333334,
              "lng": -116.10135067777776,
              "alt": 1899.835222222222,
              "speed2d": 4.479111111111111,
              "speed3d": 4.467777777777777,
              "msFromStart": 31229.015333333344
            },
            {
              "lat": 43.76502436,
              "lng": -116.10134851,
              "alt": 1901.3279,
              "speed2d": 4.6854,
              "speed3d": 4.725,
              "msFromStart": 31751.937666666683
            },
            {
              "lat": 43.7650503,
              "lng": -116.1013466,
              "alt": 1902.2044444444446,
              "speed2d": 4.686555555555556,
              "speed3d": 4.763333333333334,
              "msFromStart": 32274.625148148152
            },
            {
              "lat": 43.76506554444444,
              "lng": -116.10133962222221,
              "alt": 1901.9354444444443,
              "speed2d": 4.488777777777778,
              "speed3d": 4.554444444444444,
              "msFromStart": 32768.925444444445
            },
            {
              "lat": 43.765082166666666,
              "lng": -116.10133548888888,
              "alt": 1901.2787777777778,
              "speed2d": 4.676555555555556,
              "speed3d": 4.658888888888889,
              "msFromStart": 33263.528888888875
            },
            {
              "lat": 43.76510586666667,
              "lng": -116.10133240000002,
              "alt": 1901.5694444444443,
              "speed2d": 4.891,
              "speed3d": 4.903333333333333,
              "msFromStart": 33759.078111111085
            },
            {
              "lat": 43.76512975555555,
              "lng": -116.10132926666667,
              "alt": 1901.7777777777776,
              "speed2d": 5.114222222222223,
              "speed3d": 5.111111111111111,
              "msFromStart": 34254.50904288497
            },
            {
              "lat": 43.76514672222222,
              "lng": -116.1013246888889,
              "alt": 1901.1383333333333,
              "speed2d": 4.952333333333334,
              "speed3d": 4.9911111111111115,
              "msFromStart": 34749.48378947365
            },
            {
              "lat": 43.76515893333334,
              "lng": -116.10131980000001,
              "alt": 1899.8335555555554,
              "speed2d": 4.707666666666666,
              "speed3d": 4.734444444444445,
              "msFromStart": 35244.747352826496
            },
            {
              "lat": 43.76518179999999,
              "lng": -116.10131733333334,
              "alt": 1899.9695555555556,
              "speed2d": 4.8051111111111116,
              "speed3d": 4.792222222222222,
              "msFromStart": 35741.262222222256
            },
            {
              "lat": 43.76520747777778,
              "lng": -116.10131475555556,
              "alt": 1900.600888888889,
              "speed2d": 4.781777777777777,
              "speed3d": 4.783333333333333,
              "msFromStart": 36237.68111111113
            },
            {
              "lat": 43.76523422222222,
              "lng": -116.10131198888888,
              "alt": 1901.4279999999999,
              "speed2d": 4.863,
              "speed3d": 4.872222222222221,
              "msFromStart": 36733.21555555554
            },
            {
              "lat": 43.765260111111104,
              "lng": -116.10130840000001,
              "alt": 1902.1663333333333,
              "speed2d": 4.828555555555555,
              "speed3d": 4.833333333333333,
              "msFromStart": 37228.83525925925
            },
            {
              "lat": 43.76528551111112,
              "lng": -116.1013048111111,
              "alt": 1902.8115555555557,
              "speed2d": 4.793111111111111,
              "speed3d": 4.8022222222222215,
              "msFromStart": 37725.14977777777
            },
            {
              "lat": 43.76531273,
              "lng": -116.10130126,
              "alt": 1903.5822999999998,
              "speed2d": 4.8314,
              "speed3d": 4.83,
              "msFromStart": 38248.608066666675
            },
            {
              "lat": 43.76533903333333,
              "lng": -116.10129750000002,
              "alt": 1904.2323333333334,
              "speed2d": 4.794333333333333,
              "speed3d": 4.823333333333333,
              "msFromStart": 38770.438888888915
            },
            {
              "lat": 43.765364866666665,
              "lng": -116.10129426666666,
              "alt": 1904.9868888888889,
              "speed2d": 4.841111111111111,
              "speed3d": 4.850000000000001,
              "msFromStart": 39264.778814814825
            },
            {
              "lat": 43.765391,
              "lng": -116.10129161111111,
              "alt": 1905.7737777777777,
              "speed2d": 4.929222222222222,
              "speed3d": 4.926666666666668,
              "msFromStart": 39759.417111111135
            },
            {
              "lat": 43.76541628888889,
              "lng": -116.10128895555555,
              "alt": 1906.3002222222224,
              "speed2d": 4.896222222222222,
              "speed3d": 4.924444444444444,
              "msFromStart": 40254.66039376219
            },
            {
              "lat": 43.76544138888889,
              "lng": -116.10128674444444,
              "alt": 1906.7214444444446,
              "speed2d": 4.902444444444444,
              "speed3d": 4.9077777777777785,
              "msFromStart": 40751.503157894724
            },
            {
              "lat": 43.76546636666667,
              "lng": -116.10128362222223,
              "alt": 1907.3654444444442,
              "speed2d": 4.867888888888889,
              "speed3d": 4.894444444444444,
              "msFromStart": 41247.602522417146
            },
            {
              "lat": 43.76549161111111,
              "lng": -116.10128052222223,
              "alt": 1907.986888888889,
              "speed2d": 4.898111111111111,
              "speed3d": 4.911111111111112,
              "msFromStart": 41740.39155555559
            },
            {
              "lat": 43.76551674444445,
              "lng": -116.10127766666666,
              "alt": 1908.5186666666668,
              "speed2d": 4.910888888888889,
              "speed3d": 4.924444444444444,
              "msFromStart": 42232.899925925936
            },
            {
              "lat": 43.765544399999996,
              "lng": -116.10127494999999,
              "alt": 1909.2585000000001,
              "speed2d": 5.0085999999999995,
              "speed3d": 5.029999999999999,
              "msFromStart": 42752.98366666663
            },
            {
              "lat": 43.76557142222222,
              "lng": -116.10127130000001,
              "alt": 1910.0634444444445,
              "speed2d": 5.000888888888889,
              "speed3d": 5.033333333333333,
              "msFromStart": 43273.645481481464
            },
            {
              "lat": 43.76559684444444,
              "lng": -116.10126737777777,
              "alt": 1910.8974444444443,
              "speed2d": 5.011777777777778,
              "speed3d": 5.0377777777777775,
              "msFromStart": 43768.447777777765
            },
            {
              "lat": 43.765621744444445,
              "lng": -116.10126355555555,
              "alt": 1911.7464444444445,
              "speed2d": 4.920999999999999,
              "speed3d": 4.9655555555555555,
              "msFromStart": 44263.34317738791
            },
            {
              "lat": 43.76564677777778,
              "lng": -116.10126082222222,
              "alt": 1912.2405555555554,
              "speed2d": 4.996888888888889,
              "speed3d": 5.025555555555556,
              "msFromStart": 44758.27494736844
            },
            {
              "lat": 43.765671644444446,
              "lng": -116.1012579222222,
              "alt": 1912.993,
              "speed2d": 4.984888888888889,
              "speed3d": 5.025555555555555,
              "msFromStart": 45253.31350487331
            },
            {
              "lat": 43.76569677777778,
              "lng": -116.10125483333336,
              "alt": 1913.926888888889,
              "speed2d": 5.0247777777777785,
              "speed3d": 5.071111111111111,
              "msFromStart": 45748.77155555555
            },
            {
              "lat": 43.76572171111111,
              "lng": -116.10125238888887,
              "alt": 1914.8286666666668,
              "speed2d": 4.9943333333333335,
              "speed3d": 5.055555555555555,
              "msFromStart": 46244.73044444443
            },
            {
              "lat": 43.76574641111111,
              "lng": -116.10124898888888,
              "alt": 1915.7742222222223,
              "speed2d": 5.0215555555555556,
              "speed3d": 5.088888888888889,
              "msFromStart": 46742.52488888886
            },
            {
              "lat": 43.76577131111111,
              "lng": -116.1012460888889,
              "alt": 1916.8537777777779,
              "speed2d": 4.987111111111111,
              "speed3d": 5.083333333333333,
              "msFromStart": 47240.99219172112
            },
            {
              "lat": 43.765795744444446,
              "lng": -116.10124433333334,
              "alt": 1917.4787777777779,
              "speed2d": 4.962666666666666,
              "speed3d": 5.053333333333335,
              "msFromStart": 47741.416705882344
            },
            {
              "lat": 43.76581983333333,
              "lng": -116.10124134444443,
              "alt": 1918.319,
              "speed2d": 4.973888888888889,
              "speed3d": 5.05,
              "msFromStart": 48239.24910629515
            },
            {
              "lat": 43.76584373333333,
              "lng": -116.10123874444444,
              "alt": 1919.0038888888887,
              "speed2d": 4.927111111111111,
              "speed3d": 5.025555555555555,
              "msFromStart": 48729.51452631578
            },
            {
              "lat": 43.765868229999995,
              "lng": -116.10123511,
              "alt": 1919.8411999999998,
              "speed2d": 4.8248,
              "speed3d": 4.924,
              "msFromStart": 49248.29111578947
            },
            {
              "lat": 43.7658931,
              "lng": -116.10123177777778,
              "alt": 1920.735111111111,
              "speed2d": 4.855666666666666,
              "speed3d": 4.94888888888889,
              "msFromStart": 49772.55494736843
            },
            {
              "lat": 43.765916655555564,
              "lng": -116.10122815555553,
              "alt": 1921.499111111111,
              "speed2d": 4.908444444444444,
              "speed3d": 5.021111111111111,
              "msFromStart": 50268.99357894737
            },
            {
              "lat": 43.76593977777778,
              "lng": -116.10122278888889,
              "alt": 1921.818888888889,
              "speed2d": 4.924777777777778,
              "speed3d": 4.987777777777778,
              "msFromStart": 50762.668
            },
            {
              "lat": 43.76596446666667,
              "lng": -116.10121995555554,
              "alt": 1921.869,
              "speed2d": 5.120666666666668,
              "speed3d": 5.173333333333334,
              "msFromStart": 51255.992296296296
            },
            {
              "lat": 43.76598887777777,
              "lng": -116.10121645555554,
              "alt": 1921.9173333333335,
              "speed2d": 5.202,
              "speed3d": 5.242222222222223,
              "msFromStart": 51749.02177777781
            },
            {
              "lat": 43.76601325555556,
              "lng": -116.10121302222223,
              "alt": 1922.083,
              "speed2d": 5.233888888888888,
              "speed3d": 5.268888888888889,
              "msFromStart": 52242.07437037038
            },
            {
              "lat": 43.76603706666666,
              "lng": -116.10120996666666,
              "alt": 1922.2436666666667,
              "speed2d": 5.1594444444444445,
              "speed3d": 5.206666666666667,
              "msFromStart": 52735.33733333331
            },
            {
              "lat": 43.76605972222222,
              "lng": -116.10120628888887,
              "alt": 1922.3872222222221,
              "speed2d": 5.0088888888888885,
              "speed3d": 5.062222222222222,
              "msFromStart": 53228.992074074056
            },
            {
              "lat": 43.76608378000001,
              "lng": -116.10120288000002,
              "alt": 1922.7592999999997,
              "speed2d": 4.937399999999999,
              "speed3d": 4.968,
              "msFromStart": 53751.645666666634
            },
            {
              "lat": 43.76610807777779,
              "lng": -116.10119966666667,
              "alt": 1923.1689999999999,
              "speed2d": 4.941888888888889,
              "speed3d": 4.961111111111112,
              "msFromStart": 54274.47410916178
            },
            {
              "lat": 43.76613126666667,
              "lng": -116.10119585555556,
              "alt": 1923.623111111111,
              "speed2d": 4.980666666666666,
              "speed3d": 5.016666666666667,
              "msFromStart": 54769.85873684213
            },
            {
              "lat": 43.76615412222222,
              "lng": -116.10119211111112,
              "alt": 1923.9517777777778,
              "speed2d": 4.963666666666667,
              "speed3d": 4.97,
              "msFromStart": 55265.29374658869
            },
            {
              "lat": 43.76617991111111,
              "lng": -116.1011895111111,
              "alt": 1924.4211111111113,
              "speed2d": 5.152333333333333,
              "speed3d": 5.181111111111111,
              "msFromStart": 55760.91511111108
            },
            {
              "lat": 43.76620397777778,
              "lng": -116.10118625555555,
              "alt": 1924.7447777777777,
              "speed2d": 5.0489999999999995,
              "speed3d": 5.095555555555555,
              "msFromStart": 56256.63844444443
            },
            {
              "lat": 43.766227755555555,
              "lng": -116.10118257777776,
              "alt": 1925.1058888888888,
              "speed2d": 4.950999999999999,
              "speed3d": 4.9911111111111115,
              "msFromStart": 56752.69511111112
            },
            {
              "lat": 43.7662506,
              "lng": -116.10117904444444,
              "alt": 1925.3090000000002,
              "speed2d": 4.810222222222222,
              "speed3d": 4.848888888888888,
              "msFromStart": 57248.412518518526
            },
            {
              "lat": 43.76627387777778,
              "lng": -116.10117561111112,
              "alt": 1925.711111111111,
              "speed2d": 4.879,
              "speed3d": 4.895555555555555,
              "msFromStart": 57742.639555555594
            },
            {
              "lat": 43.766297033333345,
              "lng": -116.10117175555555,
              "alt": 1926.0316666666668,
              "speed2d": 4.797333333333334,
              "speed3d": 4.822222222222222,
              "msFromStart": 58236.75829629631
            },
            {
              "lat": 43.7663222111111,
              "lng": -116.10116851111113,
              "alt": 1926.568777777778,
              "speed2d": 5.030444444444444,
              "speed3d": 5.037777777777778,
              "msFromStart": 58731.039999999986
            },
            {
              "lat": 43.766346899999995,
              "lng": -116.10116483333334,
              "alt": 1927.0911111111109,
              "speed2d": 5.020111111111111,
              "speed3d": 5.0633333333333335,
              "msFromStart": 59225.50835087718
            },
            {
              "lat": 43.76637261,
              "lng": -116.10116151,
              "alt": 1927.5099,
              "speed2d": 5.0302999999999995,
              "speed3d": 5.053000000000001,
              "msFromStart": 59748.17021052631
            },
            {
              "lat": 43.76639804444444,
              "lng": -116.10115882222223,
              "alt": 1927.8324444444445,
              "speed2d": 4.950444444444445,
              "speed3d": 4.996666666666666,
              "msFromStart": 60270.8702300195
            },
            {
              "lat": 43.766422188888896,
              "lng": -116.10115615555557,
              "alt": 1928.2302222222224,
              "speed2d": 4.973444444444445,
              "speed3d": 4.992222222222223,
              "msFromStart": 60765.93822222219
            },
            {
              "lat": 43.766445777777776,
              "lng": -116.10115264444443,
              "alt": 1928.6314444444442,
              "speed2d": 4.935333333333333,
              "speed3d": 4.9733333333333345,
              "msFromStart": 61260.814440087124
            },
            {
              "lat": 43.76646921111111,
              "lng": -116.10114896666667,
              "alt": 1929.0992222222224,
              "speed2d": 4.875444444444446,
              "speed3d": 4.906666666666666,
              "msFromStart": 61754.97152941178
            },
            {
              "lat": 43.76649261111111,
              "lng": -116.10114572222221,
              "alt": 1929.5443333333335,
              "speed2d": 4.912,
              "speed3d": 4.930000000000001,
              "msFromStart": 62249.4328998968
            },
            {
              "lat": 43.76651654444444,
              "lng": -116.10114229999999,
              "alt": 1930.1035555555552,
              "speed2d": 4.958555555555556,
              "speed3d": 4.992222222222223,
              "msFromStart": 62744.91536842103
            },
            {
              "lat": 43.76654057777777,
              "lng": -116.1011400111111,
              "alt": 1930.585111111111,
              "speed2d": 4.9830000000000005,
              "speed3d": 5.014444444444445,
              "msFromStart": 63240.4889473684
            },
            {
              "lat": 43.7665643,
              "lng": -116.10113702222222,
              "alt": 1931.0625555555557,
              "speed2d": 5.006666666666667,
              "speed3d": 5.0344444444444445,
              "msFromStart": 63736.212000000014
            },
            {
              "lat": 43.76658743333334,
              "lng": -116.10113217777777,
              "alt": 1931.759,
              "speed2d": 4.959444444444445,
              "speed3d": 5.013333333333334,
              "msFromStart": 64231.91000000001
            },
            {
              "lat": 43.766610711111106,
              "lng": -116.10112793333334,
              "alt": 1932.5337777777777,
              "speed2d": 4.9833333333333325,
              "speed3d": 5.026666666666666,
              "msFromStart": 64727.447999999975
            },
            {
              "lat": 43.76663501000001,
              "lng": -116.10112405,
              "alt": 1933.1724000000002,
              "speed2d": 4.971800000000001,
              "speed3d": 5.032000000000001,
              "msFromStart": 65249.90026666666
            },
            {
              "lat": 43.76665907777778,
              "lng": -116.10111988888889,
              "alt": 1933.838888888889,
              "speed2d": 4.957333333333333,
              "speed3d": 5.0200000000000005,
              "msFromStart": 65770.41377777781
            },
            {
              "lat": 43.766681722222216,
              "lng": -116.10111594444444,
              "alt": 1934.4533333333331,
              "speed2d": 4.946555555555555,
              "speed3d": 5.026666666666667,
              "msFromStart": 66264.47214814817
            },
            {
              "lat": 43.76670387777777,
              "lng": -116.10111117777778,
              "alt": 1935.1106666666665,
              "speed2d": 4.894222222222222,
              "speed3d": 4.966666666666667,
              "msFromStart": 66761.52266666673
            },
            {
              "lat": 43.76672683333334,
              "lng": -116.10110701111111,
              "alt": 1935.7833333333335,
              "speed2d": 4.983333333333334,
              "speed3d": 5.036666666666667,
              "msFromStart": 67258.49111111114
            },
            {
              "lat": 43.76675161111111,
              "lng": -116.10110336666666,
              "alt": 1936.6478888888892,
              "speed2d": 5.186777777777778,
              "speed3d": 5.262222222222222,
              "msFromStart": 67754.80933333335
            },
            {
              "lat": 43.76677415555556,
              "lng": -116.10109927777776,
              "alt": 1937.2676666666664,
              "speed2d": 5.013333333333334,
              "speed3d": 5.131111111111111,
              "msFromStart": 68250.53955555554
            },
            {
              "lat": 43.7667956,
              "lng": -116.10109591111112,
              "alt": 1937.8123333333335,
              "speed2d": 4.831222222222222,
              "speed3d": 4.942222222222222,
              "msFromStart": 68744.76666666662
            },
            {
              "lat": 43.76681771111111,
              "lng": -116.10109207777779,
              "alt": 1938.4721111111114,
              "speed2d": 4.806222222222223,
              "speed3d": 4.8966666666666665,
              "msFromStart": 69238.84376608185
            },
            {
              "lat": 43.766839377777785,
              "lng": -116.1010888888889,
              "alt": 1939.0205555555556,
              "speed2d": 4.779111111111112,
              "speed3d": 4.893333333333333,
              "msFromStart": 69732.72842105257
            },
            {
              "lat": 43.766860799999996,
              "lng": -116.10108776666668,
              "alt": 1939.418111111111,
              "speed2d": 4.698333333333332,
              "speed3d": 4.797777777777777,
              "msFromStart": 70227.07210916175
            },
            {
              "lat": 43.76688333,
              "lng": -116.10108596999999,
              "alt": 1939.8275,
              "speed2d": 4.715199999999999,
              "speed3d": 4.788000000000001,
              "msFromStart": 70750.8706666666
            },
            {
              "lat": 43.76690744444444,
              "lng": -116.10108214444445,
              "alt": 1940.4797777777778,
              "speed2d": 4.940333333333332,
              "speed3d": 5.003333333333334,
              "msFromStart": 71274.85274074072
            },
            {
              "lat": 43.76693038888889,
              "lng": -116.1010790888889,
              "alt": 1940.866111111111,
              "speed2d": 4.999777777777778,
              "speed3d": 5.082222222222223,
              "msFromStart": 71771.24533333335
            },
            {
              "lat": 43.76695308888889,
              "lng": -116.10107587777776,
              "alt": 1941.3153333333335,
              "speed2d": 4.983888888888889,
              "speed3d": 5.072222222222222,
              "msFromStart": 72267.2525925926
            },
            {
              "lat": 43.766975022222226,
              "lng": -116.10107246666666,
              "alt": 1941.6091111111111,
              "speed2d": 4.891222222222223,
              "speed3d": 4.967777777777777,
              "msFromStart": 72762.21555555558
            },
            {
              "lat": 43.76699716666666,
              "lng": -116.10106908888889,
              "alt": 1941.9646666666667,
              "speed2d": 4.922888888888889,
              "speed3d": 4.992222222222223,
              "msFromStart": 73257.31835477585
            },
            {
              "lat": 43.767018933333325,
              "lng": -116.10106508888889,
              "alt": 1942.4612222222224,
              "speed2d": 4.849222222222223,
              "speed3d": 4.927777777777778,
              "msFromStart": 73752.94989473691
            },
            {
              "lat": 43.767041311111115,
              "lng": -116.1010615,
              "alt": 1942.9836666666667,
              "speed2d": 4.920666666666666,
              "speed3d": 4.986666666666666,
              "msFromStart": 74248.35826900588
            },
            {
              "lat": 43.7670633,
              "lng": -116.10105766666666,
              "alt": 1943.4640000000002,
              "speed2d": 4.872222222222222,
              "speed3d": 4.953333333333333,
              "msFromStart": 74742.76266666665
            },
            {
              "lat": 43.76708555555556,
              "lng": -116.1010539,
              "alt": 1943.936,
              "speed2d": 4.88411111111111,
              "speed3d": 4.971111111111112,
              "msFromStart": 75237.08074074074
            },
            {
              "lat": 43.7671076,
              "lng": -116.10105019999999,
              "alt": 1944.3208888888887,
              "speed2d": 4.889,
              "speed3d": 4.95888888888889,
              "msFromStart": 75731.45511111117
            },
            {
              "lat": 43.76713132,
              "lng": -116.10104684,
              "alt": 1944.4946999999997,
              "speed2d": 5.0046,
              "speed3d": 5.066000000000001,
              "msFromStart": 76253.08320000002
            },
            {
              "lat": 43.76715448888889,
              "lng": -116.10104382222221,
              "alt": 1944.7384444444447,
              "speed2d": 4.893222222222223,
              "speed3d": 4.965555555555555,
              "msFromStart": 76774.00711111116
            },
            {
              "lat": 43.767175822222214,
              "lng": -116.10104088888889,
              "alt": 1944.8935555555556,
              "speed2d": 4.736444444444444,
              "speed3d": 4.814444444444445,
              "msFromStart": 77267.78281481481
            },
            {
              "lat": 43.76719659999999,
              "lng": -116.10103747777777,
              "alt": 1945.3297777777775,
              "speed2d": 4.631777777777779,
              "speed3d": 4.6899999999999995,
              "msFromStart": 77762.44799999993
            },
            {
              "lat": 43.76721753333334,
              "lng": -116.1010339888889,
              "alt": 1945.6626666666666,
              "speed2d": 4.645666666666666,
              "speed3d": 4.70888888888889,
              "msFromStart": 78257.38888888888
            },
            {
              "lat": 43.76723788888889,
              "lng": -116.10102923333332,
              "alt": 1945.9166666666663,
              "speed2d": 4.644666666666667,
              "speed3d": 4.688888888888888,
              "msFromStart": 78753.24533333341
            },
            {
              "lat": 43.767259177777774,
              "lng": -116.10102526666665,
              "alt": 1946.2777777777778,
              "speed2d": 4.709555555555555,
              "speed3d": 4.755555555555556,
              "msFromStart": 79248.8792592593
            },
            {
              "lat": 43.76728033333334,
              "lng": -116.1010215888889,
              "alt": 1946.5682222222222,
              "speed2d": 4.7012222222222215,
              "speed3d": 4.766666666666667,
              "msFromStart": 79743.25688888894
            },
            {
              "lat": 43.76730106666666,
              "lng": -116.10101752222224,
              "alt": 1946.981777777778,
              "speed2d": 4.682666666666666,
              "speed3d": 4.728888888888888,
              "msFromStart": 80237.80296296302
            },
            {
              "lat": 43.7673225,
              "lng": -116.10101393333333,
              "alt": 1947.3941111111112,
              "speed2d": 4.733111111111111,
              "speed3d": 4.785555555555556,
              "msFromStart": 80733.5253333334
            },
            {
              "lat": 43.76734394444444,
              "lng": -116.10101035555556,
              "alt": 1947.6951111111111,
              "speed2d": 4.797666666666666,
              "speed3d": 4.84,
              "msFromStart": 81229.23940740743
            },
            {
              "lat": 43.76736596,
              "lng": -116.10100665,
              "alt": 1948.1986000000002,
              "speed2d": 4.721399999999999,
              "speed3d": 4.787000000000001,
              "msFromStart": 81751.9586666666
            },
            {
              "lat": 43.76738813333334,
              "lng": -116.10100297777778,
              "alt": 1948.7558888888889,
              "speed2d": 4.713777777777778,
              "speed3d": 4.774444444444444,
              "msFromStart": 82274.57330214423
            },
            {
              "lat": 43.76740927777777,
              "lng": -116.10099961111109,
              "alt": 1949.279111111111,
              "speed2d": 4.718555555555556,
              "speed3d": 4.796666666666667,
              "msFromStart": 82769.53178947365
            },
            {
              "lat": 43.76743033333334,
              "lng": -116.10099594444445,
              "alt": 1949.7947777777777,
              "speed2d": 4.721333333333334,
              "speed3d": 4.797777777777777,
              "msFromStart": 83264.29461208574
            },
            {
              "lat": 43.767451655555554,
              "lng": -116.1009925,
              "alt": 1950.3370000000002,
              "speed2d": 4.740777777777778,
              "speed3d": 4.816666666666666,
              "msFromStart": 83758.30577777771
            },
            {
              "lat": 43.76747283333333,
              "lng": -116.10098884444444,
              "alt": 1950.948888888889,
              "speed2d": 4.732666666666666,
              "speed3d": 4.831111111111111,
              "msFromStart": 84252.53955555553
            },
            {
              "lat": 43.76749408888888,
              "lng": -116.10098598888891,
              "alt": 1951.473888888889,
              "speed2d": 4.721333333333334,
              "speed3d": 4.812222222222221,
              "msFromStart": 84747.96444444449
            },
            {
              "lat": 43.76751507777778,
              "lng": -116.10098292222222,
              "alt": 1952.152,
              "speed2d": 4.664777777777777,
              "speed3d": 4.776666666666667,
              "msFromStart": 85243.22844444445
            },
            {
              "lat": 43.767536799999995,
              "lng": -116.10098032222223,
              "alt": 1952.747,
              "speed2d": 4.794555555555556,
              "speed3d": 4.886666666666666,
              "msFromStart": 85737.37244444442
            },
            {
              "lat": 43.76755767777779,
              "lng": -116.10097675555555,
              "alt": 1953.373111111111,
              "speed2d": 4.742111111111111,
              "speed3d": 4.873333333333333,
              "msFromStart": 86231.48740740739
            },
            {
              "lat": 43.767577911111104,
              "lng": -116.10097264444445,
              "alt": 1953.8863333333334,
              "speed2d": 4.641555555555555,
              "speed3d": 4.76,
              "msFromStart": 86725.9342222222
            },
            {
              "lat": 43.76759993,
              "lng": -116.10096856999999,
              "alt": 1954.3093,
              "speed2d": 4.7376000000000005,
              "speed3d": 4.833,
              "msFromStart": 87248.69639999997
            },
            {
              "lat": 43.76762274444444,
              "lng": -116.1009650111111,
              "alt": 1954.753333333333,
              "speed2d": 4.8245555555555555,
              "speed3d": 4.926666666666668,
              "msFromStart": 87774.08488888887
            },
            {
              "lat": 43.76764312222222,
              "lng": -116.10096087777777,
              "alt": 1955.1174444444446,
              "speed2d": 4.720666666666666,
              "speed3d": 4.83,
              "msFromStart": 88271.82103703704
            },
            {
              "lat": 43.767661977777784,
              "lng": -116.10095578888888,
              "alt": 1955.5621111111113,
              "speed2d": 4.516111111111112,
              "speed3d": 4.6466666666666665,
              "msFromStart": 88768.96044444444
            },
            {
              "lat": 43.76768071111111,
              "lng": -116.10095141111111,
              "alt": 1955.7764444444445,
              "speed2d": 4.431555555555556,
              "speed3d": 4.525555555555555,
              "msFromStart": 89264.89315399612
            },
            {
              "lat": 43.7676989,
              "lng": -116.10094727777779,
              "alt": 1955.8618888888889,
              "speed2d": 4.348,
              "speed3d": 4.452222222222223,
              "msFromStart": 89757.63578947372
            },
            {
              "lat": 43.76771746666667,
              "lng": -116.1009422111111,
              "alt": 1956.1280000000002,
              "speed2d": 4.406888888888888,
              "speed3d": 4.473333333333334,
              "msFromStart": 90250.73816764134
            },
            {
              "lat": 43.76773671111111,
              "lng": -116.10093893333335,
              "alt": 1956.021,
              "speed2d": 4.524333333333333,
              "speed3d": 4.575555555555556,
              "msFromStart": 90745.95111111112
            },
            {
              "lat": 43.76775793333334,
              "lng": -116.10093716666667,
              "alt": 1956.2725555555555,
              "speed2d": 4.701888888888888,
              "speed3d": 4.757777777777777,
              "msFromStart": 91241.31066666666
            },
            {
              "lat": 43.767778288888884,
              "lng": -116.10093316666666,
              "alt": 1956.8048888888889,
              "speed2d": 4.669666666666666,
              "speed3d": 4.75,
              "msFromStart": 91736.41244444449
            },
            {
              "lat": 43.76779695555556,
              "lng": -116.10092795555553,
              "alt": 1957.122222222222,
              "speed2d": 4.530111111111111,
              "speed3d": 4.613333333333333,
              "msFromStart": 92231.26474074076
            },
            {
              "lat": 43.76781758,
              "lng": -116.10092367000001,
              "alt": 1957.7340000000002,
              "speed2d": 4.5045,
              "speed3d": 4.582999999999999,
              "msFromStart": 92752.65933333331
            },
            {
              "lat": 43.767838422222226,
              "lng": -116.10091955555556,
              "alt": 1958.4392222222227,
              "speed2d": 4.525555555555555,
              "speed3d": 4.6033333333333335,
              "msFromStart": 93274.0820662768
            },
            {
              "lat": 43.767858077777774,
              "lng": -116.1009151,
              "alt": 1959.116,
              "speed2d": 4.508777777777777,
              "speed3d": 4.593333333333333,
              "msFromStart": 93768.38694736843
            },
            {
              "lat": 43.767878588888884,
              "lng": -116.10090942222222,
              "alt": 1960.3308888888887,
              "speed2d": 4.555333333333333,
              "speed3d": 4.667777777777778,
              "msFromStart": 94263.0320233918
            },
            {
              "lat": 43.76789886666667,
              "lng": -116.10090435555556,
              "alt": 1961.1672222222223,
              "speed2d": 4.570777777777778,
              "speed3d": 4.676666666666667,
              "msFromStart": 94758.97066666662
            },
            {
              "lat": 43.76791868888889,
              "lng": -116.10089904444445,
              "alt": 1962.0940000000003,
              "speed2d": 4.446111111111112,
              "speed3d": 4.612222222222222,
              "msFromStart": 95254.9779259259
            },
            {
              "lat": 43.76793731111111,
              "lng": -116.10089339999999,
              "alt": 1962.443888888889,
              "speed2d": 4.383444444444444,
              "speed3d": 4.49,
              "msFromStart": 95750.74222222218
            },
            {
              "lat": 43.76795744444445,
              "lng": -116.10088632222221,
              "alt": 1963.2168888888887,
              "speed2d": 4.522555555555556,
              "speed3d": 4.646666666666666,
              "msFromStart": 96246.4748148148
            },
            {
              "lat": 43.76797628888888,
              "lng": -116.10087748888888,
              "alt": 1963.8666666666668,
              "speed2d": 4.463444444444444,
              "speed3d": 4.580000000000001,
              "msFromStart": 96742.17777777783
            },
            {
              "lat": 43.76799928888889,
              "lng": -116.10087311111113,
              "alt": 1964.9150000000002,
              "speed2d": 4.8195555555555565,
              "speed3d": 4.9111111111111105,
              "msFromStart": 97237.9503703704
            },
            {
              "lat": 43.768021499999996,
              "lng": -116.1008693,
              "alt": 1965.3612222222225,
              "speed2d": 4.872,
              "speed3d": 5.012222222222223,
              "msFromStart": 97734.0133333333
            },
            {
              "lat": 43.768042855555564,
              "lng": -116.10086562222222,
              "alt": 1965.8505555555555,
              "speed2d": 4.805888888888889,
              "speed3d": 4.937777777777778,
              "msFromStart": 98229.55274074074
            },
            {
              "lat": 43.768064589999994,
              "lng": -116.10086154999999,
              "alt": 1966.3991,
              "speed2d": 4.7128000000000005,
              "speed3d": 4.846,
              "msFromStart": 98750.27666666672
            },
            {
              "lat": 43.768086499999995,
              "lng": -116.10085670000001,
              "alt": 1967.2603333333332,
              "speed2d": 4.681444444444445,
              "speed3d": 4.827777777777778,
              "msFromStart": 99271.11779337234
            },
            {
              "lat": 43.768107622222225,
              "lng": -116.10085234444445,
              "alt": 1968.116,
              "speed2d": 4.736777777777777,
              "speed3d": 4.851111111111112,
              "msFromStart": 99765.4821052631
            },
            {
              "lat": 43.76813023333333,
              "lng": -116.10084867777778,
              "alt": 1969.1196666666667,
              "speed2d": 4.926888888888889,
              "speed3d": 5.061111111111111,
              "msFromStart": 100259.96128654967
            },
            {
              "lat": 43.76815188888889,
              "lng": -116.1008451,
              "alt": 1969.4498888888888,
              "speed2d": 4.905555555555556,
              "speed3d": 5.0344444444444445,
              "msFromStart": 100754.70666666662
            },
            {
              "lat": 43.76817504444445,
              "lng": -116.10084201111111,
              "alt": 1970.245111111111,
              "speed2d": 5.0537777777777775,
              "speed3d": 5.185555555555555,
              "msFromStart": 101249.6379259259
            },
            {
              "lat": 43.76819724444445,
              "lng": -116.10083867777779,
              "alt": 1971.2310000000002,
              "speed2d": 4.939333333333333,
              "speed3d": 5.104444444444445,
              "msFromStart": 101745.20622222217
            },
            {
              "lat": 43.76821861111111,
              "lng": -116.1008349222222,
              "alt": 1971.9940000000001,
              "speed2d": 4.801111111111112,
              "speed3d": 4.995555555555556,
              "msFromStart": 102240.94711111106
            },
            {
              "lat": 43.76823806666667,
              "lng": -116.10083085555556,
              "alt": 1972.234888888889,
              "speed2d": 4.55411111111111,
              "speed3d": 4.698888888888889,
              "msFromStart": 102737.12355555549
            },
            {
              "lat": 43.76825911111111,
              "lng": -116.10082715555555,
              "alt": 1972.7314444444444,
              "speed2d": 4.729444444444445,
              "speed3d": 4.816666666666666,
              "msFromStart": 103232.84534892785
            },
            {
              "lat": 43.76828121111111,
              "lng": -116.10082421111109,
              "alt": 1973.4301111111113,
              "speed2d": 4.8887777777777774,
              "speed3d": 5.032222222222222,
              "msFromStart": 103726.57431578946
            },
            {
              "lat": 43.768304889999996,
              "lng": -116.10082114999999,
              "alt": 1974.2474000000002,
              "speed2d": 4.9185,
              "speed3d": 5.041,
              "msFromStart": 104247.70203508771
            },
            {
              "lat": 43.76832862222223,
              "lng": -116.10081758888889,
              "alt": 1975.0963333333334,
              "speed2d": 4.904,
              "speed3d": 5.043333333333333,
              "msFromStart": 104769.6684444444
            },
            {
              "lat": 43.768350033333334,
              "lng": -116.10081393333331,
              "alt": 1975.7826666666667,
              "speed2d": 4.759444444444444,
              "speed3d": 4.911111111111113,
              "msFromStart": 105264.46607407407
            },
            {
              "lat": 43.76837065555556,
              "lng": -116.10081029999998,
              "alt": 1976.3287777777778,
              "speed2d": 4.596333333333333,
              "speed3d": 4.783333333333332,
              "msFromStart": 105760.02222222218
            },
            {
              "lat": 43.768386822222226,
              "lng": -116.1008056111111,
              "alt": 1976.4674444444447,
              "speed2d": 3.8901111111111115,
              "speed3d": 4.068888888888889,
              "msFromStart": 106255.6453333333
            },
            {
              "lat": 43.768405611111106,
              "lng": -116.10080152222221,
              "alt": 1976.8984444444445,
              "speed2d": 4.153777777777777,
              "speed3d": 4.206666666666667,
              "msFromStart": 106751.23288888883
            },
            {
              "lat": 43.768429922222225,
              "lng": -116.10079917777779,
              "alt": 1977.9326666666666,
              "speed2d": 4.997777777777778,
              "speed3d": 5.025555555555554,
              "msFromStart": 107246.66281481479
            },
            {
              "lat": 43.7684527,
              "lng": -116.10079643333334,
              "alt": 1978.8045555555555,
              "speed2d": 4.882444444444444,
              "speed3d": 5.062222222222223,
              "msFromStart": 107741.47644444444
            },
            {
              "lat": 43.76847463333334,
              "lng": -116.10079353333333,
              "alt": 1979.7333333333336,
              "speed2d": 4.815666666666666,
              "speed3d": 4.956666666666666,
              "msFromStart": 108236.25612475633
            },
            {
              "lat": 43.768496088888895,
              "lng": -116.1007899222222,
              "alt": 1980.6324444444444,
              "speed2d": 4.68888888888889,
              "speed3d": 4.86,
              "msFromStart": 108731.14652631572
            },
            {
              "lat": 43.76851657777778,
              "lng": -116.10078584444445,
              "alt": 1981.3547777777778,
              "speed2d": 4.556222222222223,
              "speed3d": 4.721111111111111,
              "msFromStart": 109226.10213905128
            },
            {
              "lat": 43.76853858,
              "lng": -116.1007822,
              "alt": 1982.0694,
              "speed2d": 4.5906,
              "speed3d": 4.737,
              "msFromStart": 109748.90688888886
            },
            {
              "lat": 43.76856143333333,
              "lng": -116.10077906666666,
              "alt": 1983.0313333333334,
              "speed2d": 4.698777777777778,
              "speed3d": 4.858888888888889,
              "msFromStart": 110271.82800000001
            },
            {
              "lat": 43.76858323333333,
              "lng": -116.10077665555556,
              "alt": 1983.975777777778,
              "speed2d": 4.759333333333332,
              "speed3d": 4.916666666666667,
              "msFromStart": 110767.47911111117
            },
            {
              "lat": 43.76860526666666,
              "lng": -116.10077406666666,
              "alt": 1985.0083333333332,
              "speed2d": 4.818777777777777,
              "speed3d": 5.003333333333333,
              "msFromStart": 111263.14325925929
            },
            {
              "lat": 43.768626866666665,
              "lng": -116.1007710222222,
              "alt": 1985.976333333333,
              "speed2d": 4.769333333333334,
              "speed3d": 4.974444444444445,
              "msFromStart": 111758.76
            },
            {
              "lat": 43.76864893333333,
              "lng": -116.10076809999998,
              "alt": 1986.9204444444445,
              "speed2d": 4.7907777777777785,
              "speed3d": 5.015555555555555,
              "msFromStart": 112254.04977777778
            },
            {
              "lat": 43.768670144444435,
              "lng": -116.10076478888888,
              "alt": 1987.6821111111112,
              "speed2d": 4.723333333333334,
              "speed3d": 4.925555555555556,
              "msFromStart": 112748.0506666667
            },
            {
              "lat": 43.76869222222223,
              "lng": -116.100762,
              "alt": 1988.3106666666667,
              "speed2d": 4.728,
              "speed3d": 4.910000000000001,
              "msFromStart": 113242.12523976609
            },
            {
              "lat": 43.768715666666665,
              "lng": -116.1007583,
              "alt": 1988.88,
              "speed2d": 4.954666666666667,
              "speed3d": 5.122222222222222,
              "msFromStart": 113737.0101052631
            },
            {
              "lat": 43.7687408111111,
              "lng": -116.10075622222224,
              "alt": 1989.4697777777778,
              "speed2d": 5.256555555555554,
              "speed3d": 5.388888888888889,
              "msFromStart": 114232.07601299539
            },
            {
              "lat": 43.76876764444444,
              "lng": -116.10075411111112,
              "alt": 1990.2322222222222,
              "speed2d": 5.588888888888889,
              "speed3d": 5.735555555555556,
              "msFromStart": 114727.75199999998
            },
            {
              "lat": 43.768794590000006,
              "lng": -116.10075056,
              "alt": 1990.9626999999998,
              "speed2d": 5.5198,
              "speed3d": 5.716,
              "msFromStart": 115250.84777777779
            },
            {
              "lat": 43.76881916666667,
              "lng": -116.10074605555556,
              "alt": 1991.3405555555555,
              "speed2d": 5.209888888888888,
              "speed3d": 5.398888888888889,
              "msFromStart": 115772.90133333337
            },
            {
              "lat": 43.768841566666666,
              "lng": -116.1007416,
              "alt": 1991.7103333333334,
              "speed2d": 4.9574444444444445,
              "speed3d": 5.147777777777779,
              "msFromStart": 116267.49362962964
            },
            {
              "lat": 43.76886416666667,
              "lng": -116.10073840000001,
              "alt": 1992.2494444444442,
              "speed2d": 4.874444444444444,
              "speed3d": 5.017777777777778,
              "msFromStart": 116762.71111111107
            },
            {
              "lat": 43.76888666666667,
              "lng": -116.10073573333332,
              "alt": 1992.858888888889,
              "speed2d": 4.868444444444445,
              "speed3d": 5.003333333333333,
              "msFromStart": 117258.10666666664
            },
            {
              "lat": 43.768910299999995,
              "lng": -116.10073297777777,
              "alt": 1993.1287777777782,
              "speed2d": 5.019555555555555,
              "speed3d": 5.133333333333335,
              "msFromStart": 117753.96444444441
            },
            {
              "lat": 43.76893418888889,
              "lng": -116.10073022222224,
              "alt": 1993.1216666666667,
              "speed2d": 5.072555555555556,
              "speed3d": 5.164444444444445,
              "msFromStart": 118249.62244054579
            },
            {
              "lat": 43.76895845555555,
              "lng": -116.10072792222222,
              "alt": 1993.0962222222222,
              "speed2d": 5.124111111111111,
              "speed3d": 5.202222222222222,
              "msFromStart": 118744.29642105266
            },
            {
              "lat": 43.7689831,
              "lng": -116.10072633333334,
              "alt": 1993.0067777777774,
              "speed2d": 5.1274444444444445,
              "speed3d": 5.2155555555555555,
              "msFromStart": 119238.99007667319
            },
            {
              "lat": 43.76900603333334,
              "lng": -116.10072296666665,
              "alt": 1992.9181111111109,
              "speed2d": 4.894444444444444,
              "speed3d": 4.94888888888889,
              "msFromStart": 119734.40400000001
            },
            {
              "lat": 43.76903124444444,
              "lng": -116.10071958888888,
              "alt": 1993.4252222222221,
              "speed2d": 5.112,
              "speed3d": 5.1466666666666665,
              "msFromStart": 120229.81451851853
            },
            {
              "lat": 43.76905746999999,
              "lng": -116.10071667999999,
              "alt": 1993.5974999999999,
              "speed2d": 5.125,
              "speed3d": 5.199,
              "msFromStart": 120751.95888888884
            },
            {
              "lat": 43.76908287777778,
              "lng": -116.10071396666666,
              "alt": 1993.626222222222,
              "speed2d": 5.031444444444444,
              "speed3d": 5.072222222222222,
              "msFromStart": 121274.1425185185
            },
            {
              "lat": 43.769107500000004,
              "lng": -116.100711,
              "alt": 1994.1194444444443,
              "speed2d": 5.065222222222222,
              "speed3d": 5.145555555555555,
              "msFromStart": 121769.49422222226
            },
            {
              "lat": 43.76912926666667,
              "lng": -116.10070695555555,
              "alt": 1994.7834444444445,
              "speed2d": 4.842777777777778,
              "speed3d": 4.912222222222223,
              "msFromStart": 122265.43762962965
            },
            {
              "lat": 43.76915178888889,
              "lng": -116.10070364444445,
              "alt": 1995.4452222222226,
              "speed2d": 4.893444444444445,
              "speed3d": 4.948888888888889,
              "msFromStart": 122763.49066666668
            },
            {
              "lat": 43.76917571111111,
              "lng": -116.10070114444444,
              "alt": 1995.389222222222,
              "speed2d": 4.9784444444444444,
              "speed3d": 5.035555555555556,
              "msFromStart": 123261.06281481484
            },
            {
              "lat": 43.76919917777778,
              "lng": -116.10069820000001,
              "alt": 1995.1254444444444,
              "speed2d": 4.8999999999999995,
              "speed3d": 4.96111111111111,
              "msFromStart": 123755.86755555555
            },
            {
              "lat": 43.769222766666665,
              "lng": -116.10069507777779,
              "alt": 1995.101333333333,
              "speed2d": 4.854222222222221,
              "speed3d": 4.902222222222222,
              "msFromStart": 124250.18261208574
            },
            {
              "lat": 43.76924585555555,
              "lng": -116.10069186666666,
              "alt": 1995.6214444444447,
              "speed2d": 4.947888888888889,
              "speed3d": 4.973333333333333,
              "msFromStart": 124743.64589473681
            },
            {
              "lat": 43.76926805555555,
              "lng": -116.10068886666666,
              "alt": 1996.4304444444447,
              "speed2d": 4.952333333333334,
              "speed3d": 5.028888888888888,
              "msFromStart": 125237.00694996749
            },
            {
              "lat": 43.76929007777778,
              "lng": -116.1006859,
              "alt": 1997.2975555555554,
              "speed2d": 4.922888888888888,
              "speed3d": 4.995555555555555,
              "msFromStart": 125730.26399999997
            },
            {
              "lat": 43.76931277,
              "lng": -116.10068229999999,
              "alt": 1998.0475999999999,
              "speed2d": 4.8667,
              "speed3d": 4.925999999999999,
              "msFromStart": 126251.00088888886
            },
            {
              "lat": 43.76933718888889,
              "lng": -116.10067936666667,
              "alt": 1998.8282222222222,
              "speed2d": 4.998111111111111,
              "speed3d": 5.08,
              "msFromStart": 126772.17955555554
            },
            {
              "lat": 43.76935898888889,
              "lng": -116.10067683333332,
              "alt": 1999.3677777777777,
              "speed2d": 4.936555555555556,
              "speed3d": 4.99888888888889,
              "msFromStart": 127266.40088888888
            },
            {
              "lat": 43.76938036666667,
              "lng": -116.10067373333334,
              "alt": 1999.9053333333334,
              "speed2d": 4.839888888888889,
              "speed3d": 4.926666666666667,
              "msFromStart": 127762.27555555559
            },
            {
              "lat": 43.76940273333334,
              "lng": -116.1006711111111,
              "alt": 2000.5539999999999,
              "speed2d": 4.937111111111111,
              "speed3d": 5.012222222222222,
              "msFromStart": 128258.16366471734
            },
            {
              "lat": 43.76942295555555,
              "lng": -116.10066801111111,
              "alt": 2001.2044444444448,
              "speed2d": 4.721333333333334,
              "speed3d": 4.807777777777778,
              "msFromStart": 128753.44421052627
            },
            {
              "lat": 43.76944677777778,
              "lng": -116.10066583333332,
              "alt": 2001.3482222222224,
              "speed2d": 4.843999999999999,
              "speed3d": 4.918888888888889,
              "msFromStart": 129248.77661858348
            },
            {
              "lat": 43.76947153333333,
              "lng": -116.10066386666666,
              "alt": 2001.2276666666667,
              "speed2d": 5.016777777777778,
              "speed3d": 5.057777777777777,
              "msFromStart": 129744.796
            },
            {
              "lat": 43.769494244444445,
              "lng": -116.10066102222221,
              "alt": 2001.7842222222225,
              "speed2d": 4.987555555555556,
              "speed3d": 5.0488888888888885,
              "msFromStart": 130240.7255308642
            },
            {
              "lat": 43.76951782222223,
              "lng": -116.100658,
              "alt": 2002.3393333333333,
              "speed2d": 5.106111111111111,
              "speed3d": 5.157777777777778,
              "msFromStart": 130735.41999999998
            },
            {
              "lat": 43.7695413888889,
              "lng": -116.10065444444444,
              "alt": 2002.1761111111111,
              "speed2d": 4.948666666666666,
              "speed3d": 5.043333333333334,
              "msFromStart": 131230.06409876543
            },
            {
              "lat": 43.76956593333333,
              "lng": -116.10065145555555,
              "alt": 2002.0052222222223,
              "speed2d": 4.917777777777778,
              "speed3d": 4.986666666666667,
              "msFromStart": 131725.63599999994
            },
            {
              "lat": 43.76959065999999,
              "lng": -116.10064876999999,
              "alt": 2001.9681,
              "speed2d": 4.8033,
              "speed3d": 4.876,
              "msFromStart": 132248.90288888884
            },
            {
              "lat": 43.76961264444445,
              "lng": -116.10064506666666,
              "alt": 2002.8651111111112,
              "speed2d": 4.732888888888889,
              "speed3d": 4.8133333333333335,
              "msFromStart": 132772.18222222233
            },
            {
              "lat": 43.769633933333324,
              "lng": -116.10064136666666,
              "alt": 2003.5157777777779,
              "speed2d": 4.689111111111111,
              "speed3d": 4.783333333333334,
              "msFromStart": 133267.39079922033
            },
            {
              "lat": 43.76965522222223,
              "lng": -116.10063992222223,
              "alt": 2004.0393333333336,
              "speed2d": 4.697444444444445,
              "speed3d": 4.768888888888889,
              "msFromStart": 133760.47368421056
            },
            {
              "lat": 43.76967542222223,
              "lng": -116.10063668888888,
              "alt": 2004.5574444444444,
              "speed2d": 4.624777777777778,
              "speed3d": 4.7011111111111115,
              "msFromStart": 134253.59803508778
            },
            {
              "lat": 43.7696976,
              "lng": -116.1006339,
              "alt": 2004.7974444444444,
              "speed2d": 4.804333333333333,
              "speed3d": 4.836666666666667,
              "msFromStart": 134748.272
            },
            {
              "lat": 43.76971902222222,
              "lng": -116.10063041111111,
              "alt": 2005.2742222222225,
              "speed2d": 4.832111111111111,
              "speed3d": 4.901111111111112,
              "msFromStart": 135243.49530864198
            },
            {
              "lat": 43.769741422222225,
              "lng": -116.10062728888889,
              "alt": 2005.9403333333332,
              "speed2d": 4.934222222222223,
              "speed3d": 4.978888888888889,
              "msFromStart": 135740.52000000014
            },
            {
              "lat": 43.769764244444445,
              "lng": -116.10062444444446,
              "alt": 2005.2815555555555,
              "speed2d": 4.5665555555555555,
              "speed3d": 4.6866666666666665,
              "msFromStart": 136237.39358024704
            },
            {
              "lat": 43.769787388888886,
              "lng": -116.10062142222223,
              "alt": 2004.6192222222223,
              "speed2d": 4.513777777777778,
              "speed3d": 4.5600000000000005,
              "msFromStart": 136731.544
            },
            {
              "lat": 43.7698136,
              "lng": -116.10061806,
              "alt": 2004.3794,
              "speed2d": 4.8031,
              "speed3d": 4.798,
              "msFromStart": 137252.68266666663
            },
            {
              "lat": 43.769836866666665,
              "lng": -116.1006140888889,
              "alt": 2005.1651111111114,
              "speed2d": 4.805777777777777,
              "speed3d": 4.876666666666666,
              "msFromStart": 137773.85244444435
            },
            {
              "lat": 43.769856833333336,
              "lng": -116.10060965555556,
              "alt": 2005.835111111111,
              "speed2d": 4.6434444444444445,
              "speed3d": 4.714444444444444,
              "msFromStart": 138268.4374580896
            },
            {
              "lat": 43.769876966666665,
              "lng": -116.10060503333332,
              "alt": 2006.4712222222222,
              "speed2d": 4.635888888888888,
              "speed3d": 4.707777777777777,
              "msFromStart": 138766.38652631588
            },
            {
              "lat": 43.7698964,
              "lng": -116.10060068888889,
              "alt": 2007.0111111111114,
              "speed2d": 4.53511111111111,
              "speed3d": 4.602222222222222,
              "msFromStart": 139264.39449054017
            },
            {
              "lat": 43.76991553333333,
              "lng": -116.10059638888889,
              "alt": 2007.5587777777778,
              "speed2d": 4.513,
              "speed3d": 4.561111111111111,
              "msFromStart": 139760.7096470589
            },
            {
              "lat": 43.76993635555555,
              "lng": -116.10059316666667,
              "alt": 2008.3713333333333,
              "speed2d": 4.667444444444444,
              "speed3d": 4.722222222222223,
              "msFromStart": 140255.9761898865
            },
            {
              "lat": 43.7699560888889,
              "lng": -116.1005888,
              "alt": 2008.8265555555554,
              "speed2d": 4.610111111111111,
              "speed3d": 4.674444444444444,
              "msFromStart": 140748.1583157894
            },
            {
              "lat": 43.769976766666666,
              "lng": -116.10058479999998,
              "alt": 2009.3716666666667,
              "speed2d": 4.654444444444445,
              "speed3d": 4.719999999999999,
              "msFromStart": 141240.37352046775
            },
            {
              "lat": 43.76999952222223,
              "lng": -116.10058111111111,
              "alt": 2009.496,
              "speed2d": 4.701444444444444,
              "speed3d": 4.747777777777777,
              "msFromStart": 141734.67199999993
            },
            {
              "lat": 43.77002148888889,
              "lng": -116.10057608888889,
              "alt": 2009.9286666666667,
              "speed2d": 4.633,
              "speed3d": 4.724444444444445,
              "msFromStart": 142229.16483950615
            },
            {
              "lat": 43.77004493,
              "lng": -116.1005718,
              "alt": 2010.6114,
              "speed2d": 4.8328,
              "speed3d": 4.9,
              "msFromStart": 142750.17377777782
            },
            {
              "lat": 43.77006594444445,
              "lng": -116.10056666666667,
              "alt": 2011.0752222222227,
              "speed2d": 4.660333333333333,
              "speed3d": 4.774444444444445,
              "msFromStart": 143271.3924366472
            },
            {
              "lat": 43.77008778888888,
              "lng": -116.10056244444442,
              "alt": 2010.8868888888892,
              "speed2d": 4.610222222222222,
              "speed3d": 4.681111111111111,
              "msFromStart": 143766.62484210535
            },
            {
              "lat": 43.77011180000001,
              "lng": -116.10055934444446,
              "alt": 2010.992111111111,
              "speed2d": 4.811000000000001,
              "speed3d": 4.888888888888888,
              "msFromStart": 144262.2509681612
            },
            {
              "lat": 43.77013461111111,
              "lng": -116.10055593333334,
              "alt": 2011.3614444444443,
              "speed2d": 4.731111111111111,
              "speed3d": 4.853333333333333,
              "msFromStart": 144759.40800000005
            },
            {
              "lat": 43.77015726666667,
              "lng": -116.10055217777777,
              "alt": 2011.7978888888892,
              "speed2d": 4.719222222222222,
              "speed3d": 4.825555555555556,
              "msFromStart": 145256.27081481484
            },
            {
              "lat": 43.770180088888885,
              "lng": -116.10054843333333,
              "alt": 2011.9662222222223,
              "speed2d": 4.691111111111111,
              "speed3d": 4.83,
              "msFromStart": 145749.80799999993
            },
            {
              "lat": 43.77020333333333,
              "lng": -116.10054483333333,
              "alt": 2012.4266666666667,
              "speed2d": 4.692,
              "speed3d": 4.828888888888889,
              "msFromStart": 146242.96395061724
            },
            {
              "lat": 43.77022647777778,
              "lng": -116.10054112222224,
              "alt": 2013.1869999999997,
              "speed2d": 4.59988888888889,
              "speed3d": 4.802222222222222,
              "msFromStart": 146737.12000000002
            },
            {
              "lat": 43.77024848888889,
              "lng": -116.10053727777779,
              "alt": 2013.7889999999998,
              "speed2d": 4.536666666666667,
              "speed3d": 4.755555555555555,
              "msFromStart": 147231.78962962967
            },
            {
              "lat": 43.770270133333334,
              "lng": -116.10053343333333,
              "alt": 2014.4394444444445,
              "speed2d": 4.522444444444444,
              "speed3d": 4.7188888888888885,
              "msFromStart": 147728.5920000001
            },
            {
              "lat": 43.77029312222222,
              "lng": -116.10053018888888,
              "alt": 2015.5234444444445,
              "speed2d": 4.606333333333334,
              "speed3d": 4.85,
              "msFromStart": 148225.62153086427
            },
            {
              "lat": 43.77031627,
              "lng": -116.10052671,
              "alt": 2016.8303999999998,
              "speed2d": 4.7887,
              "speed3d": 5.035,
              "msFromStart": 148749.4684444445
            },
            {
              "lat": 43.77033803333333,
              "lng": -116.1005227888889,
              "alt": 2018.2317777777778,
              "speed2d": 4.7123333333333335,
              "speed3d": 4.998888888888889,
              "msFromStart": 149272.8314074075
            },
            {
              "lat": 43.770358433333335,
              "lng": -116.10051914444443,
              "alt": 2019.658111111111,
              "speed2d": 4.663333333333334,
              "speed3d": 4.937777777777778,
              "msFromStart": 149767.18400000015
            },
            {
              "lat": 43.77037972222223,
              "lng": -116.10051567777779,
              "alt": 2021.172888888889,
              "speed2d": 4.730888888888889,
              "speed3d": 5.003333333333334,
              "msFromStart": 150261.6596543211
            },
            {
              "lat": 43.770400477777784,
              "lng": -116.10051247777778,
              "alt": 2022.6602222222223,
              "speed2d": 4.679666666666666,
              "speed3d": 4.987777777777778,
              "msFromStart": 150757.7680000001
            },
            {
              "lat": 43.770420977777775,
              "lng": -116.10050946666667,
              "alt": 2023.9734444444446,
              "speed2d": 4.678333333333333,
              "speed3d": 4.966666666666666,
              "msFromStart": 151253.90795061734
            },
            {
              "lat": 43.77044262222222,
              "lng": -116.10050688888889,
              "alt": 2025.3803333333335,
              "speed2d": 4.785444444444444,
              "speed3d": 5.088888888888889,
              "msFromStart": 151748.60800000004
            },
            {
              "lat": 43.770463622222216,
              "lng": -116.10050401111113,
              "alt": 2026.7662222222223,
              "speed2d": 4.671111111111111,
              "speed3d": 4.982222222222222,
              "msFromStart": 152243.07891358028
            },
            {
              "lat": 43.7704853111111,
              "lng": -116.10050081111112,
              "alt": 2027.3681111111111,
              "speed2d": 4.676111111111112,
              "speed3d": 4.972222222222222,
              "msFromStart": 152737.5919999999
            },
            {
              "lat": 43.770507533333344,
              "lng": -116.10049776666666,
              "alt": 2028.068,
              "speed2d": 4.718555555555556,
              "speed3d": 5.015555555555554,
              "msFromStart": 153231.943111111
            },
            {
              "lat": 43.770529599999996,
              "lng": -116.1004956,
              "alt": 2028.9942,
              "speed2d": 4.7226,
              "speed3d": 4.995,
              "msFromStart": 153752.66133333324
            },
            {
              "lat": 43.77055105555556,
              "lng": -116.1004920888889,
              "alt": 2030.0805555555555,
              "speed2d": 4.755222222222222,
              "speed3d": 5.006666666666667,
              "msFromStart": 154273.7093801169
            },
            {
              "lat": 43.77057251111111,
              "lng": -116.1004892,
              "alt": 2031.0896666666665,
              "speed2d": 4.813444444444444,
              "speed3d": 5.094444444444445,
              "msFromStart": 154769.3549473684
            },
            {
              "lat": 43.77059144444444,
              "lng": -116.10048494444445,
              "alt": 2031.9626666666666,
              "speed2d": 4.536666666666667,
              "speed3d": 4.794444444444444,
              "msFromStart": 155265.04431448993
            },
            {
              "lat": 43.77061453333333,
              "lng": -116.10048219999999,
              "alt": 2032.6945555555556,
              "speed2d": 4.888333333333334,
              "speed3d": 5.071111111111112,
              "msFromStart": 155759.7840000001
            },
            {
              "lat": 43.77063936666667,
              "lng": -116.10047853333333,
              "alt": 2032.6889999999999,
              "speed2d": 5.180444444444444,
              "speed3d": 5.385555555555555,
              "msFromStart": 156254.27812345687
            },
            {
              "lat": 43.77066161111111,
              "lng": -116.10047315555555,
              "alt": 2032.3263333333334,
              "speed2d": 5.102555555555555,
              "speed3d": 5.245555555555555,
              "msFromStart": 156748.2240000001
            },
            {
              "lat": 43.77068666666667,
              "lng": -116.10046937777777,
              "alt": 2032.1914444444446,
              "speed2d": 5.291555555555555,
              "speed3d": 5.436666666666666,
              "msFromStart": 157242.32395061737
            },
            {
              "lat": 43.770711177777784,
              "lng": -116.10046617777778,
              "alt": 2031.9672222222225,
              "speed2d": 5.231888888888889,
              "speed3d": 5.347777777777777,
              "msFromStart": 157737.91200000007
            },
            {
              "lat": 43.77073497777778,
              "lng": -116.10046345555556,
              "alt": 2031.69,
              "speed2d": 5.1705555555555565,
              "speed3d": 5.305555555555555,
              "msFromStart": 158233.6660792723
            },
            {
              "lat": 43.77075542222222,
              "lng": -116.10045915555557,
              "alt": 2031.7179999999998,
              "speed2d": 4.939777777777778,
              "speed3d": 5.0633333333333335,
              "msFromStart": 158728.94484210532
            },
            {
              "lat": 43.770772470000004,
              "lng": -116.10045262999999,
              "alt": 2031.7396,
              "speed2d": 4.5047999999999995,
              "speed3d": 4.654,
              "msFromStart": 159251.65497076028
            },
            {
              "lat": 43.77079333333334,
              "lng": -116.10044845555556,
              "alt": 2031.5295555555556,
              "speed2d": 4.435999999999999,
              "speed3d": 4.52888888888889,
              "msFromStart": 159774.35199999996
            },
            {
              "lat": 43.77081997777777,
              "lng": -116.10044628888889,
              "alt": 2031.2397777777776,
              "speed2d": 4.91511111111111,
              "speed3d": 4.941111111111112,
              "msFromStart": 160269.45995061722
            },
            {
              "lat": 43.77084406666667,
              "lng": -116.10044248888889,
              "alt": 2031.5706666666667,
              "speed2d": 4.865666666666667,
              "speed3d": 4.988888888888888,
              "msFromStart": 160764.1039999999
            },
            {
              "lat": 43.770865855555556,
              "lng": -116.10043723333332,
              "alt": 2032.1907777777776,
              "speed2d": 4.6322222222222225,
              "speed3d": 4.753333333333332,
              "msFromStart": 161258.81422222214
            },
            {
              "lat": 43.77088328888889,
              "lng": -116.10043031111111,
              "alt": 2032.9077777777777,
              "speed2d": 4.195111111111111,
              "speed3d": 4.40888888888889,
              "msFromStart": 161754.3919999999
            },
            {
              "lat": 43.77090052222222,
              "lng": -116.1004256,
              "alt": 2033.5782222222224,
              "speed2d": 3.8973333333333335,
              "speed3d": 4.07,
              "msFromStart": 162250.09224691347
            },
            {
              "lat": 43.770915699999996,
              "lng": -116.10041948888886,
              "alt": 2034.4153333333336,
              "speed2d": 3.5406666666666666,
              "speed3d": 3.8144444444444447,
              "msFromStart": 162745.67199999993
            },
            {
              "lat": 43.77092888888889,
              "lng": -116.10041300000002,
              "alt": 2035.6184444444445,
              "speed2d": 3.0914444444444444,
              "speed3d": 3.423333333333334,
              "msFromStart": 163240.98181156587
            },
            {
              "lat": 43.77094802222222,
              "lng": -116.10040762222222,
              "alt": 2036.9847777777777,
              "speed2d": 3.3503333333333334,
              "speed3d": 3.608888888888889,
              "msFromStart": 163734.76547368415
            },
            {
              "lat": 43.770969388888886,
              "lng": -116.10040302222222,
              "alt": 2038.1348888888883,
              "speed2d": 3.8212222222222225,
              "speed3d": 4.1322222222222225,
              "msFromStart": 164228.38802339172
            },
            {
              "lat": 43.77098743999999,
              "lng": -116.10039726,
              "alt": 2039.3303,
              "speed2d": 3.83,
              "speed3d": 4.119999999999999,
              "msFromStart": 164750.3866666666
            },
            {
              "lat": 43.771011200000004,
              "lng": -116.10039358888889,
              "alt": 2040.170888888889,
              "speed2d": 4.181111111111111,
              "speed3d": 4.438888888888888,
              "msFromStart": 165272.55565432095
            },
            {
              "lat": 43.77102854444445,
              "lng": -116.10038902222222,
              "alt": 2040.9303333333332,
              "speed2d": 4.0329999999999995,
              "speed3d": 4.367777777777778,
              "msFromStart": 165766.91999999995
            },
            {
              "lat": 43.771044399999994,
              "lng": -116.10038316666666,
              "alt": 2041.7822222222223,
              "speed2d": 3.7416666666666663,
              "speed3d": 4.044444444444444,
              "msFromStart": 166261.39496296292
            },
            {
              "lat": 43.77106804444445,
              "lng": -116.10037987777778,
              "alt": 2042.811666666667,
              "speed2d": 4.156,
              "speed3d": 4.442222222222222,
              "msFromStart": 166756.86399999994
            },
            {
              "lat": 43.77108957777777,
              "lng": -116.10037592222221,
              "alt": 2043.9015555555554,
              "speed2d": 4.298333333333334,
              "speed3d": 4.590000000000001,
              "msFromStart": 167252.49896296288
            },
            {
              "lat": 43.77110543333333,
              "lng": -116.10037042222223,
              "alt": 2044.9740000000002,
              "speed2d": 4.076888888888888,
              "speed3d": 4.447777777777778,
              "msFromStart": 167748.15199999994
            },
            {
              "lat": 43.771124422222215,
              "lng": -116.1003667777778,
              "alt": 2045.75,
              "speed2d": 4.174777777777779,
              "speed3d": 4.441111111111111,
              "msFromStart": 168243.71718778426
            },
            {
              "lat": 43.771147577777775,
              "lng": -116.10036280000001,
              "alt": 2046.4532222222224,
              "speed2d": 4.377,
              "speed3d": 4.685555555555556,
              "msFromStart": 168738.72842105274
            },
            {
              "lat": 43.77116995555555,
              "lng": -116.10035755555556,
              "alt": 2047.1678888888891,
              "speed2d": 4.474222222222222,
              "speed3d": 4.73,
              "msFromStart": 169233.61325536075
            },
            {
              "lat": 43.77119635555556,
              "lng": -116.10035347777779,
              "alt": 2047.6908888888886,
              "speed2d": 4.693333333333333,
              "speed3d": 4.986666666666666,
              "msFromStart": 169728.1422222223
            },
            {
              "lat": 43.771220119999995,
              "lng": -116.10034971999998,
              "alt": 2048.0149,
              "speed2d": 4.6165,
              "speed3d": 4.925000000000001,
              "msFromStart": 170250.17733333338
            },
            {
              "lat": 43.77124603333333,
              "lng": -116.10034684444446,
              "alt": 2048.213444444444,
              "speed2d": 4.6401111111111115,
              "speed3d": 4.9222222222222225,
              "msFromStart": 170773.0960000001
            },
            {
              "lat": 43.77126683333333,
              "lng": -116.10034305555556,
              "alt": 2048.5874444444444,
              "speed2d": 4.468444444444444,
              "speed3d": 4.7877777777777775,
              "msFromStart": 171268.8843456791
            },
            {
              "lat": 43.771292,
              "lng": -116.10034071111109,
              "alt": 2049.070333333333,
              "speed2d": 4.484333333333333,
              "speed3d": 4.774444444444444,
              "msFromStart": 171765.9680000001
            },
            {
              "lat": 43.771318211111115,
              "lng": -116.1003382222222,
              "alt": 2049.7076666666667,
              "speed2d": 4.575,
              "speed3d": 4.818888888888889,
              "msFromStart": 172262.85609876554
            },
            {
              "lat": 43.77134786666666,
              "lng": -116.10033724444445,
              "alt": 2049.9235555555556,
              "speed2d": 4.947111111111112,
              "speed3d": 5.174444444444445,
              "msFromStart": 172757.25600000005
            },
            {
              "lat": 43.77136840000001,
              "lng": -116.10033421111112,
              "alt": 2050.123777777778,
              "speed2d": 4.6514444444444445,
              "speed3d": 4.952222222222222,
              "msFromStart": 173251.4982923977
            },
            {
              "lat": 43.771393200000006,
              "lng": -116.1003315,
              "alt": 2050.1188888888887,
              "speed2d": 4.5551111111111116,
              "speed3d": 4.79,
              "msFromStart": 173747.26736842107
            },
            {
              "lat": 43.77141508888889,
              "lng": -116.1003297111111,
              "alt": 2049.7001111111113,
              "speed2d": 4.357888888888889,
              "speed3d": 4.601111111111112,
              "msFromStart": 174243.20174658866
            },
            {
              "lat": 43.771429999999995,
              "lng": -116.10032701111109,
              "alt": 2049.1787777777777,
              "speed2d": 3.7526666666666664,
              "speed3d": 4.055555555555555,
              "msFromStart": 174738.2862222221
            },
            {
              "lat": 43.77144706666667,
              "lng": -116.10032587777778,
              "alt": 2048.6777777777775,
              "speed2d": 3.4604444444444447,
              "speed3d": 3.6522222222222225,
              "msFromStart": 175233.07525925915
            },
            {
              "lat": 43.771473066666665,
              "lng": -116.10032416666665,
              "alt": 2048.704111111111,
              "speed2d": 3.808888888888889,
              "speed3d": 3.9288888888888884,
              "msFromStart": 175727.03466666664
            },
            {
              "lat": 43.77150608,
              "lng": -116.10032401999999,
              "alt": 2048.8697,
              "speed2d": 4.4036,
              "speed3d": 4.54,
              "msFromStart": 176248.1888888889
            },
            {
              "lat": 43.771534566666666,
              "lng": -116.1003235222222,
              "alt": 2049.0620000000004,
              "speed2d": 4.539777777777777,
              "speed3d": 4.675555555555556,
              "msFromStart": 176769.14400000003
            },
            {
              "lat": 43.771562644444444,
              "lng": -116.10032202222223,
              "alt": 2049.576888888889,
              "speed2d": 4.663,
              "speed3d": 4.805555555555555,
              "msFromStart": 177262.91358024694
            },
            {
              "lat": 43.77159078888889,
              "lng": -116.10032097777776,
              "alt": 2050.0905555555555,
              "speed2d": 4.749777777777778,
              "speed3d": 4.897777777777778,
              "msFromStart": 177758.35200000007
            },
            {
              "lat": 43.771605722222226,
              "lng": -116.10031687777777,
              "alt": 2050.282222222222,
              "speed2d": 4.082666666666666,
              "speed3d": 4.383333333333334,
              "msFromStart": 178254.06732683568
            },
            {
              "lat": 43.77162194444443,
              "lng": -116.10031335555556,
              "alt": 2050.685,
              "speed2d": 3.742444444444445,
              "speed3d": 3.9288888888888893,
              "msFromStart": 178749.8029473685
            },
            {
              "lat": 43.771645933333346,
              "lng": -116.10031113333332,
              "alt": 2051.1344444444444,
              "speed2d": 3.8049999999999997,
              "speed3d": 3.948888888888888,
              "msFromStart": 179245.5049356726
            },
            {
              "lat": 43.771668766666664,
              "lng": -116.10030905555556,
              "alt": 2051.5664444444446,
              "speed2d": 4.006777777777778,
              "speed3d": 4.1722222222222225,
              "msFromStart": 179740.8373333334
            },
            {
              "lat": 43.7716872,
              "lng": -116.10029985555556,
              "alt": 2052.884111111111,
              "speed2d": 4.166,
              "speed3d": 4.305555555555556,
              "msFromStart": 180236.0044444445
            },
            {
              "lat": 43.77170152222222,
              "lng": -116.10029235555554,
              "alt": 2052.2876666666666,
              "speed2d": 3.9385555555555554,
              "speed3d": 4.135555555555555,
              "msFromStart": 180730.44266666676
            },
            {
              "lat": 43.77171257777778,
              "lng": -116.10028854444445,
              "alt": 2050.7996666666663,
              "speed2d": 3.5679999999999996,
              "speed3d": 3.7144444444444447,
              "msFromStart": 181224.8320000001
            },
            {
              "lat": 43.77172294,
              "lng": -116.10028709,
              "alt": 2048.995,
              "speed2d": 3.1191999999999998,
              "speed3d": 3.2310000000000003,
              "msFromStart": 181748.1133333333
            },
            {
              "lat": 43.77173352222222,
              "lng": -116.10028000000001,
              "alt": 2048.7622222222226,
              "speed2d": 2.940444444444444,
              "speed3d": 2.9722222222222223,
              "msFromStart": 182271.4346666666
            },
            {
              "lat": 43.77174628888889,
              "lng": -116.10027233333332,
              "alt": 2049.7055555555557,
              "speed2d": 2.998222222222222,
              "speed3d": 3.024444444444444,
              "msFromStart": 182765.408
            },
            {
              "lat": 43.7717626,
              "lng": -116.10026643333332,
              "alt": 2050.205888888889,
              "speed2d": 3.3225555555555553,
              "speed3d": 3.3177777777777773,
              "msFromStart": 183259.1531851852
            },
            {
              "lat": 43.77178488888888,
              "lng": -116.1002599888889,
              "alt": 2050.952222222222,
              "speed2d": 3.960111111111111,
              "speed3d": 3.9377777777777783,
              "msFromStart": 183753.29600000012
            },
            {
              "lat": 43.77180378888889,
              "lng": -116.10025488888888,
              "alt": 2051.2424444444446,
              "speed2d": 4.020111111111111,
              "speed3d": 4.064444444444444,
              "msFromStart": 184247.58814814824
            },
            {
              "lat": 43.77181948888889,
              "lng": -116.10024855555555,
              "alt": 2051.7675555555556,
              "speed2d": 3.9179999999999997,
              "speed3d": 3.9588888888888887,
              "msFromStart": 184742.72177777768
            },
            {
              "lat": 43.77183941111112,
              "lng": -116.10024567777779,
              "alt": 2052.251777777778,
              "speed2d": 4.031,
              "speed3d": 4.046666666666667,
              "msFromStart": 185238.17303703693
            },
            {
              "lat": 43.77185985555556,
              "lng": -116.10024137777778,
              "alt": 2052.8059999999996,
              "speed2d": 4.025888888888889,
              "speed3d": 4.053333333333335,
              "msFromStart": 185734.69688888887
            },
            {
              "lat": 43.77188354444445,
              "lng": -116.10023544444445,
              "alt": 2052.9058888888885,
              "speed2d": 4.5264444444444445,
              "speed3d": 4.4911111111111115,
              "msFromStart": 186231.37333333335
            },
            {
              "lat": 43.771907444444444,
              "lng": -116.10022891111109,
              "alt": 2052.884222222222,
              "speed2d": 4.9448888888888884,
              "speed3d": 4.954444444444444,
              "msFromStart": 186726.89422222218
            },
            {
              "lat": 43.771930080000004,
              "lng": -116.10022217,
              "alt": 2052.9691000000003,
              "speed2d": 4.8536,
              "speed3d": 4.879,
              "msFromStart": 187249.56266666664
            },
            {
              "lat": 43.77195482222222,
              "lng": -116.10021705555555,
              "alt": 2052.644666666667,
              "speed2d": 4.8484444444444454,
              "speed3d": 4.862222222222223,
              "msFromStart": 187771.6959999999
            },
            {
              "lat": 43.77197617777779,
              "lng": -116.10021696666668,
              "alt": 2051.9657777777775,
              "speed2d": 4.583,
              "speed3d": 4.636666666666667,
              "msFromStart": 188266.2247173488
            },
            {
              "lat": 43.77199852222222,
              "lng": -116.10022453333335,
              "alt": 2051.0616666666665,
              "speed2d": 4.628333333333333,
              "speed3d": 4.591111111111111,
              "msFromStart": 188760.66021052626
            },
            {
              "lat": 43.77202624444445,
              "lng": -116.10023727777778,
              "alt": 2050.093888888889,
              "speed2d": 5.065111111111111,
              "speed3d": 5.054444444444445,
              "msFromStart": 189255.14647953212
            },
            {
              "lat": 43.77204427777778,
              "lng": -116.10024373333333,
              "alt": 2049.8018888888887,
              "speed2d": 4.5887777777777785,
              "speed3d": 4.667777777777777,
              "msFromStart": 189750.29333333342
            }
          ]
        }
      ]
    },
    {
      "id": "lift-coach",
      "pathType": "lift",
      "name": "Coach",
      "speed": "slow",
      "seats": 2,
      "videos": [
        {
          "videoId": "GH010035",
          "pathId": "lift-coach",
          "startSec": 40,
          "endSec": 238,
          "id": "video-segment-9",
          "gps": [
            {
              "lat": 43.76367897777778,
              "lng": -116.10316906666667,
              "alt": 1883.2394444444444,
              "speed2d": 0.3473333333333333,
              "speed3d": 0.3777777777777778,
              "msFromStart": 40264.99459259261
            },
            {
              "lat": 43.76367932222223,
              "lng": -116.1031723,
              "alt": 1883.3993333333333,
              "speed2d": 0.25022222222222223,
              "speed3d": 0.22555555555555554,
              "msFromStart": 40759.65088888892
            },
            {
              "lat": 43.763695922222226,
              "lng": -116.10314706666665,
              "alt": 1883.889666666667,
              "speed2d": 1.6937777777777776,
              "speed3d": 1.5266666666666666,
              "msFromStart": 41254.49076413256
            },
            {
              "lat": 43.763704833333335,
              "lng": -116.10313686666666,
              "alt": 1883.8681111111114,
              "speed2d": 1.9140000000000001,
              "speed3d": 1.9155555555555552,
              "msFromStart": 41749.91115789474
            },
            {
              "lat": 43.763719755555556,
              "lng": -116.10312934444445,
              "alt": 1883.2618888888887,
              "speed2d": 2.4082222222222223,
              "speed3d": 2.347777777777778,
              "msFromStart": 42245.38711500975
            },
            {
              "lat": 43.76372803333334,
              "lng": -116.10312858888891,
              "alt": 1882.8725555555557,
              "speed2d": 2.3954444444444443,
              "speed3d": 2.417777777777778,
              "msFromStart": 42740.96311111108
            },
            {
              "lat": 43.76373552222222,
              "lng": -116.10311043333331,
              "alt": 1881.3856666666666,
              "speed2d": 2.747777777777778,
              "speed3d": 2.7055555555555553,
              "msFromStart": 43236.482074074054
            },
            {
              "lat": 43.76373612222222,
              "lng": -116.10310451111113,
              "alt": 1881.0324444444445,
              "speed2d": 2.377111111111111,
              "speed3d": 2.4877777777777776,
              "msFromStart": 43731.73288888889
            },
            {
              "lat": 43.7637308,
              "lng": -116.10311182222222,
              "alt": 1880.8268888888892,
              "speed2d": 1.4606666666666666,
              "speed3d": 1.5644444444444447,
              "msFromStart": 44227.04577777777
            },
            {
              "lat": 43.76372838026367,
              "lng": -116.10311734926047,
              "alt": 1881.1117912237357,
              "speed2d": 1.135992990556566,
              "speed3d": 1.254766881257394,
              "msFromStart": 44500
            },
            {
              "lat": 43.76373483720984,
              "lng": -116.10313490212384,
              "alt": 1883.036650759036,
              "speed2d": 1.3447466871060025,
              "speed3d": 1.48984987286712,
              "msFromStart": 45000
            },
            {
              "lat": 43.76374129415601,
              "lng": -116.10315245498721,
              "alt": 1884.9615102943364,
              "speed2d": 1.553500383655439,
              "speed3d": 1.7249328644768458,
              "msFromStart": 45500
            },
            {
              "lat": 43.76374326,
              "lng": -116.10317556,
              "alt": 1887.1440000000002,
              "speed2d": 1.2563999999999997,
              "speed3d": 1.452,
              "msFromStart": 46218.49916491229
            },
            {
              "lat": 43.76372565,
              "lng": -116.103177625,
              "alt": 1886.23425,
              "speed2d": 0.9810000000000001,
              "speed3d": 0.86,
              "msFromStart": 46744.05510526315
            },
            {
              "lat": 43.7636856375,
              "lng": -116.1031743625,
              "alt": 1884.131625,
              "speed2d": 3.9402500000000003,
              "speed3d": 3.6487499999999997,
              "msFromStart": 47273.6125745614
            },
            {
              "lat": 43.763653677777775,
              "lng": -116.10317238888888,
              "alt": 1882.5005555555554,
              "speed2d": 5.126222222222221,
              "speed3d": 5.1177777777777775,
              "msFromStart": 47747.531555555535
            },
            {
              "lat": 43.76363387777777,
              "lng": -116.10317338888888,
              "alt": 1881.3988888888887,
              "speed2d": 4.769888888888889,
              "speed3d": 4.789999999999999,
              "msFromStart": 48241.965555555544
            },
            {
              "lat": 43.76361613333334,
              "lng": -116.10317020000001,
              "alt": 1880.2833333333335,
              "speed2d": 4.404333333333334,
              "speed3d": 4.536666666666666,
              "msFromStart": 48736.239555555556
            },
            {
              "lat": 43.763614759999996,
              "lng": -116.10317122,
              "alt": 1880.1066,
              "speed2d": 3.1004,
              "speed3d": 3.34,
              "msFromStart": 49120.69871111111
            },
            {
              "lat": 43.763613157142856,
              "lng": -116.10317867142857,
              "alt": 1879.669,
              "speed2d": 1.924142857142857,
              "speed3d": 1.92,
              "msFromStart": 49773.79650793652
            },
            {
              "lat": 43.76362544,
              "lng": -116.10318398000001,
              "alt": 1880.2448,
              "speed2d": 1.2044000000000001,
              "speed3d": 1.2859999999999998,
              "msFromStart": 50211.22186666667
            },
            {
              "lat": 43.76367277777777,
              "lng": -116.10321215555555,
              "alt": 1884.9765555555555,
              "speed2d": 3.723,
              "speed3d": 3.58,
              "msFromStart": 50771.9542222222
            },
            {
              "lat": 43.763682275,
              "lng": -116.1032183625,
              "alt": 1885.7387499999998,
              "speed2d": 3.0976250000000003,
              "speed3d": 3.2875,
              "msFromStart": 51239.33614912281
            },
            {
              "lat": 43.76368075,
              "lng": -116.10321485,
              "alt": 1885.229,
              "speed2d": 1.701,
              "speed3d": 1.6400000000000001,
              "msFromStart": 51845.0381052632
            },
            {
              "lat": 43.76369211666666,
              "lng": -116.10322126666668,
              "alt": 1886.089,
              "speed2d": 2.037333333333333,
              "speed3d": 2.0749999999999997,
              "msFromStart": 52331.20366081873
            },
            {
              "lat": 43.76368952,
              "lng": -116.10322512,
              "alt": 1885.507,
              "speed2d": 1.0722,
              "speed3d": 1.184,
              "msFromStart": 52785.57555555558
            },
            {
              "lat": 43.763694855555556,
              "lng": -116.10321828888891,
              "alt": 1884.592111111111,
              "speed2d": 1.3794444444444443,
              "speed3d": 1.353333333333333,
              "msFromStart": 53247.12229629631
            },
            {
              "lat": 43.763696133333326,
              "lng": -116.10321768333333,
              "alt": 1884.5238333333332,
              "speed2d": 1.193,
              "speed3d": 1.245,
              "msFromStart": 53677.0752222222
            },
            {
              "lat": 43.76369306666666,
              "lng": -116.1032219,
              "alt": 1884.6480000000001,
              "speed2d": 0.5341666666666667,
              "speed3d": 0.6166666666666666,
              "msFromStart": 54180.312444444426
            },
            {
              "lat": 43.76368703333333,
              "lng": -116.10323183333334,
              "alt": 1885.472166666667,
              "speed2d": 0.4561666666666667,
              "speed3d": 0.47500000000000003,
              "msFromStart": 54693.902370370364
            },
            {
              "lat": 43.763678266666666,
              "lng": -116.10323054999999,
              "alt": 1884.6145,
              "speed2d": 0.5133333333333334,
              "speed3d": 0.52,
              "msFromStart": 55225.96477777778
            },
            {
              "lat": 43.76368164,
              "lng": -116.10324359999998,
              "alt": 1884.3074,
              "speed2d": 1.016,
              "speed3d": 1.024,
              "msFromStart": 55699.13493333332
            },
            {
              "lat": 43.76368205,
              "lng": -116.10325580000001,
              "alt": 1884.9574999999998,
              "speed2d": 0.9643333333333333,
              "speed3d": 0.995,
              "msFromStart": 56253.00987134502
            },
            {
              "lat": 43.76367232,
              "lng": -116.10326136,
              "alt": 1885.6296,
              "speed2d": 0.9187999999999998,
              "speed3d": 0.9039999999999999,
              "msFromStart": 56832.65014736839
            },
            {
              "lat": 43.7636675,
              "lng": -116.10326217142857,
              "alt": 1885.894142857143,
              "speed2d": 0.8795714285714286,
              "speed3d": 0.9485714285714286,
              "msFromStart": 57214.72592147033
            },
            {
              "lat": 43.763662,
              "lng": -116.10326736666667,
              "alt": 1885.4723333333336,
              "speed2d": 0.9216666666666666,
              "speed3d": 0.9099999999999999,
              "msFromStart": 57683.88459259263
            },
            {
              "lat": 43.76365276,
              "lng": -116.1032639,
              "alt": 1885.2282000000002,
              "speed2d": 0.9944000000000002,
              "speed3d": 1.036,
              "msFromStart": 58263.64835555556
            },
            {
              "lat": 43.76364655,
              "lng": -116.10326789999999,
              "alt": 1885.538,
              "speed2d": 0.5780000000000001,
              "speed3d": 0.65,
              "msFromStart": 58830.479999999996
            },
            {
              "lat": 43.76364271666667,
              "lng": -116.10326831666667,
              "alt": 1885.632,
              "speed2d": 0.45399999999999996,
              "speed3d": 0.575,
              "msFromStart": 59215.726814814814
            },
            {
              "lat": 43.763640450000004,
              "lng": -116.103263375,
              "alt": 1884.8274999999999,
              "speed2d": 0.272,
              "speed3d": 0.335,
              "msFromStart": 59724.94066666665
            },
            {
              "lat": 43.7636363,
              "lng": -116.103258775,
              "alt": 1882.37575,
              "speed2d": 0.54125,
              "speed3d": 0.495,
              "msFromStart": 60247.72411111111
            },
            {
              "lat": 43.763628499999996,
              "lng": -116.10325342857143,
              "alt": 1879.5454285714288,
              "speed2d": 0.9821428571428572,
              "speed3d": 0.9857142857142857,
              "msFromStart": 60775.32514285711
            },
            {
              "lat": 43.763625499344656,
              "lng": -116.10325410032767,
              "alt": 1878.6434475480603,
              "speed2d": 1.0938484863004596,
              "speed3d": 1.1221294076233608,
              "msFromStart": 61000
            },
            {
              "lat": 43.763618300000005,
              "lng": -116.103255775,
              "alt": 1876.56325,
              "speed2d": 0.8267499999999999,
              "speed3d": 1.085,
              "msFromStart": 61744.2876842105
            },
            {
              "lat": 43.76361753333333,
              "lng": -116.1032501,
              "alt": 1875.776,
              "speed2d": 0.11633333333333334,
              "speed3d": 0.5266666666666666,
              "msFromStart": 62321.56977777778
            },
            {
              "lat": 43.763613660000004,
              "lng": -116.10325074000002,
              "alt": 1876.0259999999998,
              "speed2d": 0.2416,
              "speed3d": 0.524,
              "msFromStart": 62741.14213333335
            },
            {
              "lat": 43.763607183333335,
              "lng": -116.10325283333333,
              "alt": 1876.4478333333334,
              "speed2d": 0.4995,
              "speed3d": 0.5783333333333333,
              "msFromStart": 63250.71062962964
            },
            {
              "lat": 43.763599625000005,
              "lng": -116.10325485,
              "alt": 1876.89025,
              "speed2d": 0.8325,
              "speed3d": 0.8074999999999999,
              "msFromStart": 63810.711111111144
            },
            {
              "lat": 43.763590300000004,
              "lng": -116.103256625,
              "alt": 1878.07275,
              "speed2d": 1.2725,
              "speed3d": 1.2025000000000001,
              "msFromStart": 64278.978833333356
            },
            {
              "lat": 43.76358637339456,
              "lng": -116.10325602353713,
              "alt": 1878.3323960288765,
              "speed2d": 1.316630945499839,
              "speed3d": 1.3821170296383214,
              "msFromStart": 64500
            },
            {
              "lat": 43.763569159999996,
              "lng": -116.10325462,
              "alt": 1880.8536,
              "speed2d": 1.2189999999999999,
              "speed3d": 1.264,
              "msFromStart": 65320.22297777777
            },
            {
              "lat": 43.76356018,
              "lng": -116.10325687999998,
              "alt": 1883.0413999999998,
              "speed2d": 0.9768,
              "speed3d": 1.0779999999999998,
              "msFromStart": 65792.10311111108
            },
            {
              "lat": 43.76356016,
              "lng": -116.10325959999999,
              "alt": 1884.2072000000003,
              "speed2d": 0.37,
              "speed3d": 0.44599999999999995,
              "msFromStart": 66285.884
            },
            {
              "lat": 43.763557725,
              "lng": -116.1032605125,
              "alt": 1884.027375,
              "speed2d": 0.5015000000000001,
              "speed3d": 0.55125,
              "msFromStart": 66738.54500000003
            },
            {
              "lat": 43.763554733333336,
              "lng": -116.1032603,
              "alt": 1883.7183333333332,
              "speed2d": 0.7406666666666667,
              "speed3d": 0.72,
              "msFromStart": 67200.03155555557
            },
            {
              "lat": 43.763550599999995,
              "lng": -116.10326046666667,
              "alt": 1882.7946666666667,
              "speed2d": 0.7153333333333334,
              "speed3d": 0.6566666666666667,
              "msFromStart": 67847.59777777774
            },
            {
              "lat": 43.76354564,
              "lng": -116.10326047999999,
              "alt": 1882.5924,
              "speed2d": 0.8549999999999999,
              "speed3d": 0.898,
              "msFromStart": 68272.0237333333
            },
            {
              "lat": 43.763540250000005,
              "lng": -116.1032606875,
              "alt": 1882.3848750000002,
              "speed2d": 1.172375,
              "speed3d": 1.0875,
              "msFromStart": 68780.84966666662
            },
            {
              "lat": 43.7635358,
              "lng": -116.10325966666667,
              "alt": 1882.272,
              "speed2d": 1.1803333333333332,
              "speed3d": 1.2366666666666668,
              "msFromStart": 69139.87979084962
            },
            {
              "lat": 43.76352865,
              "lng": -116.10326404999999,
              "alt": 1882.768,
              "speed2d": 1.0082499999999999,
              "speed3d": 1.0075,
              "msFromStart": 69775.963882353
            },
            {
              "lat": 43.763522625,
              "lng": -116.10326972499999,
              "alt": 1883.8049999999998,
              "speed2d": 1.112875,
              "speed3d": 1.13375,
              "msFromStart": 70274.20956862748
            },
            {
              "lat": 43.7635179,
              "lng": -116.10327146,
              "alt": 1883.6724,
              "speed2d": 1.1036000000000001,
              "speed3d": 1.116,
              "msFromStart": 70743.20711111116
            },
            {
              "lat": 43.76351316,
              "lng": -116.10327290000001,
              "alt": 1883.5335999999998,
              "speed2d": 0.9156000000000001,
              "speed3d": 0.994,
              "msFromStart": 71260.72400000002
            },
            {
              "lat": 43.763510600000004,
              "lng": -116.10327459999999,
              "alt": 1883.9222857142854,
              "speed2d": 0.5645714285714286,
              "speed3d": 0.6585714285714286,
              "msFromStart": 71694.51092063489
            },
            {
              "lat": 43.763507925,
              "lng": -116.103275675,
              "alt": 1883.75025,
              "speed2d": 0.45475,
              "speed3d": 0.54,
              "msFromStart": 72297.53281286548
            },
            {
              "lat": 43.76350528,
              "lng": -116.10327542,
              "alt": 1883.3042,
              "speed2d": 0.5774,
              "speed3d": 0.584,
              "msFromStart": 72756.6776421053
            },
            {
              "lat": 43.76350133333333,
              "lng": -116.10327576666667,
              "alt": 1883.2636666666667,
              "speed2d": 0.8051666666666667,
              "speed3d": 0.83,
              "msFromStart": 73264.17739571152
            },
            {
              "lat": 43.76349787142858,
              "lng": -116.10327838571429,
              "alt": 1883.6445714285715,
              "speed2d": 0.6401428571428571,
              "speed3d": 0.6414285714285715,
              "msFromStart": 73759.32209523814
            },
            {
              "lat": 43.763492275,
              "lng": -116.1032814125,
              "alt": 1883.962875,
              "speed2d": 0.9875,
              "speed3d": 1.00625,
              "msFromStart": 74261.44411111115
            },
            {
              "lat": 43.763486,
              "lng": -116.10328417499998,
              "alt": 1884.5525000000002,
              "speed2d": 1.032,
              "speed3d": 1.105,
              "msFromStart": 74757.78311111109
            },
            {
              "lat": 43.7634827,
              "lng": -116.1032907,
              "alt": 1885.0845,
              "speed2d": 1.0536666666666665,
              "speed3d": 1.0916666666666666,
              "msFromStart": 75208.14599999998
            },
            {
              "lat": 43.763477699999996,
              "lng": -116.10329752499999,
              "alt": 1886.24125,
              "speed2d": 1.302,
              "speed3d": 1.345,
              "msFromStart": 75652.72499999996
            },
            {
              "lat": 43.7634666,
              "lng": -116.1033012,
              "alt": 1886.5216666666665,
              "speed2d": 1.5273333333333334,
              "speed3d": 1.6033333333333335,
              "msFromStart": 76280.38063157897
            },
            {
              "lat": 43.763459100000006,
              "lng": -116.1033052,
              "alt": 1886.79875,
              "speed2d": 1.748,
              "speed3d": 1.825,
              "msFromStart": 76711.14800000006
            },
            {
              "lat": 43.76345513107473,
              "lng": -116.1033127679573,
              "alt": 1887.6769691904444,
              "speed2d": 1.8287633718836036,
              "speed3d": 1.828968416373509,
              "msFromStart": 77000
            },
            {
              "lat": 43.76344256666667,
              "lng": -116.10332016666666,
              "alt": 1888.6673333333333,
              "speed2d": 1.7389999999999999,
              "speed3d": 1.8433333333333333,
              "msFromStart": 77764.38399999996
            },
            {
              "lat": 43.7634331,
              "lng": -116.103324,
              "alt": 1889.2566666666667,
              "speed2d": 1.818,
              "speed3d": 1.9766666666666666,
              "msFromStart": 78331.74666666667
            },
            {
              "lat": 43.763427799999995,
              "lng": -116.10332873333333,
              "alt": 1889.8649999999998,
              "speed2d": 1.573,
              "speed3d": 1.7433333333333332,
              "msFromStart": 78770.79733333338
            },
            {
              "lat": 43.7634216,
              "lng": -116.1033407,
              "alt": 1891.3955,
              "speed2d": 1.806,
              "speed3d": 2.04,
              "msFromStart": 79292.40000000001
            },
            {
              "lat": 43.76341763999999,
              "lng": -116.10335024,
              "alt": 1891.781,
              "speed2d": 1.8994,
              "speed3d": 2.148,
              "msFromStart": 79716.1669333334
            },
            {
              "lat": 43.763410449999995,
              "lng": -116.103345175,
              "alt": 1891.45125,
              "speed2d": 1.312,
              "speed3d": 1.5575,
              "msFromStart": 80255.45766666673
            },
            {
              "lat": 43.76340331428571,
              "lng": -116.1033467857143,
              "alt": 1891.5677142857141,
              "speed2d": 1.3937142857142857,
              "speed3d": 1.5799999999999998,
              "msFromStart": 80750.53866666667
            },
            {
              "lat": 43.76339614,
              "lng": -116.10335235999999,
              "alt": 1891.8314000000005,
              "speed2d": 1.5655999999999999,
              "speed3d": 1.77,
              "msFromStart": 81234.84677894737
            },
            {
              "lat": 43.763390666666666,
              "lng": -116.10335606666668,
              "alt": 1891.7933333333333,
              "speed2d": 1.4623333333333333,
              "speed3d": 1.6183333333333332,
              "msFromStart": 81705.65052631573
            },
            {
              "lat": 43.763379400000005,
              "lng": -116.1033581,
              "alt": 1891.4395000000002,
              "speed2d": 1.925,
              "speed3d": 2.0625,
              "msFromStart": 82321.53933333335
            },
            {
              "lat": 43.763374166666665,
              "lng": -116.1033606,
              "alt": 1891.41,
              "speed2d": 1.4166666666666667,
              "speed3d": 1.54,
              "msFromStart": 82825.31081481483
            },
            {
              "lat": 43.76336993333333,
              "lng": -116.10336323333334,
              "alt": 1891.629,
              "speed2d": 1.4543333333333335,
              "speed3d": 1.5499999999999998,
              "msFromStart": 83155.05214814816
            },
            {
              "lat": 43.76336236,
              "lng": -116.1033706,
              "alt": 1892.079,
              "speed2d": 1.4613999999999998,
              "speed3d": 1.532,
              "msFromStart": 83832.60444444441
            },
            {
              "lat": 43.76335353333333,
              "lng": -116.10337078333333,
              "alt": 1892.5191666666665,
              "speed2d": 1.7548333333333332,
              "speed3d": 1.8516666666666666,
              "msFromStart": 84299.8111111111
            },
            {
              "lat": 43.76334773999999,
              "lng": -116.10337312,
              "alt": 1892.8074000000001,
              "speed2d": 1.3761999999999999,
              "speed3d": 1.512,
              "msFromStart": 84734.77484444447
            },
            {
              "lat": 43.76334035000001,
              "lng": -116.103378125,
              "alt": 1893.51925,
              "speed2d": 1.358,
              "speed3d": 1.5125,
              "msFromStart": 85284.12094444447
            },
            {
              "lat": 43.7633319,
              "lng": -116.10338247142857,
              "alt": 1894.6408571428572,
              "speed2d": 1.510857142857143,
              "speed3d": 1.625714285714286,
              "msFromStart": 85814.39333333338
            },
            {
              "lat": 43.763325328571426,
              "lng": -116.10338377142857,
              "alt": 1894.9124285714288,
              "speed2d": 1.7212857142857143,
              "speed3d": 1.8114285714285718,
              "msFromStart": 86199.98819047622
            },
            {
              "lat": 43.763315739999996,
              "lng": -116.10338428,
              "alt": 1895.0086,
              "speed2d": 1.8648,
              "speed3d": 1.9799999999999998,
              "msFromStart": 86695.196
            },
            {
              "lat": 43.763307174999994,
              "lng": -116.10338337499999,
              "alt": 1894.37725,
              "speed2d": 1.8227499999999999,
              "speed3d": 1.9949999999999999,
              "msFromStart": 87121.55687719295
            },
            {
              "lat": 43.76329495,
              "lng": -116.1033792,
              "alt": 1892.3345,
              "speed2d": 1.4975,
              "speed3d": 1.5550000000000002,
              "msFromStart": 87932.52336842098
            },
            {
              "lat": 43.763288939999995,
              "lng": -116.10337806,
              "alt": 1891.7688,
              "speed2d": 1.5784,
              "speed3d": 1.646,
              "msFromStart": 88256.97372631576
            },
            {
              "lat": 43.76328185,
              "lng": -116.10337670000001,
              "alt": 1891.067,
              "speed2d": 1.405,
              "speed3d": 1.4849999999999999,
              "msFromStart": 88702.81533333329
            },
            {
              "lat": 43.7632745,
              "lng": -116.10338056666666,
              "alt": 1891.9456666666667,
              "speed2d": 1.3096666666666668,
              "speed3d": 1.2799999999999998,
              "msFromStart": 89299.04177777775
            },
            {
              "lat": 43.76325898333333,
              "lng": -116.10337788333334,
              "alt": 1892.4106666666667,
              "speed2d": 2.7758333333333334,
              "speed3d": 2.7399999999999998,
              "msFromStart": 89839.71244444439
            },
            {
              "lat": 43.763252988888894,
              "lng": -116.1033809,
              "alt": 1892.8576666666668,
              "speed2d": 1.8478888888888887,
              "speed3d": 2.0677777777777777,
              "msFromStart": 90270.18385185182
            },
            {
              "lat": 43.763248250000004,
              "lng": -116.10338312500001,
              "alt": 1893.0939999999998,
              "speed2d": 1.41475,
              "speed3d": 1.5050000000000001,
              "msFromStart": 90791.44866666672
            },
            {
              "lat": 43.763243800000005,
              "lng": -116.10338473333333,
              "alt": 1893.3483333333334,
              "speed2d": 1.4263333333333332,
              "speed3d": 1.4766666666666666,
              "msFromStart": 91148.08843664723
            },
            {
              "lat": 43.76323515,
              "lng": -116.1033906,
              "alt": 1893.9295,
              "speed2d": 1.4144999999999999,
              "speed3d": 1.51,
              "msFromStart": 91807.65263157897
            },
            {
              "lat": 43.76322366000001,
              "lng": -116.10338754,
              "alt": 1891.7613999999999,
              "speed2d": 2.223,
              "speed3d": 2.184,
              "msFromStart": 92313.41494736844
            },
            {
              "lat": 43.76321473333333,
              "lng": -116.10338473333334,
              "alt": 1890.0145555555557,
              "speed2d": 2.0581111111111112,
              "speed3d": 2.143333333333333,
              "msFromStart": 92742.14800000003
            },
            {
              "lat": 43.763208222222225,
              "lng": -116.10338484444443,
              "alt": 1888.5962222222222,
              "speed2d": 1.5355555555555553,
              "speed3d": 1.5677777777777777,
              "msFromStart": 93236.95496296298
            },
            {
              "lat": 43.763201466666665,
              "lng": -116.10338757777777,
              "alt": 1888.6646666666668,
              "speed2d": 1.5215555555555556,
              "speed3d": 1.5366666666666666,
              "msFromStart": 93732.51199999996
            },
            {
              "lat": 43.7631978,
              "lng": -116.10339052857142,
              "alt": 1888.754428571429,
              "speed2d": 1.1720000000000002,
              "speed3d": 1.22,
              "msFromStart": 94173.12558730155
            },
            {
              "lat": 43.76319003333334,
              "lng": -116.10339531666668,
              "alt": 1888.7305,
              "speed2d": 1.5403333333333333,
              "speed3d": 1.5083333333333333,
              "msFromStart": 94733.24681481482
            },
            {
              "lat": 43.763180899999995,
              "lng": -116.1033922,
              "alt": 1885.9775,
              "speed2d": 1.6185,
              "speed3d": 1.6,
              "msFromStart": 95329.72977777779
            },
            {
              "lat": 43.76317511428571,
              "lng": -116.10338682857143,
              "alt": 1883.0610000000001,
              "speed2d": 1.3675714285714284,
              "speed3d": 1.4571428571428573,
              "msFromStart": 95784.58971428574
            },
            {
              "lat": 43.76317131249999,
              "lng": -116.1033836125,
              "alt": 1880.5868750000002,
              "speed2d": 0.9999999999999999,
              "speed3d": 1.1837499999999999,
              "msFromStart": 96235.8567777778
            },
            {
              "lat": 43.76316385,
              "lng": -116.1033832,
              "alt": 1878.2435,
              "speed2d": 1.2374999999999998,
              "speed3d": 1.385,
              "msFromStart": 96814.8351111111
            },
            {
              "lat": 43.763157774999996,
              "lng": -116.10337905,
              "alt": 1876.5252500000001,
              "speed2d": 1.4172500000000001,
              "speed3d": 1.595,
              "msFromStart": 97173.2291871345
            },
            {
              "lat": 43.76314921666666,
              "lng": -116.10337258333334,
              "alt": 1873.652166666667,
              "speed2d": 1.1548333333333334,
              "speed3d": 1.4849999999999999,
              "msFromStart": 97778.1248421052
            },
            {
              "lat": 43.76314638646068,
              "lng": -116.10337145362472,
              "alt": 1872.5145467612058,
              "speed2d": 1.0975045681587485,
              "speed3d": 1.519779165323783,
              "msFromStart": 98000
            },
            {
              "lat": 43.76313880423054,
              "lng": -116.10337236349233,
              "alt": 1872.20952447439,
              "speed2d": 1.1464641113550293,
              "speed3d": 1.5241118682615071,
              "msFromStart": 98500
            },
            {
              "lat": 43.7631272,
              "lng": -116.10337469999999,
              "alt": 1871.95125,
              "speed2d": 1.17075,
              "speed3d": 1.5175,
              "msFromStart": 99289.43566666667
            },
            {
              "lat": 43.763121999999996,
              "lng": -116.10337799999999,
              "alt": 1872.324,
              "speed2d": 1.2530000000000001,
              "speed3d": 1.52,
              "msFromStart": 99674.28799999997
            },
            {
              "lat": 43.76311604,
              "lng": -116.10338274,
              "alt": 1872.4652,
              "speed2d": 1.1186,
              "speed3d": 1.3679999999999999,
              "msFromStart": 100290.04151111108
            },
            {
              "lat": 43.7631104,
              "lng": -116.10338780000001,
              "alt": 1872.5478000000003,
              "speed2d": 1.3054000000000001,
              "speed3d": 1.48,
              "msFromStart": 100773.78577777777
            },
            {
              "lat": 43.76310645,
              "lng": -116.10339255,
              "alt": 1872.158,
              "speed2d": 1.361,
              "speed3d": 1.455,
              "msFromStart": 101213.65422222222
            },
            {
              "lat": 43.763100475,
              "lng": -116.1033992,
              "alt": 1871.509,
              "speed2d": 1.65375,
              "speed3d": 1.775,
              "msFromStart": 101723.03400000001
            },
            {
              "lat": 43.7630964955242,
              "lng": -116.10340093756048,
              "alt": 1871.5183107657967,
              "speed2d": 1.4513184678529072,
              "speed3d": 1.6225125001154403,
              "msFromStart": 102000
            },
            {
              "lat": 43.76309071457603,
              "lng": -116.10340047084793,
              "alt": 1872.696507951001,
              "speed2d": 0.23181359588878592,
              "speed3d": 0.6253533508945119,
              "msFromStart": 102500
            },
            {
              "lat": 43.76308266666666,
              "lng": -116.10341626666667,
              "alt": 1872.6593333333335,
              "speed2d": 1.3413333333333333,
              "speed3d": 1.44,
              "msFromStart": 103195.12281481476
            },
            {
              "lat": 43.763076675,
              "lng": -116.1034227,
              "alt": 1873.0629999999999,
              "speed2d": 1.2452500000000002,
              "speed3d": 1.2774999999999999,
              "msFromStart": 103717.61666666665
            },
            {
              "lat": 43.763066433333336,
              "lng": -116.10341955,
              "alt": 1871.3841666666667,
              "speed2d": 1.3428333333333335,
              "speed3d": 1.425,
              "msFromStart": 104258.42755555557
            },
            {
              "lat": 43.76305263333333,
              "lng": -116.10341216666666,
              "alt": 1868.9196666666667,
              "speed2d": 2.2793333333333337,
              "speed3d": 2.1466666666666665,
              "msFromStart": 104790.01066666664
            },
            {
              "lat": 43.76304639114199,
              "lng": -116.10340998790467,
              "alt": 1867.9984984084022,
              "speed2d": 2.537111759240537,
              "speed3d": 2.42880266612804,
              "msFromStart": 105000
            },
            {
              "lat": 43.7630325,
              "lng": -116.103408,
              "alt": 1865.4705,
              "speed2d": 1.5685,
              "speed3d": 1.72,
              "msFromStart": 105725.22000000002
            },
            {
              "lat": 43.763025033333335,
              "lng": -116.10340483333334,
              "alt": 1864.427333333333,
              "speed2d": 1.7730000000000001,
              "speed3d": 1.8666666666666667,
              "msFromStart": 106073.75318518521
            },
            {
              "lat": 43.7630133,
              "lng": -116.1034009,
              "alt": 1862.2416666666668,
              "speed2d": 1.293,
              "speed3d": 1.4766666666666666,
              "msFromStart": 106807.61080701754
            },
            {
              "lat": 43.763009266666664,
              "lng": -116.10340016666666,
              "alt": 1859.877,
              "speed2d": 1.146,
              "speed3d": 1.5033333333333332,
              "msFromStart": 107229.5319220273
            },
            {
              "lat": 43.76300315,
              "lng": -116.10340314999999,
              "alt": 1857.3105,
              "speed2d": 1.3439999999999999,
              "speed3d": 1.7349999999999999,
              "msFromStart": 107842.27133333328
            },
            {
              "lat": 43.76300321428571,
              "lng": -116.10340534285714,
              "alt": 1855.354,
              "speed2d": 0.8987142857142856,
              "speed3d": 1.5428571428571427,
              "msFromStart": 108292.98546031743
            },
            {
              "lat": 43.76300264,
              "lng": -116.10340824,
              "alt": 1853.8543999999997,
              "speed2d": 0.9202,
              "speed3d": 1.5999999999999996,
              "msFromStart": 108704.16320000001
            },
            {
              "lat": 43.76300325,
              "lng": -116.1034104,
              "alt": 1851.5825,
              "speed2d": 0.632,
              "speed3d": 1.6349999999999998,
              "msFromStart": 109187.65
            },
            {
              "lat": 43.76300206,
              "lng": -116.1034121,
              "alt": 1848.9586,
              "speed2d": 1.0034,
              "speed3d": 1.936,
              "msFromStart": 109748.86719999994
            },
            {
              "lat": 43.76299974285715,
              "lng": -116.10341412857143,
              "alt": 1846.948857142857,
              "speed2d": 1.0951428571428572,
              "speed3d": 2.0942857142857143,
              "msFromStart": 110248.97307936501
            },
            {
              "lat": 43.76299933333333,
              "lng": -116.10341456666669,
              "alt": 1844.204,
              "speed2d": 0.654,
              "speed3d": 2.0366666666666666,
              "msFromStart": 110839.20088888882
            },
            {
              "lat": 43.76300208749999,
              "lng": -116.1034176875,
              "alt": 1841.9556249999998,
              "speed2d": 0.74375,
              "speed3d": 2.1525000000000003,
              "msFromStart": 111231.46618128651
            },
            {
              "lat": 43.76300402222223,
              "lng": -116.10342377777778,
              "alt": 1839.4477777777777,
              "speed2d": 0.9229999999999999,
              "speed3d": 2.3511111111111114,
              "msFromStart": 111773.82063157893
            },
            {
              "lat": 43.763005674999995,
              "lng": -116.1034259,
              "alt": 1838.4252500000002,
              "speed2d": 0.5355,
              "speed3d": 2.25,
              "msFromStart": 112130.65284210525
            },
            {
              "lat": 43.7630043,
              "lng": -116.1034352,
              "alt": 1836.2434285714285,
              "speed2d": 1.0004285714285714,
              "speed3d": 2.412857142857143,
              "msFromStart": 112770.1558095238
            },
            {
              "lat": 43.762999425,
              "lng": -116.10343845,
              "alt": 1834.1317499999998,
              "speed2d": 1.0259999999999998,
              "speed3d": 2.4975000000000005,
              "msFromStart": 113367.31533333333
            },
            {
              "lat": 43.76299304285715,
              "lng": -116.10343739999999,
              "alt": 1834.0628571428574,
              "speed2d": 1.2718571428571428,
              "speed3d": 2.5214285714285714,
              "msFromStart": 113809.29333333328
            },
            {
              "lat": 43.762986375000004,
              "lng": -116.10343945,
              "alt": 1834.632,
              "speed2d": 1.155,
              "speed3d": 2.4175,
              "msFromStart": 114264.80411111107
            },
            {
              "lat": 43.762984474999996,
              "lng": -116.10344414999999,
              "alt": 1834.87525,
              "speed2d": 0.5832499999999999,
              "speed3d": 2.075,
              "msFromStart": 114759.13177777776
            },
            {
              "lat": 43.762979333333334,
              "lng": -116.10344760000001,
              "alt": 1836.1253333333334,
              "speed2d": 0.6723333333333333,
              "speed3d": 1.9366666666666665,
              "msFromStart": 115184.66133333331
            },
            {
              "lat": 43.76296793333333,
              "lng": -116.10345170000002,
              "alt": 1837.7346666666667,
              "speed2d": 1.036,
              "speed3d": 1.9866666666666664,
              "msFromStart": 115899.8693333333
            },
            {
              "lat": 43.762965460000004,
              "lng": -116.10345502000001,
              "alt": 1838.1472,
              "speed2d": 0.8594,
              "speed3d": 1.842,
              "msFromStart": 116153.01372631577
            },
            {
              "lat": 43.76295765,
              "lng": -116.10346045,
              "alt": 1838.9735,
              "speed2d": 1.0190000000000001,
              "speed3d": 1.7349999999999999,
              "msFromStart": 116917.65094736846
            },
            {
              "lat": 43.76295266666667,
              "lng": -116.10346311666667,
              "alt": 1839.1385,
              "speed2d": 1.1580000000000001,
              "speed3d": 1.8633333333333333,
              "msFromStart": 117275.25113450293
            },
            {
              "lat": 43.76294355,
              "lng": -116.10346874999999,
              "alt": 1840.6995,
              "speed2d": 1.241,
              "speed3d": 1.7850000000000001,
              "msFromStart": 117825.69866666662
            },
            {
              "lat": 43.762931916666666,
              "lng": -116.10347438333334,
              "alt": 1842.8383333333331,
              "speed2d": 1.7448333333333332,
              "speed3d": 2.0566666666666666,
              "msFromStart": 118339.15970370371
            },
            {
              "lat": 43.76292492500001,
              "lng": -116.1034762,
              "alt": 1844.0445,
              "speed2d": 1.546,
              "speed3d": 1.8575,
              "msFromStart": 118691.1415555556
            },
            {
              "lat": 43.76291266,
              "lng": -116.10347954,
              "alt": 1845.7151999999999,
              "speed2d": 1.7468,
              "speed3d": 1.8940000000000001,
              "msFromStart": 119297.58524444446
            },
            {
              "lat": 43.762904633333335,
              "lng": -116.10348063333333,
              "alt": 1846.126,
              "speed2d": 1.751,
              "speed3d": 1.9750000000000003,
              "msFromStart": 119712.51762962964
            },
            {
              "lat": 43.76289716666667,
              "lng": -116.10348283333335,
              "alt": 1847.0333333333333,
              "speed2d": 1.5766666666666667,
              "speed3d": 1.8399999999999999,
              "msFromStart": 120116.63970370372
            },
            {
              "lat": 43.762892535962,
              "lng": -116.10348571566894,
              "alt": 1848.0118461083716,
              "speed2d": 1.049955820647625,
              "speed3d": 1.2435962005887498,
              "msFromStart": 120500
            },
            {
              "lat": 43.76289162743054,
              "lng": -116.10349374103018,
              "alt": 1849.0347011116385,
              "speed2d": 0.950017359928727,
              "speed3d": 1.152743054480661,
              "msFromStart": 121000
            },
            {
              "lat": 43.7628899,
              "lng": -116.10350455,
              "alt": 1850.4725,
              "speed2d": 0.8875,
              "speed3d": 1.05,
              "msFromStart": 121675.5355789474
            },
            {
              "lat": 43.762885138684524,
              "lng": -116.10350683373055,
              "alt": 1851.2788352453529,
              "speed2d": 1.2154640663151657,
              "speed3d": 1.287298744232097,
              "msFromStart": 122000
            },
            {
              "lat": 43.762874922222224,
              "lng": -116.10351286666665,
              "alt": 1853.4782222222223,
              "speed2d": 1.3669999999999998,
              "speed3d": 1.4688888888888891,
              "msFromStart": 122763.43338271607
            },
            {
              "lat": 43.76286992857143,
              "lng": -116.10351825714285,
              "alt": 1854.7802857142856,
              "speed2d": 1.3332857142857144,
              "speed3d": 1.2971428571428572,
              "msFromStart": 123223.02869841273
            },
            {
              "lat": 43.76285901666667,
              "lng": -116.10352390000001,
              "alt": 1856.5098333333335,
              "speed2d": 1.5418333333333334,
              "speed3d": 1.53,
              "msFromStart": 123793.55933333328
            },
            {
              "lat": 43.76285145,
              "lng": -116.10352915,
              "alt": 1857.9955,
              "speed2d": 1.4485000000000001,
              "speed3d": 1.56,
              "msFromStart": 124206.88933333328
            },
            {
              "lat": 43.762846613992046,
              "lng": -116.10353184172948,
              "alt": 1858.8960835959986,
              "speed2d": 1.4782069343566062,
              "speed3d": 1.560169932849126,
              "msFromStart": 124500
            },
            {
              "lat": 43.76283132857142,
              "lng": -116.1035361142857,
              "alt": 1860.8934285714286,
              "speed2d": 1.9507142857142856,
              "speed3d": 1.957142857142857,
              "msFromStart": 125261.89098412695
            },
            {
              "lat": 43.76282322,
              "lng": -116.10354224,
              "alt": 1862.9674,
              "speed2d": 1.4687999999999999,
              "speed3d": 1.54,
              "msFromStart": 125758.91591111118
            },
            {
              "lat": 43.76281684999999,
              "lng": -116.10355185,
              "alt": 1865.2683333333332,
              "speed2d": 1.6870000000000003,
              "speed3d": 1.6016666666666666,
              "msFromStart": 126159.25674074079
            },
            {
              "lat": 43.7628095678244,
              "lng": -116.10355663468532,
              "alt": 1866.4599421911423,
              "speed2d": 1.975587490287488,
              "speed3d": 1.947755244755243,
              "msFromStart": 126500
            },
            {
              "lat": 43.76279525714286,
              "lng": -116.10356198571428,
              "alt": 1868.2648571428572,
              "speed2d": 1.8617142857142859,
              "speed3d": 1.9342857142857144,
              "msFromStart": 127284.9973333333
            },
            {
              "lat": 43.76278748,
              "lng": -116.10356546,
              "alt": 1869.4266000000002,
              "speed2d": 1.7164,
              "speed3d": 1.7239999999999998,
              "msFromStart": 127758.73404444446
            },
            {
              "lat": 43.76277733333333,
              "lng": -116.10356546666665,
              "alt": 1870.1713333333332,
              "speed2d": 2.136,
              "speed3d": 2.2466666666666666,
              "msFromStart": 128166.51022222225
            },
            {
              "lat": 43.762771433333334,
              "lng": -116.10356503333333,
              "alt": 1870.933,
              "speed2d": 1.5436666666666667,
              "speed3d": 1.6099999999999999,
              "msFromStart": 128624.34118518519
            },
            {
              "lat": 43.762765599999994,
              "lng": -116.10357385,
              "alt": 1873.0535,
              "speed2d": 1.0190000000000001,
              "speed3d": 1.12,
              "msFromStart": 129374.65599999999
            },
            {
              "lat": 43.76276622857143,
              "lng": -116.10357834285716,
              "alt": 1874.1454285714285,
              "speed2d": 1.0194285714285714,
              "speed3d": 1.1700000000000002,
              "msFromStart": 129720.24457142854
            },
            {
              "lat": 43.76275818002063,
              "lng": -116.10358258225908,
              "alt": 1874.426156691581,
              "speed2d": 1.6731363708402087,
              "speed3d": 1.6581512938479015,
              "msFromStart": 130000
            },
            {
              "lat": 43.7627467,
              "lng": -116.10358545,
              "alt": 1875.4245,
              "speed2d": 1.3984999999999999,
              "speed3d": 1.47,
              "msFromStart": 130830.62733333334
            },
            {
              "lat": 43.762743316666665,
              "lng": -116.10358765,
              "alt": 1876.3661666666667,
              "speed2d": 1.1711666666666667,
              "speed3d": 1.2933333333333332,
              "msFromStart": 131169.15283430798
            },
            {
              "lat": 43.76273896666667,
              "lng": -116.10359565,
              "alt": 1878.3816666666667,
              "speed2d": 0.978,
              "speed3d": 1.165,
              "msFromStart": 131793.17347368415
            },
            {
              "lat": 43.762735666666664,
              "lng": -116.10359913333332,
              "alt": 1879.1046666666666,
              "speed2d": 1.0296666666666667,
              "speed3d": 1.2366666666666666,
              "msFromStart": 132178.82804678357
            },
            {
              "lat": 43.76273436666667,
              "lng": -116.10360361666666,
              "alt": 1880.0831666666668,
              "speed2d": 0.8043333333333333,
              "speed3d": 1.0783333333333334,
              "msFromStart": 132739.04792592584
            },
            {
              "lat": 43.76273706666666,
              "lng": -116.10361436666665,
              "alt": 1882.7703333333336,
              "speed2d": 0.9257777777777777,
              "speed3d": 1.3444444444444446,
              "msFromStart": 133225.6837037036
            },
            {
              "lat": 43.76273402000001,
              "lng": -116.10362144999999,
              "alt": 1883.8419000000001,
              "speed2d": 0.9912000000000001,
              "speed3d": 1.4599999999999997,
              "msFromStart": 133747.34511111115
            },
            {
              "lat": 43.76272935714286,
              "lng": -116.10362925714287,
              "alt": 1884.4455714285712,
              "speed2d": 1.2651428571428571,
              "speed3d": 1.62,
              "msFromStart": 134307.95244444447
            },
            {
              "lat": 43.76272137142857,
              "lng": -116.1036335,
              "alt": 1884.9512857142859,
              "speed2d": 1.6740000000000002,
              "speed3d": 1.9671428571428573,
              "msFromStart": 134810.51428571422
            },
            {
              "lat": 43.762718500000005,
              "lng": -116.10364125000001,
              "alt": 1885.6960000000001,
              "speed2d": 1.4798333333333333,
              "speed3d": 1.7866666666666666,
              "msFromStart": 135285.60888888888
            },
            {
              "lat": 43.76271886666667,
              "lng": -116.1036473,
              "alt": 1886.4676666666667,
              "speed2d": 1.1573333333333333,
              "speed3d": 1.57,
              "msFromStart": 135734.157037037
            },
            {
              "lat": 43.76271535,
              "lng": -116.103647725,
              "alt": 1886.3892500000002,
              "speed2d": 1.1725,
              "speed3d": 1.4324999999999999,
              "msFromStart": 136219.4832046783
            },
            {
              "lat": 43.762706449999996,
              "lng": -116.10364575,
              "alt": 1886.7845,
              "speed2d": 1.2805,
              "speed3d": 1.5550000000000002,
              "msFromStart": 136881.28084210528
            },
            {
              "lat": 43.76270256000001,
              "lng": -116.10364734000001,
              "alt": 1886.8188,
              "speed2d": 1.3941999999999999,
              "speed3d": 1.5419999999999998,
              "msFromStart": 137206.74743391812
            },
            {
              "lat": 43.7626959,
              "lng": -116.10365208571429,
              "alt": 1887.366,
              "speed2d": 1.377857142857143,
              "speed3d": 1.5385714285714285,
              "msFromStart": 137759.37968253964
            },
            {
              "lat": 43.76268828333333,
              "lng": -116.10365,
              "alt": 1886.6788333333334,
              "speed2d": 1.6633333333333336,
              "speed3d": 1.73,
              "msFromStart": 138231.42844444443
            },
            {
              "lat": 43.76267835714285,
              "lng": -116.10364809999999,
              "alt": 1885.132,
              "speed2d": 2.114,
              "speed3d": 2.147142857142857,
              "msFromStart": 138671.18488888888
            },
            {
              "lat": 43.7626765,
              "lng": -116.10365318571428,
              "alt": 1886.031,
              "speed2d": 0.845,
              "speed3d": 0.9328571428571428,
              "msFromStart": 139299.0438095238
            },
            {
              "lat": 43.762671000000005,
              "lng": -116.10365601428573,
              "alt": 1886.2507142857141,
              "speed2d": 1.1565714285714286,
              "speed3d": 1.137142857142857,
              "msFromStart": 139809.05447619062
            },
            {
              "lat": 43.76266445,
              "lng": -116.10365425,
              "alt": 1885.386,
              "speed2d": 1.5905,
              "speed3d": 1.525,
              "msFromStart": 140099.3600000002
            },
            {
              "lat": 43.762655712072906,
              "lng": -116.10365019797996,
              "alt": 1883.8729976336072,
              "speed2d": 1.8330057600155116,
              "speed3d": 1.9149254317322781,
              "msFromStart": 140500
            },
            {
              "lat": 43.762646966666665,
              "lng": -116.10365685000001,
              "alt": 1885.3461666666665,
              "speed2d": 1.4531666666666667,
              "speed3d": 1.4466666666666665,
              "msFromStart": 141274.12607407413
            },
            {
              "lat": 43.76263278333333,
              "lng": -116.10366065000001,
              "alt": 1886.3871666666666,
              "speed2d": 2.3108333333333335,
              "speed3d": 2.2,
              "msFromStart": 141806.4586666668
            },
            {
              "lat": 43.762622333333326,
              "lng": -116.10366555,
              "alt": 1887.7728333333332,
              "speed2d": 2.232666666666667,
              "speed3d": 2.3200000000000003,
              "msFromStart": 142228.4324210527
            },
            {
              "lat": 43.76261678750001,
              "lng": -116.10367352499999,
              "alt": 1889.7538749999999,
              "speed2d": 1.40075,
              "speed3d": 1.4787499999999998,
              "msFromStart": 142720.43368421053
            },
            {
              "lat": 43.762609360000006,
              "lng": -116.10368046000002,
              "alt": 1891.838,
              "speed2d": 1.21,
              "speed3d": 1.222,
              "msFromStart": 143257.12345263152
            },
            {
              "lat": 43.76260238178162,
              "lng": -116.10368370266009,
              "alt": 1892.8244767387832,
              "speed2d": 1.764459677653478,
              "speed3d": 1.8107370408014491,
              "msFromStart": 143500
            },
            {
              "lat": 43.7625968,
              "lng": -116.10369395000001,
              "alt": 1894.967,
              "speed2d": 0.8275,
              "speed3d": 1.005,
              "msFromStart": 144171.47199999983
            },
            {
              "lat": 43.762586133333336,
              "lng": -116.10369914999998,
              "alt": 1896.1696666666667,
              "speed2d": 1.5311666666666666,
              "speed3d": 1.5416666666666665,
              "msFromStart": 144775.7599999999
            },
            {
              "lat": 43.76257661666667,
              "lng": -116.10370396666667,
              "alt": 1897.5474999999997,
              "speed2d": 1.4891666666666665,
              "speed3d": 1.595,
              "msFromStart": 145352.64237037033
            },
            {
              "lat": 43.762571050000005,
              "lng": -116.1037071,
              "alt": 1898.2965,
              "speed2d": 1.5885,
              "speed3d": 1.605,
              "msFromStart": 145711.15022222218
            },
            {
              "lat": 43.762560900000004,
              "lng": -116.10370951666667,
              "alt": 1899.1178333333332,
              "speed2d": 1.813,
              "speed3d": 1.8033333333333332,
              "msFromStart": 146234.94177777774
            },
            {
              "lat": 43.76255285,
              "lng": -116.1037118,
              "alt": 1899.83,
              "speed2d": 2.039,
              "speed3d": 2.12,
              "msFromStart": 146619.72488888892
            },
            {
              "lat": 43.7625426,
              "lng": -116.1037144,
              "alt": 1901.2599999999998,
              "speed2d": 1.6126666666666665,
              "speed3d": 1.6833333333333331,
              "msFromStart": 147361.5292631579
            },
            {
              "lat": 43.762539966666665,
              "lng": -116.10371873333334,
              "alt": 1902.2273333333333,
              "speed2d": 1.256,
              "speed3d": 1.3566666666666667,
              "msFromStart": 147637.19242105263
            },
            {
              "lat": 43.76253730650608,
              "lng": -116.10372114211573,
              "alt": 1902.6104949097778,
              "speed2d": 1.1651499874463414,
              "speed3d": 1.291152222264618,
              "msFromStart": 148000
            },
            {
              "lat": 43.76253526833621,
              "lng": -116.10373083466902,
              "alt": 1905.4675248167275,
              "speed2d": 1.1124067936497788,
              "speed3d": 0.05633275613737054,
              "msFromStart": 148500
            },
            {
              "lat": 43.762526699999995,
              "lng": -116.10372618749999,
              "alt": 1902.259,
              "speed2d": 1.1717499999999998,
              "speed3d": 1.1675,
              "msFromStart": 149246.31000000003
            },
            {
              "lat": 43.7625188,
              "lng": -116.103723,
              "alt": 1900.7735,
              "speed2d": 1.3886666666666667,
              "speed3d": 1.3983333333333332,
              "msFromStart": 149681.43481481486
            },
            {
              "lat": 43.76251438333333,
              "lng": -116.10372008333333,
              "alt": 1898.8261666666667,
              "speed2d": 0.7946666666666666,
              "speed3d": 0.8350000000000001,
              "msFromStart": 150212.9546666667
            },
            {
              "lat": 43.76250822,
              "lng": -116.10371905999999,
              "alt": 1896.6622,
              "speed2d": 1.0972,
              "speed3d": 1.052,
              "msFromStart": 150740.01279999997
            },
            {
              "lat": 43.76249862857143,
              "lng": -116.10371844285714,
              "alt": 1894.892,
              "speed2d": 1.3702857142857143,
              "speed3d": 1.425714285714286,
              "msFromStart": 151292.55199999997
            },
            {
              "lat": 43.7624911375,
              "lng": -116.103715675,
              "alt": 1893.3603750000002,
              "speed2d": 1.2327499999999998,
              "speed3d": 1.30875,
              "msFromStart": 151783.90766666664
            },
            {
              "lat": 43.762486114285714,
              "lng": -116.1037159285714,
              "alt": 1892.4588571428567,
              "speed2d": 1.0228571428571431,
              "speed3d": 1.112857142857143,
              "msFromStart": 152202.55557560566
            },
            {
              "lat": 43.76247975,
              "lng": -116.10371760000001,
              "alt": 1891.35425,
              "speed2d": 1.08925,
              "speed3d": 1.0975000000000001,
              "msFromStart": 152738.45663157903
            },
            {
              "lat": 43.76247164,
              "lng": -116.10371602,
              "alt": 1889.9943999999998,
              "speed2d": 1.1612,
              "speed3d": 1.194,
              "msFromStart": 153279.97737543867
            },
            {
              "lat": 43.762467099999995,
              "lng": -116.10371965,
              "alt": 1890.3325,
              "speed2d": 1.0135,
              "speed3d": 1.17,
              "msFromStart": 153769.45955555563
            },
            {
              "lat": 43.762461733333325,
              "lng": -116.10372111666666,
              "alt": 1890.5328333333334,
              "speed2d": 1.3751666666666666,
              "speed3d": 1.393333333333333,
              "msFromStart": 154172.81066666674
            },
            {
              "lat": 43.762450799999996,
              "lng": -116.10372103333333,
              "alt": 1890.0113333333334,
              "speed2d": 1.5050000000000001,
              "speed3d": 1.6400000000000001,
              "msFromStart": 154787.58400000003
            },
            {
              "lat": 43.76244697777777,
              "lng": -116.10372551111112,
              "alt": 1890.8517777777777,
              "speed2d": 1.1056666666666668,
              "speed3d": 1.238888888888889,
              "msFromStart": 155228.1671111111
            },
            {
              "lat": 43.76244326666667,
              "lng": -116.10372886666667,
              "alt": 1891.5103333333332,
              "speed2d": 0.9846666666666666,
              "speed3d": 1.0033333333333334,
              "msFromStart": 155705.8002962962
            },
            {
              "lat": 43.762442403378635,
              "lng": -116.10373546803883,
              "alt": 1893.0595953398056,
              "speed2d": 0.865932427184492,
              "speed3d": 0.8365980582524654,
              "msFromStart": 156000
            },
            {
              "lat": 43.762436508304205,
              "lng": -116.10373432309386,
              "alt": 1891.9715322330096,
              "speed2d": 0.43727650485428526,
              "speed3d": 1.161183171521022,
              "msFromStart": 156500
            },
            {
              "lat": 43.76242363333333,
              "lng": -116.10372283333334,
              "alt": 1888.536,
              "speed2d": 1.508,
              "speed3d": 1.5066666666666668,
              "msFromStart": 157209.25866666684
            },
            {
              "lat": 43.76241716666667,
              "lng": -116.10372073333335,
              "alt": 1887.9186666666665,
              "speed2d": 0.5993333333333334,
              "speed3d": 0.8466666666666667,
              "msFromStart": 157850.18049122815
            },
            {
              "lat": 43.76240716666666,
              "lng": -116.10372793333332,
              "alt": 1888.7916666666667,
              "speed2d": 1.3833333333333335,
              "speed3d": 1.4266666666666667,
              "msFromStart": 158417.94311111112
            },
            {
              "lat": 43.76240307142857,
              "lng": -116.10372904285714,
              "alt": 1888.2382857142854,
              "speed2d": 1.187142857142857,
              "speed3d": 1.29,
              "msFromStart": 158724.2577777778
            },
            {
              "lat": 43.76239435,
              "lng": -116.10373022499999,
              "alt": 1887.48975,
              "speed2d": 1.47525,
              "speed3d": 1.515,
              "msFromStart": 159173.97422222228
            },
            {
              "lat": 43.762389527235776,
              "lng": -116.1037291057201,
              "alt": 1885.7668028455284,
              "speed2d": 1.0581440185830542,
              "speed3d": 1.0007549361208063,
              "msFromStart": 159500
            },
            {
              "lat": 43.7623749,
              "lng": -116.10373055,
              "alt": 1886.5715,
              "speed2d": 1.5415,
              "speed3d": 1.59,
              "msFromStart": 160289.4186666666
            },
            {
              "lat": 43.762368676797145,
              "lng": -116.10372636803804,
              "alt": 1885.5545549610488,
              "speed2d": 1.9254687006182125,
              "speed3d": 1.8803605389370865,
              "msFromStart": 160500
            },
            {
              "lat": 43.76235508,
              "lng": -116.10372036000001,
              "alt": 1882.2488,
              "speed2d": 1.3654000000000002,
              "speed3d": 1.516,
              "msFromStart": 161333.74399999998
            },
            {
              "lat": 43.76234733333333,
              "lng": -116.10371653333334,
              "alt": 1880.4806666666668,
              "speed2d": 1.563,
              "speed3d": 1.6600000000000001,
              "msFromStart": 161736.31585185174
            },
            {
              "lat": 43.76233285,
              "lng": -116.10371135,
              "alt": 1877.076,
              "speed2d": 1.2959999999999998,
              "speed3d": 1.775,
              "msFromStart": 162459.5056842105
            },
            {
              "lat": 43.76232746666667,
              "lng": -116.10370966666667,
              "alt": 1875.9009999999998,
              "speed2d": 1.3913333333333333,
              "speed3d": 1.7133333333333334,
              "msFromStart": 162743.73978947365
            },
            {
              "lat": 43.7623146125,
              "lng": -116.1037059375,
              "alt": 1873.8449999999998,
              "speed2d": 1.87875,
              "speed3d": 2.1849999999999996,
              "msFromStart": 163257.21715789475
            },
            {
              "lat": 43.762301199999996,
              "lng": -116.10370217142858,
              "alt": 1872.1687142857143,
              "speed2d": 2.328,
              "speed3d": 2.5642857142857145,
              "msFromStart": 163728.9596190477
            },
            {
              "lat": 43.762284314285715,
              "lng": -116.10370688571427,
              "alt": 1872.0872857142858,
              "speed2d": 2.524,
              "speed3d": 2.8057142857142856,
              "msFromStart": 164295.1043809524
            },
            {
              "lat": 43.76227474444445,
              "lng": -116.10371024444443,
              "alt": 1873.1504444444445,
              "speed2d": 2.5006666666666666,
              "speed3d": 2.6911111111111112,
              "msFromStart": 164743.239111111
            },
            {
              "lat": 43.76226868571429,
              "lng": -116.10371552857143,
              "alt": 1874.218857142857,
              "speed2d": 1.744,
              "speed3d": 2.052857142857143,
              "msFromStart": 165238.46806349195
            },
            {
              "lat": 43.76226639999999,
              "lng": -116.10372691111111,
              "alt": 1875.9915555555556,
              "speed2d": 1.1526666666666667,
              "speed3d": 1.38,
              "msFromStart": 165733.20533333326
            },
            {
              "lat": 43.762262262499995,
              "lng": -116.1037325625,
              "alt": 1876.9837499999999,
              "speed2d": 1.157,
              "speed3d": 1.3149999999999997,
              "msFromStart": 166213.38311111103
            },
            {
              "lat": 43.762255675000006,
              "lng": -116.10374115,
              "alt": 1878.5675,
              "speed2d": 1.3105,
              "speed3d": 1.365,
              "msFromStart": 166794.55288888884
            },
            {
              "lat": 43.762250375,
              "lng": -116.10374652499999,
              "alt": 1879.4802499999998,
              "speed2d": 1.5085,
              "speed3d": 1.5750000000000002,
              "msFromStart": 167144.73244444438
            },
            {
              "lat": 43.76224295,
              "lng": -116.10374965,
              "alt": 1880.7907500000001,
              "speed2d": 1.47,
              "speed3d": 1.4525000000000001,
              "msFromStart": 167860.0829473685
            },
            {
              "lat": 43.7622384,
              "lng": -116.10374971666667,
              "alt": 1881.0433333333335,
              "speed2d": 1.4736666666666667,
              "speed3d": 1.538333333333333,
              "msFromStart": 168274.02959649128
            },
            {
              "lat": 43.76223256666666,
              "lng": -116.10375333333333,
              "alt": 1881.4709999999998,
              "speed2d": 1.3805,
              "speed3d": 1.45,
              "msFromStart": 168783.84600000002
            },
            {
              "lat": 43.76222636666667,
              "lng": -116.10375916666666,
              "alt": 1882.3016666666667,
              "speed2d": 1.4020000000000001,
              "speed3d": 1.4066666666666667,
              "msFromStart": 169345.17950877192
            },
            {
              "lat": 43.76222396419978,
              "lng": -116.10376053747079,
              "alt": 1882.676792521423,
              "speed2d": 1.4995608067168313,
              "speed3d": 1.4466467584177125,
              "msFromStart": 169500
            },
            {
              "lat": 43.76221802,
              "lng": -116.10376858000001,
              "alt": 1884.8087999999998,
              "speed2d": 0.7574000000000001,
              "speed3d": 0.85,
              "msFromStart": 170289.47078362567
            },
            {
              "lat": 43.76221128,
              "lng": -116.10377621999999,
              "alt": 1886.1447999999998,
              "speed2d": 1.4929999999999999,
              "speed3d": 1.408,
              "msFromStart": 170739.09688888898
            },
            {
              "lat": 43.762203542857144,
              "lng": -116.1037809,
              "alt": 1887.0067142857142,
              "speed2d": 1.6460000000000001,
              "speed3d": 1.7157142857142855,
              "msFromStart": 171232.48584126993
            },
            {
              "lat": 43.76219845,
              "lng": -116.1037883,
              "alt": 1888.6205,
              "speed2d": 1.2445,
              "speed3d": 1.3050000000000002,
              "msFromStart": 171838.0568888889
            },
            {
              "lat": 43.762191975,
              "lng": -116.103794625,
              "alt": 1890.52625,
              "speed2d": 1.44475,
              "speed3d": 1.2525,
              "msFromStart": 172388.97263157897
            },
            {
              "lat": 43.7621861375,
              "lng": -116.10379855000001,
              "alt": 1890.97325,
              "speed2d": 1.8338750000000001,
              "speed3d": 1.8875,
              "msFromStart": 172746.72168421058
            },
            {
              "lat": 43.762184025,
              "lng": -116.10380157499999,
              "alt": 1891.983125,
              "speed2d": 1.017375,
              "speed3d": 1.0274999999999999,
              "msFromStart": 173283.25119298257
            },
            {
              "lat": 43.76217735,
              "lng": -116.10380683333332,
              "alt": 1892.9545,
              "speed2d": 1.4531666666666665,
              "speed3d": 1.46,
              "msFromStart": 173754.7749629629
            },
            {
              "lat": 43.7621746,
              "lng": -116.10381625,
              "alt": 1894.685,
              "speed2d": 1.0635,
              "speed3d": 1.025,
              "msFromStart": 174285.5995555555
            },
            {
              "lat": 43.762165125,
              "lng": -116.103820575,
              "alt": 1895.5185000000001,
              "speed2d": 1.8624999999999998,
              "speed3d": 1.8225,
              "msFromStart": 174726.84266666658
            },
            {
              "lat": 43.762161175,
              "lng": -116.10382492499998,
              "alt": 1896.5423749999998,
              "speed2d": 1.195125,
              "speed3d": 1.3012499999999998,
              "msFromStart": 175278.33355555547
            },
            {
              "lat": 43.76215669999999,
              "lng": -116.10382895,
              "alt": 1897.4975,
              "speed2d": 1.29575,
              "speed3d": 1.2825,
              "msFromStart": 175772.55600000004
            },
            {
              "lat": 43.7621475,
              "lng": -116.10383595,
              "alt": 1899.22375,
              "speed2d": 1.8072499999999998,
              "speed3d": 1.9149999999999998,
              "msFromStart": 176266.6133333333
            },
            {
              "lat": 43.7621445875,
              "lng": -116.1038397125,
              "alt": 1900.1891249999999,
              "speed2d": 1.2261250000000001,
              "speed3d": 1.355,
              "msFromStart": 176721.26111111103
            },
            {
              "lat": 43.762138719999996,
              "lng": -116.10384391999999,
              "alt": 1901.1738,
              "speed2d": 1.4544,
              "speed3d": 1.548,
              "msFromStart": 177208.28473450287
            },
            {
              "lat": 43.762140551647356,
              "lng": -116.10384880706175,
              "alt": 1901.8469435521724,
              "speed2d": 0.6045286189820133,
              "speed3d": 0.9227639721183294,
              "msFromStart": 177500
            },
            {
              "lat": 43.76213465000001,
              "lng": -116.10386027499999,
              "alt": 1902.61475,
              "speed2d": 1.363,
              "speed3d": 1.3825,
              "msFromStart": 178243.81419883049
            },
            {
              "lat": 43.7621297,
              "lng": -116.1038669,
              "alt": 1902.8131666666666,
              "speed2d": 1.6681666666666668,
              "speed3d": 1.775,
              "msFromStart": 178712.8408888889
            },
            {
              "lat": 43.762129699999996,
              "lng": -116.1038725857143,
              "alt": 1902.958714285714,
              "speed2d": 1.041,
              "speed3d": 1.1342857142857143,
              "msFromStart": 179264.43125396827
            },
            {
              "lat": 43.762124783333334,
              "lng": -116.10388013333333,
              "alt": 1903.9464999999998,
              "speed2d": 1.4376666666666666,
              "speed3d": 1.4233333333333333,
              "msFromStart": 179782.6736666668
            },
            {
              "lat": 43.7621163,
              "lng": -116.1038856,
              "alt": 1904.8029999999999,
              "speed2d": 1.635,
              "speed3d": 1.6600000000000001,
              "msFromStart": 180393.71199999997
            },
            {
              "lat": 43.762111925,
              "lng": -116.10388809999999,
              "alt": 1905.076,
              "speed2d": 1.608,
              "speed3d": 1.70625,
              "msFromStart": 180744.79599999986
            },
            {
              "lat": 43.7621046,
              "lng": -116.10389404,
              "alt": 1906.0954000000002,
              "speed2d": 1.5962,
              "speed3d": 1.686,
              "msFromStart": 181302.21546666656
            },
            {
              "lat": 43.762099649999996,
              "lng": -116.10389568333335,
              "alt": 1906.728,
              "speed2d": 1.5921666666666667,
              "speed3d": 1.6633333333333333,
              "msFromStart": 181668.5214814814
            },
            {
              "lat": 43.7620914,
              "lng": -116.10390029999999,
              "alt": 1907.433,
              "speed2d": 1.666,
              "speed3d": 1.755,
              "msFromStart": 182236.1837660818
            },
            {
              "lat": 43.762084725,
              "lng": -116.1039046,
              "alt": 1907.74775,
              "speed2d": 1.4905,
              "speed3d": 1.5699999999999998,
              "msFromStart": 182828.0770526316
            },
            {
              "lat": 43.7620787,
              "lng": -116.1039065,
              "alt": 1907.8229999999999,
              "speed2d": 1.4716666666666667,
              "speed3d": 1.5833333333333335,
              "msFromStart": 183337.5788226121
            },
            {
              "lat": 43.76207391250001,
              "lng": -116.10391025,
              "alt": 1907.8988749999999,
              "speed2d": 1.4974999999999998,
              "speed3d": 1.5962500000000002,
              "msFromStart": 183749.7333333334
            },
            {
              "lat": 43.76207104,
              "lng": -116.10391601999999,
              "alt": 1908.1509999999998,
              "speed2d": 1.2272,
              "speed3d": 1.26,
              "msFromStart": 184271.71395555564
            },
            {
              "lat": 43.76206615,
              "lng": -116.10392035000001,
              "alt": 1908.4429999999998,
              "speed2d": 1.476,
              "speed3d": 1.5325,
              "msFromStart": 184711.73244444444
            },
            {
              "lat": 43.76206134,
              "lng": -116.10392392,
              "alt": 1908.7887999999998,
              "speed2d": 1.2366,
              "speed3d": 1.324,
              "msFromStart": 185272.77866666665
            },
            {
              "lat": 43.762055525,
              "lng": -116.103929575,
              "alt": 1909.4085,
              "speed2d": 1.284,
              "speed3d": 1.2775,
              "msFromStart": 185852.03400000004
            },
            {
              "lat": 43.7620513,
              "lng": -116.10393268,
              "alt": 1909.6997999999999,
              "speed2d": 1.3448,
              "speed3d": 1.4,
              "msFromStart": 186283.02933333337
            },
            {
              "lat": 43.76204683333333,
              "lng": -116.1039358,
              "alt": 1910.2279999999998,
              "speed2d": 1.3126666666666666,
              "speed3d": 1.3,
              "msFromStart": 186708.3057777778
            },
            {
              "lat": 43.76204106666666,
              "lng": -116.10393988333334,
              "alt": 1910.7538333333332,
              "speed2d": 1.3159999999999998,
              "speed3d": 1.3599999999999999,
              "msFromStart": 187221.8319766082
            },
            {
              "lat": 43.762036949999995,
              "lng": -116.10394240000001,
              "alt": 1910.91175,
              "speed2d": 1.2142499999999998,
              "speed3d": 1.3,
              "msFromStart": 187680.26189473676
            },
            {
              "lat": 43.762031914285714,
              "lng": -116.1039465,
              "alt": 1911.5361428571427,
              "speed2d": 1.1591428571428573,
              "speed3d": 1.2757142857142856,
              "msFromStart": 188222.46107268162
            },
            {
              "lat": 43.7620276,
              "lng": -116.10395047142858,
              "alt": 1912.226714285714,
              "speed2d": 1.0388571428571427,
              "speed3d": 1.1042857142857143,
              "msFromStart": 188740.62247619045
            },
            {
              "lat": 43.762020650000004,
              "lng": -116.10395718333332,
              "alt": 1913.4993333333332,
              "speed2d": 1.2863333333333333,
              "speed3d": 1.4049999999999998,
              "msFromStart": 189356.76799999995
            },
            {
              "lat": 43.762017119999996,
              "lng": -116.10396022,
              "alt": 1913.875,
              "speed2d": 1.2162000000000002,
              "speed3d": 1.304,
              "msFromStart": 189736.2648888888
            },
            {
              "lat": 43.762011675000004,
              "lng": -116.10396412499999,
              "alt": 1914.29675,
              "speed2d": 1.3962499999999998,
              "speed3d": 1.4575,
              "msFromStart": 190209.30533333326
            },
            {
              "lat": 43.76200522,
              "lng": -116.10396832,
              "alt": 1914.8822,
              "speed2d": 1.3538000000000001,
              "speed3d": 1.4020000000000001,
              "msFromStart": 190781.424
            },
            {
              "lat": 43.7619997,
              "lng": -116.10397254,
              "alt": 1915.3575999999998,
              "speed2d": 1.3732,
              "speed3d": 1.482,
              "msFromStart": 191232.50240000003
            },
            {
              "lat": 43.7619952625,
              "lng": -116.1039798125,
              "alt": 1916.162375,
              "speed2d": 1.193625,
              "speed3d": 1.3025000000000002,
              "msFromStart": 191776.7271111112
            },
            {
              "lat": 43.76199645,
              "lng": -116.10398445,
              "alt": 1916.3419999999999,
              "speed2d": 0.785,
              "speed3d": 0.9225,
              "msFromStart": 192285.1790409357
            },
            {
              "lat": 43.761991325,
              "lng": -116.10398950000001,
              "alt": 1916.8845,
              "speed2d": 1.194,
              "speed3d": 1.165,
              "msFromStart": 192739.0021052632
            },
            {
              "lat": 43.76198472857142,
              "lng": -116.10399297142857,
              "alt": 1917.5382857142856,
              "speed2d": 1.4681428571428572,
              "speed3d": 1.5057142857142856,
              "msFromStart": 193186.99341687566
            },
            {
              "lat": 43.761978428571425,
              "lng": -116.10399381428573,
              "alt": 1916.9361428571428,
              "speed2d": 1.4122857142857144,
              "speed3d": 1.4557142857142855,
              "msFromStart": 193736.85231746026
            },
            {
              "lat": 43.761972925,
              "lng": -116.10399512500001,
              "alt": 1917.5203749999998,
              "speed2d": 1.2125000000000001,
              "speed3d": 1.2974999999999999,
              "msFromStart": 194251.27699999989
            },
            {
              "lat": 43.761964649999996,
              "lng": -116.10399743333332,
              "alt": 1918.755,
              "speed2d": 1.4814999999999998,
              "speed3d": 1.465,
              "msFromStart": 194829.39999999997
            },
            {
              "lat": 43.76195595,
              "lng": -116.1040031,
              "alt": 1919.4335,
              "speed2d": 1.7725,
              "speed3d": 1.795,
              "msFromStart": 195352.5191111111
            },
            {
              "lat": 43.761950642857144,
              "lng": -116.10400711428571,
              "alt": 1919.7848571428574,
              "speed2d": 1.801857142857143,
              "speed3d": 1.8457142857142859,
              "msFromStart": 195710.0728888889
            },
            {
              "lat": 43.761941057142856,
              "lng": -116.10401365714286,
              "alt": 1920.4974285714288,
              "speed2d": 1.946,
              "speed3d": 1.9785714285714286,
              "msFromStart": 196299.46031746035
            },
            {
              "lat": 43.761934611111116,
              "lng": -116.1040176111111,
              "alt": 1921.119888888889,
              "speed2d": 1.693,
              "speed3d": 1.8077777777777777,
              "msFromStart": 196756.38222222216
            },
            {
              "lat": 43.7619293,
              "lng": -116.1040202857143,
              "alt": 1921.4557142857143,
              "speed2d": 1.5205714285714285,
              "speed3d": 1.5385714285714285,
              "msFromStart": 197237.01599999995
            },
            {
              "lat": 43.76192295,
              "lng": -116.104023,
              "alt": 1922.019,
              "speed2d": 1.8605,
              "speed3d": 1.915,
              "msFromStart": 197610.50177777768
            },
            {
              "lat": 43.761916475,
              "lng": -116.10402635,
              "alt": 1922.30125,
              "speed2d": 1.565,
              "speed3d": 1.595,
              "msFromStart": 198229.09825730984
            },
            {
              "lat": 43.76191191365349,
              "lng": -116.10402701057464,
              "alt": 1922.5629297622493,
              "speed2d": 1.5986915948579181,
              "speed3d": 1.6232598622992325,
              "msFromStart": 198500
            },
            {
              "lat": 43.76190659685163,
              "lng": -116.10403057842852,
              "alt": 1923.0155574995183,
              "speed2d": 1.5343303091874874,
              "speed3d": 1.5323145673301453,
              "msFromStart": 199000
            },
            {
              "lat": 43.761898475,
              "lng": -116.104033875,
              "alt": 1923.39025,
              "speed2d": 1.74525,
              "speed3d": 1.705,
              "msFromStart": 199658.5286666667
            },
            {
              "lat": 43.76188633333334,
              "lng": -116.1040392,
              "alt": 1923.8356666666668,
              "speed2d": 1.7006666666666665,
              "speed3d": 1.813333333333333,
              "msFromStart": 200404.47762962966
            },
            {
              "lat": 43.761883866666665,
              "lng": -116.1040415,
              "alt": 1924.0953333333332,
              "speed2d": 1.6953333333333331,
              "speed3d": 1.69,
              "msFromStart": 200624.40651851855
            },
            {
              "lat": 43.76187798000001,
              "lng": -116.10404256000001,
              "alt": 1924.2656,
              "speed2d": 1.2171999999999998,
              "speed3d": 1.3459999999999999,
              "msFromStart": 201254.8764444445
            },
            {
              "lat": 43.76187172,
              "lng": -116.10404742,
              "alt": 1924.942,
              "speed2d": 1.7352,
              "speed3d": 1.6680000000000001,
              "msFromStart": 201738.848
            },
            {
              "lat": 43.76186486182156,
              "lng": -116.1040496427948,
              "alt": 1926.0635040606596,
              "speed2d": 2.1822607576668043,
              "speed3d": 2.1654585124259143,
              "msFromStart": 202000
            },
            {
              "lat": 43.76185734285714,
              "lng": -116.10405937142858,
              "alt": 1927.2425714285714,
              "speed2d": 1.5962857142857145,
              "speed3d": 1.7014285714285715,
              "msFromStart": 202735.87103759393
            },
            {
              "lat": 43.761854220000004,
              "lng": -116.10406228,
              "alt": 1927.19,
              "speed2d": 1.3954,
              "speed3d": 1.47,
              "msFromStart": 203214.3114853801
            },
            {
              "lat": 43.7618503,
              "lng": -116.10406610000001,
              "alt": 1926.9822857142858,
              "speed2d": 1.314142857142857,
              "speed3d": 1.372857142857143,
              "msFromStart": 203757.18019047618
            },
            {
              "lat": 43.761845,
              "lng": -116.10406796666668,
              "alt": 1926.9300000000003,
              "speed2d": 1.4116666666666666,
              "speed3d": 1.4049999999999998,
              "msFromStart": 204271.73214814815
            },
            {
              "lat": 43.76184045,
              "lng": -116.10407025,
              "alt": 1926.911,
              "speed2d": 1.6675,
              "speed3d": 1.6400000000000001,
              "msFromStart": 204592.74399999998
            },
            {
              "lat": 43.761834566666664,
              "lng": -116.10407363333334,
              "alt": 1927.141,
              "speed2d": 1.6653333333333333,
              "speed3d": 1.663333333333333,
              "msFromStart": 205079.63733333326
            },
            {
              "lat": 43.761826600000006,
              "lng": -116.10407810000001,
              "alt": 1926.8595,
              "speed2d": 1.3250000000000002,
              "speed3d": 1.225,
              "msFromStart": 205860.3266666666
            },
            {
              "lat": 43.761822675,
              "lng": -116.10407915,
              "alt": 1926.68925,
              "speed2d": 1.5135,
              "speed3d": 1.445,
              "msFromStart": 206218.4079999999
            },
            {
              "lat": 43.761817199999996,
              "lng": -116.10407983333334,
              "alt": 1926.623,
              "speed2d": 1.4593333333333331,
              "speed3d": 1.5299999999999998,
              "msFromStart": 206677.01007407415
            },
            {
              "lat": 43.76181066666667,
              "lng": -116.10408213333334,
              "alt": 1926.6908333333333,
              "speed2d": 1.4158333333333335,
              "speed3d": 1.395,
              "msFromStart": 207272.7921481482
            },
            {
              "lat": 43.76180588,
              "lng": -116.1040854,
              "alt": 1926.8368,
              "speed2d": 1.4368,
              "speed3d": 1.4899999999999998,
              "msFromStart": 207681.32746666664
            },
            {
              "lat": 43.761808333333335,
              "lng": -116.10408886666667,
              "alt": 1926.4686666666666,
              "speed2d": 0.5603333333333333,
              "speed3d": 0.5933333333333334,
              "msFromStart": 208271.21798050683
            },
            {
              "lat": 43.7618068,
              "lng": -116.10408976666668,
              "alt": 1926.504,
              "speed2d": 0.5023333333333333,
              "speed3d": 0.5599999999999999,
              "msFromStart": 208748.09347368428
            },
            {
              "lat": 43.761807383333334,
              "lng": -116.1040898,
              "alt": 1926.3235,
              "speed2d": 0.138,
              "speed3d": 0.2016666666666667,
              "msFromStart": 209197.58679922036
            },
            {
              "lat": 43.76180395,
              "lng": -116.1040928,
              "alt": 1926.5425,
              "speed2d": 0.7835,
              "speed3d": 0.595,
              "msFromStart": 209573.38844444448
            },
            {
              "lat": 43.76179405,
              "lng": -116.10409358333334,
              "alt": 1926.8553333333332,
              "speed2d": 1.4155000000000002,
              "speed3d": 1.5116666666666667,
              "msFromStart": 210168.60562962978
            },
            {
              "lat": 43.761793575,
              "lng": -116.104096075,
              "alt": 1926.9857499999998,
              "speed2d": 0.63725,
              "speed3d": 0.6375,
              "msFromStart": 210747.21600000007
            },
            {
              "lat": 43.761792,
              "lng": -116.10409687500001,
              "alt": 1926.9137500000002,
              "speed2d": 0.583,
              "speed3d": 0.5525,
              "msFromStart": 211192.05600000016
            },
            {
              "lat": 43.76178752,
              "lng": -116.10410102,
              "alt": 1927.8217999999997,
              "speed2d": 0.704,
              "speed3d": 0.6140000000000001,
              "msFromStart": 211808.3666526315
            },
            {
              "lat": 43.76178103333333,
              "lng": -116.10410484999998,
              "alt": 1928.3628333333334,
              "speed2d": 1.3086666666666666,
              "speed3d": 1.2566666666666668,
              "msFromStart": 212262.9220263157
            },
            {
              "lat": 43.7617764,
              "lng": -116.10410838000001,
              "alt": 1928.5728,
              "speed2d": 1.3076,
              "speed3d": 1.3279999999999998,
              "msFromStart": 212701.72199999998
            },
            {
              "lat": 43.761771466666666,
              "lng": -116.1041116,
              "alt": 1928.599,
              "speed2d": 1.13,
              "speed3d": 1.07,
              "msFromStart": 213293.1585263158
            },
            {
              "lat": 43.76176662857143,
              "lng": -116.10411481428571,
              "alt": 1929.0828571428574,
              "speed2d": 1.1777142857142857,
              "speed3d": 1.21,
              "msFromStart": 213752.46821052636
            },
            {
              "lat": 43.761761783333334,
              "lng": -116.10411811666667,
              "alt": 1929.4205,
              "speed2d": 1.2413333333333334,
              "speed3d": 1.26,
              "msFromStart": 214181.8800000001
            },
            {
              "lat": 43.7617546,
              "lng": -116.10412285,
              "alt": 1930.1879999999999,
              "speed2d": 1.2653333333333334,
              "speed3d": 1.2983333333333333,
              "msFromStart": 214815.8053333333
            },
            {
              "lat": 43.761750771428574,
              "lng": -116.10412620000001,
              "alt": 1930.7844285714284,
              "speed2d": 1.102,
              "speed3d": 1.1414285714285715,
              "msFromStart": 215239.1356190475
            },
            {
              "lat": 43.7617477,
              "lng": -116.10412825,
              "alt": 1931.201,
              "speed2d": 1.1625,
              "speed3d": 1.22,
              "msFromStart": 215541.6915555556
            },
            {
              "lat": 43.761743824999996,
              "lng": -116.1041316,
              "alt": 1931.3990000000001,
              "speed2d": 0.84625,
              "speed3d": 0.96,
              "msFromStart": 216213.34000000014
            },
            {
              "lat": 43.761739500000004,
              "lng": -116.10413517500001,
              "alt": 1931.88625,
              "speed2d": 1.08175,
              "speed3d": 1.12,
              "msFromStart": 216777.02400000006
            },
            {
              "lat": 43.76173516666667,
              "lng": -116.1041368,
              "alt": 1931.8386666666665,
              "speed2d": 1.291,
              "speed3d": 1.3233333333333333,
              "msFromStart": 217180.6506666668
            },
            {
              "lat": 43.76173082,
              "lng": -116.10414155999999,
              "alt": 1932.0428000000002,
              "speed2d": 0.9346,
              "speed3d": 1.024,
              "msFromStart": 217789.59573333326
            },
            {
              "lat": 43.76173035000001,
              "lng": -116.10414315,
              "alt": 1931.6080000000002,
              "speed2d": 0.64675,
              "speed3d": 0.6799999999999999,
              "msFromStart": 218317.81749707597
            },
            {
              "lat": 43.761728940000005,
              "lng": -116.10414374000001,
              "alt": 1931.3408,
              "speed2d": 0.7200000000000001,
              "speed3d": 0.754,
              "msFromStart": 218648.03705263152
            },
            {
              "lat": 43.76172765,
              "lng": -116.10414532499999,
              "alt": 1930.98225,
              "speed2d": 0.48100000000000004,
              "speed3d": 0.5225,
              "msFromStart": 219239.72610526305
            },
            {
              "lat": 43.7617271,
              "lng": -116.1041477,
              "alt": 1930.9283999999998,
              "speed2d": 0.4072,
              "speed3d": 0.398,
              "msFromStart": 219692.55999999994
            },
            {
              "lat": 43.761723975,
              "lng": -116.104151825,
              "alt": 1930.9471249999997,
              "speed2d": 0.68625,
              "speed3d": 0.66625,
              "msFromStart": 220247.83433333325
            },
            {
              "lat": 43.76172808752803,
              "lng": -116.10415454164799,
              "alt": 1931.265232511118,
              "speed2d": -0.19495369996934075,
              "speed3d": -0.11162808330778418,
              "msFromStart": 220500
            },
            {
              "lat": 43.76171655,
              "lng": -116.1041615,
              "alt": 1931.693,
              "speed2d": 1.2265000000000001,
              "speed3d": 1.135,
              "msFromStart": 221149.48755555556
            },
            {
              "lat": 43.761711866666666,
              "lng": -116.10416314999999,
              "alt": 1931.596,
              "speed2d": 0.7628333333333333,
              "speed3d": 0.8200000000000001,
              "msFromStart": 221827.08800000002
            },
            {
              "lat": 43.761709120000006,
              "lng": -116.1041635,
              "alt": 1931.3438,
              "speed2d": 0.8542000000000001,
              "speed3d": 0.808,
              "msFromStart": 222292.55736140354
            },
            {
              "lat": 43.761706950000004,
              "lng": -116.10416335,
              "alt": 1931.1915,
              "speed2d": 0.7695000000000001,
              "speed3d": 0.815,
              "msFromStart": 222686.44252631578
            },
            {
              "lat": 43.76170351666667,
              "lng": -116.10416648333333,
              "alt": 1931.180166666667,
              "speed2d": 0.7796666666666667,
              "speed3d": 0.7966666666666666,
              "msFromStart": 223281.78596491227
            },
            {
              "lat": 43.76170425702405,
              "lng": -116.10416785358981,
              "alt": 1931.220047864215,
              "speed2d": 0.4894536916548804,
              "speed3d": 0.487294483734088,
              "msFromStart": 223500
            },
            {
              "lat": 43.761702400000004,
              "lng": -116.10417233333334,
              "alt": 1931.3646666666666,
              "speed2d": 0.4703333333333334,
              "speed3d": 0.4966666666666667,
              "msFromStart": 224217.5253333333
            },
            {
              "lat": 43.761703644018176,
              "lng": -116.10417576230245,
              "alt": 1931.9398465033141,
              "speed2d": 0.6198487554931197,
              "speed3d": 0.2814446860816933,
              "msFromStart": 224500
            },
            {
              "lat": 43.761699607006015,
              "lng": -116.10417542091315,
              "alt": 1930.6617378673682,
              "speed2d": 0.778139071351338,
              "speed3d": 1.4030254514936615,
              "msFromStart": 225000
            },
            {
              "lat": 43.7616921875,
              "lng": -116.10418020000002,
              "alt": 1932.3328749999998,
              "speed2d": 0.7861250000000001,
              "speed3d": 0.8300000000000001,
              "msFromStart": 225771.71333333338
            },
            {
              "lat": 43.761690828571425,
              "lng": -116.10418119999999,
              "alt": 1932.3767142857141,
              "speed2d": 0.5872857142857143,
              "speed3d": 0.6414285714285715,
              "msFromStart": 226231.84647619055
            },
            {
              "lat": 43.76168888333333,
              "lng": -116.10418236666668,
              "alt": 1932.256666666667,
              "speed2d": 0.581,
              "speed3d": 0.6283333333333334,
              "msFromStart": 226733.1244444444
            },
            {
              "lat": 43.761690361910446,
              "lng": -116.10418422152239,
              "alt": 1932.573032537132,
              "speed2d": 0.6340701492803617,
              "speed3d": 0.46785970143927674,
              "msFromStart": 227000
            },
            {
              "lat": 43.7616884,
              "lng": -116.10419295,
              "alt": 1933.2383333333332,
              "speed2d": 0.6940000000000001,
              "speed3d": 0.7449999999999999,
              "msFromStart": 227850.09263157885
            },
            {
              "lat": 43.761688225,
              "lng": -116.104194475,
              "alt": 1933.1635,
              "speed2d": 0.41724999999999995,
              "speed3d": 0.49249999999999994,
              "msFromStart": 228259.19819883027
            },
            {
              "lat": 43.76168784,
              "lng": -116.10419960000002,
              "alt": 1933.9606,
              "speed2d": 0.5558,
              "speed3d": 0.638,
              "msFromStart": 228801.39022222228
            },
            {
              "lat": 43.7616855,
              "lng": -116.1042031625,
              "alt": 1934.548875,
              "speed2d": 0.614125,
              "speed3d": 0.7274999999999999,
              "msFromStart": 229249.63800000006
            },
            {
              "lat": 43.76167992,
              "lng": -116.10420367999998,
              "alt": 1934.1892000000003,
              "speed2d": 0.9577999999999999,
              "speed3d": 0.97,
              "msFromStart": 229825.0510222222
            },
            {
              "lat": 43.761675499999996,
              "lng": -116.10420260000001,
              "alt": 1933.6145,
              "speed2d": 0.988,
              "speed3d": 1.105,
              "msFromStart": 230254.512
            },
            {
              "lat": 43.7616701,
              "lng": -116.10420303333333,
              "alt": 1933.1860000000001,
              "speed2d": 0.9156666666666666,
              "speed3d": 0.8999999999999999,
              "msFromStart": 230858.76800000004
            },
            {
              "lat": 43.76166546666666,
              "lng": -116.10420533333333,
              "alt": 1933.377,
              "speed2d": 1.1826666666666668,
              "speed3d": 1.1533333333333333,
              "msFromStart": 231206.37481481486
            },
            {
              "lat": 43.76166143333333,
              "lng": -116.10420746666668,
              "alt": 1933.0833333333335,
              "speed2d": 0.8393333333333333,
              "speed3d": 0.9066666666666666,
              "msFromStart": 231793.25155555562
            },
            {
              "lat": 43.761657166666666,
              "lng": -116.10421,
              "alt": 1933.1503333333335,
              "speed2d": 0.821,
              "speed3d": 0.9033333333333333,
              "msFromStart": 232288.51786354778
            },
            {
              "lat": 43.76165483333334,
              "lng": -116.10421196666667,
              "alt": 1932.9856666666665,
              "speed2d": 0.7529999999999999,
              "speed3d": 0.7733333333333333,
              "msFromStart": 232666.88421052624
            },
            {
              "lat": 43.76163866,
              "lng": -116.10421018,
              "alt": 1932.3075999999999,
              "speed2d": 2.1435999999999997,
              "speed3d": 2.182,
              "msFromStart": 233368.83584561397
            },
            {
              "lat": 43.76163662,
              "lng": -116.10421065999999,
              "alt": 1932.375,
              "speed2d": 1.3100000000000003,
              "speed3d": 1.518,
              "msFromStart": 233701.77511111117
            },
            {
              "lat": 43.76163685,
              "lng": -116.10421182500001,
              "alt": 1932.11725,
              "speed2d": 0.45525,
              "speed3d": 0.5125000000000001,
              "msFromStart": 234190.3353333335
            },
            {
              "lat": 43.761632860000006,
              "lng": -116.10421070000001,
              "alt": 1931.4830000000002,
              "speed2d": 0.8088,
              "speed3d": 0.786,
              "msFromStart": 234690.52699999994
            },
            {
              "lat": 43.76162545714286,
              "lng": -116.10421412857144,
              "alt": 1930.9475714285713,
              "speed2d": 1.239,
              "speed3d": 1.2857142857142856,
              "msFromStart": 235312.58160902245
            },
            {
              "lat": 43.76162295,
              "lng": -116.104215,
              "alt": 1930.7365,
              "speed2d": 0.78,
              "speed3d": 0.645,
              "msFromStart": 235864.71410526312
            },
            {
              "lat": 43.76161605,
              "lng": -116.104215975,
              "alt": 1930.9029999999998,
              "speed2d": 1.1572500000000001,
              "speed3d": 1.275,
              "msFromStart": 236332.8203976608
            },
            {
              "lat": 43.7616158,
              "lng": -116.10421465,
              "alt": 1930.8155000000002,
              "speed2d": 0.41600000000000004,
              "speed3d": 0.365,
              "msFromStart": 236854.59199999992
            },
            {
              "lat": 43.76161343333333,
              "lng": -116.10421943333333,
              "alt": 1930.784,
              "speed2d": 0.8906666666666667,
              "speed3d": 0.8333333333333333,
              "msFromStart": 237184.0533333332
            },
            {
              "lat": 43.76161351332043,
              "lng": -116.10422964752887,
              "alt": 1930.9602038588841,
              "speed2d": 0.7509274077662296,
              "speed3d": 0.81595366109634,
              "msFromStart": 237500
            }
          ]
        }
      ]
    },
    {
      "id": "lift-deer-point-express",
      "pathType": "lift",
      "name": "Deer Point Express",
      "speed": "fast",
      "seats": 4,
      "videos": [
        {
          "videoId": "GH010033",
          "pathId": "lift-deer-point-express",
          "startSec": 8,
          "endSec": 250,
          "id": "video-segment-10",
          "gps": [
            {
              "lat": 43.76376851111111,
              "lng": -116.1024860888889,
              "alt": 1886.2421111111112,
              "speed2d": 4.301777777777778,
              "speed3d": 4.054444444444444,
              "msFromStart": 8267.14700779727
            },
            {
              "lat": 43.76372620000001,
              "lng": -116.10246483333333,
              "alt": 1885.4248333333335,
              "speed2d": 6.143833333333333,
              "speed3d": 5.923333333333334,
              "msFromStart": 8679.258388888882
            },
            {
              "lat": 43.76363415,
              "lng": -116.1024248,
              "alt": 1884.074,
              "speed2d": 10.179,
              "speed3d": 9.805,
              "msFromStart": 9229.061833333333
            },
            {
              "lat": 43.76353196,
              "lng": -116.10238860000001,
              "alt": 1882.4732,
              "speed2d": 13.395199999999999,
              "speed3d": 13.216,
              "msFromStart": 9807.876666666674
            },
            {
              "lat": 43.7635036709494,
              "lng": -116.10238062710765,
              "alt": 1881.6636773286086,
              "speed2d": 13.76787161246881,
              "speed3d": 13.63056585931002,
              "msFromStart": 10000
            },
            {
              "lat": 43.76343814834021,
              "lng": -116.10236308227452,
              "alt": 1879.33492921329,
              "speed2d": 13.828157823220502,
              "speed3d": 13.756162131709381,
              "msFromStart": 10500
            },
            {
              "lat": 43.76337262573102,
              "lng": -116.1023455374414,
              "alt": 1877.0061810979714,
              "speed2d": 13.888444033972196,
              "speed3d": 13.881758404108743,
              "msFromStart": 11000
            },
            {
              "lat": 43.76330710312184,
              "lng": -116.10232799260827,
              "alt": 1874.6774329826528,
              "speed2d": 13.94873024472389,
              "speed3d": 14.007354676508104,
              "msFromStart": 11500
            },
            {
              "lat": 43.763241580512656,
              "lng": -116.10231044777514,
              "alt": 1872.3486848673342,
              "speed2d": 14.009016455475583,
              "speed3d": 14.132950948907464,
              "msFromStart": 12000
            },
            {
              "lat": 43.76315451250001,
              "lng": -116.10228428750001,
              "alt": 1868.87575,
              "speed2d": 13.94675,
              "speed3d": 14.18375,
              "msFromStart": 12697.253789473689
            },
            {
              "lat": 43.76312088495486,
              "lng": -116.10227317573846,
              "alt": 1867.2223326699216,
              "speed2d": 13.776443289840223,
              "speed3d": 14.042387506422596,
              "msFromStart": 13000
            },
            {
              "lat": 43.76306105600952,
              "lng": -116.10226138405478,
              "alt": 1864.743507087553,
              "speed2d": 13.670595122662704,
              "speed3d": 13.963248689841272,
              "msFromStart": 13500
            },
            {
              "lat": 43.76300122706419,
              "lng": -116.10224959237112,
              "alt": 1862.2646815051846,
              "speed2d": 13.564746955485182,
              "speed3d": 13.88410987325995,
              "msFromStart": 14000
            },
            {
              "lat": 43.76294139811885,
              "lng": -116.10223780068745,
              "alt": 1859.7858559228162,
              "speed2d": 13.458898788307662,
              "speed3d": 13.804971056678626,
              "msFromStart": 14500
            },
            {
              "lat": 43.76288033831032,
              "lng": -116.10223355307468,
              "alt": 1858.7281896267928,
              "speed2d": 13.136738143706541,
              "speed3d": 13.559402465652191,
              "msFromStart": 15000
            },
            {
              "lat": 43.76279120000001,
              "lng": -116.10220214,
              "alt": 1852.059,
              "speed2d": 13.2258,
              "speed3d": 13.713999999999999,
              "msFromStart": 15779.772222222224
            },
            {
              "lat": 43.76275132,
              "lng": -116.10217623999999,
              "alt": 1846.8436000000002,
              "speed2d": 12.244,
              "speed3d": 13.04,
              "msFromStart": 16276.231466666666
            },
            {
              "lat": 43.76273843753032,
              "lng": -116.1021650822766,
              "alt": 1845.2106926867489,
              "speed2d": 11.731395979614623,
              "speed3d": 12.487507120098924,
              "msFromStart": 16500
            },
            {
              "lat": 43.76272848353213,
              "lng": -116.10213821801909,
              "alt": 1841.814699277419,
              "speed2d": 9.631441924402584,
              "speed3d": 10.481638821983237,
              "msFromStart": 17000
            },
            {
              "lat": 43.76267965,
              "lng": -116.10212932499999,
              "alt": 1838.15575,
              "speed2d": 9.191499999999998,
              "speed3d": 10.15,
              "msFromStart": 17703.232947368426
            },
            {
              "lat": 43.76264592,
              "lng": -116.1021145,
              "alt": 1834.9906,
              "speed2d": 8.952200000000001,
              "speed3d": 9.927999999999999,
              "msFromStart": 18269.006477192986
            },
            {
              "lat": 43.762599433333335,
              "lng": -116.10208976666667,
              "alt": 1831.4789999999998,
              "speed2d": 9.170333333333334,
              "speed3d": 10.136666666666667,
              "msFromStart": 18885.967333333334
            },
            {
              "lat": 43.76257183333333,
              "lng": -116.10207431111111,
              "alt": 1829.334111111111,
              "speed2d": 9.245333333333335,
              "speed3d": 10.241111111111111,
              "msFromStart": 19271.230222222228
            },
            {
              "lat": 43.762544077777775,
              "lng": -116.10205260000001,
              "alt": 1826.3403333333333,
              "speed2d": 9.121111111111112,
              "speed3d": 10.165555555555555,
              "msFromStart": 19765.688000000013
            },
            {
              "lat": 43.76253078446666,
              "lng": -116.1020413953701,
              "alt": 1824.8514727690201,
              "speed2d": 9.144997336564265,
              "speed3d": 10.175096358837703,
              "msFromStart": 20000
            },
            {
              "lat": 43.76251115432318,
              "lng": -116.10202906361327,
              "alt": 1823.990766477576,
              "speed2d": 8.376905055427484,
              "speed3d": 9.616392274917763,
              "msFromStart": 20500
            },
            {
              "lat": 43.7624924,
              "lng": -116.10201006666668,
              "alt": 1822.903,
              "speed2d": 7.063000000000001,
              "speed3d": 8.379999999999999,
              "msFromStart": 21235.363185185186
            },
            {
              "lat": 43.76249125,
              "lng": -116.1019946,
              "alt": 1822.6495,
              "speed2d": 6.1835,
              "speed3d": 7.359999999999999,
              "msFromStart": 21775.071333333344
            },
            {
              "lat": 43.76248585,
              "lng": -116.1019876,
              "alt": 1823.2604999999999,
              "speed2d": 5.627000000000001,
              "speed3d": 6.93,
              "msFromStart": 22324.80894444444
            },
            {
              "lat": 43.76249618,
              "lng": -116.1019808,
              "alt": 1824.266,
              "speed2d": 4.7802,
              "speed3d": 5.946,
              "msFromStart": 22738.678111111105
            },
            {
              "lat": 43.762509837500005,
              "lng": -116.1019753125,
              "alt": 1825.3913750000002,
              "speed2d": 4.158875,
              "speed3d": 5.268750000000001,
              "msFromStart": 23207.178374999996
            },
            {
              "lat": 43.7625075,
              "lng": -116.10196255,
              "alt": 1824.30425,
              "speed2d": 5.06175,
              "speed3d": 5.785,
              "msFromStart": 23728.728888888887
            },
            {
              "lat": 43.76248782819047,
              "lng": -116.10195430201324,
              "alt": 1822.4923721840044,
              "speed2d": 7.208791457593474,
              "speed3d": 7.63022082321643,
              "msFromStart": 24000
            },
            {
              "lat": 43.7624775217199,
              "lng": -116.10194588215084,
              "alt": 1822.1852742982323,
              "speed2d": 6.519131359169979,
              "speed3d": 7.053757215111684,
              "msFromStart": 24500
            },
            {
              "lat": 43.76246721524934,
              "lng": -116.10193746228845,
              "alt": 1821.87817641246,
              "speed2d": 5.829471260746483,
              "speed3d": 6.4772936070069385,
              "msFromStart": 25000
            },
            {
              "lat": 43.76245573333333,
              "lng": -116.10192746666667,
              "alt": 1821.601,
              "speed2d": 5.063666666666666,
              "speed3d": 5.83,
              "msFromStart": 25599.235999999997
            },
            {
              "lat": 43.76245735,
              "lng": -116.10191664999999,
              "alt": 1821.8485,
              "speed2d": 4.5875,
              "speed3d": 5.27,
              "msFromStart": 26233.26763157895
            },
            {
              "lat": 43.76245332,
              "lng": -116.10189888,
              "alt": 1821.5459999999998,
              "speed2d": 5.160399999999999,
              "speed3d": 5.676,
              "msFromStart": 26800.58187368422
            },
            {
              "lat": 43.76244850022998,
              "lng": -116.10189385236218,
              "alt": 1821.380293054718,
              "speed2d": 5.614622708479477,
              "speed3d": 6.060022997581371,
              "msFromStart": 27000
            },
            {
              "lat": 43.7624502,
              "lng": -116.1018778,
              "alt": 1822.9938000000002,
              "speed2d": 4.2022,
              "speed3d": 4.902,
              "msFromStart": 27811.223022222213
            },
            {
              "lat": 43.76243961666667,
              "lng": -116.10186746666666,
              "alt": 1823.5236666666665,
              "speed2d": 5.011,
              "speed3d": 5.245,
              "msFromStart": 28303.818518518514
            },
            {
              "lat": 43.7624283,
              "lng": -116.10185626666669,
              "alt": 1823.5504444444443,
              "speed2d": 5.039777777777778,
              "speed3d": 5.573333333333332,
              "msFromStart": 28735.276555555563
            },
            {
              "lat": 43.76242433197118,
              "lng": -116.10184936412246,
              "alt": 1823.6785281335358,
              "speed2d": 4.368345671263453,
              "speed3d": 4.706440504260525,
              "msFromStart": 29000
            },
            {
              "lat": 43.762427450000004,
              "lng": -116.1018294,
              "alt": 1824.904,
              "speed2d": 4.02,
              "speed3d": 4.455,
              "msFromStart": 29863.27549999998
            },
            {
              "lat": 43.7624161,
              "lng": -116.10181963333332,
              "alt": 1825.5216666666665,
              "speed2d": 4.491666666666666,
              "speed3d": 4.71,
              "msFromStart": 30312.158888888887
            },
            {
              "lat": 43.7624091,
              "lng": -116.10180896666668,
              "alt": 1826.5320000000002,
              "speed2d": 4.741333333333333,
              "speed3d": 4.883333333333333,
              "msFromStart": 30751.60955555556
            },
            {
              "lat": 43.762401257142855,
              "lng": -116.1017943,
              "alt": 1828.1127142857142,
              "speed2d": 5.096714285714285,
              "speed3d": 5.167142857142857,
              "msFromStart": 31303.895283208032
            },
            {
              "lat": 43.7623971125,
              "lng": -116.101786,
              "alt": 1828.9646249999998,
              "speed2d": 5.419,
              "speed3d": 5.500000000000001,
              "msFromStart": 31732.281578947386
            },
            {
              "lat": 43.762399200000004,
              "lng": -116.1017804,
              "alt": 1830.095,
              "speed2d": 4.1745,
              "speed3d": 4.605,
              "msFromStart": 32172.38810526317
            },
            {
              "lat": 43.76241104527882,
              "lng": -116.10177833574907,
              "alt": 1832.9241556691347,
              "speed2d": 3.835390669025637,
              "speed3d": 3.610802044426994,
              "msFromStart": 32500
            },
            {
              "lat": 43.76240546759456,
              "lng": -116.10176500513657,
              "alt": 1832.9792491596795,
              "speed2d": 3.8954090329939772,
              "speed3d": 4.428122899197905,
              "msFromStart": 33000
            },
            {
              "lat": 43.76237935,
              "lng": -116.1017411,
              "alt": 1832.411,
              "speed2d": 4.9375,
              "speed3d": 4.71,
              "msFromStart": 33710.74300000002
            },
            {
              "lat": 43.76236623679034,
              "lng": -116.10173026511475,
              "alt": 1830.624173141969,
              "speed2d": 5.350892922700059,
              "speed3d": 5.092124330660692,
              "msFromStart": 34000
            },
            {
              "lat": 43.76233085,
              "lng": -116.10169859999999,
              "alt": 1825.1375,
              "speed2d": 5.3149999999999995,
              "speed3d": 5.37,
              "msFromStart": 34919.38255555551
            },
            {
              "lat": 43.762320349999996,
              "lng": -116.10168933749999,
              "alt": 1824.655375,
              "speed2d": 5.270625,
              "speed3d": 5.362499999999999,
              "msFromStart": 35236.01270614034
            },
            {
              "lat": 43.76230545,
              "lng": -116.1016728,
              "alt": 1824.425,
              "speed2d": 5.1705000000000005,
              "speed3d": 5.220000000000001,
              "msFromStart": 35691.46515789475
            },
            {
              "lat": 43.762292323410115,
              "lng": -116.10166095235232,
              "alt": 1823.7533657285867,
              "speed2d": 5.17830648326148,
              "speed3d": 5.221010236728652,
              "msFromStart": 36000
            },
            {
              "lat": 43.7622551,
              "lng": -116.1016258,
              "alt": 1821.2685000000001,
              "speed2d": 5.3095,
              "speed3d": 5.364999999999999,
              "msFromStart": 36863.11299999998
            },
            {
              "lat": 43.762242799999996,
              "lng": -116.10161408333332,
              "alt": 1820.427,
              "speed2d": 5.2965,
              "speed3d": 5.406666666666667,
              "msFromStart": 37179.557979360165
            },
            {
              "lat": 43.762230956773,
              "lng": -116.10160669155054,
              "alt": 1819.9803667857457,
              "speed2d": 4.9813662021651925,
              "speed3d": 5.0604577527064905,
              "msFromStart": 37500
            },
            {
              "lat": 43.762210551343664,
              "lng": -116.10159090163498,
              "alt": 1819.3864230387583,
              "speed2d": 4.918206539908854,
              "speed3d": 4.981508174886068,
              "msFromStart": 38000
            },
            {
              "lat": 43.762178219999996,
              "lng": -116.10156520000001,
              "alt": 1817.7354,
              "speed2d": 4.9296,
              "speed3d": 5.006,
              "msFromStart": 38777.03315555554
            },
            {
              "lat": 43.7621588,
              "lng": -116.10154842857145,
              "alt": 1816.0691428571427,
              "speed2d": 5.312428571428571,
              "speed3d": 5.365714285714286,
              "msFromStart": 39238.2085079365
            },
            {
              "lat": 43.762152853971585,
              "lng": -116.1015432376019,
              "alt": 1816.8835229580316,
              "speed2d": 5.332167547479054,
              "speed3d": 5.4456138627166295,
              "msFromStart": 39500
            },
            {
              "lat": 43.762138962499996,
              "lng": -116.10153077500001,
              "alt": 1819.307125,
              "speed2d": 5.23075,
              "speed3d": 5.317499999999999,
              "msFromStart": 40201.02949999999
            },
            {
              "lat": 43.76212565,
              "lng": -116.101518825,
              "alt": 1820.74075,
              "speed2d": 5.096,
              "speed3d": 5.16,
              "msFromStart": 40778.48399999999
            },
            {
              "lat": 43.76211852855091,
              "lng": -116.1015119056941,
              "alt": 1821.10274439788,
              "speed2d": 5.145628706048125,
              "speed3d": 5.170285853830373,
              "msFromStart": 41000
            },
            {
              "lat": 43.76210009664999,
              "lng": -116.10149478516668,
              "alt": 1822.3584163309797,
              "speed2d": 5.487818167211804,
              "speed3d": 5.261148333046413,
              "msFromStart": 41500
            },
            {
              "lat": 43.762087333333334,
              "lng": -116.1014801,
              "alt": 1823.342,
              "speed2d": 5.255666666666667,
              "speed3d": 5.312222222222222,
              "msFromStart": 42264.059048732954
            },
            {
              "lat": 43.76207615,
              "lng": -116.10146795,
              "alt": 1824.328,
              "speed2d": 5.249499999999999,
              "speed3d": 5.28,
              "msFromStart": 42814.0613333333
            },
            {
              "lat": 43.7620681,
              "lng": -116.101459,
              "alt": 1826.478,
              "speed2d": 5.196,
              "speed3d": 5.23,
              "msFromStart": 43281.85044444441
            },
            {
              "lat": 43.762066974999996,
              "lng": -116.101450575,
              "alt": 1829.617,
              "speed2d": 4.85825,
              "speed3d": 4.925,
              "msFromStart": 43777.682999999975
            },
            {
              "lat": 43.762064550000005,
              "lng": -116.10144034999999,
              "alt": 1833.1805,
              "speed2d": 4.3685,
              "speed3d": 4.375,
              "msFromStart": 44437.496222222224
            },
            {
              "lat": 43.76205824,
              "lng": -116.1014359,
              "alt": 1833.7040000000002,
              "speed2d": 4.4414,
              "speed3d": 4.422000000000001,
              "msFromStart": 44651.4120888889
            },
            {
              "lat": 43.762043274999996,
              "lng": -116.1014225,
              "alt": 1835.9162500000002,
              "speed2d": 4.59075,
              "speed3d": 4.5600000000000005,
              "msFromStart": 45247.32462573101
            },
            {
              "lat": 43.76202794444445,
              "lng": -116.10141252222222,
              "alt": 1837.8316666666665,
              "speed2d": 4.856111111111112,
              "speed3d": 4.8455555555555545,
              "msFromStart": 45729.626947368444
            },
            {
              "lat": 43.76202083333333,
              "lng": -116.10140439999999,
              "alt": 1839.3373333333334,
              "speed2d": 4.7508333333333335,
              "speed3d": 4.766666666666667,
              "msFromStart": 46161.39910916181
            },
            {
              "lat": 43.762011967446306,
              "lng": -116.10139778160323,
              "alt": 1840.0350336393344,
              "speed2d": 4.796197491033847,
              "speed3d": 4.849954922426052,
              "msFromStart": 46500
            },
            {
              "lat": 43.76200157691831,
              "lng": -116.10138752095683,
              "alt": 1841.5858199425404,
              "speed2d": 4.885166386988291,
              "speed3d": 4.895413482402776,
              "msFromStart": 47000
            },
            {
              "lat": 43.76198475,
              "lng": -116.101371125,
              "alt": 1844.418,
              "speed2d": 4.958,
              "speed3d": 4.970000000000001,
              "msFromStart": 47820.428000000014
            },
            {
              "lat": 43.761980199999996,
              "lng": -116.10136676666666,
              "alt": 1845.8273333333336,
              "speed2d": 4.881,
              "speed3d": 4.8933333333333335,
              "msFromStart": 48095.46466666668
            },
            {
              "lat": 43.76197512570474,
              "lng": -116.10136216478931,
              "alt": 1847.4458922551587,
              "speed2d": 4.662220095902345,
              "speed3d": 4.718239465271714,
              "msFromStart": 48500
            },
            {
              "lat": 43.7619694,
              "lng": -116.10135565,
              "alt": 1851.2535,
              "speed2d": 4.4005,
              "speed3d": 4.42,
              "msFromStart": 49113.951999999976
            },
            {
              "lat": 43.761956600000005,
              "lng": -116.10134335000001,
              "alt": 1854.635,
              "speed2d": 4.5809999999999995,
              "speed3d": 4.6,
              "msFromStart": 49746.22088888889
            },
            {
              "lat": 43.76195158358186,
              "lng": -116.10134038194003,
              "alt": 1856.2552800440167,
              "speed2d": 4.645390450482925,
              "speed3d": 4.684197017740956,
              "msFromStart": 50000
            },
            {
              "lat": 43.761936299999995,
              "lng": -116.10132366666667,
              "alt": 1859.582,
              "speed2d": 4.815666666666667,
              "speed3d": 4.843333333333333,
              "msFromStart": 50698.42996491231
            },
            {
              "lat": 43.76192605,
              "lng": -116.10131375,
              "alt": 1863.061,
              "speed2d": 4.868499999999999,
              "speed3d": 4.93,
              "msFromStart": 51256.9909532164
            },
            {
              "lat": 43.76192150497701,
              "lng": -116.10130938260292,
              "alt": 1864.4424231078237,
              "speed2d": 4.870037736224072,
              "speed3d": 4.980120339144647,
              "msFromStart": 51500
            },
            {
              "lat": 43.761904799999996,
              "lng": -116.101295,
              "alt": 1868.77475,
              "speed2d": 4.677,
              "speed3d": 4.7575,
              "msFromStart": 52371.68016666667
            },
            {
              "lat": 43.7618966,
              "lng": -116.1012872,
              "alt": 1870.7894999999999,
              "speed2d": 4.819,
              "speed3d": 4.904999999999999,
              "msFromStart": 52743.6036666667
            },
            {
              "lat": 43.76189299340668,
              "lng": -116.10128453770437,
              "alt": 1872.0591401557688,
              "speed2d": 4.726485639690519,
              "speed3d": 4.841489518116087,
              "msFromStart": 53000
            },
            {
              "lat": 43.76188316147668,
              "lng": -116.10127685392715,
              "alt": 1874.824473743698,
              "speed2d": 4.811585537974569,
              "speed3d": 4.932372904633035,
              "msFromStart": 53500
            },
            {
              "lat": 43.761870566666666,
              "lng": -116.1012671,
              "alt": 1878.5746666666669,
              "speed2d": 4.878,
              "speed3d": 5.0200000000000005,
              "msFromStart": 54165.29155555554
            },
            {
              "lat": 43.761863525,
              "lng": -116.101262775,
              "alt": 1882.4665,
              "speed2d": 4.5912500000000005,
              "speed3d": 4.7725,
              "msFromStart": 54778.2673333333
            },
            {
              "lat": 43.7618581,
              "lng": -116.10125816666667,
              "alt": 1884.7223333333334,
              "speed2d": 4.5103333333333335,
              "speed3d": 4.693333333333333,
              "msFromStart": 55226.86851461986
            },
            {
              "lat": 43.7618528,
              "lng": -116.10125260000001,
              "alt": 1887.4635,
              "speed2d": 4.394,
              "speed3d": 4.58,
              "msFromStart": 55713.06631578945
            },
            {
              "lat": 43.7618431,
              "lng": -116.1012445,
              "alt": 1890.8635,
              "speed2d": 4.381,
              "speed3d": 4.595,
              "msFromStart": 56373.927
            },
            {
              "lat": 43.761838600000004,
              "lng": -116.10124125,
              "alt": 1892.1255,
              "speed2d": 4.4525,
              "speed3d": 4.619999999999999,
              "msFromStart": 56594.38300000001
            },
            {
              "lat": 43.76183045474287,
              "lng": -116.10123587431947,
              "alt": 1894.684617005552,
              "speed2d": 4.525214737416948,
              "speed3d": 4.757852625830527,
              "msFromStart": 57000
            },
            {
              "lat": 43.7618152,
              "lng": -116.1012267,
              "alt": 1899.5774999999999,
              "speed2d": 4.557,
              "speed3d": 4.805,
              "msFromStart": 57777.14622222225
            },
            {
              "lat": 43.761804260000005,
              "lng": -116.10121828000001,
              "alt": 1902.9004,
              "speed2d": 4.6418,
              "speed3d": 4.902,
              "msFromStart": 58271.34888888889
            },
            {
              "lat": 43.76179464285714,
              "lng": -116.1012112,
              "alt": 1905.7157142857143,
              "speed2d": 4.566571428571429,
              "speed3d": 4.85,
              "msFromStart": 58757.592253968236
            },
            {
              "lat": 43.761783666666666,
              "lng": -116.10120313333331,
              "alt": 1908.212,
              "speed2d": 4.608666666666666,
              "speed3d": 4.8933333333333335,
              "msFromStart": 59223.19792592591
            },
            {
              "lat": 43.76177658978778,
              "lng": -116.10119777193866,
              "alt": 1909.610031601127,
              "speed2d": 4.620993222827948,
              "speed3d": 4.905456393774196,
              "msFromStart": 59500
            },
            {
              "lat": 43.76175613333333,
              "lng": -116.10118363333333,
              "alt": 1913.8233333333333,
              "speed2d": 4.491,
              "speed3d": 4.826666666666666,
              "msFromStart": 60323.56674074073
            },
            {
              "lat": 43.7617404,
              "lng": -116.10117373333333,
              "alt": 1916.4183333333335,
              "speed2d": 4.675666666666666,
              "speed3d": 4.99,
              "msFromStart": 60854.90333333332
            },
            {
              "lat": 43.761728899999994,
              "lng": -116.10116646666668,
              "alt": 1918.4616666666666,
              "speed2d": 4.594,
              "speed3d": 4.946666666666667,
              "msFromStart": 61313.33684210526
            },
            {
              "lat": 43.76171836,
              "lng": -116.10115956000001,
              "alt": 1920.4180000000001,
              "speed2d": 4.532,
              "speed3d": 4.8999999999999995,
              "msFromStart": 61757.68959999997
            },
            {
              "lat": 43.76171293713829,
              "lng": -116.10115540517668,
              "alt": 1921.5518720372254,
              "speed2d": 4.502358586592992,
              "speed3d": 4.84794823324124,
              "msFromStart": 62000
            },
            {
              "lat": 43.76168796666666,
              "lng": -116.10113873333333,
              "alt": 1925.3139999999999,
              "speed2d": 4.617666666666667,
              "speed3d": 4.996666666666667,
              "msFromStart": 62891.544000000045
            },
            {
              "lat": 43.761680819999995,
              "lng": -116.10113332,
              "alt": 1926.2164,
              "speed2d": 4.577,
              "speed3d": 4.972,
              "msFromStart": 63177.620533333364
            },
            {
              "lat": 43.76166586,
              "lng": -116.10112316000001,
              "alt": 1928.3654000000001,
              "speed2d": 4.5384,
              "speed3d": 4.925999999999999,
              "msFromStart": 63760.7368
            },
            {
              "lat": 43.76165318333334,
              "lng": -116.10111570000001,
              "alt": 1930.2553333333335,
              "speed2d": 4.495,
              "speed3d": 4.901666666666666,
              "msFromStart": 64239.01396296297
            },
            {
              "lat": 43.761637300000004,
              "lng": -116.10110625,
              "alt": 1931.8110000000001,
              "speed2d": 4.47,
              "speed3d": 4.87,
              "msFromStart": 64842.08977777781
            },
            {
              "lat": 43.76162193333333,
              "lng": -116.1010953,
              "alt": 1933.786,
              "speed2d": 4.338333333333333,
              "speed3d": 4.75,
              "msFromStart": 65419.335157894755
            },
            {
              "lat": 43.76161968389868,
              "lng": -116.10109570051819,
              "alt": 1934.1368902599975,
              "speed2d": 4.328210922851581,
              "speed3d": 4.769081921386861,
              "msFromStart": 65500
            },
            {
              "lat": 43.76160552759326,
              "lng": -116.10108450856731,
              "alt": 1935.6898490643916,
              "speed2d": 4.32579104158256,
              "speed3d": 4.7509328118692045,
              "msFromStart": 66000
            },
            {
              "lat": 43.76159156838264,
              "lng": -116.10107339370155,
              "alt": 1937.2394598000903,
              "speed2d": 4.3045405470869404,
              "speed3d": 4.710843963958146,
              "msFromStart": 66500
            },
            {
              "lat": 43.761570899999995,
              "lng": -116.1010586,
              "alt": 1939.3936666666666,
              "speed2d": 4.423,
              "speed3d": 4.833333333333333,
              "msFromStart": 67182.0528888889
            },
            {
              "lat": 43.76155034999999,
              "lng": -116.10104575,
              "alt": 1941.4085,
              "speed2d": 4.603,
              "speed3d": 4.99,
              "msFromStart": 67786.01999999993
            },
            {
              "lat": 43.76153595,
              "lng": -116.10103475,
              "alt": 1942.9625,
              "speed2d": 4.6955,
              "speed3d": 5.09,
              "msFromStart": 68197.79133333333
            },
            {
              "lat": 43.76152421936868,
              "lng": -116.10102561452652,
              "alt": 1943.8225073813173,
              "speed2d": 4.859974636242777,
              "speed3d": 5.229976942038888,
              "msFromStart": 68500
            },
            {
              "lat": 43.761498,
              "lng": -116.10100595,
              "alt": 1945.812,
              "speed2d": 4.9055,
              "speed3d": 5.275,
              "msFromStart": 69298.21911111113
            },
            {
              "lat": 43.761491133359726,
              "lng": -116.10100113559596,
              "alt": 1946.4359623703785,
              "speed2d": 4.885706787878589,
              "speed3d": 5.2992949225587465,
              "msFromStart": 69500
            },
            {
              "lat": 43.7614758965208,
              "lng": -116.10098895109117,
              "alt": 1948.1439706930275,
              "speed2d": 4.767011597379174,
              "speed3d": 5.291988402620826,
              "msFromStart": 70000
            },
            {
              "lat": 43.761449400000004,
              "lng": -116.10097175,
              "alt": 1949.926,
              "speed2d": 4.862,
              "speed3d": 5.25,
              "msFromStart": 70811.4012631579
            },
            {
              "lat": 43.76143663333333,
              "lng": -116.1009626,
              "alt": 1950.8583333333336,
              "speed2d": 4.874,
              "speed3d": 5.27,
              "msFromStart": 71198.5801609907
            },
            {
              "lat": 43.7614191,
              "lng": -116.100952,
              "alt": 1952.35,
              "speed2d": 4.734,
              "speed3d": 5.15,
              "msFromStart": 71775.52517647058
            },
            {
              "lat": 43.76140363333334,
              "lng": -116.1009426,
              "alt": 1954.2276666666667,
              "speed2d": 4.497999999999999,
              "speed3d": 4.99,
              "msFromStart": 72334.33614035086
            },
            {
              "lat": 43.76140169625584,
              "lng": -116.10094283830689,
              "alt": 1955.0540743571594,
              "speed2d": 4.203619102510154,
              "speed3d": 4.715165493227223,
              "msFromStart": 72500
            },
            {
              "lat": 43.76138460010215,
              "lng": -116.10092921241713,
              "alt": 1956.3979623987977,
              "speed2d": 4.438332369749563,
              "speed3d": 4.875405681493529,
              "msFromStart": 73000
            },
            {
              "lat": 43.76137053967784,
              "lng": -116.10092288328313,
              "alt": 1958.292799589234,
              "speed2d": 4.301154078158982,
              "speed3d": 4.637180260529942,
              "msFromStart": 73500
            },
            {
              "lat": 43.761352099999996,
              "lng": -116.10090905,
              "alt": 1959.556,
              "speed2d": 4.395,
              "speed3d": 4.83,
              "msFromStart": 74084.8806666667
            },
            {
              "lat": 43.761334684535214,
              "lng": -116.10089576808987,
              "alt": 1960.3945678261127,
              "speed2d": 4.748526250978786,
              "speed3d": 5.141427578920264,
              "msFromStart": 74500
            },
            {
              "lat": 43.761321051972175,
              "lng": -116.10088862722353,
              "alt": 1961.6507111350777,
              "speed2d": 4.540791956989751,
              "speed3d": 4.972643465054173,
              "msFromStart": 75000
            },
            {
              "lat": 43.76129978,
              "lng": -116.10087676,
              "alt": 1963.5888,
              "speed2d": 4.3364,
              "speed3d": 4.8,
              "msFromStart": 75739.65324444439
            },
            {
              "lat": 43.76127949999999,
              "lng": -116.10086559999999,
              "alt": 1964.99925,
              "speed2d": 4.4225,
              "speed3d": 4.88,
              "msFromStart": 76340.4330526316
            },
            {
              "lat": 43.761261149999996,
              "lng": -116.100852025,
              "alt": 1966.0317499999999,
              "speed2d": 4.58825,
              "speed3d": 5.0175,
              "msFromStart": 76849.32326315794
            },
            {
              "lat": 43.76124258333333,
              "lng": -116.10083965,
              "alt": 1967.0361666666665,
              "speed2d": 4.771833333333333,
              "speed3d": 5.181666666666667,
              "msFromStart": 77330.62133333333
            },
            {
              "lat": 43.761225814285716,
              "lng": -116.10082978571427,
              "alt": 1967.8249999999998,
              "speed2d": 4.888142857142857,
              "speed3d": 5.3,
              "msFromStart": 77766.54476190472
            },
            {
              "lat": 43.7612118,
              "lng": -116.10082295000001,
              "alt": 1968.6779999999999,
              "speed2d": 4.804,
              "speed3d": 5.245,
              "msFromStart": 78182.65866666666
            },
            {
              "lat": 43.76119465,
              "lng": -116.1008143,
              "alt": 1969.478,
              "speed2d": 4.996,
              "speed3d": 5.32,
              "msFromStart": 78594.32533333337
            },
            {
              "lat": 43.761171475,
              "lng": -116.10080364999999,
              "alt": 1970.692875,
              "speed2d": 5.007750000000001,
              "speed3d": 5.432499999999999,
              "msFromStart": 79198.17111111117
            },
            {
              "lat": 43.76115675,
              "lng": -116.1007946,
              "alt": 1971.7905,
              "speed2d": 4.785500000000001,
              "speed3d": 5.205,
              "msFromStart": 79694.08977777783
            },
            {
              "lat": 43.761133799999996,
              "lng": -116.10077834,
              "alt": 1973.0834000000002,
              "speed2d": 4.849600000000001,
              "speed3d": 5.26,
              "msFromStart": 80338.73137777779
            },
            {
              "lat": 43.761115725,
              "lng": -116.100768,
              "alt": 1974.03575,
              "speed2d": 4.84975,
              "speed3d": 5.262499999999999,
              "msFromStart": 80850.62888888887
            },
            {
              "lat": 43.76111059748347,
              "lng": -116.10076528934809,
              "alt": 1974.3383381056062,
              "speed2d": 4.83673890875664,
              "speed3d": 5.24779585639226,
              "msFromStart": 81000
            },
            {
              "lat": 43.761092665582396,
              "lng": -116.1007545505756,
              "alt": 1975.223487808602,
              "speed2d": 4.874499557231629,
              "speed3d": 5.227855245278411,
              "msFromStart": 81500
            },
            {
              "lat": 43.76106374285714,
              "lng": -116.10074015714285,
              "alt": 1976.8602857142857,
              "speed2d": 4.905857142857143,
              "speed3d": 5.3,
              "msFromStart": 82279.57881035922
            },
            {
              "lat": 43.761051,
              "lng": -116.10073176666667,
              "alt": 1977.3033333333333,
              "speed2d": 4.905,
              "speed3d": 5.306666666666667,
              "msFromStart": 82640.33955555552
            },
            {
              "lat": 43.7610299,
              "lng": -116.10071719999999,
              "alt": 1978.1195,
              "speed2d": 4.811999999999999,
              "speed3d": 5.21,
              "msFromStart": 83271.36733333333
            },
            {
              "lat": 43.76102219502767,
              "lng": -116.10071214414343,
              "alt": 1978.590293489589,
              "speed2d": 4.789688629779433,
              "speed3d": 5.1791814039935025,
              "msFromStart": 83500
            },
            {
              "lat": 43.7610015,
              "lng": -116.10069942499999,
              "alt": 1979.96625,
              "speed2d": 4.64025,
              "speed3d": 5.0575,
              "msFromStart": 84148.48511111112
            },
            {
              "lat": 43.76098398571428,
              "lng": -116.10068841428571,
              "alt": 1980.6477142857143,
              "speed2d": 4.610285714285714,
              "speed3d": 5.022857142857142,
              "msFromStart": 84687.38431746027
            },
            {
              "lat": 43.760964599999994,
              "lng": -116.1006759875,
              "alt": 1981.5217499999999,
              "speed2d": 4.642250000000001,
              "speed3d": 5.048749999999999,
              "msFromStart": 85250.29066666664
            },
            {
              "lat": 43.7609496,
              "lng": -116.10066676666666,
              "alt": 1982.3503333333335,
              "speed2d": 4.587666666666666,
              "speed3d": 5.001666666666667,
              "msFromStart": 85710.02362962965
            },
            {
              "lat": 43.76092895,
              "lng": -116.10065205000001,
              "alt": 1982.8505,
              "speed2d": 4.704,
              "speed3d": 5.085,
              "msFromStart": 86297.82955555555
            },
            {
              "lat": 43.76091136666667,
              "lng": -116.10064020000002,
              "alt": 1983.873,
              "speed2d": 4.5409999999999995,
              "speed3d": 4.96,
              "msFromStart": 86874.09288888889
            },
            {
              "lat": 43.76089975000001,
              "lng": -116.10063315,
              "alt": 1984.5910000000001,
              "speed2d": 4.44475,
              "speed3d": 4.859999999999999,
              "msFromStart": 87258.43491812867
            },
            {
              "lat": 43.76089127149133,
              "lng": -116.10062769219351,
              "alt": 1984.9993247699604,
              "speed2d": 4.482297506914597,
              "speed3d": 4.879208142750976,
              "msFromStart": 87500
            },
            {
              "lat": 43.76086834,
              "lng": -116.10061415999999,
              "alt": 1986.2662,
              "speed2d": 4.4524,
              "speed3d": 4.86,
              "msFromStart": 88181.74721403512
            },
            {
              "lat": 43.76084910000001,
              "lng": -116.100600675,
              "alt": 1987.184,
              "speed2d": 4.554625000000001,
              "speed3d": 4.95625,
              "msFromStart": 88715.38733333338
            },
            {
              "lat": 43.76084544083439,
              "lng": -116.10058935408432,
              "alt": 1987.9019274956188,
              "speed2d": 4.147986093501421,
              "speed3d": 4.606409829843251,
              "msFromStart": 89000
            },
            {
              "lat": 43.76082025903761,
              "lng": -116.10057775653803,
              "alt": 1988.6275084575493,
              "speed2d": 4.8327276400375485,
              "speed3d": 5.144052882264046,
              "msFromStart": 89500
            },
            {
              "lat": 43.760797871225506,
              "lng": -116.10056912206429,
              "alt": 1989.354001214519,
              "speed2d": 4.989253307554568,
              "speed3d": 5.325309412074633,
              "msFromStart": 90000
            },
            {
              "lat": 43.76078508875738,
              "lng": -116.10055918014463,
              "alt": 1990.5095363331866,
              "speed2d": 4.627935541835519,
              "speed3d": 5.018530177030157,
              "msFromStart": 90500
            },
            {
              "lat": 43.76076345,
              "lng": -116.1005425,
              "alt": 1992.5085,
              "speed2d": 3.9965,
              "speed3d": 4.49,
              "msFromStart": 91356.33389473686
            },
            {
              "lat": 43.760749375,
              "lng": -116.100535275,
              "alt": 1993.72775,
              "speed2d": 3.944,
              "speed3d": 4.385,
              "msFromStart": 91824.05221052637
            },
            {
              "lat": 43.760732975,
              "lng": -116.100525275,
              "alt": 1994.1837500000001,
              "speed2d": 4.2044999999999995,
              "speed3d": 4.6025,
              "msFromStart": 92236.72891228073
            },
            {
              "lat": 43.760706666666664,
              "lng": -116.10050723333333,
              "alt": 1994.9733333333334,
              "speed2d": 4.45,
              "speed3d": 4.84,
              "msFromStart": 92924.28977777786
            },
            {
              "lat": 43.760691185714286,
              "lng": -116.10049577142857,
              "alt": 1995.2695714285715,
              "speed2d": 4.622571428571429,
              "speed3d": 4.991428571428572,
              "msFromStart": 93309.40088888891
            },
            {
              "lat": 43.760679249999995,
              "lng": -116.10048772500001,
              "alt": 1995.9912499999998,
              "speed2d": 4.504499999999999,
              "speed3d": 4.9125,
              "msFromStart": 93680.92755555557
            },
            {
              "lat": 43.76067268651386,
              "lng": -116.10048206706783,
              "alt": 1997.1531547277475,
              "speed2d": 4.184699283503207,
              "speed3d": 4.5894641832421454,
              "msFromStart": 94000
            },
            {
              "lat": 43.760637425,
              "lng": -116.100456725,
              "alt": 1997.49575,
              "speed2d": 4.821999999999999,
              "speed3d": 5.180000000000001,
              "msFromStart": 94767.4566666667
            },
            {
              "lat": 43.760623933333335,
              "lng": -116.10044771666666,
              "alt": 1998.3043333333333,
              "speed2d": 4.672666666666666,
              "speed3d": 5.058333333333334,
              "msFromStart": 95170.69466666669
            },
            {
              "lat": 43.7606002,
              "lng": -116.10043345,
              "alt": 1999.6165,
              "speed2d": 4.5255,
              "speed3d": 4.905,
              "msFromStart": 95866.94666666658
            },
            {
              "lat": 43.76058906666667,
              "lng": -116.10042763333334,
              "alt": 2000.0496666666668,
              "speed2d": 4.524333333333333,
              "speed3d": 4.906666666666667,
              "msFromStart": 96169.32987134498
            },
            {
              "lat": 43.760570075000004,
              "lng": -116.10041464999999,
              "alt": 2000.7867499999998,
              "speed2d": 4.57775,
              "speed3d": 4.9325,
              "msFromStart": 96679.7352631579
            },
            {
              "lat": 43.76054211666667,
              "lng": -116.10039725,
              "alt": 2001.5595,
              "speed2d": 4.852333333333333,
              "speed3d": 5.185,
              "msFromStart": 97328.13334984519
            },
            {
              "lat": 43.760524950000004,
              "lng": -116.1003868,
              "alt": 2002.1709999999998,
              "speed2d": 4.85,
              "speed3d": 5.195,
              "msFromStart": 97769.4832941176
            },
            {
              "lat": 43.7604993,
              "lng": -116.10037235,
              "alt": 2003.06,
              "speed2d": 4.7545,
              "speed3d": 5.11,
              "msFromStart": 98457.39410526317
            },
            {
              "lat": 43.760486349999994,
              "lng": -116.100364775,
              "alt": 2003.5955,
              "speed2d": 4.69275,
              "speed3d": 5.0525,
              "msFromStart": 98813.99368421055
            },
            {
              "lat": 43.76047065,
              "lng": -116.1003544,
              "alt": 2004.1215000000002,
              "speed2d": 4.6985,
              "speed3d": 5.025,
              "msFromStart": 99225.448
            },
            {
              "lat": 43.760460617357055,
              "lng": -116.10034744950408,
              "alt": 2004.485064614936,
              "speed2d": 4.683218713535589,
              "speed3d": 5.0174715577596265,
              "msFromStart": 99500
            },
            {
              "lat": 43.76043442,
              "lng": -116.10032934000002,
              "alt": 2005.5202,
              "speed2d": 4.6158,
              "speed3d": 4.958,
              "msFromStart": 100229.23795555555
            },
            {
              "lat": 43.76042387748426,
              "lng": -116.10032128038776,
              "alt": 2005.7911726773555,
              "speed2d": 4.676236879973771,
              "speed3d": 5.002078448952827,
              "msFromStart": 100500
            },
            {
              "lat": 43.760396549999996,
              "lng": -116.10030125,
              "alt": 2006.3933333333332,
              "speed2d": 4.749666666666666,
              "speed3d": 5.076666666666667,
              "msFromStart": 101210.19955555553
            },
            {
              "lat": 43.760376825,
              "lng": -116.10028820000001,
              "alt": 2006.99775,
              "speed2d": 4.80725,
              "speed3d": 5.12,
              "msFromStart": 101709.48799999994
            },
            {
              "lat": 43.7603543875,
              "lng": -116.100275025,
              "alt": 2007.9213750000001,
              "speed2d": 4.776625,
              "speed3d": 5.1175,
              "msFromStart": 102300.71416666664
            },
            {
              "lat": 43.76033966666667,
              "lng": -116.10026711666666,
              "alt": 2008.6401666666666,
              "speed2d": 4.500333333333334,
              "speed3d": 4.876666666666667,
              "msFromStart": 102769.0248888889
            },
            {
              "lat": 43.76032205,
              "lng": -116.10025425,
              "alt": 2009.5974999999999,
              "speed2d": 4.24,
              "speed3d": 4.63,
              "msFromStart": 103347.74088888889
            },
            {
              "lat": 43.76030926,
              "lng": -116.10024546,
              "alt": 2010.0402,
              "speed2d": 4.2224,
              "speed3d": 4.574,
              "msFromStart": 103739.40213333332
            },
            {
              "lat": 43.76029825127091,
              "lng": -116.10023821755617,
              "alt": 2010.386557949751,
              "speed2d": 4.430653943595866,
              "speed3d": 4.717932570505167,
              "msFromStart": 104000
            },
            {
              "lat": 43.7602665,
              "lng": -116.1002129,
              "alt": 2011.8345,
              "speed2d": 4.43,
              "speed3d": 4.745,
              "msFromStart": 104889.05377777782
            },
            {
              "lat": 43.76025975,
              "lng": -116.10020850000001,
              "alt": 2012.0885,
              "speed2d": 4.494,
              "speed3d": 4.82,
              "msFromStart": 105053.76044444452
            },
            {
              "lat": 43.760240955947026,
              "lng": -116.10019771034582,
              "alt": 2012.7506994047646,
              "speed2d": 4.5955540925792455,
              "speed3d": 4.914913784847004,
              "msFromStart": 105500
            },
            {
              "lat": 43.76021977096305,
              "lng": -116.10018554471147,
              "alt": 2013.4893771464408,
              "speed2d": 4.714413738660608,
              "speed3d": 5.016294071210519,
              "msFromStart": 106000
            },
            {
              "lat": 43.7601902,
              "lng": -116.10016785,
              "alt": 2014.4805,
              "speed2d": 4.849500000000001,
              "speed3d": 5.140000000000001,
              "msFromStart": 106704.02000000005
            },
            {
              "lat": 43.760178194647715,
              "lng": -116.10015954448971,
              "alt": 2014.8343030907017,
              "speed2d": 4.865507085365868,
              "speed3d": 5.161566928353667,
              "msFromStart": 107000
            },
            {
              "lat": 43.76015826306042,
              "lng": -116.10014459354171,
              "alt": 2015.3864346381356,
              "speed2d": 4.883738132333454,
              "speed3d": 5.176180172608853,
              "msFromStart": 107500
            },
            {
              "lat": 43.7601283125,
              "lng": -116.100124075,
              "alt": 2016.653625,
              "speed2d": 4.778875,
              "speed3d": 5.10625,
              "msFromStart": 108298.79283333337
            },
            {
              "lat": 43.7601145,
              "lng": -116.10011528,
              "alt": 2017.4363999999998,
              "speed2d": 4.5962,
              "speed3d": 4.92,
              "msFromStart": 108720.93493333338
            },
            {
              "lat": 43.76009335,
              "lng": -116.1001008,
              "alt": 2018.1385,
              "speed2d": 4.7780000000000005,
              "speed3d": 5.075,
              "msFromStart": 109230.8868888889
            },
            {
              "lat": 43.7600723,
              "lng": -116.1000882,
              "alt": 2019.316,
              "speed2d": 4.7635,
              "speed3d": 5.050000000000001,
              "msFromStart": 109782.54466666662
            },
            {
              "lat": 43.76004911428571,
              "lng": -116.10007432857141,
              "alt": 2019.9107142857144,
              "speed2d": 4.9794285714285715,
              "speed3d": 5.268571428571429,
              "msFromStart": 110306.33003174604
            },
            {
              "lat": 43.760034533333325,
              "lng": -116.10006543333334,
              "alt": 2020.858777777778,
              "speed2d": 4.745333333333334,
              "speed3d": 5.085555555555556,
              "msFromStart": 110746.59466666674
            },
            {
              "lat": 43.76002081249999,
              "lng": -116.10005778750002,
              "alt": 2021.7836249999996,
              "speed2d": 4.45425,
              "speed3d": 4.821250000000001,
              "msFromStart": 111214.37384210528
            },
            {
              "lat": 43.760001849999995,
              "lng": -116.1000469,
              "alt": 2023.0194999999999,
              "speed2d": 4.3575,
              "speed3d": 4.68,
              "msFromStart": 111792.34021052628
            },
            {
              "lat": 43.7599829,
              "lng": -116.10003355,
              "alt": 2023.8205,
              "speed2d": 4.4735,
              "speed3d": 4.78,
              "msFromStart": 112287.73319298244
            },
            {
              "lat": 43.75996916666667,
              "lng": -116.1000245,
              "alt": 2024.4759999999999,
              "speed2d": 4.544,
              "speed3d": 4.858333333333333,
              "msFromStart": 112645.48400000007
            },
            {
              "lat": 43.75995534353802,
              "lng": -116.1000136130767,
              "alt": 2025.0731462842741,
              "speed2d": 4.632484396164203,
              "speed3d": 4.94,
              "msFromStart": 113000
            },
            {
              "lat": 43.759923400000005,
              "lng": -116.09999483333334,
              "alt": 2026.496,
              "speed2d": 4.648333333333333,
              "speed3d": 4.963333333333333,
              "msFromStart": 113845.64148148149
            },
            {
              "lat": 43.759906533333336,
              "lng": -116.09998376666667,
              "alt": 2027.2653333333335,
              "speed2d": 4.583333333333334,
              "speed3d": 4.91,
              "msFromStart": 114321.32148148149
            },
            {
              "lat": 43.75989965707406,
              "lng": -116.09997769074789,
              "alt": 2027.5618172687703,
              "speed2d": 4.610746819874459,
              "speed3d": 4.942365073552062,
              "msFromStart": 114500
            },
            {
              "lat": 43.75986975,
              "lng": -116.0999622,
              "alt": 2028.9335,
              "speed2d": 4.447,
              "speed3d": 4.765000000000001,
              "msFromStart": 115363.82133333334
            },
            {
              "lat": 43.75985566666666,
              "lng": -116.0999529,
              "alt": 2029.5003333333332,
              "speed2d": 4.495333333333333,
              "speed3d": 4.8133333333333335,
              "msFromStart": 115730.81985185188
            },
            {
              "lat": 43.75983198,
              "lng": -116.09993605999998,
              "alt": 2030.2494000000002,
              "speed2d": 4.6256,
              "speed3d": 4.928,
              "msFromStart": 116332.4260023392
            },
            {
              "lat": 43.759818075,
              "lng": -116.09992654999999,
              "alt": 2030.7337500000003,
              "speed2d": 4.6165,
              "speed3d": 4.9350000000000005,
              "msFromStart": 116711.62000000005
            },
            {
              "lat": 43.75980725444334,
              "lng": -116.09991860743186,
              "alt": 2031.1347172581206,
              "speed2d": 4.645563809558904,
              "speed3d": 4.937689819409306,
              "msFromStart": 117000
            },
            {
              "lat": 43.75977816666667,
              "lng": -116.0998974,
              "alt": 2032.2746666666667,
              "speed2d": 4.7683333333333335,
              "speed3d": 5.0633333333333335,
              "msFromStart": 117728.85999999999
            },
            {
              "lat": 43.75976600662276,
              "lng": -116.09988936051481,
              "alt": 2032.4758065981305,
              "speed2d": 4.919107079989277,
              "speed3d": 5.128393800160842,
              "msFromStart": 118000
            },
            {
              "lat": 43.7597456064434,
              "lng": -116.09987358951965,
              "alt": 2033.1640573655766,
              "speed2d": 4.918199396814273,
              "speed3d": 5.142009047785889,
              "msFromStart": 118500
            },
            {
              "lat": 43.759725206264044,
              "lng": -116.09985781852448,
              "alt": 2033.8523081330227,
              "speed2d": 4.917291713639271,
              "speed3d": 5.155624295410935,
              "msFromStart": 119000
            },
            {
              "lat": 43.759704806084684,
              "lng": -116.09984204752931,
              "alt": 2034.5405589004688,
              "speed2d": 4.916384030464267,
              "speed3d": 5.1692395430359825,
              "msFromStart": 119500
            },
            {
              "lat": 43.759670500000006,
              "lng": -116.09981565,
              "alt": 2035.6922499999998,
              "speed2d": 4.926,
              "speed3d": 5.205,
              "msFromStart": 120344.70133333335
            },
            {
              "lat": 43.759655099999996,
              "lng": -116.09980518333334,
              "alt": 2036.2455,
              "speed2d": 4.957166666666666,
              "speed3d": 5.2283333333333335,
              "msFromStart": 120728.78666666668
            },
            {
              "lat": 43.759632950000004,
              "lng": -116.09978955000001,
              "alt": 2037.1455,
              "speed2d": 4.8955,
              "speed3d": 5.201666666666666,
              "msFromStart": 121305.2935672515
            },
            {
              "lat": 43.75962246666666,
              "lng": -116.09978206666666,
              "alt": 2037.6716666666666,
              "speed2d": 4.777,
              "speed3d": 5.083333333333334,
              "msFromStart": 121617.18161403513
            },
            {
              "lat": 43.75960224999999,
              "lng": -116.099768675,
              "alt": 2038.78075,
              "speed2d": 4.46825,
              "speed3d": 4.795,
              "msFromStart": 122255.04343034061
            },
            {
              "lat": 43.75958912,
              "lng": -116.09975994,
              "alt": 2039.3887999999997,
              "speed2d": 4.4590000000000005,
              "speed3d": 4.776,
              "msFromStart": 122628.19011764709
            },
            {
              "lat": 43.759574835654355,
              "lng": -116.09975097627535,
              "alt": 2040.0802255497078,
              "speed2d": 4.523836548464806,
              "speed3d": 4.842427582820578,
              "msFromStart": 123000
            },
            {
              "lat": 43.75955644704086,
              "lng": -116.09973930750458,
              "alt": 2040.7474289208387,
              "speed2d": 4.496414886989617,
              "speed3d": 4.794313605744891,
              "msFromStart": 123500
            },
            {
              "lat": 43.759532050000004,
              "lng": -116.0997232,
              "alt": 2041.846,
              "speed2d": 4.378,
              "speed3d": 4.67,
              "msFromStart": 124223.92493567255
            },
            {
              "lat": 43.759521319521134,
              "lng": -116.09971504634743,
              "alt": 2042.1862191559642,
              "speed2d": 4.402574849279053,
              "speed3d": 4.740383232852702,
              "msFromStart": 124500
            },
            {
              "lat": 43.75949093333334,
              "lng": -116.09969419999999,
              "alt": 2043.1973333333333,
              "speed2d": 4.6005,
              "speed3d": 4.890000000000001,
              "msFromStart": 125274.32940740744
            },
            {
              "lat": 43.759482347038166,
              "lng": -116.09968851055487,
              "alt": 2043.408347010432,
              "speed2d": 4.587690799638313,
              "speed3d": 4.879060292819289,
              "msFromStart": 125500
            },
            {
              "lat": 43.759465498018635,
              "lng": -116.09967927713433,
              "alt": 2044.0767835229517,
              "speed2d": 4.420778966819057,
              "speed3d": 4.729115856716884,
              "msFromStart": 126000
            },
            {
              "lat": 43.75944115,
              "lng": -116.09966615,
              "alt": 2045.058,
              "speed2d": 4.1675,
              "speed3d": 4.51,
              "msFromStart": 126724.888
            },
            {
              "lat": 43.759421625,
              "lng": -116.09965685,
              "alt": 2045.9724999999999,
              "speed2d": 4.13875,
              "speed3d": 4.4325,
              "msFromStart": 127303.57902339182
            },
            {
              "lat": 43.759410933333335,
              "lng": -116.0996504,
              "alt": 2046.38,
              "speed2d": 4.346333333333333,
              "speed3d": 4.603333333333333,
              "msFromStart": 127577.74133333334
            },
            {
              "lat": 43.75938685,
              "lng": -116.0996305,
              "alt": 2048.0365,
              "speed2d": 3.9639999999999995,
              "speed3d": 4.215,
              "msFromStart": 128372.97911111111
            },
            {
              "lat": 43.75937438,
              "lng": -116.09962390000001,
              "alt": 2048.4508,
              "speed2d": 4.556,
              "speed3d": 4.772,
              "msFromStart": 128642.3832888889
            },
            {
              "lat": 43.759357999669845,
              "lng": -116.09961533033699,
              "alt": 2048.965569189646,
              "speed2d": 4.949194154032211,
              "speed3d": 5.1130557084041,
              "msFromStart": 129000
            },
            {
              "lat": 43.75933765285474,
              "lng": -116.09960314953024,
              "alt": 2049.766638159085,
              "speed2d": 4.704031558542267,
              "speed3d": 4.989889183251491,
              "msFromStart": 129500
            },
            {
              "lat": 43.7593061,
              "lng": -116.09957975,
              "alt": 2051.1440000000002,
              "speed2d": 5.128,
              "speed3d": 5.32,
              "msFromStart": 130243.22222222219
            },
            {
              "lat": 43.7592817,
              "lng": -116.09956220000001,
              "alt": 2052.1440000000002,
              "speed2d": 5.1290000000000004,
              "speed3d": 5.38,
              "msFromStart": 130821.68622222217
            },
            {
              "lat": 43.7592665,
              "lng": -116.09955015,
              "alt": 2052.488,
              "speed2d": 5.35375,
              "speed3d": 5.5775,
              "msFromStart": 131165.8917543859
            },
            {
              "lat": 43.759240311111114,
              "lng": -116.09952595555556,
              "alt": 2053.203666666667,
              "speed2d": 5.512,
              "speed3d": 5.772222222222222,
              "msFromStart": 131756.45094736834
            },
            {
              "lat": 43.759220955555556,
              "lng": -116.09951328888887,
              "alt": 2054.112,
              "speed2d": 5.061222222222222,
              "speed3d": 5.394444444444444,
              "msFromStart": 132250.80085769974
            },
            {
              "lat": 43.75920836666667,
              "lng": -116.09950785555554,
              "alt": 2055.3226666666665,
              "speed2d": 3.692333333333333,
              "speed3d": 4.173333333333333,
              "msFromStart": 132746.13199999998
            },
            {
              "lat": 43.759191425,
              "lng": -116.09949785,
              "alt": 2056.30875,
              "speed2d": 3.9952500000000004,
              "speed3d": 4.255000000000001,
              "msFromStart": 133268.99577777775
            },
            {
              "lat": 43.75918173402804,
              "lng": -116.09949116041683,
              "alt": 2056.650812235492,
              "speed2d": 4.471567069610992,
              "speed3d": 4.753048385662192,
              "msFromStart": 133500
            },
            {
              "lat": 43.759162933333336,
              "lng": -116.0994795,
              "alt": 2058.7010000000005,
              "speed2d": 3.283333333333333,
              "speed3d": 3.7766666666666664,
              "msFromStart": 134231.05733333333
            },
            {
              "lat": 43.75914525,
              "lng": -116.09946755,
              "alt": 2059.2725,
              "speed2d": 3.9574999999999996,
              "speed3d": 4.244999999999999,
              "msFromStart": 134726.14399999994
            },
            {
              "lat": 43.75913492110283,
              "lng": -116.0994607067854,
              "alt": 2059.4534034404996,
              "speed2d": 4.1687613761998055,
              "speed3d": 4.439761376199805,
              "msFromStart": 135000
            },
            {
              "lat": 43.759108749999996,
              "lng": -116.0994426,
              "alt": 2059.9485000000004,
              "speed2d": 4.285,
              "speed3d": 4.525,
              "msFromStart": 135717.9919999999
            },
            {
              "lat": 43.75908385714286,
              "lng": -116.09942528571426,
              "alt": 2061.080571428571,
              "speed2d": 4.796,
              "speed3d": 5.051428571428572,
              "msFromStart": 136324.41607351712
            },
            {
              "lat": 43.75907015,
              "lng": -116.09941655,
              "alt": 2061.693,
              "speed2d": 4.7105,
              "speed3d": 4.995,
              "msFromStart": 136681.16968421056
            },
            {
              "lat": 43.759058416396854,
              "lng": -116.09941007178014,
              "alt": 2062.0234576328644,
              "speed2d": 4.615078892620111,
              "speed3d": 4.863355808999194,
              "msFromStart": 137000
            },
            {
              "lat": 43.75903269999999,
              "lng": -116.09939705,
              "alt": 2062.37275,
              "speed2d": 4.328749999999999,
              "speed3d": 4.5725,
              "msFromStart": 137725.14888888877
            },
            {
              "lat": 43.75901256666666,
              "lng": -116.09938243333333,
              "alt": 2062.4696666666664,
              "speed2d": 4.719666666666667,
              "speed3d": 4.866666666666667,
              "msFromStart": 138229.63999999984
            },
            {
              "lat": 43.75900187551663,
              "lng": -116.09937574487248,
              "alt": 2062.930477229831,
              "speed2d": 4.257387117069207,
              "speed3d": 4.606716836858131,
              "msFromStart": 138500
            },
            {
              "lat": 43.758972420000006,
              "lng": -116.09935635999999,
              "alt": 2064.6582000000003,
              "speed2d": 4.2222,
              "speed3d": 4.536,
              "msFromStart": 139349.77955555555
            },
            {
              "lat": 43.75896414,
              "lng": -116.09935174,
              "alt": 2065.1976,
              "speed2d": 3.9214,
              "speed3d": 4.18,
              "msFromStart": 139646.83235555553
            },
            {
              "lat": 43.75893585,
              "lng": -116.0993344,
              "alt": 2065.62,
              "speed2d": 4.083,
              "speed3d": 4.455,
              "msFromStart": 140421.77022222226
            },
            {
              "lat": 43.75892813333333,
              "lng": -116.09933016666666,
              "alt": 2066.2861666666668,
              "speed2d": 3.788666666666667,
              "speed3d": 4.086666666666667,
              "msFromStart": 140695.81911111117
            },
            {
              "lat": 43.75890932,
              "lng": -116.09931664000001,
              "alt": 2067.2542,
              "speed2d": 4.3022,
              "speed3d": 4.496,
              "msFromStart": 141205.8476350878
            },
            {
              "lat": 43.758881466666665,
              "lng": -116.09929576666667,
              "alt": 2068.62,
              "speed2d": 4.6530000000000005,
              "speed3d": 4.859999999999999,
              "msFromStart": 141932.90778947354
            },
            {
              "lat": 43.7588739,
              "lng": -116.0992917,
              "alt": 2068.9655000000002,
              "speed2d": 4.64725,
              "speed3d": 4.9025,
              "msFromStart": 142125.7835789472
            },
            {
              "lat": 43.7588483,
              "lng": -116.09927440000001,
              "alt": 2069.64,
              "speed2d": 4.68375,
              "speed3d": 4.93,
              "msFromStart": 142787.38622222212
            },
            {
              "lat": 43.7588288,
              "lng": -116.0992633,
              "alt": 2070.0525,
              "speed2d": 4.2235,
              "speed3d": 4.505,
              "msFromStart": 143283.4982222222
            },
            {
              "lat": 43.75880518571429,
              "lng": -116.09925347142857,
              "alt": 2069.562857142857,
              "speed2d": 4.418571428571429,
              "speed3d": 4.731428571428571,
              "msFromStart": 143788.77688888877
            },
            {
              "lat": 43.7587904,
              "lng": -116.09924723333333,
              "alt": 2069.084,
              "speed2d": 3.958,
              "speed3d": 4.303333333333334,
              "msFromStart": 144151.81777777764
            },
            {
              "lat": 43.75877339458018,
              "lng": -116.09923586682336,
              "alt": 2068.6396928835543,
              "speed2d": 4.291136996583255,
              "speed3d": 4.367945051251176,
              "msFromStart": 144500
            },
            {
              "lat": 43.75875118361,
              "lng": -116.09922002235972,
              "alt": 2068.2289814091628,
              "speed2d": 4.287307518966552,
              "speed3d": 4.425387215501725,
              "msFromStart": 145000
            },
            {
              "lat": 43.7587226,
              "lng": -116.09919935,
              "alt": 2067.7345,
              "speed2d": 4.33625,
              "speed3d": 4.547499999999999,
              "msFromStart": 145644.96000000005
            },
            {
              "lat": 43.75870770638119,
              "lng": -116.09918906047594,
              "alt": 2067.653749884616,
              "speed2d": 4.130250816918045,
              "speed3d": 4.414094144036032,
              "msFromStart": 146000
            },
            {
              "lat": 43.75868771611959,
              "lng": -116.09917590671073,
              "alt": 2068.1660544242804,
              "speed2d": 4.2531346760943,
              "speed3d": 4.539574141082207,
              "msFromStart": 146500
            },
            {
              "lat": 43.75866705867222,
              "lng": -116.09916123422546,
              "alt": 2070.427524968455,
              "speed2d": 4.639405779869964,
              "speed3d": 5.069828690672661,
              "msFromStart": 147000
            },
            {
              "lat": 43.758640099999994,
              "lng": -116.099145375,
              "alt": 2068.0470000000005,
              "speed2d": 4.2692499999999995,
              "speed3d": 4.4975000000000005,
              "msFromStart": 147708.0266666666
            },
            {
              "lat": 43.75862957455391,
              "lng": -116.09913953582779,
              "alt": 2067.7529058440773,
              "speed2d": 4.08977206748778,
              "speed3d": 4.257664977431259,
              "msFromStart": 148000
            },
            {
              "lat": 43.758604,
              "lng": -116.0991271,
              "alt": 2067.951,
              "speed2d": 3.6825,
              "speed3d": 3.825,
              "msFromStart": 148753.22399999996
            },
            {
              "lat": 43.75858195,
              "lng": -116.09910984999999,
              "alt": 2067.7135,
              "speed2d": 4.404999999999999,
              "speed3d": 4.445,
              "msFromStart": 149248.832
            },
            {
              "lat": 43.75856980059027,
              "lng": -116.09910037854549,
              "alt": 2067.8007736494396,
              "speed2d": 4.68150825120119,
              "speed3d": 4.717776734015033,
              "msFromStart": 149500
            },
            {
              "lat": 43.758548187963996,
              "lng": -116.09908612893818,
              "alt": 2068.0905301019466,
              "speed2d": 4.562833710114583,
              "speed3d": 4.674464857706052,
              "msFromStart": 150000
            },
            {
              "lat": 43.75851973333334,
              "lng": -116.09906699999999,
              "alt": 2068.5046666666667,
              "speed2d": 4.579666666666667,
              "speed3d": 4.736666666666666,
              "msFromStart": 150641.50459259265
            },
            {
              "lat": 43.75850186585269,
              "lng": -116.09905423756642,
              "alt": 2068.9612828628283,
              "speed2d": 4.908310469921936,
              "speed3d": 5.151414314141396,
              "msFromStart": 151000
            },
            {
              "lat": 43.7584717,
              "lng": -116.09903255,
              "alt": 2068.8125,
              "speed2d": 4.7485,
              "speed3d": 4.865,
              "msFromStart": 151722.6686315789
            },
            {
              "lat": 43.7584429375,
              "lng": -116.0990099125,
              "alt": 2068.2104999999997,
              "speed2d": 5.27275,
              "speed3d": 5.465,
              "msFromStart": 152300.3671695906
            },
            {
              "lat": 43.7584255,
              "lng": -116.0989974,
              "alt": 2069.1025,
              "speed2d": 4.802,
              "speed3d": 4.975,
              "msFromStart": 152740.7391111111
            },
            {
              "lat": 43.758400275,
              "lng": -116.098978125,
              "alt": 2069.2725,
              "speed2d": 4.781000000000001,
              "speed3d": 4.97,
              "msFromStart": 153359.94022222224
            },
            {
              "lat": 43.758384516666666,
              "lng": -116.09896776666665,
              "alt": 2069.1613333333335,
              "speed2d": 4.694166666666667,
              "speed3d": 4.92,
              "msFromStart": 153776.92918518526
            },
            {
              "lat": 43.758378901972726,
              "lng": -116.09896363539859,
              "alt": 2069.4565448983208,
              "speed2d": 4.0444035197320005,
              "speed3d": 4.279259149532199,
              "msFromStart": 154000
            },
            {
              "lat": 43.75836021568743,
              "lng": -116.09894238586311,
              "alt": 2069.2735735907386,
              "speed2d": 4.388542096704128,
              "speed3d": 3.8570175664579565,
              "msFromStart": 154500
            },
            {
              "lat": 43.75831815,
              "lng": -116.0989275,
              "alt": 2067.2368333333334,
              "speed2d": 5.873333333333333,
              "speed3d": 6.051666666666667,
              "msFromStart": 155237.81970370366
            },
            {
              "lat": 43.75830500842695,
              "lng": -116.09892059761309,
              "alt": 2066.722547563242,
              "speed2d": 5.878992052587545,
              "speed3d": 5.946524421455241,
              "msFromStart": 155500
            },
            {
              "lat": 43.75827820000001,
              "lng": -116.0989119,
              "alt": 2066.2001666666665,
              "speed2d": 4.470166666666667,
              "speed3d": 4.666666666666667,
              "msFromStart": 156209.41941520458
            },
            {
              "lat": 43.75825558333333,
              "lng": -116.09889923333333,
              "alt": 2066.759833333333,
              "speed2d": 4.395,
              "speed3d": 4.486666666666666,
              "msFromStart": 156793.57403508775
            },
            {
              "lat": 43.758242149999994,
              "lng": -116.098891975,
              "alt": 2067.13825,
              "speed2d": 3.9913749999999997,
              "speed3d": 4.2212499999999995,
              "msFromStart": 157195.15585964912
            },
            {
              "lat": 43.7582241,
              "lng": -116.0988815,
              "alt": 2067.5455,
              "speed2d": 4.0265,
              "speed3d": 4.105,
              "msFromStart": 157718.19377777784
            },
            {
              "lat": 43.758213303034665,
              "lng": -116.0988716811416,
              "alt": 2067.6457507228583,
              "speed2d": 4.221151011463433,
              "speed3d": 4.319320809170748,
              "msFromStart": 158000
            },
            {
              "lat": 43.758183700000004,
              "lng": -116.0988536,
              "alt": 2068.0225,
              "speed2d": 4.066000000000001,
              "speed3d": 4.225,
              "msFromStart": 158847.33466666663
            },
            {
              "lat": 43.758160024999995,
              "lng": -116.098839225,
              "alt": 2067.9647499999996,
              "speed2d": 4.985,
              "speed3d": 4.902500000000001,
              "msFromStart": 159342.4942222222
            },
            {
              "lat": 43.75815142853955,
              "lng": -116.09883396502754,
              "alt": 2067.8550878001456,
              "speed2d": 5.3362155226776515,
              "speed3d": 5.284622916738843,
              "msFromStart": 159500
            },
            {
              "lat": 43.7581336821321,
              "lng": -116.09881340362325,
              "alt": 2067.5012532377827,
              "speed2d": 4.461922956468325,
              "speed3d": 4.437488554928301,
              "msFromStart": 160000
            },
            {
              "lat": 43.75810328,
              "lng": -116.09879466000001,
              "alt": 2068.4312,
              "speed2d": 4.9104,
              "speed3d": 5.016000000000001,
              "msFromStart": 160700.14524444452
            },
            {
              "lat": 43.75808999926883,
              "lng": -116.0987855444023,
              "alt": 2068.6085232113487,
              "speed2d": 5.107528316611868,
              "speed3d": 5.105314893092127,
              "msFromStart": 161000
            },
            {
              "lat": 43.7580636,
              "lng": -116.0987677,
              "alt": 2069.4195,
              "speed2d": 4.5355,
              "speed3d": 4.605,
              "msFromStart": 161706.82357894746
            },
            {
              "lat": 43.75804516666667,
              "lng": -116.09875600000001,
              "alt": 2070.192666666667,
              "speed2d": 4.625,
              "speed3d": 4.696666666666666,
              "msFromStart": 162193.36729824572
            },
            {
              "lat": 43.758032855587416,
              "lng": -116.09874783015225,
              "alt": 2070.3905251807596,
              "speed2d": 4.765321029039661,
              "speed3d": 4.812020516136254,
              "msFromStart": 162500
            },
            {
              "lat": 43.758004549999995,
              "lng": -116.09872855,
              "alt": 2071.0699999999997,
              "speed2d": 4.814749999999999,
              "speed3d": 4.9,
              "msFromStart": 163220.06355555548
            },
            {
              "lat": 43.75797703333333,
              "lng": -116.09871056666665,
              "alt": 2071.342666666667,
              "speed2d": 4.793,
              "speed3d": 4.890000000000001,
              "msFromStart": 163935.3493333333
            },
            {
              "lat": 43.7579662,
              "lng": -116.0987044,
              "alt": 2071.527,
              "speed2d": 4.6080000000000005,
              "speed3d": 4.705,
              "msFromStart": 164238.00444444444
            },
            {
              "lat": 43.7579456,
              "lng": -116.09869043333333,
              "alt": 2072.1986666666667,
              "speed2d": 4.453666666666667,
              "speed3d": 4.516666666666667,
              "msFromStart": 164797.45303703716
            },
            {
              "lat": 43.757929575,
              "lng": -116.0986808,
              "alt": 2072.47475,
              "speed2d": 4.724499999999999,
              "speed3d": 4.7625,
              "msFromStart": 165187.13911111126
            },
            {
              "lat": 43.7579162,
              "lng": -116.09867305,
              "alt": 2072.866,
              "speed2d": 4.4415,
              "speed3d": 4.625,
              "msFromStart": 165558.15911111113
            },
            {
              "lat": 43.757888675,
              "lng": -116.098655725,
              "alt": 2073.5160000000005,
              "speed2d": 4.616,
              "speed3d": 4.7075,
              "msFromStart": 166272.4150643275
            },
            {
              "lat": 43.7578757,
              "lng": -116.0986474,
              "alt": 2073.401,
              "speed2d": 4.2915,
              "speed3d": 4.42,
              "msFromStart": 166657.85642105265
            },
            {
              "lat": 43.757858033333335,
              "lng": -116.09864026666666,
              "alt": 2075.2236666666668,
              "speed2d": 3.527,
              "speed3d": 3.6466666666666665,
              "msFromStart": 167309.2794697856
            },
            {
              "lat": 43.757844750000004,
              "lng": -116.0986316,
              "alt": 2075.992,
              "speed2d": 4.275,
              "speed3d": 4.305,
              "msFromStart": 167648.04088888888
            },
            {
              "lat": 43.757811833333335,
              "lng": -116.0986083,
              "alt": 2076.543666666667,
              "speed2d": 5.004333333333333,
              "speed3d": 5.066666666666666,
              "msFromStart": 168426.2376296296
            },
            {
              "lat": 43.7578044,
              "lng": -116.09860336666668,
              "alt": 2076.813666666667,
              "speed2d": 5.0136666666666665,
              "speed3d": 5.15,
              "msFromStart": 168609.67466666666
            },
            {
              "lat": 43.75778195,
              "lng": -116.09859069999999,
              "alt": 2078.2169999999996,
              "speed2d": 4.2524999999999995,
              "speed3d": 4.325,
              "msFromStart": 169297.5768888889
            },
            {
              "lat": 43.7577628,
              "lng": -116.09857834,
              "alt": 2079.3116,
              "speed2d": 4.526400000000001,
              "speed3d": 4.69,
              "msFromStart": 169798.60266666673
            },
            {
              "lat": 43.75775620478885,
              "lng": -116.09857481565638,
              "alt": 2080.097508216679,
              "speed2d": 4.403717308213422,
              "speed3d": 4.611912991777241,
              "msFromStart": 170000
            },
            {
              "lat": 43.757725225,
              "lng": -116.09855457500001,
              "alt": 2082.242,
              "speed2d": 4.7585,
              "speed3d": 5.012499999999999,
              "msFromStart": 170796.4020000001
            },
            {
              "lat": 43.757707749999994,
              "lng": -116.098544875,
              "alt": 2083.29775,
              "speed2d": 4.451,
              "speed3d": 4.7275,
              "msFromStart": 171290.8821052632
            },
            {
              "lat": 43.7576901,
              "lng": -116.09853296666668,
              "alt": 2084.422666666667,
              "speed2d": 4.58,
              "speed3d": 4.826666666666666,
              "msFromStart": 171763.63256140344
            },
            {
              "lat": 43.757677900000004,
              "lng": -116.0985259,
              "alt": 2085.345333333333,
              "speed2d": 4.442,
              "speed3d": 4.760000000000001,
              "msFromStart": 172112.60322807007
            },
            {
              "lat": 43.75765915,
              "lng": -116.098517225,
              "alt": 2086.55175,
              "speed2d": 4.484500000000001,
              "speed3d": 4.789999999999999,
              "msFromStart": 172598.73777777771
            },
            {
              "lat": 43.757629525,
              "lng": -116.098501075,
              "alt": 2087.9804999999997,
              "speed2d": 4.70925,
              "speed3d": 4.93,
              "msFromStart": 173368.72400000002
            },
            {
              "lat": 43.7576088,
              "lng": -116.09848553333333,
              "alt": 2088.407666666667,
              "speed2d": 5.404,
              "speed3d": 5.463333333333334,
              "msFromStart": 173854.9342222223
            },
            {
              "lat": 43.757593525,
              "lng": -116.09847476249999,
              "alt": 2088.8641249999996,
              "speed2d": 5.279375,
              "speed3d": 5.55375,
              "msFromStart": 174221.7811111112
            },
            {
              "lat": 43.757585369620806,
              "lng": -116.09847001697551,
              "alt": 2089.753654339769,
              "speed2d": 4.551210280146961,
              "speed3d": 4.8220909870689015,
              "msFromStart": 174500
            },
            {
              "lat": 43.75755615,
              "lng": -116.098450025,
              "alt": 2090.2877500000004,
              "speed2d": 5.14025,
              "speed3d": 5.2625,
              "msFromStart": 175251.93777777776
            },
            {
              "lat": 43.75753284,
              "lng": -116.09843286,
              "alt": 2091.2731999999996,
              "speed2d": 5.031,
              "speed3d": 5.1979999999999995,
              "msFromStart": 175870.73777777792
            },
            {
              "lat": 43.75751555,
              "lng": -116.0984231,
              "alt": 2092.2195,
              "speed2d": 5.0165,
              "speed3d": 5.12,
              "msFromStart": 176338.46905263158
            },
            {
              "lat": 43.75749973999999,
              "lng": -116.0984133,
              "alt": 2093.0502,
              "speed2d": 4.8826,
              "speed3d": 5.022,
              "msFromStart": 176762.5493894738
            },
            {
              "lat": 43.75748385,
              "lng": -116.09840175,
              "alt": 2093.6645,
              "speed2d": 4.9190000000000005,
              "speed3d": 5.015000000000001,
              "msFromStart": 177192.137263158
            },
            {
              "lat": 43.75747158825869,
              "lng": -116.09839307213751,
              "alt": 2094.410314266755,
              "speed2d": 5.105799067765062,
              "speed3d": 5.121997669412655,
              "msFromStart": 177500
            },
            {
              "lat": 43.75744486000001,
              "lng": -116.09837912,
              "alt": 2095.1216,
              "speed2d": 4.9298,
              "speed3d": 5.148,
              "msFromStart": 178181.0364444445
            },
            {
              "lat": 43.75743532018529,
              "lng": -116.09836984630405,
              "alt": 2095.5401190018883,
              "speed2d": 4.5522187646271055,
              "speed3d": 4.619711638717202,
              "msFromStart": 178500
            },
            {
              "lat": 43.757401099999996,
              "lng": -116.09834565,
              "alt": 2096.6385,
              "speed2d": 4.967499999999999,
              "speed3d": 5.039999999999999,
              "msFromStart": 179364.68933333334
            },
            {
              "lat": 43.75738768888888,
              "lng": -116.09833503333333,
              "alt": 2097.1185555555558,
              "speed2d": 4.962888888888889,
              "speed3d": 5.083333333333333,
              "msFromStart": 179722.17777777783
            },
            {
              "lat": 43.75737842894097,
              "lng": -116.09832818731286,
              "alt": 2097.2917097725776,
              "speed2d": 4.706491969184273,
              "speed3d": 4.819191397452098,
              "msFromStart": 180000
            },
            {
              "lat": 43.757352875,
              "lng": -116.0983096,
              "alt": 2098.11225,
              "speed2d": 4.2485,
              "speed3d": 4.4,
              "msFromStart": 180794.1871111111
            },
            {
              "lat": 43.75733833333333,
              "lng": -116.09830303333334,
              "alt": 2098.616666666667,
              "speed2d": 4.435333333333333,
              "speed3d": 4.46,
              "msFromStart": 181206.3131851852
            },
            {
              "lat": 43.7573163,
              "lng": -116.09828765,
              "alt": 2098.767,
              "speed2d": 4.893000000000001,
              "speed3d": 4.9350000000000005,
              "msFromStart": 181728.33333333326
            },
            {
              "lat": 43.7573079973836,
              "lng": -116.09827984349965,
              "alt": 2099.4958748942718,
              "speed2d": 4.404810302952558,
              "speed3d": 4.541856092644829,
              "msFromStart": 182000
            },
            {
              "lat": 43.757279,
              "lng": -116.0982622,
              "alt": 2100.4615,
              "speed2d": 4.3885000000000005,
              "speed3d": 4.41,
              "msFromStart": 182831.2871578947
            },
            {
              "lat": 43.75726795,
              "lng": -116.09825570000001,
              "alt": 2101.0575,
              "speed2d": 4.6365,
              "speed3d": 4.695,
              "msFromStart": 183107.92294736835
            },
            {
              "lat": 43.75723908,
              "lng": -116.09823744,
              "alt": 2102.284,
              "speed2d": 4.9574,
              "speed3d": 5.014,
              "msFromStart": 183829.59360000002
            },
            {
              "lat": 43.75722569999999,
              "lng": -116.0982302,
              "alt": 2102.8784,
              "speed2d": 4.909999999999999,
              "speed3d": 5.018,
              "msFromStart": 184170.59946666664
            },
            {
              "lat": 43.757212853101024,
              "lng": -116.09822328693588,
              "alt": 2103.6805676987274,
              "speed2d": 4.456815781811112,
              "speed3d": 3.929614675337309,
              "msFromStart": 184500
            },
            {
              "lat": 43.757192100000005,
              "lng": -116.0982112,
              "alt": 2104.9156000000003,
              "speed2d": 4.1734,
              "speed3d": 4.372,
              "msFromStart": 185159.90026666655
            },
            {
              "lat": 43.75716921666666,
              "lng": -116.09819440000001,
              "alt": 2106.141833333333,
              "speed2d": 4.296833333333333,
              "speed3d": 4.45,
              "msFromStart": 185804.06533333322
            },
            {
              "lat": 43.757150550000006,
              "lng": -116.098184,
              "alt": 2106.5609999999997,
              "speed2d": 4.897,
              "speed3d": 4.904999999999999,
              "msFromStart": 186244.74222222215
            },
            {
              "lat": 43.7571037,
              "lng": -116.0981652,
              "alt": 2105.875,
              "speed2d": 7.364333333333333,
              "speed3d": 7.416666666666666,
              "msFromStart": 186932.2613333332
            },
            {
              "lat": 43.75708764444445,
              "lng": -116.09815475555558,
              "alt": 2105.4809999999998,
              "speed2d": 6.639333333333335,
              "speed3d": 6.8022222222222215,
              "msFromStart": 187261.91138141643
            },
            {
              "lat": 43.757072525,
              "lng": -116.098144525,
              "alt": 2105.757,
              "speed2d": 5.71775,
              "speed3d": 5.8575,
              "msFromStart": 187645.21094736835
            },
            {
              "lat": 43.757058362433916,
              "lng": -116.09813505311347,
              "alt": 2106.0140158084137,
              "speed2d": 5.534294740464797,
              "speed3d": 5.450899630925756,
              "msFromStart": 188000
            },
            {
              "lat": 43.75704016683822,
              "lng": -116.09812486051125,
              "alt": 2106.436128564057,
              "speed2d": 4.914777880684314,
              "speed3d": 4.897554411823092,
              "msFromStart": 188500
            },
            {
              "lat": 43.75701675979817,
              "lng": -116.09810752754149,
              "alt": 2106.2603537115506,
              "speed2d": 5.538915502797808,
              "speed3d": 5.421972604068955,
              "msFromStart": 189000
            },
            {
              "lat": 43.757000755989075,
              "lng": -116.09809867249318,
              "alt": 2106.4866131485683,
              "speed2d": 4.185341028283088,
              "speed3d": 4.514566697009041,
              "msFromStart": 189500
            },
            {
              "lat": 43.756971666666665,
              "lng": -116.09808016666666,
              "alt": 2108.173666666667,
              "speed2d": 4.669333333333333,
              "speed3d": 4.66,
              "msFromStart": 190280.6254814815
            },
            {
              "lat": 43.75696966740987,
              "lng": -116.0980777963443,
              "alt": 2109.2305463660055,
              "speed2d": 3.285428659331184,
              "speed3d": 3.572612566961105,
              "msFromStart": 190500
            },
            {
              "lat": 43.7569458474379,
              "lng": -116.09806099000045,
              "alt": 2109.1319793785556,
              "speed2d": 4.987911070735313,
              "speed3d": 4.823763832821748,
              "msFromStart": 191000
            },
            {
              "lat": 43.75692891220528,
              "lng": -116.0980503250913,
              "alt": 2109.5709020214617,
              "speed2d": 4.619730538773799,
              "speed3d": 4.496849532244567,
              "msFromStart": 191500
            },
            {
              "lat": 43.756903300000005,
              "lng": -116.098034,
              "alt": 2110.216,
              "speed2d": 4.2785,
              "speed3d": 4.245,
              "msFromStart": 192235.21679532173
            },
            {
              "lat": 43.75687905,
              "lng": -116.09801815,
              "alt": 2110.9345000000003,
              "speed2d": 4.4435,
              "speed3d": 4.485,
              "msFromStart": 192895.37473684212
            },
            {
              "lat": 43.75686372,
              "lng": -116.09800579999998,
              "alt": 2111.0264,
              "speed2d": 5.094799999999999,
              "speed3d": 5.032,
              "msFromStart": 193253.0388023392
            },
            {
              "lat": 43.75684165,
              "lng": -116.09798922499999,
              "alt": 2111.5145,
              "speed2d": 4.70225,
              "speed3d": 4.8549999999999995,
              "msFromStart": 193830.1831111111
            },
            {
              "lat": 43.7568303,
              "lng": -116.0979816,
              "alt": 2112.003,
              "speed2d": 4.672666666666667,
              "speed3d": 4.663333333333333,
              "msFromStart": 194150.77362962964
            },
            {
              "lat": 43.7568169552275,
              "lng": -116.09797238696504,
              "alt": 2112.3030159131918,
              "speed2d": 4.853193661524397,
              "speed3d": 4.776336673467088,
              "msFromStart": 194500
            },
            {
              "lat": 43.75678965,
              "lng": -116.0979533,
              "alt": 2112.7685,
              "speed2d": 4.599,
              "speed3d": 4.535,
              "msFromStart": 195259.3586666667
            },
            {
              "lat": 43.75678015892625,
              "lng": -116.0979461458688,
              "alt": 2112.843541871207,
              "speed2d": 4.7927293440212155,
              "speed3d": 4.720664056836309,
              "msFromStart": 195500
            },
            {
              "lat": 43.7567543,
              "lng": -116.09792675,
              "alt": 2113.0370000000003,
              "speed2d": 4.692,
              "speed3d": 4.695,
              "msFromStart": 196197.0280000001
            },
            {
              "lat": 43.756737275,
              "lng": -116.09791527499999,
              "alt": 2113.364,
              "speed2d": 4.53375,
              "speed3d": 4.5575,
              "msFromStart": 196692.50533333328
            },
            {
              "lat": 43.75672614305131,
              "lng": -116.09790797329963,
              "alt": 2113.6286574741016,
              "speed2d": 4.569916674889217,
              "speed3d": 4.583379625061546,
              "msFromStart": 197000
            },
            {
              "lat": 43.756697875,
              "lng": -116.097888425,
              "alt": 2113.7847500000003,
              "speed2d": 4.772,
              "speed3d": 4.795,
              "msFromStart": 197764.96673684215
            },
            {
              "lat": 43.756680599999996,
              "lng": -116.0978784,
              "alt": 2114.016333333333,
              "speed2d": 4.4910000000000005,
              "speed3d": 4.553333333333333,
              "msFromStart": 198259.62423391815
            },
            {
              "lat": 43.7566659,
              "lng": -116.09786965,
              "alt": 2114.322,
              "speed2d": 4.5695,
              "speed3d": 4.545,
              "msFromStart": 198644.31999999995
            },
            {
              "lat": 43.756635124999995,
              "lng": -116.0978508,
              "alt": 2115.2842499999997,
              "speed2d": 4.8535,
              "speed3d": 4.835,
              "msFromStart": 199386.668
            },
            {
              "lat": 43.756622566666664,
              "lng": -116.09784176666666,
              "alt": 2115.676,
              "speed2d": 4.925666666666666,
              "speed3d": 4.926666666666667,
              "msFromStart": 199690.6053333333
            },
            {
              "lat": 43.756598525,
              "lng": -116.09782602499999,
              "alt": 2116.38,
              "speed2d": 4.78825,
              "speed3d": 4.8325,
              "msFromStart": 200311.5086666667
            },
            {
              "lat": 43.75658154285715,
              "lng": -116.0978145142857,
              "alt": 2116.8030000000003,
              "speed2d": 4.769857142857143,
              "speed3d": 4.8,
              "msFromStart": 200759.8337777777
            },
            {
              "lat": 43.75656875,
              "lng": -116.097806275,
              "alt": 2117.1372499999998,
              "speed2d": 4.66775,
              "speed3d": 4.7975,
              "msFromStart": 201105.84911111096
            },
            {
              "lat": 43.756562009080966,
              "lng": -116.09780703798961,
              "alt": 2119.1419938902786,
              "speed2d": 2.7157564472330558,
              "speed3d": 3.001624541674885,
              "msFromStart": 201500
            },
            {
              "lat": 43.756531325000005,
              "lng": -116.0977825,
              "alt": 2119.18725,
              "speed2d": 4.40225,
              "speed3d": 4.3625,
              "msFromStart": 202244.5087017545
            },
            {
              "lat": 43.756509928571425,
              "lng": -116.09776629999999,
              "alt": 2119.715,
              "speed2d": 4.665285714285714,
              "speed3d": 4.808571428571429,
              "msFromStart": 202767.6462222223
            },
            {
              "lat": 43.756488833333336,
              "lng": -116.097751,
              "alt": 2120.5223333333333,
              "speed2d": 4.8886666666666665,
              "speed3d": 4.88,
              "msFromStart": 203300.42340740748
            },
            {
              "lat": 43.756472685714286,
              "lng": -116.09774224285715,
              "alt": 2121.2462857142855,
              "speed2d": 4.7015714285714285,
              "speed3d": 4.894285714285714,
              "msFromStart": 203704.2497777778
            },
            {
              "lat": 43.756447575,
              "lng": -116.097725,
              "alt": 2121.71575,
              "speed2d": 4.9835,
              "speed3d": 5.03,
              "msFromStart": 204337.06222222224
            },
            {
              "lat": 43.756434133333336,
              "lng": -116.09771558333333,
              "alt": 2122.023833333333,
              "speed2d": 4.8356666666666674,
              "speed3d": 4.946666666666667,
              "msFromStart": 204684.60207407412
            },
            {
              "lat": 43.75642216745712,
              "lng": -116.09770750601666,
              "alt": 2122.5539762864764,
              "speed2d": 4.744132484035238,
              "speed3d": 4.760803201034676,
              "msFromStart": 205000
            },
            {
              "lat": 43.75639303333333,
              "lng": -116.09769156666667,
              "alt": 2123.322333333333,
              "speed2d": 4.573666666666666,
              "speed3d": 4.656666666666666,
              "msFromStart": 205736.84977777785
            },
            {
              "lat": 43.756382589804744,
              "lng": -116.09768575960419,
              "alt": 2123.39841632358,
              "speed2d": 4.5441442686984175,
              "speed3d": 4.564286890582014,
              "msFromStart": 206000
            },
            {
              "lat": 43.756347899999994,
              "lng": -116.09766315,
              "alt": 2123.572,
              "speed2d": 4.145,
              "speed3d": 4.245,
              "msFromStart": 206919.223111111
            },
            {
              "lat": 43.756339374999996,
              "lng": -116.09765725,
              "alt": 2123.9577499999996,
              "speed2d": 4.3687499999999995,
              "speed3d": 4.39,
              "msFromStart": 207139.24088888877
            },
            {
              "lat": 43.75631679999999,
              "lng": -116.09764125000001,
              "alt": 2124.7079999999996,
              "speed2d": 4.7565,
              "speed3d": 4.825,
              "msFromStart": 207688.84463157886
            },
            {
              "lat": 43.756302250000005,
              "lng": -116.09763029999999,
              "alt": 2124.8635,
              "speed2d": 4.465,
              "speed3d": 4.54,
              "msFromStart": 208100.9372631577
            },
            {
              "lat": 43.75627826666666,
              "lng": -116.09761373333333,
              "alt": 2124.0553333333332,
              "speed2d": 4.855333333333333,
              "speed3d": 4.869999999999999,
              "msFromStart": 208650.5813333334
            },
            {
              "lat": 43.7562425,
              "lng": -116.097589,
              "alt": 2122.9375,
              "speed2d": 5.04,
              "speed3d": 5.0649999999999995,
              "msFromStart": 209447.91288888885
            },
            {
              "lat": 43.75623383999999,
              "lng": -116.0975837,
              "alt": 2122.8202,
              "speed2d": 4.888,
              "speed3d": 4.958,
              "msFromStart": 209662.5827555555
            },
            {
              "lat": 43.7562127,
              "lng": -116.0975702,
              "alt": 2122.373,
              "speed2d": 4.9286666666666665,
              "speed3d": 4.93,
              "msFromStart": 210154.3199999999
            },
            {
              "lat": 43.75619753365357,
              "lng": -116.09755982188163,
              "alt": 2122.0207270939923,
              "speed2d": 4.981443218123733,
              "speed3d": 4.978033244710564,
              "msFromStart": 210500
            },
            {
              "lat": 43.75617514153542,
              "lng": -116.09754455234298,
              "alt": 2121.660942240877,
              "speed2d": 5.043566833036397,
              "speed3d": 5.035110668242114,
              "msFromStart": 211000
            },
            {
              "lat": 43.7561404,
              "lng": -116.09751940000001,
              "alt": 2120.399,
              "speed2d": 4.936,
              "speed3d": 4.916666666666666,
              "msFromStart": 211841.89866666682
            },
            {
              "lat": 43.75612066,
              "lng": -116.09750419999999,
              "alt": 2119.0582,
              "speed2d": 5.4002,
              "speed3d": 5.356,
              "msFromStart": 212270.39887719305
            },
            {
              "lat": 43.7560927,
              "lng": -116.09748054999999,
              "alt": 2117.8093333333336,
              "speed2d": 5.650166666666667,
              "speed3d": 5.711666666666667,
              "msFromStart": 212858.8728421051
            },
            {
              "lat": 43.75608702488891,
              "lng": -116.09747672930106,
              "alt": 2117.6885959536867,
              "speed2d": 5.439082963052855,
              "speed3d": 5.474806837814281,
              "msFromStart": 213000
            },
            {
              "lat": 43.756064882283034,
              "lng": -116.09746050153726,
              "alt": 2118.5112409428707,
              "speed2d": 5.484424351447179,
              "speed3d": 5.387035006074942,
              "msFromStart": 213500
            },
            {
              "lat": 43.75604872434541,
              "lng": -116.09745141044529,
              "alt": 2118.414604857226,
              "speed2d": 4.208443186840479,
              "speed3d": 4.3640269917752965,
              "msFromStart": 214000
            },
            {
              "lat": 43.75602704823069,
              "lng": -116.09743564867163,
              "alt": 2118.017420020006,
              "speed2d": 4.952862309161273,
              "speed3d": 4.832666740762449,
              "msFromStart": 214500
            },
            {
              "lat": 43.7559902,
              "lng": -116.09741405,
              "alt": 2117.969,
              "speed2d": 4.598000000000001,
              "speed3d": 4.555,
              "msFromStart": 215444.41288888888
            },
            {
              "lat": 43.7559811,
              "lng": -116.09740830000001,
              "alt": 2118.0653333333335,
              "speed2d": 4.575333333333333,
              "speed3d": 4.59,
              "msFromStart": 215692.14488888887
            },
            {
              "lat": 43.755956133333335,
              "lng": -116.09739353333333,
              "alt": 2118.663,
              "speed2d": 4.591,
              "speed3d": 4.543333333333333,
              "msFromStart": 216352.76948148146
            },
            {
              "lat": 43.755941475,
              "lng": -116.097384125,
              "alt": 2118.91325,
              "speed2d": 4.8615,
              "speed3d": 4.8225,
              "msFromStart": 216696.86155555554
            },
            {
              "lat": 43.755911999999995,
              "lng": -116.0973621,
              "alt": 2118.388,
              "speed2d": 6.1785,
              "speed3d": 6.0600000000000005,
              "msFromStart": 217261.17644444446
            },
            {
              "lat": 43.75590201792677,
              "lng": -116.09735517078377,
              "alt": 2118.819731619103,
              "speed2d": 5.822365460564496,
              "speed3d": 5.817854090587882,
              "msFromStart": 217500
            },
            {
              "lat": 43.75587650000001,
              "lng": -116.09733796,
              "alt": 2120.1966,
              "speed2d": 4.5886000000000005,
              "speed3d": 4.716,
              "msFromStart": 218168.41431578956
            },
            {
              "lat": 43.755857133333336,
              "lng": -116.09732473333334,
              "alt": 2120.450333333333,
              "speed2d": 4.819,
              "speed3d": 4.743333333333333,
              "msFromStart": 218681.0346666667
            },
            {
              "lat": 43.75584398670778,
              "lng": -116.09731561587836,
              "alt": 2120.9424939032924,
              "speed2d": 4.642166822716563,
              "speed3d": 4.613446334979933,
              "msFromStart": 219000
            },
            {
              "lat": 43.755811,
              "lng": -116.09729395,
              "alt": 2120.8695,
              "speed2d": 5.254,
              "speed3d": 5.17,
              "msFromStart": 219789.52444444448
            },
            {
              "lat": 43.755788011111115,
              "lng": -116.09727816666665,
              "alt": 2120.5216666666665,
              "speed2d": 5.626111111111111,
              "speed3d": 5.73,
              "msFromStart": 220257.51881481486
            },
            {
              "lat": 43.755773100000006,
              "lng": -116.09726795,
              "alt": 2122.1585,
              "speed2d": 4.34675,
              "speed3d": 4.407500000000001,
              "msFromStart": 220752.624
            },
            {
              "lat": 43.75575225,
              "lng": -116.09725209999999,
              "alt": 2123.4672499999997,
              "speed2d": 4.58,
              "speed3d": 4.565,
              "msFromStart": 221330.0855789474
            },
            {
              "lat": 43.755735214285714,
              "lng": -116.09724028571429,
              "alt": 2123.847285714286,
              "speed2d": 4.785857142857143,
              "speed3d": 4.804285714285714,
              "msFromStart": 221765.93636090227
            },
            {
              "lat": 43.755719250000006,
              "lng": -116.09723006666667,
              "alt": 2124.2173333333335,
              "speed2d": 4.560666666666667,
              "speed3d": 4.63,
              "msFromStart": 222209.63885380118
            },
            {
              "lat": 43.75570475,
              "lng": -116.0972198,
              "alt": 2124.69,
              "speed2d": 4.692,
              "speed3d": 4.65,
              "msFromStart": 222594.99599999998
            },
            {
              "lat": 43.755689130975504,
              "lng": -116.09720895948996,
              "alt": 2125.01506485774,
              "speed2d": 4.859097339514542,
              "speed3d": 4.87,
              "msFromStart": 223000
            },
            {
              "lat": 43.755663850000005,
              "lng": -116.097193425,
              "alt": 2125.578,
              "speed2d": 4.850999999999999,
              "speed3d": 4.890000000000001,
              "msFromStart": 223641.5595555555
            },
            {
              "lat": 43.755634474999994,
              "lng": -116.097173425,
              "alt": 2126.92125,
              "speed2d": 5.2010000000000005,
              "speed3d": 5.1825,
              "msFromStart": 224357.39777777775
            },
            {
              "lat": 43.75561697777776,
              "lng": -116.09716055555553,
              "alt": 2127.5654444444444,
              "speed2d": 5.199666666666667,
              "speed3d": 5.29,
              "msFromStart": 224771.7937777778
            },
            {
              "lat": 43.75560154,
              "lng": -116.09715018,
              "alt": 2127.9106,
              "speed2d": 4.854,
              "speed3d": 4.906000000000001,
              "msFromStart": 225191.81795555566
            },
            {
              "lat": 43.755587312726604,
              "lng": -116.09713916793541,
              "alt": 2128.339344968706,
              "speed2d": 5.470383662458176,
              "speed3d": 5.4801996331067135,
              "msFromStart": 225500
            },
            {
              "lat": 43.75556976283791,
              "lng": -116.0971310608567,
              "alt": 2128.797949666774,
              "speed2d": 4.659469077968331,
              "speed3d": 4.596763054027862,
              "msFromStart": 226000
            },
            {
              "lat": 43.75553595,
              "lng": -116.09710465,
              "alt": 2129.249,
              "speed2d": 5.311,
              "speed3d": 5.275,
              "msFromStart": 226780.09244444454
            },
            {
              "lat": 43.755510775,
              "lng": -116.097087475,
              "alt": 2129.9925000000003,
              "speed2d": 5.225750000000001,
              "speed3d": 5.342499999999999,
              "msFromStart": 227370.86306432754
            },
            {
              "lat": 43.755495233333335,
              "lng": -116.09707776666667,
              "alt": 2130.8661666666667,
              "speed2d": 4.8341666666666665,
              "speed3d": 4.94,
              "msFromStart": 227796.96505263163
            },
            {
              "lat": 43.755483133333335,
              "lng": -116.09706940000001,
              "alt": 2131.5313333333334,
              "speed2d": 4.581,
              "speed3d": 4.68,
              "msFromStart": 228145.1837192983
            },
            {
              "lat": 43.75546203333333,
              "lng": -116.09705043333335,
              "alt": 2132.3733333333334,
              "speed2d": 5.008,
              "speed3d": 5.006666666666667,
              "msFromStart": 228676.09600000008
            },
            {
              "lat": 43.75543485,
              "lng": -116.0970337,
              "alt": 2133.6095,
              "speed2d": 4.4705,
              "speed3d": 4.574999999999999,
              "msFromStart": 229444.62222222224
            },
            {
              "lat": 43.755416249999996,
              "lng": -116.0970217,
              "alt": 2134.2035,
              "speed2d": 4.7885,
              "speed3d": 4.845000000000001,
              "msFromStart": 229911.70666666678
            },
            {
              "lat": 43.75541247220656,
              "lng": -116.09701895556925,
              "alt": 2134.334061508636,
              "speed2d": 4.868796439901308,
              "speed3d": 4.9092245329573645,
              "msFromStart": 230000
            },
            {
              "lat": 43.75538173333333,
              "lng": -116.0969996,
              "alt": 2134.909,
              "speed2d": 4.776,
              "speed3d": 4.87,
              "msFromStart": 230801.40799999994
            },
            {
              "lat": 43.75536905,
              "lng": -116.09699085,
              "alt": 2135.2815,
              "speed2d": 4.922499999999999,
              "speed3d": 4.955,
              "msFromStart": 231122.84799999988
            },
            {
              "lat": 43.75534145,
              "lng": -116.09697395,
              "alt": 2136.3675000000003,
              "speed2d": 4.4465,
              "speed3d": 4.46,
              "msFromStart": 231891.84000000003
            },
            {
              "lat": 43.75533015,
              "lng": -116.09696686666666,
              "alt": 2136.6916666666666,
              "speed2d": 4.537,
              "speed3d": 4.651666666666667,
              "msFromStart": 232202.79157894736
            },
            {
              "lat": 43.75531635,
              "lng": -116.09695865,
              "alt": 2136.9925000000003,
              "speed2d": 4.3415,
              "speed3d": 4.415,
              "msFromStart": 232605.9781052631
            },
            {
              "lat": 43.7552956,
              "lng": -116.0969442,
              "alt": 2137.4719999999998,
              "speed2d": 4.9864999999999995,
              "speed3d": 5.08,
              "msFromStart": 233101.17136842094
            },
            {
              "lat": 43.755275450000006,
              "lng": -116.09693447499998,
              "alt": 2138.1805000000004,
              "speed2d": 4.29575,
              "speed3d": 4.404999999999999,
              "msFromStart": 233734.2222222222
            },
            {
              "lat": 43.755260625,
              "lng": -116.096926175,
              "alt": 2138.67175,
              "speed2d": 4.348,
              "speed3d": 4.44,
              "msFromStart": 234147.21999999994
            },
            {
              "lat": 43.7552479179038,
              "lng": -116.09691878566728,
              "alt": 2139.0122956546984,
              "speed2d": 4.434071711990723,
              "speed3d": 4.4880896157940695,
              "msFromStart": 234500
            },
            {
              "lat": 43.7552211,
              "lng": -116.0969008,
              "alt": 2139.6014999999998,
              "speed2d": 4.7995,
              "speed3d": 4.8149999999999995,
              "msFromStart": 235194.0715555555
            },
            {
              "lat": 43.75520967081428,
              "lng": -116.09689266411279,
              "alt": 2140.017650101026,
              "speed2d": 4.573299789865047,
              "speed3d": 4.731419623373486,
              "msFromStart": 235500
            },
            {
              "lat": 43.75518016666666,
              "lng": -116.09687246666667,
              "alt": 2141.186,
              "speed2d": 5.120333333333333,
              "speed3d": 5.126666666666667,
              "msFromStart": 236211.7617777776
            },
            {
              "lat": 43.75516962981551,
              "lng": -116.09686532342252,
              "alt": 2141.4179565955546,
              "speed2d": 4.948532760992628,
              "speed3d": 4.965958256824971,
              "msFromStart": 236500
            },
            {
              "lat": 43.755152145317645,
              "lng": -116.09685546886165,
              "alt": 2141.2776100278834,
              "speed2d": 3.7143797158630685,
              "speed3d": 3.880253143908712,
              "msFromStart": 237000
            },
            {
              "lat": 43.75512594999999,
              "lng": -116.096837075,
              "alt": 2143.25425,
              "speed2d": 4.39525,
              "speed3d": 4.4350000000000005,
              "msFromStart": 237720.9477894737
            },
            {
              "lat": 43.75510394,
              "lng": -116.09682202,
              "alt": 2143.7545999999998,
              "speed2d": 4.9352,
              "speed3d": 5.0680000000000005,
              "msFromStart": 238244.07578947372
            },
            {
              "lat": 43.75508805,
              "lng": -116.0968127,
              "alt": 2144.297,
              "speed2d": 4.3305,
              "speed3d": 4.425000000000001,
              "msFromStart": 238739.30755555566
            },
            {
              "lat": 43.75507765836715,
              "lng": -116.09680478690998,
              "alt": 2144.2248847778355,
              "speed2d": 4.74492224940725,
              "speed3d": 4.788405515097268,
              "msFromStart": 239000
            },
            {
              "lat": 43.7550495,
              "lng": -116.0967899,
              "alt": 2146.0645000000004,
              "speed2d": 4.551500000000001,
              "speed3d": 4.71,
              "msFromStart": 239757.13733333332
            },
            {
              "lat": 43.755032224999994,
              "lng": -116.096779,
              "alt": 2147.1367499999997,
              "speed2d": 4.658,
              "speed3d": 4.7325,
              "msFromStart": 240197.30311111108
            },
            {
              "lat": 43.75500613333334,
              "lng": -116.09676196666666,
              "alt": 2147.076666666667,
              "speed2d": 5.570666666666666,
              "speed3d": 5.586666666666667,
              "msFromStart": 240756.43081481487
            },
            {
              "lat": 43.75498455,
              "lng": -116.09674895,
              "alt": 2147.93525,
              "speed2d": 5.1905,
              "speed3d": 5.3575,
              "msFromStart": 241269.6442339182
            },
            {
              "lat": 43.75496496666667,
              "lng": -116.09673850000001,
              "alt": 2148.809666666667,
              "speed2d": 4.918,
              "speed3d": 5.036666666666666,
              "msFromStart": 241801.38778947364
            },
            {
              "lat": 43.7549456,
              "lng": -116.09672569999998,
              "alt": 2149.0333333333333,
              "speed2d": 5.139,
              "speed3d": 5.266666666666667,
              "msFromStart": 242259.82989473682
            },
            {
              "lat": 43.754923700000006,
              "lng": -116.0967121,
              "alt": 2149.466,
              "speed2d": 5.0585,
              "speed3d": 5.04,
              "msFromStart": 242809.22311111106
            },
            {
              "lat": 43.75490312,
              "lng": -116.0966976,
              "alt": 2149.2922,
              "speed2d": 5.654,
              "speed3d": 5.754,
              "msFromStart": 243248.59022222215
            },
            {
              "lat": 43.75488646666666,
              "lng": -116.09668803333332,
              "alt": 2150.034666666667,
              "speed2d": 4.906666666666667,
              "speed3d": 5.046666666666667,
              "msFromStart": 243725.56681481475
            },
            {
              "lat": 43.754863549999996,
              "lng": -116.09667555,
              "alt": 2151.4115,
              "speed2d": 4.292,
              "speed3d": 4.395,
              "msFromStart": 244432.27733333333
            },
            {
              "lat": 43.7548487,
              "lng": -116.09666843333333,
              "alt": 2152.1183333333333,
              "speed2d": 4.131666666666667,
              "speed3d": 4.203333333333333,
              "msFromStart": 244900.23822222213
            },
            {
              "lat": 43.75484411171107,
              "lng": -116.09666634128945,
              "alt": 2152.217849650802,
              "speed2d": 4.3389469089498895,
              "speed3d": 4.350838722193565,
              "msFromStart": 245000
            },
            {
              "lat": 43.75482225714286,
              "lng": -116.09664810000001,
              "alt": 2152.980857142857,
              "speed2d": 4.119,
              "speed3d": 4.274285714285714,
              "msFromStart": 245725.05600000007
            },
            {
              "lat": 43.75481356666666,
              "lng": -116.09664136666666,
              "alt": 2154.8210000000004,
              "speed2d": 3.109666666666667,
              "speed3d": 3.3566666666666665,
              "msFromStart": 246274.14666666678
            },
            {
              "lat": 43.7548053,
              "lng": -116.0966384,
              "alt": 2156.3275,
              "speed2d": 2.7675,
              "speed3d": 2.975,
              "msFromStart": 246740.70800000004
            },
            {
              "lat": 43.754802269825284,
              "lng": -116.09663322111942,
              "alt": 2156.8414009271464,
              "speed2d": 2.905452691997171,
              "speed3d": 3.025129412125557,
              "msFromStart": 247000
            },
            {
              "lat": 43.7547676,
              "lng": -116.09661356666666,
              "alt": 2157.8043333333335,
              "speed2d": 4.484333333333333,
              "speed3d": 4.546666666666667,
              "msFromStart": 247756.33936842106
            },
            {
              "lat": 43.75474335,
              "lng": -116.0966018,
              "alt": 2158.302,
              "speed2d": 5.2105,
              "speed3d": 5.305,
              "msFromStart": 248223.16294736846
            },
            {
              "lat": 43.754716625,
              "lng": -116.096595375,
              "alt": 2158.7690000000002,
              "speed2d": 5.297124999999999,
              "speed3d": 5.48625,
              "msFromStart": 248772.56399999993
            },
            {
              "lat": 43.75470515,
              "lng": -116.09659657499999,
              "alt": 2158.83675,
              "speed2d": 4.742999999999999,
              "speed3d": 4.9624999999999995,
              "msFromStart": 249129.7173333332
            },
            {
              "lat": 43.754679933333335,
              "lng": -116.09660483333332,
              "alt": 2158.991666666667,
              "speed2d": 4.4,
              "speed3d": 4.526666666666666,
              "msFromStart": 249827.61896296294
            }
          ]
        }
      ]
    },
    {
      "id": "lift-pine-creek",
      "pathType": "lift",
      "name": "Pine Creek",
      "speed": "fast",
      "seats": 4,
      "videos": [
        {
          "videoId": "GH010024",
          "pathId": "lift-pine-creek",
          "startSec": 12,
          "endSec": 344,
          "id": "video-segment-13",
          "gps": [
            {
              "lat": 43.76631246666666,
              "lng": -116.07062113333333,
              "alt": 1748.928222222222,
              "speed2d": 7.1576666666666675,
              "speed3d": 7.151111111111111,
              "msFromStart": 12229.810063352823
            },
            {
              "lat": 43.766336888888894,
              "lng": -116.07067071111112,
              "alt": 1746.1854444444446,
              "speed2d": 7.682777777777778,
              "speed3d": 7.999999999999998,
              "msFromStart": 12725.56705263157
            },
            {
              "lat": 43.76635451999999,
              "lng": -116.07072260000001,
              "alt": 1742.8217000000002,
              "speed2d": 7.636100000000001,
              "speed3d": 8.009,
              "msFromStart": 13248.338478947364
            },
            {
              "lat": 43.76637228888889,
              "lng": -116.07076873333332,
              "alt": 1738.892111111111,
              "speed2d": 7.3214444444444435,
              "speed3d": 7.872222222222222,
              "msFromStart": 13769.455000000002
            },
            {
              "lat": 43.76639181111111,
              "lng": -116.07081339999999,
              "alt": 1734.5693333333334,
              "speed2d": 7.32,
              "speed3d": 7.903333333333333,
              "msFromStart": 14263.167777777777
            },
            {
              "lat": 43.76641236666667,
              "lng": -116.0708654222222,
              "alt": 1731.2427777777777,
              "speed2d": 7.764000000000001,
              "speed3d": 8.38,
              "msFromStart": 14757.319666666659
            },
            {
              "lat": 43.76643352222222,
              "lng": -116.07090861111112,
              "alt": 1728.0782222222224,
              "speed2d": 7.613111111111112,
              "speed3d": 8.393333333333333,
              "msFromStart": 15251.41948148148
            },
            {
              "lat": 43.7664466888889,
              "lng": -116.07094658888887,
              "alt": 1724.874777777778,
              "speed2d": 7.040222222222222,
              "speed3d": 7.89,
              "msFromStart": 15745.31522222222
            },
            {
              "lat": 43.76645775555556,
              "lng": -116.07099414444446,
              "alt": 1721.351111111111,
              "speed2d": 7.054666666666666,
              "speed3d": 7.955555555555556,
              "msFromStart": 16239.12554191033
            },
            {
              "lat": 43.76647568888889,
              "lng": -116.07104079999998,
              "alt": 1717.812111111111,
              "speed2d": 7.236888888888888,
              "speed3d": 8.16777777777778,
              "msFromStart": 16732.733157894738
            },
            {
              "lat": 43.766490266666665,
              "lng": -116.07107623333333,
              "alt": 1714.6863333333333,
              "speed2d": 6.865555555555556,
              "speed3d": 7.9911111111111115,
              "msFromStart": 17226.53215204678
            },
            {
              "lat": 43.76649899999999,
              "lng": -116.07110766000001,
              "alt": 1712.5819999999999,
              "speed2d": 6.385500000000001,
              "speed3d": 7.5920000000000005,
              "msFromStart": 17748.622000000003
            },
            {
              "lat": 43.766516377777776,
              "lng": -116.07112463333333,
              "alt": 1709.7294444444442,
              "speed2d": 5.651666666666667,
              "speed3d": 6.946666666666665,
              "msFromStart": 18271.030666666666
            },
            {
              "lat": 43.76654582222223,
              "lng": -116.07116324444445,
              "alt": 1705.4676666666667,
              "speed2d": 6.275333333333332,
              "speed3d": 7.513333333333334,
              "msFromStart": 18766.60266666666
            },
            {
              "lat": 43.766575966666664,
              "lng": -116.07120600000002,
              "alt": 1701.0896666666667,
              "speed2d": 6.895,
              "speed3d": 8.125555555555554,
              "msFromStart": 19262.09537037037
            },
            {
              "lat": 43.766603577777765,
              "lng": -116.07125482222223,
              "alt": 1696.7881111111108,
              "speed2d": 7.373555555555556,
              "speed3d": 8.659999999999998,
              "msFromStart": 19757.28055555557
            },
            {
              "lat": 43.766626,
              "lng": -116.0713010111111,
              "alt": 1691.7818888888887,
              "speed2d": 7.406888888888889,
              "speed3d": 8.86,
              "msFromStart": 20252.49881481482
            },
            {
              "lat": 43.76665107777778,
              "lng": -116.07133652222221,
              "alt": 1686.5845555555554,
              "speed2d": 7.130222222222221,
              "speed3d": 8.793333333333333,
              "msFromStart": 20747.850777777774
            },
            {
              "lat": 43.76668244444445,
              "lng": -116.0713705,
              "alt": 1681.6353333333334,
              "speed2d": 7.237111111111111,
              "speed3d": 8.932222222222222,
              "msFromStart": 21243.035555555554
            },
            {
              "lat": 43.766723111111105,
              "lng": -116.07140542222223,
              "alt": 1676.4288888888887,
              "speed2d": 7.840333333333334,
              "speed3d": 9.5,
              "msFromStart": 21737.747444444434
            },
            {
              "lat": 43.766764800000004,
              "lng": -116.07143443333334,
              "alt": 1673.2572222222223,
              "speed2d": 8.601666666666667,
              "speed3d": 10.20111111111111,
              "msFromStart": 22232.323031189077
            },
            {
              "lat": 43.76677881111111,
              "lng": -116.07145994444446,
              "alt": 1672.3884444444445,
              "speed2d": 8.262111111111112,
              "speed3d": 9.892222222222223,
              "msFromStart": 22726.596210526313
            },
            {
              "lat": 43.76678616,
              "lng": -116.07148509999999,
              "alt": 1672.3312,
              "speed2d": 8.1538,
              "speed3d": 9.682,
              "msFromStart": 23111.042098245613
            },
            {
              "lat": 43.76680097656863,
              "lng": -116.07150694101105,
              "alt": 1674.4196549311673,
              "speed2d": 8.182705248478303,
              "speed3d": 9.504682408555704,
              "msFromStart": 23500
            },
            {
              "lat": 43.76681992904538,
              "lng": -116.07153353507256,
              "alt": 1678.0620314572961,
              "speed2d": 8.140962670914536,
              "speed3d": 9.20844476132896,
              "msFromStart": 24000
            },
            {
              "lat": 43.766856175,
              "lng": -116.071577175,
              "alt": 1684.2087500000002,
              "speed2d": 8.260750000000002,
              "speed3d": 8.897499999999999,
              "msFromStart": 24857.43100000001
            },
            {
              "lat": 43.766858493472455,
              "lng": -116.07157887325401,
              "alt": 1686.1533456386092,
              "speed2d": 7.8195376896310975,
              "speed3d": 9.484272181533324,
              "msFromStart": 25000
            },
            {
              "lat": 43.76683377999999,
              "lng": -116.07162886,
              "alt": 1690.798,
              "speed2d": 7.1778,
              "speed3d": 7.452,
              "msFromStart": 25829.560355555535
            },
            {
              "lat": 43.76683156666667,
              "lng": -116.07164933333334,
              "alt": 1692.021666666667,
              "speed2d": 7.1875,
              "speed3d": 7.640000000000001,
              "msFromStart": 26193.27605555555
            },
            {
              "lat": 43.76682077885218,
              "lng": -116.07165607488804,
              "alt": 1693.210749304228,
              "speed2d": 6.856026456478681,
              "speed3d": 7.011767391264844,
              "msFromStart": 26500
            },
            {
              "lat": 43.76682985,
              "lng": -116.0717137,
              "alt": 1695.751,
              "speed2d": 7.7695,
              "speed3d": 7.890000000000001,
              "msFromStart": 27046.65983333336
            },
            {
              "lat": 43.76682185,
              "lng": -116.0717478,
              "alt": 1696.7975000000001,
              "speed2d": 6.6145,
              "speed3d": 7.085,
              "msFromStart": 27763.192210526293
            },
            {
              "lat": 43.76681066417173,
              "lng": -116.07175122294663,
              "alt": 1695.382460911432,
              "speed2d": 5.8297407907493435,
              "speed3d": 6.196143390224196,
              "msFromStart": 28000
            },
            {
              "lat": 43.766798949999995,
              "lng": -116.07174985,
              "alt": 1701.722,
              "speed2d": 5.2015,
              "speed3d": 5.390000000000001,
              "msFromStart": 28836.77133333333
            },
            {
              "lat": 43.7667969,
              "lng": -116.07173850000001,
              "alt": 1705.610333333333,
              "speed2d": 4.880333333333334,
              "speed3d": 5.06,
              "msFromStart": 29093.478555555557
            },
            {
              "lat": 43.76679136914211,
              "lng": -116.0717130208588,
              "alt": 1712.2619770704464,
              "speed2d": 3.7707000575995666,
              "speed3d": 3.771285770586043,
              "msFromStart": 29500
            },
            {
              "lat": 43.766773325,
              "lng": -116.07174435,
              "alt": 1712.8395,
              "speed2d": 4.8835,
              "speed3d": 4.892499999999999,
              "msFromStart": 30362.661333333337
            },
            {
              "lat": 43.766770333333334,
              "lng": -116.07175423333334,
              "alt": 1714.2106666666668,
              "speed2d": 4.909,
              "speed3d": 4.8966666666666665,
              "msFromStart": 30706.38355555557
            },
            {
              "lat": 43.766765400000004,
              "lng": -116.07176126666667,
              "alt": 1717.732,
              "speed2d": 4.921,
              "speed3d": 4.916666666666666,
              "msFromStart": 31347.835578947364
            },
            {
              "lat": 43.766763087499996,
              "lng": -116.0717673625,
              "alt": 1718.8405,
              "speed2d": 4.73975,
              "speed3d": 4.79125,
              "msFromStart": 31743.941842105243
            },
            {
              "lat": 43.76676108257064,
              "lng": -116.07177549688363,
              "alt": 1718.8267409683265,
              "speed2d": 4.583331052188641,
              "speed3d": 4.60451800512877,
              "msFromStart": 32000
            },
            {
              "lat": 43.766758749999994,
              "lng": -116.07179675,
              "alt": 1720.267,
              "speed2d": 4.9785,
              "speed3d": 4.975,
              "msFromStart": 32658.710500000005
            },
            {
              "lat": 43.766756449999995,
              "lng": -116.0718051,
              "alt": 1722.19775,
              "speed2d": 5.027749999999999,
              "speed3d": 5.055,
              "msFromStart": 33154.5155
            },
            {
              "lat": 43.766752849999996,
              "lng": -116.07181555,
              "alt": 1723.5005,
              "speed2d": 4.526,
              "speed3d": 4.625,
              "msFromStart": 33759.22649999996
            },
            {
              "lat": 43.766752090697565,
              "lng": -116.0718283341877,
              "alt": 1721.941504891563,
              "speed2d": 4.259364217793512,
              "speed3d": 4.240743453048253,
              "msFromStart": 34000
            },
            {
              "lat": 43.766733599999995,
              "lng": -116.07179516666667,
              "alt": 1729.7876666666666,
              "speed2d": 4.124666666666666,
              "speed3d": 4.196666666666666,
              "msFromStart": 34869.47614814818
            },
            {
              "lat": 43.766732614285715,
              "lng": -116.07177268571428,
              "alt": 1736.842,
              "speed2d": 4.622,
              "speed3d": 4.73,
              "msFromStart": 35328.192
            },
            {
              "lat": 43.76672845555555,
              "lng": -116.07176096666666,
              "alt": 1741.6908888888888,
              "speed2d": 4.204555555555555,
              "speed3d": 4.483333333333333,
              "msFromStart": 35767.70133333335
            },
            {
              "lat": 43.766723194791616,
              "lng": -116.07175824182984,
              "alt": 1743.2570167204028,
              "speed2d": 3.8239688779545222,
              "speed3d": 4.11557731198977,
              "msFromStart": 36000
            },
            {
              "lat": 43.766721593449184,
              "lng": -116.0717484866134,
              "alt": 1748.2687735715713,
              "speed2d": 3.863976358048631,
              "speed3d": 4.706160638878116,
              "msFromStart": 36500
            },
            {
              "lat": 43.76671185,
              "lng": -116.0717681,
              "alt": 1748.653,
              "speed2d": 4.5695,
              "speed3d": 4.635,
              "msFromStart": 37362.00042105262
            },
            {
              "lat": 43.76671251018704,
              "lng": -116.07176739864735,
              "alt": 1750.0015944147126,
              "speed2d": 4.733857550282906,
              "speed3d": 4.829864735742028,
              "msFromStart": 37500
            },
            {
              "lat": 43.766713366666664,
              "lng": -116.07177216666668,
              "alt": 1754.1406666666667,
              "speed2d": 4.851333333333333,
              "speed3d": 5.1433333333333335,
              "msFromStart": 38113.616713450254
            },
            {
              "lat": 43.76671756940962,
              "lng": -116.07177240755776,
              "alt": 1757.7754680897494,
              "speed2d": 4.742835343548879,
              "speed3d": 5.044067268194481,
              "msFromStart": 38500
            },
            {
              "lat": 43.766722607639664,
              "lng": -116.07178476843272,
              "alt": 1759.6962541788102,
              "speed2d": 4.626736998967322,
              "speed3d": 4.947057869757062,
              "msFromStart": 39000
            },
            {
              "lat": 43.766727645869715,
              "lng": -116.07179712930768,
              "alt": 1761.617040267871,
              "speed2d": 4.510638654385765,
              "speed3d": 4.850048471319642,
              "msFromStart": 39500
            },
            {
              "lat": 43.766734650000004,
              "lng": -116.0718145,
              "alt": 1764.2939999999999,
              "speed2d": 4.3565000000000005,
              "speed3d": 4.72,
              "msFromStart": 40197.80033333333
            },
            {
              "lat": 43.766739457142855,
              "lng": -116.07183331428573,
              "alt": 1766.0862857142856,
              "speed2d": 4.454857142857143,
              "speed3d": 4.8257142857142865,
              "msFromStart": 40775.55933333331
            },
            {
              "lat": 43.76673718000001,
              "lng": -116.07185807999998,
              "alt": 1765.5867999999998,
              "speed2d": 4.3484,
              "speed3d": 4.63,
              "msFromStart": 41303.55035555555
            },
            {
              "lat": 43.76673685725775,
              "lng": -116.07186935318786,
              "alt": 1765.6777218386553,
              "speed2d": 4.543100106898561,
              "speed3d": 4.726833511497602,
              "msFromStart": 41500
            },
            {
              "lat": 43.766748,
              "lng": -116.0718995,
              "alt": 1768.5805,
              "speed2d": 4.577500000000001,
              "speed3d": 4.88,
              "msFromStart": 42314.652842105264
            },
            {
              "lat": 43.76675618571429,
              "lng": -116.07190778571429,
              "alt": 1771.7217142857144,
              "speed2d": 4.280428571428571,
              "speed3d": 4.585714285714285,
              "msFromStart": 42809.823368421065
            },
            {
              "lat": 43.76676186,
              "lng": -116.07192201999999,
              "alt": 1772.5996,
              "speed2d": 4.4216,
              "speed3d": 4.6739999999999995,
              "msFromStart": 43161.31926315791
            },
            {
              "lat": 43.76676825,
              "lng": -116.0719401,
              "alt": 1773.9725,
              "speed2d": 4.513,
              "speed3d": 4.75,
              "msFromStart": 43599.78100000002
            },
            {
              "lat": 43.76677764285714,
              "lng": -116.07196161428571,
              "alt": 1776.7079999999999,
              "speed2d": 4.571571428571429,
              "speed3d": 4.801428571428571,
              "msFromStart": 44223.786952380964
            },
            {
              "lat": 43.7667838,
              "lng": -116.07198410000001,
              "alt": 1777.5673333333332,
              "speed2d": 4.690666666666666,
              "speed3d": 4.859999999999999,
              "msFromStart": 44747.97888888892
            },
            {
              "lat": 43.76678890000001,
              "lng": -116.07200436,
              "alt": 1777.6897999999999,
              "speed2d": 4.6979999999999995,
              "speed3d": 4.878000000000001,
              "msFromStart": 45192.898156862764
            },
            {
              "lat": 43.76679466666667,
              "lng": -116.07202693333332,
              "alt": 1778.596,
              "speed2d": 4.520666666666667,
              "speed3d": 4.65,
              "msFromStart": 45780.38298039213
            },
            {
              "lat": 43.76680464285714,
              "lng": -116.07204555714284,
              "alt": 1781.3372857142858,
              "speed2d": 4.531,
              "speed3d": 4.687142857142858,
              "msFromStart": 46316.28885154061
            },
            {
              "lat": 43.76681,
              "lng": -116.07206114285714,
              "alt": 1782.7155714285716,
              "speed2d": 4.507285714285715,
              "speed3d": 4.6742857142857135,
              "msFromStart": 46733.07371428573
            },
            {
              "lat": 43.766820249999995,
              "lng": -116.0720892,
              "alt": 1785.576,
              "speed2d": 4.515,
              "speed3d": 4.6850000000000005,
              "msFromStart": 47459.34831578948
            },
            {
              "lat": 43.76682605,
              "lng": -116.0721058,
              "alt": 1787.2115,
              "speed2d": 4.535500000000001,
              "speed3d": 4.71,
              "msFromStart": 47871.94147368424
            },
            {
              "lat": 43.76682782479505,
              "lng": -116.07211086847478,
              "alt": 1787.7645977608752,
              "speed2d": 4.533577978694514,
              "speed3d": 4.7140886555974,
              "msFromStart": 48000
            },
            {
              "lat": 43.7668329,
              "lng": -116.07214317500001,
              "alt": 1788.473,
              "speed2d": 4.3412500000000005,
              "speed3d": 4.529999999999999,
              "msFromStart": 48723.050888888916
            },
            {
              "lat": 43.7668417,
              "lng": -116.07216835,
              "alt": 1789.8919999999998,
              "speed2d": 4.484999999999999,
              "speed3d": 4.655,
              "msFromStart": 49271.91444444447
            },
            {
              "lat": 43.76684575544923,
              "lng": -116.07217859156647,
              "alt": 1790.3773587800547,
              "speed2d": 4.497443115348069,
              "speed3d": 4.67944311534807,
              "msFromStart": 49500
            },
            {
              "lat": 43.76685534285715,
              "lng": -116.0722152,
              "alt": 1790.2234285714285,
              "speed2d": 4.494142857142857,
              "speed3d": 4.678571428571428,
              "msFromStart": 50227.16311111113
            },
            {
              "lat": 43.76686695,
              "lng": -116.072240075,
              "alt": 1791.883,
              "speed2d": 4.7444999999999995,
              "speed3d": 4.8625,
              "msFromStart": 50729.64044444448
            },
            {
              "lat": 43.766874128883515,
              "lng": -116.07225350394916,
              "alt": 1793.3666005671676,
              "speed2d": 4.845864930154398,
              "speed3d": 5.091153240205863,
              "msFromStart": 51000
            },
            {
              "lat": 43.76688798000001,
              "lng": -116.07229287999999,
              "alt": 1794.8960000000002,
              "speed2d": 4.6796,
              "speed3d": 4.89,
              "msFromStart": 51840.38471111108
            },
            {
              "lat": 43.76689206666667,
              "lng": -116.07230863333334,
              "alt": 1794.9613333333332,
              "speed2d": 4.596333333333334,
              "speed3d": 4.81,
              "msFromStart": 52159.4733333333
            },
            {
              "lat": 43.76690215000001,
              "lng": -116.0723411,
              "alt": 1795.43975,
              "speed2d": 4.60075,
              "speed3d": 4.7625,
              "msFromStart": 52819.91866666671
            },
            {
              "lat": 43.76691055,
              "lng": -116.07235610000001,
              "alt": 1796.51075,
              "speed2d": 4.721,
              "speed3d": 4.905,
              "msFromStart": 53150.147333333356
            },
            {
              "lat": 43.76691967290636,
              "lng": -116.0723730333567,
              "alt": 1797.3512216716854,
              "speed2d": 4.840665024278448,
              "speed3d": 5.001534130471873,
              "msFromStart": 53500
            },
            {
              "lat": 43.76693066973764,
              "lng": -116.07240195027039,
              "alt": 1797.3494358120067,
              "speed2d": 5.048630082085946,
              "speed3d": 5.248477868984386,
              "msFromStart": 54000
            },
            {
              "lat": 43.766950433333335,
              "lng": -116.07243223333333,
              "alt": 1799.9066666666668,
              "speed2d": 5.025333333333333,
              "speed3d": 5.223333333333334,
              "msFromStart": 54729.54577777781
            },
            {
              "lat": 43.766966177777775,
              "lng": -116.072454,
              "alt": 1801.724777777778,
              "speed2d": 5.108888888888888,
              "speed3d": 5.338888888888889,
              "msFromStart": 55244.588
            },
            {
              "lat": 43.76696945555556,
              "lng": -116.07247683333333,
              "alt": 1801.8587777777775,
              "speed2d": 4.698888888888889,
              "speed3d": 4.973333333333334,
              "msFromStart": 55739.59466666663
            },
            {
              "lat": 43.766977475,
              "lng": -116.07250300000001,
              "alt": 1802.55925,
              "speed2d": 4.65125,
              "speed3d": 4.85,
              "msFromStart": 56302.525888888864
            },
            {
              "lat": 43.76698895,
              "lng": -116.07251875,
              "alt": 1803.8605,
              "speed2d": 4.9215,
              "speed3d": 5.095000000000001,
              "msFromStart": 56644.62888888887
            },
            {
              "lat": 43.76700385,
              "lng": -116.0725554,
              "alt": 1804.859,
              "speed2d": 4.8155,
              "speed3d": 5.055,
              "msFromStart": 57413.08999999998
            },
            {
              "lat": 43.76700836,
              "lng": -116.07256688,
              "alt": 1805.3744,
              "speed2d": 4.7136,
              "speed3d": 4.966,
              "msFromStart": 57683.746399999975
            },
            {
              "lat": 43.767017700000004,
              "lng": -116.07259383333333,
              "alt": 1806.1756666666668,
              "speed2d": 4.634333333333333,
              "speed3d": 4.875,
              "msFromStart": 58268.757333333306
            },
            {
              "lat": 43.76702575,
              "lng": -116.07261835,
              "alt": 1806.7089999999998,
              "speed2d": 4.6485,
              "speed3d": 4.88,
              "msFromStart": 58763.91866666665
            },
            {
              "lat": 43.767033274999996,
              "lng": -116.072642575,
              "alt": 1806.961,
              "speed2d": 4.682,
              "speed3d": 4.91,
              "msFromStart": 59231.307166666666
            },
            {
              "lat": 43.767041925,
              "lng": -116.07267069999999,
              "alt": 1807.5755,
              "speed2d": 4.708,
              "speed3d": 4.9350000000000005,
              "msFromStart": 59780.79566666667
            },
            {
              "lat": 43.76704825,
              "lng": -116.0727039,
              "alt": 1807.0675,
              "speed2d": 4.656499999999999,
              "speed3d": 4.87,
              "msFromStart": 60384.97311111111
            },
            {
              "lat": 43.767051939999995,
              "lng": -116.07271996,
              "alt": 1806.891,
              "speed2d": 4.6714,
              "speed3d": 4.8759999999999994,
              "msFromStart": 60675.82062222222
            },
            {
              "lat": 43.767057750000006,
              "lng": -116.07274686666668,
              "alt": 1806.53,
              "speed2d": 4.701499999999999,
              "speed3d": 4.8966666666666665,
              "msFromStart": 61153.30366666667
            },
            {
              "lat": 43.76706104880351,
              "lng": -116.07276575577387,
              "alt": 1806.079438199432,
              "speed2d": 4.648695828769808,
              "speed3d": 4.82,
              "msFromStart": 61500
            },
            {
              "lat": 43.767067175,
              "lng": -116.07280300000001,
              "alt": 1804.9734999999998,
              "speed2d": 4.6625,
              "speed3d": 4.827500000000001,
              "msFromStart": 62142.88500877195
            },
            {
              "lat": 43.767072080000005,
              "lng": -116.07283533999998,
              "alt": 1803.897,
              "speed2d": 4.6042000000000005,
              "speed3d": 4.756,
              "msFromStart": 62718.573684210554
            },
            {
              "lat": 43.7670782875,
              "lng": -116.0728669875,
              "alt": 1802.876875,
              "speed2d": 4.56975,
              "speed3d": 4.69875,
              "msFromStart": 63294.64082017546
            },
            {
              "lat": 43.76708407999999,
              "lng": -116.07288696,
              "alt": 1803.0962000000002,
              "speed2d": 4.555999999999999,
              "speed3d": 4.684,
              "msFromStart": 63696.66097777778
            },
            {
              "lat": 43.76709365000001,
              "lng": -116.072920575,
              "alt": 1803.35075,
              "speed2d": 4.58975,
              "speed3d": 4.7124999999999995,
              "msFromStart": 64354.39805555556
            },
            {
              "lat": 43.767099200000004,
              "lng": -116.0729401,
              "alt": 1803.521,
              "speed2d": 4.5065,
              "speed3d": 4.6375,
              "msFromStart": 64766.255555555574
            },
            {
              "lat": 43.76710255745005,
              "lng": -116.0729450361859,
              "alt": 1804.0687871411415,
              "speed2d": 4.1573831459447685,
              "speed3d": 4.020638576574612,
              "msFromStart": 65000
            },
            {
              "lat": 43.76711206666667,
              "lng": -116.07298787777776,
              "alt": 1804.0593333333334,
              "speed2d": 4.4752222222222215,
              "speed3d": 4.596666666666666,
              "msFromStart": 65741.456
            },
            {
              "lat": 43.76711804999999,
              "lng": -116.07300871666666,
              "alt": 1804.1805,
              "speed2d": 4.440666666666666,
              "speed3d": 4.568333333333333,
              "msFromStart": 66172.03133333333
            },
            {
              "lat": 43.76712555,
              "lng": -116.07303759999999,
              "alt": 1804.2335,
              "speed2d": 4.498,
              "speed3d": 4.59,
              "msFromStart": 66730.94399999994
            },
            {
              "lat": 43.767133175,
              "lng": -116.07306725,
              "alt": 1804.3462499999998,
              "speed2d": 4.60725,
              "speed3d": 4.71,
              "msFromStart": 67280.74926315786
            },
            {
              "lat": 43.767138466666665,
              "lng": -116.07308760000001,
              "alt": 1804.5045000000002,
              "speed2d": 4.643666666666666,
              "speed3d": 4.753333333333334,
              "msFromStart": 67665.69894736845
            },
            {
              "lat": 43.76714722222222,
              "lng": -116.07311924444446,
              "alt": 1805.0434444444445,
              "speed2d": 4.692555555555556,
              "speed3d": 4.793333333333333,
              "msFromStart": 68270.89726315792
            },
            {
              "lat": 43.76715392,
              "lng": -116.07314568,
              "alt": 1805.3248,
              "speed2d": 4.7344,
              "speed3d": 4.824,
              "msFromStart": 68756.12960000003
            },
            {
              "lat": 43.7671611125,
              "lng": -116.0731766125,
              "alt": 1805.465625,
              "speed2d": 4.825999999999999,
              "speed3d": 4.927500000000001,
              "msFromStart": 69290.884
            },
            {
              "lat": 43.7671658,
              "lng": -116.07319419999999,
              "alt": 1805.6542000000002,
              "speed2d": 4.7458,
              "speed3d": 4.868,
              "msFromStart": 69648.65333333338
            },
            {
              "lat": 43.76717187142857,
              "lng": -116.07322058571428,
              "alt": 1805.9694285714288,
              "speed2d": 4.511142857142858,
              "speed3d": 4.6328571428571435,
              "msFromStart": 70246.07225396829
            },
            {
              "lat": 43.76717805,
              "lng": -116.07323975,
              "alt": 1806.2075,
              "speed2d": 4.4155,
              "speed3d": 4.53,
              "msFromStart": 70693.67999999998
            },
            {
              "lat": 43.76717977810849,
              "lng": -116.07325241629859,
              "alt": 1805.7050772924774,
              "speed2d": 4.2474635141477,
              "speed3d": 4.381072971704603,
              "msFromStart": 71000
            },
            {
              "lat": 43.76719115,
              "lng": -116.07329119999999,
              "alt": 1805.5549999999998,
              "speed2d": 4.3165000000000004,
              "speed3d": 4.3975,
              "msFromStart": 71820.03711111116
            },
            {
              "lat": 43.76719656666667,
              "lng": -116.07331060000001,
              "alt": 1805.7416666666666,
              "speed2d": 4.431,
              "speed3d": 4.51,
              "msFromStart": 72177.21471345032
            },
            {
              "lat": 43.767203047873764,
              "lng": -116.07332486723956,
              "alt": 1806.101342569231,
              "speed2d": 4.425880871191844,
              "speed3d": 4.486744702866081,
              "msFromStart": 72500
            },
            {
              "lat": 43.767214540000005,
              "lng": -116.07336848,
              "alt": 1806.3272000000002,
              "speed2d": 4.5594,
              "speed3d": 4.646,
              "msFromStart": 73333.69066666666
            },
            {
              "lat": 43.76721742677354,
              "lng": -116.07337745811091,
              "alt": 1806.531004968043,
              "speed2d": 4.6161192952150625,
              "speed3d": 4.697119295215064,
              "msFromStart": 73500
            },
            {
              "lat": 43.76722835,
              "lng": -116.073420825,
              "alt": 1807.0875,
              "speed2d": 4.64025,
              "speed3d": 4.7175,
              "msFromStart": 74366.67133333332
            },
            {
              "lat": 43.767231800000005,
              "lng": -116.0734394,
              "alt": 1807.1950000000002,
              "speed2d": 4.6765,
              "speed3d": 4.745,
              "msFromStart": 74709.71599999999
            },
            {
              "lat": 43.767236039407365,
              "lng": -116.07345480371256,
              "alt": 1807.3094158721158,
              "speed2d": 4.7060384878298285,
              "speed3d": 4.746277248077131,
              "msFromStart": 75000
            },
            {
              "lat": 43.76724481666667,
              "lng": -116.07349943333332,
              "alt": 1807.6631666666667,
              "speed2d": 4.871666666666666,
              "speed3d": 4.926666666666667,
              "msFromStart": 75816.16666666666
            },
            {
              "lat": 43.76724744464687,
              "lng": -116.07350653644619,
              "alt": 1808.202854213479,
              "speed2d": 4.848747150870509,
              "speed3d": 4.909339406087849,
              "msFromStart": 76000
            },
            {
              "lat": 43.76725765,
              "lng": -116.07355305,
              "alt": 1808.359,
              "speed2d": 5.182499999999999,
              "speed3d": 5.225,
              "msFromStart": 76768.38533333341
            },
            {
              "lat": 43.767267075,
              "lng": -116.0735911,
              "alt": 1808.6465,
              "speed2d": 5.46325,
              "speed3d": 5.494999999999999,
              "msFromStart": 77360.24810526318
            },
            {
              "lat": 43.76727056666667,
              "lng": -116.0736034,
              "alt": 1808.714,
              "speed2d": 5.472333333333334,
              "speed3d": 5.55,
              "msFromStart": 77567.31284210531
            },
            {
              "lat": 43.76727674162084,
              "lng": -116.07362548932225,
              "alt": 1808.7901621809488,
              "speed2d": 5.359739734462813,
              "speed3d": 5.429320235443829,
              "msFromStart": 78000
            },
            {
              "lat": 43.7672838171796,
              "lng": -116.07365064274843,
              "alt": 1808.8764765888757,
              "speed2d": 5.226934089648278,
              "speed3d": 5.295958918475258,
              "msFromStart": 78500
            },
            {
              "lat": 43.76729089273836,
              "lng": -116.07367579617461,
              "alt": 1808.9627909968026,
              "speed2d": 5.094128444833743,
              "speed3d": 5.162597601506687,
              "msFromStart": 79000
            },
            {
              "lat": 43.76729796829712,
              "lng": -116.07370094960078,
              "alt": 1809.0491054047295,
              "speed2d": 4.961322800019207,
              "speed3d": 5.029236284538116,
              "msFromStart": 79500
            },
            {
              "lat": 43.767304675735026,
              "lng": -116.07372705822748,
              "alt": 1809.0514791590465,
              "speed2d": 4.772311101040671,
              "speed3d": 4.8334488808325355,
              "msFromStart": 80000
            },
            {
              "lat": 43.7673161,
              "lng": -116.07376175,
              "alt": 1809.3425,
              "speed2d": 4.7125,
              "speed3d": 4.779999999999999,
              "msFromStart": 80733.87688888892
            },
            {
              "lat": 43.767324939999995,
              "lng": -116.07378492000001,
              "alt": 1809.5062,
              "speed2d": 4.671,
              "speed3d": 4.741999999999999,
              "msFromStart": 81245.08311111112
            },
            {
              "lat": 43.76733213333333,
              "lng": -116.07381023333332,
              "alt": 1809.2953333333335,
              "speed2d": 4.6433333333333335,
              "speed3d": 4.6899999999999995,
              "msFromStart": 81771.43807407406
            },
            {
              "lat": 43.767338325,
              "lng": -116.07383972500001,
              "alt": 1808.94375,
              "speed2d": 4.70375,
              "speed3d": 4.75,
              "msFromStart": 82304.65866666668
            },
            {
              "lat": 43.76734093333333,
              "lng": -116.07385583333333,
              "alt": 1808.868,
              "speed2d": 4.618666666666667,
              "speed3d": 4.7,
              "msFromStart": 82633.99466666672
            },
            {
              "lat": 43.7673442,
              "lng": -116.07388745,
              "alt": 1808.831,
              "speed2d": 4.477,
              "speed3d": 4.525,
              "msFromStart": 83265.42210526316
            },
            {
              "lat": 43.76735456666667,
              "lng": -116.07391528333333,
              "alt": 1809.2859999999998,
              "speed2d": 4.594833333333334,
              "speed3d": 4.651666666666666,
              "msFromStart": 83815.6494736842
            },
            {
              "lat": 43.7673599,
              "lng": -116.07392955,
              "alt": 1809.6,
              "speed2d": 4.590999999999999,
              "speed3d": 4.635,
              "msFromStart": 84118.27452631578
            },
            {
              "lat": 43.767372566666666,
              "lng": -116.073959,
              "alt": 1810.4940000000001,
              "speed2d": 4.9223333333333334,
              "speed3d": 4.9366666666666665,
              "msFromStart": 84648.92696296301
            },
            {
              "lat": 43.76737295755193,
              "lng": -116.07398125727613,
              "alt": 1809.7660071007006,
              "speed2d": 4.914656730466263,
              "speed3d": 4.9445194226527684,
              "msFromStart": 85000
            },
            {
              "lat": 43.76737451862367,
              "lng": -116.0740115824109,
              "alt": 1808.7814730526309,
              "speed2d": 4.859530317732741,
              "speed3d": 4.856275264777285,
              "msFromStart": 85500
            },
            {
              "lat": 43.76737722,
              "lng": -116.0740524,
              "alt": 1807.9176000000002,
              "speed2d": 4.8244,
              "speed3d": 4.85,
              "msFromStart": 86197.38071111112
            },
            {
              "lat": 43.767391149999995,
              "lng": -116.0740804,
              "alt": 1808.9589999999998,
              "speed2d": 4.884,
              "speed3d": 4.925000000000001,
              "msFromStart": 86784.96066666662
            },
            {
              "lat": 43.76740115,
              "lng": -116.07410237500001,
              "alt": 1809.84425,
              "speed2d": 4.95625,
              "speed3d": 4.9825,
              "msFromStart": 87223.92956140349
            },
            {
              "lat": 43.76740828268213,
              "lng": -116.0741179778203,
              "alt": 1810.4236271561242,
              "speed2d": 5.1306800095659435,
              "speed3d": 5.129111137683175,
              "msFromStart": 87500
            },
            {
              "lat": 43.76741845733421,
              "lng": -116.07414594937241,
              "alt": 1810.745649649832,
              "speed2d": 5.136973608791507,
              "speed3d": 5.1465933577541865,
              "msFromStart": 88000
            },
            {
              "lat": 43.767417546295725,
              "lng": -116.07418097164613,
              "alt": 1809.300875067773,
              "speed2d": 4.783183423249304,
              "speed3d": 4.987464959604896,
              "msFromStart": 88500
            },
            {
              "lat": 43.767449528571426,
              "lng": -116.07421378571428,
              "alt": 1812.2097142857144,
              "speed2d": 5.251857142857142,
              "speed3d": 5.295714285714285,
              "msFromStart": 89286.3852698413
            },
            {
              "lat": 43.76745776666666,
              "lng": -116.0742301,
              "alt": 1812.4050000000002,
              "speed2d": 5.1315,
              "speed3d": 5.2,
              "msFromStart": 89644.16066666668
            },
            {
              "lat": 43.767472425,
              "lng": -116.074266825,
              "alt": 1813.05525,
              "speed2d": 5.09025,
              "speed3d": 5.13,
              "msFromStart": 90345.29299999999
            },
            {
              "lat": 43.76747017971881,
              "lng": -116.07427980169336,
              "alt": 1812.747540857642,
              "speed2d": 5.000969007247352,
              "speed3d": 5.089309849230027,
              "msFromStart": 90500
            },
            {
              "lat": 43.767473246634836,
              "lng": -116.0743096109526,
              "alt": 1812.6526306372366,
              "speed2d": 4.8706494477514575,
              "speed3d": 4.929389668650874,
              "msFromStart": 91000
            },
            {
              "lat": 43.76748515,
              "lng": -116.07435724999999,
              "alt": 1813.3355000000001,
              "speed2d": 4.961500000000001,
              "speed3d": 5.005,
              "msFromStart": 91814.26888888891
            },
            {
              "lat": 43.767493599999995,
              "lng": -116.07439310000001,
              "alt": 1813.7730000000001,
              "speed2d": 4.9465,
              "speed3d": 4.995,
              "msFromStart": 92447.23410526318
            },
            {
              "lat": 43.767498599999996,
              "lng": -116.0744105,
              "alt": 1813.939,
              "speed2d": 4.989,
              "speed3d": 5.025,
              "msFromStart": 92750.43578947373
            },
            {
              "lat": 43.76750335,
              "lng": -116.07442815,
              "alt": 1814.172,
              "speed2d": 5.0275,
              "speed3d": 5.085,
              "msFromStart": 93053.63747368428
            },
            {
              "lat": 43.76751531666667,
              "lng": -116.07446551666666,
              "alt": 1815.1405,
              "speed2d": 5.083833333333334,
              "speed3d": 5.126666666666667,
              "msFromStart": 93714.1822222222
            },
            {
              "lat": 43.7675197115688,
              "lng": -116.07448172693425,
              "alt": 1815.4010580248816,
              "speed2d": 5.07682951290823,
              "speed3d": 5.125852435458849,
              "msFromStart": 94000
            },
            {
              "lat": 43.7675286,
              "lng": -116.0745222,
              "alt": 1815.8781999999999,
              "speed2d": 5.054,
              "speed3d": 5.122,
              "msFromStart": 94698.7696
            },
            {
              "lat": 43.76752765,
              "lng": -116.074541,
              "alt": 1815.748,
              "speed2d": 4.885999999999999,
              "speed3d": 4.945,
              "msFromStart": 95034.25333333333
            },
            {
              "lat": 43.767538928571426,
              "lng": -116.07458552857143,
              "alt": 1816.35,
              "speed2d": 4.904857142857143,
              "speed3d": 4.952857142857143,
              "msFromStart": 95816.92019047611
            },
            {
              "lat": 43.767546475,
              "lng": -116.074611775,
              "alt": 1816.94175,
              "speed2d": 4.91275,
              "speed3d": 4.96,
              "msFromStart": 96286.7872222222
            },
            {
              "lat": 43.767553066666665,
              "lng": -116.07463771666667,
              "alt": 1817.2461666666666,
              "speed2d": 4.931333333333333,
              "speed3d": 4.993333333333333,
              "msFromStart": 96740.07288888894
            },
            {
              "lat": 43.7675601,
              "lng": -116.07465905714287,
              "alt": 1818.0741428571428,
              "speed2d": 4.703571428571428,
              "speed3d": 4.785714285714286,
              "msFromStart": 97207.03691228073
            },
            {
              "lat": 43.767564014791596,
              "lng": -116.07467183911758,
              "alt": 1818.58697060045,
              "speed2d": 4.571778735923659,
              "speed3d": 4.653814190404739,
              "msFromStart": 97500
            },
            {
              "lat": 43.767573549999994,
              "lng": -116.07471445,
              "alt": 1819.6860000000001,
              "speed2d": 4.4785,
              "speed3d": 4.52,
              "msFromStart": 98362.03555555554
            },
            {
              "lat": 43.767575300000004,
              "lng": -116.074730975,
              "alt": 1819.9852500000002,
              "speed2d": 4.5415,
              "speed3d": 4.585,
              "msFromStart": 98650.89988888885
            },
            {
              "lat": 43.76758223333333,
              "lng": -116.07476316666666,
              "alt": 1820.3439999999998,
              "speed2d": 4.745666666666667,
              "speed3d": 4.773333333333333,
              "msFromStart": 99187.33762962956
            },
            {
              "lat": 43.767587103923304,
              "lng": -116.07478154761912,
              "alt": 1820.5735889906873,
              "speed2d": 4.809351434533334,
              "speed3d": 4.844159090103561,
              "msFromStart": 99500
            },
            {
              "lat": 43.76759558339289,
              "lng": -116.07481049390371,
              "alt": 1820.8431149883372,
              "speed2d": 4.861338658799154,
              "speed3d": 4.8921084717079575,
              "msFromStart": 100000
            },
            {
              "lat": 43.76760406286248,
              "lng": -116.07483944018828,
              "alt": 1821.1126409859871,
              "speed2d": 4.913325883064973,
              "speed3d": 4.940057853312354,
              "msFromStart": 100500
            },
            {
              "lat": 43.76762035,
              "lng": -116.07489235,
              "alt": 1821.7485,
              "speed2d": 5.0280000000000005,
              "speed3d": 5.05,
              "msFromStart": 101416.17711111113
            },
            {
              "lat": 43.767622520656225,
              "lng": -116.0748970691584,
              "alt": 1821.9366057072862,
              "speed2d": 5.055802567618672,
              "speed3d": 5.079840228130717,
              "msFromStart": 101500
            },
            {
              "lat": 43.767630746584636,
              "lng": -116.0749246101121,
              "alt": 1822.5760466620181,
              "speed2d": 4.977179052928834,
              "speed3d": 5.016214262485762,
              "msFromStart": 102000
            },
            {
              "lat": 43.767644575000006,
              "lng": -116.07497185000001,
              "alt": 1823.5755,
              "speed2d": 4.91725,
              "speed3d": 4.975,
              "msFromStart": 102846.16463157898
            },
            {
              "lat": 43.76764998,
              "lng": -116.07499240000001,
              "alt": 1823.8900000000003,
              "speed2d": 4.9030000000000005,
              "speed3d": 4.964,
              "msFromStart": 103214.30783157899
            },
            {
              "lat": 43.76765896,
              "lng": -116.0750272,
              "alt": 1824.2618,
              "speed2d": 4.9466,
              "speed3d": 4.994,
              "msFromStart": 103818.94639999994
            },
            {
              "lat": 43.76766605,
              "lng": -116.07505175,
              "alt": 1824.6635,
              "speed2d": 4.970000000000001,
              "speed3d": 5.025,
              "msFromStart": 104247.84622222217
            },
            {
              "lat": 43.767674666666665,
              "lng": -116.07507163333332,
              "alt": 1825.4596666666666,
              "speed2d": 4.952999999999999,
              "speed3d": 5.02,
              "msFromStart": 104632.94577777776
            },
            {
              "lat": 43.767690900000005,
              "lng": -116.0751104,
              "alt": 1826.9234999999999,
              "speed2d": 5.0385,
              "speed3d": 5.09,
              "msFromStart": 105348.536
            },
            {
              "lat": 43.767697249539225,
              "lng": -116.07511822534647,
              "alt": 1827.9841739351282,
              "speed2d": 5.130027646760027,
              "speed3d": 5.140841012988896,
              "msFromStart": 105500
            },
            {
              "lat": 43.7676994,
              "lng": -116.07515924,
              "alt": 1826.6847999999998,
              "speed2d": 4.9904,
              "speed3d": 5.064,
              "msFromStart": 106153.23253333327
            },
            {
              "lat": 43.76770182,
              "lng": -116.07519873999999,
              "alt": 1825.4607999999998,
              "speed2d": 4.926,
              "speed3d": 4.992,
              "msFromStart": 106779.86222222217
            },
            {
              "lat": 43.767712450000005,
              "lng": -116.07523052500001,
              "alt": 1826.76025,
              "speed2d": 4.8332500000000005,
              "speed3d": 4.8975,
              "msFromStart": 107384.36505263158
            },
            {
              "lat": 43.76771517499999,
              "lng": -116.07524662499999,
              "alt": 1827.0722500000002,
              "speed2d": 4.6850000000000005,
              "speed3d": 4.7749999999999995,
              "msFromStart": 107700.6707368421
            },
            {
              "lat": 43.76771511490567,
              "lng": -116.07526204114204,
              "alt": 1826.906954022602,
              "speed2d": 4.490536544298078,
              "speed3d": 4.549955312885971,
              "msFromStart": 108000
            },
            {
              "lat": 43.76772246421198,
              "lng": -116.07528942705359,
              "alt": 1827.5453989390749,
              "speed2d": 4.585090750708585,
              "speed3d": 4.615976025170972,
              "msFromStart": 108500
            },
            {
              "lat": 43.76773315,
              "lng": -116.0753234,
              "alt": 1828.4985,
              "speed2d": 4.7035,
              "speed3d": 4.72,
              "msFromStart": 109118.17044444437
            },
            {
              "lat": 43.7677403895316,
              "lng": -116.07534497304755,
              "alt": 1829.0858147619513,
              "speed2d": 4.790890499680264,
              "speed3d": 4.811612522554443,
              "msFromStart": 109500
            },
            {
              "lat": 43.76774847506128,
              "lng": -116.07537282212374,
              "alt": 1829.8393883023684,
              "speed2d": 4.844938443291462,
              "speed3d": 4.878543789616538,
              "msFromStart": 110000
            },
            {
              "lat": 43.76775852593293,
              "lng": -116.07540151063536,
              "alt": 1830.568274349226,
              "speed2d": 4.918935018081023,
              "speed3d": 4.957684511316602,
              "msFromStart": 110500
            },
            {
              "lat": 43.76776753071096,
              "lng": -116.07543097093479,
              "alt": 1831.3058209331443,
              "speed2d": 5.037489137547969,
              "speed3d": 5.063722843869926,
              "msFromStart": 111000
            },
            {
              "lat": 43.76777899648816,
              "lng": -116.07545869353136,
              "alt": 1832.029051769564,
              "speed2d": 5.058218110819369,
              "speed3d": 5.111848425682808,
              "msFromStart": 111500
            },
            {
              "lat": 43.76779181666666,
              "lng": -116.07550101666668,
              "alt": 1833.151,
              "speed2d": 5.004833333333333,
              "speed3d": 5.055,
              "msFromStart": 112270.14584405461
            },
            {
              "lat": 43.767803799999996,
              "lng": -116.07552865555556,
              "alt": 1834.2715555555556,
              "speed2d": 5.079666666666667,
              "speed3d": 5.144444444444445,
              "msFromStart": 112774.44336842104
            },
            {
              "lat": 43.76781208888889,
              "lng": -116.07555552222222,
              "alt": 1835.0483333333334,
              "speed2d": 5.016222222222222,
              "speed3d": 5.093333333333334,
              "msFromStart": 113269.56851461987
            },
            {
              "lat": 43.7678167,
              "lng": -116.0755818,
              "alt": 1835.7326000000003,
              "speed2d": 4.8552,
              "speed3d": 4.936,
              "msFromStart": 113764.648
            },
            {
              "lat": 43.767819415997394,
              "lng": -116.07559449752071,
              "alt": 1836.1634034302879,
              "speed2d": 4.8522046821538725,
              "speed3d": 4.8860705576179,
              "msFromStart": 114000
            },
            {
              "lat": 43.76782735808017,
              "lng": -116.07562454696821,
              "alt": 1836.3396615683548,
              "speed2d": 4.9497020190320224,
              "speed3d": 5.015606059789782,
              "msFromStart": 114500
            },
            {
              "lat": 43.76783922727857,
              "lng": -116.0756519723926,
              "alt": 1837.2270735510936,
              "speed2d": 4.9714201267484475,
              "speed3d": 5.030758227964033,
              "msFromStart": 115000
            },
            {
              "lat": 43.76785014323975,
              "lng": -116.07568031923287,
              "alt": 1837.8999684139783,
              "speed2d": 5.000511549183592,
              "speed3d": 5.046221127041018,
              "msFromStart": 115500
            },
            {
              "lat": 43.76787725,
              "lng": -116.0757222,
              "alt": 1840.8835,
              "speed2d": 4.991,
              "speed3d": 5.07,
              "msFromStart": 116376.68688888887
            },
            {
              "lat": 43.76788220250732,
              "lng": -116.07572491557626,
              "alt": 1841.500281128685,
              "speed2d": 4.879080543005937,
              "speed3d": 4.9265653447128654,
              "msFromStart": 116500
            },
            {
              "lat": 43.76789193333333,
              "lng": -116.07576809999999,
              "alt": 1842.4996666666666,
              "speed2d": 4.9590000000000005,
              "speed3d": 5.036666666666666,
              "msFromStart": 117210.99525925922
            },
            {
              "lat": 43.767900600000004,
              "lng": -116.0758016,
              "alt": 1843.502,
              "speed2d": 4.9295,
              "speed3d": 5.005,
              "msFromStart": 117809.29999999994
            },
            {
              "lat": 43.767909575,
              "lng": -116.075831925,
              "alt": 1844.4837499999999,
              "speed2d": 4.917,
              "speed3d": 5.015000000000001,
              "msFromStart": 118360.85789473682
            },
            {
              "lat": 43.76791665,
              "lng": -116.0758488,
              "alt": 1845.4317500000002,
              "speed2d": 4.917,
              "speed3d": 5.005,
              "msFromStart": 118689.89284210523
            },
            {
              "lat": 43.767925875,
              "lng": -116.075878625,
              "alt": 1846.2559999999999,
              "speed2d": 4.85475,
              "speed3d": 4.9525,
              "msFromStart": 119252.15804093564
            },
            {
              "lat": 43.7679353125,
              "lng": -116.07590247499998,
              "alt": 1847.3231250000001,
              "speed2d": 4.804124999999999,
              "speed3d": 4.90125,
              "msFromStart": 119732.95088888891
            },
            {
              "lat": 43.76794816666666,
              "lng": -116.07592981666667,
              "alt": 1849.1201666666668,
              "speed2d": 4.7535,
              "speed3d": 4.845000000000001,
              "msFromStart": 120319.31029629632
            },
            {
              "lat": 43.767957275,
              "lng": -116.07594685,
              "alt": 1850.6864999999998,
              "speed2d": 4.807,
              "speed3d": 4.9075,
              "msFromStart": 120681.23544444445
            },
            {
              "lat": 43.7679683,
              "lng": -116.0759751,
              "alt": 1852.490428571429,
              "speed2d": 4.897142857142858,
              "speed3d": 4.998571428571429,
              "msFromStart": 121221.08266666668
            },
            {
              "lat": 43.767973133333335,
              "lng": -116.0759947,
              "alt": 1853.1103333333335,
              "speed2d": 4.886,
              "speed3d": 5.003333333333334,
              "msFromStart": 121574.00266666664
            },
            {
              "lat": 43.76798115,
              "lng": -116.0760257,
              "alt": 1854.0214999999998,
              "speed2d": 4.8265,
              "speed3d": 4.945,
              "msFromStart": 122150.34884210522
            },
            {
              "lat": 43.76798557512521,
              "lng": -116.0760463182399,
              "alt": 1854.2681944187452,
              "speed2d": 4.833569854571593,
              "speed3d": 4.992140710779448,
              "msFromStart": 122500
            },
            {
              "lat": 43.767993561982045,
              "lng": -116.07607024177898,
              "alt": 1855.33110017467,
              "speed2d": 4.706748418016611,
              "speed3d": 4.819202388204468,
              "msFromStart": 123000
            },
            {
              "lat": 43.76800646666666,
              "lng": -116.07611376666665,
              "alt": 1856.7506666666666,
              "speed2d": 4.690333333333333,
              "speed3d": 4.793333333333334,
              "msFromStart": 123846.80607407412
            },
            {
              "lat": 43.76801116666667,
              "lng": -116.07613363333334,
              "alt": 1857.3083333333334,
              "speed2d": 4.693333333333333,
              "speed3d": 4.816666666666666,
              "msFromStart": 124213.2654814815
            },
            {
              "lat": 43.76801925,
              "lng": -116.0761694,
              "alt": 1858.544,
              "speed2d": 4.612,
              "speed3d": 4.735,
              "msFromStart": 124900.66266666666
            },
            {
              "lat": 43.76802335,
              "lng": -116.0761849,
              "alt": 1858.9270000000001,
              "speed2d": 4.679,
              "speed3d": 4.779999999999999,
              "msFromStart": 125175.63599999998
            },
            {
              "lat": 43.76803566666667,
              "lng": -116.07621736666667,
              "alt": 1860.3880000000001,
              "speed2d": 4.684333333333334,
              "speed3d": 4.786666666666667,
              "msFromStart": 125827.41200000004
            },
            {
              "lat": 43.768044159999995,
              "lng": -116.07623426,
              "alt": 1861.2728,
              "speed2d": 4.7514,
              "speed3d": 4.866,
              "msFromStart": 126161.60017777783
            },
            {
              "lat": 43.768059631004405,
              "lng": -116.07624758751123,
              "alt": 1863.0555764175645,
              "speed2d": 4.851352839573007,
              "speed3d": 5.005493450557971,
              "msFromStart": 126500
            },
            {
              "lat": 43.76806643333334,
              "lng": -116.0763009,
              "alt": 1863.0140000000001,
              "speed2d": 5.031666666666667,
              "speed3d": 5.133333333333334,
              "msFromStart": 127294.89234307995
            },
            {
              "lat": 43.76807225,
              "lng": -116.07631415,
              "alt": 1863.627,
              "speed2d": 5.0395,
              "speed3d": 5.155,
              "msFromStart": 127542.70652631577
            },
            {
              "lat": 43.76808202857143,
              "lng": -116.07635451428571,
              "alt": 1864.370142857143,
              "speed2d": 4.901142857142857,
              "speed3d": 5.028571428571428,
              "msFromStart": 128286.144160401
            },
            {
              "lat": 43.768087900000005,
              "lng": -116.07638027499999,
              "alt": 1864.84225,
              "speed2d": 4.848,
              "speed3d": 4.9775,
              "msFromStart": 128753.57288888894
            },
            {
              "lat": 43.76809253027902,
              "lng": -116.07639334855395,
              "alt": 1865.3655459462518,
              "speed2d": 4.840552342116007,
              "speed3d": 4.965733299351506,
              "msFromStart": 129000
            },
            {
              "lat": 43.76810871666667,
              "lng": -116.07643621666668,
              "alt": 1866.9851666666666,
              "speed2d": 4.937166666666667,
              "speed3d": 5.056666666666667,
              "msFromStart": 129778.9747407407
            },
            {
              "lat": 43.768117933333336,
              "lng": -116.07646706666665,
              "alt": 1867.4973333333332,
              "speed2d": 5.021333333333334,
              "speed3d": 5.136666666666667,
              "msFromStart": 130300.43318518516
            },
            {
              "lat": 43.7681274,
              "lng": -116.07648884,
              "alt": 1868.4436,
              "speed2d": 5.0526,
              "speed3d": 5.1819999999999995,
              "msFromStart": 130703.5493333333
            },
            {
              "lat": 43.76813718,
              "lng": -116.07652276000002,
              "alt": 1869.2976,
              "speed2d": 4.981,
              "speed3d": 5.12,
              "msFromStart": 131319.51279999997
            },
            {
              "lat": 43.76814215,
              "lng": -116.0765425,
              "alt": 1869.7469999999998,
              "speed2d": 4.8875,
              "speed3d": 5.03,
              "msFromStart": 131694.19199999995
            },
            {
              "lat": 43.768146202898414,
              "lng": -116.07655894225731,
              "alt": 1870.0448514966417,
              "speed2d": 4.841160692280994,
              "speed3d": 4.963503686993446,
              "msFromStart": 132000
            },
            {
              "lat": 43.76815513333333,
              "lng": -116.07659423333334,
              "alt": 1871.0386666666668,
              "speed2d": 4.774666666666666,
              "speed3d": 4.923333333333334,
              "msFromStart": 132649.13748148142
            },
            {
              "lat": 43.768164299999995,
              "lng": -116.07663604999999,
              "alt": 1871.501,
              "speed2d": 4.82,
              "speed3d": 4.9399999999999995,
              "msFromStart": 133373.88947368422
            },
            {
              "lat": 43.768173966666666,
              "lng": -116.0766608,
              "alt": 1872.5368333333333,
              "speed2d": 4.945666666666667,
              "speed3d": 5.0649999999999995,
              "msFromStart": 133813.47178947376
            },
            {
              "lat": 43.76818142222223,
              "lng": -116.07668351111113,
              "alt": 1873.2536666666667,
              "speed2d": 4.967111111111112,
              "speed3d": 5.095555555555556,
              "msFromStart": 134225.53086939576
            },
            {
              "lat": 43.7681868,
              "lng": -116.07670055,
              "alt": 1873.7055,
              "speed2d": 5.005,
              "speed3d": 5.125,
              "msFromStart": 134527.45999999996
            },
            {
              "lat": 43.76819605,
              "lng": -116.07674825000001,
              "alt": 1874.2235,
              "speed2d": 4.8145,
              "speed3d": 4.975,
              "msFromStart": 135378.4728888889
            },
            {
              "lat": 43.768201559999994,
              "lng": -116.07677304,
              "alt": 1874.5900000000001,
              "speed2d": 4.8306000000000004,
              "speed3d": 4.970000000000001,
              "msFromStart": 135819.34044444456
            },
            {
              "lat": 43.768208275,
              "lng": -116.07680095,
              "alt": 1875.0462499999999,
              "speed2d": 4.866,
              "speed3d": 4.99,
              "msFromStart": 136315.32755555562
            },
            {
              "lat": 43.768216566666666,
              "lng": -116.07683200000001,
              "alt": 1875.5299999999997,
              "speed2d": 4.895666666666667,
              "speed3d": 5.02,
              "msFromStart": 136866.49866666662
            },
            {
              "lat": 43.76822185,
              "lng": -116.07685175,
              "alt": 1875.8625,
              "speed2d": 4.8825,
              "speed3d": 5.015,
              "msFromStart": 137224.6435087719
            },
            {
              "lat": 43.7682308,
              "lng": -116.07688456666668,
              "alt": 1876.4213333333332,
              "speed2d": 4.927333333333333,
              "speed3d": 5.053333333333334,
              "msFromStart": 137801.1368421053
            },
            {
              "lat": 43.76823553333333,
              "lng": -116.07690016666666,
              "alt": 1876.748,
              "speed2d": 4.967666666666666,
              "speed3d": 5.09,
              "msFromStart": 138075.54526315795
            },
            {
              "lat": 43.7682439918962,
              "lng": -116.07692336655653,
              "alt": 1877.663204432267,
              "speed2d": 5.036927326940135,
              "speed3d": 5.134771697961172,
              "msFromStart": 138500
            },
            {
              "lat": 43.7682581,
              "lng": -116.07696995,
              "alt": 1878.7604999999999,
              "speed2d": 5.0795,
              "speed3d": 5.23,
              "msFromStart": 139315.32133333333
            },
            {
              "lat": 43.76826371428571,
              "lng": -116.0769900857143,
              "alt": 1879.1055714285715,
              "speed2d": 5.042571428571429,
              "speed3d": 5.188571428571429,
              "msFromStart": 139673.20266666674
            },
            {
              "lat": 43.768271262499994,
              "lng": -116.0770245125,
              "alt": 1879.482125,
              "speed2d": 4.852625000000001,
              "speed3d": 5.0075,
              "msFromStart": 140306.08511111117
            },
            {
              "lat": 43.76827626666666,
              "lng": -116.07704666666666,
              "alt": 1879.7296666666666,
              "speed2d": 4.7636666666666665,
              "speed3d": 4.9,
              "msFromStart": 140717.44711111113
            },
            {
              "lat": 43.768285425,
              "lng": -116.07707769999999,
              "alt": 1880.4432499999998,
              "speed2d": 4.8205,
              "speed3d": 4.9425,
              "msFromStart": 141279.61200000002
            },
            {
              "lat": 43.7682922,
              "lng": -116.07711233333333,
              "alt": 1880.5213333333331,
              "speed2d": 4.636666666666667,
              "speed3d": 4.806666666666667,
              "msFromStart": 141926.11200000008
            },
            {
              "lat": 43.768293549999996,
              "lng": -116.0771257,
              "alt": 1880.4189999999999,
              "speed2d": 4.563,
              "speed3d": 4.71,
              "msFromStart": 142173.8075555556
            },
            {
              "lat": 43.76830412857142,
              "lng": -116.07716168571429,
              "alt": 1881.392285714286,
              "speed2d": 4.7978571428571435,
              "speed3d": 4.921428571428572,
              "msFromStart": 142807.45777777763
            },
            {
              "lat": 43.76830658333334,
              "lng": -116.0771833,
              "alt": 1881.389333333333,
              "speed2d": 4.7325,
              "speed3d": 4.873333333333333,
              "msFromStart": 143183.9445458088
            },
            {
              "lat": 43.768319383333335,
              "lng": -116.07721889999999,
              "alt": 1882.717,
              "speed2d": 4.984166666666667,
              "speed3d": 5.101666666666666,
              "msFromStart": 143806.169122807
            },
            {
              "lat": 43.76832691111111,
              "lng": -116.07724253333333,
              "alt": 1883.516222222222,
              "speed2d": 4.950444444444445,
              "speed3d": 5.0922222222222215,
              "msFromStart": 144235.9918596491
            },
            {
              "lat": 43.76833402500001,
              "lng": -116.0772686125,
              "alt": 1884.0124999999998,
              "speed2d": 4.906375000000001,
              "speed3d": 5.0425,
              "msFromStart": 144702.7760000001
            },
            {
              "lat": 43.76834255,
              "lng": -116.07730475,
              "alt": 1883.9995,
              "speed2d": 4.9785,
              "speed3d": 5.085,
              "msFromStart": 145307.1422222223
            },
            {
              "lat": 43.768352,
              "lng": -116.07734276666667,
              "alt": 1884.2663333333335,
              "speed2d": 5.012333333333333,
              "speed3d": 5.156666666666666,
              "msFromStart": 145939.60533333322
            },
            {
              "lat": 43.76835663333334,
              "lng": -116.07736196666667,
              "alt": 1884.6486666666665,
              "speed2d": 4.926333333333333,
              "speed3d": 5.066666666666666,
              "msFromStart": 146288.0577777777
            },
            {
              "lat": 43.76836045,
              "lng": -116.0773782,
              "alt": 1884.9085,
              "speed2d": 4.914,
              "speed3d": 5.045,
              "msFromStart": 146572.4315555555
            },
            {
              "lat": 43.76837243333333,
              "lng": -116.0774234,
              "alt": 1885.6913333333332,
              "speed2d": 4.919666666666666,
              "speed3d": 5.04,
              "msFromStart": 147370.6148148148
            },
            {
              "lat": 43.76837927142858,
              "lng": -116.0774474,
              "alt": 1886.297142857143,
              "speed2d": 4.936857142857143,
              "speed3d": 5.065714285714285,
              "msFromStart": 147795.09485714292
            },
            {
              "lat": 43.768384733333335,
              "lng": -116.0774673,
              "alt": 1886.8136666666664,
              "speed2d": 4.8919999999999995,
              "speed3d": 5.029999999999999,
              "msFromStart": 148159.30133333337
            },
            {
              "lat": 43.76839395,
              "lng": -116.07749978333334,
              "alt": 1887.6785,
              "speed2d": 4.7845,
              "speed3d": 4.926666666666667,
              "msFromStart": 148773.96800000002
            },
            {
              "lat": 43.768401000000004,
              "lng": -116.077525675,
              "alt": 1888.36875,
              "speed2d": 4.727,
              "speed3d": 4.86,
              "msFromStart": 149269.3950175439
            },
            {
              "lat": 43.768410450000005,
              "lng": -116.0775625,
              "alt": 1889.1515,
              "speed2d": 4.811999999999999,
              "speed3d": 4.93,
              "msFromStart": 149929.5813333334
            },
            {
              "lat": 43.76841385,
              "lng": -116.077576775,
              "alt": 1889.43675,
              "speed2d": 4.796,
              "speed3d": 4.93,
              "msFromStart": 150190.88000000006
            },
            {
              "lat": 43.7684201,
              "lng": -116.077602,
              "alt": 1889.9305,
              "speed2d": 4.8105,
              "speed3d": 4.925,
              "msFromStart": 150644.6275555555
            },
            {
              "lat": 43.76842835,
              "lng": -116.07763084999999,
              "alt": 1890.6495,
              "speed2d": 4.832,
              "speed3d": 4.96,
              "msFromStart": 151167.06799999994
            },
            {
              "lat": 43.76843754,
              "lng": -116.07766078,
              "alt": 1891.4638000000002,
              "speed2d": 4.8354,
              "speed3d": 4.964,
              "msFromStart": 151716.9991111112
            },
            {
              "lat": 43.768444650000006,
              "lng": -116.07768709999999,
              "alt": 1892.039,
              "speed2d": 4.819,
              "speed3d": 4.9625,
              "msFromStart": 152198.17711111117
            },
            {
              "lat": 43.76845335,
              "lng": -116.07772095,
              "alt": 1892.7115,
              "speed2d": 4.797499999999999,
              "speed3d": 4.915,
              "msFromStart": 152816.759111111
            },
            {
              "lat": 43.768458249999995,
              "lng": -116.07774284999999,
              "alt": 1893.02,
              "speed2d": 4.8205,
              "speed3d": 4.96,
              "msFromStart": 153201.68261988295
            },
            {
              "lat": 43.76846515,
              "lng": -116.07777595,
              "alt": 1893.5555,
              "speed2d": 4.846,
              "speed3d": 4.985,
              "msFromStart": 153779.3599999999
            },
            {
              "lat": 43.768472149999994,
              "lng": -116.07780375,
              "alt": 1894.3025,
              "speed2d": 4.7524999999999995,
              "speed3d": 4.9,
              "msFromStart": 154302.08807017538
            },
            {
              "lat": 43.768476775,
              "lng": -116.077820925,
              "alt": 1894.65575,
              "speed2d": 4.58425,
              "speed3d": 4.762499999999999,
              "msFromStart": 154659.7231111112
            },
            {
              "lat": 43.768483716666665,
              "lng": -116.07785608333333,
              "alt": 1895.0295,
              "speed2d": 4.522666666666666,
              "speed3d": 4.665,
              "msFromStart": 155319.88577777785
            },
            {
              "lat": 43.7684901,
              "lng": -116.07787746666668,
              "alt": 1895.5103333333332,
              "speed2d": 4.591333333333333,
              "speed3d": 4.713333333333333,
              "msFromStart": 155713.71259259258
            },
            {
              "lat": 43.7685076,
              "lng": -116.07790765,
              "alt": 1896.7745,
              "speed2d": 5.0725,
              "speed3d": 5.12,
              "msFromStart": 156226.65288888887
            },
            {
              "lat": 43.76853152,
              "lng": -116.07794448,
              "alt": 1898.3562,
              "speed2d": 5.7844,
              "speed3d": 5.866,
              "msFromStart": 156777.7679999999
            },
            {
              "lat": 43.768541799999994,
              "lng": -116.07798030000001,
              "alt": 1899.3356666666668,
              "speed2d": 5.525,
              "speed3d": 5.7,
              "msFromStart": 157383.94666666666
            },
            {
              "lat": 43.76854478571429,
              "lng": -116.078001,
              "alt": 1899.5407142857143,
              "speed2d": 5.272714285714286,
              "speed3d": 5.430000000000001,
              "msFromStart": 157752.94590476181
            },
            {
              "lat": 43.768549433333334,
              "lng": -116.0780239,
              "alt": 1899.9956666666667,
              "speed2d": 5.0009999999999994,
              "speed3d": 5.1866666666666665,
              "msFromStart": 158180.81342690048
            },
            {
              "lat": 43.7685505973595,
              "lng": -116.0780389235282,
              "alt": 1900.1403028868287,
              "speed2d": 4.615003698701605,
              "speed3d": 4.844858728014972,
              "msFromStart": 158500
            },
            {
              "lat": 43.76856625,
              "lng": -116.07808725000001,
              "alt": 1901.408,
              "speed2d": 4.877,
              "speed3d": 4.985,
              "msFromStart": 159333.52343859657
            },
            {
              "lat": 43.768571225,
              "lng": -116.078104025,
              "alt": 1901.8795,
              "speed2d": 4.93075,
              "speed3d": 5.0625,
              "msFromStart": 159622.4304444444
            },
            {
              "lat": 43.7685817375,
              "lng": -116.07814256249999,
              "alt": 1902.7118749999997,
              "speed2d": 5.0287500000000005,
              "speed3d": 5.160000000000001,
              "msFromStart": 160269.47688888884
            },
            {
              "lat": 43.76858884000001,
              "lng": -116.07816937999999,
              "alt": 1903.324,
              "speed2d": 5.036,
              "speed3d": 5.17,
              "msFromStart": 160726.5626666667
            },
            {
              "lat": 43.768596779999996,
              "lng": -116.07819846000001,
              "alt": 1904.0348,
              "speed2d": 4.9796,
              "speed3d": 5.132,
              "msFromStart": 161233.19520000007
            },
            {
              "lat": 43.76860029188491,
              "lng": -116.0782131239251,
              "alt": 1904.252318461016,
              "speed2d": 4.856665372859143,
              "speed3d": 5.004665372859143,
              "msFromStart": 161500
            },
            {
              "lat": 43.76860621666667,
              "lng": -116.07824951666666,
              "alt": 1904.3176666666668,
              "speed2d": 4.636333333333333,
              "speed3d": 4.795,
              "msFromStart": 162158.5103703703
            },
            {
              "lat": 43.76861455,
              "lng": -116.07828715,
              "alt": 1904.8025,
              "speed2d": 4.5455000000000005,
              "speed3d": 4.68,
              "msFromStart": 162854.09866666666
            },
            {
              "lat": 43.7686232,
              "lng": -116.0783164,
              "alt": 1905.606,
              "speed2d": 4.6645,
              "speed3d": 4.789999999999999,
              "msFromStart": 163375.93515789477
            },
            {
              "lat": 43.7686305,
              "lng": -116.0783352,
              "alt": 1906.475,
              "speed2d": 4.824,
              "speed3d": 4.92,
              "msFromStart": 163706.12463157906
            },
            {
              "lat": 43.768636073802526,
              "lng": -116.07835158373703,
              "alt": 1907.068477099915,
              "speed2d": 4.863634964945224,
              "speed3d": 4.966466353215831,
              "msFromStart": 164000
            },
            {
              "lat": 43.76864460197597,
              "lng": -116.07837939215747,
              "alt": 1907.9340576972306,
              "speed2d": 4.875431304631605,
              "speed3d": 4.980003136462498,
              "msFromStart": 164500
            },
            {
              "lat": 43.76865313014942,
              "lng": -116.07840720057791,
              "alt": 1908.799638294546,
              "speed2d": 4.887227644317985,
              "speed3d": 4.993539919709164,
              "msFromStart": 165000
            },
            {
              "lat": 43.76866165832286,
              "lng": -116.07843500899835,
              "alt": 1909.6652188918613,
              "speed2d": 4.899023984004366,
              "speed3d": 5.00707670295583,
              "msFromStart": 165500
            },
            {
              "lat": 43.76867755,
              "lng": -116.07848525,
              "alt": 1911.2945,
              "speed2d": 4.949999999999999,
              "speed3d": 5.050000000000001,
              "msFromStart": 166401.664
            },
            {
              "lat": 43.76868793333333,
              "lng": -116.07851070000001,
              "alt": 1912.504,
              "speed2d": 5.112,
              "speed3d": 5.23,
              "msFromStart": 166841.34399999992
            },
            {
              "lat": 43.76869484999999,
              "lng": -116.07853065,
              "alt": 1913.2330000000002,
              "speed2d": 5.07575,
              "speed3d": 5.2299999999999995,
              "msFromStart": 167198.6276666666
            },
            {
              "lat": 43.76870038792728,
              "lng": -116.07854732933437,
              "alt": 1913.867138812905,
              "speed2d": 5.0416631945478905,
              "speed3d": 5.18065997515688,
              "msFromStart": 167500
            },
            {
              "lat": 43.76870271536402,
              "lng": -116.0785737912293,
              "alt": 1913.7986924289257,
              "speed2d": 4.617100835450012,
              "speed3d": 4.744501790250028,
              "msFromStart": 168000
            },
            {
              "lat": 43.7687106765765,
              "lng": -116.0786003839511,
              "alt": 1914.7719965342596,
              "speed2d": 4.631020217584231,
              "speed3d": 4.796135554059429,
              "msFromStart": 168500
            },
            {
              "lat": 43.7687184799283,
              "lng": -116.07862723184127,
              "alt": 1915.5745367999727,
              "speed2d": 4.645255313767906,
              "speed3d": 4.771673751690539,
              "msFromStart": 169000
            },
            {
              "lat": 43.76873002,
              "lng": -116.07866778,
              "alt": 1916.7534000000003,
              "speed2d": 4.728,
              "speed3d": 4.88,
              "msFromStart": 169735.077688889
            },
            {
              "lat": 43.76873674,
              "lng": -116.07869185999999,
              "alt": 1917.4958,
              "speed2d": 4.7488,
              "speed3d": 4.904,
              "msFromStart": 170174.0394666668
            },
            {
              "lat": 43.76874633333333,
              "lng": -116.07872896666666,
              "alt": 1918.4826666666665,
              "speed2d": 4.707333333333334,
              "speed3d": 4.863333333333333,
              "msFromStart": 170855.98577777774
            },
            {
              "lat": 43.7687522,
              "lng": -116.07875063333333,
              "alt": 1919.1599999999999,
              "speed2d": 4.695333333333334,
              "speed3d": 4.86,
              "msFromStart": 171259.51437037034
            },
            {
              "lat": 43.7687588,
              "lng": -116.07877553333333,
              "alt": 1919.8186666666668,
              "speed2d": 4.7010000000000005,
              "speed3d": 4.8566666666666665,
              "msFromStart": 171718.10400000008
            },
            {
              "lat": 43.76876523333334,
              "lng": -116.07880120000002,
              "alt": 1920.404,
              "speed2d": 4.750666666666667,
              "speed3d": 4.903333333333333,
              "msFromStart": 172176.72888888896
            },
            {
              "lat": 43.76877385,
              "lng": -116.078832925,
              "alt": 1921.1835,
              "speed2d": 4.819500000000001,
              "speed3d": 4.9675,
              "msFromStart": 172740.9873333333
            },
            {
              "lat": 43.768782083333335,
              "lng": -116.07885983333333,
              "alt": 1921.9609999999998,
              "speed2d": 4.849666666666667,
              "speed3d": 5,
              "msFromStart": 173231.7500818713
            },
            {
              "lat": 43.76879126200613,
              "lng": -116.07887555880922,
              "alt": 1923.025563113848,
              "speed2d": 5.12224970542087,
              "speed3d": 5.245014531438642,
              "msFromStart": 173500
            },
            {
              "lat": 43.76879903632314,
              "lng": -116.07890205958326,
              "alt": 1923.8273959557914,
              "speed2d": 5.026347546688786,
              "speed3d": 5.159894272208981,
              "msFromStart": 174000
            },
            {
              "lat": 43.76881285,
              "lng": -116.0789474,
              "alt": 1925.2385,
              "speed2d": 4.920999999999999,
              "speed3d": 5.0600000000000005,
              "msFromStart": 174845.83377777773
            },
            {
              "lat": 43.76882644,
              "lng": -116.07897679999999,
              "alt": 1927.0412000000001,
              "speed2d": 5.196000000000001,
              "speed3d": 5.3420000000000005,
              "msFromStart": 175348.1237333333
            },
            {
              "lat": 43.76883437777778,
              "lng": -116.0789986111111,
              "alt": 1928.107777777778,
              "speed2d": 5.1370000000000005,
              "speed3d": 5.325555555555555,
              "msFromStart": 175755.56977777774
            },
            {
              "lat": 43.76883774444444,
              "lng": -116.07902307777778,
              "alt": 1928.4265555555558,
              "speed2d": 4.775555555555555,
              "speed3d": 4.973333333333334,
              "msFromStart": 176250.96740740736
            },
            {
              "lat": 43.7688476,
              "lng": -116.0790529,
              "alt": 1929.413,
              "speed2d": 4.856,
              "speed3d": 5,
              "msFromStart": 176801.20000000004
            },
            {
              "lat": 43.7688565,
              "lng": -116.07908565,
              "alt": 1929.8564999999999,
              "speed2d": 4.869999999999999,
              "speed3d": 5.015000000000001,
              "msFromStart": 177378.65066666662
            },
            {
              "lat": 43.76886236666666,
              "lng": -116.0791093,
              "alt": 1930.094,
              "speed2d": 4.988333333333333,
              "speed3d": 5.14,
              "msFromStart": 177753.4939259258
            },
            {
              "lat": 43.76886663683069,
              "lng": -116.07912562843704,
              "alt": 1930.265426917511,
              "speed2d": 5.119789435600578,
              "speed3d": 5.288111432706223,
              "msFromStart": 178000
            },
            {
              "lat": 43.768878666666666,
              "lng": -116.07917366666666,
              "alt": 1930.625,
              "speed2d": 5.145333333333333,
              "speed3d": 5.313333333333333,
              "msFromStart": 178781.28
            },
            {
              "lat": 43.768882073729316,
              "lng": -116.07918598395499,
              "alt": 1930.799499296598,
              "speed2d": 5.094189848051064,
              "speed3d": 5.2729679234585785,
              "msFromStart": 179000
            },
            {
              "lat": 43.76889528333333,
              "lng": -116.07923375,
              "alt": 1931.593,
              "speed2d": 4.902666666666667,
              "speed3d": 5.083333333333334,
              "msFromStart": 179856.81644444432
            },
            {
              "lat": 43.76889713153554,
              "lng": -116.07924085882375,
              "alt": 1931.74773420315,
              "speed2d": 4.822940839398875,
              "speed3d": 5.0026479015028125,
              "msFromStart": 180000
            },
            {
              "lat": 43.76890556917796,
              "lng": -116.07926914465321,
              "alt": 1932.4676962461656,
              "speed2d": 4.805121479481075,
              "speed3d": 4.968717655331233,
              "msFromStart": 180500
            },
            {
              "lat": 43.76891736666667,
              "lng": -116.0793181,
              "alt": 1933.02,
              "speed2d": 4.871666666666666,
              "speed3d": 5.036666666666666,
              "msFromStart": 181308.18755555557
            },
            {
              "lat": 43.7689268,
              "lng": -116.0793572,
              "alt": 1933.268,
              "speed2d": 4.9585,
              "speed3d": 5.12,
              "msFromStart": 181941.7484444446
            },
            {
              "lat": 43.7689311,
              "lng": -116.07937515,
              "alt": 1933.5095000000001,
              "speed2d": 4.9350000000000005,
              "speed3d": 5.1,
              "msFromStart": 182244.7342222223
            },
            {
              "lat": 43.768934671078156,
              "lng": -116.07939006430985,
              "alt": 1933.7256918752091,
              "speed2d": 4.9069810935931875,
              "speed3d": 5.07118920435538,
              "msFromStart": 182500
            },
            {
              "lat": 43.7689441,
              "lng": -116.0794373,
              "alt": 1933.7640000000001,
              "speed2d": 4.8705,
              "speed3d": 5.02,
              "msFromStart": 183315.85085380118
            },
            {
              "lat": 43.768948249999994,
              "lng": -116.07945945,
              "alt": 1933.7549999999999,
              "speed2d": 4.8694999999999995,
              "speed3d": 5.04,
              "msFromStart": 183700.28715789475
            },
            {
              "lat": 43.76895434285714,
              "lng": -116.07948502857144,
              "alt": 1934.1342857142859,
              "speed2d": 4.850142857142857,
              "speed3d": 5.012857142857143,
              "msFromStart": 184167.11073684218
            },
            {
              "lat": 43.768962300000005,
              "lng": -116.0795161,
              "alt": 1934.7013333333334,
              "speed2d": 4.844333333333333,
              "speed3d": 5.003333333333334,
              "msFromStart": 184735.61600000007
            },
            {
              "lat": 43.76897148333333,
              "lng": -116.07955076666667,
              "alt": 1935.3421666666666,
              "speed2d": 4.893999999999999,
              "speed3d": 5.055000000000001,
              "msFromStart": 185350.33600000007
            },
            {
              "lat": 43.76897376769242,
              "lng": -116.07955897076968,
              "alt": 1935.5266163565136,
              "speed2d": 4.890091666064083,
              "speed3d": 5.049068375450342,
              "msFromStart": 185500
            },
            {
              "lat": 43.76898065840655,
              "lng": -116.07958653362617,
              "alt": 1936.1683141093752,
              "speed2d": 4.852766964555357,
              "speed3d": 5.010786630313186,
              "msFromStart": 186000
            },
            {
              "lat": 43.76899034,
              "lng": -116.07962527999999,
              "alt": 1937.0376,
              "speed2d": 4.7852,
              "speed3d": 4.952,
              "msFromStart": 186708.816
            },
            {
              "lat": 43.76899605,
              "lng": -116.07965425,
              "alt": 1937.121,
              "speed2d": 4.7805,
              "speed3d": 4.925,
              "msFromStart": 187220.6053333333
            },
            {
              "lat": 43.76900046666666,
              "lng": -116.0796848,
              "alt": 1936.7926666666667,
              "speed2d": 4.767,
              "speed3d": 4.923333333333333,
              "msFromStart": 187742.6399999999
            },
            {
              "lat": 43.76900159623558,
              "lng": -116.07970076163633,
              "alt": 1936.165577405108,
              "speed2d": 4.777259901141156,
              "speed3d": 4.924618657319834,
              "msFromStart": 188000
            },
            {
              "lat": 43.769009522202055,
              "lng": -116.07972889881728,
              "alt": 1936.9049568484163,
              "speed2d": 4.831609385488492,
              "speed3d": 4.975571298895461,
              "msFromStart": 188500
            },
            {
              "lat": 43.7690216,
              "lng": -116.07977145,
              "alt": 1938.0295,
              "speed2d": 4.9079999999999995,
              "speed3d": 5.055,
              "msFromStart": 189257.97052631603
            },
            {
              "lat": 43.76902914,
              "lng": -116.07979938,
              "alt": 1938.834,
              "speed2d": 4.8738,
              "speed3d": 5.028,
              "msFromStart": 189770.72479999997
            },
            {
              "lat": 43.7690341,
              "lng": -116.079833275,
              "alt": 1938.6805,
              "speed2d": 4.796,
              "speed3d": 4.947500000000001,
              "msFromStart": 190360.2893333333
            },
            {
              "lat": 43.769037049999994,
              "lng": -116.079857,
              "alt": 1938.3635000000002,
              "speed2d": 4.8315,
              "speed3d": 4.9675,
              "msFromStart": 190743.9159999999
            },
            {
              "lat": 43.769043266666664,
              "lng": -116.07988576666665,
              "alt": 1938.667,
              "speed2d": 4.827333333333333,
              "speed3d": 4.973333333333334,
              "msFromStart": 191246.2136296295
            },
            {
              "lat": 43.76905302,
              "lng": -116.07991998,
              "alt": 1939.7061999999999,
              "speed2d": 4.828399999999999,
              "speed3d": 4.974,
              "msFromStart": 191868.28088888878
            },
            {
              "lat": 43.76905596666667,
              "lng": -116.07993159999998,
              "alt": 1940.0430000000001,
              "speed2d": 4.792,
              "speed3d": 4.943333333333333,
              "msFromStart": 192087.9004444443
            },
            {
              "lat": 43.7690638,
              "lng": -116.07995995,
              "alt": 1941.4695000000002,
              "speed2d": 4.537,
              "speed3d": 4.695,
              "msFromStart": 192692.4577777777
            },
            {
              "lat": 43.7690729,
              "lng": -116.07999040000001,
              "alt": 1942.651,
              "speed2d": 4.559666666666667,
              "speed3d": 4.703333333333333,
              "msFromStart": 193279.109411306
            },
            {
              "lat": 43.76907745,
              "lng": -116.08000584999999,
              "alt": 1943.2085,
              "speed2d": 4.582,
              "speed3d": 4.73,
              "msFromStart": 193572.61473684217
            },
            {
              "lat": 43.76909014,
              "lng": -116.08004848000002,
              "alt": 1944.5048000000002,
              "speed2d": 4.747999999999999,
              "speed3d": 4.902,
              "msFromStart": 194332.38910877204
            },
            {
              "lat": 43.76909855,
              "lng": -116.08007760000001,
              "alt": 1945.228,
              "speed2d": 4.833,
              "speed3d": 4.965,
              "msFromStart": 194839.85599999997
            },
            {
              "lat": 43.7691066,
              "lng": -116.08010575,
              "alt": 1945.9502499999999,
              "speed2d": 4.90175,
              "speed3d": 5.0424999999999995,
              "msFromStart": 195322.37355555553
            },
            {
              "lat": 43.76911378,
              "lng": -116.08013345999998,
              "alt": 1946.5478,
              "speed2d": 4.99,
              "speed3d": 5.132,
              "msFromStart": 195775.41688888884
            },
            {
              "lat": 43.7691214,
              "lng": -116.08016304,
              "alt": 1947.1984000000002,
              "speed2d": 4.9512,
              "speed3d": 5.112,
              "msFromStart": 196280.4940444444
            },
            {
              "lat": 43.76912478,
              "lng": -116.08018734000001,
              "alt": 1947.4733999999999,
              "speed2d": 4.7042,
              "speed3d": 4.8919999999999995,
              "msFromStart": 196731.71448888892
            },
            {
              "lat": 43.76912745,
              "lng": -116.080210775,
              "alt": 1947.8017499999999,
              "speed2d": 4.509250000000001,
              "speed3d": 4.6825,
              "msFromStart": 197177.76888888894
            },
            {
              "lat": 43.76913636666667,
              "lng": -116.08024186666665,
              "alt": 1948.9443333333334,
              "speed2d": 4.578333333333333,
              "speed3d": 4.703333333333334,
              "msFromStart": 197753.6480000001
            },
            {
              "lat": 43.7691414751304,
              "lng": -116.08025533778591,
              "alt": 1949.6313790657139,
              "speed2d": 4.658215001947231,
              "speed3d": 4.781239470609635,
              "msFromStart": 198000
            },
            {
              "lat": 43.769155319999996,
              "lng": -116.08029765999999,
              "alt": 1951.3937999999998,
              "speed2d": 4.6152,
              "speed3d": 4.782,
              "msFromStart": 198808.71343157903
            },
            {
              "lat": 43.76915834932424,
              "lng": -116.08030752165074,
              "alt": 1951.8041425285148,
              "speed2d": 4.599328906991127,
              "speed3d": 4.757434194996588,
              "msFromStart": 199000
            },
            {
              "lat": 43.76916650916861,
              "lng": -116.08033412404521,
              "alt": 1952.8651348405324,
              "speed2d": 4.605384944152873,
              "speed3d": 4.697413484053291,
              "msFromStart": 199500
            },
            {
              "lat": 43.7691777,
              "lng": -116.08037286666666,
              "alt": 1954.233666666667,
              "speed2d": 4.651666666666666,
              "speed3d": 4.81,
              "msFromStart": 200211.39407407405
            },
            {
              "lat": 43.769182397912395,
              "lng": -116.08038862620067,
              "alt": 1954.9038195557073,
              "speed2d": 4.673916884125551,
              "speed3d": 4.830831158744495,
              "msFromStart": 200500
            },
            {
              "lat": 43.76919336666667,
              "lng": -116.08042593333332,
              "alt": 1956.2726666666667,
              "speed2d": 4.699666666666666,
              "speed3d": 4.853333333333333,
              "msFromStart": 201182.24237037048
            },
            {
              "lat": 43.76919848868311,
              "lng": -116.0804415819815,
              "alt": 1957.0482101610492,
              "speed2d": 4.593477704696077,
              "speed3d": 4.787045632066881,
              "msFromStart": 201500
            },
            {
              "lat": 43.769210666666666,
              "lng": -116.08048366666665,
              "alt": 1958.5093333333334,
              "speed2d": 4.669333333333333,
              "speed3d": 4.843333333333334,
              "msFromStart": 202263.79644444457
            },
            {
              "lat": 43.76921995,
              "lng": -116.08051365,
              "alt": 1959.721,
              "speed2d": 4.727,
              "speed3d": 4.880000000000001,
              "msFromStart": 202813.9040000001
            },
            {
              "lat": 43.769228399999996,
              "lng": -116.08054023333332,
              "alt": 1960.9656666666667,
              "speed2d": 4.735666666666667,
              "speed3d": 4.906666666666667,
              "msFromStart": 203309.01299025345
            },
            {
              "lat": 43.769234825,
              "lng": -116.08056305,
              "alt": 1962.03325,
              "speed2d": 4.6827499999999995,
              "speed3d": 4.8525,
              "msFromStart": 203749.22021052623
            },
            {
              "lat": 43.7692416,
              "lng": -116.08058940000001,
              "alt": 1963.088,
              "speed2d": 4.6635,
              "speed3d": 4.83,
              "msFromStart": 204244.34530994145
            },
            {
              "lat": 43.769250099999994,
              "lng": -116.08062205,
              "alt": 1964.4814999999999,
              "speed2d": 4.59,
              "speed3d": 4.77,
              "msFromStart": 204876.18444444437
            },
            {
              "lat": 43.769255099999995,
              "lng": -116.08064285,
              "alt": 1965.375,
              "speed2d": 4.5280000000000005,
              "speed3d": 4.71,
              "msFromStart": 205288.12711111104
            },
            {
              "lat": 43.76926245,
              "lng": -116.0806666,
              "alt": 1966.3355,
              "speed2d": 4.6015,
              "speed3d": 4.76,
              "msFromStart": 205728.67822222222
            },
            {
              "lat": 43.769269449999996,
              "lng": -116.08068689999999,
              "alt": 1967.1112500000002,
              "speed2d": 4.7055,
              "speed3d": 4.875,
              "msFromStart": 206086.86000000002
            },
            {
              "lat": 43.76927656867645,
              "lng": -116.08070987779176,
              "alt": 1968.004887267808,
              "speed2d": 4.737641574662653,
              "speed3d": 4.911031632895496,
              "msFromStart": 206500
            },
            {
              "lat": 43.7692904,
              "lng": -116.08075435,
              "alt": 1969.745,
              "speed2d": 4.7965,
              "speed3d": 4.970000000000001,
              "msFromStart": 207296.95866666664
            },
            {
              "lat": 43.7692971,
              "lng": -116.08077675,
              "alt": 1970.6255,
              "speed2d": 4.7665,
              "speed3d": 4.9399999999999995,
              "msFromStart": 207709.66577777776
            },
            {
              "lat": 43.76930695,
              "lng": -116.08080655,
              "alt": 1971.916,
              "speed2d": 4.7615,
              "speed3d": 4.945,
              "msFromStart": 208259.92851461985
            },
            {
              "lat": 43.7693141,
              "lng": -116.08082905,
              "alt": 1972.8449999999998,
              "speed2d": 4.7524999999999995,
              "speed3d": 4.945,
              "msFromStart": 208672.3957894736
            },
            {
              "lat": 43.769322,
              "lng": -116.08085285000001,
              "alt": 1973.8665,
              "speed2d": 4.7625,
              "speed3d": 4.94,
              "msFromStart": 209112.25768421037
            },
            {
              "lat": 43.76932529110967,
              "lng": -116.08087432789675,
              "alt": 1974.138298576059,
              "speed2d": 4.570454322620184,
              "speed3d": 4.665456773798198,
              "msFromStart": 209500
            },
            {
              "lat": 43.76933762857143,
              "lng": -116.08091332857143,
              "alt": 1975.7720000000002,
              "speed2d": 4.547285714285715,
              "speed3d": 4.752857142857143,
              "msFromStart": 210265.10793650796
            },
            {
              "lat": 43.76934445,
              "lng": -116.08093695000001,
              "alt": 1976.6025,
              "speed2d": 4.5504999999999995,
              "speed3d": 4.75,
              "msFromStart": 210708.17733333327
            },
            {
              "lat": 43.76935193333333,
              "lng": -116.08096706666669,
              "alt": 1977.202,
              "speed2d": 4.623666666666667,
              "speed3d": 4.8133333333333335,
              "msFromStart": 211228.95466666657
            },
            {
              "lat": 43.769361720000006,
              "lng": -116.08099894,
              "alt": 1978.4414000000002,
              "speed2d": 4.6364,
              "speed3d": 4.832,
              "msFromStart": 211834.55199999997
            },
            {
              "lat": 43.76937266666666,
              "lng": -116.08103003333333,
              "alt": 1979.6543333333334,
              "speed2d": 4.620666666666667,
              "speed3d": 4.83,
              "msFromStart": 212440.67022222222
            },
            {
              "lat": 43.769379650000005,
              "lng": -116.081049975,
              "alt": 1980.4092500000002,
              "speed2d": 4.58325,
              "speed3d": 4.785,
              "msFromStart": 212839.99377777783
            },
            {
              "lat": 43.769387475,
              "lng": -116.0810735,
              "alt": 1981.26975,
              "speed2d": 4.601,
              "speed3d": 4.797499999999999,
              "msFromStart": 213294.09913450296
            },
            {
              "lat": 43.769395628571424,
              "lng": -116.08110115714285,
              "alt": 1982.2188571428574,
              "speed2d": 4.646857142857143,
              "speed3d": 4.852857142857144,
              "msFromStart": 213799.83843609024
            },
            {
              "lat": 43.76940425,
              "lng": -116.08112955,
              "alt": 1983.2965,
              "speed2d": 4.6370000000000005,
              "speed3d": 4.82,
              "msFromStart": 214336.7025497076
            },
            {
              "lat": 43.76941213333333,
              "lng": -116.0811543,
              "alt": 1984.3246666666669,
              "speed2d": 4.660666666666667,
              "speed3d": 4.853333333333333,
              "msFromStart": 214804.27555555556
            },
            {
              "lat": 43.7694177,
              "lng": -116.08117185,
              "alt": 1985.0275000000001,
              "speed2d": 4.6690000000000005,
              "speed3d": 4.875,
              "msFromStart": 215134.54222222223
            },
            {
              "lat": 43.76942785,
              "lng": -116.0812094,
              "alt": 1986.4650000000001,
              "speed2d": 4.667,
              "speed3d": 4.855,
              "msFromStart": 215822.06888888893
            },
            {
              "lat": 43.76943453333334,
              "lng": -116.08123409999999,
              "alt": 1987.3406666666665,
              "speed2d": 4.619000000000001,
              "speed3d": 4.823333333333333,
              "msFromStart": 216289.421925926
            },
            {
              "lat": 43.76944212857143,
              "lng": -116.08125764285714,
              "alt": 1988.329857142857,
              "speed2d": 4.587142857142857,
              "speed3d": 4.802857142857143,
              "msFromStart": 216752.88558730148
            },
            {
              "lat": 43.7694508,
              "lng": -116.08128633333334,
              "alt": 1989.275,
              "speed2d": 4.602333333333334,
              "speed3d": 4.81,
              "msFromStart": 217297.71081481475
            },
            {
              "lat": 43.769461066666665,
              "lng": -116.08132083333334,
              "alt": 1990.3893333333335,
              "speed2d": 4.657333333333333,
              "speed3d": 4.86,
              "msFromStart": 217940.5315555554
            },
            {
              "lat": 43.7694649,
              "lng": -116.08133350000001,
              "alt": 1990.8333333333335,
              "speed2d": 4.663333333333333,
              "speed3d": 4.869999999999999,
              "msFromStart": 218179.3925925924
            },
            {
              "lat": 43.769472949999994,
              "lng": -116.081361975,
              "alt": 1991.843,
              "speed2d": 4.6697500000000005,
              "speed3d": 4.8875,
              "msFromStart": 218711.35157894733
            },
            {
              "lat": 43.7694826,
              "lng": -116.08139525,
              "alt": 1993.259,
              "speed2d": 4.6525,
              "speed3d": 4.885,
              "msFromStart": 219343.79831578943
            },
            {
              "lat": 43.76948538882501,
              "lng": -116.08140310860439,
              "alt": 1993.571409795352,
              "speed2d": 4.633426575174571,
              "speed3d": 4.820812301440113,
              "msFromStart": 219500
            },
            {
              "lat": 43.7694925040975,
              "lng": -116.08142688417735,
              "alt": 1994.899615045178,
              "speed2d": 4.4465368774952925,
              "speed3d": 4.699504097499477,
              "msFromStart": 220000
            },
            {
              "lat": 43.7695057,
              "lng": -116.08147212499999,
              "alt": 1996.74775,
              "speed2d": 4.577,
              "speed3d": 4.7775,
              "msFromStart": 220857.24000000002
            },
            {
              "lat": 43.769510857142855,
              "lng": -116.08148905714287,
              "alt": 1997.5489999999998,
              "speed2d": 4.572,
              "speed3d": 4.797142857142857,
              "msFromStart": 221186.44000000003
            },
            {
              "lat": 43.7695206,
              "lng": -116.08152185,
              "alt": 1998.8785,
              "speed2d": 4.686,
              "speed3d": 4.865,
              "msFromStart": 221790.85511111116
            },
            {
              "lat": 43.769523394158874,
              "lng": -116.08153280848185,
              "alt": 1999.2950340139787,
              "speed2d": 4.6328385677032005,
              "speed3d": 4.876771354064014,
              "msFromStart": 222000
            },
            {
              "lat": 43.7695371,
              "lng": -116.0815781,
              "alt": 2001.539,
              "speed2d": 4.628,
              "speed3d": 4.846666666666667,
              "msFromStart": 222872.5312592591
            },
            {
              "lat": 43.76954235,
              "lng": -116.08159515,
              "alt": 2002.348,
              "speed2d": 4.585000000000001,
              "speed3d": 4.83,
              "msFromStart": 223207.26599999983
            },
            {
              "lat": 43.76955323333333,
              "lng": -116.08163033333335,
              "alt": 2003.9586666666667,
              "speed2d": 4.592,
              "speed3d": 4.816666666666666,
              "msFromStart": 223881.37094736856
            },
            {
              "lat": 43.76955965,
              "lng": -116.0816535,
              "alt": 2005.0535,
              "speed2d": 4.48,
              "speed3d": 4.734999999999999,
              "msFromStart": 224349.0772163744
            },
            {
              "lat": 43.76956633333334,
              "lng": -116.08167303333335,
              "alt": 2007.0783333333334,
              "speed2d": 4.463333333333334,
              "speed3d": 4.713333333333334,
              "msFromStart": 224797.80237037037
            },
            {
              "lat": 43.76957000642566,
              "lng": -116.08168115228514,
              "alt": 2008.2207055081678,
              "speed2d": 4.455137533960599,
              "speed3d": 4.690275067921199,
              "msFromStart": 225000
            },
            {
              "lat": 43.7695780349522,
              "lng": -116.08170610269704,
              "alt": 2009.6540209091838,
              "speed2d": 4.458741001883507,
              "speed3d": 4.697482003767015,
              "msFromStart": 225500
            },
            {
              "lat": 43.76958606347873,
              "lng": -116.08173105310894,
              "alt": 2011.0873363101996,
              "speed2d": 4.462344469806415,
              "speed3d": 4.704688939612831,
              "msFromStart": 226000
            },
            {
              "lat": 43.76959409200526,
              "lng": -116.08175600352084,
              "alt": 2012.5206517112156,
              "speed2d": 4.465947937729323,
              "speed3d": 4.711895875458647,
              "msFromStart": 226500
            },
            {
              "lat": 43.76960212053179,
              "lng": -116.08178095393274,
              "alt": 2013.9539671122313,
              "speed2d": 4.469551405652232,
              "speed3d": 4.719102811304463,
              "msFromStart": 227000
            },
            {
              "lat": 43.76961014905832,
              "lng": -116.08180590434463,
              "alt": 2015.3872825132473,
              "speed2d": 4.473154873575139,
              "speed3d": 4.726309747150279,
              "msFromStart": 227500
            },
            {
              "lat": 43.76961966138379,
              "lng": -116.08183198513993,
              "alt": 2017.2328115234654,
              "speed2d": 4.652158486048487,
              "speed3d": 4.74,
              "msFromStart": 228000
            },
            {
              "lat": 43.76962833333334,
              "lng": -116.08186523333333,
              "alt": 2018.5543333333333,
              "speed2d": 4.447333333333334,
              "speed3d": 4.72,
              "msFromStart": 228687.5519999999
            },
            {
              "lat": 43.76963583333333,
              "lng": -116.08189543333334,
              "alt": 2019.6313333333335,
              "speed2d": 4.459666666666667,
              "speed3d": 4.731666666666667,
              "msFromStart": 229272.8430175437
            },
            {
              "lat": 43.769641199999995,
              "lng": -116.08191755,
              "alt": 2020.4655,
              "speed2d": 4.43,
              "speed3d": 4.705,
              "msFromStart": 229712.30399999995
            },
            {
              "lat": 43.76964805,
              "lng": -116.081943,
              "alt": 2021.4325000000001,
              "speed2d": 4.44775,
              "speed3d": 4.7225,
              "msFromStart": 230206.9679999999
            },
            {
              "lat": 43.76965353087091,
              "lng": -116.08195975752068,
              "alt": 2022.0084617870937,
              "speed2d": 4.626382581573117,
              "speed3d": 4.673298156019205,
              "msFromStart": 230500
            },
            {
              "lat": 43.769661166666666,
              "lng": -116.0819875,
              "alt": 2023.623,
              "speed2d": 4.481,
              "speed3d": 4.76,
              "msFromStart": 231086.879111111
            },
            {
              "lat": 43.76966652524722,
              "lng": -116.08200801501982,
              "alt": 2024.515104554246,
              "speed2d": 4.434355929412953,
              "speed3d": 4.71590567161239,
              "msFromStart": 231500
            },
            {
              "lat": 43.76967695,
              "lng": -116.0820492,
              "alt": 2026.2465,
              "speed2d": 4.3485,
              "speed3d": 4.64,
              "msFromStart": 232324.80800000005
            },
            {
              "lat": 43.7696751269763,
              "lng": -116.08205831426923,
              "alt": 2025.8233794281011,
              "speed2d": 4.161111029482995,
              "speed3d": 4.456825330190201,
              "msFromStart": 232500
            },
            {
              "lat": 43.76969076666666,
              "lng": -116.08209113333334,
              "alt": 2028.871,
              "speed2d": 4.314333333333333,
              "speed3d": 4.596666666666667,
              "msFromStart": 233233.15911111113
            },
            {
              "lat": 43.7696941627267,
              "lng": -116.08210077819662,
              "alt": 2029.5748283589326,
              "speed2d": 4.091329410473552,
              "speed3d": 4.39775370974411,
              "msFromStart": 233500
            },
            {
              "lat": 43.7697063,
              "lng": -116.0821378,
              "alt": 2031.0806666666667,
              "speed2d": 4.322,
              "speed3d": 4.596666666666667,
              "msFromStart": 234223.1090526316
            },
            {
              "lat": 43.76971625,
              "lng": -116.08216759999999,
              "alt": 2032.316,
              "speed2d": 4.6475,
              "speed3d": 4.895,
              "msFromStart": 234744.696
            },
            {
              "lat": 43.76972835,
              "lng": -116.0822034,
              "alt": 2033.913,
              "speed2d": 4.7735,
              "speed3d": 5.035,
              "msFromStart": 235403.33066666668
            },
            {
              "lat": 43.769738499999995,
              "lng": -116.08222435999998,
              "alt": 2036.0054,
              "speed2d": 4.6832,
              "speed3d": 4.978000000000001,
              "msFromStart": 235871.808
            },
            {
              "lat": 43.76974501111111,
              "lng": -116.08224281111112,
              "alt": 2037.2792222222224,
              "speed2d": 4.573555555555555,
              "speed3d": 4.883333333333333,
              "msFromStart": 236257.69955555553
            },
            {
              "lat": 43.76975255555556,
              "lng": -116.0822682111111,
              "alt": 2038.561777777778,
              "speed2d": 4.545444444444444,
              "speed3d": 4.8311111111111105,
              "msFromStart": 236753.4133333334
            },
            {
              "lat": 43.76976021428572,
              "lng": -116.08229134285715,
              "alt": 2039.751142857143,
              "speed2d": 4.6930000000000005,
              "speed3d": 4.957142857142857,
              "msFromStart": 237193.90400000016
            },
            {
              "lat": 43.76977170000001,
              "lng": -116.08232465999998,
              "alt": 2041.4408,
              "speed2d": 4.9024,
              "speed3d": 5.2,
              "msFromStart": 237809.42471111112
            },
            {
              "lat": 43.76977535244768,
              "lng": -116.08233510699334,
              "alt": 2041.9490256575416,
              "speed2d": 4.951482516678174,
              "speed3d": 5.14834498888122,
              "msFromStart": 238000
            },
            {
              "lat": 43.76979043333333,
              "lng": -116.08237826666667,
              "alt": 2044.1696666666667,
              "speed2d": 4.812666666666667,
              "speed3d": 5.135,
              "msFromStart": 238796.05936842106
            },
            {
              "lat": 43.7697992,
              "lng": -116.08240563333334,
              "alt": 2045.5453333333335,
              "speed2d": 4.604333333333334,
              "speed3d": 4.9366666666666665,
              "msFromStart": 239317.83946978557
            },
            {
              "lat": 43.7698058,
              "lng": -116.0824286125,
              "alt": 2046.7205,
              "speed2d": 4.5105,
              "speed3d": 4.8625,
              "msFromStart": 239764.38799999998
            },
            {
              "lat": 43.769813299999996,
              "lng": -116.0824528,
              "alt": 2048.1525,
              "speed2d": 4.452500000000001,
              "speed3d": 4.78,
              "msFromStart": 240248.28
            },
            {
              "lat": 43.769824650000004,
              "lng": -116.0824763,
              "alt": 2050.8455,
              "speed2d": 4.470000000000001,
              "speed3d": 4.82,
              "msFromStart": 240770.74666666664
            },
            {
              "lat": 43.76983151686244,
              "lng": -116.08248312025027,
              "alt": 2052.979961906502,
              "speed2d": 4.327210135970374,
              "speed3d": 4.725421561073238,
              "msFromStart": 241000
            },
            {
              "lat": 43.769842456072844,
              "lng": -116.08251050971269,
              "alt": 2054.837555854798,
              "speed2d": 4.674861557478398,
              "speed3d": 4.998901821139144,
              "msFromStart": 241500
            },
            {
              "lat": 43.769855549615635,
              "lng": -116.08253915667775,
              "alt": 2057.0266487814383,
              "speed2d": 5.455336146941094,
              "speed3d": 5.595835091838184,
              "msFromStart": 242000
            },
            {
              "lat": 43.76986420184467,
              "lng": -116.08256521120913,
              "alt": 2058.532332322131,
              "speed2d": 5.343514123866992,
              "speed3d": 5.525946327416871,
              "msFromStart": 242500
            },
            {
              "lat": 43.769872854073704,
              "lng": -116.0825912657405,
              "alt": 2060.038015862824,
              "speed2d": 5.231692100792889,
              "speed3d": 5.456057562995556,
              "msFromStart": 243000
            },
            {
              "lat": 43.76988150630274,
              "lng": -116.0826173202719,
              "alt": 2061.5436994035167,
              "speed2d": 5.119870077718787,
              "speed3d": 5.386168798574242,
              "msFromStart": 243500
            },
            {
              "lat": 43.76989015853177,
              "lng": -116.08264337480327,
              "alt": 2063.0493829442094,
              "speed2d": 5.008048054644684,
              "speed3d": 5.316280034152928,
              "msFromStart": 244000
            },
            {
              "lat": 43.76989881076081,
              "lng": -116.08266942933464,
              "alt": 2064.5550664849025,
              "speed2d": 4.896226031570582,
              "speed3d": 5.246391269731614,
              "msFromStart": 244500
            },
            {
              "lat": 43.76990746298984,
              "lng": -116.08269548386602,
              "alt": 2066.060750025595,
              "speed2d": 4.784404008496479,
              "speed3d": 5.1765025053102995,
              "msFromStart": 245000
            },
            {
              "lat": 43.76991611521888,
              "lng": -116.0827215383974,
              "alt": 2067.5664335662877,
              "speed2d": 4.672581985422377,
              "speed3d": 5.106613740888986,
              "msFromStart": 245500
            },
            {
              "lat": 43.7699329,
              "lng": -116.08276203999999,
              "alt": 2071.0406,
              "speed2d": 4.584,
              "speed3d": 5.086,
              "msFromStart": 246334.0208
            },
            {
              "lat": 43.769937999999996,
              "lng": -116.08277453333335,
              "alt": 2072.0793333333336,
              "speed2d": 4.508666666666667,
              "speed3d": 5.033333333333333,
              "msFromStart": 246597.89866666665
            },
            {
              "lat": 43.769949849999996,
              "lng": -116.08280935,
              "alt": 2074.212,
              "speed2d": 4.495833333333334,
              "speed3d": 5.0266666666666655,
              "msFromStart": 247285.03007407405
            },
            {
              "lat": 43.7699555,
              "lng": -116.08282729999999,
              "alt": 2075.334,
              "speed2d": 4.4285,
              "speed3d": 4.9399999999999995,
              "msFromStart": 247642.28355555562
            },
            {
              "lat": 43.76996521666666,
              "lng": -116.08285748333334,
              "alt": 2077.0063333333333,
              "speed2d": 4.482833333333334,
              "speed3d": 5.066666666666666,
              "msFromStart": 248219.38624561415
            },
            {
              "lat": 43.76997675,
              "lng": -116.08289195,
              "alt": 2079.1345,
              "speed2d": 4.484,
              "speed3d": 5.02,
              "msFromStart": 248906.98357894729
            },
            {
              "lat": 43.769984475,
              "lng": -116.08291102499999,
              "alt": 2080.43275,
              "speed2d": 4.561249999999999,
              "speed3d": 5.09,
              "msFromStart": 249292.14568421044
            },
            {
              "lat": 43.769992759999994,
              "lng": -116.08293303999999,
              "alt": 2082.0046,
              "speed2d": 4.5692,
              "speed3d": 5.1499999999999995,
              "msFromStart": 249733.2373333333
            },
            {
              "lat": 43.7699995,
              "lng": -116.08295643333334,
              "alt": 2082.8535,
              "speed2d": 4.664,
              "speed3d": 5.221666666666668,
              "msFromStart": 250156.23970370356
            },
            {
              "lat": 43.770008000000004,
              "lng": -116.0829874,
              "alt": 2084.126666666667,
              "speed2d": 4.459333333333333,
              "speed3d": 5.02,
              "msFromStart": 250742.54666666663
            },
            {
              "lat": 43.770015816666664,
              "lng": -116.08301108333332,
              "alt": 2085.4998333333333,
              "speed2d": 4.4896666666666665,
              "speed3d": 5.036666666666667,
              "msFromStart": 251217.68799999994
            },
            {
              "lat": 43.770024500000005,
              "lng": -116.08304015,
              "alt": 2086.868,
              "speed2d": 4.436,
              "speed3d": 4.955,
              "msFromStart": 251794.7533333333
            },
            {
              "lat": 43.77003285,
              "lng": -116.0830647,
              "alt": 2088.3905,
              "speed2d": 4.481,
              "speed3d": 4.99,
              "msFromStart": 252289.33288888884
            },
            {
              "lat": 43.77004061666666,
              "lng": -116.08308701666667,
              "alt": 2089.9559999999997,
              "speed2d": 4.419,
              "speed3d": 5.008333333333333,
              "msFromStart": 252741.0256296296
            },
            {
              "lat": 43.77004665121299,
              "lng": -116.08309587000385,
              "alt": 2091.1661728926056,
              "speed2d": 3.3886365043457007,
              "speed3d": 4.325121294201656,
              "msFromStart": 253000
            },
            {
              "lat": 43.77005482,
              "lng": -116.08313150000001,
              "alt": 2092.7534,
              "speed2d": 4.4816,
              "speed3d": 5.056,
              "msFromStart": 253626.3536842105
            },
            {
              "lat": 43.770066557142854,
              "lng": -116.0831640714286,
              "alt": 2095.1117142857142,
              "speed2d": 4.513285714285714,
              "speed3d": 5.144285714285714,
              "msFromStart": 254297.43092083145
            },
            {
              "lat": 43.77007267142857,
              "lng": -116.08318355714286,
              "alt": 2096.4315714285717,
              "speed2d": 4.403571428571429,
              "speed3d": 5.054285714285714,
              "msFromStart": 254707.29317647064
            },
            {
              "lat": 43.77007704066748,
              "lng": -116.08319972901143,
              "alt": 2097.5234603261842,
              "speed2d": 4.382579460778769,
              "speed3d": 5.06070089870205,
              "msFromStart": 255000
            },
            {
              "lat": 43.77008984,
              "lng": -116.08323916,
              "alt": 2099.8156,
              "speed2d": 4.3496,
              "speed3d": 5.032,
              "msFromStart": 255785.31822222224
            },
            {
              "lat": 43.77009790000001,
              "lng": -116.083262475,
              "alt": 2101.3275,
              "speed2d": 4.410625,
              "speed3d": 5.0625,
              "msFromStart": 256252.75111111117
            },
            {
              "lat": 43.77010896666666,
              "lng": -116.08329459999999,
              "alt": 2103.3303333333333,
              "speed2d": 4.413666666666667,
              "speed3d": 5.053333333333334,
              "msFromStart": 256903.00918518513
            },
            {
              "lat": 43.77011353333333,
              "lng": -116.08330816666665,
              "alt": 2104.283333333333,
              "speed2d": 4.443666666666667,
              "speed3d": 5.069999999999999,
              "msFromStart": 257177.70696296293
            },
            {
              "lat": 43.7701257,
              "lng": -116.08333698333334,
              "alt": 2107.1356666666666,
              "speed2d": 4.368166666666667,
              "speed3d": 5.02,
              "msFromStart": 257810.10370370367
            },
            {
              "lat": 43.7701353,
              "lng": -116.083358925,
              "alt": 2109.4565,
              "speed2d": 4.34875,
              "speed3d": 5.005,
              "msFromStart": 258300.67819883034
            },
            {
              "lat": 43.77014762500001,
              "lng": -116.083384225,
              "alt": 2112.50975,
              "speed2d": 4.27325,
              "speed3d": 4.9475,
              "msFromStart": 258892.35999999993
            },
            {
              "lat": 43.770156557142855,
              "lng": -116.0834008,
              "alt": 2114.7867142857144,
              "speed2d": 4.297,
              "speed3d": 4.992857142857143,
              "msFromStart": 259289.48562071836
            },
            {
              "lat": 43.77016476666667,
              "lng": -116.08341753333332,
              "alt": 2116.7753333333335,
              "speed2d": 4.2605,
              "speed3d": 4.95,
              "msFromStart": 259681.6491851852
            },
            {
              "lat": 43.77017130484661,
              "lng": -116.08343096090489,
              "alt": 2118.3519919416804,
              "speed2d": 4.19792407229242,
              "speed3d": 4.951486269217603,
              "msFromStart": 260000
            },
            {
              "lat": 43.77018118558315,
              "lng": -116.08345367011304,
              "alt": 2120.422550488411,
              "speed2d": 4.369260701529998,
              "speed3d": 5.148485844036208,
              "msFromStart": 260500
            },
            {
              "lat": 43.77019583333333,
              "lng": -116.08348516666666,
              "alt": 2123.884666666667,
              "speed2d": 4.473000000000001,
              "speed3d": 5.113333333333333,
              "msFromStart": 261177.25688888875
            },
            {
              "lat": 43.7702094,
              "lng": -116.083512825,
              "alt": 2127.39175,
              "speed2d": 4.38225,
              "speed3d": 5.0775,
              "msFromStart": 261808.9373333333
            },
            {
              "lat": 43.77021925,
              "lng": -116.0835364,
              "alt": 2129.613,
              "speed2d": 4.451499999999999,
              "speed3d": 5.13,
              "msFromStart": 262302.90444444446
            },
            {
              "lat": 43.77022615,
              "lng": -116.08357050000001,
              "alt": 2129.821,
              "speed2d": 4.4215,
              "speed3d": 5.0600000000000005,
              "msFromStart": 262878.9200000001
            },
            {
              "lat": 43.770225298261224,
              "lng": -116.08358048708753,
              "alt": 2129.1432313771984,
              "speed2d": 4.591936079826132,
              "speed3d": 5.316052163604046,
              "msFromStart": 263000
            },
            {
              "lat": 43.77024305,
              "lng": -116.0836106,
              "alt": 2133.0115,
              "speed2d": 4.3625,
              "speed3d": 4.93,
              "msFromStart": 263723.456
            },
            {
              "lat": 43.77025664999999,
              "lng": -116.083637575,
              "alt": 2136.278,
              "speed2d": 4.301,
              "speed3d": 4.955,
              "msFromStart": 264344.9588888889
            },
            {
              "lat": 43.7702639,
              "lng": -116.08365263333334,
              "alt": 2137.9849999999997,
              "speed2d": 4.292666666666667,
              "speed3d": 4.943333333333333,
              "msFromStart": 264700.8242962962
            },
            {
              "lat": 43.77027805,
              "lng": -116.08368565,
              "alt": 2141.4184999999998,
              "speed2d": 4.3705,
              "speed3d": 5.095000000000001,
              "msFromStart": 265448.22917647054
            },
            {
              "lat": 43.770282671428575,
              "lng": -116.08369668571427,
              "alt": 2142.593142857143,
              "speed2d": 4.316428571428571,
              "speed3d": 4.9957142857142856,
              "msFromStart": 265707.47576470574
            },
            {
              "lat": 43.77028782136103,
              "lng": -116.08370942372325,
              "alt": 2143.8928474943646,
              "speed2d": 4.243545638218453,
              "speed3d": 4.944599920526742,
              "msFromStart": 266000
            },
            {
              "lat": 43.77029712887696,
              "lng": -116.08373068067772,
              "alt": 2146.5200872152286,
              "speed2d": 4.002464956982892,
              "speed3d": 4.694183871896679,
              "msFromStart": 266500
            },
            {
              "lat": 43.77030660122192,
              "lng": -116.08375295815503,
              "alt": 2148.8535521854624,
              "speed2d": 4.108489978266114,
              "speed3d": 4.761691378893973,
              "msFromStart": 267000
            },
            {
              "lat": 43.77031541002963,
              "lng": -116.08377469203573,
              "alt": 2150.880401211829,
              "speed2d": 4.1373038352615765,
              "speed3d": 4.827551623455032,
              "msFromStart": 267500
            },
            {
              "lat": 43.77032935,
              "lng": -116.08380885,
              "alt": 2154.0915,
              "speed2d": 4.3085,
              "speed3d": 4.945,
              "msFromStart": 268270.72210526303
            },
            {
              "lat": 43.77033358675847,
              "lng": -116.08381927186305,
              "alt": 2155.12315954162,
              "speed2d": 4.446427244219108,
              "speed3d": 5.125662335826847,
              "msFromStart": 268500
            },
            {
              "lat": 43.77034552917978,
              "lng": -116.08385025064837,
              "alt": 2157.0115750024224,
              "speed2d": 5.597295480536325,
              "speed3d": 6.0083096753352025,
              "msFromStart": 269000
            },
            {
              "lat": 43.770362266666666,
              "lng": -116.08389026666667,
              "alt": 2160.059666666667,
              "speed2d": 4.675666666666666,
              "speed3d": 5.346666666666667,
              "msFromStart": 269849.4571851852
            },
            {
              "lat": 43.77036978333334,
              "lng": -116.08390864999998,
              "alt": 2161.1650000000004,
              "speed2d": 4.541333333333334,
              "speed3d": 5.219999999999999,
              "msFromStart": 270225.5176296296
            },
            {
              "lat": 43.77037865,
              "lng": -116.0839275,
              "alt": 2162.8135,
              "speed2d": 4.2395,
              "speed3d": 4.885,
              "msFromStart": 270666.1439999999
            },
            {
              "lat": 43.770389325000004,
              "lng": -116.08395254999999,
              "alt": 2164.90325,
              "speed2d": 4.56525,
              "speed3d": 5.105,
              "msFromStart": 271189.7671111108
            },
            {
              "lat": 43.77039599794582,
              "lng": -116.08396905294596,
              "alt": 2166.04382210646,
              "speed2d": 4.823365503800782,
              "speed3d": 5.245753876989382,
              "msFromStart": 271500
            },
            {
              "lat": 43.770409,
              "lng": -116.08400405,
              "alt": 2168.1589999999997,
              "speed2d": 4.83,
              "speed3d": 5.4,
              "msFromStart": 272152.5119999998
            },
            {
              "lat": 43.77042083333333,
              "lng": -116.08403583333333,
              "alt": 2169.8613333333333,
              "speed2d": 4.913666666666666,
              "speed3d": 5.456666666666667,
              "msFromStart": 272728.41599999985
            },
            {
              "lat": 43.77042537847351,
              "lng": -116.08404941974996,
              "alt": 2170.6671595576136,
              "speed2d": 4.471619146913546,
              "speed3d": 5.036280293970265,
              "msFromStart": 273000
            },
            {
              "lat": 43.77044156666667,
              "lng": -116.08408626666667,
              "alt": 2173.0603333333333,
              "speed2d": 4.5555,
              "speed3d": 5.1883333333333335,
              "msFromStart": 273724.07270175416
            },
            {
              "lat": 43.770452399999996,
              "lng": -116.0841173,
              "alt": 2174.026,
              "speed2d": 4.7524999999999995,
              "speed3d": 5.22,
              "msFromStart": 274291.6185263156
            },
            {
              "lat": 43.7704648,
              "lng": -116.08414695,
              "alt": 2175.72275,
              "speed2d": 4.91925,
              "speed3d": 5.4075,
              "msFromStart": 274834.04800000007
            },
            {
              "lat": 43.770473175,
              "lng": -116.084168275,
              "alt": 2176.94825,
              "speed2d": 4.997,
              "speed3d": 5.4975000000000005,
              "msFromStart": 275212.1600000001
            },
            {
              "lat": 43.7704813,
              "lng": -116.0841916,
              "alt": 2178.2290000000003,
              "speed2d": 4.7204999999999995,
              "speed3d": 5.3,
              "msFromStart": 275656.656
            },
            {
              "lat": 43.77049565,
              "lng": -116.0842318,
              "alt": 2180.3815,
              "speed2d": 4.6355,
              "speed3d": 5.145,
              "msFromStart": 276451.7251764706
            },
            {
              "lat": 43.77050007142858,
              "lng": -116.08424432857142,
              "alt": 2180.9869999999996,
              "speed2d": 4.502857142857143,
              "speed3d": 5.094285714285713,
              "msFromStart": 276710.46776470594
            },
            {
              "lat": 43.77050463284282,
              "lng": -116.08425963937655,
              "alt": 2181.5137866601162,
              "speed2d": 4.479923408436223,
              "speed3d": 5.033054953888745,
              "msFromStart": 277000
            },
            {
              "lat": 43.7705168,
              "lng": -116.084299525,
              "alt": 2182.9615,
              "speed2d": 4.591749999999999,
              "speed3d": 5.072500000000001,
              "msFromStart": 277716.87688888883
            },
            {
              "lat": 43.77052835,
              "lng": -116.08433615,
              "alt": 2184.546,
              "speed2d": 4.529,
              "speed3d": 5.0649999999999995,
              "msFromStart": 278418.4951578947
            },
            {
              "lat": 43.770535175,
              "lng": -116.08435765,
              "alt": 2185.3687499999996,
              "speed2d": 4.69825,
              "speed3d": 5.1499999999999995,
              "msFromStart": 278803.315368421
            },
            {
              "lat": 43.770543260000004,
              "lng": -116.08438416,
              "alt": 2186.363,
              "speed2d": 4.765,
              "speed3d": 5.244000000000001,
              "msFromStart": 279270.5970526315
            },
            {
              "lat": 43.77054790378378,
              "lng": -116.08439724720719,
              "alt": 2186.9082612612615,
              "speed2d": 4.926763963963963,
              "speed3d": 5.356576576576576,
              "msFromStart": 279500
            },
            {
              "lat": 43.770561150000006,
              "lng": -116.08444392500002,
              "alt": 2188.41725,
              "speed2d": 4.6805,
              "speed3d": 5.109999999999999,
              "msFromStart": 280343.90088888875
            },
            {
              "lat": 43.77056907142857,
              "lng": -116.08446764285713,
              "alt": 2189.288857142857,
              "speed2d": 4.703285714285714,
              "speed3d": 5.161428571428572,
              "msFromStart": 280779.35085714277
            },
            {
              "lat": 43.770578075,
              "lng": -116.08449487499999,
              "alt": 2190.3142500000004,
              "speed2d": 4.697,
              "speed3d": 5.180000000000001,
              "msFromStart": 281277.22311111086
            },
            {
              "lat": 43.770587475,
              "lng": -116.084525125,
              "alt": 2191.3847499999997,
              "speed2d": 4.5465,
              "speed3d": 5,
              "msFromStart": 281840.8542222223
            },
            {
              "lat": 43.77059378571429,
              "lng": -116.08454460000002,
              "alt": 2192.1004285714284,
              "speed2d": 4.448571428571429,
              "speed3d": 4.918571428571428,
              "msFromStart": 282216.199873016
            },
            {
              "lat": 43.77060383333333,
              "lng": -116.08457816666666,
              "alt": 2193.2856666666667,
              "speed2d": 4.5216666666666665,
              "speed3d": 4.9799999999999995,
              "msFromStart": 282845.8773333333
            },
            {
              "lat": 43.77060605401416,
              "lng": -116.08458589881933,
              "alt": 2193.61891868264,
              "speed2d": 4.467815967985585,
              "speed3d": 4.965976977530298,
              "msFromStart": 283000
            },
            {
              "lat": 43.77061893333334,
              "lng": -116.08463046666667,
              "alt": 2194.8616666666667,
              "speed2d": 4.641333333333333,
              "speed3d": 5.056666666666667,
              "msFromStart": 283800.9959298245
            },
            {
              "lat": 43.77062531999999,
              "lng": -116.08465274,
              "alt": 2195.5029999999997,
              "speed2d": 4.6644,
              "speed3d": 5.109999999999999,
              "msFromStart": 284201.13684210513
            },
            {
              "lat": 43.77063491999999,
              "lng": -116.08468722,
              "alt": 2196.4084,
              "speed2d": 4.6824,
              "speed3d": 5.104,
              "msFromStart": 284816.86186666676
            },
            {
              "lat": 43.77063845245531,
              "lng": -116.08469706136883,
              "alt": 2196.8302055011727,
              "speed2d": 4.7709380956233645,
              "speed3d": 5.1481056548823005,
              "msFromStart": 285000
            },
            {
              "lat": 43.77064512471696,
              "lng": -116.08472583742318,
              "alt": 2197.3605160768584,
              "speed2d": 4.719605744969916,
              "speed3d": 5.14100957494986,
              "msFromStart": 285500
            },
            {
              "lat": 43.770654324999995,
              "lng": -116.08475952500001,
              "alt": 2198.494,
              "speed2d": 4.63575,
              "speed3d": 5.090000000000001,
              "msFromStart": 286118.9137777778
            },
            {
              "lat": 43.77065965266634,
              "lng": -116.08478017383196,
              "alt": 2199.0781995143275,
              "speed2d": 4.529569694026224,
              "speed3d": 4.94196211753278,
              "msFromStart": 286500
            },
            {
              "lat": 43.7706705,
              "lng": -116.084824175,
              "alt": 2200.218,
              "speed2d": 4.602,
              "speed3d": 5.03,
              "msFromStart": 287285.9640000001
            },
            {
              "lat": 43.77067733999999,
              "lng": -116.08484829999999,
              "alt": 2201.0594,
              "speed2d": 4.6006,
              "speed3d": 5.054,
              "msFromStart": 287740.416
            },
            {
              "lat": 43.77068305,
              "lng": -116.08486865,
              "alt": 2201.739,
              "speed2d": 4.577,
              "speed3d": 5.035,
              "msFromStart": 288126.48000000004
            },
            {
              "lat": 43.770691299999996,
              "lng": -116.08489986666666,
              "alt": 2202.5793333333336,
              "speed2d": 4.561333333333334,
              "speed3d": 5.013333333333333,
              "msFromStart": 288713.99229629623
            },
            {
              "lat": 43.7707011,
              "lng": -116.08493315,
              "alt": 2203.7439999999997,
              "speed2d": 4.646,
              "speed3d": 5.05,
              "msFromStart": 289337.4722807017
            },
            {
              "lat": 43.77070853333333,
              "lng": -116.08495986666667,
              "alt": 2204.5946666666664,
              "speed2d": 4.608666666666666,
              "speed3d": 5.09,
              "msFromStart": 289832.2391578945
            },
            {
              "lat": 43.77071625,
              "lng": -116.08498775000001,
              "alt": 2205.58,
              "speed2d": 4.6315,
              "speed3d": 5.045,
              "msFromStart": 290354.43976608163
            },
            {
              "lat": 43.77071823201868,
              "lng": -116.08499482111016,
              "alt": 2205.819321438958,
              "speed2d": 4.3686403735539345,
              "speed3d": 4.885738059846185,
              "msFromStart": 290500
            },
            {
              "lat": 43.770728516666665,
              "lng": -116.08503703333334,
              "alt": 2206.969,
              "speed2d": 4.4985,
              "speed3d": 4.968333333333333,
              "msFromStart": 291289.58874074067
            },
            {
              "lat": 43.77073395714286,
              "lng": -116.08506017142858,
              "alt": 2207.640857142857,
              "speed2d": 4.440285714285715,
              "speed3d": 4.907142857142857,
              "msFromStart": 291733.3318095238
            },
            {
              "lat": 43.770737051600925,
              "lng": -116.08507468144659,
              "alt": 2207.8419672210716,
              "speed2d": 4.513243949003356,
              "speed3d": 4.875609872508389,
              "msFromStart": 292000
            },
            {
              "lat": 43.7707483,
              "lng": -116.08511245,
              "alt": 2209.6515,
              "speed2d": 4.6015,
              "speed3d": 5.07,
              "msFromStart": 292718.49777777767
            },
            {
              "lat": 43.77075320813989,
              "lng": -116.08512627744349,
              "alt": 2210.623547953574,
              "speed2d": 4.525510690287066,
              "speed3d": 4.87,
              "msFromStart": 293000
            },
            {
              "lat": 43.770762500000004,
              "lng": -116.08516775,
              "alt": 2211.8360000000002,
              "speed2d": 4.417,
              "speed3d": 4.91,
              "msFromStart": 293791.1073684212
            },
            {
              "lat": 43.770764845334064,
              "lng": -116.08517924636968,
              "alt": 2212.0398123592886,
              "speed2d": 4.432388307244812,
              "speed3d": 4.901687082306427,
              "msFromStart": 294000
            },
            {
              "lat": 43.770779,
              "lng": -116.08522529999999,
              "alt": 2214.226,
              "speed2d": 4.5280000000000005,
              "speed3d": 5.053333333333333,
              "msFromStart": 294893.4755555556
            },
            {
              "lat": 43.770784325,
              "lng": -116.08524405,
              "alt": 2214.9707500000004,
              "speed2d": 4.50675,
              "speed3d": 5.03,
              "msFromStart": 295251.52444444457
            },
            {
              "lat": 43.77079195,
              "lng": -116.0852715,
              "alt": 2216.118,
              "speed2d": 4.508,
              "speed3d": 4.995,
              "msFromStart": 295773.3866666668
            },
            {
              "lat": 43.77079895714286,
              "lng": -116.0852981142857,
              "alt": 2216.976857142857,
              "speed2d": 4.545571428571428,
              "speed3d": 5.062857142857143,
              "msFromStart": 296259.17358730175
            },
            {
              "lat": 43.770807649999995,
              "lng": -116.08532797499998,
              "alt": 2218.32775,
              "speed2d": 4.4955,
              "speed3d": 5.03,
              "msFromStart": 296830.2506666669
            },
            {
              "lat": 43.7708122,
              "lng": -116.08534235,
              "alt": 2219.0689999999995,
              "speed2d": 4.3985,
              "speed3d": 4.967499999999999,
              "msFromStart": 297118.98666666704
            },
            {
              "lat": 43.77082135,
              "lng": -116.085376,
              "alt": 2220.3109999999997,
              "speed2d": 4.452,
              "speed3d": 4.955,
              "msFromStart": 297751.56266666664
            },
            {
              "lat": 43.770827100000005,
              "lng": -116.08539805,
              "alt": 2220.999,
              "speed2d": 4.4355,
              "speed3d": 4.955,
              "msFromStart": 298164.1759999999
            },
            {
              "lat": 43.770832600000006,
              "lng": -116.08541966666667,
              "alt": 2221.6693333333333,
              "speed2d": 4.366,
              "speed3d": 4.92,
              "msFromStart": 298576.7839999999
            },
            {
              "lat": 43.77084103333333,
              "lng": -116.08545213333333,
              "alt": 2222.628666666667,
              "speed2d": 4.519333333333333,
              "speed3d": 5.0200000000000005,
              "msFromStart": 299181.9306666663
            },
            {
              "lat": 43.77084865,
              "lng": -116.08548114999999,
              "alt": 2223.6545,
              "speed2d": 4.414,
              "speed3d": 4.93,
              "msFromStart": 299733.2648421053
            },
            {
              "lat": 43.77085118784,
              "lng": -116.08549756352012,
              "alt": 2224.1967269363136,
              "speed2d": 5.110265597610618,
              "speed3d": 4.403498669090682,
              "msFromStart": 300000
            },
            {
              "lat": 43.770861971428566,
              "lng": -116.0855332,
              "alt": 2225.311857142857,
              "speed2d": 4.484428571428571,
              "speed3d": 5.007142857142857,
              "msFromStart": 300701.39987301594
            },
            {
              "lat": 43.770869399999995,
              "lng": -116.08556258,
              "alt": 2226.2408,
              "speed2d": 4.4302,
              "speed3d": 4.962,
              "msFromStart": 301250.6268444447
            },
            {
              "lat": 43.77087283401834,
              "lng": -116.08557582101425,
              "alt": 2226.6685675615945,
              "speed2d": 4.406865650204378,
              "speed3d": 4.954637056678833,
              "msFromStart": 301500
            },
            {
              "lat": 43.77088446666667,
              "lng": -116.08561713333333,
              "alt": 2228.1746666666663,
              "speed2d": 4.504,
              "speed3d": 4.996666666666666,
              "msFromStart": 302280.1226666667
            },
            {
              "lat": 43.770892450000005,
              "lng": -116.085644375,
              "alt": 2229.46475,
              "speed2d": 4.455,
              "speed3d": 5.0075,
              "msFromStart": 302812.2559999999
            },
            {
              "lat": 43.770899675,
              "lng": -116.08567195,
              "alt": 2230.37025,
              "speed2d": 4.5169999999999995,
              "speed3d": 5.0675,
              "msFromStart": 303321.6686315788
            },
            {
              "lat": 43.77090533333333,
              "lng": -116.08569356666666,
              "alt": 2231.1026666666667,
              "speed2d": 4.461666666666667,
              "speed3d": 4.999999999999999,
              "msFromStart": 303730.04631578946
            },
            {
              "lat": 43.770912825,
              "lng": -116.08572107500001,
              "alt": 2232.3211250000004,
              "speed2d": 4.387499999999999,
              "speed3d": 4.94625,
              "msFromStart": 304271.4551578947
            },
            {
              "lat": 43.77091643333333,
              "lng": -116.08573626666666,
              "alt": 2232.815,
              "speed2d": 4.232,
              "speed3d": 4.8133333333333335,
              "msFromStart": 304574.0871111111
            },
            {
              "lat": 43.7709264375,
              "lng": -116.0857719375,
              "alt": 2234.324,
              "speed2d": 4.443125,
              "speed3d": 5.015,
              "msFromStart": 305261.13155555556
            },
            {
              "lat": 43.77093078333333,
              "lng": -116.08579031666667,
              "alt": 2234.975,
              "speed2d": 4.158166666666667,
              "speed3d": 4.781666666666666,
              "msFromStart": 305645.69600000005
            },
            {
              "lat": 43.77093668333333,
              "lng": -116.08581919999999,
              "alt": 2236.3616666666667,
              "speed2d": 4.126333333333333,
              "speed3d": 4.71,
              "msFromStart": 306249.7280000002
            },
            {
              "lat": 43.77094543333333,
              "lng": -116.08585083333332,
              "alt": 2237.6083333333336,
              "speed2d": 4.332,
              "speed3d": 4.88,
              "msFromStart": 306863.89333333314
            },
            {
              "lat": 43.770951999999994,
              "lng": -116.08587345,
              "alt": 2238.666,
              "speed2d": 4.4955,
              "speed3d": 5.0649999999999995,
              "msFromStart": 307295.0399999997
            },
            {
              "lat": 43.7709591,
              "lng": -116.0859011,
              "alt": 2239.661,
              "speed2d": 4.489000000000001,
              "speed3d": 5.029999999999999,
              "msFromStart": 307819.4755555556
            },
            {
              "lat": 43.77096665,
              "lng": -116.08592845000001,
              "alt": 2240.718,
              "speed2d": 4.4635,
              "speed3d": 5.02,
              "msFromStart": 308344.4666666668
            },
            {
              "lat": 43.770972725,
              "lng": -116.0859505,
              "alt": 2241.64825,
              "speed2d": 4.44325,
              "speed3d": 5.0275,
              "msFromStart": 308770.32666666666
            },
            {
              "lat": 43.77098,
              "lng": -116.08597739999999,
              "alt": 2242.70425,
              "speed2d": 4.415500000000001,
              "speed3d": 4.9925,
              "msFromStart": 309291.689122807
            },
            {
              "lat": 43.77098592499999,
              "lng": -116.0859985,
              "alt": 2243.67625,
              "speed2d": 4.43825,
              "speed3d": 5.02,
              "msFromStart": 309704.3115789474
            },
            {
              "lat": 43.770990468506945,
              "lng": -116.08601356795074,
              "alt": 2244.459929807246,
              "speed2d": 4.464872942821502,
              "speed3d": 5.03793739079026,
              "msFromStart": 310000
            },
            {
              "lat": 43.77099735336986,
              "lng": -116.08603954856551,
              "alt": 2245.5463525142186,
              "speed2d": 4.389529160034263,
              "speed3d": 5.007626673577002,
              "msFromStart": 310500
            },
            {
              "lat": 43.77100656666667,
              "lng": -116.08607460000002,
              "alt": 2247.0226666666667,
              "speed2d": 4.288666666666667,
              "speed3d": 4.926666666666667,
              "msFromStart": 311175.5928888888
            },
            {
              "lat": 43.771014075000004,
              "lng": -116.0861023,
              "alt": 2248.0944999999997,
              "speed2d": 4.332,
              "speed3d": 4.9325,
              "msFromStart": 311710.7817777778
            },
            {
              "lat": 43.7710235,
              "lng": -116.08613586666667,
              "alt": 2249.454333333333,
              "speed2d": 4.377666666666666,
              "speed3d": 4.96,
              "msFromStart": 312364.7016296297
            },
            {
              "lat": 43.771029524999996,
              "lng": -116.08615759999999,
              "alt": 2250.25425,
              "speed2d": 4.3915,
              "speed3d": 4.9975,
              "msFromStart": 312781.5017777777
            },
            {
              "lat": 43.7710358,
              "lng": -116.0861827,
              "alt": 2250.8917500000002,
              "speed2d": 4.423,
              "speed3d": 4.995,
              "msFromStart": 313248.90355555544
            },
            {
              "lat": 43.77104493333333,
              "lng": -116.0862149,
              "alt": 2252.1603333333333,
              "speed2d": 4.471,
              "speed3d": 5.03,
              "msFromStart": 313868.2324210527
            },
            {
              "lat": 43.77104856666667,
              "lng": -116.08622676666667,
              "alt": 2252.913666666667,
              "speed2d": 4.2956666666666665,
              "speed3d": 4.926666666666667,
              "msFromStart": 314125.25866666675
            },
            {
              "lat": 43.77105385464815,
              "lng": -116.0862450658037,
              "alt": 2253.9356768302296,
              "speed2d": 4.345102209033961,
              "speed3d": 4.867843862440689,
              "msFromStart": 314500
            },
            {
              "lat": 43.77106620000001,
              "lng": -116.08628585,
              "alt": 2256.3734999999997,
              "speed2d": 4.191,
              "speed3d": 4.87,
              "msFromStart": 315355.97155555524
            },
            {
              "lat": 43.7710698,
              "lng": -116.086298025,
              "alt": 2257.0705000000003,
              "speed2d": 4.24325,
              "speed3d": 4.9275,
              "msFromStart": 315603.62577777775
            },
            {
              "lat": 43.77107801428572,
              "lng": -116.08632657142856,
              "alt": 2258.636571428571,
              "speed2d": 4.136,
              "speed3d": 4.855714285714286,
              "msFromStart": 316196.48533333314
            },
            {
              "lat": 43.7710852,
              "lng": -116.0863517,
              "alt": 2259.7386666666666,
              "speed2d": 4.297,
              "speed3d": 4.949999999999999,
              "msFromStart": 316693.58340740734
            },
            {
              "lat": 43.7710967,
              "lng": -116.08639023333333,
              "alt": 2261.216,
              "speed2d": 4.449333333333334,
              "speed3d": 5.0633333333333335,
              "msFromStart": 317425.78962962964
            },
            {
              "lat": 43.77109985,
              "lng": -116.0864032,
              "alt": 2261.5789999999997,
              "speed2d": 4.4595,
              "speed3d": 5.085,
              "msFromStart": 317663.74400000006
            },
            {
              "lat": 43.77111113333333,
              "lng": -116.08644114999998,
              "alt": 2262.6466666666665,
              "speed2d": 4.958166666666667,
              "speed3d": 5.473333333333333,
              "msFromStart": 318295.2737777779
            },
            {
              "lat": 43.7711173,
              "lng": -116.08646425,
              "alt": 2263.1040000000003,
              "speed2d": 4.9485,
              "speed3d": 5.455,
              "msFromStart": 318680.744
            },
            {
              "lat": 43.77112770000001,
              "lng": -116.08649528,
              "alt": 2264.5526,
              "speed2d": 4.6602,
              "speed3d": 5.216,
              "msFromStart": 319259.9038877193
            },
            {
              "lat": 43.77113588,
              "lng": -116.0865199,
              "alt": 2265.7662,
              "speed2d": 4.4404,
              "speed3d": 5.0520000000000005,
              "msFromStart": 319743.98821052635
            },
            {
              "lat": 43.771142850000004,
              "lng": -116.08654305,
              "alt": 2266.6335,
              "speed2d": 4.3835,
              "speed3d": 4.945,
              "msFromStart": 320194.34947368427
            },
            {
              "lat": 43.771152380000004,
              "lng": -116.08657382,
              "alt": 2267.7704,
              "speed2d": 4.6208,
              "speed3d": 5.138,
              "msFromStart": 320765.8496000001
            },
            {
              "lat": 43.7711585,
              "lng": -116.08659575,
              "alt": 2268.357,
              "speed2d": 4.747999999999999,
              "speed3d": 5.215,
              "msFromStart": 321156.2044444445
            },
            {
              "lat": 43.77116945,
              "lng": -116.08663519999999,
              "alt": 2269.577,
              "speed2d": 4.869,
              "speed3d": 5.325,
              "msFromStart": 321844.83022222214
            },
            {
              "lat": 43.77117648571429,
              "lng": -116.08665758571429,
              "alt": 2270.505142857143,
              "speed2d": 4.755571428571429,
              "speed3d": 5.2628571428571425,
              "msFromStart": 322254.71568253957
            },
            {
              "lat": 43.77118185,
              "lng": -116.08667514999999,
              "alt": 2271.2574999999997,
              "speed2d": 4.60125,
              "speed3d": 5.125,
              "msFromStart": 322589.3537777778
            },
            {
              "lat": 43.7711945,
              "lng": -116.08671749999999,
              "alt": 2273.059,
              "speed2d": 4.474,
              "speed3d": 4.985,
              "msFromStart": 323414.8204444444
            },
            {
              "lat": 43.7712017,
              "lng": -116.0867426,
              "alt": 2274.0623333333333,
              "speed2d": 4.517333333333333,
              "speed3d": 5.02,
              "msFromStart": 323900.098962963
            },
            {
              "lat": 43.77120435,
              "lng": -116.0867519,
              "alt": 2274.416,
              "speed2d": 4.566000000000001,
              "speed3d": 5.04,
              "msFromStart": 324074.04888888885
            },
            {
              "lat": 43.771215825,
              "lng": -116.0867929,
              "alt": 2276.01825,
              "speed2d": 4.63275,
              "speed3d": 5.0775,
              "msFromStart": 324843.5250526317
            },
            {
              "lat": 43.77122235,
              "lng": -116.08681625,
              "alt": 2276.9049999999997,
              "speed2d": 4.6259999999999994,
              "speed3d": 5.105,
              "msFromStart": 325283.4408421055
            },
            {
              "lat": 43.771230966666664,
              "lng": -116.08684693333333,
              "alt": 2278.1203333333333,
              "speed2d": 4.572666666666667,
              "speed3d": 5.07,
              "msFromStart": 325860.92266666645
            },
            {
              "lat": 43.771233190799364,
              "lng": -116.08685466731342,
              "alt": 2278.329399577579,
              "speed2d": 4.664757861160576,
              "speed3d": 5.092082318584289,
              "msFromStart": 326000
            },
            {
              "lat": 43.77124316,
              "lng": -116.08688594,
              "alt": 2280.0534000000002,
              "speed2d": 4.4542,
              "speed3d": 4.9799999999999995,
              "msFromStart": 326630.75733333325
            },
            {
              "lat": 43.771254866666666,
              "lng": -116.08692513333332,
              "alt": 2281.8526666666667,
              "speed2d": 4.645,
              "speed3d": 5.13,
              "msFromStart": 327381.5371851849
            },
            {
              "lat": 43.7712600375,
              "lng": -116.08694270000002,
              "alt": 2282.6765,
              "speed2d": 4.5885,
              "speed3d": 5.140000000000001,
              "msFromStart": 327723.8233333333
            },
            {
              "lat": 43.77126583333333,
              "lng": -116.0869643,
              "alt": 2283.5159999999996,
              "speed2d": 4.589,
              "speed3d": 5.12,
              "msFromStart": 328135.4305185184
            },
            {
              "lat": 43.77127286666666,
              "lng": -116.08699116666665,
              "alt": 2284.3443333333335,
              "speed2d": 4.601666666666667,
              "speed3d": 5.14,
              "msFromStart": 328631.2
            },
            {
              "lat": 43.7712819,
              "lng": -116.08702405,
              "alt": 2285.498,
              "speed2d": 4.5995,
              "speed3d": 5.09,
              "msFromStart": 329244.38400000014
            },
            {
              "lat": 43.77128512817451,
              "lng": -116.0870369881432,
              "alt": 2286.0350168248333,
              "speed2d": 4.407552142263992,
              "speed3d": 4.840418704504488,
              "msFromStart": 329500
            },
            {
              "lat": 43.771300440000005,
              "lng": -116.08708276000002,
              "alt": 2288.1668,
              "speed2d": 4.617,
              "speed3d": 5.142,
              "msFromStart": 330373.7734736842
            },
            {
              "lat": 43.771306616666664,
              "lng": -116.08710315,
              "alt": 2289.01,
              "speed2d": 4.560333333333333,
              "speed3d": 5.108333333333334,
              "msFromStart": 330759.2142222222
            },
            {
              "lat": 43.771315575,
              "lng": -116.08713175,
              "alt": 2290.2,
              "speed2d": 4.59125,
              "speed3d": 5.1225,
              "msFromStart": 331295.8195555555
            },
            {
              "lat": 43.771322825,
              "lng": -116.087154325,
              "alt": 2291.26675,
              "speed2d": 4.58875,
              "speed3d": 5.145,
              "msFromStart": 331721.4959999999
            },
            {
              "lat": 43.771328999999994,
              "lng": -116.08717465,
              "alt": 2292.207,
              "speed2d": 4.541,
              "speed3d": 5.13,
              "msFromStart": 332105.41955555533
            },
            {
              "lat": 43.77133592560621,
              "lng": -116.08719593831233,
              "alt": 2293.2394306498545,
              "speed2d": 4.637821532492726,
              "speed3d": 5.22984481086324,
              "msFromStart": 332500
            },
            {
              "lat": 43.7713496,
              "lng": -116.08723737499999,
              "alt": 2295.6755,
              "speed2d": 4.53775,
              "speed3d": 5.18,
              "msFromStart": 333314.2133333337
            },
            {
              "lat": 43.771358500000005,
              "lng": -116.08726884999999,
              "alt": 2296.4030000000002,
              "speed2d": 4.82,
              "speed3d": 5.29,
              "msFromStart": 333863.94666666666
            },
            {
              "lat": 43.77136642,
              "lng": -116.08729364000001,
              "alt": 2297.2264,
              "speed2d": 5.005000000000001,
              "speed3d": 5.5,
              "msFromStart": 334287.20711111114
            },
            {
              "lat": 43.77137515,
              "lng": -116.0873235,
              "alt": 2298.053,
              "speed2d": 5.0515,
              "speed3d": 5.52,
              "msFromStart": 334799.46021052636
            },
            {
              "lat": 43.77137836415963,
              "lng": -116.08733446993821,
              "alt": 2298.363648707547,
              "speed2d": 4.9815475802787095,
              "speed3d": 5.481226782118965,
              "msFromStart": 335000
            },
            {
              "lat": 43.77138664326649,
              "lng": -116.08736184363256,
              "alt": 2299.091061564427,
              "speed2d": 4.853053927599559,
              "speed3d": 5.311337595150255,
              "msFromStart": 335500
            },
            {
              "lat": 43.77139492237335,
              "lng": -116.0873892173269,
              "alt": 2299.818474421307,
              "speed2d": 4.7245602749204085,
              "speed3d": 5.141448408181547,
              "msFromStart": 336000
            },
            {
              "lat": 43.77141045,
              "lng": -116.08744085000001,
              "alt": 2301.1985,
              "speed2d": 4.4815000000000005,
              "speed3d": 4.85,
              "msFromStart": 336944.11911111104
            },
            {
              "lat": 43.7714149,
              "lng": -116.08745647499998,
              "alt": 2301.66125,
              "speed2d": 4.590999999999999,
              "speed3d": 4.9475,
              "msFromStart": 337232.8364444443
            },
            {
              "lat": 43.77142190000001,
              "lng": -116.0874801,
              "alt": 2302.38,
              "speed2d": 4.74225,
              "speed3d": 5.08,
              "msFromStart": 337658.94488888886
            },
            {
              "lat": 43.7714273783314,
              "lng": -116.08749951645237,
              "alt": 2302.8394508370325,
              "speed2d": 4.776061441596914,
              "speed3d": 5.110068268441015,
              "msFromStart": 338000
            },
            {
              "lat": 43.77143545694624,
              "lng": -116.08752797004759,
              "alt": 2303.5382996866892,
              "speed2d": 4.79358132919277,
              "speed3d": 5.129534810214189,
              "msFromStart": 338500
            },
            {
              "lat": 43.7714486,
              "lng": -116.08757399999999,
              "alt": 2304.6565,
              "speed2d": 4.8215,
              "speed3d": 5.145,
              "msFromStart": 339310.0524444449
            },
            {
              "lat": 43.77145542857143,
              "lng": -116.08760034285713,
              "alt": 2304.8719999999994,
              "speed2d": 4.968428571428572,
              "speed3d": 5.2285714285714295,
              "msFromStart": 339769.5636691731
            },
            {
              "lat": 43.771459596881954,
              "lng": -116.08761402144421,
              "alt": 2304.988658560272,
              "speed2d": 5.157222814750163,
              "speed3d": 5.34938403277814,
              "msFromStart": 340000
            },
            {
              "lat": 43.77146738757818,
              "lng": -116.08764251150852,
              "alt": 2304.7657422886773,
              "speed2d": 5.111161034139992,
              "speed3d": 5.2470245203110935,
              "msFromStart": 340500
            },
            {
              "lat": 43.77147449410889,
              "lng": -116.08767088865949,
              "alt": 2304.167702499797,
              "speed2d": 4.972754049025001,
              "speed3d": 5.008129601164776,
              "msFromStart": 341000
            },
            {
              "lat": 43.77148378079585,
              "lng": -116.08770066271329,
              "alt": 2304.539127431086,
              "speed2d": 5.122113113006873,
              "speed3d": 5.169521536821835,
              "msFromStart": 341500
            },
            {
              "lat": 43.771486857508485,
              "lng": -116.08771381831215,
              "alt": 2304.902270459684,
              "speed2d": 4.531020537241687,
              "speed3d": 4.614803888796045,
              "msFromStart": 342000
            },
            {
              "lat": 43.77148993422112,
              "lng": -116.08772697391102,
              "alt": 2305.265413488283,
              "speed2d": 3.939927961476502,
              "speed3d": 4.060086240770255,
              "msFromStart": 342500
            },
            {
              "lat": 43.77149301093376,
              "lng": -116.08774012950988,
              "alt": 2305.628556516881,
              "speed2d": 3.348835385711317,
              "speed3d": 3.5053685927444667,
              "msFromStart": 343000
            },
            {
              "lat": 43.7714960876464,
              "lng": -116.08775328510875,
              "alt": 2305.991699545479,
              "speed2d": 2.7577428099461314,
              "speed3d": 2.950650944718677,
              "msFromStart": 343500
            },
            {
              "lat": 43.771499164359035,
              "lng": -116.08776644070761,
              "alt": 2306.3548425740773,
              "speed2d": 2.166650234180946,
              "speed3d": 2.395933296692888,
              "msFromStart": 344000
            }
          ]
        }
      ]
    },
    {
      "id": "lift-bitterroot",
      "pathType": "lift",
      "name": "Bitterroot",
      "speed": "slow",
      "seats": 2,
      "videos": [
        {
          "videoId": "GH010044",
          "pathId": "lift-bitterroot",
          "startSec": 12,
          "endSec": 375,
          "id": "video-segment-14",
          "gps": [
            {
              "lat": 43.77689617777778,
              "lng": -116.10724011111111,
              "alt": 1900.485111111111,
              "speed2d": 2.206,
              "speed3d": 2.062222222222222,
              "msFromStart": 12233.186391812864
            },
            {
              "lat": 43.776877988888884,
              "lng": -116.1072268222222,
              "alt": 1900.7736666666667,
              "speed2d": 3.3985555555555553,
              "speed3d": 3.336666666666666,
              "msFromStart": 12729.078666666674
            },
            {
              "lat": 43.77686667777778,
              "lng": -116.10721919999999,
              "alt": 1901.3907777777779,
              "speed2d": 2.929333333333333,
              "speed3d": 3.0633333333333335,
              "msFromStart": 13225.107129629629
            },
            {
              "lat": 43.776860490000004,
              "lng": -116.10721581,
              "alt": 1902.0823,
              "speed2d": 1.9457,
              "speed3d": 2.0229999999999997,
              "msFromStart": 13748.820472222214
            },
            {
              "lat": 43.77685751111111,
              "lng": -116.10720945555558,
              "alt": 1902.4763333333333,
              "speed2d": 1.2955555555555556,
              "speed3d": 1.3655555555555554,
              "msFromStart": 14272.334487654318
            },
            {
              "lat": 43.77685325555556,
              "lng": -116.10720453333333,
              "alt": 1903.1942222222222,
              "speed2d": 1.3163333333333334,
              "speed3d": 1.324444444444444,
              "msFromStart": 14767.902333333326
            },
            {
              "lat": 43.77684522222222,
              "lng": -116.10719844444444,
              "alt": 1903.4823333333331,
              "speed2d": 1.8519999999999999,
              "speed3d": 1.8255555555555556,
              "msFromStart": 15263.107648148147
            },
            {
              "lat": 43.7768336111111,
              "lng": -116.10719013333333,
              "alt": 1903.2355555555555,
              "speed2d": 2.6382222222222227,
              "speed3d": 2.567777777777778,
              "msFromStart": 15757.65333333333
            },
            {
              "lat": 43.77681874444444,
              "lng": -116.10718462222222,
              "alt": 1902.463777777778,
              "speed2d": 3.126333333333333,
              "speed3d": 3.132222222222222,
              "msFromStart": 16253.012560753737
            },
            {
              "lat": 43.77680607777778,
              "lng": -116.10717888888888,
              "alt": 1902.4632222222222,
              "speed2d": 2.8405555555555555,
              "speed3d": 2.8966666666666674,
              "msFromStart": 16749.906210526307
            },
            {
              "lat": 43.776797755555556,
              "lng": -116.10717575555555,
              "alt": 1902.9011111111108,
              "speed2d": 2.0851111111111114,
              "speed3d": 2.188888888888889,
              "msFromStart": 17246.35540155945
            },
            {
              "lat": 43.77679362222223,
              "lng": -116.10717248888886,
              "alt": 1903.332888888889,
              "speed2d": 1.383111111111111,
              "speed3d": 1.45,
              "msFromStart": 17741.51811111112
            },
            {
              "lat": 43.776787666666664,
              "lng": -116.10716914444446,
              "alt": 1903.6955555555553,
              "speed2d": 1.3398888888888887,
              "speed3d": 1.29,
              "msFromStart": 18235.94033333334
            },
            {
              "lat": 43.77677315555555,
              "lng": -116.1071609,
              "alt": 1903.992888888889,
              "speed2d": 2.529,
              "speed3d": 2.4122222222222227,
              "msFromStart": 18728.53644444445
            },
            {
              "lat": 43.77675698,
              "lng": -116.10715258999998,
              "alt": 1903.9689000000003,
              "speed2d": 3.2057,
              "speed3d": 3.1969999999999996,
              "msFromStart": 19249.071733333334
            },
            {
              "lat": 43.77674376666668,
              "lng": -116.10714986666666,
              "alt": 1903.687111111111,
              "speed2d": 2.8054444444444444,
              "speed3d": 2.8933333333333335,
              "msFromStart": 19771.148
            },
            {
              "lat": 43.77673576666667,
              "lng": -116.10714992222222,
              "alt": 1903.0683333333332,
              "speed2d": 1.943111111111111,
              "speed3d": 2.0233333333333334,
              "msFromStart": 20266.027701754385
            },
            {
              "lat": 43.77673081111111,
              "lng": -116.10714927777778,
              "alt": 1902.8075555555556,
              "speed2d": 1.3533333333333335,
              "speed3d": 1.4188888888888886,
              "msFromStart": 20761.302421052627
            },
            {
              "lat": 43.776725411111116,
              "lng": -116.10714743333331,
              "alt": 1902.4052222222224,
              "speed2d": 1.4403333333333335,
              "speed3d": 1.3711111111111112,
              "msFromStart": 21256.751358674464
            },
            {
              "lat": 43.77671574444445,
              "lng": -116.10713976666666,
              "alt": 1902.444888888889,
              "speed2d": 2.2415555555555557,
              "speed3d": 2.1655555555555552,
              "msFromStart": 21752.65255555556
            },
            {
              "lat": 43.77670273333333,
              "lng": -116.10713192222222,
              "alt": 1902.1938888888892,
              "speed2d": 2.983222222222222,
              "speed3d": 2.9222222222222225,
              "msFromStart": 22248.285111111112
            },
            {
              "lat": 43.77668901111111,
              "lng": -116.107127,
              "alt": 1901.8972222222224,
              "speed2d": 3.1808888888888887,
              "speed3d": 3.202222222222222,
              "msFromStart": 22743.12388888889
            },
            {
              "lat": 43.776678588888885,
              "lng": -116.10712699999999,
              "alt": 1901.2296666666668,
              "speed2d": 2.418555555555556,
              "speed3d": 2.548888888888889,
              "msFromStart": 23237.984666666664
            },
            {
              "lat": 43.77667176666666,
              "lng": -116.10713225555554,
              "alt": 1899.9042222222222,
              "speed2d": 1.541222222222222,
              "speed3d": 1.6044444444444448,
              "msFromStart": 23733.01855555554
            },
            {
              "lat": 43.7766662,
              "lng": -116.10713226666665,
              "alt": 1899.2604444444441,
              "speed2d": 1.428111111111111,
              "speed3d": 1.4344444444444444,
              "msFromStart": 24227.728666666662
            },
            {
              "lat": 43.77665963999999,
              "lng": -116.10713193999999,
              "alt": 1898.6871,
              "speed2d": 1.5055,
              "speed3d": 1.475,
              "msFromStart": 24748.966277777763
            },
            {
              "lat": 43.77665131111111,
              "lng": -116.10712674444444,
              "alt": 1898.0647777777776,
              "speed2d": 2.0643333333333334,
              "speed3d": 1.988888888888889,
              "msFromStart": 25270.740185185186
            },
            {
              "lat": 43.77663938888889,
              "lng": -116.10711995555558,
              "alt": 1897.341777777778,
              "speed2d": 2.7953333333333337,
              "speed3d": 2.732222222222222,
              "msFromStart": 25766.167333333335
            },
            {
              "lat": 43.77662874444444,
              "lng": -116.10711826666667,
              "alt": 1896.8174444444444,
              "speed2d": 2.6573333333333338,
              "speed3d": 2.732222222222222,
              "msFromStart": 26261.58098375569
            },
            {
              "lat": 43.776622133333326,
              "lng": -116.10711803333334,
              "alt": 1896.135,
              "speed2d": 1.902111111111111,
              "speed3d": 1.9877777777777776,
              "msFromStart": 26756.930842105285
            },
            {
              "lat": 43.77661821111111,
              "lng": -116.10711681111111,
              "alt": 1895.6282222222221,
              "speed2d": 1.3902222222222222,
              "speed3d": 1.4066666666666667,
              "msFromStart": 27252.385087719304
            },
            {
              "lat": 43.776613633333334,
              "lng": -116.10711276666667,
              "alt": 1895.4462222222228,
              "speed2d": 1.5081111111111114,
              "speed3d": 1.4944444444444445,
              "msFromStart": 27748.12599999999
            },
            {
              "lat": 43.77660533333333,
              "lng": -116.10711105555556,
              "alt": 1894.961777777778,
              "speed2d": 2.045111111111111,
              "speed3d": 1.9944444444444442,
              "msFromStart": 28243.59240740741
            },
            {
              "lat": 43.77659413333333,
              "lng": -116.10710504444444,
              "alt": 1894.466222222222,
              "speed2d": 2.6580000000000004,
              "speed3d": 2.6011111111111114,
              "msFromStart": 28738.2727777778
            },
            {
              "lat": 43.77658301111111,
              "lng": -116.10709855555555,
              "alt": 1894.2146666666667,
              "speed2d": 2.811777777777778,
              "speed3d": 2.837777777777778,
              "msFromStart": 29232.81844444445
            },
            {
              "lat": 43.77657483333333,
              "lng": -116.1070943888889,
              "alt": 1893.9778888888886,
              "speed2d": 2.2517777777777774,
              "speed3d": 2.35,
              "msFromStart": 29727.11077777778
            },
            {
              "lat": 43.77656917,
              "lng": -116.10709288000001,
              "alt": 1893.3135000000002,
              "speed2d": 1.6230999999999998,
              "speed3d": 1.669,
              "msFromStart": 30249.099380701762
            },
            {
              "lat": 43.77656404444445,
              "lng": -116.1070896111111,
              "alt": 1892.7062222222223,
              "speed2d": 1.5339999999999998,
              "speed3d": 1.5366666666666666,
              "msFromStart": 30771.65863157896
            },
            {
              "lat": 43.77655764444444,
              "lng": -116.10708325555555,
              "alt": 1892.3738888888893,
              "speed2d": 1.8780000000000001,
              "speed3d": 1.827777777777778,
              "msFromStart": 31266.844723196882
            },
            {
              "lat": 43.77654901111111,
              "lng": -116.10707616666666,
              "alt": 1892.0604444444448,
              "speed2d": 2.3145555555555557,
              "speed3d": 2.267777777777778,
              "msFromStart": 31762.314777777767
            },
            {
              "lat": 43.77653773333333,
              "lng": -116.10706995555554,
              "alt": 1891.3703333333333,
              "speed2d": 2.7611111111111106,
              "speed3d": 2.742222222222222,
              "msFromStart": 32257.73651851851
            },
            {
              "lat": 43.776527533333336,
              "lng": -116.10706556666668,
              "alt": 1890.9554444444443,
              "speed2d": 2.561888888888889,
              "speed3d": 2.612222222222222,
              "msFromStart": 32752.986555555544
            },
            {
              "lat": 43.77651992222222,
              "lng": -116.10706137777777,
              "alt": 1890.6650000000002,
              "speed2d": 2.108,
              "speed3d": 2.1544444444444446,
              "msFromStart": 33248.10881481481
            },
            {
              "lat": 43.77651445555555,
              "lng": -116.10705405555554,
              "alt": 1890.4494444444445,
              "speed2d": 1.807777777777778,
              "speed3d": 1.8266666666666667,
              "msFromStart": 33742.90877777779
            },
            {
              "lat": 43.7765087,
              "lng": -116.10704407777779,
              "alt": 1889.8553333333332,
              "speed2d": 2.015444444444445,
              "speed3d": 1.972222222222222,
              "msFromStart": 34237.977222222224
            },
            {
              "lat": 43.776500277777785,
              "lng": -116.10703378888888,
              "alt": 1889.5936666666669,
              "speed2d": 2.270777777777778,
              "speed3d": 2.2544444444444447,
              "msFromStart": 34733.82044444445
            },
            {
              "lat": 43.77648997777778,
              "lng": -116.10702381111112,
              "alt": 1889.6034444444444,
              "speed2d": 2.495111111111111,
              "speed3d": 2.473333333333333,
              "msFromStart": 35229.188
            },
            {
              "lat": 43.776480570000004,
              "lng": -116.10701618,
              "alt": 1889.6251,
              "speed2d": 2.2981,
              "speed3d": 2.361,
              "msFromStart": 35750.592222222214
            },
            {
              "lat": 43.77647496666667,
              "lng": -116.10701025555555,
              "alt": 1889.8944444444446,
              "speed2d": 1.614,
              "speed3d": 1.6755555555555557,
              "msFromStart": 36272.10198310591
            },
            {
              "lat": 43.77647118888889,
              "lng": -116.10700655555556,
              "alt": 1889.8162222222222,
              "speed2d": 1.0834444444444444,
              "speed3d": 1.1288888888888888,
              "msFromStart": 36766.50631578946
            },
            {
              "lat": 43.77646832222223,
              "lng": -116.1070009,
              "alt": 1890.0738888888889,
              "speed2d": 0.9165555555555556,
              "speed3d": 0.9199999999999999,
              "msFromStart": 37261.296417154
            },
            {
              "lat": 43.7764639,
              "lng": -116.10699473333332,
              "alt": 1890.4670000000003,
              "speed2d": 1.1391111111111112,
              "speed3d": 1.1144444444444443,
              "msFromStart": 37757.116222222256
            },
            {
              "lat": 43.77645691111111,
              "lng": -116.10698656666666,
              "alt": 1890.5494444444444,
              "speed2d": 1.568888888888889,
              "speed3d": 1.5344444444444445,
              "msFromStart": 38252.904148148154
            },
            {
              "lat": 43.776449755555554,
              "lng": -116.10697962222223,
              "alt": 1890.8065555555554,
              "speed2d": 1.65,
              "speed3d": 1.6744444444444442,
              "msFromStart": 38748.45844444445
            },
            {
              "lat": 43.77644359999999,
              "lng": -116.1069743888889,
              "alt": 1891.0710000000001,
              "speed2d": 1.3724444444444446,
              "speed3d": 1.4366666666666665,
              "msFromStart": 39243.99007407408
            },
            {
              "lat": 43.77643911111112,
              "lng": -116.10697056666666,
              "alt": 1891.3383333333334,
              "speed2d": 0.9783333333333333,
              "speed3d": 1.04,
              "msFromStart": 39739.49355555557
            },
            {
              "lat": 43.77643488888889,
              "lng": -116.10696695555556,
              "alt": 1891.5765555555556,
              "speed2d": 0.8888888888888888,
              "speed3d": 0.9322222222222222,
              "msFromStart": 40234.51177777779
            },
            {
              "lat": 43.77643063333333,
              "lng": -116.1069629,
              "alt": 1891.8155555555554,
              "speed2d": 0.9832222222222221,
              "speed3d": 1.001111111111111,
              "msFromStart": 40728.21688888893
            },
            {
              "lat": 43.776424889999994,
              "lng": -116.10695749,
              "alt": 1892.2158,
              "speed2d": 1.2124000000000001,
              "speed3d": 1.237,
              "msFromStart": 41249.67611929827
            },
            {
              "lat": 43.77641832222223,
              "lng": -116.10694933333335,
              "alt": 1892.8006666666668,
              "speed2d": 1.5441111111111112,
              "speed3d": 1.5444444444444445,
              "msFromStart": 41772.04736842109
            },
            {
              "lat": 43.7764124,
              "lng": -116.10694081111112,
              "alt": 1893.3872222222226,
              "speed2d": 1.578222222222222,
              "speed3d": 1.651111111111111,
              "msFromStart": 42267.082943469795
            },
            {
              "lat": 43.776406355555565,
              "lng": -116.10693422222224,
              "alt": 1893.4294444444442,
              "speed2d": 1.3334444444444444,
              "speed3d": 1.373333333333333,
              "msFromStart": 42762.43622222221
            },
            {
              "lat": 43.776400222222215,
              "lng": -116.10692587777777,
              "alt": 1893.5093333333334,
              "speed2d": 1.4705555555555556,
              "speed3d": 1.4866666666666666,
              "msFromStart": 43258.168666666665
            },
            {
              "lat": 43.776394322222224,
              "lng": -116.10692032222224,
              "alt": 1893.938333333333,
              "speed2d": 1.4420000000000002,
              "speed3d": 1.4666666666666668,
              "msFromStart": 43754.884888888904
            },
            {
              "lat": 43.77638561111111,
              "lng": -116.1069147,
              "alt": 1894.403,
              "speed2d": 1.8092222222222223,
              "speed3d": 1.7988888888888888,
              "msFromStart": 44251.18725925927
            },
            {
              "lat": 43.77637642222223,
              "lng": -116.10690532222222,
              "alt": 1894.9306666666666,
              "speed2d": 2.088888888888889,
              "speed3d": 2.098888888888889,
              "msFromStart": 44746.2257777778
            },
            {
              "lat": 43.77636705555556,
              "lng": -116.10689513333334,
              "alt": 1895.5215555555558,
              "speed2d": 2.2790000000000004,
              "speed3d": 2.2900000000000005,
              "msFromStart": 45241.0634814815
            },
            {
              "lat": 43.77635901111111,
              "lng": -116.1068863888889,
              "alt": 1896.2574444444444,
              "speed2d": 2.116888888888889,
              "speed3d": 2.1966666666666663,
              "msFromStart": 45735.53666666669
            },
            {
              "lat": 43.776352744444445,
              "lng": -116.10687775555556,
              "alt": 1896.929,
              "speed2d": 1.777444444444444,
              "speed3d": 1.8433333333333333,
              "msFromStart": 46229.5118011696
            },
            {
              "lat": 43.77634625,
              "lng": -116.10686670000001,
              "alt": 1897.8348,
              "speed2d": 1.6856,
              "speed3d": 1.7739999999999998,
              "msFromStart": 46749.55421052634
            },
            {
              "lat": 43.77634079999999,
              "lng": -116.10685591111113,
              "alt": 1898.8341111111113,
              "speed2d": 1.6765555555555556,
              "speed3d": 1.7944444444444447,
              "msFromStart": 47269.62129824561
            },
            {
              "lat": 43.77633993333333,
              "lng": -116.10684243333333,
              "alt": 1899.001111111111,
              "speed2d": 1.5675555555555556,
              "speed3d": 1.6744444444444444,
              "msFromStart": 47762.57399999998
            },
            {
              "lat": 43.77633863333334,
              "lng": -116.10683200000001,
              "alt": 1899.1113333333335,
              "speed2d": 1.187,
              "speed3d": 1.3955555555555557,
              "msFromStart": 48263.37118518517
            },
            {
              "lat": 43.7763331375,
              "lng": -116.1068276375,
              "alt": 1899.3201250000002,
              "speed2d": 0.95725,
              "speed3d": 1.04125,
              "msFromStart": 48756.35933333333
            },
            {
              "lat": 43.77632498888889,
              "lng": -116.10682044444442,
              "alt": 1900.2632222222223,
              "speed2d": 1.3445555555555555,
              "speed3d": 1.4455555555555557,
              "msFromStart": 49246.16918518519
            },
            {
              "lat": 43.7763164,
              "lng": -116.10681263333333,
              "alt": 1900.8153333333332,
              "speed2d": 1.5436666666666667,
              "speed3d": 1.6022222222222222,
              "msFromStart": 49747.856000000036
            },
            {
              "lat": 43.77630526666667,
              "lng": -116.10680476666667,
              "alt": 1901.3292222222221,
              "speed2d": 2.0156666666666667,
              "speed3d": 2.061111111111111,
              "msFromStart": 50246.69881481482
            },
            {
              "lat": 43.77629831111112,
              "lng": -116.10679365555556,
              "alt": 1901.5112222222224,
              "speed2d": 2.050777777777778,
              "speed3d": 2.1188888888888893,
              "msFromStart": 50742.48755555553
            },
            {
              "lat": 43.77629544444444,
              "lng": -116.10678094444447,
              "alt": 1901.2646666666667,
              "speed2d": 1.7817777777777777,
              "speed3d": 1.8366666666666664,
              "msFromStart": 51237.59610916177
            },
            {
              "lat": 43.77629103333333,
              "lng": -116.10676765555556,
              "alt": 1901.198888888889,
              "speed2d": 1.8316666666666666,
              "speed3d": 1.8477777777777777,
              "msFromStart": 51731.294736842094
            },
            {
              "lat": 43.7762843,
              "lng": -116.10675716000002,
              "alt": 1902.0633,
              "speed2d": 1.8338999999999999,
              "speed3d": 1.889,
              "msFromStart": 52252.50597192983
            },
            {
              "lat": 43.77627742222222,
              "lng": -116.10674634444445,
              "alt": 1902.6263333333332,
              "speed2d": 1.7724444444444447,
              "speed3d": 1.8044444444444445,
              "msFromStart": 52774.245777777796
            },
            {
              "lat": 43.776266211111114,
              "lng": -116.10673782222221,
              "alt": 1903.288777777778,
              "speed2d": 2.031,
              "speed3d": 2.033333333333333,
              "msFromStart": 53268.80155555556
            },
            {
              "lat": 43.77625528888889,
              "lng": -116.1067276,
              "alt": 1903.9208888888888,
              "speed2d": 2.2925555555555555,
              "speed3d": 2.316666666666667,
              "msFromStart": 53763.98644444445
            },
            {
              "lat": 43.77624397777778,
              "lng": -116.10671986666665,
              "alt": 1904.5411111111111,
              "speed2d": 2.335444444444444,
              "speed3d": 2.4111111111111114,
              "msFromStart": 54259.13962962962
            },
            {
              "lat": 43.776233455555555,
              "lng": -116.10671484444445,
              "alt": 1904.8346666666662,
              "speed2d": 2.200222222222222,
              "speed3d": 2.2399999999999998,
              "msFromStart": 54754.11688888886
            },
            {
              "lat": 43.776224400000004,
              "lng": -116.10670871111112,
              "alt": 1905.465888888889,
              "speed2d": 2.039,
              "speed3d": 2.1488888888888886,
              "msFromStart": 55249.42192592592
            },
            {
              "lat": 43.776217711111116,
              "lng": -116.10670545555556,
              "alt": 1905.854888888889,
              "speed2d": 1.6766666666666667,
              "speed3d": 1.78,
              "msFromStart": 55745.6417777778
            },
            {
              "lat": 43.7762099,
              "lng": -116.10670153333332,
              "alt": 1906.327,
              "speed2d": 1.6447777777777777,
              "speed3d": 1.7099999999999995,
              "msFromStart": 56241.25879142302
            },
            {
              "lat": 43.77620228888889,
              "lng": -116.10669352222223,
              "alt": 1906.932,
              "speed2d": 1.816,
              "speed3d": 1.8588888888888888,
              "msFromStart": 56735.10884210529
            },
            {
              "lat": 43.7761942,
              "lng": -116.10668372222221,
              "alt": 1907.3694444444443,
              "speed2d": 2.039111111111111,
              "speed3d": 2.1222222222222222,
              "msFromStart": 57229.06125536065
            },
            {
              "lat": 43.776188160000004,
              "lng": -116.10667297999998,
              "alt": 1907.4402999999998,
              "speed2d": 1.9447999999999999,
              "speed3d": 2.025,
              "msFromStart": 57751.284666666674
            },
            {
              "lat": 43.77618252222223,
              "lng": -116.10666665555556,
              "alt": 1907.4185555555557,
              "speed2d": 1.5281111111111112,
              "speed3d": 1.6699999999999997,
              "msFromStart": 58273.649999999994
            },
            {
              "lat": 43.776178433333335,
              "lng": -116.10666575555557,
              "alt": 1907.3364444444449,
              "speed2d": 1.0953333333333335,
              "speed3d": 1.2333333333333332,
              "msFromStart": 58768.69888888887
            },
            {
              "lat": 43.77617652222222,
              "lng": -116.10666042222223,
              "alt": 1907.4503333333332,
              "speed2d": 0.8208888888888888,
              "speed3d": 0.9588888888888889,
              "msFromStart": 59263.89740740741
            },
            {
              "lat": 43.77617733333334,
              "lng": -116.10665555555555,
              "alt": 1907.1333333333334,
              "speed2d": 0.5995555555555556,
              "speed3d": 0.7344444444444443,
              "msFromStart": 59759.47733333333
            },
            {
              "lat": 43.7761762,
              "lng": -116.10664975555558,
              "alt": 1906.3068888888888,
              "speed2d": 0.6923333333333335,
              "speed3d": 0.7188888888888889,
              "msFromStart": 60255.1265925926
            },
            {
              "lat": 43.77617406666667,
              "lng": -116.1066428,
              "alt": 1905.7965555555556,
              "speed2d": 0.8581111111111113,
              "speed3d": 0.8677777777777778,
              "msFromStart": 60750.90955555557
            },
            {
              "lat": 43.77617266666667,
              "lng": -116.10663617777777,
              "alt": 1904.9651111111111,
              "speed2d": 0.8578888888888888,
              "speed3d": 0.8788888888888889,
              "msFromStart": 61246.31003898635
            },
            {
              "lat": 43.77616948888889,
              "lng": -116.10663134444445,
              "alt": 1904.3343333333335,
              "speed2d": 0.8064444444444445,
              "speed3d": 0.7966666666666667,
              "msFromStart": 61740.65326315787
            },
            {
              "lat": 43.77616572222222,
              "lng": -116.10663117777779,
              "alt": 1904.4101111111113,
              "speed2d": 0.7967777777777777,
              "speed3d": 0.7977777777777778,
              "msFromStart": 62235.15647563352
            },
            {
              "lat": 43.776160422222226,
              "lng": -116.10662927777777,
              "alt": 1904.5281111111112,
              "speed2d": 1.1183333333333332,
              "speed3d": 1.0544444444444445,
              "msFromStart": 62730.52222222221
            },
            {
              "lat": 43.77615315555556,
              "lng": -116.10662068888888,
              "alt": 1904.6181111111111,
              "speed2d": 1.5266666666666668,
              "speed3d": 1.5266666666666668,
              "msFromStart": 63225.89755555555
            },
            {
              "lat": 43.77614842,
              "lng": -116.10661314000001,
              "alt": 1904.1797,
              "speed2d": 1.4203999999999999,
              "speed3d": 1.4199999999999997,
              "msFromStart": 63748.46833333337
            },
            {
              "lat": 43.776142377777774,
              "lng": -116.10660626666666,
              "alt": 1903.1891111111108,
              "speed2d": 1.4763333333333335,
              "speed3d": 1.4888888888888892,
              "msFromStart": 64270.74970370372
            },
            {
              "lat": 43.77613892222222,
              "lng": -116.10660199999998,
              "alt": 1901.8361111111112,
              "speed2d": 1.0863333333333334,
              "speed3d": 1.1833333333333333,
              "msFromStart": 64764.82666666667
            },
            {
              "lat": 43.7761368,
              "lng": -116.10659645555556,
              "alt": 1900.6344444444444,
              "speed2d": 0.8093333333333333,
              "speed3d": 0.9344444444444444,
              "msFromStart": 65259.022740740744
            },
            {
              "lat": 43.77613437777778,
              "lng": -116.10659055555554,
              "alt": 1899.8083333333334,
              "speed2d": 0.8942222222222221,
              "speed3d": 1.0166666666666666,
              "msFromStart": 65753.64244444443
            },
            {
              "lat": 43.776130477777784,
              "lng": -116.10658432222223,
              "alt": 1899.0811111111109,
              "speed2d": 1.0130000000000001,
              "speed3d": 1.1566666666666665,
              "msFromStart": 66248.61157504872
            },
            {
              "lat": 43.77612402222222,
              "lng": -116.10657824444445,
              "alt": 1898.1698888888889,
              "speed2d": 1.2267777777777777,
              "speed3d": 1.3466666666666667,
              "msFromStart": 66744.46863157893
            },
            {
              "lat": 43.776115288888896,
              "lng": -116.10657386666666,
              "alt": 1898.313,
              "speed2d": 1.4426666666666668,
              "speed3d": 1.5688888888888888,
              "msFromStart": 67240.24208966861
            },
            {
              "lat": 43.776108722222226,
              "lng": -116.10657213333333,
              "alt": 1898.6599999999999,
              "speed2d": 1.3163333333333334,
              "speed3d": 1.4422222222222223,
              "msFromStart": 67735.49422222222
            },
            {
              "lat": 43.776103,
              "lng": -116.10656978888889,
              "alt": 1899.0296666666668,
              "speed2d": 1.1605555555555556,
              "speed3d": 1.2855555555555553,
              "msFromStart": 68230.61200000001
            },
            {
              "lat": 43.77609827777778,
              "lng": -116.10656682222222,
              "alt": 1899.633111111111,
              "speed2d": 1.0446666666666666,
              "speed3d": 1.1377777777777776,
              "msFromStart": 68725.40088888894
            },
            {
              "lat": 43.77609515,
              "lng": -116.10656322,
              "alt": 1899.3035,
              "speed2d": 0.8573999999999999,
              "speed3d": 0.9729999999999999,
              "msFromStart": 69247.79920000002
            },
            {
              "lat": 43.776093355555545,
              "lng": -116.10655611111112,
              "alt": 1898.4417777777778,
              "speed2d": 0.9279999999999999,
              "speed3d": 1.0322222222222222,
              "msFromStart": 69770.70222222221
            },
            {
              "lat": 43.77608574444445,
              "lng": -116.10654814444443,
              "alt": 1898.598,
              "speed2d": 1.4642222222222223,
              "speed3d": 1.488888888888889,
              "msFromStart": 70266.06503703704
            },
            {
              "lat": 43.776077933333326,
              "lng": -116.10654428888887,
              "alt": 1898.9834444444446,
              "speed2d": 1.5386666666666668,
              "speed3d": 1.6211111111111112,
              "msFromStart": 70761.26177777778
            },
            {
              "lat": 43.77607084444444,
              "lng": -116.10653898888889,
              "alt": 1899.2895555555556,
              "speed2d": 1.5326666666666668,
              "speed3d": 1.5777777777777777,
              "msFromStart": 71256.43345808965
            },
            {
              "lat": 43.7760638,
              "lng": -116.10653444444442,
              "alt": 1899.6623333333334,
              "speed2d": 1.448888888888889,
              "speed3d": 1.5333333333333334,
              "msFromStart": 71751.56084210522
            },
            {
              "lat": 43.776058811111106,
              "lng": -116.10653114444446,
              "alt": 1900.004333333333,
              "speed2d": 1.2074444444444445,
              "speed3d": 1.2733333333333334,
              "msFromStart": 72246.9538479532
            },
            {
              "lat": 43.77605304444444,
              "lng": -116.10652675555556,
              "alt": 1900.6877777777781,
              "speed2d": 1.2594444444444446,
              "speed3d": 1.3044444444444445,
              "msFromStart": 72743.41866666672
            },
            {
              "lat": 43.776050622222215,
              "lng": -116.10652301111112,
              "alt": 1900.5675555555554,
              "speed2d": 1.0235555555555556,
              "speed3d": 1.0788888888888888,
              "msFromStart": 73239.4158518519
            },
            {
              "lat": 43.77604967777778,
              "lng": -116.1065163,
              "alt": 1899.205777777778,
              "speed2d": 1.0535555555555556,
              "speed3d": 1.0855555555555556,
              "msFromStart": 73733.11377777778
            },
            {
              "lat": 43.77604734444445,
              "lng": -116.10650747777777,
              "alt": 1897.997111111111,
              "speed2d": 1.283222222222222,
              "speed3d": 1.3655555555555559,
              "msFromStart": 74227.17955555554
            },
            {
              "lat": 43.77604562,
              "lng": -116.10650023000002,
              "alt": 1895.8858,
              "speed2d": 1.1542000000000001,
              "speed3d": 1.331,
              "msFromStart": 74751.23066666666
            },
            {
              "lat": 43.77604415555555,
              "lng": -116.10649267777778,
              "alt": 1893.8976666666667,
              "speed2d": 1.048111111111111,
              "speed3d": 1.371111111111111,
              "msFromStart": 75275.11125925925
            },
            {
              "lat": 43.77604353333333,
              "lng": -116.106489,
              "alt": 1892.9995555555556,
              "speed2d": 0.8147777777777778,
              "speed3d": 1.2255555555555555,
              "msFromStart": 75770.28577777775
            },
            {
              "lat": 43.77604344444444,
              "lng": -116.10648535555555,
              "alt": 1891.8972222222221,
              "speed2d": 0.6854444444444444,
              "speed3d": 1.148888888888889,
              "msFromStart": 76265.11724756334
            },
            {
              "lat": 43.77604101111112,
              "lng": -116.10647782222222,
              "alt": 1890.598111111111,
              "speed2d": 1.0255555555555556,
              "speed3d": 1.3988888888888888,
              "msFromStart": 76759.1764210526
            },
            {
              "lat": 43.77603615555556,
              "lng": -116.10647237777778,
              "alt": 1890.6074444444446,
              "speed2d": 1.201888888888889,
              "speed3d": 1.558888888888889,
              "msFromStart": 77253.34707212474
            },
            {
              "lat": 43.7760315,
              "lng": -116.1064663111111,
              "alt": 1890.2045555555555,
              "speed2d": 1.3298888888888891,
              "speed3d": 1.6255555555555556,
              "msFromStart": 77748.12622222229
            },
            {
              "lat": 43.77602843333332,
              "lng": -116.10645784444445,
              "alt": 1888.7543333333338,
              "speed2d": 1.4342222222222223,
              "speed3d": 1.8144444444444445,
              "msFromStart": 78243.13362962966
            },
            {
              "lat": 43.77602915555555,
              "lng": -116.10645324444442,
              "alt": 1887.3813333333333,
              "speed2d": 0.9894444444444443,
              "speed3d": 1.5444444444444445,
              "msFromStart": 78738.81066666669
            },
            {
              "lat": 43.77602502222222,
              "lng": -116.10645144444445,
              "alt": 1886.8818888888889,
              "speed2d": 1.0436666666666667,
              "speed3d": 1.531111111111111,
              "msFromStart": 79233.98207407407
            },
            {
              "lat": 43.776017522222226,
              "lng": -116.10644869999999,
              "alt": 1887.5681111111112,
              "speed2d": 1.274888888888889,
              "speed3d": 1.6688888888888886,
              "msFromStart": 79726.86311111104
            },
            {
              "lat": 43.776011180000005,
              "lng": -116.10644626,
              "alt": 1888.5509,
              "speed2d": 1.2245,
              "speed3d": 1.5759999999999996,
              "msFromStart": 80247.75359999997
            },
            {
              "lat": 43.77600487777778,
              "lng": -116.10644424444445,
              "alt": 1889.3912222222223,
              "speed2d": 1.1797777777777778,
              "speed3d": 1.4533333333333331,
              "msFromStart": 80771.45777777773
            },
            {
              "lat": 43.77599907777777,
              "lng": -116.10643863333334,
              "alt": 1889.904,
              "speed2d": 1.193111111111111,
              "speed3d": 1.461111111111111,
              "msFromStart": 81267.43675633529
            },
            {
              "lat": 43.775993222222226,
              "lng": -116.1064361777778,
              "alt": 1890.5414444444446,
              "speed2d": 1.1137777777777778,
              "speed3d": 1.356666666666667,
              "msFromStart": 81762.33810526323
            },
            {
              "lat": 43.775988866666665,
              "lng": -116.10643496666668,
              "alt": 1891.4358888888892,
              "speed2d": 0.8791111111111112,
              "speed3d": 1.108888888888889,
              "msFromStart": 82256.96632358676
            },
            {
              "lat": 43.77598363333333,
              "lng": -116.10643241111111,
              "alt": 1892.427222222222,
              "speed2d": 0.920111111111111,
              "speed3d": 1.0444444444444445,
              "msFromStart": 82750.72888888883
            },
            {
              "lat": 43.77597665555555,
              "lng": -116.1064273888889,
              "alt": 1893.2238888888887,
              "speed2d": 1.176222222222222,
              "speed3d": 1.2433333333333334,
              "msFromStart": 83244.80785185183
            },
            {
              "lat": 43.77596856666666,
              "lng": -116.10642233333334,
              "alt": 1894.0065555555557,
              "speed2d": 1.4206666666666667,
              "speed3d": 1.447777777777778,
              "msFromStart": 83740.49866666671
            },
            {
              "lat": 43.775960588888886,
              "lng": -116.10641926666668,
              "alt": 1894.9742222222221,
              "speed2d": 1.4587777777777782,
              "speed3d": 1.5266666666666666,
              "msFromStart": 84236.18251851854
            },
            {
              "lat": 43.77595607777778,
              "lng": -116.10641618888889,
              "alt": 1896.2808888888885,
              "speed2d": 1.114,
              "speed3d": 1.1555555555555554,
              "msFromStart": 84731.19377777782
            },
            {
              "lat": 43.77595126666667,
              "lng": -116.1064116888889,
              "alt": 1897.5136666666663,
              "speed2d": 1.0358888888888889,
              "speed3d": 1.0122222222222221,
              "msFromStart": 85226.35333333333
            },
            {
              "lat": 43.77594539,
              "lng": -116.10640662,
              "alt": 1898.1395000000002,
              "speed2d": 1.1022999999999998,
              "speed3d": 1.121,
              "msFromStart": 85749.93666666668
            },
            {
              "lat": 43.77593983333334,
              "lng": -116.10640244444446,
              "alt": 1899.0873333333332,
              "speed2d": 1.1112222222222223,
              "speed3d": 1.086666666666667,
              "msFromStart": 86273.03585185186
            },
            {
              "lat": 43.77593160000001,
              "lng": -116.10639765555557,
              "alt": 1900.052222222222,
              "speed2d": 1.4031111111111112,
              "speed3d": 1.38,
              "msFromStart": 86767.02533333338
            },
            {
              "lat": 43.77592378888888,
              "lng": -116.10639423333333,
              "alt": 1901.0100000000002,
              "speed2d": 1.424111111111111,
              "speed3d": 1.4433333333333334,
              "msFromStart": 87261.96409356728
            },
            {
              "lat": 43.77591692222221,
              "lng": -116.1063947111111,
              "alt": 1901.5505555555555,
              "speed2d": 1.3031111111111113,
              "speed3d": 1.327777777777778,
              "msFromStart": 87759.70063157895
            },
            {
              "lat": 43.77591081111111,
              "lng": -116.10639451111112,
              "alt": 1902.5992222222221,
              "speed2d": 1.1814444444444443,
              "speed3d": 1.2155555555555557,
              "msFromStart": 88256.79186744637
            },
            {
              "lat": 43.77590434444444,
              "lng": -116.10639377777777,
              "alt": 1903.3586666666665,
              "speed2d": 1.1805555555555554,
              "speed3d": 1.1966666666666668,
              "msFromStart": 88750.7128888888
            },
            {
              "lat": 43.77589495555556,
              "lng": -116.10638680000002,
              "alt": 1903.8765555555553,
              "speed2d": 1.5642222222222222,
              "speed3d": 1.54,
              "msFromStart": 89244.185037037
            },
            {
              "lat": 43.77588771111112,
              "lng": -116.1063804,
              "alt": 1904.2824444444445,
              "speed2d": 1.5751111111111111,
              "speed3d": 1.5944444444444446,
              "msFromStart": 89737.1297777778
            },
            {
              "lat": 43.77587922222222,
              "lng": -116.10637378888889,
              "alt": 1905.1967777777775,
              "speed2d": 1.7102222222222223,
              "speed3d": 1.7066666666666666,
              "msFromStart": 90230.54696296295
            },
            {
              "lat": 43.77587168888889,
              "lng": -116.10636862222223,
              "alt": 1906.3785555555555,
              "speed2d": 1.641,
              "speed3d": 1.6933333333333334,
              "msFromStart": 90726.06488888885
            },
            {
              "lat": 43.77586526,
              "lng": -116.10636477,
              "alt": 1907.579,
              "speed2d": 1.4401000000000002,
              "speed3d": 1.4759999999999998,
              "msFromStart": 91249.01226666666
            },
            {
              "lat": 43.77585622222222,
              "lng": -116.10636164444445,
              "alt": 1908.7087777777779,
              "speed2d": 1.5596666666666668,
              "speed3d": 1.6044444444444443,
              "msFromStart": 91770.91999999998
            },
            {
              "lat": 43.775847877777785,
              "lng": -116.10635843333333,
              "alt": 1909.4224444444444,
              "speed2d": 1.6611111111111112,
              "speed3d": 1.697777777777778,
              "msFromStart": 92265.52088888889
            },
            {
              "lat": 43.775840233333334,
              "lng": -116.10635074444444,
              "alt": 1909.9741111111111,
              "speed2d": 1.7167777777777777,
              "speed3d": 1.758888888888889,
              "msFromStart": 92761.11733333334
            },
            {
              "lat": 43.77583187777778,
              "lng": -116.1063455111111,
              "alt": 1910.3342222222223,
              "speed2d": 1.7054444444444445,
              "speed3d": 1.7300000000000002,
              "msFromStart": 93256.58148148148
            },
            {
              "lat": 43.77582405555556,
              "lng": -116.10633756666667,
              "alt": 1911.0282222222222,
              "speed2d": 1.7506666666666666,
              "speed3d": 1.7655555555555555,
              "msFromStart": 93751.1182222222
            },
            {
              "lat": 43.775813955555556,
              "lng": -116.1063295,
              "alt": 1911.9787777777776,
              "speed2d": 2.0175555555555555,
              "speed3d": 2.043333333333333,
              "msFromStart": 94245.75259259257
            },
            {
              "lat": 43.77580418888889,
              "lng": -116.10632254444445,
              "alt": 1913.219111111111,
              "speed2d": 2.083555555555556,
              "speed3d": 2.121111111111111,
              "msFromStart": 94741.14844444445
            },
            {
              "lat": 43.77579525555555,
              "lng": -116.10631655555555,
              "alt": 1914.3545555555556,
              "speed2d": 1.9908888888888887,
              "speed3d": 2.0644444444444443,
              "msFromStart": 95236.75007407405
            },
            {
              "lat": 43.77578574444444,
              "lng": -116.10631237777777,
              "alt": 1914.9124444444446,
              "speed2d": 2.049,
              "speed3d": 2.085555555555555,
              "msFromStart": 95732.87022222215
            },
            {
              "lat": 43.77577406666666,
              "lng": -116.10630506666665,
              "alt": 1915.812222222222,
              "speed2d": 2.3448888888888892,
              "speed3d": 2.3922222222222222,
              "msFromStart": 96228.54869395708
            },
            {
              "lat": 43.77576464,
              "lng": -116.10629644999999,
              "alt": 1916.9594,
              "speed2d": 2.1971000000000003,
              "speed3d": 2.2740000000000005,
              "msFromStart": 96749.67010526315
            },
            {
              "lat": 43.775756022222225,
              "lng": -116.10628884444445,
              "alt": 1918.1640000000002,
              "speed2d": 2.008,
              "speed3d": 2.138888888888889,
              "msFromStart": 97271.05059649123
            },
            {
              "lat": 43.77574700000001,
              "lng": -116.10628252222223,
              "alt": 1919.2848888888889,
              "speed2d": 1.9423333333333335,
              "speed3d": 2.055555555555556,
              "msFromStart": 97766.84000000005
            },
            {
              "lat": 43.77573677777778,
              "lng": -116.10627662222223,
              "alt": 1920.716888888889,
              "speed2d": 1.9902222222222221,
              "speed3d": 2.1455555555555557,
              "msFromStart": 98262.46681481485
            },
            {
              "lat": 43.77572564444444,
              "lng": -116.10627084444442,
              "alt": 1921.195666666667,
              "speed2d": 2.176222222222222,
              "speed3d": 2.272222222222222,
              "msFromStart": 98756.71288888893
            },
            {
              "lat": 43.775710455555554,
              "lng": -116.10626324444445,
              "alt": 1921.4114444444444,
              "speed2d": 2.5968888888888886,
              "speed3d": 2.6455555555555557,
              "msFromStart": 99251.13866666668
            },
            {
              "lat": 43.775693888888895,
              "lng": -116.10625403333333,
              "alt": 1921.3707777777781,
              "speed2d": 2.901111111111111,
              "speed3d": 2.9422222222222225,
              "msFromStart": 99746.83555555553
            },
            {
              "lat": 43.77567847777778,
              "lng": -116.1062478222222,
              "alt": 1920.8184444444444,
              "speed2d": 2.8492222222222225,
              "speed3d": 2.9277777777777776,
              "msFromStart": 100242.47214814815
            },
            {
              "lat": 43.77566572222223,
              "lng": -116.10624262222223,
              "alt": 1920.398222222222,
              "speed2d": 2.536222222222222,
              "speed3d": 2.6033333333333335,
              "msFromStart": 100737.35911111113
            },
            {
              "lat": 43.77565514444444,
              "lng": -116.1062350111111,
              "alt": 1920.4377777777777,
              "speed2d": 2.2581111111111114,
              "speed3d": 2.3066666666666666,
              "msFromStart": 101232.21996101363
            },
            {
              "lat": 43.77564592222222,
              "lng": -116.10622808888888,
              "alt": 1921.1894444444445,
              "speed2d": 2.260222222222222,
              "speed3d": 2.286666666666666,
              "msFromStart": 101727.27621052625
            },
            {
              "lat": 43.77563659999999,
              "lng": -116.10622099999999,
              "alt": 1922.348,
              "speed2d": 2.1894,
              "speed3d": 2.2499999999999996,
              "msFromStart": 102249.89424561399
            },
            {
              "lat": 43.77562751111111,
              "lng": -116.10621584444443,
              "alt": 1923.3119999999997,
              "speed2d": 2.1466666666666665,
              "speed3d": 2.2055555555555557,
              "msFromStart": 102772.68977777775
            },
            {
              "lat": 43.77561735555555,
              "lng": -116.10621122222223,
              "alt": 1923.6616666666669,
              "speed2d": 2.1393333333333335,
              "speed3d": 2.2,
              "msFromStart": 103268.12266666666
            },
            {
              "lat": 43.77560524444445,
              "lng": -116.10620547777778,
              "alt": 1923.3102222222224,
              "speed2d": 2.1767777777777777,
              "speed3d": 2.2055555555555553,
              "msFromStart": 103764.07111111112
            },
            {
              "lat": 43.775591955555555,
              "lng": -116.10619732222222,
              "alt": 1922.6918888888888,
              "speed2d": 2.403,
              "speed3d": 2.391111111111111,
              "msFromStart": 104259.76148148149
            },
            {
              "lat": 43.7755785,
              "lng": -116.1061901111111,
              "alt": 1922.772777777778,
              "speed2d": 2.5207777777777776,
              "speed3d": 2.5511111111111116,
              "msFromStart": 104754.21333333335
            },
            {
              "lat": 43.775569000000004,
              "lng": -116.1061859888889,
              "alt": 1923.145111111111,
              "speed2d": 2.330888888888889,
              "speed3d": 2.335555555555555,
              "msFromStart": 105248.70874074075
            },
            {
              "lat": 43.77555768888889,
              "lng": -116.10617667777777,
              "alt": 1923.308888888889,
              "speed2d": 2.6137777777777775,
              "speed3d": 2.6044444444444452,
              "msFromStart": 105743.87377777774
            },
            {
              "lat": 43.7755491,
              "lng": -116.10617301111111,
              "alt": 1924.0875555555558,
              "speed2d": 2.339444444444444,
              "speed3d": 2.381111111111111,
              "msFromStart": 106239.18351656919
            },
            {
              "lat": 43.77554047777778,
              "lng": -116.1061684777778,
              "alt": 1924.4052222222224,
              "speed2d": 2.207444444444444,
              "speed3d": 2.2477777777777774,
              "msFromStart": 106734.80421052639
            },
            {
              "lat": 43.77553327777778,
              "lng": -116.10616251111112,
              "alt": 1925.0193333333334,
              "speed2d": 2.0764444444444443,
              "speed3d": 2.072222222222222,
              "msFromStart": 107230.36565562061
            },
            {
              "lat": 43.77552548888889,
              "lng": -116.10615661111112,
              "alt": 1925.1444444444446,
              "speed2d": 2.106666666666667,
              "speed3d": 2.1355555555555554,
              "msFromStart": 107725.37600000005
            },
            {
              "lat": 43.77551858,
              "lng": -116.10614934,
              "alt": 1924.8851000000002,
              "speed2d": 2.0914,
              "speed3d": 2.071,
              "msFromStart": 108247.65222222226
            },
            {
              "lat": 43.77551088888888,
              "lng": -116.10614312222222,
              "alt": 1924.8526666666667,
              "speed2d": 2.0684444444444443,
              "speed3d": 2.1033333333333335,
              "msFromStart": 108769.27822222224
            },
            {
              "lat": 43.77550421111111,
              "lng": -116.10614108888889,
              "alt": 1924.701888888889,
              "speed2d": 1.9403333333333335,
              "speed3d": 1.9333333333333331,
              "msFromStart": 109263.58962962967
            },
            {
              "lat": 43.77549634444444,
              "lng": -116.10613512222221,
              "alt": 1924.1965555555553,
              "speed2d": 2.0563333333333333,
              "speed3d": 2.0388888888888888,
              "msFromStart": 109758.81066666673
            },
            {
              "lat": 43.77548872222222,
              "lng": -116.10612801111112,
              "alt": 1924.6005555555557,
              "speed2d": 2.0977777777777775,
              "speed3d": 2.121111111111111,
              "msFromStart": 110254.14859259265
            },
            {
              "lat": 43.775486022222225,
              "lng": -116.10612282222222,
              "alt": 1925.2805555555556,
              "speed2d": 1.5123333333333335,
              "speed3d": 1.5855555555555556,
              "msFromStart": 110749.59022222226
            },
            {
              "lat": 43.77548313333333,
              "lng": -116.10611748888888,
              "alt": 1926.4914444444448,
              "speed2d": 1.286,
              "speed3d": 1.3055555555555556,
              "msFromStart": 111244.81703703706
            },
            {
              "lat": 43.77547974444444,
              "lng": -116.10610892222223,
              "alt": 1927.7681111111108,
              "speed2d": 1.4397777777777778,
              "speed3d": 1.5155555555555553,
              "msFromStart": 111739.14311111112
            },
            {
              "lat": 43.77547791111112,
              "lng": -116.10609825555555,
              "alt": 1928.2585555555556,
              "speed2d": 1.6192222222222223,
              "speed3d": 1.6977777777777776,
              "msFromStart": 112233.70375048736
            },
            {
              "lat": 43.77547925555556,
              "lng": -116.10608507777776,
              "alt": 1928.8933333333334,
              "speed2d": 1.7902222222222222,
              "speed3d": 1.9155555555555557,
              "msFromStart": 112729.56294736845
            },
            {
              "lat": 43.77548253333334,
              "lng": -116.10607320000001,
              "alt": 1929.3176666666668,
              "speed2d": 1.7927777777777776,
              "speed3d": 1.9,
              "msFromStart": 113225.45656140354
            },
            {
              "lat": 43.775488440000004,
              "lng": -116.1060624,
              "alt": 1929.8662000000002,
              "speed2d": 1.7534999999999998,
              "speed3d": 1.9200000000000002,
              "msFromStart": 113748.28000000004
            },
            {
              "lat": 43.775492166666666,
              "lng": -116.10605517777778,
              "alt": 1930.468111111111,
              "speed2d": 1.423,
              "speed3d": 1.6355555555555557,
              "msFromStart": 114271.11096296298
            },
            {
              "lat": 43.77549565555556,
              "lng": -116.10604942222223,
              "alt": 1931.2231111111112,
              "speed2d": 1.2602222222222221,
              "speed3d": 1.5122222222222221,
              "msFromStart": 114766.84177777772
            },
            {
              "lat": 43.77549963333334,
              "lng": -116.10604193333336,
              "alt": 1932.1038888888888,
              "speed2d": 1.3937777777777778,
              "speed3d": 1.6444444444444446,
              "msFromStart": 115262.13303703701
            },
            {
              "lat": 43.77549668888888,
              "lng": -116.10603487777779,
              "alt": 1932.8289999999997,
              "speed2d": 1.1397777777777776,
              "speed3d": 1.4700000000000002,
              "msFromStart": 115755.50133333336
            },
            {
              "lat": 43.77549061111111,
              "lng": -116.10602582222222,
              "alt": 1933.1918888888888,
              "speed2d": 1.353,
              "speed3d": 1.6188888888888893,
              "msFromStart": 116249.05440935673
            },
            {
              "lat": 43.775485555555555,
              "lng": -116.10601913333332,
              "alt": 1933.1928888888888,
              "speed2d": 1.3781111111111113,
              "speed3d": 1.57,
              "msFromStart": 116744.11578947367
            },
            {
              "lat": 43.77547896666667,
              "lng": -116.10601175555554,
              "alt": 1933.377,
              "speed2d": 1.6240000000000003,
              "speed3d": 1.8066666666666666,
              "msFromStart": 117239.38723326835
            },
            {
              "lat": 43.77547863333333,
              "lng": -116.10600762222222,
              "alt": 1934.3596666666665,
              "speed2d": 1.0903333333333334,
              "speed3d": 1.42,
              "msFromStart": 117735.01999999996
            },
            {
              "lat": 43.77548164444445,
              "lng": -116.10600045555556,
              "alt": 1935.8828888888888,
              "speed2d": 0.9963333333333335,
              "speed3d": 1.4000000000000001,
              "msFromStart": 118230.63693827158
            },
            {
              "lat": 43.77548605555556,
              "lng": -116.1059909888889,
              "alt": 1936.8071111111112,
              "speed2d": 1.3388888888888888,
              "speed3d": 1.661111111111111,
              "msFromStart": 118725.796
            },
            {
              "lat": 43.77548833,
              "lng": -116.10598126,
              "alt": 1938.0244000000002,
              "speed2d": 1.4751,
              "speed3d": 1.832,
              "msFromStart": 119248.49400000002
            },
            {
              "lat": 43.77548891111111,
              "lng": -116.1059695888889,
              "alt": 1939.5861111111112,
              "speed2d": 1.7048888888888887,
              "speed3d": 2.103333333333333,
              "msFromStart": 119771.7022222222
            },
            {
              "lat": 43.7754903,
              "lng": -116.10596001111111,
              "alt": 1940.7016666666668,
              "speed2d": 1.5198888888888888,
              "speed3d": 2.022222222222222,
              "msFromStart": 120267.2362962963
            },
            {
              "lat": 43.77549265555556,
              "lng": -116.10595105555555,
              "alt": 1941.9502222222222,
              "speed2d": 1.356111111111111,
              "speed3d": 1.9499999999999997,
              "msFromStart": 120761.94666666673
            },
            {
              "lat": 43.77549476666667,
              "lng": -116.10594223333335,
              "alt": 1943.4854444444443,
              "speed2d": 1.2514444444444444,
              "speed3d": 1.9277777777777778,
              "msFromStart": 121256.75911111113
            },
            {
              "lat": 43.77549154444445,
              "lng": -116.10593614444444,
              "alt": 1943.7977777777778,
              "speed2d": 1.1014444444444444,
              "speed3d": 1.8055555555555556,
              "msFromStart": 121752.30933333334
            },
            {
              "lat": 43.77548198888889,
              "lng": -116.10593388888888,
              "alt": 1944.1169999999997,
              "speed2d": 1.1856666666666666,
              "speed3d": 1.77,
              "msFromStart": 122247.536374269
            },
            {
              "lat": 43.775469811111115,
              "lng": -116.10593155555557,
              "alt": 1944.5222222222224,
              "speed2d": 1.5408888888888888,
              "speed3d": 1.9911111111111108,
              "msFromStart": 122741.17557894732
            },
            {
              "lat": 43.7754592,
              "lng": -116.10592567777778,
              "alt": 1945.168222222222,
              "speed2d": 1.6796666666666666,
              "speed3d": 2.0644444444444447,
              "msFromStart": 123234.85051591937
            },
            {
              "lat": 43.77544746666668,
              "lng": -116.10591878888889,
              "alt": 1945.3654444444444,
              "speed2d": 1.872888888888889,
              "speed3d": 2.1688888888888886,
              "msFromStart": 123729.71199999996
            },
            {
              "lat": 43.77543374444444,
              "lng": -116.10591302222221,
              "alt": 1946.0483333333332,
              "speed2d": 2.2034444444444445,
              "speed3d": 2.4244444444444446,
              "msFromStart": 124224.90479012343
            },
            {
              "lat": 43.77542086,
              "lng": -116.10590592,
              "alt": 1946.8063000000002,
              "speed2d": 2.2504,
              "speed3d": 2.4909999999999997,
              "msFromStart": 124748.49666666664
            },
            {
              "lat": 43.77540838888889,
              "lng": -116.10590143333332,
              "alt": 1947.6408888888886,
              "speed2d": 2.2973333333333334,
              "speed3d": 2.5933333333333337,
              "msFromStart": 125271.94607407405
            },
            {
              "lat": 43.77539935555555,
              "lng": -116.10589677777779,
              "alt": 1947.592111111111,
              "speed2d": 2.1213333333333333,
              "speed3d": 2.404444444444444,
              "msFromStart": 125766.71377777774
            },
            {
              "lat": 43.775391822222225,
              "lng": -116.10589242222221,
              "alt": 1947.2206666666668,
              "speed2d": 1.8377777777777777,
              "speed3d": 2.1033333333333335,
              "msFromStart": 126261.3794074074
            },
            {
              "lat": 43.77538381111112,
              "lng": -116.10588694444444,
              "alt": 1947.0154444444443,
              "speed2d": 1.8012222222222218,
              "speed3d": 1.9933333333333332,
              "msFromStart": 126756.08355555558
            },
            {
              "lat": 43.77537451111112,
              "lng": -116.10587813333333,
              "alt": 1946.4869999999999,
              "speed2d": 2.0136666666666665,
              "speed3d": 2.177777777777778,
              "msFromStart": 127250.80984015594
            },
            {
              "lat": 43.775367511111114,
              "lng": -116.10587211111111,
              "alt": 1946.2207777777778,
              "speed2d": 1.8486666666666667,
              "speed3d": 1.9777777777777779,
              "msFromStart": 127745.60926315782
            },
            {
              "lat": 43.7753584,
              "lng": -116.1058677111111,
              "alt": 1945.9102222222223,
              "speed2d": 1.9104444444444444,
              "speed3d": 1.9833333333333336,
              "msFromStart": 128240.60726705648
            },
            {
              "lat": 43.77534826666667,
              "lng": -116.10586204444442,
              "alt": 1945.9745555555555,
              "speed2d": 2.049777777777778,
              "speed3d": 2.1555555555555554,
              "msFromStart": 128736.772
            },
            {
              "lat": 43.775338455555556,
              "lng": -116.10585821111111,
              "alt": 1945.4706666666664,
              "speed2d": 2.054222222222222,
              "speed3d": 2.101111111111111,
              "msFromStart": 129233.15170370371
            },
            {
              "lat": 43.77532693333334,
              "lng": -116.105854,
              "alt": 1945.3372222222224,
              "speed2d": 2.243444444444444,
              "speed3d": 2.2744444444444443,
              "msFromStart": 129729.67600000004
            },
            {
              "lat": 43.77531325555555,
              "lng": -116.10584583333335,
              "alt": 1945.5740000000003,
              "speed2d": 2.541777777777778,
              "speed3d": 2.582222222222222,
              "msFromStart": 130225.95980246917
            },
            {
              "lat": 43.77530241,
              "lng": -116.10583823,
              "alt": 1946.6475000000003,
              "speed2d": 2.3058,
              "speed3d": 2.3890000000000002,
              "msFromStart": 130748.09911111108
            },
            {
              "lat": 43.77529139999999,
              "lng": -116.10583134444444,
              "alt": 1947.4752222222223,
              "speed2d": 2.1834444444444445,
              "speed3d": 2.2844444444444445,
              "msFromStart": 131270.07543079922
            },
            {
              "lat": 43.7752816,
              "lng": -116.10582715555555,
              "alt": 1947.8852222222224,
              "speed2d": 2.0024444444444445,
              "speed3d": 2.1144444444444446,
              "msFromStart": 131764.98947368425
            },
            {
              "lat": 43.77527514444445,
              "lng": -116.10582245555555,
              "alt": 1947.6954444444445,
              "speed2d": 1.806,
              "speed3d": 1.9055555555555552,
              "msFromStart": 132259.8775152697
            },
            {
              "lat": 43.77527191111111,
              "lng": -116.10581851111112,
              "alt": 1947.068111111111,
              "speed2d": 1.4222222222222223,
              "speed3d": 1.531111111111111,
              "msFromStart": 132754.36000000004
            },
            {
              "lat": 43.775268266666664,
              "lng": -116.10581175555555,
              "alt": 1946.4628888888888,
              "speed2d": 1.4444444444444444,
              "speed3d": 1.482222222222222,
              "msFromStart": 133248.72809876545
            },
            {
              "lat": 43.775263977777776,
              "lng": -116.10580383333335,
              "alt": 1946.2193333333335,
              "speed2d": 1.607,
              "speed3d": 1.6144444444444446,
              "msFromStart": 133742.80399999995
            },
            {
              "lat": 43.77525693333333,
              "lng": -116.10579724444445,
              "alt": 1945.6492222222223,
              "speed2d": 1.7647777777777776,
              "speed3d": 1.7744444444444445,
              "msFromStart": 134236.97471604933
            },
            {
              "lat": 43.77525279999999,
              "lng": -116.10579172222224,
              "alt": 1945.514333333333,
              "speed2d": 1.6008888888888886,
              "speed3d": 1.6711111111111114,
              "msFromStart": 134732.01599999995
            },
            {
              "lat": 43.7752541,
              "lng": -116.10578643333335,
              "alt": 1945.5442222222223,
              "speed2d": 1.1984444444444444,
              "speed3d": 1.2455555555555555,
              "msFromStart": 135227.10320987648
            },
            {
              "lat": 43.77525445,
              "lng": -116.10577777,
              "alt": 1945.6250999999997,
              "speed2d": 1.2304,
              "speed3d": 1.2680000000000002,
              "msFromStart": 135749.06666666653
            },
            {
              "lat": 43.77525166666667,
              "lng": -116.10577084444445,
              "alt": 1945.3324444444443,
              "speed2d": 1.2067777777777775,
              "speed3d": 1.221111111111111,
              "msFromStart": 136271.35391812862
            },
            {
              "lat": 43.77524654444444,
              "lng": -116.10576386666668,
              "alt": 1945.221888888889,
              "speed2d": 1.4328888888888889,
              "speed3d": 1.441111111111111,
              "msFromStart": 136767.85684210534
            },
            {
              "lat": 43.77524336666667,
              "lng": -116.10575737777776,
              "alt": 1945.2266666666665,
              "speed2d": 1.4338888888888888,
              "speed3d": 1.4455555555555555,
              "msFromStart": 137264.23750120404
            },
            {
              "lat": 43.775237455555555,
              "lng": -116.10575148888887,
              "alt": 1945.355111111111,
              "speed2d": 1.5010000000000001,
              "speed3d": 1.5233333333333334,
              "msFromStart": 137758.84611764696
            },
            {
              "lat": 43.775229566666674,
              "lng": -116.10574805555555,
              "alt": 1945.663222222222,
              "speed2d": 1.6446666666666665,
              "speed3d": 1.6622222222222223,
              "msFromStart": 138253.41612383895
            },
            {
              "lat": 43.775222088888896,
              "lng": -116.10574545555556,
              "alt": 1945.732111111111,
              "speed2d": 1.6311111111111112,
              "speed3d": 1.6822222222222223,
              "msFromStart": 138748.99368421064
            },
            {
              "lat": 43.77521433333334,
              "lng": -116.10574365555556,
              "alt": 1945.148888888889,
              "speed2d": 1.6313333333333335,
              "speed3d": 1.6644444444444444,
              "msFromStart": 139244.55665237177
            },
            {
              "lat": 43.77520688888889,
              "lng": -116.10574004444445,
              "alt": 1944.9470000000001,
              "speed2d": 1.7058888888888888,
              "speed3d": 1.7177777777777778,
              "msFromStart": 139739.41600000003
            },
            {
              "lat": 43.77519713333333,
              "lng": -116.10573326666668,
              "alt": 1944.7910000000002,
              "speed2d": 2.0285555555555552,
              "speed3d": 2.003333333333333,
              "msFromStart": 140234.19061728395
            },
            {
              "lat": 43.7751867,
              "lng": -116.10572562222222,
              "alt": 1944.772,
              "speed2d": 2.2366666666666664,
              "speed3d": 2.2333333333333334,
              "msFromStart": 140729.1519999999
            },
            {
              "lat": 43.775175940000004,
              "lng": -116.10571817,
              "alt": 1943.9497999999999,
              "speed2d": 2.3434,
              "speed3d": 2.3680000000000003,
              "msFromStart": 141251.64888888883
            },
            {
              "lat": 43.775165300000005,
              "lng": -116.10571633333335,
              "alt": 1943.3047777777779,
              "speed2d": 2.184444444444445,
              "speed3d": 2.1944444444444446,
              "msFromStart": 141774.1706666667
            },
            {
              "lat": 43.775155644444446,
              "lng": -116.10571298888888,
              "alt": 1943.259,
              "speed2d": 2.187555555555556,
              "speed3d": 2.1955555555555555,
              "msFromStart": 142269.07443274854
            },
            {
              "lat": 43.77514637777777,
              "lng": -116.10570795555557,
              "alt": 1942.9929999999997,
              "speed2d": 2.2254444444444443,
              "speed3d": 2.2044444444444444,
              "msFromStart": 142763.4972631579
            },
            {
              "lat": 43.77513734444445,
              "lng": -116.10570124444445,
              "alt": 1942.9268888888892,
              "speed2d": 2.240555555555556,
              "speed3d": 2.27,
              "msFromStart": 143257.901785575
            },
            {
              "lat": 43.77512844444444,
              "lng": -116.10569617777777,
              "alt": 1942.8034444444447,
              "speed2d": 2.142,
              "speed3d": 2.1566666666666667,
              "msFromStart": 143752.4879999999
            },
            {
              "lat": 43.775118444444445,
              "lng": -116.10569096666666,
              "alt": 1942.861777777778,
              "speed2d": 2.095222222222222,
              "speed3d": 2.0955555555555554,
              "msFromStart": 144247.24128395057
            },
            {
              "lat": 43.77510811111111,
              "lng": -116.1056848888889,
              "alt": 1942.7421111111114,
              "speed2d": 2.195111111111111,
              "speed3d": 2.1999999999999997,
              "msFromStart": 144742.832
            },
            {
              "lat": 43.77509893333333,
              "lng": -116.10567895555556,
              "alt": 1942.5855555555556,
              "speed2d": 2.1189999999999998,
              "speed3d": 2.128888888888889,
              "msFromStart": 145238.52444444443
            },
            {
              "lat": 43.77508997777778,
              "lng": -116.10567253333332,
              "alt": 1942.4961111111113,
              "speed2d": 2.041777777777778,
              "speed3d": 2.048888888888889,
              "msFromStart": 145733.9999999999
            },
            {
              "lat": 43.77508025555556,
              "lng": -116.10566277777777,
              "alt": 1942.2945555555557,
              "speed2d": 2.256,
              "speed3d": 2.2488888888888887,
              "msFromStart": 146229.28691358017
            },
            {
              "lat": 43.77507152,
              "lng": -116.10565565000002,
              "alt": 1942.1749,
              "speed2d": 2.0079000000000002,
              "speed3d": 2.031,
              "msFromStart": 146751.09555555557
            },
            {
              "lat": 43.775061566666665,
              "lng": -116.10564950000001,
              "alt": 1942.129666666667,
              "speed2d": 2.021,
              "speed3d": 2.0277777777777777,
              "msFromStart": 147272.8227680312
            },
            {
              "lat": 43.77505157777778,
              "lng": -116.10564462222221,
              "alt": 1941.9055555555558,
              "speed2d": 2.05,
              "speed3d": 2.0466666666666664,
              "msFromStart": 147767.38105263165
            },
            {
              "lat": 43.77504142222222,
              "lng": -116.10563896666667,
              "alt": 1941.893888888889,
              "speed2d": 2.0892222222222223,
              "speed3d": 2.082222222222222,
              "msFromStart": 148262.0041793373
            },
            {
              "lat": 43.77503197777777,
              "lng": -116.10563064444446,
              "alt": 1942.0295555555556,
              "speed2d": 2.1438888888888887,
              "speed3d": 2.1588888888888893,
              "msFromStart": 148756.84799999997
            },
            {
              "lat": 43.77502213333332,
              "lng": -116.10562377777778,
              "alt": 1942.1365555555558,
              "speed2d": 2.174444444444444,
              "speed3d": 2.1733333333333333,
              "msFromStart": 149251.75960493824
            },
            {
              "lat": 43.77501284444444,
              "lng": -116.10561733333334,
              "alt": 1942.2416666666666,
              "speed2d": 2.1225555555555555,
              "speed3d": 2.1466666666666665,
              "msFromStart": 149746.86399999994
            },
            {
              "lat": 43.77500417777778,
              "lng": -116.1056091,
              "alt": 1942.2763333333332,
              "speed2d": 2.1452222222222224,
              "speed3d": 2.145555555555555,
              "msFromStart": 150242.0009876543
            },
            {
              "lat": 43.774996211111116,
              "lng": -116.10560286666669,
              "alt": 1942.5176666666666,
              "speed2d": 2.002222222222222,
              "speed3d": 2.0155555555555553,
              "msFromStart": 150737.144
            },
            {
              "lat": 43.774984733333326,
              "lng": -116.1055973777778,
              "alt": 1942.8473333333332,
              "speed2d": 2.2511111111111113,
              "speed3d": 2.2466666666666666,
              "msFromStart": 151232.26788304097
            },
            {
              "lat": 43.774973966666664,
              "lng": -116.10559372222222,
              "alt": 1943.0262222222225,
              "speed2d": 2.291111111111111,
              "speed3d": 2.312222222222222,
              "msFromStart": 151727.26905263166
            },
            {
              "lat": 43.774963819999996,
              "lng": -116.10558875000001,
              "alt": 1942.8264000000001,
              "speed2d": 2.1496999999999997,
              "speed3d": 2.178,
              "msFromStart": 152249.80538011703
            },
            {
              "lat": 43.77495422222222,
              "lng": -116.10558043333333,
              "alt": 1943.0444444444443,
              "speed2d": 2.090777777777778,
              "speed3d": 2.1311111111111107,
              "msFromStart": 152772.73599999992
            },
            {
              "lat": 43.77494708888889,
              "lng": -116.1055745,
              "alt": 1943.5025555555558,
              "speed2d": 1.8083333333333331,
              "speed3d": 1.847777777777778,
              "msFromStart": 153268.0798024691
            },
            {
              "lat": 43.77493955555555,
              "lng": -116.10556755555555,
              "alt": 1943.9212222222222,
              "speed2d": 1.7994444444444446,
              "speed3d": 1.8166666666666664,
              "msFromStart": 153762.54400000002
            },
            {
              "lat": 43.774930733333335,
              "lng": -116.10556015555557,
              "alt": 1944.0471111111112,
              "speed2d": 1.9292222222222222,
              "speed3d": 1.9544444444444444,
              "msFromStart": 154256.90251851856
            },
            {
              "lat": 43.774920888888886,
              "lng": -116.10555374444444,
              "alt": 1943.5896666666667,
              "speed2d": 2.017,
              "speed3d": 2.0100000000000002,
              "msFromStart": 154751.49600000013
            },
            {
              "lat": 43.7749103,
              "lng": -116.10554767777778,
              "alt": 1943.5384444444444,
              "speed2d": 2.1843333333333335,
              "speed3d": 2.2055555555555557,
              "msFromStart": 155246.28701234577
            },
            {
              "lat": 43.774902222222224,
              "lng": -116.10553955555557,
              "alt": 1943.4417777777778,
              "speed2d": 2.117,
              "speed3d": 2.1055555555555556,
              "msFromStart": 155742.048
            },
            {
              "lat": 43.77489402222223,
              "lng": -116.10553204444444,
              "alt": 1943.7293333333337,
              "speed2d": 2.149888888888889,
              "speed3d": 2.1688888888888886,
              "msFromStart": 156237.89392592592
            },
            {
              "lat": 43.77488616666667,
              "lng": -116.10552369999999,
              "alt": 1944.414,
              "speed2d": 2.1414444444444443,
              "speed3d": 2.134444444444444,
              "msFromStart": 156733.28799999985
            },
            {
              "lat": 43.77487582222223,
              "lng": -116.10551743333335,
              "alt": 1944.9841111111114,
              "speed2d": 2.2814444444444444,
              "speed3d": 2.31,
              "msFromStart": 157228.51282131247
            },
            {
              "lat": 43.774867840000006,
              "lng": -116.10551119000002,
              "alt": 1945.6466,
              "speed2d": 2.0745999999999998,
              "speed3d": 2.118,
              "msFromStart": 157750.63199999998
            },
            {
              "lat": 43.774860644444445,
              "lng": -116.10550354444446,
              "alt": 1946.2531111111111,
              "speed2d": 1.9035555555555554,
              "speed3d": 1.9755555555555557,
              "msFromStart": 158272.6771046134
            },
            {
              "lat": 43.77485252222222,
              "lng": -116.10549830000001,
              "alt": 1946.3705555555555,
              "speed2d": 1.975111111111111,
              "speed3d": 2.008888888888889,
              "msFromStart": 158767.376
            },
            {
              "lat": 43.77485003333333,
              "lng": -116.10549110000001,
              "alt": 1946.5148888888887,
              "speed2d": 1.6292222222222221,
              "speed3d": 1.7011111111111112,
              "msFromStart": 159262.1009382716
            },
            {
              "lat": 43.77484738888889,
              "lng": -116.10548692222221,
              "alt": 1946.6542222222224,
              "speed2d": 1.2157777777777776,
              "speed3d": 1.3144444444444443,
              "msFromStart": 159756.85599999994
            },
            {
              "lat": 43.774845033333335,
              "lng": -116.10548244444445,
              "alt": 1946.503888888889,
              "speed2d": 1.0156666666666665,
              "speed3d": 1.04,
              "msFromStart": 160251.73846913577
            },
            {
              "lat": 43.77483977777778,
              "lng": -116.10547714444445,
              "alt": 1946.6617777777776,
              "speed2d": 1.146,
              "speed3d": 1.1466666666666665,
              "msFromStart": 160747.36799999996
            },
            {
              "lat": 43.774830744444444,
              "lng": -116.10547206666666,
              "alt": 1946.9996666666668,
              "speed2d": 1.5364444444444445,
              "speed3d": 1.51,
              "msFromStart": 161243.08444444442
            },
            {
              "lat": 43.774821277777775,
              "lng": -116.10546454444444,
              "alt": 1947.2615555555556,
              "speed2d": 1.8586666666666667,
              "speed3d": 1.862222222222222,
              "msFromStart": 161738.58399999992
            },
            {
              "lat": 43.774813022222226,
              "lng": -116.10545772222223,
              "alt": 1947.324777777778,
              "speed2d": 1.9394444444444443,
              "speed3d": 1.9488888888888891,
              "msFromStart": 162233.9921195581
            },
            {
              "lat": 43.77480716666666,
              "lng": -116.10544910000002,
              "alt": 1947.8462222222224,
              "speed2d": 1.8236666666666668,
              "speed3d": 1.8666666666666665,
              "msFromStart": 162729.05936842112
            },
            {
              "lat": 43.77479957,
              "lng": -116.10544402000002,
              "alt": 1948.4434,
              "speed2d": 1.7429,
              "speed3d": 1.788,
              "msFromStart": 163251.39943859653
            },
            {
              "lat": 43.77479095555556,
              "lng": -116.10543854444444,
              "alt": 1948.702111111111,
              "speed2d": 1.7238888888888888,
              "speed3d": 1.778888888888889,
              "msFromStart": 163772.53599999993
            },
            {
              "lat": 43.77478153333334,
              "lng": -116.1054321888889,
              "alt": 1949.0159999999998,
              "speed2d": 1.8493333333333335,
              "speed3d": 1.8544444444444443,
              "msFromStart": 164266.20740740735
            },
            {
              "lat": 43.77477078888889,
              "lng": -116.10542487777778,
              "alt": 1949.1853333333333,
              "speed2d": 2.0837777777777777,
              "speed3d": 2.091111111111111,
              "msFromStart": 164761.14400000003
            },
            {
              "lat": 43.77475897777778,
              "lng": -116.10541962222221,
              "alt": 1949.252888888889,
              "speed2d": 2.3064444444444447,
              "speed3d": 2.305555555555556,
              "msFromStart": 165256.3907160494
            },
            {
              "lat": 43.774749,
              "lng": -116.10541306666667,
              "alt": 1949.5988888888892,
              "speed2d": 2.2487777777777778,
              "speed3d": 2.3122222222222226,
              "msFromStart": 165752.26400000008
            },
            {
              "lat": 43.77474105555555,
              "lng": -116.10540707777777,
              "alt": 1950.3401111111111,
              "speed2d": 2.113777777777778,
              "speed3d": 2.16,
              "msFromStart": 166248.17580246922
            },
            {
              "lat": 43.7747323,
              "lng": -116.10540224444445,
              "alt": 1951.0273333333334,
              "speed2d": 2.0118888888888886,
              "speed3d": 2.101111111111111,
              "msFromStart": 166743.69600000008
            },
            {
              "lat": 43.77472432222223,
              "lng": -116.10539770000001,
              "alt": 1951.469222222222,
              "speed2d": 1.8376666666666668,
              "speed3d": 1.927777777777778,
              "msFromStart": 167239.09617154003
            },
            {
              "lat": 43.774715344444445,
              "lng": -116.10539194444443,
              "alt": 1952.1477777777777,
              "speed2d": 1.879111111111111,
              "speed3d": 1.9911111111111113,
              "msFromStart": 167734.15578947365
            },
            {
              "lat": 43.77470882222222,
              "lng": -116.10538555555556,
              "alt": 1952.8305555555553,
              "speed2d": 1.7153333333333336,
              "speed3d": 1.8344444444444445,
              "msFromStart": 168229.19959454186
            },
            {
              "lat": 43.77470053,
              "lng": -116.10537903,
              "alt": 1953.3603000000003,
              "speed2d": 1.8125,
              "speed3d": 1.9240000000000002,
              "msFromStart": 168752.1737777778
            },
            {
              "lat": 43.77469355555556,
              "lng": -116.1053698,
              "alt": 1954.072222222222,
              "speed2d": 1.802,
              "speed3d": 1.942222222222222,
              "msFromStart": 169275.1294814815
            },
            {
              "lat": 43.77468667777778,
              "lng": -116.10536413333332,
              "alt": 1954.5757777777776,
              "speed2d": 1.6581111111111113,
              "speed3d": 1.8166666666666667,
              "msFromStart": 169769.8320000001
            },
            {
              "lat": 43.774678022222226,
              "lng": -116.10535933333334,
              "alt": 1955.2852222222223,
              "speed2d": 1.7307777777777777,
              "speed3d": 1.8733333333333333,
              "msFromStart": 170264.50883950625
            },
            {
              "lat": 43.77467064444444,
              "lng": -116.10535425555555,
              "alt": 1956.0007777777778,
              "speed2d": 1.6928888888888889,
              "speed3d": 1.8777777777777778,
              "msFromStart": 170759.75200000004
            },
            {
              "lat": 43.77466231111111,
              "lng": -116.10534798888888,
              "alt": 1956.7474444444445,
              "speed2d": 1.8282222222222224,
              "speed3d": 1.9922222222222223,
              "msFromStart": 171255.18182716056
            },
            {
              "lat": 43.77465396666666,
              "lng": -116.10534046666668,
              "alt": 1957.3597777777777,
              "speed2d": 1.9092222222222224,
              "speed3d": 2.0877777777777777,
              "msFromStart": 171751.1840000001
            },
            {
              "lat": 43.774645444444445,
              "lng": -116.10533544444444,
              "alt": 1957.9393333333333,
              "speed2d": 1.9007777777777777,
              "speed3d": 2.0855555555555556,
              "msFromStart": 172247.15170890198
            },
            {
              "lat": 43.77463644444445,
              "lng": -116.10533046666666,
              "alt": 1958.579111111111,
              "speed2d": 1.9193333333333333,
              "speed3d": 2.092222222222222,
              "msFromStart": 172742.33684210532
            },
            {
              "lat": 43.77462844444443,
              "lng": -116.10532378888888,
              "alt": 1959.607111111111,
              "speed2d": 1.9294444444444443,
              "speed3d": 2.146666666666667,
              "msFromStart": 173237.33376998053
            },
            {
              "lat": 43.77461972222221,
              "lng": -116.10531851111111,
              "alt": 1960.287222222222,
              "speed2d": 1.97,
              "speed3d": 2.1622222222222223,
              "msFromStart": 173731.73155555557
            },
            {
              "lat": 43.77461085555555,
              "lng": -116.10531276666666,
              "alt": 1961.2038888888892,
              "speed2d": 1.9843333333333333,
              "speed3d": 2.236666666666667,
              "msFromStart": 174225.9285925926
            },
            {
              "lat": 43.77460195999999,
              "lng": -116.10530786,
              "alt": 1961.8683,
              "speed2d": 1.8883,
              "speed3d": 2.1300000000000003,
              "msFromStart": 174747.036
            },
            {
              "lat": 43.77459323333334,
              "lng": -116.1053022888889,
              "alt": 1962.2925555555555,
              "speed2d": 1.8927777777777777,
              "speed3d": 2.101111111111111,
              "msFromStart": 175268.35674074074
            },
            {
              "lat": 43.774583855555555,
              "lng": -116.10529826666667,
              "alt": 1962.739111111111,
              "speed2d": 1.9964444444444445,
              "speed3d": 2.1744444444444446,
              "msFromStart": 175764.33599999998
            },
            {
              "lat": 43.7745749111111,
              "lng": -116.1052899888889,
              "alt": 1963.5203333333334,
              "speed2d": 2.143222222222222,
              "speed3d": 2.3266666666666667,
              "msFromStart": 176260.52760493825
            },
            {
              "lat": 43.77456635555556,
              "lng": -116.10528206666666,
              "alt": 1964.3984444444443,
              "speed2d": 2.0728888888888886,
              "speed3d": 2.3033333333333332,
              "msFromStart": 176755.936
            },
            {
              "lat": 43.77455710000001,
              "lng": -116.1052749888889,
              "alt": 1965.1534444444446,
              "speed2d": 2.118888888888889,
              "speed3d": 2.3166666666666664,
              "msFromStart": 177251.07055490575
            },
            {
              "lat": 43.77454768888889,
              "lng": -116.10526519999999,
              "alt": 1965.7882222222224,
              "speed2d": 2.2729999999999997,
              "speed3d": 2.4766666666666666,
              "msFromStart": 177745.31789473677
            },
            {
              "lat": 43.77453725555556,
              "lng": -116.1052600111111,
              "alt": 1966.4736666666665,
              "speed2d": 2.2526666666666664,
              "speed3d": 2.452222222222222,
              "msFromStart": 178239.4697231968
            },
            {
              "lat": 43.77452724444444,
              "lng": -116.1052520111111,
              "alt": 1967.102333333333,
              "speed2d": 2.258111111111111,
              "speed3d": 2.4766666666666666,
              "msFromStart": 178734.12088888898
            },
            {
              "lat": 43.77451915555555,
              "lng": -116.10524020000001,
              "alt": 1968.507888888889,
              "speed2d": 2.1285555555555553,
              "speed3d": 2.4233333333333333,
              "msFromStart": 179228.93777777787
            },
            {
              "lat": 43.77451302,
              "lng": -116.10523453,
              "alt": 1969.4164999999998,
              "speed2d": 1.6897000000000002,
              "speed3d": 2.076,
              "msFromStart": 179751.68177777776
            },
            {
              "lat": 43.77450767777778,
              "lng": -116.10523241111112,
              "alt": 1969.8535555555557,
              "speed2d": 1.2922222222222222,
              "speed3d": 1.721111111111111,
              "msFromStart": 180274.46083950612
            },
            {
              "lat": 43.774502411111115,
              "lng": -116.1052306888889,
              "alt": 1970.2923333333333,
              "speed2d": 0.9509999999999998,
              "speed3d": 1.4322222222222223,
              "msFromStart": 180769.31199999995
            },
            {
              "lat": 43.77449846666667,
              "lng": -116.10522591111109,
              "alt": 1970.7183333333335,
              "speed2d": 0.7682222222222223,
              "speed3d": 1.24,
              "msFromStart": 181264.15130864189
            },
            {
              "lat": 43.77449351111112,
              "lng": -116.1052206777778,
              "alt": 1971.1902222222222,
              "speed2d": 0.7994444444444444,
              "speed3d": 1.2133333333333334,
              "msFromStart": 181759.32799999992
            },
            {
              "lat": 43.77449058888889,
              "lng": -116.1052200111111,
              "alt": 1971.909111111111,
              "speed2d": 0.5522222222222222,
              "speed3d": 1.107777777777778,
              "msFromStart": 182254.56831708897
            },
            {
              "lat": 43.774487988888886,
              "lng": -116.1052217111111,
              "alt": 1972.2064444444445,
              "speed2d": 0.28722222222222227,
              "speed3d": 0.9666666666666667,
              "msFromStart": 182749.85936842114
            },
            {
              "lat": 43.77448708888889,
              "lng": -116.10522377777777,
              "alt": 1972.6446666666668,
              "speed2d": 0.1631111111111111,
              "speed3d": 0.8922222222222222,
              "msFromStart": 183245.03582066292
            },
            {
              "lat": 43.7744857,
              "lng": -116.10522548888889,
              "alt": 1972.750111111111,
              "speed2d": 0.2966666666666667,
              "speed3d": 0.8533333333333333,
              "msFromStart": 183738.9831111112
            },
            {
              "lat": 43.77448275555555,
              "lng": -116.10522551111113,
              "alt": 1973.0253333333333,
              "speed2d": 0.18966666666666665,
              "speed3d": 0.768888888888889,
              "msFromStart": 184232.73807407415
            },
            {
              "lat": 43.77448166666667,
              "lng": -116.10522273333333,
              "alt": 1973.5203333333334,
              "speed2d": 0.26855555555555555,
              "speed3d": 0.7744444444444443,
              "msFromStart": 184727.6426666667
            },
            {
              "lat": 43.774482049999996,
              "lng": -116.10522341999999,
              "alt": 1973.4801,
              "speed2d": 0.4401,
              "speed3d": 0.7699999999999999,
              "msFromStart": 185250.40133333337
            },
            {
              "lat": 43.77448102222223,
              "lng": -116.10522546666668,
              "alt": 1973.5247777777777,
              "speed2d": 0.39311111111111113,
              "speed3d": 0.6755555555555557,
              "msFromStart": 185773.55199999988
            },
            {
              "lat": 43.774479011111104,
              "lng": -116.10522673333332,
              "alt": 1973.8068888888888,
              "speed2d": 0.30977777777777776,
              "speed3d": 0.5822222222222221,
              "msFromStart": 186269.10083950608
            },
            {
              "lat": 43.77447725555555,
              "lng": -116.10522817777778,
              "alt": 1974.197,
              "speed2d": 0.285,
              "speed3d": 0.55,
              "msFromStart": 186763.75199999998
            },
            {
              "lat": 43.77447582222223,
              "lng": -116.10522947777778,
              "alt": 1974.4004444444445,
              "speed2d": 0.3065555555555556,
              "speed3d": 0.5133333333333334,
              "msFromStart": 187258.33173749186
            },
            {
              "lat": 43.774471955555555,
              "lng": -116.1052292,
              "alt": 1975.075,
              "speed2d": 0.27399999999999997,
              "speed3d": 0.5366666666666666,
              "msFromStart": 187753.3734736842
            },
            {
              "lat": 43.77446948888889,
              "lng": -116.10522968888888,
              "alt": 1976.0986666666668,
              "speed2d": 0.2897777777777778,
              "speed3d": 0.5755555555555556,
              "msFromStart": 188248.5189863548
            },
            {
              "lat": 43.77446707777777,
              "lng": -116.10523054444445,
              "alt": 1977.2636666666667,
              "speed2d": 0.29877777777777775,
              "speed3d": 0.6900000000000001,
              "msFromStart": 188743.94488888892
            },
            {
              "lat": 43.77446569999999,
              "lng": -116.10523185555556,
              "alt": 1977.745888888889,
              "speed2d": 0.14755555555555558,
              "speed3d": 0.6311111111111111,
              "msFromStart": 189239.43614814818
            },
            {
              "lat": 43.774464244444445,
              "lng": -116.10523453333333,
              "alt": 1978.0373333333332,
              "speed2d": 0.2628888888888889,
              "speed3d": 0.6311111111111111,
              "msFromStart": 189734.8800000001
            },
            {
              "lat": 43.77446157777778,
              "lng": -116.10523763333333,
              "alt": 1978.4494444444445,
              "speed2d": 0.37633333333333335,
              "speed3d": 0.6833333333333333,
              "msFromStart": 190230.27051851858
            },
            {
              "lat": 43.77446073333333,
              "lng": -116.10523618888888,
              "alt": 1979.6220000000003,
              "speed2d": 0.18788888888888888,
              "speed3d": 0.6755555555555557,
              "msFromStart": 190725.2462222221
            },
            {
              "lat": 43.77445994,
              "lng": -116.10523578,
              "alt": 1980.174,
              "speed2d": 0.1935,
              "speed3d": 0.6560000000000001,
              "msFromStart": 191247.59511111103
            },
            {
              "lat": 43.774459522222216,
              "lng": -116.10523782222222,
              "alt": 1980.5053333333333,
              "speed2d": 0.36133333333333334,
              "speed3d": 0.6866666666666666,
              "msFromStart": 191769.8320000001
            },
            {
              "lat": 43.774458366666664,
              "lng": -116.10523893333334,
              "alt": 1980.772111111111,
              "speed2d": 0.322,
              "speed3d": 0.6477777777777779,
              "msFromStart": 192264.63802469143
            },
            {
              "lat": 43.77445641111111,
              "lng": -116.10523564444445,
              "alt": 1981.1173333333331,
              "speed2d": 0.1491111111111111,
              "speed3d": 0.53,
              "msFromStart": 192759.92000000007
            },
            {
              "lat": 43.774454955555555,
              "lng": -116.10523275555556,
              "alt": 1981.3584444444446,
              "speed2d": 0.22177777777777777,
              "speed3d": 0.5155555555555557,
              "msFromStart": 193255.30666666673
            },
            {
              "lat": 43.774454133333336,
              "lng": -116.10523340000002,
              "alt": 1981.4682222222223,
              "speed2d": 0.10399999999999998,
              "speed3d": 0.4322222222222223,
              "msFromStart": 193750.9599999999
            },
            {
              "lat": 43.774454088888895,
              "lng": -116.10523380000001,
              "alt": 1981.625888888889,
              "speed2d": 0.17277777777777778,
              "speed3d": 0.4088888888888889,
              "msFromStart": 194246.6065185184
            },
            {
              "lat": 43.77445315555556,
              "lng": -116.10523326666667,
              "alt": 1982.155777777778,
              "speed2d": 0.12266666666666666,
              "speed3d": 0.3966666666666667,
              "msFromStart": 194741.51822222234
            },
            {
              "lat": 43.77445113333333,
              "lng": -116.10523342222223,
              "alt": 1982.3417777777777,
              "speed2d": 0.11488888888888889,
              "speed3d": 0.36888888888888893,
              "msFromStart": 195236.31022222235
            },
            {
              "lat": 43.77445133333334,
              "lng": -116.10523453333333,
              "alt": 1982.275,
              "speed2d": 0.29255555555555557,
              "speed3d": 0.38111111111111107,
              "msFromStart": 195731.74222222227
            },
            {
              "lat": 43.774450655555555,
              "lng": -116.10523796666668,
              "alt": 1982.181,
              "speed2d": 0.4565555555555555,
              "speed3d": 0.5077777777777778,
              "msFromStart": 196227.2755555556
            },
            {
              "lat": 43.77444913,
              "lng": -116.10523674000002,
              "alt": 1982.4956,
              "speed2d": 0.1736,
              "speed3d": 0.26899999999999996,
              "msFromStart": 196749.711111111
            },
            {
              "lat": 43.774449,
              "lng": -116.10523707777776,
              "alt": 1983.2284444444444,
              "speed2d": 0.33455555555555555,
              "speed3d": 0.4144444444444445,
              "msFromStart": 197272.07849252754
            },
            {
              "lat": 43.774447555555554,
              "lng": -116.10523614444443,
              "alt": 1983.7709999999997,
              "speed2d": 0.11822222222222223,
              "speed3d": 0.3355555555555556,
              "msFromStart": 197767.4357894737
            },
            {
              "lat": 43.77444574444444,
              "lng": -116.10523623333333,
              "alt": 1983.8452222222222,
              "speed2d": 0.16666666666666669,
              "speed3d": 0.31777777777777777,
              "msFromStart": 198262.87959454197
            },
            {
              "lat": 43.774441455555554,
              "lng": -116.10523302222222,
              "alt": 1983.7976666666666,
              "speed2d": 0.539,
              "speed3d": 0.5444444444444445,
              "msFromStart": 198758.4035555556
            },
            {
              "lat": 43.774436933333334,
              "lng": -116.10523101111112,
              "alt": 1983.854888888889,
              "speed2d": 0.7405555555555554,
              "speed3d": 0.7377777777777778,
              "msFromStart": 199253.90666666668
            },
            {
              "lat": 43.77443122222222,
              "lng": -116.10522763333333,
              "alt": 1984.1467777777777,
              "speed2d": 0.9721111111111111,
              "speed3d": 1.0033333333333332,
              "msFromStart": 199749.0008888888
            },
            {
              "lat": 43.77442855555556,
              "lng": -116.10522386666665,
              "alt": 1984.3673333333331,
              "speed2d": 0.8423333333333334,
              "speed3d": 0.8644444444444443,
              "msFromStart": 200243.9069629629
            },
            {
              "lat": 43.774426022222215,
              "lng": -116.10521803333333,
              "alt": 1984.9505555555556,
              "speed2d": 0.8530000000000002,
              "speed3d": 0.9044444444444446,
              "msFromStart": 200737.95377777788
            },
            {
              "lat": 43.77442304444444,
              "lng": -116.10521515555556,
              "alt": 1985.5247777777777,
              "speed2d": 0.6978888888888889,
              "speed3d": 0.7811111111111111,
              "msFromStart": 201231.74962962972
            },
            {
              "lat": 43.774419519999995,
              "lng": -116.10521018,
              "alt": 1986.0053,
              "speed2d": 0.7673,
              "speed3d": 0.8159999999999998,
              "msFromStart": 201752.59199999998
            },
            {
              "lat": 43.77441395555556,
              "lng": -116.10520650000001,
              "alt": 1986.0699999999997,
              "speed2d": 0.9344444444444443,
              "speed3d": 0.9844444444444445,
              "msFromStart": 202273.72201169585
            },
            {
              "lat": 43.77440981111111,
              "lng": -116.10520165555556,
              "alt": 1986.3221111111113,
              "speed2d": 1.026888888888889,
              "speed3d": 1.048888888888889,
              "msFromStart": 202769.74989473697
            },
            {
              "lat": 43.77440415555556,
              "lng": -116.1051963,
              "alt": 1986.5476666666668,
              "speed2d": 1.2177777777777776,
              "speed3d": 1.2377777777777776,
              "msFromStart": 203266.2021676415
            },
            {
              "lat": 43.7744001,
              "lng": -116.10519321111113,
              "alt": 1986.6782222222223,
              "speed2d": 1.0316666666666667,
              "speed3d": 1.1055555555555556,
              "msFromStart": 203763.1182222223
            },
            {
              "lat": 43.77439415555556,
              "lng": -116.1051911888889,
              "alt": 1986.986111111111,
              "speed2d": 1.1863333333333332,
              "speed3d": 1.1733333333333333,
              "msFromStart": 204259.949925926
            },
            {
              "lat": 43.77438726666667,
              "lng": -116.10518943333335,
              "alt": 1986.9692222222222,
              "speed2d": 1.3294444444444444,
              "speed3d": 1.3666666666666667,
              "msFromStart": 204754.77866666677
            },
            {
              "lat": 43.774381988888884,
              "lng": -116.10518789999999,
              "alt": 1987.265777777778,
              "speed2d": 1.2854444444444446,
              "speed3d": 1.3066666666666669,
              "msFromStart": 205249.2417777779
            },
            {
              "lat": 43.774377133333324,
              "lng": -116.10518574444444,
              "alt": 1987.599111111111,
              "speed2d": 1.1636666666666668,
              "speed3d": 1.2077777777777778,
              "msFromStart": 205745.05599999998
            },
            {
              "lat": 43.77437162222222,
              "lng": -116.10518336666667,
              "alt": 1987.8501111111111,
              "speed2d": 1.1677777777777778,
              "speed3d": 1.1766666666666665,
              "msFromStart": 206241.03911111108
            },
            {
              "lat": 43.774365844444446,
              "lng": -116.1051796,
              "alt": 1988.2436666666665,
              "speed2d": 1.2462222222222223,
              "speed3d": 1.2611111111111113,
              "msFromStart": 206735.33333333334
            },
            {
              "lat": 43.77435947777778,
              "lng": -116.10517296666666,
              "alt": 1988.9105555555552,
              "speed2d": 1.4620000000000002,
              "speed3d": 1.4855555555555555,
              "msFromStart": 207229.21787134506
            },
            {
              "lat": 43.77435363,
              "lng": -116.10516707,
              "alt": 1989.56,
              "speed2d": 1.4416,
              "speed3d": 1.4979999999999998,
              "msFromStart": 207750.67452631574
            },
            {
              "lat": 43.77434819999999,
              "lng": -116.10516024444445,
              "alt": 1990.2046666666668,
              "speed2d": 1.3594444444444447,
              "speed3d": 1.4188888888888889,
              "msFromStart": 208272.2868771929
            },
            {
              "lat": 43.77434256666666,
              "lng": -116.10515303333334,
              "alt": 1990.864666666667,
              "speed2d": 1.4584444444444442,
              "speed3d": 1.53,
              "msFromStart": 208767.6959999999
            },
            {
              "lat": 43.774337177777774,
              "lng": -116.10514821111111,
              "alt": 1991.5867777777776,
              "speed2d": 1.3696666666666666,
              "speed3d": 1.4877777777777779,
              "msFromStart": 209263.37214814808
            },
            {
              "lat": 43.77433143333333,
              "lng": -116.10514407777777,
              "alt": 1991.9327777777778,
              "speed2d": 1.2582222222222221,
              "speed3d": 1.3866666666666667,
              "msFromStart": 209758.6097777779
            },
            {
              "lat": 43.77432552222223,
              "lng": -116.1051424111111,
              "alt": 1991.898111111111,
              "speed2d": 1.1183333333333334,
              "speed3d": 1.231111111111111,
              "msFromStart": 210253.66607407422
            },
            {
              "lat": 43.77432016666667,
              "lng": -116.10514015555556,
              "alt": 1992.1189999999997,
              "speed2d": 0.9727777777777777,
              "speed3d": 1.0633333333333332,
              "msFromStart": 210748.00533333345
            },
            {
              "lat": 43.77431500000001,
              "lng": -116.10513614444443,
              "alt": 1992.481888888889,
              "speed2d": 1.0486666666666666,
              "speed3d": 1.1066666666666667,
              "msFromStart": 211241.6782222223
            },
            {
              "lat": 43.77430926666666,
              "lng": -116.10513263333333,
              "alt": 1992.7226666666663,
              "speed2d": 1.1285555555555555,
              "speed3d": 1.1933333333333334,
              "msFromStart": 211730.48000000007
            },
            {
              "lat": 43.77430269999999,
              "lng": -116.10512942,
              "alt": 1993.0564,
              "speed2d": 1.2546000000000002,
              "speed3d": 1.294,
              "msFromStart": 212246.53726315795
            },
            {
              "lat": 43.774296066666665,
              "lng": -116.10512515555556,
              "alt": 1993.5993333333333,
              "speed2d": 1.259111111111111,
              "speed3d": 1.341111111111111,
              "msFromStart": 212772.29136842105
            },
            {
              "lat": 43.77429012499999,
              "lng": -116.10512233749999,
              "alt": 1993.9228750000002,
              "speed2d": 1.2702499999999999,
              "speed3d": 1.31375,
              "msFromStart": 213245.65874922607
            },
            {
              "lat": 43.77428398888888,
              "lng": -116.10511855555555,
              "alt": 1994.4273333333333,
              "speed2d": 1.2913333333333332,
              "speed3d": 1.3733333333333333,
              "msFromStart": 213737.3035294117
            },
            {
              "lat": 43.77427796666666,
              "lng": -116.10511563333333,
              "alt": 1994.7277777777774,
              "speed2d": 1.0947777777777778,
              "speed3d": 1.1833333333333333,
              "msFromStart": 214261.41458823517
            },
            {
              "lat": 43.774272800000006,
              "lng": -116.10511073333335,
              "alt": 1995.3195555555556,
              "speed2d": 1.0544444444444445,
              "speed3d": 1.1533333333333335,
              "msFromStart": 214762.92799999993
            },
            {
              "lat": 43.774267977777775,
              "lng": -116.10510795555555,
              "alt": 1995.661333333333,
              "speed2d": 1.0064444444444445,
              "speed3d": 1.0933333333333333,
              "msFromStart": 215258.674074074
            },
            {
              "lat": 43.774262766666666,
              "lng": -116.1051036111111,
              "alt": 1996.1719999999998,
              "speed2d": 1.1233333333333333,
              "speed3d": 1.21,
              "msFromStart": 215753.24088888892
            },
            {
              "lat": 43.774257011111104,
              "lng": -116.10509893333334,
              "alt": 1996.5576666666666,
              "speed2d": 1.2558888888888888,
              "speed3d": 1.3411111111111111,
              "msFromStart": 216247.5395555556
            },
            {
              "lat": 43.77425139999999,
              "lng": -116.10509447777775,
              "alt": 1996.9845555555555,
              "speed2d": 1.2530000000000001,
              "speed3d": 1.3399999999999999,
              "msFromStart": 216742.10488888898
            },
            {
              "lat": 43.7742456,
              "lng": -116.10509052222223,
              "alt": 1997.2842222222223,
              "speed2d": 1.2697777777777777,
              "speed3d": 1.3555555555555556,
              "msFromStart": 217236.6555165693
            },
            {
              "lat": 43.77423964444444,
              "lng": -116.1050877888889,
              "alt": 1997.5115555555558,
              "speed2d": 1.196888888888889,
              "speed3d": 1.2922222222222222,
              "msFromStart": 217730.39242105256
            },
            {
              "lat": 43.77423404,
              "lng": -116.10508320000001,
              "alt": 1997.9382,
              "speed2d": 1.1795,
              "speed3d": 1.2559999999999998,
              "msFromStart": 218251.5131228069
            },
            {
              "lat": 43.77422804444444,
              "lng": -116.10507986666667,
              "alt": 1998.229111111111,
              "speed2d": 1.2105555555555556,
              "speed3d": 1.2766666666666668,
              "msFromStart": 218774.54577777782
            },
            {
              "lat": 43.77422249999999,
              "lng": -116.10507548888889,
              "alt": 1998.744333333333,
              "speed2d": 1.3079999999999998,
              "speed3d": 1.371111111111111,
              "msFromStart": 219270.50903703712
            },
            {
              "lat": 43.7742166,
              "lng": -116.10507201111112,
              "alt": 1999.1293333333333,
              "speed2d": 1.355333333333333,
              "speed3d": 1.4166666666666667,
              "msFromStart": 219765.464888889
            },
            {
              "lat": 43.7742095111111,
              "lng": -116.10506598888888,
              "alt": 1999.6042222222222,
              "speed2d": 1.75,
              "speed3d": 1.7755555555555553,
              "msFromStart": 220260.29155555565
            },
            {
              "lat": 43.77420218888889,
              "lng": -116.10505992222222,
              "alt": 2000.0864444444444,
              "speed2d": 1.8414444444444442,
              "speed3d": 1.8966666666666667,
              "msFromStart": 220756.34488888882
            },
            {
              "lat": 43.77419464444445,
              "lng": -116.10505303333333,
              "alt": 2000.7514444444441,
              "speed2d": 1.911777777777778,
              "speed3d": 1.9955555555555555,
              "msFromStart": 221252.56266666658
            },
            {
              "lat": 43.77418715555556,
              "lng": -116.10504555555556,
              "alt": 2001.206,
              "speed2d": 1.9734444444444443,
              "speed3d": 2.0555555555555554,
              "msFromStart": 221747.35822222225
            },
            {
              "lat": 43.77417957777778,
              "lng": -116.10504151111111,
              "alt": 2001.6572222222221,
              "speed2d": 1.8485555555555557,
              "speed3d": 1.9477777777777778,
              "msFromStart": 222241.88040545813
            },
            {
              "lat": 43.774171900000006,
              "lng": -116.10503516666667,
              "alt": 2002.2726666666667,
              "speed2d": 1.8942222222222225,
              "speed3d": 1.981111111111111,
              "msFromStart": 222737.2244210527
            },
            {
              "lat": 43.77416424444445,
              "lng": -116.10502934444445,
              "alt": 2002.9062222222226,
              "speed2d": 1.8963333333333334,
              "speed3d": 2.0055555555555555,
              "msFromStart": 223232.75206237828
            },
            {
              "lat": 43.77415637777778,
              "lng": -116.1050224,
              "alt": 2003.5520000000001,
              "speed2d": 1.9983333333333333,
              "speed3d": 2.091111111111111,
              "msFromStart": 223727.86488888896
            },
            {
              "lat": 43.77414713,
              "lng": -116.10501699000001,
              "alt": 2003.9433,
              "speed2d": 2.043,
              "speed3d": 2.1460000000000004,
              "msFromStart": 224250.3909333334
            },
            {
              "lat": 43.77413753333333,
              "lng": -116.10501074444443,
              "alt": 2004.3605555555555,
              "speed2d": 2.148111111111111,
              "speed3d": 2.221111111111111,
              "msFromStart": 224773.6906666666
            },
            {
              "lat": 43.77412812222222,
              "lng": -116.10500188888889,
              "alt": 2004.742,
              "speed2d": 2.388,
              "speed3d": 2.4666666666666663,
              "msFromStart": 225269.5881481481
            },
            {
              "lat": 43.774119477777774,
              "lng": -116.10499628888888,
              "alt": 2005.122111111111,
              "speed2d": 2.178888888888889,
              "speed3d": 2.3,
              "msFromStart": 225764.62044444447
            },
            {
              "lat": 43.774110488888894,
              "lng": -116.10499175555555,
              "alt": 2005.455888888889,
              "speed2d": 2.095222222222222,
              "speed3d": 2.1655555555555557,
              "msFromStart": 226259.41511111116
            },
            {
              "lat": 43.774100677777774,
              "lng": -116.10498475555555,
              "alt": 2006.0718888888887,
              "speed2d": 2.193111111111111,
              "speed3d": 2.268888888888889,
              "msFromStart": 226753.99644444443
            },
            {
              "lat": 43.77409115555555,
              "lng": -116.10497766666666,
              "alt": 2006.6656666666665,
              "speed2d": 2.140777777777778,
              "speed3d": 2.2455555555555557,
              "msFromStart": 227248.5686705653
            },
            {
              "lat": 43.77408207777777,
              "lng": -116.10497083333331,
              "alt": 2007.165222222222,
              "speed2d": 2.047333333333333,
              "speed3d": 2.136666666666667,
              "msFromStart": 227743.58315789484
            },
            {
              "lat": 43.77407288888889,
              "lng": -116.10496577777776,
              "alt": 2007.5923333333333,
              "speed2d": 1.968,
              "speed3d": 2.046666666666667,
              "msFromStart": 228238.70298635488
            },
            {
              "lat": 43.77406373333333,
              "lng": -116.10495933333335,
              "alt": 2008.2584444444444,
              "speed2d": 2.051,
              "speed3d": 2.128888888888889,
              "msFromStart": 228733.7235555556
            },
            {
              "lat": 43.77405365555555,
              "lng": -116.10495343333332,
              "alt": 2008.6875555555555,
              "speed2d": 2.252,
              "speed3d": 2.3055555555555554,
              "msFromStart": 229228.70755555565
            },
            {
              "lat": 43.77404284000001,
              "lng": -116.10494589,
              "alt": 2009.2598000000003,
              "speed2d": 2.3258,
              "speed3d": 2.3890000000000002,
              "msFromStart": 229751.19066666672
            },
            {
              "lat": 43.77403227777778,
              "lng": -116.10493793333335,
              "alt": 2009.9365555555555,
              "speed2d": 2.4285555555555556,
              "speed3d": 2.5033333333333334,
              "msFromStart": 230273.6364444445
            },
            {
              "lat": 43.77402253333334,
              "lng": -116.1049309888889,
              "alt": 2010.5566666666666,
              "speed2d": 2.334,
              "speed3d": 2.4544444444444444,
              "msFromStart": 230768.20977777775
            },
            {
              "lat": 43.774013522222226,
              "lng": -116.10492637777779,
              "alt": 2010.7395555555554,
              "speed2d": 2.096,
              "speed3d": 2.194444444444444,
              "msFromStart": 231262.7265185185
            },
            {
              "lat": 43.77400497777778,
              "lng": -116.10492386666667,
              "alt": 2010.7359999999999,
              "speed2d": 1.9398888888888892,
              "speed3d": 1.9955555555555553,
              "msFromStart": 231757.61066666667
            },
            {
              "lat": 43.773996422222226,
              "lng": -116.10491768888888,
              "alt": 2010.9858888888887,
              "speed2d": 1.952,
              "speed3d": 1.9844444444444447,
              "msFromStart": 232252.66058479532
            },
            {
              "lat": 43.77398737777778,
              "lng": -116.10491075555555,
              "alt": 2011.3004444444443,
              "speed2d": 2.106444444444444,
              "speed3d": 2.1044444444444443,
              "msFromStart": 232748.44968421056
            },
            {
              "lat": 43.77397716666667,
              "lng": -116.1049022,
              "alt": 2011.7612222222224,
              "speed2d": 2.376444444444444,
              "speed3d": 2.3966666666666665,
              "msFromStart": 233244.40054580904
            },
            {
              "lat": 43.77396703333333,
              "lng": -116.10489596666666,
              "alt": 2012.0063333333333,
              "speed2d": 2.372333333333333,
              "speed3d": 2.4211111111111108,
              "msFromStart": 233739.91377777775
            },
            {
              "lat": 43.77395756666667,
              "lng": -116.10489071111111,
              "alt": 2012.3923333333332,
              "speed2d": 2.2181111111111114,
              "speed3d": 2.261111111111111,
              "msFromStart": 234235.23199999996
            },
            {
              "lat": 43.77394873333333,
              "lng": -116.10488454444445,
              "alt": 2012.7853333333337,
              "speed2d": 2.0595555555555554,
              "speed3d": 2.11,
              "msFromStart": 234729.90844444447
            },
            {
              "lat": 43.77393948,
              "lng": -116.10487850999999,
              "alt": 2013.058,
              "speed2d": 2.0702,
              "speed3d": 2.105,
              "msFromStart": 235251.87546666677
            },
            {
              "lat": 43.77393078888888,
              "lng": -116.10487247777777,
              "alt": 2013.5025555555555,
              "speed2d": 2.1034444444444444,
              "speed3d": 2.12,
              "msFromStart": 235774.50133333335
            },
            {
              "lat": 43.77392258888888,
              "lng": -116.10486532222224,
              "alt": 2013.9805555555554,
              "speed2d": 2.1453333333333333,
              "speed3d": 2.1733333333333333,
              "msFromStart": 236269.78311111117
            },
            {
              "lat": 43.77391447777778,
              "lng": -116.10485873333334,
              "alt": 2014.3855555555554,
              "speed2d": 2.1342222222222222,
              "speed3d": 2.16,
              "msFromStart": 236764.76266666668
            },
            {
              "lat": 43.773906555555556,
              "lng": -116.10485075555555,
              "alt": 2014.753777777778,
              "speed2d": 2.1644444444444444,
              "speed3d": 2.181111111111111,
              "msFromStart": 237259.68472514616
            },
            {
              "lat": 43.77389845555557,
              "lng": -116.10484293333334,
              "alt": 2015.091,
              "speed2d": 2.207222222222222,
              "speed3d": 2.215555555555556,
              "msFromStart": 237754.78736842098
            },
            {
              "lat": 43.77389026666667,
              "lng": -116.10483485555557,
              "alt": 2015.735888888889,
              "speed2d": 2.212333333333333,
              "speed3d": 2.2466666666666666,
              "msFromStart": 238249.94835087712
            },
            {
              "lat": 43.77388241111111,
              "lng": -116.10482525555557,
              "alt": 2016.3536666666664,
              "speed2d": 2.291888888888889,
              "speed3d": 2.3044444444444445,
              "msFromStart": 238745.36000000007
            },
            {
              "lat": 43.7738743,
              "lng": -116.10481664444444,
              "alt": 2016.9737777777777,
              "speed2d": 2.2715555555555556,
              "speed3d": 2.312222222222222,
              "msFromStart": 239240.8343703705
            },
            {
              "lat": 43.773866500000004,
              "lng": -116.10480910000001,
              "alt": 2017.6518888888888,
              "speed2d": 2.155111111111111,
              "speed3d": 2.21,
              "msFromStart": 239735.74577777786
            },
            {
              "lat": 43.773858777777775,
              "lng": -116.10480178888889,
              "alt": 2018.0667777777776,
              "speed2d": 2.078,
              "speed3d": 2.1199999999999997,
              "msFromStart": 240230.45896296308
            },
            {
              "lat": 43.773850733333326,
              "lng": -116.10479497777777,
              "alt": 2018.5515555555555,
              "speed2d": 2.097888888888889,
              "speed3d": 2.1411111111111114,
              "msFromStart": 240725.34666666665
            },
            {
              "lat": 43.773842269999996,
              "lng": -116.10478776000001,
              "alt": 2018.9907999999998,
              "speed2d": 2.0957,
              "speed3d": 2.12,
              "msFromStart": 241247.7613333333
            },
            {
              "lat": 43.77383362222222,
              "lng": -116.10478027777778,
              "alt": 2019.462,
              "speed2d": 2.1317777777777778,
              "speed3d": 2.1644444444444444,
              "msFromStart": 241769.7831111111
            },
            {
              "lat": 43.77382533333334,
              "lng": -116.1047727888889,
              "alt": 2019.9967777777779,
              "speed2d": 2.208333333333333,
              "speed3d": 2.23,
              "msFromStart": 242264.2490448343
            },
            {
              "lat": 43.77381675555555,
              "lng": -116.10476455555555,
              "alt": 2020.6003333333335,
              "speed2d": 2.304777777777778,
              "speed3d": 2.336666666666667,
              "msFromStart": 242759.05431578937
            },
            {
              "lat": 43.77380876666667,
              "lng": -116.1047561111111,
              "alt": 2021.3237777777777,
              "speed2d": 2.2484444444444445,
              "speed3d": 2.3,
              "msFromStart": 243253.95145419092
            },
            {
              "lat": 43.773801077777776,
              "lng": -116.10474781111111,
              "alt": 2021.9536666666668,
              "speed2d": 2.2186666666666666,
              "speed3d": 2.271111111111111,
              "msFromStart": 243748.98222222232
            },
            {
              "lat": 43.77379313333333,
              "lng": -116.1047401888889,
              "alt": 2022.2982222222222,
              "speed2d": 2.2125555555555554,
              "speed3d": 2.2733333333333334,
              "msFromStart": 244244.10785185196
            },
            {
              "lat": 43.77378503333333,
              "lng": -116.10473388888889,
              "alt": 2022.6227777777779,
              "speed2d": 2.175777777777778,
              "speed3d": 2.2155555555555555,
              "msFromStart": 244740.10044444437
            },
            {
              "lat": 43.773776555555564,
              "lng": -116.10472712222222,
              "alt": 2023.0366666666666,
              "speed2d": 2.235111111111111,
              "speed3d": 2.267777777777778,
              "msFromStart": 245236.35703703697
            },
            {
              "lat": 43.77376831111111,
              "lng": -116.10472006666666,
              "alt": 2023.3965555555556,
              "speed2d": 2.2287777777777777,
              "speed3d": 2.2655555555555553,
              "msFromStart": 245731.56088888895
            },
            {
              "lat": 43.77375914444444,
              "lng": -116.10471365555554,
              "alt": 2023.4511111111112,
              "speed2d": 2.346888888888889,
              "speed3d": 2.355555555555555,
              "msFromStart": 246226.38666666672
            },
            {
              "lat": 43.773748319999996,
              "lng": -116.10470906,
              "alt": 2023.1526000000003,
              "speed2d": 2.4099999999999997,
              "speed3d": 2.4050000000000002,
              "msFromStart": 246748.90266666678
            },
            {
              "lat": 43.77373778888889,
              "lng": -116.1047045222222,
              "alt": 2022.801888888889,
              "speed2d": 2.367,
              "speed3d": 2.3777777777777773,
              "msFromStart": 247271.4595711502
            },
            {
              "lat": 43.77372745555556,
              "lng": -116.10470097777778,
              "alt": 2022.229888888889,
              "speed2d": 2.3267777777777776,
              "speed3d": 2.3466666666666667,
              "msFromStart": 247766.236631579
            },
            {
              "lat": 43.77371718888888,
              "lng": -116.10469862222223,
              "alt": 2021.665111111111,
              "speed2d": 2.253222222222222,
              "speed3d": 2.2655555555555553,
              "msFromStart": 248260.9920935673
            },
            {
              "lat": 43.77370723333334,
              "lng": -116.10469426666666,
              "alt": 2021.2794444444444,
              "speed2d": 2.2065555555555556,
              "speed3d": 2.236666666666667,
              "msFromStart": 248756.64533333332
            },
            {
              "lat": 43.77369742222222,
              "lng": -116.10468954444445,
              "alt": 2021.0442222222218,
              "speed2d": 2.1725555555555554,
              "speed3d": 2.218888888888889,
              "msFromStart": 249252.58044444438
            },
            {
              "lat": 43.77368786666668,
              "lng": -116.10468383333334,
              "alt": 2020.8667777777775,
              "speed2d": 2.146777777777778,
              "speed3d": 2.1933333333333334,
              "msFromStart": 249747.5866666666
            },
            {
              "lat": 43.77367825555556,
              "lng": -116.10467896666667,
              "alt": 2020.6083333333336,
              "speed2d": 2.1696666666666666,
              "speed3d": 2.22,
              "msFromStart": 250242.2497777777
            },
            {
              "lat": 43.77366882222222,
              "lng": -116.10467363333335,
              "alt": 2020.4256666666668,
              "speed2d": 2.193666666666667,
              "speed3d": 2.223333333333333,
              "msFromStart": 250736.89599999995
            },
            {
              "lat": 43.77365847777778,
              "lng": -116.10466854444446,
              "alt": 2019.9808888888888,
              "speed2d": 2.356666666666667,
              "speed3d": 2.398888888888889,
              "msFromStart": 251231.5371851851
            },
            {
              "lat": 43.773648755555556,
              "lng": -116.10466226666667,
              "alt": 2019.7969999999998,
              "speed2d": 2.349333333333333,
              "speed3d": 2.4177777777777774,
              "msFromStart": 251726.20088888888
            },
            {
              "lat": 43.7736379,
              "lng": -116.10465650999998,
              "alt": 2019.7951000000003,
              "speed2d": 2.3790999999999998,
              "speed3d": 2.425,
              "msFromStart": 252248.3879017544
            },
            {
              "lat": 43.7736276,
              "lng": -116.1046508,
              "alt": 2019.5083333333332,
              "speed2d": 2.257888888888889,
              "speed3d": 2.32,
              "msFromStart": 252770.9515789474
            },
            {
              "lat": 43.773618588888894,
              "lng": -116.10464412222223,
              "alt": 2019.3038888888889,
              "speed2d": 2.206111111111111,
              "speed3d": 2.2633333333333336,
              "msFromStart": 253266.08201169598
            },
            {
              "lat": 43.77360903333334,
              "lng": -116.10463904444445,
              "alt": 2019.1248888888888,
              "speed2d": 2.243333333333333,
              "speed3d": 2.2877777777777775,
              "msFromStart": 253760.45066666673
            },
            {
              "lat": 43.77359898888889,
              "lng": -116.1046338888889,
              "alt": 2018.9430000000002,
              "speed2d": 2.296111111111111,
              "speed3d": 2.34,
              "msFromStart": 254254.59437037044
            },
            {
              "lat": 43.77358854444444,
              "lng": -116.10462873333333,
              "alt": 2018.7492222222222,
              "speed2d": 2.324555555555556,
              "speed3d": 2.38,
              "msFromStart": 254749.7964444445
            },
            {
              "lat": 43.77357842222223,
              "lng": -116.10462296666667,
              "alt": 2018.6307777777774,
              "speed2d": 2.2794444444444437,
              "speed3d": 2.322222222222222,
              "msFromStart": 255245.43762962968
            },
            {
              "lat": 43.7735685,
              "lng": -116.10461618888888,
              "alt": 2018.5223333333338,
              "speed2d": 2.2712222222222223,
              "speed3d": 2.3144444444444443,
              "msFromStart": 255742.01333333325
            },
            {
              "lat": 43.7735581,
              "lng": -116.1046092888889,
              "alt": 2018.362777777778,
              "speed2d": 2.3842222222222222,
              "speed3d": 2.424444444444444,
              "msFromStart": 256238.8293333332
            },
            {
              "lat": 43.77354852222223,
              "lng": -116.10460301111112,
              "alt": 2018.2304444444444,
              "speed2d": 2.2764444444444445,
              "speed3d": 2.3222222222222224,
              "msFromStart": 256733.66933333338
            },
            {
              "lat": 43.77353877777777,
              "lng": -116.10459673333334,
              "alt": 2018.0794444444446,
              "speed2d": 2.2772222222222225,
              "speed3d": 2.305555555555556,
              "msFromStart": 257227.81857309947
            },
            {
              "lat": 43.773528400000004,
              "lng": -116.10458971999999,
              "alt": 2017.9901,
              "speed2d": 2.3076000000000003,
              "speed3d": 2.344,
              "msFromStart": 257750.18189473683
            },
            {
              "lat": 43.77351825555556,
              "lng": -116.10458221111111,
              "alt": 2017.8658888888888,
              "speed2d": 2.2385555555555556,
              "speed3d": 2.284444444444445,
              "msFromStart": 258272.75680311886
            },
            {
              "lat": 43.77350813333333,
              "lng": -116.10457767777777,
              "alt": 2017.5682222222222,
              "speed2d": 2.257777777777778,
              "speed3d": 2.2833333333333337,
              "msFromStart": 258766.89244444435
            },
            {
              "lat": 43.77349825555555,
              "lng": -116.10457204444444,
              "alt": 2017.4119999999998,
              "speed2d": 2.2435555555555555,
              "speed3d": 2.287777777777778,
              "msFromStart": 259260.75792592586
            },
            {
              "lat": 43.77348907777778,
              "lng": -116.10456552222222,
              "alt": 2017.3353333333334,
              "speed2d": 2.217,
              "speed3d": 2.24,
              "msFromStart": 259756.01955555557
            },
            {
              "lat": 43.77347983333333,
              "lng": -116.10455826666666,
              "alt": 2017.4015555555557,
              "speed2d": 2.237,
              "speed3d": 2.2600000000000002,
              "msFromStart": 260251.76385185184
            },
            {
              "lat": 43.77347108888888,
              "lng": -116.10454977777778,
              "alt": 2017.523333333333,
              "speed2d": 2.2602222222222217,
              "speed3d": 2.263333333333333,
              "msFromStart": 260746.90577777766
            },
            {
              "lat": 43.773462499999994,
              "lng": -116.10454124444443,
              "alt": 2017.7051111111111,
              "speed2d": 2.2144444444444447,
              "speed3d": 2.238888888888889,
              "msFromStart": 261241.81837037028
            },
            {
              "lat": 43.77345376666667,
              "lng": -116.1045332111111,
              "alt": 2017.7874444444444,
              "speed2d": 2.2096666666666667,
              "speed3d": 2.2033333333333336,
              "msFromStart": 261736.59022222224
            },
            {
              "lat": 43.77344442222223,
              "lng": -116.10452689999998,
              "alt": 2017.6275555555553,
              "speed2d": 2.2026666666666666,
              "speed3d": 2.2233333333333336,
              "msFromStart": 262231.30164522416
            },
            {
              "lat": 43.77343522222222,
              "lng": -116.10452006666665,
              "alt": 2017.6118888888889,
              "speed2d": 2.220222222222222,
              "speed3d": 2.2155555555555555,
              "msFromStart": 262725.850105263
            },
            {
              "lat": 43.773425030000006,
              "lng": -116.10451424999998,
              "alt": 2017.6288,
              "speed2d": 2.1942000000000004,
              "speed3d": 2.197,
              "msFromStart": 263247.8191578945
            },
            {
              "lat": 43.77341493333334,
              "lng": -116.10450523333333,
              "alt": 2017.688888888889,
              "speed2d": 2.2768888888888887,
              "speed3d": 2.2688888888888887,
              "msFromStart": 263769.9813333333
            },
            {
              "lat": 43.77340558888889,
              "lng": -116.10449704444446,
              "alt": 2017.7850000000003,
              "speed2d": 2.321222222222222,
              "speed3d": 2.3400000000000003,
              "msFromStart": 264264.7445925925
            },
            {
              "lat": 43.77339606666667,
              "lng": -116.10449027777777,
              "alt": 2017.611777777778,
              "speed2d": 2.352,
              "speed3d": 2.3311111111111105,
              "msFromStart": 264759.56977777765
            },
            {
              "lat": 43.773386188888885,
              "lng": -116.10448436666667,
              "alt": 2017.347888888889,
              "speed2d": 2.3386666666666667,
              "speed3d": 2.354444444444445,
              "msFromStart": 265254.44799999986
            },
            {
              "lat": 43.77337738888889,
              "lng": -116.10447641111111,
              "alt": 2017.4193333333333,
              "speed2d": 2.1535555555555557,
              "speed3d": 2.191111111111111,
              "msFromStart": 265749.90044444444
            },
            {
              "lat": 43.773369555555554,
              "lng": -116.10446904444446,
              "alt": 2017.7474444444442,
              "speed2d": 2.002,
              "speed3d": 1.988888888888889,
              "msFromStart": 266245.56088888895
            },
            {
              "lat": 43.773360677777774,
              "lng": -116.10446104444443,
              "alt": 2018.0105555555556,
              "speed2d": 2.1064444444444446,
              "speed3d": 2.098888888888889,
              "msFromStart": 266741.1537777778
            },
            {
              "lat": 43.773350799999996,
              "lng": -116.10445437777777,
              "alt": 2018.0812222222223,
              "speed2d": 2.2314444444444446,
              "speed3d": 2.2166666666666663,
              "msFromStart": 267236.65540740744
            },
            {
              "lat": 43.773341611111114,
              "lng": -116.1044476,
              "alt": 2018.303111111111,
              "speed2d": 2.127888888888889,
              "speed3d": 2.15,
              "msFromStart": 267730.896
            },
            {
              "lat": 43.77333175,
              "lng": -116.10443894,
              "alt": 2018.5729,
              "speed2d": 2.2289,
              "speed3d": 2.2170000000000005,
              "msFromStart": 268252.16293333337
            },
            {
              "lat": 43.773321466666665,
              "lng": -116.10442924444445,
              "alt": 2019.008888888889,
              "speed2d": 2.384333333333333,
              "speed3d": 2.3577777777777778,
              "msFromStart": 268774.68711111095
            },
            {
              "lat": 43.7733104,
              "lng": -116.10442057777777,
              "alt": 2018.894222222222,
              "speed2d": 2.592888888888889,
              "speed3d": 2.583333333333333,
              "msFromStart": 269270.2456296294
            },
            {
              "lat": 43.773299699999995,
              "lng": -116.10441345555556,
              "alt": 2018.6997777777779,
              "speed2d": 2.4943333333333335,
              "speed3d": 2.5022222222222217,
              "msFromStart": 269766.07999999996
            },
            {
              "lat": 43.773289211111106,
              "lng": -116.10440462222223,
              "alt": 2018.7562222222223,
              "speed2d": 2.4528888888888885,
              "speed3d": 2.4688888888888894,
              "msFromStart": 270261.98044444434
            },
            {
              "lat": 43.77327878888889,
              "lng": -116.10439684444444,
              "alt": 2018.6473333333336,
              "speed2d": 2.3179999999999996,
              "speed3d": 2.3377777777777777,
              "msFromStart": 270757.20533333346
            },
            {
              "lat": 43.77326871111111,
              "lng": -116.10439145555557,
              "alt": 2018.2616666666668,
              "speed2d": 2.187888888888889,
              "speed3d": 2.1944444444444446,
              "msFromStart": 271252.1724444447
            },
            {
              "lat": 43.77325795555555,
              "lng": -116.10438626666667,
              "alt": 2017.9733333333334,
              "speed2d": 2.2645555555555554,
              "speed3d": 2.2555555555555555,
              "msFromStart": 271746.9706666668
            },
            {
              "lat": 43.77324723333333,
              "lng": -116.10437953333334,
              "alt": 2017.7601111111112,
              "speed2d": 2.3067777777777776,
              "speed3d": 2.2944444444444443,
              "msFromStart": 272241.67588304117
            },
            {
              "lat": 43.773236311111106,
              "lng": -116.10437193333333,
              "alt": 2017.6603333333333,
              "speed2d": 2.3914444444444443,
              "speed3d": 2.3777777777777778,
              "msFromStart": 272735.7962105262
            },
            {
              "lat": 43.773225999999994,
              "lng": -116.10436372222222,
              "alt": 2017.445,
              "speed2d": 2.3303333333333334,
              "speed3d": 2.355555555555555,
              "msFromStart": 273229.73719038314
            },
            {
              "lat": 43.77321611111111,
              "lng": -116.10435729999999,
              "alt": 2017.1155555555558,
              "speed2d": 2.1862222222222223,
              "speed3d": 2.187777777777778,
              "msFromStart": 273725.5573333333
            },
            {
              "lat": 43.77320517,
              "lng": -116.10435085,
              "alt": 2017.0163999999997,
              "speed2d": 2.1954000000000002,
              "speed3d": 2.2060000000000004,
              "msFromStart": 274249.9351111111
            },
            {
              "lat": 43.77319467777778,
              "lng": -116.10434425555556,
              "alt": 2016.9176666666667,
              "speed2d": 2.1808888888888887,
              "speed3d": 2.1933333333333334,
              "msFromStart": 274773.1182222223
            },
            {
              "lat": 43.773186100000004,
              "lng": -116.10433673333334,
              "alt": 2016.9937777777777,
              "speed2d": 2.1212222222222223,
              "speed3d": 2.1255555555555556,
              "msFromStart": 275268.2560000001
            },
            {
              "lat": 43.77317753333333,
              "lng": -116.10433,
              "alt": 2017.3047777777776,
              "speed2d": 2.128111111111111,
              "speed3d": 2.1344444444444446,
              "msFromStart": 275763.1235555556
            },
            {
              "lat": 43.77316897777778,
              "lng": -116.10432376666665,
              "alt": 2017.6515555555557,
              "speed2d": 1.9985555555555556,
              "speed3d": 2.0033333333333334,
              "msFromStart": 276257.9425185186
            },
            {
              "lat": 43.77316041111111,
              "lng": -116.10431523333332,
              "alt": 2017.9187777777777,
              "speed2d": 2.0485555555555552,
              "speed3d": 2.042222222222222,
              "msFromStart": 276753.7297777779
            },
            {
              "lat": 43.77315155555556,
              "lng": -116.10430621111111,
              "alt": 2018.184777777778,
              "speed2d": 2.191555555555556,
              "speed3d": 2.1766666666666667,
              "msFromStart": 277249.8826666669
            },
            {
              "lat": 43.77314216666666,
              "lng": -116.10429819999999,
              "alt": 2018.457111111111,
              "speed2d": 2.260222222222222,
              "speed3d": 2.2544444444444443,
              "msFromStart": 277746.2044444447
            },
            {
              "lat": 43.77313288888889,
              "lng": -116.10429056666666,
              "alt": 2018.7555555555557,
              "speed2d": 2.171444444444444,
              "speed3d": 2.187777777777778,
              "msFromStart": 278242.4870799223
            },
            {
              "lat": 43.77312374444444,
              "lng": -116.10428403333333,
              "alt": 2018.9555555555555,
              "speed2d": 2.144888888888889,
              "speed3d": 2.152222222222222,
              "msFromStart": 278736.8437894739
            },
            {
              "lat": 43.77311482222222,
              "lng": -116.10427858888889,
              "alt": 2019.0144444444445,
              "speed2d": 2.0837777777777777,
              "speed3d": 2.101111111111111,
              "msFromStart": 279230.5057725799
            },
            {
              "lat": 43.77310606999998,
              "lng": -116.10427195,
              "alt": 2019.0595999999998,
              "speed2d": 1.9951,
              "speed3d": 1.999,
              "msFromStart": 279752.4302222221
            },
            {
              "lat": 43.77309704444445,
              "lng": -116.10426276666668,
              "alt": 2019.310111111111,
              "speed2d": 2.1332222222222224,
              "speed3d": 2.121111111111111,
              "msFromStart": 280274.80651851837
            },
            {
              "lat": 43.77308852222222,
              "lng": -116.10425266666667,
              "alt": 2019.5815555555557,
              "speed2d": 2.2187777777777775,
              "speed3d": 2.2244444444444444,
              "msFromStart": 280770.38933333324
            },
            {
              "lat": 43.773079833333334,
              "lng": -116.10424566666666,
              "alt": 2019.7856666666667,
              "speed2d": 2.1915555555555555,
              "speed3d": 2.1911111111111112,
              "msFromStart": 281266.15288888884
            },
            {
              "lat": 43.77307101111111,
              "lng": -116.10423911111111,
              "alt": 2019.9333333333334,
              "speed2d": 2.1963333333333335,
              "speed3d": 2.2133333333333334,
              "msFromStart": 281760.4640000001
            },
            {
              "lat": 43.7730621,
              "lng": -116.1042341,
              "alt": 2020.061222222222,
              "speed2d": 2.1413333333333333,
              "speed3d": 2.1644444444444444,
              "msFromStart": 282254.24308771937
            },
            {
              "lat": 43.77305337777778,
              "lng": -116.10422718888888,
              "alt": 2020.2273333333333,
              "speed2d": 2.1901111111111113,
              "speed3d": 2.171111111111111,
              "msFromStart": 282748.0808421053
            },
            {
              "lat": 43.77304483333333,
              "lng": -116.1042203111111,
              "alt": 2020.466222222222,
              "speed2d": 2.1005555555555557,
              "speed3d": 2.1444444444444444,
              "msFromStart": 283241.957551657
            },
            {
              "lat": 43.773036777777776,
              "lng": -116.10421607777776,
              "alt": 2020.4681111111108,
              "speed2d": 2.0417777777777775,
              "speed3d": 2.033333333333333,
              "msFromStart": 283736.73599999986
            },
            {
              "lat": 43.77302818888889,
              "lng": -116.10420935555555,
              "alt": 2020.6685555555557,
              "speed2d": 2.1727777777777777,
              "speed3d": 2.1655555555555552,
              "msFromStart": 284232.01639506145
            },
            {
              "lat": 43.77301972222222,
              "lng": -116.10420237777777,
              "alt": 2020.930111111111,
              "speed2d": 2.2174444444444448,
              "speed3d": 2.2333333333333334,
              "msFromStart": 284728.14933333325
            },
            {
              "lat": 43.7730119,
              "lng": -116.10419645555554,
              "alt": 2021.0798888888887,
              "speed2d": 2.095666666666667,
              "speed3d": 2.1277777777777778,
              "msFromStart": 285224.7190123456
            },
            {
              "lat": 43.77300327999999,
              "lng": -116.10419139,
              "alt": 2021.0649,
              "speed2d": 2.131,
              "speed3d": 2.1140000000000003,
              "msFromStart": 285747.6160000002
            },
            {
              "lat": 43.77299435555556,
              "lng": -116.10418445555554,
              "alt": 2021.126888888889,
              "speed2d": 2.201666666666667,
              "speed3d": 2.216666666666667,
              "msFromStart": 286269.8388148151
            },
            {
              "lat": 43.77298568888889,
              "lng": -116.1041771888889,
              "alt": 2021.3792222222223,
              "speed2d": 2.2425555555555556,
              "speed3d": 2.2277777777777774,
              "msFromStart": 286763.63911111123
            },
            {
              "lat": 43.77297715555555,
              "lng": -116.10416834444445,
              "alt": 2021.6954444444443,
              "speed2d": 2.253444444444445,
              "speed3d": 2.274444444444444,
              "msFromStart": 287257.2379259261
            },
            {
              "lat": 43.77296881111111,
              "lng": -116.10416156666666,
              "alt": 2021.9015555555557,
              "speed2d": 2.1786666666666665,
              "speed3d": 2.18,
              "msFromStart": 287753.5502222222
            },
            {
              "lat": 43.77296028888888,
              "lng": -116.10415364444444,
              "alt": 2022.2298888888888,
              "speed2d": 2.264888888888889,
              "speed3d": 2.2788888888888885,
              "msFromStart": 288250.8247329435
            },
            {
              "lat": 43.77295193333333,
              "lng": -116.10414626666666,
              "alt": 2022.5965555555554,
              "speed2d": 2.2056666666666667,
              "speed3d": 2.2344444444444442,
              "msFromStart": 288747.3869473685
            },
            {
              "lat": 43.77294387777778,
              "lng": -116.10414015555557,
              "alt": 2022.8509999999999,
              "speed2d": 2.1323333333333334,
              "speed3d": 2.1455555555555557,
              "msFromStart": 289243.65656660195
            },
            {
              "lat": 43.772935266666664,
              "lng": -116.10413256666666,
              "alt": 2023.2423333333334,
              "speed2d": 2.225333333333333,
              "speed3d": 2.255555555555555,
              "msFromStart": 289738.35733333323
            },
            {
              "lat": 43.77292711111111,
              "lng": -116.1041252,
              "alt": 2023.5334444444443,
              "speed2d": 2.2082222222222225,
              "speed3d": 2.236666666666667,
              "msFromStart": 290232.22676543193
            },
            {
              "lat": 43.77291807777778,
              "lng": -116.1041193,
              "alt": 2023.7625555555555,
              "speed2d": 2.2988888888888885,
              "speed3d": 2.3077777777777775,
              "msFromStart": 290726.7946666666
            },
            {
              "lat": 43.77290866,
              "lng": -116.10411233,
              "alt": 2023.9505,
              "speed2d": 2.2793,
              "speed3d": 2.3209999999999997,
              "msFromStart": 291249.20622222207
            },
            {
              "lat": 43.77289945555555,
              "lng": -116.10410752222222,
              "alt": 2024.018,
              "speed2d": 2.167333333333333,
              "speed3d": 2.1911111111111112,
              "msFromStart": 291770.99199999997
            },
            {
              "lat": 43.77289066666666,
              "lng": -116.10410234444444,
              "alt": 2024.2822222222221,
              "speed2d": 2.1513333333333335,
              "speed3d": 2.178888888888889,
              "msFromStart": 292265.09274074074
            },
            {
              "lat": 43.772882377777776,
              "lng": -116.10409731111112,
              "alt": 2024.61,
              "speed2d": 2.018,
              "speed3d": 2.042222222222222,
              "msFromStart": 292759.8915555556
            },
            {
              "lat": 43.77287398888888,
              "lng": -116.10409147777777,
              "alt": 2024.7300000000002,
              "speed2d": 2.0452222222222223,
              "speed3d": 2.0555555555555554,
              "msFromStart": 293254.9307446394
            },
            {
              "lat": 43.77286553333334,
              "lng": -116.1040855,
              "alt": 2024.822333333333,
              "speed2d": 2.1398888888888887,
              "speed3d": 2.1544444444444446,
              "msFromStart": 293749.6505263156
            },
            {
              "lat": 43.772857055555555,
              "lng": -116.10407803333334,
              "alt": 2025.0016666666668,
              "speed2d": 2.2327777777777778,
              "speed3d": 2.2477777777777783,
              "msFromStart": 294244.250136452
            },
            {
              "lat": 43.7728482,
              "lng": -116.10407045555554,
              "alt": 2025.1405555555552,
              "speed2d": 2.317,
              "speed3d": 2.332222222222222,
              "msFromStart": 294738.7200000001
            },
            {
              "lat": 43.77283953333333,
              "lng": -116.10406365555555,
              "alt": 2025.2653333333333,
              "speed2d": 2.263111111111111,
              "speed3d": 2.2755555555555556,
              "msFromStart": 295233.1296790125
            },
            {
              "lat": 43.77283071111112,
              "lng": -116.10405672222223,
              "alt": 2025.2645555555555,
              "speed2d": 2.278,
              "speed3d": 2.3077777777777775,
              "msFromStart": 295728.0426666666
            },
            {
              "lat": 43.77282161,
              "lng": -116.1040507,
              "alt": 2025.4454,
              "speed2d": 2.2058999999999997,
              "speed3d": 2.225,
              "msFromStart": 296250.72888888884
            },
            {
              "lat": 43.772812211111116,
              "lng": -116.10404541111113,
              "alt": 2025.513,
              "speed2d": 2.1896666666666667,
              "speed3d": 2.2077777777777774,
              "msFromStart": 296773.2444444444
            },
            {
              "lat": 43.772803800000005,
              "lng": -116.10403934444443,
              "alt": 2025.5892222222221,
              "speed2d": 2.191888888888889,
              "speed3d": 2.1955555555555555,
              "msFromStart": 297268.1881325535
            },
            {
              "lat": 43.77279532222222,
              "lng": -116.10403303333334,
              "alt": 2025.793222222222,
              "speed2d": 2.1894444444444443,
              "speed3d": 2.2111111111111112,
              "msFromStart": 297763.12589473685
            },
            {
              "lat": 43.77278735555556,
              "lng": -116.10402597777778,
              "alt": 2026.3065555555556,
              "speed2d": 2.153888888888889,
              "speed3d": 2.163333333333333,
              "msFromStart": 298258.0639480183
            },
            {
              "lat": 43.77277885555556,
              "lng": -116.10401874444445,
              "alt": 2026.6054444444446,
              "speed2d": 2.169,
              "speed3d": 2.1933333333333334,
              "msFromStart": 298753.1306666666
            },
            {
              "lat": 43.77277007777778,
              "lng": -116.10401243333334,
              "alt": 2026.7127777777778,
              "speed2d": 2.229,
              "speed3d": 2.2455555555555557,
              "msFromStart": 299248.2615308641
            },
            {
              "lat": 43.77276106666667,
              "lng": -116.10400632222223,
              "alt": 2026.8636666666669,
              "speed2d": 2.264222222222222,
              "speed3d": 2.278888888888889,
              "msFromStart": 299743.1253333334
            },
            {
              "lat": 43.77275252222222,
              "lng": -116.10399902222223,
              "alt": 2027.182777777778,
              "speed2d": 2.2960000000000003,
              "speed3d": 2.3033333333333337,
              "msFromStart": 300237.8500740741
            },
            {
              "lat": 43.77274458888889,
              "lng": -116.1039907,
              "alt": 2027.3757777777778,
              "speed2d": 2.3819999999999997,
              "speed3d": 2.378888888888889,
              "msFromStart": 300732.8213333334
            },
            {
              "lat": 43.77273631111112,
              "lng": -116.10398082222223,
              "alt": 2027.794222222222,
              "speed2d": 2.447111111111111,
              "speed3d": 2.496666666666666,
              "msFromStart": 301227.94251851866
            },
            {
              "lat": 43.77272761,
              "lng": -116.10397472000001,
              "alt": 2028.1405,
              "speed2d": 2.131,
              "speed3d": 2.184,
              "msFromStart": 301751.54044444434
            },
            {
              "lat": 43.772719333333335,
              "lng": -116.10396689999999,
              "alt": 2028.5152222222225,
              "speed2d": 2.0377777777777775,
              "speed3d": 2.0777777777777775,
              "msFromStart": 302275.556148148
            },
            {
              "lat": 43.77271075555555,
              "lng": -116.10395876666668,
              "alt": 2028.9777777777776,
              "speed2d": 2.1414444444444443,
              "speed3d": 2.1733333333333333,
              "msFromStart": 302770.78400000016
            },
            {
              "lat": 43.77270241111111,
              "lng": -116.1039534222222,
              "alt": 2029.110777777778,
              "speed2d": 2.0989999999999998,
              "speed3d": 2.137777777777778,
              "msFromStart": 303265.548912281
            },
            {
              "lat": 43.77269328888889,
              "lng": -116.10394761111111,
              "alt": 2029.2296666666668,
              "speed2d": 2.2836666666666665,
              "speed3d": 2.283333333333333,
              "msFromStart": 303759.9511578948
            },
            {
              "lat": 43.7726841,
              "lng": -116.10394094444445,
              "alt": 2029.4554444444448,
              "speed2d": 2.3037777777777775,
              "speed3d": 2.334444444444444,
              "msFromStart": 304254.2249668617
            },
            {
              "lat": 43.77267508888889,
              "lng": -116.10393574444446,
              "alt": 2029.5970000000002,
              "speed2d": 2.244555555555556,
              "speed3d": 2.2711111111111113,
              "msFromStart": 304748.76800000016
            },
            {
              "lat": 43.7726662,
              "lng": -116.10392813333334,
              "alt": 2029.861777777778,
              "speed2d": 2.2196666666666665,
              "speed3d": 2.2666666666666666,
              "msFromStart": 305243.47180246934
            },
            {
              "lat": 43.77265801111111,
              "lng": -116.10392258888889,
              "alt": 2030.0016666666668,
              "speed2d": 2.087111111111111,
              "speed3d": 2.1177777777777775,
              "msFromStart": 305738.9973333331
            },
            {
              "lat": 43.772649511111105,
              "lng": -116.1039135111111,
              "alt": 2030.5361111111113,
              "speed2d": 2.2466666666666666,
              "speed3d": 2.241111111111111,
              "msFromStart": 306234.9528888886
            },
            {
              "lat": 43.772640366666664,
              "lng": -116.10390665555556,
              "alt": 2030.8642222222225,
              "speed2d": 2.288111111111111,
              "speed3d": 2.353333333333334,
              "msFromStart": 306730.2613333334
            },
            {
              "lat": 43.772631455555555,
              "lng": -116.10390178888889,
              "alt": 2031.0853333333332,
              "speed2d": 2.1715555555555555,
              "speed3d": 2.1955555555555555,
              "msFromStart": 307225.2353580248
            },
            {
              "lat": 43.772621810000004,
              "lng": -116.10389352999998,
              "alt": 2031.4079000000002,
              "speed2d": 2.2552,
              "speed3d": 2.297,
              "msFromStart": 307748.49955555564
            },
            {
              "lat": 43.77261282222222,
              "lng": -116.10388734444443,
              "alt": 2031.624777777778,
              "speed2d": 2.1132222222222223,
              "speed3d": 2.17,
              "msFromStart": 308272.0842729046
            },
            {
              "lat": 43.77260476666667,
              "lng": -116.1038833888889,
              "alt": 2031.8194444444448,
              "speed2d": 1.9867777777777778,
              "speed3d": 2.0344444444444445,
              "msFromStart": 308766.7368421054
            },
            {
              "lat": 43.77259617777778,
              "lng": -116.10388008888889,
              "alt": 2031.8226666666667,
              "speed2d": 2.0132222222222222,
              "speed3d": 2.0255555555555556,
              "msFromStart": 309260.8946380769
            },
            {
              "lat": 43.772587744444444,
              "lng": -116.10387417777775,
              "alt": 2031.936333333333,
              "speed2d": 2.0516666666666663,
              "speed3d": 2.0733333333333333,
              "msFromStart": 309755.59466666664
            },
            {
              "lat": 43.77257934444444,
              "lng": -116.10386594444445,
              "alt": 2032.52,
              "speed2d": 2.1483333333333334,
              "speed3d": 2.167777777777778,
              "msFromStart": 310250.59595061716
            },
            {
              "lat": 43.77257018888889,
              "lng": -116.10385838888891,
              "alt": 2032.899333333333,
              "speed2d": 2.271111111111111,
              "speed3d": 2.2933333333333334,
              "msFromStart": 310746.07999999984
            },
            {
              "lat": 43.77256151111111,
              "lng": -116.1038510111111,
              "alt": 2032.968888888889,
              "speed2d": 2.2524444444444445,
              "speed3d": 2.2766666666666664,
              "msFromStart": 311241.8125432097
            },
            {
              "lat": 43.772552722222215,
              "lng": -116.1038437,
              "alt": 2033.2432222222224,
              "speed2d": 2.3095555555555554,
              "speed3d": 2.345555555555556,
              "msFromStart": 311736.94933333335
            },
            {
              "lat": 43.772544922222224,
              "lng": -116.10383435555556,
              "alt": 2033.850111111111,
              "speed2d": 2.1481111111111115,
              "speed3d": 2.201111111111111,
              "msFromStart": 312231.7517037038
            },
            {
              "lat": 43.77253641111112,
              "lng": -116.1038307,
              "alt": 2033.788777777778,
              "speed2d": 2.065222222222222,
              "speed3d": 2.1011111111111114,
              "msFromStart": 312725.8453333333
            },
            {
              "lat": 43.77252721000001,
              "lng": -116.10382064,
              "alt": 2034.3413999999998,
              "speed2d": 2.2636999999999996,
              "speed3d": 2.2800000000000002,
              "msFromStart": 313247.05485847936
            },
            {
              "lat": 43.77251856666666,
              "lng": -116.10381143333333,
              "alt": 2034.7423333333336,
              "speed2d": 2.1623333333333337,
              "speed3d": 2.2133333333333334,
              "msFromStart": 313769.7381052632
            },
            {
              "lat": 43.77251002222223,
              "lng": -116.10380336666665,
              "alt": 2034.9682222222223,
              "speed2d": 2.2698888888888886,
              "speed3d": 2.2944444444444447,
              "msFromStart": 314265.50523716706
            },
            {
              "lat": 43.77250148888889,
              "lng": -116.1037961111111,
              "alt": 2035.201777777778,
              "speed2d": 2.1271111111111107,
              "speed3d": 2.178888888888889,
              "msFromStart": 314760.3733333333
            },
            {
              "lat": 43.77249353333333,
              "lng": -116.10378814444445,
              "alt": 2035.6177777777777,
              "speed2d": 2.1334444444444447,
              "speed3d": 2.1511111111111108,
              "msFromStart": 315254.7772839506
            },
            {
              "lat": 43.77248518888889,
              "lng": -116.10377966666667,
              "alt": 2036.1476666666665,
              "speed2d": 2.227222222222222,
              "speed3d": 2.2866666666666666,
              "msFromStart": 315750.21866666677
            },
            {
              "lat": 43.772476866666665,
              "lng": -116.10377163333332,
              "alt": 2036.6363333333331,
              "speed2d": 2.192555555555556,
              "speed3d": 2.2333333333333334,
              "msFromStart": 316246.2032592594
            },
            {
              "lat": 43.77246898888889,
              "lng": -116.10376505555556,
              "alt": 2036.831888888889,
              "speed2d": 2.1644444444444444,
              "speed3d": 2.21,
              "msFromStart": 316741.3866666666
            },
            {
              "lat": 43.77246087777778,
              "lng": -116.10375785555556,
              "alt": 2037.085111111111,
              "speed2d": 2.195777777777778,
              "speed3d": 2.264444444444444,
              "msFromStart": 317236.148148148
            },
            {
              "lat": 43.77245315555556,
              "lng": -116.10375111111111,
              "alt": 2037.4693333333335,
              "speed2d": 2.0484444444444443,
              "speed3d": 2.1155555555555554,
              "msFromStart": 317731.4026666666
            },
            {
              "lat": 43.77244497777779,
              "lng": -116.10374504444444,
              "alt": 2037.4841111111114,
              "speed2d": 2.098222222222222,
              "speed3d": 2.1233333333333335,
              "msFromStart": 318226.9205042235
            },
            {
              "lat": 43.772435990000005,
              "lng": -116.10373679,
              "alt": 2037.6215000000002,
              "speed2d": 2.2358,
              "speed3d": 2.2710000000000004,
              "msFromStart": 318749.84505263163
            },
            {
              "lat": 43.77242717777778,
              "lng": -116.10372772222223,
              "alt": 2038.0341111111113,
              "speed2d": 2.242222222222222,
              "speed3d": 2.282222222222222,
              "msFromStart": 319272.708054581
            },
            {
              "lat": 43.77241858888889,
              "lng": -116.10372116666666,
              "alt": 2038.1814444444444,
              "speed2d": 2.2382222222222223,
              "speed3d": 2.2811111111111115,
              "msFromStart": 319767.9999999999
            },
            {
              "lat": 43.77241032222222,
              "lng": -116.10371415555555,
              "alt": 2038.3795555555553,
              "speed2d": 2.265333333333334,
              "speed3d": 2.312222222222222,
              "msFromStart": 320263.2572839504
            },
            {
              "lat": 43.77240106666667,
              "lng": -116.10370773333334,
              "alt": 2038.381777777778,
              "speed2d": 2.305444444444445,
              "speed3d": 2.3477777777777775,
              "msFromStart": 320758.16533333337
            },
            {
              "lat": 43.77239159999999,
              "lng": -116.10370169999999,
              "alt": 2038.3274444444444,
              "speed2d": 2.248444444444445,
              "speed3d": 2.3211111111111107,
              "msFromStart": 321252.7672098766
            },
            {
              "lat": 43.77238187777778,
              "lng": -116.10369574444445,
              "alt": 2038.2285555555554,
              "speed2d": 2.2231111111111113,
              "speed3d": 2.272222222222222,
              "msFromStart": 321741.2266666667
            },
            {
              "lat": 43.77237228888889,
              "lng": -116.10369088888888,
              "alt": 2038.064111111111,
              "speed2d": 2.2026666666666666,
              "speed3d": 2.2688888888888887,
              "msFromStart": 322226.6225860949
            },
            {
              "lat": 43.772362755555555,
              "lng": -116.10368696666669,
              "alt": 2038.147888888889,
              "speed2d": 2.2552222222222222,
              "speed3d": 2.305555555555556,
              "msFromStart": 322724.7023157894
            },
            {
              "lat": 43.77235443333333,
              "lng": -116.10368108888889,
              "alt": 2038.3545555555554,
              "speed2d": 2.1310000000000002,
              "speed3d": 2.2044444444444444,
              "msFromStart": 323229.61178947357
            },
            {
              "lat": 43.77234563333334,
              "lng": -116.10367576666667,
              "alt": 2038.4161111111111,
              "speed2d": 2.0927777777777776,
              "speed3d": 2.168888888888889,
              "msFromStart": 323743.9548235295
            },
            {
              "lat": 43.772335777777776,
              "lng": -116.10367097777778,
              "alt": 2038.1536666666666,
              "speed2d": 2.1554444444444445,
              "speed3d": 2.1644444444444444,
              "msFromStart": 324265.5315962239
            },
            {
              "lat": 43.772325466666665,
              "lng": -116.10366442222222,
              "alt": 2037.8694444444445,
              "speed2d": 2.2393333333333336,
              "speed3d": 2.2888888888888888,
              "msFromStart": 324770.82666666684
            },
            {
              "lat": 43.772316155555565,
              "lng": -116.10365831111112,
              "alt": 2037.958111111111,
              "speed2d": 2.224,
              "speed3d": 2.283333333333333,
              "msFromStart": 325267.35980246944
            },
            {
              "lat": 43.7723073,
              "lng": -116.1036532111111,
              "alt": 2038.1205555555557,
              "speed2d": 2.0993333333333335,
              "speed3d": 2.1844444444444444,
              "msFromStart": 325764.16000000015
            },
            {
              "lat": 43.77229870000001,
              "lng": -116.10364697777777,
              "alt": 2038.303888888889,
              "speed2d": 2.071888888888889,
              "speed3d": 2.1366666666666667,
              "msFromStart": 326261.0656790125
            },
            {
              "lat": 43.77229019999999,
              "lng": -116.10364065555555,
              "alt": 2038.508888888889,
              "speed2d": 2.0943333333333336,
              "speed3d": 2.183333333333333,
              "msFromStart": 326755.9786666667
            },
            {
              "lat": 43.77228165555555,
              "lng": -116.10363382222222,
              "alt": 2038.7083333333333,
              "speed2d": 2.137,
              "speed3d": 2.24,
              "msFromStart": 327249.8378271607
            },
            {
              "lat": 43.77227308888889,
              "lng": -116.10362614444446,
              "alt": 2038.9611111111112,
              "speed2d": 2.219888888888889,
              "speed3d": 2.321111111111111,
              "msFromStart": 327744.6933333334
            },
            {
              "lat": 43.77226463333333,
              "lng": -116.10361988888889,
              "alt": 2039.0106666666666,
              "speed2d": 2.223222222222222,
              "speed3d": 2.308888888888889,
              "msFromStart": 328240.0748330086
            },
            {
              "lat": 43.772257555555555,
              "lng": -116.10361272222222,
              "alt": 2039.1274444444443,
              "speed2d": 2.1267777777777774,
              "speed3d": 2.236666666666667,
              "msFromStart": 328734.91031578934
            },
            {
              "lat": 43.77225171111111,
              "lng": -116.10360494444444,
              "alt": 2039.239,
              "speed2d": 2.014777777777778,
              "speed3d": 2.046666666666667,
              "msFromStart": 329229.4517894735
            },
            {
              "lat": 43.7722434,
              "lng": -116.10359817,
              "alt": 2039.2039,
              "speed2d": 2.0221999999999998,
              "speed3d": 2.094,
              "msFromStart": 329751.2835555555
            },
            {
              "lat": 43.772235300000006,
              "lng": -116.10359258888889,
              "alt": 2039.116111111111,
              "speed2d": 2.0404444444444443,
              "speed3d": 2.0255555555555556,
              "msFromStart": 330272.980345679
            },
            {
              "lat": 43.77222556666667,
              "lng": -116.10358466666669,
              "alt": 2039.2576666666669,
              "speed2d": 2.439222222222222,
              "speed3d": 2.434444444444444,
              "msFromStart": 330767.16800000006
            },
            {
              "lat": 43.772217655555565,
              "lng": -116.10357685555553,
              "alt": 2039.5173333333332,
              "speed2d": 2.372777777777778,
              "speed3d": 2.4055555555555554,
              "msFromStart": 331261.3372839507
            },
            {
              "lat": 43.772209866666664,
              "lng": -116.10356872222224,
              "alt": 2039.9557777777777,
              "speed2d": 2.331111111111111,
              "speed3d": 2.3677777777777775,
              "msFromStart": 331755.9893333334
            },
            {
              "lat": 43.77220226666667,
              "lng": -116.10356214444442,
              "alt": 2040.4455555555555,
              "speed2d": 2.113,
              "speed3d": 2.1966666666666668,
              "msFromStart": 332250.7936790124
            },
            {
              "lat": 43.77219362222222,
              "lng": -116.10355722222221,
              "alt": 2040.5855555555556,
              "speed2d": 2.1083333333333334,
              "speed3d": 2.148888888888889,
              "msFromStart": 332739.99999999994
            },
            {
              "lat": 43.77218463333333,
              "lng": -116.10355234444445,
              "alt": 2040.9530000000002,
              "speed2d": 2.1098888888888885,
              "speed3d": 2.1855555555555557,
              "msFromStart": 333226.44460038986
            },
            {
              "lat": 43.77217537777778,
              "lng": -116.10354695555556,
              "alt": 2041.0030000000002,
              "speed2d": 2.1725555555555554,
              "speed3d": 2.2177777777777776,
              "msFromStart": 333726.0244210526
            },
            {
              "lat": 43.77216593333333,
              "lng": -116.10354029999999,
              "alt": 2041.0424444444445,
              "speed2d": 2.228666666666667,
              "speed3d": 2.321111111111111,
              "msFromStart": 334232.6770526315
            },
            {
              "lat": 43.77215786666666,
              "lng": -116.10353507777778,
              "alt": 2041.1015555555557,
              "speed2d": 1.9988888888888885,
              "speed3d": 2.0700000000000003,
              "msFromStart": 334746.52423529414
            },
            {
              "lat": 43.77214858888889,
              "lng": -116.10352909999999,
              "alt": 2041.173888888889,
              "speed2d": 2.126888888888889,
              "speed3d": 2.177777777777778,
              "msFromStart": 335265.83843137266
            },
            {
              "lat": 43.77213967777778,
              "lng": -116.10352226666666,
              "alt": 2041.5575555555554,
              "speed2d": 2.233111111111111,
              "speed3d": 2.2777777777777777,
              "msFromStart": 335770.144
            },
            {
              "lat": 43.77213191111112,
              "lng": -116.10351482222222,
              "alt": 2042.0985555555553,
              "speed2d": 2.1964444444444444,
              "speed3d": 2.2944444444444443,
              "msFromStart": 336266.3583209876
            },
            {
              "lat": 43.77212387777777,
              "lng": -116.1035075111111,
              "alt": 2042.6154444444444,
              "speed2d": 2.298,
              "speed3d": 2.3511111111111114,
              "msFromStart": 336762.06933333346
            },
            {
              "lat": 43.772115533333334,
              "lng": -116.1035001,
              "alt": 2043.1454444444448,
              "speed2d": 2.293111111111111,
              "speed3d": 2.38,
              "msFromStart": 337257.4982716052
            },
            {
              "lat": 43.772107866666666,
              "lng": -116.1034925888889,
              "alt": 2043.744111111111,
              "speed2d": 2.223777777777778,
              "speed3d": 2.327777777777778,
              "msFromStart": 337752.3520000002
            },
            {
              "lat": 43.77209917777778,
              "lng": -116.1034873,
              "alt": 2043.7658888888886,
              "speed2d": 2.2046666666666668,
              "speed3d": 2.3044444444444445,
              "msFromStart": 338246.8922261211
            },
            {
              "lat": 43.772091688888885,
              "lng": -116.10348179999998,
              "alt": 2044.0536666666667,
              "speed2d": 1.9605555555555556,
              "speed3d": 2.063333333333333,
              "msFromStart": 338741.2362105264
            },
            {
              "lat": 43.77208451111112,
              "lng": -116.10347506666668,
              "alt": 2044.2651111111113,
              "speed2d": 2.0292222222222223,
              "speed3d": 2.082222222222222,
              "msFromStart": 339235.474526316
            },
            {
              "lat": 43.77207663333334,
              "lng": -116.1034684,
              "alt": 2044.3854444444444,
              "speed2d": 2.0740000000000003,
              "speed3d": 2.141111111111111,
              "msFromStart": 339731.2604444445
            },
            {
              "lat": 43.77206883333333,
              "lng": -116.1034631,
              "alt": 2044.5893333333331,
              "speed2d": 2.0766666666666667,
              "speed3d": 2.122222222222222,
              "msFromStart": 340228.28444444446
            },
            {
              "lat": 43.77206063000001,
              "lng": -116.10345563999998,
              "alt": 2045.0981,
              "speed2d": 2.1761,
              "speed3d": 2.229,
              "msFromStart": 340752.0017777778
            },
            {
              "lat": 43.77205284444445,
              "lng": -116.1034489,
              "alt": 2045.605222222222,
              "speed2d": 2.0668888888888888,
              "speed3d": 2.171111111111111,
              "msFromStart": 341275.0243950618
            },
            {
              "lat": 43.77204534444445,
              "lng": -116.10344350000001,
              "alt": 2045.900666666667,
              "speed2d": 2.0220000000000002,
              "speed3d": 2.111111111111111,
              "msFromStart": 341769.152
            },
            {
              "lat": 43.77203745555555,
              "lng": -116.10343835555554,
              "alt": 2046.182222222222,
              "speed2d": 2.030777777777778,
              "speed3d": 2.0988888888888884,
              "msFromStart": 342262.5534814814
            },
            {
              "lat": 43.772029266666664,
              "lng": -116.10343358888889,
              "alt": 2046.2050000000002,
              "speed2d": 2.005777777777778,
              "speed3d": 2.081111111111111,
              "msFromStart": 342756.44800000003
            },
            {
              "lat": 43.77202122222222,
              "lng": -116.1034285111111,
              "alt": 2046.6536666666668,
              "speed2d": 2.020777777777778,
              "speed3d": 2.0922222222222224,
              "msFromStart": 343250.63329434703
            },
            {
              "lat": 43.772012911111105,
              "lng": -116.10342202222222,
              "alt": 2047.1582222222223,
              "speed2d": 2.056888888888889,
              "speed3d": 2.1444444444444444,
              "msFromStart": 343746.133894737
            },
            {
              "lat": 43.7720049,
              "lng": -116.10341514444445,
              "alt": 2047.4142222222224,
              "speed2d": 2.1086666666666667,
              "speed3d": 2.1533333333333333,
              "msFromStart": 344242.34273684246
            },
            {
              "lat": 43.771996588888896,
              "lng": -116.10340774444445,
              "alt": 2047.7614444444444,
              "speed2d": 2.1519999999999997,
              "speed3d": 2.2333333333333334,
              "msFromStart": 344738.30222222226
            },
            {
              "lat": 43.771988677777784,
              "lng": -116.10340047777778,
              "alt": 2048.0894444444443,
              "speed2d": 2.0885555555555553,
              "speed3d": 2.152222222222222,
              "msFromStart": 345234.06222222233
            },
            {
              "lat": 43.77198064444444,
              "lng": -116.10339393333334,
              "alt": 2048.4993333333337,
              "speed2d": 2.149,
              "speed3d": 2.2266666666666666,
              "msFromStart": 345728.5777777777
            },
            {
              "lat": 43.77197206,
              "lng": -116.10338783,
              "alt": 2048.6868,
              "speed2d": 2.1057,
              "speed3d": 2.198,
              "msFromStart": 346249.53066666645
            },
            {
              "lat": 43.77196421111111,
              "lng": -116.10338243333334,
              "alt": 2048.619777777778,
              "speed2d": 1.9792222222222224,
              "speed3d": 2.037777777777778,
              "msFromStart": 346771.3600000002
            },
            {
              "lat": 43.77195594444444,
              "lng": -116.10337424444444,
              "alt": 2049.0796666666665,
              "speed2d": 2.2015555555555553,
              "speed3d": 2.2288888888888887,
              "msFromStart": 347266.240395062
            },
            {
              "lat": 43.77194754444444,
              "lng": -116.10336668888888,
              "alt": 2049.4829999999997,
              "speed2d": 2.284,
              "speed3d": 2.368888888888889,
              "msFromStart": 347761.14133333333
            },
            {
              "lat": 43.77193906666666,
              "lng": -116.10336036666666,
              "alt": 2049.8542222222222,
              "speed2d": 2.187666666666667,
              "speed3d": 2.276666666666667,
              "msFromStart": 348256.054934373
            },
            {
              "lat": 43.77193055555556,
              "lng": -116.10335436666668,
              "alt": 2050.1721111111115,
              "speed2d": 2.167222222222222,
              "speed3d": 2.2422222222222223,
              "msFromStart": 348751.0517894735
            },
            {
              "lat": 43.77192052222223,
              "lng": -116.10334918888888,
              "alt": 2050.2301111111115,
              "speed2d": 2.221,
              "speed3d": 2.2955555555555556,
              "msFromStart": 349246.0934736838
            },
            {
              "lat": 43.77190983333333,
              "lng": -116.1033443,
              "alt": 2049.8874444444446,
              "speed2d": 2.2159999999999997,
              "speed3d": 2.2988888888888885,
              "msFromStart": 349742.040888889
            },
            {
              "lat": 43.77189946666667,
              "lng": -116.10334068888888,
              "alt": 2049.4154444444443,
              "speed2d": 2.113777777777778,
              "speed3d": 2.1733333333333333,
              "msFromStart": 350238.71288888913
            },
            {
              "lat": 43.77188938888889,
              "lng": -116.10333541111112,
              "alt": 2049.027222222222,
              "speed2d": 2.1081111111111115,
              "speed3d": 2.172222222222222,
              "msFromStart": 350735.047111111
            },
            {
              "lat": 43.771879488888885,
              "lng": -116.10333006666667,
              "alt": 2048.7975555555554,
              "speed2d": 2.1053333333333333,
              "speed3d": 2.141111111111111,
              "msFromStart": 351231.11111111083
            },
            {
              "lat": 43.77186907777778,
              "lng": -116.10332368888888,
              "alt": 2048.677333333333,
              "speed2d": 2.278777777777778,
              "speed3d": 2.3111111111111113,
              "msFromStart": 351726.8373333332
            },
            {
              "lat": 43.77185835,
              "lng": -116.10331833999999,
              "alt": 2048.4326,
              "speed2d": 2.2055,
              "speed3d": 2.261,
              "msFromStart": 352249.80657777755
            },
            {
              "lat": 43.77184815555556,
              "lng": -116.10331036666668,
              "alt": 2048.522888888889,
              "speed2d": 2.2321111111111107,
              "speed3d": 2.2888888888888888,
              "msFromStart": 352772.0746666665
            },
            {
              "lat": 43.771840044444446,
              "lng": -116.1033030888889,
              "alt": 2048.9535555555553,
              "speed2d": 2.1301111111111113,
              "speed3d": 2.1966666666666668,
              "msFromStart": 353266.4337673811
            },
            {
              "lat": 43.771832144444446,
              "lng": -116.10329675555556,
              "alt": 2049.3224444444445,
              "speed2d": 2.0998888888888887,
              "speed3d": 2.1533333333333333,
              "msFromStart": 353760.3301052633
            },
            {
              "lat": 43.77182443333332,
              "lng": -116.10329024444445,
              "alt": 2049.5784444444444,
              "speed2d": 2.069666666666667,
              "speed3d": 2.1344444444444446,
              "msFromStart": 354253.97726315825
            },
            {
              "lat": 43.771816466666664,
              "lng": -116.10328406666666,
              "alt": 2049.7521111111114,
              "speed2d": 1.9675555555555557,
              "speed3d": 2.0322222222222224,
              "msFromStart": 354748.1671111111
            },
            {
              "lat": 43.77180731111111,
              "lng": -116.10327857777776,
              "alt": 2049.6313333333333,
              "speed2d": 1.9957777777777777,
              "speed3d": 2.028888888888889,
              "msFromStart": 355242.7911111111
            },
            {
              "lat": 43.77179755555555,
              "lng": -116.1032757888889,
              "alt": 2049.353888888889,
              "speed2d": 2.002,
              "speed3d": 2.042222222222222,
              "msFromStart": 355737.7528888889
            },
            {
              "lat": 43.771787455555554,
              "lng": -116.10327322222223,
              "alt": 2049.0555555555557,
              "speed2d": 1.9544444444444442,
              "speed3d": 1.9955555555555557,
              "msFromStart": 356232.98488888884
            },
            {
              "lat": 43.77177716666666,
              "lng": -116.1032677,
              "alt": 2048.7814444444443,
              "speed2d": 2.0808888888888886,
              "speed3d": 2.145555555555555,
              "msFromStart": 356727.9680000001
            },
            {
              "lat": 43.771766369999995,
              "lng": -116.10326145,
              "alt": 2048.4352,
              "speed2d": 2.1363000000000003,
              "speed3d": 2.1590000000000003,
              "msFromStart": 357250.2540444447
            },
            {
              "lat": 43.77175546666667,
              "lng": -116.10325635555554,
              "alt": 2048.0574444444446,
              "speed2d": 2.0817777777777775,
              "speed3d": 2.14,
              "msFromStart": 357773.35466666677
            },
            {
              "lat": 43.77174531111112,
              "lng": -116.10325201111111,
              "alt": 2047.6918888888888,
              "speed2d": 2.082888888888889,
              "speed3d": 2.144444444444445,
              "msFromStart": 358269.37789213797
            },
            {
              "lat": 43.77173552222223,
              "lng": -116.10324906666668,
              "alt": 2047.3971111111111,
              "speed2d": 2.037111111111111,
              "speed3d": 2.0733333333333333,
              "msFromStart": 358764.1128421052
            },
            {
              "lat": 43.77172598888889,
              "lng": -116.10324548888889,
              "alt": 2047.1512222222223,
              "speed2d": 1.9943333333333333,
              "speed3d": 2.051111111111111,
              "msFromStart": 359258.1541052631
            },
            {
              "lat": 43.77171674444444,
              "lng": -116.10324158888889,
              "alt": 2046.844,
              "speed2d": 1.9293333333333333,
              "speed3d": 1.9711111111111108,
              "msFromStart": 359752.9991111112
            },
            {
              "lat": 43.77170764444444,
              "lng": -116.10323765555555,
              "alt": 2046.527222222222,
              "speed2d": 1.976222222222222,
              "speed3d": 2.0266666666666664,
              "msFromStart": 360248.48711111135
            },
            {
              "lat": 43.771698844444444,
              "lng": -116.10323244444444,
              "alt": 2046.2908888888887,
              "speed2d": 1.9717777777777779,
              "speed3d": 1.9855555555555555,
              "msFromStart": 360744.11733333324
            },
            {
              "lat": 43.77168838888889,
              "lng": -116.10322553333333,
              "alt": 2046.1227777777779,
              "speed2d": 2.2087777777777777,
              "speed3d": 2.23,
              "msFromStart": 361239.8613333332
            },
            {
              "lat": 43.771678433333335,
              "lng": -116.10321956666667,
              "alt": 2046.0378888888888,
              "speed2d": 2.204888888888889,
              "speed3d": 2.2244444444444444,
              "msFromStart": 361735.53422222217
            },
            {
              "lat": 43.771668633333334,
              "lng": -116.10321303333333,
              "alt": 2046.0343333333333,
              "speed2d": 2.208111111111111,
              "speed3d": 2.246666666666667,
              "msFromStart": 362231.1502222222
            },
            {
              "lat": 43.77165986666667,
              "lng": -116.10320874444444,
              "alt": 2045.8202222222221,
              "speed2d": 1.9872222222222222,
              "speed3d": 2.0344444444444445,
              "msFromStart": 362726.3484444445
            },
            {
              "lat": 43.7716506,
              "lng": -116.10320303000002,
              "alt": 2045.6719000000003,
              "speed2d": 1.9463000000000001,
              "speed3d": 1.9639999999999997,
              "msFromStart": 363248.72336842114
            },
            {
              "lat": 43.7716408,
              "lng": -116.10319815555556,
              "alt": 2045.4327777777776,
              "speed2d": 2.092222222222222,
              "speed3d": 2.077777777777778,
              "msFromStart": 363772.170105263
            },
            {
              "lat": 43.771630844444445,
              "lng": -116.10319122222221,
              "alt": 2045.4037777777778,
              "speed2d": 2.2723333333333335,
              "speed3d": 2.256666666666667,
              "msFromStart": 364268.6972631576
            },
            {
              "lat": 43.771620899999995,
              "lng": -116.10318323333331,
              "alt": 2045.4085555555557,
              "speed2d": 2.347,
              "speed3d": 2.347777777777778,
              "msFromStart": 364764.5759999999
            },
            {
              "lat": 43.77161135555556,
              "lng": -116.10317483333331,
              "alt": 2045.5446666666667,
              "speed2d": 2.2951111111111113,
              "speed3d": 2.332222222222222,
              "msFromStart": 365259.93599999975
            },
            {
              "lat": 43.77160277777777,
              "lng": -116.10316776666666,
              "alt": 2045.6725555555554,
              "speed2d": 2.152888888888889,
              "speed3d": 2.1622222222222223,
              "msFromStart": 365753.3493333334
            },
            {
              "lat": 43.77159485555555,
              "lng": -116.10316181111112,
              "alt": 2045.839777777778,
              "speed2d": 2.066,
              "speed3d": 2.077777777777778,
              "msFromStart": 366245.2053333335
            },
            {
              "lat": 43.771586544444446,
              "lng": -116.10315508888888,
              "alt": 2046.0136666666665,
              "speed2d": 2.1462222222222223,
              "speed3d": 2.14,
              "msFromStart": 366738.4746666668
            },
            {
              "lat": 43.77157806666668,
              "lng": -116.10314676666667,
              "alt": 2046.331111111111,
              "speed2d": 2.1902222222222223,
              "speed3d": 2.192222222222222,
              "msFromStart": 367232.8746666669
            },
            {
              "lat": 43.77156898888889,
              "lng": -116.10313943333333,
              "alt": 2046.489222222222,
              "speed2d": 2.249777777777778,
              "speed3d": 2.2455555555555553,
              "msFromStart": 367728.1813333333
            },
            {
              "lat": 43.77155938000001,
              "lng": -116.10313173,
              "alt": 2046.6639,
              "speed2d": 2.1643,
              "speed3d": 2.1870000000000003,
              "msFromStart": 368251.75831578946
            },
            {
              "lat": 43.771550211111105,
              "lng": -116.1031266,
              "alt": 2046.7868888888888,
              "speed2d": 1.9996666666666667,
              "speed3d": 2.022222222222222,
              "msFromStart": 368774.61894736835
            },
            {
              "lat": 43.77154169999999,
              "lng": -116.10312151111111,
              "alt": 2046.9406666666669,
              "speed2d": 1.9397777777777776,
              "speed3d": 1.9500000000000002,
              "msFromStart": 369269.5393684209
            },
            {
              "lat": 43.771532799999996,
              "lng": -116.10311511111111,
              "alt": 2047.0734444444442,
              "speed2d": 2.068777777777778,
              "speed3d": 2.0577777777777775,
              "msFromStart": 369764.65066666657
            },
            {
              "lat": 43.771524011111104,
              "lng": -116.10310763333332,
              "alt": 2047.1651111111112,
              "speed2d": 2.1373333333333333,
              "speed3d": 2.147777777777778,
              "msFromStart": 370259.9146666664
            },
            {
              "lat": 43.77151611111111,
              "lng": -116.10310143333335,
              "alt": 2047.3875555555555,
              "speed2d": 2.0065555555555554,
              "speed3d": 2.022222222222222,
              "msFromStart": 370754.62755555543
            },
            {
              "lat": 43.771507033333336,
              "lng": -116.10309541111111,
              "alt": 2047.581777777778,
              "speed2d": 2.2105555555555556,
              "speed3d": 2.172222222222222,
              "msFromStart": 371248.8995555552
            },
            {
              "lat": 43.77149795555555,
              "lng": -116.10308769999999,
              "alt": 2047.7234444444448,
              "speed2d": 2.258,
              "speed3d": 2.3055555555555554,
              "msFromStart": 371744.6915555554
            },
            {
              "lat": 43.77149062222222,
              "lng": -116.10308251111113,
              "alt": 2047.692777777778,
              "speed2d": 2.0398888888888886,
              "speed3d": 2.0477777777777777,
              "msFromStart": 372241.69955555524
            },
            {
              "lat": 43.7714822,
              "lng": -116.10307676666667,
              "alt": 2047.739,
              "speed2d": 2.1531111111111114,
              "speed3d": 2.1277777777777773,
              "msFromStart": 372736.43199999986
            },
            {
              "lat": 43.77147347777778,
              "lng": -116.10307391111112,
              "alt": 2047.5357777777779,
              "speed2d": 2.2777777777777777,
              "speed3d": 2.257777777777778,
              "msFromStart": 373229.34399999975
            },
            {
              "lat": 43.77146321,
              "lng": -116.10306908,
              "alt": 2047.4158000000002,
              "speed2d": 2.5595999999999997,
              "speed3d": 2.547,
              "msFromStart": 373750.6357894736
            },
            {
              "lat": 43.77145077777778,
              "lng": -116.10306176666666,
              "alt": 2047.5211111111112,
              "speed2d": 3.0574444444444446,
              "speed3d": 2.898888888888889,
              "msFromStart": 374272.65178947354
            },
            {
              "lat": 43.771431575,
              "lng": -116.1030476,
              "alt": 2047.6747500000001,
              "speed2d": 4.585875,
              "speed3d": 4.483750000000001,
              "msFromStart": 374742.448
            }
          ]
        }
      ]
    },
    {
      "id": "lift-showcase",
      "pathType": "lift",
      "name": "Showcase",
      "speed": "fast",
      "seats": 4,
      "videos": [
        {
          "videoId": "GH010026",
          "pathId": "lift-showcase",
          "startSec": 0,
          "endSec": 457,
          "id": "video-segment-20",
          "gps": [
            {
              "lat": 43.764060799999996,
              "lng": -116.096111475,
              "alt": 1929.9523749999998,
              "speed2d": 3.509125,
              "speed3d": 3.5037499999999997,
              "msFromStart": 264.43415789473687
            },
            {
              "lat": 43.76404732222222,
              "lng": -116.09611264444445,
              "alt": 1929.8416666666667,
              "speed2d": 3.1646666666666667,
              "speed3d": 3.2188888888888885,
              "msFromStart": 731.0796842105264
            },
            {
              "lat": 43.76403619999999,
              "lng": -116.09612001,
              "alt": 1929.5790000000002,
              "speed2d": 2.6085,
              "speed3d": 2.663,
              "msFromStart": 1252.6961578947366
            },
            {
              "lat": 43.76402456666667,
              "lng": -116.09611813333333,
              "alt": 1929.2385555555556,
              "speed2d": 2.4058888888888887,
              "speed3d": 2.4466666666666663,
              "msFromStart": 1774.4759999999994
            },
            {
              "lat": 43.764015266666675,
              "lng": -116.0961134222222,
              "alt": 1929.0195555555556,
              "speed2d": 2.2319999999999998,
              "speed3d": 2.271111111111111,
              "msFromStart": 2269.2610987654316
            },
            {
              "lat": 43.764006,
              "lng": -116.09611053333334,
              "alt": 1928.8888888888891,
              "speed2d": 2.2054444444444448,
              "speed3d": 2.23,
              "msFromStart": 2764.900666666666
            },
            {
              "lat": 43.763995333333334,
              "lng": -116.09611154444444,
              "alt": 1928.7062222222223,
              "speed2d": 2.385888888888889,
              "speed3d": 2.336666666666667,
              "msFromStart": 3260.5575308641974
            },
            {
              "lat": 43.76398094444444,
              "lng": -116.09610932222222,
              "alt": 1928.5855555555554,
              "speed2d": 2.8235555555555556,
              "speed3d": 2.8344444444444448,
              "msFromStart": 3756.215999999999
            },
            {
              "lat": 43.76396931111111,
              "lng": -116.09610898888889,
              "alt": 1928.5718888888891,
              "speed2d": 2.615222222222222,
              "speed3d": 2.6633333333333336,
              "msFromStart": 4251.360820987654
            },
            {
              "lat": 43.76396055555554,
              "lng": -116.09610884444444,
              "alt": 1928.52,
              "speed2d": 2.1442222222222225,
              "speed3d": 2.238888888888889,
              "msFromStart": 4745.551666666667
            },
            {
              "lat": 43.76395471111111,
              "lng": -116.0961096,
              "alt": 1928.447333333333,
              "speed2d": 1.6857777777777778,
              "speed3d": 1.7166666666666668,
              "msFromStart": 5240.3748710201435
            },
            {
              "lat": 43.76394686666667,
              "lng": -116.09610998888888,
              "alt": 1928.512666666667,
              "speed2d": 1.7922222222222224,
              "speed3d": 1.7644444444444445,
              "msFromStart": 5736.40652631579
            },
            {
              "lat": 43.763936533333336,
              "lng": -116.09611073333333,
              "alt": 1928.4665555555555,
              "speed2d": 2.1608888888888886,
              "speed3d": 2.111111111111111,
              "msFromStart": 6232.072948343079
            },
            {
              "lat": 43.76392190000001,
              "lng": -116.09610828888889,
              "alt": 1928.3262222222224,
              "speed2d": 2.8288888888888892,
              "speed3d": 2.7711111111111113,
              "msFromStart": 6726.68494444444
            },
            {
              "lat": 43.76390798,
              "lng": -116.09610684,
              "alt": 1928.2942999999998,
              "speed2d": 2.8261,
              "speed3d": 2.8790000000000004,
              "msFromStart": 7248.677916666665
            },
            {
              "lat": 43.76389715555555,
              "lng": -116.09610577777778,
              "alt": 1928.2902222222222,
              "speed2d": 2.2797777777777775,
              "speed3d": 2.3622222222222224,
              "msFromStart": 7770.581999999998
            },
            {
              "lat": 43.76388997777778,
              "lng": -116.09610525555553,
              "alt": 1928.2897777777778,
              "speed2d": 1.7256666666666667,
              "speed3d": 1.7599999999999998,
              "msFromStart": 8265.592858024693
            },
            {
              "lat": 43.76388175555556,
              "lng": -116.09610417777778,
              "alt": 1928.3514444444445,
              "speed2d": 1.863,
              "speed3d": 1.8433333333333333,
              "msFromStart": 8761.67966666667
            },
            {
              "lat": 43.76387236666667,
              "lng": -116.09610408888891,
              "alt": 1928.432,
              "speed2d": 1.983777777777778,
              "speed3d": 1.9755555555555553,
              "msFromStart": 9257.482450617286
            },
            {
              "lat": 43.76386108888888,
              "lng": -116.09610488888887,
              "alt": 1928.1618888888888,
              "speed2d": 2.3456666666666663,
              "speed3d": 2.2844444444444445,
              "msFromStart": 9752.719333333342
            },
            {
              "lat": 43.763847122222224,
              "lng": -116.0961044,
              "alt": 1927.9973333333332,
              "speed2d": 2.821222222222222,
              "speed3d": 2.8100000000000005,
              "msFromStart": 10247.842648473037
            },
            {
              "lat": 43.76383557777778,
              "lng": -116.09610427777778,
              "alt": 1928.0633333333335,
              "speed2d": 2.5275555555555558,
              "speed3d": 2.5866666666666664,
              "msFromStart": 10742.775263157897
            },
            {
              "lat": 43.76382737777778,
              "lng": -116.09610376666666,
              "alt": 1928.0222222222221,
              "speed2d": 1.905111111111111,
              "speed3d": 1.9755555555555553,
              "msFromStart": 11237.527798245612
            },
            {
              "lat": 43.76382073333333,
              "lng": -116.09610425555556,
              "alt": 1928.0284444444444,
              "speed2d": 1.6838888888888888,
              "speed3d": 1.6933333333333334,
              "msFromStart": 11731.801499999992
            },
            {
              "lat": 43.76381323333333,
              "lng": -116.09610476666666,
              "alt": 1927.9565555555557,
              "speed2d": 1.6882222222222223,
              "speed3d": 1.6822222222222223,
              "msFromStart": 12226.718888888885
            },
            {
              "lat": 43.76380197999999,
              "lng": -116.09610430000001,
              "alt": 1927.9802,
              "speed2d": 2.0457,
              "speed3d": 2.0050000000000003,
              "msFromStart": 12751.053416666662
            },
            {
              "lat": 43.76378925555556,
              "lng": -116.09610383333333,
              "alt": 1927.7542222222223,
              "speed2d": 2.4064444444444444,
              "speed3d": 2.408888888888889,
              "msFromStart": 13274.720759259259
            },
            {
              "lat": 43.76377893333333,
              "lng": -116.09610344444444,
              "alt": 1927.7053333333336,
              "speed2d": 2.2266666666666666,
              "speed3d": 2.2733333333333334,
              "msFromStart": 13769.370000000006
            },
            {
              "lat": 43.76377155555556,
              "lng": -116.09610231111111,
              "alt": 1927.594,
              "speed2d": 1.7014444444444443,
              "speed3d": 1.76,
              "msFromStart": 14263.773239766082
            },
            {
              "lat": 43.763766277777776,
              "lng": -116.09609955555557,
              "alt": 1927.6596666666665,
              "speed2d": 1.326222222222222,
              "speed3d": 1.3877777777777778,
              "msFromStart": 14757.770210526307
            },
            {
              "lat": 43.7637623,
              "lng": -116.09609994444445,
              "alt": 1927.406444444444,
              "speed2d": 1.032111111111111,
              "speed3d": 1.0722222222222222,
              "msFromStart": 15252.043734892788
            },
            {
              "lat": 43.7637562,
              "lng": -116.09609880000001,
              "alt": 1927.4140000000002,
              "speed2d": 1.1391111111111112,
              "speed3d": 1.1366666666666665,
              "msFromStart": 15747.08911111112
            },
            {
              "lat": 43.76374845555556,
              "lng": -116.09609646666665,
              "alt": 1927.4852222222223,
              "speed2d": 1.339888888888889,
              "speed3d": 1.3233333333333335,
              "msFromStart": 16242.170962962966
            },
            {
              "lat": 43.76374134444445,
              "lng": -116.09609314444447,
              "alt": 1927.757,
              "speed2d": 1.3184444444444443,
              "speed3d": 1.3455555555555556,
              "msFromStart": 16737.24155555555
            },
            {
              "lat": 43.763737500000005,
              "lng": -116.09609100000002,
              "alt": 1928.0720000000001,
              "speed2d": 1.035,
              "speed3d": 1.0866666666666664,
              "msFromStart": 17232.30014814814
            },
            {
              "lat": 43.76373173333334,
              "lng": -116.09608722222222,
              "alt": 1928.6845555555556,
              "speed2d": 1.0193333333333334,
              "speed3d": 1.0022222222222223,
              "msFromStart": 17727.327777777766
            },
            {
              "lat": 43.7637258,
              "lng": -116.09608424,
              "alt": 1929.1062,
              "speed2d": 1.0865,
              "speed3d": 1.0699999999999998,
              "msFromStart": 18249.518911111107
            },
            {
              "lat": 43.76371947777778,
              "lng": -116.09608217777777,
              "alt": 1929.8452222222222,
              "speed2d": 1.2122222222222223,
              "speed3d": 1.2311111111111113,
              "msFromStart": 18770.941333333325
            },
            {
              "lat": 43.76371357777778,
              "lng": -116.0960819888889,
              "alt": 1930.0625555555555,
              "speed2d": 1.2124444444444444,
              "speed3d": 1.1877777777777778,
              "msFromStart": 19265.35058024691
            },
            {
              "lat": 43.76370765555556,
              "lng": -116.0960843,
              "alt": 1930.1698888888889,
              "speed2d": 1.1773333333333333,
              "speed3d": 1.2255555555555555,
              "msFromStart": 19760.621999999992
            },
            {
              "lat": 43.76370188888889,
              "lng": -116.09608298888888,
              "alt": 1930.4065555555553,
              "speed2d": 1.0774444444444444,
              "speed3d": 1.0566666666666666,
              "msFromStart": 20256.115988304096
            },
            {
              "lat": 43.76369457777778,
              "lng": -116.09608017777778,
              "alt": 1930.755111111111,
              "speed2d": 1.1143333333333336,
              "speed3d": 1.15,
              "msFromStart": 20751.992526315807
            },
            {
              "lat": 43.76368907777778,
              "lng": -116.09607803333333,
              "alt": 1930.8772222222224,
              "speed2d": 1.0114444444444444,
              "speed3d": 1.0244444444444445,
              "msFromStart": 21247.55418908383
            },
            {
              "lat": 43.76368504444444,
              "lng": -116.09607725555556,
              "alt": 1930.8269999999998,
              "speed2d": 0.8263333333333334,
              "speed3d": 0.8477777777777777,
              "msFromStart": 21742.241222222226
            },
            {
              "lat": 43.763680244444444,
              "lng": -116.0960797111111,
              "alt": 1930.5484444444446,
              "speed2d": 0.8093333333333332,
              "speed3d": 0.8033333333333332,
              "msFromStart": 22236.860703703704
            },
            {
              "lat": 43.763674477777776,
              "lng": -116.09608076666666,
              "alt": 1930.2787777777776,
              "speed2d": 0.9003333333333334,
              "speed3d": 0.888888888888889,
              "msFromStart": 22731.42177777777
            },
            {
              "lat": 43.76366871111111,
              "lng": -116.09607812222221,
              "alt": 1930.802,
              "speed2d": 0.9191111111111112,
              "speed3d": 0.9322222222222223,
              "msFromStart": 23226.15425925926
            },
            {
              "lat": 43.76366355,
              "lng": -116.09607765000001,
              "alt": 1930.9638000000002,
              "speed2d": 0.8665000000000002,
              "speed3d": 0.8659999999999998,
              "msFromStart": 23748.873166666686
            },
            {
              "lat": 43.763656277777784,
              "lng": -116.09607352222221,
              "alt": 1930.8905555555555,
              "speed2d": 0.9687777777777775,
              "speed3d": 0.9644444444444443,
              "msFromStart": 24271.87522222223
            },
            {
              "lat": 43.76365035555556,
              "lng": -116.09606758888889,
              "alt": 1931.362111111111,
              "speed2d": 1.133888888888889,
              "speed3d": 1.1144444444444443,
              "msFromStart": 24767.844000000005
            },
            {
              "lat": 43.763644422222214,
              "lng": -116.0960629888889,
              "alt": 1931.7482222222225,
              "speed2d": 1.249222222222222,
              "speed3d": 1.2355555555555555,
              "msFromStart": 25263.336230019493
            },
            {
              "lat": 43.763636444444444,
              "lng": -116.09606217777777,
              "alt": 1931.7903333333336,
              "speed2d": 1.4187777777777777,
              "speed3d": 1.4155555555555555,
              "msFromStart": 25757.926315789464
            },
            {
              "lat": 43.763629055555555,
              "lng": -116.09606026666665,
              "alt": 1931.9622222222224,
              "speed2d": 1.424,
              "speed3d": 1.4288888888888889,
              "msFromStart": 26252.92119493177
            },
            {
              "lat": 43.76362204444444,
              "lng": -116.09605729999998,
              "alt": 1932.261888888889,
              "speed2d": 1.2977777777777777,
              "speed3d": 1.3299999999999998,
              "msFromStart": 26749.061888888886
            },
            {
              "lat": 43.76361615555555,
              "lng": -116.09605494444445,
              "alt": 1932.4733333333336,
              "speed2d": 1.2134444444444445,
              "speed3d": 1.192222222222222,
              "msFromStart": 27244.735296296294
            },
            {
              "lat": 43.76360787777779,
              "lng": -116.09605119999998,
              "alt": 1932.5496666666668,
              "speed2d": 1.3735555555555556,
              "speed3d": 1.3933333333333333,
              "msFromStart": 27738.976666666662
            },
            {
              "lat": 43.76360242222223,
              "lng": -116.09604907777779,
              "alt": 1932.5467777777776,
              "speed2d": 1.2714444444444446,
              "speed3d": 1.2944444444444443,
              "msFromStart": 28233.668185185183
            },
            {
              "lat": 43.763597922222225,
              "lng": -116.09604783333334,
              "alt": 1932.5042222222223,
              "speed2d": 1.127888888888889,
              "speed3d": 1.14,
              "msFromStart": 28729.786111111112
            },
            {
              "lat": 43.76359191111112,
              "lng": -116.09604847777777,
              "alt": 1932.514777777778,
              "speed2d": 1.157,
              "speed3d": 1.1533333333333333,
              "msFromStart": 29225.564814814818
            },
            {
              "lat": 43.763584679999994,
              "lng": -116.09604703,
              "alt": 1932.5438,
              "speed2d": 1.2264,
              "speed3d": 1.229,
              "msFromStart": 29747.69650000002
            },
            {
              "lat": 43.763578833333334,
              "lng": -116.09604327777778,
              "alt": 1933.0432222222223,
              "speed2d": 1.157333333333333,
              "speed3d": 1.1833333333333333,
              "msFromStart": 30269.752789473692
            },
            {
              "lat": 43.76357457777778,
              "lng": -116.09604213333333,
              "alt": 1933.3020000000001,
              "speed2d": 1.0337777777777777,
              "speed3d": 1.0433333333333334,
              "msFromStart": 30764.30147368422
            },
            {
              "lat": 43.76357112222222,
              "lng": -116.0960418777778,
              "alt": 1933.3424444444447,
              "speed2d": 0.8574444444444445,
              "speed3d": 0.8933333333333333,
              "msFromStart": 31258.943662768033
            },
            {
              "lat": 43.763565500000006,
              "lng": -116.09603887777777,
              "alt": 1933.5123333333336,
              "speed2d": 0.9874444444444446,
              "speed3d": 0.9577777777777778,
              "msFromStart": 31753.84122222221
            },
            {
              "lat": 43.76355833333333,
              "lng": -116.09603522222223,
              "alt": 1934.101333333333,
              "speed2d": 1.2362222222222221,
              "speed3d": 1.25,
              "msFromStart": 32248.511629629622
            },
            {
              "lat": 43.76355353333334,
              "lng": -116.09603513333332,
              "alt": 1934.1613333333335,
              "speed2d": 1.08,
              "speed3d": 1.1155555555555556,
              "msFromStart": 32742.528999999995
            },
            {
              "lat": 43.763548911111116,
              "lng": -116.09603422222222,
              "alt": 1934.4245555555553,
              "speed2d": 0.9558888888888888,
              "speed3d": 0.9922222222222221,
              "msFromStart": 33236.90918518519
            },
            {
              "lat": 43.76354322222222,
              "lng": -116.09602743333333,
              "alt": 1935.1713333333332,
              "speed2d": 1.1358888888888892,
              "speed3d": 1.1022222222222222,
              "msFromStart": 33732.36744444448
            },
            {
              "lat": 43.76353487777777,
              "lng": -116.09602256666666,
              "alt": 1935.2494444444444,
              "speed2d": 1.3871111111111112,
              "speed3d": 1.4255555555555555,
              "msFromStart": 34227.9998888889
            },
            {
              "lat": 43.76352647,
              "lng": -116.0960218,
              "alt": 1934.9195000000002,
              "speed2d": 1.4137999999999997,
              "speed3d": 1.377,
              "msFromStart": 34751.504888888914
            },
            {
              "lat": 43.76351362222222,
              "lng": -116.09601778888889,
              "alt": 1933.8785555555555,
              "speed2d": 1.7414444444444448,
              "speed3d": 1.7366666666666666,
              "msFromStart": 35274.65955555556
            },
            {
              "lat": 43.76350668888889,
              "lng": -116.09601622222222,
              "alt": 1933.4217777777778,
              "speed2d": 1.4657777777777778,
              "speed3d": 1.5111111111111113,
              "msFromStart": 35769.57866666667
            },
            {
              "lat": 43.76350237777777,
              "lng": -116.0960155,
              "alt": 1933.8948888888888,
              "speed2d": 1.1998888888888888,
              "speed3d": 1.22,
              "msFromStart": 36264.2178388564
            },
            {
              "lat": 43.76349712222222,
              "lng": -116.09601372222222,
              "alt": 1934.4325555555556,
              "speed2d": 1.1722222222222223,
              "speed3d": 1.1688888888888889,
              "msFromStart": 36758.3614736842
            },
            {
              "lat": 43.76349258888888,
              "lng": -116.09601152222223,
              "alt": 1935.1724444444446,
              "speed2d": 1.0683333333333334,
              "speed3d": 1.1044444444444443,
              "msFromStart": 37252.97984795322
            },
            {
              "lat": 43.76348748888889,
              "lng": -116.09600852222223,
              "alt": 1935.6925555555556,
              "speed2d": 1.1157777777777778,
              "speed3d": 1.1099999999999999,
              "msFromStart": 37748.91266666668
            },
            {
              "lat": 43.76348218888889,
              "lng": -116.09600593333333,
              "alt": 1936.5031111111111,
              "speed2d": 1.1554444444444445,
              "speed3d": 1.1911111111111112,
              "msFromStart": 38244.53303703705
            },
            {
              "lat": 43.76347788888889,
              "lng": -116.09600584444448,
              "alt": 1937.1273333333336,
              "speed2d": 1.0637777777777777,
              "speed3d": 1.132222222222222,
              "msFromStart": 38739.11755555558
            },
            {
              "lat": 43.76347297777778,
              "lng": -116.09600402222223,
              "alt": 1937.5306666666665,
              "speed2d": 1.0361111111111112,
              "speed3d": 1.0633333333333335,
              "msFromStart": 39234.099703703716
            },
            {
              "lat": 43.76346756666666,
              "lng": -116.09600025555557,
              "alt": 1938.350555555556,
              "speed2d": 1.1303333333333334,
              "speed3d": 1.2077777777777778,
              "msFromStart": 39730.30911111113
            },
            {
              "lat": 43.763463788888885,
              "lng": -116.09599898888891,
              "alt": 1938.5066666666667,
              "speed2d": 0.9600000000000001,
              "speed3d": 1.0377777777777777,
              "msFromStart": 40226.342218323596
            },
            {
              "lat": 43.7634579,
              "lng": -116.095995,
              "alt": 1939.1927,
              "speed2d": 1.1249,
              "speed3d": 1.152,
              "msFromStart": 40749.24063157898
            },
            {
              "lat": 43.76345126666667,
              "lng": -116.09599146666667,
              "alt": 1939.6314444444445,
              "speed2d": 1.1436666666666666,
              "speed3d": 1.2422222222222223,
              "msFromStart": 41272.149005847954
            },
            {
              "lat": 43.76344546666667,
              "lng": -116.09599018888889,
              "alt": 1939.7408888888888,
              "speed2d": 1.0317777777777777,
              "speed3d": 1.0877777777777777,
              "msFromStart": 41767.65866666663
            },
            {
              "lat": 43.763441199999995,
              "lng": -116.09598893333335,
              "alt": 1940.7646666666665,
              "speed2d": 0.9186666666666667,
              "speed3d": 1.0466666666666666,
              "msFromStart": 42262.9434074074
            },
            {
              "lat": 43.763438666666666,
              "lng": -116.09598693333332,
              "alt": 1941.7873333333332,
              "speed2d": 0.6786666666666666,
              "speed3d": 0.8688888888888888,
              "msFromStart": 42757.600444444455
            },
            {
              "lat": 43.76343413333334,
              "lng": -116.0959866111111,
              "alt": 1942.2496666666666,
              "speed2d": 0.7755555555555554,
              "speed3d": 0.8966666666666666,
              "msFromStart": 43252.33903703705
            },
            {
              "lat": 43.76342756666667,
              "lng": -116.09598826666667,
              "alt": 1943.2966666666666,
              "speed2d": 1.196111111111111,
              "speed3d": 1.2944444444444443,
              "msFromStart": 43747.38866666668
            },
            {
              "lat": 43.763421622222225,
              "lng": -116.09598749999999,
              "alt": 1944.7135555555553,
              "speed2d": 1.301777777777778,
              "speed3d": 1.4566666666666666,
              "msFromStart": 44242.36496296298
            },
            {
              "lat": 43.76341458888889,
              "lng": -116.09598630000002,
              "alt": 1945.5810000000001,
              "speed2d": 1.4235555555555557,
              "speed3d": 1.5522222222222222,
              "msFromStart": 44737.09777777782
            },
            {
              "lat": 43.763407711111114,
              "lng": -116.09598492222221,
              "alt": 1946.3267777777778,
              "speed2d": 1.394888888888889,
              "speed3d": 1.6244444444444446,
              "msFromStart": 45231.869111111126
            },
            {
              "lat": 43.763406455555554,
              "lng": -116.09598439999999,
              "alt": 1946.769888888889,
              "speed2d": 0.8,
              "speed3d": 1.108888888888889,
              "msFromStart": 45726.77977777778
            },
            {
              "lat": 43.76340402000001,
              "lng": -116.09598633,
              "alt": 1946.4587,
              "speed2d": 0.48079999999999995,
              "speed3d": 0.8079999999999997,
              "msFromStart": 46249.51995438596
            },
            {
              "lat": 43.763400000000004,
              "lng": -116.0959882111111,
              "alt": 1946.013,
              "speed2d": 0.48477777777777775,
              "speed3d": 0.7077777777777778,
              "msFromStart": 46773.00694736843
            },
            {
              "lat": 43.76339504444444,
              "lng": -116.09598821111112,
              "alt": 1946.0525555555555,
              "speed2d": 0.5842222222222222,
              "speed3d": 0.7544444444444445,
              "msFromStart": 47268.881769980515
            },
            {
              "lat": 43.76339296666667,
              "lng": -116.09599031111111,
              "alt": 1946.656111111111,
              "speed2d": 0.6038888888888888,
              "speed3d": 0.7799999999999999,
              "msFromStart": 47764.50311111113
            },
            {
              "lat": 43.76339226666667,
              "lng": -116.09599404444445,
              "alt": 1947.0157777777777,
              "speed2d": 0.5522222222222223,
              "speed3d": 0.7444444444444445,
              "msFromStart": 48259.969037037044
            },
            {
              "lat": 43.76338865555556,
              "lng": -116.09599557777777,
              "alt": 1947.5681111111112,
              "speed2d": 0.6652222222222223,
              "speed3d": 0.8200000000000001,
              "msFromStart": 48755.04933333335
            },
            {
              "lat": 43.76338158888889,
              "lng": -116.09599761111113,
              "alt": 1947.6677777777777,
              "speed2d": 1.0444444444444445,
              "speed3d": 1.1111111111111112,
              "msFromStart": 49249.89985185185
            },
            {
              "lat": 43.76337414444444,
              "lng": -116.09599827777778,
              "alt": 1947.9208888888888,
              "speed2d": 1.300888888888889,
              "speed3d": 1.3944444444444446,
              "msFromStart": 49744.18177777777
            },
            {
              "lat": 43.76336772222222,
              "lng": -116.09599608888888,
              "alt": 1948.235888888889,
              "speed2d": 1.329,
              "speed3d": 1.3688888888888888,
              "msFromStart": 50238.56973879143
            },
            {
              "lat": 43.76335682222222,
              "lng": -116.09599684444443,
              "alt": 1948.7268888888889,
              "speed2d": 1.8178888888888887,
              "speed3d": 1.8211111111111111,
              "msFromStart": 50733.326736842144
            },
            {
              "lat": 43.763350933333335,
              "lng": -116.09599748888888,
              "alt": 1949.3934444444444,
              "speed2d": 1.6322222222222222,
              "speed3d": 1.7433333333333332,
              "msFromStart": 51228.00085769982
            },
            {
              "lat": 43.7633441,
              "lng": -116.09599929999999,
              "alt": 1949.7033,
              "speed2d": 1.5673,
              "speed3d": 1.621,
              "msFromStart": 51749.72266666668
            },
            {
              "lat": 43.7633354,
              "lng": -116.09599895555556,
              "alt": 1950.217111111111,
              "speed2d": 1.7474444444444446,
              "speed3d": 1.79,
              "msFromStart": 52271.7742962963
            },
            {
              "lat": 43.76332583333333,
              "lng": -116.09599838888887,
              "alt": 1950.4968888888889,
              "speed2d": 1.9124444444444448,
              "speed3d": 1.988888888888889,
              "msFromStart": 52767.37533333334
            },
            {
              "lat": 43.76331436666667,
              "lng": -116.0959979,
              "alt": 1950.3093333333331,
              "speed2d": 1.9727777777777777,
              "speed3d": 2.042222222222222,
              "msFromStart": 53263.06770370371
            },
            {
              "lat": 43.76330540000001,
              "lng": -116.09599537777778,
              "alt": 1950.7233333333334,
              "speed2d": 1.7492222222222222,
              "speed3d": 1.8955555555555557,
              "msFromStart": 53758.864222222226
            },
            {
              "lat": 43.76329533333333,
              "lng": -116.09599201111112,
              "alt": 1950.8295555555553,
              "speed2d": 1.8123333333333331,
              "speed3d": 1.99,
              "msFromStart": 54254.402296296306
            },
            {
              "lat": 43.76328576666667,
              "lng": -116.0959909222222,
              "alt": 1950.8542222222225,
              "speed2d": 1.76,
              "speed3d": 1.9666666666666668,
              "msFromStart": 54749.22400000003
            },
            {
              "lat": 43.76327574444444,
              "lng": -116.09598954444446,
              "alt": 1950.9813333333332,
              "speed2d": 1.8304444444444443,
              "speed3d": 2.0055555555555555,
              "msFromStart": 55244.18484210527
            },
            {
              "lat": 43.76326366666666,
              "lng": -116.09598758888887,
              "alt": 1951.4112222222222,
              "speed2d": 2.1086666666666667,
              "speed3d": 2.2866666666666666,
              "msFromStart": 55739.62568421052
            },
            {
              "lat": 43.76325188888889,
              "lng": -116.09598658888889,
              "alt": 1951.3053333333335,
              "speed2d": 2.0878888888888887,
              "speed3d": 2.256666666666667,
              "msFromStart": 56235.134362573095
            },
            {
              "lat": 43.763239866666666,
              "lng": -116.09598533333335,
              "alt": 1951.4752222222219,
              "speed2d": 2.0162222222222224,
              "speed3d": 2.1655555555555557,
              "msFromStart": 56730.81333333335
            },
            {
              "lat": 43.763227155555555,
              "lng": -116.09598314444445,
              "alt": 1951.754888888889,
              "speed2d": 2.086888888888889,
              "speed3d": 2.2744444444444447,
              "msFromStart": 57226.16600000002
            },
            {
              "lat": 43.76321718,
              "lng": -116.09598009000001,
              "alt": 1952.6901999999998,
              "speed2d": 1.8268000000000002,
              "speed3d": 2.091,
              "msFromStart": 57747.581000000035
            },
            {
              "lat": 43.76320966666667,
              "lng": -116.09597343333334,
              "alt": 1954.0828888888886,
              "speed2d": 1.6202222222222225,
              "speed3d": 1.9733333333333334,
              "msFromStart": 58269.11792592594
            },
            {
              "lat": 43.763202755555554,
              "lng": -116.0959665,
              "alt": 1955.610222222222,
              "speed2d": 1.605777777777778,
              "speed3d": 2.0444444444444443,
              "msFromStart": 58763.922888888905
            },
            {
              "lat": 43.763194822222225,
              "lng": -116.09596376666666,
              "alt": 1956.2491111111112,
              "speed2d": 1.462111111111111,
              "speed3d": 1.9566666666666666,
              "msFromStart": 59258.962444444434
            },
            {
              "lat": 43.76318437777777,
              "lng": -116.09596126666665,
              "alt": 1956.8784444444445,
              "speed2d": 1.7117777777777778,
              "speed3d": 2.121111111111111,
              "msFromStart": 59754.53822222218
            },
            {
              "lat": 43.76317498888889,
              "lng": -116.09595694444445,
              "alt": 1957.9405555555554,
              "speed2d": 1.7518888888888888,
              "speed3d": 2.2111111111111112,
              "msFromStart": 60249.96696296295
            },
            {
              "lat": 43.76316764444445,
              "lng": -116.09595223333332,
              "alt": 1959.634777777778,
              "speed2d": 1.689111111111111,
              "speed3d": 2.252222222222222,
              "msFromStart": 60744.919999999984
            },
            {
              "lat": 43.76316143333334,
              "lng": -116.09594442222223,
              "alt": 1961.3783333333333,
              "speed2d": 1.6112222222222221,
              "speed3d": 2.305555555555556,
              "msFromStart": 61239.79445614034
            },
            {
              "lat": 43.763155222222224,
              "lng": -116.09593667777779,
              "alt": 1963.4655555555555,
              "speed2d": 1.5494444444444448,
              "speed3d": 2.4011111111111108,
              "msFromStart": 61734.52357894732
            },
            {
              "lat": 43.763153711111116,
              "lng": -116.09593211111111,
              "alt": 1965.5463333333332,
              "speed2d": 0.9799999999999999,
              "speed3d": 2.223333333333333,
              "msFromStart": 62229.61959454189
            },
            {
              "lat": 43.763149922222226,
              "lng": -116.09592572222222,
              "alt": 1967.8248888888886,
              "speed2d": 1.0513333333333335,
              "speed3d": 2.3444444444444446,
              "msFromStart": 62726.213777777775
            },
            {
              "lat": 43.76314177,
              "lng": -116.095916,
              "alt": 1970.6267000000003,
              "speed2d": 1.4244999999999999,
              "speed3d": 2.725,
              "msFromStart": 63250.01426666668
            },
            {
              "lat": 43.76313543333333,
              "lng": -116.09590844444445,
              "alt": 1972.9337777777778,
              "speed2d": 1.385,
              "speed3d": 2.8277777777777775,
              "msFromStart": 63772.06488888888
            },
            {
              "lat": 43.76312667777778,
              "lng": -116.09590145555553,
              "alt": 1974.972111111111,
              "speed2d": 1.584,
              "speed3d": 3.0111111111111106,
              "msFromStart": 64266.768740740736
            },
            {
              "lat": 43.76312063333334,
              "lng": -116.0958929,
              "alt": 1977.628333333333,
              "speed2d": 1.7075555555555557,
              "speed3d": 3.227777777777778,
              "msFromStart": 64762.221333333306
            },
            {
              "lat": 43.76311547777778,
              "lng": -116.09588446666666,
              "alt": 1980.0642222222223,
              "speed2d": 1.6078888888888891,
              "speed3d": 3.312222222222222,
              "msFromStart": 65257.165777777765
            },
            {
              "lat": 43.76310856666666,
              "lng": -116.09587651111111,
              "alt": 1981.977111111111,
              "speed2d": 1.6391111111111107,
              "speed3d": 3.366666666666667,
              "msFromStart": 65750.62400000001
            },
            {
              "lat": 43.76309921111111,
              "lng": -116.09586908888889,
              "alt": 1983.1219999999998,
              "speed2d": 1.7684444444444445,
              "speed3d": 3.4144444444444444,
              "msFromStart": 66244.15955555557
            },
            {
              "lat": 43.76309255555556,
              "lng": -116.095863,
              "alt": 1984.5095555555556,
              "speed2d": 1.5317777777777781,
              "speed3d": 3.2888888888888888,
              "msFromStart": 66738.31733333331
            },
            {
              "lat": 43.763083377777775,
              "lng": -116.0958602888889,
              "alt": 1985.8093333333338,
              "speed2d": 1.5174444444444446,
              "speed3d": 3.2444444444444445,
              "msFromStart": 67232.82770370368
            },
            {
              "lat": 43.76307143333334,
              "lng": -116.09585574444445,
              "alt": 1986.5853333333332,
              "speed2d": 1.778111111111111,
              "speed3d": 3.32,
              "msFromStart": 67728.49955555554
            },
            {
              "lat": 43.76305912,
              "lng": -116.09585299,
              "alt": 1987.2983,
              "speed2d": 1.8624,
              "speed3d": 3.3150000000000004,
              "msFromStart": 68251.69226666667
            },
            {
              "lat": 43.76304601111111,
              "lng": -116.09585145555556,
              "alt": 1987.6934444444441,
              "speed2d": 1.9441111111111113,
              "speed3d": 3.278888888888889,
              "msFromStart": 68774.43466666671
            },
            {
              "lat": 43.763034266666665,
              "lng": -116.09584890000001,
              "alt": 1988.5274444444447,
              "speed2d": 1.9651111111111115,
              "speed3d": 3.2622222222222224,
              "msFromStart": 69269.6671111111
            },
            {
              "lat": 43.76302342222222,
              "lng": -116.09584798888889,
              "alt": 1989.1753333333336,
              "speed2d": 1.8538888888888891,
              "speed3d": 3.1755555555555555,
              "msFromStart": 69765.00933333326
            },
            {
              "lat": 43.7630146,
              "lng": -116.0958449888889,
              "alt": 1990.1526666666666,
              "speed2d": 1.7154444444444443,
              "speed3d": 3.061111111111111,
              "msFromStart": 70260.41437037036
            },
            {
              "lat": 43.76300432222222,
              "lng": -116.09584233333334,
              "alt": 1990.8923333333332,
              "speed2d": 1.7967777777777776,
              "speed3d": 3.0933333333333333,
              "msFromStart": 70755.97422222226
            },
            {
              "lat": 43.7629954,
              "lng": -116.09584146666666,
              "alt": 1991.3052222222223,
              "speed2d": 1.6340000000000001,
              "speed3d": 2.9255555555555555,
              "msFromStart": 71251.14050682262
            },
            {
              "lat": 43.76298425555556,
              "lng": -116.0958398111111,
              "alt": 1991.6142222222222,
              "speed2d": 1.7994444444444444,
              "speed3d": 2.9411111111111112,
              "msFromStart": 71745.2164210526
            },
            {
              "lat": 43.76297376666666,
              "lng": -116.09583648888889,
              "alt": 1991.7584444444444,
              "speed2d": 1.7495555555555558,
              "speed3d": 2.866666666666667,
              "msFromStart": 72239.50929434696
            },
            {
              "lat": 43.76296038888889,
              "lng": -116.09583286666667,
              "alt": 1991.412888888889,
              "speed2d": 2.008666666666667,
              "speed3d": 2.867777777777778,
              "msFromStart": 72734.8995555556
            },
            {
              "lat": 43.76294517777777,
              "lng": -116.09582801111111,
              "alt": 1991.3249999999998,
              "speed2d": 2.373666666666667,
              "speed3d": 3.0944444444444446,
              "msFromStart": 73230.38770370373
            },
            {
              "lat": 43.76293292222221,
              "lng": -116.09582628888889,
              "alt": 1991.2956666666666,
              "speed2d": 2.2432222222222222,
              "speed3d": 2.9555555555555557,
              "msFromStart": 73725.82844444444
            },
            {
              "lat": 43.762919890000006,
              "lng": -116.09582426000001,
              "alt": 1991.0407999999998,
              "speed2d": 2.128,
              "speed3d": 2.787,
              "msFromStart": 74248.41533333334
            },
            {
              "lat": 43.76290572222223,
              "lng": -116.09582261111109,
              "alt": 1990.8494444444445,
              "speed2d": 2.3001111111111117,
              "speed3d": 2.8400000000000003,
              "msFromStart": 74769.79333333336
            },
            {
              "lat": 43.762891188888894,
              "lng": -116.09582045555555,
              "alt": 1990.3655555555556,
              "speed2d": 2.353888888888889,
              "speed3d": 2.8233333333333333,
              "msFromStart": 75264.11188304095
            },
            {
              "lat": 43.762876988888884,
              "lng": -116.09581723333334,
              "alt": 1990.061666666667,
              "speed2d": 2.356666666666667,
              "speed3d": 2.7744444444444443,
              "msFromStart": 75759.74021052636
            },
            {
              "lat": 43.762863855555565,
              "lng": -116.09581382222223,
              "alt": 1989.9061111111112,
              "speed2d": 2.401,
              "speed3d": 2.7422222222222223,
              "msFromStart": 76255.30612865501
            },
            {
              "lat": 43.762849322222216,
              "lng": -116.09580993333333,
              "alt": 1989.5572222222222,
              "speed2d": 2.5608888888888885,
              "speed3d": 2.8655555555555554,
              "msFromStart": 76750.34666666668
            },
            {
              "lat": 43.76283335555556,
              "lng": -116.09580784444444,
              "alt": 1988.862111111111,
              "speed2d": 2.579555555555556,
              "speed3d": 2.7977777777777777,
              "msFromStart": 77245.0428148148
            },
            {
              "lat": 43.762819155555555,
              "lng": -116.09580557777777,
              "alt": 1988.4662222222223,
              "speed2d": 2.541666666666667,
              "speed3d": 2.761111111111111,
              "msFromStart": 77738.57155555557
            },
            {
              "lat": 43.76280415555555,
              "lng": -116.09580264444443,
              "alt": 1987.8012222222223,
              "speed2d": 2.630222222222222,
              "speed3d": 2.786666666666666,
              "msFromStart": 78232.3020740741
            },
            {
              "lat": 43.76279212222222,
              "lng": -116.09580062222221,
              "alt": 1987.933777777778,
              "speed2d": 2.4806666666666666,
              "speed3d": 2.6699999999999995,
              "msFromStart": 78727.3066666667
            },
            {
              "lat": 43.76278120999999,
              "lng": -116.09579712000001,
              "alt": 1988.3156999999999,
              "speed2d": 2.2888,
              "speed3d": 2.4700000000000006,
              "msFromStart": 79249.96280000002
            },
            {
              "lat": 43.76276808888889,
              "lng": -116.09579483333331,
              "alt": 1988.2017777777776,
              "speed2d": 2.4286666666666665,
              "speed3d": 2.5455555555555556,
              "msFromStart": 79772.6733333334
            },
            {
              "lat": 43.762754099999995,
              "lng": -116.09579367777778,
              "alt": 1988.0137777777775,
              "speed2d": 2.612777777777777,
              "speed3d": 2.7255555555555557,
              "msFromStart": 80268.01111111113
            },
            {
              "lat": 43.76274147777778,
              "lng": -116.09579164444443,
              "alt": 1988.1763333333333,
              "speed2d": 2.6213333333333333,
              "speed3d": 2.7311111111111113,
              "msFromStart": 80763.71200000001
            },
            {
              "lat": 43.762727,
              "lng": -116.09579065555555,
              "alt": 1988.302222222222,
              "speed2d": 2.9118888888888885,
              "speed3d": 3.0011111111111113,
              "msFromStart": 81259.2297777778
            },
            {
              "lat": 43.76271432222222,
              "lng": -116.09579005555555,
              "alt": 1987.9011111111113,
              "speed2d": 2.595888888888889,
              "speed3d": 2.744444444444444,
              "msFromStart": 81753.93866666671
            },
            {
              "lat": 43.7627011,
              "lng": -116.09579034444444,
              "alt": 1987.2672222222222,
              "speed2d": 2.4455555555555555,
              "speed3d": 2.5277777777777777,
              "msFromStart": 82248.49481481484
            },
            {
              "lat": 43.76268903333334,
              "lng": -116.09578908888889,
              "alt": 1986.8846666666666,
              "speed2d": 2.363,
              "speed3d": 2.4277777777777776,
              "msFromStart": 82742.7635555556
            },
            {
              "lat": 43.76267492222222,
              "lng": -116.09578577777776,
              "alt": 1986.483888888889,
              "speed2d": 2.635,
              "speed3d": 2.6533333333333333,
              "msFromStart": 83237.4672592593
            },
            {
              "lat": 43.76266283333333,
              "lng": -116.09578427777778,
              "alt": 1986.4977777777776,
              "speed2d": 2.578777777777778,
              "speed3d": 2.644444444444445,
              "msFromStart": 83734.02577777779
            },
            {
              "lat": 43.76265102222222,
              "lng": -116.09578236666667,
              "alt": 1986.5462222222222,
              "speed2d": 2.464777777777778,
              "speed3d": 2.506666666666667,
              "msFromStart": 84230.40903703702
            },
            {
              "lat": 43.76263853333333,
              "lng": -116.0957835222222,
              "alt": 1986.4143333333336,
              "speed2d": 2.4213333333333336,
              "speed3d": 2.4966666666666666,
              "msFromStart": 84725.34933333329
            },
            {
              "lat": 43.762628080000006,
              "lng": -116.09578545000001,
              "alt": 1986.5294000000001,
              "speed2d": 2.2437,
              "speed3d": 2.266,
              "msFromStart": 85247.79566315789
            },
            {
              "lat": 43.762615133333334,
              "lng": -116.09578481111112,
              "alt": 1986.7797777777778,
              "speed2d": 2.474111111111111,
              "speed3d": 2.4988888888888887,
              "msFromStart": 85770.7654736842
            },
            {
              "lat": 43.76260087777778,
              "lng": -116.09578275555556,
              "alt": 1986.5955555555554,
              "speed2d": 2.715222222222222,
              "speed3d": 2.7555555555555555,
              "msFromStart": 86266.20823391812
            },
            {
              "lat": 43.762589955555555,
              "lng": -116.09578197777779,
              "alt": 1986.714888888889,
              "speed2d": 2.485333333333333,
              "speed3d": 2.533333333333333,
              "msFromStart": 86761.46666666672
            },
            {
              "lat": 43.76257790000001,
              "lng": -116.09578035555555,
              "alt": 1986.4935555555555,
              "speed2d": 2.4714444444444443,
              "speed3d": 2.5122222222222224,
              "msFromStart": 87256.62074074076
            },
            {
              "lat": 43.762565877777774,
              "lng": -116.09577802222222,
              "alt": 1986.217222222222,
              "speed2d": 2.4828888888888887,
              "speed3d": 2.4866666666666664,
              "msFromStart": 87751.43111111112
            },
            {
              "lat": 43.762554,
              "lng": -116.09577771111111,
              "alt": 1986.0357777777776,
              "speed2d": 2.4121111111111113,
              "speed3d": 2.4766666666666666,
              "msFromStart": 88245.95007407406
            },
            {
              "lat": 43.76254244444444,
              "lng": -116.09577774444443,
              "alt": 1986.0184444444444,
              "speed2d": 2.4320000000000004,
              "speed3d": 2.428888888888889,
              "msFromStart": 88739.4408888889
            },
            {
              "lat": 43.762530100000006,
              "lng": -116.09577726666666,
              "alt": 1985.4440000000002,
              "speed2d": 2.3956666666666666,
              "speed3d": 2.423333333333334,
              "msFromStart": 89233.41481481481
            },
            {
              "lat": 43.76251776666667,
              "lng": -116.09577797777779,
              "alt": 1985.2037777777778,
              "speed2d": 2.464888888888889,
              "speed3d": 2.467777777777778,
              "msFromStart": 89729.73244444441
            },
            {
              "lat": 43.762504822222226,
              "lng": -116.09577882222221,
              "alt": 1984.6877777777777,
              "speed2d": 2.5352222222222225,
              "speed3d": 2.5477777777777777,
              "msFromStart": 90226.02580896684
            },
            {
              "lat": 43.76249219000001,
              "lng": -116.09577881000001,
              "alt": 1984.3927,
              "speed2d": 2.5543000000000005,
              "speed3d": 2.524,
              "msFromStart": 90748.79326315786
            },
            {
              "lat": 43.76247728888888,
              "lng": -116.09577871111108,
              "alt": 1983.9466666666665,
              "speed2d": 2.6727777777777777,
              "speed3d": 2.6900000000000004,
              "msFromStart": 91271.61071345025
            },
            {
              "lat": 43.76246444444445,
              "lng": -116.09577792222224,
              "alt": 1983.199111111111,
              "speed2d": 2.4928888888888885,
              "speed3d": 2.5344444444444445,
              "msFromStart": 91767.53333333325
            },
            {
              "lat": 43.76245401111111,
              "lng": -116.09577716666665,
              "alt": 1982.6016666666667,
              "speed2d": 2.2224444444444442,
              "speed3d": 2.2188888888888894,
              "msFromStart": 92263.30918518513
            },
            {
              "lat": 43.76244311111111,
              "lng": -116.09577922222223,
              "alt": 1982.246111111111,
              "speed2d": 2.215222222222222,
              "speed3d": 2.2322222222222226,
              "msFromStart": 92758.25244444446
            },
            {
              "lat": 43.762434733333336,
              "lng": -116.09577911111111,
              "alt": 1981.8842222222224,
              "speed2d": 1.8605555555555555,
              "speed3d": 1.9155555555555555,
              "msFromStart": 93252.93392592594
            },
            {
              "lat": 43.76242957777778,
              "lng": -116.09577624444444,
              "alt": 1981.8963333333336,
              "speed2d": 1.4056666666666666,
              "speed3d": 1.4533333333333331,
              "msFromStart": 93746.90133333337
            },
            {
              "lat": 43.762424422222225,
              "lng": -116.09577525555557,
              "alt": 1981.9385555555555,
              "speed2d": 1.1924444444444444,
              "speed3d": 1.2166666666666668,
              "msFromStart": 94240.6891851852
            },
            {
              "lat": 43.76242061111111,
              "lng": -116.09577214444444,
              "alt": 1982.3679999999997,
              "speed2d": 1.1293333333333333,
              "speed3d": 1.1111111111111112,
              "msFromStart": 94734.0444444444
            },
            {
              "lat": 43.76241489999999,
              "lng": -116.09576926666666,
              "alt": 1982.2481111111113,
              "speed2d": 1.1525555555555556,
              "speed3d": 1.1722222222222223,
              "msFromStart": 95228.13079142295
            },
            {
              "lat": 43.76240813333333,
              "lng": -116.09576743333334,
              "alt": 1982.5577777777778,
              "speed2d": 1.404,
              "speed3d": 1.3388888888888888,
              "msFromStart": 95725.31452631573
            },
            {
              "lat": 43.76240155000001,
              "lng": -116.09576787,
              "alt": 1983.4759999999999,
              "speed2d": 1.5097000000000003,
              "speed3d": 1.5550000000000002,
              "msFromStart": 96250.05694736839
            },
            {
              "lat": 43.762398588888885,
              "lng": -116.09576569999999,
              "alt": 1984.7536666666665,
              "speed2d": 1.298111111111111,
              "speed3d": 1.3133333333333335,
              "msFromStart": 96772.98133333339
            },
            {
              "lat": 43.7623944,
              "lng": -116.09576366666667,
              "alt": 1985.8103333333333,
              "speed2d": 1.278111111111111,
              "speed3d": 1.34,
              "msFromStart": 97268.05288888892
            },
            {
              "lat": 43.76239093333333,
              "lng": -116.09576415555557,
              "alt": 1985.7683333333334,
              "speed2d": 0.8217777777777777,
              "speed3d": 0.9066666666666667,
              "msFromStart": 97762.78666666674
            },
            {
              "lat": 43.762388844444445,
              "lng": -116.09576538888888,
              "alt": 1986.161111111111,
              "speed2d": 0.6328888888888888,
              "speed3d": 0.6988888888888889,
              "msFromStart": 98257.49111111113
            },
            {
              "lat": 43.762385888888886,
              "lng": -116.09576850000002,
              "alt": 1986.371555555556,
              "speed2d": 0.7502222222222221,
              "speed3d": 0.7822222222222223,
              "msFromStart": 98752.21333333326
            },
            {
              "lat": 43.76238242222223,
              "lng": -116.09577007777777,
              "alt": 1986.2253333333333,
              "speed2d": 0.7512222222222222,
              "speed3d": 0.7733333333333334,
              "msFromStart": 99247.08103703702
            },
            {
              "lat": 43.76237657777777,
              "lng": -116.09576822222223,
              "alt": 1986.2255555555557,
              "speed2d": 1.0244444444444445,
              "speed3d": 1.0066666666666668,
              "msFromStart": 99742.52355555561
            },
            {
              "lat": 43.762370944444456,
              "lng": -116.09576774444444,
              "alt": 1986.4195555555555,
              "speed2d": 1.1644444444444444,
              "speed3d": 1.1688888888888889,
              "msFromStart": 100238.05688888891
            },
            {
              "lat": 43.76236493333333,
              "lng": -116.09576715555558,
              "alt": 1986.2166666666665,
              "speed2d": 1.1695555555555557,
              "speed3d": 1.1833333333333333,
              "msFromStart": 100733.72355555551
            },
            {
              "lat": 43.76236023333333,
              "lng": -116.09576811111111,
              "alt": 1986.0035555555555,
              "speed2d": 1.0017777777777779,
              "speed3d": 1.0122222222222221,
              "msFromStart": 101229.06991033135
            },
            {
              "lat": 43.76235625,
              "lng": -116.09576996000001,
              "alt": 1986.0184999999997,
              "speed2d": 0.9206000000000001,
              "speed3d": 0.9199999999999998,
              "msFromStart": 101750.51936842102
            },
            {
              "lat": 43.76234992222222,
              "lng": -116.09576995555557,
              "alt": 1986.138888888889,
              "speed2d": 1.1683333333333334,
              "speed3d": 1.1433333333333333,
              "msFromStart": 102272.13938401561
            },
            {
              "lat": 43.762341911111115,
              "lng": -116.09576918888891,
              "alt": 1985.7935555555555,
              "speed2d": 1.409111111111111,
              "speed3d": 1.3911111111111112,
              "msFromStart": 102767.5377777778
            },
            {
              "lat": 43.762334711111116,
              "lng": -116.09576782222221,
              "alt": 1985.407777777778,
              "speed2d": 1.4142222222222223,
              "speed3d": 1.4055555555555554,
              "msFromStart": 103262.95481481482
            },
            {
              "lat": 43.76232854444444,
              "lng": -116.09576643333334,
              "alt": 1985.028666666667,
              "speed2d": 1.369,
              "speed3d": 1.402222222222222,
              "msFromStart": 103757.95999999999
            },
            {
              "lat": 43.762326011111114,
              "lng": -116.09576726666667,
              "alt": 1984.8011111111114,
              "speed2d": 0.9845555555555556,
              "speed3d": 1.02,
              "msFromStart": 104252.82251851851
            },
            {
              "lat": 43.76232291111111,
              "lng": -116.09576726666667,
              "alt": 1985.0476666666664,
              "speed2d": 0.8712222222222223,
              "speed3d": 0.8655555555555555,
              "msFromStart": 104747.27911111119
            },
            {
              "lat": 43.76231618888889,
              "lng": -116.09576674444445,
              "alt": 1984.4875555555554,
              "speed2d": 1.092111111111111,
              "speed3d": 1.0611111111111111,
              "msFromStart": 105241.81027680312
            },
            {
              "lat": 43.76230784444444,
              "lng": -116.09576762222221,
              "alt": 1984.167777777778,
              "speed2d": 1.4156666666666669,
              "speed3d": 1.3744444444444444,
              "msFromStart": 105736.80210526311
            },
            {
              "lat": 43.7622993,
              "lng": -116.09576685555554,
              "alt": 1984.0768888888888,
              "speed2d": 1.5506666666666666,
              "speed3d": 1.57,
              "msFromStart": 106231.89211176087
            },
            {
              "lat": 43.76229301111111,
              "lng": -116.09576573333334,
              "alt": 1984.2156666666663,
              "speed2d": 1.436,
              "speed3d": 1.4288888888888889,
              "msFromStart": 106727.30000000002
            },
            {
              "lat": 43.76228563000001,
              "lng": -116.09576503000001,
              "alt": 1984.3771,
              "speed2d": 1.4872,
              "speed3d": 1.5070000000000001,
              "msFromStart": 107250.21888888889
            },
            {
              "lat": 43.76228183333333,
              "lng": -116.09576481111111,
              "alt": 1984.5694444444446,
              "speed2d": 1.0699999999999998,
              "speed3d": 1.1044444444444446,
              "msFromStart": 107772.82666666668
            },
            {
              "lat": 43.76227764444445,
              "lng": -116.0957641,
              "alt": 1984.3735555555559,
              "speed2d": 0.940111111111111,
              "speed3d": 0.94,
              "msFromStart": 108267.84592592595
            },
            {
              "lat": 43.76227042222222,
              "lng": -116.0957634,
              "alt": 1984.0912222222223,
              "speed2d": 1.178111111111111,
              "speed3d": 1.157777777777778,
              "msFromStart": 108762.70222222227
            },
            {
              "lat": 43.76226508888889,
              "lng": -116.09576327777778,
              "alt": 1984.2753333333335,
              "speed2d": 1.2196666666666665,
              "speed3d": 1.231111111111111,
              "msFromStart": 109257.68518518523
            },
            {
              "lat": 43.76226154444445,
              "lng": -116.09576197777778,
              "alt": 1984.4443333333331,
              "speed2d": 1.0372222222222223,
              "speed3d": 1.0555555555555556,
              "msFromStart": 109753.23999999999
            },
            {
              "lat": 43.762256811111115,
              "lng": -116.09575906666666,
              "alt": 1984.9662222222223,
              "speed2d": 1.1785555555555556,
              "speed3d": 1.1677777777777778,
              "msFromStart": 110248.60681481479
            },
            {
              "lat": 43.76224991111111,
              "lng": -116.0957593,
              "alt": 1984.6886666666664,
              "speed2d": 1.3039999999999998,
              "speed3d": 1.3033333333333332,
              "msFromStart": 110742.99288888881
            },
            {
              "lat": 43.76224508888889,
              "lng": -116.09575596666667,
              "alt": 1984.659,
              "speed2d": 1.209111111111111,
              "speed3d": 1.2466666666666668,
              "msFromStart": 111237.25905653017
            },
            {
              "lat": 43.76223875555555,
              "lng": -116.09575507777777,
              "alt": 1984.3344444444444,
              "speed2d": 1.2321111111111112,
              "speed3d": 1.1933333333333334,
              "msFromStart": 111731.43789473682
            },
            {
              "lat": 43.76223021111112,
              "lng": -116.09575313333332,
              "alt": 1984.249888888889,
              "speed2d": 1.515222222222222,
              "speed3d": 1.5055555555555555,
              "msFromStart": 112225.872431449
            },
            {
              "lat": 43.76222147000001,
              "lng": -116.09575191999998,
              "alt": 1984.0513,
              "speed2d": 1.6573,
              "speed3d": 1.631,
              "msFromStart": 112749.49288888893
            },
            {
              "lat": 43.76221313333333,
              "lng": -116.09575170000002,
              "alt": 1983.7992222222222,
              "speed2d": 1.6634444444444445,
              "speed3d": 1.7200000000000002,
              "msFromStart": 113273.16296296299
            },
            {
              "lat": 43.7622081,
              "lng": -116.09574884444444,
              "alt": 1984.176,
              "speed2d": 1.5615555555555554,
              "speed3d": 1.5388888888888888,
              "msFromStart": 113768.40088888888
            },
            {
              "lat": 43.76220111111111,
              "lng": -116.09574593333335,
              "alt": 1984.1440000000002,
              "speed2d": 1.611333333333333,
              "speed3d": 1.6133333333333335,
              "msFromStart": 114263.55659259258
            },
            {
              "lat": 43.762195633333334,
              "lng": -116.09574233333333,
              "alt": 1984.9806666666668,
              "speed2d": 1.697111111111111,
              "speed3d": 1.6955555555555555,
              "msFromStart": 114758.72711111105
            },
            {
              "lat": 43.76219044444444,
              "lng": -116.09573705555555,
              "alt": 1986.5648888888888,
              "speed2d": 1.9029999999999998,
              "speed3d": 1.898888888888889,
              "msFromStart": 115253.825037037
            },
            {
              "lat": 43.76218655555556,
              "lng": -116.0957333,
              "alt": 1988.0718888888891,
              "speed2d": 1.8107777777777778,
              "speed3d": 1.881111111111111,
              "msFromStart": 115748.62666666662
            },
            {
              "lat": 43.76217942222223,
              "lng": -116.0957309,
              "alt": 1988.5977777777778,
              "speed2d": 1.913,
              "speed3d": 1.94,
              "msFromStart": 116243.38966081869
            },
            {
              "lat": 43.76216706666667,
              "lng": -116.09572995555557,
              "alt": 1987.8784444444443,
              "speed2d": 2.220777777777778,
              "speed3d": 2.218888888888889,
              "msFromStart": 116738.11663157896
            },
            {
              "lat": 43.76215575555556,
              "lng": -116.09572858888887,
              "alt": 1987.6584444444445,
              "speed2d": 2.348333333333333,
              "speed3d": 2.362222222222222,
              "msFromStart": 117232.94822612086
            },
            {
              "lat": 43.76214762222223,
              "lng": -116.09572572222223,
              "alt": 1988.3777777777777,
              "speed2d": 2.2824444444444447,
              "speed3d": 2.3377777777777777,
              "msFromStart": 117728.44
            },
            {
              "lat": 43.76213532,
              "lng": -116.09572105000001,
              "alt": 1988.503,
              "speed2d": 2.5749,
              "speed3d": 2.5420000000000003,
              "msFromStart": 118251.19333333334
            },
            {
              "lat": 43.7621207,
              "lng": -116.09571685555557,
              "alt": 1988.2471111111113,
              "speed2d": 2.7943333333333333,
              "speed3d": 2.827777777777778,
              "msFromStart": 118772.17333333337
            },
            {
              "lat": 43.76210972222223,
              "lng": -116.09571523333335,
              "alt": 1988.0231111111111,
              "speed2d": 2.563222222222222,
              "speed3d": 2.5900000000000003,
              "msFromStart": 119266.1266666667
            },
            {
              "lat": 43.762101333333334,
              "lng": -116.0957124888889,
              "alt": 1989.1387777777777,
              "speed2d": 2.4635555555555557,
              "speed3d": 2.5055555555555555,
              "msFromStart": 119762.6133333333
            },
            {
              "lat": 43.76209096666667,
              "lng": -116.09571093333334,
              "alt": 1989.4677777777777,
              "speed2d": 2.4723333333333337,
              "speed3d": 2.508888888888889,
              "msFromStart": 120259.0096296296
            },
            {
              "lat": 43.76208151111111,
              "lng": -116.09570636666668,
              "alt": 1990.0352222222223,
              "speed2d": 2.448666666666667,
              "speed3d": 2.521111111111111,
              "msFromStart": 120754.03111111115
            },
            {
              "lat": 43.7620725,
              "lng": -116.09570487777776,
              "alt": 1990.4332222222224,
              "speed2d": 2.291888888888889,
              "speed3d": 2.363333333333333,
              "msFromStart": 121248.9015048733
            },
            {
              "lat": 43.762061688888885,
              "lng": -116.09570251111111,
              "alt": 1990.9437777777778,
              "speed2d": 2.521777777777778,
              "speed3d": 2.5588888888888888,
              "msFromStart": 121743.7174736842
            },
            {
              "lat": 43.762053811111116,
              "lng": -116.09569688888891,
              "alt": 1992.7092222222225,
              "speed2d": 2.657666666666667,
              "speed3d": 2.7522222222222226,
              "msFromStart": 122238.52324366472
            },
            {
              "lat": 43.7620481,
              "lng": -116.09569185555556,
              "alt": 1994.334888888889,
              "speed2d": 2.4354444444444443,
              "speed3d": 2.5911111111111107,
              "msFromStart": 122733.29999999996
            },
            {
              "lat": 43.76203886666667,
              "lng": -116.0956916222222,
              "alt": 1994.0531111111109,
              "speed2d": 2.1756666666666664,
              "speed3d": 2.331111111111111,
              "msFromStart": 123228.04138271601
            },
            {
              "lat": 43.76202959,
              "lng": -116.09569085000003,
              "alt": 1993.9028,
              "speed2d": 2.1084,
              "speed3d": 2.2170000000000005,
              "msFromStart": 123750.06955555547
            },
            {
              "lat": 43.76201783333333,
              "lng": -116.09569025555554,
              "alt": 1994.0302222222222,
              "speed2d": 2.3663333333333334,
              "speed3d": 2.4466666666666668,
              "msFromStart": 124272.33525925926
            },
            {
              "lat": 43.76200736666667,
              "lng": -116.09568982222223,
              "alt": 1994.367777777778,
              "speed2d": 2.359,
              "speed3d": 2.513333333333333,
              "msFromStart": 124768.20000000008
            },
            {
              "lat": 43.76199784444445,
              "lng": -116.09568835555555,
              "alt": 1994.417888888889,
              "speed2d": 2.200111111111111,
              "speed3d": 2.3088888888888883,
              "msFromStart": 125263.92459259262
            },
            {
              "lat": 43.76198645555555,
              "lng": -116.09568356666666,
              "alt": 1994.7143333333333,
              "speed2d": 2.343888888888889,
              "speed3d": 2.4366666666666665,
              "msFromStart": 125758.65955555557
            },
            {
              "lat": 43.76197512222222,
              "lng": -116.0956809888889,
              "alt": 1994.8473333333334,
              "speed2d": 2.405111111111111,
              "speed3d": 2.4988888888888887,
              "msFromStart": 126253.37278752436
            },
            {
              "lat": 43.761964355555556,
              "lng": -116.09567693333334,
              "alt": 1995.2331111111112,
              "speed2d": 2.4442222222222223,
              "speed3d": 2.528888888888889,
              "msFromStart": 126748.39494736839
            },
            {
              "lat": 43.76195273333333,
              "lng": -116.09567595555556,
              "alt": 1995.5351111111113,
              "speed2d": 2.4383333333333335,
              "speed3d": 2.5677777777777777,
              "msFromStart": 127243.47651202076
            },
            {
              "lat": 43.76194201111111,
              "lng": -116.09567667777777,
              "alt": 1995.848777777778,
              "speed2d": 2.407111111111111,
              "speed3d": 2.488888888888889,
              "msFromStart": 127738.73200000002
            },
            {
              "lat": 43.76193205555556,
              "lng": -116.0956779777778,
              "alt": 1996.1805555555552,
              "speed2d": 2.2694444444444444,
              "speed3d": 2.408888888888889,
              "msFromStart": 128234.05106172842
            },
            {
              "lat": 43.76192227777778,
              "lng": -116.09567711111109,
              "alt": 1996.6214444444443,
              "speed2d": 2.2015555555555553,
              "speed3d": 2.304444444444444,
              "msFromStart": 128729.58400000003
            },
            {
              "lat": 43.76191292222222,
              "lng": -116.09567665555556,
              "alt": 1996.9853333333333,
              "speed2d": 2.1262222222222222,
              "speed3d": 2.2588888888888894,
              "msFromStart": 129225.11990123459
            },
            {
              "lat": 43.76190212000001,
              "lng": -116.09567601,
              "alt": 1997.4129,
              "speed2d": 2.2512,
              "speed3d": 2.333,
              "msFromStart": 129747.97711111113
            },
            {
              "lat": 43.7618918,
              "lng": -116.09567466666667,
              "alt": 1998.0974444444446,
              "speed2d": 2.268777777777778,
              "speed3d": 2.4266666666666667,
              "msFromStart": 130270.8162962963
            },
            {
              "lat": 43.76188366666666,
              "lng": -116.09567695555556,
              "alt": 1998.4615555555556,
              "speed2d": 2.0856666666666666,
              "speed3d": 2.206666666666667,
              "msFromStart": 130766.19466666662
            },
            {
              "lat": 43.76187483333334,
              "lng": -116.09567836666666,
              "alt": 1998.5754444444444,
              "speed2d": 2.037333333333333,
              "speed3d": 2.167777777777778,
              "msFromStart": 131261.55948538007
            },
            {
              "lat": 43.76186581111112,
              "lng": -116.09568076666667,
              "alt": 1998.9943333333329,
              "speed2d": 2.1243333333333334,
              "speed3d": 2.2144444444444447,
              "msFromStart": 131756.8475789473
            },
            {
              "lat": 43.761857544444446,
              "lng": -116.09568111111113,
              "alt": 2000.1965555555555,
              "speed2d": 2.2329999999999997,
              "speed3d": 2.3377777777777777,
              "msFromStart": 132251.92399740085
            },
            {
              "lat": 43.761848522222216,
              "lng": -116.09568027777777,
              "alt": 2001.1804444444444,
              "speed2d": 2.3393333333333333,
              "speed3d": 2.44,
              "msFromStart": 132745.75599999994
            },
            {
              "lat": 43.76184013333334,
              "lng": -116.09567946666665,
              "alt": 2002.3064444444444,
              "speed2d": 2.323888888888889,
              "speed3d": 2.4766666666666666,
              "msFromStart": 133239.61017283943
            },
            {
              "lat": 43.761830877777776,
              "lng": -116.09567873333333,
              "alt": 2002.8966666666665,
              "speed2d": 2.3223333333333334,
              "speed3d": 2.447777777777778,
              "msFromStart": 133734.84400000007
            },
            {
              "lat": 43.761822511111106,
              "lng": -116.09567686666665,
              "alt": 2003.800222222222,
              "speed2d": 2.2194444444444446,
              "speed3d": 2.3966666666666665,
              "msFromStart": 134230.30350617293
            },
            {
              "lat": 43.76181492222222,
              "lng": -116.09567684444445,
              "alt": 2004.6078888888887,
              "speed2d": 2.116666666666667,
              "speed3d": 2.2688888888888887,
              "msFromStart": 134725.7600000001
            },
            {
              "lat": 43.76180475,
              "lng": -116.09567735000002,
              "alt": 2005.3277999999998,
              "speed2d": 2.2578,
              "speed3d": 2.431,
              "msFromStart": 135248.60933333338
            },
            {
              "lat": 43.76179667777778,
              "lng": -116.09567695555556,
              "alt": 2006.0795555555558,
              "speed2d": 2.0540000000000003,
              "speed3d": 2.2377777777777785,
              "msFromStart": 135770.84266666658
            },
            {
              "lat": 43.76178655555556,
              "lng": -116.09567607777777,
              "alt": 2006.7754444444442,
              "speed2d": 2.291333333333333,
              "speed3d": 2.4288888888888884,
              "msFromStart": 136265.37590643266
            },
            {
              "lat": 43.7617772,
              "lng": -116.09567446666667,
              "alt": 2007.2035555555556,
              "speed2d": 2.193777777777778,
              "speed3d": 2.398888888888889,
              "msFromStart": 136759.38610526302
            },
            {
              "lat": 43.761766244444445,
              "lng": -116.09567215555555,
              "alt": 2008.245777777778,
              "speed2d": 2.507,
              "speed3d": 2.646666666666667,
              "msFromStart": 137253.49537102005
            },
            {
              "lat": 43.761760233333334,
              "lng": -116.09567158888892,
              "alt": 2009.1931111111114,
              "speed2d": 1.931111111111111,
              "speed3d": 2.2344444444444447,
              "msFromStart": 137748.52799999996
            },
            {
              "lat": 43.76175156666667,
              "lng": -116.09567129999999,
              "alt": 2009.688,
              "speed2d": 1.9592222222222222,
              "speed3d": 2.17,
              "msFromStart": 138243.74953086415
            },
            {
              "lat": 43.76174274444444,
              "lng": -116.0956700888889,
              "alt": 2010.598222222222,
              "speed2d": 2.062888888888889,
              "speed3d": 2.282222222222222,
              "msFromStart": 138739.19999999992
            },
            {
              "lat": 43.76173321111111,
              "lng": -116.0956702111111,
              "alt": 2011.137111111111,
              "speed2d": 2.0914444444444444,
              "speed3d": 2.335555555555555,
              "msFromStart": 139234.7363950617
            },
            {
              "lat": 43.761722255555554,
              "lng": -116.0956701,
              "alt": 2011.0023333333334,
              "speed2d": 2.1382222222222222,
              "speed3d": 2.3144444444444443,
              "msFromStart": 139730.5680000001
            },
            {
              "lat": 43.76171125555556,
              "lng": -116.09566926666668,
              "alt": 2010.9178888888887,
              "speed2d": 2.156111111111111,
              "speed3d": 2.3555555555555556,
              "msFromStart": 140226.33046913586
            },
            {
              "lat": 43.76170007000001,
              "lng": -116.09566745000001,
              "alt": 2010.8032,
              "speed2d": 2.1511,
              "speed3d": 2.268,
              "msFromStart": 140748.87199999997
            },
            {
              "lat": 43.761688122222225,
              "lng": -116.09566363333332,
              "alt": 2011.3592222222223,
              "speed2d": 2.419,
              "speed3d": 2.5233333333333334,
              "msFromStart": 141271.33670175436
            },
            {
              "lat": 43.761677,
              "lng": -116.0956644888889,
              "alt": 2011.5316666666668,
              "speed2d": 2.2889999999999997,
              "speed3d": 2.46,
              "msFromStart": 141766.46821052628
            },
            {
              "lat": 43.76166888888889,
              "lng": -116.0956648222222,
              "alt": 2012.023777777778,
              "speed2d": 2.0653333333333332,
              "speed3d": 2.2,
              "msFromStart": 142261.41997660816
            },
            {
              "lat": 43.76165768888889,
              "lng": -116.09566564444444,
              "alt": 2011.7024444444444,
              "speed2d": 2.043888888888889,
              "speed3d": 2.2177777777777776,
              "msFromStart": 142755.17599999998
            },
            {
              "lat": 43.761651844444444,
              "lng": -116.09566658888889,
              "alt": 2011.6242222222222,
              "speed2d": 1.515,
              "speed3d": 1.7044444444444444,
              "msFromStart": 143248.95822222222
            },
            {
              "lat": 43.76164586666667,
              "lng": -116.09566638888889,
              "alt": 2011.4974444444442,
              "speed2d": 1.3156666666666668,
              "speed3d": 1.4266666666666665,
              "msFromStart": 143744.09600000008
            },
            {
              "lat": 43.76163701111111,
              "lng": -116.09566111111111,
              "alt": 2011.2328888888887,
              "speed2d": 1.5744444444444445,
              "speed3d": 1.66,
              "msFromStart": 144239.55970370377
            },
            {
              "lat": 43.761628422222216,
              "lng": -116.09565794444445,
              "alt": 2010.351222222222,
              "speed2d": 1.459888888888889,
              "speed3d": 1.5333333333333332,
              "msFromStart": 144735.656
            },
            {
              "lat": 43.76162197777778,
              "lng": -116.09565575555554,
              "alt": 2010.4373333333333,
              "speed2d": 1.3974444444444447,
              "speed3d": 1.45,
              "msFromStart": 145231.65748148147
            },
            {
              "lat": 43.76161633333334,
              "lng": -116.09565573333333,
              "alt": 2010.4615555555556,
              "speed2d": 1.1496666666666666,
              "speed3d": 1.258888888888889,
              "msFromStart": 145726.44799999992
            },
            {
              "lat": 43.76161343,
              "lng": -116.09565341,
              "alt": 2011.0954,
              "speed2d": 0.9628,
              "speed3d": 1.034,
              "msFromStart": 146248.6301754385
            },
            {
              "lat": 43.761607633333334,
              "lng": -116.09564893333334,
              "alt": 2011.6447777777776,
              "speed2d": 1.2313333333333332,
              "speed3d": 1.2866666666666666,
              "msFromStart": 146771.3389473683
            },
            {
              "lat": 43.761601255555554,
              "lng": -116.09564662222223,
              "alt": 2012.380222222222,
              "speed2d": 1.431888888888889,
              "speed3d": 1.5000000000000002,
              "msFromStart": 147266.62317868735
            },
            {
              "lat": 43.76159462222222,
              "lng": -116.09564487777779,
              "alt": 2012.7082222222223,
              "speed2d": 1.4134444444444445,
              "speed3d": 1.5255555555555556,
              "msFromStart": 147761.99999999988
            },
            {
              "lat": 43.76158627777778,
              "lng": -116.09564317777779,
              "alt": 2012.5672222222224,
              "speed2d": 1.4401111111111111,
              "speed3d": 1.5355555555555556,
              "msFromStart": 148257.2408888888
            },
            {
              "lat": 43.761581988888885,
              "lng": -116.09564197777777,
              "alt": 2012.5804444444443,
              "speed2d": 1.0083333333333333,
              "speed3d": 1.1944444444444446,
              "msFromStart": 148751.5599999999
            },
            {
              "lat": 43.761577900000006,
              "lng": -116.09564,
              "alt": 2012.864111111111,
              "speed2d": 0.8902222222222221,
              "speed3d": 1.0222222222222221,
              "msFromStart": 149245.87516049377
            },
            {
              "lat": 43.7615694,
              "lng": -116.09564057777779,
              "alt": 2012.936777777778,
              "speed2d": 1.1848888888888889,
              "speed3d": 1.3022222222222222,
              "msFromStart": 149741.0880000001
            },
            {
              "lat": 43.76156778888889,
              "lng": -116.09564314444444,
              "alt": 2013.0662222222225,
              "speed2d": 0.6481111111111111,
              "speed3d": 0.9322222222222223,
              "msFromStart": 150236.42034567907
            },
            {
              "lat": 43.76156658888889,
              "lng": -116.09564046666667,
              "alt": 2013.8427777777779,
              "speed2d": 0.5041111111111112,
              "speed3d": 0.8122222222222223,
              "msFromStart": 150731.58399999994
            },
            {
              "lat": 43.76156086666666,
              "lng": -116.09564227777778,
              "alt": 2013.9557777777777,
              "speed2d": 0.737,
              "speed3d": 0.9855555555555555,
              "msFromStart": 151226.6681221572
            },
            {
              "lat": 43.76155471,
              "lng": -116.09564471,
              "alt": 2014.0665000000001,
              "speed2d": 0.9375999999999999,
              "speed3d": 1.115,
              "msFromStart": 151748.91999999987
            },
            {
              "lat": 43.76155057777778,
              "lng": -116.09564596666667,
              "alt": 2014.1146666666666,
              "speed2d": 0.7233333333333334,
              "speed3d": 0.9777777777777777,
              "msFromStart": 152271.09286549693
            },
            {
              "lat": 43.761546877777775,
              "lng": -116.09564717777778,
              "alt": 2014.0040000000001,
              "speed2d": 0.6212222222222221,
              "speed3d": 0.8644444444444443,
              "msFromStart": 152765.61599999986
            },
            {
              "lat": 43.761541211111116,
              "lng": -116.09564563333335,
              "alt": 2013.895,
              "speed2d": 0.8384444444444445,
              "speed3d": 0.9633333333333333,
              "msFromStart": 153260.26903703692
            },
            {
              "lat": 43.7615355,
              "lng": -116.09564356666667,
              "alt": 2014.186333333333,
              "speed2d": 1.0236666666666667,
              "speed3d": 1.1533333333333333,
              "msFromStart": 153755.8799999999
            },
            {
              "lat": 43.76152883333333,
              "lng": -116.09564038888888,
              "alt": 2014.861333333333,
              "speed2d": 1.2853333333333332,
              "speed3d": 1.4255555555555555,
              "msFromStart": 154251.51664197523
            },
            {
              "lat": 43.761522888888884,
              "lng": -116.0956362888889,
              "alt": 2016.1765555555558,
              "speed2d": 1.5053333333333334,
              "speed3d": 1.6922222222222223,
              "msFromStart": 154746.35200000013
            },
            {
              "lat": 43.76151816666667,
              "lng": -116.09563057777777,
              "alt": 2017.9618888888886,
              "speed2d": 1.597,
              "speed3d": 1.8955555555555557,
              "msFromStart": 155241.0560000001
            },
            {
              "lat": 43.7615137,
              "lng": -116.09562411111112,
              "alt": 2019.274,
              "speed2d": 1.589,
              "speed3d": 2.008888888888889,
              "msFromStart": 155735.76000000013
            },
            {
              "lat": 43.76150963333333,
              "lng": -116.0956205888889,
              "alt": 2020.3002222222221,
              "speed2d": 1.3047777777777778,
              "speed3d": 1.8744444444444444,
              "msFromStart": 156230.41118648482
            },
            {
              "lat": 43.76150522,
              "lng": -116.0956215,
              "alt": 2020.092,
              "speed2d": 0.836,
              "speed3d": 1.5329999999999997,
              "msFromStart": 156752.19999999992
            },
            {
              "lat": 43.76150011111111,
              "lng": -116.09562027777777,
              "alt": 2019.9191111111109,
              "speed2d": 0.772,
              "speed3d": 1.413333333333333,
              "msFromStart": 157274.2791838856
            },
            {
              "lat": 43.761495588888884,
              "lng": -116.09562222222223,
              "alt": 2019.8167777777776,
              "speed2d": 0.7242222222222223,
              "speed3d": 1.3433333333333333,
              "msFromStart": 157770.9920000001
            },
            {
              "lat": 43.761491355555556,
              "lng": -116.09562187777777,
              "alt": 2019.930222222222,
              "speed2d": 0.6492222222222223,
              "speed3d": 1.2955555555555556,
              "msFromStart": 158267.61441975314
            },
            {
              "lat": 43.76148765555555,
              "lng": -116.09562527777778,
              "alt": 2019.8524444444445,
              "speed2d": 0.6360000000000001,
              "speed3d": 1.2433333333333334,
              "msFromStart": 158761.59200000006
            },
            {
              "lat": 43.76148382222222,
              "lng": -116.0956291,
              "alt": 2019.751777777778,
              "speed2d": 0.6846666666666666,
              "speed3d": 1.22,
              "msFromStart": 159255.31081481487
            },
            {
              "lat": 43.761481466666666,
              "lng": -116.09563115555557,
              "alt": 2020.2076666666665,
              "speed2d": 0.5558888888888889,
              "speed3d": 1.1544444444444446,
              "msFromStart": 159750.09600000008
            },
            {
              "lat": 43.761473922222216,
              "lng": -116.09562517777778,
              "alt": 2021.3746666666666,
              "speed2d": 0.9492222222222222,
              "speed3d": 1.3911111111111112,
              "msFromStart": 160245.21698765436
            },
            {
              "lat": 43.76146674444445,
              "lng": -116.0956200111111,
              "alt": 2022.0881111111112,
              "speed2d": 1.2443333333333333,
              "speed3d": 1.6400000000000001,
              "msFromStart": 160741.31999999992
            },
            {
              "lat": 43.76146121111111,
              "lng": -116.09561836666668,
              "alt": 2021.992222222222,
              "speed2d": 1.1432222222222224,
              "speed3d": 1.5611111111111113,
              "msFromStart": 161237.45742430142
            },
            {
              "lat": 43.76145816666667,
              "lng": -116.09561901111113,
              "alt": 2022.3631111111113,
              "speed2d": 0.8517777777777777,
              "speed3d": 1.3922222222222222,
              "msFromStart": 161732.822736842
            },
            {
              "lat": 43.76145393333333,
              "lng": -116.09562024444445,
              "alt": 2022.3285555555553,
              "speed2d": 0.7425555555555555,
              "speed3d": 1.2466666666666666,
              "msFromStart": 162228.11015984396
            },
            {
              "lat": 43.76144643,
              "lng": -116.09561966000001,
              "alt": 2021.5275000000001,
              "speed2d": 0.8735,
              "speed3d": 1.2269999999999999,
              "msFromStart": 162751.43422222222
            },
            {
              "lat": 43.76144053333333,
              "lng": -116.09561920000002,
              "alt": 2021.8441111111108,
              "speed2d": 0.9518888888888889,
              "speed3d": 1.2955555555555556,
              "msFromStart": 163274.70716049382
            },
            {
              "lat": 43.76143594444444,
              "lng": -116.09561683333332,
              "alt": 2022.3931111111112,
              "speed2d": 0.8314444444444445,
              "speed3d": 1.21,
              "msFromStart": 163769.3759999999
            },
            {
              "lat": 43.76143244444444,
              "lng": -116.09561481111112,
              "alt": 2023.0574444444444,
              "speed2d": 0.7098888888888889,
              "speed3d": 1.1066666666666667,
              "msFromStart": 164264.03496296285
            },
            {
              "lat": 43.76142793333334,
              "lng": -116.09561102222222,
              "alt": 2023.3809999999999,
              "speed2d": 0.8595555555555556,
              "speed3d": 1.1844444444444444,
              "msFromStart": 164759.68799999988
            },
            {
              "lat": 43.76142178888889,
              "lng": -116.09560992222221,
              "alt": 2023.4664444444443,
              "speed2d": 1.0565555555555555,
              "speed3d": 1.3088888888888888,
              "msFromStart": 165255.37066666657
            },
            {
              "lat": 43.76141495555556,
              "lng": -116.09560813333333,
              "alt": 2023.3826666666669,
              "speed2d": 1.1615555555555557,
              "speed3d": 1.3888888888888888,
              "msFromStart": 165750.2399999999
            },
            {
              "lat": 43.761407500000004,
              "lng": -116.0956060111111,
              "alt": 2023.5634444444445,
              "speed2d": 1.3441111111111113,
              "speed3d": 1.5555555555555558,
              "msFromStart": 166244.94824171532
            },
            {
              "lat": 43.761401677777776,
              "lng": -116.09560511111111,
              "alt": 2023.5500000000002,
              "speed2d": 1.2306666666666666,
              "speed3d": 1.4133333333333333,
              "msFromStart": 166739.48715789462
            },
            {
              "lat": 43.761394288888894,
              "lng": -116.09560305555556,
              "alt": 2023.795888888889,
              "speed2d": 1.1949999999999998,
              "speed3d": 1.4355555555555555,
              "msFromStart": 167233.98771150084
            },
            {
              "lat": 43.76138778888889,
              "lng": -116.09560554444444,
              "alt": 2023.4895555555554,
              "speed2d": 1.0504444444444445,
              "speed3d": 1.2388888888888887,
              "msFromStart": 167728.38222222225
            },
            {
              "lat": 43.76138194999999,
              "lng": -116.09561033000001,
              "alt": 2023.0498999999998,
              "speed2d": 0.9994999999999999,
              "speed3d": 1.166,
              "msFromStart": 168250.23555555556
            },
            {
              "lat": 43.76137823333333,
              "lng": -116.09561253333331,
              "alt": 2023.3411111111109,
              "speed2d": 0.8292222222222221,
              "speed3d": 1.0133333333333334,
              "msFromStart": 168772.23200000002
            },
            {
              "lat": 43.76137495555555,
              "lng": -116.09561454444443,
              "alt": 2023.3422222222225,
              "speed2d": 0.7034444444444444,
              "speed3d": 0.8822222222222222,
              "msFromStart": 169266.86202469136
            },
            {
              "lat": 43.761365822222224,
              "lng": -116.0956149111111,
              "alt": 2022.8544444444444,
              "speed2d": 1.1753333333333333,
              "speed3d": 1.2322222222222223,
              "msFromStart": 169761.96799999996
            },
            {
              "lat": 43.76135704444445,
              "lng": -116.09561333333333,
              "alt": 2022.594222222222,
              "speed2d": 1.4869999999999997,
              "speed3d": 1.5266666666666666,
              "msFromStart": 170257.22409876535
            },
            {
              "lat": 43.761347611111105,
              "lng": -116.09560796666668,
              "alt": 2022.4974444444445,
              "speed2d": 1.7112222222222222,
              "speed3d": 1.7511111111111113,
              "msFromStart": 170752.9199999999
            },
            {
              "lat": 43.76133932222222,
              "lng": -116.09560399999998,
              "alt": 2023.3884444444443,
              "speed2d": 1.756888888888889,
              "speed3d": 1.8511111111111114,
              "msFromStart": 171248.58803898626
            },
            {
              "lat": 43.76133396666667,
              "lng": -116.09560167777778,
              "alt": 2023.732777777778,
              "speed2d": 1.341888888888889,
              "speed3d": 1.4544444444444444,
              "msFromStart": 171743.64631578943
            },
            {
              "lat": 43.76132378888888,
              "lng": -116.09560236666665,
              "alt": 2023.7665555555554,
              "speed2d": 1.659,
              "speed3d": 1.6866666666666668,
              "msFromStart": 172238.5668304093
            },
            {
              "lat": 43.76131313333333,
              "lng": -116.09560276666667,
              "alt": 2023.6666666666665,
              "speed2d": 1.842111111111111,
              "speed3d": 1.9044444444444444,
              "msFromStart": 172733.10933333324
            },
            {
              "lat": 43.76130688888889,
              "lng": -116.09559901111112,
              "alt": 2023.8972222222221,
              "speed2d": 1.5834444444444444,
              "speed3d": 1.662222222222222,
              "msFromStart": 173227.58044444435
            },
            {
              "lat": 43.76129902,
              "lng": -116.09559564000001,
              "alt": 2024.0374000000002,
              "speed2d": 1.7212999999999998,
              "speed3d": 1.764,
              "msFromStart": 173749.76933333336
            },
            {
              "lat": 43.76128832222223,
              "lng": -116.095595,
              "alt": 2024.1021111111113,
              "speed2d": 2.039111111111111,
              "speed3d": 2.0344444444444445,
              "msFromStart": 174272.0476049382
            },
            {
              "lat": 43.761276266666655,
              "lng": -116.09559311111113,
              "alt": 2024.151,
              "speed2d": 2.397444444444445,
              "speed3d": 2.428888888888889,
              "msFromStart": 174767.0319999999
            },
            {
              "lat": 43.76126334444444,
              "lng": -116.09559045555555,
              "alt": 2024.3894444444443,
              "speed2d": 2.6195555555555554,
              "speed3d": 2.6377777777777776,
              "msFromStart": 175262.07170370364
            },
            {
              "lat": 43.76125387777778,
              "lng": -116.09558845555556,
              "alt": 2025.5198888888888,
              "speed2d": 2.487222222222223,
              "speed3d": 2.577777777777778,
              "msFromStart": 175757.25599999996
            },
            {
              "lat": 43.76124441111111,
              "lng": -116.0955876,
              "alt": 2026.1213333333333,
              "speed2d": 2.333,
              "speed3d": 2.437777777777778,
              "msFromStart": 176252.4734087069
            },
            {
              "lat": 43.761235411111116,
              "lng": -116.09558494444445,
              "alt": 2026.708,
              "speed2d": 2.2618888888888886,
              "speed3d": 2.3355555555555556,
              "msFromStart": 176747.74821052642
            },
            {
              "lat": 43.76122301111111,
              "lng": -116.09558073333334,
              "alt": 2026.6801111111115,
              "speed2d": 2.5012222222222227,
              "speed3d": 2.5411111111111113,
              "msFromStart": 177243.00781286566
            },
            {
              "lat": 43.7612104,
              "lng": -116.09557664444444,
              "alt": 2026.840333333333,
              "speed2d": 2.617111111111112,
              "speed3d": 2.701111111111111,
              "msFromStart": 177738.02133333334
            },
            {
              "lat": 43.76120085555556,
              "lng": -116.09557389999999,
              "alt": 2026.971,
              "speed2d": 2.3641111111111113,
              "speed3d": 2.453333333333333,
              "msFromStart": 178232.9194074074
            },
            {
              "lat": 43.76118924444445,
              "lng": -116.09557229999999,
              "alt": 2026.405,
              "speed2d": 2.3272222222222223,
              "speed3d": 2.3677777777777775,
              "msFromStart": 178727.27822222223
            },
            {
              "lat": 43.76117385,
              "lng": -116.09557041999999,
              "alt": 2025.3899000000001,
              "speed2d": 2.534,
              "speed3d": 2.5520000000000005,
              "msFromStart": 179249.0186666667
            },
            {
              "lat": 43.76115882222222,
              "lng": -116.09556914444443,
              "alt": 2024.581111111111,
              "speed2d": 2.6325555555555553,
              "speed3d": 2.6622222222222223,
              "msFromStart": 179771.17599999992
            },
            {
              "lat": 43.761148866666666,
              "lng": -116.09556611111111,
              "alt": 2025.0665555555554,
              "speed2d": 2.5995555555555554,
              "speed3d": 2.595555555555556,
              "msFromStart": 180266.07427160488
            },
            {
              "lat": 43.76113465555555,
              "lng": -116.09556161111112,
              "alt": 2025.1584444444443,
              "speed2d": 2.9266666666666667,
              "speed3d": 2.9155555555555557,
              "msFromStart": 180761.81600000008
            },
            {
              "lat": 43.761119666666666,
              "lng": -116.09555594444443,
              "alt": 2025.1304444444445,
              "speed2d": 3.1646666666666667,
              "speed3d": 3.185555555555556,
              "msFromStart": 181257.65612995456
            },
            {
              "lat": 43.76110954444445,
              "lng": -116.09555401111112,
              "alt": 2025.586,
              "speed2d": 2.8089999999999997,
              "speed3d": 2.88,
              "msFromStart": 181753.2530526315
            },
            {
              "lat": 43.761099766666675,
              "lng": -116.09555235555555,
              "alt": 2026.049,
              "speed2d": 2.5971111111111114,
              "speed3d": 2.6444444444444444,
              "msFromStart": 182248.8079532163
            },
            {
              "lat": 43.76109103333333,
              "lng": -116.0955501111111,
              "alt": 2026.451777777778,
              "speed2d": 2.348,
              "speed3d": 2.4188888888888886,
              "msFromStart": 182744.34133333343
            },
            {
              "lat": 43.76108066666667,
              "lng": -116.0955465777778,
              "alt": 2026.8807777777777,
              "speed2d": 2.4283333333333332,
              "speed3d": 2.431111111111111,
              "msFromStart": 183239.75496296305
            },
            {
              "lat": 43.761065699999996,
              "lng": -116.09554211111111,
              "alt": 2026.759111111111,
              "speed2d": 2.7848888888888883,
              "speed3d": 2.8044444444444445,
              "msFromStart": 183734.02488888885
            },
            {
              "lat": 43.76105486666667,
              "lng": -116.0955395111111,
              "alt": 2026.702222222222,
              "speed2d": 2.546111111111111,
              "speed3d": 2.6344444444444446,
              "msFromStart": 184228.13155555553
            },
            {
              "lat": 43.761045689999996,
              "lng": -116.09553734,
              "alt": 2026.994,
              "speed2d": 2.2893,
              "speed3d": 2.324,
              "msFromStart": 184751.00044444448
            },
            {
              "lat": 43.76103123333334,
              "lng": -116.09553525555555,
              "alt": 2027.1207777777777,
              "speed2d": 2.704888888888889,
              "speed3d": 2.698888888888889,
              "msFromStart": 185274.06439506175
            },
            {
              "lat": 43.76101765555555,
              "lng": -116.09553259999998,
              "alt": 2027.0197777777778,
              "speed2d": 2.8293333333333335,
              "speed3d": 2.87,
              "msFromStart": 185768.91199999995
            },
            {
              "lat": 43.761007877777786,
              "lng": -116.09553203333334,
              "alt": 2027.1276666666663,
              "speed2d": 2.5003333333333333,
              "speed3d": 2.5855555555555556,
              "msFromStart": 186263.60994931773
            },
            {
              "lat": 43.76099978888889,
              "lng": -116.09552951111111,
              "alt": 2027.4148888888888,
              "speed2d": 2.202,
              "speed3d": 2.266666666666667,
              "msFromStart": 186758.07578947375
            },
            {
              "lat": 43.7609907,
              "lng": -116.09552687777776,
              "alt": 2028.0311111111112,
              "speed2d": 2.2663333333333333,
              "speed3d": 2.302222222222222,
              "msFromStart": 187252.5424093568
            },
            {
              "lat": 43.76098136666666,
              "lng": -116.09552691111111,
              "alt": 2028.862222222222,
              "speed2d": 2.334222222222222,
              "speed3d": 2.3866666666666667,
              "msFromStart": 187747.39733333333
            },
            {
              "lat": 43.76097064444444,
              "lng": -116.09552620000001,
              "alt": 2029.3086666666668,
              "speed2d": 2.433666666666667,
              "speed3d": 2.4899999999999998,
              "msFromStart": 188242.30903703702
            },
            {
              "lat": 43.76096011111111,
              "lng": -116.0955226,
              "alt": 2029.6573333333336,
              "speed2d": 2.4222222222222225,
              "speed3d": 2.473333333333333,
              "msFromStart": 188736.81777777776
            },
            {
              "lat": 43.760948911111115,
              "lng": -116.09551506666666,
              "alt": 2030.841,
              "speed2d": 2.6773333333333333,
              "speed3d": 2.717777777777777,
              "msFromStart": 189231.39051851848
            },
            {
              "lat": 43.76093704444445,
              "lng": -116.09550933333334,
              "alt": 2031.470777777778,
              "speed2d": 2.741111111111111,
              "speed3d": 2.8366666666666664,
              "msFromStart": 189727.61066666673
            },
            {
              "lat": 43.76092858000001,
              "lng": -116.0955066,
              "alt": 2031.9202,
              "speed2d": 2.3095,
              "speed3d": 2.4600000000000004,
              "msFromStart": 190251.69288888894
            },
            {
              "lat": 43.76092242222222,
              "lng": -116.09550716666666,
              "alt": 2031.842,
              "speed2d": 1.7735555555555553,
              "speed3d": 1.9177777777777778,
              "msFromStart": 190774.7919999999
            },
            {
              "lat": 43.76091478888889,
              "lng": -116.09550964444443,
              "alt": 2030.9487777777777,
              "speed2d": 1.5566666666666664,
              "speed3d": 1.6666666666666667,
              "msFromStart": 191270.1655620532
            },
            {
              "lat": 43.760907455555554,
              "lng": -116.0955132777778,
              "alt": 2029.2424444444443,
              "speed2d": 1.1894444444444445,
              "speed3d": 1.2577777777777777,
              "msFromStart": 191765.57305263152
            },
            {
              "lat": 43.76090572222222,
              "lng": -116.0955137222222,
              "alt": 2029.591111111111,
              "speed2d": 0.8904444444444445,
              "speed3d": 0.9233333333333333,
              "msFromStart": 192260.79965691996
            },
            {
              "lat": 43.760905,
              "lng": -116.0955137888889,
              "alt": 2030.9813333333334,
              "speed2d": 0.9225555555555556,
              "speed3d": 0.9666666666666666,
              "msFromStart": 192754.1617777777
            },
            {
              "lat": 43.760903722222224,
              "lng": -116.09551367777777,
              "alt": 2032.1113333333333,
              "speed2d": 0.9926666666666667,
              "speed3d": 0.9855555555555554,
              "msFromStart": 193247.3374814814
            },
            {
              "lat": 43.760898511111115,
              "lng": -116.0955104111111,
              "alt": 2032.4035555555554,
              "speed2d": 1.061777777777778,
              "speed3d": 1.0822222222222222,
              "msFromStart": 193743.31022222224
            },
            {
              "lat": 43.76089436666666,
              "lng": -116.09550665555555,
              "alt": 2032.7412222222224,
              "speed2d": 1.0948888888888888,
              "speed3d": 1.1133333333333333,
              "msFromStart": 194239.78903703706
            },
            {
              "lat": 43.76088766666666,
              "lng": -116.09550201111112,
              "alt": 2033.0504444444446,
              "speed2d": 1.389111111111111,
              "speed3d": 1.3722222222222222,
              "msFromStart": 194734.33600000007
            },
            {
              "lat": 43.76088152222223,
              "lng": -116.0955012888889,
              "alt": 2033.5033333333333,
              "speed2d": 1.4993333333333334,
              "speed3d": 1.5277777777777777,
              "msFromStart": 195228.50725925932
            },
            {
              "lat": 43.76087641,
              "lng": -116.09550207,
              "alt": 2033.8627999999999,
              "speed2d": 1.3379,
              "speed3d": 1.38,
              "msFromStart": 195751.2795555555
            },
            {
              "lat": 43.76086932222223,
              "lng": -116.09550343333333,
              "alt": 2033.606666666667,
              "speed2d": 1.3843333333333332,
              "speed3d": 1.4100000000000001,
              "msFromStart": 196274.21728395057
            },
            {
              "lat": 43.76086247777778,
              "lng": -116.09550357777776,
              "alt": 2033.6845555555556,
              "speed2d": 1.3918888888888887,
              "speed3d": 1.4055555555555554,
              "msFromStart": 196769.0000000001
            },
            {
              "lat": 43.760855344444444,
              "lng": -116.09550175555556,
              "alt": 2033.577,
              "speed2d": 1.4536666666666669,
              "speed3d": 1.4633333333333332,
              "msFromStart": 197263.68535412612
            },
            {
              "lat": 43.7608486,
              "lng": -116.0954984888889,
              "alt": 2034.152,
              "speed2d": 1.5379999999999998,
              "speed3d": 1.552222222222222,
              "msFromStart": 197758.40336842104
            },
            {
              "lat": 43.76084127777777,
              "lng": -116.09549741111113,
              "alt": 2034.1956666666667,
              "speed2d": 1.544111111111111,
              "speed3d": 1.5844444444444443,
              "msFromStart": 198253.07848732942
            },
            {
              "lat": 43.76083264444445,
              "lng": -116.09549897777777,
              "alt": 2033.5316666666668,
              "speed2d": 1.6056666666666668,
              "speed3d": 1.5744444444444445,
              "msFromStart": 198747.271111111
            },
            {
              "lat": 43.76081875555556,
              "lng": -116.09549898888889,
              "alt": 2032.9403333333332,
              "speed2d": 2.1708888888888893,
              "speed3d": 2.112222222222222,
              "msFromStart": 199241.47525925917
            },
            {
              "lat": 43.76080505555555,
              "lng": -116.0954974,
              "alt": 2032.7844444444445,
              "speed2d": 2.518111111111111,
              "speed3d": 2.52,
              "msFromStart": 199737.00088888896
            },
            {
              "lat": 43.76079656666668,
              "lng": -116.09549814444446,
              "alt": 2033.4367777777775,
              "speed2d": 2.2453333333333334,
              "speed3d": 2.283333333333333,
              "msFromStart": 200232.79525925935
            },
            {
              "lat": 43.760789744444445,
              "lng": -116.0954954,
              "alt": 2034.3401111111114,
              "speed2d": 1.9860000000000002,
              "speed3d": 2.0222222222222226,
              "msFromStart": 200727.97333333333
            },
            {
              "lat": 43.760780069999996,
              "lng": -116.09549562,
              "alt": 2034.4385,
              "speed2d": 1.9428999999999998,
              "speed3d": 1.989,
              "msFromStart": 201250.48294736844
            },
            {
              "lat": 43.7607715,
              "lng": -116.09549406666667,
              "alt": 2034.3153333333332,
              "speed2d": 1.8368888888888888,
              "speed3d": 1.8555555555555556,
              "msFromStart": 201772.8825263158
            },
            {
              "lat": 43.76076226666667,
              "lng": -116.09549262222222,
              "alt": 2034.5434444444443,
              "speed2d": 1.9954444444444446,
              "speed3d": 1.9799999999999998,
              "msFromStart": 202267.8122729045
            },
            {
              "lat": 43.76075162222222,
              "lng": -116.09548954444445,
              "alt": 2035.0013333333332,
              "speed2d": 2.2843333333333335,
              "speed3d": 2.2922222222222217,
              "msFromStart": 202763.21422222222
            },
            {
              "lat": 43.76074166666666,
              "lng": -116.09548677777778,
              "alt": 2035.4151111111107,
              "speed2d": 2.2907777777777776,
              "speed3d": 2.3266666666666667,
              "msFromStart": 203258.59733333334
            },
            {
              "lat": 43.760731211111114,
              "lng": -116.09548483333333,
              "alt": 2036.0825555555557,
              "speed2d": 2.358777777777778,
              "speed3d": 2.364444444444444,
              "msFromStart": 203752.61155555546
            },
            {
              "lat": 43.760718355555554,
              "lng": -116.095483,
              "alt": 2036.7747777777777,
              "speed2d": 2.6278888888888896,
              "speed3d": 2.703333333333334,
              "msFromStart": 204246.47792592584
            },
            {
              "lat": 43.76070826666667,
              "lng": -116.09548116666666,
              "alt": 2037.3951111111112,
              "speed2d": 2.4355555555555557,
              "speed3d": 2.5122222222222224,
              "msFromStart": 204742.288
            },
            {
              "lat": 43.76069878888889,
              "lng": -116.09547814444444,
              "alt": 2037.8002222222224,
              "speed2d": 2.3504444444444443,
              "speed3d": 2.4244444444444446,
              "msFromStart": 205238.5028148148
            },
            {
              "lat": 43.760688288888886,
              "lng": -116.0954753111111,
              "alt": 2037.9427777777778,
              "speed2d": 2.378222222222222,
              "speed3d": 2.43,
              "msFromStart": 205733.9057777779
            },
            {
              "lat": 43.76067638888889,
              "lng": -116.09547515555555,
              "alt": 2037.287222222222,
              "speed2d": 2.3883333333333336,
              "speed3d": 2.4722222222222228,
              "msFromStart": 206229.07945419112
            },
            {
              "lat": 43.76066605999999,
              "lng": -116.09547871999999,
              "alt": 2036.4606999999999,
              "speed2d": 2.1395999999999997,
              "speed3d": 2.198,
              "msFromStart": 206751.48126315782
            },
            {
              "lat": 43.76065044444445,
              "lng": -116.09547685555556,
              "alt": 2035.8415555555555,
              "speed2d": 2.5835555555555554,
              "speed3d": 2.56,
              "msFromStart": 207273.88032748527
            },
            {
              "lat": 43.760636455555556,
              "lng": -116.09547583333335,
              "alt": 2035.6642222222222,
              "speed2d": 2.7206666666666663,
              "speed3d": 2.7577777777777777,
              "msFromStart": 207769.41866666655
            },
            {
              "lat": 43.76062484444444,
              "lng": -116.0954716111111,
              "alt": 2035.9092222222223,
              "speed2d": 2.6167777777777776,
              "speed3d": 2.684444444444445,
              "msFromStart": 208265.0275555554
            },
            {
              "lat": 43.760614033333326,
              "lng": -116.09547073333333,
              "alt": 2035.3105555555555,
              "speed2d": 2.2993333333333337,
              "speed3d": 2.3744444444444444,
              "msFromStart": 208759.76533333323
            },
            {
              "lat": 43.760603155555565,
              "lng": -116.0954687888889,
              "alt": 2035.4913333333334,
              "speed2d": 2.413333333333333,
              "speed3d": 2.4099999999999997,
              "msFromStart": 209254.32503703694
            },
            {
              "lat": 43.76059112222222,
              "lng": -116.09546323333333,
              "alt": 2036.0745555555554,
              "speed2d": 2.7369999999999997,
              "speed3d": 2.77,
              "msFromStart": 209749.28177777783
            },
            {
              "lat": 43.76057696666666,
              "lng": -116.09545886666668,
              "alt": 2036.3467777777778,
              "speed2d": 3.0875555555555554,
              "speed3d": 3.12,
              "msFromStart": 210244.38162962973
            },
            {
              "lat": 43.76056655555555,
              "lng": -116.09545452222223,
              "alt": 2036.1796666666664,
              "speed2d": 2.7423333333333333,
              "speed3d": 2.884444444444444,
              "msFromStart": 210739.91999999993
            },
            {
              "lat": 43.760556188888884,
              "lng": -116.0954531,
              "alt": 2036.2640000000001,
              "speed2d": 2.5802222222222224,
              "speed3d": 2.686666666666667,
              "msFromStart": 211235.49511111103
            },
            {
              "lat": 43.76054633333333,
              "lng": -116.0954535111111,
              "alt": 2035.761888888889,
              "speed2d": 2.238111111111111,
              "speed3d": 2.4000000000000004,
              "msFromStart": 211730.3413333333
            },
            {
              "lat": 43.760537,
              "lng": -116.09545453333334,
              "alt": 2034.4294444444445,
              "speed2d": 1.8812222222222224,
              "speed3d": 1.9866666666666666,
              "msFromStart": 212225.0916959064
            },
            {
              "lat": 43.76052670000001,
              "lng": -116.09545533000001,
              "alt": 2033.4344999999998,
              "speed2d": 1.7645,
              "speed3d": 1.8259999999999998,
              "msFromStart": 212748.25178947367
            },
            {
              "lat": 43.76051652222222,
              "lng": -116.09545365555556,
              "alt": 2033.8347777777778,
              "speed2d": 2.0278888888888886,
              "speed3d": 2.097777777777778,
              "msFromStart": 213271.51298245616
            },
            {
              "lat": 43.760506577777775,
              "lng": -116.0954544111111,
              "alt": 2034.1736666666666,
              "speed2d": 2.1386666666666665,
              "speed3d": 2.2011111111111106,
              "msFromStart": 213766.0800000001
            },
            {
              "lat": 43.76049478888888,
              "lng": -116.09545492222223,
              "alt": 2034.1367777777778,
              "speed2d": 2.3,
              "speed3d": 2.3633333333333333,
              "msFromStart": 214260.38192592605
            },
            {
              "lat": 43.76048061111111,
              "lng": -116.09545554444445,
              "alt": 2034.0801111111114,
              "speed2d": 2.6318888888888887,
              "speed3d": 2.6822222222222223,
              "msFromStart": 214754.9031111111
            },
            {
              "lat": 43.76046835555556,
              "lng": -116.09545510000002,
              "alt": 2034.1753333333336,
              "speed2d": 2.5806666666666667,
              "speed3d": 2.6944444444444446,
              "msFromStart": 215249.5502222222
            },
            {
              "lat": 43.760457888888894,
              "lng": -116.0954514,
              "alt": 2033.9186666666665,
              "speed2d": 2.3081111111111112,
              "speed3d": 2.3955555555555557,
              "msFromStart": 215744.90844444436
            },
            {
              "lat": 43.76044575555555,
              "lng": -116.09544904444446,
              "alt": 2033.6362222222224,
              "speed2d": 2.3080000000000003,
              "speed3d": 2.3955555555555557,
              "msFromStart": 216240.39435477572
            },
            {
              "lat": 43.76043184444445,
              "lng": -116.0954485,
              "alt": 2032.8339999999998,
              "speed2d": 2.4142222222222225,
              "speed3d": 2.448888888888889,
              "msFromStart": 216735.37936842092
            },
            {
              "lat": 43.760419711111105,
              "lng": -116.09544563333333,
              "alt": 2032.4663333333335,
              "speed2d": 2.3916666666666666,
              "speed3d": 2.44,
              "msFromStart": 217230.23842495115
            },
            {
              "lat": 43.76040713,
              "lng": -116.09544346,
              "alt": 2032.4007,
              "speed2d": 2.4321,
              "speed3d": 2.47,
              "msFromStart": 217752.57466666674
            },
            {
              "lat": 43.76039497777778,
              "lng": -116.09544024444445,
              "alt": 2032.4176666666663,
              "speed2d": 2.3948888888888886,
              "speed3d": 2.46,
              "msFromStart": 218274.95496296303
            },
            {
              "lat": 43.76038357777777,
              "lng": -116.09543691111112,
              "alt": 2032.634888888889,
              "speed2d": 2.446333333333333,
              "speed3d": 2.486666666666667,
              "msFromStart": 218770.3360000001
            },
            {
              "lat": 43.760372244444454,
              "lng": -116.09543357777778,
              "alt": 2032.8038888888889,
              "speed2d": 2.4444444444444446,
              "speed3d": 2.498888888888889,
              "msFromStart": 219265.82577777788
            },
            {
              "lat": 43.76036123333333,
              "lng": -116.09543250000003,
              "alt": 2032.5717777777777,
              "speed2d": 2.293222222222222,
              "speed3d": 2.373333333333333,
              "msFromStart": 219761.1733333334
            },
            {
              "lat": 43.76035007777777,
              "lng": -116.09543042222222,
              "alt": 2032.5278888888886,
              "speed2d": 2.273111111111111,
              "speed3d": 2.3155555555555556,
              "msFromStart": 220256.437925926
            },
            {
              "lat": 43.7603389888889,
              "lng": -116.09542784444446,
              "alt": 2032.7236666666665,
              "speed2d": 2.352333333333333,
              "speed3d": 2.41,
              "msFromStart": 220751.22844444448
            },
            {
              "lat": 43.76032743333333,
              "lng": -116.09542585555556,
              "alt": 2033.049888888889,
              "speed2d": 2.4802222222222223,
              "speed3d": 2.5166666666666666,
              "msFromStart": 221245.95496296298
            },
            {
              "lat": 43.76031566666667,
              "lng": -116.09542473333333,
              "alt": 2033.2965555555556,
              "speed2d": 2.5232222222222216,
              "speed3d": 2.5944444444444446,
              "msFromStart": 221741.22666666674
            },
            {
              "lat": 43.76030332222222,
              "lng": -116.0954208111111,
              "alt": 2032.9705555555556,
              "speed2d": 2.4752222222222224,
              "speed3d": 2.5166666666666666,
              "msFromStart": 222236.56205847964
            },
            {
              "lat": 43.76029208888889,
              "lng": -116.09542083333334,
              "alt": 2032.9034444444442,
              "speed2d": 2.3298888888888887,
              "speed3d": 2.3966666666666665,
              "msFromStart": 222731.1713684211
            },
            {
              "lat": 43.76028091111111,
              "lng": -116.09542098888889,
              "alt": 2032.7245555555558,
              "speed2d": 2.258888888888889,
              "speed3d": 2.306666666666666,
              "msFromStart": 223225.63605458097
            },
            {
              "lat": 43.76026766,
              "lng": -116.09542103,
              "alt": 2032.6895,
              "speed2d": 2.4227999999999996,
              "speed3d": 2.442,
              "msFromStart": 223748.32533333334
            },
            {
              "lat": 43.760258199999996,
              "lng": -116.09541592222222,
              "alt": 2033.3694444444445,
              "speed2d": 2.314,
              "speed3d": 2.376666666666667,
              "msFromStart": 224271.24503703704
            },
            {
              "lat": 43.760247822222226,
              "lng": -116.09541316666667,
              "alt": 2033.874777777778,
              "speed2d": 2.3416666666666663,
              "speed3d": 2.3722222222222222,
              "msFromStart": 224766.36266666668
            },
            {
              "lat": 43.76023567777777,
              "lng": -116.09541033333333,
              "alt": 2033.5865555555554,
              "speed2d": 2.375111111111111,
              "speed3d": 2.4122222222222227,
              "msFromStart": 225261.43970370374
            },
            {
              "lat": 43.76022394444444,
              "lng": -116.09540764444445,
              "alt": 2033.720111111111,
              "speed2d": 2.4219999999999997,
              "speed3d": 2.4555555555555557,
              "msFromStart": 225756.80711111106
            },
            {
              "lat": 43.760213444444446,
              "lng": -116.09540553333333,
              "alt": 2034.4148888888888,
              "speed2d": 2.452555555555555,
              "speed3d": 2.4811111111111113,
              "msFromStart": 226252.20681481474
            },
            {
              "lat": 43.76020145555556,
              "lng": -116.09540421111112,
              "alt": 2034.8852222222222,
              "speed2d": 2.552333333333333,
              "speed3d": 2.5922222222222224,
              "msFromStart": 226747.20355555546
            },
            {
              "lat": 43.760189411111114,
              "lng": -116.09540179999999,
              "alt": 2035.1493333333333,
              "speed2d": 2.5677777777777777,
              "speed3d": 2.638888888888889,
              "msFromStart": 227242.17715399602
            },
            {
              "lat": 43.76018254444445,
              "lng": -116.09539983333335,
              "alt": 2035.9275555555555,
              "speed2d": 2.1069999999999998,
              "speed3d": 2.1977777777777776,
              "msFromStart": 227737.92673684214
            },
            {
              "lat": 43.760173466666664,
              "lng": -116.09539872222223,
              "alt": 2036.5525555555555,
              "speed2d": 2.1213333333333333,
              "speed3d": 2.166666666666667,
              "msFromStart": 228233.78114619886
            },
            {
              "lat": 43.7601635,
              "lng": -116.09539815555557,
              "alt": 2036.4935555555553,
              "speed2d": 2.0294444444444446,
              "speed3d": 2.1,
              "msFromStart": 228727.94133333326
            },
            {
              "lat": 43.76015467,
              "lng": -116.09539674000001,
              "alt": 2037.1304,
              "speed2d": 1.9891999999999999,
              "speed3d": 2.0290000000000004,
              "msFromStart": 229248.98559999996
            },
            {
              "lat": 43.760142811111116,
              "lng": -116.09539372222223,
              "alt": 2037.3697777777777,
              "speed2d": 2.245444444444445,
              "speed3d": 2.2766666666666664,
              "msFromStart": 229771.0453333333
            },
            {
              "lat": 43.760132122222224,
              "lng": -116.09539154444444,
              "alt": 2037.795222222222,
              "speed2d": 2.3388888888888886,
              "speed3d": 2.3566666666666665,
              "msFromStart": 230265.97096296298
            },
            {
              "lat": 43.760119777777774,
              "lng": -116.09538865555555,
              "alt": 2037.9406666666669,
              "speed2d": 2.469555555555556,
              "speed3d": 2.5155555555555558,
              "msFromStart": 230761.3528888889
            },
            {
              "lat": 43.76010968888889,
              "lng": -116.09538881111109,
              "alt": 2037.7824444444448,
              "speed2d": 2.1915555555555555,
              "speed3d": 2.2933333333333334,
              "msFromStart": 231256.75741130603
            },
            {
              "lat": 43.76009962222223,
              "lng": -116.09538646666667,
              "alt": 2038.0581111111107,
              "speed2d": 2.244111111111111,
              "speed3d": 2.2199999999999998,
              "msFromStart": 231751.24715789466
            },
            {
              "lat": 43.76008786666667,
              "lng": -116.09538322222222,
              "alt": 2038.6096666666667,
              "speed2d": 2.4763333333333337,
              "speed3d": 2.496666666666667,
              "msFromStart": 232245.55602339172
            },
            {
              "lat": 43.760076711111104,
              "lng": -116.09537928888889,
              "alt": 2038.8306666666667,
              "speed2d": 2.4588888888888887,
              "speed3d": 2.5222222222222226,
              "msFromStart": 232740.77333333343
            },
            {
              "lat": 43.760066655555555,
              "lng": -116.09537802222222,
              "alt": 2039.2168888888887,
              "speed2d": 2.401333333333333,
              "speed3d": 2.417777777777778,
              "msFromStart": 233236.2989629631
            },
            {
              "lat": 43.760056266666666,
              "lng": -116.09537313333334,
              "alt": 2039.9195555555557,
              "speed2d": 2.531,
              "speed3d": 2.5811111111111114,
              "msFromStart": 233731.3235555556
            },
            {
              "lat": 43.7600457,
              "lng": -116.09536829999999,
              "alt": 2040.659,
              "speed2d": 2.641111111111111,
              "speed3d": 2.673333333333334,
              "msFromStart": 234226.16829629635
            },
            {
              "lat": 43.76003290999999,
              "lng": -116.09536338999999,
              "alt": 2041.4515,
              "speed2d": 2.8117,
              "speed3d": 2.862,
              "msFromStart": 234748.60133333327
            },
            {
              "lat": 43.76002128888889,
              "lng": -116.09535798888889,
              "alt": 2042.1184444444445,
              "speed2d": 2.71,
              "speed3d": 2.8133333333333335,
              "msFromStart": 235271.06162962958
            },
            {
              "lat": 43.76000915555555,
              "lng": -116.09535393333334,
              "alt": 2043.0195555555558,
              "speed2d": 2.8531111111111116,
              "speed3d": 2.911111111111111,
              "msFromStart": 235765.96444444454
            },
            {
              "lat": 43.75999735555555,
              "lng": -116.0953509,
              "alt": 2043.4663333333335,
              "speed2d": 2.7135555555555557,
              "speed3d": 2.8144444444444447,
              "msFromStart": 236260.8947056531
            },
            {
              "lat": 43.759986222222224,
              "lng": -116.09534706666668,
              "alt": 2044.3997777777777,
              "speed2d": 2.705111111111111,
              "speed3d": 2.8099999999999996,
              "msFromStart": 236756.24757894722
            },
            {
              "lat": 43.75997367777777,
              "lng": -116.09534365555555,
              "alt": 2044.7666666666667,
              "speed2d": 2.708222222222222,
              "speed3d": 2.861111111111111,
              "msFromStart": 237251.6734191032
            },
            {
              "lat": 43.75996126666667,
              "lng": -116.09534114444445,
              "alt": 2044.5017777777778,
              "speed2d": 2.569888888888889,
              "speed3d": 2.6622222222222223,
              "msFromStart": 237746.4782222222
            },
            {
              "lat": 43.759948944444446,
              "lng": -116.09533836666668,
              "alt": 2044.3952222222222,
              "speed2d": 2.547777777777778,
              "speed3d": 2.6422222222222222,
              "msFromStart": 238241.06133333332
            },
            {
              "lat": 43.75993801111111,
              "lng": -116.09533572222222,
              "alt": 2044.6057777777778,
              "speed2d": 2.449666666666667,
              "speed3d": 2.5444444444444447,
              "msFromStart": 238735.7795555556
            },
            {
              "lat": 43.75993095555556,
              "lng": -116.09533147777776,
              "alt": 2045.4034444444442,
              "speed2d": 2.0887777777777776,
              "speed3d": 2.218888888888889,
              "msFromStart": 239230.54844444452
            },
            {
              "lat": 43.75992041111111,
              "lng": -116.09532853333334,
              "alt": 2045.5502222222224,
              "speed2d": 2.2034444444444445,
              "speed3d": 2.267777777777778,
              "msFromStart": 239725.3342222223
            },
            {
              "lat": 43.75990877000001,
              "lng": -116.09532610999997,
              "alt": 2045.7235,
              "speed2d": 2.2742,
              "speed3d": 2.3459999999999996,
              "msFromStart": 240247.63013333338
            },
            {
              "lat": 43.75989664444445,
              "lng": -116.0953247,
              "alt": 2046.3327777777777,
              "speed2d": 2.4248888888888884,
              "speed3d": 2.54,
              "msFromStart": 240770.10133333335
            },
            {
              "lat": 43.759885833333335,
              "lng": -116.09531983333332,
              "alt": 2047.2513333333332,
              "speed2d": 2.5562222222222224,
              "speed3d": 2.6022222222222227,
              "msFromStart": 241265.17773099412
            },
            {
              "lat": 43.75987281111111,
              "lng": -116.09531410000001,
              "alt": 2047.8714444444443,
              "speed2d": 2.7382222222222223,
              "speed3d": 2.8222222222222224,
              "msFromStart": 241760.7781052631
            },
            {
              "lat": 43.759861522222224,
              "lng": -116.09531143333334,
              "alt": 2048.6711111111113,
              "speed2d": 2.6219999999999994,
              "speed3d": 2.7822222222222224,
              "msFromStart": 242256.4524600389
            },
            {
              "lat": 43.75985103333333,
              "lng": -116.09531184444444,
              "alt": 2049.226777777778,
              "speed2d": 2.4431111111111115,
              "speed3d": 2.5922222222222224,
              "msFromStart": 242751.0435555555
            },
            {
              "lat": 43.7598400111111,
              "lng": -116.09531327777779,
              "alt": 2049.289,
              "speed2d": 2.3197777777777775,
              "speed3d": 2.4611111111111112,
              "msFromStart": 243245.30103703693
            },
            {
              "lat": 43.75983044444445,
              "lng": -116.09531162222223,
              "alt": 2049.8934444444444,
              "speed2d": 2.242,
              "speed3d": 2.3633333333333333,
              "msFromStart": 243740.80266666663
            },
            {
              "lat": 43.75981742222223,
              "lng": -116.09530676666667,
              "alt": 2050.090777777778,
              "speed2d": 2.4915555555555557,
              "speed3d": 2.5766666666666667,
              "msFromStart": 244236.74459259253
            },
            {
              "lat": 43.75980591111111,
              "lng": -116.0953042,
              "alt": 2050.4425555555554,
              "speed2d": 2.468777777777778,
              "speed3d": 2.6255555555555556,
              "msFromStart": 244732.34311111108
            },
            {
              "lat": 43.7597968,
              "lng": -116.09530471111111,
              "alt": 2050.6402222222223,
              "speed2d": 2.2698888888888886,
              "speed3d": 2.352222222222222,
              "msFromStart": 245227.80711111112
            },
            {
              "lat": 43.75978524000001,
              "lng": -116.0953031,
              "alt": 2051.0579,
              "speed2d": 2.4007000000000005,
              "speed3d": 2.513,
              "msFromStart": 245750.63333333333
            },
            {
              "lat": 43.75977444444445,
              "lng": -116.0953049,
              "alt": 2051.553888888889,
              "speed2d": 2.379555555555556,
              "speed3d": 2.4844444444444447,
              "msFromStart": 246273.35318518511
            },
            {
              "lat": 43.7597629,
              "lng": -116.09530313333332,
              "alt": 2051.316222222222,
              "speed2d": 2.350111111111111,
              "speed3d": 2.418888888888889,
              "msFromStart": 246768.05511111103
            },
            {
              "lat": 43.75975103333333,
              "lng": -116.09530228888889,
              "alt": 2050.958222222222,
              "speed2d": 2.347555555555555,
              "speed3d": 2.4244444444444446,
              "msFromStart": 247262.70827290445
            },
            {
              "lat": 43.759739855555566,
              "lng": -116.09530411111112,
              "alt": 2050.756,
              "speed2d": 2.3163333333333336,
              "speed3d": 2.3533333333333335,
              "msFromStart": 247758.1330526315
            },
            {
              "lat": 43.759728755555564,
              "lng": -116.09530636666665,
              "alt": 2050.719888888889,
              "speed2d": 2.322,
              "speed3d": 2.3788888888888895,
              "msFromStart": 248253.74385964902
            },
            {
              "lat": 43.75971900000001,
              "lng": -116.09530773333333,
              "alt": 2050.722333333333,
              "speed2d": 2.2048888888888887,
              "speed3d": 2.2444444444444445,
              "msFromStart": 248749.22400000007
            },
            {
              "lat": 43.75970828888889,
              "lng": -116.09530747777777,
              "alt": 2050.9657777777775,
              "speed2d": 2.2685555555555554,
              "speed3d": 2.298888888888889,
              "msFromStart": 249244.58844444455
            },
            {
              "lat": 43.75969555555556,
              "lng": -116.09530413333334,
              "alt": 2051.0268888888886,
              "speed2d": 2.5457777777777775,
              "speed3d": 2.5422222222222226,
              "msFromStart": 249738.66933333332
            },
            {
              "lat": 43.75968424444445,
              "lng": -116.09530167777778,
              "alt": 2051.174555555556,
              "speed2d": 2.525444444444444,
              "speed3d": 2.5522222222222224,
              "msFromStart": 250232.35229629627
            },
            {
              "lat": 43.759671355555554,
              "lng": -116.09529884444444,
              "alt": 2051.348,
              "speed2d": 2.6788888888888884,
              "speed3d": 2.701111111111111,
              "msFromStart": 250727.45955555548
            },
            {
              "lat": 43.759660159999996,
              "lng": -116.09529654,
              "alt": 2051.6574,
              "speed2d": 2.5799,
              "speed3d": 2.6300000000000003,
              "msFromStart": 251250.60853333326
            },
            {
              "lat": 43.75965293333334,
              "lng": -116.09529364444444,
              "alt": 2052.493888888889,
              "speed2d": 2.245,
              "speed3d": 2.3099999999999996,
              "msFromStart": 251773.55200000008
            },
            {
              "lat": 43.759643600000004,
              "lng": -116.09529227777777,
              "alt": 2052.526444444444,
              "speed2d": 2.178333333333333,
              "speed3d": 2.2,
              "msFromStart": 252268.82778947378
            },
            {
              "lat": 43.759631299999995,
              "lng": -116.09529100000002,
              "alt": 2052.2213333333334,
              "speed2d": 2.3357777777777775,
              "speed3d": 2.374444444444444,
              "msFromStart": 252763.2614736843
            },
            {
              "lat": 43.75961941111111,
              "lng": -116.09529031111111,
              "alt": 2051.960111111111,
              "speed2d": 2.424222222222222,
              "speed3d": 2.421111111111111,
              "msFromStart": 253257.54836647183
            },
            {
              "lat": 43.759609377777785,
              "lng": -116.09528812222221,
              "alt": 2052.3621111111115,
              "speed2d": 2.4705555555555554,
              "speed3d": 2.4822222222222226,
              "msFromStart": 253753.04622222224
            },
            {
              "lat": 43.75960096666666,
              "lng": -116.09528759999999,
              "alt": 2052.765777777778,
              "speed2d": 2.2951111111111113,
              "speed3d": 2.367777777777778,
              "msFromStart": 254248.938962963
            },
            {
              "lat": 43.7595936,
              "lng": -116.0952878222222,
              "alt": 2053.368222222222,
              "speed2d": 2.1344444444444446,
              "speed3d": 2.137777777777778,
              "msFromStart": 254743.85777777783
            },
            {
              "lat": 43.75957998888889,
              "lng": -116.09528575555555,
              "alt": 2053.416,
              "speed2d": 2.4063333333333334,
              "speed3d": 2.438888888888889,
              "msFromStart": 255238.42814814826
            },
            {
              "lat": 43.75956577777778,
              "lng": -116.09528927777777,
              "alt": 2053.6542222222224,
              "speed2d": 2.36,
              "speed3d": 2.368888888888889,
              "msFromStart": 255733.1955555556
            },
            {
              "lat": 43.7595513,
              "lng": -116.09529057777777,
              "alt": 2054.114888888889,
              "speed2d": 2.4033333333333333,
              "speed3d": 2.4355555555555557,
              "msFromStart": 256228.0746666667
            },
            {
              "lat": 43.75953759,
              "lng": -116.09529094,
              "alt": 2054.8509,
              "speed2d": 2.4314000000000004,
              "speed3d": 2.46,
              "msFromStart": 256751.24666666667
            },
            {
              "lat": 43.759521944444444,
              "lng": -116.0952925888889,
              "alt": 2055.136111111111,
              "speed2d": 2.5794444444444444,
              "speed3d": 2.5933333333333333,
              "msFromStart": 257274.63533723197
            },
            {
              "lat": 43.7595084,
              "lng": -116.0952924111111,
              "alt": 2055.338333333333,
              "speed2d": 2.562666666666667,
              "speed3d": 2.6166666666666667,
              "msFromStart": 257769.9031578948
            },
            {
              "lat": 43.759495799999996,
              "lng": -116.09529155555556,
              "alt": 2055.6434444444444,
              "speed2d": 2.521333333333333,
              "speed3d": 2.547777777777778,
              "msFromStart": 258265.01913450303
            },
            {
              "lat": 43.75948221111111,
              "lng": -116.09528912222221,
              "alt": 2056.202444444444,
              "speed2d": 2.708,
              "speed3d": 2.718888888888889,
              "msFromStart": 258759.96266666666
            },
            {
              "lat": 43.75947005555555,
              "lng": -116.0952878,
              "alt": 2056.5319999999997,
              "speed2d": 2.627888888888889,
              "speed3d": 2.6833333333333336,
              "msFromStart": 259254.80651851848
            },
            {
              "lat": 43.759457488888884,
              "lng": -116.0952862,
              "alt": 2056.6563333333334,
              "speed2d": 2.6435555555555554,
              "speed3d": 2.687777777777778,
              "msFromStart": 259748.96355555567
            },
            {
              "lat": 43.759448033333335,
              "lng": -116.09528443333332,
              "alt": 2056.861888888889,
              "speed2d": 2.2932222222222225,
              "speed3d": 2.3677777777777775,
              "msFromStart": 260242.92325925943
            },
            {
              "lat": 43.75943816666666,
              "lng": -116.09528382222223,
              "alt": 2056.9673333333335,
              "speed2d": 2.192555555555556,
              "speed3d": 2.24,
              "msFromStart": 260737.94133333338
            },
            {
              "lat": 43.75942942222222,
              "lng": -116.09528344444445,
              "alt": 2057.3105555555558,
              "speed2d": 2.181333333333333,
              "speed3d": 2.2111111111111112,
              "msFromStart": 261233.34933333343
            },
            {
              "lat": 43.759422155555555,
              "lng": -116.095283,
              "alt": 2057.7791111111114,
              "speed2d": 2.0364444444444443,
              "speed3d": 2.0966666666666667,
              "msFromStart": 261728.75733333337
            },
            {
              "lat": 43.759416480000006,
              "lng": -116.09528172,
              "alt": 2059.0543,
              "speed2d": 1.9575,
              "speed3d": 1.995,
              "msFromStart": 262251.6676210527
            },
            {
              "lat": 43.75940967777778,
              "lng": -116.0952816111111,
              "alt": 2060.5350000000003,
              "speed2d": 2.101111111111111,
              "speed3d": 2.1466666666666665,
              "msFromStart": 262774.34694736864
            },
            {
              "lat": 43.75939954444444,
              "lng": -116.09527964444445,
              "alt": 2061.2275555555557,
              "speed2d": 2.4303333333333335,
              "speed3d": 2.453333333333333,
              "msFromStart": 263269.3930292401
            },
            {
              "lat": 43.75938671111112,
              "lng": -116.0952770222222,
              "alt": 2060.88,
              "speed2d": 2.559555555555556,
              "speed3d": 2.5788888888888892,
              "msFromStart": 263763.37066666665
            },
            {
              "lat": 43.75937481111111,
              "lng": -116.09527625555556,
              "alt": 2060.761444444444,
              "speed2d": 2.5588888888888888,
              "speed3d": 2.572222222222222,
              "msFromStart": 264257.0293333333
            },
            {
              "lat": 43.75936248888889,
              "lng": -116.09527452222221,
              "alt": 2060.788777777778,
              "speed2d": 2.586777777777778,
              "speed3d": 2.6122222222222224,
              "msFromStart": 264752.1066666666
            },
            {
              "lat": 43.75935011111111,
              "lng": -116.09527114444445,
              "alt": 2060.942,
              "speed2d": 2.6401111111111106,
              "speed3d": 2.651111111111111,
              "msFromStart": 265247.695111111
            },
            {
              "lat": 43.759339077777774,
              "lng": -116.09526772222223,
              "alt": 2060.916333333333,
              "speed2d": 2.412888888888889,
              "speed3d": 2.492222222222223,
              "msFromStart": 265743.0640000001
            },
            {
              "lat": 43.759329433333335,
              "lng": -116.09526571111111,
              "alt": 2060.3754444444444,
              "speed2d": 2.1683333333333334,
              "speed3d": 2.161111111111111,
              "msFromStart": 266238.3851851853
            },
            {
              "lat": 43.75931805555555,
              "lng": -116.09526314444443,
              "alt": 2060.2512222222226,
              "speed2d": 2.352777777777778,
              "speed3d": 2.3633333333333333,
              "msFromStart": 266734.33688888897
            },
            {
              "lat": 43.75930723333333,
              "lng": -116.09526066666666,
              "alt": 2060.1404444444443,
              "speed2d": 2.3507777777777776,
              "speed3d": 2.3633333333333333,
              "msFromStart": 267230.5024405459
            },
            {
              "lat": 43.759296866666666,
              "lng": -116.09525736666667,
              "alt": 2060.346777777778,
              "speed2d": 2.3265555555555557,
              "speed3d": 2.3433333333333333,
              "msFromStart": 267726.3174736844
            },
            {
              "lat": 43.75928362,
              "lng": -116.0952517,
              "alt": 2060.4769,
              "speed2d": 2.5503,
              "speed3d": 2.5220000000000002,
              "msFromStart": 268249.49861052656
            },
            {
              "lat": 43.75926784444444,
              "lng": -116.09524532222223,
              "alt": 2060.4302222222223,
              "speed2d": 2.929111111111111,
              "speed3d": 2.9255555555555555,
              "msFromStart": 268771.76533333346
            },
            {
              "lat": 43.75925554444445,
              "lng": -116.09524221111111,
              "alt": 2060.164111111111,
              "speed2d": 2.5705555555555555,
              "speed3d": 2.661111111111111,
              "msFromStart": 269266.16000000015
            },
            {
              "lat": 43.75924477777778,
              "lng": -116.09523953333333,
              "alt": 2060.2056666666667,
              "speed2d": 2.4008888888888893,
              "speed3d": 2.385555555555556,
              "msFromStart": 269760.4533333334
            },
            {
              "lat": 43.75923053333334,
              "lng": -116.09523275555557,
              "alt": 2060.6998888888893,
              "speed2d": 2.9323333333333337,
              "speed3d": 2.891111111111111,
              "msFromStart": 270254.763851852
            },
            {
              "lat": 43.75921705555555,
              "lng": -116.09522609999999,
              "alt": 2060.9156666666668,
              "speed2d": 2.9321111111111113,
              "speed3d": 2.99,
              "msFromStart": 270750.1102222224
            },
            {
              "lat": 43.75920254444444,
              "lng": -116.09522061111112,
              "alt": 2060.6872222222223,
              "speed2d": 3.0292222222222223,
              "speed3d": 3.017777777777778,
              "msFromStart": 271245.8038518521
            },
            {
              "lat": 43.75918771111112,
              "lng": -116.09521362222222,
              "alt": 2060.9235555555556,
              "speed2d": 3.1782222222222223,
              "speed3d": 3.2322222222222226,
              "msFromStart": 271740.84444444434
            },
            {
              "lat": 43.75917624444444,
              "lng": -116.09521029999999,
              "alt": 2061.017777777778,
              "speed2d": 2.7524444444444445,
              "speed3d": 2.847777777777778,
              "msFromStart": 272235.62757114996
            },
            {
              "lat": 43.75916475555555,
              "lng": -116.09520800000001,
              "alt": 2060.8385555555556,
              "speed2d": 2.489444444444445,
              "speed3d": 2.5533333333333332,
              "msFromStart": 272730.09010526334
            },
            {
              "lat": 43.75915287,
              "lng": -116.09520534999999,
              "alt": 2060.5928,
              "speed2d": 2.3926000000000003,
              "speed3d": 2.4099999999999997,
              "msFromStart": 273251.9186245617
            },
            {
              "lat": 43.759140699999996,
              "lng": -116.09520135555556,
              "alt": 2060.684,
              "speed2d": 2.4610000000000003,
              "speed3d": 2.4955555555555557,
              "msFromStart": 273774.18488888897
            },
            {
              "lat": 43.759129044444435,
              "lng": -116.09519848888888,
              "alt": 2060.5041111111113,
              "speed2d": 2.4396666666666667,
              "speed3d": 2.49,
              "msFromStart": 274269.1205925927
            },
            {
              "lat": 43.75911893333333,
              "lng": -116.0951952111111,
              "alt": 2060.500666666667,
              "speed2d": 2.2701111111111114,
              "speed3d": 2.29,
              "msFromStart": 274763.59466666676
            },
            {
              "lat": 43.75910722222222,
              "lng": -116.09519362222223,
              "alt": 2060.3397777777777,
              "speed2d": 2.4015555555555554,
              "speed3d": 2.422222222222222,
              "msFromStart": 275257.97688888904
            },
            {
              "lat": 43.759096722222225,
              "lng": -116.09519276666664,
              "alt": 2060.300444444444,
              "speed2d": 2.304888888888889,
              "speed3d": 2.346666666666667,
              "msFromStart": 275753.84533333336
            },
            {
              "lat": 43.759085244444435,
              "lng": -116.0951897111111,
              "alt": 2060.4024444444444,
              "speed2d": 2.4266666666666663,
              "speed3d": 2.421111111111111,
              "msFromStart": 276250.2032592593
            },
            {
              "lat": 43.75907313333333,
              "lng": -116.09518796666667,
              "alt": 2060.222222222222,
              "speed2d": 2.456,
              "speed3d": 2.4899999999999998,
              "msFromStart": 276745.4577777777
            },
            {
              "lat": 43.759062088888896,
              "lng": -116.09518618888887,
              "alt": 2059.951777777778,
              "speed2d": 2.3393333333333333,
              "speed3d": 2.3544444444444443,
              "msFromStart": 277240.30846003885
            },
            {
              "lat": 43.75905004444445,
              "lng": -116.09518325555555,
              "alt": 2059.448222222222,
              "speed2d": 2.3771111111111107,
              "speed3d": 2.3677777777777775,
              "msFromStart": 277735.21010526305
            },
            {
              "lat": 43.75903702222222,
              "lng": -116.09518017777779,
              "alt": 2059.3687777777777,
              "speed2d": 2.546777777777778,
              "speed3d": 2.542222222222222,
              "msFromStart": 278230.14503963594
            },
            {
              "lat": 43.75902497777778,
              "lng": -116.09517845555557,
              "alt": 2059.3777777777777,
              "speed2d": 2.5422222222222226,
              "speed3d": 2.5677777777777777,
              "msFromStart": 278725.83466666675
            },
            {
              "lat": 43.759014119999996,
              "lng": -116.09517636000001,
              "alt": 2059.6415,
              "speed2d": 2.3912,
              "speed3d": 2.379,
              "msFromStart": 279249.428088889
            },
            {
              "lat": 43.75900132222222,
              "lng": -116.09517183333331,
              "alt": 2060.1542222222224,
              "speed2d": 2.5742222222222226,
              "speed3d": 2.606666666666667,
              "msFromStart": 279771.66755555547
            },
            {
              "lat": 43.758991377777775,
              "lng": -116.09516857777778,
              "alt": 2060.816222222222,
              "speed2d": 2.43,
              "speed3d": 2.4611111111111112,
              "msFromStart": 280265.86074074067
            },
            {
              "lat": 43.75898192222222,
              "lng": -116.09516621111109,
              "alt": 2061.2542222222223,
              "speed2d": 2.2622222222222224,
              "speed3d": 2.2888888888888888,
              "msFromStart": 280760.0764444446
            },
            {
              "lat": 43.75897015555555,
              "lng": -116.09516384444443,
              "alt": 2061.327,
              "speed2d": 2.4522222222222223,
              "speed3d": 2.4522222222222227,
              "msFromStart": 281254.39822222246
            },
            {
              "lat": 43.7589559,
              "lng": -116.09515891111113,
              "alt": 2061.1948888888887,
              "speed2d": 2.834555555555555,
              "speed3d": 2.805555555555556,
              "msFromStart": 281750.5777777776
            },
            {
              "lat": 43.75894237777778,
              "lng": -116.09515400000001,
              "alt": 2061.0623333333333,
              "speed2d": 2.882111111111111,
              "speed3d": 2.9,
              "msFromStart": 282247.3192670563
            },
            {
              "lat": 43.758931077777774,
              "lng": -116.09515035555555,
              "alt": 2061.518777777778,
              "speed2d": 2.7586666666666666,
              "speed3d": 2.8066666666666666,
              "msFromStart": 282741.7330526315
            },
            {
              "lat": 43.75892062222222,
              "lng": -116.09514726666666,
              "alt": 2061.619888888889,
              "speed2d": 2.5340000000000003,
              "speed3d": 2.5644444444444443,
              "msFromStart": 283235.3056062376
            },
            {
              "lat": 43.758911633333334,
              "lng": -116.09514621111111,
              "alt": 2061.6104444444445,
              "speed2d": 2.2235555555555555,
              "speed3d": 2.267777777777778,
              "msFromStart": 283729.7280000002
            },
            {
              "lat": 43.75890014000001,
              "lng": -116.09514372999999,
              "alt": 2061.7842,
              "speed2d": 2.4569,
              "speed3d": 2.4320000000000004,
              "msFromStart": 284252.122666667
            },
            {
              "lat": 43.75888967777779,
              "lng": -116.09514170000001,
              "alt": 2061.960777777778,
              "speed2d": 2.320777777777778,
              "speed3d": 2.38,
              "msFromStart": 284774.9724444445
            },
            {
              "lat": 43.75888038888888,
              "lng": -116.09513941111112,
              "alt": 2062.525111111111,
              "speed2d": 2.331444444444444,
              "speed3d": 2.312222222222222,
              "msFromStart": 285270.51970370376
            },
            {
              "lat": 43.7588694888889,
              "lng": -116.09513491111112,
              "alt": 2063.1834444444444,
              "speed2d": 2.570666666666667,
              "speed3d": 2.5644444444444443,
              "msFromStart": 285766.58488888876
            },
            {
              "lat": 43.75885956666667,
              "lng": -116.09513020000001,
              "alt": 2064.5996666666665,
              "speed2d": 2.6564444444444444,
              "speed3d": 2.722222222222222,
              "msFromStart": 286262.7721481479
            },
            {
              "lat": 43.75885474444445,
              "lng": -116.09512763333333,
              "alt": 2067.1401111111113,
              "speed2d": 2.4718888888888886,
              "speed3d": 2.5655555555555556,
              "msFromStart": 286757.65333333344
            },
            {
              "lat": 43.75884934444444,
              "lng": -116.09512575555556,
              "alt": 2069.3412222222223,
              "speed2d": 2.330888888888889,
              "speed3d": 2.438888888888889,
              "msFromStart": 287252.0782222224
            },
            {
              "lat": 43.758843411111116,
              "lng": -116.09512285555556,
              "alt": 2071.320222222222,
              "speed2d": 2.269444444444445,
              "speed3d": 2.3966666666666665,
              "msFromStart": 287746.9759999998
            },
            {
              "lat": 43.75883666666666,
              "lng": -116.09512041111111,
              "alt": 2072.883777777778,
              "speed2d": 2.255888888888889,
              "speed3d": 2.416666666666667,
              "msFromStart": 288242.04977777746
            },
            {
              "lat": 43.75882637777777,
              "lng": -116.0951182888889,
              "alt": 2072.911666666667,
              "speed2d": 2.3356666666666666,
              "speed3d": 2.4444444444444446,
              "msFromStart": 288737.21599999984
            },
            {
              "lat": 43.75881505555555,
              "lng": -116.09511481111113,
              "alt": 2073.0280000000002,
              "speed2d": 2.442888888888889,
              "speed3d": 2.558888888888889,
              "msFromStart": 289232.4189629627
            },
            {
              "lat": 43.75880694444445,
              "lng": -116.0951134888889,
              "alt": 2073.5567777777774,
              "speed2d": 2.2376666666666667,
              "speed3d": 2.3533333333333335,
              "msFromStart": 289726.9440000001
            },
            {
              "lat": 43.75879392000001,
              "lng": -116.09510924,
              "alt": 2074.0919,
              "speed2d": 2.5457,
              "speed3d": 2.609,
              "msFromStart": 290248.5968000001
            },
            {
              "lat": 43.75877963333333,
              "lng": -116.09510386666665,
              "alt": 2074.3611111111113,
              "speed2d": 2.842777777777778,
              "speed3d": 2.9444444444444438,
              "msFromStart": 290771.0915555557
            },
            {
              "lat": 43.75877002222222,
              "lng": -116.09510420000001,
              "alt": 2074.2725555555558,
              "speed2d": 2.322,
              "speed3d": 2.4800000000000004,
              "msFromStart": 291266.41066666687
            },
            {
              "lat": 43.75875945555556,
              "lng": -116.09510331111112,
              "alt": 2074.0915555555557,
              "speed2d": 2.204,
              "speed3d": 2.305555555555556,
              "msFromStart": 291761.2568888889
            },
            {
              "lat": 43.75874944444445,
              "lng": -116.09510432222224,
              "alt": 2074.088222222222,
              "speed2d": 2.143555555555556,
              "speed3d": 2.2177777777777776,
              "msFromStart": 292255.95717738796
            },
            {
              "lat": 43.75873887777779,
              "lng": -116.0951029,
              "alt": 2073.9772222222223,
              "speed2d": 2.126555555555555,
              "speed3d": 2.2044444444444444,
              "msFromStart": 292751.1949473685
            },
            {
              "lat": 43.75872726666667,
              "lng": -116.09510077777776,
              "alt": 2073.710555555556,
              "speed2d": 2.2620000000000005,
              "speed3d": 2.3,
              "msFromStart": 293246.6465912931
            },
            {
              "lat": 43.758715599999995,
              "lng": -116.09509788888889,
              "alt": 2073.826777777778,
              "speed2d": 2.403111111111111,
              "speed3d": 2.4633333333333334,
              "msFromStart": 293742.92266666656
            },
            {
              "lat": 43.75870470000001,
              "lng": -116.09509583333333,
              "alt": 2073.8924444444447,
              "speed2d": 2.3667777777777776,
              "speed3d": 2.4344444444444444,
              "msFromStart": 294239.59545678983
            },
            {
              "lat": 43.75869395555555,
              "lng": -116.09509468888888,
              "alt": 2074.0036666666665,
              "speed2d": 2.3131111111111116,
              "speed3d": 2.3811111111111107,
              "msFromStart": 294733.81333333347
            },
            {
              "lat": 43.75868301111111,
              "lng": -116.0950935888889,
              "alt": 2073.7257777777777,
              "speed2d": 2.2697777777777777,
              "speed3d": 2.312222222222222,
              "msFromStart": 295226.739160494
            },
            {
              "lat": 43.758669909999995,
              "lng": -116.09509128,
              "alt": 2073.6454,
              "speed2d": 2.5103,
              "speed3d": 2.5140000000000002,
              "msFromStart": 295748.73333333316
            },
            {
              "lat": 43.75865736666667,
              "lng": -116.09508877777778,
              "alt": 2073.6659999999997,
              "speed2d": 2.530555555555556,
              "speed3d": 2.5811111111111114,
              "msFromStart": 296271.5561481479
            },
            {
              "lat": 43.758645144444436,
              "lng": -116.09508555555556,
              "alt": 2073.510222222222,
              "speed2d": 2.580444444444444,
              "speed3d": 2.6166666666666663,
              "msFromStart": 296766.7377777777
            },
            {
              "lat": 43.758633277777776,
              "lng": -116.09508306666667,
              "alt": 2073.340111111111,
              "speed2d": 2.5012222222222222,
              "speed3d": 2.5388888888888888,
              "msFromStart": 297261.85175828455
            },
            {
              "lat": 43.75862213333334,
              "lng": -116.09508241111112,
              "alt": 2073.257888888889,
              "speed2d": 2.4513333333333334,
              "speed3d": 2.48,
              "msFromStart": 297756.5473684209
            },
            {
              "lat": 43.758611388888895,
              "lng": -116.09508106666667,
              "alt": 2073.078666666667,
              "speed2d": 2.326111111111111,
              "speed3d": 2.36,
              "msFromStart": 298251.08867576334
            },
            {
              "lat": 43.75860115555556,
              "lng": -116.09508038888889,
              "alt": 2073.238666666667,
              "speed2d": 2.3195555555555556,
              "speed3d": 2.3322222222222218,
              "msFromStart": 298745.6213333333
            },
            {
              "lat": 43.75859002222222,
              "lng": -116.09507856666669,
              "alt": 2073.382,
              "speed2d": 2.368888888888889,
              "speed3d": 2.41,
              "msFromStart": 299240.1680987654
            },
            {
              "lat": 43.75857947777778,
              "lng": -116.09507710000001,
              "alt": 2073.1937777777775,
              "speed2d": 2.278888888888889,
              "speed3d": 2.3099999999999996,
              "msFromStart": 299735.6906666668
            },
            {
              "lat": 43.758568744444446,
              "lng": -116.09507426666664,
              "alt": 2073.305,
              "speed2d": 2.389555555555556,
              "speed3d": 2.367777777777778,
              "msFromStart": 300231.7280000002
            },
            {
              "lat": 43.75855780000001,
              "lng": -116.0950703888889,
              "alt": 2073.9635555555556,
              "speed2d": 2.5342222222222226,
              "speed3d": 2.576666666666667,
              "msFromStart": 300727.2106666666
            },
            {
              "lat": 43.75854595,
              "lng": -116.09506677,
              "alt": 2074.288,
              "speed2d": 2.6174,
              "speed3d": 2.639,
              "msFromStart": 301249.8654222222
            },
            {
              "lat": 43.7585337,
              "lng": -116.09506524444443,
              "alt": 2074.1915555555556,
              "speed2d": 2.542111111111111,
              "speed3d": 2.586666666666667,
              "msFromStart": 301771.67822222214
            },
            {
              "lat": 43.75852257777778,
              "lng": -116.09506374444443,
              "alt": 2074.0420000000004,
              "speed2d": 2.486777777777778,
              "speed3d": 2.5155555555555553,
              "msFromStart": 302265.79034697847
            },
            {
              "lat": 43.75851087777778,
              "lng": -116.09506001111112,
              "alt": 2074.235555555556,
              "speed2d": 2.5501111111111108,
              "speed3d": 2.5655555555555556,
              "msFromStart": 302762.0328421055
            },
            {
              "lat": 43.758499244444444,
              "lng": -116.09505779999999,
              "alt": 2074.521222222222,
              "speed2d": 2.613888888888889,
              "speed3d": 2.6422222222222222,
              "msFromStart": 303259.0192800523
            },
            {
              "lat": 43.758489000000004,
              "lng": -116.09505562222223,
              "alt": 2074.8917777777774,
              "speed2d": 2.4766666666666666,
              "speed3d": 2.525555555555555,
              "msFromStart": 303753.87733333325
            },
            {
              "lat": 43.758479144444436,
              "lng": -116.09505206666665,
              "alt": 2075.6533333333336,
              "speed2d": 2.4406666666666665,
              "speed3d": 2.4899999999999998,
              "msFromStart": 304247.60138271586
            },
            {
              "lat": 43.758467333333336,
              "lng": -116.09504841111111,
              "alt": 2075.9713333333334,
              "speed2d": 2.6021111111111113,
              "speed3d": 2.6411111111111114,
              "msFromStart": 304741.95200000005
            },
            {
              "lat": 43.758456,
              "lng": -116.09504476666666,
              "alt": 2076.2077777777777,
              "speed2d": 2.624111111111111,
              "speed3d": 2.677777777777778,
              "msFromStart": 305236.6637037039
            },
            {
              "lat": 43.75844542222222,
              "lng": -116.09504065555555,
              "alt": 2076.967222222222,
              "speed2d": 2.5805555555555557,
              "speed3d": 2.6566666666666667,
              "msFromStart": 305732.608
            },
            {
              "lat": 43.758434477777776,
              "lng": -116.09503768888888,
              "alt": 2077.5686666666666,
              "speed2d": 2.5413333333333337,
              "speed3d": 2.6322222222222225,
              "msFromStart": 306229.1968395063
            },
            {
              "lat": 43.75842479000001,
              "lng": -116.09503449,
              "alt": 2078.542,
              "speed2d": 2.3531999999999997,
              "speed3d": 2.5070000000000006,
              "msFromStart": 306752.29244444455
            },
            {
              "lat": 43.758414300000005,
              "lng": -116.09503046666666,
              "alt": 2078.9817777777776,
              "speed2d": 2.3102222222222224,
              "speed3d": 2.435555555555556,
              "msFromStart": 307274.8106042886
            },
            {
              "lat": 43.758404122222224,
              "lng": -116.09502856666667,
              "alt": 2079.1073333333334,
              "speed2d": 2.2317777777777774,
              "speed3d": 2.38,
              "msFromStart": 307769.0475789474
            },
            {
              "lat": 43.7583939,
              "lng": -116.0950261777778,
              "alt": 2079.2328888888887,
              "speed2d": 2.237111111111111,
              "speed3d": 2.3477777777777775,
              "msFromStart": 308263.05223651725
            },
            {
              "lat": 43.7583836,
              "lng": -116.09502474444444,
              "alt": 2079.224222222222,
              "speed2d": 2.1954444444444445,
              "speed3d": 2.312222222222222,
              "msFromStart": 308759.8826666667
            },
            {
              "lat": 43.75837383333333,
              "lng": -116.09502293333333,
              "alt": 2079.291,
              "speed2d": 2.1222222222222222,
              "speed3d": 2.258888888888889,
              "msFromStart": 309258.1742222223
            },
            {
              "lat": 43.75836436666667,
              "lng": -116.09502073333333,
              "alt": 2079.3725555555557,
              "speed2d": 2.075222222222222,
              "speed3d": 2.1566666666666667,
              "msFromStart": 309753.32266666676
            },
            {
              "lat": 43.7583534,
              "lng": -116.09501963333334,
              "alt": 2079.1641111111107,
              "speed2d": 2.1646666666666667,
              "speed3d": 2.2377777777777776,
              "msFromStart": 310246.77135802485
            },
            {
              "lat": 43.75834046666667,
              "lng": -116.09501802222223,
              "alt": 2078.894,
              "speed2d": 2.4307777777777777,
              "speed3d": 2.4844444444444447,
              "msFromStart": 310739.8399999999
            },
            {
              "lat": 43.7583293,
              "lng": -116.09501832222222,
              "alt": 2079.0407777777777,
              "speed2d": 2.4257777777777774,
              "speed3d": 2.4944444444444445,
              "msFromStart": 311232.7361975307
            },
            {
              "lat": 43.758319111111106,
              "lng": -116.09501738888888,
              "alt": 2079.110777777778,
              "speed2d": 2.241444444444445,
              "speed3d": 2.3222222222222224,
              "msFromStart": 311727.3066666667
            },
            {
              "lat": 43.75830886000001,
              "lng": -116.09501842999998,
              "alt": 2078.582,
              "speed2d": 2.098,
              "speed3d": 2.16,
              "msFromStart": 312250.29660818714
            },
            {
              "lat": 43.75829794444445,
              "lng": -116.09501893333332,
              "alt": 2078.457333333333,
              "speed2d": 2.173888888888889,
              "speed3d": 2.194444444444444,
              "msFromStart": 312773.10989473696
            },
            {
              "lat": 43.758285355555564,
              "lng": -116.09501542222222,
              "alt": 2078.334,
              "speed2d": 2.3733333333333335,
              "speed3d": 2.41,
              "msFromStart": 313268.32784925296
            },
            {
              "lat": 43.7582769,
              "lng": -116.0950095,
              "alt": 2078.630222222222,
              "speed2d": 2.1440000000000006,
              "speed3d": 2.167777777777778,
              "msFromStart": 313763.2533333333
            },
            {
              "lat": 43.758266600000006,
              "lng": -116.09500650000001,
              "alt": 2078.873888888889,
              "speed2d": 2.242888888888889,
              "speed3d": 2.2899999999999996,
              "msFromStart": 314258.023308642
            },
            {
              "lat": 43.75825667777778,
              "lng": -116.09500568888892,
              "alt": 2078.937,
              "speed2d": 2.2093333333333334,
              "speed3d": 2.224444444444444,
              "msFromStart": 314752.8959999998
            },
            {
              "lat": 43.75824524444444,
              "lng": -116.09500376666666,
              "alt": 2079.1563333333334,
              "speed2d": 2.4043333333333337,
              "speed3d": 2.421111111111111,
              "msFromStart": 315247.8415802466
            },
            {
              "lat": 43.75823316666666,
              "lng": -116.09500154444444,
              "alt": 2079.2093333333332,
              "speed2d": 2.541222222222222,
              "speed3d": 2.56,
              "msFromStart": 315743.7013333333
            },
            {
              "lat": 43.75822341111112,
              "lng": -116.09500045555555,
              "alt": 2079.4483333333333,
              "speed2d": 2.4174444444444445,
              "speed3d": 2.4522222222222223,
              "msFromStart": 316240.03417283937
            },
            {
              "lat": 43.758212588888895,
              "lng": -116.09499802222223,
              "alt": 2079.872888888889,
              "speed2d": 2.487888888888889,
              "speed3d": 2.5066666666666664,
              "msFromStart": 316735.37066666665
            },
            {
              "lat": 43.7582015,
              "lng": -116.09499594444445,
              "alt": 2080.2381111111113,
              "speed2d": 2.5589999999999997,
              "speed3d": 2.606666666666667,
              "msFromStart": 317230.16766211815
            },
            {
              "lat": 43.758189449999996,
              "lng": -116.09499356999999,
              "alt": 2080.716,
              "speed2d": 2.5660000000000007,
              "speed3d": 2.5980000000000008,
              "msFromStart": 317752.28378947376
            },
            {
              "lat": 43.7581771,
              "lng": -116.0949923888889,
              "alt": 2080.7175555555555,
              "speed2d": 2.4614444444444445,
              "speed3d": 2.517777777777778,
              "msFromStart": 318274.3132631581
            },
            {
              "lat": 43.75816667777778,
              "lng": -116.09499308888891,
              "alt": 2080.571,
              "speed2d": 2.253222222222222,
              "speed3d": 2.3066666666666666,
              "msFromStart": 318768.73600000015
            },
            {
              "lat": 43.75815804444445,
              "lng": -116.09499359999998,
              "alt": 2080.478333333333,
              "speed2d": 1.9452222222222224,
              "speed3d": 1.9799999999999998,
              "msFromStart": 319263.08701234584
            },
            {
              "lat": 43.75814882222222,
              "lng": -116.09499334444446,
              "alt": 2080.966111111111,
              "speed2d": 2.033333333333333,
              "speed3d": 2.04,
              "msFromStart": 319757.3866666665
            },
            {
              "lat": 43.758138277777775,
              "lng": -116.09499083333333,
              "alt": 2081.4204444444445,
              "speed2d": 2.239888888888889,
              "speed3d": 2.231111111111111,
              "msFromStart": 320251.67446913547
            },
            {
              "lat": 43.758127144444444,
              "lng": -116.09498873333332,
              "alt": 2081.585777777778,
              "speed2d": 2.290222222222222,
              "speed3d": 2.331111111111111,
              "msFromStart": 320746.784
            },
            {
              "lat": 43.75811817777778,
              "lng": -116.09498895555558,
              "alt": 2081.8066666666664,
              "speed2d": 2.0822222222222218,
              "speed3d": 2.122222222222222,
              "msFromStart": 321242.33204938256
            },
            {
              "lat": 43.75810781111111,
              "lng": -116.0949886,
              "alt": 2081.7773333333334,
              "speed2d": 2.0514444444444444,
              "speed3d": 2.0555555555555554,
              "msFromStart": 321737.67466666666
            },
            {
              "lat": 43.75809889999999,
              "lng": -116.09498908888888,
              "alt": 2082.108333333333,
              "speed2d": 2.026777777777778,
              "speed3d": 2.037777777777778,
              "msFromStart": 322232.9132995451
            },
            {
              "lat": 43.7580888,
              "lng": -116.09498869999999,
              "alt": 2082.1092222222223,
              "speed2d": 2.1101111111111113,
              "speed3d": 2.1244444444444444,
              "msFromStart": 322728.4968421051
            },
            {
              "lat": 43.75807897,
              "lng": -116.09498626000001,
              "alt": 2082.7688,
              "speed2d": 2.1965,
              "speed3d": 2.165,
              "msFromStart": 323251.797539181
            },
            {
              "lat": 43.7580656888889,
              "lng": -116.0949816,
              "alt": 2083.353555555556,
              "speed2d": 2.5874444444444444,
              "speed3d": 2.5888888888888886,
              "msFromStart": 323774.4426666666
            },
            {
              "lat": 43.75805376666666,
              "lng": -116.09497837777779,
              "alt": 2084.2453333333333,
              "speed2d": 2.715444444444444,
              "speed3d": 2.74,
              "msFromStart": 324269.19506172824
            },
            {
              "lat": 43.75804216666667,
              "lng": -116.0949767111111,
              "alt": 2084.8904444444443,
              "speed2d": 2.672888888888889,
              "speed3d": 2.7133333333333334,
              "msFromStart": 324763.9680000002
            },
            {
              "lat": 43.758032199999995,
              "lng": -116.09497272222224,
              "alt": 2085.5253333333335,
              "speed2d": 2.507111111111111,
              "speed3d": 2.5855555555555556,
              "msFromStart": 325258.7559506176
            },
            {
              "lat": 43.758025377777784,
              "lng": -116.09496812222223,
              "alt": 2086.2762222222223,
              "speed2d": 2.079222222222222,
              "speed3d": 2.1644444444444444,
              "msFromStart": 325753.7493333334
            },
            {
              "lat": 43.75801494444445,
              "lng": -116.09496752222222,
              "alt": 2087.2471111111113,
              "speed2d": 2.2457777777777777,
              "speed3d": 2.3055555555555554,
              "msFromStart": 326248.85688888904
            },
            {
              "lat": 43.75800071111111,
              "lng": -116.09497007777779,
              "alt": 2087.699888888889,
              "speed2d": 2.7520000000000002,
              "speed3d": 2.7544444444444443,
              "msFromStart": 326744.1493333334
            },
            {
              "lat": 43.75798795555555,
              "lng": -116.09496971111112,
              "alt": 2088.7670000000003,
              "speed2d": 2.839888888888889,
              "speed3d": 2.942222222222222,
              "msFromStart": 327239.5365925928
            },
            {
              "lat": 43.757978288888886,
              "lng": -116.0949699,
              "alt": 2089.0507777777775,
              "speed2d": 2.363888888888889,
              "speed3d": 2.513333333333333,
              "msFromStart": 327734.6773333334
            },
            {
              "lat": 43.75797115555555,
              "lng": -116.09496876666667,
              "alt": 2089.5087777777776,
              "speed2d": 1.9128888888888889,
              "speed3d": 2.0233333333333334,
              "msFromStart": 328229.68649772595
            },
            {
              "lat": 43.75795995,
              "lng": -116.09496714,
              "alt": 2089.7289,
              "speed2d": 2.0681000000000003,
              "speed3d": 2.133,
              "msFromStart": 328752.2618947367
            },
            {
              "lat": 43.75794792222223,
              "lng": -116.0949646777778,
              "alt": 2089.8284444444444,
              "speed2d": 2.2276666666666665,
              "speed3d": 2.273333333333333,
              "msFromStart": 329274.8631994799
            },
            {
              "lat": 43.75793757777778,
              "lng": -116.09496185555557,
              "alt": 2090.0427777777777,
              "speed2d": 2.2441111111111116,
              "speed3d": 2.297777777777778,
              "msFromStart": 329769.6106666666
            },
            {
              "lat": 43.757926644444446,
              "lng": -116.0949604111111,
              "alt": 2090.374777777778,
              "speed2d": 2.2815555555555553,
              "speed3d": 2.33,
              "msFromStart": 330264.19199999975
            },
            {
              "lat": 43.75791618888889,
              "lng": -116.09495873333333,
              "alt": 2090.503222222222,
              "speed2d": 2.2004444444444444,
              "speed3d": 2.2733333333333334,
              "msFromStart": 330759.88266666676
            },
            {
              "lat": 43.75790443333334,
              "lng": -116.09495692222222,
              "alt": 2090.5480000000002,
              "speed2d": 2.323888888888889,
              "speed3d": 2.3388888888888886,
              "msFromStart": 331256.13767901255
            },
            {
              "lat": 43.75789352222222,
              "lng": -116.0949559888889,
              "alt": 2090.7656666666667,
              "speed2d": 2.2912222222222223,
              "speed3d": 2.37,
              "msFromStart": 331750.67733333324
            },
            {
              "lat": 43.75788386666666,
              "lng": -116.0949559,
              "alt": 2090.8824444444444,
              "speed2d": 2.1501111111111113,
              "speed3d": 2.212222222222222,
              "msFromStart": 332244.30251332006
            },
            {
              "lat": 43.75787381111111,
              "lng": -116.09495653333332,
              "alt": 2090.8456666666666,
              "speed2d": 2.1443333333333334,
              "speed3d": 2.184444444444445,
              "msFromStart": 332738.4050526315
            },
            {
              "lat": 43.757864411111115,
              "lng": -116.09495742222222,
              "alt": 2091.1226666666666,
              "speed2d": 2.0718888888888887,
              "speed3d": 2.123333333333333,
              "msFromStart": 333232.7646315789
            },
            {
              "lat": 43.75785565555555,
              "lng": -116.09495897777776,
              "alt": 2091.1622222222227,
              "speed2d": 1.9495555555555555,
              "speed3d": 1.9877777777777776,
              "msFromStart": 333728.1066666666
            },
            {
              "lat": 43.75784528,
              "lng": -116.09495984,
              "alt": 2090.9362,
              "speed2d": 1.9966000000000002,
              "speed3d": 2.01,
              "msFromStart": 334251.7797333332
            },
            {
              "lat": 43.75783564444445,
              "lng": -116.09496122222221,
              "alt": 2090.943222222222,
              "speed2d": 1.9449999999999998,
              "speed3d": 1.9844444444444442,
              "msFromStart": 334774.43200000015
            },
            {
              "lat": 43.757827088888895,
              "lng": -116.09496127777777,
              "alt": 2091.259111111111,
              "speed2d": 1.9164444444444442,
              "speed3d": 1.9411111111111112,
              "msFromStart": 335268.9783703706
            },
            {
              "lat": 43.757817388888895,
              "lng": -116.09496012222222,
              "alt": 2091.6988888888886,
              "speed2d": 2.112111111111111,
              "speed3d": 2.1122222222222224,
              "msFromStart": 335763.64800000016
            },
            {
              "lat": 43.75780514444444,
              "lng": -116.09495627777778,
              "alt": 2091.9442222222224,
              "speed2d": 2.379222222222222,
              "speed3d": 2.395555555555555,
              "msFromStart": 336258.3988148151
            },
            {
              "lat": 43.75779444444444,
              "lng": -116.09495419999999,
              "alt": 2091.9487777777777,
              "speed2d": 2.321888888888889,
              "speed3d": 2.3511111111111114,
              "msFromStart": 336753.9199999999
            },
            {
              "lat": 43.757782711111105,
              "lng": -116.09495177777775,
              "alt": 2091.6626666666666,
              "speed2d": 2.3853333333333335,
              "speed3d": 2.414444444444445,
              "msFromStart": 337249.8415802468
            },
            {
              "lat": 43.75777124444444,
              "lng": -116.09494932222222,
              "alt": 2091.2654444444443,
              "speed2d": 2.3855555555555554,
              "speed3d": 2.3788888888888886,
              "msFromStart": 337745.01333333337
            },
            {
              "lat": 43.75775873333333,
              "lng": -116.09494674444443,
              "alt": 2091.213111111111,
              "speed2d": 2.5665555555555555,
              "speed3d": 2.5644444444444443,
              "msFromStart": 338239.7819051333
            },
            {
              "lat": 43.75774591111111,
              "lng": -116.09494382222222,
              "alt": 2091.1195555555555,
              "speed2d": 2.689222222222222,
              "speed3d": 2.6866666666666665,
              "msFromStart": 338734.5802105265
            },
            {
              "lat": 43.75773164444444,
              "lng": -116.09493768888888,
              "alt": 2090.757111111111,
              "speed2d": 2.861,
              "speed3d": 2.852222222222222,
              "msFromStart": 339229.3945263161
            },
            {
              "lat": 43.75771951,
              "lng": -116.09493392,
              "alt": 2091.1234,
              "speed2d": 2.7038999999999995,
              "speed3d": 2.75,
              "msFromStart": 339751.56266666675
            },
            {
              "lat": 43.757707399999994,
              "lng": -116.09492878888888,
              "alt": 2091.398888888889,
              "speed2d": 2.6258888888888885,
              "speed3d": 2.6311111111111107,
              "msFromStart": 340273.63772839523
            },
            {
              "lat": 43.75769655555555,
              "lng": -116.09492694444447,
              "alt": 2091.9176666666667,
              "speed2d": 2.4925555555555556,
              "speed3d": 2.5488888888888885,
              "msFromStart": 340768.5333333334
            },
            {
              "lat": 43.75768645555556,
              "lng": -116.09492636666667,
              "alt": 2091.9938888888887,
              "speed2d": 2.3137777777777777,
              "speed3d": 2.3322222222222226,
              "msFromStart": 341263.5940740742
            },
            {
              "lat": 43.75767532222222,
              "lng": -116.09492436666666,
              "alt": 2092.109,
              "speed2d": 2.3514444444444447,
              "speed3d": 2.367777777777778,
              "msFromStart": 341758.9013333334
            },
            {
              "lat": 43.75766444444445,
              "lng": -116.09492245555558,
              "alt": 2092.758333333333,
              "speed2d": 2.4984444444444445,
              "speed3d": 2.5144444444444445,
              "msFromStart": 342254.33491877856
            },
            {
              "lat": 43.7576541,
              "lng": -116.09491858888887,
              "alt": 2092.9625555555554,
              "speed2d": 2.380111111111111,
              "speed3d": 2.408888888888889,
              "msFromStart": 342749.4349473683
            },
            {
              "lat": 43.75764345555555,
              "lng": -116.09491402222221,
              "alt": 2093.5992222222226,
              "speed2d": 2.3105555555555557,
              "speed3d": 2.3766666666666665,
              "msFromStart": 343244.35536842083
            },
            {
              "lat": 43.75763443333334,
              "lng": -116.09491234444445,
              "alt": 2093.9845555555557,
              "speed2d": 2.032,
              "speed3d": 2.088888888888889,
              "msFromStart": 343739.77777777787
            },
            {
              "lat": 43.757627211111114,
              "lng": -116.09491043333333,
              "alt": 2094.2997777777778,
              "speed2d": 1.7217777777777779,
              "speed3d": 1.8055555555555556,
              "msFromStart": 344235.60177777807
            },
            {
              "lat": 43.75762136666667,
              "lng": -116.09490878888889,
              "alt": 2094.5824444444443,
              "speed2d": 1.4014444444444445,
              "speed3d": 1.4866666666666668,
              "msFromStart": 344730.6524444445
            },
            {
              "lat": 43.7576156,
              "lng": -116.09490726999998,
              "alt": 2094.824,
              "speed2d": 1.1797,
              "speed3d": 1.2640000000000002,
              "msFromStart": 345252.5646222222
            },
            {
              "lat": 43.757611177777775,
              "lng": -116.09490598888887,
              "alt": 2095.183666666667,
              "speed2d": 0.9193333333333333,
              "speed3d": 1.0188888888888887,
              "msFromStart": 345775.15733333345
            },
            {
              "lat": 43.75760646666667,
              "lng": -116.09490471111111,
              "alt": 2095.3205555555555,
              "speed2d": 0.9013333333333332,
              "speed3d": 0.971111111111111,
              "msFromStart": 346270.646123457
            },
            {
              "lat": 43.75760104444445,
              "lng": -116.0949016,
              "alt": 2095.7114444444446,
              "speed2d": 1.1365555555555555,
              "speed3d": 1.1744444444444444,
              "msFromStart": 346766.1759999999
            },
            {
              "lat": 43.75759647777778,
              "lng": -116.09489976666666,
              "alt": 2095.695888888889,
              "speed2d": 1.0255555555555556,
              "speed3d": 1.1144444444444443,
              "msFromStart": 347261.7142144248
            },
            {
              "lat": 43.75759192222222,
              "lng": -116.09489826666666,
              "alt": 2095.817777777778,
              "speed2d": 0.988222222222222,
              "speed3d": 1.031111111111111,
              "msFromStart": 347756.5355789475
            },
            {
              "lat": 43.75758518888888,
              "lng": -116.09489576666667,
              "alt": 2095.9063333333334,
              "speed2d": 1.2121111111111111,
              "speed3d": 1.2177777777777776,
              "msFromStart": 348250.9709473687
            },
            {
              "lat": 43.75757734444444,
              "lng": -116.09489290000002,
              "alt": 2095.889888888889,
              "speed2d": 1.4427777777777777,
              "speed3d": 1.46,
              "msFromStart": 348745.7688888888
            },
            {
              "lat": 43.757570666666666,
              "lng": -116.09488991111111,
              "alt": 2096.0624444444447,
              "speed2d": 1.513222222222222,
              "speed3d": 1.5277777777777777,
              "msFromStart": 349240.85688888875
            },
            {
              "lat": 43.757563411111114,
              "lng": -116.09488767777778,
              "alt": 2095.8054444444447,
              "speed2d": 1.409,
              "speed3d": 1.4633333333333336,
              "msFromStart": 349735.376
            },
            {
              "lat": 43.757558700000004,
              "lng": -116.09488658888891,
              "alt": 2095.533111111111,
              "speed2d": 0.9233333333333333,
              "speed3d": 0.988888888888889,
              "msFromStart": 350229.44000000006
            },
            {
              "lat": 43.75755364999999,
              "lng": -116.09488503,
              "alt": 2095.5672999999997,
              "speed2d": 0.9772000000000001,
              "speed3d": 0.9579999999999999,
              "msFromStart": 350751.7146666665
            },
            {
              "lat": 43.75754745555555,
              "lng": -116.09488394444445,
              "alt": 2095.260333333333,
              "speed2d": 1.115,
              "speed3d": 1.1033333333333333,
              "msFromStart": 351274.5467654319
            },
            {
              "lat": 43.75754013333333,
              "lng": -116.0948824,
              "alt": 2094.753222222222,
              "speed2d": 1.2325555555555554,
              "speed3d": 1.2144444444444447,
              "msFromStart": 351770.00533333345
            },
            {
              "lat": 43.75753095555555,
              "lng": -116.09488035555556,
              "alt": 2094.254,
              "speed2d": 1.430222222222222,
              "speed3d": 1.4233333333333333,
              "msFromStart": 352265.54009616654
            },
            {
              "lat": 43.757523899999995,
              "lng": -116.09487753333335,
              "alt": 2094.2305555555554,
              "speed2d": 1.3785555555555555,
              "speed3d": 1.3822222222222222,
              "msFromStart": 352761.0105263157
            },
            {
              "lat": 43.757518044444446,
              "lng": -116.09487491111112,
              "alt": 2094.2775555555554,
              "speed2d": 1.2771111111111113,
              "speed3d": 1.3077777777777777,
              "msFromStart": 353256.4463157894
            },
            {
              "lat": 43.757513,
              "lng": -116.09487265555558,
              "alt": 2094.543666666667,
              "speed2d": 1.2313333333333334,
              "speed3d": 1.22,
              "msFromStart": 353751.61777777784
            },
            {
              "lat": 43.75750747777778,
              "lng": -116.09486917777778,
              "alt": 2094.597222222222,
              "speed2d": 1.266,
              "speed3d": 1.2922222222222222,
              "msFromStart": 354246.5777777779
            },
            {
              "lat": 43.757502233333334,
              "lng": -116.0948681,
              "alt": 2094.3815555555557,
              "speed2d": 1.1235555555555556,
              "speed3d": 1.11,
              "msFromStart": 354741.21777777775
            },
            {
              "lat": 43.75749558888889,
              "lng": -116.09486442222222,
              "alt": 2094.8184444444446,
              "speed2d": 1.3755555555555559,
              "speed3d": 1.371111111111111,
              "msFromStart": 355235.6017777777
            },
            {
              "lat": 43.757489255555555,
              "lng": -116.09486297777778,
              "alt": 2094.5460000000003,
              "speed2d": 1.2828888888888887,
              "speed3d": 1.3322222222222222,
              "msFromStart": 355730.4213333332
            },
            {
              "lat": 43.75748378888889,
              "lng": -116.0948606,
              "alt": 2094.1235555555554,
              "speed2d": 1.1407777777777777,
              "speed3d": 1.14,
              "msFromStart": 356225.589333333
            },
            {
              "lat": 43.757477259999995,
              "lng": -116.09486011,
              "alt": 2094.0352,
              "speed2d": 1.0767,
              "speed3d": 1.093,
              "msFromStart": 356748.1395555556
            },
            {
              "lat": 43.75747207777778,
              "lng": -116.09485905555556,
              "alt": 2094.224222222222,
              "speed2d": 0.9946666666666666,
              "speed3d": 1.0299999999999998,
              "msFromStart": 357270.6048914881
            },
            {
              "lat": 43.75746792222222,
              "lng": -116.09485835555556,
              "alt": 2094.317888888889,
              "speed2d": 0.9281111111111111,
              "speed3d": 0.9288888888888889,
              "msFromStart": 357765.96547368437
            },
            {
              "lat": 43.75746115555555,
              "lng": -116.09485717777778,
              "alt": 2094.530888888889,
              "speed2d": 1.3293333333333335,
              "speed3d": 1.29,
              "msFromStart": 358261.5376842108
            },
            {
              "lat": 43.75745266666667,
              "lng": -116.09485596666664,
              "alt": 2094.4746666666665,
              "speed2d": 1.5987777777777776,
              "speed3d": 1.62,
              "msFromStart": 358756.5297777778
            },
            {
              "lat": 43.75744538888888,
              "lng": -116.09485476666667,
              "alt": 2094.555222222222,
              "speed2d": 1.542111111111111,
              "speed3d": 1.5455555555555556,
              "msFromStart": 359251.0577777778
            },
            {
              "lat": 43.75743843333333,
              "lng": -116.09485415555555,
              "alt": 2094.7121111111114,
              "speed2d": 1.5086666666666668,
              "speed3d": 1.5366666666666668,
              "msFromStart": 359746.1102222222
            },
            {
              "lat": 43.75743056666667,
              "lng": -116.0948526888889,
              "alt": 2094.751222222222,
              "speed2d": 1.6263333333333336,
              "speed3d": 1.6222222222222222,
              "msFromStart": 360241.58222222223
            },
            {
              "lat": 43.75742144444445,
              "lng": -116.09485082222221,
              "alt": 2094.9576666666667,
              "speed2d": 1.8751111111111112,
              "speed3d": 1.8544444444444443,
              "msFromStart": 360736.79644444457
            },
            {
              "lat": 43.757412555555554,
              "lng": -116.09484915555555,
              "alt": 2095.0774444444446,
              "speed2d": 1.8763333333333332,
              "speed3d": 1.8922222222222222,
              "msFromStart": 361231.8044444446
            },
            {
              "lat": 43.75740453333334,
              "lng": -116.09484768888889,
              "alt": 2095.3272222222226,
              "speed2d": 1.844,
              "speed3d": 1.851111111111111,
              "msFromStart": 361727.0257777779
            },
            {
              "lat": 43.75739488,
              "lng": -116.09484676999999,
              "alt": 2095.6656,
              "speed2d": 2.0454,
              "speed3d": 2.0490000000000004,
              "msFromStart": 362249.934577778
            },
            {
              "lat": 43.75738434444444,
              "lng": -116.09484745555555,
              "alt": 2096.1857777777777,
              "speed2d": 2.354111111111111,
              "speed3d": 2.344444444444444,
              "msFromStart": 362772.5546666666
            },
            {
              "lat": 43.75737478888889,
              "lng": -116.0948471222222,
              "alt": 2096.315,
              "speed2d": 2.174888888888889,
              "speed3d": 2.2377777777777776,
              "msFromStart": 363267.503802469
            },
            {
              "lat": 43.75736587777777,
              "lng": -116.09484712222223,
              "alt": 2096.382111111111,
              "speed2d": 2.0660000000000003,
              "speed3d": 2.0844444444444443,
              "msFromStart": 363762.71999999986
            },
            {
              "lat": 43.757357355555556,
              "lng": -116.09484455555554,
              "alt": 2096.549666666667,
              "speed2d": 2.0095555555555555,
              "speed3d": 2.041111111111111,
              "msFromStart": 364258.0799999997
            },
            {
              "lat": 43.75734777777778,
              "lng": -116.09484185555556,
              "alt": 2096.895666666667,
              "speed2d": 2.1871111111111112,
              "speed3d": 2.1644444444444444,
              "msFromStart": 364753.0222222221
            },
            {
              "lat": 43.75733712222222,
              "lng": -116.09483794444445,
              "alt": 2097.388,
              "speed2d": 2.466111111111111,
              "speed3d": 2.4799999999999995,
              "msFromStart": 365247.63022222195
            },
            {
              "lat": 43.75732549999999,
              "lng": -116.0948343777778,
              "alt": 2097.9664444444447,
              "speed2d": 2.694222222222222,
              "speed3d": 2.6844444444444444,
              "msFromStart": 365742.9137777779
            },
            {
              "lat": 43.7573148,
              "lng": -116.09483080000001,
              "alt": 2098.674,
              "speed2d": 2.6372222222222224,
              "speed3d": 2.706666666666666,
              "msFromStart": 366238.7377777782
            },
            {
              "lat": 43.75730642222223,
              "lng": -116.09483191111113,
              "alt": 2099.0498888888887,
              "speed2d": 2.2407777777777773,
              "speed3d": 2.3288888888888883,
              "msFromStart": 366733.6640000001
            },
            {
              "lat": 43.75729625555556,
              "lng": -116.09483125555556,
              "alt": 2099.4959999999996,
              "speed2d": 2.287888888888889,
              "speed3d": 2.3022222222222224,
              "msFromStart": 367227.87200000015
            },
            {
              "lat": 43.7572849,
              "lng": -116.09482869000001,
              "alt": 2099.9457,
              "speed2d": 2.3448,
              "speed3d": 2.399,
              "msFromStart": 367749.50821052626
            },
            {
              "lat": 43.75727384444445,
              "lng": -116.09482494444445,
              "alt": 2100.3564444444446,
              "speed2d": 2.4205555555555556,
              "speed3d": 2.435555555555556,
              "msFromStart": 368271.1242105261
            },
            {
              "lat": 43.7572623,
              "lng": -116.09482103333333,
              "alt": 2100.576,
              "speed2d": 2.550666666666667,
              "speed3d": 2.5977777777777775,
              "msFromStart": 368766.423111111
            },
            {
              "lat": 43.757252755555555,
              "lng": -116.09481876666668,
              "alt": 2101.037,
              "speed2d": 2.4195555555555557,
              "speed3d": 2.4877777777777776,
              "msFromStart": 369262.6311111108
            },
            {
              "lat": 43.75724224444444,
              "lng": -116.09481699999999,
              "alt": 2101.304111111111,
              "speed2d": 2.4474444444444443,
              "speed3d": 2.4722222222222223,
              "msFromStart": 369758.03911111114
            },
            {
              "lat": 43.757232011111114,
              "lng": -116.0948151111111,
              "alt": 2101.6712222222222,
              "speed2d": 2.4051111111111108,
              "speed3d": 2.457777777777778,
              "msFromStart": 370252.8071111112
            },
            {
              "lat": 43.757221333333334,
              "lng": -116.09481193333333,
              "alt": 2102.3378888888888,
              "speed2d": 2.4914444444444444,
              "speed3d": 2.5222222222222226,
              "msFromStart": 370747.8417777779
            },
            {
              "lat": 43.75721078888889,
              "lng": -116.09480822222221,
              "alt": 2103.3143333333333,
              "speed2d": 2.5087777777777776,
              "speed3d": 2.5677777777777777,
              "msFromStart": 371243.089777778
            },
            {
              "lat": 43.75720027777778,
              "lng": -116.09480613333335,
              "alt": 2103.982666666667,
              "speed2d": 2.4714444444444443,
              "speed3d": 2.5522222222222224,
              "msFromStart": 371738.12444444443
            },
            {
              "lat": 43.757191600000006,
              "lng": -116.09480657777777,
              "alt": 2104.5207777777778,
              "speed2d": 2.2075555555555555,
              "speed3d": 2.3122222222222226,
              "msFromStart": 372232.9884444445
            },
            {
              "lat": 43.75718302222222,
              "lng": -116.09480681111111,
              "alt": 2104.9772222222223,
              "speed2d": 2.0167777777777776,
              "speed3d": 2.1333333333333333,
              "msFromStart": 372727.73221052636
            },
            {
              "lat": 43.75717236,
              "lng": -116.0948066,
              "alt": 2105.3833,
              "speed2d": 2.185,
              "speed3d": 2.2170000000000005,
              "msFromStart": 373249.86021052644
            },
            {
              "lat": 43.75715737777778,
              "lng": -116.09480433333334,
              "alt": 2105.262222222222,
              "speed2d": 2.624888888888889,
              "speed3d": 2.6622222222222227,
              "msFromStart": 373772.0906666668
            },
            {
              "lat": 43.75714502222222,
              "lng": -116.09480168888888,
              "alt": 2105.038111111111,
              "speed2d": 2.5855555555555556,
              "speed3d": 2.6177777777777775,
              "msFromStart": 374266.922666667
            },
            {
              "lat": 43.75713365555555,
              "lng": -116.09479886666668,
              "alt": 2105.5918888888887,
              "speed2d": 2.567555555555556,
              "speed3d": 2.6255555555555556,
              "msFromStart": 374762.3057777779
            },
            {
              "lat": 43.757123255555555,
              "lng": -116.09479590000001,
              "alt": 2106.3364444444446,
              "speed2d": 2.4704444444444444,
              "speed3d": 2.508888888888889,
              "msFromStart": 375258.1297777781
            },
            {
              "lat": 43.757111344444446,
              "lng": -116.09479245555553,
              "alt": 2106.804777777778,
              "speed2d": 2.6193333333333335,
              "speed3d": 2.6688888888888886,
              "msFromStart": 375753.2160000001
            },
            {
              "lat": 43.757100811111115,
              "lng": -116.09479052222221,
              "alt": 2107.260222222222,
              "speed2d": 2.460111111111111,
              "speed3d": 2.5555555555555554,
              "msFromStart": 376247.7120000002
            },
            {
              "lat": 43.7570906,
              "lng": -116.0947905888889,
              "alt": 2107.183777777778,
              "speed2d": 2.3255555555555554,
              "speed3d": 2.38,
              "msFromStart": 376742.68799999985
            },
            {
              "lat": 43.757078755555554,
              "lng": -116.09478984444442,
              "alt": 2107.058777777778,
              "speed2d": 2.464888888888889,
              "speed3d": 2.4755555555555553,
              "msFromStart": 377238.0479999997
            },
            {
              "lat": 43.75706704444444,
              "lng": -116.09478945555556,
              "alt": 2107.265,
              "speed2d": 2.5252222222222223,
              "speed3d": 2.561111111111111,
              "msFromStart": 377733.24799999985
            },
            {
              "lat": 43.75705564444444,
              "lng": -116.0947873111111,
              "alt": 2107.8535555555554,
              "speed2d": 2.524888888888889,
              "speed3d": 2.5455555555555556,
              "msFromStart": 378228.3199999996
            },
            {
              "lat": 43.75704292,
              "lng": -116.09478533000001,
              "alt": 2108.2681,
              "speed2d": 2.5608000000000004,
              "speed3d": 2.633,
              "msFromStart": 378750.8960000001
            },
            {
              "lat": 43.75703107777778,
              "lng": -116.09478476666666,
              "alt": 2108.7635555555557,
              "speed2d": 2.4286666666666665,
              "speed3d": 2.4755555555555557,
              "msFromStart": 379273.4720000004
            },
            {
              "lat": 43.75701936666667,
              "lng": -116.09478353333333,
              "alt": 2108.9956666666667,
              "speed2d": 2.409333333333333,
              "speed3d": 2.4477777777777776,
              "msFromStart": 379768.18844444444
            },
            {
              "lat": 43.75700674444445,
              "lng": -116.0947815777778,
              "alt": 2109.1459999999997,
              "speed2d": 2.558777777777778,
              "speed3d": 2.5677777777777773,
              "msFromStart": 380262.6204444444
            },
            {
              "lat": 43.75699421111111,
              "lng": -116.09478135555555,
              "alt": 2109.5506666666665,
              "speed2d": 2.5854444444444447,
              "speed3d": 2.624444444444445,
              "msFromStart": 380757.50577777793
            },
            {
              "lat": 43.75698147777778,
              "lng": -116.09478025555555,
              "alt": 2109.7553333333335,
              "speed2d": 2.6455555555555557,
              "speed3d": 2.6855555555555553,
              "msFromStart": 381252.753777778
            },
            {
              "lat": 43.75697141111111,
              "lng": -116.09477906666666,
              "alt": 2110.1378888888894,
              "speed2d": 2.407,
              "speed3d": 2.4511111111111115,
              "msFromStart": 381747.9662222222
            },
            {
              "lat": 43.75696025555555,
              "lng": -116.09477572222224,
              "alt": 2110.4572222222223,
              "speed2d": 2.464111111111111,
              "speed3d": 2.4888888888888894,
              "msFromStart": 382243.15022222215
            },
            {
              "lat": 43.75694847777778,
              "lng": -116.09477213333334,
              "alt": 2110.8097777777775,
              "speed2d": 2.5662222222222217,
              "speed3d": 2.5866666666666664,
              "msFromStart": 382738.31410526315
            },
            {
              "lat": 43.75693743333333,
              "lng": -116.09477071111112,
              "alt": 2111.0805555555557,
              "speed2d": 2.437888888888889,
              "speed3d": 2.518888888888889,
              "msFromStart": 383233.4618947368
            },
            {
              "lat": 43.75692658888889,
              "lng": -116.09476935555554,
              "alt": 2111.550888888889,
              "speed2d": 2.3454444444444444,
              "speed3d": 2.3777777777777778,
              "msFromStart": 383728.2133333334
            },
            {
              "lat": 43.756914990000006,
              "lng": -116.09476784,
              "alt": 2112.0868,
              "speed2d": 2.4911000000000003,
              "speed3d": 2.5290000000000004,
              "msFromStart": 384249.9280000001
            },
            {
              "lat": 43.75690285555555,
              "lng": -116.09476405555557,
              "alt": 2112.7677777777776,
              "speed2d": 2.7122222222222225,
              "speed3d": 2.7088888888888887,
              "msFromStart": 384772.3004444445
            },
            {
              "lat": 43.75689190000001,
              "lng": -116.09476064444446,
              "alt": 2113.362888888889,
              "speed2d": 2.6661111111111113,
              "speed3d": 2.746666666666667,
              "msFromStart": 385267.74044444464
            },
            {
              "lat": 43.756883466666665,
              "lng": -116.09475937777778,
              "alt": 2114.144222222222,
              "speed2d": 2.3512222222222223,
              "speed3d": 2.4411111111111112,
              "msFromStart": 385762.9315555555
            },
            {
              "lat": 43.756874733333326,
              "lng": -116.09475822222223,
              "alt": 2114.9306666666666,
              "speed2d": 2.24,
              "speed3d": 2.3144444444444447,
              "msFromStart": 386257.92355555546
            },
            {
              "lat": 43.75686553333333,
              "lng": -116.09475757777777,
              "alt": 2115.449777777778,
              "speed2d": 2.244111111111111,
              "speed3d": 2.3033333333333337,
              "msFromStart": 386752.7466666668
            },
            {
              "lat": 43.7568553,
              "lng": -116.09475607777777,
              "alt": 2115.8154444444444,
              "speed2d": 2.344888888888889,
              "speed3d": 2.4144444444444444,
              "msFromStart": 387247.43466666696
            },
            {
              "lat": 43.75684548888889,
              "lng": -116.09475404444444,
              "alt": 2116.599888888889,
              "speed2d": 2.4153333333333338,
              "speed3d": 2.4722222222222223,
              "msFromStart": 387742.5717894736
            },
            {
              "lat": 43.75683666666667,
              "lng": -116.09475302222222,
              "alt": 2117.2091111111113,
              "speed2d": 2.248222222222222,
              "speed3d": 2.3344444444444443,
              "msFromStart": 388238.0682105262
            },
            {
              "lat": 43.75682804444445,
              "lng": -116.09475284444444,
              "alt": 2117.6511111111113,
              "speed2d": 2.1252222222222223,
              "speed3d": 2.23,
              "msFromStart": 388733.1626666667
            },
            {
              "lat": 43.75681732222222,
              "lng": -116.09475032222221,
              "alt": 2118.0676666666664,
              "speed2d": 2.3886666666666665,
              "speed3d": 2.4,
              "msFromStart": 389227.7546666669
            },
            {
              "lat": 43.75680476,
              "lng": -116.09474659,
              "alt": 2118.5807999999997,
              "speed2d": 2.6433999999999997,
              "speed3d": 2.693,
              "msFromStart": 389750.496
            },
            {
              "lat": 43.75679336666666,
              "lng": -116.09474423333333,
              "alt": 2119.296777777778,
              "speed2d": 2.654444444444444,
              "speed3d": 2.707777777777778,
              "msFromStart": 390273.9839999999
            },
            {
              "lat": 43.75678351111112,
              "lng": -116.0947432888889,
              "alt": 2119.989888888889,
              "speed2d": 2.4345555555555554,
              "speed3d": 2.5555555555555554,
              "msFromStart": 390769.04000000015
            },
            {
              "lat": 43.75677422222222,
              "lng": -116.09474343333333,
              "alt": 2120.1285555555555,
              "speed2d": 2.2354444444444446,
              "speed3d": 2.3433333333333333,
              "msFromStart": 391263.3920000002
            },
            {
              "lat": 43.75676512222222,
              "lng": -116.09474336666666,
              "alt": 2120.367111111111,
              "speed2d": 2.113888888888889,
              "speed3d": 2.174444444444444,
              "msFromStart": 391758.38399999996
            },
            {
              "lat": 43.75675576666667,
              "lng": -116.09474118888889,
              "alt": 2121.459444444444,
              "speed2d": 2.2716666666666665,
              "speed3d": 2.3488888888888892,
              "msFromStart": 392253.8879999998
            },
            {
              "lat": 43.75674544444445,
              "lng": -116.09473991111112,
              "alt": 2122.141,
              "speed2d": 2.4189999999999996,
              "speed3d": 2.528888888888889,
              "msFromStart": 392749.45422222227
            },
            {
              "lat": 43.75673575555555,
              "lng": -116.09473803333333,
              "alt": 2122.6692222222223,
              "speed2d": 2.3496666666666663,
              "speed3d": 2.4644444444444447,
              "msFromStart": 393245.0702222223
            },
            {
              "lat": 43.75672543333334,
              "lng": -116.09473722222221,
              "alt": 2123.1694444444447,
              "speed2d": 2.3762222222222222,
              "speed3d": 2.521111111111111,
              "msFromStart": 393740.31663157884
            },
            {
              "lat": 43.75671532222222,
              "lng": -116.09473601111111,
              "alt": 2123.420888888889,
              "speed2d": 2.331444444444444,
              "speed3d": 2.434444444444444,
              "msFromStart": 394235.2673684208
            },
            {
              "lat": 43.75670455555556,
              "lng": -116.09473406666666,
              "alt": 2123.741666666667,
              "speed2d": 2.433555555555556,
              "speed3d": 2.528888888888889,
              "msFromStart": 394729.60355555546
            },
            {
              "lat": 43.75669369,
              "lng": -116.09472965,
              "alt": 2124.2571,
              "speed2d": 2.4642,
              "speed3d": 2.553,
              "msFromStart": 395250.5919999997
            },
            {
              "lat": 43.75668084444444,
              "lng": -116.0947261,
              "alt": 2124.6615555555554,
              "speed2d": 2.6755555555555555,
              "speed3d": 2.7688888888888887,
              "msFromStart": 395772.4248888889
            },
            {
              "lat": 43.75666973333333,
              "lng": -116.09472330000001,
              "alt": 2125.0223333333333,
              "speed2d": 2.567,
              "speed3d": 2.6877777777777783,
              "msFromStart": 396267.5128888888
            },
            {
              "lat": 43.75665818888889,
              "lng": -116.09472198888889,
              "alt": 2125.164777777778,
              "speed2d": 2.5500000000000003,
              "speed3d": 2.648888888888889,
              "msFromStart": 396762.87644444464
            },
            {
              "lat": 43.7566467,
              "lng": -116.09471971111111,
              "alt": 2125.6285555555555,
              "speed2d": 2.6342222222222222,
              "speed3d": 2.722222222222222,
              "msFromStart": 397258.4604444448
            },
            {
              "lat": 43.75663505555555,
              "lng": -116.09471755555555,
              "alt": 2125.7874444444446,
              "speed2d": 2.571111111111111,
              "speed3d": 2.6900000000000004,
              "msFromStart": 397753.7010526316
            },
            {
              "lat": 43.75662338888889,
              "lng": -116.09471509999999,
              "alt": 2125.6584444444447,
              "speed2d": 2.510888888888889,
              "speed3d": 2.61,
              "msFromStart": 398248.66694736853
            },
            {
              "lat": 43.75661196666666,
              "lng": -116.09471282222223,
              "alt": 2125.9026666666664,
              "speed2d": 2.528666666666666,
              "speed3d": 2.606666666666667,
              "msFromStart": 398743.7155555556
            },
            {
              "lat": 43.75660076666667,
              "lng": -116.09471024444444,
              "alt": 2126.028222222222,
              "speed2d": 2.4952222222222225,
              "speed3d": 2.586666666666667,
              "msFromStart": 399238.8675555557
            },
            {
              "lat": 43.756590244444446,
              "lng": -116.09470852222222,
              "alt": 2126.275111111111,
              "speed2d": 2.355888888888889,
              "speed3d": 2.497777777777778,
              "msFromStart": 399734.37511111115
            },
            {
              "lat": 43.75658165555556,
              "lng": -116.09470653333334,
              "alt": 2126.5924444444445,
              "speed2d": 2.1594444444444445,
              "speed3d": 2.2622222222222224,
              "msFromStart": 400230.32711111126
            },
            {
              "lat": 43.75657305,
              "lng": -116.09470418999999,
              "alt": 2127.5659,
              "speed2d": 2.2906999999999997,
              "speed3d": 2.395,
              "msFromStart": 400752.65600000013
            },
            {
              "lat": 43.75656444444444,
              "lng": -116.09470342222221,
              "alt": 2128.584444444444,
              "speed2d": 2.3083333333333336,
              "speed3d": 2.44,
              "msFromStart": 401273.6782222224
            },
            {
              "lat": 43.756555588888894,
              "lng": -116.09470166666667,
              "alt": 2129.0298888888888,
              "speed2d": 2.3088888888888888,
              "speed3d": 2.397777777777778,
              "msFromStart": 401768.58488888893
            },
            {
              "lat": 43.756544688888894,
              "lng": -116.09469703333333,
              "alt": 2129.6908888888893,
              "speed2d": 2.6391111111111107,
              "speed3d": 2.716666666666667,
              "msFromStart": 402264.53688888904
            },
            {
              "lat": 43.75653366666666,
              "lng": -116.09469260000002,
              "alt": 2130.4467777777777,
              "speed2d": 2.7971111111111115,
              "speed3d": 2.8777777777777773,
              "msFromStart": 402760.1599999999
            },
            {
              "lat": 43.75652372222222,
              "lng": -116.09468951111113,
              "alt": 2130.9454444444445,
              "speed2d": 2.643555555555556,
              "speed3d": 2.767777777777778,
              "msFromStart": 403255.5199999998
            },
            {
              "lat": 43.756514,
              "lng": -116.09468748888888,
              "alt": 2131.1555555555556,
              "speed2d": 2.403111111111111,
              "speed3d": 2.506666666666667,
              "msFromStart": 403750.55157894734
            },
            {
              "lat": 43.75650397777778,
              "lng": -116.09468481111112,
              "alt": 2131.4676666666664,
              "speed2d": 2.4065555555555553,
              "speed3d": 2.4811111111111113,
              "msFromStart": 404245.32042105263
            },
            {
              "lat": 43.756493199999994,
              "lng": -116.09468186666666,
              "alt": 2131.827,
              "speed2d": 2.493111111111111,
              "speed3d": 2.5555555555555554,
              "msFromStart": 404740.9137777778
            },
            {
              "lat": 43.75648104444444,
              "lng": -116.0946792111111,
              "alt": 2131.923333333333,
              "speed2d": 2.6326666666666667,
              "speed3d": 2.7055555555555557,
              "msFromStart": 405237.5377777781
            },
            {
              "lat": 43.756470566666664,
              "lng": -116.09467702222221,
              "alt": 2132.1568888888887,
              "speed2d": 2.5515555555555554,
              "speed3d": 2.5900000000000003,
              "msFromStart": 405733.0808888888
            },
            {
              "lat": 43.75646011111111,
              "lng": -116.09467490000002,
              "alt": 2132.529,
              "speed2d": 2.4877777777777776,
              "speed3d": 2.5922222222222224,
              "msFromStart": 406227.2728888887
            },
            {
              "lat": 43.75644905,
              "lng": -116.09467197,
              "alt": 2133.0438,
              "speed2d": 2.4832,
              "speed3d": 2.537,
              "msFromStart": 406749.3520000001
            },
            {
              "lat": 43.75643693333333,
              "lng": -116.0946690777778,
              "alt": 2133.344888888889,
              "speed2d": 2.5494444444444446,
              "speed3d": 2.62,
              "msFromStart": 407271.9111111113
            },
            {
              "lat": 43.75642666666667,
              "lng": -116.09466806666666,
              "alt": 2133.3748888888886,
              "speed2d": 2.3064444444444443,
              "speed3d": 2.388888888888889,
              "msFromStart": 407766.96711111115
            },
            {
              "lat": 43.756417111111105,
              "lng": -116.09466685555557,
              "alt": 2133.5264444444447,
              "speed2d": 2.1897777777777776,
              "speed3d": 2.2511111111111113,
              "msFromStart": 408262.0231111112
            },
            {
              "lat": 43.7564076,
              "lng": -116.09466565555557,
              "alt": 2133.7563333333333,
              "speed2d": 2.052888888888889,
              "speed3d": 2.1244444444444444,
              "msFromStart": 408756.63326315774
            },
            {
              "lat": 43.75639687777778,
              "lng": -116.09466353333335,
              "alt": 2133.922555555556,
              "speed2d": 2.207222222222222,
              "speed3d": 2.2044444444444444,
              "msFromStart": 409250.88673684176
            },
            {
              "lat": 43.756385211111116,
              "lng": -116.09466137777777,
              "alt": 2133.9554444444443,
              "speed2d": 2.4754444444444443,
              "speed3d": 2.4988888888888887,
              "msFromStart": 409745.9093333334
            },
            {
              "lat": 43.7563743,
              "lng": -116.09466033333334,
              "alt": 2133.7876666666666,
              "speed2d": 2.358,
              "speed3d": 2.3833333333333333,
              "msFromStart": 410241.8933333333
            },
            {
              "lat": 43.756363099999994,
              "lng": -116.09465755555556,
              "alt": 2133.990777777778,
              "speed2d": 2.3729999999999998,
              "speed3d": 2.41,
              "msFromStart": 410737.10222222214
            },
            {
              "lat": 43.75635065555556,
              "lng": -116.0946547,
              "alt": 2134.007222222222,
              "speed2d": 2.5941111111111113,
              "speed3d": 2.5922222222222224,
              "msFromStart": 411231.34222222207
            },
            {
              "lat": 43.75633843333333,
              "lng": -116.09465244444443,
              "alt": 2134.047777777778,
              "speed2d": 2.554666666666667,
              "speed3d": 2.6077777777777778,
              "msFromStart": 411725.9662222223
            },
            {
              "lat": 43.756325340000004,
              "lng": -116.09465,
              "alt": 2134.1719999999996,
              "speed2d": 2.5851999999999995,
              "speed3d": 2.576,
              "msFromStart": 412248.5760000001
            },
            {
              "lat": 43.756312344444446,
              "lng": -116.09464666666665,
              "alt": 2134.4307777777776,
              "speed2d": 2.662333333333333,
              "speed3d": 2.6922222222222225,
              "msFromStart": 412771.3902222223
            },
            {
              "lat": 43.75630122222222,
              "lng": -116.09464406666667,
              "alt": 2135.1038888888893,
              "speed2d": 2.5131111111111113,
              "speed3d": 2.5644444444444443,
              "msFromStart": 413266.86222222226
            },
            {
              "lat": 43.756290766666666,
              "lng": -116.09464195555555,
              "alt": 2135.6575555555555,
              "speed2d": 2.403666666666667,
              "speed3d": 2.454444444444445,
              "msFromStart": 413761.7077894738
            },
            {
              "lat": 43.756278344444446,
              "lng": -116.09463992222221,
              "alt": 2135.5364444444444,
              "speed2d": 2.6252222222222223,
              "speed3d": 2.6266666666666665,
              "msFromStart": 414256.05221052666
            },
            {
              "lat": 43.7562669,
              "lng": -116.09463728888889,
              "alt": 2135.8342222222223,
              "speed2d": 2.5701111111111112,
              "speed3d": 2.63,
              "msFromStart": 414750.75555555563
            },
            {
              "lat": 43.75625668888889,
              "lng": -116.09463585555557,
              "alt": 2136.006,
              "speed2d": 2.3391111111111114,
              "speed3d": 2.397777777777778,
              "msFromStart": 415245.90755555575
            },
            {
              "lat": 43.75624597777778,
              "lng": -116.09463396666666,
              "alt": 2136.151888888889,
              "speed2d": 2.357111111111111,
              "speed3d": 2.3788888888888886,
              "msFromStart": 415740.95288888895
            },
            {
              "lat": 43.75623451111112,
              "lng": -116.09463274444444,
              "alt": 2136.0360000000005,
              "speed2d": 2.395888888888889,
              "speed3d": 2.408888888888889,
              "msFromStart": 416235.8648888891
            },
            {
              "lat": 43.75622362222222,
              "lng": -116.09463109999999,
              "alt": 2136.1444444444446,
              "speed2d": 2.368777777777778,
              "speed3d": 2.3999999999999995,
              "msFromStart": 416731.2177777778
            },
            {
              "lat": 43.75621377777777,
              "lng": -116.09462963333333,
              "alt": 2136.3317777777775,
              "speed2d": 2.1834444444444445,
              "speed3d": 2.227777777777778,
              "msFromStart": 417227.1217777779
            },
            {
              "lat": 43.7562019,
              "lng": -116.09462784000002,
              "alt": 2136.4743,
              "speed2d": 2.3760999999999997,
              "speed3d": 2.3770000000000002,
              "msFromStart": 417749.888
            },
            {
              "lat": 43.756188988888894,
              "lng": -116.09462617777777,
              "alt": 2136.449,
              "speed2d": 2.567222222222222,
              "speed3d": 2.554444444444444,
              "msFromStart": 418271.88977777783
            },
            {
              "lat": 43.756177566666665,
              "lng": -116.09462482222222,
              "alt": 2136.741,
              "speed2d": 2.5043333333333333,
              "speed3d": 2.5500000000000003,
              "msFromStart": 418766.7536842107
            },
            {
              "lat": 43.75616716666667,
              "lng": -116.09462253333335,
              "alt": 2137.1493333333337,
              "speed2d": 2.462666666666667,
              "speed3d": 2.4911111111111106,
              "msFromStart": 419261.8863157899
            },
            {
              "lat": 43.75615666666667,
              "lng": -116.09462022222223,
              "alt": 2137.2866666666664,
              "speed2d": 2.364444444444444,
              "speed3d": 2.41,
              "msFromStart": 419756.68622222217
            },
            {
              "lat": 43.75614534444445,
              "lng": -116.09461802222224,
              "alt": 2137.5795555555555,
              "speed2d": 2.5038888888888886,
              "speed3d": 2.5044444444444447,
              "msFromStart": 420251.07022222213
            },
            {
              "lat": 43.75613377777778,
              "lng": -116.09461531111113,
              "alt": 2137.7974444444444,
              "speed2d": 2.554333333333333,
              "speed3d": 2.5922222222222224,
              "msFromStart": 420745.9306666666
            },
            {
              "lat": 43.756123288888894,
              "lng": -116.09461269999998,
              "alt": 2138.0363333333335,
              "speed2d": 2.5157777777777777,
              "speed3d": 2.5655555555555556,
              "msFromStart": 421241.3866666665
            },
            {
              "lat": 43.75611394444444,
              "lng": -116.0946113,
              "alt": 2138.279111111111,
              "speed2d": 2.275555555555556,
              "speed3d": 2.32,
              "msFromStart": 421736.69333333324
            },
            {
              "lat": 43.75610424444445,
              "lng": -116.09460851111112,
              "alt": 2138.6868888888885,
              "speed2d": 2.2875555555555556,
              "speed3d": 2.307777777777778,
              "msFromStart": 422231.813333333
            },
            {
              "lat": 43.7560934,
              "lng": -116.09460525555556,
              "alt": 2139.0564444444444,
              "speed2d": 2.4323333333333332,
              "speed3d": 2.4633333333333334,
              "msFromStart": 422727.0471111111
            },
            {
              "lat": 43.75608163,
              "lng": -116.09460207999999,
              "alt": 2139.5744999999997,
              "speed2d": 2.5385,
              "speed3d": 2.567,
              "msFromStart": 423249.9440000001
            },
            {
              "lat": 43.756069277777776,
              "lng": -116.09459965555557,
              "alt": 2139.9101111111113,
              "speed2d": 2.5545555555555555,
              "speed3d": 2.6133333333333333,
              "msFromStart": 423772.29305263155
            },
            {
              "lat": 43.756059244444444,
              "lng": -116.09460050000003,
              "alt": 2140.148,
              "speed2d": 2.2892222222222225,
              "speed3d": 2.365555555555556,
              "msFromStart": 424266.68294736836
            },
            {
              "lat": 43.75604911111111,
              "lng": -116.09459953333331,
              "alt": 2140.4696666666664,
              "speed2d": 2.2,
              "speed3d": 2.2455555555555557,
              "msFromStart": 424761.5537777778
            },
            {
              "lat": 43.75603887777778,
              "lng": -116.09459747777777,
              "alt": 2140.9792222222222,
              "speed2d": 2.267111111111111,
              "speed3d": 2.296666666666667,
              "msFromStart": 425257.0257777778
            },
            {
              "lat": 43.75602878888889,
              "lng": -116.09459542222221,
              "alt": 2141.452222222222,
              "speed2d": 2.2342222222222223,
              "speed3d": 2.332222222222222,
              "msFromStart": 425751.66577777785
            },
            {
              "lat": 43.75601934444445,
              "lng": -116.09459434444446,
              "alt": 2141.8225555555555,
              "speed2d": 2.1146666666666665,
              "speed3d": 2.1555555555555554,
              "msFromStart": 426245.26577777806
            },
            {
              "lat": 43.75600772222222,
              "lng": -116.09459245555556,
              "alt": 2141.9743333333336,
              "speed2d": 2.3326666666666664,
              "speed3d": 2.3788888888888895,
              "msFromStart": 426739.8257777778
            },
            {
              "lat": 43.75599616666666,
              "lng": -116.09459017777777,
              "alt": 2142.1608888888886,
              "speed2d": 2.4413333333333327,
              "speed3d": 2.4644444444444447,
              "msFromStart": 427235.58577777783
            },
            {
              "lat": 43.75598359999999,
              "lng": -116.0945872111111,
              "alt": 2142.6947777777777,
              "speed2d": 2.648333333333334,
              "speed3d": 2.668888888888889,
              "msFromStart": 427731.16799999995
            },
            {
              "lat": 43.75597166666667,
              "lng": -116.09458486666668,
              "alt": 2143.0674444444444,
              "speed2d": 2.5917777777777777,
              "speed3d": 2.6799999999999997,
              "msFromStart": 428226.5279999997
            },
            {
              "lat": 43.75596084,
              "lng": -116.09458392,
              "alt": 2143.0261,
              "speed2d": 2.3076,
              "speed3d": 2.365,
              "msFromStart": 428749.5216842105
            },
            {
              "lat": 43.75594925555556,
              "lng": -116.0945821888889,
              "alt": 2143.3742222222218,
              "speed2d": 2.3645555555555555,
              "speed3d": 2.4099999999999997,
              "msFromStart": 429272.6416842104
            },
            {
              "lat": 43.7559385,
              "lng": -116.09458085555553,
              "alt": 2143.849111111111,
              "speed2d": 2.4234444444444443,
              "speed3d": 2.471111111111111,
              "msFromStart": 429767.751111111
            },
            {
              "lat": 43.755927022222224,
              "lng": -116.09458042222224,
              "alt": 2144.1845555555556,
              "speed2d": 2.5353333333333334,
              "speed3d": 2.583333333333333,
              "msFromStart": 430262.2631111109
            },
            {
              "lat": 43.75591701111111,
              "lng": -116.09458024444444,
              "alt": 2144.385333333333,
              "speed2d": 2.3284444444444445,
              "speed3d": 2.417777777777778,
              "msFromStart": 430756.7822222223
            },
            {
              "lat": 43.755906466666666,
              "lng": -116.09457748888889,
              "alt": 2144.5017777777775,
              "speed2d": 2.3806666666666665,
              "speed3d": 2.4,
              "msFromStart": 431251.31022222224
            },
            {
              "lat": 43.75589617777778,
              "lng": -116.09457583333332,
              "alt": 2144.636111111111,
              "speed2d": 2.3492222222222225,
              "speed3d": 2.4066666666666667,
              "msFromStart": 431746.4995555555
            },
            {
              "lat": 43.75588496666667,
              "lng": -116.09457352222223,
              "alt": 2144.846,
              "speed2d": 2.4560000000000004,
              "speed3d": 2.4855555555555555,
              "msFromStart": 432242.5155555553
            },
            {
              "lat": 43.755874411111115,
              "lng": -116.09457125555556,
              "alt": 2145.117888888889,
              "speed2d": 2.415333333333333,
              "speed3d": 2.462222222222222,
              "msFromStart": 432737.8915555555
            },
            {
              "lat": 43.755863233333336,
              "lng": -116.09456886666668,
              "alt": 2145.3816666666667,
              "speed2d": 2.538666666666667,
              "speed3d": 2.5377777777777775,
              "msFromStart": 433232.4675555555
            },
            {
              "lat": 43.75585197777778,
              "lng": -116.09456723333332,
              "alt": 2145.6454444444444,
              "speed2d": 2.4691111111111113,
              "speed3d": 2.5488888888888885,
              "msFromStart": 433726.8800000001
            },
            {
              "lat": 43.75584052,
              "lng": -116.0945656,
              "alt": 2145.797,
              "speed2d": 2.4031000000000002,
              "speed3d": 2.4330000000000003,
              "msFromStart": 434248.5440000001
            },
            {
              "lat": 43.75582845555556,
              "lng": -116.09456312222221,
              "alt": 2146.1016666666665,
              "speed2d": 2.534222222222222,
              "speed3d": 2.562222222222222,
              "msFromStart": 434770.97599999997
            },
            {
              "lat": 43.755817500000006,
              "lng": -116.09456164444445,
              "alt": 2146.1763333333333,
              "speed2d": 2.491111111111111,
              "speed3d": 2.5100000000000002,
              "msFromStart": 435266.9119999999
            },
            {
              "lat": 43.755806688888896,
              "lng": -116.09456013333332,
              "alt": 2146.3051111111113,
              "speed2d": 2.410222222222222,
              "speed3d": 2.454444444444445,
              "msFromStart": 435762.5564444444
            },
            {
              "lat": 43.75579566666667,
              "lng": -116.09455813333332,
              "alt": 2146.4645555555553,
              "speed2d": 2.4234444444444447,
              "speed3d": 2.446666666666667,
              "msFromStart": 436257.8364444444
            },
            {
              "lat": 43.755783611111106,
              "lng": -116.09455692222224,
              "alt": 2146.6512222222223,
              "speed2d": 2.524888888888889,
              "speed3d": 2.518888888888889,
              "msFromStart": 436753.2302222223
            },
            {
              "lat": 43.75577071111111,
              "lng": -116.09455412222222,
              "alt": 2146.9586666666664,
              "speed2d": 2.7216666666666667,
              "speed3d": 2.7111111111111112,
              "msFromStart": 437248.7662222225
            },
            {
              "lat": 43.75575878888889,
              "lng": -116.09455196666667,
              "alt": 2147.2561111111113,
              "speed2d": 2.6390000000000002,
              "speed3d": 2.718888888888889,
              "msFromStart": 437744.0817777777
            },
            {
              "lat": 43.75574898888889,
              "lng": -116.09454934444446,
              "alt": 2147.9088888888896,
              "speed2d": 2.3253333333333335,
              "speed3d": 2.407777777777777,
              "msFromStart": 438239.1217777776
            },
            {
              "lat": 43.755739766666665,
              "lng": -116.0945476888889,
              "alt": 2148.3407777777775,
              "speed2d": 2.169,
              "speed3d": 2.2177777777777776,
              "msFromStart": 438733.529263158
            },
            {
              "lat": 43.75572891111111,
              "lng": -116.09454591111111,
              "alt": 2148.435333333333,
              "speed2d": 2.228888888888889,
              "speed3d": 2.2877777777777775,
              "msFromStart": 439227.1461052635
            },
            {
              "lat": 43.75571674,
              "lng": -116.09454266,
              "alt": 2148.6574,
              "speed2d": 2.4947999999999997,
              "speed3d": 2.4699999999999998,
              "msFromStart": 439749.696168421
            },
            {
              "lat": 43.75570383333333,
              "lng": -116.09453924444446,
              "alt": 2149.0935555555557,
              "speed2d": 2.6112222222222217,
              "speed3d": 2.693333333333334,
              "msFromStart": 440274.679111111
            },
            {
              "lat": 43.75569298888889,
              "lng": -116.09453804444443,
              "alt": 2149.1467777777784,
              "speed2d": 2.3792222222222223,
              "speed3d": 2.4644444444444447,
              "msFromStart": 440770.70577777765
            },
            {
              "lat": 43.75568293333334,
              "lng": -116.09453772222221,
              "alt": 2149.1484444444445,
              "speed2d": 2.1785555555555556,
              "speed3d": 2.2233333333333336,
              "msFromStart": 441265.0257777774
            },
            {
              "lat": 43.755671500000005,
              "lng": -116.09453708888888,
              "alt": 2149.245888888889,
              "speed2d": 2.2665555555555557,
              "speed3d": 2.2533333333333334,
              "msFromStart": 441759.28888888884
            },
            {
              "lat": 43.75565795555555,
              "lng": -116.09453474444445,
              "alt": 2149.3303333333333,
              "speed2d": 2.6648888888888886,
              "speed3d": 2.661111111111111,
              "msFromStart": 442253.48088888876
            },
            {
              "lat": 43.75564473333334,
              "lng": -116.09453262222223,
              "alt": 2149.153444444444,
              "speed2d": 2.6997777777777783,
              "speed3d": 2.7111111111111112,
              "msFromStart": 442748.4337777778
            },
            {
              "lat": 43.75563316666667,
              "lng": -116.09453056666668,
              "alt": 2149.1601111111113,
              "speed2d": 2.4926666666666666,
              "speed3d": 2.5277777777777777,
              "msFromStart": 443244.3377777779
            },
            {
              "lat": 43.75562203333334,
              "lng": -116.09452765555555,
              "alt": 2149.192888888889,
              "speed2d": 2.419777777777778,
              "speed3d": 2.428888888888889,
              "msFromStart": 443739.45431578945
            },
            {
              "lat": 43.75561071111112,
              "lng": -116.09452554444445,
              "alt": 2149.246111111111,
              "speed2d": 2.4347777777777777,
              "speed3d": 2.4466666666666663,
              "msFromStart": 444233.58652631566
            },
            {
              "lat": 43.75559824444444,
              "lng": -116.09452393333333,
              "alt": 2148.9192222222223,
              "speed2d": 2.4451111111111112,
              "speed3d": 2.433333333333333,
              "msFromStart": 444728.13762703055
            },
            {
              "lat": 43.75558475,
              "lng": -116.09452194,
              "alt": 2148.3857,
              "speed2d": 2.5173,
              "speed3d": 2.5169999999999995,
              "msFromStart": 445250.98577777797
            },
            {
              "lat": 43.75557156666667,
              "lng": -116.09451960000001,
              "alt": 2147.938,
              "speed2d": 2.5606666666666666,
              "speed3d": 2.5477777777777777,
              "msFromStart": 445773.9413333333
            },
            {
              "lat": 43.755558944444445,
              "lng": -116.09451715555555,
              "alt": 2147.775777777778,
              "speed2d": 2.661777777777778,
              "speed3d": 2.67,
              "msFromStart": 446269.4933333332
            },
            {
              "lat": 43.7555466,
              "lng": -116.09451469999999,
              "alt": 2147.786666666667,
              "speed2d": 2.660555555555556,
              "speed3d": 2.6622222222222227,
              "msFromStart": 446765.1306666666
            },
            {
              "lat": 43.755534866666665,
              "lng": -116.09451291111112,
              "alt": 2147.46,
              "speed2d": 2.563777777777778,
              "speed3d": 2.576666666666666,
              "msFromStart": 447260.8746666665
            },
            {
              "lat": 43.75552301111111,
              "lng": -116.09451114444444,
              "alt": 2147.0897777777777,
              "speed2d": 2.5676666666666668,
              "speed3d": 2.5688888888888886,
              "msFromStart": 447755.75111111114
            },
            {
              "lat": 43.755511600000006,
              "lng": -116.09450915555556,
              "alt": 2146.848111111111,
              "speed2d": 2.471333333333334,
              "speed3d": 2.4944444444444445,
              "msFromStart": 448249.5431111113
            },
            {
              "lat": 43.75550084444444,
              "lng": -116.09450714444445,
              "alt": 2146.7254444444443,
              "speed2d": 2.4348888888888887,
              "speed3d": 2.4555555555555557,
              "msFromStart": 448743.54694736836
            },
            {
              "lat": 43.75548945555555,
              "lng": -116.09450472222223,
              "alt": 2146.554666666667,
              "speed2d": 2.563222222222222,
              "speed3d": 2.523333333333333,
              "msFromStart": 449237.81557894737
            },
            {
              "lat": 43.75547741111111,
              "lng": -116.0945023888889,
              "alt": 2146.2322222222224,
              "speed2d": 2.663888888888889,
              "speed3d": 2.69,
              "msFromStart": 449732.6273996101
            },
            {
              "lat": 43.75546538888888,
              "lng": -116.09450062222221,
              "alt": 2145.876111111111,
              "speed2d": 2.7004444444444444,
              "speed3d": 2.7055555555555557,
              "msFromStart": 450228.4479999999
            },
            {
              "lat": 43.75545306,
              "lng": -116.09449884,
              "alt": 2145.3403,
              "speed2d": 2.6022999999999996,
              "speed3d": 2.6350000000000002,
              "msFromStart": 450751.53386666655
            },
            {
              "lat": 43.7554416111111,
              "lng": -116.0944963,
              "alt": 2145.1117777777777,
              "speed2d": 2.4681111111111114,
              "speed3d": 2.5055555555555555,
              "msFromStart": 451274.135111111
            },
            {
              "lat": 43.755431022222226,
              "lng": -116.09449403333333,
              "alt": 2145.001,
              "speed2d": 2.4167777777777775,
              "speed3d": 2.438888888888889,
              "msFromStart": 451768.7466666667
            },
            {
              "lat": 43.75542053333333,
              "lng": -116.09449204444444,
              "alt": 2145.063444444444,
              "speed2d": 2.3793333333333333,
              "speed3d": 2.396666666666666,
              "msFromStart": 452262.76266666676
            },
            {
              "lat": 43.755409433333334,
              "lng": -116.09448952222222,
              "alt": 2145.1490000000003,
              "speed2d": 2.5053333333333336,
              "speed3d": 2.482222222222222,
              "msFromStart": 452757.57511111116
            },
            {
              "lat": 43.75539702222222,
              "lng": -116.0944875,
              "alt": 2145.0537777777777,
              "speed2d": 2.6774444444444447,
              "speed3d": 2.673333333333334,
              "msFromStart": 453253.38311111124
            },
            {
              "lat": 43.75538452222222,
              "lng": -116.09448474444443,
              "alt": 2145.051111111111,
              "speed2d": 2.790222222222222,
              "speed3d": 2.7966666666666664,
              "msFromStart": 453749.09305263154
            },
            {
              "lat": 43.755373811111106,
              "lng": -116.09448315555557,
              "alt": 2144.9125555555556,
              "speed2d": 2.46,
              "speed3d": 2.511111111111111,
              "msFromStart": 454244.6804210525
            },
            {
              "lat": 43.75536318888889,
              "lng": -116.09448118888889,
              "alt": 2144.9035555555556,
              "speed2d": 2.4255555555555555,
              "speed3d": 2.4266666666666667,
              "msFromStart": 454739.82415594533
            },
            {
              "lat": 43.75535272222223,
              "lng": -116.09448008888889,
              "alt": 2144.6327777777774,
              "speed2d": 2.316222222222222,
              "speed3d": 2.3433333333333333,
              "msFromStart": 455234.1440000001
            },
            {
              "lat": 43.75534216666667,
              "lng": -116.09447903333333,
              "alt": 2144.219111111111,
              "speed2d": 2.2740000000000005,
              "speed3d": 2.287777777777778,
              "msFromStart": 455728.5364938271
            },
            {
              "lat": 43.75533079,
              "lng": -116.09447833,
              "alt": 2143.8514,
              "speed2d": 2.3484000000000003,
              "speed3d": 2.362,
              "msFromStart": 456250.5688888886
            },
            {
              "lat": 43.75531885555556,
              "lng": -116.09448006666666,
              "alt": 2143.532444444444,
              "speed2d": 2.4442222222222223,
              "speed3d": 2.4499999999999997,
              "msFromStart": 456772.7324444444
            }
          ]
        }
      ]
    },
    {
      "id": "trail-morning-star",
      "pathType": "trail",
      "name": "Morning Star",
      "difficulty": "blue",
      "videos": [
        {
          "videoId": "GX010083",
          "pathId": "trail-morning-star",
          "startSec": 28,
          "endSec": 78,
          "id": "video-segment-49",
          "gps": [
            {
              "lat": 43.77194583333334,
              "lng": -116.10013123333331,
              "alt": 2065.6164444444444,
              "speed2d": 1.5110000000000001,
              "speed3d": 1.7222222222222223,
              "msFromStart": 28258.872555555565
            },
            {
              "lat": 43.7719299,
              "lng": -116.10011691111113,
              "alt": 2065.7585555555556,
              "speed2d": 2.7863333333333338,
              "speed3d": 2.688888888888889,
              "msFromStart": 28753.61666666667
            },
            {
              "lat": 43.7719048,
              "lng": -116.10009947777777,
              "alt": 2066.315777777778,
              "speed2d": 4.040777777777778,
              "speed3d": 3.9911111111111115,
              "msFromStart": 29248.31923976608
            },
            {
              "lat": 43.77188382222222,
              "lng": -116.10008402222222,
              "alt": 2066.306666666667,
              "speed2d": 4.369666666666666,
              "speed3d": 4.420000000000001,
              "msFromStart": 29743.061368421033
            },
            {
              "lat": 43.771863422222225,
              "lng": -116.10006718888889,
              "alt": 2066.4638888888885,
              "speed2d": 4.651555555555555,
              "speed3d": 4.642222222222222,
              "msFromStart": 30237.94239181286
            },
            {
              "lat": 43.77184246666667,
              "lng": -116.10004745555555,
              "alt": 2065.985333333333,
              "speed2d": 4.961555555555555,
              "speed3d": 4.975555555555555,
              "msFromStart": 30733.37066666667
            },
            {
              "lat": 43.77181776666667,
              "lng": -116.1000276111111,
              "alt": 2065.4205555555554,
              "speed2d": 5.626111111111111,
              "speed3d": 5.568888888888889,
              "msFromStart": 31228.63922222222
            },
            {
              "lat": 43.77179381,
              "lng": -116.09999147999999,
              "alt": 2064.5481999999997,
              "speed2d": 6.7372000000000005,
              "speed3d": 6.661999999999999,
              "msFromStart": 31750.512499999993
            },
            {
              "lat": 43.771752166666666,
              "lng": -116.09996046666666,
              "alt": 2063.932555555555,
              "speed2d": 8.681,
              "speed3d": 8.464444444444446,
              "msFromStart": 32272.44207407407
            },
            {
              "lat": 43.77170587777778,
              "lng": -116.09992886666667,
              "alt": 2063.174222222222,
              "speed2d": 10.110666666666667,
              "speed3d": 10.14,
              "msFromStart": 32767.29477777778
            },
            {
              "lat": 43.771674922222225,
              "lng": -116.09988468888888,
              "alt": 2061.8451111111112,
              "speed2d": 10.096222222222222,
              "speed3d": 10.159999999999998,
              "msFromStart": 33262.34070370371
            },
            {
              "lat": 43.77163780000001,
              "lng": -116.09984204444444,
              "alt": 2060.997444444444,
              "speed2d": 10.588999999999999,
              "speed3d": 10.605555555555556,
              "msFromStart": 33757.85599999998
            },
            {
              "lat": 43.77158738888889,
              "lng": -116.09980371111111,
              "alt": 2060.3143333333333,
              "speed2d": 11.567,
              "speed3d": 11.523333333333332,
              "msFromStart": 34253.22644444443
            },
            {
              "lat": 43.771535088888896,
              "lng": -116.0997675888889,
              "alt": 2059.493111111111,
              "speed2d": 11.997777777777777,
              "speed3d": 12.134444444444444,
              "msFromStart": 34748.136666666636
            },
            {
              "lat": 43.77149615555556,
              "lng": -116.09974052222222,
              "alt": 2057.4284444444443,
              "speed2d": 11.011111111111113,
              "speed3d": 11.202222222222222,
              "msFromStart": 35242.80071345029
            },
            {
              "lat": 43.77144722222222,
              "lng": -116.0997282111111,
              "alt": 2055.3882222222223,
              "speed2d": 11.429333333333332,
              "speed3d": 11.53111111111111,
              "msFromStart": 35736.86231578951
            },
            {
              "lat": 43.77139921111112,
              "lng": -116.09972276666666,
              "alt": 2053.6053333333334,
              "speed2d": 10.997999999999998,
              "speed3d": 11.285555555555554,
              "msFromStart": 36231.07445224174
            },
            {
              "lat": 43.771346199999996,
              "lng": -116.09971533333334,
              "alt": 2051.482666666667,
              "speed2d": 11.728888888888887,
              "speed3d": 11.751111111111111,
              "msFromStart": 36726.01555555558
            },
            {
              "lat": 43.7712849,
              "lng": -116.09970264285715,
              "alt": 2050.0904285714287,
              "speed2d": 13.464857142857145,
              "speed3d": 13.47285714285714,
              "msFromStart": 37166.01104761906
            },
            {
              "lat": 43.77123837770413,
              "lng": -116.099681458539,
              "alt": 2048.681234041177,
              "speed2d": 14.42090345084027,
              "speed3d": 14.429381855806762,
              "msFromStart": 37500
            },
            {
              "lat": 43.771173094835405,
              "lng": -116.0996380880552,
              "alt": 2046.3211559223712,
              "speed2d": 15.379701133770572,
              "speed3d": 15.526852511196477,
              "msFromStart": 38000
            },
            {
              "lat": 43.7710754,
              "lng": -116.09959002,
              "alt": 2042.6864,
              "speed2d": 15.5854,
              "speed3d": 15.904,
              "msFromStart": 38728.62213333337
            },
            {
              "lat": 43.771013339999996,
              "lng": -116.0995657,
              "alt": 2040.3577999999998,
              "speed2d": 15.048399999999999,
              "speed3d": 15.394,
              "msFromStart": 39224.28195789475
            },
            {
              "lat": 43.77094275555556,
              "lng": -116.099553,
              "alt": 2038.2015555555554,
              "speed2d": 14.750444444444444,
              "speed3d": 15.182222222222222,
              "msFromStart": 39752.51136842102
            },
            {
              "lat": 43.770882775000004,
              "lng": -116.0995434,
              "alt": 2035.868375,
              "speed2d": 14.174499999999998,
              "speed3d": 14.517499999999998,
              "msFromStart": 40261.367846491215
            },
            {
              "lat": 43.7708271,
              "lng": -116.09952923333334,
              "alt": 2033.8905,
              "speed2d": 14.370166666666666,
              "speed3d": 14.703333333333333,
              "msFromStart": 40696.6492962963
            },
            {
              "lat": 43.7707539125,
              "lng": -116.09949490000001,
              "alt": 2031.1326250000002,
              "speed2d": 15.198625000000002,
              "speed3d": 15.53875,
              "msFromStart": 41244.06
            },
            {
              "lat": 43.77072179008738,
              "lng": -116.09947337844126,
              "alt": 2029.6890768354071,
              "speed2d": 15.397112820109582,
              "speed3d": 15.67372082692962,
              "msFromStart": 41500
            },
            {
              "lat": 43.77066990665132,
              "lng": -116.0994213,
              "alt": 2028.761478270363,
              "speed2d": 13.404779881029896,
              "speed3d": 12.60199127336843,
              "msFromStart": 42000
            },
            {
              "lat": 43.77057296000001,
              "lng": -116.09941809,
              "alt": 2021.3959,
              "speed2d": 14.3961,
              "speed3d": 14.952,
              "msFromStart": 42748.0236666667
            },
            {
              "lat": 43.770506233333336,
              "lng": -116.09941717777777,
              "alt": 2018.5951111111108,
              "speed2d": 14.237555555555556,
              "speed3d": 14.816666666666666,
              "msFromStart": 43270.582740740734
            },
            {
              "lat": 43.770441625000004,
              "lng": -116.099419175,
              "alt": 2016.13375,
              "speed2d": 14.463999999999999,
              "speed3d": 15.024999999999999,
              "msFromStart": 43753.24233333331
            },
            {
              "lat": 43.770381566666664,
              "lng": -116.09941926666667,
              "alt": 2013.6473333333333,
              "speed2d": 14.557333333333332,
              "speed3d": 15.12,
              "msFromStart": 44208.04044444443
            },
            {
              "lat": 43.770314716666675,
              "lng": -116.0994101,
              "alt": 2011.1841666666667,
              "speed2d": 15.1885,
              "speed3d": 15.751666666666667,
              "msFromStart": 44675.34
            },
            {
              "lat": 43.770230325,
              "lng": -116.09938137500001,
              "alt": 2007.353,
              "speed2d": 14.8665,
              "speed3d": 15.5425,
              "msFromStart": 45335.29547368422
            },
            {
              "lat": 43.77017754285715,
              "lng": -116.09936267142857,
              "alt": 2005.5629999999999,
              "speed2d": 15.156714285714285,
              "speed3d": 15.772857142857141,
              "msFromStart": 45716.95181954889
            },
            {
              "lat": 43.770108324999995,
              "lng": -116.09933497499999,
              "alt": 2003.1775,
              "speed2d": 15.43075,
              "speed3d": 16.08125,
              "msFromStart": 46216.570406432766
            },
            {
              "lat": 43.77004305714286,
              "lng": -116.09929922857143,
              "alt": 2000.3557142857144,
              "speed2d": 15.98042857142857,
              "speed3d": 16.548571428571428,
              "msFromStart": 46692.17853968255
            },
            {
              "lat": 43.77000109907123,
              "lng": -116.09927575722071,
              "alt": 1998.6015779564702,
              "speed2d": 16.158460351553433,
              "speed3d": 16.7324852416616,
              "msFromStart": 47000
            },
            {
              "lat": 43.769938324098085,
              "lng": -116.09925304082444,
              "alt": 1996.203644260444,
              "speed2d": 15.381472784674477,
              "speed3d": 16.013167143034632,
              "msFromStart": 47500
            },
            {
              "lat": 43.76987554912494,
              "lng": -116.09923032442816,
              "alt": 1993.8057105644177,
              "speed2d": 14.604485217795522,
              "speed3d": 15.293849044407667,
              "msFromStart": 48000
            },
            {
              "lat": 43.769812774151795,
              "lng": -116.09920760803188,
              "alt": 1991.4077768683915,
              "speed2d": 13.827497650916566,
              "speed3d": 14.574530945780701,
              "msFromStart": 48500
            },
            {
              "lat": 43.76970215,
              "lng": -116.0991712,
              "alt": 1987.3165,
              "speed2d": 12.624500000000001,
              "speed3d": 13.43,
              "msFromStart": 49378.48631578948
            },
            {
              "lat": 43.76966625,
              "lng": -116.09917535,
              "alt": 1987.04,
              "speed2d": 13.153,
              "speed3d": 13.795,
              "msFromStart": 49626.50642105265
            },
            {
              "lat": 43.76961689515654,
              "lng": -116.09917419804037,
              "alt": 1985.7842335789562,
              "speed2d": 13.350854109364205,
              "speed3d": 13.969359461785563,
              "msFromStart": 50000
            },
            {
              "lat": 43.769511574999996,
              "lng": -116.099164425,
              "alt": 1982.34,
              "speed2d": 13.57025,
              "speed3d": 14.21,
              "msFromStart": 50823.357499999984
            },
            {
              "lat": 43.769447962499996,
              "lng": -116.099146725,
              "alt": 1980.3575,
              "speed2d": 14.191125,
              "speed3d": 14.886250000000002,
              "msFromStart": 51276.70916666666
            },
            {
              "lat": 43.7694121,
              "lng": -116.0991341,
              "alt": 1979.327,
              "speed2d": 14.0855,
              "speed3d": 14.865,
              "msFromStart": 51551.56233333334
            },
            {
              "lat": 43.76932106666666,
              "lng": -116.09910836666668,
              "alt": 1977.0988333333335,
              "speed2d": 13.804333333333334,
              "speed3d": 14.488333333333333,
              "msFromStart": 52238.72025925926
            },
            {
              "lat": 43.76925812857143,
              "lng": -116.09909604285713,
              "alt": 1974.6452857142858,
              "speed2d": 14.141714285714286,
              "speed3d": 14.804285714285713,
              "msFromStart": 52709.91282539682
            },
            {
              "lat": 43.769217335532595,
              "lng": -116.09909301967551,
              "alt": 1972.865005652126,
              "speed2d": 14.594584795643344,
              "speed3d": 15.139980277825437,
              "msFromStart": 53000
            },
            {
              "lat": 43.7691122125,
              "lng": -116.09910632500001,
              "alt": 1968.8392500000002,
              "speed2d": 15.582875,
              "speed3d": 16.28,
              "msFromStart": 53708.72199999999
            },
            {
              "lat": 43.7690368,
              "lng": -116.0991313,
              "alt": 1964.146,
              "speed2d": 15.872125000000002,
              "speed3d": 16.61625,
              "msFromStart": 54278.98331578947
            },
            {
              "lat": 43.76895188,
              "lng": -116.09915397999998,
              "alt": 1961.4482,
              "speed2d": 16.598,
              "speed3d": 17.308,
              "msFromStart": 54812.069010526284
            },
            {
              "lat": 43.76890625,
              "lng": -116.09916129999999,
              "alt": 1961.0265,
              "speed2d": 16.825,
              "speed3d": 17.56,
              "msFromStart": 55071.06968421048
            },
            {
              "lat": 43.768841719852425,
              "lng": -116.09917726996775,
              "alt": 1959.3759279328838,
              "speed2d": 16.11176882377212,
              "speed3d": 17.139334665512468,
              "msFromStart": 55500
            },
            {
              "lat": 43.768720557142856,
              "lng": -116.09915191428573,
              "alt": 1955.1321428571428,
              "speed2d": 17.638142857142856,
              "speed3d": 18.267142857142854,
              "msFromStart": 56197.69238095238
            },
            {
              "lat": 43.7686642012892,
              "lng": -116.09914129715017,
              "alt": 1954.3660274519902,
              "speed2d": 18.315752632742797,
              "speed3d": 18.81519203853175,
              "msFromStart": 56500
            },
            {
              "lat": 43.76858729216299,
              "lng": -116.09912304324185,
              "alt": 1952.0223469786495,
              "speed2d": 17.423283153682036,
              "speed3d": 18.537085401997235,
              "msFromStart": 57000
            },
            {
              "lat": 43.76850901417092,
              "lng": -116.09910438983844,
              "alt": 1949.819302836037,
              "speed2d": 16.56955084911859,
              "speed3d": 18.33520130517801,
              "msFromStart": 57500
            },
            {
              "lat": 43.768502837244775,
              "lng": -116.099101151954,
              "alt": 1940.6566733808074,
              "speed2d": 12.265325724009424,
              "speed3d": 13.459783768962133,
              "msFromStart": 58000
            },
            {
              "lat": 43.7683605,
              "lng": -116.099087325,
              "alt": 1937.69575,
              "speed2d": 15.41,
              "speed3d": 16.322499999999998,
              "msFromStart": 58795.30099999998
            },
            {
              "lat": 43.76830966,
              "lng": -116.09908907999998,
              "alt": 1935.4347999999998,
              "speed2d": 15.408,
              "speed3d": 16.478,
              "msFromStart": 59156.04847719296
            },
            {
              "lat": 43.76826560424393,
              "lng": -116.09908143668699,
              "alt": 1932.7323892472302,
              "speed2d": 14.562157884274242,
              "speed3d": 15.781359800890444,
              "msFromStart": 59500
            },
            {
              "lat": 43.768169799999995,
              "lng": -116.09915835,
              "alt": 1927.107,
              "speed2d": 14.085,
              "speed3d": 15.26,
              "msFromStart": 60402.76211111111
            },
            {
              "lat": 43.76816470471551,
              "lng": -116.09917498206897,
              "alt": 1926.4503734481327,
              "speed2d": 14.172877988538936,
              "speed3d": 15.316820689683023,
              "msFromStart": 60500
            },
            {
              "lat": 43.768059799999996,
              "lng": -116.09923825,
              "alt": 1923.02925,
              "speed2d": 14.834249999999999,
              "speed3d": 15.9125,
              "msFromStart": 61255.89027777778
            },
            {
              "lat": 43.768026224130324,
              "lng": -116.09925187620198,
              "alt": 1921.8925987016523,
              "speed2d": 14.74244498024661,
              "speed3d": 15.821147022386796,
              "msFromStart": 61500
            },
            {
              "lat": 43.76796335393264,
              "lng": -116.09926847702705,
              "alt": 1919.5817804649314,
              "speed2d": 13.957408075876556,
              "speed3d": 15.143345201573126,
              "msFromStart": 62000
            },
            {
              "lat": 43.7678729,
              "lng": -116.09929735,
              "alt": 1915.3065000000001,
              "speed2d": 14.446,
              "speed3d": 15.495000000000001,
              "msFromStart": 62660.88288888887
            },
            {
              "lat": 43.76783093049801,
              "lng": -116.09931893183732,
              "alt": 1912.9712274640572,
              "speed2d": 14.40637260154157,
              "speed3d": 15.54645055485705,
              "msFromStart": 63000
            },
            {
              "lat": 43.767767011392316,
              "lng": -116.09934725807216,
              "alt": 1909.7377726443929,
              "speed2d": 14.27199314419081,
              "speed3d": 15.492672373210642,
              "msFromStart": 63500
            },
            {
              "lat": 43.76772102688958,
              "lng": -116.09940922956703,
              "alt": 1904.9377162195299,
              "speed2d": 14.657919807591986,
              "speed3d": 15.837752806362296,
              "msFromStart": 64000
            },
            {
              "lat": 43.767683819817655,
              "lng": -116.09947750481092,
              "alt": 1898.5826202189348,
              "speed2d": 14.887995101691649,
              "speed3d": 16.227439499384925,
              "msFromStart": 64500
            },
            {
              "lat": 43.767622149999994,
              "lng": -116.09958085,
              "alt": 1888.6064999999999,
              "speed2d": 14.836375,
              "speed3d": 16.65375,
              "msFromStart": 65299.213271929824
            },
            {
              "lat": 43.767580550000005,
              "lng": -116.0996108,
              "alt": 1885.7085,
              "speed2d": 13.970500000000001,
              "speed3d": 16.05,
              "msFromStart": 65657.47377777778
            },
            {
              "lat": 43.7675274595225,
              "lng": -116.0996288265537,
              "alt": 1884.454767945694,
              "speed2d": 13.593931788129607,
              "speed3d": 15.440720767315334,
              "msFromStart": 66000
            },
            {
              "lat": 43.76743555100738,
              "lng": -116.0996550780121,
              "alt": 1884.341977588654,
              "speed2d": 14.137427891448482,
              "speed3d": 15.778807731742653,
              "msFromStart": 66500
            },
            {
              "lat": 43.76734364249226,
              "lng": -116.09968132947053,
              "alt": 1884.2291872316139,
              "speed2d": 14.680923994767356,
              "speed3d": 16.11689469616997,
              "msFromStart": 67000
            },
            {
              "lat": 43.767182975000004,
              "lng": -116.0997281,
              "alt": 1883.84375,
              "speed2d": 15.53575,
              "speed3d": 16.665,
              "msFromStart": 67885.79022222225
            },
            {
              "lat": 43.767130200000004,
              "lng": -116.09974629999999,
              "alt": 1882.4761111111109,
              "speed2d": 15.325777777777779,
              "speed3d": 16.48111111111111,
              "msFromStart": 68243.49244444445
            },
            {
              "lat": 43.767118229721994,
              "lng": -116.09975158718375,
              "alt": 1879.2090484807336,
              "speed2d": 13.298391227974365,
              "speed3d": 14.575265596063815,
              "msFromStart": 68500
            },
            {
              "lat": 43.766949133333334,
              "lng": -116.09979799999999,
              "alt": 1880.2890000000002,
              "speed2d": 15.374333333333333,
              "speed3d": 16.323333333333334,
              "msFromStart": 69397.68842105263
            },
            {
              "lat": 43.7669020111111,
              "lng": -116.09980868888887,
              "alt": 1879.6346666666666,
              "speed2d": 14.699,
              "speed3d": 15.759999999999998,
              "msFromStart": 69727.55705263159
            },
            {
              "lat": 43.76685148,
              "lng": -116.09982111999999,
              "alt": 1878.7387999999999,
              "speed2d": 13.814399999999997,
              "speed3d": 14.869999999999997,
              "msFromStart": 70112.4037894737
            },
            {
              "lat": 43.76679894516909,
              "lng": -116.09983600082073,
              "alt": 1878.1258483975105,
              "speed2d": 13.406348709170228,
              "speed3d": 14.37341125138384,
              "msFromStart": 70500
            },
            {
              "lat": 43.76667343333334,
              "lng": -116.09987273333334,
              "alt": 1877.4389999999999,
              "speed2d": 13.142333333333333,
              "speed3d": 13.923333333333332,
              "msFromStart": 71379.928
            },
            {
              "lat": 43.76661535,
              "lng": -116.09989,
              "alt": 1876.8519999999999,
              "speed2d": 13.0645,
              "speed3d": 13.895,
              "msFromStart": 71793.508
            },
            {
              "lat": 43.76659956828393,
              "lng": -116.09989614084105,
              "alt": 1876.1516029026734,
              "speed2d": 11.714623977761303,
              "speed3d": 12.67051136179982,
              "msFromStart": 72000
            },
            {
              "lat": 43.766504936499466,
              "lng": -116.09992277725819,
              "alt": 1877.690077520132,
              "speed2d": 14.213979043368674,
              "speed3d": 14.624091394198203,
              "msFromStart": 72500
            },
            {
              "lat": 43.766443236926364,
              "lng": -116.09994460708256,
              "alt": 1876.9401826266937,
              "speed2d": 13.45195646971318,
              "speed3d": 13.896430581158475,
              "msFromStart": 73000
            },
            {
              "lat": 43.766380231565705,
              "lng": -116.09996339346793,
              "alt": 1876.2050157473418,
              "speed2d": 12.539959023626832,
              "speed3d": 13.014525459321783,
              "msFromStart": 73500
            },
            {
              "lat": 43.76632194043405,
              "lng": -116.09999316745116,
              "alt": 1875.4166769481076,
              "speed2d": 12.169409450594847,
              "speed3d": 12.689481974206007,
              "msFromStart": 74000
            },
            {
              "lat": 43.766261610873954,
              "lng": -116.10002355706442,
              "alt": 1873.8756722214825,
              "speed2d": 11.900507217908112,
              "speed3d": 12.548280570963877,
              "msFromStart": 74500
            },
            {
              "lat": 43.76620627190028,
              "lng": -116.10005243946026,
              "alt": 1874.1773833381908,
              "speed2d": 11.382746683456379,
              "speed3d": 11.956987427666144,
              "msFromStart": 75000
            },
            {
              "lat": 43.76615089837916,
              "lng": -116.10008135612563,
              "alt": 1874.4834069675092,
              "speed2d": 10.863883230325584,
              "speed3d": 11.364127104693859,
              "msFromStart": 75500
            },
            {
              "lat": 43.76609737831489,
              "lng": -116.10010392605318,
              "alt": 1874.3003522616968,
              "speed2d": 10.377393758493488,
              "speed3d": 10.850065521257998,
              "msFromStart": 76000
            },
            {
              "lat": 43.766051020793704,
              "lng": -116.100134746038,
              "alt": 1873.971475522552,
              "speed2d": 9.95993611272891,
              "speed3d": 10.437579596194906,
              "msFromStart": 76500
            },
            {
              "lat": 43.76598951628251,
              "lng": -116.10016172494822,
              "alt": 1875.3336135445386,
              "speed2d": 10.65189746448256,
              "speed3d": 10.956691287418241,
              "msFromStart": 77000
            },
            {
              "lat": 43.765943474451646,
              "lng": -116.10019493228047,
              "alt": 1875.2143532185862,
              "speed2d": 10.430193098409124,
              "speed3d": 10.720876704497934,
              "msFromStart": 77500
            },
            {
              "lat": 43.76589743262078,
              "lng": -116.10022813961272,
              "alt": 1875.0950928926338,
              "speed2d": 10.208488732335688,
              "speed3d": 10.485062121577627,
              "msFromStart": 78000
            }
          ]
        }
      ]
    },
    {
      "id": "trail-deer-point-lift-to-nugget-cat",
      "pathType": "trail",
      "name": "Deer Point Lift to Nugget Cat Trail",
      "difficulty": "blue",
      "isCatTrack": true,
      "videos": [
        {
          "videoId": "GX010072",
          "pathId": "trail-deer-point-lift-to-nugget-cat",
          "startSec": 6,
          "endSec": 51,
          "id": "video-segment-34",
          "gps": [
            {
              "lat": 43.75526591111111,
              "lng": -116.0944312,
              "alt": 2143.076666666667,
              "speed2d": 2.8869999999999996,
              "speed3d": 2.8722222222222222,
              "msFromStart": 6259.428925925928
            },
            {
              "lat": 43.75524816666666,
              "lng": -116.09442665555555,
              "alt": 2142.9604444444444,
              "speed2d": 3.486666666666667,
              "speed3d": 3.3844444444444446,
              "msFromStart": 6754.457666666669
            },
            {
              "lat": 43.75522703333333,
              "lng": -116.09441482222222,
              "alt": 2142.8232222222223,
              "speed2d": 4.357444444444445,
              "speed3d": 4.2877777777777775,
              "msFromStart": 7249.688481481482
            },
            {
              "lat": 43.75520835555556,
              "lng": -116.0943989111111,
              "alt": 2142.632,
              "speed2d": 4.89111111111111,
              "speed3d": 4.841111111111111,
              "msFromStart": 7745.513222222218
            },
            {
              "lat": 43.7551923,
              "lng": -116.09437875555557,
              "alt": 2142.7446666666665,
              "speed2d": 4.941222222222222,
              "speed3d": 5.0166666666666675,
              "msFromStart": 8240.881037037036
            },
            {
              "lat": 43.75518501111111,
              "lng": -116.09435963333333,
              "alt": 2142.6236666666664,
              "speed2d": 3.829222222222222,
              "speed3d": 3.928888888888889,
              "msFromStart": 8734.92377777777
            },
            {
              "lat": 43.75518557777778,
              "lng": -116.09433410000003,
              "alt": 2142.4346666666665,
              "speed2d": 3.950333333333333,
              "speed3d": 3.8811111111111107,
              "msFromStart": 9229.165861598438
            },
            {
              "lat": 43.755188090000004,
              "lng": -116.09431067,
              "alt": 2142.3949,
              "speed2d": 3.7081999999999997,
              "speed3d": 3.8180000000000005,
              "msFromStart": 9751.638921052634
            },
            {
              "lat": 43.755192099999995,
              "lng": -116.09429684444444,
              "alt": 2142.2092222222223,
              "speed2d": 2.9394444444444447,
              "speed3d": 2.997777777777778,
              "msFromStart": 10273.921003898637
            },
            {
              "lat": 43.75519805555556,
              "lng": -116.09428545555556,
              "alt": 2142.1005555555553,
              "speed2d": 2.5604444444444443,
              "speed3d": 2.608888888888889,
              "msFromStart": 10768.079777777786
            },
            {
              "lat": 43.75520331111112,
              "lng": -116.09427400000001,
              "alt": 2142.1423333333337,
              "speed2d": 2.413222222222222,
              "speed3d": 2.4144444444444444,
              "msFromStart": 11262.369759259262
            },
            {
              "lat": 43.75520755555556,
              "lng": -116.09426326666667,
              "alt": 2142.101888888889,
              "speed2d": 2.102888888888889,
              "speed3d": 2.148888888888889,
              "msFromStart": 11757.104833333335
            },
            {
              "lat": 43.75521117777778,
              "lng": -116.0942488888889,
              "alt": 2142.8716666666664,
              "speed2d": 2.2485555555555554,
              "speed3d": 2.22,
              "msFromStart": 12251.98622222222
            },
            {
              "lat": 43.75521568888889,
              "lng": -116.09423985555557,
              "alt": 2144.05,
              "speed2d": 2.0124444444444443,
              "speed3d": 2.0733333333333337,
              "msFromStart": 12747.201166666662
            },
            {
              "lat": 43.75522021111111,
              "lng": -116.0942359777778,
              "alt": 2144.788222222222,
              "speed2d": 1.5663333333333331,
              "speed3d": 1.6377777777777776,
              "msFromStart": 13242.248814814815
            },
            {
              "lat": 43.75522966666667,
              "lng": -116.09423524444446,
              "alt": 2145.0803333333333,
              "speed2d": 1.7074444444444445,
              "speed3d": 1.7177777777777776,
              "msFromStart": 13736.794722222217
            },
            {
              "lat": 43.75523887777778,
              "lng": -116.0942362,
              "alt": 2145.3825555555554,
              "speed2d": 1.7343333333333335,
              "speed3d": 1.792222222222222,
              "msFromStart": 14231.194287524364
            },
            {
              "lat": 43.75524651,
              "lng": -116.09423672,
              "alt": 2145.3393,
              "speed2d": 1.5193999999999999,
              "speed3d": 1.5989999999999998,
              "msFromStart": 14752.715921052637
            },
            {
              "lat": 43.75525237777777,
              "lng": -116.0942368,
              "alt": 2144.8058888888886,
              "speed2d": 1.2674444444444446,
              "speed3d": 1.3233333333333333,
              "msFromStart": 15274.518466861602
            },
            {
              "lat": 43.755256366666664,
              "lng": -116.0942345111111,
              "alt": 2144.0534444444443,
              "speed2d": 1.1105555555555555,
              "speed3d": 1.1444444444444444,
              "msFromStart": 15769.682388888894
            },
            {
              "lat": 43.755262411111104,
              "lng": -116.09423692222222,
              "alt": 2143.579888888889,
              "speed2d": 1.176,
              "speed3d": 1.1277777777777775,
              "msFromStart": 16265.132074074076
            },
            {
              "lat": 43.755269411111115,
              "lng": -116.09423137777777,
              "alt": 2143.5644444444442,
              "speed2d": 1.5302222222222224,
              "speed3d": 1.5233333333333334,
              "msFromStart": 16761.241833333326
            },
            {
              "lat": 43.75527726666667,
              "lng": -116.09422927777777,
              "alt": 2143.549222222222,
              "speed2d": 1.5767777777777778,
              "speed3d": 1.5666666666666669,
              "msFromStart": 17257.1915
            },
            {
              "lat": 43.75528517777778,
              "lng": -116.09422687777777,
              "alt": 2143.264222222222,
              "speed2d": 1.7399999999999998,
              "speed3d": 1.7377777777777776,
              "msFromStart": 17752.612333333353
            },
            {
              "lat": 43.75529556666667,
              "lng": -116.09422497777777,
              "alt": 2143.4585555555554,
              "speed2d": 2.0154444444444444,
              "speed3d": 1.9455555555555555,
              "msFromStart": 18247.879592592595
            },
            {
              "lat": 43.75530606666666,
              "lng": -116.0942213888889,
              "alt": 2143.4075555555555,
              "speed2d": 2.266777777777778,
              "speed3d": 2.287777777777778,
              "msFromStart": 18742.805555555544
            },
            {
              "lat": 43.75531392222222,
              "lng": -116.0942216888889,
              "alt": 2143.4235555555556,
              "speed2d": 1.8928888888888886,
              "speed3d": 1.9088888888888886,
              "msFromStart": 19237.86217933723
            },
            {
              "lat": 43.75532331111111,
              "lng": -116.09421701111111,
              "alt": 2143.458666666667,
              "speed2d": 2.147222222222222,
              "speed3d": 2.1333333333333333,
              "msFromStart": 19733.322210526305
            },
            {
              "lat": 43.75533218888889,
              "lng": -116.09421432222223,
              "alt": 2143.452222222222,
              "speed2d": 2.077777777777778,
              "speed3d": 2.091111111111111,
              "msFromStart": 20228.448054580887
            },
            {
              "lat": 43.75533935,
              "lng": -116.09420828999998,
              "alt": 2143.5204,
              "speed2d": 1.9905000000000002,
              "speed3d": 2.007,
              "msFromStart": 20749.572333333323
            },
            {
              "lat": 43.755349066666675,
              "lng": -116.09420676666666,
              "alt": 2143.246555555556,
              "speed2d": 1.9652222222222222,
              "speed3d": 1.9444444444444442,
              "msFromStart": 21271.21892592592
            },
            {
              "lat": 43.75535902222222,
              "lng": -116.09420436666666,
              "alt": 2142.8879999999995,
              "speed2d": 2.1905555555555556,
              "speed3d": 2.206666666666667,
              "msFromStart": 21767.249
            },
            {
              "lat": 43.75536783333334,
              "lng": -116.09420031111111,
              "alt": 2142.787888888889,
              "speed2d": 2.2365555555555554,
              "speed3d": 2.2066666666666666,
              "msFromStart": 22263.01751851852
            },
            {
              "lat": 43.755378444444446,
              "lng": -116.09419504444446,
              "alt": 2142.7922222222223,
              "speed2d": 2.475,
              "speed3d": 2.4622222222222225,
              "msFromStart": 22757.818444444445
            },
            {
              "lat": 43.7553882,
              "lng": -116.09418993333335,
              "alt": 2142.67,
              "speed2d": 2.333,
              "speed3d": 2.3522222222222218,
              "msFromStart": 23252.627814814816
            },
            {
              "lat": 43.75539476666667,
              "lng": -116.09418105555554,
              "alt": 2142.204333333333,
              "speed2d": 2.3825555555555558,
              "speed3d": 2.3966666666666665,
              "msFromStart": 23747.598333333328
            },
            {
              "lat": 43.75540285555556,
              "lng": -116.09416979999997,
              "alt": 2141.972111111111,
              "speed2d": 2.658888888888889,
              "speed3d": 2.595555555555556,
              "msFromStart": 24242.977479532157
            },
            {
              "lat": 43.75541461111111,
              "lng": -116.09415790000001,
              "alt": 2141.9840000000004,
              "speed2d": 2.9913333333333334,
              "speed3d": 2.968888888888889,
              "msFromStart": 24739.442736842087
            },
            {
              "lat": 43.755427766666664,
              "lng": -116.09414703333333,
              "alt": 2142.1557777777775,
              "speed2d": 3.1071111111111107,
              "speed3d": 3.1077777777777778,
              "msFromStart": 25235.90211695906
            },
            {
              "lat": 43.755441000000005,
              "lng": -116.09413973333332,
              "alt": 2142.244777777778,
              "speed2d": 3.091555555555556,
              "speed3d": 3.092222222222222,
              "msFromStart": 25732.109333333345
            },
            {
              "lat": 43.755450800000006,
              "lng": -116.09413187777778,
              "alt": 2141.9783333333335,
              "speed2d": 2.9663333333333335,
              "speed3d": 2.9944444444444445,
              "msFromStart": 26227.984111111116
            },
            {
              "lat": 43.75545789,
              "lng": -116.09412032,
              "alt": 2141.4804,
              "speed2d": 2.8291000000000004,
              "speed3d": 2.834,
              "msFromStart": 26750.103499999997
            },
            {
              "lat": 43.75546291111111,
              "lng": -116.09410778888888,
              "alt": 2140.900888888889,
              "speed2d": 2.702111111111111,
              "speed3d": 2.6999999999999997,
              "msFromStart": 27272.312592592596
            },
            {
              "lat": 43.7554679,
              "lng": -116.09409441111112,
              "alt": 2140.2929999999997,
              "speed2d": 2.772666666666667,
              "speed3d": 2.7777777777777777,
              "msFromStart": 27767.62522222224
            },
            {
              "lat": 43.755473933333334,
              "lng": -116.09408076666668,
              "alt": 2139.666555555555,
              "speed2d": 2.9058888888888883,
              "speed3d": 2.9000000000000004,
              "msFromStart": 28262.440111111115
            },
            {
              "lat": 43.75548277777778,
              "lng": -116.09406307777778,
              "alt": 2139.2722222222224,
              "speed2d": 3.4971111111111113,
              "speed3d": 3.425555555555556,
              "msFromStart": 28755.821555555547
            },
            {
              "lat": 43.75549258888889,
              "lng": -116.09404384444443,
              "alt": 2138.982666666667,
              "speed2d": 3.800777777777778,
              "speed3d": 3.8411111111111103,
              "msFromStart": 29249.485442495126
            },
            {
              "lat": 43.75550278888889,
              "lng": -116.09402948888889,
              "alt": 2138.2864444444444,
              "speed2d": 3.6372222222222224,
              "speed3d": 3.672222222222221,
              "msFromStart": 29744.120736842116
            },
            {
              "lat": 43.75551417777778,
              "lng": -116.09401693333334,
              "alt": 2137.333,
              "speed2d": 3.6037777777777777,
              "speed3d": 3.657777777777778,
              "msFromStart": 30238.83170955166
            },
            {
              "lat": 43.75552727777779,
              "lng": -116.09400648888891,
              "alt": 2136.945777777778,
              "speed2d": 3.4494444444444445,
              "speed3d": 3.5244444444444447,
              "msFromStart": 30733.640888888876
            },
            {
              "lat": 43.755543599999996,
              "lng": -116.09399524444446,
              "alt": 2136.6763333333333,
              "speed2d": 3.7703333333333333,
              "speed3d": 3.741111111111111,
              "msFromStart": 31228.464703703703
            },
            {
              "lat": 43.75556489000001,
              "lng": -116.09398159,
              "alt": 2136.0521,
              "speed2d": 4.499,
              "speed3d": 4.495,
              "msFromStart": 31750.79916666668
            },
            {
              "lat": 43.75558802222223,
              "lng": -116.09396781111111,
              "alt": 2135.387,
              "speed2d": 5.064777777777778,
              "speed3d": 5.0344444444444445,
              "msFromStart": 32273.264148148148
            },
            {
              "lat": 43.75561186666666,
              "lng": -116.09395476666668,
              "alt": 2134.6466666666665,
              "speed2d": 5.522666666666667,
              "speed3d": 5.5488888888888885,
              "msFromStart": 32768.58677777777
            },
            {
              "lat": 43.755638000000005,
              "lng": -116.09393963333332,
              "alt": 2134.179222222222,
              "speed2d": 6.025666666666667,
              "speed3d": 6.022222222222222,
              "msFromStart": 33263.56403703703
            },
            {
              "lat": 43.75566662222222,
              "lng": -116.09392385555554,
              "alt": 2133.854888888889,
              "speed2d": 6.423111111111111,
              "speed3d": 6.427777777777777,
              "msFromStart": 33757.553999999975
            },
            {
              "lat": 43.75569535555556,
              "lng": -116.09390651111111,
              "alt": 2133.2052222222223,
              "speed2d": 6.721666666666666,
              "speed3d": 6.763333333333333,
              "msFromStart": 34251.75014035087
            },
            {
              "lat": 43.7557253888889,
              "lng": -116.09388859999999,
              "alt": 2132.611888888889,
              "speed2d": 6.977444444444443,
              "speed3d": 7.017777777777777,
              "msFromStart": 34746.64694736844
            },
            {
              "lat": 43.75575692222222,
              "lng": -116.09386881111112,
              "alt": 2131.876666666667,
              "speed2d": 7.450888888888889,
              "speed3d": 7.461111111111111,
              "msFromStart": 35241.96883820664
            },
            {
              "lat": 43.755786422222215,
              "lng": -116.09384827777778,
              "alt": 2130.7831111111113,
              "speed2d": 7.550444444444444,
              "speed3d": 7.617777777777777,
              "msFromStart": 35738.84355555553
            },
            {
              "lat": 43.7558252,
              "lng": -116.09382796666667,
              "alt": 2129.8221111111116,
              "speed2d": 8.452555555555557,
              "speed3d": 8.436666666666666,
              "msFromStart": 36235.29207407406
            },
            {
              "lat": 43.75586658888889,
              "lng": -116.09380407777778,
              "alt": 2129.0318888888887,
              "speed2d": 9.326555555555556,
              "speed3d": 9.332222222222223,
              "msFromStart": 36729.414666666635
            },
            {
              "lat": 43.75591251,
              "lng": -116.09377924999998,
              "alt": 2128.1711,
              "speed2d": 9.9355,
              "speed3d": 10.011,
              "msFromStart": 37251.07106666665
            },
            {
              "lat": 43.7559589,
              "lng": -116.09375485555555,
              "alt": 2127.0375555555556,
              "speed2d": 10.372333333333334,
              "speed3d": 10.404444444444444,
              "msFromStart": 37773.7897777778
            },
            {
              "lat": 43.75600813333334,
              "lng": -116.09373110000001,
              "alt": 2126.1936666666666,
              "speed2d": 10.984666666666667,
              "speed3d": 11.074444444444444,
              "msFromStart": 38268.91414814815
            },
            {
              "lat": 43.75605462222223,
              "lng": -116.09370808888889,
              "alt": 2124.8864444444444,
              "speed2d": 11.192111111111108,
              "speed3d": 11.29111111111111,
              "msFromStart": 38763.57266666665
            },
            {
              "lat": 43.75610465555555,
              "lng": -116.09368406666667,
              "alt": 2123.6814444444444,
              "speed2d": 11.601333333333335,
              "speed3d": 11.688888888888888,
              "msFromStart": 39258.62134502924
            },
            {
              "lat": 43.75615938888888,
              "lng": -116.0936601,
              "alt": 2122.7182222222223,
              "speed2d": 12.23411111111111,
              "speed3d": 12.318888888888889,
              "msFromStart": 39754.795578947385
            },
            {
              "lat": 43.7562136,
              "lng": -116.09363807777777,
              "alt": 2121.657222222222,
              "speed2d": 12.462777777777779,
              "speed3d": 12.58888888888889,
              "msFromStart": 40250.53100194932
            },
            {
              "lat": 43.75626953333333,
              "lng": -116.09361735555557,
              "alt": 2120.5195555555556,
              "speed2d": 12.692222222222222,
              "speed3d": 12.816666666666668,
              "msFromStart": 40744.2742222222
            },
            {
              "lat": 43.75632708888889,
              "lng": -116.09359652222223,
              "alt": 2119.445888888889,
              "speed2d": 12.98011111111111,
              "speed3d": 13.126666666666665,
              "msFromStart": 41238.197481481475
            },
            {
              "lat": 43.756385522222224,
              "lng": -116.09357572222223,
              "alt": 2118.5697777777777,
              "speed2d": 13.282333333333334,
              "speed3d": 13.382222222222222,
              "msFromStart": 41733.637777777745
            },
            {
              "lat": 43.75644723333333,
              "lng": -116.09355416666666,
              "alt": 2118.001666666667,
              "speed2d": 13.732333333333331,
              "speed3d": 13.828888888888887,
              "msFromStart": 42229.25199999997
            },
            {
              "lat": 43.756507811111106,
              "lng": -116.09353253333332,
              "alt": 2117.2947777777777,
              "speed2d": 13.897,
              "speed3d": 14.032222222222222,
              "msFromStart": 42724.9551111111
            },
            {
              "lat": 43.75656762999999,
              "lng": -116.09350977000001,
              "alt": 2116.0618,
              "speed2d": 13.612499999999999,
              "speed3d": 13.787,
              "msFromStart": 43248.03540000001
            },
            {
              "lat": 43.75662605555556,
              "lng": -116.09348457777777,
              "alt": 2114.8360000000002,
              "speed2d": 13.329222222222223,
              "speed3d": 13.467777777777776,
              "msFromStart": 43770.561111111136
            },
            {
              "lat": 43.756685955555554,
              "lng": -116.0934598111111,
              "alt": 2113.869777777778,
              "speed2d": 13.69688888888889,
              "speed3d": 13.782222222222222,
              "msFromStart": 44265.51190253413
            },
            {
              "lat": 43.75674782222222,
              "lng": -116.09343314444445,
              "alt": 2113.2523333333334,
              "speed2d": 13.948,
              "speed3d": 14.091111111111111,
              "msFromStart": 44760.388842105254
            },
            {
              "lat": 43.756805955555556,
              "lng": -116.09340714444446,
              "alt": 2112.5021111111114,
              "speed2d": 13.778,
              "speed3d": 13.898888888888889,
              "msFromStart": 45255.12947758284
            },
            {
              "lat": 43.756866200000005,
              "lng": -116.09338608888889,
              "alt": 2111.6827777777776,
              "speed2d": 13.718222222222222,
              "speed3d": 13.875555555555557,
              "msFromStart": 45749.340444444446
            },
            {
              "lat": 43.756925,
              "lng": -116.09336765555557,
              "alt": 2111.127777777778,
              "speed2d": 13.466666666666669,
              "speed3d": 13.604444444444445,
              "msFromStart": 46243.81325925925
            },
            {
              "lat": 43.75698412222222,
              "lng": -116.0933538,
              "alt": 2110.7093333333332,
              "speed2d": 13.26288888888889,
              "speed3d": 13.412222222222223,
              "msFromStart": 46739.545333333306
            },
            {
              "lat": 43.75704127777777,
              "lng": -116.09334043333334,
              "alt": 2110.0305555555556,
              "speed2d": 12.940555555555555,
              "speed3d": 13.078888888888889,
              "msFromStart": 47235.26629629628
            },
            {
              "lat": 43.75709896666667,
              "lng": -116.09332187777775,
              "alt": 2109.4377777777777,
              "speed2d": 13.114666666666666,
              "speed3d": 13.175555555555555,
              "msFromStart": 47730.43911111111
            },
            {
              "lat": 43.75715877,
              "lng": -116.09329231,
              "alt": 2108.8408999999997,
              "speed2d": 13.5065,
              "speed3d": 13.586,
              "msFromStart": 48252.769666666674
            },
            {
              "lat": 43.75720815555555,
              "lng": -116.09325362222222,
              "alt": 2107.8145555555557,
              "speed2d": 13.195222222222222,
              "speed3d": 13.376666666666669,
              "msFromStart": 48774.14822222223
            },
            {
              "lat": 43.757252244444445,
              "lng": -116.09321215555555,
              "alt": 2106.7805555555556,
              "speed2d": 12.914111111111113,
              "speed3d": 13.032222222222222,
              "msFromStart": 49268.40496296297
            },
            {
              "lat": 43.75729455555556,
              "lng": -116.09316711111111,
              "alt": 2105.942222222222,
              "speed2d": 12.51988888888889,
              "speed3d": 12.686666666666666,
              "msFromStart": 49763.74400000002
            },
            {
              "lat": 43.75733437777778,
              "lng": -116.09312112222221,
              "alt": 2105.082111111111,
              "speed2d": 12.210222222222221,
              "speed3d": 12.362222222222224,
              "msFromStart": 50259.10444444444
            },
            {
              "lat": 43.757373666666666,
              "lng": -116.09307673333333,
              "alt": 2104.602555555555,
              "speed2d": 11.90622222222222,
              "speed3d": 12.072222222222221,
              "msFromStart": 50754.322666666645
            }
          ]
        }
      ]
    },
    {
      "id": "trail-morning-star-lift-to-bitterroot-lift",
      "pathType": "trail",
      "name": "Morning Star Lift to Bitterroot Lift",
      "difficulty": "green",
      "isCatTrack": true,
      "videos": [
        {
          "videoId": "GX010077",
          "pathId": "trail-morning-star-lift-to-bitterroot-lift",
          "startSec": 240,
          "endSec": 293,
          "id": "video-segment-38",
          "gps": [
            {
              "lat": 43.772322777777774,
              "lng": -116.10007808888889,
              "alt": 2089.974,
              "speed2d": 9.40911111111111,
              "speed3d": 9.42111111111111,
              "msFromStart": 240254.91644444436
            },
            {
              "lat": 43.77228824444445,
              "lng": -116.1001140111111,
              "alt": 2089.204,
              "speed2d": 9.559666666666667,
              "speed3d": 9.576666666666668,
              "msFromStart": 240749.78311111106
            },
            {
              "lat": 43.77225125555557,
              "lng": -116.10015080000001,
              "alt": 2088.551111111111,
              "speed2d": 9.844555555555555,
              "speed3d": 9.868888888888891,
              "msFromStart": 241244.9342222222
            },
            {
              "lat": 43.77221702222222,
              "lng": -116.10018947777775,
              "alt": 2088.3823333333335,
              "speed2d": 9.896777777777778,
              "speed3d": 9.942222222222222,
              "msFromStart": 241739.46044444444
            },
            {
              "lat": 43.77218944444445,
              "lng": -116.10023317777778,
              "alt": 2087.514,
              "speed2d": 9.869777777777779,
              "speed3d": 9.927777777777777,
              "msFromStart": 242233.8623781677
            },
            {
              "lat": 43.77217111111111,
              "lng": -116.10028313333332,
              "alt": 2086.1525555555554,
              "speed2d": 9.779888888888888,
              "speed3d": 9.86888888888889,
              "msFromStart": 242730.2770526316
            },
            {
              "lat": 43.772152711111104,
              "lng": -116.10033368888888,
              "alt": 2085.470777777778,
              "speed2d": 9.507111111111112,
              "speed3d": 9.594444444444445,
              "msFromStart": 243227.40219883042
            },
            {
              "lat": 43.772130110000006,
              "lng": -116.10039042,
              "alt": 2085.0943,
              "speed2d": 9.5795,
              "speed3d": 9.664,
              "msFromStart": 243750.39199999996
            },
            {
              "lat": 43.77210472222222,
              "lng": -116.10044421111112,
              "alt": 2084.439555555555,
              "speed2d": 9.613666666666667,
              "speed3d": 9.66111111111111,
              "msFromStart": 244272.4699259259
            },
            {
              "lat": 43.77207647777778,
              "lng": -116.10049624444444,
              "alt": 2084.1163333333334,
              "speed2d": 9.874333333333333,
              "speed3d": 9.955555555555556,
              "msFromStart": 244766.26844444452
            },
            {
              "lat": 43.77204986666667,
              "lng": -116.10053764444444,
              "alt": 2083.2414444444444,
              "speed2d": 9.522555555555556,
              "speed3d": 9.657777777777778,
              "msFromStart": 245259.8343703705
            },
            {
              "lat": 43.77202237777778,
              "lng": -116.10057556666666,
              "alt": 2082.109888888889,
              "speed2d": 9.330777777777778,
              "speed3d": 9.447777777777777,
              "msFromStart": 245754.57688888878
            },
            {
              "lat": 43.77199081111112,
              "lng": -116.10062076666667,
              "alt": 2081.571111111111,
              "speed2d": 9.666666666666666,
              "speed3d": 9.688888888888888,
              "msFromStart": 246249.80444444428
            },
            {
              "lat": 43.771956977777776,
              "lng": -116.10066967777777,
              "alt": 2081.0004444444444,
              "speed2d": 10.076,
              "speed3d": 10.13888888888889,
              "msFromStart": 246746.01155555563
            },
            {
              "lat": 43.77192142222222,
              "lng": -116.10072210000001,
              "alt": 2080.3777777777777,
              "speed2d": 10.567,
              "speed3d": 10.62888888888889,
              "msFromStart": 247242.5847797272
            },
            {
              "lat": 43.77189163333334,
              "lng": -116.10076716666667,
              "alt": 2078.7835555555557,
              "speed2d": 10.43388888888889,
              "speed3d": 10.577777777777778,
              "msFromStart": 247739.25726315787
            },
            {
              "lat": 43.771866188888886,
              "lng": -116.10081402222221,
              "alt": 2077.530333333333,
              "speed2d": 10.225777777777777,
              "speed3d": 10.382222222222222,
              "msFromStart": 248235.94264847293
            },
            {
              "lat": 43.77184394444445,
              "lng": -116.10086412222222,
              "alt": 2076.260555555556,
              "speed2d": 10.223333333333333,
              "speed3d": 10.326666666666666,
              "msFromStart": 248731.39733333339
            },
            {
              "lat": 43.7718183,
              "lng": -116.1009204,
              "alt": 2074.417111111111,
              "speed2d": 10.745111111111111,
              "speed3d": 10.863333333333333,
              "msFromStart": 249226.18706172844
            },
            {
              "lat": 43.771796210000005,
              "lng": -116.10098783000001,
              "alt": 2073.3312,
              "speed2d": 11.108199999999998,
              "speed3d": 11.235000000000001,
              "msFromStart": 249748.336888889
            },
            {
              "lat": 43.771772222222225,
              "lng": -116.10106037777778,
              "alt": 2071.997444444444,
              "speed2d": 11.793666666666667,
              "speed3d": 11.89777777777778,
              "msFromStart": 250270.52325925938
            },
            {
              "lat": 43.77174658888889,
              "lng": -116.10113284444445,
              "alt": 2071.2520000000004,
              "speed2d": 12.286888888888889,
              "speed3d": 12.414444444444445,
              "msFromStart": 250767.1342222223
            },
            {
              "lat": 43.7717198,
              "lng": -116.10120777777777,
              "alt": 2070.874111111111,
              "speed2d": 12.747777777777777,
              "speed3d": 12.81111111111111,
              "msFromStart": 251264.312888889
            },
            {
              "lat": 43.771691266666664,
              "lng": -116.10128317777777,
              "alt": 2070.539333333333,
              "speed2d": 13.099666666666668,
              "speed3d": 13.26888888888889,
              "msFromStart": 251758.95822222225
            },
            {
              "lat": 43.77166884444445,
              "lng": -116.10134874444445,
              "alt": 2070.3966666666665,
              "speed2d": 12.548666666666668,
              "speed3d": 12.71777777777778,
              "msFromStart": 252252.65855750494
            },
            {
              "lat": 43.77164675555556,
              "lng": -116.10141232222222,
              "alt": 2069.9977777777776,
              "speed2d": 12.129666666666669,
              "speed3d": 12.298888888888888,
              "msFromStart": 252746.13726315787
            },
            {
              "lat": 43.77162523333334,
              "lng": -116.10147116666667,
              "alt": 2069.2444444444445,
              "speed2d": 11.657,
              "speed3d": 11.80888888888889,
              "msFromStart": 253239.54215464587
            },
            {
              "lat": 43.77160606666667,
              "lng": -116.1015302777778,
              "alt": 2068.897,
              "speed2d": 11.100777777777779,
              "speed3d": 11.275555555555556,
              "msFromStart": 253733.35466666662
            },
            {
              "lat": 43.771585788888885,
              "lng": -116.10158533333333,
              "alt": 2068.4644444444443,
              "speed2d": 10.663,
              "speed3d": 10.814444444444444,
              "msFromStart": 254227.39802469133
            },
            {
              "lat": 43.77156900999999,
              "lng": -116.10163734,
              "alt": 2068.1955,
              "speed2d": 9.7349,
              "speed3d": 9.939,
              "msFromStart": 254749.52933333334
            },
            {
              "lat": 43.77155404444444,
              "lng": -116.1016840888889,
              "alt": 2067.3625555555554,
              "speed2d": 8.854000000000001,
              "speed3d": 9.031111111111112,
              "msFromStart": 255272.01096296296
            },
            {
              "lat": 43.77154003333334,
              "lng": -116.10173043333333,
              "alt": 2067.0707777777775,
              "speed2d": 8.324333333333334,
              "speed3d": 8.45111111111111,
              "msFromStart": 255767.60888888888
            },
            {
              "lat": 43.771521533333335,
              "lng": -116.10178873333334,
              "alt": 2067.477666666666,
              "speed2d": 8.526333333333334,
              "speed3d": 8.568888888888889,
              "msFromStart": 256263.39348148147
            },
            {
              "lat": 43.771499899999995,
              "lng": -116.10184994444444,
              "alt": 2067.507888888889,
              "speed2d": 9.092111111111112,
              "speed3d": 9.105555555555554,
              "msFromStart": 256758.4293333333
            },
            {
              "lat": 43.771482066666664,
              "lng": -116.10190325555556,
              "alt": 2066.995888888889,
              "speed2d": 9.04611111111111,
              "speed3d": 9.182222222222222,
              "msFromStart": 257253.21436257305
            },
            {
              "lat": 43.771469177777774,
              "lng": -116.10194735555555,
              "alt": 2065.951888888889,
              "speed2d": 8.668555555555555,
              "speed3d": 8.774444444444445,
              "msFromStart": 257748.47494736844
            },
            {
              "lat": 43.77145853333333,
              "lng": -116.10199205555556,
              "alt": 2065.1854444444443,
              "speed2d": 8.451555555555556,
              "speed3d": 8.594444444444445,
              "msFromStart": 258243.9076283301
            },
            {
              "lat": 43.77145685555555,
              "lng": -116.10203672222224,
              "alt": 2065.824111111111,
              "speed2d": 7.871777777777776,
              "speed3d": 8.014444444444445,
              "msFromStart": 258739.17866666664
            },
            {
              "lat": 43.77145624444444,
              "lng": -116.10208016666668,
              "alt": 2066.8333333333335,
              "speed2d": 7.351,
              "speed3d": 7.444444444444445,
              "msFromStart": 259234.34093827158
            },
            {
              "lat": 43.77145484444445,
              "lng": -116.1021206,
              "alt": 2068.000111111111,
              "speed2d": 6.711666666666667,
              "speed3d": 6.813333333333334,
              "msFromStart": 259728.37333333335
            },
            {
              "lat": 43.771452100000005,
              "lng": -116.10216284,
              "alt": 2068.9102,
              "speed2d": 6.4933,
              "speed3d": 6.494999999999999,
              "msFromStart": 260249.32151111113
            },
            {
              "lat": 43.77144833333333,
              "lng": -116.1022067,
              "alt": 2069.9707777777776,
              "speed2d": 6.436555555555556,
              "speed3d": 6.456666666666667,
              "msFromStart": 260772.65333333338
            },
            {
              "lat": 43.771443766666664,
              "lng": -116.10224803333334,
              "alt": 2070.580222222222,
              "speed2d": 6.51111111111111,
              "speed3d": 6.488888888888889,
              "msFromStart": 261269.38014814822
            },
            {
              "lat": 43.771439122222226,
              "lng": -116.10228934444444,
              "alt": 2071.7434444444443,
              "speed2d": 6.486777777777777,
              "speed3d": 6.5,
              "msFromStart": 261765.17244444444
            },
            {
              "lat": 43.77143584444445,
              "lng": -116.10232760000001,
              "alt": 2072.8340000000003,
              "speed2d": 6.305888888888889,
              "speed3d": 6.359999999999999,
              "msFromStart": 262260.6076413255
            },
            {
              "lat": 43.77143021111111,
              "lng": -116.10235874444446,
              "alt": 2072.787888888889,
              "speed2d": 5.960888888888889,
              "speed3d": 5.996666666666668,
              "msFromStart": 262755.79957894754
            },
            {
              "lat": 43.77142235555556,
              "lng": -116.10238804444445,
              "alt": 2072.2744444444443,
              "speed2d": 5.790666666666667,
              "speed3d": 5.824444444444444,
              "msFromStart": 263250.8832488632
            },
            {
              "lat": 43.77141473333333,
              "lng": -116.10241252222222,
              "alt": 2071.991666666667,
              "speed2d": 5.204888888888888,
              "speed3d": 5.264444444444445,
              "msFromStart": 263744.99733333336
            },
            {
              "lat": 43.771407333333336,
              "lng": -116.10243795555554,
              "alt": 2071.905,
              "speed2d": 4.846111111111111,
              "speed3d": 4.901111111111112,
              "msFromStart": 264238.6098765433
            },
            {
              "lat": 43.77140073333334,
              "lng": -116.10246003333334,
              "alt": 2071.5995555555555,
              "speed2d": 4.436444444444444,
              "speed3d": 4.4688888888888885,
              "msFromStart": 264733.2906666669
            },
            {
              "lat": 43.77139548888889,
              "lng": -116.10248182222222,
              "alt": 2071.6203333333333,
              "speed2d": 4.079777777777777,
              "speed3d": 4.126666666666667,
              "msFromStart": 265228.5458765435
            },
            {
              "lat": 43.77139142,
              "lng": -116.1025046,
              "alt": 2071.7033,
              "speed2d": 3.896,
              "speed3d": 3.89,
              "msFromStart": 265751.2706666667
            },
            {
              "lat": 43.77138566666666,
              "lng": -116.10252807777778,
              "alt": 2071.809888888889,
              "speed2d": 3.9201111111111113,
              "speed3d": 3.94,
              "msFromStart": 266273.9253333334
            },
            {
              "lat": 43.771383533333335,
              "lng": -116.10254835555556,
              "alt": 2072.1176666666665,
              "speed2d": 3.646444444444444,
              "speed3d": 3.6733333333333333,
              "msFromStart": 266768.1573333332
            },
            {
              "lat": 43.77137989999999,
              "lng": -116.10256933333334,
              "alt": 2072.4902222222227,
              "speed2d": 3.472888888888889,
              "speed3d": 3.465555555555555,
              "msFromStart": 267262.0786432746
            },
            {
              "lat": 43.77137847777777,
              "lng": -116.10259018888888,
              "alt": 2072.7477777777776,
              "speed2d": 3.4712222222222224,
              "speed3d": 3.4955555555555557,
              "msFromStart": 267756.48084210546
            },
            {
              "lat": 43.771375899999995,
              "lng": -116.10260626666667,
              "alt": 2072.793222222222,
              "speed2d": 3.1660000000000004,
              "speed3d": 3.186666666666667,
              "msFromStart": 268251.08258869423
            },
            {
              "lat": 43.77137441111111,
              "lng": -116.10262308888889,
              "alt": 2072.844,
              "speed2d": 2.9415555555555555,
              "speed3d": 2.998888888888889,
              "msFromStart": 268746.8479999999
            },
            {
              "lat": 43.77137746666667,
              "lng": -116.10263414444444,
              "alt": 2072.8547777777776,
              "speed2d": 2.4182222222222225,
              "speed3d": 2.4777777777777783,
              "msFromStart": 269243.20928395033
            },
            {
              "lat": 43.77137731111111,
              "lng": -116.10264483333333,
              "alt": 2072.817222222222,
              "speed2d": 2.058666666666667,
              "speed3d": 2.09,
              "msFromStart": 269737.97333333327
            },
            {
              "lat": 43.771374944444446,
              "lng": -116.10265493333333,
              "alt": 2072.3234444444447,
              "speed2d": 1.956,
              "speed3d": 1.9633333333333334,
              "msFromStart": 270231.8959012344
            },
            {
              "lat": 43.771373811111104,
              "lng": -116.10266686666668,
              "alt": 2072.2057777777777,
              "speed2d": 1.7876666666666665,
              "speed3d": 1.8077777777777777,
              "msFromStart": 270726.78399999987
            },
            {
              "lat": 43.7713755,
              "lng": -116.10267870999999,
              "alt": 2072.2884000000004,
              "speed2d": 1.7499000000000002,
              "speed3d": 1.7699999999999996,
              "msFromStart": 271249.6853333331
            },
            {
              "lat": 43.77137678888889,
              "lng": -116.10269038888889,
              "alt": 2072.374111111111,
              "speed2d": 1.5995555555555556,
              "speed3d": 1.6222222222222222,
              "msFromStart": 271771.96088888886
            },
            {
              "lat": 43.77137444444445,
              "lng": -116.10270517777778,
              "alt": 2072.416777777778,
              "speed2d": 1.8326666666666669,
              "speed3d": 1.8366666666666667,
              "msFromStart": 272266.4972475633
            },
            {
              "lat": 43.771372722222225,
              "lng": -116.1027137222222,
              "alt": 2072.3506666666667,
              "speed2d": 1.6877777777777778,
              "speed3d": 1.7244444444444447,
              "msFromStart": 272761.19242105284
            },
            {
              "lat": 43.77137186666667,
              "lng": -116.10272054444444,
              "alt": 2072.257,
              "speed2d": 1.3937777777777776,
              "speed3d": 1.4288888888888889,
              "msFromStart": 273255.94509681646
            },
            {
              "lat": 43.77137012222222,
              "lng": -116.10272862222222,
              "alt": 2072.407444444444,
              "speed2d": 1.3184444444444443,
              "speed3d": 1.33,
              "msFromStart": 273750.64533333335
            },
            {
              "lat": 43.77136942222222,
              "lng": -116.10273964444445,
              "alt": 2072.4796666666666,
              "speed2d": 1.5004444444444445,
              "speed3d": 1.5144444444444445,
              "msFromStart": 274245.3315555556
            },
            {
              "lat": 43.77136785555555,
              "lng": -116.10274985555556,
              "alt": 2072.5376666666666,
              "speed2d": 1.4861111111111112,
              "speed3d": 1.4944444444444445,
              "msFromStart": 274740.7573333335
            },
            {
              "lat": 43.77136497777778,
              "lng": -116.10276172222223,
              "alt": 2072.3831111111112,
              "speed2d": 1.7584444444444445,
              "speed3d": 1.7422222222222223,
              "msFromStart": 275236.5698765435
            },
            {
              "lat": 43.77136142222222,
              "lng": -116.10277176666668,
              "alt": 2072.011888888889,
              "speed2d": 1.7592222222222222,
              "speed3d": 1.7566666666666668,
              "msFromStart": 275731.7866666666
            },
            {
              "lat": 43.77135666666666,
              "lng": -116.1027831222222,
              "alt": 2070.9355555555558,
              "speed2d": 2.0474444444444444,
              "speed3d": 2.0488888888888885,
              "msFromStart": 276226.69412345666
            },
            {
              "lat": 43.77134961999999,
              "lng": -116.10279615,
              "alt": 2070.3621000000003,
              "speed2d": 2.1635,
              "speed3d": 2.2290000000000005,
              "msFromStart": 276749.7431111112
            },
            {
              "lat": 43.77134692222222,
              "lng": -116.10280472222225,
              "alt": 2069.7563333333333,
              "speed2d": 1.9089999999999998,
              "speed3d": 2.0044444444444443,
              "msFromStart": 277273.0418089671
            },
            {
              "lat": 43.77134414444444,
              "lng": -116.1028106,
              "alt": 2069.420222222222,
              "speed2d": 1.5954444444444442,
              "speed3d": 1.7166666666666666,
              "msFromStart": 277767.4442105261
            },
            {
              "lat": 43.77134243333333,
              "lng": -116.10282163333332,
              "alt": 2069.2866666666664,
              "speed2d": 1.5819999999999999,
              "speed3d": 1.651111111111111,
              "msFromStart": 278261.3828200127
            },
            {
              "lat": 43.77134407777778,
              "lng": -116.10283185555556,
              "alt": 2069.482111111111,
              "speed2d": 1.492,
              "speed3d": 1.61,
              "msFromStart": 278757.07733333326
            },
            {
              "lat": 43.7713437,
              "lng": -116.1028369222222,
              "alt": 2069.2631111111114,
              "speed2d": 1.2388888888888887,
              "speed3d": 1.3200000000000003,
              "msFromStart": 279253.67743209866
            },
            {
              "lat": 43.771343200000004,
              "lng": -116.10284384444445,
              "alt": 2068.8083333333334,
              "speed2d": 1.2804444444444445,
              "speed3d": 1.3533333333333335,
              "msFromStart": 279748.20266666677
            },
            {
              "lat": 43.771341500000005,
              "lng": -116.1028542111111,
              "alt": 2068.8493333333336,
              "speed2d": 1.3835555555555554,
              "speed3d": 1.43,
              "msFromStart": 280241.6266666668
            },
            {
              "lat": 43.77134143333333,
              "lng": -116.10286564444445,
              "alt": 2068.8874444444446,
              "speed2d": 1.5966666666666667,
              "speed3d": 1.6055555555555556,
              "msFromStart": 280735.8826666666
            },
            {
              "lat": 43.77133782222222,
              "lng": -116.10287690000001,
              "alt": 2068.4365555555555,
              "speed2d": 1.7067777777777777,
              "speed3d": 1.7577777777777777,
              "msFromStart": 281230.58548148134
            },
            {
              "lat": 43.771335577777776,
              "lng": -116.10288678888888,
              "alt": 2067.840777777778,
              "speed2d": 1.6616666666666666,
              "speed3d": 1.7277777777777776,
              "msFromStart": 281725.22666666657
            },
            {
              "lat": 43.77133661,
              "lng": -116.10289791999999,
              "alt": 2068.1277000000005,
              "speed2d": 1.6212000000000004,
              "speed3d": 1.6989999999999998,
              "msFromStart": 282247.32785029226
            },
            {
              "lat": 43.77133874444445,
              "lng": -116.1029041111111,
              "alt": 2068.333222222222,
              "speed2d": 1.2005555555555556,
              "speed3d": 1.2533333333333332,
              "msFromStart": 282769.74821052636
            },
            {
              "lat": 43.771339466666674,
              "lng": -116.10291284444443,
              "alt": 2068.325,
              "speed2d": 1.2281111111111112,
              "speed3d": 1.267777777777778,
              "msFromStart": 283264.81235087727
            },
            {
              "lat": 43.77133595555556,
              "lng": -116.10292676666664,
              "alt": 2068.2670000000003,
              "speed2d": 1.4917777777777779,
              "speed3d": 1.4844444444444447,
              "msFromStart": 283760.25599999994
            },
            {
              "lat": 43.771334455555554,
              "lng": -116.10293725555557,
              "alt": 2067.852555555555,
              "speed2d": 1.6238888888888887,
              "speed3d": 1.6366666666666667,
              "msFromStart": 284255.9000493826
            },
            {
              "lat": 43.771331066666676,
              "lng": -116.10295083333332,
              "alt": 2067.847333333333,
              "speed2d": 1.758888888888889,
              "speed3d": 1.7755555555555556,
              "msFromStart": 284751.33866666665
            },
            {
              "lat": 43.77133064444445,
              "lng": -116.10296046666666,
              "alt": 2067.8104444444443,
              "speed2d": 1.711888888888889,
              "speed3d": 1.7522222222222223,
              "msFromStart": 285246.67851851846
            },
            {
              "lat": 43.77132928888889,
              "lng": -116.10296735555556,
              "alt": 2067.9114444444444,
              "speed2d": 1.3812222222222221,
              "speed3d": 1.4522222222222223,
              "msFromStart": 285742.6346666668
            },
            {
              "lat": 43.77132653333334,
              "lng": -116.10297494444444,
              "alt": 2068.085333333333,
              "speed2d": 1.3344444444444443,
              "speed3d": 1.3355555555555556,
              "msFromStart": 286238.93056790147
            },
            {
              "lat": 43.77132442222222,
              "lng": -116.10298497777777,
              "alt": 2067.9976666666666,
              "speed2d": 1.4601111111111111,
              "speed3d": 1.478888888888889,
              "msFromStart": 286735.6373333333
            },
            {
              "lat": 43.77132083333333,
              "lng": -116.10299192222222,
              "alt": 2067.931777777778,
              "speed2d": 1.3316666666666666,
              "speed3d": 1.3477777777777777,
              "msFromStart": 287232.5362962962
            },
            {
              "lat": 43.77131886666667,
              "lng": -116.10299824444444,
              "alt": 2067.6916666666666,
              "speed2d": 1.275111111111111,
              "speed3d": 1.308888888888889,
              "msFromStart": 287727.9253333333
            },
            {
              "lat": 43.77131518,
              "lng": -116.10300504000001,
              "alt": 2067.7024,
              "speed2d": 1.2306,
              "speed3d": 1.2569999999999997,
              "msFromStart": 288249.9771321637
            },
            {
              "lat": 43.77131252222222,
              "lng": -116.10301238888889,
              "alt": 2067.703,
              "speed2d": 1.2396666666666667,
              "speed3d": 1.25,
              "msFromStart": 288772.0151578949
            },
            {
              "lat": 43.771306666666675,
              "lng": -116.10302331111113,
              "alt": 2067.6314444444447,
              "speed2d": 1.5885555555555555,
              "speed3d": 1.5444444444444445,
              "msFromStart": 289266.607189084
            },
            {
              "lat": 43.77130294444445,
              "lng": -116.1030327888889,
              "alt": 2067.202333333333,
              "speed2d": 1.6736666666666666,
              "speed3d": 1.7155555555555555,
              "msFromStart": 289763.04000000015
            },
            {
              "lat": 43.77129992222222,
              "lng": -116.10304464444445,
              "alt": 2067.161888888889,
              "speed2d": 1.7914444444444444,
              "speed3d": 1.7855555555555553,
              "msFromStart": 290260.4158024694
            },
            {
              "lat": 43.77129541111111,
              "lng": -116.10305635555557,
              "alt": 2067.079888888889,
              "speed2d": 1.907111111111111,
              "speed3d": 1.906666666666667,
              "msFromStart": 290755.2853333332
            },
            {
              "lat": 43.77129235555555,
              "lng": -116.10307122222221,
              "alt": 2066.6893333333333,
              "speed2d": 2.148222222222222,
              "speed3d": 2.1711111111111108,
              "msFromStart": 291248.81659259234
            },
            {
              "lat": 43.77128916666666,
              "lng": -116.10308133333332,
              "alt": 2066.455111111111,
              "speed2d": 1.942888888888889,
              "speed3d": 1.9788888888888891,
              "msFromStart": 291742.9333333333
            },
            {
              "lat": 43.771288677777775,
              "lng": -116.10309337777777,
              "alt": 2066.092333333333,
              "speed2d": 1.996,
              "speed3d": 1.9944444444444445,
              "msFromStart": 292237.3711760883
            },
            {
              "lat": 43.77128703333333,
              "lng": -116.10311196666666,
              "alt": 2066.122,
              "speed2d": 2.342777777777778,
              "speed3d": 2.304444444444444,
              "msFromStart": 292732.1128421053
            }
          ]
        }
      ]
    },
    {
      "id": "trail-showcase",
      "pathType": "trail",
      "name": "Showcase",
      "difficulty": "blue",
      "videos": [
        {
          "videoId": "GH010037",
          "pathId": "trail-showcase",
          "startSec": 43,
          "endSec": 85,
          "id": "video-segment-42",
          "gps": [
            {
              "lat": 43.75643368888888,
              "lng": -116.09487442222222,
              "alt": 2099.228,
              "speed2d": 7.397777777777778,
              "speed3d": 7.566666666666667,
              "msFromStart": 43249.04713060429
            },
            {
              "lat": 43.75645797777777,
              "lng": -116.09484901111111,
              "alt": 2099.779777777778,
              "speed2d": 6.193444444444444,
              "speed3d": 6.422222222222222,
              "msFromStart": 43742.956631578934
            },
            {
              "lat": 43.75648328888889,
              "lng": -116.09482652222223,
              "alt": 2100.3828888888893,
              "speed2d": 5.7283333333333335,
              "speed3d": 5.81,
              "msFromStart": 44237.26134892786
            },
            {
              "lat": 43.7565086,
              "lng": -116.09480427777777,
              "alt": 2100.4343333333336,
              "speed2d": 5.914666666666667,
              "speed3d": 5.955555555555556,
              "msFromStart": 44732.80177777776
            },
            {
              "lat": 43.75653172222222,
              "lng": -116.09478317777777,
              "alt": 2100.495,
              "speed2d": 5.719222222222223,
              "speed3d": 5.817777777777779,
              "msFromStart": 45228.41948148148
            },
            {
              "lat": 43.756554820000005,
              "lng": -116.09476063999999,
              "alt": 2100.8595,
              "speed2d": 5.3581,
              "speed3d": 5.460000000000001,
              "msFromStart": 45751.37500000004
            },
            {
              "lat": 43.756576244444446,
              "lng": -116.09474041111112,
              "alt": 2101.3026666666665,
              "speed2d": 4.794111111111111,
              "speed3d": 4.887777777777778,
              "msFromStart": 46274.57851851852
            },
            {
              "lat": 43.756598100000005,
              "lng": -116.09472185555555,
              "alt": 2101.6144444444444,
              "speed2d": 4.8726666666666665,
              "speed3d": 4.8933333333333335,
              "msFromStart": 46770.98244444443
            },
            {
              "lat": 43.75662037777778,
              "lng": -116.09470609999998,
              "alt": 2101.7074444444443,
              "speed2d": 4.838,
              "speed3d": 4.877777777777778,
              "msFromStart": 47266.55052241714
            },
            {
              "lat": 43.75664172222222,
              "lng": -116.09469276666667,
              "alt": 2101.473222222222,
              "speed2d": 4.815666666666666,
              "speed3d": 4.835555555555556,
              "msFromStart": 47759.746526315765
            },
            {
              "lat": 43.75666452222222,
              "lng": -116.09468018888889,
              "alt": 2101.0022222222224,
              "speed2d": 5.254555555555556,
              "speed3d": 5.246666666666667,
              "msFromStart": 48253.29635867446
            },
            {
              "lat": 43.75668928888889,
              "lng": -116.09466871111111,
              "alt": 2100.343555555555,
              "speed2d": 5.7701111111111105,
              "speed3d": 5.764444444444443,
              "msFromStart": 48748.76088888892
            },
            {
              "lat": 43.756715933333325,
              "lng": -116.09465595555555,
              "alt": 2099.601,
              "speed2d": 6.255333333333334,
              "speed3d": 6.276666666666666,
              "msFromStart": 49244.334666666684
            },
            {
              "lat": 43.756746955555556,
              "lng": -116.0946398111111,
              "alt": 2099.0769999999998,
              "speed2d": 6.686333333333333,
              "speed3d": 6.764444444444446,
              "msFromStart": 49739.57955555556
            },
            {
              "lat": 43.75677731111111,
              "lng": -116.09462737777778,
              "alt": 2098.474555555555,
              "speed2d": 6.746888888888889,
              "speed3d": 6.824444444444445,
              "msFromStart": 50234.690074074075
            },
            {
              "lat": 43.756808177777785,
              "lng": -116.09461574444444,
              "alt": 2097.307,
              "speed2d": 7.105333333333332,
              "speed3d": 7.1933333333333325,
              "msFromStart": 50729.39466666666
            },
            {
              "lat": 43.756842379999995,
              "lng": -116.09460335,
              "alt": 2095.976,
              "speed2d": 7.562600000000001,
              "speed3d": 7.674999999999999,
              "msFromStart": 51251.702866666674
            },
            {
              "lat": 43.756878344444445,
              "lng": -116.09459178888888,
              "alt": 2094.5124444444446,
              "speed2d": 7.90577777777778,
              "speed3d": 8.066666666666666,
              "msFromStart": 51774.53844444448
            },
            {
              "lat": 43.75691404444445,
              "lng": -116.09458341111113,
              "alt": 2092.8325555555557,
              "speed2d": 8.222,
              "speed3d": 8.431111111111111,
              "msFromStart": 52270.07529434699
            },
            {
              "lat": 43.75694905555556,
              "lng": -116.09457880000001,
              "alt": 2090.7965555555556,
              "speed2d": 8.322888888888889,
              "speed3d": 8.635555555555555,
              "msFromStart": 52766.086736842124
            },
            {
              "lat": 43.75698445555556,
              "lng": -116.09457424444446,
              "alt": 2088.846666666667,
              "speed2d": 8.48,
              "speed3d": 8.794444444444444,
              "msFromStart": 53261.94196881092
            },
            {
              "lat": 43.757028633333334,
              "lng": -116.09457218888889,
              "alt": 2086.8237777777777,
              "speed2d": 9.394,
              "speed3d": 9.662222222222223,
              "msFromStart": 53757.072444444435
            },
            {
              "lat": 43.75708218888889,
              "lng": -116.09457507777778,
              "alt": 2085.0377777777776,
              "speed2d": 10.556666666666665,
              "speed3d": 10.845555555555556,
              "msFromStart": 54252.004148148146
            },
            {
              "lat": 43.75713914444444,
              "lng": -116.09458710000001,
              "alt": 2083.177888888889,
              "speed2d": 11.477222222222222,
              "speed3d": 11.775555555555556,
              "msFromStart": 54746.43066666667
            },
            {
              "lat": 43.7572023,
              "lng": -116.09460701111111,
              "alt": 2081.5686666666666,
              "speed2d": 12.645555555555553,
              "speed3d": 12.914444444444442,
              "msFromStart": 55240.870740740735
            },
            {
              "lat": 43.757267811111106,
              "lng": -116.09462322222222,
              "alt": 2079.6277777777773,
              "speed2d": 13.814777777777778,
              "speed3d": 14.078888888888889,
              "msFromStart": 55735.567111111086
            },
            {
              "lat": 43.75733858888889,
              "lng": -116.09463272222223,
              "alt": 2077.649888888889,
              "speed2d": 14.991777777777777,
              "speed3d": 15.303333333333333,
              "msFromStart": 56230.078740740726
            },
            {
              "lat": 43.75741017,
              "lng": -116.09463251999999,
              "alt": 2075.5279,
              "speed2d": 15.100900000000001,
              "speed3d": 15.539,
              "msFromStart": 56751.17033333333
            },
            {
              "lat": 43.757481222222225,
              "lng": -116.09463307777779,
              "alt": 2073.8667777777778,
              "speed2d": 14.806555555555555,
              "speed3d": 15.274444444444445,
              "msFromStart": 57272.73103703705
            },
            {
              "lat": 43.757550566666666,
              "lng": -116.09464469999999,
              "alt": 2072.050111111111,
              "speed2d": 14.94188888888889,
              "speed3d": 15.304444444444446,
              "msFromStart": 57768.372000000025
            },
            {
              "lat": 43.75762496666667,
              "lng": -116.09466868888887,
              "alt": 2070.003111111111,
              "speed2d": 15.73088888888889,
              "speed3d": 16.095555555555556,
              "msFromStart": 58263.85607407409
            },
            {
              "lat": 43.75769415555556,
              "lng": -116.0946893,
              "alt": 2068.0963333333334,
              "speed2d": 15.727555555555556,
              "speed3d": 16.148888888888887,
              "msFromStart": 58758.39644444445
            },
            {
              "lat": 43.757762477777774,
              "lng": -116.09470244444444,
              "alt": 2065.9022222222225,
              "speed2d": 15.700777777777777,
              "speed3d": 16.15555555555556,
              "msFromStart": 59252.96207407407
            },
            {
              "lat": 43.757831566666674,
              "lng": -116.09470547777778,
              "alt": 2063.9456666666665,
              "speed2d": 15.747666666666667,
              "speed3d": 16.186666666666667,
              "msFromStart": 59748.0062222222
            },
            {
              "lat": 43.757899644444436,
              "lng": -116.09469421111112,
              "alt": 2062.2376666666664,
              "speed2d": 15.619,
              "speed3d": 16.095555555555553,
              "msFromStart": 60242.95377777777
            },
            {
              "lat": 43.757967111111114,
              "lng": -116.09468397777778,
              "alt": 2060.452,
              "speed2d": 15.414888888888887,
              "speed3d": 15.88888888888889,
              "msFromStart": 60737.323555555566
            },
            {
              "lat": 43.75803666666667,
              "lng": -116.0946856111111,
              "alt": 2059.183444444445,
              "speed2d": 15.294333333333332,
              "speed3d": 15.75111111111111,
              "msFromStart": 61231.847407407426
            },
            {
              "lat": 43.75810343333333,
              "lng": -116.09469595555555,
              "alt": 2057.9935555555558,
              "speed2d": 14.912888888888888,
              "speed3d": 15.36111111111111,
              "msFromStart": 61727.218666666704
            },
            {
              "lat": 43.75817778,
              "lng": -116.09470732999999,
              "alt": 2056.2006,
              "speed2d": 15.0635,
              "speed3d": 15.416,
              "msFromStart": 62250.02949473687
            },
            {
              "lat": 43.75826017777777,
              "lng": -116.09470843333332,
              "alt": 2055.165555555556,
              "speed2d": 15.846222222222222,
              "speed3d": 16.166666666666668,
              "msFromStart": 62772.288210526356
            },
            {
              "lat": 43.75833155555555,
              "lng": -116.09470866666668,
              "alt": 2053.5294444444444,
              "speed2d": 15.687222222222223,
              "speed3d": 16.10222222222222,
              "msFromStart": 63266.969165692026
            },
            {
              "lat": 43.758400300000005,
              "lng": -116.09471772222221,
              "alt": 2052.173444444444,
              "speed2d": 15.228333333333333,
              "speed3d": 15.65777777777778,
              "msFromStart": 63761.47288888887
            },
            {
              "lat": 43.75846915555555,
              "lng": -116.09473895555554,
              "alt": 2051.2054444444443,
              "speed2d": 15.033666666666667,
              "speed3d": 15.394444444444446,
              "msFromStart": 64256.05999999999
            },
            {
              "lat": 43.75853424444445,
              "lng": -116.09477012222222,
              "alt": 2049.9280000000003,
              "speed2d": 14.939555555555554,
              "speed3d": 15.326666666666666,
              "msFromStart": 64751.05155555558
            },
            {
              "lat": 43.75859278888888,
              "lng": -116.0948029111111,
              "alt": 2048.3496666666665,
              "speed2d": 14.546888888888887,
              "speed3d": 14.915555555555553,
              "msFromStart": 65246.33651851854
            },
            {
              "lat": 43.75865845555555,
              "lng": -116.09483438888887,
              "alt": 2046.5051111111113,
              "speed2d": 14.637777777777778,
              "speed3d": 14.98,
              "msFromStart": 65742.63333333333
            },
            {
              "lat": 43.75873875555556,
              "lng": -116.09486308888889,
              "alt": 2045.1947777777777,
              "speed2d": 15.191666666666666,
              "speed3d": 15.484444444444444,
              "msFromStart": 66239.07577777778
            },
            {
              "lat": 43.758821266666665,
              "lng": -116.09489132222224,
              "alt": 2043.6108888888887,
              "speed2d": 16.02766666666667,
              "speed3d": 16.32777777777778,
              "msFromStart": 66735.69600000001
            },
            {
              "lat": 43.75889632222221,
              "lng": -116.09491867777777,
              "alt": 2041.6553333333334,
              "speed2d": 16.56611111111111,
              "speed3d": 16.86111111111111,
              "msFromStart": 67231.81807407406
            },
            {
              "lat": 43.758977866666676,
              "lng": -116.09494268888889,
              "alt": 2040.1156666666666,
              "speed2d": 16.78033333333333,
              "speed3d": 17.14,
              "msFromStart": 67725.87644444438
            },
            {
              "lat": 43.75906241,
              "lng": -116.09495525999999,
              "alt": 2037.8662000000002,
              "speed2d": 16.8795,
              "speed3d": 17.259999999999998,
              "msFromStart": 68247.36533333329
            },
            {
              "lat": 43.75914764444444,
              "lng": -116.09495285555553,
              "alt": 2035.0788888888892,
              "speed2d": 17.078,
              "speed3d": 17.503333333333334,
              "msFromStart": 68769.50399999993
            },
            {
              "lat": 43.75922526666667,
              "lng": -116.09493229999998,
              "alt": 2033.915,
              "speed2d": 16.939555555555557,
              "speed3d": 17.377777777777776,
              "msFromStart": 69264.60044444441
            },
            {
              "lat": 43.759304,
              "lng": -116.09490141111111,
              "alt": 2033.1589999999999,
              "speed2d": 16.836555555555556,
              "speed3d": 17.286666666666665,
              "msFromStart": 69761.23466666664
            },
            {
              "lat": 43.759377555555545,
              "lng": -116.09486709999999,
              "alt": 2031.9714444444444,
              "speed2d": 16.45111111111111,
              "speed3d": 16.847777777777775,
              "msFromStart": 70258.01229629628
            },
            {
              "lat": 43.7594459,
              "lng": -116.09483353333334,
              "alt": 2030.2267777777777,
              "speed2d": 15.857444444444445,
              "speed3d": 16.368888888888886,
              "msFromStart": 70754.74844444444
            },
            {
              "lat": 43.75951137777778,
              "lng": -116.09480117777777,
              "alt": 2028.5526666666667,
              "speed2d": 15.274333333333333,
              "speed3d": 15.752222222222223,
              "msFromStart": 71250.63674074074
            },
            {
              "lat": 43.75957656666667,
              "lng": -116.09477303333333,
              "alt": 2026.3315555555555,
              "speed2d": 14.963,
              "speed3d": 15.44888888888889,
              "msFromStart": 71743.15022222225
            },
            {
              "lat": 43.75963414444445,
              "lng": -116.09473365555556,
              "alt": 2023.9561111111109,
              "speed2d": 14.717666666666666,
              "speed3d": 15.224444444444444,
              "msFromStart": 72236.09199220274
            },
            {
              "lat": 43.75968520000001,
              "lng": -116.09468691111111,
              "alt": 2021.4962222222225,
              "speed2d": 14.384666666666666,
              "speed3d": 15.023333333333335,
              "msFromStart": 72732.09684210525
            },
            {
              "lat": 43.75972532222222,
              "lng": -116.09463565555555,
              "alt": 2018.450666666667,
              "speed2d": 13.584555555555555,
              "speed3d": 14.27,
              "msFromStart": 73228.25353606237
            },
            {
              "lat": 43.759778340000004,
              "lng": -116.09459397000002,
              "alt": 2015.2292999999997,
              "speed2d": 13.060099999999998,
              "speed3d": 13.83,
              "msFromStart": 73750.97133333335
            },
            {
              "lat": 43.759834944444435,
              "lng": -116.09457407777779,
              "alt": 2010.1547777777778,
              "speed2d": 12.779333333333332,
              "speed3d": 13.636666666666668,
              "msFromStart": 74273.29096296297
            },
            {
              "lat": 43.75989112222222,
              "lng": -116.09454703333333,
              "alt": 2005.9052222222222,
              "speed2d": 13.173333333333332,
              "speed3d": 14.087777777777777,
              "msFromStart": 74767.1324444445
            },
            {
              "lat": 43.75995718888889,
              "lng": -116.09452345555555,
              "alt": 2002.6149999999998,
              "speed2d": 13.758444444444443,
              "speed3d": 14.716666666666665,
              "msFromStart": 75260.89274074076
            },
            {
              "lat": 43.760024466666664,
              "lng": -116.09449924444444,
              "alt": 1999.9441111111112,
              "speed2d": 14.085777777777777,
              "speed3d": 15.001111111111111,
              "msFromStart": 75754.7182222222
            },
            {
              "lat": 43.760094877777775,
              "lng": -116.09447928888889,
              "alt": 1997.2032222222224,
              "speed2d": 14.543666666666667,
              "speed3d": 15.428888888888887,
              "msFromStart": 76248.79466666664
            },
            {
              "lat": 43.7601685,
              "lng": -116.0944660222222,
              "alt": 1993.5932222222223,
              "speed2d": 15.280444444444447,
              "speed3d": 16.14333333333333,
              "msFromStart": 76743.84888888887
            },
            {
              "lat": 43.76024048888888,
              "lng": -116.09444853333333,
              "alt": 1989.1966666666667,
              "speed2d": 15.794777777777776,
              "speed3d": 16.755555555555556,
              "msFromStart": 77239.11496296295
            },
            {
              "lat": 43.760304633333334,
              "lng": -116.09441745555556,
              "alt": 1984.947777777778,
              "speed2d": 15.930666666666665,
              "speed3d": 16.995555555555555,
              "msFromStart": 77734.83733333336
            },
            {
              "lat": 43.76035944444444,
              "lng": -116.09437030000001,
              "alt": 1981.0654444444442,
              "speed2d": 15.871111111111112,
              "speed3d": 16.956666666666667,
              "msFromStart": 78230.2743391813
            },
            {
              "lat": 43.76042613999999,
              "lng": -116.09433246000003,
              "alt": 1977.3091,
              "speed2d": 15.8367,
              "speed3d": 17.017,
              "msFromStart": 78751.83073684211
            },
            {
              "lat": 43.76049263333334,
              "lng": -116.09429604444443,
              "alt": 1974.0878888888888,
              "speed2d": 15.701999999999998,
              "speed3d": 16.902222222222225,
              "msFromStart": 79273.4212865497
            },
            {
              "lat": 43.76055671111111,
              "lng": -116.09426387777776,
              "alt": 1972.1763333333336,
              "speed2d": 15.332555555555556,
              "speed3d": 16.58111111111111,
              "msFromStart": 79768.23466666664
            },
            {
              "lat": 43.76062505555556,
              "lng": -116.09424822222222,
              "alt": 1971.286888888889,
              "speed2d": 15.144888888888888,
              "speed3d": 16.225555555555555,
              "msFromStart": 80263.19540740739
            },
            {
              "lat": 43.76069328888889,
              "lng": -116.09423946666668,
              "alt": 1969.6328888888888,
              "speed2d": 15.298333333333332,
              "speed3d": 16.31888888888889,
              "msFromStart": 80758.47911111108
            },
            {
              "lat": 43.7607714,
              "lng": -116.0942365111111,
              "alt": 1968.6023333333333,
              "speed2d": 16.015,
              "speed3d": 16.865555555555556,
              "msFromStart": 81253.88770370369
            },
            {
              "lat": 43.76084829999999,
              "lng": -116.09423855555555,
              "alt": 1966.7513333333334,
              "speed2d": 16.468888888888888,
              "speed3d": 17.33777777777778,
              "msFromStart": 81749.6666666667
            },
            {
              "lat": 43.76092085555556,
              "lng": -116.09424030000001,
              "alt": 1964.9705555555556,
              "speed2d": 16.355222222222224,
              "speed3d": 17.198888888888888,
              "msFromStart": 82245.33225730996
            },
            {
              "lat": 43.760994999999994,
              "lng": -116.09423908888888,
              "alt": 1963.397333333333,
              "speed2d": 16.35977777777778,
              "speed3d": 17.207777777777775,
              "msFromStart": 82740.39621052625
            },
            {
              "lat": 43.761071566666665,
              "lng": -116.09423793333332,
              "alt": 1962.4585555555557,
              "speed2d": 16.34733333333333,
              "speed3d": 17.14222222222222,
              "msFromStart": 83235.4306432748
            },
            {
              "lat": 43.761149122222214,
              "lng": -116.09423723333333,
              "alt": 1961.951,
              "speed2d": 16.23288888888889,
              "speed3d": 16.963333333333335,
              "msFromStart": 83730.65200000006
            },
            {
              "lat": 43.76122998,
              "lng": -116.09424240999999,
              "alt": 1961.2687999999998,
              "speed2d": 16.27,
              "speed3d": 16.896,
              "msFromStart": 84253.05866666671
            },
            {
              "lat": 43.76131694444444,
              "lng": -116.09425687777778,
              "alt": 1960.8614444444447,
              "speed2d": 16.97333333333333,
              "speed3d": 17.45,
              "msFromStart": 84773.7884444445
            }
          ]
        }
      ]
    },
    {
      "id": "trail-bitterroot-basin",
      "pathType": "trail",
      "name": "Bitterroot Basin",
      "difficulty": "blue",
      "videos": [
        {
          "videoId": "GX010079",
          "pathId": "trail-bitterroot-basin",
          "startSec": 76,
          "endSec": 135,
          "id": "video-segment-40",
          "gps": [
            {
              "lat": 43.772478288888884,
              "lng": -116.1069101,
              "alt": 2008.8641111111112,
              "speed2d": 3.8183333333333334,
              "speed3d": 3.898888888888889,
              "msFromStart": 76246.86215984402
            },
            {
              "lat": 43.77248674444444,
              "lng": -116.10693050000003,
              "alt": 2009.109,
              "speed2d": 3.4073333333333338,
              "speed3d": 3.516666666666667,
              "msFromStart": 76742.73422222224
            },
            {
              "lat": 43.772497388888894,
              "lng": -116.10694444444445,
              "alt": 2009.175888888889,
              "speed2d": 3.0120000000000005,
              "speed3d": 3.102222222222222,
              "msFromStart": 77238.41125925926
            },
            {
              "lat": 43.77250522222222,
              "lng": -116.10695944444443,
              "alt": 2008.9824444444444,
              "speed2d": 2.695333333333333,
              "speed3d": 2.782222222222223,
              "msFromStart": 77733.1164444444
            },
            {
              "lat": 43.772510666666676,
              "lng": -116.1069729,
              "alt": 2008.5146666666667,
              "speed2d": 2.2533333333333334,
              "speed3d": 2.3744444444444444,
              "msFromStart": 78227.72666666664
            },
            {
              "lat": 43.77251569,
              "lng": -116.10698885,
              "alt": 2008.1635999999996,
              "speed2d": 2.1731,
              "speed3d": 2.2670000000000003,
              "msFromStart": 78749.82466666662
            },
            {
              "lat": 43.77252143333334,
              "lng": -116.10700312222222,
              "alt": 2007.755222222222,
              "speed2d": 1.9831111111111113,
              "speed3d": 2.1155555555555554,
              "msFromStart": 79272.44829629626
            },
            {
              "lat": 43.77252892222222,
              "lng": -116.1070158888889,
              "alt": 2007.3758888888888,
              "speed2d": 2.1384444444444446,
              "speed3d": 2.2399999999999998,
              "msFromStart": 79769.01733333326
            },
            {
              "lat": 43.772540077777776,
              "lng": -116.10702433333333,
              "alt": 2006.8465555555556,
              "speed2d": 2.448444444444444,
              "speed3d": 2.546666666666667,
              "msFromStart": 80264.77925146198
            },
            {
              "lat": 43.77255248888888,
              "lng": -116.10703286666667,
              "alt": 2006.9432222222222,
              "speed2d": 2.820111111111111,
              "speed3d": 2.913333333333333,
              "msFromStart": 80758.1469473685
            },
            {
              "lat": 43.772564933333335,
              "lng": -116.10704284444444,
              "alt": 2007.6530000000002,
              "speed2d": 3.1178888888888894,
              "speed3d": 3.2288888888888896,
              "msFromStart": 81251.92906042891
            },
            {
              "lat": 43.77257815555556,
              "lng": -116.10704954444445,
              "alt": 2008.283,
              "speed2d": 3.344333333333333,
              "speed3d": 3.397777777777778,
              "msFromStart": 81747.87288888896
            },
            {
              "lat": 43.77259573333333,
              "lng": -116.10705048888887,
              "alt": 2008.4861111111109,
              "speed2d": 3.8904444444444444,
              "speed3d": 3.9511111111111115,
              "msFromStart": 82243.79214814819
            },
            {
              "lat": 43.772615622222226,
              "lng": -116.10704914444446,
              "alt": 2008.228,
              "speed2d": 4.438555555555556,
              "speed3d": 4.4688888888888885,
              "msFromStart": 82738.74844444447
            },
            {
              "lat": 43.7726392,
              "lng": -116.1070555,
              "alt": 2007.3155555555556,
              "speed2d": 5.055666666666666,
              "speed3d": 5.1466666666666665,
              "msFromStart": 83233.69807407408
            },
            {
              "lat": 43.772662800000006,
              "lng": -116.10706717777778,
              "alt": 2006.578888888889,
              "speed2d": 5.669777777777778,
              "speed3d": 5.725555555555555,
              "msFromStart": 83729.00622222226
            },
            {
              "lat": 43.77269332,
              "lng": -116.10708904999998,
              "alt": 2005.6191000000001,
              "speed2d": 6.723,
              "speed3d": 6.779,
              "msFromStart": 84251.6686666667
            },
            {
              "lat": 43.772728,
              "lng": -116.10711483333333,
              "alt": 2005.1806666666666,
              "speed2d": 7.5501111111111125,
              "speed3d": 7.646666666666666,
              "msFromStart": 84773.68133333337
            },
            {
              "lat": 43.77275755555556,
              "lng": -116.10714124444445,
              "alt": 2004.3176666666668,
              "speed2d": 7.781777777777777,
              "speed3d": 7.89111111111111,
              "msFromStart": 85268.17630409359
            },
            {
              "lat": 43.77279226666666,
              "lng": -116.10716783333332,
              "alt": 2003.6601111111108,
              "speed2d": 8.164555555555555,
              "speed3d": 8.31111111111111,
              "msFromStart": 85762.70105263159
            },
            {
              "lat": 43.772828499999996,
              "lng": -116.10718726666666,
              "alt": 2002.8998888888889,
              "speed2d": 8.084888888888889,
              "speed3d": 8.29888888888889,
              "msFromStart": 86257.43656920077
            },
            {
              "lat": 43.7728638,
              "lng": -116.10719166666667,
              "alt": 2002.2607777777775,
              "speed2d": 7.996777777777779,
              "speed3d": 8.13,
              "msFromStart": 86753.00888888884
            },
            {
              "lat": 43.772907566666674,
              "lng": -116.10718253333334,
              "alt": 2002.0614444444443,
              "speed2d": 9.182444444444446,
              "speed3d": 9.202222222222222,
              "msFromStart": 87248.31970370369
            },
            {
              "lat": 43.77295252222223,
              "lng": -116.10716571111112,
              "alt": 2001.2622222222221,
              "speed2d": 10.192777777777778,
              "speed3d": 10.280000000000001,
              "msFromStart": 87742.24977777785
            },
            {
              "lat": 43.7729963,
              "lng": -116.107137,
              "alt": 2000.188,
              "speed2d": 10.971111111111112,
              "speed3d": 11.041111111111112,
              "msFromStart": 88236.28029629633
            },
            {
              "lat": 43.77304554444445,
              "lng": -116.10710496666668,
              "alt": 1998.9774444444442,
              "speed2d": 11.847777777777779,
              "speed3d": 11.96,
              "msFromStart": 88731.26488888887
            },
            {
              "lat": 43.7730954,
              "lng": -116.10708639999999,
              "alt": 1997.5576666666666,
              "speed2d": 11.560555555555556,
              "speed3d": 11.855555555555554,
              "msFromStart": 89226.63007407404
            },
            {
              "lat": 43.77314369,
              "lng": -116.10708795999997,
              "alt": 1995.5468999999998,
              "speed2d": 10.5755,
              "speed3d": 10.917,
              "msFromStart": 89750.72599999994
            },
            {
              "lat": 43.77318774444445,
              "lng": -116.10709493333331,
              "alt": 1993.7534444444445,
              "speed2d": 9.866666666666667,
              "speed3d": 10.20888888888889,
              "msFromStart": 90274.46785964908
            },
            {
              "lat": 43.77322512222222,
              "lng": -116.10710585555555,
              "alt": 1991.9642222222224,
              "speed2d": 9.272111111111112,
              "speed3d": 9.666666666666668,
              "msFromStart": 90769.3490526315
            },
            {
              "lat": 43.77325988888889,
              "lng": -116.10711363333333,
              "alt": 1989.2781111111112,
              "speed2d": 8.879222222222221,
              "speed3d": 9.28888888888889,
              "msFromStart": 91264.2007173489
            },
            {
              "lat": 43.77330523333334,
              "lng": -116.10710817777779,
              "alt": 1987.3375555555554,
              "speed2d": 9.793444444444443,
              "speed3d": 10.016666666666667,
              "msFromStart": 91759.20177777784
            },
            {
              "lat": 43.77336051111111,
              "lng": -116.10708705555554,
              "alt": 1986.2288888888888,
              "speed2d": 11.41511111111111,
              "speed3d": 11.54888888888889,
              "msFromStart": 92254.1814814815
            },
            {
              "lat": 43.773422422222225,
              "lng": -116.10705954444444,
              "alt": 1985.4237777777778,
              "speed2d": 12.710777777777777,
              "speed3d": 12.916666666666664,
              "msFromStart": 92749.01599999996
            },
            {
              "lat": 43.7734845,
              "lng": -116.10702814444444,
              "alt": 1984.3481111111112,
              "speed2d": 13.538333333333336,
              "speed3d": 13.719999999999999,
              "msFromStart": 93243.92637037035
            },
            {
              "lat": 43.77354801111112,
              "lng": -116.10700796666666,
              "alt": 1981.920222222222,
              "speed2d": 13.795555555555557,
              "speed3d": 14.106666666666667,
              "msFromStart": 93739.19822222224
            },
            {
              "lat": 43.77361226666666,
              "lng": -116.10700360000001,
              "alt": 1979.1806666666666,
              "speed2d": 13.724333333333334,
              "speed3d": 14.038888888888888,
              "msFromStart": 94234.4025185185
            },
            {
              "lat": 43.77367875555556,
              "lng": -116.10700674444443,
              "alt": 1976.256,
              "speed2d": 13.949444444444444,
              "speed3d": 14.258888888888887,
              "msFromStart": 94729.19199999992
            },
            {
              "lat": 43.77375232999999,
              "lng": -116.10701805000001,
              "alt": 1973.3118,
              "speed2d": 14.538100000000002,
              "speed3d": 14.885,
              "msFromStart": 95251.32741052625
            },
            {
              "lat": 43.77382698888889,
              "lng": -116.10702592222223,
              "alt": 1970.5208888888887,
              "speed2d": 14.879333333333335,
              "speed3d": 15.215555555555554,
              "msFromStart": 95773.14357894729
            },
            {
              "lat": 43.77390338888889,
              "lng": -116.10702074444444,
              "alt": 1967.8255555555556,
              "speed2d": 16.092666666666666,
              "speed3d": 16.355555555555554,
              "msFromStart": 96267.52655750481
            },
            {
              "lat": 43.773983211111116,
              "lng": -116.10699978888888,
              "alt": 1965.6916666666662,
              "speed2d": 17.234555555555556,
              "speed3d": 17.532222222222224,
              "msFromStart": 96762.13955555556
            },
            {
              "lat": 43.774061044444444,
              "lng": -116.10697373333333,
              "alt": 1963.1744444444446,
              "speed2d": 17.463444444444445,
              "speed3d": 17.86777777777778,
              "msFromStart": 97257.03407407408
            },
            {
              "lat": 43.774141122222225,
              "lng": -116.10695230000002,
              "alt": 1960.6978888888889,
              "speed2d": 17.639111111111113,
              "speed3d": 17.997777777777777,
              "msFromStart": 97752.96266666662
            },
            {
              "lat": 43.77422804444444,
              "lng": -116.10694465555555,
              "alt": 1958.0507777777777,
              "speed2d": 18.153555555555553,
              "speed3d": 18.526666666666667,
              "msFromStart": 98248.77985185182
            },
            {
              "lat": 43.774314133333334,
              "lng": -116.10694783333332,
              "alt": 1955.239111111111,
              "speed2d": 18.35288888888889,
              "speed3d": 18.74888888888889,
              "msFromStart": 98743.7377777778
            },
            {
              "lat": 43.77439786666667,
              "lng": -116.106953,
              "alt": 1952.4006666666667,
              "speed2d": 18.338777777777775,
              "speed3d": 18.764444444444443,
              "msFromStart": 99238.80755555557
            },
            {
              "lat": 43.774484022222225,
              "lng": -116.10695609999999,
              "alt": 1949.5992222222221,
              "speed2d": 18.72188888888889,
              "speed3d": 19.11777777777778,
              "msFromStart": 99734.66844444441
            },
            {
              "lat": 43.7745563,
              "lng": -116.10695609999999,
              "alt": 1946.9986666666666,
              "speed2d": 19.075333333333333,
              "speed3d": 19.48333333333333,
              "msFromStart": 100147.84460818711
            },
            {
              "lat": 43.774674475,
              "lng": -116.10695032500001,
              "alt": 1943.02975,
              "speed2d": 18.93025,
              "speed3d": 19.377499999999998,
              "msFromStart": 100834.18526315787
            },
            {
              "lat": 43.77474884285714,
              "lng": -116.10694479999998,
              "alt": 1940.5545714285715,
              "speed2d": 18.802285714285713,
              "speed3d": 19.302857142857146,
              "msFromStart": 101265.61593984961
            },
            {
              "lat": 43.77483097142857,
              "lng": -116.10693828571429,
              "alt": 1938.2305714285712,
              "speed2d": 18.934,
              "speed3d": 19.39,
              "msFromStart": 101729.09314285713
            },
            {
              "lat": 43.774929449999995,
              "lng": -116.1069333125,
              "alt": 1935.0351249999999,
              "speed2d": 18.8955,
              "speed3d": 19.39625,
              "msFromStart": 102291.0255
            },
            {
              "lat": 43.775001859999996,
              "lng": -116.10692983999999,
              "alt": 1932.694,
              "speed2d": 18.4976,
              "speed3d": 19.029999999999998,
              "msFromStart": 102726.24640000003
            },
            {
              "lat": 43.77508814444444,
              "lng": -116.10693027777776,
              "alt": 1929.0310000000002,
              "speed2d": 18.376111111111115,
              "speed3d": 18.939999999999998,
              "msFromStart": 103254.83911111113
            },
            {
              "lat": 43.77516494285714,
              "lng": -116.10693534285713,
              "alt": 1925.527857142857,
              "speed2d": 17.900285714285715,
              "speed3d": 18.504285714285714,
              "msFromStart": 103756.90133333327
            },
            {
              "lat": 43.7752439625,
              "lng": -116.10694314999999,
              "alt": 1922.675375,
              "speed2d": 17.594,
              "speed3d": 18.168750000000003,
              "msFromStart": 104264.35199999996
            },
            {
              "lat": 43.775319716666665,
              "lng": -116.10695085,
              "alt": 1920.5955,
              "speed2d": 17.627333333333333,
              "speed3d": 18.18166666666667,
              "msFromStart": 104731.32770370363
            },
            {
              "lat": 43.775418925000004,
              "lng": -116.10696522500001,
              "alt": 1917.9675000000002,
              "speed2d": 17.226750000000003,
              "speed3d": 17.8125,
              "msFromStart": 105360.94168421053
            },
            {
              "lat": 43.77548274,
              "lng": -116.10697776,
              "alt": 1916.417,
              "speed2d": 17.064,
              "speed3d": 17.616,
              "msFromStart": 105775.80067368422
            },
            {
              "lat": 43.775548799999996,
              "lng": -116.10699795555556,
              "alt": 1914.6129999999998,
              "speed2d": 16.78022222222222,
              "speed3d": 17.342222222222222,
              "msFromStart": 106226.33872904483
            },
            {
              "lat": 43.77562133,
              "lng": -116.10702651000001,
              "alt": 1912.6565999999998,
              "speed2d": 16.4249,
              "speed3d": 17.004,
              "msFromStart": 106748.0653333333
            },
            {
              "lat": 43.77568323333333,
              "lng": -116.10706136666667,
              "alt": 1911.1425555555559,
              "speed2d": 15.204444444444444,
              "speed3d": 15.857777777777779,
              "msFromStart": 107269.7791111111
            },
            {
              "lat": 43.77573361111111,
              "lng": -116.10710139999999,
              "alt": 1909.7473333333335,
              "speed2d": 14.068999999999999,
              "speed3d": 14.688888888888888,
              "msFromStart": 107764.13333333333
            },
            {
              "lat": 43.77577627777778,
              "lng": -116.1071415,
              "alt": 1908.4298888888889,
              "speed2d": 12.601222222222223,
              "speed3d": 13.322222222222221,
              "msFromStart": 108258.66325925927
            },
            {
              "lat": 43.77581132222222,
              "lng": -116.10717768888888,
              "alt": 1906.069777777778,
              "speed2d": 11.084555555555553,
              "speed3d": 11.78888888888889,
              "msFromStart": 108753.85688888891
            },
            {
              "lat": 43.77585167777778,
              "lng": -116.10720801111113,
              "alt": 1904.3227777777777,
              "speed2d": 10.331777777777777,
              "speed3d": 11.03222222222222,
              "msFromStart": 109249.25244444444
            },
            {
              "lat": 43.775901322222225,
              "lng": -116.1072293,
              "alt": 1903.8853333333334,
              "speed2d": 10.193444444444445,
              "speed3d": 10.725555555555555,
              "msFromStart": 109745.19022222224
            },
            {
              "lat": 43.77597596666667,
              "lng": -116.10724526666667,
              "alt": 1903.4629999999997,
              "speed2d": 11.155333333333335,
              "speed3d": 11.53,
              "msFromStart": 110369.08954385965
            },
            {
              "lat": 43.7760176,
              "lng": -116.1072481,
              "alt": 1902.83,
              "speed2d": 11.191333333333333,
              "speed3d": 11.616666666666667,
              "msFromStart": 110753.42638596494
            },
            {
              "lat": 43.77604127773273,
              "lng": -116.10724829427538,
              "alt": 1901.9605866525833,
              "speed2d": 11.129932917122268,
              "speed3d": 11.512457554870664,
              "msFromStart": 111000
            },
            {
              "lat": 43.77613735,
              "lng": -116.10723975,
              "alt": 1898.4847499999998,
              "speed2d": 12.13525,
              "speed3d": 12.5175,
              "msFromStart": 111863.21844444444
            },
            {
              "lat": 43.776166225000004,
              "lng": -116.1072398,
              "alt": 1897.3367500000002,
              "speed2d": 12.05125,
              "speed3d": 12.495,
              "msFromStart": 112138.74933333334
            },
            {
              "lat": 43.776246525000005,
              "lng": -116.10725550000001,
              "alt": 1894.1525000000001,
              "speed2d": 11.741499999999998,
              "speed3d": 12.237499999999999,
              "msFromStart": 112908.97488888894
            },
            {
              "lat": 43.77628215,
              "lng": -116.1072698,
              "alt": 1893.049,
              "speed2d": 11.3275,
              "speed3d": 11.785,
              "msFromStart": 113293.77333333335
            },
            {
              "lat": 43.7763181,
              "lng": -116.10728522,
              "alt": 1891.8457999999998,
              "speed2d": 10.6152,
              "speed3d": 11.198,
              "msFromStart": 113705.40000000002
            },
            {
              "lat": 43.77636418749999,
              "lng": -116.1072937,
              "alt": 1890.11625,
              "speed2d": 10.024125,
              "speed3d": 10.565000000000001,
              "msFromStart": 114240.85466666667
            },
            {
              "lat": 43.776411350000004,
              "lng": -116.1072894125,
              "alt": 1888.5282499999998,
              "speed2d": 9.83125,
              "speed3d": 10.36875,
              "msFromStart": 114778.00933333335
            },
            {
              "lat": 43.77644316666667,
              "lng": -116.10727606666666,
              "alt": 1887.3351666666667,
              "speed2d": 9.326166666666666,
              "speed3d": 9.901666666666667,
              "msFromStart": 115200.39490058483
            },
            {
              "lat": 43.776477325,
              "lng": -116.107250325,
              "alt": 1886.38325,
              "speed2d": 8.7785,
              "speed3d": 9.34,
              "msFromStart": 115700.10547368426
            },
            {
              "lat": 43.77649439730956,
              "lng": -116.10723104691978,
              "alt": 1886.1333599942102,
              "speed2d": 8.438943509353567,
              "speed3d": 9.032941317778658,
              "msFromStart": 116000
            },
            {
              "lat": 43.776521644568625,
              "lng": -116.10719801552962,
              "alt": 1885.8543393306363,
              "speed2d": 7.915825237881377,
              "speed3d": 8.450890389854104,
              "msFromStart": 116500
            },
            {
              "lat": 43.77656604,
              "lng": -116.10714456,
              "alt": 1885.395,
              "speed2d": 7.1416,
              "speed3d": 7.609999999999999,
              "msFromStart": 117313.73306666664
            },
            {
              "lat": 43.7765807,
              "lng": -116.1071273,
              "alt": 1885.2845,
              "speed2d": 7.0105,
              "speed3d": 7.43,
              "msFromStart": 117583.09688888889
            },
            {
              "lat": 43.776619833333335,
              "lng": -116.1070926,
              "alt": 1884.9429999999998,
              "speed2d": 7.107333333333333,
              "speed3d": 7.473333333333333,
              "msFromStart": 118215.3748148148
            },
            {
              "lat": 43.7766539,
              "lng": -116.10707250000002,
              "alt": 1884.6490000000001,
              "speed2d": 6.566333333333333,
              "speed3d": 6.946666666666667,
              "msFromStart": 118765.57333333339
            },
            {
              "lat": 43.77666300503845,
              "lng": -116.10706312359696,
              "alt": 1883.8470503845872,
              "speed2d": 5.253752553805851,
              "speed3d": 5.905093538446611,
              "msFromStart": 119000
            },
            {
              "lat": 43.7767171,
              "lng": -116.10705868571428,
              "alt": 1884.2092857142861,
              "speed2d": 6.043285714285714,
              "speed3d": 6.321428571428571,
              "msFromStart": 119794.56514285719
            },
            {
              "lat": 43.77674433333334,
              "lng": -116.10705819999998,
              "alt": 1884.0374444444444,
              "speed2d": 5.960111111111112,
              "speed3d": 6.297777777777778,
              "msFromStart": 120250.11920467837
            },
            {
              "lat": 43.776769216666665,
              "lng": -116.10706019999999,
              "alt": 1883.5293333333334,
              "speed2d": 5.825,
              "speed3d": 6.098333333333333,
              "msFromStart": 120718.36799999996
            },
            {
              "lat": 43.776787725763405,
              "lng": -116.10705869188394,
              "alt": 1883.0303383388928,
              "speed2d": 6.16670498180156,
              "speed3d": 6.379767678279665,
              "msFromStart": 121000
            },
            {
              "lat": 43.776821874999996,
              "lng": -116.107075075,
              "alt": 1881.6455,
              "speed2d": 5.007499999999999,
              "speed3d": 5.379999999999999,
              "msFromStart": 121845.93111111104
            },
            {
              "lat": 43.776832500000005,
              "lng": -116.10708002499999,
              "alt": 1881.1655,
              "speed2d": 4.70475,
              "speed3d": 5.1225000000000005,
              "msFromStart": 122148.03555555551
            },
            {
              "lat": 43.776856725,
              "lng": -116.107093575,
              "alt": 1880.3185,
              "speed2d": 4.2924999999999995,
              "speed3d": 4.715,
              "msFromStart": 122822.76944444442
            },
            {
              "lat": 43.776865275,
              "lng": -116.107098925,
              "alt": 1880.14275,
              "speed2d": 4.0685,
              "speed3d": 4.5024999999999995,
              "msFromStart": 123084.52822222219
            },
            {
              "lat": 43.776887599999995,
              "lng": -116.107113525,
              "alt": 1880.122,
              "speed2d": 3.2175,
              "speed3d": 3.6625,
              "msFromStart": 123867.9611111111
            },
            {
              "lat": 43.776898733333326,
              "lng": -116.10712163333332,
              "alt": 1880.5063333333333,
              "speed2d": 2.867166666666667,
              "speed3d": 3.295,
              "msFromStart": 124284.8462222222
            },
            {
              "lat": 43.7769134,
              "lng": -116.1071282,
              "alt": 1880.8319999999999,
              "speed2d": 2.0100000000000002,
              "speed3d": 2.45,
              "msFromStart": 124954.68000000002
            },
            {
              "lat": 43.77692095,
              "lng": -116.107131875,
              "alt": 1881.3215,
              "speed2d": 1.6207500000000001,
              "speed3d": 2.0599999999999996,
              "msFromStart": 125339.85368421051
            },
            {
              "lat": 43.77692474891971,
              "lng": -116.10713441728394,
              "alt": 1881.8294089476267,
              "speed2d": 1.5139753108171061,
              "speed3d": 1.7805246930130156,
              "msFromStart": 125500
            },
            {
              "lat": 43.776939119999994,
              "lng": -116.1071328,
              "alt": 1882.9715999999999,
              "speed2d": 0.9196,
              "speed3d": 1.266,
              "msFromStart": 126257.72850994149
            },
            {
              "lat": 43.77695166666667,
              "lng": -116.107133,
              "alt": 1884.315,
              "speed2d": 0.8493333333333334,
              "speed3d": 1.05,
              "msFromStart": 126850.86977777784
            },
            {
              "lat": 43.7769591,
              "lng": -116.10713384,
              "alt": 1885.1782000000003,
              "speed2d": 0.7392000000000001,
              "speed3d": 0.8639999999999999,
              "msFromStart": 127279.3403555556
            },
            {
              "lat": 43.7769666,
              "lng": -116.10713365,
              "alt": 1885.8881250000002,
              "speed2d": 0.7186250000000001,
              "speed3d": 0.805,
              "msFromStart": 127709.67833333337
            },
            {
              "lat": 43.7769756,
              "lng": -116.10713245,
              "alt": 1886.5655,
              "speed2d": 0.76325,
              "speed3d": 0.8300000000000001,
              "msFromStart": 128294.42522222226
            },
            {
              "lat": 43.7769811,
              "lng": -116.10713347142857,
              "alt": 1887.2244285714285,
              "speed2d": 0.4918571428571429,
              "speed3d": 0.56,
              "msFromStart": 128776.9066666667
            },
            {
              "lat": 43.77698466,
              "lng": -116.10713434000002,
              "alt": 1887.6413999999997,
              "speed2d": 0.32,
              "speed3d": 0.35000000000000003,
              "msFromStart": 129207.02782222227
            },
            {
              "lat": 43.77698728,
              "lng": -116.10713342,
              "alt": 1887.4074,
              "speed2d": 0.46559999999999996,
              "speed3d": 0.49,
              "msFromStart": 129768.59822222227
            },
            {
              "lat": 43.776990299999994,
              "lng": -116.10713351428572,
              "alt": 1887.597,
              "speed2d": 0.3394285714285714,
              "speed3d": 0.4514285714285715,
              "msFromStart": 130240.48717794489
            },
            {
              "lat": 43.77699318,
              "lng": -116.10713492000001,
              "alt": 1887.9116,
              "speed2d": 0.15940000000000001,
              "speed3d": 0.26799999999999996,
              "msFromStart": 130771.06981052624
            },
            {
              "lat": 43.776996866666664,
              "lng": -116.10713403333335,
              "alt": 1887.9216666666666,
              "speed2d": 0.18,
              "speed3d": 0.31666666666666665,
              "msFromStart": 131310.84916959063
            },
            {
              "lat": 43.776997475,
              "lng": -116.107135025,
              "alt": 1888.15525,
              "speed2d": 0.26225,
              "speed3d": 0.34750000000000003,
              "msFromStart": 131763.6756666666
            },
            {
              "lat": 43.776999579999995,
              "lng": -116.10713568,
              "alt": 1888.3180000000002,
              "speed2d": 0.256,
              "speed3d": 0.372,
              "msFromStart": 132211.12133333326
            },
            {
              "lat": 43.77700228826355,
              "lng": -116.10713602510096,
              "alt": 1888.5564983635836,
              "speed2d": 0.2266397024537474,
              "speed3d": 0.3565181721404458,
              "msFromStart": 132500
            },
            {
              "lat": 43.77701201428572,
              "lng": -116.10713561428571,
              "alt": 1889.4415714285715,
              "speed2d": 0.24842857142857142,
              "speed3d": 0.3128571428571429,
              "msFromStart": 133243.40698412692
            },
            {
              "lat": 43.7770151,
              "lng": -116.10713733333333,
              "alt": 1889.9911666666667,
              "speed2d": 0.1696666666666667,
              "speed3d": 0.21833333333333335,
              "msFromStart": 133757.3904444445
            },
            {
              "lat": 43.77701905714285,
              "lng": -116.10713812857142,
              "alt": 1890.421857142857,
              "speed2d": 0.2897142857142857,
              "speed3d": 0.31714285714285717,
              "msFromStart": 134239.77517460322
            },
            {
              "lat": 43.777024450000006,
              "lng": -116.107135875,
              "alt": 1890.6185,
              "speed2d": 0.3665,
              "speed3d": 0.39749999999999996,
              "msFromStart": 134869.44155555562
            }
          ]
        }
      ]
    },
    {
      "id": "trail-alpine",
      "pathType": "trail",
      "name": "Alpine",
      "difficulty": "blue",
      "videos": [
        {
          "videoId": "GX010071",
          "pathId": "trail-alpine",
          "startSec": 56,
          "endSec": 88,
          "id": "video-segment-33",
          "gps": [
            {
              "lat": 43.75728544444444,
              "lng": -116.09327146666666,
              "alt": 2107.857,
              "speed2d": 10.193000000000001,
              "speed3d": 10.43111111111111,
              "msFromStart": 56268.47400000001
            },
            {
              "lat": 43.75732271111111,
              "lng": -116.0932528111111,
              "alt": 2107.499222222222,
              "speed2d": 9.281111111111112,
              "speed3d": 9.441111111111113,
              "msFromStart": 56764.31044444443
            },
            {
              "lat": 43.757363177777776,
              "lng": -116.09324844444444,
              "alt": 2105.941333333333,
              "speed2d": 9.235444444444443,
              "speed3d": 9.377777777777778,
              "msFromStart": 57260.06940740741
            },
            {
              "lat": 43.75740365555555,
              "lng": -116.09325656666667,
              "alt": 2104.2305555555554,
              "speed2d": 9.253555555555554,
              "speed3d": 9.416666666666668,
              "msFromStart": 57755.63555555558
            },
            {
              "lat": 43.75744725555555,
              "lng": -116.09327298888888,
              "alt": 2102.612888888889,
              "speed2d": 9.749555555555556,
              "speed3d": 9.87111111111111,
              "msFromStart": 58250.88977777779
            },
            {
              "lat": 43.75749264444444,
              "lng": -116.09329651111109,
              "alt": 2101.2401111111108,
              "speed2d": 10.27111111111111,
              "speed3d": 10.38888888888889,
              "msFromStart": 58745.32488888887
            },
            {
              "lat": 43.75753927777778,
              "lng": -116.09332408888889,
              "alt": 2099.463888888889,
              "speed2d": 10.849666666666664,
              "speed3d": 11.00888888888889,
              "msFromStart": 59240.605465886925
            },
            {
              "lat": 43.757597866666664,
              "lng": -116.09334413333333,
              "alt": 2098.027222222222,
              "speed2d": 11.661444444444443,
              "speed3d": 11.745555555555555,
              "msFromStart": 59738.29789473681
            },
            {
              "lat": 43.75766598888889,
              "lng": -116.09335662222223,
              "alt": 2096.338444444444,
              "speed2d": 12.862444444444446,
              "speed3d": 12.943333333333332,
              "msFromStart": 60235.367750487305
            },
            {
              "lat": 43.7577304,
              "lng": -116.0933626,
              "alt": 2093.9734444444443,
              "speed2d": 13.692444444444442,
              "speed3d": 13.855555555555556,
              "msFromStart": 60729.439555555524
            },
            {
              "lat": 43.75779369999999,
              "lng": -116.09336029,
              "alt": 2091.2253,
              "speed2d": 14.029999999999998,
              "speed3d": 14.251000000000001,
              "msFromStart": 61250.6545333333
            },
            {
              "lat": 43.757865155555564,
              "lng": -116.09336108888891,
              "alt": 2088.9545555555555,
              "speed2d": 14.566888888888887,
              "speed3d": 14.803333333333333,
              "msFromStart": 61771.905777777756
            },
            {
              "lat": 43.75792753333332,
              "lng": -116.0933742,
              "alt": 2086.899,
              "speed2d": 14.590666666666666,
              "speed3d": 14.904444444444444,
              "msFromStart": 62265.60444444444
            },
            {
              "lat": 43.757986333333335,
              "lng": -116.09340111111112,
              "alt": 2084.9652222222226,
              "speed2d": 14.591777777777779,
              "speed3d": 14.874444444444443,
              "msFromStart": 62758.97377777779
            },
            {
              "lat": 43.75804841111111,
              "lng": -116.09343675555556,
              "alt": 2083.3,
              "speed2d": 14.878,
              "speed3d": 15.156666666666665,
              "msFromStart": 63252.97163352829
            },
            {
              "lat": 43.758108211111114,
              "lng": -116.09347598888888,
              "alt": 2081.478777777778,
              "speed2d": 15.021000000000003,
              "speed3d": 15.325555555555553,
              "msFromStart": 63748.72252631583
            },
            {
              "lat": 43.75816718888889,
              "lng": -116.09351222222223,
              "alt": 2079.5005555555554,
              "speed2d": 14.982333333333335,
              "speed3d": 15.312222222222221,
              "msFromStart": 64244.55176608189
            },
            {
              "lat": 43.75822633333333,
              "lng": -116.09353828888888,
              "alt": 2077.3315555555555,
              "speed2d": 14.914777777777779,
              "speed3d": 15.226666666666667,
              "msFromStart": 64740.325333333356
            },
            {
              "lat": 43.7582906,
              "lng": -116.09355209999998,
              "alt": 2074.392333333333,
              "speed2d": 15.340444444444444,
              "speed3d": 15.681111111111111,
              "msFromStart": 65236.011851851865
            },
            {
              "lat": 43.75835444444444,
              "lng": -116.09356242222222,
              "alt": 2071.5107777777775,
              "speed2d": 15.400777777777776,
              "speed3d": 15.83111111111111,
              "msFromStart": 65731.37244444442
            },
            {
              "lat": 43.75841546666667,
              "lng": -116.09357366666667,
              "alt": 2068.322777777778,
              "speed2d": 15.172666666666666,
              "speed3d": 15.652222222222221,
              "msFromStart": 66226.56859259261
            },
            {
              "lat": 43.75848356,
              "lng": -116.09359135,
              "alt": 2065.5411999999997,
              "speed2d": 15.268400000000002,
              "speed3d": 15.741000000000001,
              "msFromStart": 66748.71600000006
            },
            {
              "lat": 43.75855467777778,
              "lng": -116.09360960000001,
              "alt": 2062.389444444445,
              "speed2d": 15.802666666666667,
              "speed3d": 16.245555555555555,
              "msFromStart": 67271.00355555557
            },
            {
              "lat": 43.75863033333333,
              "lng": -116.09362666666667,
              "alt": 2059.6923333333334,
              "speed2d": 16.822333333333333,
              "speed3d": 17.20111111111111,
              "msFromStart": 67766.33599999992
            },
            {
              "lat": 43.75870201111111,
              "lng": -116.09362553333335,
              "alt": 2056.271111111111,
              "speed2d": 17.45777777777778,
              "speed3d": 17.993333333333332,
              "msFromStart": 68261.77377777775
            },
            {
              "lat": 43.75876933333333,
              "lng": -116.09361424444444,
              "alt": 2052.876,
              "speed2d": 17.197444444444447,
              "speed3d": 17.83888888888889,
              "msFromStart": 68757.42266666667
            },
            {
              "lat": 43.758832122222216,
              "lng": -116.0936008,
              "alt": 2049.5493333333334,
              "speed2d": 16.47911111111111,
              "speed3d": 17.212222222222223,
              "msFromStart": 69252.75730994152
            },
            {
              "lat": 43.75889632222222,
              "lng": -116.09359325555556,
              "alt": 2045.8623333333333,
              "speed2d": 16.08811111111111,
              "speed3d": 16.83111111111111,
              "msFromStart": 69747.20884210522
            },
            {
              "lat": 43.75896442222222,
              "lng": -116.09359505555555,
              "alt": 2042.7251111111113,
              "speed2d": 15.951555555555556,
              "speed3d": 16.726666666666667,
              "msFromStart": 70241.62481091617
            },
            {
              "lat": 43.75903274444445,
              "lng": -116.09360492222223,
              "alt": 2039.7701111111114,
              "speed2d": 15.786222222222221,
              "speed3d": 16.56,
              "msFromStart": 70736.08444444451
            },
            {
              "lat": 43.7591017,
              "lng": -116.09362688888889,
              "alt": 2036.942666666667,
              "speed2d": 15.806333333333335,
              "speed3d": 16.645555555555557,
              "msFromStart": 71230.53511111115
            },
            {
              "lat": 43.75916993,
              "lng": -116.09365479000002,
              "alt": 2034.6346,
              "speed2d": 15.4467,
              "speed3d": 16.244,
              "msFromStart": 71752.3986666667
            },
            {
              "lat": 43.75924306666667,
              "lng": -116.09368153333332,
              "alt": 2032.2236666666665,
              "speed2d": 15.814,
              "speed3d": 16.52777777777778,
              "msFromStart": 72274.55037037039
            },
            {
              "lat": 43.75931144444445,
              "lng": -116.09370387777778,
              "alt": 2029.0474444444446,
              "speed2d": 16.220777777777776,
              "speed3d": 16.962222222222223,
              "msFromStart": 72770.03244444451
            },
            {
              "lat": 43.75937675555555,
              "lng": -116.09372018888891,
              "alt": 2025.4343333333334,
              "speed2d": 16.17011111111111,
              "speed3d": 17.005555555555556,
              "msFromStart": 73265.14992592597
            },
            {
              "lat": 43.75944726666666,
              "lng": -116.09373075555555,
              "alt": 2021.8567777777776,
              "speed2d": 16.455555555555556,
              "speed3d": 17.22222222222222,
              "msFromStart": 73759.16400000006
            },
            {
              "lat": 43.759525350000004,
              "lng": -116.0937429,
              "alt": 2019.2939999999999,
              "speed2d": 17.189999999999998,
              "speed3d": 17.925,
              "msFromStart": 74226.17957894743
            },
            {
              "lat": 43.7596284,
              "lng": -116.09375883333333,
              "alt": 2016.167,
              "speed2d": 17.791666666666668,
              "speed3d": 18.526666666666667,
              "msFromStart": 74841.09066666674
            },
            {
              "lat": 43.75971825,
              "lng": -116.09377355000001,
              "alt": 2013.5325,
              "speed2d": 17.985500000000002,
              "speed3d": 18.665,
              "msFromStart": 75382.94844444442
            },
            {
              "lat": 43.75978988,
              "lng": -116.09378178,
              "alt": 2011.1026,
              "speed2d": 18.1318,
              "speed3d": 18.854,
              "msFromStart": 75840.6067555555
            },
            {
              "lat": 43.7598232,
              "lng": -116.0937844,
              "alt": 2010.3225,
              "speed2d": 18.09,
              "speed3d": 18.86,
              "msFromStart": 76044.62311111102
            },
            {
              "lat": 43.759929799999995,
              "lng": -116.0937814857143,
              "alt": 2008.0497142857143,
              "speed2d": 17.846714285714285,
              "speed3d": 18.562857142857144,
              "msFromStart": 76700.2011428571
            },
            {
              "lat": 43.760035359999996,
              "lng": -116.09376918,
              "alt": 2005.6166,
              "speed2d": 17.872,
              "speed3d": 18.592,
              "msFromStart": 77335.62666666665
            },
            {
              "lat": 43.76011306666667,
              "lng": -116.0937643,
              "alt": 2003.9378333333332,
              "speed2d": 17.389833333333335,
              "speed3d": 18.12666666666667,
              "msFromStart": 77820.76118518511
            },
            {
              "lat": 43.7601739,
              "lng": -116.09376803333333,
              "alt": 2002.4566666666667,
              "speed2d": 16.976666666666667,
              "speed3d": 17.676666666666666,
              "msFromStart": 78214.41263157895
            },
            {
              "lat": 43.7602543125,
              "lng": -116.0937875375,
              "alt": 1999.7792499999998,
              "speed2d": 16.382125000000002,
              "speed3d": 17.1175,
              "msFromStart": 78791.62526315787
            },
            {
              "lat": 43.760310942857146,
              "lng": -116.09380658571428,
              "alt": 1996.9945714285714,
              "speed2d": 15.974,
              "speed3d": 16.684285714285714,
              "msFromStart": 79266.92593483708
            },
            {
              "lat": 43.760367099999996,
              "lng": -116.0938276,
              "alt": 1994.2005,
              "speed2d": 16.0375,
              "speed3d": 16.71,
              "msFromStart": 79727.01533333331
            },
            {
              "lat": 43.760434075,
              "lng": -116.09384965,
              "alt": 1991.69425,
              "speed2d": 15.75525,
              "speed3d": 16.4375,
              "msFromStart": 80277.63199999998
            },
            {
              "lat": 43.76049906666667,
              "lng": -116.09387143333333,
              "alt": 1987.9636666666665,
              "speed2d": 15.20866666666667,
              "speed3d": 15.953333333333333,
              "msFromStart": 80855.71999999996
            },
            {
              "lat": 43.7605377,
              "lng": -116.0938808,
              "alt": 1986.2963333333332,
              "speed2d": 15.185666666666666,
              "speed3d": 15.89,
              "msFromStart": 81167.57955555552
            },
            {
              "lat": 43.760618775,
              "lng": -116.093901075,
              "alt": 1983.89275,
              "speed2d": 15.526,
              "speed3d": 16.1575,
              "msFromStart": 81735.68266666673
            },
            {
              "lat": 43.760682259999996,
              "lng": -116.09391550000001,
              "alt": 1982.8074000000001,
              "speed2d": 15.619999999999997,
              "speed3d": 16.278000000000002,
              "msFromStart": 82153.38471111118
            },
            {
              "lat": 43.760783225000004,
              "lng": -116.09393287500001,
              "alt": 1980.012375,
              "speed2d": 16.51025,
              "speed3d": 17.03125,
              "msFromStart": 82786.64588888887
            },
            {
              "lat": 43.76086037777778,
              "lng": -116.09394454444444,
              "alt": 1978.377111111111,
              "speed2d": 16.593111111111114,
              "speed3d": 17.198888888888888,
              "msFromStart": 83274.42696296297
            },
            {
              "lat": 43.76093654444445,
              "lng": -116.0939572,
              "alt": 1977.0587777777778,
              "speed2d": 16.398222222222223,
              "speed3d": 17.003333333333334,
              "msFromStart": 83769.4702222223
            },
            {
              "lat": 43.76098894,
              "lng": -116.09396962000001,
              "alt": 1975.7138,
              "speed2d": 15.9424,
              "speed3d": 16.59,
              "msFromStart": 84154.56348070179
            },
            {
              "lat": 43.76107783333334,
              "lng": -116.09399626666666,
              "alt": 1973.6406666666664,
              "speed2d": 15.602,
              "speed3d": 16.163333333333334,
              "msFromStart": 84797.11508771924
            },
            {
              "lat": 43.76113563333334,
              "lng": -116.09401663333333,
              "alt": 1972.2943333333333,
              "speed2d": 15.54,
              "speed3d": 16.10333333333333,
              "msFromStart": 85200.95791812862
            },
            {
              "lat": 43.761222620000005,
              "lng": -116.09405212,
              "alt": 1970.567,
              "speed2d": 15.702199999999998,
              "speed3d": 16.188,
              "msFromStart": 85793.49120000002
            },
            {
              "lat": 43.761284242857144,
              "lng": -116.09408045714285,
              "alt": 1968.9375714285713,
              "speed2d": 15.732,
              "speed3d": 16.277142857142856,
              "msFromStart": 86251.5500952381
            },
            {
              "lat": 43.76135,
              "lng": -116.09411265,
              "alt": 1967.82,
              "speed2d": 15.753499999999999,
              "speed3d": 16.21,
              "msFromStart": 86711.47066666663
            },
            {
              "lat": 43.761422249999995,
              "lng": -116.09415454999998,
              "alt": 1966.7044999999998,
              "speed2d": 15.792499999999999,
              "speed3d": 16.245,
              "msFromStart": 87220.66333333332
            },
            {
              "lat": 43.7615154,
              "lng": -116.09421149999999,
              "alt": 1965.2223333333332,
              "speed2d": 15.739666666666668,
              "speed3d": 16.183333333333334,
              "msFromStart": 87894.45866666667
            }
          ]
        }
      ]
    },
    {
      "id": "trail-coach",
      "pathType": "trail",
      "name": "Coach",
      "difficulty": "green",
      "videos": [
        {
          "videoId": "GH010036",
          "pathId": "trail-coach",
          "startSec": 0,
          "endSec": 80,
          "id": "video-segment-43",
          "gps": [
            {
              "lat": 43.76149412857143,
              "lng": -116.10420077142857,
              "alt": 1930.3634285714284,
              "speed2d": 0.39471428571428574,
              "speed3d": 0.4528571428571429,
              "msFromStart": 323.128
            },
            {
              "lat": 43.76149474444445,
              "lng": -116.1042020111111,
              "alt": 1930.324222222222,
              "speed2d": 0.2546666666666667,
              "speed3d": 0.29666666666666663,
              "msFromStart": 762.8346666666667
            },
            {
              "lat": 43.7614959,
              "lng": -116.10420250000001,
              "alt": 1930.4906666666666,
              "speed2d": 0.18455555555555556,
              "speed3d": 0.2011111111111111,
              "msFromStart": 1257.540962962963
            },
            {
              "lat": 43.76149747777778,
              "lng": -116.1042034111111,
              "alt": 1930.9906666666666,
              "speed2d": 0.23188888888888892,
              "speed3d": 0.2566666666666667,
              "msFromStart": 1752.3457777777771
            },
            {
              "lat": 43.76149812222223,
              "lng": -116.10420484444444,
              "alt": 1931.1592222222223,
              "speed2d": 0.21166666666666664,
              "speed3d": 0.23444444444444446,
              "msFromStart": 2247.4401851851844
            },
            {
              "lat": 43.76149973333332,
              "lng": -116.1042042111111,
              "alt": 1931.1164444444446,
              "speed2d": 0.22266666666666668,
              "speed3d": 0.2222222222222222,
              "msFromStart": 2743.3065555555545
            },
            {
              "lat": 43.76149958888889,
              "lng": -116.10420080000002,
              "alt": 1930.9852222222223,
              "speed2d": 0.469,
              "speed3d": 0.4588888888888889,
              "msFromStart": 3238.8078148148143
            },
            {
              "lat": 43.76149771111111,
              "lng": -116.10420173333334,
              "alt": 1930.9513333333332,
              "speed2d": 0.4421111111111111,
              "speed3d": 0.44111111111111106,
              "msFromStart": 3733.2077777777763
            },
            {
              "lat": 43.7614945,
              "lng": -116.10420113333333,
              "alt": 1931.0855555555554,
              "speed2d": 0.6414444444444445,
              "speed3d": 0.6122222222222222,
              "msFromStart": 4227.48028265107
            },
            {
              "lat": 43.76149092,
              "lng": -116.10420344,
              "alt": 1931.1339,
              "speed2d": 0.891,
              "speed3d": 0.874,
              "msFromStart": 4749.012710526314
            },
            {
              "lat": 43.76148662222222,
              "lng": -116.10420738888888,
              "alt": 1930.997333333333,
              "speed2d": 0.9860000000000001,
              "speed3d": 0.9833333333333333,
              "msFromStart": 5270.944853801169
            },
            {
              "lat": 43.761481377777784,
              "lng": -116.10420823333334,
              "alt": 1930.706222222222,
              "speed2d": 1.235111111111111,
              "speed3d": 1.211111111111111,
              "msFromStart": 5766.540333333336
            },
            {
              "lat": 43.76147648888888,
              "lng": -116.10421205555554,
              "alt": 1930.5227777777777,
              "speed2d": 1.2703333333333333,
              "speed3d": 1.3344444444444445,
              "msFromStart": 6262.41690740741
            },
            {
              "lat": 43.76147241111111,
              "lng": -116.10421369999999,
              "alt": 1930.6842222222222,
              "speed2d": 1.2152222222222222,
              "speed3d": 1.0611111111111111,
              "msFromStart": 6758.897611111114
            },
            {
              "lat": 43.76146177777778,
              "lng": -116.1042181888889,
              "alt": 1930.458333333333,
              "speed2d": 1.8917777777777778,
              "speed3d": 1.942222222222222,
              "msFromStart": 7254.889518518519
            },
            {
              "lat": 43.761455355555555,
              "lng": -116.10422144444443,
              "alt": 1930.3365555555556,
              "speed2d": 1.6275555555555554,
              "speed3d": 1.6588888888888889,
              "msFromStart": 7749.468388888889
            },
            {
              "lat": 43.76145270000001,
              "lng": -116.10422410000001,
              "alt": 1930.0697777777775,
              "speed2d": 1.162,
              "speed3d": 1.2033333333333334,
              "msFromStart": 8244.384203703703
            },
            {
              "lat": 43.76145038888889,
              "lng": -116.10422574444445,
              "alt": 1929.7868888888893,
              "speed2d": 0.8102222222222222,
              "speed3d": 0.8755555555555555,
              "msFromStart": 8740.416444444443
            },
            {
              "lat": 43.76144703333334,
              "lng": -116.10422852222226,
              "alt": 1929.4036666666666,
              "speed2d": 1.0013333333333332,
              "speed3d": 1.017777777777778,
              "msFromStart": 9235.868111111111
            },
            {
              "lat": 43.761443477777775,
              "lng": -116.10423105555554,
              "alt": 1928.934777777778,
              "speed2d": 1.06,
              "speed3d": 1.0933333333333333,
              "msFromStart": 9729.584444444448
            },
            {
              "lat": 43.76143761,
              "lng": -116.10423461000002,
              "alt": 1928.4608,
              "speed2d": 1.3551,
              "speed3d": 1.379,
              "msFromStart": 10250.883107017544
            },
            {
              "lat": 43.761432577777775,
              "lng": -116.10424035555556,
              "alt": 1928.0636666666664,
              "speed2d": 1.269111111111111,
              "speed3d": 1.346666666666667,
              "msFromStart": 10772.753315789463
            },
            {
              "lat": 43.76142535555556,
              "lng": -116.10424593333332,
              "alt": 1927.51,
              "speed2d": 1.7663333333333333,
              "speed3d": 1.7455555555555555,
              "msFromStart": 11267.283880116956
            },
            {
              "lat": 43.76141463333334,
              "lng": -116.10425444444445,
              "alt": 1927.1652222222224,
              "speed2d": 2.2493333333333334,
              "speed3d": 2.3000000000000003,
              "msFromStart": 11762.090833333328
            },
            {
              "lat": 43.76140334444444,
              "lng": -116.10426401111111,
              "alt": 1926.725888888889,
              "speed2d": 2.810888888888889,
              "speed3d": 2.727777777777778,
              "msFromStart": 12256.911166666665
            },
            {
              "lat": 43.76138694444444,
              "lng": -116.10427808888889,
              "alt": 1926.668888888889,
              "speed2d": 3.477555555555555,
              "speed3d": 3.5211111111111117,
              "msFromStart": 12751.728333333327
            },
            {
              "lat": 43.761374366666665,
              "lng": -116.10429123333333,
              "alt": 1926.6984444444447,
              "speed2d": 3.3056666666666668,
              "speed3d": 3.329999999999999,
              "msFromStart": 13246.736277777774
            },
            {
              "lat": 43.76135894444444,
              "lng": -116.10430735555556,
              "alt": 1926.8866666666668,
              "speed2d": 3.8737777777777778,
              "speed3d": 3.805555555555556,
              "msFromStart": 13742.262499999997
            },
            {
              "lat": 43.76134038888889,
              "lng": -116.10432492222222,
              "alt": 1926.7405555555556,
              "speed2d": 4.382333333333333,
              "speed3d": 4.355555555555555,
              "msFromStart": 14237.468333333332
            },
            {
              "lat": 43.76132296666667,
              "lng": -116.10434246666668,
              "alt": 1926.2477777777779,
              "speed2d": 4.763000000000001,
              "speed3d": 4.740000000000001,
              "msFromStart": 14731.730500000003
            },
            {
              "lat": 43.761303700000006,
              "lng": -116.1043608111111,
              "alt": 1925.9108888888886,
              "speed2d": 5.070222222222222,
              "speed3d": 5.07,
              "msFromStart": 15225.937903508773
            },
            {
              "lat": 43.76127983,
              "lng": -116.10438437999998,
              "alt": 1925.9100000000003,
              "speed2d": 5.5684000000000005,
              "speed3d": 5.5489999999999995,
              "msFromStart": 15747.586631578943
            },
            {
              "lat": 43.76125781111111,
              "lng": -116.10440554444446,
              "alt": 1925.427888888889,
              "speed2d": 5.802555555555556,
              "speed3d": 5.754444444444445,
              "msFromStart": 16268.347746588692
            },
            {
              "lat": 43.76123173333333,
              "lng": -116.10443226666666,
              "alt": 1925.3435555555557,
              "speed2d": 6.628222222222222,
              "speed3d": 6.527777777777777,
              "msFromStart": 16759.246444444452
            },
            {
              "lat": 43.76120501111111,
              "lng": -116.10446105555556,
              "alt": 1925.1833333333334,
              "speed2d": 7.159,
              "speed3d": 7.185555555555555,
              "msFromStart": 17258.962370370376
            },
            {
              "lat": 43.761183225,
              "lng": -116.10448606249999,
              "alt": 1924.761,
              "speed2d": 7.109499999999999,
              "speed3d": 7.1175,
              "msFromStart": 17753.773000000012
            },
            {
              "lat": 43.761158933333334,
              "lng": -116.10451457777776,
              "alt": 1924.4250000000002,
              "speed2d": 7.252555555555555,
              "speed3d": 7.264444444444445,
              "msFromStart": 18245.204405228764
            },
            {
              "lat": 43.761134466666675,
              "lng": -116.10454587777777,
              "alt": 1923.896777777778,
              "speed2d": 7.360444444444444,
              "speed3d": 7.35888888888889,
              "msFromStart": 18747.189764705876
            },
            {
              "lat": 43.76110978888889,
              "lng": -116.10457912222222,
              "alt": 1923.4285555555555,
              "speed2d": 7.679444444444445,
              "speed3d": 7.642222222222222,
              "msFromStart": 19245.782625386997
            },
            {
              "lat": 43.76108374444445,
              "lng": -116.10461740000001,
              "alt": 1923.3094444444444,
              "speed2d": 8.027222222222221,
              "speed3d": 8.02888888888889,
              "msFromStart": 19740.223368421062
            },
            {
              "lat": 43.76105754444445,
              "lng": -116.10465898888887,
              "alt": 1923.2823333333336,
              "speed2d": 8.442333333333334,
              "speed3d": 8.423333333333334,
              "msFromStart": 20234.557576998057
            },
            {
              "lat": 43.761034433333336,
              "lng": -116.10469871111111,
              "alt": 1922.867,
              "speed2d": 8.401222222222223,
              "speed3d": 8.434444444444443,
              "msFromStart": 20729.339777777783
            },
            {
              "lat": 43.76100995000001,
              "lng": -116.10474302,
              "alt": 1922.2325000000003,
              "speed2d": 8.8001,
              "speed3d": 8.765,
              "msFromStart": 21251.428266666673
            },
            {
              "lat": 43.76098437777778,
              "lng": -116.10479015555556,
              "alt": 1921.6341111111115,
              "speed2d": 9.072444444444445,
              "speed3d": 9.088888888888889,
              "msFromStart": 21772.775555555563
            },
            {
              "lat": 43.76096254444444,
              "lng": -116.10483455555556,
              "alt": 1921.0506666666665,
              "speed2d": 9.130333333333333,
              "speed3d": 9.164444444444444,
              "msFromStart": 22267.05814814815
            },
            {
              "lat": 43.760941977777776,
              "lng": -116.10487900000001,
              "alt": 1920.3161111111108,
              "speed2d": 9.094555555555555,
              "speed3d": 9.136666666666667,
              "msFromStart": 22762.525777777777
            },
            {
              "lat": 43.76092084444444,
              "lng": -116.10492513333332,
              "alt": 1919.8075555555556,
              "speed2d": 9.180222222222222,
              "speed3d": 9.206666666666667,
              "msFromStart": 23258.26474074074
            },
            {
              "lat": 43.76089938888889,
              "lng": -116.10497301111111,
              "alt": 1919.674111111111,
              "speed2d": 9.100777777777779,
              "speed3d": 9.104444444444445,
              "msFromStart": 23754.570888888877
            },
            {
              "lat": 43.76087767777778,
              "lng": -116.10502498888889,
              "alt": 1919.9115555555559,
              "speed2d": 9.226777777777777,
              "speed3d": 9.255555555555553,
              "msFromStart": 24251.261255360623
            },
            {
              "lat": 43.760858855555554,
              "lng": -116.10507617777776,
              "alt": 1919.8912222222223,
              "speed2d": 9.211777777777778,
              "speed3d": 9.247777777777777,
              "msFromStart": 24748.913473684224
            },
            {
              "lat": 43.76084356666667,
              "lng": -116.10512756666667,
              "alt": 1919.8122222222219,
              "speed2d": 9.00111111111111,
              "speed3d": 9.042222222222223,
              "msFromStart": 25245.711671826626
            },
            {
              "lat": 43.760831466666666,
              "lng": -116.1051774,
              "alt": 1919.5353333333333,
              "speed2d": 8.795777777777777,
              "speed3d": 8.837777777777777,
              "msFromStart": 25738.89129411763
            },
            {
              "lat": 43.760824155555554,
              "lng": -116.10522797777779,
              "alt": 1919.374111111111,
              "speed2d": 8.390222222222222,
              "speed3d": 8.447777777777777,
              "msFromStart": 26231.922858617123
            },
            {
              "lat": 43.760825000000004,
              "lng": -116.10528176999999,
              "alt": 1919.0814,
              "speed2d": 8.1563,
              "speed3d": 8.229,
              "msFromStart": 26752.95931578946
            },
            {
              "lat": 43.760828522222226,
              "lng": -116.10533133333334,
              "alt": 1918.6745555555556,
              "speed2d": 7.719666666666667,
              "speed3d": 7.7200000000000015,
              "msFromStart": 27274.091280701752
            },
            {
              "lat": 43.76084264444445,
              "lng": -116.10538633333331,
              "alt": 1918.8541111111113,
              "speed2d": 8.379777777777777,
              "speed3d": 8.357777777777779,
              "msFromStart": 27767.975000000006
            },
            {
              "lat": 43.76086053333333,
              "lng": -116.10543050000001,
              "alt": 1918.0874444444448,
              "speed2d": 8.24611111111111,
              "speed3d": 8.3,
              "msFromStart": 28262.108333333334
            },
            {
              "lat": 43.76087981111111,
              "lng": -116.10547067777779,
              "alt": 1917.7251111111113,
              "speed2d": 7.849777777777778,
              "speed3d": 7.916666666666666,
              "msFromStart": 28756.894
            },
            {
              "lat": 43.760901000000004,
              "lng": -116.10550728888889,
              "alt": 1917.3734444444444,
              "speed2d": 7.600777777777778,
              "speed3d": 7.658888888888889,
              "msFromStart": 29251.752315789472
            },
            {
              "lat": 43.76092254444445,
              "lng": -116.10554075555557,
              "alt": 1916.9205555555557,
              "speed2d": 7.332333333333333,
              "speed3d": 7.403333333333332,
              "msFromStart": 29746.714631578936
            },
            {
              "lat": 43.760943455555555,
              "lng": -116.10557233333333,
              "alt": 1916.6883333333333,
              "speed2d": 7.07,
              "speed3d": 7.133333333333333,
              "msFromStart": 30241.72116374268
            },
            {
              "lat": 43.760967166666674,
              "lng": -116.10560493333332,
              "alt": 1916.6342222222224,
              "speed2d": 7.235777777777778,
              "speed3d": 7.23,
              "msFromStart": 30736.882666666672
            },
            {
              "lat": 43.76099454444444,
              "lng": -116.10563378888891,
              "alt": 1916.2795555555556,
              "speed2d": 7.281333333333334,
              "speed3d": 7.330000000000001,
              "msFromStart": 31232.085592592593
            },
            {
              "lat": 43.7610214,
              "lng": -116.10565827777776,
              "alt": 1916.2072222222223,
              "speed2d": 7.183444444444444,
              "speed3d": 7.224444444444445,
              "msFromStart": 31727.39222222221
            },
            {
              "lat": 43.76105103,
              "lng": -116.10568239999999,
              "alt": 1916.0858,
              "speed2d": 7.1752,
              "speed3d": 7.216000000000001,
              "msFromStart": 32250.109699999994
            },
            {
              "lat": 43.76108084444444,
              "lng": -116.10570212222221,
              "alt": 1915.504444444444,
              "speed2d": 7.058777777777777,
              "speed3d": 7.109999999999999,
              "msFromStart": 32772.44788888889
            },
            {
              "lat": 43.76110935555556,
              "lng": -116.10571826666667,
              "alt": 1915.2877777777778,
              "speed2d": 6.986333333333334,
              "speed3d": 7.045555555555555,
              "msFromStart": 33267.23255555554
            },
            {
              "lat": 43.76113677777778,
              "lng": -116.10572833333333,
              "alt": 1915.2673333333335,
              "speed2d": 6.778666666666666,
              "speed3d": 6.827777777777777,
              "msFromStart": 33761.93488888886
            },
            {
              "lat": 43.76116807777778,
              "lng": -116.10574079999999,
              "alt": 1915.5043333333333,
              "speed2d": 6.911444444444444,
              "speed3d": 6.91,
              "msFromStart": 34256.888518518506
            },
            {
              "lat": 43.76120291111111,
              "lng": -116.10574813333332,
              "alt": 1915.5045555555555,
              "speed2d": 7.201666666666666,
              "speed3d": 7.217777777777778,
              "msFromStart": 34752.53600000001
            },
            {
              "lat": 43.76123784444444,
              "lng": -116.10575037777778,
              "alt": 1915.3524444444442,
              "speed2d": 7.2796666666666665,
              "speed3d": 7.316666666666668,
              "msFromStart": 35248.197407407424
            },
            {
              "lat": 43.76127197777778,
              "lng": -116.10574617777776,
              "alt": 1914.948888888889,
              "speed2d": 7.240777777777777,
              "speed3d": 7.28888888888889,
              "msFromStart": 35743.768444444475
            },
            {
              "lat": 43.76130074444444,
              "lng": -116.10573474444446,
              "alt": 1914.0565555555554,
              "speed2d": 6.771555555555555,
              "speed3d": 6.922222222222222,
              "msFromStart": 36239.07192592595
            },
            {
              "lat": 43.761327022222225,
              "lng": -116.10572161111111,
              "alt": 1913.8025555555555,
              "speed2d": 6.502555555555555,
              "speed3d": 6.6066666666666665,
              "msFromStart": 36733.34133333336
            },
            {
              "lat": 43.761349377777776,
              "lng": -116.1057051111111,
              "alt": 1913.7566666666667,
              "speed2d": 6.05111111111111,
              "speed3d": 6.191111111111111,
              "msFromStart": 37227.70622222223
            },
            {
              "lat": 43.76137474,
              "lng": -116.10568557,
              "alt": 1914.0087,
              "speed2d": 5.9909,
              "speed3d": 6.07,
              "msFromStart": 37750.379999999976
            },
            {
              "lat": 43.76139864444444,
              "lng": -116.1056624,
              "alt": 1914.673111111111,
              "speed2d": 5.874777777777779,
              "speed3d": 5.901111111111112,
              "msFromStart": 38273.18103703703
            },
            {
              "lat": 43.76142997777777,
              "lng": -116.10564276666668,
              "alt": 1914.9207777777776,
              "speed2d": 6.211333333333333,
              "speed3d": 6.214444444444444,
              "msFromStart": 38768.59822222222
            },
            {
              "lat": 43.7614609,
              "lng": -116.10562302222222,
              "alt": 1915.201111111111,
              "speed2d": 6.461777777777778,
              "speed3d": 6.461111111111111,
              "msFromStart": 39264.073910331375
            },
            {
              "lat": 43.76149293333334,
              "lng": -116.10560344444444,
              "alt": 1915.6233333333332,
              "speed2d": 6.553111111111111,
              "speed3d": 6.564444444444445,
              "msFromStart": 39759.689894736824
            },
            {
              "lat": 43.76152837777778,
              "lng": -116.10558413333334,
              "alt": 1916.2152222222226,
              "speed2d": 6.87188888888889,
              "speed3d": 6.827777777777777,
              "msFromStart": 40254.951898635474
            },
            {
              "lat": 43.76156792222221,
              "lng": -116.10556545555556,
              "alt": 1917.147888888889,
              "speed2d": 7.395777777777777,
              "speed3d": 7.364444444444445,
              "msFromStart": 40748.768444444475
            },
            {
              "lat": 43.76160410000001,
              "lng": -116.10554572222222,
              "alt": 1918.2164444444443,
              "speed2d": 7.502555555555555,
              "speed3d": 7.488888888888888,
              "msFromStart": 41242.89822222225
            },
            {
              "lat": 43.76163754444444,
              "lng": -116.10552615555554,
              "alt": 1918.6585555555555,
              "speed2d": 7.541888888888889,
              "speed3d": 7.554444444444444,
              "msFromStart": 41738.859111111124
            },
            {
              "lat": 43.76167105555556,
              "lng": -116.10550476666666,
              "alt": 1919.4022222222222,
              "speed2d": 7.538333333333333,
              "speed3d": 7.544444444444444,
              "msFromStart": 42234.84977777778
            },
            {
              "lat": 43.761704522222225,
              "lng": -116.10548295555556,
              "alt": 1920.158777777778,
              "speed2d": 7.4801111111111105,
              "speed3d": 7.513333333333333,
              "msFromStart": 42730.22711111109
            },
            {
              "lat": 43.7617394,
              "lng": -116.10546087777777,
              "alt": 1921.2807777777778,
              "speed2d": 7.308888888888888,
              "speed3d": 7.344444444444445,
              "msFromStart": 43225.39718518517
            },
            {
              "lat": 43.76177472999999,
              "lng": -116.10543614,
              "alt": 1922.3826,
              "speed2d": 7.286200000000001,
              "speed3d": 7.3069999999999995,
              "msFromStart": 43747.45733333332
            },
            {
              "lat": 43.76180939999999,
              "lng": -116.10541087777777,
              "alt": 1923.7483333333334,
              "speed2d": 7.099222222222222,
              "speed3d": 7.1933333333333325,
              "msFromStart": 44269.756222222226
            },
            {
              "lat": 43.76183821111111,
              "lng": -116.10538821111112,
              "alt": 1924.5384444444442,
              "speed2d": 6.5263333333333335,
              "speed3d": 6.634444444444444,
              "msFromStart": 44765.38777777781
            },
            {
              "lat": 43.76186666666666,
              "lng": -116.10536851111112,
              "alt": 1924.8297777777777,
              "speed2d": 6.473888888888889,
              "speed3d": 6.530000000000001,
              "msFromStart": 45260.79959844054
            },
            {
              "lat": 43.76189540000001,
              "lng": -116.10535113333334,
              "alt": 1925.1893333333333,
              "speed2d": 6.228888888888889,
              "speed3d": 6.2733333333333325,
              "msFromStart": 45755.499789473644
            },
            {
              "lat": 43.76192362222222,
              "lng": -116.10533662222224,
              "alt": 1924.9937777777777,
              "speed2d": 6.2411111111111115,
              "speed3d": 6.304444444444445,
              "msFromStart": 46250.084982456116
            },
            {
              "lat": 43.761944066666665,
              "lng": -116.10532541111111,
              "alt": 1924.0537777777777,
              "speed2d": 5.585333333333333,
              "speed3d": 5.665555555555557,
              "msFromStart": 46744.35999999997
            },
            {
              "lat": 43.76196396666667,
              "lng": -116.10531392222224,
              "alt": 1923.076111111111,
              "speed2d": 5.315444444444445,
              "speed3d": 5.347777777777778,
              "msFromStart": 47238.759185185176
            },
            {
              "lat": 43.76198244444444,
              "lng": -116.10530429999999,
              "alt": 1922.0531111111113,
              "speed2d": 4.881777777777779,
              "speed3d": 4.907777777777778,
              "msFromStart": 47733.77911111111
            },
            {
              "lat": 43.76200419999999,
              "lng": -116.10529368888888,
              "alt": 1921.1616666666669,
              "speed2d": 5.056777777777778,
              "speed3d": 5.071111111111112,
              "msFromStart": 48228.93074074074
            },
            {
              "lat": 43.762028120000004,
              "lng": -116.10528273,
              "alt": 1920.5924,
              "speed2d": 5.1118,
              "speed3d": 5.102,
              "msFromStart": 48751.886333333314
            },
            {
              "lat": 43.76205196666666,
              "lng": -116.10527119999999,
              "alt": 1919.2089999999998,
              "speed2d": 5.610888888888889,
              "speed3d": 5.625555555555556,
              "msFromStart": 49274.67844444444
            },
            {
              "lat": 43.762075244444446,
              "lng": -116.10525812222221,
              "alt": 1917.9972222222223,
              "speed2d": 5.859222222222223,
              "speed3d": 5.957777777777777,
              "msFromStart": 49769.425777777804
            },
            {
              "lat": 43.76209707777778,
              "lng": -116.10524164444446,
              "alt": 1916.8617777777777,
              "speed2d": 5.885444444444445,
              "speed3d": 6.013333333333334,
              "msFromStart": 50264.33528265109
            },
            {
              "lat": 43.762118788888884,
              "lng": -116.10522273333333,
              "alt": 1915.739111111111,
              "speed2d": 5.957,
              "speed3d": 6.1,
              "msFromStart": 50759.75915789474
            },
            {
              "lat": 43.762136222222225,
              "lng": -116.10520220000001,
              "alt": 1914.2310000000002,
              "speed2d": 5.698555555555555,
              "speed3d": 5.927777777777778,
              "msFromStart": 51255.13067056531
            },
            {
              "lat": 43.76215845555556,
              "lng": -116.1051801888889,
              "alt": 1913.2039999999997,
              "speed2d": 6.057222222222222,
              "speed3d": 6.236666666666667,
              "msFromStart": 51750.18444444443
            },
            {
              "lat": 43.76218168888889,
              "lng": -116.10515802222221,
              "alt": 1912.2675555555552,
              "speed2d": 6.215777777777778,
              "speed3d": 6.437777777777778,
              "msFromStart": 52245.18088888889
            },
            {
              "lat": 43.76220351111111,
              "lng": -116.1051365,
              "alt": 1911.0094444444446,
              "speed2d": 6.202666666666667,
              "speed3d": 6.446666666666667,
              "msFromStart": 52740.07511111113
            },
            {
              "lat": 43.76222814444444,
              "lng": -116.10511225555555,
              "alt": 1910.243111111111,
              "speed2d": 6.422000000000001,
              "speed3d": 6.628888888888889,
              "msFromStart": 53235.13429629631
            },
            {
              "lat": 43.76224963333334,
              "lng": -116.10508842222222,
              "alt": 1909.0735555555557,
              "speed2d": 6.236333333333333,
              "speed3d": 6.554444444444445,
              "msFromStart": 53730.89422222221
            },
            {
              "lat": 43.762271411111115,
              "lng": -116.10506323333334,
              "alt": 1908.233111111111,
              "speed2d": 6.2508888888888885,
              "speed3d": 6.481111111111111,
              "msFromStart": 54226.4682962963
            },
            {
              "lat": 43.76229697,
              "lng": -116.10503655000001,
              "alt": 1907.5935,
              "speed2d": 6.3687000000000005,
              "speed3d": 6.602,
              "msFromStart": 54748.48800000002
            },
            {
              "lat": 43.762322922222225,
              "lng": -116.10500997777777,
              "alt": 1906.8948888888888,
              "speed2d": 6.454,
              "speed3d": 6.6722222222222225,
              "msFromStart": 55270.47328654971
            },
            {
              "lat": 43.76234932222222,
              "lng": -116.10498419999999,
              "alt": 1906.4415555555556,
              "speed2d": 6.596444444444445,
              "speed3d": 6.806666666666667,
              "msFromStart": 55765.173684210495
            },
            {
              "lat": 43.76237495555556,
              "lng": -116.10495843333334,
              "alt": 1905.7464444444445,
              "speed2d": 6.717888888888889,
              "speed3d": 6.895555555555555,
              "msFromStart": 56260.04414035085
            },
            {
              "lat": 43.7624014,
              "lng": -116.1049320888889,
              "alt": 1904.9858888888891,
              "speed2d": 6.917,
              "speed3d": 7.081111111111112,
              "msFromStart": 56755.555999999975
            },
            {
              "lat": 43.76243038888889,
              "lng": -116.10490624444444,
              "alt": 1904.3496666666665,
              "speed2d": 7.231888888888889,
              "speed3d": 7.376666666666667,
              "msFromStart": 57250.59644444443
            },
            {
              "lat": 43.762462533333334,
              "lng": -116.10487950000001,
              "alt": 1904.123,
              "speed2d": 7.537111111111112,
              "speed3d": 7.678888888888888,
              "msFromStart": 57743.49466666665
            },
            {
              "lat": 43.76249493333334,
              "lng": -116.10485303333334,
              "alt": 1903.9925555555556,
              "speed2d": 7.702777777777778,
              "speed3d": 7.802222222222222,
              "msFromStart": 58236.914592592584
            },
            {
              "lat": 43.76252774444444,
              "lng": -116.10482574444444,
              "alt": 1903.7656666666667,
              "speed2d": 7.951666666666666,
              "speed3d": 8.024444444444445,
              "msFromStart": 58733.27822222219
            },
            {
              "lat": 43.76256233333333,
              "lng": -116.1047962222222,
              "alt": 1904.0163333333335,
              "speed2d": 8.159222222222223,
              "speed3d": 8.234444444444444,
              "msFromStart": 59229.761407407386
            },
            {
              "lat": 43.76259813333334,
              "lng": -116.10476548888889,
              "alt": 1904.2977777777778,
              "speed2d": 8.572444444444443,
              "speed3d": 8.581111111111111,
              "msFromStart": 59725.54533333335
            },
            {
              "lat": 43.76263665,
              "lng": -116.10473405,
              "alt": 1904.1325000000004,
              "speed2d": 8.851500000000001,
              "speed3d": 8.896999999999998,
              "msFromStart": 60249.06566315792
            },
            {
              "lat": 43.76267225555556,
              "lng": -116.10470171111112,
              "alt": 1904.006222222222,
              "speed2d": 8.812333333333333,
              "speed3d": 8.864444444444443,
              "msFromStart": 60773.44947368421
            },
            {
              "lat": 43.76270552222222,
              "lng": -116.10466938888888,
              "alt": 1903.9874444444442,
              "speed2d": 8.894222222222224,
              "speed3d": 8.944444444444445,
              "msFromStart": 61269.80801169591
            },
            {
              "lat": 43.76273432222222,
              "lng": -116.10463529999998,
              "alt": 1903.6055555555554,
              "speed2d": 8.729555555555555,
              "speed3d": 8.784444444444444,
              "msFromStart": 61764.17333333336
            },
            {
              "lat": 43.76276656666667,
              "lng": -116.10459917777777,
              "alt": 1903.454777777778,
              "speed2d": 8.912555555555556,
              "speed3d": 8.913333333333334,
              "msFromStart": 62258.45377777779
            },
            {
              "lat": 43.76279878888889,
              "lng": -116.10456124444445,
              "alt": 1903.5055555555555,
              "speed2d": 9.121666666666666,
              "speed3d": 9.16,
              "msFromStart": 62753.192000000025
            },
            {
              "lat": 43.76282607777778,
              "lng": -116.10452248888886,
              "alt": 1903.0107777777778,
              "speed2d": 9.075666666666667,
              "speed3d": 9.11111111111111,
              "msFromStart": 63248.03933333335
            },
            {
              "lat": 43.76285356666667,
              "lng": -116.10448322222223,
              "alt": 1902.5911111111113,
              "speed2d": 9.088666666666667,
              "speed3d": 9.111111111111112,
              "msFromStart": 63743.140000000036
            },
            {
              "lat": 43.7628803,
              "lng": -116.10444246666665,
              "alt": 1902.3105555555555,
              "speed2d": 9.104111111111111,
              "speed3d": 9.134444444444444,
              "msFromStart": 64237.96822222225
            },
            {
              "lat": 43.762904822222225,
              "lng": -116.10440102222219,
              "alt": 1901.7474444444447,
              "speed2d": 9.067,
              "speed3d": 9.102222222222222,
              "msFromStart": 64731.60533333337
            },
            {
              "lat": 43.76292824444444,
              "lng": -116.10435901111113,
              "alt": 1901.0638888888886,
              "speed2d": 9.04577777777778,
              "speed3d": 9.083333333333332,
              "msFromStart": 65225.346900584824
            },
            {
              "lat": 43.762952719999994,
              "lng": -116.10431455999999,
              "alt": 1900.2430000000002,
              "speed2d": 8.940700000000001,
              "speed3d": 9.029,
              "msFromStart": 65747.46747368421
            },
            {
              "lat": 43.762978177777775,
              "lng": -116.10426998888889,
              "alt": 1899.5764444444442,
              "speed2d": 8.927222222222223,
              "speed3d": 8.941111111111109,
              "msFromStart": 66269.4494619883
            },
            {
              "lat": 43.76300546666667,
              "lng": -116.10422572222222,
              "alt": 1899.4048888888888,
              "speed2d": 9.206444444444447,
              "speed3d": 9.233333333333334,
              "msFromStart": 66763.03466666669
            },
            {
              "lat": 43.76303346666667,
              "lng": -116.10418297777778,
              "alt": 1898.7631111111114,
              "speed2d": 9.416999999999998,
              "speed3d": 9.426666666666668,
              "msFromStart": 67257.10607407408
            },
            {
              "lat": 43.763063377777776,
              "lng": -116.10413796666666,
              "alt": 1898.5942222222222,
              "speed2d": 9.656777777777778,
              "speed3d": 9.674444444444445,
              "msFromStart": 67753.48711111108
            },
            {
              "lat": 43.76309093333334,
              "lng": -116.10409332222221,
              "alt": 1897.9725555555553,
              "speed2d": 9.719777777777779,
              "speed3d": 9.75,
              "msFromStart": 68249.73762962961
            },
            {
              "lat": 43.76311848888889,
              "lng": -116.1040497,
              "alt": 1897.1528888888888,
              "speed2d": 9.652999999999999,
              "speed3d": 9.732222222222221,
              "msFromStart": 68744.54222222224
            },
            {
              "lat": 43.763143355555556,
              "lng": -116.10400744444443,
              "alt": 1895.9625555555554,
              "speed2d": 9.51811111111111,
              "speed3d": 9.564444444444444,
              "msFromStart": 69239.2740740741
            },
            {
              "lat": 43.763167288888894,
              "lng": -116.10396272222222,
              "alt": 1894.932111111111,
              "speed2d": 9.461000000000002,
              "speed3d": 9.537777777777778,
              "msFromStart": 69734.29333333336
            },
            {
              "lat": 43.76319044444445,
              "lng": -116.10391747777777,
              "alt": 1893.9603333333332,
              "speed2d": 9.338555555555557,
              "speed3d": 9.423333333333334,
              "msFromStart": 70229.38156725146
            },
            {
              "lat": 43.76321388,
              "lng": -116.10386566,
              "alt": 1893.2224,
              "speed2d": 9.4008,
              "speed3d": 9.474000000000002,
              "msFromStart": 70752.14547368414
            },
            {
              "lat": 43.76323717777778,
              "lng": -116.10381476666666,
              "alt": 1892.6190000000001,
              "speed2d": 9.286444444444445,
              "speed3d": 9.37888888888889,
              "msFromStart": 71274.71857309937
            },
            {
              "lat": 43.76325722222222,
              "lng": -116.10376680000002,
              "alt": 1891.8366666666666,
              "speed2d": 9.106777777777777,
              "speed3d": 9.22111111111111,
              "msFromStart": 71768.94933333328
            },
            {
              "lat": 43.763276977777785,
              "lng": -116.10371966666668,
              "alt": 1891.107,
              "speed2d": 8.991888888888889,
              "speed3d": 9.07,
              "msFromStart": 72263.26770370368
            },
            {
              "lat": 43.76329643333332,
              "lng": -116.10367083333333,
              "alt": 1890.4335555555554,
              "speed2d": 9.026333333333334,
              "speed3d": 9.11,
              "msFromStart": 72758.26755555553
            },
            {
              "lat": 43.763317155555555,
              "lng": -116.10362125555554,
              "alt": 1889.9997777777776,
              "speed2d": 9.129333333333332,
              "speed3d": 9.194444444444445,
              "msFromStart": 73253.6222222222
            },
            {
              "lat": 43.76333595555556,
              "lng": -116.10357289999999,
              "alt": 1889.269,
              "speed2d": 9.029,
              "speed3d": 9.135555555555557,
              "msFromStart": 73750.12355555558
            },
            {
              "lat": 43.763353300000006,
              "lng": -116.10352689999999,
              "alt": 1888.3513333333333,
              "speed2d": 8.797666666666666,
              "speed3d": 8.931111111111111,
              "msFromStart": 74246.49437037038
            },
            {
              "lat": 43.76336881111111,
              "lng": -116.1034821111111,
              "alt": 1887.1818888888888,
              "speed2d": 8.38411111111111,
              "speed3d": 8.537777777777778,
              "msFromStart": 74741.88444444441
            },
            {
              "lat": 43.7633871,
              "lng": -116.10343993333333,
              "alt": 1886.2163333333333,
              "speed2d": 8.120333333333333,
              "speed3d": 8.254444444444443,
              "msFromStart": 75236.97079142301
            },
            {
              "lat": 43.76341054444445,
              "lng": -116.1033979,
              "alt": 1885.8504444444443,
              "speed2d": 8.352444444444444,
              "speed3d": 8.40777777777778,
              "msFromStart": 75731.23452631586
            },
            {
              "lat": 43.763436033333335,
              "lng": -116.10335684444445,
              "alt": 1885.3767777777778,
              "speed2d": 8.474111111111112,
              "speed3d": 8.594444444444443,
              "msFromStart": 76225.51310201435
            },
            {
              "lat": 43.7634614,
              "lng": -116.10331722,
              "alt": 1885.0813,
              "speed2d": 8.116800000000001,
              "speed3d": 8.223999999999998,
              "msFromStart": 76747.88222222225
            },
            {
              "lat": 43.76349091111111,
              "lng": -116.10328073333334,
              "alt": 1885.8386666666665,
              "speed2d": 7.690555555555556,
              "speed3d": 7.834444444444444,
              "msFromStart": 77270.40992592597
            },
            {
              "lat": 43.76351486666667,
              "lng": -116.1032523888889,
              "alt": 1886.3561111111112,
              "speed2d": 6.875,
              "speed3d": 6.9866666666666655,
              "msFromStart": 77765.6853333334
            },
            {
              "lat": 43.76353890000001,
              "lng": -116.1032271,
              "alt": 1886.906777777778,
              "speed2d": 6.313333333333333,
              "speed3d": 6.403333333333333,
              "msFromStart": 78261.13570370375
            },
            {
              "lat": 43.7635616888889,
              "lng": -116.10320498888888,
              "alt": 1887.4027777777778,
              "speed2d": 5.911666666666666,
              "speed3d": 5.956666666666667,
              "msFromStart": 78757.18755555562
            },
            {
              "lat": 43.76358827777777,
              "lng": -116.10318368888889,
              "alt": 1888.1194444444445,
              "speed2d": 6.004111111111111,
              "speed3d": 5.992222222222222,
              "msFromStart": 79252.90103703708
            },
            {
              "lat": 43.76361794444445,
              "lng": -116.10316562222224,
              "alt": 1888.802777777778,
              "speed2d": 6.167333333333334,
              "speed3d": 6.141111111111112,
              "msFromStart": 79747.02044444441
            }
          ]
        }
      ]
    },
    {
      "id": "trail-lulu",
      "pathType": "trail",
      "name": "Lulu",
      "difficulty": "green",
      "videos": [
        {
          "videoId": "GX010059",
          "pathId": "trail-lulu",
          "startSec": 36,
          "endSec": 68,
          "id": "video-segment-44",
          "gps": [
            {
              "lat": 43.77111143048264,
              "lng": -116.10186791753358,
              "alt": 2051.260242304661,
              "speed2d": 6.1229100321891465,
              "speed3d": 6.201615364406114,
              "msFromStart": 36000
            },
            {
              "lat": 43.77109716334669,
              "lng": -116.10189770065293,
              "alt": 2051.2250475130527,
              "speed2d": 5.920445370787429,
              "speed3d": 5.966983420351787,
              "msFromStart": 36500
            },
            {
              "lat": 43.77107035,
              "lng": -116.1019534,
              "alt": 2051.1465,
              "speed2d": 5.544499999999999,
              "speed3d": 5.555,
              "msFromStart": 37437.38199999999
            },
            {
              "lat": 43.7710652,
              "lng": -116.10196393333334,
              "alt": 2050.9936666666667,
              "speed2d": 5.523333333333333,
              "speed3d": 5.553333333333333,
              "msFromStart": 37629.75288888887
            },
            {
              "lat": 43.77104575,
              "lng": -116.10199890000001,
              "alt": 2051.1412499999997,
              "speed2d": 5.769,
              "speed3d": 5.7775,
              "msFromStart": 38193.20977777777
            },
            {
              "lat": 43.771034102395056,
              "lng": -116.10201729841674,
              "alt": 2051.358586562515,
              "speed2d": 5.814709252594387,
              "speed3d": 5.793715240231771,
              "msFromStart": 38500
            },
            {
              "lat": 43.771010699029965,
              "lng": -116.10205434948814,
              "alt": 2051.707571403331,
              "speed2d": 6.4861559194731475,
              "speed3d": 6.405832585597057,
              "msFromStart": 39000
            },
            {
              "lat": 43.7709794,
              "lng": -116.10212566000001,
              "alt": 2052.095,
              "speed2d": 6.9322,
              "speed3d": 6.976000000000001,
              "msFromStart": 39885.17066666664
            },
            {
              "lat": 43.77096695,
              "lng": -116.10215495,
              "alt": 2052.395,
              "speed2d": 6.66,
              "speed3d": 6.74,
              "msFromStart": 40269.73799999998
            },
            {
              "lat": 43.7709432,
              "lng": -116.10220029999999,
              "alt": 2052.6915,
              "speed2d": 5.984999999999999,
              "speed3d": 5.985,
              "msFromStart": 40956.229222222224
            },
            {
              "lat": 43.770932560000006,
              "lng": -116.10221511999998,
              "alt": 2052.501,
              "speed2d": 6.0332,
              "speed3d": 6.066,
              "msFromStart": 41236.568713450295
            },
            {
              "lat": 43.77092352570297,
              "lng": -116.1022270037971,
              "alt": 2052.1716662142458,
              "speed2d": 5.73542256533974,
              "speed3d": 5.827948648345543,
              "msFromStart": 41500
            },
            {
              "lat": 43.770891275,
              "lng": -116.10225385,
              "alt": 2052.3755,
              "speed2d": 6.0367500000000005,
              "speed3d": 5.995000000000001,
              "msFromStart": 42112.06336842103
            },
            {
              "lat": 43.77087489587381,
              "lng": -116.10227734090483,
              "alt": 2052.1151723632784,
              "speed2d": 6.259752077769114,
              "speed3d": 6.264103359522645,
              "msFromStart": 42500
            },
            {
              "lat": 43.77085304159396,
              "lng": -116.10230703641497,
              "alt": 2051.8678120235577,
              "speed2d": 6.543287846015509,
              "speed3d": 6.6008498796996165,
              "msFromStart": 43000
            },
            {
              "lat": 43.77083591850363,
              "lng": -116.10234542101733,
              "alt": 2051.4564473900477,
              "speed2d": 6.9329807200266815,
              "speed3d": 6.826488122858111,
              "msFromStart": 43500
            },
            {
              "lat": 43.77081201886534,
              "lng": -116.1023756799161,
              "alt": 2050.4480503709337,
              "speed2d": 7.101396299795568,
              "speed3d": 7.057098667769864,
              "msFromStart": 44000
            },
            {
              "lat": 43.77077345,
              "lng": -116.10242149999999,
              "alt": 2048.8165,
              "speed2d": 7.333,
              "speed3d": 7.404999999999999,
              "msFromStart": 44778.81666666668
            },
            {
              "lat": 43.77076684782682,
              "lng": -116.10243750979424,
              "alt": 2048.290263548168,
              "speed2d": 6.507698924687871,
              "speed3d": 7.000922919176338,
              "msFromStart": 45000
            },
            {
              "lat": 43.77069625,
              "lng": -116.1024484,
              "alt": 2047.0845,
              "speed2d": 8.725999999999999,
              "speed3d": 8.515,
              "msFromStart": 45826.73333333334
            },
            {
              "lat": 43.77065293333333,
              "lng": -116.1024592,
              "alt": 2046.1083333333333,
              "speed2d": 9.917,
              "speed3d": 9.863333333333333,
              "msFromStart": 46285.175750487324
            },
            {
              "lat": 43.770607659999996,
              "lng": -116.10247932,
              "alt": 2044.8573999999999,
              "speed2d": 10.8306,
              "speed3d": 10.878,
              "msFromStart": 46760.00357894733
            },
            {
              "lat": 43.770569820000006,
              "lng": -116.10250482,
              "alt": 2043.7780000000002,
              "speed2d": 11.0158,
              "speed3d": 11.158,
              "msFromStart": 47198.173101754364
            },
            {
              "lat": 43.77054369703739,
              "lng": -116.10252822647853,
              "alt": 2043.2201619830819,
              "speed2d": 11.274218197805448,
              "speed3d": 11.289904084529,
              "msFromStart": 47500
            },
            {
              "lat": 43.77049049701554,
              "lng": -116.10254378983154,
              "alt": 2040.9574200176883,
              "speed2d": 11.635748226800517,
              "speed3d": 11.713808641139636,
              "msFromStart": 48000
            },
            {
              "lat": 43.770437296993684,
              "lng": -116.10255935318455,
              "alt": 2038.6946780522944,
              "speed2d": 11.997278255795587,
              "speed3d": 12.13771319775027,
              "msFromStart": 48500
            },
            {
              "lat": 43.77035015,
              "lng": -116.10258055,
              "alt": 2034.9975,
              "speed2d": 12.525500000000001,
              "speed3d": 12.780000000000001,
              "msFromStart": 49316.119
            },
            {
              "lat": 43.77029186666667,
              "lng": -116.10256533333333,
              "alt": 2032.2943333333333,
              "speed2d": 13.008,
              "speed3d": 13.303333333333333,
              "msFromStart": 49836.24399999999
            },
            {
              "lat": 43.7702443,
              "lng": -116.10255333333333,
              "alt": 2030.5133333333333,
              "speed2d": 13.276333333333332,
              "speed3d": 13.62,
              "msFromStart": 50238.22452631579
            },
            {
              "lat": 43.77021114665244,
              "lng": -116.1025486682216,
              "alt": 2029.4160727014948,
              "speed2d": 13.56538937502467,
              "speed3d": 13.851855271495797,
              "msFromStart": 50500
            },
            {
              "lat": 43.770146604720445,
              "lng": -116.10255506320202,
              "alt": 2027.7517987852445,
              "speed2d": 13.643550246803029,
              "speed3d": 13.950543240912916,
              "msFromStart": 51000
            },
            {
              "lat": 43.770083011779604,
              "lng": -116.10256195049364,
              "alt": 2026.2042616057036,
              "speed2d": 13.625318143087437,
              "speed3d": 13.971705019430155,
              "msFromStart": 51500
            },
            {
              "lat": 43.770016172648894,
              "lng": -116.10256715374885,
              "alt": 2024.2574060480683,
              "speed2d": 13.936815032309077,
              "speed3d": 14.258058661988166,
              "msFromStart": 52000
            },
            {
              "lat": 43.76994933351818,
              "lng": -116.10257235700408,
              "alt": 2022.3105504904331,
              "speed2d": 14.24831192153072,
              "speed3d": 14.544412304546176,
              "msFromStart": 52500
            },
            {
              "lat": 43.769882562164575,
              "lng": -116.10258366417719,
              "alt": 2020.410858471197,
              "speed2d": 14.549880132482494,
              "speed3d": 14.79654704161868,
              "msFromStart": 53000
            },
            {
              "lat": 43.76978716,
              "lng": -116.10255812,
              "alt": 2017.451,
              "speed2d": 14.914799999999998,
              "speed3d": 15.3,
              "msFromStart": 53719.964799999994
            },
            {
              "lat": 43.769717075,
              "lng": -116.102518875,
              "alt": 2014.2710000000002,
              "speed2d": 14.94875,
              "speed3d": 15.370000000000001,
              "msFromStart": 54304.51683333333
            },
            {
              "lat": 43.76969359752111,
              "lng": -116.10250426311559,
              "alt": 2013.1405660680628,
              "speed2d": 15.026712899153495,
              "speed3d": 15.44,
              "msFromStart": 54500
            },
            {
              "lat": 43.769618019999996,
              "lng": -116.1024721,
              "alt": 2009.8844000000001,
              "speed2d": 14.841,
              "speed3d": 15.36,
              "msFromStart": 55115.389200000005
            },
            {
              "lat": 43.76952192499999,
              "lng": -116.10242947500001,
              "alt": 2005.7305000000001,
              "speed2d": 15.352,
              "speed3d": 15.8175,
              "msFromStart": 55858.66033333331
            },
            {
              "lat": 43.76950455598853,
              "lng": -116.10242404217442,
              "alt": 2004.2569013728703,
              "speed2d": 15.620456279488865,
              "speed3d": 16.214163559414594,
              "msFromStart": 56000
            },
            {
              "lat": 43.7694439770711,
              "lng": -116.1023867698968,
              "alt": 2001.8783252866972,
              "speed2d": 15.1668071559633,
              "speed3d": 15.843298738532107,
              "msFromStart": 56500
            },
            {
              "lat": 43.76938507363984,
              "lng": -116.10234319226566,
              "alt": 2000.237508551038,
              "speed2d": 14.701260105002387,
              "speed3d": 15.143418445917474,
              "msFromStart": 57000
            },
            {
              "lat": 43.76933969282756,
              "lng": -116.1022885107922,
              "alt": 1995.9543937241633,
              "speed2d": 14.812440422485214,
              "speed3d": 15.330500710912618,
              "msFromStart": 57500
            },
            {
              "lat": 43.76929494285718,
              "lng": -116.10223300652792,
              "alt": 1991.5775865675628,
              "speed2d": 14.95182781476742,
              "speed3d": 15.546485336012822,
              "msFromStart": 58000
            },
            {
              "lat": 43.7691947,
              "lng": -116.10215596666666,
              "alt": 1986.3716666666667,
              "speed2d": 14.352,
              "speed3d": 15.11,
              "msFromStart": 58913.566444444485
            },
            {
              "lat": 43.76915315714285,
              "lng": -116.10212534285714,
              "alt": 1984.5248571428572,
              "speed2d": 14.007714285714284,
              "speed3d": 14.80285714285714,
              "msFromStart": 59290.78863492064
            },
            {
              "lat": 43.769110966666666,
              "lng": -116.10208991666666,
              "alt": 1982.5626666666667,
              "speed2d": 13.447000000000001,
              "speed3d": 14.285000000000002,
              "msFromStart": 59711.01888888891
            },
            {
              "lat": 43.76907466666666,
              "lng": -116.1020581,
              "alt": 1981.1206666666667,
              "speed2d": 13.207,
              "speed3d": 14.030000000000001,
              "msFromStart": 60068.40044444447
            },
            {
              "lat": 43.76902944840789,
              "lng": -116.10201534975366,
              "alt": 1979.1612019122174,
              "speed2d": 13.578650962108586,
              "speed3d": 14.277236426010102,
              "msFromStart": 60500
            },
            {
              "lat": 43.768945200000005,
              "lng": -116.10195575,
              "alt": 1974.931,
              "speed2d": 14.004999999999999,
              "speed3d": 14.73,
              "msFromStart": 61252.37302339183
            },
            {
              "lat": 43.76891544410602,
              "lng": -116.10193777573268,
              "alt": 1973.698476713263,
              "speed2d": 14.155762176647611,
              "speed3d": 14.833372316999025,
              "msFromStart": 61500
            },
            {
              "lat": 43.768854322106414,
              "lng": -116.10190076153933,
              "alt": 1971.411769292141,
              "speed2d": 14.271566792429601,
              "speed3d": 14.845991892370337,
              "msFromStart": 62000
            },
            {
              "lat": 43.76879398680813,
              "lng": -116.10185530671508,
              "alt": 1968.184476770735,
              "speed2d": 14.754823344930697,
              "speed3d": 15.396234501653764,
              "msFromStart": 62500
            },
            {
              "lat": 43.768718899999996,
              "lng": -116.10179875,
              "alt": 1964.1975,
              "speed2d": 15.3465,
              "speed3d": 16.064999999999998,
              "msFromStart": 63121.57566666663
            },
            {
              "lat": 43.76866526884414,
              "lng": -116.10175778150366,
              "alt": 1962.480388328592,
              "speed2d": 16.451666256547924,
              "speed3d": 17.01792587436968,
              "msFromStart": 63500
            },
            {
              "lat": 43.76860320959369,
              "lng": -116.10173592505002,
              "alt": 1959.1216489028727,
              "speed2d": 15.132001999184165,
              "speed3d": 15.675469687117571,
              "msFromStart": 64000
            },
            {
              "lat": 43.76852175,
              "lng": -116.1017185,
              "alt": 1956.186,
              "speed2d": 14.461500000000001,
              "speed3d": 15.31,
              "msFromStart": 64633.619555555575
            },
            {
              "lat": 43.7684746567514,
              "lng": -116.1017197718389,
              "alt": 1953.8990963647966,
              "speed2d": 14.524525960161835,
              "speed3d": 15.33505395986908,
              "msFromStart": 65000
            },
            {
              "lat": 43.76840787578849,
              "lng": -116.10171115494046,
              "alt": 1951.2087091852095,
              "speed2d": 14.629364891181337,
              "speed3d": 15.368564120468921,
              "msFromStart": 65500
            },
            {
              "lat": 43.7683217,
              "lng": -116.1016994,
              "alt": 1947.7395000000001,
              "speed2d": 14.771,
              "speed3d": 15.495000000000001,
              "msFromStart": 66145.7412222222
            },
            {
              "lat": 43.76827610802353,
              "lng": -116.10167986449812,
              "alt": 1946.4925218147391,
              "speed2d": 14.479706259626257,
              "speed3d": 15.279349342331038,
              "msFromStart": 66500
            },
            {
              "lat": 43.768170885714284,
              "lng": -116.10162654285713,
              "alt": 1943.8897142857145,
              "speed2d": 13.899428571428572,
              "speed3d": 14.677142857142858,
              "msFromStart": 67327.24200501255
            },
            {
              "lat": 43.7681125,
              "lng": -116.101575,
              "alt": 1942.64,
              "speed2d": 14.466999999999999,
              "speed3d": 15.1,
              "msFromStart": 67794.68266666672
            }
          ]
        }
      ]
    },
    {
      "id": "trail-silver-queen",
      "pathType": "trail",
      "name": "Silver Queen",
      "difficulty": "green",
      "videos": [
        {
          "videoId": "GX010059",
          "pathId": "trail-silver-queen",
          "startSec": 68,
          "endSec": 86,
          "id": "video-segment-45",
          "gps": [
            {
              "lat": 43.76806756,
              "lng": -116.10153128,
              "alt": 1942.022,
              "speed2d": 14.63,
              "speed3d": 15.32,
              "msFromStart": 68163.18257777781
            },
            {
              "lat": 43.76800424,
              "lng": -116.10147296,
              "alt": 1940.5804000000003,
              "speed2d": 14.578,
              "speed3d": 15.268,
              "msFromStart": 68703.01599999996
            },
            {
              "lat": 43.7679548,
              "lng": -116.10143405000001,
              "alt": 1939.8017499999999,
              "speed2d": 14.394749999999998,
              "speed3d": 15.022499999999999,
              "msFromStart": 69116.24933333328
            },
            {
              "lat": 43.76787985,
              "lng": -116.10137965,
              "alt": 1937.7445,
              "speed2d": 14.582,
              "speed3d": 15.17,
              "msFromStart": 69721.15533333327
            },
            {
              "lat": 43.76780754000001,
              "lng": -116.10131966,
              "alt": 1935.0054000000002,
              "speed2d": 15.3904,
              "speed3d": 15.979999999999999,
              "msFromStart": 70298.47733333334
            },
            {
              "lat": 43.76778537296192,
              "lng": -116.10130292551027,
              "alt": 1934.4055020923852,
              "speed2d": 14.733144518751422,
              "speed3d": 15.317075842249679,
              "msFromStart": 70500
            },
            {
              "lat": 43.76772544912439,
              "lng": -116.10123109813948,
              "alt": 1932.5800928816507,
              "speed2d": 15.51491267406857,
              "speed3d": 16.091645068179925,
              "msFromStart": 71000
            },
            {
              "lat": 43.76766134812907,
              "lng": -116.1011939928362,
              "alt": 1930.5237373592308,
              "speed2d": 15.46119675620228,
              "speed3d": 16.031042494176933,
              "msFromStart": 71500
            },
            {
              "lat": 43.76759724713375,
              "lng": -116.10115688753294,
              "alt": 1928.4673818368112,
              "speed2d": 15.407480838335992,
              "speed3d": 15.97043992017394,
              "msFromStart": 72000
            },
            {
              "lat": 43.767533146138426,
              "lng": -116.10111978222967,
              "alt": 1926.4110263143914,
              "speed2d": 15.353764920469702,
              "speed3d": 15.909837346170947,
              "msFromStart": 72500
            },
            {
              "lat": 43.76747014908332,
              "lng": -116.10107490767625,
              "alt": 1924.285517662841,
              "speed2d": 15.340389923982698,
              "speed3d": 15.878092115069302,
              "msFromStart": 73000
            },
            {
              "lat": 43.76736764,
              "lng": -116.10105804,
              "alt": 1921.6468,
              "speed2d": 14.799,
              "speed3d": 15.498000000000001,
              "msFromStart": 73763.64888888883
            },
            {
              "lat": 43.76733559440701,
              "lng": -116.10105771155321,
              "alt": 1921.1588204672933,
              "speed2d": 14.529271014083431,
              "speed3d": 15.231195270570227,
              "msFromStart": 74000
            },
            {
              "lat": 43.76722401666667,
              "lng": -116.10105325,
              "alt": 1919.1265,
              "speed2d": 15.015166666666667,
              "speed3d": 15.51,
              "msFromStart": 74782.6233333333
            },
            {
              "lat": 43.767161333333334,
              "lng": -116.10105082222222,
              "alt": 1917.5195555555551,
              "speed2d": 14.794666666666664,
              "speed3d": 15.31777777777778,
              "msFromStart": 75251.3059259259
            },
            {
              "lat": 43.767127855165235,
              "lng": -116.10104944091778,
              "alt": 1916.6513410541004,
              "speed2d": 14.735544933170946,
              "speed3d": 15.21436328878063,
              "msFromStart": 75500
            },
            {
              "lat": 43.767039525,
              "lng": -116.10104207500001,
              "alt": 1915.2740000000001,
              "speed2d": 15.178999999999998,
              "speed3d": 15.5775,
              "msFromStart": 76105.3753333333
            },
            {
              "lat": 43.76698258871076,
              "lng": -116.10103357464129,
              "alt": 1914.2085616698903,
              "speed2d": 15.294391169007108,
              "speed3d": 15.633511289252633,
              "msFromStart": 76500
            },
            {
              "lat": 43.76690933759757,
              "lng": -116.10102379474081,
              "alt": 1913.42874333714,
              "speed2d": 15.165359528622623,
              "speed3d": 15.626111027246514,
              "msFromStart": 77000
            },
            {
              "lat": 43.766837308619436,
              "lng": -116.10101210383364,
              "alt": 1911.5244244562293,
              "speed2d": 15.519983713130562,
              "speed3d": 15.872919067746546,
              "msFromStart": 77500
            },
            {
              "lat": 43.76674146666667,
              "lng": -116.10099566666668,
              "alt": 1908.7956666666666,
              "speed2d": 15.924333333333333,
              "speed3d": 16.243333333333336,
              "msFromStart": 78165.52176218326
            },
            {
              "lat": 43.76669579402457,
              "lng": -116.10098732117993,
              "alt": 1906.9800483144616,
              "speed2d": 15.798655514136733,
              "speed3d": 16.12394642619856,
              "msFromStart": 78500
            },
            {
              "lat": 43.76661408617206,
              "lng": -116.10096976443772,
              "alt": 1906.1545378667588,
              "speed2d": 16.59208884504577,
              "speed3d": 16.882366848267438,
              "msFromStart": 79000
            },
            {
              "lat": 43.76653673529706,
              "lng": -116.1009451686762,
              "alt": 1904.6078697384248,
              "speed2d": 16.778303914483764,
              "speed3d": 17.085002383490774,
              "msFromStart": 79500
            },
            {
              "lat": 43.76645938442206,
              "lng": -116.10092057291469,
              "alt": 1903.0612016100908,
              "speed2d": 16.964518983921757,
              "speed3d": 17.287637918714108,
              "msFromStart": 80000
            },
            {
              "lat": 43.766330833333335,
              "lng": -116.10087841666666,
              "alt": 1900.4141666666667,
              "speed2d": 17.21483333333333,
              "speed3d": 17.560000000000002,
              "msFromStart": 80834.54511111113
            },
            {
              "lat": 43.766281750000005,
              "lng": -116.100859,
              "alt": 1899.336,
              "speed2d": 17.0445,
              "speed3d": 17.395000000000003,
              "msFromStart": 81165.14901754388
            },
            {
              "lat": 43.76617556666667,
              "lng": -116.10081563333333,
              "alt": 1896.8063333333334,
              "speed2d": 16.495333333333335,
              "speed3d": 16.866666666666667,
              "msFromStart": 81907.38189473691
            },
            {
              "lat": 43.7661566,
              "lng": -116.10080705,
              "alt": 1896.238,
              "speed2d": 16.392,
              "speed3d": 16.77,
              "msFromStart": 82044.78610526325
            },
            {
              "lat": 43.76609792958517,
              "lng": -116.1007772864419,
              "alt": 1894.8749848810219,
              "speed2d": 16.166016134852025,
              "speed3d": 16.53810008091973,
              "msFromStart": 82500
            },
            {
              "lat": 43.76603378862799,
              "lng": -116.1007445398983,
              "alt": 1893.4137754756605,
              "speed2d": 15.921723738154753,
              "speed3d": 16.29039895310575,
              "msFromStart": 83000
            },
            {
              "lat": 43.765969647670815,
              "lng": -116.1007117933547,
              "alt": 1891.952566070299,
              "speed2d": 15.677431341457481,
              "speed3d": 16.04269782529177,
              "msFromStart": 83500
            },
            {
              "lat": 43.765905506713636,
              "lng": -116.10067904681111,
              "alt": 1890.491356664938,
              "speed2d": 15.43313894476021,
              "speed3d": 15.794996697477794,
              "msFromStart": 84000
            },
            {
              "lat": 43.76584136575646,
              "lng": -116.10064630026751,
              "alt": 1889.0301472595766,
              "speed2d": 15.188846548062937,
              "speed3d": 15.547295569663815,
              "msFromStart": 84500
            },
            {
              "lat": 43.76577722479927,
              "lng": -116.10061355372392,
              "alt": 1887.5689378542152,
              "speed2d": 14.944554151365665,
              "speed3d": 15.299594441849838,
              "msFromStart": 85000
            },
            {
              "lat": 43.765713083842094,
              "lng": -116.10058080718032,
              "alt": 1886.1077284488538,
              "speed2d": 14.700261754668393,
              "speed3d": 15.051893314035858,
              "msFromStart": 85500
            },
            {
              "lat": 43.76563677592977,
              "lng": -116.1005017279695,
              "alt": 1883.150977908579,
              "speed2d": 15.177316036278473,
              "speed3d": 15.498497108089943,
              "msFromStart": 86000
            }
          ]
        },
        {
          "videoId": "GX010061",
          "pathId": "trail-silver-queen",
          "startSec": 69,
          "endSec": 90,
          "id": "video-segment-47",
          "gps": [
            {
              "lat": 43.76960196666667,
              "lng": -116.10403251111109,
              "alt": 2026.5648888888888,
              "speed2d": 8.084888888888889,
              "speed3d": 8.193333333333333,
              "msFromStart": 69261.57733333332
            },
            {
              "lat": 43.76956167777777,
              "lng": -116.10403155555557,
              "alt": 2025.781,
              "speed2d": 8.535,
              "speed3d": 8.632222222222222,
              "msFromStart": 69757.01688888895
            },
            {
              "lat": 43.76951752222222,
              "lng": -116.10402577777778,
              "alt": 2025.0750000000003,
              "speed2d": 9.104000000000001,
              "speed3d": 9.176666666666668,
              "msFromStart": 70252.24992592595
            },
            {
              "lat": 43.76947304444444,
              "lng": -116.10401294444445,
              "alt": 2023.9217777777776,
              "speed2d": 9.604666666666667,
              "speed3d": 9.734444444444444,
              "msFromStart": 70746.51111111112
            },
            {
              "lat": 43.7694322,
              "lng": -116.10399527777778,
              "alt": 2022.648888888889,
              "speed2d": 9.542333333333332,
              "speed3d": 9.724444444444444,
              "msFromStart": 71240.77869785576
            },
            {
              "lat": 43.769392966666665,
              "lng": -116.10396571111112,
              "alt": 2021.3180000000002,
              "speed2d": 9.861777777777778,
              "speed3d": 9.972222222222221,
              "msFromStart": 71735.46063157896
            },
            {
              "lat": 43.769351211111115,
              "lng": -116.10392002222223,
              "alt": 2019.9606666666666,
              "speed2d": 10.944444444444445,
              "speed3d": 10.99777777777778,
              "msFromStart": 72230.17815204678
            },
            {
              "lat": 43.769302679999996,
              "lng": -116.10387254000001,
              "alt": 2018.9677000000001,
              "speed2d": 11.7965,
              "speed3d": 11.915000000000001,
              "msFromStart": 72752.34200000005
            },
            {
              "lat": 43.76925342222223,
              "lng": -116.10382862222224,
              "alt": 2017.762888888889,
              "speed2d": 12.051333333333332,
              "speed3d": 12.217777777777776,
              "msFromStart": 73274.73629629632
            },
            {
              "lat": 43.76920696666667,
              "lng": -116.1037853,
              "alt": 2016.2265555555555,
              "speed2d": 12.199444444444444,
              "speed3d": 12.364444444444445,
              "msFromStart": 73770.59377777773
            },
            {
              "lat": 43.7691751,
              "lng": -116.10373292222224,
              "alt": 2013.8053333333332,
              "speed2d": 11.648333333333332,
              "speed3d": 11.981111111111112,
              "msFromStart": 74266.11614814814
            },
            {
              "lat": 43.769163211111106,
              "lng": -116.1036659,
              "alt": 2010.631222222222,
              "speed2d": 11.168888888888887,
              "speed3d": 11.447777777777778,
              "msFromStart": 74759.91999999998
            },
            {
              "lat": 43.76914772222222,
              "lng": -116.10359353333334,
              "alt": 2008.4402222222222,
              "speed2d": 11.679555555555552,
              "speed3d": 11.956666666666669,
              "msFromStart": 75253.89274074073
            },
            {
              "lat": 43.76912364444444,
              "lng": -116.10352386666666,
              "alt": 2006.3457777777776,
              "speed2d": 12.219333333333333,
              "speed3d": 12.454444444444446,
              "msFromStart": 75749.22844444445
            },
            {
              "lat": 43.7690867,
              "lng": -116.1034526,
              "alt": 2004.878777777778,
              "speed2d": 13.388777777777777,
              "speed3d": 13.57777777777778,
              "msFromStart": 76244.78711111112
            },
            {
              "lat": 43.76903892222222,
              "lng": -116.10338312222223,
              "alt": 2004.0207777777778,
              "speed2d": 14.418555555555555,
              "speed3d": 14.63888888888889,
              "msFromStart": 76740.69244444443
            },
            {
              "lat": 43.768992233333336,
              "lng": -116.10331687777779,
              "alt": 2002.8235555555557,
              "speed2d": 14.58211111111111,
              "speed3d": 14.865555555555556,
              "msFromStart": 77236.34328265107
            },
            {
              "lat": 43.76895451111111,
              "lng": -116.10324302222222,
              "alt": 2000.9835555555555,
              "speed2d": 14.512333333333334,
              "speed3d": 14.831111111111111,
              "msFromStart": 77730.83747368427
            },
            {
              "lat": 43.76892435999999,
              "lng": -116.10315512,
              "alt": 2000.3867000000002,
              "speed2d": 14.5561,
              "speed3d": 14.812000000000001,
              "msFromStart": 78252.62638596495
            },
            {
              "lat": 43.768898488888894,
              "lng": -116.10306494444444,
              "alt": 1999.4633333333336,
              "speed2d": 14.578888888888892,
              "speed3d": 14.877777777777778,
              "msFromStart": 78774.15822222225
            },
            {
              "lat": 43.76886845555556,
              "lng": -116.10298833333334,
              "alt": 1998.0663333333332,
              "speed2d": 14.27,
              "speed3d": 14.545555555555556,
              "msFromStart": 79268.76325925926
            },
            {
              "lat": 43.76882974444444,
              "lng": -116.10291886666667,
              "alt": 1996.1232222222225,
              "speed2d": 14.326555555555558,
              "speed3d": 14.627777777777778,
              "msFromStart": 79765.59644444438
            },
            {
              "lat": 43.76878996666666,
              "lng": -116.10285255555557,
              "alt": 1994.4588888888886,
              "speed2d": 14.366666666666669,
              "speed3d": 14.702222222222222,
              "msFromStart": 80262.47170370365
            },
            {
              "lat": 43.76875548888889,
              "lng": -116.10278267777777,
              "alt": 1992.8792222222223,
              "speed2d": 13.920444444444444,
              "speed3d": 14.314444444444444,
              "msFromStart": 80758.62399999994
            },
            {
              "lat": 43.76872745555555,
              "lng": -116.10270344444447,
              "alt": 1991.1476666666663,
              "speed2d": 13.872333333333335,
              "speed3d": 14.17,
              "msFromStart": 81254.48103703701
            },
            {
              "lat": 43.76869792222222,
              "lng": -116.10261734444444,
              "alt": 1989.461111111111,
              "speed2d": 14.429777777777778,
              "speed3d": 14.73777777777778,
              "msFromStart": 81749.44622222215
            },
            {
              "lat": 43.76866448888889,
              "lng": -116.10253902222222,
              "alt": 1987.2215555555554,
              "speed2d": 14.759333333333334,
              "speed3d": 15.068888888888887,
              "msFromStart": 82244.53999999996
            },
            {
              "lat": 43.76862508888888,
              "lng": -116.10246325555556,
              "alt": 1984.8136666666667,
              "speed2d": 15.123777777777777,
              "speed3d": 15.484444444444446,
              "msFromStart": 82740.50488888893
            },
            {
              "lat": 43.768580677777784,
              "lng": -116.10238812222224,
              "alt": 1982.8416666666665,
              "speed2d": 15.609444444444446,
              "speed3d": 15.97111111111111,
              "msFromStart": 83236.31786354778
            },
            {
              "lat": 43.76853574444445,
              "lng": -116.10231382222221,
              "alt": 1980.7178888888889,
              "speed2d": 15.866,
              "speed3d": 16.251111111111115,
              "msFromStart": 83731.17473684208
            },
            {
              "lat": 43.76848812,
              "lng": -116.10223418000001,
              "alt": 1978.2682,
              "speed2d": 16.005599999999998,
              "speed3d": 16.413,
              "msFromStart": 84253.02452631577
            },
            {
              "lat": 43.7684364,
              "lng": -116.10215256666666,
              "alt": 1976.463777777778,
              "speed2d": 16.461,
              "speed3d": 16.822222222222223,
              "msFromStart": 84773.01599999997
            },
            {
              "lat": 43.76838187777777,
              "lng": -116.10206886666666,
              "alt": 1975.1105555555555,
              "speed2d": 17.42822222222222,
              "speed3d": 17.717777777777776,
              "msFromStart": 85265.81022222222
            },
            {
              "lat": 43.7683229888889,
              "lng": -116.10198797777778,
              "alt": 1973.612222222222,
              "speed2d": 18.214444444444446,
              "speed3d": 18.52444444444444,
              "msFromStart": 85760.2933333334
            },
            {
              "lat": 43.76826032222222,
              "lng": -116.10191587777777,
              "alt": 1971.7578888888888,
              "speed2d": 18.610111111111113,
              "speed3d": 18.963333333333335,
              "msFromStart": 86255.11714619887
            },
            {
              "lat": 43.768194788888884,
              "lng": -116.1018454777778,
              "alt": 1969.9413333333334,
              "speed2d": 18.747222222222224,
              "speed3d": 19.134444444444444,
              "msFromStart": 86750.62821052637
            },
            {
              "lat": 43.76813094444445,
              "lng": -116.10177374444444,
              "alt": 1968.2109999999998,
              "speed2d": 18.442666666666664,
              "speed3d": 18.86555555555556,
              "msFromStart": 87246.0786432749
            },
            {
              "lat": 43.768072088888886,
              "lng": -116.1016948888889,
              "alt": 1966.3193333333331,
              "speed2d": 18.108333333333334,
              "speed3d": 18.51666666666667,
              "msFromStart": 87740.74000000005
            },
            {
              "lat": 43.768020433333334,
              "lng": -116.10161351111114,
              "alt": 1964.214,
              "speed2d": 17.68766666666667,
              "speed3d": 18.138888888888886,
              "msFromStart": 88235.37471604941
            },
            {
              "lat": 43.76796742222222,
              "lng": -116.1015338888889,
              "alt": 1962.4644444444443,
              "speed2d": 17.576222222222224,
              "speed3d": 17.963333333333335,
              "msFromStart": 88730.63599999998
            },
            {
              "lat": 43.76791217777778,
              "lng": -116.10146548888888,
              "alt": 1960.5592222222222,
              "speed2d": 17.21477777777778,
              "speed3d": 17.7,
              "msFromStart": 89225.87160493825
            },
            {
              "lat": 43.76784983,
              "lng": -116.10140263,
              "alt": 1958.3921000000003,
              "speed2d": 16.9016,
              "speed3d": 17.354,
              "msFromStart": 89747.7864444444
            }
          ]
        }
      ]
    },
    {
      "id": "trail-morning-star-lift-to-silver-queen",
      "pathType": "trail",
      "name": "Morning Star Lift to Silver Queen",
      "difficulty": "green",
      "videos": [
        {
          "videoId": "GX010061",
          "pathId": "trail-morning-star-lift-to-silver-queen",
          "startSec": 18,
          "endSec": 69,
          "id": "video-segment-46",
          "gps": [
            {
              "lat": 43.77185560000001,
              "lng": -116.10099796666667,
              "alt": 2066.3579999999997,
              "speed2d": 8.750666666666667,
              "speed3d": 8.808888888888887,
              "msFromStart": 18274.02459259259
            },
            {
              "lat": 43.771814,
              "lng": -116.10100417777778,
              "alt": 2065.9192222222223,
              "speed2d": 9.159444444444443,
              "speed3d": 9.237777777777778,
              "msFromStart": 18768.680666666653
            },
            {
              "lat": 43.771773366666665,
              "lng": -116.10101066666667,
              "alt": 2065.505777777778,
              "speed2d": 9.237,
              "speed3d": 9.328888888888889,
              "msFromStart": 19263.49992592592
            },
            {
              "lat": 43.771733955555554,
              "lng": -116.1010163,
              "alt": 2064.8802222222225,
              "speed2d": 9.081555555555555,
              "speed3d": 9.19111111111111,
              "msFromStart": 19758.87088888888
            },
            {
              "lat": 43.771695677777785,
              "lng": -116.10102800000001,
              "alt": 2064.6544444444444,
              "speed2d": 8.869444444444444,
              "speed3d": 8.945555555555558,
              "msFromStart": 20254.807555555548
            },
            {
              "lat": 43.771655244444446,
              "lng": -116.1010449,
              "alt": 2064.671444444444,
              "speed2d": 9.098666666666666,
              "speed3d": 9.168888888888889,
              "msFromStart": 20752.200888888878
            },
            {
              "lat": 43.77161912222222,
              "lng": -116.10106447777777,
              "alt": 2064.660111111111,
              "speed2d": 8.767555555555555,
              "speed3d": 8.862222222222222,
              "msFromStart": 21248.570851851848
            },
            {
              "lat": 43.77158492222223,
              "lng": -116.10108514444444,
              "alt": 2064.4487777777777,
              "speed2d": 8.49911111111111,
              "speed3d": 8.58,
              "msFromStart": 21741.954999999998
            },
            {
              "lat": 43.77155398888888,
              "lng": -116.10110686666667,
              "alt": 2063.8199999999997,
              "speed2d": 8.078111111111111,
              "speed3d": 8.178888888888888,
              "msFromStart": 22235.245491228074
            },
            {
              "lat": 43.77152484444445,
              "lng": -116.10113325555557,
              "alt": 2063.2526666666668,
              "speed2d": 7.823444444444444,
              "speed3d": 7.926666666666666,
              "msFromStart": 22728.708315789496
            },
            {
              "lat": 43.77149763,
              "lng": -116.10116327,
              "alt": 2062.8644,
              "speed2d": 7.221299999999999,
              "speed3d": 7.335,
              "msFromStart": 23250.22977368422
            },
            {
              "lat": 43.77147191111111,
              "lng": -116.1011952888889,
              "alt": 2062.6247777777776,
              "speed2d": 6.9750000000000005,
              "speed3d": 7.058888888888888,
              "msFromStart": 23773.80566666668
            },
            {
              "lat": 43.771450344444446,
              "lng": -116.10122756666665,
              "alt": 2062.427777777778,
              "speed2d": 6.844333333333333,
              "speed3d": 6.908888888888889,
              "msFromStart": 24269.534666666674
            },
            {
              "lat": 43.77143224444445,
              "lng": -116.10126307777779,
              "alt": 2062.352111111111,
              "speed2d": 6.671333333333333,
              "speed3d": 6.735555555555556,
              "msFromStart": 24764.00966666668
            },
            {
              "lat": 43.77141773333334,
              "lng": -116.10129446666667,
              "alt": 2061.654111111111,
              "speed2d": 6.3085555555555555,
              "speed3d": 6.385555555555555,
              "msFromStart": 25258.44381481482
            },
            {
              "lat": 43.77140392222223,
              "lng": -116.10132822222221,
              "alt": 2061.4086666666667,
              "speed2d": 6.094333333333334,
              "speed3d": 6.167777777777778,
              "msFromStart": 25752.94622222223
            },
            {
              "lat": 43.77139079999999,
              "lng": -116.10136023333331,
              "alt": 2061.103666666667,
              "speed2d": 5.808555555555556,
              "speed3d": 5.886666666666667,
              "msFromStart": 26247.463384015595
            },
            {
              "lat": 43.77137862222222,
              "lng": -116.10139065555555,
              "alt": 2060.6988888888886,
              "speed2d": 5.578222222222222,
              "speed3d": 5.625555555555556,
              "msFromStart": 26742.01084210525
            },
            {
              "lat": 43.77136616666667,
              "lng": -116.10142357777777,
              "alt": 2060.369777777778,
              "speed2d": 5.665222222222223,
              "speed3d": 5.688888888888888,
              "msFromStart": 27236.670625730985
            },
            {
              "lat": 43.77135297777778,
              "lng": -116.10145844444446,
              "alt": 2060.0893333333333,
              "speed2d": 5.8822222222222225,
              "speed3d": 5.897777777777778,
              "msFromStart": 27731.77333333333
            },
            {
              "lat": 43.771339555555556,
              "lng": -116.10149377777779,
              "alt": 2059.6794444444445,
              "speed2d": 6.101999999999999,
              "speed3d": 6.118888888888888,
              "msFromStart": 28226.850148148147
            },
            {
              "lat": 43.77132408999999,
              "lng": -116.10153022,
              "alt": 2058.8908,
              "speed2d": 6.354,
              "speed3d": 6.386000000000001,
              "msFromStart": 28749.133333333342
            },
            {
              "lat": 43.77130801111112,
              "lng": -116.10156617777778,
              "alt": 2058.0822222222223,
              "speed2d": 6.458555555555555,
              "speed3d": 6.473333333333334,
              "msFromStart": 29271.658333333333
            },
            {
              "lat": 43.77129016666667,
              "lng": -116.10160476666667,
              "alt": 2057.594,
              "speed2d": 6.93288888888889,
              "speed3d": 6.946666666666666,
              "msFromStart": 29767.428777777764
            },
            {
              "lat": 43.771271500000005,
              "lng": -116.10164130000003,
              "alt": 2057.2525555555553,
              "speed2d": 7.0071111111111115,
              "speed3d": 7.058888888888887,
              "msFromStart": 30263.078074074063
            },
            {
              "lat": 43.771252811111104,
              "lng": -116.10167696666667,
              "alt": 2057.2344444444448,
              "speed2d": 6.843333333333334,
              "speed3d": 6.8966666666666665,
              "msFromStart": 30758.293888888868
            },
            {
              "lat": 43.77123818888889,
              "lng": -116.10170853333335,
              "alt": 2056.455,
              "speed2d": 6.583555555555556,
              "speed3d": 6.667777777777779,
              "msFromStart": 31253.391413255355
            },
            {
              "lat": 43.771223344444444,
              "lng": -116.1017414888889,
              "alt": 2055.9484444444442,
              "speed2d": 6.375,
              "speed3d": 6.434444444444445,
              "msFromStart": 31748.2297894737
            },
            {
              "lat": 43.771208555555546,
              "lng": -116.10177384444445,
              "alt": 2055.4035555555556,
              "speed2d": 6.217444444444444,
              "speed3d": 6.283333333333332,
              "msFromStart": 32242.972723196886
            },
            {
              "lat": 43.77119625555556,
              "lng": -116.10180716666666,
              "alt": 2054.8824444444444,
              "speed2d": 6.097888888888889,
              "speed3d": 6.163333333333332,
              "msFromStart": 32737.38844444443
            },
            {
              "lat": 43.771180844444444,
              "lng": -116.10183856666667,
              "alt": 2054.3805555555555,
              "speed2d": 5.960333333333333,
              "speed3d": 6.054444444444445,
              "msFromStart": 33232.00755555555
            },
            {
              "lat": 43.77116497777779,
              "lng": -116.1018699777778,
              "alt": 2054.121,
              "speed2d": 5.849111111111112,
              "speed3d": 5.896666666666667,
              "msFromStart": 33727.571111111094
            },
            {
              "lat": 43.77114911000001,
              "lng": -116.10190026000001,
              "alt": 2053.6324999999997,
              "speed2d": 5.6183,
              "speed3d": 5.691,
              "msFromStart": 34250.58086666666
            },
            {
              "lat": 43.7711316,
              "lng": -116.10193413333334,
              "alt": 2053.333777777778,
              "speed2d": 6.120111111111112,
              "speed3d": 6.085555555555556,
              "msFromStart": 34772.992222222245
            },
            {
              "lat": 43.77111546666667,
              "lng": -116.10196884444444,
              "alt": 2052.972888888889,
              "speed2d": 6.277,
              "speed3d": 6.348888888888888,
              "msFromStart": 35267.713555555565
            },
            {
              "lat": 43.771100188888894,
              "lng": -116.10200125555555,
              "alt": 2052.536111111111,
              "speed2d": 6.179777777777778,
              "speed3d": 6.23,
              "msFromStart": 35762.042222222255
            },
            {
              "lat": 43.771084344444446,
              "lng": -116.10203293333332,
              "alt": 2051.996666666667,
              "speed2d": 6.263222222222222,
              "speed3d": 6.289999999999999,
              "msFromStart": 36256.53896296298
            },
            {
              "lat": 43.771066866666665,
              "lng": -116.1020708111111,
              "alt": 2051.686111111111,
              "speed2d": 6.759777777777778,
              "speed3d": 6.770000000000001,
              "msFromStart": 36751.54800000001
            },
            {
              "lat": 43.7710503,
              "lng": -116.10210906666667,
              "alt": 2051.4558888888887,
              "speed2d": 6.853333333333333,
              "speed3d": 6.892222222222223,
              "msFromStart": 37246.726222222234
            },
            {
              "lat": 43.7710352,
              "lng": -116.1021471111111,
              "alt": 2051.146888888889,
              "speed2d": 6.800444444444443,
              "speed3d": 6.857777777777778,
              "msFromStart": 37742.47333333337
            },
            {
              "lat": 43.77102059999999,
              "lng": -116.10218648888889,
              "alt": 2050.7694444444446,
              "speed2d": 6.956444444444444,
              "speed3d": 6.982222222222222,
              "msFromStart": 38238.211777777804
            },
            {
              "lat": 43.771005933333335,
              "lng": -116.1022286888889,
              "alt": 2050.4016666666666,
              "speed2d": 7.197777777777777,
              "speed3d": 7.207777777777778,
              "msFromStart": 38733.68800000003
            },
            {
              "lat": 43.77099325555557,
              "lng": -116.10227382222223,
              "alt": 2050.177888888889,
              "speed2d": 7.396555555555556,
              "speed3d": 7.427777777777777,
              "msFromStart": 39229.062814814824
            },
            {
              "lat": 43.77098112,
              "lng": -116.10232194000001,
              "alt": 2049.7966,
              "speed2d": 7.5786999999999995,
              "speed3d": 7.606999999999999,
              "msFromStart": 39751.639333333296
            },
            {
              "lat": 43.770971,
              "lng": -116.10237161111112,
              "alt": 2049.3165555555556,
              "speed2d": 7.694333333333333,
              "speed3d": 7.708888888888889,
              "msFromStart": 40274.11785185184
            },
            {
              "lat": 43.77096327777778,
              "lng": -116.10241834444443,
              "alt": 2048.4058888888885,
              "speed2d": 7.882333333333333,
              "speed3d": 7.908888888888888,
              "msFromStart": 40768.909555555554
            },
            {
              "lat": 43.770957188888886,
              "lng": -116.10246595555554,
              "alt": 2047.5565555555556,
              "speed2d": 7.943444444444445,
              "speed3d": 8.014444444444447,
              "msFromStart": 41263.460214424944
            },
            {
              "lat": 43.77095203333333,
              "lng": -116.10251603333333,
              "alt": 2046.8983333333335,
              "speed2d": 8.102777777777778,
              "speed3d": 8.145555555555557,
              "msFromStart": 41757.382947368416
            },
            {
              "lat": 43.770947400000004,
              "lng": -116.10256794444443,
              "alt": 2046.4755555555557,
              "speed2d": 8.251111111111111,
              "speed3d": 8.32,
              "msFromStart": 42251.55343079922
            },
            {
              "lat": 43.77094376666666,
              "lng": -116.10261848888888,
              "alt": 2045.847777777778,
              "speed2d": 8.219777777777779,
              "speed3d": 8.268888888888888,
              "msFromStart": 42746.847111111114
            },
            {
              "lat": 43.77094154444444,
              "lng": -116.1026704,
              "alt": 2045.2066666666667,
              "speed2d": 8.269111111111112,
              "speed3d": 8.356666666666666,
              "msFromStart": 43242.17792592592
            },
            {
              "lat": 43.770941111111114,
              "lng": -116.10272471111112,
              "alt": 2044.9525555555551,
              "speed2d": 8.381666666666666,
              "speed3d": 8.411111111111111,
              "msFromStart": 43737.208
            },
            {
              "lat": 43.770937588888884,
              "lng": -116.10278129999999,
              "alt": 2044.937777777778,
              "speed2d": 8.64488888888889,
              "speed3d": 8.653333333333334,
              "msFromStart": 44232.354666666666
            },
            {
              "lat": 43.7709293,
              "lng": -116.10283704444443,
              "alt": 2044.665,
              "speed2d": 8.834888888888887,
              "speed3d": 8.898888888888889,
              "msFromStart": 44728.08800000003
            },
            {
              "lat": 43.77091881,
              "lng": -116.10289267,
              "alt": 2044.4106,
              "speed2d": 8.6328,
              "speed3d": 8.698,
              "msFromStart": 45251.05020000003
            },
            {
              "lat": 43.77090483333333,
              "lng": -116.10294742222223,
              "alt": 2044.4584444444442,
              "speed2d": 8.420666666666667,
              "speed3d": 8.49,
              "msFromStart": 45772.80200000004
            },
            {
              "lat": 43.77088842222223,
              "lng": -116.10299742222222,
              "alt": 2044.6342222222224,
              "speed2d": 8.34211111111111,
              "speed3d": 8.38,
              "msFromStart": 46267.413228070196
            },
            {
              "lat": 43.7708700888889,
              "lng": -116.10304526666667,
              "alt": 2044.6732222222224,
              "speed2d": 8.25611111111111,
              "speed3d": 8.283333333333333,
              "msFromStart": 46763.1677894737
            },
            {
              "lat": 43.770849677777775,
              "lng": -116.10309048888891,
              "alt": 2044.5193333333332,
              "speed2d": 8.251999999999999,
              "speed3d": 8.270000000000001,
              "msFromStart": 47259.00053801171
            },
            {
              "lat": 43.77082631111111,
              "lng": -116.10313338888889,
              "alt": 2044.2943333333333,
              "speed2d": 8.334222222222223,
              "speed3d": 8.355555555555556,
              "msFromStart": 47754.90533333333
            },
            {
              "lat": 43.77080219999999,
              "lng": -116.10317073333333,
              "alt": 2043.8796666666665,
              "speed2d": 8.313555555555556,
              "speed3d": 8.336666666666666,
              "msFromStart": 48250.59214814815
            },
            {
              "lat": 43.77077648888889,
              "lng": -116.10320878888889,
              "alt": 2043.5305555555556,
              "speed2d": 8.455,
              "speed3d": 8.47888888888889,
              "msFromStart": 48745.37822222225
            },
            {
              "lat": 43.770750122222225,
              "lng": -116.10324454444446,
              "alt": 2043.2953333333335,
              "speed2d": 8.324333333333334,
              "speed3d": 8.374444444444444,
              "msFromStart": 49240.13422222224
            },
            {
              "lat": 43.77072332222223,
              "lng": -116.10327656666668,
              "alt": 2043.0593333333334,
              "speed2d": 8.13011111111111,
              "speed3d": 8.178888888888888,
              "msFromStart": 49735.13022222225
            },
            {
              "lat": 43.77069632222223,
              "lng": -116.10330679999998,
              "alt": 2042.728111111111,
              "speed2d": 8.018888888888888,
              "speed3d": 8.037777777777777,
              "msFromStart": 50229.85874074076
            },
            {
              "lat": 43.770665529999995,
              "lng": -116.10333317,
              "alt": 2042.0459999999998,
              "speed2d": 7.9904,
              "speed3d": 8.042,
              "msFromStart": 50750.835000000014
            },
            {
              "lat": 43.77063408888889,
              "lng": -116.10336662222223,
              "alt": 2041.9404444444442,
              "speed2d": 7.896333333333333,
              "speed3d": 7.932222222222222,
              "msFromStart": 51272.40457309942
            },
            {
              "lat": 43.77060002222222,
              "lng": -116.10339787777777,
              "alt": 2042.1794444444445,
              "speed2d": 8.022222222222222,
              "speed3d": 8.045555555555556,
              "msFromStart": 51768.48736842104
            },
            {
              "lat": 43.77056526666667,
              "lng": -116.10342517777777,
              "alt": 2042.5164444444445,
              "speed2d": 8.106555555555556,
              "speed3d": 8.133333333333333,
              "msFromStart": 52264.6204288499
            },
            {
              "lat": 43.77053363333333,
              "lng": -116.10344604444444,
              "alt": 2042.3752222222222,
              "speed2d": 7.969444444444445,
              "speed3d": 7.998888888888889,
              "msFromStart": 52760.5488888889
            },
            {
              "lat": 43.77049976666667,
              "lng": -116.10346597777777,
              "alt": 2042.0537777777777,
              "speed2d": 8.048222222222222,
              "speed3d": 8.06,
              "msFromStart": 53256.25948148148
            },
            {
              "lat": 43.77046373333334,
              "lng": -116.10348736666666,
              "alt": 2042.029222222222,
              "speed2d": 8.050555555555556,
              "speed3d": 8.107777777777779,
              "msFromStart": 53751.18044444446
            },
            {
              "lat": 43.77043008888888,
              "lng": -116.10350916666667,
              "alt": 2042.1475555555555,
              "speed2d": 7.870222222222222,
              "speed3d": 7.894444444444444,
              "msFromStart": 54245.938
            },
            {
              "lat": 43.770396088888894,
              "lng": -116.10353168888886,
              "alt": 2042.5500000000002,
              "speed2d": 7.823666666666667,
              "speed3d": 7.851111111111113,
              "msFromStart": 54740.35777777776
            },
            {
              "lat": 43.77036483333333,
              "lng": -116.1035530888889,
              "alt": 2042.6491111111113,
              "speed2d": 7.632000000000001,
              "speed3d": 7.677777777777777,
              "msFromStart": 55234.57562962962
            },
            {
              "lat": 43.7703352111111,
              "lng": -116.10357045555556,
              "alt": 2042.4334444444446,
              "speed2d": 7.349555555555555,
              "speed3d": 7.405555555555556,
              "msFromStart": 55728.12088888885
            },
            {
              "lat": 43.77030448,
              "lng": -116.10358375,
              "alt": 2042.0357000000001,
              "speed2d": 7.076600000000001,
              "speed3d": 7.084999999999999,
              "msFromStart": 56249.27131929822
            },
            {
              "lat": 43.77026951111111,
              "lng": -116.10360213333334,
              "alt": 2042.0881111111112,
              "speed2d": 7.283111111111111,
              "speed3d": 7.307777777777778,
              "msFromStart": 56771.2538947368
            },
            {
              "lat": 43.77024042222223,
              "lng": -116.10362172222224,
              "alt": 2041.994,
              "speed2d": 7.145000000000001,
              "speed3d": 7.167777777777777,
              "msFromStart": 57266.244120857686
            },
            {
              "lat": 43.77021217777778,
              "lng": -116.10364242222224,
              "alt": 2041.5934444444447,
              "speed2d": 7.093333333333334,
              "speed3d": 7.134444444444444,
              "msFromStart": 57762.87777777779
            },
            {
              "lat": 43.77018253333333,
              "lng": -116.10365754444445,
              "alt": 2041.4332222222224,
              "speed2d": 6.919777777777779,
              "speed3d": 6.957777777777778,
              "msFromStart": 58259.41096296298
            },
            {
              "lat": 43.77015185555556,
              "lng": -116.10367183333334,
              "alt": 2041.1942222222224,
              "speed2d": 6.966222222222223,
              "speed3d": 6.9733333333333345,
              "msFromStart": 58754.88488888892
            },
            {
              "lat": 43.77012189999999,
              "lng": -116.10368847777777,
              "alt": 2041.184,
              "speed2d": 6.882,
              "speed3d": 6.904444444444445,
              "msFromStart": 59250.22585185186
            },
            {
              "lat": 43.770089588888894,
              "lng": -116.10370301111112,
              "alt": 2040.996111111111,
              "speed2d": 7.104777777777778,
              "speed3d": 7.112222222222223,
              "msFromStart": 59745.45866666665
            },
            {
              "lat": 43.77005784444444,
              "lng": -116.10371552222222,
              "alt": 2041.0696666666668,
              "speed2d": 7.0647777777777785,
              "speed3d": 7.105555555555555,
              "msFromStart": 60240.70548148148
            },
            {
              "lat": 43.77002935555556,
              "lng": -116.10373033333333,
              "alt": 2040.8999999999999,
              "speed2d": 6.848000000000001,
              "speed3d": 6.876666666666667,
              "msFromStart": 60736.05155555559
            },
            {
              "lat": 43.770000599999996,
              "lng": -116.10374707777777,
              "alt": 2040.400888888889,
              "speed2d": 6.90088888888889,
              "speed3d": 6.9077777777777785,
              "msFromStart": 61231.446814814844
            },
            {
              "lat": 43.769976566666664,
              "lng": -116.10376555555555,
              "alt": 2039.2133333333334,
              "speed2d": 6.679222222222222,
              "speed3d": 6.746666666666667,
              "msFromStart": 61726.999111111145
            },
            {
              "lat": 43.7699533,
              "lng": -116.10378687,
              "alt": 2038.1016,
              "speed2d": 6.353899999999999,
              "speed3d": 6.451,
              "msFromStart": 62249.85472280704
            },
            {
              "lat": 43.76993404444445,
              "lng": -116.10381262222222,
              "alt": 2037.212,
              "speed2d": 5.972999999999999,
              "speed3d": 6.061111111111112,
              "msFromStart": 62771.920421052644
            },
            {
              "lat": 43.769915455555555,
              "lng": -116.10383342222222,
              "alt": 2036.3339999999998,
              "speed2d": 5.643666666666666,
              "speed3d": 5.78888888888889,
              "msFromStart": 63266.3695165692
            },
            {
              "lat": 43.76990058888889,
              "lng": -116.10385532222222,
              "alt": 2035.542777777778,
              "speed2d": 5.212888888888889,
              "speed3d": 5.329999999999999,
              "msFromStart": 63760.52488888885
            },
            {
              "lat": 43.76988768888889,
              "lng": -116.10388447777777,
              "alt": 2034.8053333333332,
              "speed2d": 5.329777777777779,
              "speed3d": 5.442222222222222,
              "msFromStart": 64255.07348148146
            },
            {
              "lat": 43.76987257777778,
              "lng": -116.1039126222222,
              "alt": 2034.2184444444447,
              "speed2d": 5.403333333333334,
              "speed3d": 5.47,
              "msFromStart": 64751.312444444455
            },
            {
              "lat": 43.76985121111111,
              "lng": -116.10394295555555,
              "alt": 2033.469888888889,
              "speed2d": 5.908222222222222,
              "speed3d": 5.975555555555555,
              "msFromStart": 65247.387851851854
            },
            {
              "lat": 43.76983055555556,
              "lng": -116.1039706111111,
              "alt": 2032.8905555555557,
              "speed2d": 5.971444444444444,
              "speed3d": 6.097777777777778,
              "msFromStart": 65742.1328888889
            },
            {
              "lat": 43.7698066,
              "lng": -116.10399564444444,
              "alt": 2032.2078888888889,
              "speed2d": 6.221888888888889,
              "speed3d": 6.267777777777778,
              "msFromStart": 66236.6795477583
            },
            {
              "lat": 43.76977865555556,
              "lng": -116.1040209,
              "alt": 2031.2675555555554,
              "speed2d": 6.772444444444445,
              "speed3d": 6.873333333333333,
              "msFromStart": 66730.96484210523
            },
            {
              "lat": 43.76974897777778,
              "lng": -116.10404108888889,
              "alt": 2030.5140000000001,
              "speed2d": 6.867333333333333,
              "speed3d": 6.949999999999999,
              "msFromStart": 67225.39546198827
            },
            {
              "lat": 43.76970892,
              "lng": -116.10404946,
              "alt": 2029.5829,
              "speed2d": 7.4916,
              "speed3d": 7.593,
              "msFromStart": 67748.40800000001
            },
            {
              "lat": 43.76967346666667,
              "lng": -116.10404835555555,
              "alt": 2028.5815555555553,
              "speed2d": 7.447222222222222,
              "speed3d": 7.585555555555556,
              "msFromStart": 68271.46903703704
            },
            {
              "lat": 43.769638922222214,
              "lng": -116.10404,
              "alt": 2027.6151111111112,
              "speed2d": 7.626555555555555,
              "speed3d": 7.751111111111111,
              "msFromStart": 68766.50222222217
            }
          ]
        }
      ]
    },
    {
      "id": "trail-buttercup",
      "pathType": "trail",
      "name": "Buttercup",
      "difficulty": "green",
      "isCatTrack": true,
      "videos": [
        {
          "videoId": "GX010063",
          "pathId": "trail-buttercup",
          "startSec": 7,
          "endSec": 189,
          "id": "video-segment-23",
          "gps": [
            {
              "lat": 43.771491677777774,
              "lng": -116.10305046666667,
              "alt": 2066.1777777777775,
              "speed2d": 2.0763333333333334,
              "speed3d": 2.117777777777778,
              "msFromStart": 7248.488240740741
            },
            {
              "lat": 43.77148695555556,
              "lng": -116.10304353333332,
              "alt": 2066.2005555555556,
              "speed2d": 1.9917777777777776,
              "speed3d": 1.9566666666666666,
              "msFromStart": 7743.708166666664
            },
            {
              "lat": 43.77147432222222,
              "lng": -116.1030353888889,
              "alt": 2066.2480000000005,
              "speed2d": 2.3103333333333333,
              "speed3d": 2.2988888888888894,
              "msFromStart": 8238.62135185185
            },
            {
              "lat": 43.77146535555556,
              "lng": -116.10303632222221,
              "alt": 2066.1426666666666,
              "speed2d": 2.307111111111111,
              "speed3d": 2.2955555555555556,
              "msFromStart": 8732.570111111105
            },
            {
              "lat": 43.771455377777784,
              "lng": -116.10303688888888,
              "alt": 2065.9952222222223,
              "speed2d": 2.3462222222222224,
              "speed3d": 2.3633333333333333,
              "msFromStart": 9226.727411306041
            },
            {
              "lat": 43.77144526,
              "lng": -116.10304119,
              "alt": 2065.8508,
              "speed2d": 2.4144999999999994,
              "speed3d": 2.3840000000000003,
              "msFromStart": 9749.08286842105
            },
            {
              "lat": 43.77143415555556,
              "lng": -116.10304411111112,
              "alt": 2065.6905555555554,
              "speed2d": 2.508111111111111,
              "speed3d": 2.5233333333333334,
              "msFromStart": 10271.548049707602
            },
            {
              "lat": 43.771430433333336,
              "lng": -116.10305135555555,
              "alt": 2065.7031111111114,
              "speed2d": 2.2214444444444443,
              "speed3d": 2.262222222222222,
              "msFromStart": 10766.716166666669
            },
            {
              "lat": 43.77142733333332,
              "lng": -116.10306116666666,
              "alt": 2065.5594444444446,
              "speed2d": 2.0491111111111113,
              "speed3d": 2.0633333333333335,
              "msFromStart": 11262.03401851852
            },
            {
              "lat": 43.77142274444445,
              "lng": -116.10306842222222,
              "alt": 2065.0014444444446,
              "speed2d": 1.816888888888889,
              "speed3d": 1.8466666666666665,
              "msFromStart": 11757.730111111116
            },
            {
              "lat": 43.77141602222221,
              "lng": -116.10307485555555,
              "alt": 2064.351777777778,
              "speed2d": 1.757111111111111,
              "speed3d": 1.7966666666666669,
              "msFromStart": 12253.07264814815
            },
            {
              "lat": 43.771419644444435,
              "lng": -116.10308403333333,
              "alt": 2064.433777777778,
              "speed2d": 1.2673333333333334,
              "speed3d": 1.3244444444444443,
              "msFromStart": 12747.4015
            },
            {
              "lat": 43.77142045555556,
              "lng": -116.10308868888889,
              "alt": 2064.0904444444445,
              "speed2d": 0.9285555555555556,
              "speed3d": 0.9755555555555557,
              "msFromStart": 13242.06874074074
            },
            {
              "lat": 43.7714199,
              "lng": -116.10309071111112,
              "alt": 2063.757666666667,
              "speed2d": 0.6888888888888888,
              "speed3d": 0.7522222222222222,
              "msFromStart": 13737.799277777778
            },
            {
              "lat": 43.77142277777777,
              "lng": -116.10309586666668,
              "alt": 2063.346444444445,
              "speed2d": 0.47077777777777774,
              "speed3d": 0.5322222222222223,
              "msFromStart": 14233.343186159846
            },
            {
              "lat": 43.771430244444446,
              "lng": -116.1031096888889,
              "alt": 2062.4388888888884,
              "speed2d": 0.6435555555555554,
              "speed3d": 0.6844444444444444,
              "msFromStart": 14728.228631578946
            },
            {
              "lat": 43.771434830000004,
              "lng": -116.10312884,
              "alt": 2061.5706,
              "speed2d": 1.3410000000000002,
              "speed3d": 1.31,
              "msFromStart": 15251.014280701756
            },
            {
              "lat": 43.771433555555554,
              "lng": -116.10314767777778,
              "alt": 2060.540555555556,
              "speed2d": 1.8055555555555556,
              "speed3d": 1.862222222222222,
              "msFromStart": 15775.234888888897
            },
            {
              "lat": 43.771436766666675,
              "lng": -116.1031575111111,
              "alt": 2060.5111111111114,
              "speed2d": 1.6821111111111111,
              "speed3d": 1.7655555555555555,
              "msFromStart": 16271.510962962966
            },
            {
              "lat": 43.771441244444446,
              "lng": -116.10316726666669,
              "alt": 2060.608222222222,
              "speed2d": 1.8527777777777779,
              "speed3d": 1.8433333333333333,
              "msFromStart": 16766.50066666666
            },
            {
              "lat": 43.771436411111104,
              "lng": -116.103174,
              "alt": 2060.3686666666667,
              "speed2d": 2.1413333333333333,
              "speed3d": 2.138888888888889,
              "msFromStart": 17260.91322222222
            },
            {
              "lat": 43.771431344444444,
              "lng": -116.10318557777777,
              "alt": 2059.8734444444444,
              "speed2d": 2.5283333333333333,
              "speed3d": 2.4988888888888887,
              "msFromStart": 17753.94300000001
            },
            {
              "lat": 43.77143897777778,
              "lng": -116.10320335555556,
              "alt": 2059.644111111111,
              "speed2d": 2.9754444444444443,
              "speed3d": 2.9633333333333334,
              "msFromStart": 18247.45611111111
            },
            {
              "lat": 43.77144161111111,
              "lng": -116.10321594444444,
              "alt": 2059.404777777778,
              "speed2d": 3.109,
              "speed3d": 3.0999999999999996,
              "msFromStart": 18742.47866666665
            },
            {
              "lat": 43.77145081111111,
              "lng": -116.10323492222221,
              "alt": 2059.242111111111,
              "speed2d": 4.031,
              "speed3d": 3.9288888888888893,
              "msFromStart": 19237.532116959057
            },
            {
              "lat": 43.77146176666667,
              "lng": -116.10325771111111,
              "alt": 2058.8058888888886,
              "speed2d": 4.755000000000001,
              "speed3d": 4.675555555555555,
              "msFromStart": 19732.45378947368
            },
            {
              "lat": 43.77147473333333,
              "lng": -116.10328643333334,
              "alt": 2058.3805555555555,
              "speed2d": 5.678888888888889,
              "speed3d": 5.614444444444445,
              "msFromStart": 20227.360426900585
            },
            {
              "lat": 43.7714878,
              "lng": -116.10330421,
              "alt": 2057.696,
              "speed2d": 5.6464,
              "speed3d": 5.702999999999999,
              "msFromStart": 20749.72750000001
            },
            {
              "lat": 43.77149991111111,
              "lng": -116.10332241111112,
              "alt": 2057.538555555556,
              "speed2d": 5.181444444444443,
              "speed3d": 5.253333333333334,
              "msFromStart": 21272.162370370374
            },
            {
              "lat": 43.77151081111111,
              "lng": -116.10333475555555,
              "alt": 2057.3378888888888,
              "speed2d": 4.719333333333334,
              "speed3d": 4.761111111111111,
              "msFromStart": 21767.29722222223
            },
            {
              "lat": 43.77151616666667,
              "lng": -116.1033541,
              "alt": 2056.8971111111114,
              "speed2d": 4.838111111111111,
              "speed3d": 4.834444444444444,
              "msFromStart": 22262.269037037044
            },
            {
              "lat": 43.77152177777778,
              "lng": -116.10337023333332,
              "alt": 2056.3533333333335,
              "speed2d": 4.928444444444445,
              "speed3d": 4.94,
              "msFromStart": 22756.770777777794
            },
            {
              "lat": 43.77152355555555,
              "lng": -116.10337852222223,
              "alt": 2055.8347777777776,
              "speed2d": 4.597888888888888,
              "speed3d": 4.683333333333334,
              "msFromStart": 23251.447148148152
            },
            {
              "lat": 43.77152436666667,
              "lng": -116.10338406666666,
              "alt": 2055.2568888888886,
              "speed2d": 4.124444444444444,
              "speed3d": 4.1755555555555555,
              "msFromStart": 23746.66466666665
            },
            {
              "lat": 43.771528700000005,
              "lng": -116.10339772222221,
              "alt": 2054.5716666666667,
              "speed2d": 4.040111111111112,
              "speed3d": 4.072222222222222,
              "msFromStart": 24241.850518518513
            },
            {
              "lat": 43.7715275,
              "lng": -116.10341392222224,
              "alt": 2054.1613333333335,
              "speed2d": 3.840555555555556,
              "speed3d": 3.8755555555555556,
              "msFromStart": 24736.873111111105
            },
            {
              "lat": 43.77154180000001,
              "lng": -116.10344436666665,
              "alt": 2054.090111111111,
              "speed2d": 4.602333333333333,
              "speed3d": 4.475555555555555,
              "msFromStart": 25231.769270955163
            },
            {
              "lat": 43.7715585,
              "lng": -116.10347576666666,
              "alt": 2053.935444444445,
              "speed2d": 4.965444444444444,
              "speed3d": 5.021111111111111,
              "msFromStart": 25726.345578947366
            },
            {
              "lat": 43.77155698,
              "lng": -116.10350202999999,
              "alt": 2053.3177,
              "speed2d": 4.6552999999999995,
              "speed3d": 4.705,
              "msFromStart": 26248.83406842105
            },
            {
              "lat": 43.771555522222215,
              "lng": -116.10351887777776,
              "alt": 2052.648111111111,
              "speed2d": 4.167222222222223,
              "speed3d": 4.26,
              "msFromStart": 26772.79100000002
            },
            {
              "lat": 43.771550377777785,
              "lng": -116.1035337222222,
              "alt": 2052.5758888888886,
              "speed2d": 3.77,
              "speed3d": 3.818888888888889,
              "msFromStart": 27268.828370370382
            },
            {
              "lat": 43.77154614444445,
              "lng": -116.10355003333333,
              "alt": 2053.104111111111,
              "speed2d": 3.6393333333333335,
              "speed3d": 3.6522222222222225,
              "msFromStart": 27763.601888888912
            },
            {
              "lat": 43.77154361111111,
              "lng": -116.10356924444446,
              "alt": 2053.291666666667,
              "speed2d": 3.6358888888888883,
              "speed3d": 3.6533333333333338,
              "msFromStart": 28258.44396296297
            },
            {
              "lat": 43.77154153333334,
              "lng": -116.10359144444445,
              "alt": 2053.474888888889,
              "speed2d": 3.6443333333333334,
              "speed3d": 3.650000000000001,
              "msFromStart": 28753.652666666683
            },
            {
              "lat": 43.771537922222215,
              "lng": -116.10361499999999,
              "alt": 2053.6925555555554,
              "speed2d": 3.849111111111112,
              "speed3d": 3.8288888888888892,
              "msFromStart": 29248.880994152052
            },
            {
              "lat": 43.77154238888889,
              "lng": -116.10363627777778,
              "alt": 2053.527111111111,
              "speed2d": 4.045777777777778,
              "speed3d": 4.023333333333333,
              "msFromStart": 29744.11021052632
            },
            {
              "lat": 43.77154235555555,
              "lng": -116.10366704444444,
              "alt": 2053.1675555555557,
              "speed2d": 4.639333333333333,
              "speed3d": 4.554444444444444,
              "msFromStart": 30239.253499025344
            },
            {
              "lat": 43.77153965555555,
              "lng": -116.10369891111111,
              "alt": 2052.853333333333,
              "speed2d": 5.049222222222223,
              "speed3d": 5.051111111111111,
              "msFromStart": 30734.052888888895
            },
            {
              "lat": 43.77154171111111,
              "lng": -116.10372118888888,
              "alt": 2052.601111111111,
              "speed2d": 4.925444444444444,
              "speed3d": 4.946666666666666,
              "msFromStart": 31228.714740740746
            },
            {
              "lat": 43.771543470000005,
              "lng": -116.10374360000002,
              "alt": 2052.4166,
              "speed2d": 4.7391,
              "speed3d": 4.768999999999999,
              "msFromStart": 31750.420000000006
            },
            {
              "lat": 43.7715438111111,
              "lng": -116.10376357777777,
              "alt": 2051.7238888888887,
              "speed2d": 4.376333333333333,
              "speed3d": 4.477777777777778,
              "msFromStart": 32272.58774074074
            },
            {
              "lat": 43.77154864444445,
              "lng": -116.10376687777779,
              "alt": 2051.3155555555554,
              "speed2d": 3.327,
              "speed3d": 3.4344444444444444,
              "msFromStart": 32768.66677777777
            },
            {
              "lat": 43.771556588888885,
              "lng": -116.10376790000001,
              "alt": 2051.807111111111,
              "speed2d": 2.5744444444444445,
              "speed3d": 2.6533333333333324,
              "msFromStart": 33264.47866666667
            },
            {
              "lat": 43.77156938888889,
              "lng": -116.1037653111111,
              "alt": 2052.2037777777778,
              "speed2d": 2.2143333333333333,
              "speed3d": 2.221111111111111,
              "msFromStart": 33759.37011111114
            },
            {
              "lat": 43.771588288888886,
              "lng": -116.10376765555554,
              "alt": 2052.172111111111,
              "speed2d": 2.5005555555555556,
              "speed3d": 2.471111111111111,
              "msFromStart": 34254.20665692008
            },
            {
              "lat": 43.77160272222223,
              "lng": -116.10376495555556,
              "alt": 2052.299666666667,
              "speed2d": 2.6353333333333335,
              "speed3d": 2.645555555555555,
              "msFromStart": 34749.025684210515
            },
            {
              "lat": 43.77160724444444,
              "lng": -116.10375667777778,
              "alt": 2052.1685555555555,
              "speed2d": 2.5485555555555557,
              "speed3d": 2.5566666666666666,
              "msFromStart": 35243.85525146198
            },
            {
              "lat": 43.77161622222223,
              "lng": -116.10377827777778,
              "alt": 2051.178,
              "speed2d": 2.7214444444444443,
              "speed3d": 2.6744444444444446,
              "msFromStart": 35738.73066666664
            },
            {
              "lat": 43.771639799999996,
              "lng": -116.10380613333336,
              "alt": 2051.1767777777777,
              "speed2d": 3.601,
              "speed3d": 3.4877777777777776,
              "msFromStart": 36233.57659259259
            },
            {
              "lat": 43.77167097777778,
              "lng": -116.10384315555558,
              "alt": 2050.778555555555,
              "speed2d": 4.7156666666666665,
              "speed3d": 4.609999999999999,
              "msFromStart": 36728.286222222254
            },
            {
              "lat": 43.771702250000004,
              "lng": -116.10388178999999,
              "alt": 2050.3982,
              "speed2d": 5.532399999999999,
              "speed3d": 5.47,
              "msFromStart": 37250.39440000001
            },
            {
              "lat": 43.771741922222226,
              "lng": -116.10391817777779,
              "alt": 2049.9485555555557,
              "speed2d": 6.398222222222222,
              "speed3d": 6.293333333333333,
              "msFromStart": 37772.272888888874
            },
            {
              "lat": 43.77178131111111,
              "lng": -116.10396313333334,
              "alt": 2049.248888888889,
              "speed2d": 7.302555555555555,
              "speed3d": 7.229999999999999,
              "msFromStart": 38267.06466666666
            },
            {
              "lat": 43.77181455555556,
              "lng": -116.10400433333332,
              "alt": 2048.971444444444,
              "speed2d": 7.7943333333333324,
              "speed3d": 7.756666666666667,
              "msFromStart": 38762.941555555524
            },
            {
              "lat": 43.77185184444445,
              "lng": -116.10404704444444,
              "alt": 2048.704555555555,
              "speed2d": 8.311222222222222,
              "speed3d": 8.262222222222222,
              "msFromStart": 39258.763582846
            },
            {
              "lat": 43.7718715,
              "lng": -116.10408323333334,
              "alt": 2046.6256666666668,
              "speed2d": 8.012777777777776,
              "speed3d": 8.151111111111112,
              "msFromStart": 39754.28168421053
            },
            {
              "lat": 43.77186372222222,
              "lng": -116.10411397777776,
              "alt": 2041.6480000000001,
              "speed2d": 6.996555555555556,
              "speed3d": 7.122222222222222,
              "msFromStart": 40249.69784405459
            },
            {
              "lat": 43.77185083333334,
              "lng": -116.10415273333334,
              "alt": 2037.088111111111,
              "speed2d": 6.160111111111112,
              "speed3d": 6.282222222222223,
              "msFromStart": 40744.77022222226
            },
            {
              "lat": 43.77185523333334,
              "lng": -116.10419567777778,
              "alt": 2033.380111111111,
              "speed2d": 5.8629999999999995,
              "speed3d": 6.025555555555556,
              "msFromStart": 41239.87451851853
            },
            {
              "lat": 43.77184842222222,
              "lng": -116.10423523333334,
              "alt": 2028.3828888888888,
              "speed2d": 5.178222222222222,
              "speed3d": 5.432222222222221,
              "msFromStart": 41735.244000000006
            },
            {
              "lat": 43.77183872222223,
              "lng": -116.10427449999999,
              "alt": 2022.915111111111,
              "speed2d": 4.996444444444444,
              "speed3d": 5.220000000000001,
              "msFromStart": 42230.42296296297
            },
            {
              "lat": 43.77184871,
              "lng": -116.10432864,
              "alt": 2018.8032999999998,
              "speed2d": 5.534300000000001,
              "speed3d": 5.726999999999999,
              "msFromStart": 42752.190666666684
            },
            {
              "lat": 43.77187153333333,
              "lng": -116.10439793333333,
              "alt": 2014.6255555555556,
              "speed2d": 6.390888888888889,
              "speed3d": 6.595555555555556,
              "msFromStart": 43274.20703703704
            },
            {
              "lat": 43.77188973333334,
              "lng": -116.104471,
              "alt": 2010.5494444444444,
              "speed2d": 7.2252222222222215,
              "speed3d": 7.462222222222222,
              "msFromStart": 43769.67711111111
            },
            {
              "lat": 43.77190394444444,
              "lng": -116.1045288,
              "alt": 2008.6034444444444,
              "speed2d": 7.399333333333333,
              "speed3d": 7.753333333333334,
              "msFromStart": 44265.0882962963
            },
            {
              "lat": 43.77192345555555,
              "lng": -116.10457802222223,
              "alt": 2007.5274444444444,
              "speed2d": 7.387444444444444,
              "speed3d": 7.674444444444445,
              "msFromStart": 44760.20955555555
            },
            {
              "lat": 43.771945711111115,
              "lng": -116.10462788888888,
              "alt": 2006.1705555555557,
              "speed2d": 7.398,
              "speed3d": 7.736666666666666,
              "msFromStart": 45255.04344249513
            },
            {
              "lat": 43.771965188888885,
              "lng": -116.10466498888889,
              "alt": 2005.8948888888885,
              "speed2d": 6.991111111111111,
              "speed3d": 7.343333333333334,
              "msFromStart": 45749.13873684211
            },
            {
              "lat": 43.77198551111111,
              "lng": -116.10470421111114,
              "alt": 2005.1093333333333,
              "speed2d": 6.766222222222223,
              "speed3d": 7.088888888888889,
              "msFromStart": 46243.39767251462
            },
            {
              "lat": 43.772000866666666,
              "lng": -116.10474293333331,
              "alt": 2004.040777777778,
              "speed2d": 6.486888888888889,
              "speed3d": 6.852222222222222,
              "msFromStart": 46738.46666666663
            },
            {
              "lat": 43.77202641111111,
              "lng": -116.10476404444442,
              "alt": 2006.4025555555554,
              "speed2d": 6.291555555555556,
              "speed3d": 6.60111111111111,
              "msFromStart": 47233.472592592574
            },
            {
              "lat": 43.772050722222225,
              "lng": -116.10478137777778,
              "alt": 2009.3450000000003,
              "speed2d": 6.077444444444445,
              "speed3d": 6.386666666666666,
              "msFromStart": 47727.90222222225
            },
            {
              "lat": 43.77207361,
              "lng": -116.10480272000001,
              "alt": 2012.6267,
              "speed2d": 6.015999999999999,
              "speed3d": 6.247,
              "msFromStart": 48249.84480000002
            },
            {
              "lat": 43.77209013333333,
              "lng": -116.1048311111111,
              "alt": 2016.155888888889,
              "speed2d": 6.219222222222222,
              "speed3d": 6.394444444444445,
              "msFromStart": 48772.128888888896
            },
            {
              "lat": 43.772097277777775,
              "lng": -116.10486653333332,
              "alt": 2017.3517777777777,
              "speed2d": 6.307777777777778,
              "speed3d": 6.475555555555557,
              "msFromStart": 49267.11985185186
            },
            {
              "lat": 43.77210567777777,
              "lng": -116.10490366666666,
              "alt": 2017.6259999999997,
              "speed2d": 6.356333333333332,
              "speed3d": 6.523333333333333,
              "msFromStart": 49762.564000000035
            },
            {
              "lat": 43.7721187,
              "lng": -116.10493303333334,
              "alt": 2018.2874444444444,
              "speed2d": 6.029,
              "speed3d": 6.242222222222223,
              "msFromStart": 50258.10681481483
            },
            {
              "lat": 43.77212928888889,
              "lng": -116.10496680000001,
              "alt": 2019.3139999999999,
              "speed2d": 6.094777777777779,
              "speed3d": 6.212222222222222,
              "msFromStart": 50753.94888888889
            },
            {
              "lat": 43.77213617777778,
              "lng": -116.10500546666665,
              "alt": 2019.7217777777778,
              "speed2d": 6.205555555555556,
              "speed3d": 6.362222222222224,
              "msFromStart": 51249.71681481482
            },
            {
              "lat": 43.772143033333336,
              "lng": -116.10503399999999,
              "alt": 2020.3554444444444,
              "speed2d": 5.861444444444445,
              "speed3d": 6.054444444444444,
              "msFromStart": 51745.06844444443
            },
            {
              "lat": 43.77215132222222,
              "lng": -116.10506758888889,
              "alt": 2020.7456666666667,
              "speed2d": 5.746222222222222,
              "speed3d": 5.885555555555556,
              "msFromStart": 52240.31251851851
            },
            {
              "lat": 43.77215658888888,
              "lng": -116.1051038,
              "alt": 2020.8751111111114,
              "speed2d": 5.809777777777777,
              "speed3d": 5.960000000000001,
              "msFromStart": 52735.29288888891
            },
            {
              "lat": 43.77216336666667,
              "lng": -116.10514104444445,
              "alt": 2021.1572222222221,
              "speed2d": 5.898,
              "speed3d": 5.988888888888889,
              "msFromStart": 53230.17503703705
            },
            {
              "lat": 43.772166150000004,
              "lng": -116.10518511,
              "alt": 2021.0190999999998,
              "speed2d": 6.216500000000001,
              "speed3d": 6.295999999999999,
              "msFromStart": 53752.2456666667
            },
            {
              "lat": 43.77216789999999,
              "lng": -116.10523387777779,
              "alt": 2020.7792222222222,
              "speed2d": 6.5582222222222235,
              "speed3d": 6.651111111111112,
              "msFromStart": 54274.35930214425
            },
            {
              "lat": 43.77216803333333,
              "lng": -116.10527341111109,
              "alt": 2020.232111111111,
              "speed2d": 6.42188888888889,
              "speed3d": 6.556666666666667,
              "msFromStart": 54769.19178947369
            },
            {
              "lat": 43.77217054444445,
              "lng": -116.1053197111111,
              "alt": 2019.7504444444446,
              "speed2d": 6.494222222222223,
              "speed3d": 6.572222222222223,
              "msFromStart": 55263.93587134504
            },
            {
              "lat": 43.772172222222224,
              "lng": -116.10536442222221,
              "alt": 2018.8,
              "speed2d": 6.788222222222222,
              "speed3d": 6.873333333333333,
              "msFromStart": 55758.28533333332
            },
            {
              "lat": 43.772177688888895,
              "lng": -116.10541646666667,
              "alt": 2018.2697777777778,
              "speed2d": 7.009333333333332,
              "speed3d": 7.1033333333333335,
              "msFromStart": 56252.80496296296
            },
            {
              "lat": 43.77217582222222,
              "lng": -116.10546454444446,
              "alt": 2017.426666666667,
              "speed2d": 7.217444444444445,
              "speed3d": 7.290000000000001,
              "msFromStart": 56748.16311111112
            },
            {
              "lat": 43.772167288888895,
              "lng": -116.10550707777779,
              "alt": 2016.0058888888889,
              "speed2d": 7.3664444444444435,
              "speed3d": 7.503333333333334,
              "msFromStart": 57243.784
            },
            {
              "lat": 43.7721479,
              "lng": -116.1055135111111,
              "alt": 2013.371222222222,
              "speed2d": 6.805555555555555,
              "speed3d": 7.017777777777778,
              "msFromStart": 57740.12044444445
            },
            {
              "lat": 43.77212404444444,
              "lng": -116.1054985,
              "alt": 2008.931111111111,
              "speed2d": 6.195777777777779,
              "speed3d": 6.463333333333333,
              "msFromStart": 58236.14177777778
            },
            {
              "lat": 43.77210222222222,
              "lng": -116.10549017777777,
              "alt": 2004.4356666666667,
              "speed2d": 5.9639999999999995,
              "speed3d": 6.259999999999999,
              "msFromStart": 58730.616444444415
            },
            {
              "lat": 43.772078359999995,
              "lng": -116.10549724999998,
              "alt": 2001.8020000000001,
              "speed2d": 5.9052,
              "speed3d": 6.223,
              "msFromStart": 59252.477754385945
            },
            {
              "lat": 43.772060366666665,
              "lng": -116.10551562222224,
              "alt": 1999.1355555555556,
              "speed2d": 6.204888888888889,
              "speed3d": 6.488888888888889,
              "msFromStart": 59774.59515789471
            },
            {
              "lat": 43.77204555555556,
              "lng": -116.10552816666666,
              "alt": 1996.5766666666668,
              "speed2d": 6.213666666666666,
              "speed3d": 6.5855555555555565,
              "msFromStart": 60269.33874463936
            },
            {
              "lat": 43.772043999999994,
              "lng": -116.10554315555554,
              "alt": 1993.721,
              "speed2d": 6.039333333333333,
              "speed3d": 6.434444444444445,
              "msFromStart": 60764.42088888887
            },
            {
              "lat": 43.77204084444445,
              "lng": -116.10557375555554,
              "alt": 1992.0306666666665,
              "speed2d": 6.339000000000001,
              "speed3d": 6.642222222222222,
              "msFromStart": 61259.76651851851
            },
            {
              "lat": 43.77203867777778,
              "lng": -116.10561262222225,
              "alt": 1990.6897777777776,
              "speed2d": 6.881555555555556,
              "speed3d": 7.156666666666666,
              "msFromStart": 61756.03066666668
            },
            {
              "lat": 43.77203744444444,
              "lng": -116.1056519,
              "alt": 1989.2885555555558,
              "speed2d": 7.336444444444446,
              "speed3d": 7.59111111111111,
              "msFromStart": 62252.0151851852
            },
            {
              "lat": 43.77204086666667,
              "lng": -116.10569054444446,
              "alt": 1987.5393333333334,
              "speed2d": 7.6594444444444445,
              "speed3d": 7.917777777777778,
              "msFromStart": 62746.51377777777
            },
            {
              "lat": 43.77205291111111,
              "lng": -116.10572963333333,
              "alt": 1985.4918888888888,
              "speed2d": 7.8806666666666665,
              "speed3d": 8.16,
              "msFromStart": 63240.95377777777
            },
            {
              "lat": 43.772070288888884,
              "lng": -116.10576792222221,
              "alt": 1983.4625555555554,
              "speed2d": 8.001333333333333,
              "speed3d": 8.28,
              "msFromStart": 63735.7537777778
            },
            {
              "lat": 43.7720918,
              "lng": -116.10580360000002,
              "alt": 1981.5094444444446,
              "speed2d": 8.074666666666666,
              "speed3d": 8.385555555555555,
              "msFromStart": 64230.74470955167
            },
            {
              "lat": 43.7721157888889,
              "lng": -116.10582036666668,
              "alt": 1979.0088888888886,
              "speed2d": 7.430666666666666,
              "speed3d": 7.88888888888889,
              "msFromStart": 64726.35536842107
            },
            {
              "lat": 43.77213783,
              "lng": -116.10582237,
              "alt": 1976.3271999999997,
              "speed2d": 6.433400000000001,
              "speed3d": 6.912999999999999,
              "msFromStart": 65249.29626315791
            },
            {
              "lat": 43.772159066666674,
              "lng": -116.10582845555557,
              "alt": 1973.351666666667,
              "speed2d": 5.953333333333333,
              "speed3d": 6.452222222222222,
              "msFromStart": 65770.98133333333
            },
            {
              "lat": 43.77218147777778,
              "lng": -116.10583973333334,
              "alt": 1970.0967777777778,
              "speed2d": 5.883111111111111,
              "speed3d": 6.3933333333333335,
              "msFromStart": 66265.11748148149
            },
            {
              "lat": 43.772201233333334,
              "lng": -116.10587026666668,
              "alt": 1967.9627777777773,
              "speed2d": 6.048888888888888,
              "speed3d": 6.563333333333333,
              "msFromStart": 66759.5662222222
            },
            {
              "lat": 43.772221733333325,
              "lng": -116.10589612222222,
              "alt": 1966.4903333333334,
              "speed2d": 5.835666666666666,
              "speed3d": 6.449999999999999,
              "msFromStart": 67254.14696296296
            },
            {
              "lat": 43.77224454444445,
              "lng": -116.10591469999999,
              "alt": 1964.7277777777779,
              "speed2d": 5.593666666666667,
              "speed3d": 6.155555555555556,
              "msFromStart": 67749.13066666668
            },
            {
              "lat": 43.77227452222222,
              "lng": -116.10594018888888,
              "alt": 1963.1190000000001,
              "speed2d": 5.817444444444444,
              "speed3d": 6.384444444444445,
              "msFromStart": 68244.25170370373
            },
            {
              "lat": 43.77230617777778,
              "lng": -116.10596376666668,
              "alt": 1961.3861111111107,
              "speed2d": 5.989222222222223,
              "speed3d": 6.532222222222223,
              "msFromStart": 68739.76088888897
            },
            {
              "lat": 43.77233848888888,
              "lng": -116.106001,
              "alt": 1959.8928888888886,
              "speed2d": 6.490888888888888,
              "speed3d": 6.967777777777778,
              "msFromStart": 69235.16249512676
            },
            {
              "lat": 43.77237142222223,
              "lng": -116.10604320000002,
              "alt": 1958.3774444444443,
              "speed2d": 7.000111111111111,
              "speed3d": 7.495555555555555,
              "msFromStart": 69729.97852631584
            },
            {
              "lat": 43.77239932222221,
              "lng": -116.10608738888888,
              "alt": 1956.8442222222222,
              "speed2d": 7.302111111111111,
              "speed3d": 7.756666666666667,
              "msFromStart": 70224.95162053284
            },
            {
              "lat": 43.77243077,
              "lng": -116.10614136,
              "alt": 1955.0313999999998,
              "speed2d": 7.7107,
              "speed3d": 8.178,
              "msFromStart": 70748.82355555551
            },
            {
              "lat": 43.77245124444444,
              "lng": -116.10615948888889,
              "alt": 1951.8323333333333,
              "speed2d": 7.439666666666667,
              "speed3d": 8.042222222222222,
              "msFromStart": 71272.62666666665
            },
            {
              "lat": 43.77245942222223,
              "lng": -116.10617404444443,
              "alt": 1949.4362222222223,
              "speed2d": 7.053444444444444,
              "speed3d": 7.7011111111111115,
              "msFromStart": 71767.70844444449
            },
            {
              "lat": 43.772459100000006,
              "lng": -116.10619934444443,
              "alt": 1948.034666666667,
              "speed2d": 6.738333333333334,
              "speed3d": 7.392222222222223,
              "msFromStart": 72262.73200000002
            },
            {
              "lat": 43.772468133333334,
              "lng": -116.10622336666665,
              "alt": 1945.9832222222221,
              "speed2d": 6.618555555555555,
              "speed3d": 7.28,
              "msFromStart": 72757.97777777773
            },
            {
              "lat": 43.772497411111104,
              "lng": -116.10624573333335,
              "alt": 1941.4907777777776,
              "speed2d": 6.814,
              "speed3d": 7.475555555555554,
              "msFromStart": 73252.98281481481
            },
            {
              "lat": 43.77254652222223,
              "lng": -116.10625034444443,
              "alt": 1933.7744444444447,
              "speed2d": 7.01488888888889,
              "speed3d": 7.76,
              "msFromStart": 73746.93600000005
            },
            {
              "lat": 43.772583133333335,
              "lng": -116.10626679999999,
              "alt": 1927.226666666667,
              "speed2d": 7.128888888888889,
              "speed3d": 7.944444444444445,
              "msFromStart": 74240.95101754388
            },
            {
              "lat": 43.772616899999996,
              "lng": -116.10628374444445,
              "alt": 1921.4917777777778,
              "speed2d": 7.203222222222223,
              "speed3d": 8.067777777777778,
              "msFromStart": 74735.63410526322
            },
            {
              "lat": 43.77263378888889,
              "lng": -116.10632665555558,
              "alt": 1918.9194444444445,
              "speed2d": 7.222888888888889,
              "speed3d": 8.107777777777777,
              "msFromStart": 75230.33586484734
            },
            {
              "lat": 43.77264933,
              "lng": -116.10638682999999,
              "alt": 1917.8453,
              "speed2d": 7.2754,
              "speed3d": 8.135,
              "msFromStart": 75752.20844444439
            },
            {
              "lat": 43.77266712222222,
              "lng": -116.10644656666665,
              "alt": 1916.7541111111113,
              "speed2d": 7.363777777777778,
              "speed3d": 8.201111111111112,
              "msFromStart": 76274.20103703701
            },
            {
              "lat": 43.772683844444444,
              "lng": -116.10650844444443,
              "alt": 1915.4978888888888,
              "speed2d": 7.502888888888889,
              "speed3d": 8.313333333333334,
              "msFromStart": 76769.40177777775
            },
            {
              "lat": 43.7727011,
              "lng": -116.10656704444445,
              "alt": 1914.1862222222223,
              "speed2d": 7.592444444444444,
              "speed3d": 8.403333333333334,
              "msFromStart": 77264.67718518518
            },
            {
              "lat": 43.772710266666664,
              "lng": -116.1066094777778,
              "alt": 1912.5156666666667,
              "speed2d": 7.543111111111111,
              "speed3d": 8.357777777777779,
              "msFromStart": 77759.98222222224
            },
            {
              "lat": 43.77272007777778,
              "lng": -116.10664766666667,
              "alt": 1910.0720000000001,
              "speed2d": 7.5586666666666655,
              "speed3d": 8.375555555555556,
              "msFromStart": 78255.19911111111
            },
            {
              "lat": 43.77273066666667,
              "lng": -116.10669021111111,
              "alt": 1907.3018888888892,
              "speed2d": 7.619111111111111,
              "speed3d": 8.446666666666667,
              "msFromStart": 78750.05155555559
            },
            {
              "lat": 43.772736188888885,
              "lng": -116.10673087777778,
              "alt": 1904.8186666666668,
              "speed2d": 7.592888888888889,
              "speed3d": 8.448888888888888,
              "msFromStart": 79244.90794541915
            },
            {
              "lat": 43.772741744444446,
              "lng": -116.10676644444445,
              "alt": 1902.1105555555555,
              "speed2d": 7.501999999999999,
              "speed3d": 8.37,
              "msFromStart": 79739.92589473691
            },
            {
              "lat": 43.77274803333333,
              "lng": -116.10680656666666,
              "alt": 1899.0349999999996,
              "speed2d": 7.488666666666669,
              "speed3d": 8.373333333333331,
              "msFromStart": 80235.12023391818
            },
            {
              "lat": 43.77275918888889,
              "lng": -116.10684114444445,
              "alt": 1895.8096666666665,
              "speed2d": 7.476111111111112,
              "speed3d": 8.383333333333333,
              "msFromStart": 80731.29200000003
            },
            {
              "lat": 43.77277362222222,
              "lng": -116.10687348888891,
              "alt": 1892.099111111111,
              "speed2d": 7.459,
              "speed3d": 8.395555555555557,
              "msFromStart": 81227.43486419754
            },
            {
              "lat": 43.772792179999996,
              "lng": -116.10691054,
              "alt": 1888.3953999999999,
              "speed2d": 7.4545,
              "speed3d": 8.407,
              "msFromStart": 81749.91288888888
            },
            {
              "lat": 43.772810222222226,
              "lng": -116.10697585555556,
              "alt": 1885.8,
              "speed2d": 7.621333333333332,
              "speed3d": 8.564444444444444,
              "msFromStart": 82272.33585185185
            },
            {
              "lat": 43.77283057777779,
              "lng": -116.10702350000001,
              "alt": 1884.2287777777779,
              "speed2d": 7.594444444444446,
              "speed3d": 8.556666666666667,
              "msFromStart": 82767.80622222227
            },
            {
              "lat": 43.77285086666667,
              "lng": -116.10707577777778,
              "alt": 1882.8308888888891,
              "speed2d": 7.6017777777777775,
              "speed3d": 8.546666666666667,
              "msFromStart": 83262.97762962966
            },
            {
              "lat": 43.772873000000004,
              "lng": -116.10713022222222,
              "alt": 1881.2362222222223,
              "speed2d": 7.656333333333333,
              "speed3d": 8.596666666666668,
              "msFromStart": 83756.73866666664
            },
            {
              "lat": 43.77289513333334,
              "lng": -116.10718596666668,
              "alt": 1879.3432222222223,
              "speed2d": 7.7411111111111115,
              "speed3d": 8.655555555555557,
              "msFromStart": 84250.68229239763
            },
            {
              "lat": 43.77292122222223,
              "lng": -116.10724878888888,
              "alt": 1877.2952222222223,
              "speed2d": 7.9431111111111115,
              "speed3d": 8.846666666666666,
              "msFromStart": 84745.92042105258
            },
            {
              "lat": 43.77294325555555,
              "lng": -116.10731111111109,
              "alt": 1875.4285555555557,
              "speed2d": 8.068999999999999,
              "speed3d": 8.95888888888889,
              "msFromStart": 85241.2337309941
            },
            {
              "lat": 43.772972044444444,
              "lng": -116.10737103333334,
              "alt": 1873.5951111111112,
              "speed2d": 8.232333333333333,
              "speed3d": 9.103333333333332,
              "msFromStart": 85736.21599999999
            },
            {
              "lat": 43.77300797777777,
              "lng": -116.10742879999998,
              "alt": 1871.7143333333333,
              "speed2d": 8.447222222222221,
              "speed3d": 9.303333333333333,
              "msFromStart": 86231.07683950615
            },
            {
              "lat": 43.77304462222222,
              "lng": -116.10749118888889,
              "alt": 1869.594111111111,
              "speed2d": 8.701444444444444,
              "speed3d": 9.525555555555556,
              "msFromStart": 86725.52799999996
            },
            {
              "lat": 43.77307809,
              "lng": -116.10756604999999,
              "alt": 1867.2578,
              "speed2d": 8.9769,
              "speed3d": 9.794,
              "msFromStart": 87247.51844444443
            },
            {
              "lat": 43.77311616666667,
              "lng": -116.10763227777778,
              "alt": 1865.2031111111112,
              "speed2d": 9.192555555555556,
              "speed3d": 9.995555555555555,
              "msFromStart": 87770.16533333337
            },
            {
              "lat": 43.77315964444445,
              "lng": -116.10768214444444,
              "alt": 1863.5278888888888,
              "speed2d": 9.364666666666666,
              "speed3d": 10.162222222222223,
              "msFromStart": 88265.44222222225
            },
            {
              "lat": 43.773198711111114,
              "lng": -116.1077359666667,
              "alt": 1861.957222222222,
              "speed2d": 9.497555555555556,
              "speed3d": 10.287777777777777,
              "msFromStart": 88760.91466666662
            },
            {
              "lat": 43.7732392,
              "lng": -116.1077876888889,
              "alt": 1860.399222222222,
              "speed2d": 9.611333333333333,
              "speed3d": 10.383333333333333,
              "msFromStart": 89256.2671345029
            },
            {
              "lat": 43.7732803,
              "lng": -116.10784544444442,
              "alt": 1858.3843333333334,
              "speed2d": 9.762555555555556,
              "speed3d": 10.527777777777779,
              "msFromStart": 89751.0614736842
            },
            {
              "lat": 43.773325111111106,
              "lng": -116.1078946,
              "alt": 1856.3226666666665,
              "speed2d": 9.876777777777779,
              "speed3d": 10.635555555555554,
              "msFromStart": 90245.93450292398
            },
            {
              "lat": 43.77336865555556,
              "lng": -116.10795764444444,
              "alt": 1854.2372222222223,
              "speed2d": 10.103555555555555,
              "speed3d": 10.836666666666666,
              "msFromStart": 90741.62800000004
            },
            {
              "lat": 43.77341204444444,
              "lng": -116.10802086666666,
              "alt": 1851.9048888888888,
              "speed2d": 10.278666666666666,
              "speed3d": 11.01888888888889,
              "msFromStart": 91237.27723456794
            },
            {
              "lat": 43.77346314444445,
              "lng": -116.10807617777778,
              "alt": 1850.2774444444444,
              "speed2d": 10.453444444444443,
              "speed3d": 11.162222222222223,
              "msFromStart": 91731.83600000005
            },
            {
              "lat": 43.77351464444443,
              "lng": -116.10816238888889,
              "alt": 1852.2695555555556,
              "speed2d": 10.810222222222224,
              "speed3d": 11.466666666666667,
              "msFromStart": 92226.29649382719
            },
            {
              "lat": 43.77356058000001,
              "lng": -116.10825221999998,
              "alt": 1855.2426,
              "speed2d": 11.016,
              "speed3d": 11.632,
              "msFromStart": 92748.74977777773
            },
            {
              "lat": 43.773611522222225,
              "lng": -116.10832498888888,
              "alt": 1858.5010000000002,
              "speed2d": 11.111000000000002,
              "speed3d": 11.693333333333333,
              "msFromStart": 93271.3776296296
            },
            {
              "lat": 43.77367141111111,
              "lng": -116.10838054444446,
              "alt": 1861.2817777777777,
              "speed2d": 11.273888888888889,
              "speed3d": 11.79111111111111,
              "msFromStart": 93766.87999999998
            },
            {
              "lat": 43.7737259625,
              "lng": -116.108427525,
              "alt": 1864.5905000000002,
              "speed2d": 11.3795,
              "speed3d": 11.8825,
              "msFromStart": 94234.77333333332
            },
            {
              "lat": 43.77377785,
              "lng": -116.10852030000001,
              "alt": 1875.6575,
              "speed2d": 11.4665,
              "speed3d": 11.864999999999998,
              "msFromStart": 94949.40999999992
            },
            {
              "lat": 43.77379528,
              "lng": -116.10854958000002,
              "alt": 1879.2208000000003,
              "speed2d": 11.492799999999999,
              "speed3d": 11.874,
              "msFromStart": 95185.86821052626
            },
            {
              "lat": 43.773844,
              "lng": -116.1086145,
              "alt": 1886.4465,
              "speed2d": 11.406500000000001,
              "speed3d": 11.745000000000001,
              "msFromStart": 95830.04442105257
            },
            {
              "lat": 43.7738811,
              "lng": -116.1086597,
              "alt": 1890.3635,
              "speed2d": 11.414000000000001,
              "speed3d": 11.727500000000003,
              "msFromStart": 96270.37973684206
            },
            {
              "lat": 43.7739226,
              "lng": -116.10869703333333,
              "alt": 1894.1566666666668,
              "speed2d": 11.495000000000001,
              "speed3d": 11.756666666666668,
              "msFromStart": 96682.64000000003
            },
            {
              "lat": 43.77398848333333,
              "lng": -116.10874148333332,
              "alt": 1899.2910000000002,
              "speed2d": 11.669166666666666,
              "speed3d": 11.901666666666666,
              "msFromStart": 97259.55770370371
            },
            {
              "lat": 43.7740415375,
              "lng": -116.1087650125,
              "alt": 1903.3180000000002,
              "speed2d": 11.7145,
              "speed3d": 11.934999999999999,
              "msFromStart": 97726.45599999995
            },
            {
              "lat": 43.77410406,
              "lng": -116.1087911,
              "alt": 1906.8564,
              "speed2d": 11.814799999999998,
              "speed3d": 12,
              "msFromStart": 98242.98568888886
            },
            {
              "lat": 43.774168037500004,
              "lng": -116.10882219999999,
              "alt": 1909.4372500000002,
              "speed2d": 11.95525,
              "speed3d": 12.12875,
              "msFromStart": 98757.53166666672
            },
            {
              "lat": 43.774238125,
              "lng": -116.10885562499999,
              "alt": 1911.7702499999998,
              "speed2d": 12.03575,
              "speed3d": 12.2075,
              "msFromStart": 99335.72566666668
            },
            {
              "lat": 43.774287324999996,
              "lng": -116.10887650000001,
              "alt": 1913.3290000000002,
              "speed2d": 11.984,
              "speed3d": 12.15,
              "msFromStart": 99762.75377777775
            },
            {
              "lat": 43.77433196,
              "lng": -116.10889702,
              "alt": 1914.5962,
              "speed2d": 11.883,
              "speed3d": 12.058,
              "msFromStart": 100170.490245614
            },
            {
              "lat": 43.77435956766544,
              "lng": -116.10891939760204,
              "alt": 1915.0279407925443,
              "speed2d": 11.641250281193333,
              "speed3d": 11.868182840703014,
              "msFromStart": 100500
            },
            {
              "lat": 43.774451111111105,
              "lng": -116.10894288888889,
              "alt": 1917.303222222222,
              "speed2d": 11.595444444444444,
              "speed3d": 11.736666666666668,
              "msFromStart": 101249.11761923322
            },
            {
              "lat": 43.77450522222222,
              "lng": -116.10896128888888,
              "alt": 1918.5214444444443,
              "speed2d": 11.468222222222222,
              "speed3d": 11.613333333333333,
              "msFromStart": 101744.18799999997
            },
            {
              "lat": 43.774554300000005,
              "lng": -116.10898246250001,
              "alt": 1919.50725,
              "speed2d": 11.363874999999998,
              "speed3d": 11.5025,
              "msFromStart": 102211.67355555552
            },
            {
              "lat": 43.774619666666666,
              "lng": -116.10900851666666,
              "alt": 1920.2316666666666,
              "speed2d": 11.319166666666668,
              "speed3d": 11.439999999999998,
              "msFromStart": 102797.68925925922
            },
            {
              "lat": 43.77464117940931,
              "lng": -116.10901906955743,
              "alt": 1920.4342646976886,
              "speed2d": 11.288092164328237,
              "speed3d": 11.406431379832426,
              "msFromStart": 103000
            },
            {
              "lat": 43.77471008888889,
              "lng": -116.10904978888888,
              "alt": 1921.0476666666668,
              "speed2d": 10.988888888888889,
              "speed3d": 11.136666666666667,
              "msFromStart": 103724.30399999996
            },
            {
              "lat": 43.7747655,
              "lng": -116.10906887142858,
              "alt": 1921.8588571428572,
              "speed2d": 10.819857142857144,
              "speed3d": 10.932857142857143,
              "msFromStart": 104314.98590476188
            },
            {
              "lat": 43.77480288333333,
              "lng": -116.10908063333333,
              "alt": 1922.352,
              "speed2d": 10.767166666666666,
              "speed3d": 10.885,
              "msFromStart": 104688.65333333332
            },
            {
              "lat": 43.77482841237744,
              "lng": -116.10908757294793,
              "alt": 1923.5935079481983,
              "speed2d": 10.564419551341066,
              "speed3d": 10.690709775670536,
              "msFromStart": 105000
            },
            {
              "lat": 43.774904557142854,
              "lng": -116.1091133857143,
              "alt": 1923.1152857142858,
              "speed2d": 10.62657142857143,
              "speed3d": 10.735714285714286,
              "msFromStart": 105705.67115789477
            },
            {
              "lat": 43.77494746666667,
              "lng": -116.10912615,
              "alt": 1924.5194999999997,
              "speed2d": 10.457333333333333,
              "speed3d": 10.59,
              "msFromStart": 106172.16882261213
            },
            {
              "lat": 43.77497184230748,
              "lng": -116.10913192348896,
              "alt": 1925.7423688175784,
              "speed2d": 10.158258241654453,
              "speed3d": 10.308427197931929,
              "msFromStart": 106500
            },
            {
              "lat": 43.775023771509325,
              "lng": -116.10914632594685,
              "alt": 1925.8889902831406,
              "speed2d": 10.205939116614568,
              "speed3d": 10.286433471342491,
              "msFromStart": 107000
            },
            {
              "lat": 43.77507127688281,
              "lng": -116.1091586421548,
              "alt": 1926.5661439551777,
              "speed2d": 10.142218195229068,
              "speed3d": 10.224614667013276,
              "msFromStart": 107500
            },
            {
              "lat": 43.775148400000006,
              "lng": -116.10918005,
              "alt": 1927.5468333333333,
              "speed2d": 10.021333333333333,
              "speed3d": 10.12,
              "msFromStart": 108321.83681481481
            },
            {
              "lat": 43.775187700000004,
              "lng": -116.10919016,
              "alt": 1927.8552000000002,
              "speed2d": 9.935,
              "speed3d": 10.021999999999998,
              "msFromStart": 108754.7041777778
            },
            {
              "lat": 43.7752308,
              "lng": -116.10920300000001,
              "alt": 1928.241,
              "speed2d": 9.96,
              "speed3d": 10.035,
              "msFromStart": 109198.42577777781
            },
            {
              "lat": 43.77525830937376,
              "lng": -116.10921221236055,
              "alt": 1928.7136483706836,
              "speed2d": 9.932737974153556,
              "speed3d": 10.012292131523093,
              "msFromStart": 109500
            },
            {
              "lat": 43.775304792860304,
              "lng": -116.10923012529334,
              "alt": 1929.1181930171704,
              "speed2d": 9.921798167377304,
              "speed3d": 10.006462103872858,
              "msFromStart": 110000
            },
            {
              "lat": 43.775347759009705,
              "lng": -116.10923888864701,
              "alt": 1931.023777350555,
              "speed2d": 9.776006030586617,
              "speed3d": 9.852163777282987,
              "msFromStart": 110500
            },
            {
              "lat": 43.77539072515911,
              "lng": -116.10924765200069,
              "alt": 1932.92936168394,
              "speed2d": 9.630213893795931,
              "speed3d": 9.697865450693115,
              "msFromStart": 111000
            },
            {
              "lat": 43.775433691308514,
              "lng": -116.10925641535437,
              "alt": 1934.834946017325,
              "speed2d": 9.484421757005245,
              "speed3d": 9.543567124103244,
              "msFromStart": 111500
            },
            {
              "lat": 43.77547665745792,
              "lng": -116.10926517870804,
              "alt": 1936.7405303507098,
              "speed2d": 9.338629620214558,
              "speed3d": 9.389268797513372,
              "msFromStart": 112000
            },
            {
              "lat": 43.775545066666666,
              "lng": -116.10927960000001,
              "alt": 1939.5993333333333,
              "speed2d": 9.111333333333334,
              "speed3d": 9.163333333333332,
              "msFromStart": 112797.9155555556
            },
            {
              "lat": 43.775556056035036,
              "lng": -116.10928615431936,
              "alt": 1940.0589757387372,
              "speed2d": 8.871726626836749,
              "speed3d": 9.001591716454067,
              "msFromStart": 113000
            },
            {
              "lat": 43.775610566666664,
              "lng": -116.10930250000001,
              "alt": 1940.1426666666666,
              "speed2d": 8.908666666666667,
              "speed3d": 8.963333333333333,
              "msFromStart": 113623.06222222224
            },
            {
              "lat": 43.775639124855616,
              "lng": -116.10931648962828,
              "alt": 1940.1395193720812,
              "speed2d": 8.850616134190837,
              "speed3d": 8.914172462527889,
              "msFromStart": 114000
            },
            {
              "lat": 43.77567407050801,
              "lng": -116.10933631240243,
              "alt": 1940.092629883158,
              "speed2d": 8.687314941578865,
              "speed3d": 8.731728515789396,
              "msFromStart": 114500
            },
            {
              "lat": 43.775739550000004,
              "lng": -116.10936322500001,
              "alt": 1940.1667499999999,
              "speed2d": 8.748249999999999,
              "speed3d": 8.7925,
              "msFromStart": 115287.71112280697
            },
            {
              "lat": 43.775757943537876,
              "lng": -116.10937054295675,
              "alt": 1940.0995282427255,
              "speed2d": 8.773568456513523,
              "speed3d": 8.810389353776387,
              "msFromStart": 115500
            },
            {
              "lat": 43.775831733333334,
              "lng": -116.10940326666667,
              "alt": 1939.933,
              "speed2d": 8.847,
              "speed3d": 8.879999999999999,
              "msFromStart": 116371.90755555556
            },
            {
              "lat": 43.775865016666664,
              "lng": -116.10941735,
              "alt": 1940.0956666666666,
              "speed2d": 8.913,
              "speed3d": 8.951666666666668,
              "msFromStart": 116748.24503703706
            },
            {
              "lat": 43.77591376666667,
              "lng": -116.1094518,
              "alt": 1940.2069999999999,
              "speed2d": 8.926666666666668,
              "speed3d": 8.98,
              "msFromStart": 117363.30666666666
            },
            {
              "lat": 43.775933699999996,
              "lng": -116.10946615,
              "alt": 1940.141,
              "speed2d": 8.9455,
              "speed3d": 8.98,
              "msFromStart": 117611.29866666663
            },
            {
              "lat": 43.7759975,
              "lng": -116.1095216,
              "alt": 1940.3105,
              "speed2d": 9.014,
              "speed3d": 9.055,
              "msFromStart": 118437.40666666668
            },
            {
              "lat": 43.77601925,
              "lng": -116.10953855,
              "alt": 1940.2955000000002,
              "speed2d": 8.9795,
              "speed3d": 9.025,
              "msFromStart": 118739.67200000005
            },
            {
              "lat": 43.77603966037081,
              "lng": -116.10955253038715,
              "alt": 1940.2900243058364,
              "speed2d": 8.98558103562591,
              "speed3d": 9.003687193751297,
              "msFromStart": 119000
            },
            {
              "lat": 43.77609436666667,
              "lng": -116.10960443333335,
              "alt": 1940.3110000000001,
              "speed2d": 9.069333333333333,
              "speed3d": 9.096666666666668,
              "msFromStart": 119710.67733333327
            },
            {
              "lat": 43.776127849999995,
              "lng": -116.10963770000001,
              "alt": 1940.38775,
              "speed2d": 9.148,
              "speed3d": 9.1825,
              "msFromStart": 120141.25578947361
            },
            {
              "lat": 43.77615596572391,
              "lng": -116.10966804823656,
              "alt": 1940.4008622951071,
              "speed2d": 9.239619917264468,
              "speed3d": 9.247965374593912,
              "msFromStart": 120500
            },
            {
              "lat": 43.77618539267316,
              "lng": -116.1097126000311,
              "alt": 1940.4144352679298,
              "speed2d": 9.197006209398525,
              "speed3d": 9.221288419895926,
              "msFromStart": 121000
            },
            {
              "lat": 43.77622906162171,
              "lng": -116.10975029443011,
              "alt": 1939.9952426351765,
              "speed2d": 9.323633128373352,
              "speed3d": 9.34929717042112,
              "msFromStart": 121500
            },
            {
              "lat": 43.77626215015391,
              "lng": -116.10978357370142,
              "alt": 1939.482059141479,
              "speed2d": 9.205438325617468,
              "speed3d": 9.23909276001648,
              "msFromStart": 122000
            },
            {
              "lat": 43.776305166666674,
              "lng": -116.1098293,
              "alt": 1940.2303333333334,
              "speed2d": 9.099,
              "speed3d": 9.143333333333334,
              "msFromStart": 122679.79422222223
            },
            {
              "lat": 43.77634372857143,
              "lng": -116.1098698142857,
              "alt": 1940.1585714285713,
              "speed2d": 9.031142857142857,
              "speed3d": 9.084285714285713,
              "msFromStart": 123271.90228571431
            },
            {
              "lat": 43.7763688,
              "lng": -116.1099004,
              "alt": 1940.2024999999999,
              "speed2d": 8.928999999999998,
              "speed3d": 8.965,
              "msFromStart": 123716.48333333337
            },
            {
              "lat": 43.77639775,
              "lng": -116.10993563333334,
              "alt": 1940.0413333333333,
              "speed2d": 8.982166666666668,
              "speed3d": 9.01,
              "msFromStart": 124157.19370370374
            },
            {
              "lat": 43.77644138,
              "lng": -116.10998694,
              "alt": 1939.6889999999999,
              "speed2d": 8.9656,
              "speed3d": 9.004,
              "msFromStart": 124845.53111111103
            },
            {
              "lat": 43.77646166666666,
              "lng": -116.11000483333333,
              "alt": 1939.6803333333332,
              "speed2d": 8.932333333333332,
              "speed3d": 8.98,
              "msFromStart": 125139.20340740733
            },
            {
              "lat": 43.77648832263303,
              "lng": -116.1100266426783,
              "alt": 1939.7829945333979,
              "speed2d": 8.926693385088143,
              "speed3d": 8.975438783761929,
              "msFromStart": 125500
            },
            {
              "lat": 43.776547099999995,
              "lng": -116.11007436666665,
              "alt": 1940.0430000000001,
              "speed2d": 8.955666666666668,
              "speed3d": 8.99,
              "msFromStart": 126275.19614814818
            },
            {
              "lat": 43.77656161978676,
              "lng": -116.11009011188041,
              "alt": 1940.0122026990268,
              "speed2d": 8.946855785826465,
              "speed3d": 8.982678496834675,
              "msFromStart": 126500
            },
            {
              "lat": 43.77660242,
              "lng": -116.11013505999999,
              "alt": 1939.7034,
              "speed2d": 8.7396,
              "speed3d": 8.790000000000001,
              "msFromStart": 127230.59946666665
            },
            {
              "lat": 43.776616344737505,
              "lng": -116.11015142437411,
              "alt": 1939.4873902598854,
              "speed2d": 8.649385330255427,
              "speed3d": 8.690181696328851,
              "msFromStart": 127500
            },
            {
              "lat": 43.77663968301943,
              "lng": -116.11017590543045,
              "alt": 1938.907951894323,
              "speed2d": 8.336209415153315,
              "speed3d": 8.384561424342621,
              "msFromStart": 128000
            },
            {
              "lat": 43.77666240492935,
              "lng": -116.11021418413783,
              "alt": 1939.005106259177,
              "speed2d": 8.280004347489571,
              "speed3d": 8.304214564231799,
              "msFromStart": 128500
            },
            {
              "lat": 43.77668294425715,
              "lng": -116.11022273747272,
              "alt": 1938.861,
              "speed2d": 7.8528599480949,
              "speed3d": 7.944288831216555,
              "msFromStart": 129000
            },
            {
              "lat": 43.7767312,
              "lng": -116.11022453333334,
              "alt": 1938.8763333333336,
              "speed2d": 7.487666666666667,
              "speed3d": 7.5566666666666675,
              "msFromStart": 129810.91663157895
            },
            {
              "lat": 43.776757214285716,
              "lng": -116.1102324,
              "alt": 1938.634857142857,
              "speed2d": 7.357714285714286,
              "speed3d": 7.40142857142857,
              "msFromStart": 130271.97658145362
            },
            {
              "lat": 43.776770379208024,
              "lng": -116.1102409087236,
              "alt": 1938.476462768989,
              "speed2d": 7.343388778618451,
              "speed3d": 7.392535717795026,
              "msFromStart": 130500
            },
            {
              "lat": 43.776794001536594,
              "lng": -116.11026094731217,
              "alt": 1938.0555145904023,
              "speed2d": 7.218388633058653,
              "speed3d": 7.244777266117306,
              "msFromStart": 131000
            },
            {
              "lat": 43.776841700000006,
              "lng": -116.11029006666666,
              "alt": 1937.808,
              "speed2d": 7.233333333333333,
              "speed3d": 7.27,
              "msFromStart": 131756.75022222224
            },
            {
              "lat": 43.776882533333335,
              "lng": -116.11030233333332,
              "alt": 1937.7693333333332,
              "speed2d": 7.119,
              "speed3d": 7.180000000000001,
              "msFromStart": 132435.6266666667
            },
            {
              "lat": 43.77689555,
              "lng": -116.1103032,
              "alt": 1937.7665,
              "speed2d": 7.0440000000000005,
              "speed3d": 7.095,
              "msFromStart": 132683.75466666673
            },
            {
              "lat": 43.776917160943135,
              "lng": -116.11029918503687,
              "alt": 1937.7282365224166,
              "speed2d": 7.022912225885315,
              "speed3d": 7.0514874830178265,
              "msFromStart": 133000
            },
            {
              "lat": 43.776969799999996,
              "lng": -116.11030386666667,
              "alt": 1937.5116666666668,
              "speed2d": 7.209666666666667,
              "speed3d": 7.216666666666667,
              "msFromStart": 133647.3466666666
            },
            {
              "lat": 43.776995033867074,
              "lng": -116.11029378571484,
              "alt": 1937.4218517979882,
              "speed2d": 7.232354530563455,
              "speed3d": 7.2848148431639235,
              "msFromStart": 134000
            },
            {
              "lat": 43.77707661428571,
              "lng": -116.11029994285713,
              "alt": 1936.5615714285718,
              "speed2d": 7.781428571428572,
              "speed3d": 7.792857142857143,
              "msFromStart": 134801.8484210525
            },
            {
              "lat": 43.77709946,
              "lng": -116.11029292,
              "alt": 1936.4612000000002,
              "speed2d": 7.7866,
              "speed3d": 7.832000000000001,
              "msFromStart": 135131.901473684
            },
            {
              "lat": 43.77712973333333,
              "lng": -116.11026153333333,
              "alt": 1936.4119999999998,
              "speed2d": 7.656666666666666,
              "speed3d": 7.713333333333334,
              "msFromStart": 135719.133037037
            },
            {
              "lat": 43.77715953333333,
              "lng": -116.11024833333335,
              "alt": 1936.2313333333334,
              "speed2d": 7.652333333333333,
              "speed3d": 7.67,
              "msFromStart": 136178.05155555552
            },
            {
              "lat": 43.7771901400152,
              "lng": -116.11024527282777,
              "alt": 1935.971565168066,
              "speed2d": 7.81440301036356,
              "speed3d": 7.804127286282125,
              "msFromStart": 136500
            },
            {
              "lat": 43.777278425,
              "lng": -116.110216325,
              "alt": 1935.096,
              "speed2d": 8.51,
              "speed3d": 8.495000000000001,
              "msFromStart": 137275.5400000001
            },
            {
              "lat": 43.77730290340388,
              "lng": -116.1102034158111,
              "alt": 1934.8392790860314,
              "speed2d": 8.704885495330808,
              "speed3d": 8.686279362243518,
              "msFromStart": 137500
            },
            {
              "lat": 43.7773845,
              "lng": -116.11015575,
              "alt": 1933.938,
              "speed2d": 9.3305,
              "speed3d": 9.309999999999999,
              "msFromStart": 138265.58755555566
            },
            {
              "lat": 43.77742893333334,
              "lng": -116.11012635,
              "alt": 1933.5773333333332,
              "speed2d": 9.623000000000001,
              "speed3d": 9.629999999999999,
              "msFromStart": 138706.03733333346
            },
            {
              "lat": 43.777458225153175,
              "lng": -116.11010765063462,
              "alt": 1933.2548563628575,
              "speed2d": 9.807815722520818,
              "speed3d": 9.79065972652292,
              "msFromStart": 139000
            },
            {
              "lat": 43.77753993333334,
              "lng": -116.11004620000001,
              "alt": 1932.599333333333,
              "speed2d": 10.289333333333333,
              "speed3d": 10.283333333333333,
              "msFromStart": 139833.78442105278
            },
            {
              "lat": 43.77755574776769,
              "lng": -116.11003256851788,
              "alt": 1932.4265100138846,
              "speed2d": 10.383010890523366,
              "speed3d": 10.380803221327538,
              "msFromStart": 140000
            },
            {
              "lat": 43.77765005,
              "lng": -116.1099792,
              "alt": 1931.4025,
              "speed2d": 10.7015,
              "speed3d": 10.71,
              "msFromStart": 140934.01317647047
            },
            {
              "lat": 43.77767016666667,
              "lng": -116.10996023333334,
              "alt": 1931.1496666666667,
              "speed2d": 10.775,
              "speed3d": 10.79,
              "msFromStart": 141163.3357770897
            },
            {
              "lat": 43.77773205,
              "lng": -116.1099202,
              "alt": 1930.535,
              "speed2d": 10.9025,
              "speed3d": 10.925,
              "msFromStart": 141813.73684210528
            },
            {
              "lat": 43.77777466666667,
              "lng": -116.10989796666667,
              "alt": 1930.324,
              "speed2d": 10.965333333333334,
              "speed3d": 10.99,
              "msFromStart": 142225.82315789477
            },
            {
              "lat": 43.77782481428571,
              "lng": -116.1098853142857,
              "alt": 1929.9747142857143,
              "speed2d": 10.982714285714286,
              "speed3d": 11.021428571428572,
              "msFromStart": 142681.68952380953
            },
            {
              "lat": 43.777879500000004,
              "lng": -116.1098565,
              "alt": 1929.1948000000002,
              "speed2d": 10.984199999999998,
              "speed3d": 11.008,
              "msFromStart": 143271.28959999996
            },
            {
              "lat": 43.77791171141525,
              "lng": -116.10984917361242,
              "alt": 1928.8090112217965,
              "speed2d": 11.170550195174835,
              "speed3d": 11.187520946209272,
              "msFromStart": 143500
            },
            {
              "lat": 43.77794056356905,
              "lng": -116.10982128698346,
              "alt": 1928.3796366496565,
              "speed2d": 10.869533630050267,
              "speed3d": 10.892183938917242,
              "msFromStart": 144000
            },
            {
              "lat": 43.7779924,
              "lng": -116.10977693333334,
              "alt": 1927.8303333333333,
              "speed2d": 10.504333333333333,
              "speed3d": 10.516666666666667,
              "msFromStart": 144793.677925926
            },
            {
              "lat": 43.77805466666667,
              "lng": -116.1097441,
              "alt": 1927.6713333333332,
              "speed2d": 10.608333333333334,
              "speed3d": 10.646666666666668,
              "msFromStart": 145416.7056842105
            },
            {
              "lat": 43.77806301132393,
              "lng": -116.10974210636097,
              "alt": 1927.575198937361,
              "speed2d": 10.59797637353369,
              "speed3d": 10.630383056045382,
              "msFromStart": 145500
            },
            {
              "lat": 43.778115500000006,
              "lng": -116.10969186666665,
              "alt": 1926.695666666667,
              "speed2d": 10.507333333333333,
              "speed3d": 10.543333333333333,
              "msFromStart": 146240.56024951258
            },
            {
              "lat": 43.77814445,
              "lng": -116.1096567,
              "alt": 1926.6735,
              "speed2d": 10.616,
              "speed3d": 10.615,
              "msFromStart": 146598.1511111111
            },
            {
              "lat": 43.77819255,
              "lng": -116.10959134999999,
              "alt": 1925.9855,
              "speed2d": 10.7655,
              "speed3d": 10.78,
              "msFromStart": 147258.71644444444
            },
            {
              "lat": 43.778226333333336,
              "lng": -116.10955361666667,
              "alt": 1925.4686666666669,
              "speed2d": 10.813500000000001,
              "speed3d": 10.843333333333334,
              "msFromStart": 147699.09333333332
            },
            {
              "lat": 43.778262549999994,
              "lng": -116.10950559999999,
              "alt": 1924.9403333333332,
              "speed2d": 10.883000000000001,
              "speed3d": 10.9,
              "msFromStart": 148212.7145185185
            },
            {
              "lat": 43.77828388037407,
              "lng": -116.10947047771171,
              "alt": 1924.9959432348674,
              "speed2d": 11.014503190521692,
              "speed3d": 10.97349614984919,
              "msFromStart": 148500
            },
            {
              "lat": 43.77832483764069,
              "lng": -116.10939329976985,
              "alt": 1925.7234321141154,
              "speed2d": 11.334964312042683,
              "speed3d": 11.337282798521326,
              "msFromStart": 149000
            },
            {
              "lat": 43.77833659032749,
              "lng": -116.1093346693851,
              "alt": 1926.0615990458296,
              "speed2d": 10.914972070294274,
              "speed3d": 10.92483001623408,
              "msFromStart": 149500
            },
            {
              "lat": 43.7783483430143,
              "lng": -116.10927603900035,
              "alt": 1926.3997659775434,
              "speed2d": 10.494979828545864,
              "speed3d": 10.512377233946836,
              "msFromStart": 150000
            },
            {
              "lat": 43.77836009570111,
              "lng": -116.1092174086156,
              "alt": 1926.7379329092576,
              "speed2d": 10.074987586797455,
              "speed3d": 10.099924451659591,
              "msFromStart": 150500
            },
            {
              "lat": 43.778377975000005,
              "lng": -116.109114275,
              "alt": 1927.3362499999998,
              "speed2d": 9.307500000000001,
              "speed3d": 9.385,
              "msFromStart": 151382.41333333333
            },
            {
              "lat": 43.7783483,
              "lng": -116.109075175,
              "alt": 1926.335,
              "speed2d": 8.69275,
              "speed3d": 8.8175,
              "msFromStart": 151753.27333333332
            },
            {
              "lat": 43.77829928888889,
              "lng": -116.10902959999999,
              "alt": 1924.4227777777778,
              "speed2d": 8.090333333333334,
              "speed3d": 8.17,
              "msFromStart": 152234.15525925922
            },
            {
              "lat": 43.77827301111111,
              "lng": -116.10898958888889,
              "alt": 1923.3215555555557,
              "speed2d": 7.845333333333333,
              "speed3d": 7.904444444444445,
              "msFromStart": 152730.14755555548
            },
            {
              "lat": 43.778259014285716,
              "lng": -116.10895702857142,
              "alt": 1923.0307142857146,
              "speed2d": 7.676285714285714,
              "speed3d": 7.737142857142857,
              "msFromStart": 153171.31492063482
            },
            {
              "lat": 43.77822505,
              "lng": -116.108908675,
              "alt": 1922.7995,
              "speed2d": 7.5767500000000005,
              "speed3d": 7.62,
              "msFromStart": 153859.2066666666
            },
            {
              "lat": 43.778217632758356,
              "lng": -116.1088990160937,
              "alt": 1922.837696344211,
              "speed2d": 7.579982145020229,
              "speed3d": 7.619880966801524,
              "msFromStart": 154000
            },
            {
              "lat": 43.77818033333334,
              "lng": -116.10886056666666,
              "alt": 1922.8863333333334,
              "speed2d": 7.558,
              "speed3d": 7.59,
              "msFromStart": 154730.634074074
            },
            {
              "lat": 43.77815760000001,
              "lng": -116.10883873333333,
              "alt": 1922.8029999999999,
              "speed2d": 7.449666666666666,
              "speed3d": 7.5,
              "msFromStart": 155281.1494424951
            },
            {
              "lat": 43.77814720088762,
              "lng": -116.1088320500115,
              "alt": 1922.7976840612591,
              "speed2d": 7.402649093222539,
              "speed3d": 7.4031578543943715,
              "msFromStart": 155500
            },
            {
              "lat": 43.7781167,
              "lng": -116.1087995,
              "alt": 1923.2286666666669,
              "speed2d": 7.484999999999999,
              "speed3d": 7.52,
              "msFromStart": 156141.82989473705
            },
            {
              "lat": 43.778076,
              "lng": -116.10877115,
              "alt": 1923.3265,
              "speed2d": 7.73625,
              "speed3d": 7.734999999999999,
              "msFromStart": 156815.6206666667
            },
            {
              "lat": 43.77805307142857,
              "lng": -116.10875850000001,
              "alt": 1923.232857142857,
              "speed2d": 7.8144285714285715,
              "speed3d": 7.857142857142857,
              "msFromStart": 157202.81333333338
            },
            {
              "lat": 43.77803843360635,
              "lng": -116.10874446550459,
              "alt": 1923.1992776127236,
              "speed2d": 7.830375806321377,
              "speed3d": 7.832159084487118,
              "msFromStart": 157500
            },
            {
              "lat": 43.77800812086667,
              "lng": -116.10872794676442,
              "alt": 1923.0096810761715,
              "speed2d": 7.896791359544658,
              "speed3d": 7.911630686634633,
              "msFromStart": 158000
            },
            {
              "lat": 43.77796313333333,
              "lng": -116.1087018,
              "alt": 1922.679666666667,
              "speed2d": 7.992666666666667,
              "speed3d": 8.02,
              "msFromStart": 158747.07377777767
            },
            {
              "lat": 43.7779356,
              "lng": -116.10868355,
              "alt": 1922.232,
              "speed2d": 8.0545,
              "speed3d": 8.085,
              "msFromStart": 159195.66933333327
            },
            {
              "lat": 43.777915097345165,
              "lng": -116.10867667529605,
              "alt": 1921.9988417054108,
              "speed2d": 8.102862769401357,
              "speed3d": 8.124341268720608,
              "msFromStart": 159500
            },
            {
              "lat": 43.77785615,
              "lng": -116.1086285,
              "alt": 1921.4825,
              "speed2d": 8.3535,
              "speed3d": 8.365,
              "msFromStart": 160403.92336842106
            },
            {
              "lat": 43.77783649999999,
              "lng": -116.10861353333334,
              "alt": 1921.3883333333333,
              "speed2d": 8.439,
              "speed3d": 8.459999999999999,
              "msFromStart": 160688.10526315792
            },
            {
              "lat": 43.77779316666667,
              "lng": -116.10857793333334,
              "alt": 1921.1366666666668,
              "speed2d": 8.646333333333333,
              "speed3d": 8.643333333333333,
              "msFromStart": 161293.20673684214
            },
            {
              "lat": 43.77777684932855,
              "lng": -116.10856065093209,
              "alt": 1921.0616118412167,
              "speed2d": 8.782864595887064,
              "speed3d": 8.7602354854138,
              "msFromStart": 161500
            },
            {
              "lat": 43.777743590246324,
              "lng": -116.1085231366066,
              "alt": 1920.7530120045842,
              "speed2d": 8.779960423938393,
              "speed3d": 8.765286219237575,
              "msFromStart": 162000
            },
            {
              "lat": 43.777710331164094,
              "lng": -116.10848562228114,
              "alt": 1920.4444121679514,
              "speed2d": 8.777056251989723,
              "speed3d": 8.770336953061351,
              "msFromStart": 162500
            },
            {
              "lat": 43.777677072081865,
              "lng": -116.10844810795565,
              "alt": 1920.135812331319,
              "speed2d": 8.774152080041052,
              "speed3d": 8.775387686885127,
              "msFromStart": 163000
            },
            {
              "lat": 43.777643812999635,
              "lng": -116.10841059363018,
              "alt": 1919.8272124946861,
              "speed2d": 8.77124790809238,
              "speed3d": 8.780438420708903,
              "msFromStart": 163500
            },
            {
              "lat": 43.777610553917405,
              "lng": -116.10837307930471,
              "alt": 1919.5186126580534,
              "speed2d": 8.76834373614371,
              "speed3d": 8.785489154532678,
              "msFromStart": 164000
            },
            {
              "lat": 43.777577294835176,
              "lng": -116.10833556497923,
              "alt": 1919.2100128214208,
              "speed2d": 8.76543956419504,
              "speed3d": 8.790539888356454,
              "msFromStart": 164500
            },
            {
              "lat": 43.777542179432594,
              "lng": -116.10830065965017,
              "alt": 1918.5713080631451,
              "speed2d": 8.736250981230626,
              "speed3d": 8.8,
              "msFromStart": 165000
            },
            {
              "lat": 43.777496033333335,
              "lng": -116.10824081666667,
              "alt": 1918.5786666666668,
              "speed2d": 8.721666666666666,
              "speed3d": 8.771666666666667,
              "msFromStart": 165758.1598596492
            },
            {
              "lat": 43.77748277492968,
              "lng": -116.1082235229398,
              "alt": 1918.4430135611133,
              "speed2d": 8.654915473401042,
              "speed3d": 8.696089457912837,
              "msFromStart": 166000
            },
            {
              "lat": 43.777454030734354,
              "lng": -116.10818957548943,
              "alt": 1918.0595892332983,
              "speed2d": 8.586212300037626,
              "speed3d": 8.62536560297991,
              "msFromStart": 166500
            },
            {
              "lat": 43.777414140000005,
              "lng": -116.10814234,
              "alt": 1917.5438,
              "speed2d": 8.516599999999999,
              "speed3d": 8.55,
              "msFromStart": 167186.6275555557
            },
            {
              "lat": 43.777387899999994,
              "lng": -116.10811100000001,
              "alt": 1917.2145,
              "speed2d": 8.5725,
              "speed3d": 8.6,
              "msFromStart": 167609.92177777772
            },
            {
              "lat": 43.77736689028091,
              "lng": -116.10808182514724,
              "alt": 1916.9048853644977,
              "speed2d": 8.508954123191694,
              "speed3d": 8.547267784270343,
              "msFromStart": 168000
            },
            {
              "lat": 43.77734176613338,
              "lng": -116.10804608843439,
              "alt": 1916.2619692121607,
              "speed2d": 8.329746201919198,
              "speed3d": 8.39669232319974,
              "msFromStart": 168500
            },
            {
              "lat": 43.77730153333333,
              "lng": -116.1079926,
              "alt": 1916.2466666666667,
              "speed2d": 8.4025,
              "speed3d": 8.431666666666667,
              "msFromStart": 169179.1422222223
            },
            {
              "lat": 43.77726486,
              "lng": -116.10794842,
              "alt": 1916.0546,
              "speed2d": 8.4068,
              "speed3d": 8.448,
              "msFromStart": 169795.55484444456
            },
            {
              "lat": 43.77724418333333,
              "lng": -116.10792806666667,
              "alt": 1915.909333333333,
              "speed2d": 8.333166666666667,
              "speed3d": 8.378333333333334,
              "msFromStart": 170142.1444444446
            },
            {
              "lat": 43.77719843333333,
              "lng": -116.10789243333332,
              "alt": 1915.1573333333336,
              "speed2d": 8.140666666666668,
              "speed3d": 8.173333333333334,
              "msFromStart": 170903.0085614036
            },
            {
              "lat": 43.777178533333334,
              "lng": -116.10787529999999,
              "alt": 1914.8716666666664,
              "speed2d": 8.155666666666667,
              "speed3d": 8.193333333333332,
              "msFromStart": 171214.67284210533
            },
            {
              "lat": 43.77714008333333,
              "lng": -116.10784768333332,
              "alt": 1914.5706666666665,
              "speed2d": 8.251,
              "speed3d": 8.286666666666667,
              "msFromStart": 171755.56103703697
            },
            {
              "lat": 43.77712499567789,
              "lng": -116.10783486198133,
              "alt": 1914.5359135577592,
              "speed2d": 8.20692359059802,
              "speed3d": 8.235284209845156,
              "msFromStart": 172000
            },
            {
              "lat": 43.77709551230579,
              "lng": -116.10781410894967,
              "alt": 1913.9399351494792,
              "speed2d": 8.029901566623819,
              "speed3d": 8.07277405115021,
              "msFromStart": 172500
            },
            {
              "lat": 43.7770559,
              "lng": -116.10778485,
              "alt": 1913.313,
              "speed2d": 8.025,
              "speed3d": 8.04,
              "msFromStart": 173110.27644444432
            },
            {
              "lat": 43.7769993,
              "lng": -116.10774486666666,
              "alt": 1912.5279999999998,
              "speed2d": 8.193000000000001,
              "speed3d": 8.22,
              "msFromStart": 173908.09955555561
            },
            {
              "lat": 43.77697948333333,
              "lng": -116.1077305,
              "alt": 1912.1053333333334,
              "speed2d": 8.189333333333334,
              "speed3d": 8.226666666666667,
              "msFromStart": 174210.9586666667
            },
            {
              "lat": 43.77694593333334,
              "lng": -116.1076972,
              "alt": 1911.4993333333332,
              "speed2d": 8.431666666666667,
              "speed3d": 8.403333333333334,
              "msFromStart": 174678.47911111102
            },
            {
              "lat": 43.77691095,
              "lng": -116.1076617,
              "alt": 1910.7485,
              "speed2d": 8.714999999999998,
              "speed3d": 8.7225,
              "msFromStart": 175145.7997192981
            },
            {
              "lat": 43.77687362,
              "lng": -116.10762006,
              "alt": 1909.7434000000003,
              "speed2d": 8.8144,
              "speed3d": 8.846,
              "msFromStart": 175722.8968421053
            },
            {
              "lat": 43.77683902857143,
              "lng": -116.10757982857143,
              "alt": 1908.934142857143,
              "speed2d": 8.837428571428573,
              "speed3d": 8.867142857142856,
              "msFromStart": 176296.08156390986
            },
            {
              "lat": 43.77682775137895,
              "lng": -116.10756380919305,
              "alt": 1908.6616036772218,
              "speed2d": 8.85426762002042,
              "speed3d": 8.891593462717053,
              "msFromStart": 176500
            },
            {
              "lat": 43.77677845,
              "lng": -116.1075040375,
              "alt": 1907.776375,
              "speed2d": 9.018375,
              "speed3d": 9.04625,
              "msFromStart": 177290.88066666678
            },
            {
              "lat": 43.7767593,
              "lng": -116.10748653333333,
              "alt": 1907.3433333333332,
              "speed2d": 8.890666666666666,
              "speed3d": 9,
              "msFromStart": 177593.23822222228
            },
            {
              "lat": 43.77670965,
              "lng": -116.10742975,
              "alt": 1906.26,
              "speed2d": 8.574,
              "speed3d": 8.64,
              "msFromStart": 178445.14577777777
            },
            {
              "lat": 43.77670550728585,
              "lng": -116.10742624754788,
              "alt": 1906.2909702435434,
              "speed2d": 8.667920834903708,
              "speed3d": 8.673404763186701,
              "msFromStart": 178500
            },
            {
              "lat": 43.7766807,
              "lng": -116.10737765,
              "alt": 1905.248,
              "speed2d": 8.3555,
              "speed3d": 8.43,
              "msFromStart": 179105.51911111106
            },
            {
              "lat": 43.77666508526877,
              "lng": -116.1073461545476,
              "alt": 1904.8322116687832,
              "speed2d": 8.231858433261023,
              "speed3d": 8.315609062608859,
              "msFromStart": 179500
            },
            {
              "lat": 43.776628200000005,
              "lng": -116.10727215,
              "alt": 1903.932,
              "speed2d": 7.961,
              "speed3d": 8.05,
              "msFromStart": 180426.04577777773
            },
            {
              "lat": 43.77660996666666,
              "lng": -116.10723956666666,
              "alt": 1904.0033333333336,
              "speed2d": 7.742000000000001,
              "speed3d": 7.783333333333333,
              "msFromStart": 180838.59911111102
            },
            {
              "lat": 43.77659912,
              "lng": -116.1072108,
              "alt": 1904.0746,
              "speed2d": 7.4734,
              "speed3d": 7.5760000000000005,
              "msFromStart": 181168.64177777764
            },
            {
              "lat": 43.77659244187424,
              "lng": -116.1071824046525,
              "alt": 1904.3057912905829,
              "speed2d": 7.107621512096961,
              "speed3d": 7.188140568032087,
              "msFromStart": 181500
            },
            {
              "lat": 43.77658222345207,
              "lng": -116.10714142773091,
              "alt": 1904.9083468780707,
              "speed2d": 6.361161609109702,
              "speed3d": 6.354334212458655,
              "msFromStart": 182000
            },
            {
              "lat": 43.77658362857143,
              "lng": -116.10707808571429,
              "alt": 1904.3742857142856,
              "speed2d": 6.712000000000001,
              "speed3d": 6.758571428571429,
              "msFromStart": 182708.5395555555
            },
            {
              "lat": 43.77658538405436,
              "lng": -116.10705858813088,
              "alt": 1903.7716464226887,
              "speed2d": 6.328487797925632,
              "speed3d": 6.3633471989374115,
              "msFromStart": 183000
            },
            {
              "lat": 43.77659209923311,
              "lng": -116.1070231960248,
              "alt": 1903.907346584021,
              "speed2d": 5.9336924649673985,
              "speed3d": 6.16732217032207,
              "msFromStart": 183500
            },
            {
              "lat": 43.77660900622595,
              "lng": -116.10699145547393,
              "alt": 1904.0302584819867,
              "speed2d": 5.557388947184258,
              "speed3d": 5.606603707589977,
              "msFromStart": 184000
            },
            {
              "lat": 43.77661960208448,
              "lng": -116.10696104629731,
              "alt": 1903.8965896873285,
              "speed2d": 5.288752057048834,
              "speed3d": 5.210235874931442,
              "msFromStart": 184500
            },
            {
              "lat": 43.77665618333333,
              "lng": -116.10693485,
              "alt": 1903.2668333333336,
              "speed2d": 5.080666666666667,
              "speed3d": 5.108333333333333,
              "msFromStart": 185314.1774814815
            },
            {
              "lat": 43.7766708111111,
              "lng": -116.10692314444444,
              "alt": 1902.8374444444444,
              "speed2d": 4.895444444444444,
              "speed3d": 4.984444444444445,
              "msFromStart": 185736.15644444432
            },
            {
              "lat": 43.77667891618275,
              "lng": -116.1069158912816,
              "alt": 1902.6205697911328,
              "speed2d": 4.755819361804011,
              "speed3d": 4.826290586693319,
              "msFromStart": 186000
            },
            {
              "lat": 43.776700757142855,
              "lng": -116.10689407142857,
              "alt": 1902.0430000000001,
              "speed2d": 4.138857142857142,
              "speed3d": 4.252857142857143,
              "msFromStart": 186782.94821052643
            },
            {
              "lat": 43.776719825,
              "lng": -116.1068881125,
              "alt": 1901.5932500000004,
              "speed2d": 3.83575,
              "speed3d": 3.93625,
              "msFromStart": 187306.6785263159
            },
            {
              "lat": 43.776737142857144,
              "lng": -116.10688775714286,
              "alt": 1901.4452857142858,
              "speed2d": 3.568857142857143,
              "speed3d": 3.6714285714285713,
              "msFromStart": 187780.59885714296
            },
            {
              "lat": 43.77674440496321,
              "lng": -116.10689171436127,
              "alt": 1901.59732628832,
              "speed2d": 3.2326187960114057,
              "speed3d": 3.3,
              "msFromStart": 188000
            },
            {
              "lat": 43.77676432,
              "lng": -116.10689921999997,
              "alt": 1900.9388,
              "speed2d": 3.1988000000000003,
              "speed3d": 3.2899999999999996,
              "msFromStart": 188695.78097777767
            }
          ]
        }
      ]
    },
    {
      "id": "trail-shaker-ridge",
      "pathType": "trail",
      "name": "Shaker Ridge",
      "difficulty": "blue",
      "videos": [
        {
          "videoId": "GH010038",
          "pathId": "trail-shaker-ridge",
          "startSec": 4,
          "endSec": 157,
          "id": "video-segment-0",
          "gps": [
            {
              "lat": 43.755229722222225,
              "lng": -116.0944362,
              "alt": 2146.962,
              "speed2d": 3.891,
              "speed3d": 3.8744444444444444,
              "msFromStart": 4234.627863547758
            },
            {
              "lat": 43.755212244444444,
              "lng": -116.09444023333333,
              "alt": 2146.636555555556,
              "speed2d": 3.8823333333333334,
              "speed3d": 3.9122222222222227,
              "msFromStart": 4730.69577777778
            },
            {
              "lat": 43.75519276000001,
              "lng": -116.09445321999999,
              "alt": 2146.4009,
              "speed2d": 4.242999999999999,
              "speed3d": 4.193999999999999,
              "msFromStart": 5253.383422222221
            },
            {
              "lat": 43.75517993333334,
              "lng": -116.09446315555556,
              "alt": 2146.455,
              "speed2d": 3.553666666666667,
              "speed3d": 3.748888888888889,
              "msFromStart": 5773.744666666663
            },
            {
              "lat": 43.75518183333334,
              "lng": -116.09445992222221,
              "alt": 2146.5707777777775,
              "speed2d": 1.7425555555555554,
              "speed3d": 1.8888888888888886,
              "msFromStart": 6267.64598148148
            },
            {
              "lat": 43.75518515555555,
              "lng": -116.09445534444446,
              "alt": 2146.632666666667,
              "speed2d": 0.667888888888889,
              "speed3d": 0.7744444444444445,
              "msFromStart": 6763.461666666665
            },
            {
              "lat": 43.75518997777778,
              "lng": -116.09445658888889,
              "alt": 2146.6936666666666,
              "speed2d": 0.31033333333333335,
              "speed3d": 0.28888888888888886,
              "msFromStart": 7258.602932098766
            },
            {
              "lat": 43.755194844444446,
              "lng": -116.09446297777778,
              "alt": 2146.751444444444,
              "speed2d": 0.8585555555555556,
              "speed3d": 0.7577777777777778,
              "msFromStart": 7752.423333333338
            },
            {
              "lat": 43.75520584444445,
              "lng": -116.09446942222223,
              "alt": 2146.7729999999997,
              "speed2d": 1.5114444444444446,
              "speed3d": 1.4933333333333336,
              "msFromStart": 8246.414478882392
            },
            {
              "lat": 43.75520732222222,
              "lng": -116.09447866666667,
              "alt": 2146.728111111111,
              "speed2d": 1.4005555555555556,
              "speed3d": 1.4300000000000002,
              "msFromStart": 8740.76989473685
            },
            {
              "lat": 43.755209388888886,
              "lng": -116.0944849,
              "alt": 2146.620888888889,
              "speed2d": 1.2593333333333332,
              "speed3d": 1.2544444444444443,
              "msFromStart": 9235.4455214425
            },
            {
              "lat": 43.75521277777778,
              "lng": -116.0944868888889,
              "alt": 2146.6355555555556,
              "speed2d": 0.9857777777777779,
              "speed3d": 1.0433333333333332,
              "msFromStart": 9730.971277777786
            },
            {
              "lat": 43.75521412222222,
              "lng": -116.0944884111111,
              "alt": 2146.521222222222,
              "speed2d": 0.7255555555555556,
              "speed3d": 0.7422222222222223,
              "msFromStart": 10226.753981481486
            },
            {
              "lat": 43.75521509,
              "lng": -116.09448923000001,
              "alt": 2146.0245,
              "speed2d": 0.4857,
              "speed3d": 0.541,
              "msFromStart": 10750.689833333341
            },
            {
              "lat": 43.75521485555556,
              "lng": -116.09448086666669,
              "alt": 2145.399888888889,
              "speed2d": 0.45777777777777773,
              "speed3d": 0.4555555555555556,
              "msFromStart": 11273.673061728397
            },
            {
              "lat": 43.75521615555555,
              "lng": -116.09447687777778,
              "alt": 2144.903555555555,
              "speed2d": 0.445,
              "speed3d": 0.518888888888889,
              "msFromStart": 11767.253666666671
            },
            {
              "lat": 43.75521913333334,
              "lng": -116.09447591111112,
              "alt": 2144.826222222222,
              "speed2d": 0.4774444444444444,
              "speed3d": 0.5166666666666666,
              "msFromStart": 12262.05190740741
            },
            {
              "lat": 43.75522431111111,
              "lng": -116.0944784,
              "alt": 2144.717,
              "speed2d": 0.5157777777777777,
              "speed3d": 0.5422222222222222,
              "msFromStart": 12759.176666666674
            },
            {
              "lat": 43.7552319,
              "lng": -116.09448108888887,
              "alt": 2144.4482222222223,
              "speed2d": 0.8584444444444445,
              "speed3d": 0.8466666666666668,
              "msFromStart": 13255.98959876543
            },
            {
              "lat": 43.755241622222215,
              "lng": -116.09447877777778,
              "alt": 2144.1555555555556,
              "speed2d": 1.3384444444444445,
              "speed3d": 1.3111111111111111,
              "msFromStart": 13752.14033333333
            },
            {
              "lat": 43.755248944444446,
              "lng": -116.09447888888889,
              "alt": 2143.930888888889,
              "speed2d": 1.2892222222222223,
              "speed3d": 1.338888888888889,
              "msFromStart": 14247.486400909678
            },
            {
              "lat": 43.755254377777774,
              "lng": -116.09447953333333,
              "alt": 2143.6946666666668,
              "speed2d": 1.2227777777777777,
              "speed3d": 1.2311111111111113,
              "msFromStart": 14741.361736842096
            },
            {
              "lat": 43.75526698888889,
              "lng": -116.09448323333334,
              "alt": 2143.2676666666666,
              "speed2d": 1.5482222222222224,
              "speed3d": 1.5277777777777777,
              "msFromStart": 15234.838683235865
            },
            {
              "lat": 43.7552786,
              "lng": -116.09448783333335,
              "alt": 2142.88,
              "speed2d": 1.7598888888888888,
              "speed3d": 1.7855555555555556,
              "msFromStart": 15727.311055555545
            },
            {
              "lat": 43.75528529,
              "lng": -116.09449305000001,
              "alt": 2142.3516999999997,
              "speed2d": 1.5449,
              "speed3d": 1.589,
              "msFromStart": 16247.940727777775
            },
            {
              "lat": 43.75529411111111,
              "lng": -116.09449763333335,
              "alt": 2141.9923333333327,
              "speed2d": 1.5638888888888889,
              "speed3d": 1.5822222222222224,
              "msFromStart": 16770.52133333334
            },
            {
              "lat": 43.755306611111116,
              "lng": -116.09450311111111,
              "alt": 2141.521111111111,
              "speed2d": 1.745111111111111,
              "speed3d": 1.76,
              "msFromStart": 17265.806358024693
            },
            {
              "lat": 43.755314777777784,
              "lng": -116.09450746666668,
              "alt": 2141.3303333333333,
              "speed2d": 1.6435555555555554,
              "speed3d": 1.702222222222222,
              "msFromStart": 17761.318000000007
            },
            {
              "lat": 43.75532456666667,
              "lng": -116.09451175555557,
              "alt": 2141.3413333333333,
              "speed2d": 1.7702222222222221,
              "speed3d": 1.7666666666666666,
              "msFromStart": 18256.67691617934
            },
            {
              "lat": 43.755335233333334,
              "lng": -116.09451598888889,
              "alt": 2141.353777777778,
              "speed2d": 1.8112222222222223,
              "speed3d": 1.86,
              "msFromStart": 18751.744105263166
            },
            {
              "lat": 43.7553414,
              "lng": -116.0945201222222,
              "alt": 2141.344777777778,
              "speed2d": 1.592111111111111,
              "speed3d": 1.6533333333333333,
              "msFromStart": 19246.96679337232
            },
            {
              "lat": 43.75534896666666,
              "lng": -116.09452561111111,
              "alt": 2141.2875555555556,
              "speed2d": 1.4512222222222222,
              "speed3d": 1.4755555555555555,
              "msFromStart": 19742.626777777776
            },
            {
              "lat": 43.75536096666667,
              "lng": -116.09453062222222,
              "alt": 2141.418333333333,
              "speed2d": 1.6545555555555556,
              "speed3d": 1.6255555555555554,
              "msFromStart": 20238.239
            },
            {
              "lat": 43.755373266666666,
              "lng": -116.0945394888889,
              "alt": 2141.3936666666664,
              "speed2d": 1.9571111111111112,
              "speed3d": 1.9266666666666667,
              "msFromStart": 20733.65911111112
            },
            {
              "lat": 43.7553817,
              "lng": -116.0945474222222,
              "alt": 2141.4734444444443,
              "speed2d": 1.9343333333333337,
              "speed3d": 2.002222222222222,
              "msFromStart": 21229.128481481483
            },
            {
              "lat": 43.755382399999995,
              "lng": -116.09455477777777,
              "alt": 2141.5481111111108,
              "speed2d": 1.440777777777778,
              "speed3d": 1.4866666666666668,
              "msFromStart": 21724.759
            },
            {
              "lat": 43.75538861999999,
              "lng": -116.09456317,
              "alt": 2141.4286,
              "speed2d": 1.4611,
              "speed3d": 1.4729999999999999,
              "msFromStart": 22247.81496666666
            },
            {
              "lat": 43.75539057777778,
              "lng": -116.09457267777778,
              "alt": 2140.8881111111114,
              "speed2d": 1.396222222222222,
              "speed3d": 1.4055555555555554,
              "msFromStart": 22770.599999999988
            },
            {
              "lat": 43.755395299999996,
              "lng": -116.09458198888888,
              "alt": 2140.2606666666666,
              "speed2d": 1.418111111111111,
              "speed3d": 1.4677777777777776,
              "msFromStart": 23265.539247563345
            },
            {
              "lat": 43.755399688888886,
              "lng": -116.0945895,
              "alt": 2139.989,
              "speed2d": 1.3925555555555555,
              "speed3d": 1.4044444444444442,
              "msFromStart": 23759.88652631578
            },
            {
              "lat": 43.75541192222222,
              "lng": -116.09459733333334,
              "alt": 2139.8287777777778,
              "speed2d": 1.8023333333333333,
              "speed3d": 1.778888888888889,
              "msFromStart": 24254.272226120855
            },
            {
              "lat": 43.7554249,
              "lng": -116.0946065,
              "alt": 2139.2817777777777,
              "speed2d": 2.210777777777778,
              "speed3d": 2.2155555555555555,
              "msFromStart": 24748.7931111111
            },
            {
              "lat": 43.75543582222222,
              "lng": -116.09461517777777,
              "alt": 2139.3154444444444,
              "speed2d": 2.2907777777777776,
              "speed3d": 2.301111111111111,
              "msFromStart": 25243.622888888884
            },
            {
              "lat": 43.75545081111112,
              "lng": -116.09462544444445,
              "alt": 2139.0888888888885,
              "speed2d": 2.6644444444444444,
              "speed3d": 2.658888888888889,
              "msFromStart": 25739.271777777776
            },
            {
              "lat": 43.75546721111112,
              "lng": -116.0946359,
              "alt": 2138.910777777778,
              "speed2d": 2.937777777777778,
              "speed3d": 2.9077777777777776,
              "msFromStart": 26234.64877777778
            },
            {
              "lat": 43.75549446666666,
              "lng": -116.09465004444445,
              "alt": 2139.6778888888884,
              "speed2d": 3.641111111111111,
              "speed3d": 3.6077777777777773,
              "msFromStart": 26729.170777777792
            },
            {
              "lat": 43.75552221000001,
              "lng": -116.09466266,
              "alt": 2140.146,
              "speed2d": 4.0284,
              "speed3d": 3.9839999999999995,
              "msFromStart": 27251.424544444453
            },
            {
              "lat": 43.755553299999995,
              "lng": -116.09467613333334,
              "alt": 2140.374666666666,
              "speed2d": 4.5825555555555555,
              "speed3d": 4.54,
              "msFromStart": 27774.377333333345
            },
            {
              "lat": 43.755581555555544,
              "lng": -116.09468862222221,
              "alt": 2140.514222222222,
              "speed2d": 4.748111111111111,
              "speed3d": 4.753333333333333,
              "msFromStart": 28270.21474204028
            },
            {
              "lat": 43.75561014444445,
              "lng": -116.0947001222222,
              "alt": 2140.531222222222,
              "speed2d": 4.975666666666667,
              "speed3d": 4.961111111111111,
              "msFromStart": 28766.75305263156
            },
            {
              "lat": 43.75563903333333,
              "lng": -116.09471184444445,
              "alt": 2140.632222222222,
              "speed2d": 5.164444444444445,
              "speed3d": 5.174444444444444,
              "msFromStart": 29262.67671150097
            },
            {
              "lat": 43.755665977777774,
              "lng": -116.09472193333333,
              "alt": 2140.257555555556,
              "speed2d": 5.247777777777777,
              "speed3d": 5.257777777777777,
              "msFromStart": 29756.895444444453
            },
            {
              "lat": 43.75568861111112,
              "lng": -116.09473107777778,
              "alt": 2139.053333333333,
              "speed2d": 5.171333333333333,
              "speed3d": 5.237777777777778,
              "msFromStart": 30251.42681481482
            },
            {
              "lat": 43.75571721111112,
              "lng": -116.09474085555554,
              "alt": 2138.127888888889,
              "speed2d": 5.684,
              "speed3d": 5.646666666666667,
              "msFromStart": 30747.050333333344
            },
            {
              "lat": 43.75574836666667,
              "lng": -116.09475040000001,
              "alt": 2137.6730000000002,
              "speed2d": 6.002111111111111,
              "speed3d": 6.063333333333333,
              "msFromStart": 31242.720703703715
            },
            {
              "lat": 43.755779999999994,
              "lng": -116.09475968888889,
              "alt": 2137.2522222222224,
              "speed2d": 6.275,
              "speed3d": 6.285555555555556,
              "msFromStart": 31738.362222222237
            },
            {
              "lat": 43.755818533333326,
              "lng": -116.09477033333333,
              "alt": 2136.365111111111,
              "speed2d": 7.253111111111112,
              "speed3d": 7.196666666666667,
              "msFromStart": 32233.507555555567
            },
            {
              "lat": 43.75585887777777,
              "lng": -116.09478088888889,
              "alt": 2135.315111111111,
              "speed2d": 7.8743333333333325,
              "speed3d": 7.923333333333334,
              "msFromStart": 32727.310222222237
            },
            {
              "lat": 43.75590339,
              "lng": -116.09479204999998,
              "alt": 2134.0292,
              "speed2d": 8.552399999999999,
              "speed3d": 8.578,
              "msFromStart": 33248.856835087725
            },
            {
              "lat": 43.7559486111111,
              "lng": -116.09480274444444,
              "alt": 2132.990888888889,
              "speed2d": 8.982444444444445,
              "speed3d": 9.053333333333331,
              "msFromStart": 33771.2835789474
            },
            {
              "lat": 43.75598873333334,
              "lng": -116.09481175555554,
              "alt": 2131.5971111111107,
              "speed2d": 9.030000000000001,
              "speed3d": 9.184444444444445,
              "msFromStart": 34266.57067836258
            },
            {
              "lat": 43.756022233333326,
              "lng": -116.09481963333333,
              "alt": 2130.331555555556,
              "speed2d": 8.54422222222222,
              "speed3d": 8.742222222222223,
              "msFromStart": 34762.71933333331
            },
            {
              "lat": 43.75605897777777,
              "lng": -116.09482748888888,
              "alt": 2129.057555555555,
              "speed2d": 8.621444444444446,
              "speed3d": 8.756666666666666,
              "msFromStart": 35258.77903703703
            },
            {
              "lat": 43.756099111111105,
              "lng": -116.09483611111109,
              "alt": 2127.556777777778,
              "speed2d": 8.989333333333333,
              "speed3d": 9.114444444444445,
              "msFromStart": 35754.474222222205
            },
            {
              "lat": 43.75614258888889,
              "lng": -116.09484636666669,
              "alt": 2126.0924444444445,
              "speed2d": 9.469444444444445,
              "speed3d": 9.625555555555557,
              "msFromStart": 36250.08644444444
            },
            {
              "lat": 43.75618166666667,
              "lng": -116.0948553111111,
              "alt": 2124.4597777777776,
              "speed2d": 9.349555555555554,
              "speed3d": 9.584444444444443,
              "msFromStart": 36745.525555555556
            },
            {
              "lat": 43.75621942222223,
              "lng": -116.0948636111111,
              "alt": 2123.101888888889,
              "speed2d": 9.176333333333334,
              "speed3d": 9.395555555555555,
              "msFromStart": 37240.273703703715
            },
            {
              "lat": 43.75625788888889,
              "lng": -116.09487255555555,
              "alt": 2121.7106666666664,
              "speed2d": 9.190555555555555,
              "speed3d": 9.443333333333333,
              "msFromStart": 37733.17111111115
            },
            {
              "lat": 43.75629911111112,
              "lng": -116.09488308888889,
              "alt": 2120.4579999999996,
              "speed2d": 9.444444444444445,
              "speed3d": 9.610000000000001,
              "msFromStart": 38226.819025341145
            },
            {
              "lat": 43.75635168,
              "lng": -116.09489822000002,
              "alt": 2119.4496999999997,
              "speed2d": 10.149999999999997,
              "speed3d": 10.296,
              "msFromStart": 38750.32789473683
            },
            {
              "lat": 43.75640614444445,
              "lng": -116.09491247777778,
              "alt": 2118.1865555555555,
              "speed2d": 10.64511111111111,
              "speed3d": 10.822222222222221,
              "msFromStart": 39273.19398050682
            },
            {
              "lat": 43.75645393333333,
              "lng": -116.09492118888889,
              "alt": 2117.2233333333334,
              "speed2d": 10.546333333333333,
              "speed3d": 10.773333333333333,
              "msFromStart": 39766.42711111108
            },
            {
              "lat": 43.75650093333334,
              "lng": -116.09492787777778,
              "alt": 2116.4464444444443,
              "speed2d": 10.435222222222222,
              "speed3d": 10.620000000000001,
              "msFromStart": 40260.02866666666
            },
            {
              "lat": 43.75655053333333,
              "lng": -116.09493737777778,
              "alt": 2115.3790000000004,
              "speed2d": 10.566555555555556,
              "speed3d": 10.766666666666667,
              "msFromStart": 40754.92644444446
            },
            {
              "lat": 43.75659095555556,
              "lng": -116.0949520888889,
              "alt": 2114.534222222222,
              "speed2d": 10.024666666666667,
              "speed3d": 10.326666666666666,
              "msFromStart": 41250.5934814815
            },
            {
              "lat": 43.756604333333335,
              "lng": -116.09496957777777,
              "alt": 2112.569111111111,
              "speed2d": 8.397111111111112,
              "speed3d": 8.794444444444444,
              "msFromStart": 41748.16333333336
            },
            {
              "lat": 43.7566200111111,
              "lng": -116.09499054444444,
              "alt": 2111.0035555555555,
              "speed2d": 7.698888888888889,
              "speed3d": 7.975555555555555,
              "msFromStart": 42245.301333333344
            },
            {
              "lat": 43.75665703333333,
              "lng": -116.09502198888889,
              "alt": 2110.041888888889,
              "speed2d": 8.345,
              "speed3d": 8.517777777777777,
              "msFromStart": 42740.995333333325
            },
            {
              "lat": 43.75669321111112,
              "lng": -116.09505546666668,
              "alt": 2109.1157777777776,
              "speed2d": 8.94888888888889,
              "speed3d": 9.171111111111111,
              "msFromStart": 43236.45051851852
            },
            {
              "lat": 43.75672001111111,
              "lng": -116.09508362222223,
              "alt": 2108.2273333333333,
              "speed2d": 8.963,
              "speed3d": 9.143333333333333,
              "msFromStart": 43731.463777777804
            },
            {
              "lat": 43.75675234444445,
              "lng": -116.09511055555556,
              "alt": 2107.2423333333336,
              "speed2d": 9.244888888888891,
              "speed3d": 9.455555555555556,
              "msFromStart": 44226.31194931775
            },
            {
              "lat": 43.75679465,
              "lng": -116.09513504999998,
              "alt": 2106.3399999999997,
              "speed2d": 9.5208,
              "speed3d": 9.672,
              "msFromStart": 44748.24421052631
            },
            {
              "lat": 43.75684673333333,
              "lng": -116.09515340000002,
              "alt": 2105.0072222222225,
              "speed2d": 10.190444444444445,
              "speed3d": 10.341111111111111,
              "msFromStart": 45270.41366861598
            },
            {
              "lat": 43.756888411111106,
              "lng": -116.0951621888889,
              "alt": 2104.1884444444445,
              "speed2d": 9.853222222222223,
              "speed3d": 10.13888888888889,
              "msFromStart": 45765.81288888888
            },
            {
              "lat": 43.756925466666665,
              "lng": -116.09516447777776,
              "alt": 2103.4235555555556,
              "speed2d": 9.245444444444445,
              "speed3d": 9.472222222222223,
              "msFromStart": 46260.66866666667
            },
            {
              "lat": 43.756967788888886,
              "lng": -116.0951704,
              "alt": 2102.6258888888888,
              "speed2d": 9.306000000000001,
              "speed3d": 9.491111111111111,
              "msFromStart": 46753.94955555557
            },
            {
              "lat": 43.757011444444444,
              "lng": -116.09517961111112,
              "alt": 2101.912777777778,
              "speed2d": 9.453555555555555,
              "speed3d": 9.634444444444444,
              "msFromStart": 47247.6748888889
            },
            {
              "lat": 43.75705365555555,
              "lng": -116.09519018888889,
              "alt": 2101.0207777777778,
              "speed2d": 9.486222222222223,
              "speed3d": 9.663333333333334,
              "msFromStart": 47742.83155555559
            },
            {
              "lat": 43.75709615555555,
              "lng": -116.09520324444445,
              "alt": 2100.5608888888887,
              "speed2d": 9.432,
              "speed3d": 9.61888888888889,
              "msFromStart": 48237.62860038987
            },
            {
              "lat": 43.75713701111111,
              "lng": -116.0952172888889,
              "alt": 2100.110111111111,
              "speed2d": 9.27211111111111,
              "speed3d": 9.41777777777778,
              "msFromStart": 48731.24505263158
            },
            {
              "lat": 43.75718084444444,
              "lng": -116.09523351111112,
              "alt": 2099.3924444444447,
              "speed2d": 9.486,
              "speed3d": 9.617777777777778,
              "msFromStart": 49225.283754385964
            },
            {
              "lat": 43.757227459999996,
              "lng": -116.09525182999998,
              "alt": 2098.4989,
              "speed2d": 9.6792,
              "speed3d": 9.816,
              "msFromStart": 49748.824999999975
            },
            {
              "lat": 43.757275166666666,
              "lng": -116.0952731111111,
              "alt": 2097.6632222222224,
              "speed2d": 9.922555555555556,
              "speed3d": 10.046666666666667,
              "msFromStart": 50272.36503703703
            },
            {
              "lat": 43.75732182222222,
              "lng": -116.09529133333335,
              "alt": 2096.875666666667,
              "speed2d": 10.116777777777779,
              "speed3d": 10.224444444444444,
              "msFromStart": 50767.81088888888
            },
            {
              "lat": 43.75736983333333,
              "lng": -116.0953136888889,
              "alt": 2095.848,
              "speed2d": 10.389888888888889,
              "speed3d": 10.514444444444447,
              "msFromStart": 51263.02251851852
            },
            {
              "lat": 43.75741971111111,
              "lng": -116.09533642222223,
              "alt": 2094.907,
              "speed2d": 10.686666666666667,
              "speed3d": 10.806666666666668,
              "msFromStart": 51757.67399999999
            },
            {
              "lat": 43.75746896666667,
              "lng": -116.0953584111111,
              "alt": 2094.0544444444445,
              "speed2d": 10.808666666666667,
              "speed3d": 10.943333333333335,
              "msFromStart": 52252.50029629628
            },
            {
              "lat": 43.75751483333333,
              "lng": -116.0953818,
              "alt": 2092.952111111111,
              "speed2d": 10.967333333333334,
              "speed3d": 11.07,
              "msFromStart": 52747.88111111108
            },
            {
              "lat": 43.75755368888889,
              "lng": -116.09541020000002,
              "alt": 2091.1497777777777,
              "speed2d": 11.788999999999998,
              "speed3d": 11.797777777777778,
              "msFromStart": 53243.15266666665
            },
            {
              "lat": 43.757603444444456,
              "lng": -116.09544437777778,
              "alt": 2089.6665555555555,
              "speed2d": 12.629666666666667,
              "speed3d": 12.731111111111112,
              "msFromStart": 53738.04844444443
            },
            {
              "lat": 43.75764665555555,
              "lng": -116.09546963333334,
              "alt": 2088.3940000000002,
              "speed2d": 12.614555555555555,
              "speed3d": 12.748888888888887,
              "msFromStart": 54232.90550487329
            },
            {
              "lat": 43.7576897,
              "lng": -116.09548625555556,
              "alt": 2086.893666666667,
              "speed2d": 12.754888888888889,
              "speed3d": 12.82,
              "msFromStart": 54727.711157894744
            },
            {
              "lat": 43.75774450000001,
              "lng": -116.09550080000002,
              "alt": 2085.5265999999997,
              "speed2d": 12.745400000000002,
              "speed3d": 12.908,
              "msFromStart": 55250.34453684211
            },
            {
              "lat": 43.7578022,
              "lng": -116.09551861111112,
              "alt": 2084.8074444444446,
              "speed2d": 12.143777777777775,
              "speed3d": 12.326666666666666,
              "msFromStart": 55774.08333333333
            },
            {
              "lat": 43.75785488888889,
              "lng": -116.09554234444445,
              "alt": 2083.6147777777774,
              "speed2d": 11.799555555555555,
              "speed3d": 11.94111111111111,
              "msFromStart": 56269.87022222223
            },
            {
              "lat": 43.75791175555555,
              "lng": -116.09556712222223,
              "alt": 2082.2255555555557,
              "speed2d": 11.941333333333334,
              "speed3d": 12.05,
              "msFromStart": 56764.35666666668
            },
            {
              "lat": 43.75797617777778,
              "lng": -116.09558518888889,
              "alt": 2080.7049999999995,
              "speed2d": 12.726444444444445,
              "speed3d": 12.756666666666668,
              "msFromStart": 57258.74666666667
            },
            {
              "lat": 43.758042399999994,
              "lng": -116.09559273333332,
              "alt": 2079.3212222222223,
              "speed2d": 13.398444444444443,
              "speed3d": 13.526666666666669,
              "msFromStart": 57753.06066666666
            },
            {
              "lat": 43.75810707777778,
              "lng": -116.09559393333333,
              "alt": 2078.7057777777777,
              "speed2d": 13.670444444444442,
              "speed3d": 13.832222222222223,
              "msFromStart": 58247.646818713445
            },
            {
              "lat": 43.75816828888889,
              "lng": -116.09559566666665,
              "alt": 2077.8108888888887,
              "speed2d": 13.356444444444445,
              "speed3d": 13.533333333333331,
              "msFromStart": 58742.98252631578
            },
            {
              "lat": 43.75822617777778,
              "lng": -116.09560583333334,
              "alt": 2076.3334444444445,
              "speed2d": 12.84511111111111,
              "speed3d": 13.094444444444443,
              "msFromStart": 59238.507034055714
            },
            {
              "lat": 43.75828276666666,
              "lng": -116.09562763333334,
              "alt": 2074.898,
              "speed2d": 12.533000000000001,
              "speed3d": 12.70777777777778,
              "msFromStart": 59734.62894117644
            },
            {
              "lat": 43.75834067777778,
              "lng": -116.09565648888889,
              "alt": 2074.1505555555555,
              "speed2d": 12.646444444444443,
              "speed3d": 12.822222222222223,
              "msFromStart": 60230.301849329204
            },
            {
              "lat": 43.75839835000001,
              "lng": -116.09569018,
              "alt": 2073.3969,
              "speed2d": 12.5429,
              "speed3d": 12.738999999999999,
              "msFromStart": 60752.05052631583
            },
            {
              "lat": 43.75845337777778,
              "lng": -116.09572353333334,
              "alt": 2072.5765555555554,
              "speed2d": 12.209888888888889,
              "speed3d": 12.411111111111111,
              "msFromStart": 61273.836615984415
            },
            {
              "lat": 43.75850394444444,
              "lng": -116.09575141111111,
              "alt": 2071.673,
              "speed2d": 11.726888888888888,
              "speed3d": 11.95888888888889,
              "msFromStart": 61768.46488888886
            },
            {
              "lat": 43.758554511111114,
              "lng": -116.09577107777777,
              "alt": 2070.831111111111,
              "speed2d": 11.351333333333335,
              "speed3d": 11.544444444444444,
              "msFromStart": 62263.06585185185
            },
            {
              "lat": 43.758609388888885,
              "lng": -116.09578345555556,
              "alt": 2069.6177777777775,
              "speed2d": 11.543777777777777,
              "speed3d": 11.707777777777778,
              "msFromStart": 62757.55000000003
            },
            {
              "lat": 43.75866263333334,
              "lng": -116.0957999777778,
              "alt": 2068.6723333333334,
              "speed2d": 11.549555555555553,
              "speed3d": 11.733333333333334,
              "msFromStart": 63252.573017543866
            },
            {
              "lat": 43.75871365555556,
              "lng": -116.09582437777777,
              "alt": 2067.965444444444,
              "speed2d": 11.557666666666668,
              "speed3d": 11.756666666666668,
              "msFromStart": 63749.07536842105
            },
            {
              "lat": 43.758756566666676,
              "lng": -116.09585026666667,
              "alt": 2067.1313333333333,
              "speed2d": 11.45111111111111,
              "speed3d": 11.612222222222222,
              "msFromStart": 64245.114428849905
            },
            {
              "lat": 43.7587997,
              "lng": -116.09587345555558,
              "alt": 2066.0105555555556,
              "speed2d": 11.657222222222222,
              "speed3d": 11.80111111111111,
              "msFromStart": 64738.9888888889
            },
            {
              "lat": 43.758846022222215,
              "lng": -116.09588862222223,
              "alt": 2065.273777777778,
              "speed2d": 11.453888888888889,
              "speed3d": 11.63888888888889,
              "msFromStart": 65232.81207407408
            },
            {
              "lat": 43.75889355555555,
              "lng": -116.09590093333333,
              "alt": 2064.681888888889,
              "speed2d": 11.04411111111111,
              "speed3d": 11.23,
              "msFromStart": 65727.29599999997
            },
            {
              "lat": 43.75892889,
              "lng": -116.09591478000002,
              "alt": 2063.8059000000003,
              "speed2d": 10.2325,
              "speed3d": 10.472999999999999,
              "msFromStart": 66249.24306666666
            },
            {
              "lat": 43.758959222222224,
              "lng": -116.09593713333332,
              "alt": 2062.8523333333333,
              "speed2d": 9.998444444444445,
              "speed3d": 10.124444444444443,
              "msFromStart": 66770.93777777784
            },
            {
              "lat": 43.758988200000005,
              "lng": -116.09596035555556,
              "alt": 2061.918222222222,
              "speed2d": 9.734444444444446,
              "speed3d": 9.913333333333334,
              "msFromStart": 67265.93881481484
            },
            {
              "lat": 43.75901817777777,
              "lng": -116.09597900000001,
              "alt": 2060.8315555555555,
              "speed2d": 9.404333333333334,
              "speed3d": 9.576666666666668,
              "msFromStart": 67763.07066666674
            },
            {
              "lat": 43.75905173333334,
              "lng": -116.0959888,
              "alt": 2059.2876666666666,
              "speed2d": 9.285666666666666,
              "speed3d": 9.422222222222222,
              "msFromStart": 68259.99422222223
            },
            {
              "lat": 43.75908612222222,
              "lng": -116.09599051111113,
              "alt": 2057.460555555556,
              "speed2d": 9.338222222222221,
              "speed3d": 9.51,
              "msFromStart": 68756.04799999994
            },
            {
              "lat": 43.75911886666667,
              "lng": -116.09598474444445,
              "alt": 2056.3737777777774,
              "speed2d": 9.209,
              "speed3d": 9.385555555555555,
              "msFromStart": 69251.53431578944
            },
            {
              "lat": 43.75915625555555,
              "lng": -116.0959817,
              "alt": 2055.386111111111,
              "speed2d": 9.353222222222222,
              "speed3d": 9.48777777777778,
              "msFromStart": 69745.60463157893
            },
            {
              "lat": 43.75919632222222,
              "lng": -116.0959886,
              "alt": 2054.2433333333333,
              "speed2d": 9.570666666666668,
              "speed3d": 9.678888888888888,
              "msFromStart": 70240.05453411303
            },
            {
              "lat": 43.75923788888889,
              "lng": -116.09599883333334,
              "alt": 2053.148111111111,
              "speed2d": 9.904888888888888,
              "speed3d": 10.023333333333333,
              "msFromStart": 70736.32088888886
            },
            {
              "lat": 43.75927804444444,
              "lng": -116.09600352222223,
              "alt": 2051.907888888889,
              "speed2d": 10.138555555555554,
              "speed3d": 10.257777777777777,
              "msFromStart": 71232.41333333332
            },
            {
              "lat": 43.759315900000004,
              "lng": -116.09600047777778,
              "alt": 2050.6800000000003,
              "speed2d": 9.974222222222224,
              "speed3d": 10.165555555555557,
              "msFromStart": 71727.08355555554
            },
            {
              "lat": 43.759353180000005,
              "lng": -116.09599016,
              "alt": 2049.778,
              "speed2d": 9.6451,
              "speed3d": 9.806,
              "msFromStart": 72248.86786666665
            },
            {
              "lat": 43.759392388888884,
              "lng": -116.09598064444444,
              "alt": 2048.4625555555554,
              "speed2d": 9.479333333333333,
              "speed3d": 9.673333333333334,
              "msFromStart": 72769.94799999997
            },
            {
              "lat": 43.75942652222223,
              "lng": -116.09597785555556,
              "alt": 2047.118111111111,
              "speed2d": 8.929555555555556,
              "speed3d": 9.162222222222223,
              "msFromStart": 73263.7317037037
            },
            {
              "lat": 43.75945424444445,
              "lng": -116.09598696666666,
              "alt": 2045.3884444444448,
              "speed2d": 8.171111111111111,
              "speed3d": 8.427777777777777,
              "msFromStart": 73758.02488888893
            },
            {
              "lat": 43.75947735555555,
              "lng": -116.09600165555555,
              "alt": 2043.3264444444444,
              "speed2d": 7.695777777777778,
              "speed3d": 7.954444444444444,
              "msFromStart": 74252.57030799222
            },
            {
              "lat": 43.7595094,
              "lng": -116.09602761111111,
              "alt": 2042.0453333333332,
              "speed2d": 8.40711111111111,
              "speed3d": 8.528888888888888,
              "msFromStart": 74747.72757894738
            },
            {
              "lat": 43.75954484444444,
              "lng": -116.09605235555554,
              "alt": 2040.591777777778,
              "speed2d": 9.040888888888889,
              "speed3d": 9.225555555555555,
              "msFromStart": 75243.02640935672
            },
            {
              "lat": 43.759580211111114,
              "lng": -116.09606775555557,
              "alt": 2039.0416666666665,
              "speed2d": 9.228666666666665,
              "speed3d": 9.44,
              "msFromStart": 75738.76266666669
            },
            {
              "lat": 43.75961806666667,
              "lng": -116.09607424444445,
              "alt": 2037.4293333333335,
              "speed2d": 9.510555555555555,
              "speed3d": 9.72888888888889,
              "msFromStart": 76234.28562962965
            },
            {
              "lat": 43.75965686666667,
              "lng": -116.09608178888891,
              "alt": 2036.2403333333334,
              "speed2d": 9.71477777777778,
              "speed3d": 9.901111111111113,
              "msFromStart": 76728.78044444449
            },
            {
              "lat": 43.75970168999999,
              "lng": -116.09608982,
              "alt": 2035.5728000000001,
              "speed2d": 9.9175,
              "speed3d": 10.118,
              "msFromStart": 77250.54533333337
            },
            {
              "lat": 43.759749955555556,
              "lng": -116.09611223333332,
              "alt": 2034.5564444444444,
              "speed2d": 10.487444444444444,
              "speed3d": 10.563333333333333,
              "msFromStart": 77772.00800000007
            },
            {
              "lat": 43.75979701111111,
              "lng": -116.09615085555556,
              "alt": 2033.0183333333334,
              "speed2d": 11.373444444444447,
              "speed3d": 11.481111111111112,
              "msFromStart": 78266.41263157896
            },
            {
              "lat": 43.75983442222223,
              "lng": -116.09618393333334,
              "alt": 2031.5492222222222,
              "speed2d": 11.197555555555557,
              "speed3d": 11.446666666666665,
              "msFromStart": 78761.93726315787
            },
            {
              "lat": 43.75987411111112,
              "lng": -116.09620583333334,
              "alt": 2030.0074444444444,
              "speed2d": 11.000111111111114,
              "speed3d": 11.197777777777777,
              "msFromStart": 79257.31795711498
            },
            {
              "lat": 43.759914566666666,
              "lng": -116.09621906666668,
              "alt": 2028.7316666666668,
              "speed2d": 10.827666666666666,
              "speed3d": 11.062222222222221,
              "msFromStart": 79751.88711111105
            },
            {
              "lat": 43.75995492222222,
              "lng": -116.09622396666666,
              "alt": 2027.7221111111107,
              "speed2d": 10.69988888888889,
              "speed3d": 10.90111111111111,
              "msFromStart": 80246.54399999995
            },
            {
              "lat": 43.7599984,
              "lng": -116.09622531111111,
              "alt": 2027.0312222222224,
              "speed2d": 10.729666666666665,
              "speed3d": 10.94111111111111,
              "msFromStart": 80741.87644444442
            },
            {
              "lat": 43.760039899999995,
              "lng": -116.09622335555555,
              "alt": 2026.5328888888887,
              "speed2d": 10.469777777777779,
              "speed3d": 10.673333333333332,
              "msFromStart": 81237.24311111111
            },
            {
              "lat": 43.76008731111111,
              "lng": -116.09623686666667,
              "alt": 2025.5142222222223,
              "speed2d": 10.613222222222221,
              "speed3d": 10.747777777777777,
              "msFromStart": 81732.4764444444
            },
            {
              "lat": 43.760126811111114,
              "lng": -116.09626912222221,
              "alt": 2023.3063333333334,
              "speed2d": 10.58088888888889,
              "speed3d": 10.842222222222222,
              "msFromStart": 82227.53866666666
            },
            {
              "lat": 43.76014841,
              "lng": -116.09629847000001,
              "alt": 2021.5256999999997,
              "speed2d": 9.511800000000001,
              "speed3d": 9.908,
              "msFromStart": 82749.43466666674
            },
            {
              "lat": 43.76016147777777,
              "lng": -116.09632282222222,
              "alt": 2020.0422222222223,
              "speed2d": 8.229333333333333,
              "speed3d": 8.644444444444444,
              "msFromStart": 83271.4677037037
            },
            {
              "lat": 43.760177955555555,
              "lng": -116.0963475,
              "alt": 2018.8941111111112,
              "speed2d": 7.620666666666667,
              "speed3d": 8.014444444444445,
              "msFromStart": 83766.57911111109
            },
            {
              "lat": 43.7601935,
              "lng": -116.09636605555556,
              "alt": 2017.37,
              "speed2d": 6.863444444444444,
              "speed3d": 7.356666666666667,
              "msFromStart": 84261.81053411307
            },
            {
              "lat": 43.76021313333334,
              "lng": -116.09636953333334,
              "alt": 2015.751,
              "speed2d": 6.282666666666667,
              "speed3d": 6.774444444444444,
              "msFromStart": 84757.29010526321
            },
            {
              "lat": 43.76023514444445,
              "lng": -116.0963713111111,
              "alt": 2014.4654444444445,
              "speed2d": 6.076111111111111,
              "speed3d": 6.552222222222222,
              "msFromStart": 85252.74113840159
            },
            {
              "lat": 43.76026536666666,
              "lng": -116.09638976666666,
              "alt": 2012.9208888888888,
              "speed2d": 6.357777777777778,
              "speed3d": 6.833333333333334,
              "msFromStart": 85748.02577777774
            },
            {
              "lat": 43.76028436666667,
              "lng": -116.09640693333333,
              "alt": 2011.0555555555554,
              "speed2d": 6.162333333333334,
              "speed3d": 6.702222222222223,
              "msFromStart": 86243.25970370369
            },
            {
              "lat": 43.760312166666665,
              "lng": -116.09642063333334,
              "alt": 2009.242888888889,
              "speed2d": 6.469666666666667,
              "speed3d": 6.95,
              "msFromStart": 86738.35733333335
            },
            {
              "lat": 43.760355499999996,
              "lng": -116.09642582222222,
              "alt": 2006.7144444444443,
              "speed2d": 7.808333333333333,
              "speed3d": 8.157777777777778,
              "msFromStart": 87233.33318518518
            },
            {
              "lat": 43.76039780000001,
              "lng": -116.09643086666668,
              "alt": 2004.1392222222225,
              "speed2d": 8.49788888888889,
              "speed3d": 8.964444444444446,
              "msFromStart": 87727.87644444438
            },
            {
              "lat": 43.76043958999999,
              "lng": -116.09644446,
              "alt": 2001.905,
              "speed2d": 8.7501,
              "speed3d": 9.276000000000002,
              "msFromStart": 88250.02280701752
            },
            {
              "lat": 43.760478577777775,
              "lng": -116.09646646666666,
              "alt": 1999.702,
              "speed2d": 8.882444444444445,
              "speed3d": 9.404444444444444,
              "msFromStart": 88772.73305263161
            },
            {
              "lat": 43.76052073333334,
              "lng": -116.09648466666665,
              "alt": 1998.2808888888892,
              "speed2d": 9.181444444444445,
              "speed3d": 9.662222222222223,
              "msFromStart": 89267.88412475635
            },
            {
              "lat": 43.7605718888889,
              "lng": -116.09650292222224,
              "alt": 1996.2801111111114,
              "speed2d": 9.84488888888889,
              "speed3d": 10.30111111111111,
              "msFromStart": 89762.65422222226
            },
            {
              "lat": 43.76062585555556,
              "lng": -116.09652056666667,
              "alt": 1994.1093333333333,
              "speed2d": 10.45411111111111,
              "speed3d": 10.911111111111111,
              "msFromStart": 90257.29955555557
            },
            {
              "lat": 43.76068691111111,
              "lng": -116.09655484444446,
              "alt": 1991.3476666666666,
              "speed2d": 11.508111111111113,
              "speed3d": 11.895555555555555,
              "msFromStart": 90751.59822222215
            },
            {
              "lat": 43.76074537777778,
              "lng": -116.09659392222224,
              "alt": 1989.812777777778,
              "speed2d": 12.355555555555556,
              "speed3d": 12.762222222222222,
              "msFromStart": 91246.0822222222
            },
            {
              "lat": 43.76080258888889,
              "lng": -116.0966293111111,
              "alt": 1988.0294444444444,
              "speed2d": 12.93611111111111,
              "speed3d": 13.36,
              "msFromStart": 91741.4462222223
            },
            {
              "lat": 43.760853811111105,
              "lng": -116.09665166666666,
              "alt": 1986.030888888889,
              "speed2d": 12.942777777777776,
              "speed3d": 13.408888888888889,
              "msFromStart": 92236.89451851853
            },
            {
              "lat": 43.76091062222223,
              "lng": -116.09667522222222,
              "alt": 1984.6985555555557,
              "speed2d": 13.06388888888889,
              "speed3d": 13.507777777777777,
              "msFromStart": 92732.32799999995
            },
            {
              "lat": 43.76097023333333,
              "lng": -116.09670933333332,
              "alt": 1983.087,
              "speed2d": 13.351444444444445,
              "speed3d": 13.748888888888889,
              "msFromStart": 93227.85407407404
            },
            {
              "lat": 43.76100520850626,
              "lng": -116.09673159749747,
              "alt": 1982.0757253905024,
              "speed2d": 13.673414165083965,
              "speed3d": 14.031502005589417,
              "msFromStart": 93500
            },
            {
              "lat": 43.761063838757906,
              "lng": -116.09676192435538,
              "alt": 1981.2182887138297,
              "speed2d": 13.46162825993638,
              "speed3d": 13.806561072668321,
              "msFromStart": 94000
            },
            {
              "lat": 43.76112246900956,
              "lng": -116.0967922512133,
              "alt": 1980.360852037157,
              "speed2d": 13.249842354788795,
              "speed3d": 13.581620139747226,
              "msFromStart": 94500
            },
            {
              "lat": 43.7611810992612,
              "lng": -116.09682257807123,
              "alt": 1979.5034153604843,
              "speed2d": 13.03805644964121,
              "speed3d": 13.35667920682613,
              "msFromStart": 95000
            },
            {
              "lat": 43.761239729512845,
              "lng": -116.09685290492914,
              "alt": 1978.6459786838116,
              "speed2d": 12.826270544493624,
              "speed3d": 13.131738273905034,
              "msFromStart": 95500
            },
            {
              "lat": 43.761298359764496,
              "lng": -116.09688323178706,
              "alt": 1977.7885420071389,
              "speed2d": 12.61448463934604,
              "speed3d": 12.906797340983939,
              "msFromStart": 96000
            },
            {
              "lat": 43.76135699001614,
              "lng": -116.09691355864499,
              "alt": 1976.9311053304664,
              "speed2d": 12.402698734198456,
              "speed3d": 12.681856408062844,
              "msFromStart": 96500
            },
            {
              "lat": 43.76141562026778,
              "lng": -116.09694388550291,
              "alt": 1976.0736686537937,
              "speed2d": 12.19091282905087,
              "speed3d": 12.456915475141749,
              "msFromStart": 97000
            },
            {
              "lat": 43.76147425051943,
              "lng": -116.09697421236082,
              "alt": 1975.216231977121,
              "speed2d": 11.979126923903285,
              "speed3d": 12.231974542220653,
              "msFromStart": 97500
            },
            {
              "lat": 43.76153288077108,
              "lng": -116.09700453921874,
              "alt": 1974.3587953004483,
              "speed2d": 11.767341018755701,
              "speed3d": 12.007033609299558,
              "msFromStart": 98000
            },
            {
              "lat": 43.76159151102272,
              "lng": -116.09703486607667,
              "alt": 1973.5013586237756,
              "speed2d": 11.555555113608115,
              "speed3d": 11.782092676378461,
              "msFromStart": 98500
            },
            {
              "lat": 43.761650141274366,
              "lng": -116.09706519293458,
              "alt": 1972.6439219471029,
              "speed2d": 11.34376920846053,
              "speed3d": 11.557151743457368,
              "msFromStart": 99000
            },
            {
              "lat": 43.76170877152601,
              "lng": -116.0970955197925,
              "alt": 1971.7864852704304,
              "speed2d": 11.131983303312946,
              "speed3d": 11.33221081053627,
              "msFromStart": 99500
            },
            {
              "lat": 43.76176740177766,
              "lng": -116.09712584665043,
              "alt": 1970.9290485937577,
              "speed2d": 10.92019739816536,
              "speed3d": 11.107269877615176,
              "msFromStart": 100000
            },
            {
              "lat": 43.761826032029305,
              "lng": -116.09715617350835,
              "alt": 1970.071611917085,
              "speed2d": 10.708411493017776,
              "speed3d": 10.88232894469408,
              "msFromStart": 100500
            },
            {
              "lat": 43.76188466228095,
              "lng": -116.09718650036626,
              "alt": 1969.2141752404123,
              "speed2d": 10.496625587870192,
              "speed3d": 10.657388011772985,
              "msFromStart": 101000
            },
            {
              "lat": 43.76194329253259,
              "lng": -116.09721682722419,
              "alt": 1968.3567385637396,
              "speed2d": 10.284839682722605,
              "speed3d": 10.43244707885189,
              "msFromStart": 101500
            },
            {
              "lat": 43.76200192278424,
              "lng": -116.09724715408211,
              "alt": 1967.4993018870668,
              "speed2d": 10.073053777575021,
              "speed3d": 10.207506145930795,
              "msFromStart": 102000
            },
            {
              "lat": 43.76206055303589,
              "lng": -116.09727748094002,
              "alt": 1966.6418652103944,
              "speed2d": 9.861267872427437,
              "speed3d": 9.9825652130097,
              "msFromStart": 102500
            },
            {
              "lat": 43.762122025849955,
              "lng": -116.09729923564709,
              "alt": 1964.6592977447867,
              "speed2d": 9.505274150045329,
              "speed3d": 9.625650385312781,
              "msFromStart": 103000
            },
            {
              "lat": 43.76217757645868,
              "lng": -116.09733884960578,
              "alt": 1965.0208320343866,
              "speed2d": 9.449723541315896,
              "speed3d": 9.543690470793944,
              "msFromStart": 103500
            },
            {
              "lat": 43.76224615,
              "lng": -116.0973879,
              "alt": 1965.4340000000002,
              "speed2d": 9.368500000000001,
              "speed3d": 9.48,
              "msFromStart": 104120.47908771933
            },
            {
              "lat": 43.762285766302526,
              "lng": -116.09741752849135,
              "alt": 1965.6290573796716,
              "speed2d": 9.294009788022358,
              "speed3d": 9.437512235027949,
              "msFromStart": 104500
            },
            {
              "lat": 43.76233690174213,
              "lng": -116.0974567470797,
              "alt": 1965.955528766246,
              "speed2d": 9.078962581321228,
              "speed3d": 9.194365075899812,
              "msFromStart": 105000
            },
            {
              "lat": 43.76238920566156,
              "lng": -116.09749525489285,
              "alt": 1966.1316944311031,
              "speed2d": 9.260889257593615,
              "speed3d": 9.364163307087374,
              "msFromStart": 105500
            },
            {
              "lat": 43.76244150958099,
              "lng": -116.09753376270601,
              "alt": 1966.3078600959602,
              "speed2d": 9.442815933866001,
              "speed3d": 9.533961538274934,
              "msFromStart": 106000
            },
            {
              "lat": 43.76249688008144,
              "lng": -116.09757481375497,
              "alt": 1966.9031167362125,
              "speed2d": 9.67849670705342,
              "speed3d": 9.744343673017926,
              "msFromStart": 106500
            },
            {
              "lat": 43.76254505932499,
              "lng": -116.09760990081277,
              "alt": 1966.5155880381412,
              "speed2d": 9.788121942363643,
              "speed3d": 9.859554907579627,
              "msFromStart": 107000
            },
            {
              "lat": 43.762593238568535,
              "lng": -116.09764498787057,
              "alt": 1966.1280593400702,
              "speed2d": 9.897747177673867,
              "speed3d": 9.974766142141327,
              "msFromStart": 107500
            },
            {
              "lat": 43.76264211359694,
              "lng": -116.09768118879009,
              "alt": 1965.9452167638485,
              "speed2d": 9.92726107871721,
              "speed3d": 10.035410349854235,
              "msFromStart": 108000
            },
            {
              "lat": 43.762687823184905,
              "lng": -116.09771406184366,
              "alt": 1965.0800294519074,
              "speed2d": 10.19810940838569,
              "speed3d": 10.26085245153057,
              "msFromStart": 108500
            },
            {
              "lat": 43.76273758446211,
              "lng": -116.09774810105763,
              "alt": 1964.6456837776593,
              "speed2d": 10.379007846635488,
              "speed3d": 10.423755757179602,
              "msFromStart": 109000
            },
            {
              "lat": 43.76278734573931,
              "lng": -116.09778214027162,
              "alt": 1964.2113381034114,
              "speed2d": 10.559906284885287,
              "speed3d": 10.586659062828636,
              "msFromStart": 109500
            },
            {
              "lat": 43.76283710701651,
              "lng": -116.0978161794856,
              "alt": 1963.7769924291636,
              "speed2d": 10.740804723135087,
              "speed3d": 10.749562368477669,
              "msFromStart": 110000
            },
            {
              "lat": 43.76288686829371,
              "lng": -116.09785021869958,
              "alt": 1963.3426467549157,
              "speed2d": 10.921703161384885,
              "speed3d": 10.912465674126702,
              "msFromStart": 110500
            },
            {
              "lat": 43.76297983333333,
              "lng": -116.09791403333332,
              "alt": 1962.5226666666667,
              "speed2d": 11.241,
              "speed3d": 11.299999999999999,
              "msFromStart": 111437.58755555558
            },
            {
              "lat": 43.76298496811092,
              "lng": -116.09791709809308,
              "alt": 1962.388409809355,
              "speed2d": 11.199817027922522,
              "speed3d": 11.266191453941557,
              "msFromStart": 111500
            },
            {
              "lat": 43.763034603312065,
              "lng": -116.0979539791928,
              "alt": 1961.6593101406459,
              "speed2d": 11.34822304674477,
              "speed3d": 11.416066839286799,
              "msFromStart": 112000
            },
            {
              "lat": 43.763084238513215,
              "lng": -116.09799086029253,
              "alt": 1960.930210471937,
              "speed2d": 11.49662906556702,
              "speed3d": 11.56594222463204,
              "msFromStart": 112500
            },
            {
              "lat": 43.763138066331635,
              "lng": -116.09802890381499,
              "alt": 1960.0435958877877,
              "speed2d": 12.263589529485651,
              "speed3d": 12.273405291945627,
              "msFromStart": 113000
            },
            {
              "lat": 43.76319705,
              "lng": -116.0980759,
              "alt": 1959.3485,
              "speed2d": 11.527000000000001,
              "speed3d": 11.6,
              "msFromStart": 113661.62063157899
            },
            {
              "lat": 43.7632679,
              "lng": -116.09812987500001,
              "alt": 1958.6149999999998,
              "speed2d": 11.711,
              "speed3d": 11.780000000000001,
              "msFromStart": 114376.58044444447
            },
            {
              "lat": 43.76329725555556,
              "lng": -116.09815464444443,
              "alt": 1958.0216666666668,
              "speed2d": 11.596666666666666,
              "speed3d": 11.69222222222222,
              "msFromStart": 114734.97600000002
            },
            {
              "lat": 43.763312008830844,
              "lng": -116.0981706772734,
              "alt": 1957.3403523568393,
              "speed2d": 11.286620639306756,
              "speed3d": 11.451774923340052,
              "msFromStart": 115000
            },
            {
              "lat": 43.76335408323696,
              "lng": -116.09820407446388,
              "alt": 1956.4141100351662,
              "speed2d": 11.255846793107377,
              "speed3d": 11.396281102324776,
              "msFromStart": 115500
            },
            {
              "lat": 43.763397935870024,
              "lng": -116.0982408468276,
              "alt": 1955.5730386141533,
              "speed2d": 11.314481752081946,
              "speed3d": 11.418686995468258,
              "msFromStart": 116000
            },
            {
              "lat": 43.76343680503671,
              "lng": -116.09826816029607,
              "alt": 1954.4932764454552,
              "speed2d": 11.122549270872419,
              "speed3d": 11.222779581215931,
              "msFromStart": 116500
            },
            {
              "lat": 43.76347567420339,
              "lng": -116.09829547376455,
              "alt": 1953.4135142767573,
              "speed2d": 10.930616789662892,
              "speed3d": 11.026872166963603,
              "msFromStart": 117000
            },
            {
              "lat": 43.76351454337008,
              "lng": -116.09832278723303,
              "alt": 1952.3337521080593,
              "speed2d": 10.738684308453365,
              "speed3d": 10.830964752711276,
              "msFromStart": 117500
            },
            {
              "lat": 43.76355341253676,
              "lng": -116.09835010070151,
              "alt": 1951.2539899393614,
              "speed2d": 10.54675182724384,
              "speed3d": 10.63505733845895,
              "msFromStart": 118000
            },
            {
              "lat": 43.763592281703446,
              "lng": -116.09837741416999,
              "alt": 1950.1742277706635,
              "speed2d": 10.354819346034313,
              "speed3d": 10.439149924206621,
              "msFromStart": 118500
            },
            {
              "lat": 43.763631150870125,
              "lng": -116.09840472763847,
              "alt": 1949.0944656019653,
              "speed2d": 10.162886864824786,
              "speed3d": 10.243242509954294,
              "msFromStart": 119000
            },
            {
              "lat": 43.76367415703917,
              "lng": -116.09842718654815,
              "alt": 1948.304443131261,
              "speed2d": 9.754654913257632,
              "speed3d": 9.850086311529315,
              "msFromStart": 119500
            },
            {
              "lat": 43.76370611889964,
              "lng": -116.09846260538404,
              "alt": 1946.7409198619257,
              "speed2d": 9.923864762809425,
              "speed3d": 9.98351343303969,
              "msFromStart": 120000
            },
            {
              "lat": 43.76375558333333,
              "lng": -116.09851404999999,
              "alt": 1944.3168333333335,
              "speed2d": 10.142833333333334,
              "speed3d": 10.263333333333334,
              "msFromStart": 120761.05355555561
            },
            {
              "lat": 43.76380155,
              "lng": -116.0985415,
              "alt": 1941.9395,
              "speed2d": 9.8345,
              "speed3d": 10.035,
              "msFromStart": 121419.85399999999
            },
            {
              "lat": 43.763828950000004,
              "lng": -116.09856585,
              "alt": 1940.115,
              "speed2d": 8.993500000000001,
              "speed3d": 9.26,
              "msFromStart": 121969.65399999995
            },
            {
              "lat": 43.76384245,
              "lng": -116.09858129999999,
              "alt": 1939.1847500000001,
              "speed2d": 8.65525,
              "speed3d": 8.9175,
              "msFromStart": 122272.71833333331
            },
            {
              "lat": 43.763865075,
              "lng": -116.098603825,
              "alt": 1937.3597499999998,
              "speed2d": 8.19175,
              "speed3d": 8.46,
              "msFromStart": 122770.71733333335
            },
            {
              "lat": 43.7638721512806,
              "lng": -116.09861647424921,
              "alt": 1936.652559510993,
              "speed2d": 7.893774110380719,
              "speed3d": 8.178210562103313,
              "msFromStart": 123000
            },
            {
              "lat": 43.7639152586776,
              "lng": -116.09863598436854,
              "alt": 1935.2398215547703,
              "speed2d": 8.853345603570302,
              "speed3d": 8.799721402765613,
              "msFromStart": 123500
            },
            {
              "lat": 43.7639662,
              "lng": -116.0986592,
              "alt": 1934.0059999999999,
              "speed2d": 9.55,
              "speed3d": 9.685,
              "msFromStart": 124118.48533333334
            },
            {
              "lat": 43.7640125,
              "lng": -116.0986891,
              "alt": 1932.7476666666666,
              "speed2d": 10.071666666666665,
              "speed3d": 10.246666666666668,
              "msFromStart": 124692.72757894741
            },
            {
              "lat": 43.76403933100322,
              "lng": -116.09870702214177,
              "alt": 1931.995512738914,
              "speed2d": 10.341674302118793,
              "speed3d": 10.53076805952149,
              "msFromStart": 125000
            },
            {
              "lat": 43.7640944,
              "lng": -116.09873205,
              "alt": 1930.8775,
              "speed2d": 9.682,
              "speed3d": 9.9,
              "msFromStart": 125679.34755555559
            },
            {
              "lat": 43.76412001850119,
              "lng": -116.09874798195813,
              "alt": 1930.1554395556682,
              "speed2d": 9.642512847807696,
              "speed3d": 9.832339735060096,
              "msFromStart": 126000
            },
            {
              "lat": 43.7641847,
              "lng": -116.0988003,
              "alt": 1928.2800000000002,
              "speed2d": 10.1235,
              "speed3d": 10.215,
              "msFromStart": 126806.86800000002
            },
            {
              "lat": 43.76422230000001,
              "lng": -116.09883926666669,
              "alt": 1927.6458888888887,
              "speed2d": 10.633333333333333,
              "speed3d": 10.836666666666664,
              "msFromStart": 127274.26962962963
            },
            {
              "lat": 43.76424295555556,
              "lng": -116.09887648888889,
              "alt": 1926.7575555555559,
              "speed2d": 9.598222222222223,
              "speed3d": 9.941111111111109,
              "msFromStart": 127768.59911111112
            },
            {
              "lat": 43.764252733333336,
              "lng": -116.098906,
              "alt": 1926.0736666666667,
              "speed2d": 8.509833333333333,
              "speed3d": 8.828333333333333,
              "msFromStart": 128180.60804678366
            },
            {
              "lat": 43.764256799741744,
              "lng": -116.09893710364143,
              "alt": 1925.5295455468,
              "speed2d": 8.278447417851407,
              "speed3d": 8.518089072117414,
              "msFromStart": 128500
            },
            {
              "lat": 43.76428102887443,
              "lng": -116.09897282564982,
              "alt": 1925.0950341936418,
              "speed2d": 8.071777284678834,
              "speed3d": 8.22908693467284,
              "msFromStart": 129000
            },
            {
              "lat": 43.76430525800711,
              "lng": -116.0990085476582,
              "alt": 1924.660522840484,
              "speed2d": 7.86510715150626,
              "speed3d": 7.940084797228266,
              "msFromStart": 129500
            },
            {
              "lat": 43.76433246789338,
              "lng": -116.09903894697473,
              "alt": 1923.881039451408,
              "speed2d": 7.507456646158235,
              "speed3d": 7.459667304140687,
              "msFromStart": 130000
            },
            {
              "lat": 43.7643489420429,
              "lng": -116.09908851695297,
              "alt": 1924.3440368177298,
              "speed2d": 7.69358994743573,
              "speed3d": 7.668667708481335,
              "msFromStart": 130500
            },
            {
              "lat": 43.76436541619242,
              "lng": -116.09913808693123,
              "alt": 1924.8070341840514,
              "speed2d": 7.8797232487132245,
              "speed3d": 7.877668112821983,
              "msFromStart": 131000
            },
            {
              "lat": 43.76438189034194,
              "lng": -116.09918765690948,
              "alt": 1925.2700315503732,
              "speed2d": 8.06585654999072,
              "speed3d": 8.08666851716263,
              "msFromStart": 131500
            },
            {
              "lat": 43.76441612997976,
              "lng": -116.09924052578297,
              "alt": 1925.760319215573,
              "speed2d": 8.659944540811328,
              "speed3d": 8.489675243326563,
              "msFromStart": 132000
            },
            {
              "lat": 43.764393175110534,
              "lng": -116.09928781746888,
              "alt": 1925.7698381884848,
              "speed2d": 7.788239848685765,
              "speed3d": 8.489325277425893,
              "msFromStart": 132500
            },
            {
              "lat": 43.76437043926239,
              "lng": -116.09931883738935,
              "alt": 1927.0505436770154,
              "speed2d": 7.4145495786612505,
              "speed3d": 7.77580663886776,
              "msFromStart": 133000
            },
            {
              "lat": 43.764340866666664,
              "lng": -116.09935949999999,
              "alt": 1928.920333333333,
              "speed2d": 6.909000000000001,
              "speed3d": 6.943333333333333,
              "msFromStart": 133678.51955555557
            },
            {
              "lat": 43.76433110124864,
              "lng": -116.09937720537063,
              "alt": 1930.392585891948,
              "speed2d": 6.606960102267273,
              "speed3d": 6.593082234058545,
              "msFromStart": 134000
            },
            {
              "lat": 43.764300912955,
              "lng": -116.09940201680033,
              "alt": 1932.6866555704414,
              "speed2d": 7.56034012501209,
              "speed3d": 7.424386186672428,
              "msFromStart": 134500
            },
            {
              "lat": 43.764292369639875,
              "lng": -116.09940450102671,
              "alt": 1930.8559019065424,
              "speed2d": 6.250971047505571,
              "speed3d": 6.215597982310788,
              "msFromStart": 135000
            },
            {
              "lat": 43.76428382632474,
              "lng": -116.0994069852531,
              "alt": 1929.0251482426434,
              "speed2d": 4.941601969999052,
              "speed3d": 5.006809777949148,
              "msFromStart": 135500
            },
            {
              "lat": 43.76426825,
              "lng": -116.0994124,
              "alt": 1925.632,
              "speed2d": 2.556,
              "speed3d": 2.7750000000000004,
              "msFromStart": 136436.1857777778
            },
            {
              "lat": 43.76426708784202,
              "lng": -116.09941452848906,
              "alt": 1925.5652254355066,
              "speed2d": 2.6072901392973598,
              "speed3d": 2.722182928799957,
              "msFromStart": 136500
            },
            {
              "lat": 43.764270765803154,
              "lng": -116.09943471654934,
              "alt": 1923.9314142334774,
              "speed2d": 2.709702213974958,
              "speed3d": 2.8943309865558824,
              "msFromStart": 137000
            },
            {
              "lat": 43.76426879296067,
              "lng": -116.09945845937924,
              "alt": 1922.4974790673048,
              "speed2d": 3.2222737155589587,
              "speed3d": 3.364231894288751,
              "msFromStart": 137500
            },
            {
              "lat": 43.7642675769669,
              "lng": -116.09949173860552,
              "alt": 1920.7920333265506,
              "speed2d": 4.2682311448252035,
              "speed3d": 4.46102663217762,
              "msFromStart": 138000
            },
            {
              "lat": 43.7642721692008,
              "lng": -116.09951585394668,
              "alt": 1917.8561893073306,
              "speed2d": 4.228735901217293,
              "speed3d": 4.5416127044220325,
              "msFromStart": 138500
            },
            {
              "lat": 43.76430048,
              "lng": -116.09956344,
              "alt": 1911.5631999999998,
              "speed2d": 4.7592,
              "speed3d": 5.334,
              "msFromStart": 139379.208
            },
            {
              "lat": 43.76430583333333,
              "lng": -116.09957218888889,
              "alt": 1909.744222222222,
              "speed2d": 3.9433333333333334,
              "speed3d": 4.731111111111111,
              "msFromStart": 139764.208
            },
            {
              "lat": 43.76431383333333,
              "lng": -116.0995795,
              "alt": 1907.726888888889,
              "speed2d": 3.2398888888888893,
              "speed3d": 4.162222222222223,
              "msFromStart": 140259.12589473685
            },
            {
              "lat": 43.764324499999994,
              "lng": -116.09958505999998,
              "alt": 1906.5952000000002,
              "speed2d": 3.1054,
              "speed3d": 4.03,
              "msFromStart": 140654.80404210536
            },
            {
              "lat": 43.76434345,
              "lng": -116.0995978,
              "alt": 1904.0515,
              "speed2d": 3.4715,
              "speed3d": 4.335,
              "msFromStart": 141275.81389473693
            },
            {
              "lat": 43.76434966666667,
              "lng": -116.09961113333333,
              "alt": 1902.0546666666667,
              "speed2d": 3.6239999999999997,
              "speed3d": 4.583333333333333,
              "msFromStart": 141688.14399999997
            },
            {
              "lat": 43.76434079722784,
              "lng": -116.09961349036207,
              "alt": 1901.1590238847884,
              "speed2d": 2.70832736556369,
              "speed3d": 3.873914167528519,
              "msFromStart": 142000
            },
            {
              "lat": 43.76433732688258,
              "lng": -116.09964315355128,
              "alt": 1897.4333603694654,
              "speed2d": 3.5354263185107686,
              "speed3d": 4.601034126163313,
              "msFromStart": 142500
            },
            {
              "lat": 43.76433345,
              "lng": -116.09967520000001,
              "alt": 1893.4375,
              "speed2d": 4.427,
              "speed3d": 5.41,
              "msFromStart": 143035.65155555573
            },
            {
              "lat": 43.76434162870782,
              "lng": -116.09972438615537,
              "alt": 1890.08369315515,
              "speed2d": 6.292660716499867,
              "speed3d": 6.934899741070939,
              "msFromStart": 143500
            },
            {
              "lat": 43.764338985311284,
              "lng": -116.09973314240638,
              "alt": 1884.9555038817746,
              "speed2d": 4.9081817825151735,
              "speed3d": 6.282311222068609,
              "msFromStart": 144000
            },
            {
              "lat": 43.764333255555556,
              "lng": -116.09974665555555,
              "alt": 1877.449222222222,
              "speed2d": 3.025777777777778,
              "speed3d": 5.424444444444443,
              "msFromStart": 144766.6425263159
            },
            {
              "lat": 43.7643377,
              "lng": -116.09975443333333,
              "alt": 1874.6946666666668,
              "speed2d": 2.574,
              "speed3d": 5.266666666666667,
              "msFromStart": 145171.59691228083
            },
            {
              "lat": 43.76435495,
              "lng": -116.09978115,
              "alt": 1870.973,
              "speed2d": 3.8834999999999997,
              "speed3d": 5.99,
              "msFromStart": 145730.1951111111
            },
            {
              "lat": 43.764375425000004,
              "lng": -116.09981702500001,
              "alt": 1867.7615,
              "speed2d": 5.32825,
              "speed3d": 7.15,
              "msFromStart": 146224.23977777775
            },
            {
              "lat": 43.764372521219855,
              "lng": -116.09983264312443,
              "alt": 1865.9516111990465,
              "speed2d": 5.216281790211267,
              "speed3d": 7.204069591662509,
              "msFromStart": 146500
            },
            {
              "lat": 43.7643614543915,
              "lng": -116.09985965368855,
              "alt": 1862.5183934294296,
              "speed2d": 4.986817043999394,
              "speed3d": 7.0852731290460085,
              "msFromStart": 147000
            },
            {
              "lat": 43.76436525864657,
              "lng": -116.09989593656454,
              "alt": 1859.1490259051534,
              "speed2d": 4.299378485223495,
              "speed3d": 6.778235974737668,
              "msFromStart": 147500
            },
            {
              "lat": 43.7643285159014,
              "lng": -116.09990638242294,
              "alt": 1855.2501330287014,
              "speed2d": 4.631301271572956,
              "speed3d": 6.8697760586100625,
              "msFromStart": 148000
            },
            {
              "lat": 43.764328060708564,
              "lng": -116.09994219092636,
              "alt": 1853.2584114691508,
              "speed2d": 4.975730520443935,
              "speed3d": 7.067026289241019,
              "msFromStart": 148500
            },
            {
              "lat": 43.764327449999996,
              "lng": -116.0999985,
              "alt": 1850.499,
              "speed2d": 5.7815,
              "speed3d": 7.605,
              "msFromStart": 149225.85384795332
            },
            {
              "lat": 43.764322231963526,
              "lng": -116.10002735720914,
              "alt": 1849.5671166732857,
              "speed2d": 6.6031742012697965,
              "speed3d": 8.30120600372224,
              "msFromStart": 149500
            },
            {
              "lat": 43.764335066010055,
              "lng": -116.10006898267862,
              "alt": 1847.8106695093077,
              "speed2d": 6.2147455364909305,
              "speed3d": 8.057018392823245,
              "msFromStart": 150000
            },
            {
              "lat": 43.764355075,
              "lng": -116.10012642500001,
              "alt": 1845.4945,
              "speed2d": 5.73925,
              "speed3d": 7.765000000000001,
              "msFromStart": 150697.78088888884
            },
            {
              "lat": 43.76436905,
              "lng": -116.10015665,
              "alt": 1844.48,
              "speed2d": 5.8165,
              "speed3d": 7.76,
              "msFromStart": 151068.4968888888
            },
            {
              "lat": 43.764398475,
              "lng": -116.100230525,
              "alt": 1842.6952500000002,
              "speed2d": 6.165,
              "speed3d": 7.905,
              "msFromStart": 151891.75822222236
            },
            {
              "lat": 43.7644214,
              "lng": -116.1002858,
              "alt": 1842.431,
              "speed2d": 6.401,
              "speed3d": 7.92,
              "msFromStart": 152440.97866666666
            },
            {
              "lat": 43.764427,
              "lng": -116.1002999,
              "alt": 1842.4653333333335,
              "speed2d": 6.438333333333333,
              "speed3d": 7.963333333333334,
              "msFromStart": 152578.51200000002
            },
            {
              "lat": 43.764450057735665,
              "lng": -116.10034909614939,
              "alt": 1842.3439891136636,
              "speed2d": 6.954292088821958,
              "speed3d": 8.219320766807304,
              "msFromStart": 153000
            },
            {
              "lat": 43.76445387821159,
              "lng": -116.10038142528013,
              "alt": 1841.1626716098062,
              "speed2d": 6.67790041679199,
              "speed3d": 7.969013723500851,
              "msFromStart": 153500
            },
            {
              "lat": 43.76445769868751,
              "lng": -116.10041375441088,
              "alt": 1839.981354105949,
              "speed2d": 6.4015087447620225,
              "speed3d": 7.718706680194397,
              "msFromStart": 154000
            },
            {
              "lat": 43.76446151916343,
              "lng": -116.10044608354164,
              "alt": 1838.8000366020917,
              "speed2d": 6.1251170727320545,
              "speed3d": 7.468399636887943,
              "msFromStart": 154500
            },
            {
              "lat": 43.76446533963936,
              "lng": -116.10047841267239,
              "alt": 1837.6187190982344,
              "speed2d": 5.848725400702087,
              "speed3d": 7.21809259358149,
              "msFromStart": 155000
            },
            {
              "lat": 43.76446916011528,
              "lng": -116.10051074180313,
              "alt": 1836.437401594377,
              "speed2d": 5.5723337286721195,
              "speed3d": 6.967785550275036,
              "msFromStart": 155500
            },
            {
              "lat": 43.76447298059121,
              "lng": -116.1005430709339,
              "alt": 1835.2560840905196,
              "speed2d": 5.295942056642152,
              "speed3d": 6.7174785069685825,
              "msFromStart": 156000
            },
            {
              "lat": 43.76448591512043,
              "lng": -116.10057212564102,
              "alt": 1834.7641302775507,
              "speed2d": 4.598826417953815,
              "speed3d": 6.143312613263651,
              "msFromStart": 156500
            },
            {
              "lat": 43.76446925796327,
              "lng": -116.10060089618456,
              "alt": 1832.2148550527907,
              "speed2d": 4.964975258564926,
              "speed3d": 6.2728792824821875,
              "msFromStart": 157000
            }
          ]
        }
      ]
    },
    {
      "id": "trail-lazy-mary",
      "pathType": "trail",
      "name": "Lazy Mary",
      "difficulty": "blue",
      "videos": [
        {
          "videoId": "GH010043",
          "pathId": "trail-lazy-mary",
          "startSec": 84,
          "endSec": 162,
          "id": "video-segment-1",
          "gps": [
            {
              "lat": 43.772415622222226,
              "lng": -116.10484268888888,
              "alt": 2030.6089999999997,
              "speed2d": 4.561888888888888,
              "speed3d": 4.678888888888889,
              "msFromStart": 84268.50643274853
            },
            {
              "lat": 43.77244157777778,
              "lng": -116.10483903333332,
              "alt": 2030.1758888888887,
              "speed2d": 5.219111111111111,
              "speed3d": 5.260000000000001,
              "msFromStart": 84763.83242105262
            },
            {
              "lat": 43.77245984444445,
              "lng": -116.1048264,
              "alt": 2029.7342222222221,
              "speed2d": 4.955555555555555,
              "speed3d": 5.155555555555555,
              "msFromStart": 85258.98499805068
            },
            {
              "lat": 43.77247476666667,
              "lng": -116.10480877777776,
              "alt": 2029.0031111111111,
              "speed2d": 4.73511111111111,
              "speed3d": 4.912222222222223,
              "msFromStart": 85753.48977777785
            },
            {
              "lat": 43.77249107777777,
              "lng": -116.10479677777779,
              "alt": 2028.187888888889,
              "speed2d": 4.322888888888889,
              "speed3d": 4.528888888888888,
              "msFromStart": 86248.02459259261
            },
            {
              "lat": 43.77251786666666,
              "lng": -116.10479462222223,
              "alt": 2027.4445555555556,
              "speed2d": 4.608444444444444,
              "speed3d": 4.7411111111111115,
              "msFromStart": 86742.93866666665
            },
            {
              "lat": 43.77255032222222,
              "lng": -116.1047969777778,
              "alt": 2027.2574444444447,
              "speed2d": 5.245666666666667,
              "speed3d": 5.316666666666666,
              "msFromStart": 87237.87288888889
            },
            {
              "lat": 43.77258412222223,
              "lng": -116.10479894444445,
              "alt": 2026.602222222222,
              "speed2d": 6.133,
              "speed3d": 6.1722222222222225,
              "msFromStart": 87732.73599999996
            },
            {
              "lat": 43.772619311111114,
              "lng": -116.10478906666668,
              "alt": 2025.7142222222224,
              "speed2d": 7.229222222222222,
              "speed3d": 7.250000000000001,
              "msFromStart": 88227.47866666663
            },
            {
              "lat": 43.77265838999999,
              "lng": -116.10477898,
              "alt": 2024.5445,
              "speed2d": 7.583499999999999,
              "speed3d": 7.694000000000001,
              "msFromStart": 88749.22599999997
            },
            {
              "lat": 43.772700433333334,
              "lng": -116.10477656666667,
              "alt": 2023.3536666666664,
              "speed2d": 7.775333333333334,
              "speed3d": 7.942222222222222,
              "msFromStart": 89271.74196491226
            },
            {
              "lat": 43.77273486666666,
              "lng": -116.10478486666666,
              "alt": 2021.7747777777781,
              "speed2d": 7.754111111111111,
              "speed3d": 7.8999999999999995,
              "msFromStart": 89769.00926315786
            },
            {
              "lat": 43.77277754444444,
              "lng": -116.10479965555555,
              "alt": 2020.3391111111114,
              "speed2d": 8.496555555555556,
              "speed3d": 8.587777777777777,
              "msFromStart": 90265.72995711499
            },
            {
              "lat": 43.7728284,
              "lng": -116.1048107111111,
              "alt": 2019.3893333333335,
              "speed2d": 9.555,
              "speed3d": 9.564444444444444,
              "msFromStart": 90759.79911111118
            },
            {
              "lat": 43.77289085555556,
              "lng": -116.1048139,
              "alt": 2018.8075555555554,
              "speed2d": 11.068888888888889,
              "speed3d": 11.093333333333334,
              "msFromStart": 91253.78903703706
            },
            {
              "lat": 43.77294643333333,
              "lng": -116.10481002222222,
              "alt": 2017.9218888888886,
              "speed2d": 11.544444444444444,
              "speed3d": 11.693333333333332,
              "msFromStart": 91748.52266666663
            },
            {
              "lat": 43.77299405555556,
              "lng": -116.1048076888889,
              "alt": 2015.9973333333335,
              "speed2d": 11.225888888888889,
              "speed3d": 11.408888888888889,
              "msFromStart": 92243.65955555554
            },
            {
              "lat": 43.773047866666666,
              "lng": -116.10481604444445,
              "alt": 2013.5662222222222,
              "speed2d": 11.498222222222221,
              "speed3d": 11.668888888888887,
              "msFromStart": 92740.11200000005
            },
            {
              "lat": 43.77310406666666,
              "lng": -116.10483325555556,
              "alt": 2012.1642222222224,
              "speed2d": 11.980888888888888,
              "speed3d": 12.153333333333332,
              "msFromStart": 93236.46192592596
            },
            {
              "lat": 43.77316080000001,
              "lng": -116.10484817777777,
              "alt": 2011.2755555555557,
              "speed2d": 12.277777777777779,
              "speed3d": 12.446666666666667,
              "msFromStart": 93731.87555555558
            },
            {
              "lat": 43.77321828888889,
              "lng": -116.10485543333333,
              "alt": 2010.7821111111111,
              "speed2d": 12.417333333333334,
              "speed3d": 12.617777777777778,
              "msFromStart": 94226.73769980507
            },
            {
              "lat": 43.773274990000004,
              "lng": -116.10485648999999,
              "alt": 2010.6647,
              "speed2d": 11.9851,
              "speed3d": 12.226,
              "msFromStart": 94747.1488421052
            },
            {
              "lat": 43.77333321111111,
              "lng": -116.10485982222221,
              "alt": 2010.684222222222,
              "speed2d": 11.463222222222223,
              "speed3d": 11.666666666666668,
              "msFromStart": 95267.93469785573
            },
            {
              "lat": 43.773380544444436,
              "lng": -116.10487101111113,
              "alt": 2009.536,
              "speed2d": 10.796444444444445,
              "speed3d": 11.040000000000001,
              "msFromStart": 95763.57155555557
            },
            {
              "lat": 43.77342720000001,
              "lng": -116.10489081111113,
              "alt": 2008.8444444444447,
              "speed2d": 10.507777777777777,
              "speed3d": 10.62888888888889,
              "msFromStart": 96259.47970370372
            },
            {
              "lat": 43.77347962222222,
              "lng": -116.10490955555555,
              "alt": 2008.410888888889,
              "speed2d": 10.777777777777777,
              "speed3d": 10.977777777777778,
              "msFromStart": 96755.58044444447
            },
            {
              "lat": 43.77352556666666,
              "lng": -116.1049160888889,
              "alt": 2007.999777777778,
              "speed2d": 10.34088888888889,
              "speed3d": 10.533333333333333,
              "msFromStart": 97251.16118518518
            },
            {
              "lat": 43.77357261111111,
              "lng": -116.10491507777778,
              "alt": 2007.9411111111108,
              "speed2d": 10.155999999999999,
              "speed3d": 10.307777777777778,
              "msFromStart": 97744.58488888887
            },
            {
              "lat": 43.77361606666667,
              "lng": -116.10490884444444,
              "alt": 2007.0376666666666,
              "speed2d": 9.990222222222222,
              "speed3d": 10.175555555555556,
              "msFromStart": 98238.37585185184
            },
            {
              "lat": 43.77365254444444,
              "lng": -116.1048997111111,
              "alt": 2006.3606666666665,
              "speed2d": 9.073333333333334,
              "speed3d": 9.37111111111111,
              "msFromStart": 98734.49866666668
            },
            {
              "lat": 43.773685388888886,
              "lng": -116.10489357777779,
              "alt": 2005.8282222222222,
              "speed2d": 8.048777777777778,
              "speed3d": 8.266666666666666,
              "msFromStart": 99230.66250292398
            },
            {
              "lat": 43.773721699999996,
              "lng": -116.10489476666666,
              "alt": 2005.4497777777776,
              "speed2d": 7.6835555555555555,
              "speed3d": 7.886666666666666,
              "msFromStart": 99726.05768421057
            },
            {
              "lat": 43.773763089999996,
              "lng": -116.10489698999999,
              "alt": 2005.8681000000004,
              "speed2d": 7.7349,
              "speed3d": 7.834,
              "msFromStart": 100248.73729824566
            },
            {
              "lat": 43.773805844444446,
              "lng": -116.10489344444444,
              "alt": 2006.227888888889,
              "speed2d": 8.011777777777779,
              "speed3d": 8.077777777777778,
              "msFromStart": 100770.68711111115
            },
            {
              "lat": 43.77384485555555,
              "lng": -116.10488472222221,
              "alt": 2006.740888888889,
              "speed2d": 8.012777777777776,
              "speed3d": 8.12888888888889,
              "msFromStart": 101265.21822222223
            },
            {
              "lat": 43.773878744444445,
              "lng": -116.10487327777778,
              "alt": 2006.822222222222,
              "speed2d": 7.587666666666667,
              "speed3d": 7.713333333333334,
              "msFromStart": 101760.35377777772
            },
            {
              "lat": 43.77390973333334,
              "lng": -116.10486462222222,
              "alt": 2006.433,
              "speed2d": 7.131444444444443,
              "speed3d": 7.25,
              "msFromStart": 102255.46459259256
            },
            {
              "lat": 43.77393795555555,
              "lng": -116.1048609,
              "alt": 2005.5737777777779,
              "speed2d": 6.6356666666666655,
              "speed3d": 6.763333333333334,
              "msFromStart": 102750.23466666663
            },
            {
              "lat": 43.773967355555556,
              "lng": -116.1048632,
              "alt": 2005.2177777777777,
              "speed2d": 6.24011111111111,
              "speed3d": 6.3855555555555545,
              "msFromStart": 103245.09807407403
            },
            {
              "lat": 43.77399637777777,
              "lng": -116.10486905555555,
              "alt": 2005.394888888889,
              "speed2d": 5.8452222222222225,
              "speed3d": 5.934444444444445,
              "msFromStart": 103740.47111111112
            },
            {
              "lat": 43.774028155555555,
              "lng": -116.10487655555556,
              "alt": 2005.5383333333332,
              "speed2d": 6.063777777777778,
              "speed3d": 6.098888888888888,
              "msFromStart": 104235.78361013648
            },
            {
              "lat": 43.77405954444444,
              "lng": -116.1048810888889,
              "alt": 2005.3563333333332,
              "speed2d": 6.334333333333334,
              "speed3d": 6.366666666666667,
              "msFromStart": 104730.65010526322
            },
            {
              "lat": 43.77409322222222,
              "lng": -116.10488128888889,
              "alt": 2005.2376666666667,
              "speed2d": 6.790666666666667,
              "speed3d": 6.82,
              "msFromStart": 105225.55769200785
            },
            {
              "lat": 43.77412617,
              "lng": -116.10487647000002,
              "alt": 2004.867,
              "speed2d": 6.909800000000001,
              "speed3d": 6.951000000000001,
              "msFromStart": 105748.51666666662
            },
            {
              "lat": 43.77415786666667,
              "lng": -116.10486991111111,
              "alt": 2004.3357777777778,
              "speed2d": 6.7747777777777785,
              "speed3d": 6.8933333333333335,
              "msFromStart": 106271.3028148148
            },
            {
              "lat": 43.77418424444444,
              "lng": -116.10486555555553,
              "alt": 2003.311111111111,
              "speed2d": 6.360222222222221,
              "speed3d": 6.484444444444446,
              "msFromStart": 106765.52622222227
            },
            {
              "lat": 43.77421311111111,
              "lng": -116.10486683333333,
              "alt": 2002.483,
              "speed2d": 6.316,
              "speed3d": 6.402222222222222,
              "msFromStart": 107259.93140740742
            },
            {
              "lat": 43.77424628888889,
              "lng": -116.10487440000001,
              "alt": 2001.9813333333334,
              "speed2d": 6.700333333333333,
              "speed3d": 6.745555555555556,
              "msFromStart": 107755.47733333334
            },
            {
              "lat": 43.77428055555556,
              "lng": -116.10488472222222,
              "alt": 2001.3784444444445,
              "speed2d": 7.120222222222221,
              "speed3d": 7.178888888888888,
              "msFromStart": 108250.97881481482
            },
            {
              "lat": 43.77431712222223,
              "lng": -116.1048896888889,
              "alt": 2000.723111111111,
              "speed2d": 7.665000000000001,
              "speed3d": 7.71,
              "msFromStart": 108745.84622222226
            },
            {
              "lat": 43.774356788888895,
              "lng": -116.1048896111111,
              "alt": 1999.815,
              "speed2d": 8.463777777777779,
              "speed3d": 8.461111111111112,
              "msFromStart": 109240.7440623782
            },
            {
              "lat": 43.774394666666666,
              "lng": -116.10488442222223,
              "alt": 1998.6706666666664,
              "speed2d": 8.673777777777778,
              "speed3d": 8.81888888888889,
              "msFromStart": 109736.01726315795
            },
            {
              "lat": 43.774433144444444,
              "lng": -116.1048803555556,
              "alt": 1997.5214444444446,
              "speed2d": 8.694111111111111,
              "speed3d": 8.822222222222223,
              "msFromStart": 110231.52074853805
            },
            {
              "lat": 43.774471077777775,
              "lng": -116.10488122222223,
              "alt": 1996.210222222222,
              "speed2d": 8.529111111111112,
              "speed3d": 8.724444444444444,
              "msFromStart": 110728.19999999998
            },
            {
              "lat": 43.774507522222216,
              "lng": -116.10488598888888,
              "alt": 1995.1446666666668,
              "speed2d": 8.378666666666668,
              "speed3d": 8.568888888888889,
              "msFromStart": 111224.90409876544
            },
            {
              "lat": 43.77454851,
              "lng": -116.10488912000001,
              "alt": 1994.2495999999999,
              "speed2d": 8.618,
              "speed3d": 8.748000000000001,
              "msFromStart": 111748.11244444452
            },
            {
              "lat": 43.7745919,
              "lng": -116.1048878888889,
              "alt": 1993.4221111111112,
              "speed2d": 9.071111111111112,
              "speed3d": 9.245555555555555,
              "msFromStart": 112270.99140740746
            },
            {
              "lat": 43.77462975555555,
              "lng": -116.10488514444445,
              "alt": 1992.5069999999996,
              "speed2d": 8.709444444444445,
              "speed3d": 8.931111111111111,
              "msFromStart": 112765.69600000008
            },
            {
              "lat": 43.7746696,
              "lng": -116.10489027777777,
              "alt": 1991.4536666666668,
              "speed2d": 8.596666666666668,
              "speed3d": 8.80888888888889,
              "msFromStart": 113260.41081481485
            },
            {
              "lat": 43.7747089,
              "lng": -116.10490080000001,
              "alt": 1990.8775555555558,
              "speed2d": 8.424666666666667,
              "speed3d": 8.655555555555557,
              "msFromStart": 113755.42488888885
            },
            {
              "lat": 43.77474832222222,
              "lng": -116.10491046666668,
              "alt": 1990.353777777778,
              "speed2d": 8.428111111111109,
              "speed3d": 8.594444444444445,
              "msFromStart": 114250.08038986353
            },
            {
              "lat": 43.774786777777784,
              "lng": -116.10491656666667,
              "alt": 1989.9362222222223,
              "speed2d": 8.283444444444445,
              "speed3d": 8.488888888888889,
              "msFromStart": 114743.18189473686
            },
            {
              "lat": 43.77482634444445,
              "lng": -116.1049177111111,
              "alt": 1989.7476666666666,
              "speed2d": 8.371111111111112,
              "speed3d": 8.546666666666667,
              "msFromStart": 115236.34897465887
            },
            {
              "lat": 43.774860399999994,
              "lng": -116.10492716666666,
              "alt": 1988.7294444444442,
              "speed2d": 8.034777777777776,
              "speed3d": 8.204444444444444,
              "msFromStart": 115730.86399999994
            },
            {
              "lat": 43.774894399999994,
              "lng": -116.10494955555555,
              "alt": 1987.0313333333334,
              "speed2d": 8.507888888888889,
              "speed3d": 8.7,
              "msFromStart": 116225.78172839503
            },
            {
              "lat": 43.7749287,
              "lng": -116.10497393,
              "alt": 1985.5938,
              "speed2d": 8.3834,
              "speed3d": 8.623000000000001,
              "msFromStart": 116749.37511111106
            },
            {
              "lat": 43.77496825555556,
              "lng": -116.10499291111111,
              "alt": 1984.009111111111,
              "speed2d": 8.662666666666667,
              "speed3d": 8.908888888888889,
              "msFromStart": 117272.8502222222
            },
            {
              "lat": 43.77500851111111,
              "lng": -116.10500643333333,
              "alt": 1982.3055555555557,
              "speed2d": 9.110444444444445,
              "speed3d": 9.376666666666667,
              "msFromStart": 117767.55822222224
            },
            {
              "lat": 43.775040733333334,
              "lng": -116.10502082222222,
              "alt": 1979.7605555555556,
              "speed2d": 8.605777777777778,
              "speed3d": 9.038888888888888,
              "msFromStart": 118262.26548148147
            },
            {
              "lat": 43.775071600000004,
              "lng": -116.10504731111111,
              "alt": 1977.1734444444448,
              "speed2d": 8.776222222222222,
              "speed3d": 9.165555555555555,
              "msFromStart": 118757.44977777771
            },
            {
              "lat": 43.77510734444445,
              "lng": -116.10508671111111,
              "alt": 1974.821222222222,
              "speed2d": 9.829222222222223,
              "speed3d": 10.188888888888888,
              "msFromStart": 119252.66474074071
            },
            {
              "lat": 43.7751497,
              "lng": -116.10513213333334,
              "alt": 1972.9723333333332,
              "speed2d": 10.932888888888888,
              "speed3d": 11.281111111111109,
              "msFromStart": 119747.81155555554
            },
            {
              "lat": 43.77519765555555,
              "lng": -116.10517478888889,
              "alt": 1971.351222222222,
              "speed2d": 11.583111111111112,
              "speed3d": 12.003333333333334,
              "msFromStart": 120243.08913840154
            },
            {
              "lat": 43.7752459,
              "lng": -116.10520396666666,
              "alt": 1969.5725555555555,
              "speed2d": 11.425333333333334,
              "speed3d": 11.912222222222223,
              "msFromStart": 120738.91705263154
            },
            {
              "lat": 43.77529277777778,
              "lng": -116.1052219,
              "alt": 1967.8344444444447,
              "speed2d": 10.963222222222221,
              "speed3d": 11.499999999999998,
              "msFromStart": 121234.57148797916
            },
            {
              "lat": 43.775341277777784,
              "lng": -116.1052381111111,
              "alt": 1965.913,
              "speed2d": 10.936777777777777,
              "speed3d": 11.415555555555557,
              "msFromStart": 121728.83199999998
            },
            {
              "lat": 43.775400069999996,
              "lng": -116.1052634,
              "alt": 1963.9284999999998,
              "speed2d": 11.765699999999999,
              "speed3d": 12.159999999999997,
              "msFromStart": 122250.27022222221
            },
            {
              "lat": 43.77545773333333,
              "lng": -116.10529987777777,
              "alt": 1962.4119999999998,
              "speed2d": 12.38922222222222,
              "speed3d": 12.834444444444445,
              "msFromStart": 122771.52177777785
            },
            {
              "lat": 43.775505266666656,
              "lng": -116.1053344111111,
              "alt": 1960.806888888889,
              "speed2d": 12.193444444444445,
              "speed3d": 12.692222222222222,
              "msFromStart": 123265.68562962966
            },
            {
              "lat": 43.77555787777777,
              "lng": -116.10536065555556,
              "alt": 1959.145,
              "speed2d": 12.173444444444446,
              "speed3d": 12.634444444444444,
              "msFromStart": 123761.33688888888
            },
            {
              "lat": 43.775609366666664,
              "lng": -116.10539084444443,
              "alt": 1957.6807777777776,
              "speed2d": 12.179555555555554,
              "speed3d": 12.639999999999997,
              "msFromStart": 124256.83435477583
            },
            {
              "lat": 43.77565548888889,
              "lng": -116.10542860000001,
              "alt": 1956.4118888888888,
              "speed2d": 12.04011111111111,
              "speed3d": 12.54,
              "msFromStart": 124751.1216842106
            },
            {
              "lat": 43.77569695555556,
              "lng": -116.10546604444443,
              "alt": 1955.0179999999998,
              "speed2d": 11.560333333333334,
              "speed3d": 12.052222222222223,
              "msFromStart": 125245.49462768037
            },
            {
              "lat": 43.775732922222225,
              "lng": -116.10549234444443,
              "alt": 1953.0742222222223,
              "speed2d": 10.487666666666666,
              "speed3d": 11.14888888888889,
              "msFromStart": 125741.12800000004
            },
            {
              "lat": 43.77576072222223,
              "lng": -116.10550547777777,
              "alt": 1950.7322222222224,
              "speed2d": 9.060555555555554,
              "speed3d": 9.804444444444444,
              "msFromStart": 126236.86281481484
            },
            {
              "lat": 43.77579174444445,
              "lng": -116.10551334444443,
              "alt": 1948.6114444444445,
              "speed2d": 8.371777777777778,
              "speed3d": 9.08222222222222,
              "msFromStart": 126731.956
            },
            {
              "lat": 43.77583010000001,
              "lng": -116.10551663333332,
              "alt": 1946.9454444444445,
              "speed2d": 8.471777777777778,
              "speed3d": 9.133333333333333,
              "msFromStart": 127226.85165432097
            },
            {
              "lat": 43.775857290000005,
              "lng": -116.10552245000001,
              "alt": 1945.4464999999998,
              "speed2d": 7.3901,
              "speed3d": 8.278,
              "msFromStart": 127748.64177777772
            },
            {
              "lat": 43.775877477777776,
              "lng": -116.10552464444444,
              "alt": 1942.036888888889,
              "speed2d": 6.531111111111111,
              "speed3d": 7.461111111111111,
              "msFromStart": 128270.38474074073
            },
            {
              "lat": 43.77590295555555,
              "lng": -116.1055271888889,
              "alt": 1938.3124444444445,
              "speed2d": 6.57811111111111,
              "speed3d": 7.626666666666666,
              "msFromStart": 128764.8506666667
            },
            {
              "lat": 43.775939055555554,
              "lng": -116.10552937777777,
              "alt": 1936.7602222222224,
              "speed2d": 7.003,
              "speed3d": 7.9511111111111115,
              "msFromStart": 129259.43723976609
            },
            {
              "lat": 43.77596928888889,
              "lng": -116.10552606666667,
              "alt": 1934.996222222222,
              "speed2d": 6.897111111111111,
              "speed3d": 7.9622222222222225,
              "msFromStart": 129754.43410526315
            },
            {
              "lat": 43.775980177777775,
              "lng": -116.10551186666666,
              "alt": 1931.9804444444444,
              "speed2d": 5.462555555555555,
              "speed3d": 6.8755555555555565,
              "msFromStart": 130249.59423521766
            },
            {
              "lat": 43.775995933333334,
              "lng": -116.10549608888887,
              "alt": 1929.5837777777776,
              "speed2d": 4.593666666666666,
              "speed3d": 6.1899999999999995,
              "msFromStart": 130745.50000000004
            },
            {
              "lat": 43.776009280000004,
              "lng": -116.10548318,
              "alt": 1928.2090000000003,
              "speed2d": 4.2096,
              "speed3d": 5.880000000000001,
              "msFromStart": 131131.29955555557
            },
            {
              "lat": 43.776024778819945,
              "lng": -116.10546099288898,
              "alt": 1927.0502738616751,
              "speed2d": 4.451473429842861,
              "speed3d": 6.021865681470031,
              "msFromStart": 131500
            },
            {
              "lat": 43.77604872497262,
              "lng": -116.1054257722248,
              "alt": 1925.6560684190645,
              "speed2d": 5.197150775634373,
              "speed3d": 6.489741663143136,
              "msFromStart": 132000
            },
            {
              "lat": 43.77606584116136,
              "lng": -116.10537990624564,
              "alt": 1924.4376905539202,
              "speed2d": 5.749142146106443,
              "speed3d": 6.595864062081823,
              "msFromStart": 132500
            },
            {
              "lat": 43.77610167178113,
              "lng": -116.10536221103104,
              "alt": 1922.7652827977076,
              "speed2d": 6.921270584297986,
              "speed3d": 7.793036892621683,
              "msFromStart": 133000
            },
            {
              "lat": 43.77614050267956,
              "lng": -116.10535935460034,
              "alt": 1920.733077249739,
              "speed2d": 7.267768046846305,
              "speed3d": 8.13249677229865,
              "msFromStart": 133500
            },
            {
              "lat": 43.7762039,
              "lng": -116.10535515000001,
              "alt": 1917.443,
              "speed2d": 7.845000000000001,
              "speed3d": 8.695,
              "msFromStart": 134317.48210526313
            },
            {
              "lat": 43.776231233333334,
              "lng": -116.10535616666667,
              "alt": 1916.4450000000002,
              "speed2d": 8.395999999999999,
              "speed3d": 9.116666666666667,
              "msFromStart": 134648.90442105252
            },
            {
              "lat": 43.77626083953132,
              "lng": -116.10535176066908,
              "alt": 1915.2180562132323,
              "speed2d": 8.743391305233894,
              "speed3d": 9.465879598389572,
              "msFromStart": 135000
            },
            {
              "lat": 43.77631486666668,
              "lng": -116.1053269888889,
              "alt": 1912.1041111111113,
              "speed2d": 8.37288888888889,
              "speed3d": 9.288888888888888,
              "msFromStart": 135751.56
            },
            {
              "lat": 43.77633865,
              "lng": -116.10531571666665,
              "alt": 1910.2209999999998,
              "speed2d": 7.594666666666668,
              "speed3d": 8.631666666666666,
              "msFromStart": 136173.29185185186
            },
            {
              "lat": 43.77635931623495,
              "lng": -116.1053033148307,
              "alt": 1909.007311219778,
              "speed2d": 7.41431857542227,
              "speed3d": 8.320058421961509,
              "msFromStart": 136500
            },
            {
              "lat": 43.776422249999996,
              "lng": -116.105243,
              "alt": 1907.9165,
              "speed2d": 8.942,
              "speed3d": 9.585,
              "msFromStart": 137182.9004444444
            },
            {
              "lat": 43.7764541835137,
              "lng": -116.1052147443242,
              "alt": 1907.4599503102916,
              "speed2d": 9.666331295929188,
              "speed3d": 10.211616357487125,
              "msFromStart": 137500
            },
            {
              "lat": 43.77649949518099,
              "lng": -116.10516479995808,
              "alt": 1906.5658483050984,
              "speed2d": 10.192064271399037,
              "speed3d": 10.707039442930933,
              "msFromStart": 138000
            },
            {
              "lat": 43.776619749999995,
              "lng": -116.10512370000001,
              "alt": 1903.0165000000002,
              "speed2d": 12.67175,
              "speed3d": 13.102500000000001,
              "msFromStart": 138862.92844444435
            },
            {
              "lat": 43.776656,
              "lng": -116.10511546666666,
              "alt": 1901.8850000000002,
              "speed2d": 13.007666666666665,
              "speed3d": 13.513333333333332,
              "msFromStart": 139129.4663703703
            },
            {
              "lat": 43.776736033333336,
              "lng": -116.10510156666668,
              "alt": 1899.3847777777778,
              "speed2d": 13.175888888888888,
              "speed3d": 13.747777777777777,
              "msFromStart": 139768.54044444434
            },
            {
              "lat": 43.776777800000005,
              "lng": -116.10510136666667,
              "alt": 1898.2313333333332,
              "speed2d": 12.975000000000001,
              "speed3d": 13.57,
              "msFromStart": 140133.31674074064
            },
            {
              "lat": 43.77681146432117,
              "lng": -116.10512248539061,
              "alt": 1897.6035208922438,
              "speed2d": 12.894339197077633,
              "speed3d": 13.431387941601228,
              "msFromStart": 140500
            },
            {
              "lat": 43.7768541301909,
              "lng": -116.10515701630118,
              "alt": 1896.8434995327611,
              "speed2d": 12.787674522749057,
              "speed3d": 13.246502506098363,
              "msFromStart": 141000
            },
            {
              "lat": 43.77690010214375,
              "lng": -116.10516451046658,
              "alt": 1895.1388280790247,
              "speed2d": 11.301726775956372,
              "speed3d": 11.567519966372519,
              "msFromStart": 141500
            },
            {
              "lat": 43.77696305,
              "lng": -116.10526415000001,
              "alt": 1895.4395,
              "speed2d": 13.217500000000001,
              "speed3d": 13.559999999999999,
              "msFromStart": 142294.16888888885
            },
            {
              "lat": 43.776997200000004,
              "lng": -116.10531639999999,
              "alt": 1895.1934999999999,
              "speed2d": 13.6735,
              "speed3d": 13.985,
              "msFromStart": 142720.82622222227
            },
            {
              "lat": 43.7770153700065,
              "lng": -116.1053522259207,
              "alt": 1894.717789039315,
              "speed2d": 13.763841751222417,
              "speed3d": 14.061785986829099,
              "msFromStart": 143000
            },
            {
              "lat": 43.7770611,
              "lng": -116.105458925,
              "alt": 1893.25375,
              "speed2d": 13.53625,
              "speed3d": 13.9,
              "msFromStart": 143821.5915555557
            },
            {
              "lat": 43.77708178571429,
              "lng": -116.10550567142857,
              "alt": 1893.7355714285713,
              "speed2d": 13.040285714285714,
              "speed3d": 13.399999999999999,
              "msFromStart": 144204.64907602355
            },
            {
              "lat": 43.777103325,
              "lng": -116.105574375,
              "alt": 1894.80825,
              "speed2d": 12.338000000000001,
              "speed3d": 12.629999999999999,
              "msFromStart": 144756.06126315802
            },
            {
              "lat": 43.77713250000001,
              "lng": -116.10564344285716,
              "alt": 1895.835857142857,
              "speed2d": 11.910285714285713,
              "speed3d": 12.127142857142857,
              "msFromStart": 145319.32354887223
            },
            {
              "lat": 43.777153087500004,
              "lng": -116.105693175,
              "alt": 1895.8491250000002,
              "speed2d": 11.578625,
              "speed3d": 11.785,
              "msFromStart": 145732.30399999992
            },
            {
              "lat": 43.77716481093818,
              "lng": -116.10572513650425,
              "alt": 1895.505736872727,
              "speed2d": 11.3662424727272,
              "speed3d": 11.600953212121128,
              "msFromStart": 146000
            },
            {
              "lat": 43.7771974,
              "lng": -116.10580515000001,
              "alt": 1895.215,
              "speed2d": 10.9905,
              "speed3d": 11.175,
              "msFromStart": 146667.44800000012
            },
            {
              "lat": 43.77721370834414,
              "lng": -116.10584685057627,
              "alt": 1895.3388397728704,
              "speed2d": 11.01383363102199,
              "speed3d": 11.167156385330065,
              "msFromStart": 147000
            },
            {
              "lat": 43.7772483,
              "lng": -116.1059457,
              "alt": 1894.3600000000001,
              "speed2d": 10.693000000000001,
              "speed3d": 10.892,
              "msFromStart": 147826.9187555556
            },
            {
              "lat": 43.77725836666667,
              "lng": -116.10598586666666,
              "alt": 1893.7939999999999,
              "speed2d": 10.317,
              "speed3d": 10.47,
              "msFromStart": 148178.7727407408
            },
            {
              "lat": 43.77727140599617,
              "lng": -116.10602397625551,
              "alt": 1893.6797547680671,
              "speed2d": 10.299662218708276,
              "speed3d": 10.36688734905161,
              "msFromStart": 148500
            },
            {
              "lat": 43.77730475,
              "lng": -116.106112075,
              "alt": 1893.398,
              "speed2d": 10.221875,
              "speed3d": 10.334999999999999,
              "msFromStart": 149251.38294736843
            },
            {
              "lat": 43.77732796666666,
              "lng": -116.1061771,
              "alt": 1892.7386666666666,
              "speed2d": 10.080666666666666,
              "speed3d": 10.216666666666667,
              "msFromStart": 149829.73473684216
            },
            {
              "lat": 43.7773445,
              "lng": -116.10622215000001,
              "alt": 1892.559,
              "speed2d": 9.879999999999999,
              "speed3d": 9.98,
              "msFromStart": 150242.89368421055
            },
            {
              "lat": 43.77735392808901,
              "lng": -116.10625007073563,
              "alt": 1892.1825924866555,
              "speed2d": 9.8424008114447,
              "speed3d": 9.907719109875055,
              "msFromStart": 150500
            },
            {
              "lat": 43.77737755,
              "lng": -116.10632225,
              "alt": 1891.656,
              "speed2d": 9.674499999999998,
              "speed3d": 9.735,
              "msFromStart": 151151.88266666658
            },
            {
              "lat": 43.77738157660295,
              "lng": -116.10635734272114,
              "alt": 1891.486785536974,
              "speed2d": 9.510804081971257,
              "speed3d": 9.568492036008134,
              "msFromStart": 151500
            },
            {
              "lat": 43.77737984631191,
              "lng": -116.10640282285264,
              "alt": 1890.8496090209649,
              "speed2d": 9.11953097065403,
              "speed3d": 9.18707021671245,
              "msFromStart": 152000
            },
            {
              "lat": 43.77737811602088,
              "lng": -116.10644830298413,
              "alt": 1890.212432504956,
              "speed2d": 8.7282578593368,
              "speed3d": 8.805648397416764,
              "msFromStart": 152500
            },
            {
              "lat": 43.77737638572984,
              "lng": -116.10649378311564,
              "alt": 1889.5752559889472,
              "speed2d": 8.336984748019571,
              "speed3d": 8.42422657812108,
              "msFromStart": 153000
            },
            {
              "lat": 43.777374655438805,
              "lng": -116.10653926324713,
              "alt": 1888.9380794729384,
              "speed2d": 7.945711636702343,
              "speed3d": 8.042804758825396,
              "msFromStart": 153500
            },
            {
              "lat": 43.77737292514777,
              "lng": -116.10658474337863,
              "alt": 1888.3009029569296,
              "speed2d": 7.554438525385114,
              "speed3d": 7.661382939529711,
              "msFromStart": 154000
            },
            {
              "lat": 43.777371194856734,
              "lng": -116.10663022351014,
              "alt": 1887.6637264409208,
              "speed2d": 7.163165414067885,
              "speed3d": 7.279961120234027,
              "msFromStart": 154500
            },
            {
              "lat": 43.77736436,
              "lng": -116.10670204,
              "alt": 1886.5030000000002,
              "speed2d": 6.5207999999999995,
              "speed3d": 6.715999999999999,
              "msFromStart": 155330.84631578941
            },
            {
              "lat": 43.77735832064054,
              "lng": -116.10671293597166,
              "alt": 1886.377571306534,
              "speed2d": 6.390159742735754,
              "speed3d": 6.540920128632123,
              "msFromStart": 155500
            },
            {
              "lat": 43.777341318263645,
              "lng": -116.10674321293159,
              "alt": 1885.7197520720067,
              "speed2d": 6.408344103054408,
              "speed3d": 6.531827948472796,
              "msFromStart": 156000
            },
            {
              "lat": 43.77731287142858,
              "lng": -116.10679118571429,
              "alt": 1884.7474285714288,
              "speed2d": 6.346285714285713,
              "speed3d": 6.541428571428571,
              "msFromStart": 156815.7368888889
            },
            {
              "lat": 43.77729898,
              "lng": -116.10680909999999,
              "alt": 1884.5272,
              "speed2d": 6.142200000000001,
              "speed3d": 6.332000000000001,
              "msFromStart": 157189.4336
            },
            {
              "lat": 43.7772704,
              "lng": -116.1068445,
              "alt": 1884.9495000000002,
              "speed2d": 6.2524999999999995,
              "speed3d": 6.385,
              "msFromStart": 157822.4013333332
            },
            {
              "lat": 43.777256,
              "lng": -116.1068621,
              "alt": 1885.2835,
              "speed2d": 6.1035,
              "speed3d": 6.225,
              "msFromStart": 158091.87244444425
            },
            {
              "lat": 43.777241493917685,
              "lng": -116.10689481482807,
              "alt": 1886.23522374563,
              "speed2d": 5.54547147690742,
              "speed3d": 5.380916760653317,
              "msFromStart": 158500
            },
            {
              "lat": 43.777215756306866,
              "lng": -116.10691054336802,
              "alt": 1885.3796864968351,
              "speed2d": 5.659860858361881,
              "speed3d": 5.603340557925881,
              "msFromStart": 159000
            },
            {
              "lat": 43.777175740000004,
              "lng": -116.10693264,
              "alt": 1883.9494,
              "speed2d": 5.5934,
              "speed3d": 5.715999999999999,
              "msFromStart": 159759.77919999996
            },
            {
              "lat": 43.77715601999999,
              "lng": -116.10693748,
              "alt": 1882.9160000000002,
              "speed2d": 5.2918,
              "speed3d": 5.372,
              "msFromStart": 160114.81599999996
            },
            {
              "lat": 43.777125299999994,
              "lng": -116.10694422,
              "alt": 1881.4164,
              "speed2d": 5.237,
              "speed3d": 5.26,
              "msFromStart": 160789.36909473687
            },
            {
              "lat": 43.77709836666667,
              "lng": -116.10695076666666,
              "alt": 1880.659666666667,
              "speed2d": 5.002666666666666,
              "speed3d": 5.03,
              "msFromStart": 161431.3515294118
            },
            {
              "lat": 43.77708555,
              "lng": -116.106955075,
              "alt": 1879.9119999999998,
              "speed2d": 4.7532499999999995,
              "speed3d": 4.8149999999999995,
              "msFromStart": 161841.64564705896
            }
          ]
        }
      ]
    },
    {
      "id": "trail-upper-ridge",
      "pathType": "trail",
      "name": "Upper Ridge",
      "difficulty": "blue",
      "videos": [
        {
          "videoId": "GH010034",
          "pathId": "trail-upper-ridge",
          "startSec": 13,
          "endSec": 102,
          "id": "video-segment-2",
          "gps": [
            {
              "lat": 43.75456740000001,
              "lng": -116.09649002222221,
              "alt": 2159.4053333333336,
              "speed2d": 0.704111111111111,
              "speed3d": 0.8477777777777779,
              "msFromStart": 13276.563311038015
            },
            {
              "lat": 43.754567800000004,
              "lng": -116.09649272222222,
              "alt": 2159.1765555555558,
              "speed2d": 0.23288888888888887,
              "speed3d": 0.4011111111111111,
              "msFromStart": 13771.612315789478
            },
            {
              "lat": 43.75456562222222,
              "lng": -116.0964916888889,
              "alt": 2158.9774444444442,
              "speed2d": 0.48233333333333334,
              "speed3d": 0.528888888888889,
              "msFromStart": 14266.28578752437
            },
            {
              "lat": 43.75456441111112,
              "lng": -116.09648185555557,
              "alt": 2158.9276666666665,
              "speed2d": 1.1397777777777778,
              "speed3d": 1.1044444444444446,
              "msFromStart": 14760.402111111112
            },
            {
              "lat": 43.75456892222222,
              "lng": -116.09647765555556,
              "alt": 2158.427,
              "speed2d": 1.0993333333333333,
              "speed3d": 1.16,
              "msFromStart": 15254.735666666664
            },
            {
              "lat": 43.75457358888889,
              "lng": -116.09647762222222,
              "alt": 2158.048111111111,
              "speed2d": 0.6915555555555555,
              "speed3d": 0.8033333333333332,
              "msFromStart": 15749.73844444444
            },
            {
              "lat": 43.754577644444446,
              "lng": -116.09647930000001,
              "alt": 2157.6657777777777,
              "speed2d": 0.6993333333333334,
              "speed3d": 0.75,
              "msFromStart": 16244.99422222222
            },
            {
              "lat": 43.75458286666666,
              "lng": -116.09648244444445,
              "alt": 2157.347222222222,
              "speed2d": 1.003111111111111,
              "speed3d": 1.0755555555555556,
              "msFromStart": 16740.841111111116
            },
            {
              "lat": 43.75458871111111,
              "lng": -116.09648353333334,
              "alt": 2157.218,
              "speed2d": 1.0927777777777776,
              "speed3d": 1.0877777777777777,
              "msFromStart": 17236.42123586745
            },
            {
              "lat": 43.75459357777778,
              "lng": -116.09648897777777,
              "alt": 2157.0074444444444,
              "speed2d": 1.2344444444444445,
              "speed3d": 1.2877777777777777,
              "msFromStart": 17731.192842105258
            },
            {
              "lat": 43.75459895555555,
              "lng": -116.09649456666668,
              "alt": 2156.7083333333335,
              "speed2d": 1.3485555555555557,
              "speed3d": 1.4133333333333336,
              "msFromStart": 18225.779107212475
            },
            {
              "lat": 43.75460193,
              "lng": -116.09650056,
              "alt": 2156.4188999999997,
              "speed2d": 1.0183,
              "speed3d": 1.097,
              "msFromStart": 18747.23633333334
            },
            {
              "lat": 43.7546069,
              "lng": -116.0965014111111,
              "alt": 2156.1404444444443,
              "speed2d": 0.8848888888888891,
              "speed3d": 0.9722222222222223,
              "msFromStart": 19268.987740740748
            },
            {
              "lat": 43.754613,
              "lng": -116.09649985555555,
              "alt": 2155.8507777777777,
              "speed2d": 1.0917777777777777,
              "speed3d": 1.122222222222222,
              "msFromStart": 19764.25055555557
            },
            {
              "lat": 43.75461796666666,
              "lng": -116.09649635555556,
              "alt": 2155.6263333333336,
              "speed2d": 1.1424444444444446,
              "speed3d": 1.24,
              "msFromStart": 20259.36988888889
            },
            {
              "lat": 43.75461967777778,
              "lng": -116.09649516666667,
              "alt": 2155.556111111111,
              "speed2d": 0.8924444444444444,
              "speed3d": 0.9988888888888889,
              "msFromStart": 20753.963555555543
            },
            {
              "lat": 43.754621533333335,
              "lng": -116.09649352222223,
              "alt": 2155.5648888888886,
              "speed2d": 0.8263333333333334,
              "speed3d": 0.9166666666666667,
              "msFromStart": 21248.873074074065
            },
            {
              "lat": 43.754624,
              "lng": -116.09649435555552,
              "alt": 2155.2213333333334,
              "speed2d": 0.7366666666666667,
              "speed3d": 0.841111111111111,
              "msFromStart": 21744.714999999982
            },
            {
              "lat": 43.75462806666667,
              "lng": -116.09649368888888,
              "alt": 2154.714,
              "speed2d": 0.8562222222222222,
              "speed3d": 0.961111111111111,
              "msFromStart": 22240.177280701748
            },
            {
              "lat": 43.75462956666667,
              "lng": -116.09650064444445,
              "alt": 2154.3125555555557,
              "speed2d": 0.862111111111111,
              "speed3d": 0.9833333333333333,
              "msFromStart": 22734.47589473684
            },
            {
              "lat": 43.75462745555556,
              "lng": -116.09650952222222,
              "alt": 2154.637888888889,
              "speed2d": 1.0026666666666668,
              "speed3d": 1.0677777777777777,
              "msFromStart": 23228.820787524364
            },
            {
              "lat": 43.75462851,
              "lng": -116.09651597000001,
              "alt": 2154.4024,
              "speed2d": 0.9724,
              "speed3d": 1.076,
              "msFromStart": 23751.08566666667
            },
            {
              "lat": 43.75462984444444,
              "lng": -116.0965212888889,
              "alt": 2154.0894444444443,
              "speed2d": 0.9177777777777778,
              "speed3d": 0.9777777777777779,
              "msFromStart": 24273.763000000006
            },
            {
              "lat": 43.75463092222222,
              "lng": -116.09653217777777,
              "alt": 2153.8926666666666,
              "speed2d": 1.2522222222222221,
              "speed3d": 1.29,
              "msFromStart": 24769.95322222223
            },
            {
              "lat": 43.754634122222214,
              "lng": -116.09654488888889,
              "alt": 2154.012,
              "speed2d": 1.6405555555555555,
              "speed3d": 1.6522222222222223,
              "msFromStart": 25265.956148148143
            },
            {
              "lat": 43.75463903333334,
              "lng": -116.09655914444443,
              "alt": 2153.825888888889,
              "speed2d": 1.9422222222222223,
              "speed3d": 1.9566666666666666,
              "msFromStart": 25761.30744444443
            },
            {
              "lat": 43.7546404,
              "lng": -116.09656991111112,
              "alt": 2153.3635555555556,
              "speed2d": 1.8192222222222223,
              "speed3d": 1.8722222222222222,
              "msFromStart": 26256.66748148148
            },
            {
              "lat": 43.754646344444446,
              "lng": -116.09658523333334,
              "alt": 2152.931777777778,
              "speed2d": 2.243888888888889,
              "speed3d": 2.22,
              "msFromStart": 26752.144333333337
            },
            {
              "lat": 43.754653588888885,
              "lng": -116.09660355555556,
              "alt": 2152.7224444444446,
              "speed2d": 2.684888888888889,
              "speed3d": 2.6977777777777776,
              "msFromStart": 27247.430883040935
            },
            {
              "lat": 43.754659966666665,
              "lng": -116.09662247777777,
              "alt": 2152.4157777777773,
              "speed2d": 2.946777777777778,
              "speed3d": 2.951111111111111,
              "msFromStart": 27742.184210526317
            },
            {
              "lat": 43.75466674444444,
              "lng": -116.09664373333332,
              "alt": 2152.2655555555557,
              "speed2d": 3.246111111111111,
              "speed3d": 3.256666666666667,
              "msFromStart": 28236.921461988302
            },
            {
              "lat": 43.75467551111111,
              "lng": -116.09666337777779,
              "alt": 2151.5757777777776,
              "speed2d": 3.453666666666666,
              "speed3d": 3.471111111111111,
              "msFromStart": 28731.706666666658
            },
            {
              "lat": 43.75468447777778,
              "lng": -116.0966850111111,
              "alt": 2150.9595555555557,
              "speed2d": 3.7394444444444446,
              "speed3d": 3.7266666666666666,
              "msFromStart": 29226.298703703695
            },
            {
              "lat": 43.754693620000005,
              "lng": -116.09671236,
              "alt": 2150.7532,
              "speed2d": 4.1073,
              "speed3d": 4.114,
              "msFromStart": 29747.52783333332
            },
            {
              "lat": 43.75470707777778,
              "lng": -116.0967415888889,
              "alt": 2149.9735555555553,
              "speed2d": 4.66,
              "speed3d": 4.643333333333333,
              "msFromStart": 30268.88492592592
            },
            {
              "lat": 43.75472061111111,
              "lng": -116.09677051111112,
              "alt": 2149.508,
              "speed2d": 5.049333333333333,
              "speed3d": 5.05,
              "msFromStart": 30763.37533333333
            },
            {
              "lat": 43.75473473333332,
              "lng": -116.09680455555556,
              "alt": 2149.196888888889,
              "speed2d": 5.557666666666667,
              "speed3d": 5.539999999999999,
              "msFromStart": 31258.319481481474
            },
            {
              "lat": 43.75475016666667,
              "lng": -116.09684056666667,
              "alt": 2149.0254444444445,
              "speed2d": 6.067444444444444,
              "speed3d": 6.048888888888889,
              "msFromStart": 31754.414888888874
            },
            {
              "lat": 43.754765255555554,
              "lng": -116.09687724444443,
              "alt": 2149.3954444444444,
              "speed2d": 6.387333333333333,
              "speed3d": 6.386666666666667,
              "msFromStart": 32249.964904483422
            },
            {
              "lat": 43.75477942222223,
              "lng": -116.09691459999998,
              "alt": 2149.5131111111114,
              "speed2d": 6.5407777777777785,
              "speed3d": 6.544444444444445,
              "msFromStart": 32743.87010526316
            },
            {
              "lat": 43.75479501111111,
              "lng": -116.09695345555556,
              "alt": 2149.366666666667,
              "speed2d": 6.616666666666667,
              "speed3d": 6.621111111111111,
              "msFromStart": 33237.87195321638
            },
            {
              "lat": 43.75481178888889,
              "lng": -116.09699131111113,
              "alt": 2149.0897777777777,
              "speed2d": 6.623111111111112,
              "speed3d": 6.662222222222223,
              "msFromStart": 33732.606666666696
            },
            {
              "lat": 43.7548276,
              "lng": -116.0970273888889,
              "alt": 2148.7832222222223,
              "speed2d": 6.530333333333334,
              "speed3d": 6.545555555555556,
              "msFromStart": 34227.59170370372
            },
            {
              "lat": 43.75484599,
              "lng": -116.09706639000001,
              "alt": 2148.7529,
              "speed2d": 6.6722,
              "speed3d": 6.674,
              "msFromStart": 34750.82733333332
            },
            {
              "lat": 43.754863622222224,
              "lng": -116.09710986666666,
              "alt": 2148.7807777777775,
              "speed2d": 7.003333333333334,
              "speed3d": 6.982222222222222,
              "msFromStart": 35273.92118518518
            },
            {
              "lat": 43.75488187777778,
              "lng": -116.09715308888887,
              "alt": 2148.5514444444443,
              "speed2d": 7.324777777777778,
              "speed3d": 7.277777777777777,
              "msFromStart": 35768.89555555559
            },
            {
              "lat": 43.754902411111104,
              "lng": -116.0971957888889,
              "alt": 2148.050333333333,
              "speed2d": 7.6259999999999994,
              "speed3d": 7.625555555555556,
              "msFromStart": 36263.69281481483
            },
            {
              "lat": 43.75492083333333,
              "lng": -116.09723936666667,
              "alt": 2147.8486666666663,
              "speed2d": 7.780222222222223,
              "speed3d": 7.778888888888889,
              "msFromStart": 36758.09177777781
            },
            {
              "lat": 43.754940588888886,
              "lng": -116.09728745555556,
              "alt": 2147.3596666666663,
              "speed2d": 8.207444444444445,
              "speed3d": 8.159999999999998,
              "msFromStart": 37252.51510721248
            },
            {
              "lat": 43.754962111111105,
              "lng": -116.09733482222221,
              "alt": 2147.007888888889,
              "speed2d": 8.347444444444445,
              "speed3d": 8.382222222222222,
              "msFromStart": 37747.061473684196
            },
            {
              "lat": 43.75498317777778,
              "lng": -116.09737788888887,
              "alt": 2146.3517777777774,
              "speed2d": 8.269666666666666,
              "speed3d": 8.292222222222222,
              "msFromStart": 38241.67490058478
            },
            {
              "lat": 43.755005288888896,
              "lng": -116.09742602222224,
              "alt": 2145.7210000000005,
              "speed2d": 8.642888888888889,
              "speed3d": 8.615555555555556,
              "msFromStart": 38736.53066666664
            },
            {
              "lat": 43.755029633333336,
              "lng": -116.09747540000001,
              "alt": 2145.1096666666667,
              "speed2d": 9.019333333333332,
              "speed3d": 8.986666666666666,
              "msFromStart": 39231.645481481464
            },
            {
              "lat": 43.755056177777774,
              "lng": -116.0975294888889,
              "alt": 2144.4593333333332,
              "speed2d": 9.648555555555555,
              "speed3d": 9.615555555555554,
              "msFromStart": 39727.69955555554
            },
            {
              "lat": 43.755088210000004,
              "lng": -116.09758271,
              "alt": 2143.3251,
              "speed2d": 9.985700000000001,
              "speed3d": 9.984000000000002,
              "msFromStart": 40251.21439999999
            },
            {
              "lat": 43.755122911111116,
              "lng": -116.09763421111111,
              "alt": 2142.338111111111,
              "speed2d": 10.235555555555557,
              "speed3d": 10.272222222222222,
              "msFromStart": 40774.091555555526
            },
            {
              "lat": 43.755153911111115,
              "lng": -116.09768367777778,
              "alt": 2142.014,
              "speed2d": 10.322444444444447,
              "speed3d": 10.350000000000001,
              "msFromStart": 41269.34785185185
            },
            {
              "lat": 43.75518451111111,
              "lng": -116.09773026666667,
              "alt": 2142.3313333333335,
              "speed2d": 10.17988888888889,
              "speed3d": 10.225555555555555,
              "msFromStart": 41764.474666666705
            },
            {
              "lat": 43.75521607777778,
              "lng": -116.09777583333334,
              "alt": 2142.519777777778,
              "speed2d": 10.10811111111111,
              "speed3d": 10.14111111111111,
              "msFromStart": 42259.44369590644
            },
            {
              "lat": 43.755248099999996,
              "lng": -116.0978183888889,
              "alt": 2142.7263333333335,
              "speed2d": 10.07288888888889,
              "speed3d": 10.087777777777777,
              "msFromStart": 42754.00294736841
            },
            {
              "lat": 43.75528128888889,
              "lng": -116.09785952222222,
              "alt": 2142.6523333333334,
              "speed2d": 10.064444444444444,
              "speed3d": 10.077777777777776,
              "msFromStart": 43248.314393762186
            },
            {
              "lat": 43.75531662222222,
              "lng": -116.09790014444445,
              "alt": 2142.4495555555554,
              "speed2d": 10.339777777777778,
              "speed3d": 10.324444444444444,
              "msFromStart": 43741.72088888889
            },
            {
              "lat": 43.75535512222222,
              "lng": -116.0979348888889,
              "alt": 2142.389,
              "speed2d": 10.501444444444443,
              "speed3d": 10.49888888888889,
              "msFromStart": 44235.71911111111
            },
            {
              "lat": 43.755396133333335,
              "lng": -116.09796592222223,
              "alt": 2141.9157777777777,
              "speed2d": 10.502333333333334,
              "speed3d": 10.54888888888889,
              "msFromStart": 44732.446222222185
            },
            {
              "lat": 43.7554375,
              "lng": -116.09799075555556,
              "alt": 2141.1645555555556,
              "speed2d": 10.137333333333334,
              "speed3d": 10.182222222222224,
              "msFromStart": 45229.15362962962
            },
            {
              "lat": 43.75547669,
              "lng": -116.09801141,
              "alt": 2140.7756,
              "speed2d": 9.5069,
              "speed3d": 9.619,
              "msFromStart": 45752.21400000002
            },
            {
              "lat": 43.75551657777778,
              "lng": -116.09802474444443,
              "alt": 2140.153,
              "speed2d": 9.12111111111111,
              "speed3d": 9.164444444444444,
              "msFromStart": 46274.90014814815
            },
            {
              "lat": 43.75555718888889,
              "lng": -116.09803587777779,
              "alt": 2139.5436666666665,
              "speed2d": 9.094888888888889,
              "speed3d": 9.121111111111112,
              "msFromStart": 46769.36488888887
            },
            {
              "lat": 43.75560066666667,
              "lng": -116.09804467777776,
              "alt": 2139.251888888889,
              "speed2d": 9.466666666666667,
              "speed3d": 9.454444444444443,
              "msFromStart": 47263.53690448344
            },
            {
              "lat": 43.755644211111104,
              "lng": -116.09804834444446,
              "alt": 2138.653888888889,
              "speed2d": 9.647111111111112,
              "speed3d": 9.688888888888888,
              "msFromStart": 47757.01410526318
            },
            {
              "lat": 43.755685,
              "lng": -116.0980488111111,
              "alt": 2138.5531111111113,
              "speed2d": 9.56011111111111,
              "speed3d": 9.623333333333333,
              "msFromStart": 48250.87313840157
            },
            {
              "lat": 43.755725355555555,
              "lng": -116.09804835555556,
              "alt": 2138.57,
              "speed2d": 9.712000000000002,
              "speed3d": 9.73,
              "msFromStart": 48746.405777777756
            },
            {
              "lat": 43.75576285555555,
              "lng": -116.09804924444447,
              "alt": 2137.6839999999997,
              "speed2d": 9.322111111111111,
              "speed3d": 9.39333333333333,
              "msFromStart": 49241.968
            },
            {
              "lat": 43.7558037,
              "lng": -116.09804874444444,
              "alt": 2137.3005555555555,
              "speed2d": 9.333666666666666,
              "speed3d": 9.38888888888889,
              "msFromStart": 49736.97911111115
            },
            {
              "lat": 43.75584145555556,
              "lng": -116.09804757777779,
              "alt": 2137.3133333333335,
              "speed2d": 9.124444444444444,
              "speed3d": 9.202222222222222,
              "msFromStart": 50232.04400000002
            },
            {
              "lat": 43.75587478888888,
              "lng": -116.09804384444445,
              "alt": 2137.4997777777776,
              "speed2d": 8.657333333333334,
              "speed3d": 8.752222222222223,
              "msFromStart": 50727.54444444447
            },
            {
              "lat": 43.75590740999999,
              "lng": -116.09803796,
              "alt": 2137.6155000000003,
              "speed2d": 8.109599999999999,
              "speed3d": 8.200000000000001,
              "msFromStart": 51250.543533333344
            },
            {
              "lat": 43.7559397,
              "lng": -116.09803627777778,
              "alt": 2137.365555555555,
              "speed2d": 7.5971111111111105,
              "speed3d": 7.688888888888889,
              "msFromStart": 51773.2993333333
            },
            {
              "lat": 43.75597163333333,
              "lng": -116.0980336111111,
              "alt": 2137.2952222222225,
              "speed2d": 7.565777777777777,
              "speed3d": 7.5822222222222235,
              "msFromStart": 52268.50897076022
            },
            {
              "lat": 43.75600118888889,
              "lng": -116.09803221111112,
              "alt": 2136.6544444444444,
              "speed2d": 7.382444444444445,
              "speed3d": 7.434444444444445,
              "msFromStart": 52763.68505263158
            },
            {
              "lat": 43.756035744444446,
              "lng": -116.09803194444446,
              "alt": 2136.418111111111,
              "speed2d": 7.537444444444445,
              "speed3d": 7.558888888888889,
              "msFromStart": 53258.97745808967
            },
            {
              "lat": 43.756065755555554,
              "lng": -116.09803323333334,
              "alt": 2136.013111111111,
              "speed2d": 7.349666666666667,
              "speed3d": 7.415555555555556,
              "msFromStart": 53754.7422222222
            },
            {
              "lat": 43.75609642222222,
              "lng": -116.0980369,
              "alt": 2135.7941111111113,
              "speed2d": 7.180111111111111,
              "speed3d": 7.2488888888888905,
              "msFromStart": 54250.567555555535
            },
            {
              "lat": 43.75612563333333,
              "lng": -116.09804424444445,
              "alt": 2135.471111111111,
              "speed2d": 6.957222222222223,
              "speed3d": 7.006666666666667,
              "msFromStart": 54746.44622222222
            },
            {
              "lat": 43.756159411111106,
              "lng": -116.09805828888886,
              "alt": 2135.2666666666664,
              "speed2d": 7.135999999999999,
              "speed3d": 7.126666666666667,
              "msFromStart": 55242.01429629631
            },
            {
              "lat": 43.756193466666666,
              "lng": -116.0980759888889,
              "alt": 2134.8454444444446,
              "speed2d": 7.360333333333332,
              "speed3d": 7.41,
              "msFromStart": 55736.31866666671
            },
            {
              "lat": 43.756223455555556,
              "lng": -116.09809148888888,
              "alt": 2134.544888888889,
              "speed2d": 7.23911111111111,
              "speed3d": 7.283333333333332,
              "msFromStart": 56230.58703703707
            },
            {
              "lat": 43.756253066666666,
              "lng": -116.09810648888889,
              "alt": 2134.740222222222,
              "speed2d": 7.156444444444444,
              "speed3d": 7.197777777777778,
              "msFromStart": 56725.216888888914
            },
            {
              "lat": 43.75628445,
              "lng": -116.09812301,
              "alt": 2134.6540999999997,
              "speed2d": 7.0672999999999995,
              "speed3d": 7.108,
              "msFromStart": 57247.59611929826
            },
            {
              "lat": 43.75631641111111,
              "lng": -116.09814216666666,
              "alt": 2134.321666666667,
              "speed2d": 7.072777777777777,
              "speed3d": 7.090000000000001,
              "msFromStart": 57770.725894736825
            },
            {
              "lat": 43.756347311111114,
              "lng": -116.09816185555556,
              "alt": 2133.935888888889,
              "speed2d": 7.15411111111111,
              "speed3d": 7.183333333333334,
              "msFromStart": 58266.20056530213
            },
            {
              "lat": 43.75637192222222,
              "lng": -116.09817731111112,
              "alt": 2133.6497777777777,
              "speed2d": 6.857666666666667,
              "speed3d": 6.942222222222223,
              "msFromStart": 58760.936444444415
            },
            {
              "lat": 43.75638923333334,
              "lng": -116.09818972222223,
              "alt": 2133.2945555555557,
              "speed2d": 6.437555555555555,
              "speed3d": 6.52,
              "msFromStart": 59255.800666666655
            },
            {
              "lat": 43.7563999,
              "lng": -116.09819673333334,
              "alt": 2133.965111111111,
              "speed2d": 5.755000000000001,
              "speed3d": 5.857777777777777,
              "msFromStart": 59751.47377777778
            },
            {
              "lat": 43.7564093,
              "lng": -116.09820614444446,
              "alt": 2135.2626666666665,
              "speed2d": 5.410777777777778,
              "speed3d": 5.424444444444444,
              "msFromStart": 60247.358148148145
            },
            {
              "lat": 43.75642361111112,
              "lng": -116.09821810000003,
              "alt": 2135.539777777778,
              "speed2d": 5.461777777777779,
              "speed3d": 5.466666666666667,
              "msFromStart": 60743.76399999996
            },
            {
              "lat": 43.75643891111111,
              "lng": -116.09823056666667,
              "alt": 2135.5723333333335,
              "speed2d": 5.438777777777777,
              "speed3d": 5.456666666666667,
              "msFromStart": 61239.93311111109
            },
            {
              "lat": 43.756454833333336,
              "lng": -116.09824171111111,
              "alt": 2135.077111111111,
              "speed2d": 5.279,
              "speed3d": 5.293333333333333,
              "msFromStart": 61734.94666666664
            },
            {
              "lat": 43.7564757,
              "lng": -116.09826026666667,
              "alt": 2134.4675555555555,
              "speed2d": 5.798,
              "speed3d": 5.725555555555555,
              "msFromStart": 62229.891703703695
            },
            {
              "lat": 43.756498577777776,
              "lng": -116.09827827777777,
              "alt": 2133.831888888889,
              "speed2d": 6.241,
              "speed3d": 6.213333333333333,
              "msFromStart": 62725.02488888888
            },
            {
              "lat": 43.756524230000004,
              "lng": -116.09829986000001,
              "alt": 2133.2983999999997,
              "speed2d": 6.617800000000001,
              "speed3d": 6.608,
              "msFromStart": 63247.46683508772
            },
            {
              "lat": 43.7565474,
              "lng": -116.09831999999999,
              "alt": 2133.3345555555557,
              "speed2d": 6.739555555555556,
              "speed3d": 6.76,
              "msFromStart": 63769.20126315793
            },
            {
              "lat": 43.756571433333335,
              "lng": -116.09833805555556,
              "alt": 2133.146444444444,
              "speed2d": 6.923222222222222,
              "speed3d": 6.87,
              "msFromStart": 64263.649142300215
            },
            {
              "lat": 43.75660246666668,
              "lng": -116.09835755555557,
              "alt": 2132.335,
              "speed2d": 7.411333333333333,
              "speed3d": 7.398888888888888,
              "msFromStart": 64759.02622222221
            },
            {
              "lat": 43.7566298,
              "lng": -116.09837337777779,
              "alt": 2132.0636666666664,
              "speed2d": 7.344555555555556,
              "speed3d": 7.401111111111112,
              "msFromStart": 65254.326222222226
            },
            {
              "lat": 43.75665531111112,
              "lng": -116.09838861111112,
              "alt": 2132.0533333333333,
              "speed2d": 7.190666666666667,
              "speed3d": 7.200000000000001,
              "msFromStart": 65748.94622222222
            },
            {
              "lat": 43.75668243333334,
              "lng": -116.09840378888886,
              "alt": 2131.9093333333335,
              "speed2d": 7.197111111111112,
              "speed3d": 7.2122222222222225,
              "msFromStart": 66243.43933333333
            },
            {
              "lat": 43.7567146888889,
              "lng": -116.09842351111112,
              "alt": 2131.1176666666665,
              "speed2d": 7.562222222222223,
              "speed3d": 7.532222222222223,
              "msFromStart": 66737.69688888888
            },
            {
              "lat": 43.75674672222222,
              "lng": -116.09844554444444,
              "alt": 2130.344777777778,
              "speed2d": 7.899444444444444,
              "speed3d": 7.89,
              "msFromStart": 67232.09379337233
            },
            {
              "lat": 43.756774855555555,
              "lng": -116.09847052222223,
              "alt": 2130.367666666667,
              "speed2d": 8.035555555555556,
              "speed3d": 8.022222222222222,
              "msFromStart": 67727.14231578953
            },
            {
              "lat": 43.756806909999995,
              "lng": -116.09850276999998,
              "alt": 2129.8089999999997,
              "speed2d": 8.390600000000001,
              "speed3d": 8.38,
              "msFromStart": 68249.83470175443
            },
            {
              "lat": 43.756841566666665,
              "lng": -116.09853770000001,
              "alt": 2128.4392222222223,
              "speed2d": 8.664333333333333,
              "speed3d": 8.668888888888889,
              "msFromStart": 68772.86488888896
            },
            {
              "lat": 43.756875988888886,
              "lng": -116.09857246666667,
              "alt": 2126.5835555555554,
              "speed2d": 8.741000000000001,
              "speed3d": 8.774444444444445,
              "msFromStart": 69268.0321481482
            },
            {
              "lat": 43.756910688888894,
              "lng": -116.09861247777778,
              "alt": 2124.6760000000004,
              "speed2d": 8.933,
              "speed3d": 8.966666666666667,
              "msFromStart": 69761.67644444444
            },
            {
              "lat": 43.756943622222224,
              "lng": -116.09865656666666,
              "alt": 2123.1299999999997,
              "speed2d": 9.315222222222221,
              "speed3d": 9.352222222222222,
              "msFromStart": 70255.67066666666
            },
            {
              "lat": 43.75696864444445,
              "lng": -116.09869977777777,
              "alt": 2122.6433333333334,
              "speed2d": 9.312444444444445,
              "speed3d": 9.393333333333334,
              "msFromStart": 70751.6737777778
            },
            {
              "lat": 43.75699275555555,
              "lng": -116.09874931111112,
              "alt": 2122.032,
              "speed2d": 9.558222222222222,
              "speed3d": 9.574444444444444,
              "msFromStart": 71247.68444444444
            },
            {
              "lat": 43.75701461111111,
              "lng": -116.09880442222223,
              "alt": 2121.4723333333336,
              "speed2d": 9.898333333333333,
              "speed3d": 9.963333333333331,
              "msFromStart": 71742.92177777772
            },
            {
              "lat": 43.75703281111111,
              "lng": -116.09886096666665,
              "alt": 2120.9156666666668,
              "speed2d": 9.92511111111111,
              "speed3d": 10.001111111111111,
              "msFromStart": 72237.77425341128
            },
            {
              "lat": 43.75705125555555,
              "lng": -116.09891715555554,
              "alt": 2119.9467777777777,
              "speed2d": 9.710666666666667,
              "speed3d": 9.785555555555556,
              "msFromStart": 72731.39663157896
            },
            {
              "lat": 43.757071749999994,
              "lng": -116.09897980000001,
              "alt": 2119.1397,
              "speed2d": 9.8715,
              "speed3d": 9.917,
              "msFromStart": 73252.5727368421
            },
            {
              "lat": 43.75709374444444,
              "lng": -116.09904223333334,
              "alt": 2118.48,
              "speed2d": 9.975888888888889,
              "speed3d": 10.044444444444444,
              "msFromStart": 73774.96266666667
            },
            {
              "lat": 43.757117433333335,
              "lng": -116.09909516666666,
              "alt": 2117.721555555556,
              "speed2d": 9.825999999999999,
              "speed3d": 9.94888888888889,
              "msFromStart": 74270.2826666667
            },
            {
              "lat": 43.75714532222222,
              "lng": -116.0991340111111,
              "alt": 2116.6802222222223,
              "speed2d": 9.230222222222222,
              "speed3d": 9.37222222222222,
              "msFromStart": 74766.64266666672
            },
            {
              "lat": 43.75717797777778,
              "lng": -116.09916342222223,
              "alt": 2115.378666666667,
              "speed2d": 8.812222222222221,
              "speed3d": 8.938888888888888,
              "msFromStart": 75262.70592592594
            },
            {
              "lat": 43.757206999999994,
              "lng": -116.09919828888889,
              "alt": 2114.287222222222,
              "speed2d": 8.814333333333334,
              "speed3d": 8.944444444444445,
              "msFromStart": 75757.16622222218
            },
            {
              "lat": 43.757230866666674,
              "lng": -116.09924146666667,
              "alt": 2112.513111111111,
              "speed2d": 8.856222222222222,
              "speed3d": 8.972222222222221,
              "msFromStart": 76251.76325925921
            },
            {
              "lat": 43.757252666666666,
              "lng": -116.09929406666666,
              "alt": 2110.779,
              "speed2d": 9.189555555555554,
              "speed3d": 9.338888888888889,
              "msFromStart": 76747.5484444444
            },
            {
              "lat": 43.757272066666665,
              "lng": -116.0993506111111,
              "alt": 2109.4674444444445,
              "speed2d": 9.520333333333333,
              "speed3d": 9.654444444444445,
              "msFromStart": 77243.1811773879
            },
            {
              "lat": 43.75729637777779,
              "lng": -116.09941131111111,
              "alt": 2108.1924444444444,
              "speed2d": 10.092333333333336,
              "speed3d": 10.234444444444446,
              "msFromStart": 77737.72884210527
            },
            {
              "lat": 43.75732595555555,
              "lng": -116.09946792222222,
              "alt": 2106.5044444444443,
              "speed2d": 10.330888888888891,
              "speed3d": 10.523333333333333,
              "msFromStart": 78232.05402988954
            },
            {
              "lat": 43.757362099999995,
              "lng": -116.09951976,
              "alt": 2104.7284,
              "speed2d": 10.3369,
              "speed3d": 10.572000000000001,
              "msFromStart": 78753.10155555556
            },
            {
              "lat": 43.75739664444444,
              "lng": -116.09956356666667,
              "alt": 2103.727888888889,
              "speed2d": 10.079444444444444,
              "speed3d": 10.366666666666667,
              "msFromStart": 79274.47881481482
            },
            {
              "lat": 43.757427633333336,
              "lng": -116.09959202222224,
              "alt": 2102.740222222222,
              "speed2d": 9.333444444444446,
              "speed3d": 9.643333333333334,
              "msFromStart": 79770.2133333334
            },
            {
              "lat": 43.757462888888895,
              "lng": -116.0996223888889,
              "alt": 2101.5858888888893,
              "speed2d": 9.250555555555554,
              "speed3d": 9.453333333333333,
              "msFromStart": 80266.17200000002
            },
            {
              "lat": 43.757520544444446,
              "lng": -116.09966062222222,
              "alt": 2099.7437777777777,
              "speed2d": 10.67522222222222,
              "speed3d": 10.731111111111112,
              "msFromStart": 80762.31733333331
            },
            {
              "lat": 43.75757714444445,
              "lng": -116.09969867777777,
              "alt": 2098.3341111111113,
              "speed2d": 11.587444444444444,
              "speed3d": 11.785555555555556,
              "msFromStart": 81259.53392592592
            },
            {
              "lat": 43.75762537777778,
              "lng": -116.09973644444445,
              "alt": 2097.1174444444446,
              "speed2d": 11.729777777777779,
              "speed3d": 11.975555555555555,
              "msFromStart": 81760.96088888893
            },
            {
              "lat": 43.757666533333335,
              "lng": -116.09977816666668,
              "alt": 2095.917777777778,
              "speed2d": 11.421222222222223,
              "speed3d": 11.712222222222222,
              "msFromStart": 82260.87037037038
            },
            {
              "lat": 43.75770888888889,
              "lng": -116.0998222111111,
              "alt": 2094.514111111111,
              "speed2d": 11.326888888888888,
              "speed3d": 11.58222222222222,
              "msFromStart": 82753.02577777773
            },
            {
              "lat": 43.75775617777777,
              "lng": -116.0998655,
              "alt": 2093.035222222222,
              "speed2d": 11.515111111111112,
              "speed3d": 11.797777777777778,
              "msFromStart": 83244.33369200776
            },
            {
              "lat": 43.75779804444444,
              "lng": -116.09989384444444,
              "alt": 2092.737,
              "speed2d": 11.073444444444444,
              "speed3d": 11.394444444444444,
              "msFromStart": 83735.35326315786
            },
            {
              "lat": 43.75783712222222,
              "lng": -116.09991993333334,
              "alt": 2092.7606666666666,
              "speed2d": 10.66088888888889,
              "speed3d": 10.912222222222223,
              "msFromStart": 84226.82711890835
            },
            {
              "lat": 43.757882959999996,
              "lng": -116.09995812999999,
              "alt": 2091.2253,
              "speed2d": 10.6899,
              "speed3d": 10.918000000000001,
              "msFromStart": 84748.74266666666
            },
            {
              "lat": 43.75792788888889,
              "lng": -116.10001077777777,
              "alt": 2089.2315555555556,
              "speed2d": 11.081,
              "speed3d": 11.282222222222222,
              "msFromStart": 85271.5154074074
            },
            {
              "lat": 43.75797267777778,
              "lng": -116.10006278888889,
              "alt": 2087.183111111111,
              "speed2d": 11.395555555555557,
              "speed3d": 11.662222222222223,
              "msFromStart": 85768.28977777783
            },
            {
              "lat": 43.75801723333334,
              "lng": -116.1001067777778,
              "alt": 2085.362222222222,
              "speed2d": 11.278111111111112,
              "speed3d": 11.606666666666666,
              "msFromStart": 86264.95081481483
            },
            {
              "lat": 43.758067033333326,
              "lng": -116.10014467777776,
              "alt": 2083.4211111111113,
              "speed2d": 11.306666666666667,
              "speed3d": 11.581111111111113,
              "msFromStart": 86760.55999999997
            },
            {
              "lat": 43.758125855555555,
              "lng": -116.1001757,
              "alt": 2081.894333333333,
              "speed2d": 11.861444444444446,
              "speed3d": 12.136666666666665,
              "msFromStart": 87255.65288888889
            },
            {
              "lat": 43.758179766666665,
              "lng": -116.10020111111112,
              "alt": 2080.9785555555554,
              "speed2d": 12.030555555555555,
              "speed3d": 12.312222222222223,
              "msFromStart": 87749.10133333338
            },
            {
              "lat": 43.758231511111106,
              "lng": -116.10022953333333,
              "alt": 2079.4682222222223,
              "speed2d": 11.951777777777776,
              "speed3d": 12.268888888888888,
              "msFromStart": 88242.81274074077
            },
            {
              "lat": 43.75827476666667,
              "lng": -116.10025868888889,
              "alt": 2077.2077777777777,
              "speed2d": 11.451666666666666,
              "speed3d": 11.914444444444445,
              "msFromStart": 88738.23377777782
            },
            {
              "lat": 43.75829933333333,
              "lng": -116.10029708888888,
              "alt": 2073.723555555556,
              "speed2d": 10.022,
              "speed3d": 10.545555555555556,
              "msFromStart": 89233.61022222224
            },
            {
              "lat": 43.758336622222224,
              "lng": -116.10034872222222,
              "alt": 2071.2453333333333,
              "speed2d": 10.414666666666667,
              "speed3d": 10.815555555555553,
              "msFromStart": 89728.1244444445
            },
            {
              "lat": 43.75838636999999,
              "lng": -116.10039929,
              "alt": 2069.2816000000003,
              "speed2d": 10.8853,
              "speed3d": 11.354000000000001,
              "msFromStart": 90250.19127017548
            },
            {
              "lat": 43.758431622222226,
              "lng": -116.10043703333332,
              "alt": 2067.414222222222,
              "speed2d": 10.490666666666666,
              "speed3d": 11.02,
              "msFromStart": 90772.81052631582
            },
            {
              "lat": 43.75847501111112,
              "lng": -116.10046523333334,
              "alt": 2065.483888888889,
              "speed2d": 10.067444444444444,
              "speed3d": 10.59,
              "msFromStart": 91268.03206237819
            },
            {
              "lat": 43.75852147777778,
              "lng": -116.10048801111111,
              "alt": 2063.4476666666665,
              "speed2d": 9.930333333333333,
              "speed3d": 10.466666666666665,
              "msFromStart": 91763.50311111115
            },
            {
              "lat": 43.758567344444444,
              "lng": -116.10051257777779,
              "alt": 2061.6484444444445,
              "speed2d": 9.893777777777776,
              "speed3d": 10.420000000000002,
              "msFromStart": 92258.90503703704
            },
            {
              "lat": 43.75861398888889,
              "lng": -116.10054478888888,
              "alt": 2059.7968888888886,
              "speed2d": 10.095444444444443,
              "speed3d": 10.564444444444442,
              "msFromStart": 92753.93066666667
            },
            {
              "lat": 43.758661211111104,
              "lng": -116.1005888888889,
              "alt": 2057.5002222222224,
              "speed2d": 10.687222222222223,
              "speed3d": 11.135555555555555,
              "msFromStart": 93248.69051851853
            },
            {
              "lat": 43.75870143333333,
              "lng": -116.10064032222222,
              "alt": 2055.2725555555558,
              "speed2d": 10.811888888888888,
              "speed3d": 11.375555555555556,
              "msFromStart": 93742.53777777783
            },
            {
              "lat": 43.758738666666666,
              "lng": -116.10068807777779,
              "alt": 2053.373222222222,
              "speed2d": 10.687666666666667,
              "speed3d": 11.231111111111112,
              "msFromStart": 94236.51377777781
            },
            {
              "lat": 43.75878599999999,
              "lng": -116.10072722222223,
              "alt": 2051.1072222222224,
              "speed2d": 10.756111111111109,
              "speed3d": 11.306666666666667,
              "msFromStart": 94731.36977777781
            },
            {
              "lat": 43.75884078888889,
              "lng": -116.10075421111112,
              "alt": 2048.9346666666665,
              "speed2d": 10.818222222222222,
              "speed3d": 11.382222222222222,
              "msFromStart": 95226.39853411308
            },
            {
              "lat": 43.758900020000006,
              "lng": -116.10078028,
              "alt": 2046.7937000000002,
              "speed2d": 10.9456,
              "speed3d": 11.51,
              "msFromStart": 95749.28863157892
            },
            {
              "lat": 43.758952344444445,
              "lng": -116.10081666666665,
              "alt": 2045.0768888888892,
              "speed2d": 11.049666666666665,
              "speed3d": 11.586666666666668,
              "msFromStart": 96272.1738011696
            },
            {
              "lat": 43.758998277777785,
              "lng": -116.10085567777777,
              "alt": 2042.8382222222222,
              "speed2d": 11.091777777777779,
              "speed3d": 11.667777777777777,
              "msFromStart": 96767.38133333337
            },
            {
              "lat": 43.75903503333334,
              "lng": -116.10090458888888,
              "alt": 2040.525111111111,
              "speed2d": 11.015999999999998,
              "speed3d": 11.613333333333333,
              "msFromStart": 97262.45037037038
            },
            {
              "lat": 43.759067266666676,
              "lng": -116.10096074444444,
              "alt": 2037.8321111111113,
              "speed2d": 10.946444444444442,
              "speed3d": 11.572222222222223,
              "msFromStart": 97757.02755555554
            },
            {
              "lat": 43.759112144444444,
              "lng": -116.10101282222222,
              "alt": 2035.6808888888888,
              "speed2d": 11.149444444444445,
              "speed3d": 11.747777777777777,
              "msFromStart": 98251.72222222222
            },
            {
              "lat": 43.75916048888889,
              "lng": -116.10106054444444,
              "alt": 2033.739,
              "speed2d": 11.336555555555554,
              "speed3d": 11.941111111111113,
              "msFromStart": 98747.0835555556
            },
            {
              "lat": 43.75921811111112,
              "lng": -116.10109783333334,
              "alt": 2030.9515555555554,
              "speed2d": 11.514111111111111,
              "speed3d": 12.123333333333335,
              "msFromStart": 99242.678962963
            },
            {
              "lat": 43.759288866666665,
              "lng": -116.10111966666668,
              "alt": 2026.8452222222222,
              "speed2d": 11.780111111111111,
              "speed3d": 12.426666666666668,
              "msFromStart": 99738.94400000002
            },
            {
              "lat": 43.75935728888888,
              "lng": -116.10114634444446,
              "alt": 2024.1638888888888,
              "speed2d": 12.153222222222222,
              "speed3d": 12.790000000000001,
              "msFromStart": 100235.05038596492
            },
            {
              "lat": 43.759426411111114,
              "lng": -116.10118126666667,
              "alt": 2022.0812222222223,
              "speed2d": 12.638222222222222,
              "speed3d": 13.254444444444445,
              "msFromStart": 100730.25431578953
            },
            {
              "lat": 43.759495539999996,
              "lng": -116.10122430000001,
              "alt": 2019.8527,
              "speed2d": 13.028699999999999,
              "speed3d": 13.634,
              "msFromStart": 101252.66536842109
            },
            {
              "lat": 43.75955341111111,
              "lng": -116.10127737777778,
              "alt": 2016.9104444444445,
              "speed2d": 13.127999999999998,
              "speed3d": 13.812222222222221,
              "msFromStart": 101774.10133333337
            }
          ]
        }
      ]
    },
    {
      "id": "trail-lower-ridge",
      "pathType": "trail",
      "name": "Lower Ridge",
      "difficulty": "blue",
      "videos": [
        {
          "videoId": "GX010082",
          "pathId": "trail-lower-ridge",
          "startSec": 97,
          "endSec": 162,
          "id": "video-segment-48",
          "gps": [
            {
              "lat": 43.75889411111111,
              "lng": -116.10073181111112,
              "alt": 2002.2305555555556,
              "speed2d": 11.846777777777778,
              "speed3d": 12.904444444444444,
              "msFromStart": 97256.80318128657
            },
            {
              "lat": 43.75894026666667,
              "lng": -116.10078167777775,
              "alt": 1999.1933333333332,
              "speed2d": 12.023000000000001,
              "speed3d": 13.157777777777778,
              "msFromStart": 97751.89642105266
            },
            {
              "lat": 43.758995444444444,
              "lng": -116.10082087777778,
              "alt": 1997.8655555555556,
              "speed2d": 11.666777777777778,
              "speed3d": 12.847777777777777,
              "msFromStart": 98247.13545938925
            },
            {
              "lat": 43.75904813333333,
              "lng": -116.10084643333333,
              "alt": 1996.7510000000002,
              "speed2d": 10.729333333333333,
              "speed3d": 11.929999999999998,
              "msFromStart": 98742.908
            },
            {
              "lat": 43.759098211111116,
              "lng": -116.1008607888889,
              "alt": 1995.1724444444449,
              "speed2d": 10.429555555555558,
              "speed3d": 11.556666666666667,
              "msFromStart": 99238.99960493826
            },
            {
              "lat": 43.759146666666666,
              "lng": -116.10086643333332,
              "alt": 1993.4317777777778,
              "speed2d": 10.174222222222225,
              "speed3d": 11.304444444444446,
              "msFromStart": 99736.50400000004
            },
            {
              "lat": 43.75919896666667,
              "lng": -116.10086896666667,
              "alt": 1992.8695555555555,
              "speed2d": 10.136666666666667,
              "speed3d": 11.146666666666668,
              "msFromStart": 100234.00493827162
            },
            {
              "lat": 43.75925871111111,
              "lng": -116.10087076666666,
              "alt": 1994.001,
              "speed2d": 10.025444444444444,
              "speed3d": 11.000000000000002,
              "msFromStart": 100730.07199999996
            },
            {
              "lat": 43.75931424444445,
              "lng": -116.10086684444443,
              "alt": 1996.3616666666662,
              "speed2d": 9.092333333333332,
              "speed3d": 10.01222222222222,
              "msFromStart": 101225.85659259255
            },
            {
              "lat": 43.75936946,
              "lng": -116.10086333000001,
              "alt": 1999.0322999999996,
              "speed2d": 7.926200000000001,
              "speed3d": 8.764,
              "msFromStart": 101748.87688888889
            },
            {
              "lat": 43.75940498888889,
              "lng": -116.10085314444443,
              "alt": 2000.597111111111,
              "speed2d": 6.3661111111111115,
              "speed3d": 7.2155555555555555,
              "msFromStart": 102271.70088888888
            },
            {
              "lat": 43.75944203333333,
              "lng": -116.10084155555556,
              "alt": 2002.732888888889,
              "speed2d": 5.623666666666667,
              "speed3d": 6.328888888888889,
              "msFromStart": 102766.40444444442
            },
            {
              "lat": 43.75947874444445,
              "lng": -116.10083806666667,
              "alt": 2004.6546666666663,
              "speed2d": 4.950777777777778,
              "speed3d": 5.5744444444444445,
              "msFromStart": 103261.05191423002
            },
            {
              "lat": 43.75951551111111,
              "lng": -116.10084565555555,
              "alt": 2006.0860000000002,
              "speed2d": 4.5906666666666665,
              "speed3d": 5.095555555555555,
              "msFromStart": 103755.71326315796
            },
            {
              "lat": 43.75953935555556,
              "lng": -116.10086173333335,
              "alt": 2005.7758888888889,
              "speed2d": 4.105666666666667,
              "speed3d": 4.635555555555556,
              "msFromStart": 104250.30010656275
            },
            {
              "lat": 43.759553233333335,
              "lng": -116.1008856777778,
              "alt": 2004.4289999999999,
              "speed2d": 4.170555555555556,
              "speed3d": 4.602222222222222,
              "msFromStart": 104744.42400000003
            },
            {
              "lat": 43.759569988888884,
              "lng": -116.10091262222224,
              "alt": 2003.778111111111,
              "speed2d": 4.595777777777777,
              "speed3d": 4.966666666666667,
              "msFromStart": 105238.60879012347
            },
            {
              "lat": 43.75959464444445,
              "lng": -116.10094124444446,
              "alt": 2004.2056666666665,
              "speed2d": 5.015222222222222,
              "speed3d": 5.33,
              "msFromStart": 105733.62800000003
            },
            {
              "lat": 43.75961831111111,
              "lng": -116.10096366666669,
              "alt": 2003.501,
              "speed2d": 5.012,
              "speed3d": 5.362222222222222,
              "msFromStart": 106228.74844444446
            },
            {
              "lat": 43.759639580000005,
              "lng": -116.10097832,
              "alt": 2002.1716000000001,
              "speed2d": 4.558199999999999,
              "speed3d": 4.997,
              "msFromStart": 106751.14466666663
            },
            {
              "lat": 43.7596643,
              "lng": -116.1009802111111,
              "alt": 2001.6943333333334,
              "speed2d": 4.059555555555555,
              "speed3d": 4.472222222222222,
              "msFromStart": 107273.37539181284
            },
            {
              "lat": 43.75968973333334,
              "lng": -116.10097476666667,
              "alt": 2001.5777777777778,
              "speed2d": 4.102777777777778,
              "speed3d": 4.453333333333333,
              "msFromStart": 107767.59368421044
            },
            {
              "lat": 43.759709533333336,
              "lng": -116.10096776666666,
              "alt": 2001.3591111111111,
              "speed2d": 3.750222222222222,
              "speed3d": 4.206666666666666,
              "msFromStart": 108262.06045484074
            },
            {
              "lat": 43.75972675555556,
              "lng": -116.10096167777778,
              "alt": 2002.4119999999998,
              "speed2d": 2.886555555555555,
              "speed3d": 3.3244444444444445,
              "msFromStart": 108758.35999999999
            },
            {
              "lat": 43.75973888888888,
              "lng": -116.10095734444445,
              "alt": 2002.1587777777777,
              "speed2d": 2.286555555555556,
              "speed3d": 2.7511111111111113,
              "msFromStart": 109254.72049382713
            },
            {
              "lat": 43.759752077777776,
              "lng": -116.10095007777777,
              "alt": 2002.5434444444445,
              "speed2d": 2.0972222222222223,
              "speed3d": 2.4855555555555555,
              "msFromStart": 109749.62000000004
            },
            {
              "lat": 43.75976987777778,
              "lng": -116.10094328888889,
              "alt": 2003.7664444444447,
              "speed2d": 1.866,
              "speed3d": 2.2244444444444444,
              "msFromStart": 110244.29382716051
            },
            {
              "lat": 43.75978797777778,
              "lng": -116.10093778888887,
              "alt": 2005.7454444444443,
              "speed2d": 1.6316666666666668,
              "speed3d": 1.913333333333333,
              "msFromStart": 110739.05199999994
            },
            {
              "lat": 43.75980505555555,
              "lng": -116.10093204444443,
              "alt": 2007.6372222222221,
              "speed2d": 1.490777777777778,
              "speed3d": 1.6422222222222222,
              "msFromStart": 111233.87585185182
            },
            {
              "lat": 43.7598213,
              "lng": -116.10092812222223,
              "alt": 2009.4695555555554,
              "speed2d": 1.4413333333333334,
              "speed3d": 1.527777777777778,
              "msFromStart": 111729.01600000002
            },
            {
              "lat": 43.7598382,
              "lng": -116.10092469000001,
              "alt": 2011.2209,
              "speed2d": 1.6758000000000002,
              "speed3d": 1.6729999999999996,
              "msFromStart": 112251.63550877196
            },
            {
              "lat": 43.75985102222222,
              "lng": -116.1009254,
              "alt": 2011.1426666666669,
              "speed2d": 1.9171111111111112,
              "speed3d": 1.911111111111111,
              "msFromStart": 112773.86694736843
            },
            {
              "lat": 43.75986426666667,
              "lng": -116.10092608888888,
              "alt": 2010.826111111111,
              "speed2d": 2.2054444444444443,
              "speed3d": 2.191111111111111,
              "msFromStart": 113268.61567251461
            },
            {
              "lat": 43.759879411111115,
              "lng": -116.1009329111111,
              "alt": 2010.5586666666668,
              "speed2d": 2.348,
              "speed3d": 2.331111111111111,
              "msFromStart": 113763.70400000001
            },
            {
              "lat": 43.759896355555554,
              "lng": -116.10094607777776,
              "alt": 2009.5972222222222,
              "speed2d": 3.1914444444444445,
              "speed3d": 3.1255555555555556,
              "msFromStart": 114258.87516049386
            },
            {
              "lat": 43.759916266666664,
              "lng": -116.10096436666666,
              "alt": 2008.311888888889,
              "speed2d": 4.189777777777778,
              "speed3d": 4.117777777777778,
              "msFromStart": 114754.212
            },
            {
              "lat": 43.75993704444444,
              "lng": -116.10099708888889,
              "alt": 2007.3873333333336,
              "speed2d": 5.6368888888888895,
              "speed3d": 5.536666666666665,
              "msFromStart": 115249.46686419751
            },
            {
              "lat": 43.7599593,
              "lng": -116.10103223333334,
              "alt": 2006.373111111111,
              "speed2d": 6.456444444444444,
              "speed3d": 6.472222222222222,
              "msFromStart": 115744.05599999998
            },
            {
              "lat": 43.75998548888889,
              "lng": -116.10106464444446,
              "alt": 2005.1276666666668,
              "speed2d": 6.8018888888888895,
              "speed3d": 6.928888888888889,
              "msFromStart": 116238.56316049382
            },
            {
              "lat": 43.76000941111111,
              "lng": -116.10109212222221,
              "alt": 2003.4663333333335,
              "speed2d": 6.581444444444445,
              "speed3d": 6.785555555555555,
              "msFromStart": 116733.23600000006
            },
            {
              "lat": 43.76003337777778,
              "lng": -116.10111140000001,
              "alt": 2001.7012222222222,
              "speed2d": 5.960222222222222,
              "speed3d": 6.319999999999999,
              "msFromStart": 117227.92765692015
            },
            {
              "lat": 43.760062559999994,
              "lng": -116.10111445999999,
              "alt": 2000.4859,
              "speed2d": 5.3592,
              "speed3d": 5.720999999999999,
              "msFromStart": 117750.04800000005
            },
            {
              "lat": 43.76009333333333,
              "lng": -116.10110957777779,
              "alt": 2000.2735555555555,
              "speed2d": 5.264888888888889,
              "speed3d": 5.59,
              "msFromStart": 118272.1545159195
            },
            {
              "lat": 43.76012087777778,
              "lng": -116.10109826666665,
              "alt": 1999.3266666666666,
              "speed2d": 5.530555555555556,
              "speed3d": 5.806666666666667,
              "msFromStart": 118766.74800000002
            },
            {
              "lat": 43.76014666666667,
              "lng": -116.10108307777779,
              "alt": 1997.930333333333,
              "speed2d": 5.926444444444445,
              "speed3d": 6.192222222222223,
              "msFromStart": 119261.37846913582
            },
            {
              "lat": 43.76017884444445,
              "lng": -116.10105852222222,
              "alt": 1996.865111111111,
              "speed2d": 6.719111111111111,
              "speed3d": 7.039999999999999,
              "msFromStart": 119756.26800000001
            },
            {
              "lat": 43.76020132222222,
              "lng": -116.10103005555557,
              "alt": 1995.982888888889,
              "speed2d": 6.471555555555556,
              "speed3d": 6.772222222222222,
              "msFromStart": 120251.25975308644
            },
            {
              "lat": 43.760224255555556,
              "lng": -116.10099361111111,
              "alt": 1995.4094444444445,
              "speed2d": 6.808333333333334,
              "speed3d": 7.097777777777778,
              "msFromStart": 120746.61600000004
            },
            {
              "lat": 43.76024661111112,
              "lng": -116.10096571111112,
              "alt": 1995.0277777777778,
              "speed2d": 6.548,
              "speed3d": 6.891111111111111,
              "msFromStart": 121241.92928395067
            },
            {
              "lat": 43.760266055555554,
              "lng": -116.1009468111111,
              "alt": 1993.7246666666665,
              "speed2d": 5.834444444444445,
              "speed3d": 6.2666666666666675,
              "msFromStart": 121736.61599999998
            },
            {
              "lat": 43.760289344444445,
              "lng": -116.10094107777779,
              "alt": 1991.9476666666667,
              "speed2d": 5.3629999999999995,
              "speed3d": 5.788888888888888,
              "msFromStart": 122231.31901234566
            },
            {
              "lat": 43.76031861111111,
              "lng": -116.10093686666667,
              "alt": 1990.402,
              "speed2d": 5.869555555555555,
              "speed3d": 6.142222222222222,
              "msFromStart": 122726.74800000007
            },
            {
              "lat": 43.76036202000001,
              "lng": -116.10092896,
              "alt": 1989.3415000000002,
              "speed2d": 7.6021,
              "speed3d": 7.773,
              "msFromStart": 123249.80458479535
            },
            {
              "lat": 43.76040313333334,
              "lng": -116.10091753333333,
              "alt": 1987.9742222222224,
              "speed2d": 8.243666666666666,
              "speed3d": 8.524444444444445,
              "msFromStart": 123772.75915789472
            },
            {
              "lat": 43.7604388,
              "lng": -116.10090320000002,
              "alt": 1986.804111111111,
              "speed2d": 8.17811111111111,
              "speed3d": 8.5,
              "msFromStart": 124268.09984665365
            },
            {
              "lat": 43.7604762,
              "lng": -116.10088944444445,
              "alt": 1985.8756666666666,
              "speed2d": 8.025222222222222,
              "speed3d": 8.373333333333333,
              "msFromStart": 124762.97600000002
            },
            {
              "lat": 43.760512899999995,
              "lng": -116.10088146666668,
              "alt": 1983.9631111111112,
              "speed2d": 7.897111111111112,
              "speed3d": 8.258888888888889,
              "msFromStart": 125257.59476543212
            },
            {
              "lat": 43.760548211111114,
              "lng": -116.10088375555556,
              "alt": 1982.0116666666665,
              "speed2d": 7.938111111111111,
              "speed3d": 8.324444444444444,
              "msFromStart": 125751.10800000007
            },
            {
              "lat": 43.76058273333333,
              "lng": -116.10089666666666,
              "alt": 1979.900222222222,
              "speed2d": 8.124111111111112,
              "speed3d": 8.525555555555556,
              "msFromStart": 126244.7619753087
            },
            {
              "lat": 43.76061761111111,
              "lng": -116.10091001111113,
              "alt": 1978.2845555555557,
              "speed2d": 8.155222222222223,
              "speed3d": 8.591111111111111,
              "msFromStart": 126740.37999999998
            },
            {
              "lat": 43.76065238888889,
              "lng": -116.10091772222222,
              "alt": 1976.3964444444446,
              "speed2d": 8.107777777777779,
              "speed3d": 8.585555555555555,
              "msFromStart": 127236.25821962312
            },
            {
              "lat": 43.76068425555556,
              "lng": -116.10091176666667,
              "alt": 1974.752,
              "speed2d": 7.807555555555555,
              "speed3d": 8.322222222222221,
              "msFromStart": 127731.75957894733
            },
            {
              "lat": 43.76072033333334,
              "lng": -116.10089667777778,
              "alt": 1974.292333333333,
              "speed2d": 7.840777777777779,
              "speed3d": 8.266666666666667,
              "msFromStart": 128227.10052241711
            },
            {
              "lat": 43.760763440000005,
              "lng": -116.10088699999999,
              "alt": 1973.7794000000001,
              "speed2d": 8.183,
              "speed3d": 8.57,
              "msFromStart": 128748.9055555556
            },
            {
              "lat": 43.76079835555555,
              "lng": -116.10088914444445,
              "alt": 1971.7784444444446,
              "speed2d": 8.027333333333335,
              "speed3d": 8.469999999999999,
              "msFromStart": 129270.58153086425
            },
            {
              "lat": 43.760833299999994,
              "lng": -116.10090104444446,
              "alt": 1969.3443333333332,
              "speed2d": 8.582666666666666,
              "speed3d": 8.957777777777778,
              "msFromStart": 129765.52400000002
            },
            {
              "lat": 43.760874533333336,
              "lng": -116.10091642222223,
              "alt": 1967.6697777777777,
              "speed2d": 9.199000000000002,
              "speed3d": 9.591111111111111,
              "msFromStart": 130260.52227160495
            },
            {
              "lat": 43.76091696666668,
              "lng": -116.10092895555556,
              "alt": 1966.0988888888892,
              "speed2d": 9.448,
              "speed3d": 9.885555555555555,
              "msFromStart": 130755.14399999997
            },
            {
              "lat": 43.76096076666667,
              "lng": -116.10092952222223,
              "alt": 1964.6267777777778,
              "speed2d": 9.593777777777778,
              "speed3d": 10.025555555555554,
              "msFromStart": 131249.7227654321
            },
            {
              "lat": 43.7610025,
              "lng": -116.10091988888888,
              "alt": 1962.9564444444445,
              "speed2d": 9.67388888888889,
              "speed3d": 10.141111111111112,
              "msFromStart": 131744.41599999994
            },
            {
              "lat": 43.76104447777779,
              "lng": -116.10091136666667,
              "alt": 1961.255888888889,
              "speed2d": 9.687444444444445,
              "speed3d": 10.108888888888888,
              "msFromStart": 132239.20799999996
            },
            {
              "lat": 43.76109634444444,
              "lng": -116.10091247777778,
              "alt": 1960.0278888888888,
              "speed2d": 10.251444444444445,
              "speed3d": 10.661111111111111,
              "msFromStart": 132734.48800000007
            },
            {
              "lat": 43.7611425111111,
              "lng": -116.10092055555558,
              "alt": 1958.2874444444442,
              "speed2d": 10.434222222222225,
              "speed3d": 10.845555555555556,
              "msFromStart": 133229.8560000001
            },
            {
              "lat": 43.76119188888889,
              "lng": -116.1009308888889,
              "alt": 1956.5926666666667,
              "speed2d": 10.734666666666666,
              "speed3d": 11.152222222222221,
              "msFromStart": 133725.30399999997
            },
            {
              "lat": 43.76124356,
              "lng": -116.10093356,
              "alt": 1954.4455,
              "speed2d": 11.0222,
              "speed3d": 11.418999999999999,
              "msFromStart": 134248.24955555555
            },
            {
              "lat": 43.761307877777774,
              "lng": -116.10092532222224,
              "alt": 1953.3224444444445,
              "speed2d": 11.86288888888889,
              "speed3d": 12.22222222222222,
              "msFromStart": 134770.8560000001
            },
            {
              "lat": 43.761359211111106,
              "lng": -116.10091272222223,
              "alt": 1952.4130000000002,
              "speed2d": 11.51488888888889,
              "speed3d": 11.988888888888889,
              "msFromStart": 135265.95022222228
            },
            {
              "lat": 43.761410166666664,
              "lng": -116.10091152222222,
              "alt": 1950.7713333333334,
              "speed2d": 11.382000000000001,
              "speed3d": 11.761111111111111,
              "msFromStart": 135761.42399999994
            },
            {
              "lat": 43.76147053333334,
              "lng": -116.10092072222223,
              "alt": 1949.3814444444447,
              "speed2d": 11.891222222222222,
              "speed3d": 12.242222222222225,
              "msFromStart": 136256.89777777775
            },
            {
              "lat": 43.76153228888889,
              "lng": -116.10093377777778,
              "alt": 1947.9412222222222,
              "speed2d": 12.235444444444443,
              "speed3d": 12.617777777777777,
              "msFromStart": 136751.9920000001
            },
            {
              "lat": 43.7615886,
              "lng": -116.10093941111111,
              "alt": 1945.7913333333336,
              "speed2d": 12.381888888888888,
              "speed3d": 12.74,
              "msFromStart": 137246.95221312545
            },
            {
              "lat": 43.76164663333333,
              "lng": -116.1009328,
              "alt": 1943.9706666666666,
              "speed2d": 12.611333333333334,
              "speed3d": 13.075555555555555,
              "msFromStart": 137741.47452631572
            },
            {
              "lat": 43.76170792222222,
              "lng": -116.1009252,
              "alt": 1942.9955555555553,
              "speed2d": 12.334333333333333,
              "speed3d": 12.733333333333336,
              "msFromStart": 138235.94797660812
            },
            {
              "lat": 43.761776766666664,
              "lng": -116.10092178888888,
              "alt": 1942.6660000000002,
              "speed2d": 12.516444444444444,
              "speed3d": 12.867777777777777,
              "msFromStart": 138730.65066666662
            },
            {
              "lat": 43.76183874444445,
              "lng": -116.10092514444445,
              "alt": 1940.9534444444444,
              "speed2d": 12.837555555555555,
              "speed3d": 13.184444444444445,
              "msFromStart": 139225.4859259259
            },
            {
              "lat": 43.76190127,
              "lng": -116.10093862000001,
              "alt": 1939.1087,
              "speed2d": 13.0224,
              "speed3d": 13.392,
              "msFromStart": 139748.61688888885
            },
            {
              "lat": 43.76196295555555,
              "lng": -116.1009517222222,
              "alt": 1937.764111111111,
              "speed2d": 12.862777777777776,
              "speed3d": 13.244444444444447,
              "msFromStart": 140271.79180246915
            },
            {
              "lat": 43.7620304,
              "lng": -116.10095808888889,
              "alt": 1937.4498888888888,
              "speed2d": 13.089222222222222,
              "speed3d": 13.39,
              "msFromStart": 140766.54400000008
            },
            {
              "lat": 43.7620988,
              "lng": -116.10095824444443,
              "alt": 1936.807888888889,
              "speed2d": 13.338555555555557,
              "speed3d": 13.634444444444444,
              "msFromStart": 141261.24483950622
            },
            {
              "lat": 43.76216266666667,
              "lng": -116.1009622888889,
              "alt": 1935.3171111111112,
              "speed2d": 13.486000000000002,
              "speed3d": 13.763333333333334,
              "msFromStart": 141756.51200000005
            },
            {
              "lat": 43.76222372222222,
              "lng": -116.1009773222222,
              "alt": 1933.8743333333332,
              "speed2d": 13.688666666666666,
              "speed3d": 14.021111111111114,
              "msFromStart": 142251.8542222223
            },
            {
              "lat": 43.762272588888884,
              "lng": -116.10100435555556,
              "alt": 1932.0133333333333,
              "speed2d": 13.018444444444443,
              "speed3d": 13.469999999999997,
              "msFromStart": 142747.08800000005
            },
            {
              "lat": 43.76230326666666,
              "lng": -116.1010471,
              "alt": 1930.1786666666667,
              "speed2d": 11.428222222222223,
              "speed3d": 11.962222222222222,
              "msFromStart": 143242.31229629632
            },
            {
              "lat": 43.76233646666665,
              "lng": -116.10109134444446,
              "alt": 1928.0584444444446,
              "speed2d": 10.835,
              "speed3d": 11.267777777777779,
              "msFromStart": 143737.61955555555
            },
            {
              "lat": 43.762376655555556,
              "lng": -116.10113513333333,
              "alt": 1926.4611111111108,
              "speed2d": 10.91111111111111,
              "speed3d": 11.356666666666667,
              "msFromStart": 144233.09748148147
            },
            {
              "lat": 43.76241568888889,
              "lng": -116.10116747777776,
              "alt": 1925.1598888888884,
              "speed2d": 10.13188888888889,
              "speed3d": 10.688888888888886,
              "msFromStart": 144730.07466666662
            },
            {
              "lat": 43.762455088888885,
              "lng": -116.1011926,
              "alt": 1923.864111111111,
              "speed2d": 9.673777777777778,
              "speed3d": 10.173333333333334,
              "msFromStart": 145227.23585185182
            },
            {
              "lat": 43.76250399,
              "lng": -116.10121784,
              "alt": 1923.4261999999997,
              "speed2d": 9.781500000000001,
              "speed3d": 10.197000000000001,
              "msFromStart": 145749.9764444445
            },
            {
              "lat": 43.76254863333333,
              "lng": -116.1012528888889,
              "alt": 1922.581777777778,
              "speed2d": 9.754666666666669,
              "speed3d": 10.176666666666666,
              "msFromStart": 146272.20444444453
            },
            {
              "lat": 43.76258046666666,
              "lng": -116.10129248888889,
              "alt": 1920.6536666666664,
              "speed2d": 9.674888888888889,
              "speed3d": 10.11222222222222,
              "msFromStart": 146766.72800000006
            },
            {
              "lat": 43.76261381111111,
              "lng": -116.10133383333333,
              "alt": 1918.6745555555556,
              "speed2d": 9.950666666666667,
              "speed3d": 10.33,
              "msFromStart": 147261.11312800527
            },
            {
              "lat": 43.762651700000006,
              "lng": -116.1013814222222,
              "alt": 1916.7060000000001,
              "speed2d": 10.754666666666667,
              "speed3d": 11.14111111111111,
              "msFromStart": 147754.8707368422
            },
            {
              "lat": 43.762691211111104,
              "lng": -116.10142016666666,
              "alt": 1913.9146666666668,
              "speed2d": 11.089222222222224,
              "speed3d": 11.554444444444446,
              "msFromStart": 148248.5765302145
            },
            {
              "lat": 43.762736999999994,
              "lng": -116.1014379222222,
              "alt": 1911.2784444444446,
              "speed2d": 11.111,
              "speed3d": 11.604444444444445,
              "msFromStart": 148742.79288888891
            },
            {
              "lat": 43.7627882111111,
              "lng": -116.10145653333333,
              "alt": 1909.0915555555557,
              "speed2d": 11.67588888888889,
              "speed3d": 12.134444444444444,
              "msFromStart": 149237.21629629633
            },
            {
              "lat": 43.76284246666666,
              "lng": -116.10147992222221,
              "alt": 1906.8354444444444,
              "speed2d": 12.585111111111113,
              "speed3d": 13.007777777777779,
              "msFromStart": 149732.4337777777
            },
            {
              "lat": 43.76289094444444,
              "lng": -116.10151146666666,
              "alt": 1904.0813333333335,
              "speed2d": 12.894888888888888,
              "speed3d": 13.434444444444443,
              "msFromStart": 150227.91911111106
            },
            {
              "lat": 43.76293623,
              "lng": -116.10155548,
              "alt": 1900.8992999999998,
              "speed2d": 12.8939,
              "speed3d": 13.475,
              "msFromStart": 150751.67288888898
            },
            {
              "lat": 43.76297974444445,
              "lng": -116.10160866666665,
              "alt": 1898.180222222222,
              "speed2d": 12.762666666666668,
              "speed3d": 13.377777777777776,
              "msFromStart": 151275.3362962964
            },
            {
              "lat": 43.76302011111111,
              "lng": -116.10166357777779,
              "alt": 1894.8663333333334,
              "speed2d": 13.197888888888889,
              "speed3d": 13.792222222222223,
              "msFromStart": 151769.81599999993
            },
            {
              "lat": 43.763061933333326,
              "lng": -116.10171284444445,
              "alt": 1891.4450000000002,
              "speed2d": 13.136000000000003,
              "speed3d": 13.944444444444443,
              "msFromStart": 152264.0544041585
            },
            {
              "lat": 43.76310933333333,
              "lng": -116.10174712222222,
              "alt": 1888.8397777777777,
              "speed2d": 12.511333333333333,
              "speed3d": 13.322222222222223,
              "msFromStart": 152758.42947368431
            },
            {
              "lat": 43.76316794444445,
              "lng": -116.10177018888888,
              "alt": 1886.9735555555555,
              "speed2d": 12.777888888888887,
              "speed3d": 13.48777777777778,
              "msFromStart": 153252.91306042895
            },
            {
              "lat": 43.76322818888889,
              "lng": -116.10179346666665,
              "alt": 1884.4505555555556,
              "speed2d": 13.423333333333334,
              "speed3d": 14.094444444444443,
              "msFromStart": 153748.25777777773
            },
            {
              "lat": 43.763285366666665,
              "lng": -116.10182694444447,
              "alt": 1881.7004444444447,
              "speed2d": 14.127222222222223,
              "speed3d": 14.796666666666667,
              "msFromStart": 154243.7727407407
            },
            {
              "lat": 43.763344733333334,
              "lng": -116.10187144444446,
              "alt": 1879.2446666666667,
              "speed2d": 14.859555555555556,
              "speed3d": 15.488888888888887,
              "msFromStart": 154738.83733333333
            },
            {
              "lat": 43.76340487777777,
              "lng": -116.10192097777778,
              "alt": 1877.0182222222224,
              "speed2d": 15.242111111111113,
              "speed3d": 15.936666666666667,
              "msFromStart": 155233.77985185187
            },
            {
              "lat": 43.763464855555554,
              "lng": -116.10196243333333,
              "alt": 1875.413,
              "speed2d": 14.771777777777777,
              "speed3d": 15.530000000000001,
              "msFromStart": 155728.6275555555
            },
            {
              "lat": 43.763526999999996,
              "lng": -116.10199075,
              "alt": 1873.9272,
              "speed2d": 13.6673,
              "speed3d": 14.506999999999998,
              "msFromStart": 156251.0208888888
            },
            {
              "lat": 43.76359478888889,
              "lng": -116.10199712222224,
              "alt": 1873.9691111111113,
              "speed2d": 12.633666666666668,
              "speed3d": 13.402222222222221,
              "msFromStart": 156773.96799999994
            },
            {
              "lat": 43.76364537777778,
              "lng": -116.10199156666665,
              "alt": 1874.000222222222,
              "speed2d": 10.52511111111111,
              "speed3d": 11.628888888888888,
              "msFromStart": 157269.40461078618
            },
            {
              "lat": 43.76367877777778,
              "lng": -116.10198055555556,
              "alt": 1872.8783333333333,
              "speed2d": 8.289555555555555,
              "speed3d": 9.385555555555554,
              "msFromStart": 157764.23494736853
            },
            {
              "lat": 43.76370447777777,
              "lng": -116.10197386666667,
              "alt": 1872.0593333333334,
              "speed2d": 6.226444444444446,
              "speed3d": 7.663333333333334,
              "msFromStart": 158258.93545419115
            },
            {
              "lat": 43.76372216666667,
              "lng": -116.10195872222224,
              "alt": 1872.5407777777775,
              "speed2d": 4.5888888888888895,
              "speed3d": 6.095555555555556,
              "msFromStart": 158753.33155555557
            },
            {
              "lat": 43.763741422222225,
              "lng": -116.10194283333333,
              "alt": 1873.068111111111,
              "speed2d": 4.001222222222222,
              "speed3d": 5.496666666666666,
              "msFromStart": 159247.75703703705
            },
            {
              "lat": 43.76375601111111,
              "lng": -116.10193251111113,
              "alt": 1872.192,
              "speed2d": 3.543666666666667,
              "speed3d": 5.065555555555555,
              "msFromStart": 159743.23733333324
            },
            {
              "lat": 43.76377105555555,
              "lng": -116.10192855555555,
              "alt": 1870.6273333333331,
              "speed2d": 3.2973333333333334,
              "speed3d": 4.918888888888889,
              "msFromStart": 160239.0008888888
            },
            {
              "lat": 43.7637859888889,
              "lng": -116.10193135555554,
              "alt": 1870.0062222222223,
              "speed2d": 2.8307777777777776,
              "speed3d": 4.5200000000000005,
              "msFromStart": 160734.95999999996
            },
            {
              "lat": 43.763811722222215,
              "lng": -116.10192865555555,
              "alt": 1870.8794444444443,
              "speed2d": 3.0404444444444447,
              "speed3d": 4.476666666666667,
              "msFromStart": 161230.84711111107
            },
            {
              "lat": 43.763839399999995,
              "lng": -116.10192326666666,
              "alt": 1872.1532222222224,
              "speed2d": 3.2367777777777778,
              "speed3d": 4.430000000000001,
              "msFromStart": 161725.5253333332
            }
          ]
        }
      ]
    },
    {
      "id": "trail-sourdough",
      "pathType": "trail",
      "name": "Sourdough",
      "difficulty": "blue",
      "videos": [
        {
          "videoId": "GH010034",
          "pathId": "trail-sourdough",
          "startSec": 102,
          "endSec": 126,
          "id": "video-segment-3",
          "gps": [
            {
              "lat": 43.75959075555556,
              "lng": -116.10134193333332,
              "alt": 2015.1436666666668,
              "speed2d": 13.05111111111111,
              "speed3d": 13.700000000000001,
              "msFromStart": 102267.85244444448
            },
            {
              "lat": 43.759628577777775,
              "lng": -116.10141286666668,
              "alt": 2010.7309999999998,
              "speed2d": 13.021777777777778,
              "speed3d": 13.757777777777775,
              "msFromStart": 102761.16800000005
            },
            {
              "lat": 43.75967067777778,
              "lng": -116.10148522222221,
              "alt": 2006.9635555555553,
              "speed2d": 13.165555555555557,
              "speed3d": 13.892222222222223,
              "msFromStart": 103254.91777777781
            },
            {
              "lat": 43.75971941111111,
              "lng": -116.10156028888892,
              "alt": 2004.8651111111108,
              "speed2d": 13.535555555555554,
              "speed3d": 14.236666666666668,
              "msFromStart": 103750.57866666671
            },
            {
              "lat": 43.759771488888894,
              "lng": -116.10163093333333,
              "alt": 2003.0613333333336,
              "speed2d": 13.790999999999999,
              "speed3d": 14.49,
              "msFromStart": 104246.30103703705
            },
            {
              "lat": 43.759822877777786,
              "lng": -116.10169804444445,
              "alt": 2001.5587777777778,
              "speed2d": 13.918111111111111,
              "speed3d": 14.603333333333335,
              "msFromStart": 104741.50488888887
            },
            {
              "lat": 43.759876122222224,
              "lng": -116.10176674444443,
              "alt": 1999.5770000000002,
              "speed2d": 14.08611111111111,
              "speed3d": 14.751111111111111,
              "msFromStart": 105236.80488109162
            },
            {
              "lat": 43.75992137777778,
              "lng": -116.10184316666665,
              "alt": 1997.4534444444446,
              "speed2d": 14.290222222222223,
              "speed3d": 14.953333333333335,
              "msFromStart": 105732.69684210532
            },
            {
              "lat": 43.759962944444446,
              "lng": -116.10192184444445,
              "alt": 1995.038,
              "speed2d": 14.391444444444447,
              "speed3d": 15.057777777777776,
              "msFromStart": 106228.45205458094
            },
            {
              "lat": 43.76000708,
              "lng": -116.10201009,
              "alt": 1991.973,
              "speed2d": 14.528900000000002,
              "speed3d": 15.207,
              "msFromStart": 106750.47666666667
            },
            {
              "lat": 43.760051977777785,
              "lng": -116.10209544444444,
              "alt": 1990.0595555555558,
              "speed2d": 14.624777777777776,
              "speed3d": 15.296666666666665,
              "msFromStart": 107272.36385185184
            },
            {
              "lat": 43.760099833333335,
              "lng": -116.10216664444447,
              "alt": 1988.619222222222,
              "speed2d": 14.578444444444445,
              "speed3d": 15.256666666666668,
              "msFromStart": 107767.02044444442
            },
            {
              "lat": 43.760146633333335,
              "lng": -116.10223472222222,
              "alt": 1986.752,
              "speed2d": 14.44211111111111,
              "speed3d": 15.126666666666665,
              "msFromStart": 108261.85081481481
            },
            {
              "lat": 43.76018394444445,
              "lng": -116.10231303333336,
              "alt": 1985.623,
              "speed2d": 14.431888888888889,
              "speed3d": 15.075555555555557,
              "msFromStart": 108757.28266666665
            },
            {
              "lat": 43.76021482222223,
              "lng": -116.10240207777778,
              "alt": 1984.8455555555556,
              "speed2d": 14.548777777777776,
              "speed3d": 15.193333333333335,
              "msFromStart": 109252.74059259259
            },
            {
              "lat": 43.76021264444445,
              "lng": -116.1024682111111,
              "alt": 1987.8823333333332,
              "speed2d": 13.574555555555555,
              "speed3d": 14.322222222222223,
              "msFromStart": 109748.06222222224
            },
            {
              "lat": 43.760200888888896,
              "lng": -116.10252922222224,
              "alt": 1991.0805555555557,
              "speed2d": 12.506111111111114,
              "speed3d": 13.134444444444444,
              "msFromStart": 110243.50055360624
            },
            {
              "lat": 43.76020545555556,
              "lng": -116.10261241111112,
              "alt": 1991.1265555555558,
              "speed2d": 12.450777777777777,
              "speed3d": 12.945555555555558,
              "msFromStart": 110739.4602105263
            },
            {
              "lat": 43.76022723333333,
              "lng": -116.10268754444444,
              "alt": 1990.2095555555554,
              "speed2d": 12.394888888888888,
              "speed3d": 12.951111111111112,
              "msFromStart": 111235.48042105262
            },
            {
              "lat": 43.76025853333333,
              "lng": -116.10274323333331,
              "alt": 1988.9437777777778,
              "speed2d": 11.949555555555557,
              "speed3d": 12.501111111111111,
              "msFromStart": 111731.55199999995
            },
            {
              "lat": 43.76029186666667,
              "lng": -116.10279933333332,
              "alt": 1987.1646666666666,
              "speed2d": 11.552222222222222,
              "speed3d": 12.146666666666667,
              "msFromStart": 112227.34113580243
            },
            {
              "lat": 43.76033015,
              "lng": -116.10283725000001,
              "alt": 1984.4989,
              "speed2d": 10.6729,
              "speed3d": 11.366,
              "msFromStart": 112748.78511111106
            },
            {
              "lat": 43.76036612222222,
              "lng": -116.10284925555555,
              "alt": 1980.3755555555554,
              "speed2d": 9.423000000000002,
              "speed3d": 10.236666666666665,
              "msFromStart": 113270.7893333333
            },
            {
              "lat": 43.76040057777778,
              "lng": -116.10286356666666,
              "alt": 1977.5225555555555,
              "speed2d": 8.588777777777779,
              "speed3d": 9.434444444444445,
              "msFromStart": 113768.76888888887
            },
            {
              "lat": 43.76043285555556,
              "lng": -116.10289675555555,
              "alt": 1976.1642222222222,
              "speed2d": 8.280444444444445,
              "speed3d": 9.102222222222222,
              "msFromStart": 114265.90222222221
            },
            {
              "lat": 43.76046276666667,
              "lng": -116.10293756666667,
              "alt": 1974.6133333333332,
              "speed2d": 8.169444444444444,
              "speed3d": 8.994444444444444,
              "msFromStart": 114758.28888888887
            },
            {
              "lat": 43.76048251111111,
              "lng": -116.10298492222222,
              "alt": 1973.0463333333332,
              "speed2d": 7.9511111111111115,
              "speed3d": 8.794444444444446,
              "msFromStart": 115250.73975828459
            },
            {
              "lat": 43.76050406666667,
              "lng": -116.10303524444444,
              "alt": 1971.703111111111,
              "speed2d": 7.866999999999999,
              "speed3d": 8.693333333333333,
              "msFromStart": 115745.3461052632
            },
            {
              "lat": 43.7605305,
              "lng": -116.10308375555556,
              "alt": 1970.5118888888887,
              "speed2d": 7.875666666666667,
              "speed3d": 8.684444444444445,
              "msFromStart": 116240.361840156
            },
            {
              "lat": 43.76055965555556,
              "lng": -116.1031304888889,
              "alt": 1969.2189999999998,
              "speed2d": 7.940666666666667,
              "speed3d": 8.735555555555555,
              "msFromStart": 116736.56000000003
            },
            {
              "lat": 43.76059238888888,
              "lng": -116.10316996666668,
              "alt": 1967.4132222222224,
              "speed2d": 7.924888888888888,
              "speed3d": 8.737777777777778,
              "msFromStart": 117232.60434567904
            },
            {
              "lat": 43.76062311666667,
              "lng": -116.10319451666666,
              "alt": 1965.8593333333333,
              "speed2d": 7.812499999999999,
              "speed3d": 8.643333333333333,
              "msFromStart": 117644.26533333334
            },
            {
              "lat": 43.76065383999045,
              "lng": -116.10320500869726,
              "alt": 1964.30262440439,
              "speed2d": 7.730701826313581,
              "speed3d": 8.568513366195212,
              "msFromStart": 118000
            },
            {
              "lat": 43.760697841204575,
              "lng": -116.10321486254365,
              "alt": 1962.1863213923807,
              "speed2d": 7.649976084630565,
              "speed3d": 8.496504488637592,
              "msFromStart": 118500
            },
            {
              "lat": 43.76075783333333,
              "lng": -116.1032271,
              "alt": 1959.402,
              "speed2d": 7.586666666666667,
              "speed3d": 8.433333333333334,
              "msFromStart": 119174.01111111112
            },
            {
              "lat": 43.760812939999994,
              "lng": -116.10323104,
              "alt": 1957.7402000000002,
              "speed2d": 7.9276,
              "speed3d": 8.71,
              "msFromStart": 119727.49439999995
            },
            {
              "lat": 43.76087794,
              "lng": -116.10323894,
              "alt": 1956.2941999999998,
              "speed2d": 8.552200000000001,
              "speed3d": 9.258,
              "msFromStart": 120310.34837894735
            },
            {
              "lat": 43.760939825,
              "lng": -116.10325312500001,
              "alt": 1954.676,
              "speed2d": 9.139,
              "speed3d": 9.8075,
              "msFromStart": 120833.07852631576
            },
            {
              "lat": 43.760985777777776,
              "lng": -116.10328272222225,
              "alt": 1953.3851111111107,
              "speed2d": 9.491,
              "speed3d": 10.158888888888889,
              "msFromStart": 121245.76116439242
            },
            {
              "lat": 43.76102251666667,
              "lng": -116.10332553333335,
              "alt": 1952.0838333333334,
              "speed2d": 9.588833333333334,
              "speed3d": 10.25,
              "msFromStart": 121667.58118518513
            },
            {
              "lat": 43.76104121518558,
              "lng": -116.10337075247226,
              "alt": 1950.7609190475034,
              "speed2d": 9.546212248464704,
              "speed3d": 10.18045076097775,
              "msFromStart": 122000
            },
            {
              "lat": 43.76113925,
              "lng": -116.10345643333332,
              "alt": 1948.6091666666666,
              "speed2d": 10.770833333333334,
              "speed3d": 11.315000000000001,
              "msFromStart": 122794.56022222221
            },
            {
              "lat": 43.761183239999994,
              "lng": -116.10348742,
              "alt": 1947.9238,
              "speed2d": 11.179999999999998,
              "speed3d": 11.709999999999999,
              "msFromStart": 123137.00106666666
            },
            {
              "lat": 43.76128245714286,
              "lng": -116.10352468571429,
              "alt": 1945.1087142857143,
              "speed2d": 11.881,
              "speed3d": 12.395714285714286,
              "msFromStart": 123814.65428571425
            },
            {
              "lat": 43.76134641428571,
              "lng": -116.10354207142858,
              "alt": 1943.698142857143,
              "speed2d": 12.264285714285712,
              "speed3d": 12.762857142857142,
              "msFromStart": 124246.95809523806
            },
            {
              "lat": 43.761402399999994,
              "lng": -116.1035502,
              "alt": 1942.6145,
              "speed2d": 12.52925,
              "speed3d": 13.05,
              "msFromStart": 124627.40800000005
            },
            {
              "lat": 43.76144396887717,
              "lng": -116.10353764159254,
              "alt": 1940.9524438325898,
              "speed2d": 12.472019352477249,
              "speed3d": 13.027249364962412,
              "msFromStart": 125000
            },
            {
              "lat": 43.76153405714285,
              "lng": -116.10351132857143,
              "alt": 1937.380714285714,
              "speed2d": 12.469857142857142,
              "speed3d": 13.01,
              "msFromStart": 125792.7975939849
            }
          ]
        }
      ]
    },
    {
      "id": "trail-stewarts-bowl",
      "pathType": "trail",
      "name": "Stewarts Bowl",
      "difficulty": "blue",
      "videos": [
        {
          "videoId": "GH010034",
          "pathId": "trail-stewarts-bowl",
          "startSec": 126,
          "endSec": 150,
          "id": "video-segment-4",
          "gps": [
            {
              "lat": 43.76157134,
              "lng": -116.10349762,
              "alt": 1936.1298000000002,
              "speed2d": 12.3774,
              "speed3d": 12.940000000000001,
              "msFromStart": 126182.44169356718
            },
            {
              "lat": 43.761635275,
              "lng": -116.10347285,
              "alt": 1934.13675,
              "speed2d": 12.215,
              "speed3d": 12.777499999999998,
              "msFromStart": 126881.90355555556
            },
            {
              "lat": 43.76167121666666,
              "lng": -116.10346453333334,
              "alt": 1933.3231666666666,
              "speed2d": 12.048833333333334,
              "speed3d": 12.639999999999999,
              "msFromStart": 127295.0442962963
            },
            {
              "lat": 43.76170773333333,
              "lng": -116.10347060000001,
              "alt": 1932.7420000000002,
              "speed2d": 11.934000000000001,
              "speed3d": 12.456666666666667,
              "msFromStart": 127643.75022222221
            },
            {
              "lat": 43.76174516025708,
              "lng": -116.10348418843819,
              "alt": 1931.7129506910412,
              "speed2d": 11.892080648485647,
              "speed3d": 12.41370087843104,
              "msFromStart": 128000
            },
            {
              "lat": 43.76178875374398,
              "lng": -116.10350023512048,
              "alt": 1930.0927706686616,
              "speed2d": 11.710753138552752,
              "speed3d": 12.250559608432416,
              "msFromStart": 128500
            },
            {
              "lat": 43.76183234723088,
              "lng": -116.10351628180277,
              "alt": 1928.4725906462818,
              "speed2d": 11.529425628619856,
              "speed3d": 12.087418338433794,
              "msFromStart": 129000
            },
            {
              "lat": 43.761903833333335,
              "lng": -116.1035364,
              "alt": 1925.7363333333335,
              "speed2d": 11.278666666666666,
              "speed3d": 11.87,
              "msFromStart": 129815.84577777774
            },
            {
              "lat": 43.7619426625,
              "lng": -116.103530275,
              "alt": 1924.117375,
              "speed2d": 11.178125,
              "speed3d": 11.770000000000001,
              "msFromStart": 130271.74399999998
            },
            {
              "lat": 43.761981285714285,
              "lng": -116.10351712857143,
              "alt": 1922.5967142857141,
              "speed2d": 11.132,
              "speed3d": 11.727142857142859,
              "msFromStart": 130720.27885714278
            },
            {
              "lat": 43.76203018,
              "lng": -116.10350366,
              "alt": 1921.0430000000001,
              "speed2d": 11.2194,
              "speed3d": 11.770000000000001,
              "msFromStart": 131221.22399999993
            },
            {
              "lat": 43.76208953333333,
              "lng": -116.10349924444445,
              "alt": 1919.4495555555554,
              "speed2d": 11.441111111111113,
              "speed3d": 11.994444444444444,
              "msFromStart": 131749.05599999995
            },
            {
              "lat": 43.76213316666667,
              "lng": -116.10350166666666,
              "alt": 1918.17,
              "speed2d": 11.528666666666666,
              "speed3d": 12.079999999999998,
              "msFromStart": 132152.34607407404
            },
            {
              "lat": 43.76219235,
              "lng": -116.1035045,
              "alt": 1916.356,
              "speed2d": 11.629,
              "speed3d": 12.17,
              "msFromStart": 132712.22044444454
            },
            {
              "lat": 43.762247466666665,
              "lng": -116.10349796666667,
              "alt": 1914.2288333333333,
              "speed2d": 11.786666666666667,
              "speed3d": 12.315000000000001,
              "msFromStart": 133207.8102222223
            },
            {
              "lat": 43.76232386666666,
              "lng": -116.10348713333333,
              "alt": 1911.4876666666669,
              "speed2d": 12.203166666666668,
              "speed3d": 12.696666666666667,
              "msFromStart": 133738.22214814805
            },
            {
              "lat": 43.762424300000006,
              "lng": -116.103463575,
              "alt": 1907.78175,
              "speed2d": 12.923,
              "speed3d": 13.355,
              "msFromStart": 134360.45222222217
            },
            {
              "lat": 43.7624617,
              "lng": -116.10345465,
              "alt": 1906.5685,
              "speed2d": 13.1995,
              "speed3d": 13.665,
              "msFromStart": 134580.7431111111
            },
            {
              "lat": 43.76252257272558,
              "lng": -116.10344474778644,
              "alt": 1904.649419805776,
              "speed2d": 13.446872358242373,
              "speed3d": 13.910996067057921,
              "msFromStart": 135000
            },
            {
              "lat": 43.762627675000005,
              "lng": -116.10342705,
              "alt": 1901.2955,
              "speed2d": 13.8575,
              "speed3d": 14.325,
              "msFromStart": 135736.45600000003
            },
            {
              "lat": 43.76268685,
              "lng": -116.1034027,
              "alt": 1899.0500000000002,
              "speed2d": 14.097000000000001,
              "speed3d": 14.565000000000001,
              "msFromStart": 136231.18498245618
            },
            {
              "lat": 43.76275135,
              "lng": -116.1033762,
              "alt": 1896.839,
              "speed2d": 14.3945,
              "speed3d": 14.84,
              "msFromStart": 136726.74044444453
            },
            {
              "lat": 43.76283318888889,
              "lng": -116.10335316666666,
              "alt": 1894.8361111111112,
              "speed2d": 14.826111111111112,
              "speed3d": 15.285555555555554,
              "msFromStart": 137250.0007901235
            },
            {
              "lat": 43.762880775,
              "lng": -116.10334342499999,
              "alt": 1893.4070000000002,
              "speed2d": 14.8595,
              "speed3d": 15.317499999999999,
              "msFromStart": 137607.42444444445
            },
            {
              "lat": 43.76296455000001,
              "lng": -116.103336825,
              "alt": 1891.0805,
              "speed2d": 14.961000000000002,
              "speed3d": 15.4225,
              "msFromStart": 138198.29
            },
            {
              "lat": 43.76305514285715,
              "lng": -116.10332727142858,
              "alt": 1889.1112857142857,
              "speed2d": 15.371142857142857,
              "speed3d": 15.772857142857145,
              "msFromStart": 138757.58780952377
            },
            {
              "lat": 43.76313302222221,
              "lng": -116.10331174444443,
              "alt": 1887.4074444444445,
              "speed2d": 15.805666666666665,
              "speed3d": 16.212222222222223,
              "msFromStart": 139228.55387654318
            },
            {
              "lat": 43.7632101,
              "lng": -116.1032841,
              "alt": 1885.2859000000003,
              "speed2d": 16.1183,
              "speed3d": 16.532,
              "msFromStart": 139750.60488888898
            },
            {
              "lat": 43.76327874444445,
              "lng": -116.10325316666666,
              "alt": 1883.0126666666667,
              "speed2d": 16.21,
              "speed3d": 16.655555555555555,
              "msFromStart": 140273.00207407415
            },
            {
              "lat": 43.76331211111111,
              "lng": -116.10322193333334,
              "alt": 1879.951111111111,
              "speed2d": 15.887666666666666,
              "speed3d": 16.391111111111112,
              "msFromStart": 140769.26755555568
            },
            {
              "lat": 43.76333814444445,
              "lng": -116.10320192222224,
              "alt": 1876.693888888889,
              "speed2d": 15.404555555555556,
              "speed3d": 15.922222222222224,
              "msFromStart": 141265.30373489283
            },
            {
              "lat": 43.763383033333334,
              "lng": -116.10321,
              "alt": 1873.7022222222224,
              "speed2d": 15.070777777777778,
              "speed3d": 15.597777777777779,
              "msFromStart": 141759.8846315789
            },
            {
              "lat": 43.76342342222222,
              "lng": -116.10322141111112,
              "alt": 1870.908777777778,
              "speed2d": 14.697333333333333,
              "speed3d": 15.240000000000002,
              "msFromStart": 142254.41242365167
            },
            {
              "lat": 43.763458414285715,
              "lng": -116.10323095714287,
              "alt": 1868.4444285714285,
              "speed2d": 14.444714285714287,
              "speed3d": 14.982857142857142,
              "msFromStart": 142694.48355555552
            },
            {
              "lat": 43.763485261443506,
              "lng": -116.10323231182075,
              "alt": 1867.1661951705046,
              "speed2d": 14.292257043314486,
              "speed3d": 14.825033719158963,
              "msFromStart": 143000
            },
            {
              "lat": 43.76353211428994,
              "lng": -116.10322594615977,
              "alt": 1865.9138205623035,
              "speed2d": 13.979904733771013,
              "speed3d": 14.522565976373196,
              "msFromStart": 143500
            },
            {
              "lat": 43.76357896713637,
              "lng": -116.10321958049877,
              "alt": 1864.6614459541022,
              "speed2d": 13.66755242422754,
              "speed3d": 14.220098233587429,
              "msFromStart": 144000
            },
            {
              "lat": 43.7636258199828,
              "lng": -116.10321321483778,
              "alt": 1863.4090713459009,
              "speed2d": 13.355200114684068,
              "speed3d": 13.917630490801661,
              "msFromStart": 144500
            },
            {
              "lat": 43.76367265535775,
              "lng": -116.10320589715431,
              "alt": 1861.8366522930548,
              "speed2d": 12.985750112055115,
              "speed3d": 13.568101379296317,
              "msFromStart": 145000
            },
            {
              "lat": 43.7637347,
              "lng": -116.103199,
              "alt": 1860.7359999999999,
              "speed2d": 12.664,
              "speed3d": 13.245000000000001,
              "msFromStart": 145664.29389473674
            },
            {
              "lat": 43.76379367020822,
              "lng": -116.10319930112121,
              "alt": 1859.878072610785,
              "speed2d": 13.163221035771645,
              "speed3d": 14.025493860117624,
              "msFromStart": 146000
            },
            {
              "lat": 43.7637714375,
              "lng": -116.1031699625,
              "alt": 1859.9122500000003,
              "speed2d": 10.606625,
              "speed3d": 11.3875,
              "msFromStart": 146792.45466666654
            },
            {
              "lat": 43.763770425000004,
              "lng": -116.103149225,
              "alt": 1859.56,
              "speed2d": 9.423749999999998,
              "speed3d": 10.2225,
              "msFromStart": 147314.63333333327
            },
            {
              "lat": 43.763777624999996,
              "lng": -116.103133725,
              "alt": 1859.05125,
              "speed2d": 8.81975,
              "speed3d": 9.614999999999998,
              "msFromStart": 147700.24533333327
            },
            {
              "lat": 43.76379763333333,
              "lng": -116.1031156,
              "alt": 1858.2916666666665,
              "speed2d": 8.435,
              "speed3d": 9.209999999999999,
              "msFromStart": 148177.72770370363
            },
            {
              "lat": 43.76382805,
              "lng": -116.1030995,
              "alt": 1857.382,
              "speed2d": 8.2335,
              "speed3d": 9.010000000000002,
              "msFromStart": 148691.07555555564
            },
            {
              "lat": 43.763865933333335,
              "lng": -116.1030892,
              "alt": 1856.7376666666669,
              "speed2d": 8.175333333333334,
              "speed3d": 8.91,
              "msFromStart": 149222.55377777785
            },
            {
              "lat": 43.76390525000001,
              "lng": -116.1030811,
              "alt": 1856.65075,
              "speed2d": 8.235,
              "speed3d": 8.967500000000001,
              "msFromStart": 149721.9744444445
            }
          ]
        }
      ]
    },
    {
      "id": "trail-smuggler",
      "pathType": "trail",
      "name": "Smuggler",
      "difficulty": "blue",
      "videos": [
        {
          "videoId": "GX010064",
          "pathId": "trail-smuggler",
          "startSec": 10,
          "endSec": 171,
          "id": "video-segment-24",
          "gps": [
            {
              "lat": 43.771852277777775,
              "lng": -116.1022354,
              "alt": 2056.0045555555557,
              "speed2d": 4.986777777777777,
              "speed3d": 4.995555555555556,
              "msFromStart": 10266.935245614039
            },
            {
              "lat": 43.77188127777778,
              "lng": -116.10223684444443,
              "alt": 2055.606888888889,
              "speed2d": 5.366111111111111,
              "speed3d": 5.344444444444445,
              "msFromStart": 10762.352000000003
            },
            {
              "lat": 43.77191128888889,
              "lng": -116.10223738888888,
              "alt": 2055.237111111111,
              "speed2d": 5.764222222222222,
              "speed3d": 5.783333333333333,
              "msFromStart": 11257.315666666667
            },
            {
              "lat": 43.77193374444444,
              "lng": -116.10223586666666,
              "alt": 2054.833888888889,
              "speed2d": 5.676111111111112,
              "speed3d": 5.737777777777778,
              "msFromStart": 11751.056999999992
            },
            {
              "lat": 43.77195345555555,
              "lng": -116.10223235555554,
              "alt": 2054.727555555555,
              "speed2d": 5.526888888888889,
              "speed3d": 5.550000000000001,
              "msFromStart": 12245.327962962961
            },
            {
              "lat": 43.77198311111111,
              "lng": -116.10222572222222,
              "alt": 2054.4846666666667,
              "speed2d": 6.045222222222223,
              "speed3d": 6.002222222222222,
              "msFromStart": 12741.211111111119
            },
            {
              "lat": 43.7720190888889,
              "lng": -116.10221832222221,
              "alt": 2053.9212222222222,
              "speed2d": 6.741555555555555,
              "speed3d": 6.673333333333333,
              "msFromStart": 13236.928666666672
            },
            {
              "lat": 43.77206634444445,
              "lng": -116.10221557777777,
              "alt": 2053.329777777778,
              "speed2d": 7.690222222222222,
              "speed3d": 7.6288888888888895,
              "msFromStart": 13731.966444444452
            },
            {
              "lat": 43.77211413333333,
              "lng": -116.10221259999999,
              "alt": 2052.5355555555557,
              "speed2d": 8.58911111111111,
              "speed3d": 8.535555555555554,
              "msFromStart": 14226.669013645223
            },
            {
              "lat": 43.77216474,
              "lng": -116.10220466999999,
              "alt": 2051.536,
              "speed2d": 9.300799999999999,
              "speed3d": 9.271,
              "msFromStart": 14747.99478947368
            },
            {
              "lat": 43.77221978888889,
              "lng": -116.1021966,
              "alt": 2050.259555555556,
              "speed2d": 10.191777777777776,
              "speed3d": 10.102222222222222,
              "msFromStart": 15269.873812865497
            },
            {
              "lat": 43.77228927777778,
              "lng": -116.10218818888889,
              "alt": 2049.319,
              "speed2d": 11.849555555555556,
              "speed3d": 11.709999999999999,
              "msFromStart": 15765.934666666673
            },
            {
              "lat": 43.772366633333334,
              "lng": -116.10217797777776,
              "alt": 2048.978111111111,
              "speed2d": 13.451444444444444,
              "speed3d": 13.344444444444445,
              "msFromStart": 16262.021814814816
            },
            {
              "lat": 43.77244588888889,
              "lng": -116.10216825555555,
              "alt": 2048.4726666666666,
              "speed2d": 14.706333333333333,
              "speed3d": 14.638888888888891,
              "msFromStart": 16757.94922222222
            },
            {
              "lat": 43.772525977777775,
              "lng": -116.10215555555556,
              "alt": 2047.383888888889,
              "speed2d": 15.829777777777778,
              "speed3d": 15.73777777777778,
              "msFromStart": 17253.3102962963
            },
            {
              "lat": 43.77260514444445,
              "lng": -116.10214057777779,
              "alt": 2046.688222222222,
              "speed2d": 16.689444444444444,
              "speed3d": 16.705555555555556,
              "msFromStart": 17747.157000000014
            },
            {
              "lat": 43.77267605555556,
              "lng": -116.10212227777777,
              "alt": 2045.3343333333332,
              "speed2d": 16.89622222222222,
              "speed3d": 16.92888888888889,
              "msFromStart": 18241.303296296304
            },
            {
              "lat": 43.772748299999996,
              "lng": -116.10210347777776,
              "alt": 2044.2796666666668,
              "speed2d": 17.118111111111112,
              "speed3d": 17.14,
              "msFromStart": 18736.479111111115
            },
            {
              "lat": 43.772821377777774,
              "lng": -116.10208727777777,
              "alt": 2043.0810000000001,
              "speed2d": 17.31722222222222,
              "speed3d": 17.39888888888889,
              "msFromStart": 19231.860463937624
            },
            {
              "lat": 43.77289087777778,
              "lng": -116.10207253333333,
              "alt": 2042.4043333333334,
              "speed2d": 16.940444444444445,
              "speed3d": 17.043333333333333,
              "msFromStart": 19727.65263157895
            },
            {
              "lat": 43.77296202,
              "lng": -116.10205823,
              "alt": 2041.6129999999998,
              "speed2d": 16.2706,
              "speed3d": 16.397,
              "msFromStart": 20250.71558070175
            },
            {
              "lat": 43.77303355555555,
              "lng": -116.10204814444444,
              "alt": 2040.4653333333333,
              "speed2d": 15.788444444444446,
              "speed3d": 15.925555555555556,
              "msFromStart": 20772.819888888873
            },
            {
              "lat": 43.77310145555556,
              "lng": -116.1020390888889,
              "alt": 2039.9487777777774,
              "speed2d": 15.346666666666666,
              "speed3d": 15.44111111111111,
              "msFromStart": 21267.511888888886
            },
            {
              "lat": 43.773171399999995,
              "lng": -116.1020308,
              "alt": 2040.0517777777773,
              "speed2d": 14.814444444444444,
              "speed3d": 14.934444444444445,
              "msFromStart": 21762.60288888889
            },
            {
              "lat": 43.773243699999995,
              "lng": -116.10202245555556,
              "alt": 2040.6037777777779,
              "speed2d": 14.67411111111111,
              "speed3d": 14.724444444444446,
              "msFromStart": 22257.624925925924
            },
            {
              "lat": 43.77331072222223,
              "lng": -116.1020152,
              "alt": 2040.668,
              "speed2d": 14.376111111111111,
              "speed3d": 14.497777777777777,
              "msFromStart": 22752.402777777785
            },
            {
              "lat": 43.773371177777776,
              "lng": -116.10201042222221,
              "alt": 2040.7584444444446,
              "speed2d": 13.612111111111108,
              "speed3d": 13.714444444444446,
              "msFromStart": 23247.16700000001
            },
            {
              "lat": 43.77343181111111,
              "lng": -116.10200898888887,
              "alt": 2040.4926666666668,
              "speed2d": 13.195222222222224,
              "speed3d": 13.286666666666665,
              "msFromStart": 23741.929111111123
            },
            {
              "lat": 43.773489088888894,
              "lng": -116.10200723333332,
              "alt": 2039.9361111111111,
              "speed2d": 12.927777777777779,
              "speed3d": 12.998888888888887,
              "msFromStart": 24236.686744639377
            },
            {
              "lat": 43.77354401111111,
              "lng": -116.10200736666668,
              "alt": 2039.3598888888891,
              "speed2d": 12.583333333333332,
              "speed3d": 12.68,
              "msFromStart": 24731.43252631579
            },
            {
              "lat": 43.77360143333333,
              "lng": -116.10200724444444,
              "alt": 2039.3429999999998,
              "speed2d": 12.146777777777778,
              "speed3d": 12.22111111111111,
              "msFromStart": 25226.30561793372
            },
            {
              "lat": 43.77366199,
              "lng": -116.10200732999999,
              "alt": 2039.4973,
              "speed2d": 11.7533,
              "speed3d": 11.850999999999999,
              "msFromStart": 25749.214666666667
            },
            {
              "lat": 43.773721244444445,
              "lng": -116.10200768888889,
              "alt": 2039.5115555555556,
              "speed2d": 11.500555555555556,
              "speed3d": 11.555555555555557,
              "msFromStart": 26272.079222222226
            },
            {
              "lat": 43.77377676666667,
              "lng": -116.10200776666667,
              "alt": 2039.671777777778,
              "speed2d": 11.192555555555556,
              "speed3d": 11.28,
              "msFromStart": 26767.159888888884
            },
            {
              "lat": 43.773823033333336,
              "lng": -116.10200802222222,
              "alt": 2039.1165555555558,
              "speed2d": 10.901,
              "speed3d": 10.95,
              "msFromStart": 27262.218074074073
            },
            {
              "lat": 43.77386813333334,
              "lng": -116.10200464444445,
              "alt": 2038.8762222222222,
              "speed2d": 10.870222222222223,
              "speed3d": 10.934444444444445,
              "msFromStart": 27757.25233333334
            },
            {
              "lat": 43.7739098,
              "lng": -116.1019965888889,
              "alt": 2038.7685555555556,
              "speed2d": 10.601888888888888,
              "speed3d": 10.646666666666665,
              "msFromStart": 28252.26925925926
            },
            {
              "lat": 43.77395143333333,
              "lng": -116.10198450000001,
              "alt": 2038.4482222222223,
              "speed2d": 10.321444444444445,
              "speed3d": 10.414444444444445,
              "msFromStart": 28747.242555555564
            },
            {
              "lat": 43.773989722222225,
              "lng": -116.10196943333334,
              "alt": 2038.0368888888888,
              "speed2d": 9.843555555555554,
              "speed3d": 9.91,
              "msFromStart": 29242.265530214427
            },
            {
              "lat": 43.774030311111105,
              "lng": -116.10195247777779,
              "alt": 2037.4122222222222,
              "speed2d": 9.800666666666668,
              "speed3d": 9.831111111111111,
              "msFromStart": 29737.42957894736
            },
            {
              "lat": 43.77407013333334,
              "lng": -116.10193068888888,
              "alt": 2036.7464444444445,
              "speed2d": 9.920666666666667,
              "speed3d": 9.934444444444445,
              "msFromStart": 30232.661705653023
            },
            {
              "lat": 43.77411085555556,
              "lng": -116.10190633333335,
              "alt": 2035.847,
              "speed2d": 10.190555555555557,
              "speed3d": 10.232222222222221,
              "msFromStart": 30728.136444444463
            },
            {
              "lat": 43.77415254,
              "lng": -116.10187979,
              "alt": 2035.2532999999999,
              "speed2d": 10.180100000000001,
              "speed3d": 10.200999999999999,
              "msFromStart": 31250.80313333334
            },
            {
              "lat": 43.77419612222222,
              "lng": -116.10184974444446,
              "alt": 2034.5584444444444,
              "speed2d": 10.381999999999998,
              "speed3d": 10.42222222222222,
              "msFromStart": 31772.303999999982
            },
            {
              "lat": 43.77423672222223,
              "lng": -116.10182355555555,
              "alt": 2033.9521111111108,
              "speed2d": 10.378555555555558,
              "speed3d": 10.428888888888888,
              "msFromStart": 32266.75414814814
            },
            {
              "lat": 43.774279444444446,
              "lng": -116.10180557777778,
              "alt": 2033.0901111111114,
              "speed2d": 10.33488888888889,
              "speed3d": 10.387777777777778,
              "msFromStart": 32762.54555555555
            },
            {
              "lat": 43.774324455555565,
              "lng": -116.1017904111111,
              "alt": 2032.3432222222223,
              "speed2d": 10.41622222222222,
              "speed3d": 10.465555555555556,
              "msFromStart": 33258.68340740741
            },
            {
              "lat": 43.77436866666667,
              "lng": -116.10177484444445,
              "alt": 2032.0076666666669,
              "speed2d": 10.397222222222222,
              "speed3d": 10.444444444444443,
              "msFromStart": 33755.57
            },
            {
              "lat": 43.774414944444445,
              "lng": -116.10175414444444,
              "alt": 2031.2676666666666,
              "speed2d": 10.761666666666667,
              "speed3d": 10.771111111111109,
              "msFromStart": 34252.24522807017
            },
            {
              "lat": 43.77445705555556,
              "lng": -116.10173001111112,
              "alt": 2030.3965555555558,
              "speed2d": 10.754777777777779,
              "speed3d": 10.835555555555556,
              "msFromStart": 34748.23978947366
            },
            {
              "lat": 43.77449753333333,
              "lng": -116.10170198888892,
              "alt": 2029.8425555555557,
              "speed2d": 10.661888888888889,
              "speed3d": 10.726666666666668,
              "msFromStart": 35243.97690838204
            },
            {
              "lat": 43.77453717777778,
              "lng": -116.10167554444445,
              "alt": 2029.1205555555557,
              "speed2d": 10.367555555555553,
              "speed3d": 10.45777777777778,
              "msFromStart": 35738.82755555552
            },
            {
              "lat": 43.77457746666666,
              "lng": -116.10165137777778,
              "alt": 2028.4723333333332,
              "speed2d": 10.051,
              "speed3d": 10.149999999999999,
              "msFromStart": 36233.83437037035
            },
            {
              "lat": 43.77461505555555,
              "lng": -116.1016319,
              "alt": 2028.0054444444443,
              "speed2d": 9.63777777777778,
              "speed3d": 9.743333333333332,
              "msFromStart": 36729.82044444445
            },
            {
              "lat": 43.77465857999999,
              "lng": -116.10161382000001,
              "alt": 2027.8043,
              "speed2d": 9.5726,
              "speed3d": 9.607999999999999,
              "msFromStart": 37252.902799999996
            },
            {
              "lat": 43.77470538888889,
              "lng": -116.10159708888887,
              "alt": 2026.9915555555556,
              "speed2d": 9.923222222222224,
              "speed3d": 9.93,
              "msFromStart": 37774.165777777795
            },
            {
              "lat": 43.774748988888895,
              "lng": -116.10158423333334,
              "alt": 2026.1986666666667,
              "speed2d": 10.01522222222222,
              "speed3d": 10.091111111111113,
              "msFromStart": 38267.98281481481
            },
            {
              "lat": 43.774790044444444,
              "lng": -116.10157291111113,
              "alt": 2025.7573333333332,
              "speed2d": 9.721666666666668,
              "speed3d": 9.823333333333332,
              "msFromStart": 38762.18266666663
            },
            {
              "lat": 43.774825811111114,
              "lng": -116.10156316666666,
              "alt": 2025.2264444444443,
              "speed2d": 9.062888888888889,
              "speed3d": 9.176666666666666,
              "msFromStart": 39256.669730994145
            },
            {
              "lat": 43.77486324444445,
              "lng": -116.10155352222223,
              "alt": 2024.9495555555554,
              "speed2d": 8.866111111111111,
              "speed3d": 8.923333333333334,
              "msFromStart": 39751.88168421056
            },
            {
              "lat": 43.77489985555556,
              "lng": -116.10154432222221,
              "alt": 2024.8157777777776,
              "speed2d": 8.611777777777778,
              "speed3d": 8.700000000000001,
              "msFromStart": 40246.80288109162
            },
            {
              "lat": 43.77493501111111,
              "lng": -116.10153673333333,
              "alt": 2024.2511111111112,
              "speed2d": 8.320333333333332,
              "speed3d": 8.402222222222223,
              "msFromStart": 40740.40844444443
            },
            {
              "lat": 43.77496757777778,
              "lng": -116.10152997777777,
              "alt": 2023.8288888888887,
              "speed2d": 7.8952222222222215,
              "speed3d": 7.986666666666666,
              "msFromStart": 41234.2842962963
            },
            {
              "lat": 43.774997533333334,
              "lng": -116.10152397777779,
              "alt": 2023.483444444444,
              "speed2d": 7.472777777777779,
              "speed3d": 7.526666666666667,
              "msFromStart": 41729.76755555558
            },
            {
              "lat": 43.77503115555555,
              "lng": -116.10151851111112,
              "alt": 2023.0045555555555,
              "speed2d": 7.630222222222223,
              "speed3d": 7.644444444444444,
              "msFromStart": 42225.24229629631
            },
            {
              "lat": 43.77507069,
              "lng": -116.10151266999999,
              "alt": 2022.6134000000002,
              "speed2d": 7.984500000000001,
              "speed3d": 7.976000000000001,
              "msFromStart": 42747.52500000001
            },
            {
              "lat": 43.77511254444444,
              "lng": -116.10150585555556,
              "alt": 2022.5942222222222,
              "speed2d": 8.109666666666667,
              "speed3d": 8.137777777777778,
              "msFromStart": 43270.05370370371
            },
            {
              "lat": 43.77515242222222,
              "lng": -116.10149888888887,
              "alt": 2022.4736666666668,
              "speed2d": 8.263111111111112,
              "speed3d": 8.28,
              "msFromStart": 43765.948888888866
            },
            {
              "lat": 43.77519413333333,
              "lng": -116.10149142222224,
              "alt": 2022.4517777777778,
              "speed2d": 8.426777777777778,
              "speed3d": 8.42,
              "msFromStart": 44261.72476413254
            },
            {
              "lat": 43.77523194444444,
              "lng": -116.10148614444445,
              "alt": 2022.1306666666665,
              "speed2d": 8.238444444444445,
              "speed3d": 8.307777777777778,
              "msFromStart": 44757.05515789471
            },
            {
              "lat": 43.77526547777777,
              "lng": -116.10148274444444,
              "alt": 2021.4024444444442,
              "speed2d": 7.846111111111111,
              "speed3d": 7.907777777777778,
              "msFromStart": 45252.24341130603
            },
            {
              "lat": 43.775301088888895,
              "lng": -116.10147851111111,
              "alt": 2020.936666666667,
              "speed2d": 7.743444444444446,
              "speed3d": 7.785555555555557,
              "msFromStart": 45746.95688888891
            },
            {
              "lat": 43.77533646666667,
              "lng": -116.10147555555555,
              "alt": 2020.5581111111112,
              "speed2d": 7.742000000000001,
              "speed3d": 7.753333333333334,
              "msFromStart": 46241.748814814826
            },
            {
              "lat": 43.77537292222222,
              "lng": -116.101475,
              "alt": 2019.4104444444442,
              "speed2d": 8.000555555555557,
              "speed3d": 7.993333333333334,
              "msFromStart": 46737.04444444443
            },
            {
              "lat": 43.775413,
              "lng": -116.10147576666667,
              "alt": 2018.1753333333336,
              "speed2d": 8.394,
              "speed3d": 8.363333333333333,
              "msFromStart": 47232.11377777777
            },
            {
              "lat": 43.77545931111111,
              "lng": -116.10147690000001,
              "alt": 2017.246333333333,
              "speed2d": 9.12588888888889,
              "speed3d": 9.126666666666665,
              "msFromStart": 47726.07644444445
            },
            {
              "lat": 43.77550729000001,
              "lng": -116.10148193000002,
              "alt": 2016.32,
              "speed2d": 9.532699999999998,
              "speed3d": 9.524999999999999,
              "msFromStart": 48247.594733333346
            },
            {
              "lat": 43.77555437777778,
              "lng": -116.10148999999998,
              "alt": 2015.1732222222222,
              "speed2d": 9.795222222222222,
              "speed3d": 9.824444444444445,
              "msFromStart": 48769.85800000004
            },
            {
              "lat": 43.775602899999996,
              "lng": -116.10150172222222,
              "alt": 2013.9996666666668,
              "speed2d": 10.338444444444445,
              "speed3d": 10.326666666666668,
              "msFromStart": 49264.57835087721
            },
            {
              "lat": 43.77565293333334,
              "lng": -116.10151910000002,
              "alt": 2013.1711111111113,
              "speed2d": 10.772888888888888,
              "speed3d": 10.78111111111111,
              "msFromStart": 49758.97936842107
            },
            {
              "lat": 43.77570415555555,
              "lng": -116.1015425111111,
              "alt": 2012.5464444444447,
              "speed2d": 11.395,
              "speed3d": 11.347777777777777,
              "msFromStart": 50253.45650292399
            },
            {
              "lat": 43.77575534444445,
              "lng": -116.1015686,
              "alt": 2011.9996666666668,
              "speed2d": 11.903555555555556,
              "speed3d": 11.95,
              "msFromStart": 50748.305333333316
            },
            {
              "lat": 43.775793211111115,
              "lng": -116.10159048888886,
              "alt": 2010.3194444444443,
              "speed2d": 11.164777777777777,
              "speed3d": 11.307777777777776,
              "msFromStart": 51243.40837037034
            },
            {
              "lat": 43.7758298,
              "lng": -116.10161856666664,
              "alt": 2008.6286666666665,
              "speed2d": 10.744111111111112,
              "speed3d": 10.808888888888887,
              "msFromStart": 51739.37955555553
            },
            {
              "lat": 43.77587105555556,
              "lng": -116.1016518888889,
              "alt": 2007.6015555555555,
              "speed2d": 10.852444444444444,
              "speed3d": 10.904444444444445,
              "msFromStart": 52235.35977777776
            },
            {
              "lat": 43.775909122222224,
              "lng": -116.10168972222223,
              "alt": 2006.6573333333333,
              "speed2d": 10.783999999999999,
              "speed3d": 10.877777777777776,
              "msFromStart": 52731.028888888904
            },
            {
              "lat": 43.775944233333334,
              "lng": -116.10172613333336,
              "alt": 2004.9434444444444,
              "speed2d": 10.524555555555555,
              "speed3d": 10.606666666666667,
              "msFromStart": 53226.5505925926
            },
            {
              "lat": 43.77598225,
              "lng": -116.10176787999998,
              "alt": 2003.3205000000003,
              "speed2d": 10.6816,
              "speed3d": 10.729999999999999,
              "msFromStart": 53749.10766666664
            },
            {
              "lat": 43.77602157777778,
              "lng": -116.10181498888889,
              "alt": 2002.2227777777775,
              "speed2d": 10.908888888888889,
              "speed3d": 10.981111111111112,
              "msFromStart": 54271.95212085768
            },
            {
              "lat": 43.77605871111111,
              "lng": -116.10186106666666,
              "alt": 2000.6529999999998,
              "speed2d": 11.113777777777779,
              "speed3d": 11.185555555555556,
              "msFromStart": 54768.20231578943
            },
            {
              "lat": 43.77609438888889,
              "lng": -116.10190687777778,
              "alt": 1998.6294444444445,
              "speed2d": 11.316444444444443,
              "speed3d": 11.403333333333332,
              "msFromStart": 55264.05282261207
            },
            {
              "lat": 43.77613306666667,
              "lng": -116.10195235555557,
              "alt": 1996.7422222222222,
              "speed2d": 11.686666666666666,
              "speed3d": 11.761111111111113,
              "msFromStart": 55758.1137777778
            },
            {
              "lat": 43.776177322222225,
              "lng": -116.10199634444446,
              "alt": 1995.172111111111,
              "speed2d": 12.274000000000001,
              "speed3d": 12.373333333333333,
              "msFromStart": 56252.2794814815
            },
            {
              "lat": 43.77621971111111,
              "lng": -116.10203487777778,
              "alt": 1993.194111111111,
              "speed2d": 12.164888888888889,
              "speed3d": 12.341111111111111,
              "msFromStart": 56747.58000000002
            },
            {
              "lat": 43.776262222222215,
              "lng": -116.10207474444442,
              "alt": 1991.054888888889,
              "speed2d": 12.078444444444443,
              "speed3d": 12.256666666666668,
              "msFromStart": 57242.77511111111
            },
            {
              "lat": 43.776305433333334,
              "lng": -116.10211053333336,
              "alt": 1988.7925555555553,
              "speed2d": 11.933555555555555,
              "speed3d": 12.177777777777777,
              "msFromStart": 57737.09466666663
            },
            {
              "lat": 43.77635017777777,
              "lng": -116.10214631111111,
              "alt": 1987.1676666666665,
              "speed2d": 11.533888888888889,
              "speed3d": 11.804444444444446,
              "msFromStart": 58231.488888888874
            },
            {
              "lat": 43.77639827777778,
              "lng": -116.10217643333334,
              "alt": 1985.7711111111112,
              "speed2d": 11.448444444444444,
              "speed3d": 11.683333333333334,
              "msFromStart": 58726.53200000003
            },
            {
              "lat": 43.77645529,
              "lng": -116.10220496000001,
              "alt": 1984.4776,
              "speed2d": 11.7397,
              "speed3d": 11.951,
              "msFromStart": 59249.198168421055
            },
            {
              "lat": 43.77651535555556,
              "lng": -116.10222828888888,
              "alt": 1982.7723333333336,
              "speed2d": 12.04511111111111,
              "speed3d": 12.288888888888891,
              "msFromStart": 59772.03326315789
            },
            {
              "lat": 43.77657239999999,
              "lng": -116.1022456888889,
              "alt": 1981.2063333333335,
              "speed2d": 12.135111111111108,
              "speed3d": 12.386666666666667,
              "msFromStart": 60267.17269785574
            },
            {
              "lat": 43.77662775555555,
              "lng": -116.10225842222223,
              "alt": 1979.2006666666666,
              "speed2d": 12.284333333333334,
              "speed3d": 12.543333333333333,
              "msFromStart": 60761.54355555556
            },
            {
              "lat": 43.77668599999999,
              "lng": -116.10227461111111,
              "alt": 1977.4164444444446,
              "speed2d": 12.537333333333333,
              "speed3d": 12.806666666666665,
              "msFromStart": 61255.89162962962
            },
            {
              "lat": 43.77674571111111,
              "lng": -116.10229233333334,
              "alt": 1975.7473333333335,
              "speed2d": 12.885888888888887,
              "speed3d": 13.141111111111112,
              "msFromStart": 61750.45599999996
            },
            {
              "lat": 43.776810233333336,
              "lng": -116.10230663333334,
              "alt": 1974.3018888888887,
              "speed2d": 13.448333333333332,
              "speed3d": 13.657777777777778,
              "msFromStart": 62245.096444444425
            },
            {
              "lat": 43.77687608888889,
              "lng": -116.10231890000001,
              "alt": 1972.8084444444444,
              "speed2d": 13.931333333333333,
              "speed3d": 14.202222222222224,
              "msFromStart": 62739.95466666664
            },
            {
              "lat": 43.77693923333334,
              "lng": -116.10233294444444,
              "alt": 1971.3348888888888,
              "speed2d": 14.085888888888888,
              "speed3d": 14.317777777777778,
              "msFromStart": 63234.898370370356
            },
            {
              "lat": 43.777001577777774,
              "lng": -116.10234693333335,
              "alt": 1969.8700000000001,
              "speed2d": 14.046777777777779,
              "speed3d": 14.373333333333335,
              "msFromStart": 63730.09688888889
            },
            {
              "lat": 43.77705755555556,
              "lng": -116.10236257777777,
              "alt": 1968.4595555555552,
              "speed2d": 13.50611111111111,
              "speed3d": 13.833333333333334,
              "msFromStart": 64225.44081091619
            },
            {
              "lat": 43.77712262,
              "lng": -116.10237916000001,
              "alt": 1967.2533,
              "speed2d": 13.4713,
              "speed3d": 13.716999999999999,
              "msFromStart": 64748.81284210524
            },
            {
              "lat": 43.77719538888889,
              "lng": -116.10239431111111,
              "alt": 1966.2244444444445,
              "speed2d": 13.879999999999999,
              "speed3d": 14.098888888888888,
              "msFromStart": 65272.14329044832
            },
            {
              "lat": 43.77726337777778,
              "lng": -116.10240643333331,
              "alt": 1965.1016666666665,
              "speed2d": 14.085888888888888,
              "speed3d": 14.325555555555557,
              "msFromStart": 65767.5671111111
            },
            {
              "lat": 43.777326011111114,
              "lng": -116.10241805555556,
              "alt": 1963.7786666666664,
              "speed2d": 13.91677777777778,
              "speed3d": 14.191111111111109,
              "msFromStart": 66262.87288888889
            },
            {
              "lat": 43.7773845,
              "lng": -116.10242531111112,
              "alt": 1962.5155555555557,
              "speed2d": 13.658222222222221,
              "speed3d": 13.935555555555556,
              "msFromStart": 66757.84977777781
            },
            {
              "lat": 43.77744524444445,
              "lng": -116.10243583333335,
              "alt": 1961.7963333333332,
              "speed2d": 13.528555555555556,
              "speed3d": 13.742222222222223,
              "msFromStart": 67252.70562962965
            },
            {
              "lat": 43.77751136666667,
              "lng": -116.10245364444445,
              "alt": 1961.6622222222222,
              "speed2d": 13.456555555555557,
              "speed3d": 13.73111111111111,
              "msFromStart": 67747.20888888887
            },
            {
              "lat": 43.7775766,
              "lng": -116.10247167777777,
              "alt": 1961.6761111111111,
              "speed2d": 13.435444444444443,
              "speed3d": 13.604444444444447,
              "msFromStart": 68241.74577777777
            },
            {
              "lat": 43.777638644444444,
              "lng": -116.10249484444445,
              "alt": 1961.684,
              "speed2d": 13.270777777777779,
              "speed3d": 13.484444444444442,
              "msFromStart": 68736.55822222229
            },
            {
              "lat": 43.777698599999994,
              "lng": -116.1025165888889,
              "alt": 1961.749888888889,
              "speed2d": 13.070777777777774,
              "speed3d": 13.285555555555556,
              "msFromStart": 69231.50504483434
            },
            {
              "lat": 43.777742966666665,
              "lng": -116.10254747777776,
              "alt": 1961.3763333333334,
              "speed2d": 12.123,
              "speed3d": 12.437777777777777,
              "msFromStart": 69726.8791578947
            },
            {
              "lat": 43.7777817,
              "lng": -116.10257454,
              "alt": 1960.2064999999998,
              "speed2d": 10.877699999999999,
              "speed3d": 11.177999999999999,
              "msFromStart": 70249.70035087716
            },
            {
              "lat": 43.77781963333333,
              "lng": -116.10259123333333,
              "alt": 1958.2736666666665,
              "speed2d": 10.021777777777777,
              "speed3d": 10.32888888888889,
              "msFromStart": 70771.9671111111
            },
            {
              "lat": 43.77785452222223,
              "lng": -116.10260568888889,
              "alt": 1956.616888888889,
              "speed2d": 9.619333333333334,
              "speed3d": 9.89,
              "msFromStart": 71266.69703703701
            },
            {
              "lat": 43.777895099999995,
              "lng": -116.10261377777778,
              "alt": 1955.2695555555551,
              "speed2d": 9.576666666666664,
              "speed3d": 9.822222222222223,
              "msFromStart": 71761.53066666663
            },
            {
              "lat": 43.77793968888889,
              "lng": -116.10261887777776,
              "alt": 1953.5634444444447,
              "speed2d": 9.781333333333333,
              "speed3d": 10.047777777777778,
              "msFromStart": 72256.36503703703
            },
            {
              "lat": 43.77798356666666,
              "lng": -116.10261860000001,
              "alt": 1952.157,
              "speed2d": 9.868888888888888,
              "speed3d": 10.115555555555556,
              "msFromStart": 72751.16088888895
            },
            {
              "lat": 43.7780322,
              "lng": -116.10261751111112,
              "alt": 1951.3607777777777,
              "speed2d": 10.215555555555556,
              "speed3d": 10.456666666666665,
              "msFromStart": 73245.94548148151
            },
            {
              "lat": 43.778081766666666,
              "lng": -116.10261193333332,
              "alt": 1950.495777777778,
              "speed2d": 10.237,
              "speed3d": 10.502222222222223,
              "msFromStart": 73740.70044444443
            },
            {
              "lat": 43.77813311111112,
              "lng": -116.10260546666667,
              "alt": 1949.9138888888888,
              "speed2d": 10.405111111111111,
              "speed3d": 10.60888888888889,
              "msFromStart": 74235.73205458088
            },
            {
              "lat": 43.77818552222223,
              "lng": -116.10258768888889,
              "alt": 1948.8734444444444,
              "speed2d": 10.748444444444445,
              "speed3d": 10.99777777777778,
              "msFromStart": 74731.88210526311
            },
            {
              "lat": 43.778237622222214,
              "lng": -116.10256796666668,
              "alt": 1947.9124444444444,
              "speed2d": 11.152333333333333,
              "speed3d": 11.316666666666666,
              "msFromStart": 75228.09833398307
            },
            {
              "lat": 43.7782922,
              "lng": -116.10254545,
              "alt": 1947.0989,
              "speed2d": 11.209499999999998,
              "speed3d": 11.493,
              "msFromStart": 75751.58555555552
            },
            {
              "lat": 43.77834668888889,
              "lng": -116.10252528888886,
              "alt": 1946.4344444444446,
              "speed2d": 11.393888888888888,
              "speed3d": 11.582222222222223,
              "msFromStart": 76274.84044444443
            },
            {
              "lat": 43.77840263333333,
              "lng": -116.10250436666666,
              "alt": 1945.7672222222222,
              "speed2d": 11.799222222222223,
              "speed3d": 11.954444444444444,
              "msFromStart": 76769.79911111113
            },
            {
              "lat": 43.77845373333333,
              "lng": -116.10248255555557,
              "alt": 1944.465222222222,
              "speed2d": 12.029,
              "speed3d": 12.238888888888887,
              "msFromStart": 77264.54607407408
            },
            {
              "lat": 43.77850045555555,
              "lng": -116.10245655555556,
              "alt": 1943.574111111111,
              "speed2d": 12.051333333333334,
              "speed3d": 12.254444444444443,
              "msFromStart": 77758.74488888893
            },
            {
              "lat": 43.778544322222224,
              "lng": -116.10242458888888,
              "alt": 1943.3953333333332,
              "speed2d": 11.866999999999999,
              "speed3d": 12.092222222222222,
              "msFromStart": 78252.90740740743
            },
            {
              "lat": 43.77858693333333,
              "lng": -116.10239797777778,
              "alt": 1943.8335555555557,
              "speed2d": 11.361555555555555,
              "speed3d": 11.573333333333332,
              "msFromStart": 78747.14399999997
            },
            {
              "lat": 43.7786306,
              "lng": -116.1023773888889,
              "alt": 1944.0744444444447,
              "speed2d": 10.683888888888891,
              "speed3d": 10.928888888888888,
              "msFromStart": 79241.59466666664
            },
            {
              "lat": 43.77866898888889,
              "lng": -116.10236874444443,
              "alt": 1943.5169999999998,
              "speed2d": 9.826555555555556,
              "speed3d": 10.057777777777778,
              "msFromStart": 79736.87199999994
            },
            {
              "lat": 43.77870418888889,
              "lng": -116.10237288888888,
              "alt": 1941.9809999999998,
              "speed2d": 8.938222222222223,
              "speed3d": 9.213333333333333,
              "msFromStart": 80232.19150617279
            },
            {
              "lat": 43.7787357,
              "lng": -116.1023853,
              "alt": 1941.2616666666668,
              "speed2d": 8.09,
              "speed3d": 8.346666666666666,
              "msFromStart": 80727.26399999995
            },
            {
              "lat": 43.778766649999994,
              "lng": -116.10240286000001,
              "alt": 1939.6445,
              "speed2d": 7.624499999999999,
              "speed3d": 7.854,
              "msFromStart": 81249.70511111109
            },
            {
              "lat": 43.77880558888889,
              "lng": -116.10242542222224,
              "alt": 1938.229,
              "speed2d": 7.996555555555555,
              "speed3d": 8.131111111111112,
              "msFromStart": 81771.71377777771
            },
            {
              "lat": 43.778851788888886,
              "lng": -116.10244409999999,
              "alt": 1936.812,
              "speed2d": 8.72088888888889,
              "speed3d": 8.92,
              "msFromStart": 82266.43422222216
            },
            {
              "lat": 43.778891055555555,
              "lng": -116.1024563,
              "alt": 1935.2888888888888,
              "speed2d": 8.723666666666666,
              "speed3d": 8.957777777777778,
              "msFromStart": 82762.13244444437
            },
            {
              "lat": 43.77893894444444,
              "lng": -116.10246175555554,
              "alt": 1934.0411111111111,
              "speed2d": 9.246333333333334,
              "speed3d": 9.456666666666667,
              "msFromStart": 83257.99362962961
            },
            {
              "lat": 43.778973,
              "lng": -116.10246822222221,
              "alt": 1933.0608888888887,
              "speed2d": 8.908333333333333,
              "speed3d": 9.20111111111111,
              "msFromStart": 83754.11555555562
            },
            {
              "lat": 43.779014744444446,
              "lng": -116.10248764444442,
              "alt": 1932.3492222222221,
              "speed2d": 9.479333333333333,
              "speed3d": 9.572222222222223,
              "msFromStart": 84249.9520155946
            },
            {
              "lat": 43.779070299999994,
              "lng": -116.10251862222222,
              "alt": 1931.6263333333334,
              "speed2d": 10.711444444444442,
              "speed3d": 10.864444444444445,
              "msFromStart": 84744.54231578953
            },
            {
              "lat": 43.77911903333333,
              "lng": -116.10254811111109,
              "alt": 1929.9198888888886,
              "speed2d": 11.10522222222222,
              "speed3d": 11.286666666666665,
              "msFromStart": 85238.96384145554
            },
            {
              "lat": 43.77916518888888,
              "lng": -116.10256855555556,
              "alt": 1928.178777777778,
              "speed2d": 11.061555555555557,
              "speed3d": 11.341111111111111,
              "msFromStart": 85733.11600000004
            },
            {
              "lat": 43.77921499999999,
              "lng": -116.10257666666666,
              "alt": 1926.1254444444448,
              "speed2d": 11.110333333333335,
              "speed3d": 11.364444444444443,
              "msFromStart": 86227.38153086422
            },
            {
              "lat": 43.779272389999996,
              "lng": -116.10257861,
              "alt": 1924.5521,
              "speed2d": 11.230600000000003,
              "speed3d": 11.509,
              "msFromStart": 86750.12933333327
            },
            {
              "lat": 43.77932915555556,
              "lng": -116.10258402222225,
              "alt": 1923.1551111111112,
              "speed2d": 11.380999999999998,
              "speed3d": 11.61888888888889,
              "msFromStart": 87272.94355555551
            },
            {
              "lat": 43.77937624444444,
              "lng": -116.10259996666667,
              "alt": 1920.2646666666667,
              "speed2d": 11.771888888888888,
              "speed3d": 12.093333333333332,
              "msFromStart": 87767.87199999997
            },
            {
              "lat": 43.779389222222214,
              "lng": -116.10262701111111,
              "alt": 1913.980111111111,
              "speed2d": 11.338666666666667,
              "speed3d": 11.824444444444445,
              "msFromStart": 88262.96325925925
            },
            {
              "lat": 43.77940964444444,
              "lng": -116.10266147777779,
              "alt": 1908.413888888889,
              "speed2d": 11.438888888888888,
              "speed3d": 11.955555555555554,
              "msFromStart": 88758.8515555556
            },
            {
              "lat": 43.77945175555555,
              "lng": -116.10268740000001,
              "alt": 1904.6933333333332,
              "speed2d": 11.799444444444445,
              "speed3d": 12.351111111111111,
              "msFromStart": 89254.43398050684
            },
            {
              "lat": 43.77951811111111,
              "lng": -116.1027013,
              "alt": 1902.8023333333333,
              "speed2d": 12.515444444444444,
              "speed3d": 13.036666666666667,
              "msFromStart": 89748.47410526316
            },
            {
              "lat": 43.77958817777777,
              "lng": -116.1026977222222,
              "alt": 1901.342111111111,
              "speed2d": 12.929666666666668,
              "speed3d": 13.46,
              "msFromStart": 90242.44569200781
            },
            {
              "lat": 43.779650833333335,
              "lng": -116.10267601111111,
              "alt": 1900.0371111111108,
              "speed2d": 13.107777777777777,
              "speed3d": 13.652222222222221,
              "msFromStart": 90736.94000000005
            },
            {
              "lat": 43.77968277777777,
              "lng": -116.1026446,
              "alt": 1897.1835555555554,
              "speed2d": 12.327999999999998,
              "speed3d": 13.045555555555556,
              "msFromStart": 91231.64987654325
            },
            {
              "lat": 43.77970476666667,
              "lng": -116.10260822222222,
              "alt": 1894.6693333333333,
              "speed2d": 11.471444444444444,
              "speed3d": 12.172222222222222,
              "msFromStart": 91727.15199999996
            },
            {
              "lat": 43.77971781000001,
              "lng": -116.10256893000002,
              "alt": 1891.0674,
              "speed2d": 10.4163,
              "speed3d": 11.181999999999999,
              "msFromStart": 92250.20577777774
            },
            {
              "lat": 43.779743555555555,
              "lng": -116.10252172222222,
              "alt": 1887.7922222222223,
              "speed2d": 10.542111111111112,
              "speed3d": 11.186666666666667,
              "msFromStart": 92772.73066666664
            },
            {
              "lat": 43.77977716666667,
              "lng": -116.10246854444443,
              "alt": 1886.000111111111,
              "speed2d": 11.187222222222223,
              "speed3d": 11.790000000000001,
              "msFromStart": 93267.696
            },
            {
              "lat": 43.77980854444445,
              "lng": -116.10241656666666,
              "alt": 1884.1812222222222,
              "speed2d": 11.361777777777778,
              "speed3d": 11.959999999999997,
              "msFromStart": 93762.71466666667
            },
            {
              "lat": 43.77984166666667,
              "lng": -116.10236474444446,
              "alt": 1883.1592222222223,
              "speed2d": 11.423555555555556,
              "speed3d": 11.984444444444444,
              "msFromStart": 94257.83644444443
            },
            {
              "lat": 43.77987442222223,
              "lng": -116.10231366666666,
              "alt": 1882.3572222222224,
              "speed2d": 11.388222222222222,
              "speed3d": 11.898888888888889,
              "msFromStart": 94753.34933333332
            },
            {
              "lat": 43.77990486666667,
              "lng": -116.10226122222224,
              "alt": 1881.226111111111,
              "speed2d": 11.19988888888889,
              "speed3d": 11.673333333333332,
              "msFromStart": 95248.84612865496
            },
            {
              "lat": 43.779936899999996,
              "lng": -116.10220564444447,
              "alt": 1880.6338888888888,
              "speed2d": 11.338555555555557,
              "speed3d": 11.74,
              "msFromStart": 95744.12210526317
            },
            {
              "lat": 43.779970711111105,
              "lng": -116.10214756666666,
              "alt": 1880.144,
              "speed2d": 11.618777777777778,
              "speed3d": 11.913333333333334,
              "msFromStart": 96239.33280311892
            },
            {
              "lat": 43.78000673333333,
              "lng": -116.10208467777778,
              "alt": 1879.6083333333336,
              "speed2d": 12.12788888888889,
              "speed3d": 12.43222222222222,
              "msFromStart": 96734.27999999998
            },
            {
              "lat": 43.780039699999996,
              "lng": -116.10203153333333,
              "alt": 1878.9053333333336,
              "speed2d": 11.871222222222224,
              "speed3d": 12.175555555555558,
              "msFromStart": 97229.22153086416
            },
            {
              "lat": 43.78007456,
              "lng": -116.10197544,
              "alt": 1878.6462000000001,
              "speed2d": 11.713999999999999,
              "speed3d": 11.988999999999999,
              "msFromStart": 97751.90355555552
            },
            {
              "lat": 43.78010711111111,
              "lng": -116.10192316666664,
              "alt": 1878.549888888889,
              "speed2d": 11.329777777777778,
              "speed3d": 11.598888888888888,
              "msFromStart": 98274.61125925925
            },
            {
              "lat": 43.78013713333334,
              "lng": -116.10186822222222,
              "alt": 1878.8964444444441,
              "speed2d": 10.982666666666667,
              "speed3d": 11.22111111111111,
              "msFromStart": 98769.76000000004
            },
            {
              "lat": 43.78016337777778,
              "lng": -116.10181056666667,
              "alt": 1879.6684444444447,
              "speed2d": 10.621,
              "speed3d": 10.832222222222224,
              "msFromStart": 99264.774877193
            },
            {
              "lat": 43.78018548888889,
              "lng": -116.10175911111111,
              "alt": 1878.7026666666666,
              "speed2d": 10.292555555555555,
              "speed3d": 10.50888888888889,
              "msFromStart": 99759.2732631579
            },
            {
              "lat": 43.78020532222222,
              "lng": -116.1017079111111,
              "alt": 1877.152222222222,
              "speed2d": 10.019888888888888,
              "speed3d": 10.235555555555555,
              "msFromStart": 100253.94879792075
            },
            {
              "lat": 43.780223677777784,
              "lng": -116.10165662222224,
              "alt": 1875.9013333333332,
              "speed2d": 9.700111111111113,
              "speed3d": 9.921111111111111,
              "msFromStart": 100750.02000000005
            },
            {
              "lat": 43.7802415,
              "lng": -116.10160151111113,
              "alt": 1874.432888888889,
              "speed2d": 9.562333333333335,
              "speed3d": 9.774444444444445,
              "msFromStart": 101246.2054320988
            },
            {
              "lat": 43.78025998888889,
              "lng": -116.10154196666667,
              "alt": 1873.4072222222221,
              "speed2d": 9.60511111111111,
              "speed3d": 9.79111111111111,
              "msFromStart": 101741.69199999994
            },
            {
              "lat": 43.780281144444444,
              "lng": -116.10147464444445,
              "alt": 1872.993333333333,
              "speed2d": 9.919888888888892,
              "speed3d": 10.036666666666665,
              "msFromStart": 102237.05214814811
            },
            {
              "lat": 43.78030767777778,
              "lng": -116.1013925888889,
              "alt": 1872.914777777778,
              "speed2d": 10.745222222222223,
              "speed3d": 10.845555555555555,
              "msFromStart": 102732.34000000005
            },
            {
              "lat": 43.78032314444444,
              "lng": -116.10132595555554,
              "alt": 1871.8431111111113,
              "speed2d": 10.76544444444444,
              "speed3d": 10.945555555555556,
              "msFromStart": 103227.53204938275
            },
            {
              "lat": 43.78033568000001,
              "lng": -116.10126512999999,
              "alt": 1870.125,
              "speed2d": 10.511399999999998,
              "speed3d": 10.713000000000001,
              "msFromStart": 103749.68955555552
            },
            {
              "lat": 43.7803449111111,
              "lng": -116.10120834444444,
              "alt": 1868.268888888889,
              "speed2d": 10.163,
              "speed3d": 10.396666666666668,
              "msFromStart": 104271.6305185185
            },
            {
              "lat": 43.78035697777778,
              "lng": -116.10114241111111,
              "alt": 1867.5595555555553,
              "speed2d": 10.341666666666667,
              "speed3d": 10.477777777777778,
              "msFromStart": 104765.56355555555
            },
            {
              "lat": 43.78037045555556,
              "lng": -116.10106866666666,
              "alt": 1866.8974444444448,
              "speed2d": 10.751555555555557,
              "speed3d": 10.91,
              "msFromStart": 105259.6699103314
            },
            {
              "lat": 43.7803786,
              "lng": -116.1010024888889,
              "alt": 1865.7976666666666,
              "speed2d": 10.782666666666668,
              "speed3d": 10.975555555555555,
              "msFromStart": 105754.68168421052
            },
            {
              "lat": 43.780387377777785,
              "lng": -116.10093612222222,
              "alt": 1864.8015555555555,
              "speed2d": 10.831333333333333,
              "speed3d": 11.012222222222222,
              "msFromStart": 106249.86964002598
            },
            {
              "lat": 43.780394988888894,
              "lng": -116.10087042222223,
              "alt": 1864.1525555555559,
              "speed2d": 10.830111111111112,
              "speed3d": 11.022222222222222,
              "msFromStart": 106745.57999999994
            },
            {
              "lat": 43.78040225555556,
              "lng": -116.10080495555556,
              "alt": 1863.597777777778,
              "speed2d": 10.794666666666668,
              "speed3d": 10.977777777777778,
              "msFromStart": 107241.33254320984
            },
            {
              "lat": 43.780410144444446,
              "lng": -116.10073513333333,
              "alt": 1863.1978888888889,
              "speed2d": 10.898444444444443,
              "speed3d": 11.066666666666665,
              "msFromStart": 107736.81999999998
            },
            {
              "lat": 43.78041617777778,
              "lng": -116.1006624111111,
              "alt": 1863.0536666666667,
              "speed2d": 11.095888888888888,
              "speed3d": 11.24777777777778,
              "msFromStart": 108232.11585185182
            },
            {
              "lat": 43.780422177777766,
              "lng": -116.10058844444445,
              "alt": 1863.2877777777783,
              "speed2d": 11.283666666666667,
              "speed3d": 11.423333333333336,
              "msFromStart": 108726.50799999997
            },
            {
              "lat": 43.78042882,
              "lng": -116.10051,
              "alt": 1863.5756000000001,
              "speed2d": 11.453700000000001,
              "speed3d": 11.589000000000002,
              "msFromStart": 109248.27031578944
            },
            {
              "lat": 43.78043588888889,
              "lng": -116.10043168888889,
              "alt": 1864.149,
              "speed2d": 11.484666666666666,
              "speed3d": 11.633333333333335,
              "msFromStart": 109770.31410526318
            },
            {
              "lat": 43.78044314444445,
              "lng": -116.10036163333334,
              "alt": 1864.0911111111113,
              "speed2d": 11.249888888888888,
              "speed3d": 11.413333333333334,
              "msFromStart": 110265.07285250162
            },
            {
              "lat": 43.780451988888885,
              "lng": -116.10029528888889,
              "alt": 1863.7102222222225,
              "speed2d": 10.96811111111111,
              "speed3d": 11.131111111111109,
              "msFromStart": 110760.76399999994
            },
            {
              "lat": 43.780461177777774,
              "lng": -116.10023017777777,
              "alt": 1863.4904444444444,
              "speed2d": 10.788444444444442,
              "speed3d": 10.928888888888888,
              "msFromStart": 111256.4761481481
            },
            {
              "lat": 43.780474388888884,
              "lng": -116.10016596666668,
              "alt": 1863.3276666666668,
              "speed2d": 10.712111111111113,
              "speed3d": 10.856666666666666,
              "msFromStart": 111751.38399999999
            },
            {
              "lat": 43.780490288888885,
              "lng": -116.10010771111111,
              "alt": 1862.9775555555555,
              "speed2d": 10.467111111111112,
              "speed3d": 10.625555555555556,
              "msFromStart": 112246.27308641974
            },
            {
              "lat": 43.78050706666667,
              "lng": -116.10004997777779,
              "alt": 1862.5925555555555,
              "speed2d": 10.289777777777779,
              "speed3d": 10.44,
              "msFromStart": 112741.85199999997
            },
            {
              "lat": 43.78052244444444,
              "lng": -116.09999243333334,
              "alt": 1862.0321111111111,
              "speed2d": 10.017222222222221,
              "speed3d": 10.181111111111111,
              "msFromStart": 113237.54449382712
            },
            {
              "lat": 43.7805354111111,
              "lng": -116.09993327777777,
              "alt": 1862.1036666666669,
              "speed2d": 9.777444444444445,
              "speed3d": 9.938888888888888,
              "msFromStart": 113733.23999999999
            },
            {
              "lat": 43.7805462888889,
              "lng": -116.09987345555555,
              "alt": 1862.6723333333332,
              "speed2d": 9.554,
              "speed3d": 9.694444444444446,
              "msFromStart": 114228.79279012345
            },
            {
              "lat": 43.78055406,
              "lng": -116.09980851,
              "alt": 1862.7697,
              "speed2d": 9.4282,
              "speed3d": 9.571000000000002,
              "msFromStart": 114750.94622222216
            },
            {
              "lat": 43.78055976666666,
              "lng": -116.09974787777777,
              "alt": 1862.741888888889,
              "speed2d": 9.182888888888888,
              "speed3d": 9.337777777777777,
              "msFromStart": 115272.93079142296
            },
            {
              "lat": 43.780565244444446,
              "lng": -116.0996978,
              "alt": 1862.2551111111113,
              "speed2d": 8.894333333333334,
              "speed3d": 9.044444444444446,
              "msFromStart": 115767.33852631577
            },
            {
              "lat": 43.78056908888888,
              "lng": -116.09965166666667,
              "alt": 1861.4746666666663,
              "speed2d": 8.502444444444444,
              "speed3d": 8.695555555555554,
              "msFromStart": 116262.03606497723
            },
            {
              "lat": 43.78057428888889,
              "lng": -116.0996054111111,
              "alt": 1860.8614444444447,
              "speed2d": 8.148333333333333,
              "speed3d": 8.302222222222222,
              "msFromStart": 116758.56400000004
            },
            {
              "lat": 43.78058801111111,
              "lng": -116.09955047777777,
              "alt": 1861.7459999999996,
              "speed2d": 8.132777777777775,
              "speed3d": 8.253333333333334,
              "msFromStart": 117255.04681481484
            },
            {
              "lat": 43.780604077777774,
              "lng": -116.09949016666667,
              "alt": 1862.5308888888887,
              "speed2d": 8.367666666666667,
              "speed3d": 8.464444444444446,
              "msFromStart": 117749.42399999998
            },
            {
              "lat": 43.780621077777774,
              "lng": -116.09943344444443,
              "alt": 1863.2594444444446,
              "speed2d": 8.376777777777777,
              "speed3d": 8.496666666666666,
              "msFromStart": 118243.5557530864
            },
            {
              "lat": 43.78063352222222,
              "lng": -116.09938367777778,
              "alt": 1863.2673333333335,
              "speed2d": 8.174888888888889,
              "speed3d": 8.32,
              "msFromStart": 118738.29600000005
            },
            {
              "lat": 43.78064014444444,
              "lng": -116.09933954444443,
              "alt": 1863.2144444444443,
              "speed2d": 7.741555555555555,
              "speed3d": 7.905555555555557,
              "msFromStart": 119233.17007407409
            },
            {
              "lat": 43.78064406666667,
              "lng": -116.09929468888888,
              "alt": 1863.1296666666667,
              "speed2d": 7.459333333333334,
              "speed3d": 7.5955555555555545,
              "msFromStart": 119728.25199999998
            },
            {
              "lat": 43.780651899999995,
              "lng": -116.09924056,
              "alt": 1863.6199000000001,
              "speed2d": 7.5061,
              "speed3d": 7.584,
              "msFromStart": 120250.81026900583
            },
            {
              "lat": 43.780667055555554,
              "lng": -116.0991813111111,
              "alt": 1864.7096666666666,
              "speed2d": 7.751666666666667,
              "speed3d": 7.836666666666667,
              "msFromStart": 120773.06905263157
            },
            {
              "lat": 43.78068477777778,
              "lng": -116.0991288,
              "alt": 1865.5384444444442,
              "speed2d": 7.8917777777777784,
              "speed3d": 7.957777777777778,
              "msFromStart": 121267.80753736192
            },
            {
              "lat": 43.78070657777778,
              "lng": -116.09907861111111,
              "alt": 1866.0506666666668,
              "speed2d": 8.09677777777778,
              "speed3d": 8.161111111111111,
              "msFromStart": 121762.59200000003
            },
            {
              "lat": 43.78072614444444,
              "lng": -116.09903182222223,
              "alt": 1866.1948888888887,
              "speed2d": 8.091555555555555,
              "speed3d": 8.171111111111111,
              "msFromStart": 122257.34103703708
            },
            {
              "lat": 43.78074373333333,
              "lng": -116.09898347777778,
              "alt": 1865.9353333333333,
              "speed2d": 8.14411111111111,
              "speed3d": 8.222222222222223,
              "msFromStart": 122751.82799999998
            },
            {
              "lat": 43.78075286666667,
              "lng": -116.09893778888889,
              "alt": 1865.1098888888887,
              "speed2d": 7.907555555555557,
              "speed3d": 8.02111111111111,
              "msFromStart": 123246.2542222222
            },
            {
              "lat": 43.780757,
              "lng": -116.09888988888889,
              "alt": 1864.3208888888892,
              "speed2d": 7.763333333333333,
              "speed3d": 7.857777777777778,
              "msFromStart": 123740.57199999996
            },
            {
              "lat": 43.78076273333333,
              "lng": -116.09884105555555,
              "alt": 1863.8526666666667,
              "speed2d": 7.743444444444445,
              "speed3d": 7.826666666666667,
              "msFromStart": 124235.10014814812
            },
            {
              "lat": 43.78077511111111,
              "lng": -116.09879516666666,
              "alt": 1863.3843333333332,
              "speed2d": 7.708444444444445,
              "speed3d": 7.81,
              "msFromStart": 124731.02000000005
            },
            {
              "lat": 43.78079544444444,
              "lng": -116.09875548888887,
              "alt": 1863.0213333333331,
              "speed2d": 7.580333333333334,
              "speed3d": 7.681111111111111,
              "msFromStart": 125227.06744119563
            },
            {
              "lat": 43.78081546,
              "lng": -116.09871385000001,
              "alt": 1862.8492,
              "speed2d": 7.4925999999999995,
              "speed3d": 7.585000000000001,
              "msFromStart": 125750.02000000003
            },
            {
              "lat": 43.78083483333333,
              "lng": -116.09867041111112,
              "alt": 1862.614111111111,
              "speed2d": 7.495777777777778,
              "speed3d": 7.588888888888889,
              "msFromStart": 126272.82046003902
            },
            {
              "lat": 43.78084735555556,
              "lng": -116.09863022222223,
              "alt": 1862.3718888888893,
              "speed2d": 7.34788888888889,
              "speed3d": 7.462222222222222,
              "msFromStart": 126767.78799999997
            },
            {
              "lat": 43.78085781111111,
              "lng": -116.0985899111111,
              "alt": 1861.8542222222222,
              "speed2d": 7.178333333333335,
              "speed3d": 7.275555555555555,
              "msFromStart": 127262.71881481481
            },
            {
              "lat": 43.78087125555556,
              "lng": -116.09854462222222,
              "alt": 1861.3543333333334,
              "speed2d": 7.3197777777777775,
              "speed3d": 7.395555555555555,
              "msFromStart": 127757.74000000002
            },
            {
              "lat": 43.78088925555555,
              "lng": -116.09849857777779,
              "alt": 1860.9315555555554,
              "speed2d": 7.568777777777778,
              "speed3d": 7.618888888888891,
              "msFromStart": 128252.67476543211
            },
            {
              "lat": 43.780914188888886,
              "lng": -116.0984547111111,
              "alt": 1860.1295555555557,
              "speed2d": 7.944222222222223,
              "speed3d": 7.99,
              "msFromStart": 128746.99200000006
            },
            {
              "lat": 43.78094317777778,
              "lng": -116.09841454444444,
              "alt": 1859.141888888889,
              "speed2d": 8.273111111111112,
              "speed3d": 8.324444444444445,
              "msFromStart": 129241.25613515277
            },
            {
              "lat": 43.780970366666665,
              "lng": -116.09836837777779,
              "alt": 1858.1146666666668,
              "speed2d": 8.690111111111111,
              "speed3d": 8.714444444444444,
              "msFromStart": 129735.8138947369
            },
            {
              "lat": 43.78099455555556,
              "lng": -116.09831671111111,
              "alt": 1856.9077777777777,
              "speed2d": 9.085666666666668,
              "speed3d": 9.145555555555557,
              "msFromStart": 130230.51950097468
            },
            {
              "lat": 43.78101935555556,
              "lng": -116.09826703333334,
              "alt": 1855.746111111111,
              "speed2d": 9.300666666666666,
              "speed3d": 9.351111111111111,
              "msFromStart": 130726.2222222222
            },
            {
              "lat": 43.78104565,
              "lng": -116.09820653,
              "alt": 1854.9656000000002,
              "speed2d": 9.664000000000001,
              "speed3d": 9.713000000000001,
              "msFromStart": 131249.69333333333
            },
            {
              "lat": 43.78107295555556,
              "lng": -116.09814374444446,
              "alt": 1854.1417777777779,
              "speed2d": 10.077222222222222,
              "speed3d": 10.117777777777778,
              "msFromStart": 131772.9279999999
            },
            {
              "lat": 43.78109915555556,
              "lng": -116.09808518888889,
              "alt": 1853.2778888888888,
              "speed2d": 10.430888888888889,
              "speed3d": 10.468888888888888,
              "msFromStart": 132268.37945679005
            },
            {
              "lat": 43.781124544444445,
              "lng": -116.09802243333333,
              "alt": 1852.8179999999998,
              "speed2d": 10.66588888888889,
              "speed3d": 10.734444444444446,
              "msFromStart": 132762.63199999998
            },
            {
              "lat": 43.781144833333336,
              "lng": -116.09795873333333,
              "alt": 1852.4669999999996,
              "speed2d": 10.675222222222223,
              "speed3d": 10.752222222222223,
              "msFromStart": 133256.7304691358
            },
            {
              "lat": 43.7811633,
              "lng": -116.09789690000002,
              "alt": 1851.9885555555556,
              "speed2d": 10.593888888888888,
              "speed3d": 10.687777777777779,
              "msFromStart": 133751.0880000001
            },
            {
              "lat": 43.781173188888886,
              "lng": -116.09783012222222,
              "alt": 1851.3466666666666,
              "speed2d": 10.529555555555556,
              "speed3d": 10.604444444444445,
              "msFromStart": 134245.72254191042
            },
            {
              "lat": 43.781175733333335,
              "lng": -116.09776121111112,
              "alt": 1850.3686666666667,
              "speed2d": 10.48188888888889,
              "speed3d": 10.572222222222223,
              "msFromStart": 134741.78778947363
            },
            {
              "lat": 43.78117534444444,
              "lng": -116.09769677777778,
              "alt": 1849.195,
              "speed2d": 10.330555555555556,
              "speed3d": 10.427777777777777,
              "msFromStart": 135238.04759454186
            },
            {
              "lat": 43.78117707777778,
              "lng": -116.09763214444445,
              "alt": 1848.2144444444446,
              "speed2d": 10.282,
              "speed3d": 10.374444444444444,
              "msFromStart": 135733.9075555556
            },
            {
              "lat": 43.78117303333333,
              "lng": -116.09756942222225,
              "alt": 1847.3576666666668,
              "speed2d": 10.133,
              "speed3d": 10.242222222222223,
              "msFromStart": 136229.57629629635
            },
            {
              "lat": 43.78118148,
              "lng": -116.09750983,
              "alt": 1846.6110999999999,
              "speed2d": 9.9387,
              "speed3d": 10.043999999999999,
              "msFromStart": 136751.80622222222
            },
            {
              "lat": 43.78120051111111,
              "lng": -116.09745344444443,
              "alt": 1846.362111111111,
              "speed2d": 9.734333333333332,
              "speed3d": 9.848888888888887,
              "msFromStart": 137273.74775308644
            },
            {
              "lat": 43.781210599999994,
              "lng": -116.09740336666667,
              "alt": 1846.7946666666667,
              "speed2d": 9.248333333333335,
              "speed3d": 9.428888888888888,
              "msFromStart": 137767.8480000001
            },
            {
              "lat": 43.78122052222222,
              "lng": -116.09738065555555,
              "alt": 1846.4647777777777,
              "speed2d": 8.03088888888889,
              "speed3d": 8.288888888888888,
              "msFromStart": 138261.99960493832
            },
            {
              "lat": 43.781231633333334,
              "lng": -116.09737276666667,
              "alt": 1845.9972222222218,
              "speed2d": 6.597333333333333,
              "speed3d": 6.871111111111112,
              "msFromStart": 138756.8319999999
            },
            {
              "lat": 43.78124427777778,
              "lng": -116.09736795555554,
              "alt": 1845.3033333333333,
              "speed2d": 5.481111111111112,
              "speed3d": 5.726666666666667,
              "msFromStart": 139251.8264223521
            },
            {
              "lat": 43.78125544444444,
              "lng": -116.09736089999998,
              "alt": 1844.5803333333333,
              "speed2d": 4.723444444444445,
              "speed3d": 4.963333333333333,
              "msFromStart": 139747.1284210526
            },
            {
              "lat": 43.781271344444434,
              "lng": -116.09735254444445,
              "alt": 1843.7166666666667,
              "speed2d": 4.326888888888889,
              "speed3d": 4.544444444444445,
              "msFromStart": 140242.37829239763
            },
            {
              "lat": 43.78129122222222,
              "lng": -116.0973379111111,
              "alt": 1842.8213333333333,
              "speed2d": 4.351111111111111,
              "speed3d": 4.511111111111111,
              "msFromStart": 140736.6026666667
            },
            {
              "lat": 43.78131544444443,
              "lng": -116.09731245555554,
              "alt": 1842.0515555555555,
              "speed2d": 4.792777777777778,
              "speed3d": 4.90888888888889,
              "msFromStart": 141230.65422222228
            },
            {
              "lat": 43.78134902222222,
              "lng": -116.09727993333333,
              "alt": 1841.5135555555555,
              "speed2d": 5.570777777777778,
              "speed3d": 5.6288888888888895,
              "msFromStart": 141725.54133333336
            },
            {
              "lat": 43.781385359999994,
              "lng": -116.09724644,
              "alt": 1840.7847,
              "speed2d": 6.2036,
              "speed3d": 6.2989999999999995,
              "msFromStart": 142248.40577777775
            },
            {
              "lat": 43.78141808888889,
              "lng": -116.09721871111111,
              "alt": 1840.4663333333333,
              "speed2d": 6.411333333333333,
              "speed3d": 6.547777777777778,
              "msFromStart": 142773.11199999988
            },
            {
              "lat": 43.78144976666667,
              "lng": -116.09721035555556,
              "alt": 1840.1872222222223,
              "speed2d": 6.238444444444445,
              "speed3d": 6.4,
              "msFromStart": 143270.17303703693
            },
            {
              "lat": 43.78147702222223,
              "lng": -116.09721323333332,
              "alt": 1839.8422222222223,
              "speed2d": 5.8676666666666675,
              "speed3d": 6.052222222222222,
              "msFromStart": 143764.776
            },
            {
              "lat": 43.78150484444445,
              "lng": -116.09721726666666,
              "alt": 1839.6122222222223,
              "speed2d": 5.691000000000001,
              "speed3d": 5.846666666666666,
              "msFromStart": 144259.19780636777
            },
            {
              "lat": 43.78153117777778,
              "lng": -116.0972171777778,
              "alt": 1838.8932222222224,
              "speed2d": 5.6176666666666675,
              "speed3d": 5.766666666666667,
              "msFromStart": 144754.97263157883
            },
            {
              "lat": 43.78156313333333,
              "lng": -116.0972036888889,
              "alt": 1837.8133333333335,
              "speed2d": 5.868555555555556,
              "speed3d": 5.968888888888888,
              "msFromStart": 145250.85692007784
            },
            {
              "lat": 43.78159882222222,
              "lng": -116.09718288888891,
              "alt": 1836.606888888889,
              "speed2d": 6.239888888888888,
              "speed3d": 6.343333333333334,
              "msFromStart": 145745.61777777775
            },
            {
              "lat": 43.78163585555555,
              "lng": -116.09716545555555,
              "alt": 1835.7421111111112,
              "speed2d": 6.523555555555555,
              "speed3d": 6.616666666666667,
              "msFromStart": 146240.1896296296
            },
            {
              "lat": 43.781676266666665,
              "lng": -116.09714867777775,
              "alt": 1834.697777777778,
              "speed2d": 6.860777777777779,
              "speed3d": 6.98,
              "msFromStart": 146735.6799999999
            },
            {
              "lat": 43.78170972222223,
              "lng": -116.09713474444445,
              "alt": 1833.7097777777776,
              "speed2d": 6.923777777777778,
              "speed3d": 7.051111111111112,
              "msFromStart": 147231.26962962953
            },
            {
              "lat": 43.78174385,
              "lng": -116.09712063,
              "alt": 1832.6227,
              "speed2d": 6.936999999999999,
              "speed3d": 7.066,
              "msFromStart": 147752.99777777775
            },
            {
              "lat": 43.78178085555555,
              "lng": -116.09710465555554,
              "alt": 1831.5394444444446,
              "speed2d": 7.0520000000000005,
              "speed3d": 7.174444444444445,
              "msFromStart": 148274.66172839503
            },
            {
              "lat": 43.78181871111111,
              "lng": -116.09708611111111,
              "alt": 1830.4985555555554,
              "speed2d": 7.216666666666666,
              "speed3d": 7.331111111111111,
              "msFromStart": 148770.47999999995
            },
            {
              "lat": 43.7818581,
              "lng": -116.09706532222222,
              "alt": 1829.5092222222222,
              "speed2d": 7.450222222222222,
              "speed3d": 7.556666666666667,
              "msFromStart": 149266.292345679
            },
            {
              "lat": 43.781898766666664,
              "lng": -116.09703471111112,
              "alt": 1828.6594444444443,
              "speed2d": 7.822444444444445,
              "speed3d": 7.8966666666666665,
              "msFromStart": 149760.47199999998
            },
            {
              "lat": 43.781939844444445,
              "lng": -116.09700202222223,
              "alt": 1827.9536666666668,
              "speed2d": 8.176666666666668,
              "speed3d": 8.275555555555554,
              "msFromStart": 150254.57305782972
            },
            {
              "lat": 43.78197845555555,
              "lng": -116.09696777777779,
              "alt": 1827.5214444444443,
              "speed2d": 8.408999999999999,
              "speed3d": 8.492222222222223,
              "msFromStart": 150749.82736842096
            },
            {
              "lat": 43.782022733333335,
              "lng": -116.09692603333333,
              "alt": 1827.2173333333335,
              "speed2d": 8.908333333333333,
              "speed3d": 8.97111111111111,
              "msFromStart": 151245.2716725145
            },
            {
              "lat": 43.78205683333334,
              "lng": -116.0968921222222,
              "alt": 1826.6642222222222,
              "speed2d": 8.856444444444444,
              "speed3d": 9.008888888888889,
              "msFromStart": 151740.7253333334
            },
            {
              "lat": 43.78207425555556,
              "lng": -116.09685569999999,
              "alt": 1825.6048888888888,
              "speed2d": 8.307,
              "speed3d": 8.485555555555555,
              "msFromStart": 152236.14518518525
            },
            {
              "lat": 43.782091377777775,
              "lng": -116.09682472222221,
              "alt": 1824.5655555555552,
              "speed2d": 7.775999999999999,
              "speed3d": 7.964444444444445,
              "msFromStart": 152731.20355555546
            },
            {
              "lat": 43.78210522222222,
              "lng": -116.09680165555557,
              "alt": 1823.9094444444445,
              "speed2d": 7.0536666666666665,
              "speed3d": 7.293333333333335,
              "msFromStart": 153226.1697777777
            },
            {
              "lat": 43.782115729999994,
              "lng": -116.0967983,
              "alt": 1823.6159999999998,
              "speed2d": 5.866,
              "speed3d": 6.163,
              "msFromStart": 153748.61511111102
            },
            {
              "lat": 43.78212604444445,
              "lng": -116.0967986888889,
              "alt": 1823.0893333333336,
              "speed2d": 4.846888888888889,
              "speed3d": 5.1211111111111105,
              "msFromStart": 154271.13797530858
            },
            {
              "lat": 43.782136244444445,
              "lng": -116.0967957,
              "alt": 1822.1727777777778,
              "speed2d": 4.174222222222222,
              "speed3d": 4.467777777777777,
              "msFromStart": 154766.664
            },
            {
              "lat": 43.782149188888894,
              "lng": -116.09679445555555,
              "alt": 1821.520111111111,
              "speed2d": 3.7075555555555555,
              "speed3d": 3.992222222222222,
              "msFromStart": 155262.0345990903
            },
            {
              "lat": 43.78216972222222,
              "lng": -116.09678634444444,
              "alt": 1821.4644444444446,
              "speed2d": 3.6216666666666666,
              "speed3d": 3.874444444444445,
              "msFromStart": 155755.95705263165
            },
            {
              "lat": 43.78219171111112,
              "lng": -116.09677704444444,
              "alt": 1821.4823333333334,
              "speed2d": 3.6242222222222216,
              "speed3d": 3.877777777777778,
              "msFromStart": 156249.74958284607
            },
            {
              "lat": 43.78220891111111,
              "lng": -116.09677137777777,
              "alt": 1821.351777777778,
              "speed2d": 3.4453333333333336,
              "speed3d": 3.722222222222222,
              "msFromStart": 156744.61688888885
            },
            {
              "lat": 43.78222461111112,
              "lng": -116.09676693333331,
              "alt": 1821.2396666666668,
              "speed2d": 3.225888888888889,
              "speed3d": 3.511111111111111,
              "msFromStart": 157239.7481481481
            },
            {
              "lat": 43.782240800000004,
              "lng": -116.09676677777777,
              "alt": 1821.1177777777775,
              "speed2d": 3.0415555555555556,
              "speed3d": 3.3400000000000003,
              "msFromStart": 157734.83200000005
            },
            {
              "lat": 43.78225384444444,
              "lng": -116.09676147777778,
              "alt": 1821.039,
              "speed2d": 2.761222222222222,
              "speed3d": 3.0788888888888897,
              "msFromStart": 158229.91822222227
            },
            {
              "lat": 43.78227112222222,
              "lng": -116.09675767777779,
              "alt": 1820.8995555555555,
              "speed2d": 2.6879999999999997,
              "speed3d": 2.992222222222222,
              "msFromStart": 158725.14666666673
            },
            {
              "lat": 43.7822922,
              "lng": -116.0967569,
              "alt": 1820.6930000000002,
              "speed2d": 2.7424,
              "speed3d": 3.025,
              "msFromStart": 159247.86577777783
            },
            {
              "lat": 43.78230902222222,
              "lng": -116.09675481111111,
              "alt": 1820.1821111111112,
              "speed2d": 2.6432222222222226,
              "speed3d": 2.953333333333333,
              "msFromStart": 159770.16800000012
            },
            {
              "lat": 43.78232320000001,
              "lng": -116.09675243333334,
              "alt": 1819.5386666666668,
              "speed2d": 2.5116666666666667,
              "speed3d": 2.852222222222222,
              "msFromStart": 160265.0292293698
            },
            {
              "lat": 43.782333666666666,
              "lng": -116.09675234444444,
              "alt": 1818.7195555555554,
              "speed2d": 2.306333333333333,
              "speed3d": 2.6611111111111114,
              "msFromStart": 160760.70315789466
            },
            {
              "lat": 43.78234713333334,
              "lng": -116.09675547777779,
              "alt": 1818.2222222222222,
              "speed2d": 2.2840000000000003,
              "speed3d": 2.631111111111111,
              "msFromStart": 161256.46237816755
            },
            {
              "lat": 43.78236135555556,
              "lng": -116.09676155555556,
              "alt": 1817.8015555555558,
              "speed2d": 2.3344444444444448,
              "speed3d": 2.674444444444444,
              "msFromStart": 161751.7422222222
            },
            {
              "lat": 43.78237235555556,
              "lng": -116.09676504444444,
              "alt": 1817.5802222222223,
              "speed2d": 2.233222222222222,
              "speed3d": 2.6,
              "msFromStart": 162246.88681481476
            },
            {
              "lat": 43.78238392222222,
              "lng": -116.0967687777778,
              "alt": 1817.5652222222225,
              "speed2d": 2.184222222222222,
              "speed3d": 2.534444444444444,
              "msFromStart": 162741.87733333337
            },
            {
              "lat": 43.78239657777778,
              "lng": -116.0967727,
              "alt": 1817.5947777777776,
              "speed2d": 2.163777777777778,
              "speed3d": 2.517777777777778,
              "msFromStart": 163236.80325925926
            },
            {
              "lat": 43.78240830000001,
              "lng": -116.0967759888889,
              "alt": 1817.8796666666667,
              "speed2d": 2.0869999999999997,
              "speed3d": 2.4355555555555553,
              "msFromStart": 163731.46844444444
            },
            {
              "lat": 43.78241993333334,
              "lng": -116.09677733333334,
              "alt": 1818.3005555555553,
              "speed2d": 1.964111111111111,
              "speed3d": 2.3377777777777777,
              "msFromStart": 164226.10814814817
            },
            {
              "lat": 43.78242915999999,
              "lng": -116.09677725,
              "alt": 1818.5670999999998,
              "speed2d": 1.7927000000000002,
              "speed3d": 2.1750000000000003,
              "msFromStart": 164748.6524444444
            },
            {
              "lat": 43.78243805555556,
              "lng": -116.09677917777778,
              "alt": 1818.6882222222223,
              "speed2d": 1.6697777777777778,
              "speed3d": 2.06,
              "msFromStart": 165271.41909291747
            },
            {
              "lat": 43.78244506666666,
              "lng": -116.09678111111113,
              "alt": 1818.6543333333336,
              "speed2d": 1.5741111111111112,
              "speed3d": 1.982222222222222,
              "msFromStart": 165767.4290526316
            },
            {
              "lat": 43.782446255555556,
              "lng": -116.09678055555557,
              "alt": 1818.5787777777778,
              "speed2d": 1.2698888888888888,
              "speed3d": 1.7477777777777779,
              "msFromStart": 166263.42128654974
            },
            {
              "lat": 43.782447588888886,
              "lng": -116.09677947777777,
              "alt": 1818.356111111111,
              "speed2d": 1.0275555555555556,
              "speed3d": 1.568888888888889,
              "msFromStart": 166758.00533333342
            },
            {
              "lat": 43.78244887777777,
              "lng": -116.09677713333333,
              "alt": 1817.9837777777777,
              "speed2d": 0.8015555555555556,
              "speed3d": 1.4077777777777778,
              "msFromStart": 167252.3540740742
            },
            {
              "lat": 43.78245163333334,
              "lng": -116.09677338888889,
              "alt": 1817.5545555555555,
              "speed2d": 0.6549999999999999,
              "speed3d": 1.3244444444444443,
              "msFromStart": 167747.8702222223
            },
            {
              "lat": 43.78245524444444,
              "lng": -116.0967671,
              "alt": 1817.0803333333333,
              "speed2d": 0.5684444444444444,
              "speed3d": 1.2777777777777777,
              "msFromStart": 168243.67822222228
            },
            {
              "lat": 43.78245144444445,
              "lng": -116.09676042222222,
              "alt": 1816.644111111111,
              "speed2d": 0.34944444444444445,
              "speed3d": 1.1977777777777778,
              "msFromStart": 168739.4862222223
            },
            {
              "lat": 43.78245167777778,
              "lng": -116.09675457777779,
              "alt": 1816.247222222222,
              "speed2d": 0.3852222222222222,
              "speed3d": 1.1966666666666665,
              "msFromStart": 169235.21481481488
            },
            {
              "lat": 43.782451811111116,
              "lng": -116.09675010000001,
              "alt": 1815.9537777777775,
              "speed2d": 0.5657777777777778,
              "speed3d": 1.258888888888889,
              "msFromStart": 169730.14933333328
            },
            {
              "lat": 43.78245661999999,
              "lng": -116.09674402,
              "alt": 1815.6486999999997,
              "speed2d": 0.6206,
              "speed3d": 1.284,
              "msFromStart": 170252.24210526308
            },
            {
              "lat": 43.78246563333333,
              "lng": -116.09673747777776,
              "alt": 1815.334,
              "speed2d": 0.6241111111111111,
              "speed3d": 1.2855555555555556,
              "msFromStart": 170773.4349473683
            }
          ]
        }
      ]
    },
    {
      "id": "trail-smuggler-cut-off",
      "pathType": "trail",
      "name": "Smuggler cut-off",
      "difficulty": "blue",
      "videos": [
        {
          "videoId": "GH010021",
          "pathId": "trail-smuggler-cut-off",
          "startSec": 101,
          "endSec": 130,
          "id": "video-segment-5",
          "gps": [
            {
              "lat": 43.77866986666666,
              "lng": -116.1024701888889,
              "alt": 1954.4972222222223,
              "speed2d": 13.53422222222222,
              "speed3d": 13.564444444444444,
              "msFromStart": 101248.63132943466
            },
            {
              "lat": 43.77872504444445,
              "lng": -116.10244811111112,
              "alt": 1954.0134444444448,
              "speed2d": 13.210333333333333,
              "speed3d": 13.24888888888889,
              "msFromStart": 101743.94799999997
            },
            {
              "lat": 43.778780866666665,
              "lng": -116.10243211111111,
              "alt": 1954.5275555555559,
              "speed2d": 12.995777777777779,
              "speed3d": 13.043333333333335,
              "msFromStart": 102239.22953086416
            },
            {
              "lat": 43.77883098888889,
              "lng": -116.10240972222222,
              "alt": 1954.3328888888886,
              "speed2d": 12.310555555555554,
              "speed3d": 12.389999999999999,
              "msFromStart": 102733.51999999999
            },
            {
              "lat": 43.77887894444445,
              "lng": -116.10238658888889,
              "alt": 1953.1107777777777,
              "speed2d": 11.843666666666667,
              "speed3d": 11.862222222222224,
              "msFromStart": 103227.66627160495
            },
            {
              "lat": 43.77892866,
              "lng": -116.10235813,
              "alt": 1951.9291,
              "speed2d": 11.566699999999999,
              "speed3d": 11.626999999999999,
              "msFromStart": 103749.38377777772
            },
            {
              "lat": 43.77897604444445,
              "lng": -116.10233317777778,
              "alt": 1950.912888888889,
              "speed2d": 11.159111111111109,
              "speed3d": 11.181111111111111,
              "msFromStart": 104271.30345419099
            },
            {
              "lat": 43.77902021111111,
              "lng": -116.10230395555556,
              "alt": 1949.6604444444442,
              "speed2d": 11.065,
              "speed3d": 11.087777777777779,
              "msFromStart": 104766.49894736841
            },
            {
              "lat": 43.77906515555556,
              "lng": -116.10227351111111,
              "alt": 1948.4638888888887,
              "speed2d": 11.238222222222221,
              "speed3d": 11.227777777777778,
              "msFromStart": 105261.84540090967
            },
            {
              "lat": 43.77910864444444,
              "lng": -116.10224050000002,
              "alt": 1947.5663333333332,
              "speed2d": 11.231777777777777,
              "speed3d": 11.256666666666668,
              "msFromStart": 105757.66399999999
            },
            {
              "lat": 43.77915606666667,
              "lng": -116.10220932222221,
              "alt": 1946.3046666666667,
              "speed2d": 11.370000000000001,
              "speed3d": 11.36888888888889,
              "msFromStart": 106253.23802469134
            },
            {
              "lat": 43.77920366666667,
              "lng": -116.10217541111112,
              "alt": 1945.378888888889,
              "speed2d": 11.487444444444447,
              "speed3d": 11.528888888888888,
              "msFromStart": 106746.84799999994
            },
            {
              "lat": 43.779248466666665,
              "lng": -116.10213793333335,
              "alt": 1944.9665555555557,
              "speed2d": 11.44588888888889,
              "speed3d": 11.47111111111111,
              "msFromStart": 107240.32711111108
            },
            {
              "lat": 43.77929101111111,
              "lng": -116.1020997,
              "alt": 1943.914666666667,
              "speed2d": 11.266222222222224,
              "speed3d": 11.33888888888889,
              "msFromStart": 107734.97200000001
            },
            {
              "lat": 43.779331544444446,
              "lng": -116.10206248888888,
              "alt": 1942.8791111111111,
              "speed2d": 11.166555555555556,
              "speed3d": 11.208888888888888,
              "msFromStart": 108229.81985185186
            },
            {
              "lat": 43.7793736,
              "lng": -116.10202187000002,
              "alt": 1941.7748,
              "speed2d": 11.1471,
              "speed3d": 11.189999999999998,
              "msFromStart": 108752.23622222226
            },
            {
              "lat": 43.77941587777779,
              "lng": -116.10197936666668,
              "alt": 1941.2482222222225,
              "speed2d": 11.121555555555556,
              "speed3d": 11.174444444444443,
              "msFromStart": 109274.84896686161
            },
            {
              "lat": 43.77945537777778,
              "lng": -116.10194006666669,
              "alt": 1940.6836666666666,
              "speed2d": 10.994,
              "speed3d": 11.05777777777778,
              "msFromStart": 109770.70357894729
            },
            {
              "lat": 43.77949626666667,
              "lng": -116.10189827777776,
              "alt": 1940.5995555555558,
              "speed2d": 11.235888888888887,
              "speed3d": 11.263333333333334,
              "msFromStart": 110266.72379986997
            },
            {
              "lat": 43.77953753333333,
              "lng": -116.10185837777779,
              "alt": 1940.3637777777774,
              "speed2d": 11.289666666666665,
              "speed3d": 11.333333333333334,
              "msFromStart": 110763.30399999997
            },
            {
              "lat": 43.77957854444445,
              "lng": -116.10182258888888,
              "alt": 1940.094111111111,
              "speed2d": 11.14811111111111,
              "speed3d": 11.22,
              "msFromStart": 111259.66933333332
            },
            {
              "lat": 43.77961753333333,
              "lng": -116.10179125555557,
              "alt": 1938.4282222222223,
              "speed2d": 10.916444444444444,
              "speed3d": 10.998888888888889,
              "msFromStart": 111754.16399999999
            },
            {
              "lat": 43.779655866666666,
              "lng": -116.10175446666668,
              "alt": 1936.5128888888887,
              "speed2d": 10.833444444444442,
              "speed3d": 10.92,
              "msFromStart": 112248.28286419752
            },
            {
              "lat": 43.77969344444445,
              "lng": -116.10171336666667,
              "alt": 1935.0485555555554,
              "speed2d": 10.96288888888889,
              "speed3d": 11.022222222222222,
              "msFromStart": 112741.97999999994
            },
            {
              "lat": 43.779733699999994,
              "lng": -116.10167632222223,
              "alt": 1933.1036666666666,
              "speed2d": 10.918222222222223,
              "speed3d": 11.06111111111111,
              "msFromStart": 113235.87960493822
            },
            {
              "lat": 43.7797774,
              "lng": -116.10164809999999,
              "alt": 1931.046111111111,
              "speed2d": 10.946111111111112,
              "speed3d": 11.064444444444446,
              "msFromStart": 113731.43600000003
            },
            {
              "lat": 43.779823022222224,
              "lng": -116.10162301111112,
              "alt": 1928.8358888888893,
              "speed2d": 11.193222222222223,
              "speed3d": 11.376666666666667,
              "msFromStart": 114227.16079012348
            },
            {
              "lat": 43.77987016,
              "lng": -116.10159250000001,
              "alt": 1926.3912,
              "speed2d": 11.282,
              "speed3d": 11.471,
              "msFromStart": 114749.75555555559
            },
            {
              "lat": 43.77991456666666,
              "lng": -116.10154741111111,
              "alt": 1923.8714444444445,
              "speed2d": 11.523000000000001,
              "speed3d": 11.785555555555556,
              "msFromStart": 115272.16929434701
            },
            {
              "lat": 43.77995655555556,
              "lng": -116.10150297777778,
              "alt": 1922.5279999999998,
              "speed2d": 11.857888888888887,
              "speed3d": 12.030000000000001,
              "msFromStart": 115766.7722105263
            },
            {
              "lat": 43.78000325555555,
              "lng": -116.10145804444446,
              "alt": 1921.2563333333333,
              "speed2d": 12.633777777777778,
              "speed3d": 12.826666666666666,
              "msFromStart": 116261.4837790773
            },
            {
              "lat": 43.78004883333334,
              "lng": -116.10141680000001,
              "alt": 1920.1656666666668,
              "speed2d": 12.42511111111111,
              "speed3d": 12.713333333333333,
              "msFromStart": 116757.04799999997
            },
            {
              "lat": 43.780089866666664,
              "lng": -116.10136613333334,
              "alt": 1918.3715555555552,
              "speed2d": 12.164888888888887,
              "speed3d": 12.45,
              "msFromStart": 117252.669037037
            },
            {
              "lat": 43.78012603333333,
              "lng": -116.10131037777776,
              "alt": 1916.6256666666668,
              "speed2d": 11.894222222222222,
              "speed3d": 12.22222222222222,
              "msFromStart": 117747.78399999996
            },
            {
              "lat": 43.7801593,
              "lng": -116.1012547888889,
              "alt": 1915.0497777777782,
              "speed2d": 11.669111111111112,
              "speed3d": 11.933333333333335,
              "msFromStart": 118242.85550617281
            },
            {
              "lat": 43.78019141111111,
              "lng": -116.10119224444445,
              "alt": 1913.9145555555554,
              "speed2d": 12.141666666666666,
              "speed3d": 12.373333333333331,
              "msFromStart": 118738.168
            },
            {
              "lat": 43.7802211,
              "lng": -116.10112688888888,
              "alt": 1912.3922222222222,
              "speed2d": 12.307444444444444,
              "speed3d": 12.625555555555554,
              "msFromStart": 119233.56839506172
            },
            {
              "lat": 43.78024536666667,
              "lng": -116.10106288888889,
              "alt": 1910.7498888888888,
              "speed2d": 11.690444444444445,
              "speed3d": 12.018888888888887,
              "msFromStart": 119729.26400000001
            },
            {
              "lat": 43.78026694444444,
              "lng": -116.10100205555555,
              "alt": 1908.6151111111108,
              "speed2d": 11.075222222222223,
              "speed3d": 11.528888888888888,
              "msFromStart": 120224.99989083823
            },
            {
              "lat": 43.78028961,
              "lng": -116.10094332,
              "alt": 1905.4587999999999,
              "speed2d": 10.8222,
              "speed3d": 11.167,
              "msFromStart": 120748.22399999997
            },
            {
              "lat": 43.780307766666674,
              "lng": -116.10087951111112,
              "alt": 1902.3954444444446,
              "speed2d": 10.611999999999998,
              "speed3d": 11.103333333333333,
              "msFromStart": 121271.24366471733
            },
            {
              "lat": 43.78031812222222,
              "lng": -116.10081609999999,
              "alt": 1900.2335555555555,
              "speed2d": 10.527333333333335,
              "speed3d": 10.981111111111112,
              "msFromStart": 121765.52799999993
            },
            {
              "lat": 43.7803262,
              "lng": -116.10075435555557,
              "alt": 1898.7683333333332,
              "speed2d": 10.321444444444444,
              "speed3d": 10.805555555555555,
              "msFromStart": 122259.61797530859
            },
            {
              "lat": 43.78033841111111,
              "lng": -116.10070402222222,
              "alt": 1896.8997777777777,
              "speed2d": 9.506666666666668,
              "speed3d": 10.029999999999998,
              "msFromStart": 122753.71999999999
            },
            {
              "lat": 43.7803554111111,
              "lng": -116.10065020000003,
              "alt": 1895.5265555555557,
              "speed2d": 9.873888888888889,
              "speed3d": 10.225555555555555,
              "msFromStart": 123248.01313580247
            },
            {
              "lat": 43.78037378888889,
              "lng": -116.10059348888889,
              "alt": 1894.3355555555559,
              "speed2d": 10.062555555555557,
              "speed3d": 10.450000000000001,
              "msFromStart": 123743.45999999999
            },
            {
              "lat": 43.780394022222225,
              "lng": -116.10053276666667,
              "alt": 1893.6440000000002,
              "speed2d": 10.67222222222222,
              "speed3d": 10.932222222222222,
              "msFromStart": 124238.90332943469
            },
            {
              "lat": 43.780420122222225,
              "lng": -116.10047398888887,
              "alt": 1893.4436666666668,
              "speed2d": 11.111444444444446,
              "speed3d": 11.352222222222222,
              "msFromStart": 124733.17136842104
            },
            {
              "lat": 43.78045114444444,
              "lng": -116.10041484444444,
              "alt": 1893.1335555555554,
              "speed2d": 11.62288888888889,
              "speed3d": 11.818888888888889,
              "msFromStart": 125227.34307992202
            },
            {
              "lat": 43.78048251,
              "lng": -116.10035133999999,
              "alt": 1891.7025,
              "speed2d": 11.6196,
              "speed3d": 11.883000000000001,
              "msFromStart": 125749.9991111111
            },
            {
              "lat": 43.78050881111111,
              "lng": -116.10028534444444,
              "alt": 1890.3224444444445,
              "speed2d": 11.45,
              "speed3d": 11.693333333333333,
              "msFromStart": 126272.9769876543
            },
            {
              "lat": 43.780532833333325,
              "lng": -116.10022171111112,
              "alt": 1889.081333333333,
              "speed2d": 11.604222222222221,
              "speed3d": 11.827777777777778,
              "msFromStart": 126768.92799999994
            },
            {
              "lat": 43.780558766666665,
              "lng": -116.10016127777776,
              "alt": 1888.6274444444446,
              "speed2d": 11.65011111111111,
              "speed3d": 11.858888888888888,
              "msFromStart": 127264.98172839504
            },
            {
              "lat": 43.78058308888889,
              "lng": -116.10009934444446,
              "alt": 1888.4216666666666,
              "speed2d": 11.465555555555554,
              "speed3d": 11.692222222222224,
              "msFromStart": 127761.16799999995
            },
            {
              "lat": 43.78060307777778,
              "lng": -116.10003352222222,
              "alt": 1888.7278888888889,
              "speed2d": 11.397555555555556,
              "speed3d": 11.572222222222223,
              "msFromStart": 128257.32118518515
            },
            {
              "lat": 43.780623588888886,
              "lng": -116.0999636,
              "alt": 1889.4818888888888,
              "speed2d": 11.91088888888889,
              "speed3d": 11.914444444444445,
              "msFromStart": 128753.13999999997
            },
            {
              "lat": 43.78065065555556,
              "lng": -116.09989668888889,
              "alt": 1889.3843333333334,
              "speed2d": 12.433333333333334,
              "speed3d": 12.553333333333335,
              "msFromStart": 129248.69214814814
            },
            {
              "lat": 43.78067851111111,
              "lng": -116.09984098888889,
              "alt": 1888.6576666666667,
              "speed2d": 11.755666666666666,
              "speed3d": 11.989999999999997,
              "msFromStart": 129742.95200000002
            }
          ]
        }
      ]
    },
    {
      "id": "trail-sunshine",
      "pathType": "trail",
      "name": "Sunshine",
      "difficulty": "green",
      "videos": [
        {
          "videoId": "GH010041",
          "pathId": "trail-sunshine",
          "startSec": 120,
          "endSec": 148,
          "id": "video-segment-6",
          "gps": [
            {
              "lat": 43.76673408,
              "lng": -116.09435604000001,
              "alt": 1977.1504,
              "speed2d": 4.5278,
              "speed3d": 4.64,
              "msFromStart": 120240.29329122808
            },
            {
              "lat": 43.76670398,
              "lng": -116.09434844,
              "alt": 1977.3094,
              "speed2d": 4.5244,
              "speed3d": 4.61,
              "msFromStart": 120789.54222222217
            },
            {
              "lat": 43.76667416,
              "lng": -116.09434768,
              "alt": 1976.9914,
              "speed2d": 4.7326,
              "speed3d": 4.8,
              "msFromStart": 121261.95759999997
            },
            {
              "lat": 43.76663638,
              "lng": -116.09436324,
              "alt": 1976.58,
              "speed2d": 5.149800000000001,
              "speed3d": 5.176,
              "msFromStart": 121789.84293333336
            },
            {
              "lat": 43.766593025000006,
              "lng": -116.0943895,
              "alt": 1976.3000000000002,
              "speed2d": 5.957249999999999,
              "speed3d": 5.9399999999999995,
              "msFromStart": 122301.18266666667
            },
            {
              "lat": 43.76658161354013,
              "lng": -116.09440893130633,
              "alt": 1976.3267348880076,
              "speed2d": 6.2688410834137045,
              "speed3d": 6.264600407244663,
              "msFromStart": 122500
            },
            {
              "lat": 43.7664906,
              "lng": -116.094429825,
              "alt": 1975.93725,
              "speed2d": 7.664750000000001,
              "speed3d": 7.6625,
              "msFromStart": 123400.50888888887
            },
            {
              "lat": 43.766458785714285,
              "lng": -116.09443371428571,
              "alt": 1975.8519999999999,
              "speed2d": 7.978142857142857,
              "speed3d": 7.982857142857143,
              "msFromStart": 123734.57777777773
            },
            {
              "lat": 43.766409100000004,
              "lng": -116.09443424,
              "alt": 1975.7694000000001,
              "speed2d": 8.5044,
              "speed3d": 8.486,
              "msFromStart": 124187.43186900581
            },
            {
              "lat": 43.76633312,
              "lng": -116.09442668,
              "alt": 1975.631,
              "speed2d": 9.438400000000001,
              "speed3d": 9.372,
              "msFromStart": 124783.22298947367
            },
            {
              "lat": 43.766275725,
              "lng": -116.09442435,
              "alt": 1975.34125,
              "speed2d": 10.248249999999999,
              "speed3d": 10.195,
              "msFromStart": 125177.44671345028
            },
            {
              "lat": 43.76623044222855,
              "lng": -116.0944285184204,
              "alt": 1975.118393991253,
              "speed2d": 10.801772936844497,
              "speed3d": 10.73357996862831,
              "msFromStart": 125500
            },
            {
              "lat": 43.76618218434949,
              "lng": -116.09445841759822,
              "alt": 1974.6502092304959,
              "speed2d": 10.828035728170683,
              "speed3d": 10.78661060496003,
              "msFromStart": 126000
            },
            {
              "lat": 43.766133926470424,
              "lng": -116.09448831677604,
              "alt": 1974.1820244697385,
              "speed2d": 10.854298519496867,
              "speed3d": 10.839641241291751,
              "msFromStart": 126500
            },
            {
              "lat": 43.76607739850886,
              "lng": -116.09450686442062,
              "alt": 1973.5954290297102,
              "speed2d": 11.001112117743782,
              "speed3d": 10.859073235213518,
              "msFromStart": 127000
            },
            {
              "lat": 43.76602305,
              "lng": -116.0945724,
              "alt": 1973.0605,
              "speed2d": 10.693999999999999,
              "speed3d": 10.765,
              "msFromStart": 127749.50622222226
            },
            {
              "lat": 43.76598282,
              "lng": -116.09462904,
              "alt": 1972.4436,
              "speed2d": 10.3084,
              "speed3d": 10.374,
              "msFromStart": 128382.71999999999
            },
            {
              "lat": 43.7659697,
              "lng": -116.09464295000001,
              "alt": 1972.18,
              "speed2d": 10.2015,
              "speed3d": 10.29,
              "msFromStart": 128574.65999999997
            },
            {
              "lat": 43.7659103,
              "lng": -116.09468335999999,
              "alt": 1971.0729999999999,
              "speed2d": 10.0574,
              "speed3d": 10.088000000000001,
              "msFromStart": 129304.60168421052
            },
            {
              "lat": 43.765862600000006,
              "lng": -116.094708925,
              "alt": 1970.3067500000002,
              "speed2d": 10.3505,
              "speed3d": 10.357499999999998,
              "msFromStart": 129797.63368421048
            },
            {
              "lat": 43.76582285,
              "lng": -116.09473748333333,
              "alt": 1969.6664999999998,
              "speed2d": 10.677833333333334,
              "speed3d": 10.696666666666667,
              "msFromStart": 130224.38212475629
            },
            {
              "lat": 43.765779025,
              "lng": -116.09478214999999,
              "alt": 1968.7885,
              "speed2d": 10.787749999999999,
              "speed3d": 10.8975,
              "msFromStart": 130746.01999999997
            },
            {
              "lat": 43.765749799999995,
              "lng": -116.0948188,
              "alt": 1967.8919999999998,
              "speed2d": 10.559,
              "speed3d": 10.639999999999999,
              "msFromStart": 131143.8194444444
            },
            {
              "lat": 43.76572315043448,
              "lng": -116.09484526214708,
              "alt": 1966.784214546409,
              "speed2d": 10.379151949531488,
              "speed3d": 10.429090402496819,
              "msFromStart": 131500
            },
            {
              "lat": 43.765658325000004,
              "lng": -116.09490162499999,
              "alt": 1964.1912499999999,
              "speed2d": 10.0465,
              "speed3d": 10.1175,
              "msFromStart": 132339.37577777778
            },
            {
              "lat": 43.765631819999996,
              "lng": -116.09492264,
              "alt": 1963.2318,
              "speed2d": 10.197,
              "speed3d": 10.268,
              "msFromStart": 132657.88853333326
            },
            {
              "lat": 43.76560383902897,
              "lng": -116.0949491123259,
              "alt": 1962.2126487822,
              "speed2d": 10.518471003260723,
              "speed3d": 10.553588148070725,
              "msFromStart": 133000
            },
            {
              "lat": 43.76554806,
              "lng": -116.09503518,
              "alt": 1958.4420000000002,
              "speed2d": 11.073799999999999,
              "speed3d": 11.108,
              "msFromStart": 133877.07200000016
            },
            {
              "lat": 43.76552576666667,
              "lng": -116.0950858,
              "alt": 1955.8996666666667,
              "speed2d": 11.500666666666667,
              "speed3d": 11.67,
              "msFromStart": 134316.8277894737
            },
            {
              "lat": 43.7655047,
              "lng": -116.09513418,
              "alt": 1953.5726,
              "speed2d": 11.9706,
              "speed3d": 12.152000000000001,
              "msFromStart": 134713.61903157897
            },
            {
              "lat": 43.76546544,
              "lng": -116.09519965999999,
              "alt": 1951.0349999999999,
              "speed2d": 12.704999999999998,
              "speed3d": 12.989999999999998,
              "msFromStart": 135253.66259649125
            },
            {
              "lat": 43.765426219999995,
              "lng": -116.09524382,
              "alt": 1948.8814,
              "speed2d": 12.8664,
              "speed3d": 13.158,
              "msFromStart": 135705.14062222216
            },
            {
              "lat": 43.76537955,
              "lng": -116.09529803333334,
              "alt": 1945.8623333333333,
              "speed2d": 13.431666666666667,
              "speed3d": 13.721666666666668,
              "msFromStart": 136235.4429629629
            },
            {
              "lat": 43.76535812033493,
              "lng": -116.09532821901789,
              "alt": 1944.3563015498016,
              "speed2d": 13.714878104825493,
              "speed3d": 13.937136945558008,
              "msFromStart": 136500
            },
            {
              "lat": 43.7653049,
              "lng": -116.09542456666667,
              "alt": 1940.0543333333335,
              "speed2d": 13.151666666666667,
              "speed3d": 13.64,
              "msFromStart": 137289.65570370376
            },
            {
              "lat": 43.765263425,
              "lng": -116.0954922,
              "alt": 1937.0777500000002,
              "speed2d": 13.3025,
              "speed3d": 13.754999999999999,
              "msFromStart": 137840.78666666662
            },
            {
              "lat": 43.76523315,
              "lng": -116.095531875,
              "alt": 1934.87425,
              "speed2d": 13.200999999999999,
              "speed3d": 13.795,
              "msFromStart": 138212.4273333333
            },
            {
              "lat": 43.76518855,
              "lng": -116.095589,
              "alt": 1932.1235000000001,
              "speed2d": 13.283833333333332,
              "speed3d": 13.831666666666667,
              "msFromStart": 138729.39762962953
            },
            {
              "lat": 43.765146599999994,
              "lng": -116.09565626666668,
              "alt": 1929.7054999999998,
              "speed2d": 13.544666666666666,
              "speed3d": 14.128333333333334,
              "msFromStart": 139241.4020116958
            },
            {
              "lat": 43.76509623333333,
              "lng": -116.0957402,
              "alt": 1926.9983333333334,
              "speed2d": 13.245666666666665,
              "speed3d": 13.84,
              "msFromStart": 139872.68631578938
            },
            {
              "lat": 43.76506226000001,
              "lng": -116.09578404000001,
              "alt": 1925.6114,
              "speed2d": 13.183600000000002,
              "speed3d": 13.816000000000003,
              "msFromStart": 140235.12974035082
            },
            {
              "lat": 43.7650076,
              "lng": -116.09584723333334,
              "alt": 1923.6689999999999,
              "speed2d": 13.091000000000001,
              "speed3d": 13.665,
              "msFromStart": 140807.8471111112
            },
            {
              "lat": 43.76497473333333,
              "lng": -116.09589095000001,
              "alt": 1922.173,
              "speed2d": 12.897666666666666,
              "speed3d": 13.466666666666665,
              "msFromStart": 141193.3431111112
            },
            {
              "lat": 43.764936750000004,
              "lng": -116.09596013333334,
              "alt": 1919.6375000000003,
              "speed2d": 12.944666666666667,
              "speed3d": 13.471666666666668,
              "msFromStart": 141751.94474074076
            },
            {
              "lat": 43.7649070625,
              "lng": -116.0960294625,
              "alt": 1917.3963749999998,
              "speed2d": 13.09275,
              "speed3d": 13.615,
              "msFromStart": 142243.97566666667
            },
            {
              "lat": 43.76488388,
              "lng": -116.09608461999998,
              "alt": 1915.7848,
              "speed2d": 13.286999999999999,
              "speed3d": 13.835999999999999,
              "msFromStart": 142621.8533333333
            },
            {
              "lat": 43.76483425,
              "lng": -116.096178175,
              "alt": 1913.22325,
              "speed2d": 13.255,
              "speed3d": 13.7775,
              "msFromStart": 143308.9671111111
            },
            {
              "lat": 43.76481765,
              "lng": -116.09620635,
              "alt": 1912.4155,
              "speed2d": 13.064499999999999,
              "speed3d": 13.7,
              "msFromStart": 143528.74711111115
            },
            {
              "lat": 43.76479651769548,
              "lng": -116.09627174169191,
              "alt": 1910.1209980535787,
              "speed2d": 12.728288854531716,
              "speed3d": 13.38288139784341,
              "msFromStart": 144000
            },
            {
              "lat": 43.76479027524295,
              "lng": -116.09633329647303,
              "alt": 1907.814044989057,
              "speed2d": 11.812129548272631,
              "speed3d": 12.101453763375083,
              "msFromStart": 144500
            },
            {
              "lat": 43.764763485714276,
              "lng": -116.0964365,
              "alt": 1905.5684285714287,
              "speed2d": 10.851857142857144,
              "speed3d": 11.457142857142857,
              "msFromStart": 145300.1656274019
            },
            {
              "lat": 43.764755449999996,
              "lng": -116.096490775,
              "alt": 1903.9447499999999,
              "speed2d": 10.7685,
              "speed3d": 11.29,
              "msFromStart": 145701.44355555563
            },
            {
              "lat": 43.76474462,
              "lng": -116.0965732,
              "alt": 1902.2432,
              "speed2d": 11.077399999999999,
              "speed3d": 11.616,
              "msFromStart": 146274.38400000008
            },
            {
              "lat": 43.76473711111112,
              "lng": -116.09663975555557,
              "alt": 1901.9378888888891,
              "speed2d": 10.526555555555555,
              "speed3d": 11.111111111111109,
              "msFromStart": 146748.44800000006
            },
            {
              "lat": 43.764735333333334,
              "lng": -116.0966967,
              "alt": 1901.2243333333336,
              "speed2d": 9.874333333333333,
              "speed3d": 10.323333333333334,
              "msFromStart": 147189.317925926
            },
            {
              "lat": 43.76473958313873,
              "lng": -116.09673335652086,
              "alt": 1900.2963526713415,
              "speed2d": 9.646226274535207,
              "speed3d": 10.067858878020342,
              "msFromStart": 147500
            }
          ]
        }
      ]
    },
    {
      "id": "trail-sunshine-cat",
      "pathType": "trail",
      "name": "Sunshine Cat Track",
      "difficulty": "green",
      "isCatTrack": true,
      "videos": [
        {
          "videoId": "GH010041",
          "pathId": "trail-sunshine-cat",
          "startSec": 5,
          "endSec": 120,
          "id": "video-segment-7",
          "gps": [
            {
              "lat": 43.772051077777775,
              "lng": -116.10030397777778,
              "alt": 2079.9111111111115,
              "speed2d": 2.219333333333333,
              "speed3d": 2.2355555555555555,
              "msFromStart": 5273.694827160492
            },
            {
              "lat": 43.772070055555545,
              "lng": -116.1002836,
              "alt": 2080.0963333333334,
              "speed2d": 2.8037777777777775,
              "speed3d": 2.6977777777777776,
              "msFromStart": 5767.730999999994
            },
            {
              "lat": 43.772097244444446,
              "lng": -116.10025441111111,
              "alt": 2080.200777777778,
              "speed2d": 4.045222222222222,
              "speed3d": 4.018888888888888,
              "msFromStart": 6262.150685185184
            },
            {
              "lat": 43.77210841111111,
              "lng": -116.1002384222222,
              "alt": 2080.355777777778,
              "speed2d": 3.9177777777777774,
              "speed3d": 3.956666666666667,
              "msFromStart": 6757.657944444442
            },
            {
              "lat": 43.77211492222222,
              "lng": -116.10022016666667,
              "alt": 2080.575,
              "speed2d": 3.5641111111111115,
              "speed3d": 3.6322222222222225,
              "msFromStart": 7253.478333333333
            },
            {
              "lat": 43.77212068888888,
              "lng": -116.10020684444446,
              "alt": 2080.758888888889,
              "speed2d": 3.1193333333333335,
              "speed3d": 3.1955555555555555,
              "msFromStart": 7749.993277777781
            },
            {
              "lat": 43.77212222222222,
              "lng": -116.10019036666665,
              "alt": 2080.916222222222,
              "speed2d": 2.675888888888889,
              "speed3d": 2.748888888888889,
              "msFromStart": 8246.11362962963
            },
            {
              "lat": 43.77212441111111,
              "lng": -116.10018187777776,
              "alt": 2081.110333333333,
              "speed2d": 2.3324444444444445,
              "speed3d": 2.4,
              "msFromStart": 8741.063722222225
            },
            {
              "lat": 43.77212605555556,
              "lng": -116.10018198888889,
              "alt": 2081.404666666667,
              "speed2d": 1.6198888888888887,
              "speed3d": 1.7666666666666666,
              "msFromStart": 9235.947814814816
            },
            {
              "lat": 43.77212082222222,
              "lng": -116.10017884444443,
              "alt": 2081.6793333333335,
              "speed2d": 1.1276666666666666,
              "speed3d": 1.2533333333333334,
              "msFromStart": 9730.81994444444
            },
            {
              "lat": 43.772122644444444,
              "lng": -116.10018346666666,
              "alt": 2081.842222222222,
              "speed2d": 0.5135555555555555,
              "speed3d": 0.7677777777777778,
              "msFromStart": 10225.82472417154
            },
            {
              "lat": 43.772119440000004,
              "lng": -116.10017716,
              "alt": 2082.1724999999997,
              "speed2d": 0.62,
              "speed3d": 0.7349999999999998,
              "msFromStart": 10748.712684210535
            },
            {
              "lat": 43.7721155,
              "lng": -116.10016524444444,
              "alt": 2082.3976666666667,
              "speed2d": 0.9756666666666666,
              "speed3d": 1.041111111111111,
              "msFromStart": 11271.660756335286
            },
            {
              "lat": 43.77210871111111,
              "lng": -116.10015364444445,
              "alt": 2082.428888888889,
              "speed2d": 1.1184444444444444,
              "speed3d": 1.1944444444444442,
              "msFromStart": 11767.198888888895
            },
            {
              "lat": 43.772105655555556,
              "lng": -116.10014577777777,
              "alt": 2082.383444444444,
              "speed2d": 1.1537777777777778,
              "speed3d": 1.2111111111111108,
              "msFromStart": 12262.427759259263
            },
            {
              "lat": 43.77210098888889,
              "lng": -116.10014037777778,
              "alt": 2082.4063333333334,
              "speed2d": 1.0781111111111112,
              "speed3d": 1.1644444444444442,
              "msFromStart": 12756.801277777777
            },
            {
              "lat": 43.772098366666675,
              "lng": -116.10013814444446,
              "alt": 2082.4215555555556,
              "speed2d": 0.8078888888888889,
              "speed3d": 0.9133333333333334,
              "msFromStart": 13251.421574074075
            },
            {
              "lat": 43.772095122222225,
              "lng": -116.10013455555554,
              "alt": 2082.518777777778,
              "speed2d": 0.6633333333333334,
              "speed3d": 0.7611111111111111,
              "msFromStart": 13746.833888888896
            },
            {
              "lat": 43.772088455555554,
              "lng": -116.10012975555556,
              "alt": 2082.469222222222,
              "speed2d": 0.8427777777777776,
              "speed3d": 0.89,
              "msFromStart": 14242.594333333334
            },
            {
              "lat": 43.77208262222222,
              "lng": -116.1001268111111,
              "alt": 2082.587666666667,
              "speed2d": 0.8794444444444444,
              "speed3d": 0.9577777777777778,
              "msFromStart": 14739.186555555556
            },
            {
              "lat": 43.7720736,
              "lng": -116.10012265555557,
              "alt": 2082.4594444444447,
              "speed2d": 1.1945555555555554,
              "speed3d": 1.2011111111111112,
              "msFromStart": 15235.290582846006
            },
            {
              "lat": 43.77206524444444,
              "lng": -116.10011972222222,
              "alt": 2080.9454444444445,
              "speed2d": 1.419111111111111,
              "speed3d": 1.4277777777777776,
              "msFromStart": 15729.950684210527
            },
            {
              "lat": 43.772056000000006,
              "lng": -116.10012119000001,
              "alt": 2079.714,
              "speed2d": 1.5833,
              "speed3d": 1.563,
              "msFromStart": 16252.105576315791
            },
            {
              "lat": 43.772049100000004,
              "lng": -116.10012146666666,
              "alt": 2079.8913333333335,
              "speed2d": 1.3562222222222222,
              "speed3d": 1.3966666666666665,
              "msFromStart": 16774.564500000015
            },
            {
              "lat": 43.77204358888889,
              "lng": -116.10012067777777,
              "alt": 2079.487888888889,
              "speed2d": 1.2981111111111112,
              "speed3d": 1.298888888888889,
              "msFromStart": 17269.07253703704
            },
            {
              "lat": 43.77203896666666,
              "lng": -116.10011446666665,
              "alt": 2079.488888888889,
              "speed2d": 1.1096666666666666,
              "speed3d": 1.1377777777777776,
              "msFromStart": 17762.28188888888
            },
            {
              "lat": 43.77203386666667,
              "lng": -116.10011103333333,
              "alt": 2079.1084444444446,
              "speed2d": 1.1412222222222224,
              "speed3d": 1.12,
              "msFromStart": 18256.33574074074
            },
            {
              "lat": 43.772028,
              "lng": -116.10010542222221,
              "alt": 2078.561,
              "speed2d": 1.2927777777777778,
              "speed3d": 1.2933333333333332,
              "msFromStart": 18752.867333333346
            },
            {
              "lat": 43.77202305555555,
              "lng": -116.1001005777778,
              "alt": 2078.2478888888886,
              "speed2d": 1.29,
              "speed3d": 1.297777777777778,
              "msFromStart": 19249.065518518524
            },
            {
              "lat": 43.772018088888885,
              "lng": -116.10009163333334,
              "alt": 2078.3291111111107,
              "speed2d": 1.2882222222222224,
              "speed3d": 1.287777777777778,
              "msFromStart": 19744.004777777773
            },
            {
              "lat": 43.7720104,
              "lng": -116.1000825,
              "alt": 2078.221777777778,
              "speed2d": 1.675,
              "speed3d": 1.6233333333333333,
              "msFromStart": 20239.015335282653
            },
            {
              "lat": 43.772004833333334,
              "lng": -116.10007619999999,
              "alt": 2077.696111111111,
              "speed2d": 1.6652222222222224,
              "speed3d": 1.6888888888888889,
              "msFromStart": 20734.399263157906
            },
            {
              "lat": 43.77199756666666,
              "lng": -116.10007013333333,
              "alt": 2077.467777777778,
              "speed2d": 1.7656666666666667,
              "speed3d": 1.7633333333333332,
              "msFromStart": 21229.818475633536
            },
            {
              "lat": 43.771988544444454,
              "lng": -116.10006097777777,
              "alt": 2077.3322222222223,
              "speed2d": 1.9663333333333333,
              "speed3d": 1.9466666666666668,
              "msFromStart": 21725.300222222213
            },
            {
              "lat": 43.77197506,
              "lng": -116.10005161999999,
              "alt": 2077.0145,
              "speed2d": 2.3726,
              "speed3d": 2.3449999999999998,
              "msFromStart": 22247.98423333333
            },
            {
              "lat": 43.77195461111111,
              "lng": -116.10003006666668,
              "alt": 2077.991222222223,
              "speed2d": 2.864111111111111,
              "speed3d": 2.825555555555556,
              "msFromStart": 22769.59611111111
            },
            {
              "lat": 43.77193552222222,
              "lng": -116.10001703333333,
              "alt": 2078.786111111111,
              "speed2d": 3.211888888888889,
              "speed3d": 3.1844444444444444,
              "msFromStart": 23263.81755555555
            },
            {
              "lat": 43.77191787777778,
              "lng": -116.10000085555555,
              "alt": 2079.3913333333335,
              "speed2d": 3.3426666666666667,
              "speed3d": 3.3155555555555556,
              "msFromStart": 23758.452777777762
            },
            {
              "lat": 43.77189687777778,
              "lng": -116.0999750111111,
              "alt": 2079.624222222222,
              "speed2d": 3.836111111111111,
              "speed3d": 3.7566666666666664,
              "msFromStart": 24253.442407407405
            },
            {
              "lat": 43.771871733333334,
              "lng": -116.09994217777776,
              "alt": 2080.018666666667,
              "speed2d": 4.642777777777778,
              "speed3d": 4.561111111111111,
              "msFromStart": 24749.334888888898
            },
            {
              "lat": 43.771848577777774,
              "lng": -116.09990752222222,
              "alt": 2080.8770000000004,
              "speed2d": 4.946222222222223,
              "speed3d": 4.946666666666667,
              "msFromStart": 25245.314623781684
            },
            {
              "lat": 43.77182917777778,
              "lng": -116.09988023333332,
              "alt": 2080.709777777778,
              "speed2d": 5.358222222222222,
              "speed3d": 5.274444444444444,
              "msFromStart": 25741.402210526332
            },
            {
              "lat": 43.77180997777777,
              "lng": -116.09985404444444,
              "alt": 2080.603111111111,
              "speed2d": 5.706666666666667,
              "speed3d": 5.695555555555556,
              "msFromStart": 26237.158758284608
            },
            {
              "lat": 43.77179337777778,
              "lng": -116.09982366666667,
              "alt": 2080.099,
              "speed2d": 6.013999999999999,
              "speed3d": 5.967777777777777,
              "msFromStart": 26731.568444444438
            },
            {
              "lat": 43.771772344444436,
              "lng": -116.09978774444444,
              "alt": 2079.887222222222,
              "speed2d": 6.690777777777778,
              "speed3d": 6.612222222222223,
              "msFromStart": 27226.013999999992
            },
            {
              "lat": 43.77174904,
              "lng": -116.09974633,
              "alt": 2079.5699000000004,
              "speed2d": 7.4045000000000005,
              "speed3d": 7.347,
              "msFromStart": 27748.652666666643
            },
            {
              "lat": 43.77173096666667,
              "lng": -116.09970304444445,
              "alt": 2078.600222222222,
              "speed2d": 7.697,
              "speed3d": 7.671111111111111,
              "msFromStart": 28271.087074074065
            },
            {
              "lat": 43.771714,
              "lng": -116.0996619888889,
              "alt": 2078.1852222222224,
              "speed2d": 7.768222222222222,
              "speed3d": 7.763333333333334,
              "msFromStart": 28765.270999999986
            },
            {
              "lat": 43.7716954,
              "lng": -116.09961229999999,
              "alt": 2077.3129999999996,
              "speed2d": 8.474777777777778,
              "speed3d": 8.358888888888888,
              "msFromStart": 29259.758740740734
            },
            {
              "lat": 43.771675699999996,
              "lng": -116.0995576,
              "alt": 2076.296333333333,
              "speed2d": 9.215222222222222,
              "speed3d": 9.187777777777779,
              "msFromStart": 29755.17677777776
            },
            {
              "lat": 43.771657966666666,
              "lng": -116.09950132222224,
              "alt": 2075.6135555555556,
              "speed2d": 9.556444444444443,
              "speed3d": 9.535555555555556,
              "msFromStart": 30250.352629629626
            },
            {
              "lat": 43.771645122222225,
              "lng": -116.09944562222222,
              "alt": 2074.3745555555556,
              "speed2d": 9.816444444444445,
              "speed3d": 9.784444444444444,
              "msFromStart": 30744.738222222233
            },
            {
              "lat": 43.771629955555554,
              "lng": -116.09938503333333,
              "alt": 2072.8393333333333,
              "speed2d": 10.357888888888889,
              "speed3d": 10.358888888888888,
              "msFromStart": 31239.218155945422
            },
            {
              "lat": 43.77160876666666,
              "lng": -116.0993289,
              "alt": 2072.5105555555556,
              "speed2d": 10.384111111111114,
              "speed3d": 10.421111111111111,
              "msFromStart": 31734.06705263159
            },
            {
              "lat": 43.77158115555556,
              "lng": -116.09928195555555,
              "alt": 2072.0801111111114,
              "speed2d": 10.178,
              "speed3d": 10.25111111111111,
              "msFromStart": 32228.729939571152
            },
            {
              "lat": 43.77154936,
              "lng": -116.09923783,
              "alt": 2071.618,
              "speed2d": 9.9641,
              "speed3d": 9.991,
              "msFromStart": 32750.002166666658
            },
            {
              "lat": 43.77151665555556,
              "lng": -116.09919105555555,
              "alt": 2071.3888888888887,
              "speed2d": 10.053555555555555,
              "speed3d": 10.075555555555557,
              "msFromStart": 33271.64203703703
            },
            {
              "lat": 43.771491000000005,
              "lng": -116.09913845555555,
              "alt": 2070.3015555555558,
              "speed2d": 10.268888888888888,
              "speed3d": 10.308888888888891,
              "msFromStart": 33767.07177777777
            },
            {
              "lat": 43.771464800000004,
              "lng": -116.09908811111109,
              "alt": 2069.3388888888885,
              "speed2d": 10.372555555555556,
              "speed3d": 10.393333333333333,
              "msFromStart": 34262.70837037037
            },
            {
              "lat": 43.77143471111111,
              "lng": -116.09904047777778,
              "alt": 2067.8987777777775,
              "speed2d": 10.719777777777777,
              "speed3d": 10.703333333333333,
              "msFromStart": 34758.73200000002
            },
            {
              "lat": 43.77140137777778,
              "lng": -116.09899006666667,
              "alt": 2066.711111111111,
              "speed2d": 10.946888888888887,
              "speed3d": 11.080000000000002,
              "msFromStart": 35254.466035087724
            },
            {
              "lat": 43.77137284444444,
              "lng": -116.09895131111112,
              "alt": 2065.855111111111,
              "speed2d": 10.335444444444445,
              "speed3d": 10.428888888888888,
              "msFromStart": 35749.35873684208
            },
            {
              "lat": 43.7713413,
              "lng": -116.09891150000001,
              "alt": 2065.1102222222225,
              "speed2d": 10.100333333333332,
              "speed3d": 10.231111111111112,
              "msFromStart": 36244.23041325534
            },
            {
              "lat": 43.77131068888889,
              "lng": -116.0988614888889,
              "alt": 2065.232666666667,
              "speed2d": 10.148888888888889,
              "speed3d": 10.158888888888889,
              "msFromStart": 36739.19511111108
            },
            {
              "lat": 43.77127997777778,
              "lng": -116.09879647777777,
              "alt": 2066.1296666666667,
              "speed2d": 10.459777777777777,
              "speed3d": 10.476666666666668,
              "msFromStart": 37234.226888888865
            },
            {
              "lat": 43.77125019999999,
              "lng": -116.09872858888889,
              "alt": 2066.775,
              "speed2d": 10.881888888888888,
              "speed3d": 10.883333333333333,
              "msFromStart": 37729.48977777774
            },
            {
              "lat": 43.77121501,
              "lng": -116.09866086,
              "alt": 2067.2374999999997,
              "speed2d": 11.2425,
              "speed3d": 11.254999999999999,
              "msFromStart": 38252.22493333332
            },
            {
              "lat": 43.77118352222222,
              "lng": -116.09859036666667,
              "alt": 2067.3312222222226,
              "speed2d": 11.450222222222223,
              "speed3d": 11.432222222222222,
              "msFromStart": 38774.744
            },
            {
              "lat": 43.771149322222215,
              "lng": -116.09851447777777,
              "alt": 2068.7716666666665,
              "speed2d": 12.17211111111111,
              "speed3d": 12.11,
              "msFromStart": 39269.94466666668
            },
            {
              "lat": 43.77111127777778,
              "lng": -116.09844244444444,
              "alt": 2070.9396666666667,
              "speed2d": 12.546,
              "speed3d": 12.541111111111112,
              "msFromStart": 39765.69000000004
            },
            {
              "lat": 43.77107342222222,
              "lng": -116.09837300000001,
              "alt": 2073.6573333333336,
              "speed2d": 12.694222222222221,
              "speed3d": 12.674444444444443,
              "msFromStart": 40261.24417543862
            },
            {
              "lat": 43.77104326666667,
              "lng": -116.09830385555554,
              "alt": 2075.5261111111113,
              "speed2d": 12.668000000000001,
              "speed3d": 12.692222222222222,
              "msFromStart": 40756.20168421056
            },
            {
              "lat": 43.77101561111111,
              "lng": -116.09823540000001,
              "alt": 2076.9034444444446,
              "speed2d": 12.63477777777778,
              "speed3d": 12.607777777777779,
              "msFromStart": 41251.005918128656
            },
            {
              "lat": 43.77098612222222,
              "lng": -116.09816804444445,
              "alt": 2078.2702222222224,
              "speed2d": 12.626000000000001,
              "speed3d": 12.663333333333334,
              "msFromStart": 41745.32266666664
            },
            {
              "lat": 43.770957144444445,
              "lng": -116.09809996666667,
              "alt": 2079.6922222222224,
              "speed2d": 12.50566666666667,
              "speed3d": 12.502222222222224,
              "msFromStart": 42239.74844444444
            },
            {
              "lat": 43.77092184444444,
              "lng": -116.09802685555555,
              "alt": 2081.092333333333,
              "speed2d": 12.880222222222221,
              "speed3d": 12.809999999999999,
              "msFromStart": 42734.80533333337
            },
            {
              "lat": 43.77088582499999,
              "lng": -116.09795065,
              "alt": 2083.843,
              "speed2d": 13.79625,
              "speed3d": 13.665,
              "msFromStart": 43161.109500000035
            },
            {
              "lat": 43.7708458375,
              "lng": -116.09786242500002,
              "alt": 2085.906375,
              "speed2d": 13.674375000000001,
              "speed3d": 13.84,
              "msFromStart": 43724.65200000002
            },
            {
              "lat": 43.770833479869125,
              "lng": -116.0978279532509,
              "alt": 2086.6551016575727,
              "speed2d": 12.86171189154692,
              "speed3d": 13.068702528349442,
              "msFromStart": 44000
            },
            {
              "lat": 43.7707976,
              "lng": -116.09774175,
              "alt": 2086.64525,
              "speed2d": 10.69025,
              "speed3d": 10.837499999999999,
              "msFromStart": 44879.172444444455
            },
            {
              "lat": 43.7707873,
              "lng": -116.09771286666668,
              "alt": 2086.193333333333,
              "speed2d": 9.974666666666668,
              "speed3d": 10.108333333333333,
              "msFromStart": 45218.6110760234
            },
            {
              "lat": 43.77077416457295,
              "lng": -116.09768649824382,
              "alt": 2086.2837621349663,
              "speed2d": 9.833111642323665,
              "speed3d": 9.835221793947992,
              "msFromStart": 45500
            },
            {
              "lat": 43.7707368,
              "lng": -116.09762457142857,
              "alt": 2085.3405714285714,
              "speed2d": 9.429428571428572,
              "speed3d": 9.474285714285713,
              "msFromStart": 46273.76341854637
            },
            {
              "lat": 43.7707044,
              "lng": -116.09759030000001,
              "alt": 2083.987,
              "speed2d": 9.347000000000001,
              "speed3d": 9.309999999999999,
              "msFromStart": 46835.090888888866
            },
            {
              "lat": 43.770683266666666,
              "lng": -116.09756539999998,
              "alt": 2082.8823333333335,
              "speed2d": 9.369333333333334,
              "speed3d": 9.393333333333334,
              "msFromStart": 47210.647407407385
            },
            {
              "lat": 43.770666168859684,
              "lng": -116.09754918368837,
              "alt": 2081.9195604207216,
              "speed2d": 9.295980227254347,
              "speed3d": 9.414238962298082,
              "msFromStart": 47500
            },
            {
              "lat": 43.77063525,
              "lng": -116.09749515,
              "alt": 2080.41,
              "speed2d": 8.61,
              "speed3d": 8.535,
              "msFromStart": 48265.062666666636
            },
            {
              "lat": 43.77062272540487,
              "lng": -116.09747471134229,
              "alt": 2080.260505580321,
              "speed2d": 8.959572147720623,
              "speed3d": 8.857417055821655,
              "msFromStart": 48500
            },
            {
              "lat": 43.77058388333333,
              "lng": -116.09741991666667,
              "alt": 2078.070666666667,
              "speed2d": 9.4265,
              "speed3d": 9.388333333333334,
              "msFromStart": 49247.184074074066
            },
            {
              "lat": 43.77056171999999,
              "lng": -116.09738902000001,
              "alt": 2077.0588,
              "speed2d": 9.4306,
              "speed3d": 9.456000000000001,
              "msFromStart": 49668.596
            },
            {
              "lat": 43.77053765,
              "lng": -116.097353975,
              "alt": 2076.0507499999994,
              "speed2d": 9.274750000000001,
              "speed3d": 9.3025,
              "msFromStart": 50149.44096491228
            },
            {
              "lat": 43.770520187596496,
              "lng": -116.09733115790792,
              "alt": 2074.93321687576,
              "speed2d": 9.069342241264984,
              "speed3d": 9.09766622317452,
              "msFromStart": 50500
            },
            {
              "lat": 43.77049525519321,
              "lng": -116.09729888691047,
              "alt": 2073.5385703675556,
              "speed2d": 8.799005994340675,
              "speed3d": 8.806004147026483,
              "msFromStart": 51000
            },
            {
              "lat": 43.770472726692006,
              "lng": -116.0972743243395,
              "alt": 2072.581011823218,
              "speed2d": 7.64133894302694,
              "speed3d": 7.742830860054158,
              "msFromStart": 51500
            },
            {
              "lat": 43.770434914285715,
              "lng": -116.09721578571427,
              "alt": 2069.692428571429,
              "speed2d": 8.677142857142858,
              "speed3d": 8.668571428571429,
              "msFromStart": 52205.16466666669
            },
            {
              "lat": 43.770401633333336,
              "lng": -116.09716423333333,
              "alt": 2066.9866666666667,
              "speed2d": 8.818333333333333,
              "speed3d": 8.836666666666668,
              "msFromStart": 52894.8168888889
            },
            {
              "lat": 43.77038433333333,
              "lng": -116.0971427,
              "alt": 2066.012,
              "speed2d": 8.783,
              "speed3d": 8.833333333333334,
              "msFromStart": 53225.31814814816
            },
            {
              "lat": 43.77037545311567,
              "lng": -116.09712715311566,
              "alt": 2064.875253544753,
              "speed2d": 8.446126772377529,
              "speed3d": 8.6,
              "msFromStart": 53500
            },
            {
              "lat": 43.7703402,
              "lng": -116.0970915,
              "alt": 2065.1175000000003,
              "speed2d": 8.5845,
              "speed3d": 8.605,
              "msFromStart": 54042.67777777781
            },
            {
              "lat": 43.770307525,
              "lng": -116.09705995,
              "alt": 2064.6665,
              "speed2d": 8.52675,
              "speed3d": 8.5925,
              "msFromStart": 54593.50833333332
            },
            {
              "lat": 43.77026998,
              "lng": -116.09702759999999,
              "alt": 2063.5758,
              "speed2d": 8.3132,
              "speed3d": 8.350000000000001,
              "msFromStart": 55281.10831111111
            },
            {
              "lat": 43.77023569999999,
              "lng": -116.0969965,
              "alt": 2062.0108,
              "speed2d": 8.44,
              "speed3d": 8.486,
              "msFromStart": 55839.03013333336
            },
            {
              "lat": 43.7702089,
              "lng": -116.0969701,
              "alt": 2061.615,
              "speed2d": 8.439499999999999,
              "speed3d": 8.48,
              "msFromStart": 56293.19466666668
            },
            {
              "lat": 43.77017896666667,
              "lng": -116.09694016666668,
              "alt": 2060.490333333333,
              "speed2d": 8.504999999999999,
              "speed3d": 8.543333333333333,
              "msFromStart": 56795.19599999995
            },
            {
              "lat": 43.7701477,
              "lng": -116.09691035,
              "alt": 2059.445,
              "speed2d": 8.54875,
              "speed3d": 8.5975,
              "msFromStart": 57307.501333333326
            },
            {
              "lat": 43.77011955,
              "lng": -116.09688888333335,
              "alt": 2058.199,
              "speed2d": 8.584,
              "speed3d": 8.639999999999999,
              "msFromStart": 57748.678222222195
            },
            {
              "lat": 43.77009236,
              "lng": -116.09687374,
              "alt": 2056.7812,
              "speed2d": 8.6054,
              "speed3d": 8.654000000000002,
              "msFromStart": 58156.62036491227
            },
            {
              "lat": 43.77004475,
              "lng": -116.0968529,
              "alt": 2054.06,
              "speed2d": 9.030000000000001,
              "speed3d": 9.05,
              "msFromStart": 58739.714947368455
            },
            {
              "lat": 43.770022499999996,
              "lng": -116.0968431,
              "alt": 2053.03,
              "speed2d": 9.075,
              "speed3d": 9.184999999999999,
              "msFromStart": 59042.21842105268
            },
            {
              "lat": 43.76997266666667,
              "lng": -116.09682810000001,
              "alt": 2050.665,
              "speed2d": 8.967666666666666,
              "speed3d": 9.05,
              "msFromStart": 59803.59985185183
            },
            {
              "lat": 43.769952059999994,
              "lng": -116.09681854,
              "alt": 2049.4666,
              "speed2d": 8.9076,
              "speed3d": 9.038,
              "msFromStart": 60137.57053333331
            },
            {
              "lat": 43.76991941666667,
              "lng": -116.09680759999999,
              "alt": 2047.891,
              "speed2d": 8.814333333333334,
              "speed3d": 8.94,
              "msFromStart": 60666.193999999996
            },
            {
              "lat": 43.769876116666666,
              "lng": -116.09679456666669,
              "alt": 2046.3474999999999,
              "speed2d": 8.705166666666667,
              "speed3d": 8.831666666666667,
              "msFromStart": 61336.32211111111
            },
            {
              "lat": 43.769844250000006,
              "lng": -116.0967883,
              "alt": 2045.121,
              "speed2d": 8.61,
              "speed3d": 8.745000000000001,
              "msFromStart": 61823.12000000001
            },
            {
              "lat": 43.769826375,
              "lng": -116.09678212499999,
              "alt": 2043.5247499999998,
              "speed2d": 8.57175,
              "speed3d": 8.7125,
              "msFromStart": 62126.20288888889
            },
            {
              "lat": 43.769784071428575,
              "lng": -116.09677304285715,
              "alt": 2041.3557142857144,
              "speed2d": 8.355857142857143,
              "speed3d": 8.524285714285714,
              "msFromStart": 62798.318222222224
            },
            {
              "lat": 43.76975331999999,
              "lng": -116.09676403999998,
              "alt": 2039.8301999999999,
              "speed2d": 8.2182,
              "speed3d": 8.392,
              "msFromStart": 63286.96548304093
            },
            {
              "lat": 43.76972186666667,
              "lng": -116.09675680000001,
              "alt": 2037.6886666666664,
              "speed2d": 8.02,
              "speed3d": 8.223333333333334,
              "msFromStart": 63821.704210526295
            },
            {
              "lat": 43.76969168000001,
              "lng": -116.09674814000002,
              "alt": 2036.1690000000003,
              "speed2d": 7.884399999999999,
              "speed3d": 8.096,
              "msFromStart": 64309.06808888889
            },
            {
              "lat": 43.76965672857143,
              "lng": -116.09674332857142,
              "alt": 2036.357857142857,
              "speed2d": 7.7634285714285705,
              "speed3d": 7.971428571428571,
              "msFromStart": 64793.580444444444
            },
            {
              "lat": 43.76964588208305,
              "lng": -116.09674485787474,
              "alt": 2035.6299154116818,
              "speed2d": 7.5616299788881,
              "speed3d": 7.870302603800131,
              "msFromStart": 65000
            },
            {
              "lat": 43.76959348333333,
              "lng": -116.09673924999998,
              "alt": 2035.855,
              "speed2d": 7.129666666666667,
              "speed3d": 7.329999999999999,
              "msFromStart": 65810.85933333337
            },
            {
              "lat": 43.76956686,
              "lng": -116.0967336,
              "alt": 2034.8256000000001,
              "speed2d": 6.9108,
              "speed3d": 7.119999999999999,
              "msFromStart": 66288.96151111115
            },
            {
              "lat": 43.7695466,
              "lng": -116.096728025,
              "alt": 2034.06625,
              "speed2d": 6.78675,
              "speed3d": 6.9925,
              "msFromStart": 66649.01872222227
            },
            {
              "lat": 43.769506500000006,
              "lng": -116.09671835,
              "alt": 2032.6945,
              "speed2d": 6.6175,
              "speed3d": 6.82,
              "msFromStart": 67350.05577777777
            },
            {
              "lat": 43.7694882,
              "lng": -116.09671130000001,
              "alt": 2031.635,
              "speed2d": 6.506,
              "speed3d": 6.73,
              "msFromStart": 67707.6222222222
            },
            {
              "lat": 43.76945805,
              "lng": -116.0967018,
              "alt": 2030.4415,
              "speed2d": 6.474,
              "speed3d": 6.695,
              "msFromStart": 68248.6779454191
            },
            {
              "lat": 43.76943274,
              "lng": -116.09669082,
              "alt": 2028.4874,
              "speed2d": 6.461,
              "speed3d": 6.688000000000001,
              "msFromStart": 68753.48673684211
            },
            {
              "lat": 43.769399449999995,
              "lng": -116.09667815,
              "alt": 2025.9612499999998,
              "speed2d": 6.690250000000001,
              "speed3d": 6.8875,
              "msFromStart": 69372.53877777778
            },
            {
              "lat": 43.7693776,
              "lng": -116.09667167500001,
              "alt": 2024.5315,
              "speed2d": 6.82675,
              "speed3d": 7.0600000000000005,
              "msFromStart": 69743.2337777778
            },
            {
              "lat": 43.76934678333333,
              "lng": -116.09666641666668,
              "alt": 2023.2936666666665,
              "speed2d": 6.8615,
              "speed3d": 7.111666666666666,
              "msFromStart": 70237.52933333334
            },
            {
              "lat": 43.76931866,
              "lng": -116.09666704,
              "alt": 2023.1174,
              "speed2d": 6.892599999999999,
              "speed3d": 7.122,
              "msFromStart": 70644.03466666664
            },
            {
              "lat": 43.76927226666667,
              "lng": -116.09666943333332,
              "alt": 2023.0693333333334,
              "speed2d": 7.063666666666666,
              "speed3d": 7.263333333333334,
              "msFromStart": 71281.52444444445
            },
            {
              "lat": 43.7692415,
              "lng": -116.09667438333334,
              "alt": 2023.3843333333334,
              "speed2d": 7.2026666666666666,
              "speed3d": 7.3950000000000005,
              "msFromStart": 71676.07970370376
            },
            {
              "lat": 43.76919617777777,
              "lng": -116.09668190000002,
              "alt": 2023.2361111111109,
              "speed2d": 7.448666666666666,
              "speed3d": 7.6288888888888895,
              "msFromStart": 72272.41333333336
            },
            {
              "lat": 43.76916846,
              "lng": -116.09668438,
              "alt": 2022.8296000000003,
              "speed2d": 7.477,
              "speed3d": 7.666,
              "msFromStart": 72668.54737777777
            },
            {
              "lat": 43.7691187375,
              "lng": -116.09669167499999,
              "alt": 2022.6605,
              "speed2d": 7.774999999999999,
              "speed3d": 7.9375,
              "msFromStart": 73290.31657309941
            },
            {
              "lat": 43.76908341428571,
              "lng": -116.0966975,
              "alt": 2022.4201428571428,
              "speed2d": 7.819857142857142,
              "speed3d": 7.9885714285714275,
              "msFromStart": 73758.21010526319
            },
            {
              "lat": 43.76906469628876,
              "lng": -116.09670141858088,
              "alt": 2022.3842280718031,
              "speed2d": 7.847616528905044,
              "speed3d": 8.007837815606667,
              "msFromStart": 74000
            },
            {
              "lat": 43.769015375,
              "lng": -116.096703625,
              "alt": 2022.0910000000001,
              "speed2d": 8.062999999999999,
              "speed3d": 8.2225,
              "msFromStart": 74611.24400000004
            },
            {
              "lat": 43.76897378571428,
              "lng": -116.09670985714287,
              "alt": 2021.9342857142856,
              "speed2d": 7.822571428571429,
              "speed3d": 8.02,
              "msFromStart": 75205.02412698415
            },
            {
              "lat": 43.768940300000004,
              "lng": -116.09671689999999,
              "alt": 2021.812,
              "speed2d": 7.572,
              "speed3d": 7.74,
              "msFromStart": 75705.24237037032
            },
            {
              "lat": 43.768896142857145,
              "lng": -116.09673021428571,
              "alt": 2021.9250000000002,
              "speed2d": 7.642285714285714,
              "speed3d": 7.797142857142857,
              "msFromStart": 76287.01092063492
            },
            {
              "lat": 43.76885853333333,
              "lng": -116.09674063333333,
              "alt": 2022.0586666666666,
              "speed2d": 7.634,
              "speed3d": 7.783333333333333,
              "msFromStart": 76791.27288888895
            },
            {
              "lat": 43.7688271,
              "lng": -116.09674531249999,
              "alt": 2021.9036250000001,
              "speed2d": 7.678875000000001,
              "speed3d": 7.825,
              "msFromStart": 77217.60933333337
            },
            {
              "lat": 43.76879746666667,
              "lng": -116.09675023333332,
              "alt": 2021.7473333333332,
              "speed2d": 7.724333333333334,
              "speed3d": 7.8566666666666665,
              "msFromStart": 77612.82488888883
            },
            {
              "lat": 43.768750600000004,
              "lng": -116.09675983333332,
              "alt": 2020.8253333333332,
              "speed2d": 7.706666666666667,
              "speed3d": 7.856666666666667,
              "msFromStart": 78285.50807017543
            },
            {
              "lat": 43.768710150000004,
              "lng": -116.09676250000001,
              "alt": 2020.2555,
              "speed2d": 7.634,
              "speed3d": 7.775,
              "msFromStart": 78871.98610526313
            },
            {
              "lat": 43.76868018571429,
              "lng": -116.09676342857142,
              "alt": 2019.8249999999998,
              "speed2d": 7.742571428571429,
              "speed3d": 7.872857142857143,
              "msFromStart": 79272.50967418545
            },
            {
              "lat": 43.768646233333335,
              "lng": -116.09675863333334,
              "alt": 2019.4705000000001,
              "speed2d": 7.827333333333334,
              "speed3d": 7.961666666666667,
              "msFromStart": 79723.8691111111
            },
            {
              "lat": 43.76860532857143,
              "lng": -116.09675352857144,
              "alt": 2019.2498571428573,
              "speed2d": 7.949428571428572,
              "speed3d": 8.067142857142857,
              "msFromStart": 80246.07117460316
            },
            {
              "lat": 43.768583796053804,
              "lng": -116.09675254046309,
              "alt": 2019.1533469345252,
              "speed2d": 8.073485962990963,
              "speed3d": 8.177772311302093,
              "msFromStart": 80500
            },
            {
              "lat": 43.7685138,
              "lng": -116.0967481,
              "alt": 2018.4115000000002,
              "speed2d": 8.2645,
              "speed3d": 8.375,
              "msFromStart": 81374.63555555555
            },
            {
              "lat": 43.768490112500004,
              "lng": -116.09674451250001,
              "alt": 2017.942125,
              "speed2d": 8.220875,
              "speed3d": 8.3575,
              "msFromStart": 81711.73377777777
            },
            {
              "lat": 43.76845665714286,
              "lng": -116.09673851428572,
              "alt": 2016.7634285714287,
              "speed2d": 8.288,
              "speed3d": 8.39857142857143,
              "msFromStart": 82188.38920634921
            },
            {
              "lat": 43.768409028571426,
              "lng": -116.0967305,
              "alt": 2015.803142857143,
              "speed2d": 8.504857142857142,
              "speed3d": 8.608571428571429,
              "msFromStart": 82770.2073650794
            },
            {
              "lat": 43.76837341428572,
              "lng": -116.09671872857143,
              "alt": 2015.0622857142855,
              "speed2d": 8.583857142857143,
              "speed3d": 8.714285714285715,
              "msFromStart": 83234.32209523811
            },
            {
              "lat": 43.768332,
              "lng": -116.0967,
              "alt": 2013.9544999999998,
              "speed2d": 8.6775,
              "speed3d": 8.79,
              "msFromStart": 83797.81111111106
            },
            {
              "lat": 43.76831947028194,
              "lng": -116.09669589869844,
              "alt": 2013.4306225552762,
              "speed2d": 8.620933622738697,
              "speed3d": 8.731605206239534,
              "msFromStart": 84000
            },
            {
              "lat": 43.7682729625,
              "lng": -116.0966602,
              "alt": 2009.6961250000002,
              "speed2d": 8.740375,
              "speed3d": 8.87125,
              "msFromStart": 84789.9875789473
            },
            {
              "lat": 43.768250275,
              "lng": -116.0966417,
              "alt": 2007.7295,
              "speed2d": 8.75,
              "speed3d": 8.8775,
              "msFromStart": 85203.08792982451
            },
            {
              "lat": 43.76821982,
              "lng": -116.09661883999999,
              "alt": 2005.3232,
              "speed2d": 8.919,
              "speed3d": 9.036,
              "msFromStart": 85687.36373333339
            },
            {
              "lat": 43.768199348319754,
              "lng": -116.09660381383611,
              "alt": 2003.8043827056802,
              "speed2d": 9.039723988661054,
              "speed3d": 9.168965361287668,
              "msFromStart": 86000
            },
            {
              "lat": 43.76815933333334,
              "lng": -116.09656506666667,
              "alt": 2000.6208333333334,
              "speed2d": 9.145166666666668,
              "speed3d": 9.293333333333333,
              "msFromStart": 86679.67207407409
            },
            {
              "lat": 43.76812401428572,
              "lng": -116.0965392857143,
              "alt": 1997.893857142857,
              "speed2d": 9.224857142857143,
              "speed3d": 9.374285714285714,
              "msFromStart": 87246.91434920636
            },
            {
              "lat": 43.7680914,
              "lng": -116.09651216666666,
              "alt": 1995.354,
              "speed2d": 9.254666666666665,
              "speed3d": 9.413333333333334,
              "msFromStart": 87788.72711111113
            },
            {
              "lat": 43.768068899999996,
              "lng": -116.09649368000001,
              "alt": 1993.5069999999998,
              "speed2d": 9.193,
              "speed3d": 9.376,
              "msFromStart": 88184.22888888892
            },
            {
              "lat": 43.768034400000005,
              "lng": -116.09646606666666,
              "alt": 1991.038,
              "speed2d": 9.181999999999999,
              "speed3d": 9.346666666666668,
              "msFromStart": 88757.95318518525
            },
            {
              "lat": 43.7680023,
              "lng": -116.0964401,
              "alt": 1988.955,
              "speed2d": 9.3135,
              "speed3d": 9.485,
              "msFromStart": 89242.43115789472
            },
            {
              "lat": 43.76796544,
              "lng": -116.09641461999999,
              "alt": 1987.1076,
              "speed2d": 9.4946,
              "speed3d": 9.639999999999999,
              "msFromStart": 89743.82391578943
            },
            {
              "lat": 43.767946614334605,
              "lng": -116.09640076253162,
              "alt": 1986.0393958630239,
              "speed2d": 9.594732056949523,
              "speed3d": 9.751785625721157,
              "msFromStart": 90000
            },
            {
              "lat": 43.767886425,
              "lng": -116.09636177500002,
              "alt": 1982.382,
              "speed2d": 9.69725,
              "speed3d": 9.875,
              "msFromStart": 90838.38133333334
            },
            {
              "lat": 43.7678597,
              "lng": -116.09634422,
              "alt": 1980.7968,
              "speed2d": 9.7588,
              "speed3d": 9.937999999999999,
              "msFromStart": 91206.4112
            },
            {
              "lat": 43.767817550000004,
              "lng": -116.09631695,
              "alt": 1978.2455,
              "speed2d": 9.8095,
              "speed3d": 10,
              "msFromStart": 91799.39199999995
            },
            {
              "lat": 43.76778543333334,
              "lng": -116.09629396666668,
              "alt": 1976.3816666666667,
              "speed2d": 9.810666666666666,
              "speed3d": 10.006666666666666,
              "msFromStart": 92257.11674074075
            },
            {
              "lat": 43.76774892857143,
              "lng": -116.0962642,
              "alt": 1974.172,
              "speed2d": 9.84142857142857,
              "speed3d": 10.044285714285714,
              "msFromStart": 92789.24311111117
            },
            {
              "lat": 43.767715214285715,
              "lng": -116.09623562857143,
              "alt": 1972.1070000000002,
              "speed2d": 9.900142857142857,
              "speed3d": 10.09,
              "msFromStart": 93276.6147936508
            },
            {
              "lat": 43.76768748333333,
              "lng": -116.09621098333332,
              "alt": 1970.4178333333334,
              "speed2d": 9.875333333333334,
              "speed3d": 10.084999999999999,
              "msFromStart": 93696.67488888885
            },
            {
              "lat": 43.767647475000004,
              "lng": -116.0961711,
              "alt": 1967.8515,
              "speed2d": 9.913499999999999,
              "speed3d": 10.1125,
              "msFromStart": 94315.06521637426
            },
            {
              "lat": 43.76762136666667,
              "lng": -116.09614290000002,
              "alt": 1966.35,
              "speed2d": 9.942166666666667,
              "speed3d": 10.156666666666666,
              "msFromStart": 94722.91417543867
            },
            {
              "lat": 43.76758433333333,
              "lng": -116.09610324999998,
              "alt": 1965.9893333333334,
              "speed2d": 9.792000000000002,
              "speed3d": 10.009999999999998,
              "msFromStart": 95273.02561403513
            },
            {
              "lat": 43.76755103333333,
              "lng": -116.09606683333334,
              "alt": 1965.7421666666664,
              "speed2d": 9.697,
              "speed3d": 9.92,
              "msFromStart": 95759.5146666667
            },
            {
              "lat": 43.76753770046974,
              "lng": -116.09604720169992,
              "alt": 1965.4175141310382,
              "speed2d": 9.597286885880578,
              "speed3d": 9.8016334899147,
              "msFromStart": 96000
            },
            {
              "lat": 43.767497133333336,
              "lng": -116.09598315,
              "alt": 1962.9971666666665,
              "speed2d": 9.5375,
              "speed3d": 9.766666666666667,
              "msFromStart": 96778.81466666664
            },
            {
              "lat": 43.76748938888889,
              "lng": -116.09593464444444,
              "alt": 1961.6094444444443,
              "speed2d": 9.223333333333334,
              "speed3d": 9.483333333333334,
              "msFromStart": 97246.75348148147
            },
            {
              "lat": 43.7674743,
              "lng": -116.09588744999999,
              "alt": 1960.46,
              "speed2d": 8.918,
              "speed3d": 9.155000000000001,
              "msFromStart": 97767.72400000007
            },
            {
              "lat": 43.7674598,
              "lng": -116.0958554,
              "alt": 1959.996,
              "speed2d": 8.7315,
              "speed3d": 8.975,
              "msFromStart": 98151.66088888893
            },
            {
              "lat": 43.76744216,
              "lng": -116.09581296,
              "alt": 1959.742,
              "speed2d": 8.6486,
              "speed3d": 8.878,
              "msFromStart": 98630.06808888889
            },
            {
              "lat": 43.767432,
              "lng": -116.09574420000001,
              "alt": 1959.2793333333334,
              "speed2d": 8.535333333333334,
              "speed3d": 8.746666666666666,
              "msFromStart": 99297.62495126703
            },
            {
              "lat": 43.767424899999995,
              "lng": -116.09571035,
              "alt": 1959.29,
              "speed2d": 8.666,
              "speed3d": 8.84,
              "msFromStart": 99609.3018947368
            },
            {
              "lat": 43.76741135,
              "lng": -116.09564455,
              "alt": 1959.848,
              "speed2d": 8.781,
              "speed3d": 8.975,
              "msFromStart": 100241.77726315784
            },
            {
              "lat": 43.7673992,
              "lng": -116.09559196666666,
              "alt": 1960.9923333333331,
              "speed2d": 8.721666666666666,
              "speed3d": 8.923333333333334,
              "msFromStart": 100782.53866666666
            },
            {
              "lat": 43.7673877,
              "lng": -116.09554928333336,
              "alt": 1962.1996666666666,
              "speed2d": 8.622,
              "speed3d": 8.808333333333334,
              "msFromStart": 101240.95333333334
            },
            {
              "lat": 43.76737267142857,
              "lng": -116.09549548571428,
              "alt": 1963.6168571428573,
              "speed2d": 8.680142857142858,
              "speed3d": 8.85,
              "msFromStart": 101778.66095238099
            },
            {
              "lat": 43.76735585714286,
              "lng": -116.0954494,
              "alt": 1964.753,
              "speed2d": 8.59442857142857,
              "speed3d": 8.778571428571428,
              "msFromStart": 102274.42266666668
            },
            {
              "lat": 43.767333183333335,
              "lng": -116.09540531666666,
              "alt": 1966.0001666666667,
              "speed2d": 8.225666666666665,
              "speed3d": 8.435,
              "msFromStart": 102829.02733333333
            },
            {
              "lat": 43.76731098,
              "lng": -116.09537078,
              "alt": 1966.7924,
              "speed2d": 7.855200000000001,
              "speed3d": 8.048,
              "msFromStart": 103318.87982222224
            },
            {
              "lat": 43.76729197142857,
              "lng": -116.09534260000001,
              "alt": 1967.5292857142856,
              "speed2d": 7.619285714285714,
              "speed3d": 7.804285714285713,
              "msFromStart": 103728.93276190483
            },
            {
              "lat": 43.76726673333334,
              "lng": -116.09530905,
              "alt": 1968.2646666666667,
              "speed2d": 7.416833333333333,
              "speed3d": 7.591666666666667,
              "msFromStart": 104240.96100194934
            },
            {
              "lat": 43.7672431,
              "lng": -116.09527774,
              "alt": 1968.7362000000003,
              "speed2d": 7.4224,
              "speed3d": 7.566,
              "msFromStart": 104694.24176842105
            },
            {
              "lat": 43.7672121875,
              "lng": -116.0952352125,
              "alt": 1969.281125,
              "speed2d": 7.471875,
              "speed3d": 7.608750000000001,
              "msFromStart": 105291.40105263157
            },
            {
              "lat": 43.76719156666667,
              "lng": -116.09520763333335,
              "alt": 1969.5116666666668,
              "speed2d": 7.480833333333334,
              "speed3d": 7.625,
              "msFromStart": 105689.65999999999
            },
            {
              "lat": 43.767166,
              "lng": -116.0951742,
              "alt": 1969.739,
              "speed2d": 7.4755,
              "speed3d": 7.609999999999999,
              "msFromStart": 106183.98244444442
            },
            {
              "lat": 43.767134666666664,
              "lng": -116.09513213333332,
              "alt": 1970.051,
              "speed2d": 7.382666666666667,
              "speed3d": 7.533333333333333,
              "msFromStart": 106816.45377777773
            },
            {
              "lat": 43.7671079,
              "lng": -116.09509336666667,
              "alt": 1970.4843333333333,
              "speed2d": 7.221333333333334,
              "speed3d": 7.35,
              "msFromStart": 107403.47570370368
            },
            {
              "lat": 43.767095,
              "lng": -116.09507365,
              "alt": 1970.6950000000002,
              "speed2d": 7.274,
              "speed3d": 7.390000000000001,
              "msFromStart": 107669.7322222222
            },
            {
              "lat": 43.76706873333333,
              "lng": -116.09503038333334,
              "alt": 1971.2415,
              "speed2d": 7.214666666666667,
              "speed3d": 7.353333333333333,
              "msFromStart": 108275.57459259257
            },
            {
              "lat": 43.76704686,
              "lng": -116.09499353999999,
              "alt": 1972.0629999999999,
              "speed2d": 6.921000000000001,
              "speed3d": 7.066000000000001,
              "msFromStart": 108853.24533333324
            },
            {
              "lat": 43.767034744444445,
              "lng": -116.09497036666667,
              "alt": 1972.6585555555555,
              "speed2d": 6.659888888888889,
              "speed3d": 6.823333333333332,
              "msFromStart": 109237.85715399605
            },
            {
              "lat": 43.76702546,
              "lng": -116.09494652000001,
              "alt": 1973.1938,
              "speed2d": 6.3756,
              "speed3d": 6.534,
              "msFromStart": 109621.08589473681
            },
            {
              "lat": 43.76701122,
              "lng": -116.0949107,
              "alt": 1973.8272,
              "speed2d": 6.076600000000001,
              "speed3d": 6.216,
              "msFromStart": 110190.51874619877
            },
            {
              "lat": 43.76699576666667,
              "lng": -116.09487138333333,
              "alt": 1974.2583333333332,
              "speed2d": 5.964666666666666,
              "speed3d": 6.088333333333333,
              "msFromStart": 110793.04296296295
            },
            {
              "lat": 43.76698385,
              "lng": -116.09483985,
              "alt": 1974.2306666666664,
              "speed2d": 5.939500000000001,
              "speed3d": 6.058333333333334,
              "msFromStart": 111280.1482962963
            },
            {
              "lat": 43.766973740000005,
              "lng": -116.09480996000002,
              "alt": 1974.0552000000002,
              "speed2d": 5.896800000000001,
              "speed3d": 6.022,
              "msFromStart": 111755.45875555556
            },
            {
              "lat": 43.766964200000004,
              "lng": -116.0947824,
              "alt": 1973.9053333333334,
              "speed2d": 5.8726666666666665,
              "speed3d": 5.991666666666667,
              "msFromStart": 112188.43985185184
            },
            {
              "lat": 43.76695321428571,
              "lng": -116.09474971428573,
              "alt": 1973.8135714285715,
              "speed2d": 5.803142857142857,
              "speed3d": 5.932857142857143,
              "msFromStart": 112717.0915555555
            },
            {
              "lat": 43.76694388,
              "lng": -116.09472234,
              "alt": 1973.9425999999999,
              "speed2d": 5.6838,
              "speed3d": 5.816,
              "msFromStart": 113174.14773333329
            },
            {
              "lat": 43.7669275,
              "lng": -116.09468145,
              "alt": 1974.0575,
              "speed2d": 5.5295,
              "speed3d": 5.65,
              "msFromStart": 113885.89466666663
            },
            {
              "lat": 43.7669132,
              "lng": -116.09465276666667,
              "alt": 1974.172,
              "speed2d": 5.449,
              "speed3d": 5.57,
              "msFromStart": 114409.02357894737
            },
            {
              "lat": 43.76690646666667,
              "lng": -116.09464046666666,
              "alt": 1974.1323333333335,
              "speed2d": 5.342,
              "speed3d": 5.473333333333333,
              "msFromStart": 114665.15775438596
            },
            {
              "lat": 43.76689265,
              "lng": -116.0946131,
              "alt": 1974.1595,
              "speed2d": 5.188000000000001,
              "speed3d": 5.305,
              "msFromStart": 115204.93354385965
            },
            {
              "lat": 43.7668839,
              "lng": -116.09459635,
              "alt": 1974.1689999999999,
              "speed2d": 5.1145,
              "speed3d": 5.24,
              "msFromStart": 115534.42088888887
            },
            {
              "lat": 43.76686297142858,
              "lng": -116.09455847142857,
              "alt": 1974.1315714285715,
              "speed2d": 4.960714285714285,
              "speed3d": 5.078571428571428,
              "msFromStart": 116315.4796190476
            },
            {
              "lat": 43.766849925,
              "lng": -116.094534575,
              "alt": 1974.1324999999997,
              "speed2d": 4.92975,
              "speed3d": 5.05,
              "msFromStart": 116799.17599999998
            },
            {
              "lat": 43.7668366,
              "lng": -116.09450718333332,
              "alt": 1974.0641666666668,
              "speed2d": 4.886833333333334,
              "speed3d": 5.008333333333333,
              "msFromStart": 117340.41244444443
            },
            {
              "lat": 43.766823339999995,
              "lng": -116.09448044000001,
              "alt": 1974.2286,
              "speed2d": 4.937000000000001,
              "speed3d": 5.026,
              "msFromStart": 117816.93066666662
            },
            {
              "lat": 43.76680856666667,
              "lng": -116.0944508888889,
              "alt": 1974.787888888889,
              "speed2d": 5.065111111111111,
              "speed3d": 5.1644444444444435,
              "msFromStart": 118257.19688888887
            },
            {
              "lat": 43.76680003489285,
              "lng": -116.0944359223668,
              "alt": 1975.0930619623557,
              "speed2d": 5.069951130209473,
              "speed3d": 5.173351680628323,
              "msFromStart": 118500
            },
            {
              "lat": 43.766772585714286,
              "lng": -116.0943940857143,
              "alt": 1976.2805714285716,
              "speed2d": 4.8627142857142855,
              "speed3d": 4.965714285714286,
              "msFromStart": 119274.2873884712
            },
            {
              "lat": 43.7667644351493,
              "lng": -116.0943817237311,
              "alt": 1976.5909357774271,
              "speed2d": 4.827509965835803,
              "speed3d": 4.937155638625471,
              "msFromStart": 119500
            }
          ]
        }
      ]
    },
    {
      "id": "trail-bogus-creek",
      "pathType": "trail",
      "name": "Bogus Creek Trail",
      "difficulty": "green",
      "videos": [
        {
          "videoId": "GX010068",
          "pathId": "trail-bogus-creek",
          "startSec": 234,
          "endSec": 345,
          "id": "video-segment-31",
          "gps": [
            {
              "lat": 43.76146413333334,
              "lng": -116.09395016666667,
              "alt": 1959.1061666666667,
              "speed2d": 14.544333333333332,
              "speed3d": 14.956666666666667,
              "msFromStart": 234177.7919688108
            },
            {
              "lat": 43.76152546666666,
              "lng": -116.09402793333334,
              "alt": 1957.7749999999999,
              "speed2d": 14.653666666666666,
              "speed3d": 15.043333333333333,
              "msFromStart": 234783.48547368415
            },
            {
              "lat": 43.7615792375,
              "lng": -116.0940910875,
              "alt": 1956.279375,
              "speed2d": 14.66925,
              "speed3d": 15.028750000000002,
              "msFromStart": 235306.76650877183
            },
            {
              "lat": 43.7616207,
              "lng": -116.09414003333335,
              "alt": 1955.1635,
              "speed2d": 14.430333333333333,
              "speed3d": 14.803333333333333,
              "msFromStart": 235718.87466666667
            },
            {
              "lat": 43.7616754,
              "lng": -116.09421116000001,
              "alt": 1953.9208,
              "speed2d": 14.4662,
              "speed3d": 14.760000000000002,
              "msFromStart": 236256.9152
            },
            {
              "lat": 43.761743133333326,
              "lng": -116.09430723333332,
              "alt": 1952.9666666666667,
              "speed2d": 14.799666666666667,
              "speed3d": 15.043333333333333,
              "msFromStart": 236927.4933333333
            },
            {
              "lat": 43.7617672,
              "lng": -116.09434291666668,
              "alt": 1952.7873333333332,
              "speed2d": 14.711666666666666,
              "speed3d": 15.041666666666668,
              "msFromStart": 237174.90933333326
            },
            {
              "lat": 43.7618221,
              "lng": -116.0944216,
              "alt": 1952.3605,
              "speed2d": 14.3445,
              "speed3d": 14.565000000000001,
              "msFromStart": 237752.48711111108
            },
            {
              "lat": 43.76184693873415,
              "lng": -116.09445045444748,
              "alt": 1952.0005581579464,
              "speed2d": 14.267860218339314,
              "speed3d": 14.4699328550047,
              "msFromStart": 238000
            },
            {
              "lat": 43.761896651683166,
              "lng": -116.09450696172377,
              "alt": 1951.3214617840392,
              "speed2d": 14.023331707279132,
              "speed3d": 14.184032945236948,
              "msFromStart": 238500
            },
            {
              "lat": 43.76197835,
              "lng": -116.09459975,
              "alt": 1950.1970000000001,
              "speed2d": 13.642,
              "speed3d": 13.780000000000001,
              "msFromStart": 239321.58088888886
            },
            {
              "lat": 43.762028475,
              "lng": -116.0946537,
              "alt": 1949.878,
              "speed2d": 13.103,
              "speed3d": 13.375,
              "msFromStart": 239843.49473684208
            },
            {
              "lat": 43.762067959999996,
              "lng": -116.09469118,
              "alt": 1949.2802000000001,
              "speed2d": 12.645800000000001,
              "speed3d": 12.892000000000001,
              "msFromStart": 240260.98543157894
            },
            {
              "lat": 43.76209123928621,
              "lng": -116.09471304957931,
              "alt": 1948.9971832888707,
              "speed2d": 12.522360438470486,
              "speed3d": 12.69979341952254,
              "msFromStart": 240500
            },
            {
              "lat": 43.76213824905311,
              "lng": -116.09475756244015,
              "alt": 1948.0709185894646,
              "speed2d": 12.214617109272965,
              "speed3d": 12.415947975620373,
              "msFromStart": 241000
            },
            {
              "lat": 43.76218733935206,
              "lng": -116.09479439907851,
              "alt": 1946.9051284283848,
              "speed2d": 12.139428203453784,
              "speed3d": 12.270590625453497,
              "msFromStart": 241500
            },
            {
              "lat": 43.76227605,
              "lng": -116.09486355,
              "alt": 1945.263,
              "speed2d": 12.204999999999998,
              "speed3d": 12.36,
              "msFromStart": 242403.0151111111
            },
            {
              "lat": 43.7623143625,
              "lng": -116.0948893375,
              "alt": 1944.4203750000001,
              "speed2d": 12.107750000000001,
              "speed3d": 12.298750000000002,
              "msFromStart": 242787.72266666667
            },
            {
              "lat": 43.76235778888889,
              "lng": -116.09491714444444,
              "alt": 1943.291888888889,
              "speed2d": 11.696555555555555,
              "speed3d": 11.95,
              "msFromStart": 243254.88059259258
            },
            {
              "lat": 43.76240538571428,
              "lng": -116.09495147142857,
              "alt": 1942.711,
              "speed2d": 11.328857142857142,
              "speed3d": 11.53857142857143,
              "msFromStart": 243773.33561904763
            },
            {
              "lat": 43.76245350000001,
              "lng": -116.09498450000001,
              "alt": 1942.0140000000001,
              "speed2d": 11.3315,
              "speed3d": 11.475,
              "msFromStart": 244272.24
            },
            {
              "lat": 43.76247743197604,
              "lng": -116.0949995113089,
              "alt": 1941.7638102991323,
              "speed2d": 11.480566468421566,
              "speed3d": 11.559566468421565,
              "msFromStart": 244500
            },
            {
              "lat": 43.762529516374215,
              "lng": -116.09503156091824,
              "alt": 1941.2021098748442,
              "speed2d": 11.61675461008628,
              "speed3d": 11.695754610086281,
              "msFromStart": 245000
            },
            {
              "lat": 43.76258160077239,
              "lng": -116.09506361052759,
              "alt": 1940.6404094505558,
              "speed2d": 11.752942751750997,
              "speed3d": 11.831942751750997,
              "msFromStart": 245500
            },
            {
              "lat": 43.762656388888885,
              "lng": -116.09510850000001,
              "alt": 1939.884222222222,
              "speed2d": 11.800222222222223,
              "speed3d": 11.977777777777776,
              "msFromStart": 246227.142617284
            },
            {
              "lat": 43.76270785,
              "lng": -116.09513645000001,
              "alt": 1939.4195,
              "speed2d": 11.481,
              "speed3d": 11.64,
              "msFromStart": 246748.32133333338
            },
            {
              "lat": 43.76274816666667,
              "lng": -116.095161,
              "alt": 1938.8233333333333,
              "speed2d": 11.451666666666666,
              "speed3d": 11.613333333333333,
              "msFromStart": 247158.98133333348
            },
            {
              "lat": 43.76281893333333,
              "lng": -116.0952039,
              "alt": 1938.365,
              "speed2d": 10.847333333333333,
              "speed3d": 11.06,
              "msFromStart": 247927.98222222232
            },
            {
              "lat": 43.762831725,
              "lng": -116.095209575,
              "alt": 1937.8055,
              "speed2d": 10.3375,
              "speed3d": 10.647499999999999,
              "msFromStart": 248120.4760000001
            },
            {
              "lat": 43.76288374,
              "lng": -116.09523854000003,
              "alt": 1936.0872,
              "speed2d": 9.8958,
              "speed3d": 10.004,
              "msFromStart": 248796.9194666666
            },
            {
              "lat": 43.76291775000001,
              "lng": -116.09525947499999,
              "alt": 1935.8177500000002,
              "speed2d": 10.066749999999999,
              "speed3d": 10.195,
              "msFromStart": 249179.1262222221
            },
            {
              "lat": 43.76294475037845,
              "lng": -116.0952749975808,
              "alt": 1935.2405992750355,
              "speed2d": 10.059324843518915,
              "speed3d": 10.22990514819288,
              "msFromStart": 249500
            },
            {
              "lat": 43.76299163807612,
              "lng": -116.09530552382016,
              "alt": 1935.1307654113457,
              "speed2d": 10.375191885992395,
              "speed3d": 10.487446621672456,
              "msFromStart": 250000
            },
            {
              "lat": 43.7630626,
              "lng": -116.09535295,
              "alt": 1934.718,
              "speed2d": 10.773,
              "speed3d": 10.795,
              "msFromStart": 250760.54488888886
            },
            {
              "lat": 43.76310321666667,
              "lng": -116.09538186666666,
              "alt": 1934.1470000000002,
              "speed2d": 10.880666666666666,
              "speed3d": 11.020000000000001,
              "msFromStart": 251200.70844444438
            },
            {
              "lat": 43.76313872,
              "lng": -116.09540794,
              "alt": 1933.6619999999998,
              "speed2d": 10.656399999999998,
              "speed3d": 10.806000000000001,
              "msFromStart": 251613.18755555555
            },
            {
              "lat": 43.76317273598146,
              "lng": -116.09543308520807,
              "alt": 1933.3298888964634,
              "speed2d": 10.708748239228143,
              "speed3d": 10.73422409431922,
              "msFromStart": 252000
            },
            {
              "lat": 43.76324436666667,
              "lng": -116.09548715,
              "alt": 1932.6696666666667,
              "speed2d": 10.751166666666666,
              "speed3d": 10.905000000000001,
              "msFromStart": 252796.23733333344
            },
            {
              "lat": 43.763278920000005,
              "lng": -116.09551416,
              "alt": 1932.6674,
              "speed2d": 10.503400000000001,
              "speed3d": 10.614,
              "msFromStart": 253209.61066666688
            },
            {
              "lat": 43.76332805,
              "lng": -116.09555397499999,
              "alt": 1932.11725,
              "speed2d": 10.516749999999998,
              "speed3d": 10.6275,
              "msFromStart": 253787.19999999992
            },
            {
              "lat": 43.763358925000006,
              "lng": -116.09557974999998,
              "alt": 1931.7160000000001,
              "speed2d": 10.331999999999999,
              "speed3d": 10.455,
              "msFromStart": 254171.9573333332
            },
            {
              "lat": 43.76340405714286,
              "lng": -116.09562054285715,
              "alt": 1931.023714285714,
              "speed2d": 10.105285714285715,
              "speed3d": 10.211428571428572,
              "msFromStart": 254756.87735338355
            },
            {
              "lat": 43.76344460000001,
              "lng": -116.0956563,
              "alt": 1930.95425,
              "speed2d": 9.872250000000001,
              "speed3d": 9.9775,
              "msFromStart": 255284.865637427
            },
            {
              "lat": 43.76347922222222,
              "lng": -116.09568803333335,
              "alt": 1930.7416666666666,
              "speed2d": 9.796222222222223,
              "speed3d": 9.892222222222223,
              "msFromStart": 255738.71466666664
            },
            {
              "lat": 43.76349768709011,
              "lng": -116.09570685599554,
              "alt": 1930.3327668304264,
              "speed2d": 9.70472263586573,
              "speed3d": 9.803215586949365,
              "msFromStart": 256000
            },
            {
              "lat": 43.763551525000004,
              "lng": -116.095757625,
              "alt": 1930.0875,
              "speed2d": 9.59675,
              "speed3d": 9.6775,
              "msFromStart": 256742.5686666666
            },
            {
              "lat": 43.76356884145819,
              "lng": -116.09577686037584,
              "alt": 1929.9019548284857,
              "speed2d": 9.14029740744933,
              "speed3d": 9.560630589833577,
              "msFromStart": 257000
            },
            {
              "lat": 43.76360644114333,
              "lng": -116.09581130165613,
              "alt": 1929.6431124355097,
              "speed2d": 9.426592507533982,
              "speed3d": 9.477167655922331,
              "msFromStart": 257500
            },
            {
              "lat": 43.76364116707381,
              "lng": -116.09584678941727,
              "alt": 1929.1479315865547,
              "speed2d": 9.280710539318685,
              "speed3d": 9.307024488457689,
              "msFromStart": 258000
            },
            {
              "lat": 43.763672943653,
              "lng": -116.09588566594059,
              "alt": 1928.5933566827216,
              "speed2d": 9.23135726918833,
              "speed3d": 9.233427506684352,
              "msFromStart": 258500
            },
            {
              "lat": 43.76372463333333,
              "lng": -116.09594936666667,
              "alt": 1927.7173333333335,
              "speed2d": 9.218666666666666,
              "speed3d": 9.23,
              "msFromStart": 259312.72305653035
            },
            {
              "lat": 43.763734088391566,
              "lng": -116.09596461121644,
              "alt": 1927.900456263221,
              "speed2d": 8.9458465275123,
              "speed3d": 9.040041542421694,
              "msFromStart": 259500
            },
            {
              "lat": 43.76378150000001,
              "lng": -116.09602976000001,
              "alt": 1927.0322,
              "speed2d": 9.000399999999999,
              "speed3d": 9.146,
              "msFromStart": 260303.8770526316
            },
            {
              "lat": 43.7638055,
              "lng": -116.09606335000001,
              "alt": 1926.5335,
              "speed2d": 8.818,
              "speed3d": 8.93,
              "msFromStart": 260753.41288888897
            },
            {
              "lat": 43.76383275,
              "lng": -116.09610895,
              "alt": 1926.049,
              "speed2d": 8.815000000000001,
              "speed3d": 8.805,
              "msFromStart": 261294.6133333334
            },
            {
              "lat": 43.76386316,
              "lng": -116.09615665999999,
              "alt": 1925.8247999999999,
              "speed2d": 9.2926,
              "speed3d": 9.347999999999999,
              "msFromStart": 261823.1811555555
            },
            {
              "lat": 43.76387819999999,
              "lng": -116.0961851,
              "alt": 1925.5965,
              "speed2d": 9.14825,
              "speed3d": 9.239999999999998,
              "msFromStart": 262148.0639999999
            },
            {
              "lat": 43.763906340000005,
              "lng": -116.09624052000001,
              "alt": 1925.2638000000002,
              "speed2d": 9.0378,
              "speed3d": 9.108,
              "msFromStart": 262758.3162666668
            },
            {
              "lat": 43.763916218581635,
              "lng": -116.09626399978009,
              "alt": 1924.7124518966466,
              "speed2d": 9.032350742166122,
              "speed3d": 9.058949972512467,
              "msFromStart": 263000
            },
            {
              "lat": 43.7639361038909,
              "lng": -116.09630916425479,
              "alt": 1924.6136727637022,
              "speed2d": 8.741253360910004,
              "speed3d": 8.760055584281256,
              "msFromStart": 263500
            },
            {
              "lat": 43.76396703333333,
              "lng": -116.09638173333333,
              "alt": 1924.4425,
              "speed2d": 8.943166666666666,
              "speed3d": 9.013333333333334,
              "msFromStart": 264237.1084912282
            },
            {
              "lat": 43.763976603598536,
              "lng": -116.09640587955802,
              "alt": 1924.4957965379874,
              "speed2d": 8.795862283624206,
              "speed3d": 8.904827255962758,
              "msFromStart": 264500
            },
            {
              "lat": 43.7640237,
              "lng": -116.09648396666665,
              "alt": 1925.4226666666664,
              "speed2d": 8.823666666666666,
              "speed3d": 8.866666666666667,
              "msFromStart": 265366.41350487346
            },
            {
              "lat": 43.764037914285716,
              "lng": -116.0965138,
              "alt": 1925.281714285714,
              "speed2d": 8.295857142857143,
              "speed3d": 8.428571428571429,
              "msFromStart": 265728.1973333334
            },
            {
              "lat": 43.76405405,
              "lng": -116.09654954999999,
              "alt": 1925.095,
              "speed2d": 7.9075,
              "speed3d": 7.85,
              "msFromStart": 266164.6
            },
            {
              "lat": 43.76406683147107,
              "lng": -116.09657508337338,
              "alt": 1924.985961953633,
              "speed2d": 7.731156126526161,
              "speed3d": 7.60705586803318,
              "msFromStart": 266500
            },
            {
              "lat": 43.764083505177744,
              "lng": -116.09661203079159,
              "alt": 1924.5804877230994,
              "speed2d": 7.154018506803908,
              "speed3d": 7.106844667748628,
              "msFromStart": 267000
            },
            {
              "lat": 43.76411003333333,
              "lng": -116.0966697,
              "alt": 1924.275666666667,
              "speed2d": 6.968666666666666,
              "speed3d": 6.913333333333334,
              "msFromStart": 267749.7312592593
            },
            {
              "lat": 43.764128966666675,
              "lng": -116.09670836666668,
              "alt": 1924.4298888888889,
              "speed2d": 7.273888888888889,
              "speed3d": 7.394444444444445,
              "msFromStart": 268225.8645925927
            },
            {
              "lat": 43.7641458375,
              "lng": -116.0967393125,
              "alt": 1923.901625,
              "speed2d": 6.45175,
              "speed3d": 6.505,
              "msFromStart": 268734.3664444446
            },
            {
              "lat": 43.764164457142854,
              "lng": -116.09677007142858,
              "alt": 1923.5324285714287,
              "speed2d": 6.5200000000000005,
              "speed3d": 6.591428571428572,
              "msFromStart": 269215.5386666669
            },
            {
              "lat": 43.764181300000004,
              "lng": -116.09679695,
              "alt": 1923.0504999999998,
              "speed2d": 6.442500000000001,
              "speed3d": 6.465,
              "msFromStart": 269655.47621052637
            },
            {
              "lat": 43.76420245,
              "lng": -116.0968341,
              "alt": 1922.617,
              "speed2d": 6.6815,
              "speed3d": 6.67,
              "msFromStart": 270205.4046315791
            },
            {
              "lat": 43.76421267402603,
              "lng": -116.09684963364526,
              "alt": 1922.197773496827,
              "speed2d": 6.4225165107024145,
              "speed3d": 6.286672905238288,
              "msFromStart": 270500
            },
            {
              "lat": 43.764255,
              "lng": -116.09692045,
              "alt": 1921.54,
              "speed2d": 7.759,
              "speed3d": 7.720000000000001,
              "msFromStart": 271389.50933333323
            },
            {
              "lat": 43.7642782,
              "lng": -116.09695725,
              "alt": 1921.4450000000002,
              "speed2d": 7.6185,
              "speed3d": 7.635,
              "msFromStart": 271885.5306666666
            },
            {
              "lat": 43.76428328851317,
              "lng": -116.09696559311597,
              "alt": 1921.383646120067,
              "speed2d": 7.632315455961771,
              "speed3d": 7.645261823847083,
              "msFromStart": 272000
            },
            {
              "lat": 43.764311000000006,
              "lng": -116.09701525,
              "alt": 1920.8000000000002,
              "speed2d": 7.356,
              "speed3d": 7.41,
              "msFromStart": 272683.5644444444
            },
            {
              "lat": 43.76433286666667,
              "lng": -116.09704959999999,
              "alt": 1920.4363333333333,
              "speed2d": 7.379,
              "speed3d": 7.286666666666666,
              "msFromStart": 273177.56444444426
            },
            {
              "lat": 43.764352522066005,
              "lng": -116.09707397804212,
              "alt": 1920.406668629346,
              "speed2d": 7.971697308279215,
              "speed3d": 7.817454938646831,
              "msFromStart": 273500
            },
            {
              "lat": 43.76437558004957,
              "lng": -116.09710951206077,
              "alt": 1920.5426796724314,
              "speed2d": 6.55832291363635,
              "speed3d": 6.806002370562117,
              "msFromStart": 274000
            },
            {
              "lat": 43.764433499999996,
              "lng": -116.097178525,
              "alt": 1920.58075,
              "speed2d": 9.7915,
              "speed3d": 9.6,
              "msFromStart": 274799.63466666674
            },
            {
              "lat": 43.76446522222223,
              "lng": -116.09721688888888,
              "alt": 1920.7482222222222,
              "speed2d": 9.713,
              "speed3d": 9.861111111111112,
              "msFromStart": 275267.0718440546
            },
            {
              "lat": 43.76448893333333,
              "lng": -116.0972494,
              "alt": 1920.4896666666668,
              "speed2d": 8.869,
              "speed3d": 8.95,
              "msFromStart": 275706.61052631564
            },
            {
              "lat": 43.764515,
              "lng": -116.0972874,
              "alt": 1920.1133333333332,
              "speed2d": 8.46,
              "speed3d": 8.49,
              "msFromStart": 276219.20112280664
            },
            {
              "lat": 43.76454332857143,
              "lng": -116.09733031428571,
              "alt": 1919.712142857143,
              "speed2d": 8.129571428571428,
              "speed3d": 8.19,
              "msFromStart": 276806.5155555554
            },
            {
              "lat": 43.76455223491633,
              "lng": -116.09734284211785,
              "alt": 1919.4921024768992,
              "speed2d": 7.925252443594089,
              "speed3d": 8.054704083758462,
              "msFromStart": 277000
            },
            {
              "lat": 43.7645865,
              "lng": -116.097392325,
              "alt": 1918.99325,
              "speed2d": 7.77375,
              "speed3d": 7.7825,
              "msFromStart": 277715.07644444436
            },
            {
              "lat": 43.7646002651941,
              "lng": -116.09741322572623,
              "alt": 1918.8828007475772,
              "speed2d": 7.847217882689569,
              "speed3d": 7.801664361189106,
              "msFromStart": 278000
            },
            {
              "lat": 43.764623636855944,
              "lng": -116.09744799107322,
              "alt": 1918.496194507869,
              "speed2d": 7.717699923291095,
              "speed3d": 7.69129818024805,
              "msFromStart": 278500
            },
            {
              "lat": 43.76464700851779,
              "lng": -116.09748275642022,
              "alt": 1918.1095882681607,
              "speed2d": 7.5881819638926205,
              "speed3d": 7.580931999306995,
              "msFromStart": 279000
            },
            {
              "lat": 43.76467924,
              "lng": -116.09753204,
              "alt": 1917.6842000000001,
              "speed2d": 7.4658,
              "speed3d": 7.516,
              "msFromStart": 279700.2526315788
            },
            {
              "lat": 43.76470227142857,
              "lng": -116.09756847142856,
              "alt": 1917.5672857142858,
              "speed2d": 7.5164285714285715,
              "speed3d": 7.540000000000001,
              "msFromStart": 280216.4412631576
            },
            {
              "lat": 43.764730619999995,
              "lng": -116.0976072,
              "alt": 1918.3287999999998,
              "speed2d": 7.786200000000001,
              "speed3d": 7.786,
              "msFromStart": 280711.0382222221
            },
            {
              "lat": 43.7647598,
              "lng": -116.0976479,
              "alt": 1917.9548,
              "speed2d": 7.276999999999999,
              "speed3d": 7.338000000000001,
              "msFromStart": 281370.91057777766
            },
            {
              "lat": 43.764771859999996,
              "lng": -116.09766858,
              "alt": 1917.6915999999999,
              "speed2d": 6.9282,
              "speed3d": 6.989999999999999,
              "msFromStart": 281712.11448888894
            },
            {
              "lat": 43.76479595,
              "lng": -116.0977235,
              "alt": 1917.404,
              "speed2d": 7.122,
              "speed3d": 7.125,
              "msFromStart": 282444.17333333334
            },
            {
              "lat": 43.764805949999996,
              "lng": -116.09773580000001,
              "alt": 1917.272,
              "speed2d": 7.0184999999999995,
              "speed3d": 7.045,
              "msFromStart": 282664.1839999999
            },
            {
              "lat": 43.76481761165219,
              "lng": -116.09775572812212,
              "alt": 1917.0285447425686,
              "speed2d": 6.797602854183642,
              "speed3d": 6.8227853310937645,
              "msFromStart": 283000
            },
            {
              "lat": 43.764832012517985,
              "lng": -116.09778580265751,
              "alt": 1916.6063375407782,
              "speed2d": 6.4284897535658585,
              "speed3d": 6.426397863435257,
              "msFromStart": 283500
            },
            {
              "lat": 43.764855850000004,
              "lng": -116.09783325000001,
              "alt": 1916.0065,
              "speed2d": 5.9990000000000006,
              "speed3d": 5.98,
              "msFromStart": 284286.5920000002
            },
            {
              "lat": 43.76487475,
              "lng": -116.09786473333334,
              "alt": 1915.5608333333332,
              "speed2d": 6.239166666666666,
              "speed3d": 6.303333333333333,
              "msFromStart": 284808.4286315788
            },
            {
              "lat": 43.7648901375,
              "lng": -116.0978958125,
              "alt": 1915.2085,
              "speed2d": 6.210625,
              "speed3d": 6.24,
              "msFromStart": 285302.6771345027
            },
            {
              "lat": 43.7649078,
              "lng": -116.0979251,
              "alt": 1915.2073333333333,
              "speed2d": 6.343666666666666,
              "speed3d": 6.3933333333333335,
              "msFromStart": 285770.63466666674
            },
            {
              "lat": 43.76493008,
              "lng": -116.097959,
              "alt": 1915.161,
              "speed2d": 6.532399999999999,
              "speed3d": 6.5200000000000005,
              "msFromStart": 286310.77120000013
            },
            {
              "lat": 43.764942825000006,
              "lng": -116.09798237499999,
              "alt": 1914.927,
              "speed2d": 6.80425,
              "speed3d": 6.779999999999999,
              "msFromStart": 286652.4053333333
            },
            {
              "lat": 43.76495445727458,
              "lng": -116.09800526598077,
              "alt": 1915.1029755411046,
              "speed2d": 6.333081335533383,
              "speed3d": 6.311618945938124,
              "msFromStart": 287000
            },
            {
              "lat": 43.76497257313668,
              "lng": -116.0980365344551,
              "alt": 1914.6099593851748,
              "speed2d": 6.484460457236631,
              "speed3d": 6.477060609001782,
              "msFromStart": 287500
            },
            {
              "lat": 43.76499207811108,
              "lng": -116.09806736462325,
              "alt": 1914.161177460093,
              "speed2d": 6.706495358704515,
              "speed3d": 6.719481356899171,
              "msFromStart": 288000
            },
            {
              "lat": 43.765008550000005,
              "lng": -116.09812542499999,
              "alt": 1912.78425,
              "speed2d": 6.305,
              "speed3d": 6.3525,
              "msFromStart": 288852.67911111115
            },
            {
              "lat": 43.765012221502396,
              "lng": -116.0981368324424,
              "alt": 1912.713264605502,
              "speed2d": 6.487612777919754,
              "speed3d": 6.444148449711456,
              "msFromStart": 289000
            },
            {
              "lat": 43.765026633333335,
              "lng": -116.09818431666667,
              "alt": 1911.1943333333334,
              "speed2d": 5.996166666666667,
              "speed3d": 6.111666666666667,
              "msFromStart": 289759.9486315788
            },
            {
              "lat": 43.76503056005045,
              "lng": -116.09819983952083,
              "alt": 1910.7372743163394,
              "speed2d": 5.932869692901647,
              "speed3d": 6.017095417980475,
              "msFromStart": 290000
            },
            {
              "lat": 43.765044875,
              "lng": -116.098245475,
              "alt": 1909.8422500000001,
              "speed2d": 5.544,
              "speed3d": 5.66,
              "msFromStart": 290749.51733333344
            },
            {
              "lat": 43.7650506416132,
              "lng": -116.09826007496795,
              "alt": 1909.4708044492706,
              "speed2d": 5.537269790134426,
              "speed3d": 5.600124090565774,
              "msFromStart": 291000
            },
            {
              "lat": 43.76506570178868,
              "lng": -116.09828544315216,
              "alt": 1908.892857111975,
              "speed2d": 5.409031172971447,
              "speed3d": 5.502285363574946,
              "msFromStart": 291500
            },
            {
              "lat": 43.76508063076917,
              "lng": -116.09830836991954,
              "alt": 1908.120446614626,
              "speed2d": 5.213925373375078,
              "speed3d": 5.46326062062521,
              "msFromStart": 292000
            },
            {
              "lat": 43.76510783333333,
              "lng": -116.0983572,
              "alt": 1907.5106666666668,
              "speed2d": 5.290333333333334,
              "speed3d": 5.356666666666667,
              "msFromStart": 292868.45511111093
            },
            {
              "lat": 43.7651202,
              "lng": -116.098378,
              "alt": 1907.2314999999999,
              "speed2d": 5.55475,
              "speed3d": 5.6225,
              "msFromStart": 293226.68888888863
            },
            {
              "lat": 43.765147299999995,
              "lng": -116.0984187,
              "alt": 1906.6925,
              "speed2d": 5.8765,
              "speed3d": 5.965,
              "msFromStart": 293940.21777777805
            },
            {
              "lat": 43.76515404,
              "lng": -116.09843076000001,
              "alt": 1906.2874000000002,
              "speed2d": 5.693,
              "speed3d": 5.811999999999999,
              "msFromStart": 294186.8337777781
            },
            {
              "lat": 43.76516253357054,
              "lng": -116.09844814825543,
              "alt": 1905.8165837541703,
              "speed2d": 5.697926575459866,
              "speed3d": 5.66140253420255,
              "msFromStart": 294500
            },
            {
              "lat": 43.76517453269247,
              "lng": -116.09847869349147,
              "alt": 1905.2586913949035,
              "speed2d": 5.6046673232420305,
              "speed3d": 5.715105192565674,
              "msFromStart": 295000
            },
            {
              "lat": 43.76518630603329,
              "lng": -116.09850814145551,
              "alt": 1904.3668182230574,
              "speed2d": 5.006328721404971,
              "speed3d": 4.911477221178158,
              "msFromStart": 295500
            },
            {
              "lat": 43.7652097,
              "lng": -116.09855259999999,
              "alt": 1903.488,
              "speed2d": 5.624,
              "speed3d": 5.73,
              "msFromStart": 296302.06444444467
            },
            {
              "lat": 43.76522514,
              "lng": -116.09858257999998,
              "alt": 1903.0685999999996,
              "speed2d": 5.757,
              "speed3d": 5.837999999999999,
              "msFromStart": 296791.8677333333
            },
            {
              "lat": 43.76523355968589,
              "lng": -116.09859500314136,
              "alt": 1902.698293193717,
              "speed2d": 6.664528795811067,
              "speed3d": 6.01,
              "msFromStart": 297000
            },
            {
              "lat": 43.7652557,
              "lng": -116.0986354,
              "alt": 1902.3216666666667,
              "speed2d": 5.854,
              "speed3d": 5.933333333333334,
              "msFromStart": 297706.1760000001
            },
            {
              "lat": 43.765266812600174,
              "lng": -116.09865361195841,
              "alt": 1902.127760148709,
              "speed2d": 5.948977784490619,
              "speed3d": 5.897735161196703,
              "msFromStart": 298000
            },
            {
              "lat": 43.76528316586172,
              "lng": -116.09868587887769,
              "alt": 1901.95602159556,
              "speed2d": 5.906189680805076,
              "speed3d": 5.888943085096933,
              "msFromStart": 298500
            },
            {
              "lat": 43.76529951912326,
              "lng": -116.09871814579698,
              "alt": 1901.7842830424113,
              "speed2d": 5.863401577119531,
              "speed3d": 5.8801510089971645,
              "msFromStart": 299000
            },
            {
              "lat": 43.76531581559917,
              "lng": -116.09875102385803,
              "alt": 1901.658908176707,
              "speed2d": 5.797651394124596,
              "speed3d": 5.859266066849482,
              "msFromStart": 299500
            },
            {
              "lat": 43.76533253625462,
              "lng": -116.09877933678897,
              "alt": 1901.1872040630365,
              "speed2d": 5.903424230485592,
              "speed3d": 5.928712878601651,
              "msFromStart": 300000
            },
            {
              "lat": 43.765358766666665,
              "lng": -116.0988217,
              "alt": 1900.2783333333334,
              "speed2d": 5.847666666666667,
              "speed3d": 5.906666666666667,
              "msFromStart": 300788.1386666666
            },
            {
              "lat": 43.76537265,
              "lng": -116.09884378333332,
              "alt": 1899.6971666666668,
              "speed2d": 5.5595,
              "speed3d": 5.666666666666667,
              "msFromStart": 301246.2868148146
            },
            {
              "lat": 43.76537879498172,
              "lng": -116.09884984509812,
              "alt": 1898.8636015284192,
              "speed2d": 4.992033234239199,
              "speed3d": 4.65527417843412,
              "msFromStart": 301500
            },
            {
              "lat": 43.76540665,
              "lng": -116.098892,
              "alt": 1898.5695,
              "speed2d": 5.564,
              "speed3d": 5.56,
              "msFromStart": 302273.1066666667
            },
            {
              "lat": 43.765421357142856,
              "lng": -116.09891501428572,
              "alt": 1898.219,
              "speed2d": 5.627571428571429,
              "speed3d": 5.684285714285713,
              "msFromStart": 302713.0737777778
            },
            {
              "lat": 43.765435499999995,
              "lng": -116.09893955,
              "alt": 1897.6255,
              "speed2d": 5.3285,
              "speed3d": 5.395,
              "msFromStart": 303207.88977777783
            },
            {
              "lat": 43.76544229321149,
              "lng": -116.09895579599653,
              "alt": 1897.0524046997386,
              "speed2d": 5.387061792863359,
              "speed3d": 5.391235857267188,
              "msFromStart": 303500
            },
            {
              "lat": 43.765467666666666,
              "lng": -116.0989946,
              "alt": 1896.8310000000001,
              "speed2d": 5.487666666666667,
              "speed3d": 5.49,
              "msFromStart": 304236.6002962963
            },
            {
              "lat": 43.76548854,
              "lng": -116.09902810000001,
              "alt": 1896.3229999999999,
              "speed2d": 5.6616,
              "speed3d": 5.699999999999999,
              "msFromStart": 304859.2711111109
            },
            {
              "lat": 43.765493484467456,
              "lng": -116.09903478985835,
              "alt": 1896.1347502041024,
              "speed2d": 5.6880013347674305,
              "speed3d": 5.726605284642375,
              "msFromStart": 305000
            },
            {
              "lat": 43.765509301640485,
              "lng": -116.09906035420593,
              "alt": 1895.6145004072134,
              "speed2d": 5.635169051774295,
              "speed3d": 5.668713205351953,
              "msFromStart": 305500
            },
            {
              "lat": 43.76552617226613,
              "lng": -116.09908759100372,
              "alt": 1895.1084910351601,
              "speed2d": 5.73381262404375,
              "speed3d": 5.713745778651114,
              "msFromStart": 306000
            },
            {
              "lat": 43.76554334452383,
              "lng": -116.09911052682378,
              "alt": 1894.6991202021195,
              "speed2d": 5.676177000491243,
              "speed3d": 5.6861122605095,
              "msFromStart": 306500
            },
            {
              "lat": 43.7655744,
              "lng": -116.09915277500001,
              "alt": 1893.9154999999998,
              "speed2d": 5.561,
              "speed3d": 5.632499999999999,
              "msFromStart": 307416.9644444445
            },
            {
              "lat": 43.76558186,
              "lng": -116.09916676,
              "alt": 1893.5184,
              "speed2d": 5.480799999999999,
              "speed3d": 5.577999999999999,
              "msFromStart": 307708.38613333326
            },
            {
              "lat": 43.76558926562036,
              "lng": -116.0991817826709,
              "alt": 1893.2057847940066,
              "speed2d": 5.50050513863877,
              "speed3d": 5.608953070747044,
              "msFromStart": 308000
            },
            {
              "lat": 43.76560167723619,
              "lng": -116.09920692209134,
              "alt": 1892.7564104253954,
              "speed2d": 5.499976993156075,
              "speed3d": 5.555377458362512,
              "msFromStart": 308500
            },
            {
              "lat": 43.765615600126004,
              "lng": -116.09922452894043,
              "alt": 1891.900562218308,
              "speed2d": 4.990714795728868,
              "speed3d": 5.114285414277135,
              "msFromStart": 309000
            },
            {
              "lat": 43.76562506801081,
              "lng": -116.09925269899657,
              "alt": 1891.2126528625947,
              "speed2d": 5.023494982785306,
              "speed3d": 5.081634409297021,
              "msFromStart": 309500
            },
            {
              "lat": 43.7656409,
              "lng": -116.0992905,
              "alt": 1890.1504999999997,
              "speed2d": 5.115833333333333,
              "speed3d": 5.223333333333333,
              "msFromStart": 310240.6165925925
            },
            {
              "lat": 43.76564719251152,
              "lng": -116.09930645311074,
              "alt": 1890.1500748844755,
              "speed2d": 4.706156685130187,
              "speed3d": 4.604723505829762,
              "msFromStart": 310500
            },
            {
              "lat": 43.765657775,
              "lng": -116.09936130000001,
              "alt": 1890.0025,
              "speed2d": 5.5205,
              "speed3d": 5.6025,
              "msFromStart": 311212.8547368423
            },
            {
              "lat": 43.7656657,
              "lng": -116.0994087,
              "alt": 1889.819,
              "speed2d": 5.707000000000001,
              "speed3d": 5.745,
              "msFromStart": 311818.0524444445
            },
            {
              "lat": 43.76566830987227,
              "lng": -116.09942459015,
              "alt": 1889.9249010546653,
              "speed2d": 5.848890791699188,
              "speed3d": 5.888545373585647,
              "msFromStart": 312000
            },
            {
              "lat": 43.7656738,
              "lng": -116.09948153333332,
              "alt": 1889.493,
              "speed2d": 5.708666666666667,
              "speed3d": 5.746666666666667,
              "msFromStart": 312762.36622222204
            },
            {
              "lat": 43.765675009160724,
              "lng": -116.09950257584579,
              "alt": 1889.5621239978539,
              "speed2d": 5.8318223499656145,
              "speed3d": 5.835470836207658,
              "msFromStart": 313000
            },
            {
              "lat": 43.76567343114606,
              "lng": -116.09954956596684,
              "alt": 1889.7828442694681,
              "speed2d": 6.312077865266838,
              "speed3d": 5.586229221347327,
              "msFromStart": 313500
            },
            {
              "lat": 43.7656778,
              "lng": -116.09960835999999,
              "alt": 1889.7469999999998,
              "speed2d": 6.3392,
              "speed3d": 6.386000000000001,
              "msFromStart": 314187.01440000004
            },
            {
              "lat": 43.765679450657586,
              "lng": -116.09963719736966,
              "alt": 1889.7851561683601,
              "speed2d": 6.495168739533321,
              "speed3d": 6.515064865468346,
              "msFromStart": 314500
            },
            {
              "lat": 43.7656608,
              "lng": -116.09971013333333,
              "alt": 1889.0563333333334,
              "speed2d": 6.704000000000001,
              "speed3d": 6.720000000000001,
              "msFromStart": 315420.5459649123
            },
            {
              "lat": 43.76565779495912,
              "lng": -116.09971595272961,
              "alt": 1888.8069684771554,
              "speed2d": 6.654598656462324,
              "speed3d": 6.729086253884238,
              "msFromStart": 315500
            },
            {
              "lat": 43.7656549698557,
              "lng": -116.09976160899856,
              "alt": 1888.8923710286228,
              "speed2d": 6.6809013434161475,
              "speed3d": 6.732333499187179,
              "msFromStart": 316000
            },
            {
              "lat": 43.765652144752295,
              "lng": -116.09980726526753,
              "alt": 1888.9777735800901,
              "speed2d": 6.707204030369972,
              "speed3d": 6.735580744490121,
              "msFromStart": 316500
            },
            {
              "lat": 43.7656469,
              "lng": -116.0998771,
              "alt": 1889.0700000000002,
              "speed2d": 6.8025,
              "speed3d": 6.785,
              "msFromStart": 317262.8835555555
            },
            {
              "lat": 43.765643744395405,
              "lng": -116.09989923497113,
              "alt": 1889.1021359988442,
              "speed2d": 6.85723169121786,
              "speed3d": 6.827832586917209,
              "msFromStart": 317500
            },
            {
              "lat": 43.765638473656495,
              "lng": -116.09994616836474,
              "alt": 1889.3560474858036,
              "speed2d": 6.828092173483685,
              "speed3d": 6.8092634351082415,
              "msFromStart": 318000
            },
            {
              "lat": 43.76562938048956,
              "lng": -116.09999047688726,
              "alt": 1888.9237087306158,
              "speed2d": 6.928943661309518,
              "speed3d": 6.900195104459401,
              "msFromStart": 318500
            },
            {
              "lat": 43.76562036609956,
              "lng": -116.10003409514808,
              "alt": 1888.6554738500306,
              "speed2d": 7.00096030245766,
              "speed3d": 6.909741650913977,
              "msFromStart": 319000
            },
            {
              "lat": 43.7656087,
              "lng": -116.10009120000001,
              "alt": 1887.7725,
              "speed2d": 7.191,
              "speed3d": 7.220000000000001,
              "msFromStart": 319626.33333333326
            },
            {
              "lat": 43.765593224999996,
              "lng": -116.10014502499999,
              "alt": 1888.11075,
              "speed2d": 7.160749999999999,
              "speed3d": 7.2425,
              "msFromStart": 320203.86133333296
            },
            {
              "lat": 43.765583500000005,
              "lng": -116.10018445,
              "alt": 1888.0349999999999,
              "speed2d": 7.130000000000001,
              "speed3d": 7.145,
              "msFromStart": 320643.89052631566
            },
            {
              "lat": 43.76557333779595,
              "lng": -116.10021392067313,
              "alt": 1887.7823686759298,
              "speed2d": 7.131455705565841,
              "speed3d": 7.121399716890231,
              "msFromStart": 321000
            },
            {
              "lat": 43.76555797372122,
              "lng": -116.10025337751286,
              "alt": 1887.4600785735952,
              "speed2d": 7.028765231782767,
              "speed3d": 7.022658876714199,
              "msFromStart": 321500
            },
            {
              "lat": 43.765533475,
              "lng": -116.10031045,
              "alt": 1887.02225,
              "speed2d": 7.077500000000001,
              "speed3d": 7.1000000000000005,
              "msFromStart": 322226.41999999987
            },
            {
              "lat": 43.76550616666667,
              "lng": -116.10035859999999,
              "alt": 1886.8359999999998,
              "speed2d": 6.890000000000001,
              "speed3d": 6.94,
              "msFromStart": 322899.6124444443
            },
            {
              "lat": 43.76549623333334,
              "lng": -116.10037333333332,
              "alt": 1886.652,
              "speed2d": 6.723166666666668,
              "speed3d": 6.794999999999999,
              "msFromStart": 323146.58844444423
            },
            {
              "lat": 43.7654653,
              "lng": -116.1004115,
              "alt": 1886.0210000000002,
              "speed2d": 6.23,
              "speed3d": 6.285,
              "msFromStart": 323915.7288888889
            },
            {
              "lat": 43.765452620000005,
              "lng": -116.10042708,
              "alt": 1885.6855999999998,
              "speed2d": 6.2298,
              "speed3d": 6.28,
              "msFromStart": 324218.08711111115
            },
            {
              "lat": 43.76543930456447,
              "lng": -116.10043916216537,
              "alt": 1885.6283345968134,
              "speed2d": 6.184420200462597,
              "speed3d": 6.233336492033606,
              "msFromStart": 324500
            },
            {
              "lat": 43.765401716666666,
              "lng": -116.10047251666667,
              "alt": 1885.4296666666667,
              "speed2d": 5.838666666666667,
              "speed3d": 5.906666666666666,
              "msFromStart": 325291.10399999976
            },
            {
              "lat": 43.765384399999995,
              "lng": -116.10049057500001,
              "alt": 1885.1370000000002,
              "speed2d": 6.117750000000001,
              "speed3d": 6.1225,
              "msFromStart": 325677.02821052645
            },
            {
              "lat": 43.7653571,
              "lng": -116.10051821666666,
              "alt": 1884.9208333333333,
              "speed2d": 6.181166666666667,
              "speed3d": 6.24,
              "msFromStart": 326247.2541754389
            },
            {
              "lat": 43.76533744,
              "lng": -116.10054226,
              "alt": 1884.7156,
              "speed2d": 6.2278,
              "speed3d": 6.252,
              "msFromStart": 326685.74151111103
            },
            {
              "lat": 43.765307525,
              "lng": -116.100572725,
              "alt": 1884.49925,
              "speed2d": 6.445,
              "speed3d": 6.4925,
              "msFromStart": 327300.4373333332
            },
            {
              "lat": 43.765286024999995,
              "lng": -116.1005952,
              "alt": 1884.5819999999999,
              "speed2d": 6.477499999999999,
              "speed3d": 6.529999999999999,
              "msFromStart": 327725.7848888888
            },
            {
              "lat": 43.76525827500001,
              "lng": -116.10063117499999,
              "alt": 1884.547,
              "speed2d": 6.69275,
              "speed3d": 6.6575,
              "msFromStart": 328315.78311111097
            },
            {
              "lat": 43.765234444444445,
              "lng": -116.10065447777777,
              "alt": 1885.461888888889,
              "speed2d": 6.8771111111111125,
              "speed3d": 6.897777777777778,
              "msFromStart": 328728.41422222235
            },
            {
              "lat": 43.76520516,
              "lng": -116.10068143000001,
              "alt": 1885.8858999999998,
              "speed2d": 6.8947,
              "speed3d": 6.908,
              "msFromStart": 329251.7127111112
            },
            {
              "lat": 43.765173000000004,
              "lng": -116.1007111,
              "alt": 1885.728,
              "speed2d": 6.9495000000000005,
              "speed3d": 6.945,
              "msFromStart": 329857.3653333333
            },
            {
              "lat": 43.76514937499999,
              "lng": -116.10073242499999,
              "alt": 1885.95125,
              "speed2d": 6.93975,
              "speed3d": 6.9675,
              "msFromStart": 330283.93319298245
            },
            {
              "lat": 43.765115933333334,
              "lng": -116.10076079999999,
              "alt": 1886.2153333333333,
              "speed2d": 6.99,
              "speed3d": 7.033333333333333,
              "msFromStart": 330874.2248421052
            },
            {
              "lat": 43.7651007,
              "lng": -116.1007735,
              "alt": 1886.257,
              "speed2d": 6.9885,
              "speed3d": 6.984999999999999,
              "msFromStart": 331148.5153684209
            },
            {
              "lat": 43.76508026581956,
              "lng": -116.10079032479435,
              "alt": 1886.3391432592427,
              "speed2d": 7.051357563391189,
              "speed3d": 7.118513291180077,
              "msFromStart": 331500
            },
            {
              "lat": 43.76505149438563,
              "lng": -116.10081023277793,
              "alt": 1885.7460985507253,
              "speed2d": 7.291614880239867,
              "speed3d": 7.328286436073961,
              "msFromStart": 332000
            },
            {
              "lat": 43.765023394493895,
              "lng": -116.1008325096883,
              "alt": 1886.1537072463773,
              "speed2d": 7.124404497881979,
              "speed3d": 7.125283415958953,
              "msFromStart": 332500
            },
            {
              "lat": 43.764994979434704,
              "lng": -116.1008551004463,
              "alt": 1886.3918382769548,
              "speed2d": 6.990445611538748,
              "speed3d": 6.869247396675529,
              "msFromStart": 333000
            },
            {
              "lat": 43.764966753271445,
              "lng": -116.1008776789226,
              "alt": 1887.2835036454546,
              "speed2d": 6.7470971772627175,
              "speed3d": 6.7363257104154375,
              "msFromStart": 333500
            },
            {
              "lat": 43.76494092286877,
              "lng": -116.10089602461495,
              "alt": 1886.9048503925965,
              "speed2d": 6.654124271970177,
              "speed3d": 6.6217459769617895,
              "msFromStart": 334000
            },
            {
              "lat": 43.7649161982982,
              "lng": -116.10091604576806,
              "alt": 1887.0599207324965,
              "speed2d": 6.315045231008157,
              "speed3d": 6.44,
              "msFromStart": 334500
            },
            {
              "lat": 43.764882,
              "lng": -116.10094313333333,
              "alt": 1886.475,
              "speed2d": 6.467666666666668,
              "speed3d": 6.463333333333335,
              "msFromStart": 335181.72207407403
            },
            {
              "lat": 43.76485005,
              "lng": -116.10097005,
              "alt": 1886.4875000000002,
              "speed2d": 6.5075,
              "speed3d": 6.51,
              "msFromStart": 335795.6193684211
            },
            {
              "lat": 43.76482793333333,
              "lng": -116.10099036666668,
              "alt": 1886.3876666666667,
              "speed2d": 6.497333333333334,
              "speed3d": 6.476666666666667,
              "msFromStart": 336263.08715789486
            },
            {
              "lat": 43.764796025,
              "lng": -116.1010191,
              "alt": 1886.451,
              "speed2d": 6.5337499999999995,
              "speed3d": 6.5249999999999995,
              "msFromStart": 336895.12533333316
            },
            {
              "lat": 43.76479074964248,
              "lng": -116.10102387915303,
              "alt": 1886.4096517377088,
              "speed2d": 6.478863865639326,
              "speed3d": 6.4273975943683626,
              "msFromStart": 337000
            },
            {
              "lat": 43.764757849999995,
              "lng": -116.10105375,
              "alt": 1886.92175,
              "speed2d": 6.41825,
              "speed3d": 6.3975,
              "msFromStart": 337651.504
            },
            {
              "lat": 43.7647393501236,
              "lng": -116.10106791714647,
              "alt": 1887.027909491193,
              "speed2d": 6.493151225666862,
              "speed3d": 6.340315943969276,
              "msFromStart": 338000
            },
            {
              "lat": 43.764705975000005,
              "lng": -116.10109707500001,
              "alt": 1887.69825,
              "speed2d": 6.368,
              "speed3d": 6.449999999999999,
              "msFromStart": 338659.5004444444
            },
            {
              "lat": 43.764681466666666,
              "lng": -116.10111926666667,
              "alt": 1886.8826666666664,
              "speed2d": 5.7010000000000005,
              "speed3d": 5.666666666666667,
              "msFromStart": 339276.29748148145
            },
            {
              "lat": 43.764667829226035,
              "lng": -116.10112900543389,
              "alt": 1886.9031839977968,
              "speed2d": 6.355905015063141,
              "speed3d": 6.130988453687205,
              "msFromStart": 339500
            },
            {
              "lat": 43.764645559620206,
              "lng": -116.10114675838726,
              "alt": 1886.8520580013305,
              "speed2d": 5.993631849918766,
              "speed3d": 5.839287983051475,
              "msFromStart": 340000
            },
            {
              "lat": 43.764623290014384,
              "lng": -116.10116451134064,
              "alt": 1886.8009320048643,
              "speed2d": 5.6313586847743915,
              "speed3d": 5.547587512415744,
              "msFromStart": 340500
            },
            {
              "lat": 43.7645885,
              "lng": -116.101192,
              "alt": 1886.715,
              "speed2d": 5.2765,
              "speed3d": 5.25,
              "msFromStart": 341274.415157895
            },
            {
              "lat": 43.76457627568512,
              "lng": -116.10119985913465,
              "alt": 1886.8535205776427,
              "speed2d": 5.713718249986097,
              "speed3d": 5.668306158810772,
              "msFromStart": 341500
            },
            {
              "lat": 43.764542899999995,
              "lng": -116.10122874999999,
              "alt": 1886.6385,
              "speed2d": 5.137499999999999,
              "speed3d": 5.145,
              "msFromStart": 342350.7617777778
            },
            {
              "lat": 43.76452596666667,
              "lng": -116.10124216666668,
              "alt": 1886.319,
              "speed2d": 5.364,
              "speed3d": 5.296666666666666,
              "msFromStart": 342745.1863703703
            },
            {
              "lat": 43.76450498,
              "lng": -116.10125662,
              "alt": 1885.7808,
              "speed2d": 5.447799999999999,
              "speed3d": 5.502,
              "msFromStart": 343213.8901333333
            },
            {
              "lat": 43.76448626,
              "lng": -116.10126905999999,
              "alt": 1884.8546000000001,
              "speed2d": 5.0203999999999995,
              "speed3d": 5.052,
              "msFromStart": 343729.34613333317
            },
            {
              "lat": 43.76445968333333,
              "lng": -116.10128626666668,
              "alt": 1884.6978333333334,
              "speed2d": 5.4435,
              "speed3d": 5.460000000000001,
              "msFromStart": 344326.0266666664
            },
            {
              "lat": 43.76444855,
              "lng": -116.10129562499999,
              "alt": 1884.67175,
              "speed2d": 5.3385,
              "speed3d": 5.365,
              "msFromStart": 344600.3244444444
            }
          ]
        }
      ]
    },
    {
      "id": "trail-cabin-traverse",
      "pathType": "trail",
      "name": "Cabin Traverse",
      "difficulty": "blue",
      "isCatTrack": true,
      "videos": [
        {
          "videoId": "GH010025",
          "pathId": "trail-cabin-traverse",
          "startSec": 147,
          "endSec": 265,
          "id": "video-segment-12",
          "gps": [
            {
              "lat": 43.76377033333333,
              "lng": -116.08609019999999,
              "alt": 2173.3668333333335,
              "speed2d": 8.850333333333333,
              "speed3d": 8.918333333333333,
              "msFromStart": 147266.25913725494
            },
            {
              "lat": 43.763734577777775,
              "lng": -116.08609933333335,
              "alt": 2173.1216666666664,
              "speed2d": 8.751,
              "speed3d": 8.81111111111111,
              "msFromStart": 147742.89035294112
            },
            {
              "lat": 43.76370381666666,
              "lng": -116.08611048333334,
              "alt": 2173.3306666666667,
              "speed2d": 8.6845,
              "speed3d": 8.726666666666667,
              "msFromStart": 148178.80596078423
            },
            {
              "lat": 43.76366295,
              "lng": -116.08612995,
              "alt": 2173.918,
              "speed2d": 8.555,
              "speed3d": 8.555,
              "msFromStart": 148765.66399999987
            },
            {
              "lat": 43.76364720628418,
              "lng": -116.08613853040694,
              "alt": 2174.157939005532,
              "speed2d": 8.438460255518706,
              "speed3d": 8.449824395711532,
              "msFromStart": 149000
            },
            {
              "lat": 43.7635971,
              "lng": -116.08616913333334,
              "alt": 2174.517,
              "speed2d": 8.089333333333334,
              "speed3d": 8.11,
              "msFromStart": 149783.91466666662
            },
            {
              "lat": 43.76357106,
              "lng": -116.08618858,
              "alt": 2174.61,
              "speed2d": 7.8184000000000005,
              "speed3d": 7.86,
              "msFromStart": 150224.77212631574
            },
            {
              "lat": 43.7635371,
              "lng": -116.086214525,
              "alt": 2174.88775,
              "speed2d": 7.5675,
              "speed3d": 7.5825000000000005,
              "msFromStart": 150815.24526315782
            },
            {
              "lat": 43.76350778333333,
              "lng": -116.0862373,
              "alt": 2174.9585,
              "speed2d": 7.363333333333333,
              "speed3d": 7.395,
              "msFromStart": 151332.5807095516
            },
            {
              "lat": 43.76347941428572,
              "lng": -116.08625769999999,
              "alt": 2174.8784285714287,
              "speed2d": 7.453857142857143,
              "speed3d": 7.458571428571428,
              "msFromStart": 151818.71288888878
            },
            {
              "lat": 43.7634594,
              "lng": -116.0862716,
              "alt": 2174.8046,
              "speed2d": 7.2918,
              "speed3d": 7.327999999999999,
              "msFromStart": 152170.92799999987
            },
            {
              "lat": 43.763423949999996,
              "lng": -116.08629640000001,
              "alt": 2174.6981250000003,
              "speed2d": 7.283625000000001,
              "speed3d": 7.323750000000001,
              "msFromStart": 152780.82222222225
            },
            {
              "lat": 43.763391999999996,
              "lng": -116.0863174,
              "alt": 2174.6492500000004,
              "speed2d": 7.39975,
              "speed3d": 7.3500000000000005,
              "msFromStart": 153316.46711111115
            },
            {
              "lat": 43.76336903333333,
              "lng": -116.08633276666666,
              "alt": 2174.5806666666667,
              "speed2d": 7.721333333333334,
              "speed3d": 7.68,
              "msFromStart": 153669.27170370377
            },
            {
              "lat": 43.763329399999996,
              "lng": -116.0863584,
              "alt": 2174.3346666666666,
              "speed2d": 8.258,
              "speed3d": 8.205,
              "msFromStart": 154237.46972319693
            },
            {
              "lat": 43.763294762499996,
              "lng": -116.08638116250002,
              "alt": 2174.148,
              "speed2d": 8.42425,
              "speed3d": 8.438749999999999,
              "msFromStart": 154732.1515789474
            },
            {
              "lat": 43.763259475,
              "lng": -116.08640388749998,
              "alt": 2173.8892499999997,
              "speed2d": 8.517875,
              "speed3d": 8.51375,
              "msFromStart": 155240.14663157897
            },
            {
              "lat": 43.76322077777778,
              "lng": -116.08643083333332,
              "alt": 2173.598,
              "speed2d": 8.957333333333334,
              "speed3d": 8.96111111111111,
              "msFromStart": 155772.3679999999
            },
            {
              "lat": 43.763194275000004,
              "lng": -116.08644745,
              "alt": 2173.312,
              "speed2d": 8.6845,
              "speed3d": 8.772499999999999,
              "msFromStart": 156154.05244444433
            },
            {
              "lat": 43.763147385714284,
              "lng": -116.0864724,
              "alt": 2173.2195714285713,
              "speed2d": 8.534714285714285,
              "speed3d": 8.557142857142857,
              "msFromStart": 156804.26895238095
            },
            {
              "lat": 43.763118649999996,
              "lng": -116.08648554999999,
              "alt": 2172.995,
              "speed2d": 8.55725,
              "speed3d": 8.555,
              "msFromStart": 157198.9488888889
            },
            {
              "lat": 43.7630859,
              "lng": -116.08649805,
              "alt": 2172.7585,
              "speed2d": 8.6035,
              "speed3d": 8.595,
              "msFromStart": 157658.58133333336
            },
            {
              "lat": 43.763040871428565,
              "lng": -116.08650852857143,
              "alt": 2172.4245714285717,
              "speed2d": 8.343428571428571,
              "speed3d": 8.434285714285714,
              "msFromStart": 158271.20537815132
            },
            {
              "lat": 43.76300631111111,
              "lng": -116.08651224444444,
              "alt": 2172.183111111111,
              "speed2d": 8.100888888888889,
              "speed3d": 8.123333333333333,
              "msFromStart": 158742.56752941184
            },
            {
              "lat": 43.762975371428574,
              "lng": -116.08650862857142,
              "alt": 2171.9645714285716,
              "speed2d": 7.788285714285715,
              "speed3d": 7.8514285714285705,
              "msFromStart": 159202.12713666525
            },
            {
              "lat": 43.76293798571429,
              "lng": -116.08649984285715,
              "alt": 2171.3847142857144,
              "speed2d": 7.918142857142857,
              "speed3d": 7.942857142857143,
              "msFromStart": 159728.17190977436
            },
            {
              "lat": 43.762907325,
              "lng": -116.08649207500001,
              "alt": 2170.8825,
              "speed2d": 7.52425,
              "speed3d": 7.6375,
              "msFromStart": 160202.400736842
            },
            {
              "lat": 43.76287394,
              "lng": -116.08647948000001,
              "alt": 2170.3346,
              "speed2d": 7.3568,
              "speed3d": 7.364000000000001,
              "msFromStart": 160724.98666666672
            },
            {
              "lat": 43.762830025,
              "lng": -116.08645634999999,
              "alt": 2169.723,
              "speed2d": 7.9567499999999995,
              "speed3d": 7.952500000000001,
              "msFromStart": 161357.93644444447
            },
            {
              "lat": 43.76280413333333,
              "lng": -116.08644050000001,
              "alt": 2169.5294999999996,
              "speed2d": 8.1945,
              "speed3d": 8.231666666666666,
              "msFromStart": 161724.6429629629
            },
            {
              "lat": 43.76277046,
              "lng": -116.08642,
              "alt": 2169.226,
              "speed2d": 8.401,
              "speed3d": 8.452,
              "msFromStart": 162199.54079999993
            },
            {
              "lat": 43.7627498392635,
              "lng": -116.08640954152378,
              "alt": 2169.223600254731,
              "speed2d": 8.232826285327377,
              "speed3d": 7.925169522949742,
              "msFromStart": 162500
            },
            {
              "lat": 43.76270395,
              "lng": -116.08637336666666,
              "alt": 2168.6781666666666,
              "speed2d": 8.499666666666668,
              "speed3d": 8.538333333333334,
              "msFromStart": 163175.22725925915
            },
            {
              "lat": 43.762671999999995,
              "lng": -116.08634765,
              "alt": 2168.343,
              "speed2d": 8.343,
              "speed3d": 8.41,
              "msFromStart": 163670.472
            },
            {
              "lat": 43.76263815,
              "lng": -116.08631928333332,
              "alt": 2168.0769999999998,
              "speed2d": 8.360833333333334,
              "speed3d": 8.398333333333333,
              "msFromStart": 164193.0020116959
            },
            {
              "lat": 43.76260136,
              "lng": -116.08628576000001,
              "alt": 2167.9576,
              "speed2d": 8.2908,
              "speed3d": 8.335999999999999,
              "msFromStart": 164787.09490526316
            },
            {
              "lat": 43.762588835168565,
              "lng": -116.08627325359748,
              "alt": 2167.9123802569334,
              "speed2d": 8.282167744082894,
              "speed3d": 8.337075425695001,
              "msFromStart": 165000
            },
            {
              "lat": 43.76256354870504,
              "lng": -116.08624414912833,
              "alt": 2167.4022696685156,
              "speed2d": 8.108166831714614,
              "speed3d": 8.16182270820177,
              "msFromStart": 165500
            },
            {
              "lat": 43.76253826224151,
              "lng": -116.08621504465917,
              "alt": 2166.8921590800983,
              "speed2d": 7.9341659193463325,
              "speed3d": 7.986569990708537,
              "msFromStart": 166000
            },
            {
              "lat": 43.762501275000005,
              "lng": -116.0861691625,
              "alt": 2165.955375,
              "speed2d": 7.672749999999999,
              "speed3d": 7.734999999999999,
              "msFromStart": 166771.2983333334
            },
            {
              "lat": 43.762482133333336,
              "lng": -116.08613516666665,
              "alt": 2165.0298333333335,
              "speed2d": 7.6345,
              "speed3d": 7.6883333333333335,
              "msFromStart": 167276.94740740742
            },
            {
              "lat": 43.76246428571429,
              "lng": -116.08609628571429,
              "alt": 2164.2184285714284,
              "speed2d": 7.909571428571429,
              "speed3d": 7.93,
              "msFromStart": 167777.93892063483
            },
            {
              "lat": 43.7624467,
              "lng": -116.08605253333333,
              "alt": 2164.0216666666665,
              "speed2d": 8.395166666666666,
              "speed3d": 8.406666666666666,
              "msFromStart": 168269.9240697167
            },
            {
              "lat": 43.7624318,
              "lng": -116.0860045,
              "alt": 2163.827,
              "speed2d": 8.634,
              "speed3d": 8.620000000000001,
              "msFromStart": 168811.2865882353
            },
            {
              "lat": 43.762421499999995,
              "lng": -116.08596893333333,
              "alt": 2163.768,
              "speed2d": 8.938666666666666,
              "speed3d": 8.91,
              "msFromStart": 169170.76988235296
            },
            {
              "lat": 43.762412237769986,
              "lng": -116.08593959663503,
              "alt": 2163.0815997012583,
              "speed2d": 8.195844798509473,
              "speed3d": 8.013555998136841,
              "msFromStart": 169500
            },
            {
              "lat": 43.762394057142856,
              "lng": -116.08586685714286,
              "alt": 2162.2501428571427,
              "speed2d": 8.796142857142858,
              "speed3d": 8.838571428571429,
              "msFromStart": 170220.70514954056
            },
            {
              "lat": 43.76238215714286,
              "lng": -116.08582007142857,
              "alt": 2161.6324285714286,
              "speed2d": 8.683571428571428,
              "speed3d": 8.741428571428571,
              "msFromStart": 170732.65395488736
            },
            {
              "lat": 43.76236874285714,
              "lng": -116.08576724285714,
              "alt": 2161.2774285714286,
              "speed2d": 8.762142857142857,
              "speed3d": 8.775714285714287,
              "msFromStart": 171251.97285213048
            },
            {
              "lat": 43.76235858571428,
              "lng": -116.08572152857143,
              "alt": 2161.368857142857,
              "speed2d": 8.917571428571428,
              "speed3d": 8.944285714285716,
              "msFromStart": 171675.70666666672
            },
            {
              "lat": 43.76234703333333,
              "lng": -116.08566336666667,
              "alt": 2161.462666666667,
              "speed2d": 8.960333333333335,
              "speed3d": 8.97,
              "msFromStart": 172224.6133333334
            },
            {
              "lat": 43.76233886,
              "lng": -116.08560639999999,
              "alt": 2161.1191999999996,
              "speed2d": 8.448,
              "speed3d": 8.532,
              "msFromStart": 172828.77511111114
            },
            {
              "lat": 43.76233542000001,
              "lng": -116.08557293999999,
              "alt": 2160.8584,
              "speed2d": 8.1266,
              "speed3d": 8.168,
              "msFromStart": 173202.35520000005
            },
            {
              "lat": 43.7623295,
              "lng": -116.08552700000001,
              "alt": 2160.3236666666667,
              "speed2d": 8.043,
              "speed3d": 8.006666666666668,
              "msFromStart": 173708.61866666676
            },
            {
              "lat": 43.76231925,
              "lng": -116.0854723,
              "alt": 2160.0595000000003,
              "speed2d": 8.299,
              "speed3d": 8.3,
              "msFromStart": 174273.08333333343
            },
            {
              "lat": 43.762318640844114,
              "lng": -116.08544951901199,
              "alt": 2159.942403708712,
              "speed2d": 8.137133813019146,
              "speed3d": 8.271807514004255,
              "msFromStart": 174500
            },
            {
              "lat": 43.7622998791414,
              "lng": -116.08540670291669,
              "alt": 2158.995771592344,
              "speed2d": 8.373987264874728,
              "speed3d": 8.335084728207343,
              "msFromStart": 175000
            },
            {
              "lat": 43.762260133333335,
              "lng": -116.0853549,
              "alt": 2157.5743333333335,
              "speed2d": 7.7876666666666665,
              "speed3d": 7.8966666666666665,
              "msFromStart": 175856.79747368427
            },
            {
              "lat": 43.762232475,
              "lng": -116.085341975,
              "alt": 2157.46375,
              "speed2d": 7.50125,
              "speed3d": 7.5275,
              "msFromStart": 176323.18526315794
            },
            {
              "lat": 43.762211,
              "lng": -116.08533674285715,
              "alt": 2157.5532857142857,
              "speed2d": 7.276142857142858,
              "speed3d": 7.372857142857143,
              "msFromStart": 176679.53599999993
            },
            {
              "lat": 43.7621793,
              "lng": -116.08534773999999,
              "alt": 2156.6244,
              "speed2d": 6.3309999999999995,
              "speed3d": 6.49,
              "msFromStart": 177293.20853333327
            },
            {
              "lat": 43.76216541428572,
              "lng": -116.08537492857143,
              "alt": 2155.127857142857,
              "speed2d": 5.540428571428571,
              "speed3d": 5.705714285714286,
              "msFromStart": 177718.90666666662
            },
            {
              "lat": 43.76215885,
              "lng": -116.0854178,
              "alt": 2154.693,
              "speed2d": 5.2515,
              "speed3d": 5.29,
              "msFromStart": 178346.79733333332
            },
            {
              "lat": 43.762155175000004,
              "lng": -116.08544762499999,
              "alt": 2154.66075,
              "speed2d": 5.728250000000001,
              "speed3d": 5.7375,
              "msFromStart": 178758.78399999996
            },
            {
              "lat": 43.7621491,
              "lng": -116.0854885,
              "alt": 2154.5122,
              "speed2d": 6.5228,
              "speed3d": 6.516,
              "msFromStart": 179233.2060444444
            },
            {
              "lat": 43.762145239999995,
              "lng": -116.0855256,
              "alt": 2153.9013999999997,
              "speed2d": 6.781799999999999,
              "speed3d": 6.834,
              "msFromStart": 179696.11626666665
            },
            {
              "lat": 43.76214340975516,
              "lng": -116.08554052851419,
              "alt": 2153.7119375679986,
              "speed2d": 6.519399959033015,
              "speed3d": 6.732547067172242,
              "msFromStart": 180000
            },
            {
              "lat": 43.7621222,
              "lng": -116.08564039999999,
              "alt": 2153.1798333333336,
              "speed2d": 8.552000000000001,
              "speed3d": 8.51,
              "msFromStart": 180774.0079999999
            },
            {
              "lat": 43.76210821428572,
              "lng": -116.08568737142858,
              "alt": 2153.4528571428573,
              "speed2d": 8.901285714285713,
              "speed3d": 8.935714285714285,
              "msFromStart": 181213.42516762487
            },
            {
              "lat": 43.76208869999999,
              "lng": -116.0857403,
              "alt": 2153.9102857142857,
              "speed2d": 9.347428571428571,
              "speed3d": 9.37142857142857,
              "msFromStart": 181691.79657142863
            },
            {
              "lat": 43.76206375555555,
              "lng": -116.08579987777777,
              "alt": 2154.4141111111107,
              "speed2d": 9.682222222222222,
              "speed3d": 9.698888888888888,
              "msFromStart": 182231.9231500975
            },
            {
              "lat": 43.76204362857143,
              "lng": -116.08584085714286,
              "alt": 2154.470571428572,
              "speed2d": 9.469285714285714,
              "speed3d": 9.545714285714286,
              "msFromStart": 182687.64723809523
            },
            {
              "lat": 43.76201581666667,
              "lng": -116.08588731666666,
              "alt": 2154.6295,
              "speed2d": 9.433166666666667,
              "speed3d": 9.446666666666667,
              "msFromStart": 183213.30770370373
            },
            {
              "lat": 43.76199725200616,
              "lng": -116.08591187556709,
              "alt": 2154.510222566618,
              "speed2d": 9.481815075778147,
              "speed3d": 9.494228261024583,
              "msFromStart": 183500
            },
            {
              "lat": 43.76197260452045,
              "lng": -116.08594288541896,
              "alt": 2153.787906879197,
              "speed2d": 9.221973903249282,
              "speed3d": 9.248288056573394,
              "msFromStart": 184000
            },
            {
              "lat": 43.761931775,
              "lng": -116.085993325,
              "alt": 2152.6535,
              "speed2d": 8.82775,
              "speed3d": 8.8825,
              "msFromStart": 184818.03733333328
            },
            {
              "lat": 43.76191165,
              "lng": -116.0860237,
              "alt": 2152.119,
              "speed2d": 9.086500000000001,
              "speed3d": 9.04,
              "msFromStart": 185202.90807017538
            },
            {
              "lat": 43.76188175,
              "lng": -116.0860812,
              "alt": 2151.349,
              "speed2d": 9.9145,
              "speed3d": 9.86,
              "msFromStart": 185780.22399999993
            },
            {
              "lat": 43.76185627499999,
              "lng": -116.08613141249998,
              "alt": 2150.149,
              "speed2d": 10.739875,
              "speed3d": 10.715,
              "msFromStart": 186220.0747017543
            },
            {
              "lat": 43.761816350000004,
              "lng": -116.0862058,
              "alt": 2148.6055,
              "speed2d": 11.235,
              "speed3d": 11.215,
              "msFromStart": 186907.04177777778
            },
            {
              "lat": 43.7617950375,
              "lng": -116.0862433625,
              "alt": 2147.8223749999997,
              "speed2d": 11.497375,
              "speed3d": 11.5575,
              "msFromStart": 187250.4786666667
            },
            {
              "lat": 43.76176495714285,
              "lng": -116.08629965714286,
              "alt": 2146.8712857142855,
              "speed2d": 11.733571428571429,
              "speed3d": 11.778571428571427,
              "msFromStart": 187770.3702857144
            },
            {
              "lat": 43.761739975,
              "lng": -116.086346,
              "alt": 2146.358625,
              "speed2d": 11.5025,
              "speed3d": 11.63,
              "msFromStart": 188239.29733333344
            },
            {
              "lat": 43.761714366666666,
              "lng": -116.08638555555557,
              "alt": 2146.2876666666666,
              "speed2d": 10.818999999999999,
              "speed3d": 10.976666666666667,
              "msFromStart": 188720.6648888889
            },
            {
              "lat": 43.76168648000001,
              "lng": -116.08643074,
              "alt": 2145.7196,
              "speed2d": 10.5774,
              "speed3d": 10.600000000000001,
              "msFromStart": 189249.06648888887
            },
            {
              "lat": 43.76165444,
              "lng": -116.08649102000001,
              "alt": 2144.9596,
              "speed2d": 10.615,
              "speed3d": 10.661999999999999,
              "msFromStart": 189845.08373333345
            },
            {
              "lat": 43.761631666666666,
              "lng": -116.08652836666667,
              "alt": 2144.233666666667,
              "speed2d": 10.694999999999999,
              "speed3d": 10.738333333333333,
              "msFromStart": 190227.55192982464
            },
            {
              "lat": 43.7616091,
              "lng": -116.08656435,
              "alt": 2143.7015,
              "speed2d": 10.941500000000001,
              "speed3d": 10.965,
              "msFromStart": 190566.45473684213
            },
            {
              "lat": 43.76158341946052,
              "lng": -116.08661270776997,
              "alt": 2143.0281679138857,
              "speed2d": 11.11484750613046,
              "speed3d": 11.15457232977286,
              "msFromStart": 191000
            },
            {
              "lat": 43.76153195,
              "lng": -116.0867123,
              "alt": 2141.6375,
              "speed2d": 11.47,
              "speed3d": 11.515,
              "msFromStart": 191885.6613333333
            },
            {
              "lat": 43.76151706666666,
              "lng": -116.08675428333333,
              "alt": 2140.896333333333,
              "speed2d": 11.533333333333333,
              "speed3d": 11.605,
              "msFromStart": 192234.33585185182
            },
            {
              "lat": 43.76149863333333,
              "lng": -116.08681355,
              "alt": 2139.672,
              "speed2d": 11.660499999999999,
              "speed3d": 11.726666666666667,
              "msFromStart": 192710.33466666675
            },
            {
              "lat": 43.76148398,
              "lng": -116.08687778000001,
              "alt": 2138.7466,
              "speed2d": 11.936200000000001,
              "speed3d": 12,
              "msFromStart": 193209.83662222233
            },
            {
              "lat": 43.761473575422265,
              "lng": -116.0869183577847,
              "alt": 2138.342981270265,
              "speed2d": 12.159016890638364,
              "speed3d": 11.978742962234021,
              "msFromStart": 193500
            },
            {
              "lat": 43.761466287500006,
              "lng": -116.08700891250001,
              "alt": 2136.2366250000005,
              "speed2d": 11.75175,
              "speed3d": 11.915,
              "msFromStart": 194250.66755555558
            },
            {
              "lat": 43.761466842857146,
              "lng": -116.08706719999999,
              "alt": 2134.8255714285715,
              "speed2d": 11.386857142857142,
              "speed3d": 11.532857142857143,
              "msFromStart": 194749.59619047624
            },
            {
              "lat": 43.76146131666667,
              "lng": -116.08713365,
              "alt": 2132.8706666666667,
              "speed2d": 11.212166666666667,
              "speed3d": 11.335,
              "msFromStart": 195295.52211306043
            },
            {
              "lat": 43.76145248,
              "lng": -116.08721202000001,
              "alt": 2132.2506,
              "speed2d": 11.794599999999999,
              "speed3d": 11.876,
              "msFromStart": 195818.05473684202
            },
            {
              "lat": 43.761449083892906,
              "lng": -116.08723932885674,
              "alt": 2131.9518442360813,
              "speed2d": 11.928657830815686,
              "speed3d": 12.005874556602075,
              "msFromStart": 196000
            },
            {
              "lat": 43.761435,
              "lng": -116.08734735000002,
              "alt": 2130.7438333333334,
              "speed2d": 12.184333333333333,
              "speed3d": 12.281666666666666,
              "msFromStart": 196725.7973333333
            },
            {
              "lat": 43.76142496666667,
              "lng": -116.08742331111112,
              "alt": 2129.7953333333335,
              "speed2d": 12.146,
              "speed3d": 12.292222222222222,
              "msFromStart": 197248.5327407407
            },
            {
              "lat": 43.76142018888889,
              "lng": -116.08748261111111,
              "alt": 2128.578333333334,
              "speed2d": 11.443222222222223,
              "speed3d": 11.644444444444446,
              "msFromStart": 197743.66044444457
            },
            {
              "lat": 43.76141654,
              "lng": -116.08752872,
              "alt": 2127.5516,
              "speed2d": 10.8586,
              "speed3d": 11.037999999999998,
              "msFromStart": 198150.759288889
            },
            {
              "lat": 43.76140666,
              "lng": -116.08759784000002,
              "alt": 2126.448,
              "speed2d": 10.5628,
              "speed3d": 10.712000000000002,
              "msFromStart": 198712.37048888888
            },
            {
              "lat": 43.7613915,
              "lng": -116.08766320000001,
              "alt": 2124.691,
              "speed2d": 10.465142857142858,
              "speed3d": 10.587142857142858,
              "msFromStart": 199269.36355555555
            },
            {
              "lat": 43.761369155555556,
              "lng": -116.08771421111112,
              "alt": 2122.9021111111115,
              "speed2d": 10.241555555555555,
              "speed3d": 10.387777777777776,
              "msFromStart": 199767.79012345665
            },
            {
              "lat": 43.76134151111111,
              "lng": -116.08775917777777,
              "alt": 2121.221,
              "speed2d": 9.965,
              "speed3d": 10.154444444444445,
              "msFromStart": 200274.711974009
            },
            {
              "lat": 43.76130836,
              "lng": -116.08779966,
              "alt": 2119.563,
              "speed2d": 9.5174,
              "speed3d": 9.67,
              "msFromStart": 200834.53069473678
            },
            {
              "lat": 43.76127915,
              "lng": -116.08781769999999,
              "alt": 2117.6755000000003,
              "speed2d": 9.041,
              "speed3d": 9.215,
              "msFromStart": 201262.8282807017
            },
            {
              "lat": 43.76125256666667,
              "lng": -116.08782783333334,
              "alt": 2115.88,
              "speed2d": 8.667333333333332,
              "speed3d": 8.83,
              "msFromStart": 201629.45748148143
            },
            {
              "lat": 43.7611977,
              "lng": -116.08784885,
              "alt": 2112.0615,
              "speed2d": 7.9515,
              "speed3d": 8.2,
              "msFromStart": 202391.1826666667
            },
            {
              "lat": 43.761177399999994,
              "lng": -116.08785311999999,
              "alt": 2110.6832000000004,
              "speed2d": 7.800599999999999,
              "speed3d": 8.07,
              "msFromStart": 202671.88213333333
            },
            {
              "lat": 43.76113131428571,
              "lng": -116.08786225714287,
              "alt": 2107.689142857143,
              "speed2d": 7.748571428571428,
              "speed3d": 8.055714285714286,
              "msFromStart": 203299.21904761906
            },
            {
              "lat": 43.7611072,
              "lng": -116.08786855,
              "alt": 2106.37275,
              "speed2d": 7.7955,
              "speed3d": 8.075,
              "msFromStart": 203628.80000000008
            },
            {
              "lat": 43.7610453,
              "lng": -116.08787815,
              "alt": 2104.5024999999996,
              "speed2d": 7.8835,
              "speed3d": 8.19,
              "msFromStart": 204425.28177777777
            },
            {
              "lat": 43.76103234,
              "lng": -116.08787952,
              "alt": 2103.7218,
              "speed2d": 7.688599999999999,
              "speed3d": 8.044,
              "msFromStart": 204628.83768888883
            },
            {
              "lat": 43.7609863,
              "lng": -116.087884075,
              "alt": 2101.074,
              "speed2d": 7.2955000000000005,
              "speed3d": 7.687500000000001,
              "msFromStart": 205333.07906432747
            },
            {
              "lat": 43.76097303569501,
              "lng": -116.0878905032153,
              "alt": 2100.7589362763106,
              "speed2d": 7.295466501468423,
              "speed3d": 7.4071062775330345,
              "msFromStart": 205500
            },
            {
              "lat": 43.760946133333334,
              "lng": -116.08789106666666,
              "alt": 2097.1620000000003,
              "speed2d": 5.802666666666666,
              "speed3d": 6.366666666666666,
              "msFromStart": 206177.2227368421
            },
            {
              "lat": 43.76091632,
              "lng": -116.08789404,
              "alt": 2095.4214,
              "speed2d": 5.8246,
              "speed3d": 6.3180000000000005,
              "msFromStart": 206698.15893333335
            },
            {
              "lat": 43.7608773,
              "lng": -116.08789701666666,
              "alt": 2093.6683333333335,
              "speed2d": 6.4431666666666665,
              "speed3d": 6.891666666666666,
              "msFromStart": 207268.60800000004
            },
            {
              "lat": 43.760844766666665,
              "lng": -116.0879044,
              "alt": 2092.480666666667,
              "speed2d": 6.868500000000001,
              "speed3d": 7.308333333333333,
              "msFromStart": 207727.80799999993
            },
            {
              "lat": 43.760802299999995,
              "lng": -116.0879162,
              "alt": 2091.051,
              "speed2d": 7.421142857142858,
              "speed3d": 7.797142857142857,
              "msFromStart": 208306.53485714283
            },
            {
              "lat": 43.760775100000004,
              "lng": -116.08792066666666,
              "alt": 2090.2071666666666,
              "speed2d": 7.611333333333333,
              "speed3d": 7.984999999999999,
              "msFromStart": 208673.43288888896
            },
            {
              "lat": 43.760743299999994,
              "lng": -116.0879307,
              "alt": 2089.6223333333332,
              "speed2d": 8.017333333333335,
              "speed3d": 8.316666666666666,
              "msFromStart": 209076.92266666674
            },
            {
              "lat": 43.7606836,
              "lng": -116.08795964,
              "alt": 2089.5368,
              "speed2d": 8.688,
              "speed3d": 8.932,
              "msFromStart": 209846.59022222224
            },
            {
              "lat": 43.760668,
              "lng": -116.08797006666667,
              "alt": 2089.5629999999996,
              "speed2d": 8.707333333333333,
              "speed3d": 8.966666666666667,
              "msFromStart": 210066.4231111111
            },
            {
              "lat": 43.7606264,
              "lng": -116.08799828,
              "alt": 2089.7383999999997,
              "speed2d": 8.7452,
              "speed3d": 8.972,
              "msFromStart": 210649.05869473692
            },
            {
              "lat": 43.760569274999995,
              "lng": -116.08803650000002,
              "alt": 2091.1980000000003,
              "speed2d": 8.95425,
              "speed3d": 9.055,
              "msFromStart": 211385.7488070176
            },
            {
              "lat": 43.76054431666667,
              "lng": -116.08806186666666,
              "alt": 2091.5015,
              "speed2d": 8.996333333333334,
              "speed3d": 9.131666666666668,
              "msFromStart": 211743.5080000001
            },
            {
              "lat": 43.76051234,
              "lng": -116.0881042,
              "alt": 2091.7486,
              "speed2d": 9.1306,
              "speed3d": 9.2,
              "msFromStart": 212244.30293333338
            },
            {
              "lat": 43.760476450000006,
              "lng": -116.08815787500001,
              "alt": 2092.7815,
              "speed2d": 9.820125,
              "speed3d": 9.860000000000001,
              "msFromStart": 212781.13555555552
            },
            {
              "lat": 43.76045064444445,
              "lng": -116.0882022111111,
              "alt": 2093.2051111111114,
              "speed2d": 9.692111111111112,
              "speed3d": 9.818888888888889,
              "msFromStart": 213254.6915555555
            },
            {
              "lat": 43.76042966666667,
              "lng": -116.08824515555555,
              "alt": 2093.253,
              "speed2d": 9.06,
              "speed3d": 9.186666666666666,
              "msFromStart": 213750.37688888877
            },
            {
              "lat": 43.760409511111106,
              "lng": -116.08828841111111,
              "alt": 2093.025888888889,
              "speed2d": 8.663777777777778,
              "speed3d": 8.782222222222222,
              "msFromStart": 214246.36888888874
            },
            {
              "lat": 43.76039036,
              "lng": -116.08833008,
              "alt": 2092.9156,
              "speed2d": 8.3118,
              "speed3d": 8.402,
              "msFromStart": 214741.5608888888
            },
            {
              "lat": 43.760372739999994,
              "lng": -116.08836868,
              "alt": 2093.2486,
              "speed2d": 8.3232,
              "speed3d": 8.356,
              "msFromStart": 215159.54782690047
            },
            {
              "lat": 43.760340725,
              "lng": -116.08843437499999,
              "alt": 2094.026875,
              "speed2d": 9.353875,
              "speed3d": 9.307500000000001,
              "msFromStart": 215745.03452631578
            },
            {
              "lat": 43.760326487915854,
              "lng": -116.08846255251751,
              "alt": 2094.3591241232853,
              "speed2d": 9.673332719038811,
              "speed3d": 9.63515195118425,
              "msFromStart": 216000
            },
            {
              "lat": 43.76030486412781,
              "lng": -116.08850916177494,
              "alt": 2094.554919346937,
              "speed2d": 9.400309680953693,
              "speed3d": 9.380754444815423,
              "msFromStart": 216500
            },
            {
              "lat": 43.760269525,
              "lng": -116.08858493749999,
              "alt": 2094.676375,
              "speed2d": 9.304125,
              "speed3d": 9.3225,
              "msFromStart": 217292.60066666675
            },
            {
              "lat": 43.76025045,
              "lng": -116.0886271,
              "alt": 2094.48775,
              "speed2d": 9.38175,
              "speed3d": 9.42,
              "msFromStart": 217711.49511111111
            },
            {
              "lat": 43.760229300000006,
              "lng": -116.08867183333334,
              "alt": 2094.0696666666668,
              "speed2d": 9.459000000000001,
              "speed3d": 9.503333333333334,
              "msFromStart": 218169.0654814815
            },
            {
              "lat": 43.76020622,
              "lng": -116.0887236,
              "alt": 2094.1182000000003,
              "speed2d": 9.251999999999999,
              "speed3d": 9.338,
              "msFromStart": 218700.21422222225
            },
            {
              "lat": 43.76018214,
              "lng": -116.08877965999999,
              "alt": 2093.8134,
              "speed2d": 9.0098,
              "speed3d": 9.062000000000001,
              "msFromStart": 219293.93315555557
            },
            {
              "lat": 43.7601619,
              "lng": -116.08882759999999,
              "alt": 2093.6471666666666,
              "speed2d": 9.078333333333333,
              "speed3d": 9.115,
              "msFromStart": 219773.9537777778
            },
            {
              "lat": 43.76014303333333,
              "lng": -116.08887396666665,
              "alt": 2093.677,
              "speed2d": 9.267333333333333,
              "speed3d": 9.298333333333334,
              "msFromStart": 220215.30014814818
            },
            {
              "lat": 43.760120533333335,
              "lng": -116.08892886666666,
              "alt": 2093.7433333333333,
              "speed2d": 9.696,
              "speed3d": 9.693333333333333,
              "msFromStart": 220682.80355555553
            },
            {
              "lat": 43.760089,
              "lng": -116.08900835,
              "alt": 2094.0420000000004,
              "speed2d": 10.253833333333333,
              "speed3d": 10.271666666666668,
              "msFromStart": 221287.01567251462
            },
            {
              "lat": 43.7600664,
              "lng": -116.0890742,
              "alt": 2094.414,
              "speed2d": 10.195,
              "speed3d": 10.25,
              "msFromStart": 221809.016
            },
            {
              "lat": 43.760050119999995,
              "lng": -116.0891287,
              "alt": 2094.6528000000003,
              "speed2d": 10.0782,
              "speed3d": 10.129999999999999,
              "msFromStart": 222254.11847485378
            },
            {
              "lat": 43.760036066666665,
              "lng": -116.08917053333334,
              "alt": 2094.5679999999998,
              "speed2d": 10.048666666666668,
              "speed3d": 10.106666666666666,
              "msFromStart": 222605.83111111115
            },
            {
              "lat": 43.760014475,
              "lng": -116.08925235,
              "alt": 2094.20175,
              "speed2d": 9.59775,
              "speed3d": 9.6925,
              "msFromStart": 223347.74244444445
            },
            {
              "lat": 43.76000403333333,
              "lng": -116.08930129999999,
              "alt": 2094.0018333333333,
              "speed2d": 9.342666666666666,
              "speed3d": 9.441666666666666,
              "msFromStart": 223768.8448888888
            },
            {
              "lat": 43.75999546666667,
              "lng": -116.0893566,
              "alt": 2093.804666666667,
              "speed2d": 9.164666666666667,
              "speed3d": 9.226666666666667,
              "msFromStart": 224235.90222222207
            },
            {
              "lat": 43.75998893333333,
              "lng": -116.08942006666668,
              "alt": 2093.949777777778,
              "speed2d": 9.014333333333331,
              "speed3d": 9.145555555555555,
              "msFromStart": 224749.8826666666
            },
            {
              "lat": 43.759987360000004,
              "lng": -116.08947958,
              "alt": 2094.2814,
              "speed2d": 8.758000000000001,
              "speed3d": 8.868,
              "msFromStart": 225245.91326315788
            },
            {
              "lat": 43.75998748726004,
              "lng": -116.08950975584396,
              "alt": 2094.7129109967686,
              "speed2d": 8.30296859159891,
              "speed3d": 8.582739978458731,
              "msFromStart": 225500
            },
            {
              "lat": 43.75998756,
              "lng": -116.08959484,
              "alt": 2094.7868,
              "speed2d": 8.229,
              "speed3d": 8.318,
              "msFromStart": 226246.88976842107
            },
            {
              "lat": 43.7599907,
              "lng": -116.08966221666665,
              "alt": 2095.3761666666664,
              "speed2d": 8.245999999999999,
              "speed3d": 8.353333333333333,
              "msFromStart": 226813.30399999997
            },
            {
              "lat": 43.75999165,
              "lng": -116.089694425,
              "alt": 2095.28075,
              "speed2d": 8.058499999999999,
              "speed3d": 8.174999999999999,
              "msFromStart": 227115.75999999998
            },
            {
              "lat": 43.759996075000004,
              "lng": -116.08975995,
              "alt": 2095.552,
              "speed2d": 7.641,
              "speed3d": 7.7524999999999995,
              "msFromStart": 227734.19444444447
            },
            {
              "lat": 43.75999768,
              "lng": -116.0898185,
              "alt": 2095.6754,
              "speed2d": 7.581799999999999,
              "speed3d": 7.664000000000001,
              "msFromStart": 228248.27608888893
            },
            {
              "lat": 43.76000058571429,
              "lng": -116.08988452857142,
              "alt": 2095.778428571429,
              "speed2d": 7.720142857142856,
              "speed3d": 7.792857142857144,
              "msFromStart": 228821.44800000012
            },
            {
              "lat": 43.760002125,
              "lng": -116.089929625,
              "alt": 2095.8795,
              "speed2d": 7.7355,
              "speed3d": 7.795,
              "msFromStart": 229235.02933333343
            },
            {
              "lat": 43.76000155,
              "lng": -116.089988625,
              "alt": 2096.073,
              "speed2d": 7.736750000000001,
              "speed3d": 7.797499999999999,
              "msFromStart": 229771.7686666666
            },
            {
              "lat": 43.76000056,
              "lng": -116.0900301,
              "alt": 2096.1200000000003,
              "speed2d": 7.5973999999999995,
              "speed3d": 7.676,
              "msFromStart": 230154.14222222214
            },
            {
              "lat": 43.75999701666667,
              "lng": -116.09009748333332,
              "alt": 2096.4798333333333,
              "speed2d": 7.6155,
              "speed3d": 7.670000000000001,
              "msFromStart": 230749.2261052632
            },
            {
              "lat": 43.759994125,
              "lng": -116.090152675,
              "alt": 2096.8106249999996,
              "speed2d": 7.666,
              "speed3d": 7.69625,
              "msFromStart": 231238.43217543865
            },
            {
              "lat": 43.75999002857143,
              "lng": -116.09020964285713,
              "alt": 2097.2454285714284,
              "speed2d": 7.997999999999999,
              "speed3d": 7.975714285714285,
              "msFromStart": 231716.766984127
            },
            {
              "lat": 43.759983175,
              "lng": -116.090290325,
              "alt": 2097.83275,
              "speed2d": 8.45525,
              "speed3d": 8.4525,
              "msFromStart": 232372.2871111111
            },
            {
              "lat": 43.759978833333335,
              "lng": -116.09033248333333,
              "alt": 2097.6861666666664,
              "speed2d": 8.494,
              "speed3d": 8.523333333333333,
              "msFromStart": 232747.8921481482
            },
            {
              "lat": 43.7599710875,
              "lng": -116.09038857500002,
              "alt": 2097.11025,
              "speed2d": 8.428624999999998,
              "speed3d": 8.47125,
              "msFromStart": 233265.5604444445
            },
            {
              "lat": 43.759964866666664,
              "lng": -116.09042843333333,
              "alt": 2096.6183333333333,
              "speed2d": 8.311333333333332,
              "speed3d": 8.360000000000001,
              "msFromStart": 233646.15170370368
            },
            {
              "lat": 43.75995426666667,
              "lng": -116.09049126666667,
              "alt": 2095.9049999999997,
              "speed2d": 8.226666666666667,
              "speed3d": 8.216666666666667,
              "msFromStart": 234251.51881481474
            },
            {
              "lat": 43.7599477,
              "lng": -116.0905257,
              "alt": 2095.545,
              "speed2d": 8.3285,
              "speed3d": 8.39,
              "msFromStart": 234572.04000000004
            },
            {
              "lat": 43.7599298,
              "lng": -116.09059676666666,
              "alt": 2094.435,
              "speed2d": 8.484333333333334,
              "speed3d": 8.516666666666666,
              "msFromStart": 235239.78036647185
            },
            {
              "lat": 43.759915,
              "lng": -116.09064725,
              "alt": 2093.6615,
              "speed2d": 8.8255,
              "speed3d": 8.84,
              "msFromStart": 235698.20547368415
            },
            {
              "lat": 43.75989627999999,
              "lng": -116.0907126,
              "alt": 2093.2028,
              "speed2d": 9.089799999999999,
              "speed3d": 9.108,
              "msFromStart": 236237.56843040924
            },
            {
              "lat": 43.759878699999994,
              "lng": -116.09077201428572,
              "alt": 2092.744857142857,
              "speed2d": 9.491714285714286,
              "speed3d": 9.51857142857143,
              "msFromStart": 236703.62336507931
            },
            {
              "lat": 43.75985764,
              "lng": -116.09084099999998,
              "alt": 2092.2958,
              "speed2d": 9.9404,
              "speed3d": 9.958,
              "msFromStart": 237236.73511111105
            },
            {
              "lat": 43.75984002,
              "lng": -116.09090056000001,
              "alt": 2091.8864000000003,
              "speed2d": 10.145199999999999,
              "speed3d": 10.184000000000001,
              "msFromStart": 237698.63537777774
            },
            {
              "lat": 43.759828602066264,
              "lng": -116.09094004525751,
              "alt": 2091.5778715470533,
              "speed2d": 10.216994771735479,
              "speed3d": 10.211869553891125,
              "msFromStart": 238000
            },
            {
              "lat": 43.75979492500001,
              "lng": -116.091051075,
              "alt": 2090.82325,
              "speed2d": 10.562750000000001,
              "speed3d": 10.5575,
              "msFromStart": 238818.26111111118
            },
            {
              "lat": 43.75978738561456,
              "lng": -116.09107719950175,
              "alt": 2090.8074286321635,
              "speed2d": 10.693525512220967,
              "speed3d": 10.689830540078916,
              "msFromStart": 239000
            },
            {
              "lat": 43.75975672,
              "lng": -116.09118584,
              "alt": 2089.9049999999997,
              "speed2d": 10.582999999999998,
              "speed3d": 10.636,
              "msFromStart": 239822.06400000004
            },
            {
              "lat": 43.75975082351251,
              "lng": -116.09120890685597,
              "alt": 2089.735107500867,
              "speed2d": 10.569879867646273,
              "speed3d": 10.619440717899218,
              "msFromStart": 240000
            },
            {
              "lat": 43.75973474378396,
              "lng": -116.09127319351593,
              "alt": 2089.1515920280103,
              "speed2d": 10.45758714122403,
              "speed3d": 10.494328434393582,
              "msFromStart": 240500
            },
            {
              "lat": 43.7597236467611,
              "lng": -116.09133463831836,
              "alt": 2088.945290529397,
              "speed2d": 10.211514337582308,
              "speed3d": 10.174613275207646,
              "msFromStart": 241000
            },
            {
              "lat": 43.759709533333336,
              "lng": -116.0914214,
              "alt": 2089.388,
              "speed2d": 9.622,
              "speed3d": 9.729999999999999,
              "msFromStart": 241674.4005925926
            },
            {
              "lat": 43.75969813333333,
              "lng": -116.09149483333333,
              "alt": 2089.3263333333334,
              "speed2d": 9.124,
              "speed3d": 9.15,
              "msFromStart": 242296.01244444447
            },
            {
              "lat": 43.7596901,
              "lng": -116.09154419999999,
              "alt": 2089.346,
              "speed2d": 8.8945,
              "speed3d": 8.975000000000001,
              "msFromStart": 242708.3537777777
            },
            {
              "lat": 43.75967886666667,
              "lng": -116.09160848333332,
              "alt": 2088.938666666667,
              "speed2d": 8.748000000000001,
              "speed3d": 8.77,
              "msFromStart": 243277.114074074
            },
            {
              "lat": 43.75966861666667,
              "lng": -116.09166513333332,
              "alt": 2088.8888333333334,
              "speed2d": 8.619,
              "speed3d": 8.649999999999999,
              "msFromStart": 243764.4865185185
            },
            {
              "lat": 43.7596587,
              "lng": -116.09171654285714,
              "alt": 2088.739428571429,
              "speed2d": 8.45657142857143,
              "speed3d": 8.524285714285714,
              "msFromStart": 244224.37053968257
            },
            {
              "lat": 43.759646325,
              "lng": -116.091764625,
              "alt": 2088.63325,
              "speed2d": 8.234,
              "speed3d": 8.25,
              "msFromStart": 244650.63755555553
            },
            {
              "lat": 43.759624875,
              "lng": -116.091841,
              "alt": 2087.6744999999996,
              "speed2d": 8.65825,
              "speed3d": 8.65,
              "msFromStart": 245309.44460818713
            },
            {
              "lat": 43.7596069,
              "lng": -116.0918978,
              "alt": 2087.1655,
              "speed2d": 9.1235,
              "speed3d": 9.047500000000001,
              "msFromStart": 245762.38989473687
            },
            {
              "lat": 43.759574775,
              "lng": -116.09197664999999,
              "alt": 2086.2545,
              "speed2d": 9.467,
              "speed3d": 9.4775,
              "msFromStart": 246393.8403157895
            },
            {
              "lat": 43.759564375000004,
              "lng": -116.091999375,
              "alt": 2086.00025,
              "speed2d": 9.2645,
              "speed3d": 9.375,
              "msFromStart": 246613.79599999997
            },
            {
              "lat": 43.75952488,
              "lng": -116.09206816,
              "alt": 2085.6318,
              "speed2d": 9.1168,
              "speed3d": 9.136000000000001,
              "msFromStart": 247279.28906666662
            },
            {
              "lat": 43.7595072,
              "lng": -116.09209515,
              "alt": 2085.6275,
              "speed2d": 9.1095,
              "speed3d": 9.175,
              "msFromStart": 247549.0413333333
            },
            {
              "lat": 43.75945735,
              "lng": -116.09215188750001,
              "alt": 2085.710625,
              "speed2d": 9.17,
              "speed3d": 9.18,
              "msFromStart": 248210.12611111096
            },
            {
              "lat": 43.75943642899007,
              "lng": -116.09217385576835,
              "alt": 2085.6422414431954,
              "speed2d": 9.106207071936256,
              "speed3d": 9.137110156905782,
              "msFromStart": 248500
            },
            {
              "lat": 43.75937583333334,
              "lng": -116.09222030000001,
              "alt": 2084.9776666666667,
              "speed2d": 8.659333333333334,
              "speed3d": 8.72,
              "msFromStart": 249338.965037037
            },
            {
              "lat": 43.75934818571429,
              "lng": -116.09223728571429,
              "alt": 2084.2549999999997,
              "speed2d": 8.361714285714283,
              "speed3d": 8.44,
              "msFromStart": 249754.60457142862
            },
            {
              "lat": 43.7593140625,
              "lng": -116.09224956250002,
              "alt": 2083.28375,
              "speed2d": 7.92575,
              "speed3d": 7.97375,
              "msFromStart": 250272.13673684213
            },
            {
              "lat": 43.75928646,
              "lng": -116.09226585999998,
              "alt": 2082.9682000000003,
              "speed2d": 7.951599999999999,
              "speed3d": 8.014000000000001,
              "msFromStart": 250656.46989473692
            },
            {
              "lat": 43.759260117868976,
              "lng": -116.0922813317403,
              "alt": 2082.819125739482,
              "speed2d": 7.9738490634957335,
              "speed3d": 8.001169221782105,
              "msFromStart": 251000
            },
            {
              "lat": 43.75923234703805,
              "lng": -116.09230241490174,
              "alt": 2082.1669835057373,
              "speed2d": 7.662891323998777,
              "speed3d": 7.706458362963604,
              "msFromStart": 251500
            },
            {
              "lat": 43.759204576207125,
              "lng": -116.09232349806317,
              "alt": 2081.5148412719927,
              "speed2d": 7.35193358450182,
              "speed3d": 7.411747504145103,
              "msFromStart": 252000
            },
            {
              "lat": 43.75916653333333,
              "lng": -116.09235555,
              "alt": 2080.6191666666664,
              "speed2d": 6.829833333333333,
              "speed3d": 6.933333333333334,
              "msFromStart": 252730.25777777785
            },
            {
              "lat": 43.759144174999996,
              "lng": -116.092383325,
              "alt": 2079.7155000000002,
              "speed2d": 6.312,
              "speed3d": 6.4275,
              "msFromStart": 253297.78711111125
            },
            {
              "lat": 43.759132175,
              "lng": -116.092397775,
              "alt": 2079.19325,
              "speed2d": 6.167000000000001,
              "speed3d": 6.2775,
              "msFromStart": 253600.43999999997
            },
            {
              "lat": 43.75910974999999,
              "lng": -116.09242932500001,
              "alt": 2077.81925,
              "speed2d": 6.406,
              "speed3d": 6.4750000000000005,
              "msFromStart": 254164.90977777774
            },
            {
              "lat": 43.759092249999995,
              "lng": -116.092454,
              "alt": 2076.8975,
              "speed2d": 6.7219999999999995,
              "speed3d": 6.725,
              "msFromStart": 254564.0195555555
            },
            {
              "lat": 43.75905555,
              "lng": -116.09250320000001,
              "alt": 2075.7650000000003,
              "speed2d": 7.694,
              "speed3d": 7.74,
              "msFromStart": 255251.93066666648
            },
            {
              "lat": 43.759027083333336,
              "lng": -116.09253740000001,
              "alt": 2075.5106666666666,
              "speed2d": 8.0215,
              "speed3d": 8.061666666666666,
              "msFromStart": 255728.85487719302
            },
            {
              "lat": 43.759003533333335,
              "lng": -116.09256433333334,
              "alt": 2075.1903333333335,
              "speed2d": 8.229333333333333,
              "speed3d": 8.306666666666667,
              "msFromStart": 256104.88673684216
            },
            {
              "lat": 43.75896343333333,
              "lng": -116.09261153333333,
              "alt": 2074.2196666666664,
              "speed2d": 8.168666666666667,
              "speed3d": 8.236666666666666,
              "msFromStart": 256801.6237037038
            },
            {
              "lat": 43.758929375,
              "lng": -116.09264934999999,
              "alt": 2073.3565,
              "speed2d": 8.830499999999999,
              "speed3d": 8.835,
              "msFromStart": 257328.63866666672
            },
            {
              "lat": 43.75890545714286,
              "lng": -116.09267694285714,
              "alt": 2072.4744285714287,
              "speed2d": 8.942571428571428,
              "speed3d": 9.035714285714285,
              "msFromStart": 257731.44228571432
            },
            {
              "lat": 43.75886785,
              "lng": -116.09271973333334,
              "alt": 2071.2176666666664,
              "speed2d": 9.244333333333334,
              "speed3d": 9.318333333333335,
              "msFromStart": 258314.4533333334
            },
            {
              "lat": 43.75884395999999,
              "lng": -116.09275021999999,
              "alt": 2070.5984000000003,
              "speed2d": 9.1584,
              "speed3d": 9.254000000000001,
              "msFromStart": 258701.66826666676
            },
            {
              "lat": 43.7588101,
              "lng": -116.09279686666666,
              "alt": 2069.583,
              "speed2d": 8.939333333333334,
              "speed3d": 9.02,
              "msFromStart": 259296.3306666668
            },
            {
              "lat": 43.758789820000004,
              "lng": -116.0928321,
              "alt": 2069.2796,
              "speed2d": 9.2652,
              "speed3d": 9.324,
              "msFromStart": 259669.8867555555
            },
            {
              "lat": 43.75876568571429,
              "lng": -116.09287839999999,
              "alt": 2069.0209999999997,
              "speed2d": 9.074428571428571,
              "speed3d": 9.222857142857142,
              "msFromStart": 260182.9511979949
            },
            {
              "lat": 43.758743966666664,
              "lng": -116.09292651111113,
              "alt": 2068.364777777778,
              "speed2d": 8.923,
              "speed3d": 9.027777777777779,
              "msFromStart": 260725.25136842113
            },
            {
              "lat": 43.758730111111106,
              "lng": -116.09296385555555,
              "alt": 2067.633,
              "speed2d": 8.369444444444445,
              "speed3d": 8.520000000000001,
              "msFromStart": 261220.789120208
            },
            {
              "lat": 43.7587152,
              "lng": -116.09300095,
              "alt": 2067.3285,
              "speed2d": 8.345500000000001,
              "speed3d": 8.34,
              "msFromStart": 261660.76177777778
            },
            {
              "lat": 43.7586905,
              "lng": -116.093058125,
              "alt": 2067.18125,
              "speed2d": 8.237,
              "speed3d": 8.28,
              "msFromStart": 262306.494
            },
            {
              "lat": 43.758669488888884,
              "lng": -116.09309821111113,
              "alt": 2067.0696666666668,
              "speed2d": 8.314222222222222,
              "speed3d": 8.375555555555556,
              "msFromStart": 262759.9973333333
            },
            {
              "lat": 43.758645675000004,
              "lng": -116.0931356,
              "alt": 2066.852125,
              "speed2d": 8.000625000000001,
              "speed3d": 8.0925,
              "msFromStart": 263268.5323333333
            },
            {
              "lat": 43.75862805,
              "lng": -116.09316059999999,
              "alt": 2066.7103333333334,
              "speed2d": 7.4555,
              "speed3d": 7.568333333333333,
              "msFromStart": 263676.6980740741
            },
            {
              "lat": 43.75861329196212,
              "lng": -116.09317970053682,
              "alt": 2066.7808564774455,
              "speed2d": 7.015677637314804,
              "speed3d": 7.099215990183803,
              "msFromStart": 264000
            },
            {
              "lat": 43.758588599999996,
              "lng": -116.09321742499999,
              "alt": 2067.483,
              "speed2d": 6.004,
              "speed3d": 6.085,
              "msFromStart": 264686.0693333335
            }
          ]
        },
        {
          "videoId": "GH010023",
          "pathId": "trail-cabin-traverse",
          "startSec": 9,
          "endSec": 141,
          "id": "video-segment-18",
          "gps": [
            {
              "lat": 43.772095760000006,
              "lng": -116.09111971000002,
              "alt": 2274.6545,
              "speed2d": 0.47690000000000005,
              "speed3d": 0.9279999999999998,
              "msFromStart": 9252.208027777779
            },
            {
              "lat": 43.77209213333333,
              "lng": -116.09111717777778,
              "alt": 2274.5188888888893,
              "speed2d": 0.6781111111111112,
              "speed3d": 1.021111111111111,
              "msFromStart": 9773.204333333342
            },
            {
              "lat": 43.77208948888889,
              "lng": -116.09110233333332,
              "alt": 2274.502444444445,
              "speed2d": 1.4334444444444447,
              "speed3d": 1.528888888888889,
              "msFromStart": 10267.87238271605
            },
            {
              "lat": 43.77208589999999,
              "lng": -116.09110062222221,
              "alt": 2274.391,
              "speed2d": 0.9608888888888888,
              "speed3d": 1.2533333333333332,
              "msFromStart": 10764.65766666667
            },
            {
              "lat": 43.77208384444444,
              "lng": -116.09110056666665,
              "alt": 2274.280111111111,
              "speed2d": 0.5252222222222223,
              "speed3d": 0.8166666666666667,
              "msFromStart": 11260.910876543208
            },
            {
              "lat": 43.77207996666667,
              "lng": -116.09109411111109,
              "alt": 2274.335888888889,
              "speed2d": 0.8614444444444443,
              "speed3d": 1.0033333333333334,
              "msFromStart": 11756.100333333328
            },
            {
              "lat": 43.77207738888889,
              "lng": -116.09108723333333,
              "alt": 2274.423,
              "speed2d": 0.8144444444444445,
              "speed3d": 0.981111111111111,
              "msFromStart": 12249.666165042234
            },
            {
              "lat": 43.77207362222223,
              "lng": -116.09108302222222,
              "alt": 2274.4085555555557,
              "speed2d": 0.9824444444444445,
              "speed3d": 1.04,
              "msFromStart": 12740.25468421052
            },
            {
              "lat": 43.772068744444454,
              "lng": -116.09107423333333,
              "alt": 2274.551888888889,
              "speed2d": 1.2538888888888888,
              "speed3d": 1.3122222222222222,
              "msFromStart": 13232.208305068223
            },
            {
              "lat": 43.77206344444444,
              "lng": -116.09106887777777,
              "alt": 2274.5293333333334,
              "speed2d": 1.3431111111111111,
              "speed3d": 1.402222222222222,
              "msFromStart": 13728.022611111111
            },
            {
              "lat": 43.77205951111112,
              "lng": -116.09106126666669,
              "alt": 2274.5246666666667,
              "speed2d": 1.2965555555555555,
              "speed3d": 1.3177777777777777,
              "msFromStart": 14231.372550108932
            },
            {
              "lat": 43.77205282222223,
              "lng": -116.09105226666667,
              "alt": 2274.699555555556,
              "speed2d": 1.7173333333333332,
              "speed3d": 1.7344444444444445,
              "msFromStart": 14754.624823529413
            },
            {
              "lat": 43.77204648888888,
              "lng": -116.09104435555555,
              "alt": 2274.697888888889,
              "speed2d": 1.663,
              "speed3d": 1.6644444444444448,
              "msFromStart": 15269.723212781408
            },
            {
              "lat": 43.77203927777778,
              "lng": -116.09103544444446,
              "alt": 2274.822888888889,
              "speed2d": 1.8036666666666668,
              "speed3d": 1.82,
              "msFromStart": 15767.733333333334
            },
            {
              "lat": 43.77203307777778,
              "lng": -116.09102733333333,
              "alt": 2274.708777777778,
              "speed2d": 1.8325555555555555,
              "speed3d": 1.8344444444444445,
              "msFromStart": 16265.041154320987
            },
            {
              "lat": 43.772025111111105,
              "lng": -116.09101585555554,
              "alt": 2274.6472222222224,
              "speed2d": 2.1812222222222224,
              "speed3d": 2.1933333333333334,
              "msFromStart": 16761.655000000006
            },
            {
              "lat": 43.772020766666664,
              "lng": -116.09101343333333,
              "alt": 2273.7997777777778,
              "speed2d": 1.6294444444444445,
              "speed3d": 1.6977777777777776,
              "msFromStart": 17257.346264132553
            },
            {
              "lat": 43.77201601111111,
              "lng": -116.09100557777776,
              "alt": 2272.4965555555555,
              "speed2d": 2.0424444444444445,
              "speed3d": 2.062222222222222,
              "msFromStart": 17751.34894736841
            },
            {
              "lat": 43.77201221111111,
              "lng": -116.0910005111111,
              "alt": 2271.1936666666666,
              "speed2d": 1.8623333333333334,
              "speed3d": 2.001111111111111,
              "msFromStart": 18245.54414035087
            },
            {
              "lat": 43.77200761111111,
              "lng": -116.0909881888889,
              "alt": 2269.9417777777776,
              "speed2d": 2.3506666666666667,
              "speed3d": 2.4099999999999997,
              "msFromStart": 18740.35
            },
            {
              "lat": 43.77200043333333,
              "lng": -116.09097309999999,
              "alt": 2269.786777777778,
              "speed2d": 2.5425555555555555,
              "speed3d": 2.6777777777777776,
              "msFromStart": 19235.31814814815
            },
            {
              "lat": 43.771994011111104,
              "lng": -116.09095432222222,
              "alt": 2269.934888888889,
              "speed2d": 2.761222222222222,
              "speed3d": 2.806666666666666,
              "msFromStart": 19730.639555555572
            },
            {
              "lat": 43.771986177777784,
              "lng": -116.09093316666667,
              "alt": 2270.510111111111,
              "speed2d": 2.9943333333333326,
              "speed3d": 3.0888888888888886,
              "msFromStart": 20225.797037037046
            },
            {
              "lat": 43.77198067,
              "lng": -116.09091571,
              "alt": 2271.147,
              "speed2d": 2.6582999999999997,
              "speed3d": 2.7090000000000005,
              "msFromStart": 20747.938888888904
            },
            {
              "lat": 43.77197483333333,
              "lng": -116.09089393333333,
              "alt": 2271.6167777777778,
              "speed2d": 2.9275555555555552,
              "speed3d": 2.8999999999999995,
              "msFromStart": 21270.5427654321
            },
            {
              "lat": 43.7719717,
              "lng": -116.09087517777779,
              "alt": 2272.1535555555556,
              "speed2d": 2.6929999999999996,
              "speed3d": 2.7788888888888885,
              "msFromStart": 21766.573333333323
            },
            {
              "lat": 43.77196681111111,
              "lng": -116.09085912222223,
              "alt": 2272.3566666666666,
              "speed2d": 2.6556666666666664,
              "speed3d": 2.646666666666667,
              "msFromStart": 22262.379576348285
            },
            {
              "lat": 43.771963955555556,
              "lng": -116.09084481111111,
              "alt": 2272.038555555556,
              "speed2d": 2.4305555555555554,
              "speed3d": 2.466666666666667,
              "msFromStart": 22757.73684210528
            },
            {
              "lat": 43.77195712222222,
              "lng": -116.090832,
              "alt": 2272.2763333333332,
              "speed2d": 2.3939999999999997,
              "speed3d": 2.443333333333333,
              "msFromStart": 23251.920717348934
            },
            {
              "lat": 43.77195212222222,
              "lng": -116.0908221,
              "alt": 2272.4544444444446,
              "speed2d": 1.9447777777777775,
              "speed3d": 1.9777777777777776,
              "msFromStart": 23742.943111111108
            },
            {
              "lat": 43.771945333333335,
              "lng": -116.09081227777776,
              "alt": 2272.7897777777775,
              "speed2d": 2.0652222222222223,
              "speed3d": 2.033333333333333,
              "msFromStart": 24234.9007037037
            },
            {
              "lat": 43.771938166666665,
              "lng": -116.09079902222223,
              "alt": 2272.9754444444443,
              "speed2d": 2.2145555555555556,
              "speed3d": 2.2022222222222223,
              "msFromStart": 24729.848333333324
            },
            {
              "lat": 43.77193185555556,
              "lng": -116.09078007777777,
              "alt": 2273.1077777777778,
              "speed2d": 2.8184444444444443,
              "speed3d": 2.738888888888889,
              "msFromStart": 25233.119130718955
            },
            {
              "lat": 43.771924522222214,
              "lng": -116.09076122222223,
              "alt": 2273.3572222222224,
              "speed2d": 3.0694444444444438,
              "speed3d": 3.0999999999999996,
              "msFromStart": 25758.554235294134
            },
            {
              "lat": 43.7719185111111,
              "lng": -116.09074380000001,
              "alt": 2273.5565555555554,
              "speed2d": 2.784222222222222,
              "speed3d": 2.793333333333334,
              "msFromStart": 26274.818127814087
            },
            {
              "lat": 43.77191398888889,
              "lng": -116.09073273333334,
              "alt": 2273.695666666667,
              "speed2d": 2.218444444444444,
              "speed3d": 2.3299999999999996,
              "msFromStart": 26771.883333333324
            },
            {
              "lat": 43.77191021111111,
              "lng": -116.09072434444445,
              "alt": 2273.397444444445,
              "speed2d": 1.8635555555555556,
              "speed3d": 1.8433333333333335,
              "msFromStart": 27268.507777777773
            },
            {
              "lat": 43.77190398888889,
              "lng": -116.09071234444446,
              "alt": 2273.5323333333336,
              "speed2d": 2.253111111111111,
              "speed3d": 2.2177777777777776,
              "msFromStart": 27764.999333333315
            },
            {
              "lat": 43.77189807777777,
              "lng": -116.09069533333334,
              "alt": 2273.378888888889,
              "speed2d": 2.5774444444444446,
              "speed3d": 2.522222222222222,
              "msFromStart": 28260.579979857037
            },
            {
              "lat": 43.771892377777775,
              "lng": -116.09067603333334,
              "alt": 2272.769333333333,
              "speed2d": 3.131555555555556,
              "speed3d": 3.074444444444444,
              "msFromStart": 28754.473684210505
            },
            {
              "lat": 43.77188518888889,
              "lng": -116.09066150000001,
              "alt": 2272.7889999999998,
              "speed2d": 2.8213333333333335,
              "speed3d": 2.93,
              "msFromStart": 29248.654027290442
            },
            {
              "lat": 43.77187743333334,
              "lng": -116.09064878888887,
              "alt": 2272.760333333333,
              "speed2d": 2.6793333333333336,
              "speed3d": 2.643333333333333,
              "msFromStart": 29743.700444444454
            },
            {
              "lat": 43.771867611111105,
              "lng": -116.09063590000001,
              "alt": 2273.0544444444445,
              "speed2d": 2.8483333333333336,
              "speed3d": 2.861111111111111,
              "msFromStart": 30238.80100000001
            },
            {
              "lat": 43.77185826666667,
              "lng": -116.09061947777778,
              "alt": 2272.9832222222226,
              "speed2d": 3.0878888888888887,
              "speed3d": 3.0344444444444445,
              "msFromStart": 30733.924777777796
            },
            {
              "lat": 43.77184867777778,
              "lng": -116.0906028888889,
              "alt": 2272.8436666666666,
              "speed2d": 3.201222222222222,
              "speed3d": 3.221111111111111,
              "msFromStart": 31228.870629629637
            },
            {
              "lat": 43.77183839,
              "lng": -116.09058816000001,
              "alt": 2272.7283,
              "speed2d": 3.1063999999999994,
              "speed3d": 3.097,
              "msFromStart": 31750.79877777777
            },
            {
              "lat": 43.77182772222223,
              "lng": -116.09057351111112,
              "alt": 2272.433666666667,
              "speed2d": 3.2563333333333335,
              "speed3d": 3.2644444444444445,
              "msFromStart": 32273.004493827157
            },
            {
              "lat": 43.771818188888886,
              "lng": -116.0905619888889,
              "alt": 2272.1467777777775,
              "speed2d": 3.0749999999999997,
              "speed3d": 3.0888888888888886,
              "msFromStart": 32768.30466666665
            },
            {
              "lat": 43.77180740000001,
              "lng": -116.09054885555557,
              "alt": 2271.9718888888888,
              "speed2d": 3.2545555555555556,
              "speed3d": 3.197777777777778,
              "msFromStart": 33263.523879792054
            },
            {
              "lat": 43.77179521111111,
              "lng": -116.09053205555557,
              "alt": 2271.985333333333,
              "speed2d": 3.5933333333333333,
              "speed3d": 3.6022222222222227,
              "msFromStart": 33758.5726315789
            },
            {
              "lat": 43.77178285555556,
              "lng": -116.09052144444445,
              "alt": 2271.740888888889,
              "speed2d": 3.4932222222222227,
              "speed3d": 3.4722222222222223,
              "msFromStart": 34251.87757504871
            },
            {
              "lat": 43.77176848888889,
              "lng": -116.09050513333332,
              "alt": 2271.7276666666667,
              "speed2d": 3.8689999999999998,
              "speed3d": 3.855555555555556,
              "msFromStart": 34740.45822222221
            },
            {
              "lat": 43.77175356666667,
              "lng": -116.0904933111111,
              "alt": 2271.4281111111113,
              "speed2d": 3.9870000000000005,
              "speed3d": 3.9977777777777783,
              "msFromStart": 35231.80385185185
            },
            {
              "lat": 43.77173807777777,
              "lng": -116.09047881111113,
              "alt": 2271.3264444444444,
              "speed2d": 4.020222222222222,
              "speed3d": 4.002222222222223,
              "msFromStart": 35731.329333333364
            },
            {
              "lat": 43.77172315555556,
              "lng": -116.09046604444444,
              "alt": 2271.0224444444443,
              "speed2d": 4.078888888888889,
              "speed3d": 4.087777777777778,
              "msFromStart": 36238.25166013072
            },
            {
              "lat": 43.771705744444446,
              "lng": -116.09045285555555,
              "alt": 2270.8313333333335,
              "speed2d": 4.335777777777778,
              "speed3d": 4.2844444444444445,
              "msFromStart": 36764.08258823527
            },
            {
              "lat": 43.77168557499999,
              "lng": -116.090438575,
              "alt": 2270.4531250000005,
              "speed2d": 4.69575,
              "speed3d": 4.691250000000001,
              "msFromStart": 37252.76470743034
            },
            {
              "lat": 43.77166670999999,
              "lng": -116.09042504000001,
              "alt": 2270.0478000000003,
              "speed2d": 4.7619,
              "speed3d": 4.747999999999999,
              "msFromStart": 37749.581894736846
            },
            {
              "lat": 43.77164383333333,
              "lng": -116.09040865555554,
              "alt": 2269.3372222222224,
              "speed2d": 5.25011111111111,
              "speed3d": 5.224444444444444,
              "msFromStart": 38273.40482146542
            },
            {
              "lat": 43.77162098888889,
              "lng": -116.09039164444444,
              "alt": 2268.968888888889,
              "speed2d": 5.482555555555555,
              "speed3d": 5.47111111111111,
              "msFromStart": 38769.24564705881
            },
            {
              "lat": 43.77159761111111,
              "lng": -116.09037366666666,
              "alt": 2268.696111111111,
              "speed2d": 5.648666666666667,
              "speed3d": 5.66,
              "msFromStart": 39264.662677674576
            },
            {
              "lat": 43.771572899999995,
              "lng": -116.0903539888889,
              "alt": 2268.675111111111,
              "speed2d": 5.985999999999999,
              "speed3d": 5.947777777777778,
              "msFromStart": 39759.33221052629
            },
            {
              "lat": 43.77154594444445,
              "lng": -116.09033237777778,
              "alt": 2268.786777777778,
              "speed2d": 6.322333333333333,
              "speed3d": 6.320000000000001,
              "msFromStart": 40253.92277192982
            },
            {
              "lat": 43.77151872222222,
              "lng": -116.09031031111112,
              "alt": 2268.9771111111113,
              "speed2d": 6.468222222222221,
              "speed3d": 6.466666666666666,
              "msFromStart": 40748.33800000001
            },
            {
              "lat": 43.771490988888885,
              "lng": -116.09028913333333,
              "alt": 2269.226111111111,
              "speed2d": 6.645333333333333,
              "speed3d": 6.626666666666667,
              "msFromStart": 41242.64392592592
            },
            {
              "lat": 43.77146223333333,
              "lng": -116.09026690000002,
              "alt": 2269.8504444444443,
              "speed2d": 6.668666666666667,
              "speed3d": 6.68,
              "msFromStart": 41736.67822222219
            },
            {
              "lat": 43.77143152222222,
              "lng": -116.09023976666667,
              "alt": 2269.886333333333,
              "speed2d": 7.390111111111111,
              "speed3d": 7.25,
              "msFromStart": 42231.01762962961
            },
            {
              "lat": 43.77139616666667,
              "lng": -116.0902163,
              "alt": 2269.6457777777778,
              "speed2d": 8.278666666666666,
              "speed3d": 8.258888888888889,
              "msFromStart": 42726.22399999998
            },
            {
              "lat": 43.77136221,
              "lng": -116.09018911,
              "alt": 2269.3366,
              "speed2d": 8.072899999999999,
              "speed3d": 8.09,
              "msFromStart": 43249.176666666644
            },
            {
              "lat": 43.771328733333334,
              "lng": -116.09015731111111,
              "alt": 2269.0373333333337,
              "speed2d": 8.254888888888889,
              "speed3d": 8.23,
              "msFromStart": 43772.73377777774
            },
            {
              "lat": 43.77129706666666,
              "lng": -116.0901206,
              "alt": 2268.715111111111,
              "speed2d": 8.713666666666665,
              "speed3d": 8.69111111111111,
              "msFromStart": 44268.47674074074
            },
            {
              "lat": 43.771265311111115,
              "lng": -116.09008906666668,
              "alt": 2268.471777777778,
              "speed2d": 8.667333333333334,
              "speed3d": 8.693333333333333,
              "msFromStart": 44763.38088888891
            },
            {
              "lat": 43.77123351111111,
              "lng": -116.09005513333334,
              "alt": 2267.7926666666667,
              "speed2d": 8.764333333333335,
              "speed3d": 8.741111111111111,
              "msFromStart": 45256.53755555556
            },
            {
              "lat": 43.77120095555556,
              "lng": -116.09001987777779,
              "alt": 2267.2064444444445,
              "speed2d": 9.039444444444445,
              "speed3d": 9.040000000000003,
              "msFromStart": 45745.07088888887
            },
            {
              "lat": 43.771167911111114,
              "lng": -116.0899866,
              "alt": 2266.3114444444445,
              "speed2d": 9.083555555555556,
              "speed3d": 9.11111111111111,
              "msFromStart": 46235.970974658856
            },
            {
              "lat": 43.771134555555555,
              "lng": -116.0899530111111,
              "alt": 2265.5782222222224,
              "speed2d": 9.094888888888889,
              "speed3d": 9.115555555555554,
              "msFromStart": 46733.95557894735
            },
            {
              "lat": 43.77110126666666,
              "lng": -116.08991572222223,
              "alt": 2265.389666666667,
              "speed2d": 9.248444444444445,
              "speed3d": 9.274444444444443,
              "msFromStart": 47236.48071001032
            },
            {
              "lat": 43.7710691111111,
              "lng": -116.08987623333331,
              "alt": 2264.655,
              "speed2d": 9.353777777777777,
              "speed3d": 9.347777777777779,
              "msFromStart": 47755.67647058827
            },
            {
              "lat": 43.771035033333334,
              "lng": -116.08982628888887,
              "alt": 2263.8556666666664,
              "speed2d": 9.745333333333335,
              "speed3d": 9.751111111111111,
              "msFromStart": 48270.76641394336
            },
            {
              "lat": 43.771001922222226,
              "lng": -116.08978384444444,
              "alt": 2263.1464444444446,
              "speed2d": 9.91388888888889,
              "speed3d": 9.967777777777778,
              "msFromStart": 48770.18711111107
            },
            {
              "lat": 43.770972725,
              "lng": -116.08974371250001,
              "alt": 2262.53225,
              "speed2d": 9.764,
              "speed3d": 9.832500000000001,
              "msFromStart": 49246.876372549
            },
            {
              "lat": 43.770946744444444,
              "lng": -116.08970106666668,
              "alt": 2261.2716666666665,
              "speed2d": 9.645000000000001,
              "speed3d": 9.7,
              "msFromStart": 49742.875764705896
            },
            {
              "lat": 43.77091932222222,
              "lng": -116.08964984444444,
              "alt": 2259.554111111111,
              "speed2d": 9.533888888888889,
              "speed3d": 9.66,
              "msFromStart": 50260.987089324626
            },
            {
              "lat": 43.770890433333335,
              "lng": -116.08960922222221,
              "alt": 2258.818333333333,
              "speed2d": 9.156,
              "speed3d": 9.292222222222222,
              "msFromStart": 50756.59355555555
            },
            {
              "lat": 43.77085997777778,
              "lng": -116.0895694,
              "alt": 2258.250777777778,
              "speed2d": 9.251111111111111,
              "speed3d": 9.293333333333333,
              "msFromStart": 51251.1177037037
            },
            {
              "lat": 43.77083043333333,
              "lng": -116.08952681111111,
              "alt": 2257.507333333333,
              "speed2d": 9.443888888888887,
              "speed3d": 9.54111111111111,
              "msFromStart": 51745.91911111109
            },
            {
              "lat": 43.77079990000001,
              "lng": -116.08948876666665,
              "alt": 2257.0076666666664,
              "speed2d": 9.255888888888888,
              "speed3d": 9.354444444444443,
              "msFromStart": 52240.69488499024
            },
            {
              "lat": 43.77077062222223,
              "lng": -116.08945255555557,
              "alt": 2256.353222222222,
              "speed2d": 8.959777777777777,
              "speed3d": 9.074444444444445,
              "msFromStart": 52735.361473684214
            },
            {
              "lat": 43.77074259999999,
              "lng": -116.08941343333333,
              "alt": 2255.8773333333334,
              "speed2d": 8.876333333333333,
              "speed3d": 8.962222222222222,
              "msFromStart": 53230.14919688109
            },
            {
              "lat": 43.770714433333325,
              "lng": -116.08937141111112,
              "alt": 2255.5896666666663,
              "speed2d": 9.046222222222223,
              "speed3d": 9.067777777777778,
              "msFromStart": 53725.44444444442
            },
            {
              "lat": 43.77068559999999,
              "lng": -116.08932686999998,
              "alt": 2255.1241,
              "speed2d": 9.0926,
              "speed3d": 9.197,
              "msFromStart": 54248.32226666665
            },
            {
              "lat": 43.77065742222222,
              "lng": -116.08928797777779,
              "alt": 2254.6146666666664,
              "speed2d": 8.70588888888889,
              "speed3d": 8.774444444444445,
              "msFromStart": 54771.24088888888
            },
            {
              "lat": 43.770631333333334,
              "lng": -116.08924963333334,
              "alt": 2254.1452222222224,
              "speed2d": 8.672222222222222,
              "speed3d": 8.764444444444443,
              "msFromStart": 55266.65592592593
            },
            {
              "lat": 43.77060862222223,
              "lng": -116.0892126,
              "alt": 2253.083666666667,
              "speed2d": 8.402222222222221,
              "speed3d": 8.504444444444445,
              "msFromStart": 55762.11177777776
            },
            {
              "lat": 43.77057914444444,
              "lng": -116.08917196666665,
              "alt": 2252.244111111111,
              "speed2d": 8.912444444444445,
              "speed3d": 8.873333333333333,
              "msFromStart": 56255.99555555554
            },
            {
              "lat": 43.77054652222223,
              "lng": -116.08912711111111,
              "alt": 2252.008222222222,
              "speed2d": 9.405333333333333,
              "speed3d": 9.46,
              "msFromStart": 56745.606444444435
            },
            {
              "lat": 43.77051755555556,
              "lng": -116.08907756666667,
              "alt": 2251.955,
              "speed2d": 9.767777777777777,
              "speed3d": 9.785555555555554,
              "msFromStart": 57236.79866276803
            },
            {
              "lat": 43.77049026666666,
              "lng": -116.0890253888889,
              "alt": 2251.722888888889,
              "speed2d": 10.051222222222224,
              "speed3d": 10.06777777777778,
              "msFromStart": 57732.89347368424
            },
            {
              "lat": 43.77046552222222,
              "lng": -116.08897298888888,
              "alt": 2251.3747777777776,
              "speed2d": 10.075333333333333,
              "speed3d": 10.13111111111111,
              "msFromStart": 58235.175005159974
            },
            {
              "lat": 43.77044234444445,
              "lng": -116.08892759999999,
              "alt": 2251.0096666666673,
              "speed2d": 9.527333333333333,
              "speed3d": 9.657777777777778,
              "msFromStart": 58761.17129411767
            },
            {
              "lat": 43.7704223125,
              "lng": -116.0888808875,
              "alt": 2250.1046250000004,
              "speed2d": 9.1015,
              "speed3d": 9.20375,
              "msFromStart": 59253.37409313727
            },
            {
              "lat": 43.77039773,
              "lng": -116.08883954000001,
              "alt": 2249.9745000000003,
              "speed2d": 8.829699999999999,
              "speed3d": 8.91,
              "msFromStart": 59750.41033333338
            },
            {
              "lat": 43.77037544444444,
              "lng": -116.08879561111111,
              "alt": 2249.3746666666666,
              "speed2d": 8.633111111111111,
              "speed3d": 8.666666666666666,
              "msFromStart": 60273.22355555557
            },
            {
              "lat": 43.770351677777775,
              "lng": -116.0887522111111,
              "alt": 2249.040555555556,
              "speed2d": 8.806111111111113,
              "speed3d": 8.862222222222222,
              "msFromStart": 60767.41844444442
            },
            {
              "lat": 43.77032634444445,
              "lng": -116.08870820000001,
              "alt": 2248.8533333333335,
              "speed2d": 8.877,
              "speed3d": 8.891111111111112,
              "msFromStart": 61261.98318518518
            },
            {
              "lat": 43.770300266666666,
              "lng": -116.08866274444445,
              "alt": 2248.390666666666,
              "speed2d": 9.237,
              "speed3d": 9.245555555555555,
              "msFromStart": 61757.70622222224
            },
            {
              "lat": 43.77027132222222,
              "lng": -116.088616,
              "alt": 2247.851555555556,
              "speed2d": 9.605222222222222,
              "speed3d": 9.62888888888889,
              "msFromStart": 62253.077699805064
            },
            {
              "lat": 43.770240855555556,
              "lng": -116.08856747777776,
              "alt": 2247.628888888889,
              "speed2d": 9.807333333333334,
              "speed3d": 9.835555555555556,
              "msFromStart": 62747.329473684185
            },
            {
              "lat": 43.77020986666667,
              "lng": -116.08852329999999,
              "alt": 2247.0838888888893,
              "speed2d": 9.766333333333332,
              "speed3d": 9.82777777777778,
              "msFromStart": 63241.80586354774
            },
            {
              "lat": 43.77017755555555,
              "lng": -116.0884805111111,
              "alt": 2246.6974444444445,
              "speed2d": 9.725999999999999,
              "speed3d": 9.797777777777778,
              "msFromStart": 63737.41644444444
            },
            {
              "lat": 43.770144788888885,
              "lng": -116.08843722222223,
              "alt": 2246.422,
              "speed2d": 9.746222222222222,
              "speed3d": 9.757777777777779,
              "msFromStart": 64233.12303703703
            },
            {
              "lat": 43.770108522222216,
              "lng": -116.08839636666667,
              "alt": 2246.005222222222,
              "speed2d": 9.969999999999999,
              "speed3d": 10.011111111111111,
              "msFromStart": 64728.759999999995
            },
            {
              "lat": 43.77007018,
              "lng": -116.08835569000001,
              "alt": 2245.1915,
              "speed2d": 10.051,
              "speed3d": 10.096,
              "msFromStart": 65251.72713333333
            },
            {
              "lat": 43.770030955555555,
              "lng": -116.08831596666666,
              "alt": 2244.4073333333336,
              "speed2d": 10.077111111111112,
              "speed3d": 10.125555555555557,
              "msFromStart": 65774.05355555558
            },
            {
              "lat": 43.76999181111111,
              "lng": -116.08827647777778,
              "alt": 2243.3953333333334,
              "speed2d": 10.494777777777779,
              "speed3d": 10.534444444444444,
              "msFromStart": 66268.93096296297
            },
            {
              "lat": 43.76995277777778,
              "lng": -116.08823733333332,
              "alt": 2242.7284444444444,
              "speed2d": 10.369,
              "speed3d": 10.448888888888888,
              "msFromStart": 66764.0673333333
            },
            {
              "lat": 43.76991338888889,
              "lng": -116.08819731111112,
              "alt": 2242.0743333333335,
              "speed2d": 10.459444444444445,
              "speed3d": 10.513333333333334,
              "msFromStart": 67257.2599259259
            },
            {
              "lat": 43.76987554444444,
              "lng": -116.08815857777778,
              "alt": 2240.8773333333334,
              "speed2d": 10.456888888888889,
              "speed3d": 10.54888888888889,
              "msFromStart": 67745.13955555552
            },
            {
              "lat": 43.769835422222215,
              "lng": -116.0881186,
              "alt": 2240.021222222222,
              "speed2d": 10.715222222222224,
              "speed3d": 10.75,
              "msFromStart": 68235.65526705651
            },
            {
              "lat": 43.769793588888895,
              "lng": -116.08807862222223,
              "alt": 2239.3683333333333,
              "speed2d": 10.937444444444445,
              "speed3d": 11.002222222222223,
              "msFromStart": 68734.08505263155
            },
            {
              "lat": 43.76975325555556,
              "lng": -116.08803836666665,
              "alt": 2239.0719999999997,
              "speed2d": 10.743444444444444,
              "speed3d": 10.895555555555557,
              "msFromStart": 69238.6073230134
            },
            {
              "lat": 43.7697182625,
              "lng": -116.08800465000002,
              "alt": 2238.252875,
              "speed2d": 10.536499999999998,
              "speed3d": 10.59125,
              "msFromStart": 69736.41694117649
            },
            {
              "lat": 43.76967689999999,
              "lng": -116.0879676777778,
              "alt": 2237.1131111111113,
              "speed2d": 10.720444444444446,
              "speed3d": 10.8,
              "msFromStart": 70228.0331503268
            },
            {
              "lat": 43.76963614,
              "lng": -116.08792322,
              "alt": 2236.6986000000006,
              "speed2d": 10.7059,
              "speed3d": 10.805,
              "msFromStart": 70742.08799999993
            },
            {
              "lat": 43.76959761111111,
              "lng": -116.08788104444446,
              "alt": 2235.918555555556,
              "speed2d": 10.640666666666666,
              "speed3d": 10.744444444444444,
              "msFromStart": 71261.17076023389
            },
            {
              "lat": 43.769563812499996,
              "lng": -116.0878444375,
              "alt": 2234.89075,
              "speed2d": 10.493875,
              "speed3d": 10.5975,
              "msFromStart": 71747.53242105256
            },
            {
              "lat": 43.76952896666667,
              "lng": -116.08780256666667,
              "alt": 2234.5739999999996,
              "speed2d": 10.64788888888889,
              "speed3d": 10.713333333333335,
              "msFromStart": 72234.81942105258
            },
            {
              "lat": 43.769494333333334,
              "lng": -116.08775762222221,
              "alt": 2233.2345555555553,
              "speed2d": 10.239333333333333,
              "speed3d": 10.37888888888889,
              "msFromStart": 72749.0995
            },
            {
              "lat": 43.76945852222222,
              "lng": -116.08771011111112,
              "alt": 2232.3693333333335,
              "speed2d": 10.585,
              "speed3d": 10.636666666666667,
              "msFromStart": 73257.72128947367
            },
            {
              "lat": 43.76942182222223,
              "lng": -116.08766432222222,
              "alt": 2231.905333333333,
              "speed2d": 10.816222222222223,
              "speed3d": 10.89111111111111,
              "msFromStart": 73751.73557894737
            },
            {
              "lat": 43.76938657777778,
              "lng": -116.08762631111111,
              "alt": 2230.938111111111,
              "speed2d": 10.581333333333333,
              "speed3d": 10.713333333333333,
              "msFromStart": 74245.30226120859
            },
            {
              "lat": 43.76935192222222,
              "lng": -116.08758874444445,
              "alt": 2230.0133333333333,
              "speed2d": 10.34922222222222,
              "speed3d": 10.426666666666666,
              "msFromStart": 74740.15377777783
            },
            {
              "lat": 43.76931754444445,
              "lng": -116.08754838888888,
              "alt": 2228.9196666666667,
              "speed2d": 10.522222222222222,
              "speed3d": 10.61111111111111,
              "msFromStart": 75235.03525925931
            },
            {
              "lat": 43.769283300000005,
              "lng": -116.08750757777777,
              "alt": 2227.857222222222,
              "speed2d": 10.625444444444444,
              "speed3d": 10.705555555555556,
              "msFromStart": 75729.5226666667
            },
            {
              "lat": 43.76924697,
              "lng": -116.08746385999999,
              "alt": 2226.9141,
              "speed2d": 10.4747,
              "speed3d": 10.591000000000001,
              "msFromStart": 76251.53986666666
            },
            {
              "lat": 43.76921085555556,
              "lng": -116.08741917777779,
              "alt": 2226.209222222222,
              "speed2d": 10.490333333333334,
              "speed3d": 10.584444444444445,
              "msFromStart": 76773.88044444437
            },
            {
              "lat": 43.76917714444445,
              "lng": -116.08737737777778,
              "alt": 2225.4860000000003,
              "speed2d": 10.367444444444445,
              "speed3d": 10.455555555555556,
              "msFromStart": 77268.8937153996
            },
            {
              "lat": 43.76914457777777,
              "lng": -116.08733736666666,
              "alt": 2224.637222222222,
              "speed2d": 10.070222222222224,
              "speed3d": 10.194444444444445,
              "msFromStart": 77764.27957894742
            },
            {
              "lat": 43.76911258888889,
              "lng": -116.08729589999999,
              "alt": 2223.931777777778,
              "speed2d": 10.062444444444443,
              "speed3d": 10.129999999999999,
              "msFromStart": 78258.67292787528
            },
            {
              "lat": 43.7690799888889,
              "lng": -116.0872588,
              "alt": 2223.0934444444447,
              "speed2d": 9.877555555555555,
              "speed3d": 9.986666666666668,
              "msFromStart": 78749.01777777784
            },
            {
              "lat": 43.76904754444444,
              "lng": -116.0872205,
              "alt": 2222.425222222222,
              "speed2d": 9.786333333333333,
              "speed3d": 9.846666666666666,
              "msFromStart": 79240.05259259263
            },
            {
              "lat": 43.769013855555556,
              "lng": -116.08718404444444,
              "alt": 2221.7915555555555,
              "speed2d": 9.805555555555557,
              "speed3d": 9.906666666666666,
              "msFromStart": 79735.46666666672
            },
            {
              "lat": 43.76897907777778,
              "lng": -116.08714838888888,
              "alt": 2221.083888888889,
              "speed2d": 9.849555555555554,
              "speed3d": 9.883333333333333,
              "msFromStart": 80237.29971241832
            },
            {
              "lat": 43.768941866666665,
              "lng": -116.08711594444445,
              "alt": 2220.2615555555553,
              "speed2d": 10.27111111111111,
              "speed3d": 10.30888888888889,
              "msFromStart": 80763.0569411764
            },
            {
              "lat": 43.768900724999995,
              "lng": -116.08708355000002,
              "alt": 2219.488625,
              "speed2d": 10.472999999999999,
              "speed3d": 10.505,
              "msFromStart": 81255.20043137253
            },
            {
              "lat": 43.76886042222222,
              "lng": -116.08705615555556,
              "alt": 2218.8035555555557,
              "speed2d": 10.759555555555554,
              "speed3d": 10.814444444444444,
              "msFromStart": 81725.12888888895
            },
            {
              "lat": 43.768814230000004,
              "lng": -116.08702845,
              "alt": 2218.0884,
              "speed2d": 10.884799999999998,
              "speed3d": 10.93,
              "msFromStart": 82248.73853333337
            },
            {
              "lat": 43.768765933333334,
              "lng": -116.08700495555556,
              "alt": 2217.3932222222224,
              "speed2d": 10.910555555555556,
              "speed3d": 10.983333333333334,
              "msFromStart": 82771.9764444444
            },
            {
              "lat": 43.76871851111111,
              "lng": -116.08698447777779,
              "alt": 2216.76,
              "speed2d": 11.117444444444445,
              "speed3d": 11.135555555555557,
              "msFromStart": 83267.06943469783
            },
            {
              "lat": 43.76866977777778,
              "lng": -116.08696731111111,
              "alt": 2216.2503333333334,
              "speed2d": 11.149,
              "speed3d": 11.215555555555556,
              "msFromStart": 83760.6016842105
            },
            {
              "lat": 43.76862148888889,
              "lng": -116.08695358888887,
              "alt": 2215.7833333333333,
              "speed2d": 11.091666666666665,
              "speed3d": 11.168888888888887,
              "msFromStart": 84254.39214035089
            },
            {
              "lat": 43.76857356666667,
              "lng": -116.08693986666668,
              "alt": 2215.3238888888886,
              "speed2d": 10.881,
              "speed3d": 10.954444444444444,
              "msFromStart": 84749.54400000007
            },
            {
              "lat": 43.76852732222222,
              "lng": -116.08692843333334,
              "alt": 2214.719,
              "speed2d": 10.626111111111111,
              "speed3d": 10.677777777777777,
              "msFromStart": 85244.89274074079
            },
            {
              "lat": 43.76847889999999,
              "lng": -116.08692176666668,
              "alt": 2214.035555555555,
              "speed2d": 10.755888888888888,
              "speed3d": 10.825555555555555,
              "msFromStart": 85740.4844444445
            },
            {
              "lat": 43.76843103333334,
              "lng": -116.08691536666669,
              "alt": 2213.2675555555556,
              "speed2d": 10.793333333333335,
              "speed3d": 10.80888888888889,
              "msFromStart": 86236.51674074077
            },
            {
              "lat": 43.76837878888889,
              "lng": -116.08690693333332,
              "alt": 2213.060444444445,
              "speed2d": 11.292333333333334,
              "speed3d": 11.32111111111111,
              "msFromStart": 86734.21422222225
            },
            {
              "lat": 43.76833236666666,
              "lng": -116.08690062222222,
              "alt": 2212.6195555555555,
              "speed2d": 10.522333333333334,
              "speed3d": 10.71888888888889,
              "msFromStart": 87231.55229629632
            },
            {
              "lat": 43.768290400000005,
              "lng": -116.08689581111113,
              "alt": 2211.8193333333334,
              "speed2d": 9.960333333333335,
              "speed3d": 9.941111111111113,
              "msFromStart": 87726.78666666665
            },
            {
              "lat": 43.76823677,
              "lng": -116.08689127000001,
              "alt": 2211.2726000000002,
              "speed2d": 10.858100000000002,
              "speed3d": 10.883,
              "msFromStart": 88249.13574736843
            },
            {
              "lat": 43.768188566666666,
              "lng": -116.08688587777779,
              "alt": 2210.944555555556,
              "speed2d": 10.250888888888888,
              "speed3d": 10.36222222222222,
              "msFromStart": 88770.91410526322
            },
            {
              "lat": 43.7681454,
              "lng": -116.0868808111111,
              "alt": 2210.6663333333336,
              "speed2d": 9.754111111111111,
              "speed3d": 9.882222222222222,
              "msFromStart": 89264.45447173492
            },
            {
              "lat": 43.768105955555555,
              "lng": -116.08687755555556,
              "alt": 2210.262111111111,
              "speed2d": 9.085666666666667,
              "speed3d": 9.178888888888888,
              "msFromStart": 89755.08177777777
            },
            {
              "lat": 43.76806574444444,
              "lng": -116.08687474444446,
              "alt": 2209.8103333333333,
              "speed2d": 8.981777777777777,
              "speed3d": 8.998888888888889,
              "msFromStart": 90246.93407407407
            },
            {
              "lat": 43.768022322222215,
              "lng": -116.08687032222221,
              "alt": 2209.4582222222225,
              "speed2d": 9.331777777777777,
              "speed3d": 9.336666666666668,
              "msFromStart": 90744.85155555556
            },
            {
              "lat": 43.76798216666667,
              "lng": -116.08686635555554,
              "alt": 2209.2514444444446,
              "speed2d": 8.79111111111111,
              "speed3d": 8.953333333333333,
              "msFromStart": 91248.05864923747
            },
            {
              "lat": 43.767946037499996,
              "lng": -116.086861725,
              "alt": 2209.247,
              "speed2d": 8.56675,
              "speed3d": 8.5525,
              "msFromStart": 91740.8974117647
            },
            {
              "lat": 43.76790422222222,
              "lng": -116.08685836666665,
              "alt": 2209.0533333333333,
              "speed2d": 8.795,
              "speed3d": 8.83,
              "msFromStart": 92230.36335511983
            },
            {
              "lat": 43.76786524444445,
              "lng": -116.08685733333333,
              "alt": 2208.714222222222,
              "speed2d": 8.439333333333332,
              "speed3d": 8.526666666666667,
              "msFromStart": 92727.9457777778
            },
            {
              "lat": 43.76782692,
              "lng": -116.08685385999999,
              "alt": 2208.5478999999996,
              "speed2d": 8.1501,
              "speed3d": 8.15,
              "msFromStart": 93250.8189754386
            },
            {
              "lat": 43.76778534444445,
              "lng": -116.08685214444442,
              "alt": 2208.329222222222,
              "speed2d": 8.392222222222223,
              "speed3d": 8.435555555555556,
              "msFromStart": 93773.24631578941
            },
            {
              "lat": 43.76774654444445,
              "lng": -116.08685143333332,
              "alt": 2208.029111111111,
              "speed2d": 8.447777777777777,
              "speed3d": 8.504444444444445,
              "msFromStart": 94268.23393372315
            },
            {
              "lat": 43.76771236666667,
              "lng": -116.08684948888889,
              "alt": 2207.8575555555553,
              "speed2d": 7.827999999999999,
              "speed3d": 7.86888888888889,
              "msFromStart": 94763.59644444444
            },
            {
              "lat": 43.76767462222222,
              "lng": -116.0868499,
              "alt": 2207.6408888888886,
              "speed2d": 8.26211111111111,
              "speed3d": 8.247777777777777,
              "msFromStart": 95258.6557037037
            },
            {
              "lat": 43.767635955555555,
              "lng": -116.08684913333332,
              "alt": 2207.760888888889,
              "speed2d": 8.174333333333333,
              "speed3d": 8.244444444444444,
              "msFromStart": 95752.35199999997
            },
            {
              "lat": 43.76760524444445,
              "lng": -116.08684957777778,
              "alt": 2207.7525555555553,
              "speed2d": 7.240666666666667,
              "speed3d": 7.360000000000001,
              "msFromStart": 96246.20162962962
            },
            {
              "lat": 43.76757482222222,
              "lng": -116.08685371111113,
              "alt": 2207.2355555555555,
              "speed2d": 7.284222222222222,
              "speed3d": 7.238888888888889,
              "msFromStart": 96741.20977777784
            },
            {
              "lat": 43.76754016666667,
              "lng": -116.08685451111111,
              "alt": 2206.9653333333335,
              "speed2d": 7.5473333333333334,
              "speed3d": 7.626666666666667,
              "msFromStart": 97236.28204288502
            },
            {
              "lat": 43.76750962222223,
              "lng": -116.08685530000001,
              "alt": 2206.7866666666664,
              "speed2d": 7.233666666666667,
              "speed3d": 7.1933333333333325,
              "msFromStart": 97731.1473684211
            },
            {
              "lat": 43.767473477777784,
              "lng": -116.08685988888891,
              "alt": 2206.092777777778,
              "speed2d": 8.053777777777778,
              "speed3d": 8.104444444444445,
              "msFromStart": 98226.13562573103
            },
            {
              "lat": 43.76743748,
              "lng": -116.08685903,
              "alt": 2205.8089000000004,
              "speed2d": 7.683,
              "speed3d": 7.728,
              "msFromStart": 98749.55599999994
            },
            {
              "lat": 43.76740272222222,
              "lng": -116.08685675555556,
              "alt": 2205.6586666666667,
              "speed2d": 7.350666666666667,
              "speed3d": 7.43,
              "msFromStart": 99273.09543859644
            },
            {
              "lat": 43.76737097777778,
              "lng": -116.08685459999998,
              "alt": 2205.353777777778,
              "speed2d": 7.214444444444444,
              "speed3d": 7.246666666666667,
              "msFromStart": 99769.01221052624
            },
            {
              "lat": 43.76733764444444,
              "lng": -116.08685187777778,
              "alt": 2205.018111111111,
              "speed2d": 7.4266666666666685,
              "speed3d": 7.4111111111111105,
              "msFromStart": 100263.28523976603
            },
            {
              "lat": 43.76730086666666,
              "lng": -116.08684744444443,
              "alt": 2204.830888888889,
              "speed2d": 7.819,
              "speed3d": 7.802222222222222,
              "msFromStart": 100750.99733333327
            },
            {
              "lat": 43.76726001111111,
              "lng": -116.08683994444445,
              "alt": 2204.912222222222,
              "speed2d": 8.347555555555555,
              "speed3d": 8.363333333333333,
              "msFromStart": 101240.35481481477
            },
            {
              "lat": 43.76722217777779,
              "lng": -116.08683263333333,
              "alt": 2205.0649999999996,
              "speed2d": 8.029,
              "speed3d": 8.097777777777779,
              "msFromStart": 101738.91822222217
            },
            {
              "lat": 43.76718695555556,
              "lng": -116.08682754444445,
              "alt": 2204.935111111111,
              "speed2d": 7.745333333333334,
              "speed3d": 7.796666666666668,
              "msFromStart": 102243.51538126358
            },
            {
              "lat": 43.7671537625,
              "lng": -116.0868213875,
              "alt": 2204.8816250000004,
              "speed2d": 7.597375,
              "speed3d": 7.58875,
              "msFromStart": 102739.28211764708
            },
            {
              "lat": 43.767114122222225,
              "lng": -116.0868151,
              "alt": 2204.6806666666666,
              "speed2d": 8.103333333333333,
              "speed3d": 8.12888888888889,
              "msFromStart": 103231.35830753358
            },
            {
              "lat": 43.76707781111111,
              "lng": -116.08681063333333,
              "alt": 2204.089777777778,
              "speed2d": 7.984111111111111,
              "speed3d": 7.97,
              "msFromStart": 103729.77431578949
            },
            {
              "lat": 43.76703754444445,
              "lng": -116.08680323333334,
              "alt": 2203.973444444445,
              "speed2d": 8.482333333333333,
              "speed3d": 8.457777777777777,
              "msFromStart": 104225.63752046785
            },
            {
              "lat": 43.76699436,
              "lng": -116.08679618000001,
              "alt": 2203.8554,
              "speed2d": 8.672300000000002,
              "speed3d": 8.75,
              "msFromStart": 104747.37000000005
            },
            {
              "lat": 43.766955366666664,
              "lng": -116.08678872222224,
              "alt": 2203.608111111111,
              "speed2d": 8.274666666666667,
              "speed3d": 8.304444444444446,
              "msFromStart": 105269.11437037043
            },
            {
              "lat": 43.766916,
              "lng": -116.08678259999999,
              "alt": 2203.161666666667,
              "speed2d": 8.609444444444444,
              "speed3d": 8.62,
              "msFromStart": 105764.4942222223
            },
            {
              "lat": 43.76687577777777,
              "lng": -116.0867752,
              "alt": 2202.8084444444444,
              "speed2d": 8.767555555555557,
              "speed3d": 8.796666666666665,
              "msFromStart": 106259.82370370373
            },
            {
              "lat": 43.76683624444444,
              "lng": -116.08676855555555,
              "alt": 2202.262,
              "speed2d": 8.774777777777778,
              "speed3d": 8.797777777777778,
              "msFromStart": 106754.51911111108
            },
            {
              "lat": 43.76679434444445,
              "lng": -116.08676152222222,
              "alt": 2201.6745555555553,
              "speed2d": 9.251666666666665,
              "speed3d": 9.254444444444445,
              "msFromStart": 107249.32074074072
            },
            {
              "lat": 43.76675116666666,
              "lng": -116.08675527777777,
              "alt": 2200.989,
              "speed2d": 9.649333333333335,
              "speed3d": 9.624444444444446,
              "msFromStart": 107744.80088888895
            },
            {
              "lat": 43.76670738888889,
              "lng": -116.08674744444444,
              "alt": 2200.4681111111113,
              "speed2d": 9.55311111111111,
              "speed3d": 9.707777777777778,
              "msFromStart": 108240.2235477583
            },
            {
              "lat": 43.76666668888888,
              "lng": -116.08674026666667,
              "alt": 2199.748666666667,
              "speed2d": 9.423111111111112,
              "speed3d": 9.456666666666667,
              "msFromStart": 108735.14484210522
            },
            {
              "lat": 43.76662206666667,
              "lng": -116.08673374444444,
              "alt": 2199.0264444444447,
              "speed2d": 9.885444444444445,
              "speed3d": 9.856666666666666,
              "msFromStart": 109230.02746198827
            },
            {
              "lat": 43.76657243,
              "lng": -116.08672790000001,
              "alt": 2198.1865000000003,
              "speed2d": 10.3384,
              "speed3d": 10.435000000000002,
              "msFromStart": 109752.47799999999
            },
            {
              "lat": 43.76652951111111,
              "lng": -116.08671522222222,
              "alt": 2197.5196666666666,
              "speed2d": 9.296888888888889,
              "speed3d": 9.474444444444444,
              "msFromStart": 110274.70296296298
            },
            {
              "lat": 43.766487877777784,
              "lng": -116.08669800000001,
              "alt": 2196.675333333333,
              "speed2d": 9.794222222222222,
              "speed3d": 9.822222222222223,
              "msFromStart": 110768.48177777782
            },
            {
              "lat": 43.766444766666666,
              "lng": -116.08668775555554,
              "alt": 2195.6316666666667,
              "speed2d": 9.997777777777777,
              "speed3d": 10.023333333333332,
              "msFromStart": 111261.10577777782
            },
            {
              "lat": 43.76639346666667,
              "lng": -116.08668163333333,
              "alt": 2193.903333333333,
              "speed2d": 11.28211111111111,
              "speed3d": 11.29,
              "msFromStart": 111749.48977777784
            },
            {
              "lat": 43.76634369999999,
              "lng": -116.0866739,
              "alt": 2192.614111111111,
              "speed2d": 11.061444444444446,
              "speed3d": 11.29888888888889,
              "msFromStart": 112239.65453411307
            },
            {
              "lat": 43.76629731111111,
              "lng": -116.08666145555556,
              "alt": 2191.872444444444,
              "speed2d": 10.818333333333335,
              "speed3d": 10.925555555555556,
              "msFromStart": 112738.63831578943
            },
            {
              "lat": 43.76624745555556,
              "lng": -116.08665289999999,
              "alt": 2191.1535555555556,
              "speed2d": 11.056888888888889,
              "speed3d": 11.19111111111111,
              "msFromStart": 113241.81284072925
            },
            {
              "lat": 43.766197366666674,
              "lng": -116.08664695555557,
              "alt": 2190.3475555555556,
              "speed2d": 11.161999999999999,
              "speed3d": 11.257777777777777,
              "msFromStart": 113765.17129411767
            },
            {
              "lat": 43.766174930566486,
              "lng": -116.0866443501713,
              "alt": 2190.050846084897,
              "speed2d": 11.322334779779336,
              "speed3d": 11.453753140310399,
              "msFromStart": 114000
            },
            {
              "lat": 43.766121865149344,
              "lng": -116.08663785678239,
              "alt": 2189.567449354962,
              "speed2d": 11.26750171787626,
              "speed3d": 11.334707676968195,
              "msFromStart": 114500
            },
            {
              "lat": 43.766018700000004,
              "lng": -116.08662545,
              "alt": 2188.6445000000003,
              "speed2d": 11.2335,
              "speed3d": 11.184999999999999,
              "msFromStart": 115471.26000000001
            },
            {
              "lat": 43.76596955,
              "lng": -116.0866236,
              "alt": 2187.8995,
              "speed2d": 11.062000000000001,
              "speed3d": 11.2,
              "msFromStart": 115965.73200000005
            },
            {
              "lat": 43.76594008,
              "lng": -116.08662138,
              "alt": 2187.5252,
              "speed2d": 10.811,
              "speed3d": 10.958,
              "msFromStart": 116268.12551111114
            },
            {
              "lat": 43.76589726666666,
              "lng": -116.0866179,
              "alt": 2186.938,
              "speed2d": 10.959666666666667,
              "speed3d": 11.006666666666666,
              "msFromStart": 116690.29718518522
            },
            {
              "lat": 43.76581865,
              "lng": -116.08660885,
              "alt": 2186.7255,
              "speed2d": 10.796,
              "speed3d": 10.995000000000001,
              "msFromStart": 117451.34199999998
            },
            {
              "lat": 43.76579749999999,
              "lng": -116.08660619999999,
              "alt": 2186.5836,
              "speed2d": 10.298,
              "speed3d": 10.466,
              "msFromStart": 117698.33999999997
            },
            {
              "lat": 43.765742180000004,
              "lng": -116.08660142,
              "alt": 2185.9438,
              "speed2d": 10.4708,
              "speed3d": 10.459999999999999,
              "msFromStart": 118280.28706900582
            },
            {
              "lat": 43.76569598333333,
              "lng": -116.0866006,
              "alt": 2185.652333333333,
              "speed2d": 11.0665,
              "speed3d": 11.155,
              "msFromStart": 118714.45999999998
            },
            {
              "lat": 43.76564297777778,
              "lng": -116.08659407777778,
              "alt": 2185.0963333333334,
              "speed2d": 10.931888888888889,
              "speed3d": 11.016666666666666,
              "msFromStart": 119236.89612475634
            },
            {
              "lat": 43.76559646249999,
              "lng": -116.08658824999999,
              "alt": 2184.60825,
              "speed2d": 10.777000000000001,
              "speed3d": 10.841249999999999,
              "msFromStart": 119713.04050000005
            },
            {
              "lat": 43.765558299999995,
              "lng": -116.0865809,
              "alt": 2184.2425,
              "speed2d": 10.986,
              "speed3d": 10.995000000000001,
              "msFromStart": 120092.82466666675
            },
            {
              "lat": 43.7654865,
              "lng": -116.0865689,
              "alt": 2183.7356666666665,
              "speed2d": 10.681666666666667,
              "speed3d": 10.806666666666667,
              "msFromStart": 120816.20088888892
            },
            {
              "lat": 43.76544340000001,
              "lng": -116.08656075000002,
              "alt": 2183.359166666667,
              "speed2d": 10.801833333333335,
              "speed3d": 10.825000000000001,
              "msFromStart": 121255.35051851855
            },
            {
              "lat": 43.76539783333334,
              "lng": -116.0865518,
              "alt": 2183.1566666666668,
              "speed2d": 10.917166666666667,
              "speed3d": 11.008333333333335,
              "msFromStart": 121704.54674074074
            },
            {
              "lat": 43.765369125062634,
              "lng": -116.08654502061853,
              "alt": 2183.04718973254,
              "speed2d": 10.756606803849436,
              "speed3d": 10.794961806173442,
              "msFromStart": 122000
            },
            {
              "lat": 43.765324997114575,
              "lng": -116.0865362475421,
              "alt": 2182.409892192826,
              "speed2d": 10.025467751919807,
              "speed3d": 9.989485793219686,
              "msFromStart": 122500
            },
            {
              "lat": 43.765238249999996,
              "lng": -116.08651255000001,
              "alt": 2182.639,
              "speed2d": 9.825,
              "speed3d": 9.895,
              "msFromStart": 123435.61115789475
            },
            {
              "lat": 43.76521455555555,
              "lng": -116.08650596666666,
              "alt": 2182.3774444444443,
              "speed2d": 9.258333333333335,
              "speed3d": 9.403333333333334,
              "msFromStart": 123740.59368421054
            },
            {
              "lat": 43.76517715714286,
              "lng": -116.08649638571428,
              "alt": 2181.768,
              "speed2d": 8.921857142857142,
              "speed3d": 8.924285714285713,
              "msFromStart": 124235.34249270236
            },
            {
              "lat": 43.76515546142574,
              "lng": -116.08649008462079,
              "alt": 2181.657406340855,
              "speed2d": 9.216588609410552,
              "speed3d": 9.180401898431574,
              "msFromStart": 124500
            },
            {
              "lat": 43.76508812857143,
              "lng": -116.08646912857145,
              "alt": 2181.192571428571,
              "speed2d": 9.231714285714286,
              "speed3d": 9.258571428571427,
              "msFromStart": 125289.21986181142
            },
            {
              "lat": 43.76505271111111,
              "lng": -116.08645750000001,
              "alt": 2181.006111111111,
              "speed2d": 8.997555555555556,
              "speed3d": 9.07111111111111,
              "msFromStart": 125729.52800000002
            },
            {
              "lat": 43.76501325,
              "lng": -116.08644539000001,
              "alt": 2180.7288000000003,
              "speed2d": 8.5055,
              "speed3d": 8.655,
              "msFromStart": 126250.7402222222
            },
            {
              "lat": 43.76498024,
              "lng": -116.0864349,
              "alt": 2180.5556,
              "speed2d": 8.095,
              "speed3d": 8.072,
              "msFromStart": 126718.18000000005
            },
            {
              "lat": 43.76494603333333,
              "lng": -116.08642096666667,
              "alt": 2180.3146666666667,
              "speed2d": 9.146999999999998,
              "speed3d": 9.04,
              "msFromStart": 127121.61437037043
            },
            {
              "lat": 43.7649151940776,
              "lng": -116.08640771669285,
              "alt": 2180.122426556876,
              "speed2d": 9.229897224761178,
              "speed3d": 9.105639275574498,
              "msFromStart": 127500
            },
            {
              "lat": 43.7648545,
              "lng": -116.08638032000002,
              "alt": 2179.6398,
              "speed2d": 8.817,
              "speed3d": 8.878,
              "msFromStart": 128279.57121403507
            },
            {
              "lat": 43.76481473333333,
              "lng": -116.08636076666667,
              "alt": 2179.15,
              "speed2d": 8.855666666666666,
              "speed3d": 8.83,
              "msFromStart": 128808.45221052624
            },
            {
              "lat": 43.7647816,
              "lng": -116.08634809999998,
              "alt": 2178.9137142857144,
              "speed2d": 9.470142857142857,
              "speed3d": 9.450000000000001,
              "msFromStart": 129194.14018045107
            },
            {
              "lat": 43.764756530538364,
              "lng": -116.08633802580927,
              "alt": 2178.7328784575047,
              "speed2d": 9.473195881875641,
              "speed3d": 9.467809964395565,
              "msFromStart": 129500
            },
            {
              "lat": 43.764690540000004,
              "lng": -116.08630928,
              "alt": 2178.2802,
              "speed2d": 8.803799999999999,
              "speed3d": 8.874,
              "msFromStart": 130349.872
            },
            {
              "lat": 43.76466456666667,
              "lng": -116.08629766666667,
              "alt": 2178.142333333333,
              "speed2d": 8.748333333333333,
              "speed3d": 8.753333333333334,
              "msFromStart": 130697.31866666666
            },
            {
              "lat": 43.76463976147845,
              "lng": -116.08628793176213,
              "alt": 2177.974370321877,
              "speed2d": 9.08319590834697,
              "speed3d": 9.031237861429348,
              "msFromStart": 131000
            },
            {
              "lat": 43.764579950000005,
              "lng": -116.08625665,
              "alt": 2177.7921666666666,
              "speed2d": 8.982333333333333,
              "speed3d": 9.02,
              "msFromStart": 131759.62088888884
            },
            {
              "lat": 43.76454041428572,
              "lng": -116.08623750000001,
              "alt": 2177.703571428571,
              "speed2d": 9.066428571428572,
              "speed3d": 9.095714285714285,
              "msFromStart": 132265.65104761897
            },
            {
              "lat": 43.76450286666667,
              "lng": -116.08621894999999,
              "alt": 2177.4778333333334,
              "speed2d": 8.899166666666666,
              "speed3d": 8.931666666666667,
              "msFromStart": 132760.81348148145
            },
            {
              "lat": 43.76446734285714,
              "lng": -116.0862017142857,
              "alt": 2177.113285714285,
              "speed2d": 8.851142857142857,
              "speed3d": 8.92,
              "msFromStart": 133225.92178446113
            },
            {
              "lat": 43.76442351428571,
              "lng": -116.08618005714285,
              "alt": 2176.523,
              "speed2d": 8.782714285714286,
              "speed3d": 8.911428571428571,
              "msFromStart": 133806.11284210518
            },
            {
              "lat": 43.76439834285714,
              "lng": -116.08616525714287,
              "alt": 2176.146142857143,
              "speed2d": 7.758428571428571,
              "speed3d": 7.890000000000001,
              "msFromStart": 134216.86289724306
            },
            {
              "lat": 43.764374950000004,
              "lng": -116.08615094999999,
              "alt": 2175.9225,
              "speed2d": 7.966,
              "speed3d": 7.845000000000001,
              "msFromStart": 134572.9493333334
            },
            {
              "lat": 43.76434334749636,
              "lng": -116.08613481184133,
              "alt": 2175.3345809678467,
              "speed2d": 8.404215502250311,
              "speed3d": 8.297937894302349,
              "msFromStart": 135000
            },
            {
              "lat": 43.764288799999996,
              "lng": -116.08610626666668,
              "alt": 2174.3508333333334,
              "speed2d": 8.869666666666667,
              "speed3d": 8.888333333333334,
              "msFromStart": 135749.67403921558
            },
            {
              "lat": 43.7642485375,
              "lng": -116.08608392500001,
              "alt": 2173.706625,
              "speed2d": 9.010875,
              "speed3d": 9.072500000000002,
              "msFromStart": 136255.75996078423
            },
            {
              "lat": 43.764211711111116,
              "lng": -116.08606353333333,
              "alt": 2173.0987777777777,
              "speed2d": 9.13,
              "speed3d": 9.176666666666666,
              "msFromStart": 136734.08799999993
            },
            {
              "lat": 43.76418134000001,
              "lng": -116.08604674,
              "alt": 2172.7034,
              "speed2d": 9.2338,
              "speed3d": 9.292,
              "msFromStart": 137119.79733333323
            },
            {
              "lat": 43.764134657142854,
              "lng": -116.08601935714286,
              "alt": 2171.9905714285715,
              "speed2d": 9.511,
              "speed3d": 9.578571428571427,
              "msFromStart": 137700.8876190476
            },
            {
              "lat": 43.7640818,
              "lng": -116.08598752500001,
              "alt": 2171.3925,
              "speed2d": 9.85525,
              "speed3d": 9.8525,
              "msFromStart": 138342.71564912278
            },
            {
              "lat": 43.764053266666664,
              "lng": -116.08596826666667,
              "alt": 2170.9783333333335,
              "speed2d": 9.875333333333334,
              "speed3d": 9.976666666666667,
              "msFromStart": 138695.6875789473
            },
            {
              "lat": 43.76400460000001,
              "lng": -116.085932325,
              "alt": 2170.2965,
              "speed2d": 10.194749999999999,
              "speed3d": 10.243749999999999,
              "msFromStart": 139291.53997660815
            },
            {
              "lat": 43.763965500000005,
              "lng": -116.0859014,
              "alt": 2169.7305,
              "speed2d": 10.222999999999999,
              "speed3d": 10.303333333333335,
              "msFromStart": 139776.3881481481
            },
            {
              "lat": 43.763928066666665,
              "lng": -116.08587143333334,
              "alt": 2169.2151666666664,
              "speed2d": 10.330333333333332,
              "speed3d": 10.398333333333333,
              "msFromStart": 140242.86799999993
            },
            {
              "lat": 43.76388258,
              "lng": -116.08584158,
              "alt": 2168.8525999999997,
              "speed2d": 10.763200000000001,
              "speed3d": 10.802,
              "msFromStart": 140757.08444444436
            }
          ]
        }
      ]
    },
    {
      "id": "trail-pine-creek-to-superior",
      "pathType": "trail",
      "name": "Pine Creek Lift to Superior Lift",
      "difficulty": "blue",
      "videos": [
        {
          "videoId": "GH010025",
          "pathId": "trail-pine-creek-to-superior",
          "startSec": 13,
          "endSec": 44,
          "id": "video-segment-11",
          "gps": [
            {
              "lat": 43.771578922222226,
              "lng": -116.08801017777779,
              "alt": 2300.018222222222,
              "speed2d": 1.545888888888889,
              "speed3d": 1.5255555555555556,
              "msFromStart": 13263.92860818714
            },
            {
              "lat": 43.77158604444444,
              "lng": -116.0880078888889,
              "alt": 2299.708888888889,
              "speed2d": 1.6895555555555557,
              "speed3d": 1.6700000000000002,
              "msFromStart": 13757.586333333344
            },
            {
              "lat": 43.77159625555555,
              "lng": -116.08800635555556,
              "alt": 2299.349222222222,
              "speed2d": 1.9867777777777775,
              "speed3d": 1.9755555555555557,
              "msFromStart": 14251.454333333337
            },
            {
              "lat": 43.77160494444445,
              "lng": -116.08800432222222,
              "alt": 2299.18,
              "speed2d": 2.025333333333333,
              "speed3d": 2.031111111111111,
              "msFromStart": 14745.873333333335
            },
            {
              "lat": 43.77161402222222,
              "lng": -116.08800351111111,
              "alt": 2298.926888888889,
              "speed2d": 2.137111111111111,
              "speed3d": 2.135555555555556,
              "msFromStart": 15240.424163742688
            },
            {
              "lat": 43.77162442222222,
              "lng": -116.08800482222222,
              "alt": 2298.566,
              "speed2d": 2.277222222222222,
              "speed3d": 2.291111111111111,
              "msFromStart": 15735.25410526315
            },
            {
              "lat": 43.771632833333335,
              "lng": -116.0880082222222,
              "alt": 2298.006666666667,
              "speed2d": 2.247111111111111,
              "speed3d": 2.2777777777777777,
              "msFromStart": 16230.10436062378
            },
            {
              "lat": 43.77164029000001,
              "lng": -116.08801536,
              "alt": 2297.5910000000003,
              "speed2d": 2.1888,
              "speed3d": 2.2,
              "msFromStart": 16752.470166666655
            },
            {
              "lat": 43.77164857777778,
              "lng": -116.08802426666668,
              "alt": 2297.2091111111113,
              "speed2d": 2.2365555555555554,
              "speed3d": 2.2777777777777777,
              "msFromStart": 17275.10811111111
            },
            {
              "lat": 43.77165632222222,
              "lng": -116.08803509999998,
              "alt": 2296.5537777777777,
              "speed2d": 2.4027777777777777,
              "speed3d": 2.411111111111111,
              "msFromStart": 17770.98555555556
            },
            {
              "lat": 43.77166306666666,
              "lng": -116.0880472333333,
              "alt": 2296.099222222222,
              "speed2d": 2.5138888888888884,
              "speed3d": 2.5555555555555554,
              "msFromStart": 18266.52511111111
            },
            {
              "lat": 43.771668444444444,
              "lng": -116.08805996666666,
              "alt": 2295.5208888888887,
              "speed2d": 2.4836666666666662,
              "speed3d": 2.561111111111112,
              "msFromStart": 18761.04288888889
            },
            {
              "lat": 43.77167541111112,
              "lng": -116.08807276666668,
              "alt": 2295.240333333333,
              "speed2d": 2.522666666666667,
              "speed3d": 2.5477777777777777,
              "msFromStart": 19255.458407407412
            },
            {
              "lat": 43.77168365555555,
              "lng": -116.08808668888891,
              "alt": 2294.9654444444445,
              "speed2d": 2.697777777777778,
              "speed3d": 2.7399999999999998,
              "msFromStart": 19749.74233333335
            },
            {
              "lat": 43.77169304444445,
              "lng": -116.08810173333335,
              "alt": 2294.651777777778,
              "speed2d": 2.960888888888889,
              "speed3d": 2.9555555555555557,
              "msFromStart": 20244.090370370373
            },
            {
              "lat": 43.77170437777778,
              "lng": -116.08811833333333,
              "alt": 2294.342111111111,
              "speed2d": 3.324888888888889,
              "speed3d": 3.321111111111111,
              "msFromStart": 20738.631222222204
            },
            {
              "lat": 43.771717766666676,
              "lng": -116.08813641111112,
              "alt": 2293.9934444444443,
              "speed2d": 3.7547777777777775,
              "speed3d": 3.7588888888888885,
              "msFromStart": 21233.616454191022
            },
            {
              "lat": 43.77173155555555,
              "lng": -116.08815515555555,
              "alt": 2293.515,
              "speed2d": 4.054777777777778,
              "speed3d": 4.052222222222222,
              "msFromStart": 21729.780315789456
            },
            {
              "lat": 43.771745977777776,
              "lng": -116.08817524444446,
              "alt": 2293.1277777777777,
              "speed2d": 4.323888888888889,
              "speed3d": 4.3311111111111105,
              "msFromStart": 22225.77752631578
            },
            {
              "lat": 43.77176049999999,
              "lng": -116.08819812000003,
              "alt": 2292.4265,
              "speed2d": 4.5553,
              "speed3d": 4.581,
              "msFromStart": 22748.359499999995
            },
            {
              "lat": 43.77177376666667,
              "lng": -116.08822249999999,
              "alt": 2291.4924444444446,
              "speed2d": 4.725222222222222,
              "speed3d": 4.748888888888889,
              "msFromStart": 23270.778703703694
            },
            {
              "lat": 43.77178557777777,
              "lng": -116.08825,
              "alt": 2290.353111111111,
              "speed2d": 5.153666666666668,
              "speed3d": 5.168888888888889,
              "msFromStart": 23765.504888888867
            },
            {
              "lat": 43.77179851111111,
              "lng": -116.08828016666666,
              "alt": 2289.0645555555557,
              "speed2d": 5.625222222222223,
              "speed3d": 5.654444444444444,
              "msFromStart": 24260.315777777774
            },
            {
              "lat": 43.77181367777777,
              "lng": -116.08831363333333,
              "alt": 2288.045222222222,
              "speed2d": 6.342888888888889,
              "speed3d": 6.352222222222222,
              "msFromStart": 24755.384222222216
            },
            {
              "lat": 43.77183304444445,
              "lng": -116.08835007777778,
              "alt": 2287.1855555555558,
              "speed2d": 6.998,
              "speed3d": 7.04,
              "msFromStart": 25250.70707407408
            },
            {
              "lat": 43.77185495555556,
              "lng": -116.08838537777778,
              "alt": 2286.360555555556,
              "speed2d": 7.220999999999999,
              "speed3d": 7.2666666666666675,
              "msFromStart": 25746.68366666668
            },
            {
              "lat": 43.77187783333333,
              "lng": -116.08842661111113,
              "alt": 2285.4442222222224,
              "speed2d": 7.840999999999999,
              "speed3d": 7.885555555555555,
              "msFromStart": 26242.588029239767
            },
            {
              "lat": 43.77189490000001,
              "lng": -116.08846788888889,
              "alt": 2284.5536666666667,
              "speed2d": 7.949000000000002,
              "speed3d": 8.034444444444444,
              "msFromStart": 26738.202947368405
            },
            {
              "lat": 43.77190963333334,
              "lng": -116.08851244444446,
              "alt": 2283.3976666666667,
              "speed2d": 8.261444444444445,
              "speed3d": 8.335555555555555,
              "msFromStart": 27233.564727095512
            },
            {
              "lat": 43.771923055555554,
              "lng": -116.08855943333336,
              "alt": 2282.283888888889,
              "speed2d": 8.51,
              "speed3d": 8.582222222222223,
              "msFromStart": 27727.974888888908
            },
            {
              "lat": 43.77193955999999,
              "lng": -116.08861338999999,
              "alt": 2281.2811,
              "speed2d": 9.0364,
              "speed3d": 9.088,
              "msFromStart": 28250.36293333334
            },
            {
              "lat": 43.77195522222222,
              "lng": -116.0886692,
              "alt": 2280.4366666666665,
              "speed2d": 9.300666666666666,
              "speed3d": 9.392222222222223,
              "msFromStart": 28774.72977777777
            },
            {
              "lat": 43.7719699,
              "lng": -116.08872193333333,
              "alt": 2279.5434444444445,
              "speed2d": 9.319222222222223,
              "speed3d": 9.416666666666668,
              "msFromStart": 29271.026518518516
            },
            {
              "lat": 43.77198541111112,
              "lng": -116.08877696666667,
              "alt": 2278.8534444444444,
              "speed2d": 9.507444444444445,
              "speed3d": 9.578888888888889,
              "msFromStart": 29765.59355555556
            },
            {
              "lat": 43.77199872222222,
              "lng": -116.08883757777777,
              "alt": 2278.296333333333,
              "speed2d": 10.022555555555556,
              "speed3d": 10.046666666666665,
              "msFromStart": 30259.42607407408
            },
            {
              "lat": 43.77200851111111,
              "lng": -116.08889871111111,
              "alt": 2277.525777777778,
              "speed2d": 10.164555555555554,
              "speed3d": 10.251111111111111,
              "msFromStart": 30751.512000000017
            },
            {
              "lat": 43.77201607777778,
              "lng": -116.08895607777778,
              "alt": 2276.422444444445,
              "speed2d": 9.880333333333333,
              "speed3d": 10.018888888888888,
              "msFromStart": 31244.06433333334
            },
            {
              "lat": 43.7720261111111,
              "lng": -116.0890115,
              "alt": 2275.505111111111,
              "speed2d": 9.64488888888889,
              "speed3d": 9.746666666666668,
              "msFromStart": 31738.850000000006
            },
            {
              "lat": 43.772031122222224,
              "lng": -116.0890704111111,
              "alt": 2274.778222222222,
              "speed2d": 9.675333333333333,
              "speed3d": 9.763333333333334,
              "msFromStart": 32234.006222222222
            },
            {
              "lat": 43.77203823333333,
              "lng": -116.08912699999999,
              "alt": 2274.4882222222222,
              "speed2d": 9.557666666666668,
              "speed3d": 9.648888888888887,
              "msFromStart": 32729.75133333334
            },
            {
              "lat": 43.77204418888889,
              "lng": -116.08918466666665,
              "alt": 2274.3655555555556,
              "speed2d": 9.310777777777778,
              "speed3d": 9.433333333333334,
              "msFromStart": 33225.43885185185
            },
            {
              "lat": 43.77205092,
              "lng": -116.08924119999998,
              "alt": 2273.7121,
              "speed2d": 8.950999999999999,
              "speed3d": 9.024000000000001,
              "msFromStart": 33748.17016666665
            },
            {
              "lat": 43.77205633333333,
              "lng": -116.0893002,
              "alt": 2273.463666666667,
              "speed2d": 9.020555555555557,
              "speed3d": 9.073333333333332,
              "msFromStart": 34270.812111111096
            },
            {
              "lat": 43.77206131111111,
              "lng": -116.08935538888889,
              "alt": 2273.3170000000005,
              "speed2d": 8.862444444444444,
              "speed3d": 8.96111111111111,
              "msFromStart": 34765.8282222222
            },
            {
              "lat": 43.77206622222222,
              "lng": -116.08940779999999,
              "alt": 2272.8613333333333,
              "speed2d": 8.684666666666667,
              "speed3d": 8.713333333333333,
              "msFromStart": 35260.838740740735
            },
            {
              "lat": 43.77207051111111,
              "lng": -116.08946154444443,
              "alt": 2272.298888888889,
              "speed2d": 8.748888888888889,
              "speed3d": 8.81,
              "msFromStart": 35755.85066666667
            },
            {
              "lat": 43.772075911111116,
              "lng": -116.08951533333332,
              "alt": 2271.717111111111,
              "speed2d": 8.909333333333333,
              "speed3d": 8.926666666666668,
              "msFromStart": 36250.79471345028
            },
            {
              "lat": 43.7720797,
              "lng": -116.08957443333335,
              "alt": 2271.097222222222,
              "speed2d": 9.454222222222223,
              "speed3d": 9.456666666666667,
              "msFromStart": 36745.55431578944
            },
            {
              "lat": 43.772085000000004,
              "lng": -116.089632,
              "alt": 2270.257666666667,
              "speed2d": 9.594555555555555,
              "speed3d": 9.645555555555555,
              "msFromStart": 37240.142748538
            },
            {
              "lat": 43.772091266666656,
              "lng": -116.08969028888889,
              "alt": 2269.3219999999997,
              "speed2d": 9.786,
              "speed3d": 9.818888888888889,
              "msFromStart": 37734.08533333331
            },
            {
              "lat": 43.77209664444444,
              "lng": -116.08975303333335,
              "alt": 2268.367333333333,
              "speed2d": 10.353444444444447,
              "speed3d": 10.364444444444443,
              "msFromStart": 38228.40333333332
            },
            {
              "lat": 43.772102966666665,
              "lng": -116.0898187,
              "alt": 2267.637555555555,
              "speed2d": 10.756888888888888,
              "speed3d": 10.777777777777779,
              "msFromStart": 38724.48133333333
            },
            {
              "lat": 43.77211057,
              "lng": -116.08988799000001,
              "alt": 2266.6854000000003,
              "speed2d": 11.0032,
              "speed3d": 11.091999999999999,
              "msFromStart": 39248.165466666665
            },
            {
              "lat": 43.772118400000004,
              "lng": -116.08995645555555,
              "alt": 2265.750222222222,
              "speed2d": 10.818555555555555,
              "speed3d": 10.895555555555553,
              "msFromStart": 39771.3977777778
            },
            {
              "lat": 43.77212408888889,
              "lng": -116.09002416666667,
              "alt": 2265.038666666667,
              "speed2d": 11.08911111111111,
              "speed3d": 11.155555555555555,
              "msFromStart": 40266.87696296297
            },
            {
              "lat": 43.772129311111115,
              "lng": -116.09009217777778,
              "alt": 2264.5221111111114,
              "speed2d": 11.012333333333334,
              "speed3d": 11.113333333333332,
              "msFromStart": 40761.87622222219
            },
            {
              "lat": 43.77213353333333,
              "lng": -116.09015985555556,
              "alt": 2264.052888888889,
              "speed2d": 10.935444444444444,
              "speed3d": 11.004444444444445,
              "msFromStart": 41256.69829629629
            },
            {
              "lat": 43.772135577777775,
              "lng": -116.09022547777776,
              "alt": 2263.6556666666665,
              "speed2d": 10.607888888888887,
              "speed3d": 10.734444444444446,
              "msFromStart": 41751.10800000001
            },
            {
              "lat": 43.772135866666666,
              "lng": -116.09028615555556,
              "alt": 2263.5976666666666,
              "speed2d": 9.976555555555557,
              "speed3d": 10.101111111111113,
              "msFromStart": 42245.77711111111
            },
            {
              "lat": 43.77213108888889,
              "lng": -116.09034368888888,
              "alt": 2263.611111111111,
              "speed2d": 9.391222222222222,
              "speed3d": 9.51,
              "msFromStart": 42741.57066666665
            },
            {
              "lat": 43.772119588888884,
              "lng": -116.09039595555556,
              "alt": 2263.4418888888886,
              "speed2d": 8.856666666666667,
              "speed3d": 8.958888888888888,
              "msFromStart": 43237.2174074074
            },
            {
              "lat": 43.772106455555544,
              "lng": -116.09043874444443,
              "alt": 2263.0841111111113,
              "speed2d": 8.109777777777778,
              "speed3d": 8.205555555555556,
              "msFromStart": 43731.82711111111
            }
          ]
        }
      ]
    },
    {
      "id": "trail-paradise",
      "pathType": "trail",
      "name": "Paradise",
      "difficulty": "blue",
      "videos": [
        {
          "videoId": "GH010029",
          "pathId": "trail-paradise",
          "startSec": 221,
          "endSec": 323,
          "id": "video-segment-16",
          "gps": [
            {
              "lat": 43.773071944444446,
              "lng": -116.08131266666668,
              "alt": 2093.0898888888887,
              "speed2d": 9.817222222222222,
              "speed3d": 9.871111111111112,
              "msFromStart": 221230.9090370371
            },
            {
              "lat": 43.773075633333335,
              "lng": -116.08125367777778,
              "alt": 2092.5008888888888,
              "speed2d": 9.697888888888889,
              "speed3d": 9.813333333333333,
              "msFromStart": 221727.13688888884
            },
            {
              "lat": 43.77308574999999,
              "lng": -116.08119122,
              "alt": 2092.1501,
              "speed2d": 10.0392,
              "speed3d": 9.998000000000001,
              "msFromStart": 222251.5386807017
            },
            {
              "lat": 43.77309597777778,
              "lng": -116.08111448888889,
              "alt": 2091.538,
              "speed2d": 11.383333333333333,
              "speed3d": 11.252222222222223,
              "msFromStart": 222774.71663157904
            },
            {
              "lat": 43.77310124444444,
              "lng": -116.0810224111111,
              "alt": 2090.6672222222223,
              "speed2d": 13.382888888888889,
              "speed3d": 13.247777777777776,
              "msFromStart": 223270.00394541927
            },
            {
              "lat": 43.773105055555554,
              "lng": -116.08093137777777,
              "alt": 2089.4675555555555,
              "speed2d": 13.758333333333333,
              "speed3d": 13.907777777777778,
              "msFromStart": 223765.35022222216
            },
            {
              "lat": 43.773112355555554,
              "lng": -116.08085676666666,
              "alt": 2088.9553333333333,
              "speed2d": 12.738111111111111,
              "speed3d": 12.951111111111112,
              "msFromStart": 224260.73925925916
            },
            {
              "lat": 43.77311778888888,
              "lng": -116.08079475555554,
              "alt": 2088.7645555555555,
              "speed2d": 11.07777777777778,
              "speed3d": 11.372222222222224,
              "msFromStart": 224756.52799999993
            },
            {
              "lat": 43.77312063333334,
              "lng": -116.0807336,
              "alt": 2088.536111111111,
              "speed2d": 10.387888888888888,
              "speed3d": 10.434444444444445,
              "msFromStart": 225252.44592592586
            },
            {
              "lat": 43.77312167777778,
              "lng": -116.08066704444443,
              "alt": 2088.5634444444445,
              "speed2d": 10.41588888888889,
              "speed3d": 10.506666666666668,
              "msFromStart": 225748.02044444444
            },
            {
              "lat": 43.77312094444445,
              "lng": -116.08060240000002,
              "alt": 2088.193444444444,
              "speed2d": 10.20488888888889,
              "speed3d": 10.292222222222223,
              "msFromStart": 226243.36503703706
            },
            {
              "lat": 43.773120255555554,
              "lng": -116.08054362222222,
              "alt": 2087.5267777777776,
              "speed2d": 9.681111111111111,
              "speed3d": 9.851111111111111,
              "msFromStart": 226736.74488888896
            },
            {
              "lat": 43.77311973333333,
              "lng": -116.08048693333333,
              "alt": 2087.1433333333334,
              "speed2d": 9.34588888888889,
              "speed3d": 9.446666666666665,
              "msFromStart": 227229.46398440553
            },
            {
              "lat": 43.77311495,
              "lng": -116.08043259,
              "alt": 2086.1114,
              "speed2d": 8.4214,
              "speed3d": 8.597,
              "msFromStart": 227750.84631578944
            },
            {
              "lat": 43.77310994444444,
              "lng": -116.08037487777779,
              "alt": 2085.4722222222226,
              "speed2d": 8.620777777777779,
              "speed3d": 8.676666666666668,
              "msFromStart": 228272.69433138397
            },
            {
              "lat": 43.77310816666667,
              "lng": -116.0803205888889,
              "alt": 2085.3417777777777,
              "speed2d": 8.60888888888889,
              "speed3d": 8.752222222222223,
              "msFromStart": 228767.53422222228
            },
            {
              "lat": 43.773106822222225,
              "lng": -116.08026854444445,
              "alt": 2085.4372222222223,
              "speed2d": 8.40688888888889,
              "speed3d": 8.475555555555555,
              "msFromStart": 229262.5111111112
            },
            {
              "lat": 43.773104077777774,
              "lng": -116.080215,
              "alt": 2085.312,
              "speed2d": 8.343555555555556,
              "speed3d": 8.442222222222222,
              "msFromStart": 229756.89688888882
            },
            {
              "lat": 43.77310207777777,
              "lng": -116.08016337777778,
              "alt": 2085.344888888889,
              "speed2d": 8.089444444444446,
              "speed3d": 8.182222222222222,
              "msFromStart": 230251.11525925918
            },
            {
              "lat": 43.77309941111111,
              "lng": -116.08011098888889,
              "alt": 2085.176888888889,
              "speed2d": 8.105444444444446,
              "speed3d": 8.151111111111112,
              "msFromStart": 230746.29066666675
            },
            {
              "lat": 43.77309648888888,
              "lng": -116.08005834444447,
              "alt": 2084.840777777778,
              "speed2d": 8.092555555555554,
              "speed3d": 8.148888888888889,
              "msFromStart": 231241.7751111112
            },
            {
              "lat": 43.77309284444445,
              "lng": -116.08000675555556,
              "alt": 2084.994222222222,
              "speed2d": 8.114666666666666,
              "speed3d": 8.172222222222222,
              "msFromStart": 231736.43200000006
            },
            {
              "lat": 43.77309034444445,
              "lng": -116.0799572,
              "alt": 2084.757222222222,
              "speed2d": 7.980222222222221,
              "speed3d": 8.063333333333334,
              "msFromStart": 232230.83059649137
            },
            {
              "lat": 43.77308822222223,
              "lng": -116.07991042222224,
              "alt": 2084.601,
              "speed2d": 7.601333333333333,
              "speed3d": 7.688888888888889,
              "msFromStart": 232726.11452631583
            },
            {
              "lat": 43.77308612,
              "lng": -116.07986057999999,
              "alt": 2084.6123000000002,
              "speed2d": 7.4895,
              "speed3d": 7.531,
              "msFromStart": 233249.2400561404
            },
            {
              "lat": 43.77308337777778,
              "lng": -116.07980950000001,
              "alt": 2084.517111111111,
              "speed2d": 7.4319999999999995,
              "speed3d": 7.464444444444444,
              "msFromStart": 233771.97155555565
            },
            {
              "lat": 43.77308198888889,
              "lng": -116.07976192222223,
              "alt": 2084.682,
              "speed2d": 7.499888888888889,
              "speed3d": 7.505555555555555,
              "msFromStart": 234267.0094814816
            },
            {
              "lat": 43.77308011111111,
              "lng": -116.07971171111112,
              "alt": 2084.5403333333334,
              "speed2d": 7.716111111111111,
              "speed3d": 7.746666666666668,
              "msFromStart": 234761.70399999994
            },
            {
              "lat": 43.77308014444444,
              "lng": -116.0796647222222,
              "alt": 2084.1647777777775,
              "speed2d": 7.663777777777777,
              "speed3d": 7.686666666666667,
              "msFromStart": 235256.28859259252
            },
            {
              "lat": 43.77308026666667,
              "lng": -116.07961691111112,
              "alt": 2083.9932222222224,
              "speed2d": 7.694777777777778,
              "speed3d": 7.727777777777778,
              "msFromStart": 235751.1884444444
            },
            {
              "lat": 43.77308087777778,
              "lng": -116.07957108888887,
              "alt": 2083.9653333333335,
              "speed2d": 7.467333333333333,
              "speed3d": 7.511111111111112,
              "msFromStart": 236246.18962962955
            },
            {
              "lat": 43.77308098888889,
              "lng": -116.07952351111112,
              "alt": 2083.567777777778,
              "speed2d": 7.536444444444445,
              "speed3d": 7.544444444444445,
              "msFromStart": 236740.9093333333
            },
            {
              "lat": 43.773079777777774,
              "lng": -116.07947304444444,
              "alt": 2083.0998888888885,
              "speed2d": 7.738333333333334,
              "speed3d": 7.728888888888889,
              "msFromStart": 237235.58095906425
            },
            {
              "lat": 43.77307938888889,
              "lng": -116.0794225,
              "alt": 2082.694888888889,
              "speed2d": 7.904888888888889,
              "speed3d": 7.935555555555555,
              "msFromStart": 237731.3094736843
            },
            {
              "lat": 43.773079611111115,
              "lng": -116.07937316666667,
              "alt": 2082.382333333333,
              "speed2d": 7.882777777777777,
              "speed3d": 7.904444444444445,
              "msFromStart": 238227.40408577007
            },
            {
              "lat": 43.77308076999999,
              "lng": -116.07932274,
              "alt": 2082.0173999999997,
              "speed2d": 7.7585,
              "speed3d": 7.796,
              "msFromStart": 238749.74533333338
            },
            {
              "lat": 43.77308093333333,
              "lng": -116.07926826666667,
              "alt": 2081.5676666666664,
              "speed2d": 8.077222222222222,
              "speed3d": 8.034444444444444,
              "msFromStart": 239271.48859259268
            },
            {
              "lat": 43.77308111111111,
              "lng": -116.07921417777777,
              "alt": 2081.277666666667,
              "speed2d": 8.467666666666666,
              "speed3d": 8.477777777777778,
              "msFromStart": 239766.79911111115
            },
            {
              "lat": 43.773081966666666,
              "lng": -116.07916282222223,
              "alt": 2080.783777777778,
              "speed2d": 8.386888888888889,
              "speed3d": 8.436666666666667,
              "msFromStart": 240262.43585185191
            },
            {
              "lat": 43.77308324444444,
              "lng": -116.07911361111113,
              "alt": 2079.868111111111,
              "speed2d": 8.15011111111111,
              "speed3d": 8.19,
              "msFromStart": 240757.09866666677
            },
            {
              "lat": 43.773085433333335,
              "lng": -116.07906046666669,
              "alt": 2079.1518888888886,
              "speed2d": 8.406777777777778,
              "speed3d": 8.41,
              "msFromStart": 241251.43733333348
            },
            {
              "lat": 43.77308838888889,
              "lng": -116.07900395555555,
              "alt": 2078.7374444444445,
              "speed2d": 8.865333333333332,
              "speed3d": 8.852222222222222,
              "msFromStart": 241746.43466666673
            },
            {
              "lat": 43.77309227777778,
              "lng": -116.07895241111112,
              "alt": 2078.428666666667,
              "speed2d": 8.467666666666666,
              "speed3d": 8.591111111111111,
              "msFromStart": 242241.70605847958
            },
            {
              "lat": 43.7730954,
              "lng": -116.07890452222223,
              "alt": 2078.1751111111107,
              "speed2d": 7.988,
              "speed3d": 8.023333333333333,
              "msFromStart": 242737.5738947369
            },
            {
              "lat": 43.773097711111106,
              "lng": -116.07884701111112,
              "alt": 2077.5731111111113,
              "speed2d": 8.828555555555557,
              "speed3d": 8.773333333333333,
              "msFromStart": 243233.62720727758
            },
            {
              "lat": 43.77310133333334,
              "lng": -116.07879237777777,
              "alt": 2076.954222222222,
              "speed2d": 8.698777777777778,
              "speed3d": 8.818888888888889,
              "msFromStart": 243727.89866666676
            },
            {
              "lat": 43.773106399999996,
              "lng": -116.07873942999998,
              "alt": 2076.6884,
              "speed2d": 8.376,
              "speed3d": 8.412,
              "msFromStart": 244248.64835555566
            },
            {
              "lat": 43.77311054444445,
              "lng": -116.0786757,
              "alt": 2076.411,
              "speed2d": 9.488,
              "speed3d": 9.27888888888889,
              "msFromStart": 244770.0693333334
            },
            {
              "lat": 43.773117133333336,
              "lng": -116.0786043,
              "alt": 2076.6302222222216,
              "speed2d": 10.849000000000002,
              "speed3d": 10.863333333333333,
              "msFromStart": 245264.37392592602
            },
            {
              "lat": 43.77312185555555,
              "lng": -116.07854208888888,
              "alt": 2076.5444444444443,
              "speed2d": 10.33911111111111,
              "speed3d": 10.436666666666667,
              "msFromStart": 245759.6017777778
            },
            {
              "lat": 43.77312902222222,
              "lng": -116.07848718888889,
              "alt": 2076.3044444444445,
              "speed2d": 9.631333333333332,
              "speed3d": 9.71,
              "msFromStart": 246255.22133333338
            },
            {
              "lat": 43.773136211111115,
              "lng": -116.0784334,
              "alt": 2075.827666666667,
              "speed2d": 9.251111111111111,
              "speed3d": 9.29888888888889,
              "msFromStart": 246751.8204444445
            },
            {
              "lat": 43.77314475555555,
              "lng": -116.07837942222223,
              "alt": 2075.6435555555554,
              "speed2d": 9.111222222222223,
              "speed3d": 9.13888888888889,
              "msFromStart": 247248.6824483431
            },
            {
              "lat": 43.77315350000001,
              "lng": -116.07832445555555,
              "alt": 2075.362111111111,
              "speed2d": 9.130888888888888,
              "speed3d": 9.156666666666668,
              "msFromStart": 247743.68252631582
            },
            {
              "lat": 43.77316178888889,
              "lng": -116.07827487777777,
              "alt": 2075.315888888889,
              "speed2d": 8.710444444444443,
              "speed3d": 8.787777777777777,
              "msFromStart": 248237.99571669917
            },
            {
              "lat": 43.77316908888888,
              "lng": -116.0782265888889,
              "alt": 2074.4654444444445,
              "speed2d": 8.32611111111111,
              "speed3d": 8.36888888888889,
              "msFromStart": 248732.26133333333
            },
            {
              "lat": 43.77317517777777,
              "lng": -116.07817674444445,
              "alt": 2073.7155555555555,
              "speed2d": 8.197222222222223,
              "speed3d": 8.263333333333334,
              "msFromStart": 249226.5168395061
            },
            {
              "lat": 43.773178009999995,
              "lng": -116.07812507,
              "alt": 2073.2035,
              "speed2d": 8.003800000000002,
              "speed3d": 8.033,
              "msFromStart": 249749.10622222224
            },
            {
              "lat": 43.77317835555556,
              "lng": -116.07807463333333,
              "alt": 2072.5260000000003,
              "speed2d": 7.8100000000000005,
              "speed3d": 7.8966666666666665,
              "msFromStart": 250272.11081481486
            },
            {
              "lat": 43.773179922222226,
              "lng": -116.07802758888889,
              "alt": 2071.247,
              "speed2d": 7.762888888888889,
              "speed3d": 7.779999999999999,
              "msFromStart": 250767.22755555564
            },
            {
              "lat": 43.77318783333334,
              "lng": -116.07797918888889,
              "alt": 2070.289888888889,
              "speed2d": 7.907000000000001,
              "speed3d": 7.973333333333334,
              "msFromStart": 251262.23525925932
            },
            {
              "lat": 43.773198944444445,
              "lng": -116.07793398888887,
              "alt": 2069.8295555555555,
              "speed2d": 7.791666666666667,
              "speed3d": 7.8900000000000015,
              "msFromStart": 251757.6933333334
            },
            {
              "lat": 43.77320624444444,
              "lng": -116.07788907777778,
              "alt": 2068.8607777777775,
              "speed2d": 7.390000000000001,
              "speed3d": 7.542222222222222,
              "msFromStart": 252253.27674074087
            },
            {
              "lat": 43.77320622222223,
              "lng": -116.07784707777778,
              "alt": 2067.8496666666665,
              "speed2d": 6.701999999999999,
              "speed3d": 6.823333333333334,
              "msFromStart": 252748.0888888889
            },
            {
              "lat": 43.77320282222222,
              "lng": -116.07780405555556,
              "alt": 2067.1556666666665,
              "speed2d": 6.398888888888889,
              "speed3d": 6.5744444444444445,
              "msFromStart": 253242.6064405458
            },
            {
              "lat": 43.77320677777778,
              "lng": -116.07777794444445,
              "alt": 2065.853111111111,
              "speed2d": 5.395555555555555,
              "speed3d": 5.6033333333333335,
              "msFromStart": 253736.92547368418
            },
            {
              "lat": 43.77321719999999,
              "lng": -116.07774965555556,
              "alt": 2063.5133333333333,
              "speed2d": 5.619888888888889,
              "speed3d": 5.748888888888889,
              "msFromStart": 254231.18146848594
            },
            {
              "lat": 43.773226666666666,
              "lng": -116.07771199999999,
              "alt": 2061.9866666666667,
              "speed2d": 6.069111111111111,
              "speed3d": 6.225555555555555,
              "msFromStart": 254725.9626666666
            },
            {
              "lat": 43.77323757999999,
              "lng": -116.07766863,
              "alt": 2061.2494,
              "speed2d": 6.4641,
              "speed3d": 6.677,
              "msFromStart": 255248.52275555552
            },
            {
              "lat": 43.773239911111105,
              "lng": -116.07762377777779,
              "alt": 2060.189222222222,
              "speed2d": 6.212444444444444,
              "speed3d": 6.447777777777778,
              "msFromStart": 255770.9235555556
            },
            {
              "lat": 43.77323501111112,
              "lng": -116.07757885555554,
              "alt": 2059.5913333333333,
              "speed2d": 6.271,
              "speed3d": 6.441111111111112,
              "msFromStart": 256265.81185185193
            },
            {
              "lat": 43.773229322222214,
              "lng": -116.07753266666666,
              "alt": 2058.929888888889,
              "speed2d": 6.658333333333333,
              "speed3d": 6.803333333333333,
              "msFromStart": 256761.6177777777
            },
            {
              "lat": 43.77322983333333,
              "lng": -116.07748582222223,
              "alt": 2058.012111111111,
              "speed2d": 7.217444444444444,
              "speed3d": 7.3566666666666665,
              "msFromStart": 257257.759688109
            },
            {
              "lat": 43.773236411111114,
              "lng": -116.07744433333335,
              "alt": 2057.185111111111,
              "speed2d": 7.081444444444443,
              "speed3d": 7.335555555555555,
              "msFromStart": 257753.8618947369
            },
            {
              "lat": 43.77324576666667,
              "lng": -116.07740902222223,
              "alt": 2056.5553333333332,
              "speed2d": 6.398777777777777,
              "speed3d": 6.693333333333333,
              "msFromStart": 258249.92251591955
            },
            {
              "lat": 43.773249066666665,
              "lng": -116.07737384444445,
              "alt": 2055.5884444444446,
              "speed2d": 5.706555555555556,
              "speed3d": 6.014444444444443,
              "msFromStart": 258744.58133333328
            },
            {
              "lat": 43.77324557777778,
              "lng": -116.07733923333333,
              "alt": 2055.0697777777777,
              "speed2d": 5.226888888888889,
              "speed3d": 5.527777777777778,
              "msFromStart": 259238.50686419738
            },
            {
              "lat": 43.77324124444445,
              "lng": -116.0773047111111,
              "alt": 2054.7005555555556,
              "speed2d": 5.32888888888889,
              "speed3d": 5.517777777777778,
              "msFromStart": 259733.5519999999
            },
            {
              "lat": 43.773242166666655,
              "lng": -116.07727004444445,
              "alt": 2053.896777777778,
              "speed2d": 5.593111111111112,
              "speed3d": 5.811111111111111,
              "msFromStart": 260229.18903703688
            },
            {
              "lat": 43.77325072,
              "lng": -116.07723288,
              "alt": 2052.1297999999997,
              "speed2d": 6.035500000000001,
              "speed3d": 6.211,
              "msFromStart": 260751.74266666657
            },
            {
              "lat": 43.7732646,
              "lng": -116.07719623333334,
              "alt": 2050.785888888889,
              "speed2d": 6.332333333333334,
              "speed3d": 6.647777777777778,
              "msFromStart": 261274.00059259252
            },
            {
              "lat": 43.77327951111111,
              "lng": -116.07716605555555,
              "alt": 2050.2046666666665,
              "speed2d": 6.1242222222222225,
              "speed3d": 6.384444444444444,
              "msFromStart": 261768.98577777774
            },
            {
              "lat": 43.773293511111106,
              "lng": -116.07712875555553,
              "alt": 2049.4494444444445,
              "speed2d": 6.464444444444444,
              "speed3d": 6.687777777777778,
              "msFromStart": 262264.05596881086
            },
            {
              "lat": 43.77330382222222,
              "lng": -116.07708812222222,
              "alt": 2048.2426666666665,
              "speed2d": 6.418777777777777,
              "speed3d": 6.731111111111112,
              "msFromStart": 262759.2437894737
            },
            {
              "lat": 43.77330356666667,
              "lng": -116.07704825555555,
              "alt": 2046.6944444444446,
              "speed2d": 5.777111111111111,
              "speed3d": 6.157777777777778,
              "msFromStart": 263254.4743651722
            },
            {
              "lat": 43.773302722222226,
              "lng": -116.07700856666668,
              "alt": 2045.9973333333332,
              "speed2d": 5.838,
              "speed3d": 6.132222222222222,
              "msFromStart": 263749.67466666666
            },
            {
              "lat": 43.77330558888889,
              "lng": -116.07696635555556,
              "alt": 2045.0685555555556,
              "speed2d": 6.527,
              "speed3d": 6.71111111111111,
              "msFromStart": 264244.8327901234
            },
            {
              "lat": 43.77331415555555,
              "lng": -116.07691893333335,
              "alt": 2043.6337777777776,
              "speed2d": 7.538777777777778,
              "speed3d": 7.695555555555554,
              "msFromStart": 264738.64533333346
            },
            {
              "lat": 43.77332908888889,
              "lng": -116.0768666111111,
              "alt": 2041.702888888889,
              "speed2d": 8.707444444444443,
              "speed3d": 8.905555555555557,
              "msFromStart": 265231.7797530867
            },
            {
              "lat": 43.773348822222225,
              "lng": -116.07681552222223,
              "alt": 2040.0125555555553,
              "speed2d": 9.308555555555554,
              "speed3d": 9.54111111111111,
              "msFromStart": 265727.3280000001
            },
            {
              "lat": 43.773372540000004,
              "lng": -116.07675765,
              "alt": 2038.2087000000001,
              "speed2d": 10.0395,
              "speed3d": 10.296999999999999,
              "msFromStart": 266251.70320000016
            },
            {
              "lat": 43.773392433333335,
              "lng": -116.07669413333333,
              "alt": 2036.3705555555555,
              "speed2d": 10.383555555555557,
              "speed3d": 10.739999999999998,
              "msFromStart": 266774.46844444453
            },
            {
              "lat": 43.77340557777778,
              "lng": -116.07663244444446,
              "alt": 2034.841888888889,
              "speed2d": 10.186222222222222,
              "speed3d": 10.56111111111111,
              "msFromStart": 267269.06686939584
            },
            {
              "lat": 43.773411333333335,
              "lng": -116.07657145555555,
              "alt": 2033.218111111111,
              "speed2d": 9.892777777777777,
              "speed3d": 10.298888888888888,
              "msFromStart": 267763.93936842104
            },
            {
              "lat": 43.77341166666667,
              "lng": -116.0765117111111,
              "alt": 2031.7359999999999,
              "speed2d": 9.843222222222222,
              "speed3d": 10.247777777777777,
              "msFromStart": 268258.9075399609
            },
            {
              "lat": 43.77341524444445,
              "lng": -116.07644737777777,
              "alt": 2030.3142222222223,
              "speed2d": 10.115,
              "speed3d": 10.422222222222222,
              "msFromStart": 268753.60000000003
            },
            {
              "lat": 43.77342592222222,
              "lng": -116.07637775555554,
              "alt": 2028.8280000000002,
              "speed2d": 10.886333333333333,
              "speed3d": 11.246666666666666,
              "msFromStart": 269248.17520987673
            },
            {
              "lat": 43.77344221111112,
              "lng": -116.07632473333334,
              "alt": 2026.9964444444445,
              "speed2d": 10.424777777777777,
              "speed3d": 10.885555555555555,
              "msFromStart": 269744.37333333335
            },
            {
              "lat": 43.77345511111111,
              "lng": -116.07627225555557,
              "alt": 2025.014111111111,
              "speed2d": 9.78211111111111,
              "speed3d": 10.322222222222223,
              "msFromStart": 270241.4044444444
            },
            {
              "lat": 43.77346283333333,
              "lng": -116.0762118777778,
              "alt": 2023.4327777777776,
              "speed2d": 9.776444444444444,
              "speed3d": 10.234444444444444,
              "msFromStart": 270736.3093333332
            },
            {
              "lat": 43.773471233333325,
              "lng": -116.07615082222222,
              "alt": 2022.291666666667,
              "speed2d": 9.978333333333333,
              "speed3d": 10.426666666666666,
              "msFromStart": 271230.08098765416
            },
            {
              "lat": 43.77348148,
              "lng": -116.0760861,
              "alt": 2021.3059,
              "speed2d": 10.2622,
              "speed3d": 10.620999999999999,
              "msFromStart": 271751.94222222216
            },
            {
              "lat": 43.77348826666667,
              "lng": -116.07600824444445,
              "alt": 2020.7867777777774,
              "speed2d": 11.077,
              "speed3d": 11.327777777777778,
              "msFromStart": 272274.1434074073
            },
            {
              "lat": 43.773483866666666,
              "lng": -116.07592502222222,
              "alt": 2020.4226666666666,
              "speed2d": 11.63111111111111,
              "speed3d": 11.896666666666665,
              "msFromStart": 272769.1199999999
            },
            {
              "lat": 43.773472455555556,
              "lng": -116.07584571111113,
              "alt": 2019.991777777778,
              "speed2d": 11.944555555555555,
              "speed3d": 12.146666666666667,
              "msFromStart": 273264.19792592566
            },
            {
              "lat": 43.773461100000006,
              "lng": -116.07576733333333,
              "alt": 2019.2523333333336,
              "speed2d": 12.328444444444443,
              "speed3d": 12.523333333333332,
              "msFromStart": 273759.5839999999
            },
            {
              "lat": 43.7734541,
              "lng": -116.07568916666666,
              "alt": 2018.5161111111113,
              "speed2d": 12.586111111111112,
              "speed3d": 12.835555555555555,
              "msFromStart": 274255.13086419733
            },
            {
              "lat": 43.77344754444445,
              "lng": -116.07561364444445,
              "alt": 2017.6915555555556,
              "speed2d": 12.185444444444446,
              "speed3d": 12.386666666666667,
              "msFromStart": 274750.41066666663
            },
            {
              "lat": 43.77343302222222,
              "lng": -116.07553566666668,
              "alt": 2016.764777777778,
              "speed2d": 12.165444444444443,
              "speed3d": 12.432222222222222,
              "msFromStart": 275245.546074074
            },
            {
              "lat": 43.77341817777778,
              "lng": -116.07547257777777,
              "alt": 2016.0367777777776,
              "speed2d": 11.02211111111111,
              "speed3d": 11.387777777777778,
              "msFromStart": 275740.65066666657
            },
            {
              "lat": 43.77340905555556,
              "lng": -116.07541684444445,
              "alt": 2015.41,
              "speed2d": 10.044555555555558,
              "speed3d": 10.367777777777778,
              "msFromStart": 276235.74854320975
            },
            {
              "lat": 43.77340791111111,
              "lng": -116.07535852222222,
              "alt": 2014.318888888889,
              "speed2d": 9.876444444444443,
              "speed3d": 10.1,
              "msFromStart": 276731.3599999999
            },
            {
              "lat": 43.773413711111104,
              "lng": -116.07530506666667,
              "alt": 2012.5962222222222,
              "speed2d": 9.056444444444445,
              "speed3d": 9.557777777777778,
              "msFromStart": 277227.2467212475
            },
            {
              "lat": 43.77342493,
              "lng": -116.07526668,
              "alt": 2010.9919999999997,
              "speed2d": 7.290299999999999,
              "speed3d": 7.712999999999999,
              "msFromStart": 277750.6105263158
            },
            {
              "lat": 43.77343122222222,
              "lng": -116.07521219999998,
              "alt": 2009.9806666666666,
              "speed2d": 7.767111111111111,
              "speed3d": 8.137777777777778,
              "msFromStart": 278273.89457050036
            },
            {
              "lat": 43.77342667777778,
              "lng": -116.0751626888889,
              "alt": 2008.6033333333335,
              "speed2d": 7.302222222222222,
              "speed3d": 7.635555555555555,
              "msFromStart": 278767.3493333334
            },
            {
              "lat": 43.77341205555555,
              "lng": -116.07509895555555,
              "alt": 2007.5376666666666,
              "speed2d": 8.741333333333333,
              "speed3d": 8.943333333333333,
              "msFromStart": 279259.6543209877
            },
            {
              "lat": 43.77340032222222,
              "lng": -116.07504838888889,
              "alt": 2006.1154444444444,
              "speed2d": 8.554555555555554,
              "speed3d": 9.015555555555556,
              "msFromStart": 279756.1706666667
            },
            {
              "lat": 43.773405555555556,
              "lng": -116.07502803333333,
              "alt": 2003.8844444444444,
              "speed2d": 6.702111111111112,
              "speed3d": 7.282222222222222,
              "msFromStart": 280254.8847407409
            },
            {
              "lat": 43.77341074444445,
              "lng": -116.07497805555553,
              "alt": 2001.5700000000002,
              "speed2d": 7.968,
              "speed3d": 8.211111111111112,
              "msFromStart": 280749.9626666667
            },
            {
              "lat": 43.77341758888888,
              "lng": -116.0749098888889,
              "alt": 1999.7356666666665,
              "speed2d": 9.496222222222222,
              "speed3d": 9.803333333333333,
              "msFromStart": 281243.0884262509
            },
            {
              "lat": 43.77341923333333,
              "lng": -116.07483551111112,
              "alt": 1997.72,
              "speed2d": 10.256444444444444,
              "speed3d": 10.594444444444443,
              "msFromStart": 281736.5136842104
            },
            {
              "lat": 43.77341447777778,
              "lng": -116.07476073333335,
              "alt": 1995.1684444444443,
              "speed2d": 10.455666666666666,
              "speed3d": 10.968888888888888,
              "msFromStart": 282230.100210526
            },
            {
              "lat": 43.77339463,
              "lng": -116.07468646000001,
              "alt": 1992.5477,
              "speed2d": 10.413299999999998,
              "speed3d": 10.853,
              "msFromStart": 282751.70541176473
            },
            {
              "lat": 43.77337531111111,
              "lng": -116.07460710000001,
              "alt": 1990.7806666666668,
              "speed2d": 11.326777777777778,
              "speed3d": 11.682222222222222,
              "msFromStart": 283273.74748400424
            },
            {
              "lat": 43.77336472222222,
              "lng": -116.07452633333332,
              "alt": 1989.0473333333332,
              "speed2d": 12.225888888888889,
              "speed3d": 12.623333333333331,
              "msFromStart": 283768.362105263
            },
            {
              "lat": 43.77335965555556,
              "lng": -116.07444634444444,
              "alt": 1987.0163333333333,
              "speed2d": 12.400666666666666,
              "speed3d": 12.824444444444447,
              "msFromStart": 284263.00094087044
            },
            {
              "lat": 43.77335515555555,
              "lng": -116.07436670000003,
              "alt": 1984.8178888888888,
              "speed2d": 12.312888888888889,
              "speed3d": 12.828888888888889,
              "msFromStart": 284757.9733333334
            },
            {
              "lat": 43.773342400000004,
              "lng": -116.07429182222225,
              "alt": 1983.0373333333332,
              "speed2d": 11.784,
              "speed3d": 12.301111111111112,
              "msFromStart": 285253.1358024692
            },
            {
              "lat": 43.77332175555555,
              "lng": -116.07421842222222,
              "alt": 1981.7637777777777,
              "speed2d": 11.887555555555556,
              "speed3d": 12.343333333333334,
              "msFromStart": 285748.8426666667
            },
            {
              "lat": 43.77329963333332,
              "lng": -116.07414455555555,
              "alt": 1980.242777777778,
              "speed2d": 12.056111111111111,
              "speed3d": 12.454444444444444,
              "msFromStart": 286244.8379259261
            },
            {
              "lat": 43.77328506666667,
              "lng": -116.0740615,
              "alt": 1978.495888888889,
              "speed2d": 13.009888888888892,
              "speed3d": 13.187777777777775,
              "msFromStart": 286740.58666666667
            },
            {
              "lat": 43.77326931111111,
              "lng": -116.07394606666665,
              "alt": 1977.139777777778,
              "speed2d": 15.818444444444445,
              "speed3d": 15.956666666666667,
              "msFromStart": 287236.19950617285
            },
            {
              "lat": 43.77326165555555,
              "lng": -116.07385187777777,
              "alt": 1975.812222222222,
              "speed2d": 15.250555555555557,
              "speed3d": 15.855555555555556,
              "msFromStart": 287731.64799999987
            },
            {
              "lat": 43.773256188888894,
              "lng": -116.07378365555556,
              "alt": 1974.4275555555555,
              "speed2d": 13.000222222222222,
              "speed3d": 13.482222222222223,
              "msFromStart": 288226.98928654945
            },
            {
              "lat": 43.773232699999994,
              "lng": -116.07369239000002,
              "alt": 1971.5205,
              "speed2d": 13.1835,
              "speed3d": 13.582,
              "msFromStart": 288748.79326315783
            },
            {
              "lat": 43.77320531111111,
              "lng": -116.07362065555554,
              "alt": 1969.2072222222223,
              "speed2d": 12.219333333333335,
              "speed3d": 12.84888888888889,
              "msFromStart": 289270.0864457438
            },
            {
              "lat": 43.77318155555555,
              "lng": -116.07356581111111,
              "alt": 1967.8032222222223,
              "speed2d": 11.131444444444444,
              "speed3d": 11.658888888888889,
              "msFromStart": 289764.6293333334
            },
            {
              "lat": 43.773155377777776,
              "lng": -116.07348312222224,
              "alt": 1965.8738888888888,
              "speed2d": 12.821777777777777,
              "speed3d": 12.997777777777777,
              "msFromStart": 290259.54567901243
            },
            {
              "lat": 43.77313006666667,
              "lng": -116.07338413333335,
              "alt": 1963.6958888888892,
              "speed2d": 14.78188888888889,
              "speed3d": 15.093333333333334,
              "msFromStart": 290754.68799999985
            },
            {
              "lat": 43.77310969999999,
              "lng": -116.07330421111112,
              "alt": 1962.1027777777776,
              "speed2d": 14.114333333333335,
              "speed3d": 14.694444444444443,
              "msFromStart": 291249.95180246886
            },
            {
              "lat": 43.77308655555556,
              "lng": -116.07323044444445,
              "alt": 1960.4282222222223,
              "speed2d": 13.425555555555556,
              "speed3d": 13.885555555555555,
              "msFromStart": 291745.2053333334
            },
            {
              "lat": 43.77305609999999,
              "lng": -116.07315477777777,
              "alt": 1958.502888888889,
              "speed2d": 13.456666666666669,
              "speed3d": 13.886666666666667,
              "msFromStart": 292240.4647901236
            },
            {
              "lat": 43.773021433333334,
              "lng": -116.07307856666667,
              "alt": 1957.0845555555554,
              "speed2d": 13.868555555555554,
              "speed3d": 14.265555555555554,
              "msFromStart": 292736.31999999995
            },
            {
              "lat": 43.77299026666667,
              "lng": -116.07300275555558,
              "alt": 1955.7832222222225,
              "speed2d": 13.842555555555554,
              "speed3d": 14.338888888888889,
              "msFromStart": 293232.4687095516
            },
            {
              "lat": 43.77297034444444,
              "lng": -116.07293566666668,
              "alt": 1954.4375555555557,
              "speed2d": 12.819777777777778,
              "speed3d": 13.282222222222224,
              "msFromStart": 293727.19831578963
            },
            {
              "lat": 43.7729568,
              "lng": -116.07285183999998,
              "alt": 1953.8222,
              "speed2d": 13.2498,
              "speed3d": 13.574000000000002,
              "msFromStart": 294248.6125660822
            },
            {
              "lat": 43.77295154444445,
              "lng": -116.07276927777778,
              "alt": 1954.0367777777778,
              "speed2d": 13.148666666666667,
              "speed3d": 13.510000000000002,
              "msFromStart": 294770.38933333335
            },
            {
              "lat": 43.772952399999994,
              "lng": -116.07269637777776,
              "alt": 1955.5067777777776,
              "speed2d": 12.652222222222225,
              "speed3d": 12.897777777777778,
              "msFromStart": 295264.9197037037
            },
            {
              "lat": 43.7729572,
              "lng": -116.07262703333333,
              "alt": 1956.2764444444445,
              "speed2d": 11.878222222222224,
              "speed3d": 12.112222222222222,
              "msFromStart": 295759.5733333334
            },
            {
              "lat": 43.772965311111115,
              "lng": -116.07256626666666,
              "alt": 1956.1291111111111,
              "speed2d": 10.495000000000001,
              "speed3d": 10.795555555555554,
              "msFromStart": 296254.30617283954
            },
            {
              "lat": 43.77297212222222,
              "lng": -116.0725123,
              "alt": 1955.8781111111111,
              "speed2d": 9.038777777777776,
              "speed3d": 9.282222222222222,
              "msFromStart": 296749.7066666667
            },
            {
              "lat": 43.772975833333334,
              "lng": -116.07246137777776,
              "alt": 1955.6116666666667,
              "speed2d": 8.173222222222222,
              "speed3d": 8.356666666666667,
              "msFromStart": 297245.45204938285
            },
            {
              "lat": 43.772979666666664,
              "lng": -116.07241572222223,
              "alt": 1955.5984444444443,
              "speed2d": 7.451111111111111,
              "speed3d": 7.62,
              "msFromStart": 297740.4373333333
            },
            {
              "lat": 43.77298347777778,
              "lng": -116.07237231111112,
              "alt": 1955.2606666666666,
              "speed2d": 6.905666666666667,
              "speed3d": 7.035555555555555,
              "msFromStart": 298235.0209538661
            },
            {
              "lat": 43.77298763333333,
              "lng": -116.07232922222222,
              "alt": 1954.847,
              "speed2d": 6.6915555555555555,
              "speed3d": 6.798888888888889,
              "msFromStart": 298730.00084210536
            },
            {
              "lat": 43.77299166666667,
              "lng": -116.0722852111111,
              "alt": 1954.2257777777777,
              "speed2d": 6.623555555555555,
              "speed3d": 6.694444444444445,
              "msFromStart": 299225.1941052634
            },
            {
              "lat": 43.77299574999999,
              "lng": -116.0722368,
              "alt": 1953.6014,
              "speed2d": 6.8523,
              "speed3d": 6.893999999999999,
              "msFromStart": 299747.99022222235
            },
            {
              "lat": 43.7730003,
              "lng": -116.07218893333334,
              "alt": 1953.2633333333333,
              "speed2d": 6.959000000000001,
              "speed3d": 7.023333333333334,
              "msFromStart": 300270.862814815
            },
            {
              "lat": 43.77300445555556,
              "lng": -116.07214383333334,
              "alt": 1953.0075555555559,
              "speed2d": 6.954333333333333,
              "speed3d": 7.052222222222222,
              "msFromStart": 300766.7093333332
            },
            {
              "lat": 43.77300785555556,
              "lng": -116.07210155555556,
              "alt": 1952.9514444444444,
              "speed2d": 6.4304444444444435,
              "speed3d": 6.505555555555555,
              "msFromStart": 301262.79960493796
            },
            {
              "lat": 43.77301271111111,
              "lng": -116.07206152222221,
              "alt": 1952.8144444444442,
              "speed2d": 6.198888888888889,
              "speed3d": 6.269999999999999,
              "msFromStart": 301757.76
            },
            {
              "lat": 43.773017922222216,
              "lng": -116.07202342222223,
              "alt": 1952.7674444444442,
              "speed2d": 5.986111111111111,
              "speed3d": 6.025555555555556,
              "msFromStart": 302252.1214814816
            },
            {
              "lat": 43.77302193333333,
              "lng": -116.07198235555553,
              "alt": 1952.6165555555556,
              "speed2d": 5.9574444444444445,
              "speed3d": 5.996666666666667,
              "msFromStart": 302746.97599999985
            },
            {
              "lat": 43.773024766666666,
              "lng": -116.07194406666666,
              "alt": 1952.3443333333332,
              "speed2d": 5.625222222222222,
              "speed3d": 5.686666666666667,
              "msFromStart": 303242.1006471732
            },
            {
              "lat": 43.773026144444444,
              "lng": -116.07190386666667,
              "alt": 1952.0607777777775,
              "speed2d": 5.56,
              "speed3d": 5.584444444444445,
              "msFromStart": 303737.4669473684
            },
            {
              "lat": 43.77302654444444,
              "lng": -116.07186496666667,
              "alt": 1951.5961111111112,
              "speed2d": 5.368666666666668,
              "speed3d": 5.416666666666667,
              "msFromStart": 304232.963368421
            },
            {
              "lat": 43.7730257,
              "lng": -116.07182528888889,
              "alt": 1951.199333333333,
              "speed2d": 5.306888888888889,
              "speed3d": 5.326666666666666,
              "msFromStart": 304728.04622222215
            },
            {
              "lat": 43.773025360000005,
              "lng": -116.07178383000002,
              "alt": 1950.7272,
              "speed2d": 5.3769,
              "speed3d": 5.388,
              "msFromStart": 305250.2842666665
            },
            {
              "lat": 43.7730255,
              "lng": -116.07174204444443,
              "alt": 1950.0815555555555,
              "speed2d": 5.510666666666667,
              "speed3d": 5.546666666666667,
              "msFromStart": 305772.5120000002
            },
            {
              "lat": 43.77302666666667,
              "lng": -116.07170242222224,
              "alt": 1949.5852222222222,
              "speed2d": 5.568555555555555,
              "speed3d": 5.5811111111111105,
              "msFromStart": 306267.25155555585
            },
            {
              "lat": 43.7730291,
              "lng": -116.07166306666666,
              "alt": 1949.1127777777776,
              "speed2d": 5.649111111111112,
              "speed3d": 5.676666666666667,
              "msFromStart": 306762.17599999986
            },
            {
              "lat": 43.77303168888889,
              "lng": -116.07162351111113,
              "alt": 1948.6175555555556,
              "speed2d": 5.655333333333333,
              "speed3d": 5.697777777777778,
              "msFromStart": 307257.19091357995
            },
            {
              "lat": 43.773035400000005,
              "lng": -116.07158548888887,
              "alt": 1948.1654444444443,
              "speed2d": 5.631,
              "speed3d": 5.659999999999999,
              "msFromStart": 307751.7333333333
            },
            {
              "lat": 43.77303965555556,
              "lng": -116.0715466888889,
              "alt": 1947.558222222222,
              "speed2d": 5.703777777777778,
              "speed3d": 5.731111111111111,
              "msFromStart": 308246.03307082516
            },
            {
              "lat": 43.7730433,
              "lng": -116.0715062,
              "alt": 1947.009,
              "speed2d": 5.701333333333333,
              "speed3d": 5.742222222222223,
              "msFromStart": 308740.94315789465
            },
            {
              "lat": 43.77304875555555,
              "lng": -116.07146796666666,
              "alt": 1946.465111111111,
              "speed2d": 5.65888888888889,
              "speed3d": 5.702222222222222,
              "msFromStart": 309236.18189473666
            },
            {
              "lat": 43.77305475555555,
              "lng": -116.0714296111111,
              "alt": 1945.9094444444447,
              "speed2d": 5.684777777777779,
              "speed3d": 5.737777777777778,
              "msFromStart": 309730.94577777776
            },
            {
              "lat": 43.773061899999995,
              "lng": -116.07139267777775,
              "alt": 1945.3474444444444,
              "speed2d": 5.660666666666668,
              "speed3d": 5.696666666666667,
              "msFromStart": 310225.3297777777
            },
            {
              "lat": 43.773071550000004,
              "lng": -116.07135353999999,
              "alt": 1944.674,
              "speed2d": 5.8484,
              "speed3d": 5.876999999999999,
              "msFromStart": 310748.35288888874
            },
            {
              "lat": 43.7730789888889,
              "lng": -116.07130634444445,
              "alt": 1943.9232222222222,
              "speed2d": 6.084444444444444,
              "speed3d": 6.114444444444445,
              "msFromStart": 311272.213530864
            },
            {
              "lat": 43.773085755555556,
              "lng": -116.07126034444444,
              "alt": 1943.126888888889,
              "speed2d": 6.226999999999999,
              "speed3d": 6.2700000000000005,
              "msFromStart": 311767.6799999998
            },
            {
              "lat": 43.77309053333334,
              "lng": -116.07121025555556,
              "alt": 1942.2485555555554,
              "speed2d": 6.507333333333333,
              "speed3d": 6.528888888888888,
              "msFromStart": 312262.7130136449
            },
            {
              "lat": 43.77309505555556,
              "lng": -116.07116187777778,
              "alt": 1941.3504444444445,
              "speed2d": 6.640555555555556,
              "speed3d": 6.723333333333333,
              "msFromStart": 312758.21473684214
            },
            {
              "lat": 43.773098366666666,
              "lng": -116.07111756666664,
              "alt": 1940.686222222222,
              "speed2d": 6.568888888888889,
              "speed3d": 6.614444444444444,
              "msFromStart": 313253.96884210536
            },
            {
              "lat": 43.77309733333333,
              "lng": -116.07106956666667,
              "alt": 1939.7532222222223,
              "speed2d": 6.773555555555555,
              "speed3d": 6.86,
              "msFromStart": 313748.9084444445
            },
            {
              "lat": 43.77309057777778,
              "lng": -116.07102453333334,
              "alt": 1938.8724444444445,
              "speed2d": 6.598777777777777,
              "speed3d": 6.735555555555554,
              "msFromStart": 314243.1964444444
            },
            {
              "lat": 43.7730782,
              "lng": -116.07098673333331,
              "alt": 1937.8745555555556,
              "speed2d": 6.295444444444445,
              "speed3d": 6.42,
              "msFromStart": 314737.5822222221
            },
            {
              "lat": 43.77306001111111,
              "lng": -116.07095570000001,
              "alt": 1936.8966666666665,
              "speed2d": 6.062555555555554,
              "speed3d": 6.24,
              "msFromStart": 315232.046222222
            },
            {
              "lat": 43.77303851111111,
              "lng": -116.07093198888889,
              "alt": 1935.9486666666667,
              "speed2d": 5.910555555555556,
              "speed3d": 6.045555555555556,
              "msFromStart": 315726.8035555556
            },
            {
              "lat": 43.77301196999999,
              "lng": -116.07090824,
              "alt": 1935.0668,
              "speed2d": 6.1265,
              "speed3d": 6.269,
              "msFromStart": 316249.2977777777
            },
            {
              "lat": 43.77298622222222,
              "lng": -116.07088130000001,
              "alt": 1934.3638888888888,
              "speed2d": 6.224000000000001,
              "speed3d": 6.3533333333333335,
              "msFromStart": 316771.9466666667
            },
            {
              "lat": 43.772964988888894,
              "lng": -116.07084877777777,
              "alt": 1933.846111111111,
              "speed2d": 6.4162222222222205,
              "speed3d": 6.524444444444445,
              "msFromStart": 317267.1674074074
            },
            {
              "lat": 43.77294818888889,
              "lng": -116.07081126666667,
              "alt": 1933.5528888888891,
              "speed2d": 6.670444444444444,
              "speed3d": 6.768888888888889,
              "msFromStart": 317761.80266666657
            },
            {
              "lat": 43.77293395555556,
              "lng": -116.07077009999998,
              "alt": 1933.0072222222225,
              "speed2d": 6.974,
              "speed3d": 7.04,
              "msFromStart": 318256.131202079
            },
            {
              "lat": 43.77291798888889,
              "lng": -116.07072837777778,
              "alt": 1931.7757777777779,
              "speed2d": 7.185444444444444,
              "speed3d": 7.304444444444446,
              "msFromStart": 318750.9035789472
            },
            {
              "lat": 43.77289741111112,
              "lng": -116.07069115555556,
              "alt": 1930.4722222222224,
              "speed2d": 7.4272222222222215,
              "speed3d": 7.523333333333333,
              "msFromStart": 319245.9149473681
            },
            {
              "lat": 43.77287116666667,
              "lng": -116.07065926666665,
              "alt": 1928.8696666666665,
              "speed2d": 7.819666666666667,
              "speed3d": 7.93,
              "msFromStart": 319741.43999999994
            },
            {
              "lat": 43.77284134444444,
              "lng": -116.07062934444443,
              "alt": 1926.781777777778,
              "speed2d": 8.022888888888888,
              "speed3d": 8.206666666666667,
              "msFromStart": 320237.37599999993
            },
            {
              "lat": 43.77280962222221,
              "lng": -116.07059353333332,
              "alt": 1925.2772222222225,
              "speed2d": 8.397888888888888,
              "speed3d": 8.582222222222223,
              "msFromStart": 320733.00977777794
            },
            {
              "lat": 43.77278246666667,
              "lng": -116.0705515,
              "alt": 1923.9827777777778,
              "speed2d": 8.520555555555555,
              "speed3d": 8.741111111111111,
              "msFromStart": 321228.40177777805
            },
            {
              "lat": 43.77275787999999,
              "lng": -116.07050482999998,
              "alt": 1922.8576000000003,
              "speed2d": 8.574200000000001,
              "speed3d": 8.771,
              "msFromStart": 321750.2693333333
            },
            {
              "lat": 43.77273094444444,
              "lng": -116.07045855555555,
              "alt": 1921.2983333333332,
              "speed2d": 8.71811111111111,
              "speed3d": 8.943333333333333,
              "msFromStart": 322271.3957530864
            },
            {
              "lat": 43.77270091111111,
              "lng": -116.07042172222222,
              "alt": 1919.5923333333335,
              "speed2d": 9.084777777777777,
              "speed3d": 9.27,
              "msFromStart": 322766.12266666663
            }
          ]
        }
      ]
    },
    {
      "id": "trail-lower-paradise",
      "pathType": "trail",
      "name": "Lower Paradise",
      "difficulty": "blue",
      "videos": [
        {
          "videoId": "GH010029",
          "pathId": "trail-lower-paradise",
          "startSec": 323,
          "endSec": 398,
          "id": "video-segment-17",
          "gps": [
            {
              "lat": 43.77266676666667,
              "lng": -116.07038653333335,
              "alt": 1917.8501111111113,
              "speed2d": 9.420222222222222,
              "speed3d": 9.684444444444445,
              "msFromStart": 323261.39916309284
            },
            {
              "lat": 43.77263416666666,
              "lng": -116.07034770000001,
              "alt": 1916.743111111111,
              "speed2d": 9.398,
              "speed3d": 9.72111111111111,
              "msFromStart": 323756.49347368424
            },
            {
              "lat": 43.77260685555555,
              "lng": -116.0703052111111,
              "alt": 1915.769777777778,
              "speed2d": 9.208222222222222,
              "speed3d": 9.454444444444444,
              "msFromStart": 324251.4896842106
            },
            {
              "lat": 43.772577966666674,
              "lng": -116.07025987777777,
              "alt": 1914.379111111111,
              "speed2d": 9.455777777777778,
              "speed3d": 9.707777777777778,
              "msFromStart": 324746.5991111112
            },
            {
              "lat": 43.77254705555556,
              "lng": -116.07022088888887,
              "alt": 1912.2852222222223,
              "speed2d": 9.521222222222223,
              "speed3d": 9.853333333333335,
              "msFromStart": 325241.7991111113
            },
            {
              "lat": 43.77251296666667,
              "lng": -116.07019501111111,
              "alt": 1910.4431111111114,
              "speed2d": 9.253,
              "speed3d": 9.637777777777778,
              "msFromStart": 325736.9191111111
            },
            {
              "lat": 43.77247662222222,
              "lng": -116.07017920000001,
              "alt": 1908.9235555555558,
              "speed2d": 8.991000000000001,
              "speed3d": 9.415555555555555,
              "msFromStart": 326231.9751111112
            },
            {
              "lat": 43.772439911111114,
              "lng": -116.0701666,
              "alt": 1906.9355555555553,
              "speed2d": 8.675666666666668,
              "speed3d": 9.088888888888889,
              "msFromStart": 326726.8355555555
            },
            {
              "lat": 43.772397270000006,
              "lng": -116.07014506,
              "alt": 1905.3797000000004,
              "speed2d": 8.9527,
              "speed3d": 9.356,
              "msFromStart": 327249.0250105262
            },
            {
              "lat": 43.772356788888885,
              "lng": -116.07011657777778,
              "alt": 1903.8915555555554,
              "speed2d": 8.978555555555557,
              "speed3d": 9.367777777777778,
              "msFromStart": 327771.32463157905
            },
            {
              "lat": 43.77231876666667,
              "lng": -116.07008897777777,
              "alt": 1901.1491111111113,
              "speed2d": 9.205222222222222,
              "speed3d": 9.603333333333332,
              "msFromStart": 328266.19957894756
            },
            {
              "lat": 43.772277322222216,
              "lng": -116.07007027777777,
              "alt": 1898.0594444444446,
              "speed2d": 9.683444444444444,
              "speed3d": 10.152222222222223,
              "msFromStart": 328760.9351111111
            },
            {
              "lat": 43.77223367777778,
              "lng": -116.07006386666667,
              "alt": 1895.5062222222223,
              "speed2d": 9.974555555555558,
              "speed3d": 10.517777777777779,
              "msFromStart": 329255.5591111111
            },
            {
              "lat": 43.77219216666666,
              "lng": -116.07006030000001,
              "alt": 1893.3483333333334,
              "speed2d": 9.588666666666667,
              "speed3d": 10.26,
              "msFromStart": 329750.7875555556
            },
            {
              "lat": 43.77215601111111,
              "lng": -116.07005892222222,
              "alt": 1891.1973333333333,
              "speed2d": 8.771666666666667,
              "speed3d": 9.501111111111113,
              "msFromStart": 330246.49955555564
            },
            {
              "lat": 43.77211796666667,
              "lng": -116.07005486666665,
              "alt": 1889.2198888888888,
              "speed2d": 8.426555555555556,
              "speed3d": 9.126666666666669,
              "msFromStart": 330741.62488888885
            },
            {
              "lat": 43.77207503333334,
              "lng": -116.07005173333332,
              "alt": 1887.1248888888888,
              "speed2d": 8.876888888888889,
              "speed3d": 9.491111111111111,
              "msFromStart": 331236.28088888875
            },
            {
              "lat": 43.772031166666665,
              "lng": -116.07004731111111,
              "alt": 1884.474888888889,
              "speed2d": 9.23333333333333,
              "speed3d": 9.947777777777777,
              "msFromStart": 331731.3191111112
            },
            {
              "lat": 43.77198576666667,
              "lng": -116.0700338111111,
              "alt": 1881.899111111111,
              "speed2d": 9.650666666666668,
              "speed3d": 10.303333333333333,
              "msFromStart": 332226.6631111113
            },
            {
              "lat": 43.7719297,
              "lng": -116.07000534000002,
              "alt": 1878.7239000000002,
              "speed2d": 10.9987,
              "speed3d": 11.528,
              "msFromStart": 332749.1448888889
            },
            {
              "lat": 43.77186497777777,
              "lng": -116.06997203333334,
              "alt": 1875.2994444444446,
              "speed2d": 12.72888888888889,
              "speed3d": 13.264444444444445,
              "msFromStart": 333271.34731643926
            },
            {
              "lat": 43.77179894444445,
              "lng": -116.06994987777777,
              "alt": 1872.627222222222,
              "speed2d": 13.972888888888887,
              "speed3d": 14.510000000000002,
              "msFromStart": 333765.9604210527
            },
            {
              "lat": 43.77172742222222,
              "lng": -116.06994014444446,
              "alt": 1870.8506666666667,
              "speed2d": 14.98411111111111,
              "speed3d": 15.494444444444447,
              "msFromStart": 334260.5170526317
            },
            {
              "lat": 43.77165234444445,
              "lng": -116.06994007777777,
              "alt": 1869.8001111111112,
              "speed2d": 15.714333333333332,
              "speed3d": 16.20111111111111,
              "msFromStart": 334755.50222222216
            },
            {
              "lat": 43.77157736666667,
              "lng": -116.06994194444445,
              "alt": 1868.5911111111106,
              "speed2d": 15.887111111111114,
              "speed3d": 16.387777777777778,
              "msFromStart": 335250.83022222214
            },
            {
              "lat": 43.77150511111111,
              "lng": -116.06993552222222,
              "alt": 1867.0187777777778,
              "speed2d": 15.604888888888887,
              "speed3d": 16.167777777777776,
              "msFromStart": 335745.8026666668
            },
            {
              "lat": 43.771443488888885,
              "lng": -116.06991317777778,
              "alt": 1864.231,
              "speed2d": 14.398222222222222,
              "speed3d": 15.075555555555553,
              "msFromStart": 336240.49066666694
            },
            {
              "lat": 43.77138582222222,
              "lng": -116.06989923333333,
              "alt": 1860.7060000000001,
              "speed2d": 13.798555555555557,
              "speed3d": 14.48333333333333,
              "msFromStart": 336736.23644444445
            },
            {
              "lat": 43.77133017777778,
              "lng": -116.06989377777779,
              "alt": 1857.4594444444447,
              "speed2d": 13.270666666666667,
              "speed3d": 14.022222222222222,
              "msFromStart": 337232.82844444434
            },
            {
              "lat": 43.77127193333334,
              "lng": -116.06989246666667,
              "alt": 1855.275111111111,
              "speed2d": 12.967555555555554,
              "speed3d": 13.733333333333334,
              "msFromStart": 337728.66021052643
            },
            {
              "lat": 43.77120891,
              "lng": -116.06988107000002,
              "alt": 1852.708,
              "speed2d": 12.617700000000001,
              "speed3d": 13.431999999999999,
              "msFromStart": 338251.3962105266
            },
            {
              "lat": 43.77115408888889,
              "lng": -116.06985752222222,
              "alt": 1849.9426666666668,
              "speed2d": 11.523222222222222,
              "speed3d": 12.49888888888889,
              "msFromStart": 338774.33244444453
            },
            {
              "lat": 43.77111033333333,
              "lng": -116.06983200000002,
              "alt": 1847.2285555555554,
              "speed2d": 10.477222222222222,
              "speed3d": 11.476666666666667,
              "msFromStart": 339269.91644444474
            },
            {
              "lat": 43.77106634444445,
              "lng": -116.06980633333335,
              "alt": 1843.3919999999998,
              "speed2d": 10.581666666666667,
              "speed3d": 11.508888888888889,
              "msFromStart": 339764.2648888889
            },
            {
              "lat": 43.77101862222223,
              "lng": -116.06978332222224,
              "alt": 1838.2363333333335,
              "speed2d": 11.312666666666667,
              "speed3d": 12.305555555555555,
              "msFromStart": 340257.624888889
            },
            {
              "lat": 43.77096469999999,
              "lng": -116.06976844444443,
              "alt": 1835.0389999999998,
              "speed2d": 11.738555555555553,
              "speed3d": 12.806666666666667,
              "msFromStart": 340752.80711111106
            },
            {
              "lat": 43.77091587777778,
              "lng": -116.06976277777778,
              "alt": 1832.1325555555557,
              "speed2d": 11.038666666666666,
              "speed3d": 12.311111111111112,
              "msFromStart": 341249.44711111096
            },
            {
              "lat": 43.770877122222224,
              "lng": -116.06975695555556,
              "alt": 1827.4402222222222,
              "speed2d": 9.803222222222221,
              "speed3d": 11.354444444444445,
              "msFromStart": 341744.22044444457
            },
            {
              "lat": 43.770849688888894,
              "lng": -116.06973862222223,
              "alt": 1821.8682222222224,
              "speed2d": 8.259,
              "speed3d": 10.263333333333334,
              "msFromStart": 342237.50044444477
            },
            {
              "lat": 43.77082856666666,
              "lng": -116.06971313333332,
              "alt": 1815.814222222222,
              "speed2d": 7.481444444444443,
              "speed3d": 9.796666666666667,
              "msFromStart": 342731.4644210526
            },
            {
              "lat": 43.77080652222223,
              "lng": -116.06969074444446,
              "alt": 1809.503,
              "speed2d": 7.406000000000001,
              "speed3d": 10.024444444444443,
              "msFromStart": 343225.9755789472
            },
            {
              "lat": 43.770781940000006,
              "lng": -116.06967412000002,
              "alt": 1802.0768999999998,
              "speed2d": 7.444300000000001,
              "speed3d": 10.411000000000001,
              "msFromStart": 343748.4960000001
            },
            {
              "lat": 43.77075592222222,
              "lng": -116.06966550000001,
              "alt": 1794.4326666666666,
              "speed2d": 7.439444444444444,
              "speed3d": 10.783333333333333,
              "msFromStart": 344271.61244444473
            },
            {
              "lat": 43.770721244444445,
              "lng": -116.06966065555557,
              "alt": 1789.053666666667,
              "speed2d": 8.100777777777777,
              "speed3d": 11.365555555555556,
              "msFromStart": 344766.7697777778
            },
            {
              "lat": 43.77067715555555,
              "lng": -116.06965213333334,
              "alt": 1784.6105555555557,
              "speed2d": 9.048333333333334,
              "speed3d": 12.086666666666666,
              "msFromStart": 345261.58577777783
            },
            {
              "lat": 43.77062681111111,
              "lng": -116.06963362222223,
              "alt": 1780.682888888889,
              "speed2d": 10.147444444444444,
              "speed3d": 12.947777777777777,
              "msFromStart": 345756.8995555556
            },
            {
              "lat": 43.77057805555555,
              "lng": -116.0695985111111,
              "alt": 1776.8661111111112,
              "speed2d": 10.792666666666666,
              "speed3d": 13.483333333333334,
              "msFromStart": 346252.6115555556
            },
            {
              "lat": 43.77053087777779,
              "lng": -116.06955631111111,
              "alt": 1773.7894444444446,
              "speed2d": 11.277555555555555,
              "speed3d": 13.836666666666664,
              "msFromStart": 346747.94133333326
            },
            {
              "lat": 43.77048602222222,
              "lng": -116.06952065555555,
              "alt": 1770.3624444444445,
              "speed2d": 11.466888888888889,
              "speed3d": 13.95888888888889,
              "msFromStart": 347242.965333333
            },
            {
              "lat": 43.77044017777777,
              "lng": -116.06949326666668,
              "alt": 1766.197222222222,
              "speed2d": 11.873444444444445,
              "speed3d": 14.324444444444445,
              "msFromStart": 347737.3582222224
            },
            {
              "lat": 43.77039444444444,
              "lng": -116.0694754,
              "alt": 1762.0527777777777,
              "speed2d": 11.720333333333333,
              "speed3d": 14.221111111111112,
              "msFromStart": 348231.2462222226
            },
            {
              "lat": 43.770339711111106,
              "lng": -116.06945491111111,
              "alt": 1758.6573333333333,
              "speed2d": 12.032777777777776,
              "speed3d": 14.456666666666667,
              "msFromStart": 348725.8762105264
            },
            {
              "lat": 43.7702797,
              "lng": -116.06942864999999,
              "alt": 1754.6417000000001,
              "speed2d": 12.424499999999998,
              "speed3d": 14.782,
              "msFromStart": 349248.61221052654
            },
            {
              "lat": 43.77022284444444,
              "lng": -116.06939716666666,
              "alt": 1750.9013333333335,
              "speed2d": 12.655000000000001,
              "speed3d": 14.981111111111112,
              "msFromStart": 349770.92622222204
            },
            {
              "lat": 43.77017207777778,
              "lng": -116.06936805555557,
              "alt": 1746.9647777777777,
              "speed2d": 12.733333333333334,
              "speed3d": 14.996666666666668,
              "msFromStart": 350265.3902222219
            },
            {
              "lat": 43.77012077777778,
              "lng": -116.06934683333334,
              "alt": 1743.197222222222,
              "speed2d": 12.935333333333332,
              "speed3d": 15.187777777777779,
              "msFromStart": 350760.7342222223
            },
            {
              "lat": 43.770065688888884,
              "lng": -116.06933105555555,
              "alt": 1740.622888888889,
              "speed2d": 12.802666666666667,
              "speed3d": 14.99111111111111,
              "msFromStart": 351256.7822222224
            },
            {
              "lat": 43.77000702222222,
              "lng": -116.06931400000002,
              "alt": 1738.344,
              "speed2d": 12.60288888888889,
              "speed3d": 14.767777777777777,
              "msFromStart": 351752.7502222223
            },
            {
              "lat": 43.769949999999994,
              "lng": -116.06927977777778,
              "alt": 1736.7833333333333,
              "speed2d": 12.421888888888887,
              "speed3d": 14.49888888888889,
              "msFromStart": 352248.65422222234
            },
            {
              "lat": 43.76990872222222,
              "lng": -116.06924057777776,
              "alt": 1733.9656666666667,
              "speed2d": 11.943999999999999,
              "speed3d": 14.017777777777777,
              "msFromStart": 352743.4293333333
            },
            {
              "lat": 43.769875911111114,
              "lng": -116.0692018,
              "alt": 1730.344888888889,
              "speed2d": 11.540000000000001,
              "speed3d": 13.642222222222223,
              "msFromStart": 353237.3013333333
            },
            {
              "lat": 43.769841355555556,
              "lng": -116.06916869999998,
              "alt": 1726.7735555555555,
              "speed2d": 11.002777777777776,
              "speed3d": 13.190000000000001,
              "msFromStart": 353731.82315789454
            },
            {
              "lat": 43.76980256666667,
              "lng": -116.06914505555555,
              "alt": 1724.325111111111,
              "speed2d": 10.103666666666667,
              "speed3d": 12.446666666666667,
              "msFromStart": 354226.86484210484
            },
            {
              "lat": 43.769764949999995,
              "lng": -116.0691362,
              "alt": 1722.7844999999998,
              "speed2d": 8.299199999999999,
              "speed3d": 10.88,
              "msFromStart": 354750.04799999995
            },
            {
              "lat": 43.769731122222225,
              "lng": -116.06913394444445,
              "alt": 1720.929111111111,
              "speed2d": 7.170888888888889,
              "speed3d": 9.704444444444444,
              "msFromStart": 355273.9413333334
            },
            {
              "lat": 43.769687911111106,
              "lng": -116.06911925555556,
              "alt": 1720.1588888888887,
              "speed2d": 7.9049999999999985,
              "speed3d": 9.915555555555557,
              "msFromStart": 355770.0124444443
            },
            {
              "lat": 43.76963346666666,
              "lng": -116.06908703333335,
              "alt": 1720.6656666666668,
              "speed2d": 9.478333333333333,
              "speed3d": 10.86,
              "msFromStart": 356265.8844444441
            },
            {
              "lat": 43.769583655555564,
              "lng": -116.06904737777778,
              "alt": 1719.9451111111111,
              "speed2d": 10.37811111111111,
              "speed3d": 11.583333333333332,
              "msFromStart": 356761.2231111112
            },
            {
              "lat": 43.769535733333335,
              "lng": -116.06900947777777,
              "alt": 1718.8459999999998,
              "speed2d": 10.850444444444445,
              "speed3d": 11.847777777777777,
              "msFromStart": 357256.13511111116
            },
            {
              "lat": 43.769485833333334,
              "lng": -116.06897531111112,
              "alt": 1718.8967777777775,
              "speed2d": 11.106444444444445,
              "speed3d": 12.022222222222222,
              "msFromStart": 357750.59377777774
            },
            {
              "lat": 43.76944096666667,
              "lng": -116.06895641111112,
              "alt": 1718.6814444444444,
              "speed2d": 10.121333333333332,
              "speed3d": 11.113333333333333,
              "msFromStart": 358244.68977777765
            },
            {
              "lat": 43.76940135555556,
              "lng": -116.06895361111111,
              "alt": 1717.292222222222,
              "speed2d": 9.069777777777775,
              "speed3d": 10.004444444444445,
              "msFromStart": 358739.57221052644
            },
            {
              "lat": 43.76936214444444,
              "lng": -116.06895917777777,
              "alt": 1714.9794444444442,
              "speed2d": 8.785333333333332,
              "speed3d": 9.681111111111111,
              "msFromStart": 359235.08378947404
            },
            {
              "lat": 43.76932146666666,
              "lng": -116.06897206666667,
              "alt": 1712.0700000000002,
              "speed2d": 9.073222222222222,
              "speed3d": 9.96888888888889,
              "msFromStart": 359729.7671111112
            },
            {
              "lat": 43.76927592,
              "lng": -116.06898607999999,
              "alt": 1708.8929,
              "speed2d": 9.4699,
              "speed3d": 10.378000000000002,
              "msFromStart": 360250.84000000026
            },
            {
              "lat": 43.76923211111111,
              "lng": -116.06900417777777,
              "alt": 1706.4455555555555,
              "speed2d": 9.289111111111112,
              "speed3d": 10.315555555555555,
              "msFromStart": 360772.8106666665
            },
            {
              "lat": 43.76919993333333,
              "lng": -116.06902575555556,
              "alt": 1703.6416666666667,
              "speed2d": 8.33611111111111,
              "speed3d": 9.511111111111113,
              "msFromStart": 361268.07466666633
            },
            {
              "lat": 43.76914778888888,
              "lng": -116.06902666666666,
              "alt": 1704.0568888888888,
              "speed2d": 8.604111111111113,
              "speed3d": 9.423333333333332,
              "msFromStart": 361763.0008888887
            },
            {
              "lat": 43.769087988888884,
              "lng": -116.0690202222222,
              "alt": 1705.6992222222225,
              "speed2d": 9.213222222222223,
              "speed3d": 9.927777777777777,
              "msFromStart": 362257.6568888886
            },
            {
              "lat": 43.76905221111111,
              "lng": -116.06904123333332,
              "alt": 1703.9366666666665,
              "speed2d": 8.765777777777776,
              "speed3d": 9.444444444444445,
              "msFromStart": 362752.5617777779
            },
            {
              "lat": 43.76901825555556,
              "lng": -116.0690731888889,
              "alt": 1700.6850000000004,
              "speed2d": 8.940666666666665,
              "speed3d": 9.622222222222222,
              "msFromStart": 363247.6657777779
            },
            {
              "lat": 43.76898328888889,
              "lng": -116.06910965555554,
              "alt": 1697.5936666666666,
              "speed2d": 9.084222222222222,
              "speed3d": 9.866666666666667,
              "msFromStart": 363743.33305263147
            },
            {
              "lat": 43.768951577777784,
              "lng": -116.06913856666667,
              "alt": 1694.3856666666663,
              "speed2d": 8.68488888888889,
              "speed3d": 9.583333333333332,
              "msFromStart": 364239.45094736817
            },
            {
              "lat": 43.76891504444444,
              "lng": -116.06917055555556,
              "alt": 1690.735111111111,
              "speed2d": 9.073222222222224,
              "speed3d": 9.904444444444444,
              "msFromStart": 364734.3644444445
            },
            {
              "lat": 43.76886508888889,
              "lng": -116.06920264444443,
              "alt": 1688.7003333333332,
              "speed2d": 10.089000000000002,
              "speed3d": 10.827777777777776,
              "msFromStart": 365227.7724444446
            },
            {
              "lat": 43.76881962999999,
              "lng": -116.06923726,
              "alt": 1685.2773000000002,
              "speed2d": 10.3057,
              "speed3d": 11.254,
              "msFromStart": 365749.51999999984
            },
            {
              "lat": 43.76878576666666,
              "lng": -116.06926576666666,
              "alt": 1679.753,
              "speed2d": 9.738555555555557,
              "speed3d": 10.832222222222224,
              "msFromStart": 366272.2986666664
            },
            {
              "lat": 43.768749811111114,
              "lng": -116.06929294444446,
              "alt": 1675.0244444444445,
              "speed2d": 9.677666666666667,
              "speed3d": 10.875555555555557,
              "msFromStart": 366767.4648888889
            },
            {
              "lat": 43.76871526666666,
              "lng": -116.06931935555555,
              "alt": 1670.5076666666669,
              "speed2d": 9.533999999999999,
              "speed3d": 10.88,
              "msFromStart": 367262.5528888888
            },
            {
              "lat": 43.76868792222222,
              "lng": -116.06933957777778,
              "alt": 1664.746555555556,
              "speed2d": 9.07711111111111,
              "speed3d": 10.593333333333334,
              "msFromStart": 367757.3031111111
            },
            {
              "lat": 43.76864786666667,
              "lng": -116.06936042222222,
              "alt": 1661.5498888888887,
              "speed2d": 9.093333333333334,
              "speed3d": 10.588888888888889,
              "msFromStart": 368251.7831111111
            },
            {
              "lat": 43.76861075555556,
              "lng": -116.0693913888889,
              "alt": 1658.1253333333334,
              "speed2d": 9.127555555555556,
              "speed3d": 10.647777777777778,
              "msFromStart": 368747.05515789473
            },
            {
              "lat": 43.76858436666666,
              "lng": -116.06943302222223,
              "alt": 1652.8666666666666,
              "speed2d": 9.029777777777777,
              "speed3d": 10.671111111111113,
              "msFromStart": 369242.9608421052
            },
            {
              "lat": 43.768569488888886,
              "lng": -116.06947456666667,
              "alt": 1645.2965555555554,
              "speed2d": 8.754666666666667,
              "speed3d": 10.622222222222222,
              "msFromStart": 369738.9084444445
            },
            {
              "lat": 43.76855383333334,
              "lng": -116.06951173333331,
              "alt": 1637.528,
              "speed2d": 8.519555555555556,
              "speed3d": 10.627777777777776,
              "msFromStart": 370234.9084444446
            },
            {
              "lat": 43.768539011111116,
              "lng": -116.0695385,
              "alt": 1630.1886666666664,
              "speed2d": 8.116,
              "speed3d": 10.48,
              "msFromStart": 370730.7804444445
            },
            {
              "lat": 43.768523933333334,
              "lng": -116.06956661111111,
              "alt": 1622.9265555555558,
              "speed2d": 7.827222222222223,
              "speed3d": 10.397777777777776,
              "msFromStart": 371226.49244444456
            },
            {
              "lat": 43.768502010000006,
              "lng": -116.06959480999998,
              "alt": 1615.7758999999999,
              "speed2d": 7.6096,
              "speed3d": 10.361,
              "msFromStart": 371749.03199999995
            },
            {
              "lat": 43.7684785888889,
              "lng": -116.06962703333335,
              "alt": 1608.6564444444446,
              "speed2d": 7.505777777777777,
              "speed3d": 10.398888888888889,
              "msFromStart": 372270.7804444444
            },
            {
              "lat": 43.76845815555556,
              "lng": -116.06966316666666,
              "alt": 1602.0603333333331,
              "speed2d": 7.429777777777778,
              "speed3d": 10.450000000000003,
              "msFromStart": 372767.3173333333
            },
            {
              "lat": 43.76844066666668,
              "lng": -116.06970653333332,
              "alt": 1595.5726666666665,
              "speed2d": 7.356222222222223,
              "speed3d": 10.483333333333334,
              "msFromStart": 373265.6533333332
            },
            {
              "lat": 43.76842787777778,
              "lng": -116.06974365555556,
              "alt": 1589.0188888888888,
              "speed2d": 7.044777777777778,
              "speed3d": 10.383333333333333,
              "msFromStart": 373761.33155555563
            },
            {
              "lat": 43.76841929999999,
              "lng": -116.06977224444444,
              "alt": 1582.225888888889,
              "speed2d": 6.590222222222223,
              "speed3d": 10.16,
              "msFromStart": 374254.8835555557
            },
            {
              "lat": 43.76841568,
              "lng": -116.06980192,
              "alt": 1575.8084,
              "speed2d": 6.3496,
              "speed3d": 10.11,
              "msFromStart": 374671.4987789473
            },
            {
              "lat": 43.768397540164536,
              "lng": -116.06980999797744,
              "alt": 1573.4114138594823,
              "speed2d": 6.335278732854545,
              "speed3d": 10.107985896663518,
              "msFromStart": 375000
            },
            {
              "lat": 43.76839662971146,
              "lng": -116.06985807857097,
              "alt": 1565.588020621802,
              "speed2d": 6.268512173774314,
              "speed3d": 10.1860247319521,
              "msFromStart": 375500
            },
            {
              "lat": 43.768404361744054,
              "lng": -116.06991312576535,
              "alt": 1555.7719946698417,
              "speed2d": 6.167371580923212,
              "speed3d": 10.3172117315181,
              "msFromStart": 376000
            },
            {
              "lat": 43.768384683442754,
              "lng": -116.06994607782842,
              "alt": 1552.2757625953689,
              "speed2d": 6.17525098288244,
              "speed3d": 10.279835081198685,
              "msFromStart": 376500
            },
            {
              "lat": 43.76836500514145,
              "lng": -116.06997902989148,
              "alt": 1548.779530520896,
              "speed2d": 6.183130384841667,
              "speed3d": 10.242458430879271,
              "msFromStart": 377000
            },
            {
              "lat": 43.76834532684015,
              "lng": -116.07001198195455,
              "alt": 1545.2832984464233,
              "speed2d": 6.191009786800896,
              "speed3d": 10.205081780559857,
              "msFromStart": 377500
            },
            {
              "lat": 43.76832564853884,
              "lng": -116.07004493401762,
              "alt": 1541.7870663719505,
              "speed2d": 6.198889188760123,
              "speed3d": 10.167705130240442,
              "msFromStart": 378000
            },
            {
              "lat": 43.768305970237535,
              "lng": -116.07007788608068,
              "alt": 1538.2908342974777,
              "speed2d": 6.206768590719351,
              "speed3d": 10.130328479921028,
              "msFromStart": 378500
            },
            {
              "lat": 43.768286291936235,
              "lng": -116.07011083814375,
              "alt": 1534.7946022230049,
              "speed2d": 6.214647992678579,
              "speed3d": 10.092951829601613,
              "msFromStart": 379000
            },
            {
              "lat": 43.76826661363493,
              "lng": -116.07014379020681,
              "alt": 1531.298370148532,
              "speed2d": 6.222527394637806,
              "speed3d": 10.055575179282199,
              "msFromStart": 379500
            },
            {
              "lat": 43.76824693533363,
              "lng": -116.07017674226988,
              "alt": 1527.8021380740593,
              "speed2d": 6.230406796597034,
              "speed3d": 10.018198528962785,
              "msFromStart": 380000
            },
            {
              "lat": 43.76822725703232,
              "lng": -116.07020969433295,
              "alt": 1524.3059059995865,
              "speed2d": 6.2382861985562625,
              "speed3d": 9.98082187864337,
              "msFromStart": 380500
            },
            {
              "lat": 43.76819225,
              "lng": -116.0702623,
              "alt": 1518.927,
              "speed2d": 6.2684999999999995,
              "speed3d": 9.95,
              "msFromStart": 381317.31911111093
            },
            {
              "lat": 43.76817021666667,
              "lng": -116.07028196666666,
              "alt": 1517.3966666666665,
              "speed2d": 6.311833333333333,
              "speed3d": 9.965,
              "msFromStart": 381674.8426666668
            },
            {
              "lat": 43.76813751666667,
              "lng": -116.07030721666666,
              "alt": 1515.2276666666667,
              "speed2d": 6.226833333333333,
              "speed3d": 9.866666666666665,
              "msFromStart": 382197.6044444447
            },
            {
              "lat": 43.768120413086535,
              "lng": -116.07031749277716,
              "alt": 1513.9082115720664,
              "speed2d": 6.077205811921553,
              "speed3d": 9.730155329752117,
              "msFromStart": 382500
            },
            {
              "lat": 43.76807675,
              "lng": -116.07034025,
              "alt": 1510.33,
              "speed2d": 5.5425,
              "speed3d": 9.3,
              "msFromStart": 383296.8408888888
            },
            {
              "lat": 43.768046459999994,
              "lng": -116.0703522,
              "alt": 1508.9306000000001,
              "speed2d": 5.4556000000000004,
              "speed3d": 9.178,
              "msFromStart": 383730.6957473684
            },
            {
              "lat": 43.768012075,
              "lng": -116.070373525,
              "alt": 1506.4582500000001,
              "speed2d": 5.3607499999999995,
              "speed3d": 9.055,
              "msFromStart": 384312.67368421034
            },
            {
              "lat": 43.76799176000001,
              "lng": -116.07038548,
              "alt": 1505.0982000000001,
              "speed2d": 5.3424,
              "speed3d": 9.008,
              "msFromStart": 384642.3395555555
            },
            {
              "lat": 43.76796066,
              "lng": -116.07041429999998,
              "alt": 1501.5911999999998,
              "speed2d": 5.281000000000001,
              "speed3d": 8.922,
              "msFromStart": 385302.64888888877
            },
            {
              "lat": 43.7679357,
              "lng": -116.07043156666666,
              "alt": 1498.5546666666667,
              "speed2d": 5.133333333333334,
              "speed3d": 8.81,
              "msFromStart": 385852.23466666683
            },
            {
              "lat": 43.76793029364657,
              "lng": -116.07043844535231,
              "alt": 1497.8483767365067,
              "speed2d": 5.1013276286389715,
              "speed3d": 8.76982885916916,
              "msFromStart": 386000
            },
            {
              "lat": 43.7678826,
              "lng": -116.0704673,
              "alt": 1495.129,
              "speed2d": 5.0825,
              "speed3d": 8.665,
              "msFromStart": 386813.0693333334
            },
            {
              "lat": 43.7678663,
              "lng": -116.0704757,
              "alt": 1494.166,
              "speed2d": 4.979,
              "speed3d": 8.58,
              "msFromStart": 387114.93866666674
            },
            {
              "lat": 43.7678278,
              "lng": -116.07050135,
              "alt": 1491.1945,
              "speed2d": 5.0920000000000005,
              "speed3d": 8.56,
              "msFromStart": 387803.27377777774
            },
            {
              "lat": 43.76780805,
              "lng": -116.07051440000001,
              "alt": 1488.9699999999998,
              "speed2d": 5.138,
              "speed3d": 8.6,
              "msFromStart": 388190.35822222213
            },
            {
              "lat": 43.76778065,
              "lng": -116.07052974999999,
              "alt": 1486.6335,
              "speed2d": 5.19,
              "speed3d": 8.594999999999999,
              "msFromStart": 388686.9237894737
            },
            {
              "lat": 43.767769588956654,
              "lng": -116.07053953736968,
              "alt": 1485.5460981301433,
              "speed2d": 5.113038070306756,
              "speed3d": 8.523047524576565,
              "msFromStart": 389000
            },
            {
              "lat": 43.76775525496241,
              "lng": -116.07055718072856,
              "alt": 1484.0480920054322,
              "speed2d": 4.980861059302841,
              "speed3d": 8.399563769901757,
              "msFromStart": 389500
            },
            {
              "lat": 43.767740920968166,
              "lng": -116.07057482408742,
              "alt": 1482.5500858807213,
              "speed2d": 4.848684048298925,
              "speed3d": 8.276080015226949,
              "msFromStart": 390000
            },
            {
              "lat": 43.76772658697393,
              "lng": -116.07059246744629,
              "alt": 1481.0520797560102,
              "speed2d": 4.71650703729501,
              "speed3d": 8.152596260552139,
              "msFromStart": 390500
            },
            {
              "lat": 43.767712252979685,
              "lng": -116.07061011080516,
              "alt": 1479.5540736312992,
              "speed2d": 4.584330026291095,
              "speed3d": 8.02911250587733,
              "msFromStart": 391000
            },
            {
              "lat": 43.76769791898544,
              "lng": -116.07062775416402,
              "alt": 1478.056067506588,
              "speed2d": 4.4521530152871795,
              "speed3d": 7.905628751202522,
              "msFromStart": 391500
            },
            {
              "lat": 43.7676835849912,
              "lng": -116.0706453975229,
              "alt": 1476.558061381877,
              "speed2d": 4.319976004283264,
              "speed3d": 7.7821449965277125,
              "msFromStart": 392000
            },
            {
              "lat": 43.76766925099696,
              "lng": -116.07066304088177,
              "alt": 1475.0600552571661,
              "speed2d": 4.1877989932793485,
              "speed3d": 7.658661241852904,
              "msFromStart": 392500
            },
            {
              "lat": 43.767654917002716,
              "lng": -116.07068068424064,
              "alt": 1473.562049132455,
              "speed2d": 4.055621982275433,
              "speed3d": 7.535177487178095,
              "msFromStart": 393000
            },
            {
              "lat": 43.76763812924004,
              "lng": -116.07069805029195,
              "alt": 1472.1032857537273,
              "speed2d": 3.8992631603165813,
              "speed3d": 7.390250628872936,
              "msFromStart": 393500
            },
            {
              "lat": 43.76762013333333,
              "lng": -116.07072578333334,
              "alt": 1469.7633333333333,
              "speed2d": 3.7331666666666665,
              "speed3d": 7.246666666666666,
              "msFromStart": 394297.45347368444
            },
            {
              "lat": 43.7676084,
              "lng": -116.0707352,
              "alt": 1469.5675,
              "speed2d": 3.6005000000000003,
              "speed3d": 7.13,
              "msFromStart": 394682.15644444444
            },
            {
              "lat": 43.767595066666665,
              "lng": -116.07074373333334,
              "alt": 1470.2506666666666,
              "speed2d": 3.140333333333333,
              "speed3d": 6.813333333333334,
              "msFromStart": 395370.3786666667
            },
            {
              "lat": 43.76758696666667,
              "lng": -116.0707391,
              "alt": 1471.0916666666665,
              "speed2d": 2.7536666666666667,
              "speed3d": 6.559999999999999,
              "msFromStart": 395737.1632592593
            },
            {
              "lat": 43.76757787582179,
              "lng": -116.07073642123436,
              "alt": 1471.9472777151968,
              "speed2d": 2.5563168606061546,
              "speed3d": 6.419758217948817,
              "msFromStart": 396000
            },
            {
              "lat": 43.76755703283191,
              "lng": -116.0707327831852,
              "alt": 1474.3339895385877,
              "speed2d": 2.3592558653436613,
              "speed3d": 6.211328319113488,
              "msFromStart": 396500
            },
            {
              "lat": 43.767520975,
              "lng": -116.07072729999999,
              "alt": 1478.43575,
              "speed2d": 2.0407499999999996,
              "speed3d": 5.845000000000001,
              "msFromStart": 397377.270222222
            },
            {
              "lat": 43.767508157142856,
              "lng": -116.07072892857141,
              "alt": 1479.9402857142857,
              "speed2d": 2.0524285714285715,
              "speed3d": 5.747142857142857,
              "msFromStart": 397790.5368888891
            }
          ]
        }
      ]
    },
    {
      "id": "trail-mid-paradise-cut-off",
      "pathType": "trail",
      "name": "Mid Paradise Cut Off",
      "difficulty": "blue",
      "videos": [
        {
          "videoId": "GH010029",
          "pathId": "trail-mid-paradise-cut-off",
          "startSec": 111,
          "endSec": 221,
          "id": "video-segment-15",
          "gps": [
            {
              "lat": 43.77492256,
              "lng": -116.08872814,
              "alt": 2149.4296999999997,
              "speed2d": 8.2411,
              "speed3d": 8.468,
              "msFromStart": 111250.7177076023
            },
            {
              "lat": 43.77494462222222,
              "lng": -116.08867394444444,
              "alt": 2148.8417777777777,
              "speed2d": 8.522222222222222,
              "speed3d": 8.790000000000001,
              "msFromStart": 111773.0964210526
            },
            {
              "lat": 43.77496526666667,
              "lng": -116.0886232777778,
              "alt": 2148.2211111111114,
              "speed2d": 8.618222222222222,
              "speed3d": 8.855555555555554,
              "msFromStart": 112268.05338531511
            },
            {
              "lat": 43.77498246666666,
              "lng": -116.0885736111111,
              "alt": 2146.7664444444445,
              "speed2d": 8.523222222222222,
              "speed3d": 8.745555555555555,
              "msFromStart": 112763.57999999996
            },
            {
              "lat": 43.77500336666667,
              "lng": -116.0885210111111,
              "alt": 2146.016,
              "speed2d": 8.858777777777778,
              "speed3d": 9.078888888888889,
              "msFromStart": 113259.01135802467
            },
            {
              "lat": 43.77502423333333,
              "lng": -116.08846904444445,
              "alt": 2145.4096666666665,
              "speed2d": 8.840666666666667,
              "speed3d": 9.042222222222222,
              "msFromStart": 113753.29199999999
            },
            {
              "lat": 43.7750459,
              "lng": -116.0884181,
              "alt": 2144.6824444444446,
              "speed2d": 8.761666666666667,
              "speed3d": 8.966666666666669,
              "msFromStart": 114247.5237530864
            },
            {
              "lat": 43.77506844444444,
              "lng": -116.08836924444444,
              "alt": 2143.8271111111108,
              "speed2d": 8.802777777777777,
              "speed3d": 8.963333333333333,
              "msFromStart": 114742.608
            },
            {
              "lat": 43.77509214444444,
              "lng": -116.0883195888889,
              "alt": 2142.9045555555554,
              "speed2d": 9.030111111111111,
              "speed3d": 9.163333333333334,
              "msFromStart": 115237.80977777779
            },
            {
              "lat": 43.775115255555555,
              "lng": -116.08827059999999,
              "alt": 2141.519888888889,
              "speed2d": 9.241777777777777,
              "speed3d": 9.411111111111111,
              "msFromStart": 115732.87599999996
            },
            {
              "lat": 43.77513881111111,
              "lng": -116.08822404444446,
              "alt": 2140.5488888888885,
              "speed2d": 8.771444444444445,
              "speed3d": 9.002222222222223,
              "msFromStart": 116227.83737491874
            },
            {
              "lat": 43.77516407,
              "lng": -116.08817725,
              "alt": 2139.598,
              "speed2d": 8.5422,
              "speed3d": 8.726,
              "msFromStart": 116749.75999999998
            },
            {
              "lat": 43.77519025555556,
              "lng": -116.08813180000001,
              "alt": 2138.724222222222,
              "speed2d": 8.375777777777778,
              "speed3d": 8.55111111111111,
              "msFromStart": 117271.84361273555
            },
            {
              "lat": 43.77521743333333,
              "lng": -116.08808802222222,
              "alt": 2138.4775555555557,
              "speed2d": 8.495888888888889,
              "speed3d": 8.637777777777778,
              "msFromStart": 117767.94800000008
            },
            {
              "lat": 43.77524054444445,
              "lng": -116.08803438888889,
              "alt": 2137.9640000000004,
              "speed2d": 8.730333333333332,
              "speed3d": 8.843333333333334,
              "msFromStart": 118264.12069135808
            },
            {
              "lat": 43.77525977777778,
              "lng": -116.08797482222221,
              "alt": 2137.2341111111114,
              "speed2d": 8.662,
              "speed3d": 8.821111111111112,
              "msFromStart": 118759.22399999999
            },
            {
              "lat": 43.77527295555556,
              "lng": -116.08791792222223,
              "alt": 2135.9923333333336,
              "speed2d": 8.25211111111111,
              "speed3d": 8.416666666666668,
              "msFromStart": 119254.09125925925
            },
            {
              "lat": 43.77528212222222,
              "lng": -116.08786255555553,
              "alt": 2134.2344444444443,
              "speed2d": 7.877222222222223,
              "speed3d": 8.093333333333334,
              "msFromStart": 119748.58800000002
            },
            {
              "lat": 43.77529764444444,
              "lng": -116.08780562222223,
              "alt": 2132.9274444444445,
              "speed2d": 8.019444444444444,
              "speed3d": 8.126666666666665,
              "msFromStart": 120243.17066666667
            },
            {
              "lat": 43.77531556666667,
              "lng": -116.08775195555558,
              "alt": 2131.863444444444,
              "speed2d": 8.232222222222221,
              "speed3d": 8.373333333333335,
              "msFromStart": 120738.64799999999
            },
            {
              "lat": 43.775332311111114,
              "lng": -116.0876982888889,
              "alt": 2130.7747777777777,
              "speed2d": 8.349111111111112,
              "speed3d": 8.496666666666668,
              "msFromStart": 121234.19595061727
            },
            {
              "lat": 43.775348566666665,
              "lng": -116.08765015555555,
              "alt": 2129.694,
              "speed2d": 7.887444444444444,
              "speed3d": 8.127777777777776,
              "msFromStart": 121729.27999999996
            },
            {
              "lat": 43.77536373,
              "lng": -116.0875996,
              "alt": 2128.6842,
              "speed2d": 7.445900000000001,
              "speed3d": 7.606999999999999,
              "msFromStart": 122251.83953216372
            },
            {
              "lat": 43.775382699999994,
              "lng": -116.08754707777777,
              "alt": 2127.8758888888888,
              "speed2d": 7.521222222222224,
              "speed3d": 7.661111111111112,
              "msFromStart": 122774.63747368418
            },
            {
              "lat": 43.7753991888889,
              "lng": -116.0874977,
              "alt": 2127.249333333333,
              "speed2d": 7.463777777777778,
              "speed3d": 7.608888888888889,
              "msFromStart": 123269.82823131901
            },
            {
              "lat": 43.775415977777776,
              "lng": -116.08744398888888,
              "alt": 2126.843888888889,
              "speed2d": 7.834555555555557,
              "speed3d": 7.912222222222222,
              "msFromStart": 123764.26399999995
            },
            {
              "lat": 43.77543292222223,
              "lng": -116.08739094444445,
              "alt": 2126.751111111111,
              "speed2d": 7.719444444444444,
              "speed3d": 7.854444444444445,
              "msFromStart": 124258.61204938267
            },
            {
              "lat": 43.7754503888889,
              "lng": -116.08734378888889,
              "alt": 2126.858111111111,
              "speed2d": 7.482666666666667,
              "speed3d": 7.588888888888889,
              "msFromStart": 124753.18000000005
            },
            {
              "lat": 43.775468000000004,
              "lng": -116.08729565555555,
              "alt": 2126.7594444444444,
              "speed2d": 7.470666666666667,
              "speed3d": 7.552222222222222,
              "msFromStart": 125247.98153086424
            },
            {
              "lat": 43.77548651111111,
              "lng": -116.0872495888889,
              "alt": 2126.610777777778,
              "speed2d": 7.333777777777778,
              "speed3d": 7.405555555555556,
              "msFromStart": 125743.98800000003
            },
            {
              "lat": 43.77550524444444,
              "lng": -116.08720276666668,
              "alt": 2126.462777777778,
              "speed2d": 7.425555555555556,
              "speed3d": 7.467777777777778,
              "msFromStart": 126240.03792592594
            },
            {
              "lat": 43.77552692222223,
              "lng": -116.08715637777779,
              "alt": 2126.677222222222,
              "speed2d": 7.501333333333333,
              "speed3d": 7.539999999999999,
              "msFromStart": 126735.14800000003
            },
            {
              "lat": 43.77555491111111,
              "lng": -116.08711377777779,
              "alt": 2127.6764444444443,
              "speed2d": 7.821555555555556,
              "speed3d": 7.763333333333332,
              "msFromStart": 127230.08697595843
            },
            {
              "lat": 43.775581450000004,
              "lng": -116.08706717999999,
              "alt": 2128.5750000000003,
              "speed2d": 7.7152,
              "speed3d": 7.825,
              "msFromStart": 127752.41200000003
            },
            {
              "lat": 43.7755987,
              "lng": -116.08702594444443,
              "alt": 2128.5073333333335,
              "speed2d": 6.822444444444444,
              "speed3d": 6.913333333333333,
              "msFromStart": 128274.8624561404
            },
            {
              "lat": 43.77561372222222,
              "lng": -116.08698294444443,
              "alt": 2128.7914444444446,
              "speed2d": 6.271777777777778,
              "speed3d": 6.319999999999999,
              "msFromStart": 128770.6920000001
            },
            {
              "lat": 43.775626977777776,
              "lng": -116.08693756666668,
              "alt": 2129.045666666667,
              "speed2d": 6.095,
              "speed3d": 6.126666666666667,
              "msFromStart": 129266.45017283954
            },
            {
              "lat": 43.7756386,
              "lng": -116.08689238888887,
              "alt": 2129.4413333333337,
              "speed2d": 6.030555555555556,
              "speed3d": 6.025555555555556,
              "msFromStart": 129760.90399999995
            },
            {
              "lat": 43.775649200000004,
              "lng": -116.08684807777777,
              "alt": 2129.7451111111113,
              "speed2d": 5.990333333333333,
              "speed3d": 6.015555555555554,
              "msFromStart": 130255.11585185179
            },
            {
              "lat": 43.77566096666666,
              "lng": -116.08681157777778,
              "alt": 2130.116777777778,
              "speed2d": 5.636666666666665,
              "speed3d": 5.734444444444444,
              "msFromStart": 130749.15600000002
            },
            {
              "lat": 43.77567161111111,
              "lng": -116.08678144444444,
              "alt": 2130.4312222222225,
              "speed2d": 4.809,
              "speed3d": 4.837777777777778,
              "msFromStart": 131243.35032618587
            },
            {
              "lat": 43.77567637777778,
              "lng": -116.08674075555555,
              "alt": 2130.4705555555556,
              "speed2d": 5.070555555555556,
              "speed3d": 5.033333333333333,
              "msFromStart": 131738.65684210538
            },
            {
              "lat": 43.77568323333334,
              "lng": -116.08670181111111,
              "alt": 2130.847111111111,
              "speed2d": 5.3277777777777775,
              "speed3d": 5.316666666666666,
              "msFromStart": 132234.1435477584
            },
            {
              "lat": 43.775685599999996,
              "lng": -116.0866613888889,
              "alt": 2130.7096666666666,
              "speed2d": 5.543444444444445,
              "speed3d": 5.526666666666666,
              "msFromStart": 132729.6088888888
            },
            {
              "lat": 43.77568848,
              "lng": -116.08661796,
              "alt": 2130.8868,
              "speed2d": 5.7541,
              "speed3d": 5.762,
              "msFromStart": 133252.4379999999
            },
            {
              "lat": 43.7756883,
              "lng": -116.08657584444445,
              "alt": 2130.721,
              "speed2d": 5.870111111111112,
              "speed3d": 5.8500000000000005,
              "msFromStart": 133774.17199999993
            },
            {
              "lat": 43.77568695555556,
              "lng": -116.08653413333333,
              "alt": 2130.595222222222,
              "speed2d": 5.911333333333334,
              "speed3d": 5.909999999999999,
              "msFromStart": 134268.57017283945
            },
            {
              "lat": 43.77568363333334,
              "lng": -116.08648637777779,
              "alt": 2130.559111111111,
              "speed2d": 6.560666666666666,
              "speed3d": 6.44888888888889,
              "msFromStart": 134765.07999999993
            },
            {
              "lat": 43.77567221111111,
              "lng": -116.08642003333334,
              "alt": 2130.540111111111,
              "speed2d": 8.243777777777776,
              "speed3d": 8.11,
              "msFromStart": 135261.6219259259
            },
            {
              "lat": 43.77565794444444,
              "lng": -116.08635495555555,
              "alt": 2130.0735555555557,
              "speed2d": 9.147444444444444,
              "speed3d": 9.126666666666667,
              "msFromStart": 135756.24799999993
            },
            {
              "lat": 43.77564525555556,
              "lng": -116.08630661111111,
              "alt": 2129.7365555555557,
              "speed2d": 8.329444444444444,
              "speed3d": 8.54,
              "msFromStart": 136250.7127745289
            },
            {
              "lat": 43.77563292222222,
              "lng": -116.0862665888889,
              "alt": 2129.686,
              "speed2d": 7.440444444444445,
              "speed3d": 7.507777777777779,
              "msFromStart": 136746.10947368428
            },
            {
              "lat": 43.775619299999995,
              "lng": -116.0862279,
              "alt": 2129.594111111111,
              "speed2d": 6.911444444444443,
              "speed3d": 7.015555555555556,
              "msFromStart": 137241.63306042893
            },
            {
              "lat": 43.7756054,
              "lng": -116.08619172222222,
              "alt": 2129.8524444444442,
              "speed2d": 6.5571111111111104,
              "speed3d": 6.612222222222221,
              "msFromStart": 137736.89777777786
            },
            {
              "lat": 43.77559183333334,
              "lng": -116.0861615888889,
              "alt": 2129.7555555555555,
              "speed2d": 5.847777777777778,
              "speed3d": 5.972222222222222,
              "msFromStart": 138232.04977777787
            },
            {
              "lat": 43.77557726666667,
              "lng": -116.08613331111111,
              "alt": 2128.833888888889,
              "speed2d": 5.474333333333333,
              "speed3d": 5.495555555555556,
              "msFromStart": 138726.72177777777
            },
            {
              "lat": 43.77555781,
              "lng": -116.08609996000001,
              "alt": 2128.7425,
              "speed2d": 5.9235,
              "speed3d": 5.902,
              "msFromStart": 139248.89822222223
            },
            {
              "lat": 43.77553643333334,
              "lng": -116.08606926666667,
              "alt": 2128.5965555555554,
              "speed2d": 6.145888888888889,
              "speed3d": 6.185555555555556,
              "msFromStart": 139772.12
            },
            {
              "lat": 43.77551511111112,
              "lng": -116.08604054444443,
              "alt": 2128.7381111111113,
              "speed2d": 6.273555555555555,
              "speed3d": 6.281111111111111,
              "msFromStart": 140267.28414814817
            },
            {
              "lat": 43.775492444444446,
              "lng": -116.08601223333335,
              "alt": 2128.5287777777776,
              "speed2d": 6.3675555555555565,
              "speed3d": 6.387777777777779,
              "msFromStart": 140757.9840000001
            },
            {
              "lat": 43.77546954444445,
              "lng": -116.08598542222222,
              "alt": 2128.2606666666666,
              "speed2d": 6.1257777777777775,
              "speed3d": 6.210000000000001,
              "msFromStart": 141248.55031059135
            },
            {
              "lat": 43.77544806666666,
              "lng": -116.08595986666666,
              "alt": 2128.1591111111115,
              "speed2d": 5.665222222222223,
              "speed3d": 5.743333333333334,
              "msFromStart": 141742.7663157894
            },
            {
              "lat": 43.77542686666666,
              "lng": -116.08593280000001,
              "alt": 2127.8997777777777,
              "speed2d": 5.530444444444445,
              "speed3d": 5.557777777777778,
              "msFromStart": 142239.8353271413
            },
            {
              "lat": 43.77540583333333,
              "lng": -116.08590626666668,
              "alt": 2127.7443333333335,
              "speed2d": 5.554111111111112,
              "speed3d": 5.576666666666666,
              "msFromStart": 142759.45129411755
            },
            {
              "lat": 43.77538585,
              "lng": -116.08588243749999,
              "alt": 2127.7738750000003,
              "speed2d": 5.500125000000001,
              "speed3d": 5.569999999999999,
              "msFromStart": 143253.31223529403
            },
            {
              "lat": 43.77536692222222,
              "lng": -116.08585839999999,
              "alt": 2127.665888888889,
              "speed2d": 5.230777777777778,
              "speed3d": 5.242222222222221,
              "msFromStart": 143728.01066666664
            },
            {
              "lat": 43.77534782222222,
              "lng": -116.08583495555554,
              "alt": 2127.4101111111113,
              "speed2d": 5.130111111111111,
              "speed3d": 5.217777777777778,
              "msFromStart": 144226.49777777775
            },
            {
              "lat": 43.77532991,
              "lng": -116.08581823,
              "alt": 2126.4091,
              "speed2d": 4.524699999999999,
              "speed3d": 4.567,
              "msFromStart": 144750.1880000001
            },
            {
              "lat": 43.77530814444445,
              "lng": -116.08579156666667,
              "alt": 2126.404111111111,
              "speed2d": 4.943333333333333,
              "speed3d": 4.942222222222222,
              "msFromStart": 145273.47575308653
            },
            {
              "lat": 43.77528745555555,
              "lng": -116.08575787777777,
              "alt": 2126.5155555555557,
              "speed2d": 5.5872222222222225,
              "speed3d": 5.522222222222222,
              "msFromStart": 145770.32800000007
            },
            {
              "lat": 43.77526793333334,
              "lng": -116.08572656666666,
              "alt": 2126.4585555555554,
              "speed2d": 5.777444444444445,
              "speed3d": 5.828888888888889,
              "msFromStart": 146267.04888888894
            },
            {
              "lat": 43.77524871111111,
              "lng": -116.08569666666666,
              "alt": 2126.577333333333,
              "speed2d": 5.730555555555556,
              "speed3d": 5.782222222222223,
              "msFromStart": 146761.87200000006
            },
            {
              "lat": 43.77523354444445,
              "lng": -116.0856712111111,
              "alt": 2126.7355555555555,
              "speed2d": 5.062111111111111,
              "speed3d": 5.138888888888889,
              "msFromStart": 147256.3389837557
            },
            {
              "lat": 43.775218711111116,
              "lng": -116.08564413333332,
              "alt": 2126.6563333333334,
              "speed2d": 5.049666666666667,
              "speed3d": 5.053333333333334,
              "msFromStart": 147750.53136842092
            },
            {
              "lat": 43.77520553333333,
              "lng": -116.08561754444445,
              "alt": 2126.715888888889,
              "speed2d": 4.785555555555555,
              "speed3d": 4.867777777777777,
              "msFromStart": 148244.88293177373
            },
            {
              "lat": 43.77518898888889,
              "lng": -116.08559048888888,
              "alt": 2126.8936666666664,
              "speed2d": 4.887,
              "speed3d": 4.88,
              "msFromStart": 148741.27644444443
            },
            {
              "lat": 43.77517344444445,
              "lng": -116.08557307777778,
              "alt": 2127.025666666667,
              "speed2d": 4.411777777777777,
              "speed3d": 4.477777777777777,
              "msFromStart": 149237.90488888882
            },
            {
              "lat": 43.7751598,
              "lng": -116.08554804444444,
              "alt": 2127.3353333333334,
              "speed2d": 4.362222222222223,
              "speed3d": 4.373333333333333,
              "msFromStart": 149731.77777777775
            },
            {
              "lat": 43.775146420000006,
              "lng": -116.08552455,
              "alt": 2127.5832,
              "speed2d": 4.1804,
              "speed3d": 4.204000000000001,
              "msFromStart": 150252.35555555552
            },
            {
              "lat": 43.77513306666667,
              "lng": -116.08550376666668,
              "alt": 2127.2182222222223,
              "speed2d": 3.874111111111111,
              "speed3d": 3.9188888888888886,
              "msFromStart": 150772.89599999998
            },
            {
              "lat": 43.77512123333334,
              "lng": -116.085484,
              "alt": 2126.7992222222224,
              "speed2d": 3.6656666666666666,
              "speed3d": 3.717777777777778,
              "msFromStart": 151266.20083690705
            },
            {
              "lat": 43.775109844444444,
              "lng": -116.08546322222223,
              "alt": 2126.3975555555558,
              "speed2d": 3.624444444444444,
              "speed3d": 3.6199999999999997,
              "msFromStart": 151760.5355789474
            },
            {
              "lat": 43.77509672222222,
              "lng": -116.08544325555556,
              "alt": 2126.184222222222,
              "speed2d": 3.7031111111111112,
              "speed3d": 3.775555555555556,
              "msFromStart": 152255.08304093571
            },
            {
              "lat": 43.77508692222222,
              "lng": -116.08542484444445,
              "alt": 2125.8269999999998,
              "speed2d": 3.383444444444444,
              "speed3d": 3.4155555555555557,
              "msFromStart": 152750.06933333335
            },
            {
              "lat": 43.77507444444445,
              "lng": -116.0854047111111,
              "alt": 2125.572222222222,
              "speed2d": 3.5915555555555554,
              "speed3d": 3.608888888888889,
              "msFromStart": 153245.2571851852
            },
            {
              "lat": 43.7750639888889,
              "lng": -116.08538313333332,
              "alt": 2125.2026666666666,
              "speed2d": 3.5813333333333333,
              "speed3d": 3.6200000000000006,
              "msFromStart": 153741.36355555552
            },
            {
              "lat": 43.77505203333333,
              "lng": -116.0853618,
              "alt": 2125.237666666667,
              "speed2d": 3.7443333333333335,
              "speed3d": 3.7744444444444443,
              "msFromStart": 154237.61066666662
            },
            {
              "lat": 43.77504123333334,
              "lng": -116.0853438111111,
              "alt": 2125.345,
              "speed2d": 3.5308888888888887,
              "speed3d": 3.5755555555555563,
              "msFromStart": 154732.96888888883
            },
            {
              "lat": 43.775030066666666,
              "lng": -116.08532643333332,
              "alt": 2125.374555555556,
              "speed2d": 3.4066666666666667,
              "speed3d": 3.453333333333333,
              "msFromStart": 155228.07999999993
            },
            {
              "lat": 43.77502095999999,
              "lng": -116.08530868,
              "alt": 2125.6376,
              "speed2d": 3.0972,
              "speed3d": 3.1849999999999996,
              "msFromStart": 155750.431111111
            },
            {
              "lat": 43.77501221111112,
              "lng": -116.08528965555557,
              "alt": 2125.8416666666667,
              "speed2d": 3.0802222222222224,
              "speed3d": 3.063333333333334,
              "msFromStart": 156272.69556855093
            },
            {
              "lat": 43.77500161111111,
              "lng": -116.08527103333333,
              "alt": 2125.881666666666,
              "speed2d": 3.248666666666667,
              "speed3d": 3.2477777777777774,
              "msFromStart": 156767.32210526324
            },
            {
              "lat": 43.7749939,
              "lng": -116.08525273333332,
              "alt": 2125.8795555555553,
              "speed2d": 3.118666666666667,
              "speed3d": 3.152222222222222,
              "msFromStart": 157261.9554619884
            },
            {
              "lat": 43.77498434444445,
              "lng": -116.08523782222221,
              "alt": 2126.0376666666666,
              "speed2d": 3.1630000000000003,
              "speed3d": 3.1677777777777782,
              "msFromStart": 157756.90133333334
            },
            {
              "lat": 43.7749756111111,
              "lng": -116.08521905555556,
              "alt": 2125.9614444444446,
              "speed2d": 3.292,
              "speed3d": 3.263333333333333,
              "msFromStart": 158251.94725925926
            },
            {
              "lat": 43.77496478888888,
              "lng": -116.08520072222221,
              "alt": 2125.8566666666666,
              "speed2d": 3.4873333333333334,
              "speed3d": 3.477777777777778,
              "msFromStart": 158747.21244444436
            },
            {
              "lat": 43.77495394444444,
              "lng": -116.08518231111111,
              "alt": 2126.1973333333335,
              "speed2d": 3.532444444444444,
              "speed3d": 3.525555555555556,
              "msFromStart": 159242.5134814814
            },
            {
              "lat": 43.774942066666675,
              "lng": -116.0851586,
              "alt": 2126.964,
              "speed2d": 3.8194444444444446,
              "speed3d": 3.8033333333333337,
              "msFromStart": 159737.62488888885
            },
            {
              "lat": 43.7749299,
              "lng": -116.08513942222223,
              "alt": 2126.831,
              "speed2d": 3.7097777777777776,
              "speed3d": 3.7433333333333336,
              "msFromStart": 160232.67822222214
            },
            {
              "lat": 43.77491937777778,
              "lng": -116.0851200111111,
              "alt": 2126.4964444444445,
              "speed2d": 3.6085555555555553,
              "speed3d": 3.608888888888889,
              "msFromStart": 160727.6248888888
            },
            {
              "lat": 43.7749066,
              "lng": -116.08509965,
              "alt": 2126.512,
              "speed2d": 3.7188,
              "speed3d": 3.7039999999999997,
              "msFromStart": 161250.13564912268
            },
            {
              "lat": 43.77489716666666,
              "lng": -116.08507752222224,
              "alt": 2126.7625555555555,
              "speed2d": 3.505555555555555,
              "speed3d": 3.5911111111111116,
              "msFromStart": 161773.31284210517
            },
            {
              "lat": 43.774889,
              "lng": -116.0850648111111,
              "alt": 2126.2740000000003,
              "speed2d": 2.961,
              "speed3d": 2.946666666666667,
              "msFromStart": 162268.68014035077
            },
            {
              "lat": 43.77487915555555,
              "lng": -116.08504533333333,
              "alt": 2126.029111111111,
              "speed2d": 3.338,
              "speed3d": 3.2744444444444443,
              "msFromStart": 162759.95200000005
            },
            {
              "lat": 43.774868000000005,
              "lng": -116.08502402222221,
              "alt": 2125.7207777777776,
              "speed2d": 3.7268888888888894,
              "speed3d": 3.7511111111111113,
              "msFromStart": 163250.64207407413
            },
            {
              "lat": 43.77485911111112,
              "lng": -116.08500662222221,
              "alt": 2125.664777777778,
              "speed2d": 3.385222222222222,
              "speed3d": 3.4077777777777785,
              "msFromStart": 163745.75288888882
            },
            {
              "lat": 43.77484963333334,
              "lng": -116.08498747777779,
              "alt": 2125.475,
              "speed2d": 3.421333333333334,
              "speed3d": 3.416666666666667,
              "msFromStart": 164244.23959912843
            },
            {
              "lat": 43.77484043333333,
              "lng": -116.08496769999999,
              "alt": 2125.0177777777776,
              "speed2d": 3.4587777777777777,
              "speed3d": 3.518888888888889,
              "msFromStart": 164765.43341176462
            },
            {
              "lat": 43.7748331625,
              "lng": -116.0849535875,
              "alt": 2124.6622500000003,
              "speed2d": 2.9515000000000007,
              "speed3d": 2.97875,
              "msFromStart": 165260.39819607837
            },
            {
              "lat": 43.77482337777778,
              "lng": -116.0849334888889,
              "alt": 2124.3225555555555,
              "speed2d": 3.4274444444444443,
              "speed3d": 3.403333333333333,
              "msFromStart": 165732.5244444443
            },
            {
              "lat": 43.774813166666675,
              "lng": -116.08491725555555,
              "alt": 2123.3468888888888,
              "speed2d": 3.4252222222222217,
              "speed3d": 3.485555555555555,
              "msFromStart": 166227.7564444443
            },
            {
              "lat": 43.77480432,
              "lng": -116.08490159999998,
              "alt": 2122.9874,
              "speed2d": 3.0829000000000004,
              "speed3d": 3.1799999999999997,
              "msFromStart": 166750.3302222223
            },
            {
              "lat": 43.77479366666667,
              "lng": -116.08488291111112,
              "alt": 2122.671777777778,
              "speed2d": 3.3728888888888884,
              "speed3d": 3.3322222222222218,
              "msFromStart": 167272.95969850558
            },
            {
              "lat": 43.77477792222223,
              "lng": -116.08485756666667,
              "alt": 2122.6162222222224,
              "speed2d": 4.356888888888889,
              "speed3d": 4.311111111111111,
              "msFromStart": 167768.67115789466
            },
            {
              "lat": 43.77476366666667,
              "lng": -116.08483364444443,
              "alt": 2122.4744444444445,
              "speed2d": 4.561,
              "speed3d": 4.584444444444444,
              "msFromStart": 168264.43808187125
            },
            {
              "lat": 43.77475122222222,
              "lng": -116.0848126,
              "alt": 2122.2272222222223,
              "speed2d": 4.373555555555556,
              "speed3d": 4.414444444444444,
              "msFromStart": 168759.80266666674
            },
            {
              "lat": 43.77473612222222,
              "lng": -116.08478656666668,
              "alt": 2122.2909999999997,
              "speed2d": 4.754777777777777,
              "speed3d": 4.76,
              "msFromStart": 169255.0607407408
            },
            {
              "lat": 43.7747228,
              "lng": -116.08476336666666,
              "alt": 2121.750111111111,
              "speed2d": 4.6338888888888885,
              "speed3d": 4.609999999999999,
              "msFromStart": 169750.25955555553
            },
            {
              "lat": 43.77470815555555,
              "lng": -116.0847351888889,
              "alt": 2121.532111111111,
              "speed2d": 4.966444444444445,
              "speed3d": 5.002222222222222,
              "msFromStart": 170245.34518518514
            },
            {
              "lat": 43.77469213333333,
              "lng": -116.08470694444443,
              "alt": 2121.305888888889,
              "speed2d": 5.288,
              "speed3d": 5.262222222222222,
              "msFromStart": 170739.44711111122
            },
            {
              "lat": 43.77467574444445,
              "lng": -116.08467974444444,
              "alt": 2121.3855555555556,
              "speed2d": 5.309333333333334,
              "speed3d": 5.343333333333333,
              "msFromStart": 171233.38548148156
            },
            {
              "lat": 43.774661377777775,
              "lng": -116.08465434444443,
              "alt": 2121.1093333333333,
              "speed2d": 5.027555555555556,
              "speed3d": 5.096666666666667,
              "msFromStart": 171728.14755555545
            },
            {
              "lat": 43.77464513,
              "lng": -116.08462716,
              "alt": 2120.8875000000003,
              "speed2d": 5.1479,
              "speed3d": 5.128,
              "msFromStart": 172250.71712280696
            },
            {
              "lat": 43.77462815555556,
              "lng": -116.08460065555556,
              "alt": 2120.9084444444443,
              "speed2d": 5.105888888888889,
              "speed3d": 5.154444444444444,
              "msFromStart": 172774.0101052632
            },
            {
              "lat": 43.77461394444445,
              "lng": -116.0845775222222,
              "alt": 2120.358333333333,
              "speed2d": 4.893777777777778,
              "speed3d": 4.944444444444445,
              "msFromStart": 173269.50923976616
            },
            {
              "lat": 43.77460033333333,
              "lng": -116.08455636666666,
              "alt": 2119.771,
              "speed2d": 4.6737777777777785,
              "speed3d": 4.741111111111111,
              "msFromStart": 173761.01866666658
            },
            {
              "lat": 43.77458665555555,
              "lng": -116.0845349,
              "alt": 2119.1804444444447,
              "speed2d": 4.736555555555556,
              "speed3d": 4.760000000000001,
              "msFromStart": 174251.8322962962
            },
            {
              "lat": 43.77457315555556,
              "lng": -116.08451393333334,
              "alt": 2118.4556666666663,
              "speed2d": 4.650555555555556,
              "speed3d": 4.753333333333334,
              "msFromStart": 174745.6622222223
            },
            {
              "lat": 43.77455988888889,
              "lng": -116.08449661111109,
              "alt": 2117.091,
              "speed2d": 4.469333333333333,
              "speed3d": 4.561111111111111,
              "msFromStart": 175242.45460566456
            },
            {
              "lat": 43.77454485555555,
              "lng": -116.08447589999999,
              "alt": 2116.1494444444443,
              "speed2d": 4.64911111111111,
              "speed3d": 4.745555555555556,
              "msFromStart": 175761.33082352934
            },
            {
              "lat": 43.7745307,
              "lng": -116.08445208750001,
              "alt": 2115.6485000000002,
              "speed2d": 4.835125,
              "speed3d": 4.963749999999999,
              "msFromStart": 176254.36039215678
            },
            {
              "lat": 43.77451484444444,
              "lng": -116.08442514444444,
              "alt": 2115.5184444444444,
              "speed2d": 5.195666666666667,
              "speed3d": 5.265555555555556,
              "msFromStart": 176728.02488888882
            },
            {
              "lat": 43.774497444444435,
              "lng": -116.0844012,
              "alt": 2115.1808888888886,
              "speed2d": 5.322222222222221,
              "speed3d": 5.424444444444444,
              "msFromStart": 177225.45425341127
            },
            {
              "lat": 43.77447901,
              "lng": -116.08437719,
              "alt": 2114.8450000000003,
              "speed2d": 5.270600000000001,
              "speed3d": 5.34,
              "msFromStart": 177748.86484210525
            },
            {
              "lat": 43.77445945555556,
              "lng": -116.08435320000001,
              "alt": 2114.0396666666666,
              "speed2d": 5.471222222222224,
              "speed3d": 5.596666666666667,
              "msFromStart": 178271.90658869394
            },
            {
              "lat": 43.7744448,
              "lng": -116.08433469999999,
              "alt": 2113.0788888888887,
              "speed2d": 4.9401111111111105,
              "speed3d": 5.13111111111111,
              "msFromStart": 178767.59822222227
            },
            {
              "lat": 43.77443077777778,
              "lng": -116.08431912222221,
              "alt": 2112.3104444444443,
              "speed2d": 4.534777777777777,
              "speed3d": 4.70888888888889,
              "msFromStart": 179263.23822222228
            },
            {
              "lat": 43.77441818888889,
              "lng": -116.08430434444445,
              "alt": 2111.623777777778,
              "speed2d": 4.124999999999999,
              "speed3d": 4.335555555555556,
              "msFromStart": 179757.91822222227
            },
            {
              "lat": 43.77440606666667,
              "lng": -116.08428796666666,
              "alt": 2110.8605555555555,
              "speed2d": 3.950222222222222,
              "speed3d": 4.177777777777778,
              "msFromStart": 180252.39733333339
            },
            {
              "lat": 43.77439423333334,
              "lng": -116.08427272222221,
              "alt": 2110.045777777778,
              "speed2d": 3.8762222222222222,
              "speed3d": 4.062222222222222,
              "msFromStart": 180747.2675555555
            },
            {
              "lat": 43.774381733333335,
              "lng": -116.08425803333333,
              "alt": 2109.2436666666663,
              "speed2d": 3.804111111111111,
              "speed3d": 4.044444444444444,
              "msFromStart": 181242.30014814803
            },
            {
              "lat": 43.7743703,
              "lng": -116.08424348888889,
              "alt": 2108.0655555555554,
              "speed2d": 3.7461111111111114,
              "speed3d": 3.991111111111111,
              "msFromStart": 181737.97866666655
            },
            {
              "lat": 43.77435817777778,
              "lng": -116.08422894444445,
              "alt": 2107.0275555555554,
              "speed2d": 3.8153333333333332,
              "speed3d": 4.071111111111111,
              "msFromStart": 182233.7685146198
            },
            {
              "lat": 43.77434468888889,
              "lng": -116.08421521111111,
              "alt": 2105.668,
              "speed2d": 4.040111111111111,
              "speed3d": 4.311111111111112,
              "msFromStart": 182729.0568421052
            },
            {
              "lat": 43.77432784,
              "lng": -116.08419576,
              "alt": 2104.7515999999996,
              "speed2d": 4.5158000000000005,
              "speed3d": 4.741,
              "msFromStart": 183251.70891228065
            },
            {
              "lat": 43.77431125555556,
              "lng": -116.08417475555555,
              "alt": 2104.0312222222224,
              "speed2d": 4.778888888888888,
              "speed3d": 4.995555555555556,
              "msFromStart": 183774.13511111116
            },
            {
              "lat": 43.774292533333345,
              "lng": -116.08414474444444,
              "alt": 2104.611888888889,
              "speed2d": 5.635222222222222,
              "speed3d": 5.685555555555555,
              "msFromStart": 184268.96296296298
            },
            {
              "lat": 43.77427146666667,
              "lng": -116.08410848888887,
              "alt": 2106.167888888889,
              "speed2d": 6.606333333333334,
              "speed3d": 6.594444444444445,
              "msFromStart": 184763.42933333322
            },
            {
              "lat": 43.77424794444444,
              "lng": -116.08407431111111,
              "alt": 2106.7552222222225,
              "speed2d": 7.195666666666667,
              "speed3d": 7.192222222222222,
              "msFromStart": 185257.85629629617
            },
            {
              "lat": 43.77422437777778,
              "lng": -116.08403914444445,
              "alt": 2107.2334444444446,
              "speed2d": 7.583777777777779,
              "speed3d": 7.55,
              "msFromStart": 185752.79288888883
            },
            {
              "lat": 43.77420073333333,
              "lng": -116.08399995555556,
              "alt": 2107.7375555555554,
              "speed2d": 8.051333333333334,
              "speed3d": 7.982222222222221,
              "msFromStart": 186247.84325925919
            },
            {
              "lat": 43.77417386666667,
              "lng": -116.08395487777777,
              "alt": 2107.9485555555552,
              "speed2d": 8.910111111111112,
              "speed3d": 8.877777777777778,
              "msFromStart": 186742.7573333333
            },
            {
              "lat": 43.774151066666676,
              "lng": -116.08391217777778,
              "alt": 2108.640777777778,
              "speed2d": 8.663222222222222,
              "speed3d": 8.688888888888888,
              "msFromStart": 187237.67232748534
            },
            {
              "lat": 43.774128544444444,
              "lng": -116.0838705111111,
              "alt": 2109.0783333333334,
              "speed2d": 8.52,
              "speed3d": 8.55888888888889,
              "msFromStart": 187732.93726315786
            },
            {
              "lat": 43.77410804444444,
              "lng": -116.08383483333334,
              "alt": 2109.2825555555555,
              "speed2d": 8.007888888888889,
              "speed3d": 8.082222222222223,
              "msFromStart": 188228.25381676407
            },
            {
              "lat": 43.77408671,
              "lng": -116.08379865999999,
              "alt": 2109.2477,
              "speed2d": 7.616000000000001,
              "speed3d": 7.618,
              "msFromStart": 188750.3546666666
            },
            {
              "lat": 43.77406885555556,
              "lng": -116.08375521111111,
              "alt": 2109.5922222222225,
              "speed2d": 7.557555555555556,
              "speed3d": 7.568888888888889,
              "msFromStart": 189272.2435555555
            },
            {
              "lat": 43.77404697777777,
              "lng": -116.08372952222223,
              "alt": 2108.827333333333,
              "speed2d": 7.005000000000001,
              "speed3d": 7.102222222222222,
              "msFromStart": 189767.05777777784
            },
            {
              "lat": 43.77402562222222,
              "lng": -116.08371374444444,
              "alt": 2108.041555555555,
              "speed2d": 6.183222222222223,
              "speed3d": 6.271111111111111,
              "msFromStart": 190262.09896296306
            },
            {
              "lat": 43.774008688888884,
              "lng": -116.08368200000001,
              "alt": 2107.978,
              "speed2d": 6.051222222222223,
              "speed3d": 6.016666666666666,
              "msFromStart": 190758.4320000001
            },
            {
              "lat": 43.77398886666667,
              "lng": -116.0836468888889,
              "alt": 2108.0861111111108,
              "speed2d": 6.458666666666668,
              "speed3d": 6.444444444444445,
              "msFromStart": 191254.98725925933
            },
            {
              "lat": 43.77396872222223,
              "lng": -116.08361126666667,
              "alt": 2108.103666666667,
              "speed2d": 6.803444444444445,
              "speed3d": 6.754444444444445,
              "msFromStart": 191750.535111111
            },
            {
              "lat": 43.77394818888889,
              "lng": -116.08357774444444,
              "alt": 2107.8893333333335,
              "speed2d": 6.90411111111111,
              "speed3d": 6.92,
              "msFromStart": 192245.84315009732
            },
            {
              "lat": 43.77392711111111,
              "lng": -116.08354626666667,
              "alt": 2107.595888888889,
              "speed2d": 6.884222222222222,
              "speed3d": 6.884444444444444,
              "msFromStart": 192741.27157894726
            },
            {
              "lat": 43.77390611111111,
              "lng": -116.08351642222222,
              "alt": 2107.4956666666667,
              "speed2d": 6.722777777777778,
              "speed3d": 6.796666666666666,
              "msFromStart": 193236.79460428836
            },
            {
              "lat": 43.773887333333334,
              "lng": -116.0834901888889,
              "alt": 2107.161777777778,
              "speed2d": 6.2733333333333325,
              "speed3d": 6.318888888888889,
              "msFromStart": 193733.54311111118
            },
            {
              "lat": 43.77386574444444,
              "lng": -116.08345928888887,
              "alt": 2107.485888888889,
              "speed2d": 6.374777777777777,
              "speed3d": 6.355555555555555,
              "msFromStart": 194230.59822222235
            },
            {
              "lat": 43.77384485,
              "lng": -116.08343152000002,
              "alt": 2107.4377,
              "speed2d": 6.2384,
              "speed3d": 6.294999999999999,
              "msFromStart": 194752.30533333338
            },
            {
              "lat": 43.77382361111111,
              "lng": -116.08340302222223,
              "alt": 2106.8434444444447,
              "speed2d": 6.267666666666667,
              "speed3d": 6.234444444444445,
              "msFromStart": 195272.986962963
            },
            {
              "lat": 43.77380253333334,
              "lng": -116.08337358888888,
              "alt": 2106.274222222222,
              "speed2d": 6.444222222222223,
              "speed3d": 6.475555555555555,
              "msFromStart": 195766.15111111107
            },
            {
              "lat": 43.77378293333333,
              "lng": -116.0833491111111,
              "alt": 2105.124222222222,
              "speed2d": 6.259555555555555,
              "speed3d": 6.31,
              "msFromStart": 196259.39140740738
            },
            {
              "lat": 43.7737624,
              "lng": -116.08332291111111,
              "alt": 2104.101,
              "speed2d": 6.304,
              "speed3d": 6.358888888888888,
              "msFromStart": 196753.67466666654
            },
            {
              "lat": 43.77374252222223,
              "lng": -116.08329224444444,
              "alt": 2104.065777777778,
              "speed2d": 6.3903333333333325,
              "speed3d": 6.410000000000001,
              "msFromStart": 197248.30212865488
            },
            {
              "lat": 43.773722355555556,
              "lng": -116.08326236666667,
              "alt": 2103.676111111111,
              "speed2d": 6.294666666666667,
              "speed3d": 6.405555555555555,
              "msFromStart": 197743.7642105263
            },
            {
              "lat": 43.77370267777777,
              "lng": -116.08323282222221,
              "alt": 2103.474111111111,
              "speed2d": 5.966555555555555,
              "speed3d": 6.026666666666667,
              "msFromStart": 198239.429957115
            },
            {
              "lat": 43.77368426666666,
              "lng": -116.08320427777777,
              "alt": 2103.1187777777777,
              "speed2d": 5.6721111111111115,
              "speed3d": 5.797777777777778,
              "msFromStart": 198735.00088888887
            },
            {
              "lat": 43.77366653333333,
              "lng": -116.08317653333333,
              "alt": 2102.928,
              "speed2d": 5.307111111111111,
              "speed3d": 5.414444444444444,
              "msFromStart": 199230.53748148144
            },
            {
              "lat": 43.7736481,
              "lng": -116.08314987777779,
              "alt": 2102.775333333333,
              "speed2d": 5.181888888888889,
              "speed3d": 5.243333333333332,
              "msFromStart": 199726.0853333334
            },
            {
              "lat": 43.77362816,
              "lng": -116.08312154999999,
              "alt": 2102.6158000000005,
              "speed2d": 5.126899999999999,
              "speed3d": 5.243,
              "msFromStart": 200249.14880000014
            },
            {
              "lat": 43.77361012222222,
              "lng": -116.08309822222222,
              "alt": 2102.0801111111114,
              "speed2d": 4.899111111111112,
              "speed3d": 4.997777777777777,
              "msFromStart": 200771.99466666675
            },
            {
              "lat": 43.773592333333326,
              "lng": -116.08307797777778,
              "alt": 2101.5044444444443,
              "speed2d": 4.832888888888888,
              "speed3d": 4.953333333333334,
              "msFromStart": 201267.30311111116
            },
            {
              "lat": 43.773574144444446,
              "lng": -116.08306039999998,
              "alt": 2100.9674444444445,
              "speed2d": 4.686888888888889,
              "speed3d": 4.783333333333333,
              "msFromStart": 201763.05599999992
            },
            {
              "lat": 43.77355327777778,
              "lng": -116.08304237777777,
              "alt": 2100.601222222222,
              "speed2d": 5.065555555555556,
              "speed3d": 5.151111111111111,
              "msFromStart": 202258.86245614025
            },
            {
              "lat": 43.77353318888889,
              "lng": -116.08302655555553,
              "alt": 2100.123888888889,
              "speed2d": 5.062888888888889,
              "speed3d": 5.187777777777779,
              "msFromStart": 202754.0934736842
            },
            {
              "lat": 43.773511366666675,
              "lng": -116.08300313333334,
              "alt": 2100.3338888888893,
              "speed2d": 5.482888888888889,
              "speed3d": 5.51111111111111,
              "msFromStart": 203249.13888499027
            },
            {
              "lat": 43.77348832222223,
              "lng": -116.08297870000001,
              "alt": 2100.5282222222218,
              "speed2d": 5.764666666666666,
              "speed3d": 5.855555555555554,
              "msFromStart": 203743.39111111112
            },
            {
              "lat": 43.773467611111116,
              "lng": -116.08295703333333,
              "alt": 2100.1033333333335,
              "speed2d": 5.681777777777778,
              "speed3d": 5.775555555555555,
              "msFromStart": 204237.49688888888
            },
            {
              "lat": 43.773446577777776,
              "lng": -116.08293422222222,
              "alt": 2099.7203333333337,
              "speed2d": 5.687555555555556,
              "speed3d": 5.762222222222222,
              "msFromStart": 204734.37244444445
            },
            {
              "lat": 43.77342677777778,
              "lng": -116.08290989999999,
              "alt": 2099.0912222222223,
              "speed2d": 5.834222222222223,
              "speed3d": 5.9222222222222225,
              "msFromStart": 205232.11377777776
            },
            {
              "lat": 43.77340845555556,
              "lng": -116.08288622222221,
              "alt": 2098.197222222222,
              "speed2d": 5.834888888888889,
              "speed3d": 5.944444444444445,
              "msFromStart": 205726.9164444444
            },
            {
              "lat": 43.7733885,
              "lng": -116.08285436,
              "alt": 2097.7081,
              "speed2d": 6.242300000000001,
              "speed3d": 6.289000000000001,
              "msFromStart": 206248.00293333325
            },
            {
              "lat": 43.77336915555555,
              "lng": -116.0828190888889,
              "alt": 2097.500111111111,
              "speed2d": 6.48588888888889,
              "speed3d": 6.587777777777779,
              "msFromStart": 206768.38222222222
            },
            {
              "lat": 43.773352777777774,
              "lng": -116.08278556666667,
              "alt": 2097.2323333333334,
              "speed2d": 6.37388888888889,
              "speed3d": 6.482222222222222,
              "msFromStart": 207261.33289668616
            },
            {
              "lat": 43.773337511111116,
              "lng": -116.0827504888889,
              "alt": 2097.1755555555555,
              "speed2d": 6.475666666666667,
              "speed3d": 6.528888888888888,
              "msFromStart": 207755.95031578938
            },
            {
              "lat": 43.77332282222222,
              "lng": -116.08271496666667,
              "alt": 2097.0530000000003,
              "speed2d": 6.551555555555555,
              "speed3d": 6.598888888888888,
              "msFromStart": 208250.9704171539
            },
            {
              "lat": 43.773306777777776,
              "lng": -116.08267438888889,
              "alt": 2097.6811111111115,
              "speed2d": 7.030222222222222,
              "speed3d": 7.016666666666667,
              "msFromStart": 208745.7244444445
            },
            {
              "lat": 43.77329022222223,
              "lng": -116.08263244444444,
              "alt": 2098.277888888889,
              "speed2d": 7.305777777777779,
              "speed3d": 7.33,
              "msFromStart": 209240.39348148162
            },
            {
              "lat": 43.77327462222222,
              "lng": -116.08259063333335,
              "alt": 2098.738111111111,
              "speed2d": 7.379666666666665,
              "speed3d": 7.393333333333333,
              "msFromStart": 209735.31022222232
            },
            {
              "lat": 43.77325851111111,
              "lng": -116.08254893333334,
              "alt": 2099.267333333333,
              "speed2d": 7.387555555555555,
              "speed3d": 7.402222222222222,
              "msFromStart": 210230.32414814824
            },
            {
              "lat": 43.77324512222223,
              "lng": -116.08250807777779,
              "alt": 2099.298111111111,
              "speed2d": 7.342444444444444,
              "speed3d": 7.367777777777778,
              "msFromStart": 210725.4506666667
            },
            {
              "lat": 43.77323288000001,
              "lng": -116.08246569,
              "alt": 2099.0301999999997,
              "speed2d": 7.231099999999999,
              "speed3d": 7.212,
              "msFromStart": 211248.04906666666
            },
            {
              "lat": 43.773218299999996,
              "lng": -116.08240207777777,
              "alt": 2099.555888888889,
              "speed2d": 8.872777777777777,
              "speed3d": 8.663333333333334,
              "msFromStart": 211769.75288888882
            },
            {
              "lat": 43.77320445555556,
              "lng": -116.08233952222223,
              "alt": 2100.2902222222224,
              "speed2d": 9.676444444444444,
              "speed3d": 9.675555555555555,
              "msFromStart": 212263.8247797271
            },
            {
              "lat": 43.7731893,
              "lng": -116.08228346666667,
              "alt": 2100.6798888888893,
              "speed2d": 9.70722222222222,
              "speed3d": 9.713333333333335,
              "msFromStart": 212758.77557894748
            },
            {
              "lat": 43.77317641111111,
              "lng": -116.08223348888889,
              "alt": 2100.8594444444448,
              "speed2d": 9.235777777777777,
              "speed3d": 9.323333333333334,
              "msFromStart": 213253.96941910344
            },
            {
              "lat": 43.773165066666664,
              "lng": -116.08218488888889,
              "alt": 2100.784666666667,
              "speed2d": 8.892888888888887,
              "speed3d": 8.905555555555555,
              "msFromStart": 213749.60622222224
            },
            {
              "lat": 43.77315475555556,
              "lng": -116.08213464444445,
              "alt": 2100.5313333333334,
              "speed2d": 8.834444444444445,
              "speed3d": 8.831111111111111,
              "msFromStart": 214245.38607407408
            },
            {
              "lat": 43.77314491111111,
              "lng": -116.08208327777778,
              "alt": 2100.0530000000003,
              "speed2d": 8.892111111111111,
              "speed3d": 8.896666666666667,
              "msFromStart": 214740.7831111111
            },
            {
              "lat": 43.77313534444444,
              "lng": -116.0820296777778,
              "alt": 2099.414111111111,
              "speed2d": 8.99788888888889,
              "speed3d": 8.991111111111111,
              "msFromStart": 215236.02281481476
            },
            {
              "lat": 43.77312553333333,
              "lng": -116.08197377777779,
              "alt": 2098.603555555556,
              "speed2d": 9.035777777777778,
              "speed3d": 9.05,
              "msFromStart": 215730.95288888895
            },
            {
              "lat": 43.77311551111111,
              "lng": -116.0819166,
              "alt": 2098.499222222222,
              "speed2d": 9.161777777777777,
              "speed3d": 9.194444444444446,
              "msFromStart": 216225.79051851857
            },
            {
              "lat": 43.77310665,
              "lng": -116.08186039,
              "alt": 2098.7665,
              "speed2d": 8.9088,
              "speed3d": 8.915999999999999,
              "msFromStart": 216748.5613333333
            },
            {
              "lat": 43.773098544444444,
              "lng": -116.08179434444443,
              "alt": 2098.5065555555557,
              "speed2d": 9.605777777777778,
              "speed3d": 9.552222222222222,
              "msFromStart": 217271.50417153994
            },
            {
              "lat": 43.77309533333333,
              "lng": -116.0817345111111,
              "alt": 2098.2003333333337,
              "speed2d": 9.520222222222223,
              "speed3d": 9.564444444444444,
              "msFromStart": 217767.1309473685
            },
            {
              "lat": 43.773094199999996,
              "lng": -116.08167752222221,
              "alt": 2097.760111111111,
              "speed2d": 9.239222222222224,
              "speed3d": 9.293333333333333,
              "msFromStart": 218262.74295516583
            },
            {
              "lat": 43.77308904444445,
              "lng": -116.08162091111112,
              "alt": 2097.056777777778,
              "speed2d": 9.193555555555555,
              "speed3d": 9.195555555555554,
              "msFromStart": 218757.09511111106
            },
            {
              "lat": 43.773082422222224,
              "lng": -116.08156328888889,
              "alt": 2096.144222222222,
              "speed2d": 9.250444444444446,
              "speed3d": 9.272222222222222,
              "msFromStart": 219251.04888888882
            },
            {
              "lat": 43.773079433333336,
              "lng": -116.08150011111113,
              "alt": 2095.251222222222,
              "speed2d": 9.759444444444444,
              "speed3d": 9.754444444444445,
              "msFromStart": 219746.2524444445
            },
            {
              "lat": 43.77307824444444,
              "lng": -116.08143551111111,
              "alt": 2094.5486666666666,
              "speed2d": 9.792,
              "speed3d": 9.874444444444443,
              "msFromStart": 220241.86577777786
            },
            {
              "lat": 43.77307357777777,
              "lng": -116.08137327777777,
              "alt": 2093.7314444444446,
              "speed2d": 9.818666666666667,
              "speed3d": 9.86,
              "msFromStart": 220736.5164444445
            }
          ]
        }
      ]
    },
    {
      "id": "trail-bonanza",
      "pathType": "trail",
      "name": "Bonanza",
      "difficulty": "blue",
      "videos": [
        {
          "videoId": "GX010067",
          "pathId": "trail-bonanza",
          "startSec": 72,
          "endSec": 129,
          "id": "video-segment-27",
          "gps": [
            {
              "lat": 43.77230430000001,
              "lng": -116.09079406666666,
              "alt": 2273.0843333333332,
              "speed2d": 3.091666666666667,
              "speed3d": 3.1555555555555554,
              "msFromStart": 72233.01881481482
            },
            {
              "lat": 43.772320477777775,
              "lng": -116.09079795555554,
              "alt": 2273.032,
              "speed2d": 3.3992222222222224,
              "speed3d": 3.391111111111111,
              "msFromStart": 72730.20711111109
            },
            {
              "lat": 43.772340711111106,
              "lng": -116.09079865555556,
              "alt": 2272.728888888889,
              "speed2d": 3.898666666666667,
              "speed3d": 3.903333333333333,
              "msFromStart": 73227.20355555555
            },
            {
              "lat": 43.772364610000004,
              "lng": -116.09079974,
              "alt": 2272.5047999999997,
              "speed2d": 4.4134,
              "speed3d": 4.389,
              "msFromStart": 73750.38666666669
            },
            {
              "lat": 43.77238874444445,
              "lng": -116.09080138888888,
              "alt": 2272.117888888889,
              "speed2d": 4.734444444444445,
              "speed3d": 4.765555555555556,
              "msFromStart": 74273.30592592593
            },
            {
              "lat": 43.77241377777778,
              "lng": -116.09080103333332,
              "alt": 2271.3835555555556,
              "speed2d": 5.057777777777779,
              "speed3d": 5.0122222222222215,
              "msFromStart": 74768.3444444444
            },
            {
              "lat": 43.772446200000005,
              "lng": -116.09079765555556,
              "alt": 2270.5757777777776,
              "speed2d": 5.911555555555555,
              "speed3d": 5.880000000000001,
              "msFromStart": 75263.00459259258
            },
            {
              "lat": 43.7724813,
              "lng": -116.09078728888889,
              "alt": 2269.9675555555555,
              "speed2d": 6.955333333333333,
              "speed3d": 6.891111111111112,
              "msFromStart": 75756.688
            },
            {
              "lat": 43.772516588888884,
              "lng": -116.09077412222223,
              "alt": 2269.1163333333334,
              "speed2d": 7.673,
              "speed3d": 7.687777777777778,
              "msFromStart": 76250.6333333333
            },
            {
              "lat": 43.7725465,
              "lng": -116.09075113333333,
              "alt": 2268.148,
              "speed2d": 7.883333333333332,
              "speed3d": 7.933333333333335,
              "msFromStart": 76745.83199999994
            },
            {
              "lat": 43.77257127777778,
              "lng": -116.09071685555553,
              "alt": 2267.2364444444443,
              "speed2d": 7.896666666666667,
              "speed3d": 7.996666666666667,
              "msFromStart": 77240.78414814812
            },
            {
              "lat": 43.77259234444445,
              "lng": -116.09068084444444,
              "alt": 2266.5477777777774,
              "speed2d": 7.626222222222222,
              "speed3d": 7.707777777777779,
              "msFromStart": 77734.24888888889
            },
            {
              "lat": 43.77261597777778,
              "lng": -116.09064403333333,
              "alt": 2265.539111111111,
              "speed2d": 7.693,
              "speed3d": 7.761111111111111,
              "msFromStart": 78228.09674074072
            },
            {
              "lat": 43.772643040000005,
              "lng": -116.09061326999998,
              "alt": 2264.1515999999997,
              "speed2d": 7.3307,
              "speed3d": 7.513999999999999,
              "msFromStart": 78751.63799999996
            },
            {
              "lat": 43.772677955555544,
              "lng": -116.09060294444443,
              "alt": 2262.854444444444,
              "speed2d": 6.99988888888889,
              "speed3d": 7.127777777777778,
              "msFromStart": 79274.97407407405
            },
            {
              "lat": 43.77271556666666,
              "lng": -116.09059767777777,
              "alt": 2261.6725555555554,
              "speed2d": 7.54,
              "speed3d": 7.564444444444443,
              "msFromStart": 79769.60977777776
            },
            {
              "lat": 43.772759122222226,
              "lng": -116.09058866666668,
              "alt": 2260.6873333333333,
              "speed2d": 8.721222222222222,
              "speed3d": 8.716666666666667,
              "msFromStart": 80263.8909317739
            },
            {
              "lat": 43.77280256666666,
              "lng": -116.09056914444443,
              "alt": 2260.148222222222,
              "speed2d": 9.534666666666666,
              "speed3d": 9.55,
              "msFromStart": 80757.37178947375
            },
            {
              "lat": 43.77284613333333,
              "lng": -116.09054068888888,
              "alt": 2259.0966666666664,
              "speed2d": 10.361777777777778,
              "speed3d": 10.405555555555557,
              "msFromStart": 81250.91964912282
            },
            {
              "lat": 43.77288238888889,
              "lng": -116.09050446666666,
              "alt": 2257.619777777778,
              "speed2d": 10.437888888888889,
              "speed3d": 10.546666666666667,
              "msFromStart": 81744.90399999992
            },
            {
              "lat": 43.772905200000004,
              "lng": -116.09045501111112,
              "alt": 2256.2613333333334,
              "speed2d": 10.245555555555557,
              "speed3d": 10.421111111111113,
              "msFromStart": 82239.14903703699
            },
            {
              "lat": 43.77292566666666,
              "lng": -116.09039905555555,
              "alt": 2255.644333333333,
              "speed2d": 10.16111111111111,
              "speed3d": 10.264444444444443,
              "msFromStart": 82734.2622222222
            },
            {
              "lat": 43.7729606,
              "lng": -116.09035536666667,
              "alt": 2255.0704444444445,
              "speed2d": 10.280111111111111,
              "speed3d": 10.34,
              "msFromStart": 83229.5162962963
            },
            {
              "lat": 43.77299856666667,
              "lng": -116.09031506666668,
              "alt": 2254.1865555555555,
              "speed2d": 10.484777777777778,
              "speed3d": 10.64,
              "msFromStart": 83724.98666666663
            },
            {
              "lat": 43.77303974,
              "lng": -116.09029838,
              "alt": 2253.8605000000002,
              "speed2d": 9.336699999999999,
              "speed3d": 9.568999999999999,
              "msFromStart": 84247.74279999999
            },
            {
              "lat": 43.77308914444444,
              "lng": -116.09029852222223,
              "alt": 2254.0922222222225,
              "speed2d": 9.253888888888888,
              "speed3d": 9.257777777777777,
              "msFromStart": 84769.64666666667
            },
            {
              "lat": 43.77314457777777,
              "lng": -116.09030245555556,
              "alt": 2253.3488888888887,
              "speed2d": 10.361444444444444,
              "speed3d": 10.303333333333335,
              "msFromStart": 85264.03015204679
            },
            {
              "lat": 43.773204122222225,
              "lng": -116.0903072888889,
              "alt": 2252.174,
              "speed2d": 11.57288888888889,
              "speed3d": 11.551111111111112,
              "msFromStart": 85758.4665263158
            },
            {
              "lat": 43.77326153333334,
              "lng": -116.09031004444444,
              "alt": 2250.6187777777777,
              "speed2d": 12.305444444444444,
              "speed3d": 12.305555555555557,
              "msFromStart": 86252.97621052629
            },
            {
              "lat": 43.7733247,
              "lng": -116.0903033,
              "alt": 2248.6949999999997,
              "speed2d": 13.257000000000001,
              "speed3d": 13.251111111111111,
              "msFromStart": 86747.76799999994
            },
            {
              "lat": 43.7733835,
              "lng": -116.09028511111111,
              "alt": 2246.891111111111,
              "speed2d": 13.629666666666667,
              "speed3d": 13.753333333333334,
              "msFromStart": 87242.72503703702
            },
            {
              "lat": 43.773433066666676,
              "lng": -116.09025816666667,
              "alt": 2244.885444444444,
              "speed2d": 13.36688888888889,
              "speed3d": 13.534444444444446,
              "msFromStart": 87738.23022222216
            },
            {
              "lat": 43.773481044444445,
              "lng": -116.09022057777779,
              "alt": 2242.481333333333,
              "speed2d": 13.209111111111111,
              "speed3d": 13.377777777777776,
              "msFromStart": 88233.5635555555
            },
            {
              "lat": 43.773530811111115,
              "lng": -116.09017638888889,
              "alt": 2240.478333333333,
              "speed2d": 13.064222222222222,
              "speed3d": 13.237777777777776,
              "msFromStart": 88727.99022222217
            },
            {
              "lat": 43.77359414,
              "lng": -116.09014306,
              "alt": 2238.9332999999997,
              "speed2d": 13.4146,
              "speed3d": 13.542,
              "msFromStart": 89250.23694035085
            },
            {
              "lat": 43.77365448888889,
              "lng": -116.09010676666665,
              "alt": 2237.1635555555554,
              "speed2d": 13.893666666666666,
              "speed3d": 14.02111111111111,
              "msFromStart": 89773.93305263153
            },
            {
              "lat": 43.77370692222222,
              "lng": -116.0900646888889,
              "alt": 2235.419111111111,
              "speed2d": 14.296777777777777,
              "speed3d": 14.47888888888889,
              "msFromStart": 90270.05953216371
            },
            {
              "lat": 43.77374853333334,
              "lng": -116.0900159888889,
              "alt": 2233.5765555555554,
              "speed2d": 13.975555555555555,
              "speed3d": 14.243333333333332,
              "msFromStart": 90765.67466666664
            },
            {
              "lat": 43.773771133333334,
              "lng": -116.08996095555554,
              "alt": 2231.2232222222224,
              "speed2d": 13.154666666666666,
              "speed3d": 13.474444444444444,
              "msFromStart": 91260.99496296298
            },
            {
              "lat": 43.773798255555555,
              "lng": -116.08990458888887,
              "alt": 2229.0614444444445,
              "speed2d": 12.61688888888889,
              "speed3d": 12.912222222222221,
              "msFromStart": 91755.34044444447
            },
            {
              "lat": 43.773840255555555,
              "lng": -116.08983887777778,
              "alt": 2227.1593333333335,
              "speed2d": 12.87811111111111,
              "speed3d": 13.101111111111111,
              "msFromStart": 92250.05881481482
            },
            {
              "lat": 43.77388627777778,
              "lng": -116.08978510000001,
              "alt": 2224.708888888889,
              "speed2d": 12.741,
              "speed3d": 13.11111111111111,
              "msFromStart": 92746.65866666664
            },
            {
              "lat": 43.77393338888889,
              "lng": -116.08974700000002,
              "alt": 2222.0756666666666,
              "speed2d": 12.424444444444445,
              "speed3d": 12.743333333333332,
              "msFromStart": 93242.85259259258
            },
            {
              "lat": 43.7739922,
              "lng": -116.08970894444444,
              "alt": 2219.451,
              "speed2d": 12.967222222222224,
              "speed3d": 13.22111111111111,
              "msFromStart": 93736.67022222216
            },
            {
              "lat": 43.7740553,
              "lng": -116.08966191111112,
              "alt": 2216.3696666666665,
              "speed2d": 14.381666666666668,
              "speed3d": 14.625555555555554,
              "msFromStart": 94230.72300974657
            },
            {
              "lat": 43.774107155555555,
              "lng": -116.08961326666667,
              "alt": 2213.582111111111,
              "speed2d": 14.679666666666666,
              "speed3d": 15.04111111111111,
              "msFromStart": 94726.66694736849
            },
            {
              "lat": 43.77415381000001,
              "lng": -116.08956069,
              "alt": 2209.9847999999997,
              "speed2d": 14.519299999999998,
              "speed3d": 14.992999999999999,
              "msFromStart": 95250.31410526321
            },
            {
              "lat": 43.77419864444445,
              "lng": -116.0895012,
              "alt": 2206.541444444445,
              "speed2d": 14.293,
              "speed3d": 14.672222222222219,
              "msFromStart": 95773.7333333334
            },
            {
              "lat": 43.77425463333333,
              "lng": -116.08943875555556,
              "alt": 2203.075888888889,
              "speed2d": 14.938666666666666,
              "speed3d": 15.381111111111112,
              "msFromStart": 96269.0866666667
            },
            {
              "lat": 43.774318433333335,
              "lng": -116.08938673333333,
              "alt": 2200.4051111111107,
              "speed2d": 15.361444444444444,
              "speed3d": 15.784444444444444,
              "msFromStart": 96762.49333333336
            },
            {
              "lat": 43.7743783,
              "lng": -116.08932568888889,
              "alt": 2197.715888888889,
              "speed2d": 15.794666666666668,
              "speed3d": 16.294444444444444,
              "msFromStart": 97256.29570370371
            },
            {
              "lat": 43.774428722222225,
              "lng": -116.08926249999998,
              "alt": 2195.220222222222,
              "speed2d": 15.376222222222221,
              "speed3d": 15.943333333333332,
              "msFromStart": 97752.45955555551
            },
            {
              "lat": 43.77448638888889,
              "lng": -116.08920531111112,
              "alt": 2193.7367777777777,
              "speed2d": 15.215444444444444,
              "speed3d": 15.71,
              "msFromStart": 98248.4625185185
            },
            {
              "lat": 43.774539922222225,
              "lng": -116.08914356666666,
              "alt": 2192.5261111111113,
              "speed2d": 14.957666666666666,
              "speed3d": 15.523333333333337,
              "msFromStart": 98742.87733333331
            },
            {
              "lat": 43.7745842,
              "lng": -116.08908222222223,
              "alt": 2189.7846666666665,
              "speed2d": 14.629777777777777,
              "speed3d": 15.126666666666667,
              "msFromStart": 99237.2851461988
            },
            {
              "lat": 43.77463064444444,
              "lng": -116.08902212222223,
              "alt": 2186.1334444444446,
              "speed2d": 14.409444444444446,
              "speed3d": 15.013333333333334,
              "msFromStart": 99732.30021052626
            },
            {
              "lat": 43.77467512222223,
              "lng": -116.08897431111112,
              "alt": 2182.974111111111,
              "speed2d": 13.683666666666666,
              "speed3d": 14.384444444444444,
              "msFromStart": 100227.37651981802
            },
            {
              "lat": 43.77472889,
              "lng": -116.08894314,
              "alt": 2180.5458,
              "speed2d": 12.9277,
              "speed3d": 13.625000000000004,
              "msFromStart": 100749.96044444441
            },
            {
              "lat": 43.774801922222224,
              "lng": -116.08892513333332,
              "alt": 2178.5005555555554,
              "speed2d": 13.220333333333334,
              "speed3d": 13.747777777777777,
              "msFromStart": 101272.32414814814
            },
            {
              "lat": 43.77487916666667,
              "lng": -116.08890163333334,
              "alt": 2176.4216666666666,
              "speed2d": 14.373000000000001,
              "speed3d": 14.89333333333333,
              "msFromStart": 101766.3004444444
            },
            {
              "lat": 43.77494642222222,
              "lng": -116.08887053333333,
              "alt": 2174.0935555555557,
              "speed2d": 14.439,
              "speed3d": 15.032222222222222,
              "msFromStart": 102260.56474074071
            },
            {
              "lat": 43.775001055555556,
              "lng": -116.08882282222221,
              "alt": 2170.759222222222,
              "speed2d": 14.573333333333334,
              "speed3d": 15.205555555555556,
              "msFromStart": 102756.33422222224
            },
            {
              "lat": 43.77504708888889,
              "lng": -116.08876650000002,
              "alt": 2168.2692222222227,
              "speed2d": 14.051444444444446,
              "speed3d": 14.793333333333333,
              "msFromStart": 103252.1971851852
            },
            {
              "lat": 43.775088777777775,
              "lng": -116.08871181111112,
              "alt": 2165.3888888888887,
              "speed2d": 13.320555555555556,
              "speed3d": 14.079999999999998,
              "msFromStart": 103747.83200000001
            },
            {
              "lat": 43.77513581111111,
              "lng": -116.08866407777776,
              "alt": 2162.2005555555556,
              "speed2d": 12.970333333333333,
              "speed3d": 13.772222222222222,
              "msFromStart": 104243.26548148149
            },
            {
              "lat": 43.7751958,
              "lng": -116.08862594444444,
              "alt": 2160.2344444444443,
              "speed2d": 12.812222222222223,
              "speed3d": 13.590000000000002,
              "msFromStart": 104737.98488888891
            },
            {
              "lat": 43.77525898888888,
              "lng": -116.08860646666665,
              "alt": 2157.986,
              "speed2d": 12.595555555555556,
              "speed3d": 13.405555555555555,
              "msFromStart": 105232.76965302146
            },
            {
              "lat": 43.775319933333336,
              "lng": -116.08858931111112,
              "alt": 2154.7965555555556,
              "speed2d": 13.071777777777777,
              "speed3d": 13.785555555555556,
              "msFromStart": 105728.1014736842
            },
            {
              "lat": 43.7753827,
              "lng": -116.08856358,
              "alt": 2151.6369000000004,
              "speed2d": 13.762699999999999,
              "speed3d": 14.453999999999999,
              "msFromStart": 106251.04238596489
            },
            {
              "lat": 43.77543706666667,
              "lng": -116.08853463333334,
              "alt": 2148.0377777777776,
              "speed2d": 13.606222222222222,
              "speed3d": 14.46,
              "msFromStart": 106774.13688888881
            },
            {
              "lat": 43.775492799999995,
              "lng": -116.08849919999999,
              "alt": 2145.3338888888893,
              "speed2d": 13.538777777777776,
              "speed3d": 14.329999999999998,
              "msFromStart": 107269.409037037
            },
            {
              "lat": 43.77555312222222,
              "lng": -116.08845626666667,
              "alt": 2143.3347777777776,
              "speed2d": 13.687777777777779,
              "speed3d": 14.463333333333333,
              "msFromStart": 107763.43377777773
            },
            {
              "lat": 43.77562167777778,
              "lng": -116.0884182222222,
              "alt": 2141.662222222222,
              "speed2d": 14.202888888888891,
              "speed3d": 14.845555555555556,
              "msFromStart": 108257.55600000001
            },
            {
              "lat": 43.77570302222222,
              "lng": -116.08839948888888,
              "alt": 2140.931111111111,
              "speed2d": 14.700666666666667,
              "speed3d": 15.328888888888892,
              "msFromStart": 108752.56711111119
            },
            {
              "lat": 43.77578201111111,
              "lng": -116.08839073333333,
              "alt": 2140.2234444444443,
              "speed2d": 15.07922222222222,
              "speed3d": 15.652222222222225,
              "msFromStart": 109247.87525925931
            },
            {
              "lat": 43.77585833333333,
              "lng": -116.08838026666668,
              "alt": 2138.5446666666667,
              "speed2d": 15.565777777777779,
              "speed3d": 16.082222222222224,
              "msFromStart": 109744.016
            },
            {
              "lat": 43.77593808888889,
              "lng": -116.08836304444445,
              "alt": 2136.474111111111,
              "speed2d": 16.362111111111112,
              "speed3d": 16.84,
              "msFromStart": 110240.07368421054
            },
            {
              "lat": 43.77601666666667,
              "lng": -116.08833772222222,
              "alt": 2135.050555555555,
              "speed2d": 16.82111111111111,
              "speed3d": 17.314444444444444,
              "msFromStart": 110735.4661052632
            },
            {
              "lat": 43.7760894,
              "lng": -116.0883071,
              "alt": 2132.8255555555556,
              "speed2d": 16.80955555555556,
              "speed3d": 17.34777777777778,
              "msFromStart": 111230.65053151401
            },
            {
              "lat": 43.77617035,
              "lng": -116.08827857000001,
              "alt": 2130.7425000000003,
              "speed2d": 16.721900000000005,
              "speed3d": 17.265,
              "msFromStart": 111752.42644444451
            },
            {
              "lat": 43.7762587,
              "lng": -116.08825641111112,
              "alt": 2128.826222222222,
              "speed2d": 17.051111111111112,
              "speed3d": 17.531111111111112,
              "msFromStart": 112274.08903703709
            },
            {
              "lat": 43.77633971111111,
              "lng": -116.08822288888888,
              "alt": 2128.151555555555,
              "speed2d": 17.197888888888887,
              "speed3d": 17.656666666666666,
              "msFromStart": 112768.40977777784
            },
            {
              "lat": 43.7764188,
              "lng": -116.08817945555556,
              "alt": 2126.932222222222,
              "speed2d": 17.52755555555556,
              "speed3d": 17.914444444444445,
              "msFromStart": 113263.24474074076
            },
            {
              "lat": 43.77649714444445,
              "lng": -116.08812271111111,
              "alt": 2125.422111111111,
              "speed2d": 18.09066666666667,
              "speed3d": 18.45111111111111,
              "msFromStart": 113760.09155555554
            },
            {
              "lat": 43.776577777777774,
              "lng": -116.08806584444446,
              "alt": 2124.031,
              "speed2d": 18.51611111111111,
              "speed3d": 18.87111111111111,
              "msFromStart": 114256.71437037035
            },
            {
              "lat": 43.77665833333334,
              "lng": -116.08800855555556,
              "alt": 2122.344666666667,
              "speed2d": 18.92188888888889,
              "speed3d": 19.25888888888889,
              "msFromStart": 114751.63644444442
            },
            {
              "lat": 43.776735055555555,
              "lng": -116.08794638888888,
              "alt": 2120.704888888889,
              "speed2d": 19.040444444444443,
              "speed3d": 19.443333333333335,
              "msFromStart": 115246.34103703703
            },
            {
              "lat": 43.776804911111114,
              "lng": -116.08788812222224,
              "alt": 2118.4724444444446,
              "speed2d": 18.819444444444443,
              "speed3d": 19.192222222222224,
              "msFromStart": 115740.85600000001
            },
            {
              "lat": 43.7768749,
              "lng": -116.08782850000001,
              "alt": 2116.4192222222223,
              "speed2d": 18.854222222222223,
              "speed3d": 19.246666666666666,
              "msFromStart": 116235.4774320988
            },
            {
              "lat": 43.77694114444444,
              "lng": -116.08777224444444,
              "alt": 2114.182888888889,
              "speed2d": 18.369555555555554,
              "speed3d": 18.824444444444442,
              "msFromStart": 116730.86400000007
            },
            {
              "lat": 43.777001544444445,
              "lng": -116.08772064444445,
              "alt": 2111.5253333333335,
              "speed2d": 17.49611111111111,
              "speed3d": 18.003333333333334,
              "msFromStart": 117226.3093333334
            },
            {
              "lat": 43.7770607,
              "lng": -116.08766599000002,
              "alt": 2108.8089,
              "speed2d": 16.6844,
              "speed3d": 17.198,
              "msFromStart": 117748.84644444438
            },
            {
              "lat": 43.77711721111111,
              "lng": -116.08760445555555,
              "alt": 2106.2867777777774,
              "speed2d": 16.215444444444444,
              "speed3d": 16.64,
              "msFromStart": 118271.29837037032
            },
            {
              "lat": 43.7771714888889,
              "lng": -116.08754007777779,
              "alt": 2104.1125555555554,
              "speed2d": 16.220777777777776,
              "speed3d": 16.68,
              "msFromStart": 118766.1777777778
            },
            {
              "lat": 43.777222088888884,
              "lng": -116.08748077777777,
              "alt": 2102.581777777778,
              "speed2d": 15.441555555555553,
              "speed3d": 16.006666666666668,
              "msFromStart": 119261.08385185187
            },
            {
              "lat": 43.777266,
              "lng": -116.08743168888888,
              "alt": 2101.9954444444447,
              "speed2d": 13.889888888888887,
              "speed3d": 14.473333333333333,
              "msFromStart": 119756.1262222222
            },
            {
              "lat": 43.777309977777776,
              "lng": -116.08738534444444,
              "alt": 2101.135222222222,
              "speed2d": 12.989444444444445,
              "speed3d": 13.42222222222222,
              "msFromStart": 120251.09957115009
            },
            {
              "lat": 43.777354375,
              "lng": -116.0873403,
              "alt": 2100.5555,
              "speed2d": 13.176000000000002,
              "speed3d": 13.465,
              "msFromStart": 120677.04652631574
            },
            {
              "lat": 43.77741758888888,
              "lng": -116.0872778888889,
              "alt": 2100.983888888889,
              "speed2d": 13.191555555555556,
              "speed3d": 13.544444444444443,
              "msFromStart": 121240.55031059124
            },
            {
              "lat": 43.77746097777778,
              "lng": -116.08723928888888,
              "alt": 2100.3035555555557,
              "speed2d": 12.376666666666669,
              "speed3d": 12.675555555555555,
              "msFromStart": 121736.56800000001
            },
            {
              "lat": 43.77750335555555,
              "lng": -116.08720334444445,
              "alt": 2099.155111111111,
              "speed2d": 11.865999999999998,
              "speed3d": 12.153333333333332,
              "msFromStart": 122232.6156049383
            },
            {
              "lat": 43.777538577777776,
              "lng": -116.08717178888888,
              "alt": 2097.740888888889,
              "speed2d": 10.833555555555556,
              "speed3d": 11.138888888888888,
              "msFromStart": 122727.63600000006
            },
            {
              "lat": 43.77757106,
              "lng": -116.08713746000001,
              "alt": 2095.5563,
              "speed2d": 10.106700000000002,
              "speed3d": 10.379,
              "msFromStart": 123250.04644444451
            },
            {
              "lat": 43.777601377777785,
              "lng": -116.08710218888888,
              "alt": 2093.4666666666667,
              "speed2d": 9.70488888888889,
              "speed3d": 9.953333333333333,
              "msFromStart": 123772.76800000007
            },
            {
              "lat": 43.77763032222223,
              "lng": -116.0870669777778,
              "alt": 2092.5702222222226,
              "speed2d": 9.290666666666667,
              "speed3d": 9.526666666666666,
              "msFromStart": 124267.99037037039
            },
            {
              "lat": 43.77765914444444,
              "lng": -116.08703306666665,
              "alt": 2092.2192222222225,
              "speed2d": 8.860666666666667,
              "speed3d": 9.08222222222222,
              "msFromStart": 124763.0942222222
            },
            {
              "lat": 43.77768506666666,
              "lng": -116.0869992111111,
              "alt": 2091.251888888889,
              "speed2d": 8.616999999999999,
              "speed3d": 8.775555555555556,
              "msFromStart": 125258.23444834306
            },
            {
              "lat": 43.777709977777775,
              "lng": -116.08696484444445,
              "alt": 2089.762222222222,
              "speed2d": 8.548666666666668,
              "speed3d": 8.703333333333333,
              "msFromStart": 125753.56757894732
            },
            {
              "lat": 43.77773014,
              "lng": -116.08693229999999,
              "alt": 2088.7394,
              "speed2d": 8.7008,
              "speed3d": 8.841999999999999,
              "msFromStart": 126171.72701754377
            },
            {
              "lat": 43.77776078,
              "lng": -116.0868779,
              "alt": 2087.5088,
              "speed2d": 8.7692,
              "speed3d": 8.91,
              "msFromStart": 126807.97066666666
            },
            {
              "lat": 43.7777766,
              "lng": -116.08685146666667,
              "alt": 2086.963333333333,
              "speed2d": 8.711333333333334,
              "speed3d": 8.833333333333334,
              "msFromStart": 127125.8622222222
            },
            {
              "lat": 43.77782195,
              "lng": -116.0867811,
              "alt": 2085.7200000000003,
              "speed2d": 9.0945,
              "speed3d": 9.165,
              "msFromStart": 127922.38000000006
            },
            {
              "lat": 43.77783805999999,
              "lng": -116.08676152000001,
              "alt": 2084.9144,
              "speed2d": 9.1134,
              "speed3d": 9.222,
              "msFromStart": 128180.73973333338
            },
            {
              "lat": 43.77785906108001,
              "lng": -116.08674015129411,
              "alt": 2084.0486637324343,
              "speed2d": 8.9896967651377,
              "speed3d": 9.104091705522656,
              "msFromStart": 128500
            }
          ]
        }
      ]
    },
    {
      "id": "trail-lower-bonanza",
      "pathType": "trail",
      "name": "Lower Bonanza",
      "difficulty": "blue",
      "videos": [
        {
          "videoId": "GX010067",
          "pathId": "trail-lower-bonanza",
          "startSec": 129,
          "endSec": 167,
          "id": "video-segment-28",
          "gps": [
            {
              "lat": 43.777913733333335,
              "lng": -116.08669834999999,
              "alt": 2081.4123333333337,
              "speed2d": 8.055833333333334,
              "speed3d": 8.253333333333334,
              "msFromStart": 129353.92533333332
            },
            {
              "lat": 43.77793832222222,
              "lng": -116.08669167777778,
              "alt": 2080.049111111111,
              "speed2d": 7.732111111111112,
              "speed3d": 7.956666666666666,
              "msFromStart": 129766.7653333333
            },
            {
              "lat": 43.77796895,
              "lng": -116.08669768333333,
              "alt": 2078.3516666666665,
              "speed2d": 7.479333333333333,
              "speed3d": 7.703333333333333,
              "msFromStart": 130317.33480701751
            },
            {
              "lat": 43.77798902222222,
              "lng": -116.08670828888889,
              "alt": 2077.3383333333336,
              "speed2d": 7.196888888888889,
              "speed3d": 7.449999999999999,
              "msFromStart": 130758.04589473677
            },
            {
              "lat": 43.7780052625,
              "lng": -116.08672272499999,
              "alt": 2076.58625,
              "speed2d": 6.73175,
              "speed3d": 6.99125,
              "msFromStart": 131233.09842982452
            },
            {
              "lat": 43.77802604,
              "lng": -116.08674422000001,
              "alt": 2074.7994,
              "speed2d": 6.9588,
              "speed3d": 7.16,
              "msFromStart": 131770.5085333334
            },
            {
              "lat": 43.778053375,
              "lng": -116.086776675,
              "alt": 2072.38,
              "speed2d": 7.623,
              "speed3d": 7.869999999999999,
              "msFromStart": 132380.44933333338
            },
            {
              "lat": 43.778064549999996,
              "lng": -116.08678459999999,
              "alt": 2071.435,
              "speed2d": 7.5815,
              "speed3d": 7.865,
              "msFromStart": 132600.08666666673
            },
            {
              "lat": 43.77810037500001,
              "lng": -116.086797675,
              "alt": 2068.03875,
              "speed2d": 6.8527499999999995,
              "speed3d": 7.300000000000001,
              "msFromStart": 133355.435
            },
            {
              "lat": 43.7781205,
              "lng": -116.08679331666667,
              "alt": 2066.0508333333332,
              "speed2d": 6.732,
              "speed3d": 7.178333333333334,
              "msFromStart": 133763.8164444444
            },
            {
              "lat": 43.778142933333335,
              "lng": -116.08679145,
              "alt": 2063.8236666666667,
              "speed2d": 6.770833333333332,
              "speed3d": 7.2716666666666665,
              "msFromStart": 134222.56259259259
            },
            {
              "lat": 43.7781737625,
              "lng": -116.08679793750001,
              "alt": 2060.2687499999997,
              "speed2d": 7.51975,
              "speed3d": 8.02875,
              "msFromStart": 134792.97422222226
            },
            {
              "lat": 43.778192866666664,
              "lng": -116.0868052,
              "alt": 2058.243,
              "speed2d": 7.877666666666666,
              "speed3d": 8.45,
              "msFromStart": 135138.84918518522
            },
            {
              "lat": 43.778228766666665,
              "lng": -116.08683690000001,
              "alt": 2054.226333333333,
              "speed2d": 8.747,
              "speed3d": 9.336666666666666,
              "msFromStart": 135781.0240000001
            },
            {
              "lat": 43.77825978,
              "lng": -116.0868697,
              "alt": 2051.626,
              "speed2d": 9.497399999999999,
              "speed3d": 10.059999999999999,
              "msFromStart": 136269.02542222236
            },
            {
              "lat": 43.77828983333333,
              "lng": -116.08690268333334,
              "alt": 2049.584,
              "speed2d": 10.042333333333334,
              "speed3d": 10.67,
              "msFromStart": 136706.85629629635
            },
            {
              "lat": 43.77830855078713,
              "lng": -116.08691753627267,
              "alt": 2048.048930678657,
              "speed2d": 9.845869250177996,
              "speed3d": 10.532385973992438,
              "msFromStart": 137000
            },
            {
              "lat": 43.778349,
              "lng": -116.08693915,
              "alt": 2044.404,
              "speed2d": 8.840666666666667,
              "speed3d": 9.680000000000001,
              "msFromStart": 137686.96888888878
            },
            {
              "lat": 43.77836923079448,
              "lng": -116.08694021635445,
              "alt": 2042.9216329816286,
              "speed2d": 8.593076986217257,
              "speed3d": 9.439391038795259,
              "msFromStart": 138000
            },
            {
              "lat": 43.778425028571434,
              "lng": -116.08695927142858,
              "alt": 2039.1835714285714,
              "speed2d": 9.075,
              "speed3d": 9.807142857142857,
              "msFromStart": 138771.2066031745
            },
            {
              "lat": 43.77846127777778,
              "lng": -116.08697391111112,
              "alt": 2036.8648888888888,
              "speed2d": 9.649666666666667,
              "speed3d": 10.415555555555557,
              "msFromStart": 139227.02795061722
            },
            {
              "lat": 43.77849842,
              "lng": -116.08699623999999,
              "alt": 2034.4283,
              "speed2d": 9.5288,
              "speed3d": 10.427999999999999,
              "msFromStart": 139749.67600000004
            },
            {
              "lat": 43.77852435555556,
              "lng": -116.08701453333333,
              "alt": 2031.514111111111,
              "speed2d": 8.299777777777777,
              "speed3d": 9.455555555555554,
              "msFromStart": 140272.3834385965
            },
            {
              "lat": 43.77853401220422,
              "lng": -116.0870184642203,
              "alt": 2030.0994364623755,
              "speed2d": 7.452779391837857,
              "speed3d": 8.769264846193018,
              "msFromStart": 140500
            },
            {
              "lat": 43.77852548200547,
              "lng": -116.08705034758611,
              "alt": 2026.5975101961349,
              "speed2d": 6.7934369801248256,
              "speed3d": 8.251859348136131,
              "msFromStart": 141000
            },
            {
              "lat": 43.77850010715737,
              "lng": -116.08708314433522,
              "alt": 2022.6524094246265,
              "speed2d": 5.0780507131536785,
              "speed3d": 6.023002037581953,
              "msFromStart": 141500
            },
            {
              "lat": 43.778512375000005,
              "lng": -116.08712457499999,
              "alt": 2018.53125,
              "speed2d": 5.6945,
              "speed3d": 7.4975000000000005,
              "msFromStart": 142170.23066666682
            },
            {
              "lat": 43.77852086666667,
              "lng": -116.08715396666666,
              "alt": 2015.7176666666664,
              "speed2d": 6.246,
              "speed3d": 7.963333333333333,
              "msFromStart": 142600.994962963
            },
            {
              "lat": 43.7785501,
              "lng": -116.08721923333334,
              "alt": 2010.826,
              "speed2d": 7.443333333333333,
              "speed3d": 9.036666666666667,
              "msFromStart": 143371.53629629628
            },
            {
              "lat": 43.77857138333333,
              "lng": -116.08724061666666,
              "alt": 2008.8676666666668,
              "speed2d": 7.838666666666667,
              "speed3d": 9.393333333333334,
              "msFromStart": 143712.1062222222
            },
            {
              "lat": 43.77860346666667,
              "lng": -116.08726615,
              "alt": 2005.6388333333332,
              "speed2d": 7.854333333333334,
              "speed3d": 9.451666666666668,
              "msFromStart": 144263.9551111111
            },
            {
              "lat": 43.778632875,
              "lng": -116.08729514999999,
              "alt": 2002.5015,
              "speed2d": 8.303749999999999,
              "speed3d": 9.815,
              "msFromStart": 144757.37644444444
            },
            {
              "lat": 43.778665175,
              "lng": -116.08733627500001,
              "alt": 1998.909,
              "speed2d": 8.904,
              "speed3d": 10.395,
              "msFromStart": 145305.76985964915
            },
            {
              "lat": 43.77867608045834,
              "lng": -116.08735228156529,
              "alt": 1997.6368814074974,
              "speed2d": 9.0521384549396,
              "speed3d": 10.4995371865378,
              "msFromStart": 145500
            },
            {
              "lat": 43.77870066,
              "lng": -116.08741750000002,
              "alt": 1993.5140000000001,
              "speed2d": 8.57,
              "speed3d": 10.276,
              "msFromStart": 146190.72735438612
            },
            {
              "lat": 43.7787026,
              "lng": -116.08746305,
              "alt": 1991.311,
              "speed2d": 7.954,
              "speed3d": 9.73,
              "msFromStart": 146625.39644444437
            },
            {
              "lat": 43.7786932,
              "lng": -116.08754679999998,
              "alt": 1987.7983333333334,
              "speed2d": 8.236,
              "speed3d": 9.870000000000001,
              "msFromStart": 147368.00592592586
            },
            {
              "lat": 43.77868839999999,
              "lng": -116.08759069999999,
              "alt": 1986.0277500000002,
              "speed2d": 8.90225,
              "speed3d": 10.372499999999999,
              "msFromStart": 147724.68000000002
            },
            {
              "lat": 43.778682475,
              "lng": -116.0876605,
              "alt": 1983.019,
              "speed2d": 10.007750000000001,
              "speed3d": 11.4,
              "msFromStart": 148232.26266666668
            },
            {
              "lat": 43.778691314285716,
              "lng": -116.0877207,
              "alt": 1980.238,
              "speed2d": 10.362142857142858,
              "speed3d": 11.798571428571428,
              "msFromStart": 148694.11428571434
            },
            {
              "lat": 43.77870492499999,
              "lng": -116.087795675,
              "alt": 1976.747875,
              "speed2d": 10.468875,
              "speed3d": 11.92125,
              "msFromStart": 149264.22666666674
            },
            {
              "lat": 43.778722200000004,
              "lng": -116.0878521,
              "alt": 1973.5716666666667,
              "speed2d": 10.5915,
              "speed3d": 12.07,
              "msFromStart": 149732.11200000008
            },
            {
              "lat": 43.778739540000004,
              "lng": -116.08789646,
              "alt": 1970.7076000000002,
              "speed2d": 10.6622,
              "speed3d": 12.161999999999999,
              "msFromStart": 150139.4426385966
            },
            {
              "lat": 43.77877545714285,
              "lng": -116.08797837142858,
              "alt": 1968.4001428571428,
              "speed2d": 11.533714285714286,
              "speed3d": 12.772857142857141,
              "msFromStart": 150683.50989473696
            },
            {
              "lat": 43.77880165917732,
              "lng": -116.08804947753195,
              "alt": 1967.9439908476495,
              "speed2d": 12.522874057351936,
              "speed3d": 13.641578497632851,
              "msFromStart": 151000
            },
            {
              "lat": 43.77885895,
              "lng": -116.08823596250001,
              "alt": 1966.3283750000003,
              "speed2d": 14.262875000000001,
              "speed3d": 15.216249999999999,
              "msFromStart": 151794.4697777779
            },
            {
              "lat": 43.77888576666666,
              "lng": -116.08836415,
              "alt": 1965.4510000000002,
              "speed2d": 14.886,
              "speed3d": 15.751666666666665,
              "msFromStart": 152316.14162962965
            },
            {
              "lat": 43.77890735,
              "lng": -116.08847541666665,
              "alt": 1963.8935000000001,
              "speed2d": 15.465166666666667,
              "speed3d": 16.251666666666665,
              "msFromStart": 152784.2479999999
            },
            {
              "lat": 43.77892478,
              "lng": -116.08855525999999,
              "alt": 1961.9002000000003,
              "speed2d": 15.833200000000001,
              "speed3d": 16.642000000000003,
              "msFromStart": 153153.3182222221
            },
            {
              "lat": 43.778945780384426,
              "lng": -116.0886197387381,
              "alt": 1959.6564386361554,
              "speed2d": 15.947922334640072,
              "speed3d": 16.68385489734995,
              "msFromStart": 153500
            },
            {
              "lat": 43.77898001285712,
              "lng": -116.08870321039109,
              "alt": 1956.3279306821128,
              "speed2d": 15.69578943610994,
              "speed3d": 16.51206093843162,
              "msFromStart": 154000
            },
            {
              "lat": 43.77901424532982,
              "lng": -116.08878668204407,
              "alt": 1952.9994227280702,
              "speed2d": 15.443656537579807,
              "speed3d": 16.340266979513295,
              "msFromStart": 154500
            },
            {
              "lat": 43.779074333333334,
              "lng": -116.0889287,
              "alt": 1947.192,
              "speed2d": 15.087,
              "speed3d": 16.096666666666664,
              "msFromStart": 155360.17291228072
            },
            {
              "lat": 43.779106500000005,
              "lng": -116.0889866,
              "alt": 1944.7504,
              "speed2d": 15.416200000000002,
              "speed3d": 16.430000000000003,
              "msFromStart": 155716.9913263158
            },
            {
              "lat": 43.77914882500001,
              "lng": -116.0890687,
              "alt": 1941.70225,
              "speed2d": 15.940750000000001,
              "speed3d": 16.9375,
              "msFromStart": 156183.16556725145
            },
            {
              "lat": 43.7792177,
              "lng": -116.08920466666666,
              "alt": 1936.4036666666666,
              "speed2d": 16.829666666666668,
              "speed3d": 17.87,
              "msFromStart": 156938.0693333332
            },
            {
              "lat": 43.779250149999996,
              "lng": -116.0892536,
              "alt": 1934.075,
              "speed2d": 16.75775,
              "speed3d": 17.83,
              "msFromStart": 157267.50644444436
            },
            {
              "lat": 43.779305883333336,
              "lng": -116.08932553333332,
              "alt": 1930.1756666666665,
              "speed2d": 16.076,
              "speed3d": 17.288333333333334,
              "msFromStart": 157817.5022222221
            },
            {
              "lat": 43.779341433333336,
              "lng": -116.08937456666666,
              "alt": 1927.6316666666667,
              "speed2d": 15.674,
              "speed3d": 16.86,
              "msFromStart": 158184.32888888876
            },
            {
              "lat": 43.77939648333333,
              "lng": -116.08945776666667,
              "alt": 1923.0166666666667,
              "speed2d": 15.295333333333334,
              "speed3d": 16.55833333333333,
              "msFromStart": 158789.10133333324
            },
            {
              "lat": 43.77943244000001,
              "lng": -116.08953076,
              "alt": 1920.7728,
              "speed2d": 15.3102,
              "speed3d": 16.483999999999998,
              "msFromStart": 159225.2805333333
            },
            {
              "lat": 43.779461941546074,
              "lng": -116.08958510893662,
              "alt": 1920.8015913561915,
              "speed2d": 15.364264598748862,
              "speed3d": 16.442649331490422,
              "msFromStart": 159500
            },
            {
              "lat": 43.77948949195995,
              "lng": -116.08964183319705,
              "alt": 1919.9567389448234,
              "speed2d": 15.31468739020862,
              "speed3d": 16.384649449675237,
              "msFromStart": 160000
            },
            {
              "lat": 43.77951704237383,
              "lng": -116.08969855745748,
              "alt": 1919.1118865334556,
              "speed2d": 15.265110181668376,
              "speed3d": 16.32664956786005,
              "msFromStart": 160500
            },
            {
              "lat": 43.77954459278771,
              "lng": -116.0897552817179,
              "alt": 1918.2670341220878,
              "speed2d": 15.215532973128134,
              "speed3d": 16.26864968604486,
              "msFromStart": 161000
            },
            {
              "lat": 43.779572143201584,
              "lng": -116.08981200597833,
              "alt": 1917.4221817107198,
              "speed2d": 15.16595576458789,
              "speed3d": 16.21064980422967,
              "msFromStart": 161500
            },
            {
              "lat": 43.77959969361546,
              "lng": -116.08986873023876,
              "alt": 1916.577329299352,
              "speed2d": 15.116378556047648,
              "speed3d": 16.152649922414483,
              "msFromStart": 162000
            },
            {
              "lat": 43.77962724402934,
              "lng": -116.0899254544992,
              "alt": 1915.732476887984,
              "speed2d": 15.066801347507404,
              "speed3d": 16.094650040599298,
              "msFromStart": 162500
            },
            {
              "lat": 43.77965479444322,
              "lng": -116.08998217875961,
              "alt": 1914.887624476616,
              "speed2d": 15.01722413896716,
              "speed3d": 16.03665015878411,
              "msFromStart": 163000
            },
            {
              "lat": 43.77968234485709,
              "lng": -116.09003890302004,
              "alt": 1914.042772065248,
              "speed2d": 14.967646930426918,
              "speed3d": 15.97865027696892,
              "msFromStart": 163500
            },
            {
              "lat": 43.77970989527097,
              "lng": -116.09009562728048,
              "alt": 1913.1979196538803,
              "speed2d": 14.918069721886674,
              "speed3d": 15.920650395153732,
              "msFromStart": 164000
            },
            {
              "lat": 43.77973744568485,
              "lng": -116.0901523515409,
              "alt": 1912.3530672425125,
              "speed2d": 14.868492513346432,
              "speed3d": 15.862650513338545,
              "msFromStart": 164500
            },
            {
              "lat": 43.77976499609873,
              "lng": -116.09020907580133,
              "alt": 1911.5082148311444,
              "speed2d": 14.818915304806188,
              "speed3d": 15.804650631523357,
              "msFromStart": 165000
            },
            {
              "lat": 43.7797925465126,
              "lng": -116.09026580006176,
              "alt": 1910.6633624197766,
              "speed2d": 14.769338096265944,
              "speed3d": 15.74665074970817,
              "msFromStart": 165500
            },
            {
              "lat": 43.77982009692648,
              "lng": -116.09032252432218,
              "alt": 1909.8185100084086,
              "speed2d": 14.719760887725702,
              "speed3d": 15.688650867892981,
              "msFromStart": 166000
            },
            {
              "lat": 43.77984764734036,
              "lng": -116.09037924858261,
              "alt": 1908.9736575970408,
              "speed2d": 14.670183679185458,
              "speed3d": 15.630650986077793,
              "msFromStart": 166500
            },
            {
              "lat": 43.77987519775424,
              "lng": -116.09043597284304,
              "alt": 1908.1288051856727,
              "speed2d": 14.620606470645216,
              "speed3d": 15.572651104262606,
              "msFromStart": 167000
            }
          ]
        }
      ]
    },
    {
      "id": "trail-comeback",
      "pathType": "trail",
      "name": "Comeback",
      "difficulty": "blue",
      "videos": [
        {
          "videoId": "GX010067",
          "pathId": "trail-comeback",
          "startSec": 167,
          "endSec": 212,
          "id": "video-segment-29",
          "gps": [
            {
              "lat": 43.77987519775424,
              "lng": -116.09043597284304,
              "alt": 1908.1288051856727,
              "speed2d": 14.620606470645216,
              "speed3d": 15.572651104262606,
              "msFromStart": 167000
            },
            {
              "lat": 43.77990274816811,
              "lng": -116.09049269710346,
              "alt": 1907.283952774305,
              "speed2d": 14.571029262104972,
              "speed3d": 15.514651222447418,
              "msFromStart": 167500
            },
            {
              "lat": 43.77993029858199,
              "lng": -116.09054942136389,
              "alt": 1906.4391003629369,
              "speed2d": 14.52145205356473,
              "speed3d": 15.45665134063223,
              "msFromStart": 168000
            },
            {
              "lat": 43.77995784899587,
              "lng": -116.09060614562432,
              "alt": 1905.594247951569,
              "speed2d": 14.471874845024486,
              "speed3d": 15.398651458817042,
              "msFromStart": 168500
            },
            {
              "lat": 43.779985399409746,
              "lng": -116.09066286988475,
              "alt": 1904.7493955402012,
              "speed2d": 14.422297636484242,
              "speed3d": 15.340651577001854,
              "msFromStart": 169000
            },
            {
              "lat": 43.78001294982362,
              "lng": -116.09071959414517,
              "alt": 1903.9045431288332,
              "speed2d": 14.372720427944,
              "speed3d": 15.282651695186667,
              "msFromStart": 169500
            },
            {
              "lat": 43.7800405002375,
              "lng": -116.0907763184056,
              "alt": 1903.0596907174654,
              "speed2d": 14.323143219403756,
              "speed3d": 15.224651813371478,
              "msFromStart": 170000
            },
            {
              "lat": 43.78006805065138,
              "lng": -116.09083304266603,
              "alt": 1902.2148383060974,
              "speed2d": 14.273566010863513,
              "speed3d": 15.166651931556292,
              "msFromStart": 170500
            },
            {
              "lat": 43.780095601065256,
              "lng": -116.09088976692645,
              "alt": 1901.3699858947296,
              "speed2d": 14.22398880232327,
              "speed3d": 15.108652049741103,
              "msFromStart": 171000
            },
            {
              "lat": 43.78012315147913,
              "lng": -116.09094649118688,
              "alt": 1900.5251334833615,
              "speed2d": 14.174411593783027,
              "speed3d": 15.050652167925916,
              "msFromStart": 171500
            },
            {
              "lat": 43.78015070189301,
              "lng": -116.09100321544732,
              "alt": 1899.6802810719937,
              "speed2d": 14.124834385242783,
              "speed3d": 14.992652286110728,
              "msFromStart": 172000
            },
            {
              "lat": 43.78017825230689,
              "lng": -116.09105993970773,
              "alt": 1898.835428660626,
              "speed2d": 14.07525717670254,
              "speed3d": 14.93465240429554,
              "msFromStart": 172500
            },
            {
              "lat": 43.780205802720765,
              "lng": -116.09111666396817,
              "alt": 1897.9905762492579,
              "speed2d": 14.025679968162297,
              "speed3d": 14.876652522480352,
              "msFromStart": 173000
            },
            {
              "lat": 43.78023335313464,
              "lng": -116.0911733882286,
              "alt": 1897.14572383789,
              "speed2d": 13.976102759622053,
              "speed3d": 14.818652640665164,
              "msFromStart": 173500
            },
            {
              "lat": 43.780260903548516,
              "lng": -116.09123011248901,
              "alt": 1896.300871426522,
              "speed2d": 13.926525551081811,
              "speed3d": 14.760652758849977,
              "msFromStart": 174000
            },
            {
              "lat": 43.7802884539624,
              "lng": -116.09128683674945,
              "alt": 1895.4560190151542,
              "speed2d": 13.876948342541567,
              "speed3d": 14.702652877034788,
              "msFromStart": 174500
            },
            {
              "lat": 43.780316004376274,
              "lng": -116.09134356100988,
              "alt": 1894.6111666037862,
              "speed2d": 13.827371134001325,
              "speed3d": 14.644652995219602,
              "msFromStart": 175000
            },
            {
              "lat": 43.78034355479015,
              "lng": -116.09140028527031,
              "alt": 1893.7663141924183,
              "speed2d": 13.777793925461081,
              "speed3d": 14.586653113404413,
              "msFromStart": 175500
            },
            {
              "lat": 43.78037110520403,
              "lng": -116.09145700953073,
              "alt": 1892.9214617810503,
              "speed2d": 13.728216716920837,
              "speed3d": 14.528653231589225,
              "msFromStart": 176000
            },
            {
              "lat": 43.78039865561791,
              "lng": -116.09151373379116,
              "alt": 1892.0766093696825,
              "speed2d": 13.678639508380595,
              "speed3d": 14.470653349774038,
              "msFromStart": 176500
            },
            {
              "lat": 43.780426206031784,
              "lng": -116.09157045805159,
              "alt": 1891.2317569583147,
              "speed2d": 13.629062299840351,
              "speed3d": 14.41265346795885,
              "msFromStart": 177000
            },
            {
              "lat": 43.78045375644566,
              "lng": -116.09162718231201,
              "alt": 1890.3869045469467,
              "speed2d": 13.579485091300109,
              "speed3d": 14.354653586143662,
              "msFromStart": 177500
            },
            {
              "lat": 43.78048130685954,
              "lng": -116.09168390657244,
              "alt": 1889.5420521355788,
              "speed2d": 13.529907882759865,
              "speed3d": 14.296653704328474,
              "msFromStart": 178000
            },
            {
              "lat": 43.78050885727342,
              "lng": -116.09174063083287,
              "alt": 1888.6971997242108,
              "speed2d": 13.480330674219621,
              "speed3d": 14.238653822513285,
              "msFromStart": 178500
            },
            {
              "lat": 43.78053640768729,
              "lng": -116.09179735509329,
              "alt": 1887.852347312843,
              "speed2d": 13.430753465679379,
              "speed3d": 14.180653940698098,
              "msFromStart": 179000
            },
            {
              "lat": 43.78056395810117,
              "lng": -116.09185407935372,
              "alt": 1887.007494901475,
              "speed2d": 13.381176257139135,
              "speed3d": 14.12265405888291,
              "msFromStart": 179500
            },
            {
              "lat": 43.78059150851505,
              "lng": -116.09191080361416,
              "alt": 1886.1626424901071,
              "speed2d": 13.331599048598893,
              "speed3d": 14.064654177067723,
              "msFromStart": 180000
            },
            {
              "lat": 43.78061905892893,
              "lng": -116.09196752787457,
              "alt": 1885.3177900787393,
              "speed2d": 13.282021840058649,
              "speed3d": 14.006654295252535,
              "msFromStart": 180500
            },
            {
              "lat": 43.7806466093428,
              "lng": -116.092024252135,
              "alt": 1884.4729376673713,
              "speed2d": 13.232444631518405,
              "speed3d": 13.948654413437346,
              "msFromStart": 181000
            },
            {
              "lat": 43.78067415975668,
              "lng": -116.09208097639544,
              "alt": 1883.6280852560035,
              "speed2d": 13.182867422978163,
              "speed3d": 13.89065453162216,
              "msFromStart": 181500
            },
            {
              "lat": 43.78070171017056,
              "lng": -116.09213770065587,
              "alt": 1882.7832328446354,
              "speed2d": 13.133290214437919,
              "speed3d": 13.83265464980697,
              "msFromStart": 182000
            },
            {
              "lat": 43.78072926058444,
              "lng": -116.09219442491629,
              "alt": 1881.9383804332676,
              "speed2d": 13.083713005897676,
              "speed3d": 13.774654767991784,
              "msFromStart": 182500
            },
            {
              "lat": 43.78075681099831,
              "lng": -116.09225114917672,
              "alt": 1881.0935280218996,
              "speed2d": 13.034135797357433,
              "speed3d": 13.716654886176595,
              "msFromStart": 183000
            },
            {
              "lat": 43.78078436141219,
              "lng": -116.09230787343715,
              "alt": 1880.2486756105318,
              "speed2d": 12.98455858881719,
              "speed3d": 13.658655004361407,
              "msFromStart": 183500
            },
            {
              "lat": 43.78081191182607,
              "lng": -116.09236459769757,
              "alt": 1879.4038231991638,
              "speed2d": 12.934981380276946,
              "speed3d": 13.60065512254622,
              "msFromStart": 184000
            },
            {
              "lat": 43.780839462239946,
              "lng": -116.092421321958,
              "alt": 1878.558970787796,
              "speed2d": 12.885404171736702,
              "speed3d": 13.542655240731033,
              "msFromStart": 184500
            },
            {
              "lat": 43.78086701265382,
              "lng": -116.09247804621843,
              "alt": 1877.7141183764281,
              "speed2d": 12.83582696319646,
              "speed3d": 13.484655358915845,
              "msFromStart": 185000
            },
            {
              "lat": 43.7808945630677,
              "lng": -116.09253477047885,
              "alt": 1876.86926596506,
              "speed2d": 12.786249754656216,
              "speed3d": 13.426655477100656,
              "msFromStart": 185500
            },
            {
              "lat": 43.78092211348158,
              "lng": -116.09259149473928,
              "alt": 1876.0244135536923,
              "speed2d": 12.736672546115974,
              "speed3d": 13.36865559528547,
              "msFromStart": 186000
            },
            {
              "lat": 43.780949663895456,
              "lng": -116.09264821899971,
              "alt": 1875.1795611423242,
              "speed2d": 12.68709533757573,
              "speed3d": 13.31065571347028,
              "msFromStart": 186500
            },
            {
              "lat": 43.78097721430933,
              "lng": -116.09270494326013,
              "alt": 1874.3347087309564,
              "speed2d": 12.637518129035488,
              "speed3d": 13.252655831655094,
              "msFromStart": 187000
            },
            {
              "lat": 43.78100476472321,
              "lng": -116.09276166752056,
              "alt": 1873.4898563195884,
              "speed2d": 12.587940920495244,
              "speed3d": 13.194655949839905,
              "msFromStart": 187500
            },
            {
              "lat": 43.78103231513709,
              "lng": -116.092818391781,
              "alt": 1872.6450039082206,
              "speed2d": 12.538363711955,
              "speed3d": 13.136656068024717,
              "msFromStart": 188000
            },
            {
              "lat": 43.781059865550965,
              "lng": -116.09287511604143,
              "alt": 1871.8001514968528,
              "speed2d": 12.488786503414758,
              "speed3d": 13.07865618620953,
              "msFromStart": 188500
            },
            {
              "lat": 43.78108741596484,
              "lng": -116.09293184030184,
              "alt": 1870.9552990854847,
              "speed2d": 12.439209294874516,
              "speed3d": 13.020656304394342,
              "msFromStart": 189000
            },
            {
              "lat": 43.781114966378716,
              "lng": -116.09298856456228,
              "alt": 1870.110446674117,
              "speed2d": 12.389632086334272,
              "speed3d": 12.962656422579155,
              "msFromStart": 189500
            },
            {
              "lat": 43.7811425167926,
              "lng": -116.09304528882271,
              "alt": 1869.265594262749,
              "speed2d": 12.340054877794028,
              "speed3d": 12.904656540763966,
              "msFromStart": 190000
            },
            {
              "lat": 43.781170067206475,
              "lng": -116.09310201308313,
              "alt": 1868.420741851381,
              "speed2d": 12.290477669253786,
              "speed3d": 12.846656658948778,
              "msFromStart": 190500
            },
            {
              "lat": 43.78119761762035,
              "lng": -116.09315873734356,
              "alt": 1867.575889440013,
              "speed2d": 12.240900460713542,
              "speed3d": 12.78865677713359,
              "msFromStart": 191000
            },
            {
              "lat": 43.781225168034226,
              "lng": -116.09321546160399,
              "alt": 1866.7310370286452,
              "speed2d": 12.191323252173298,
              "speed3d": 12.730656895318402,
              "msFromStart": 191500
            },
            {
              "lat": 43.78125271844811,
              "lng": -116.09327218586441,
              "alt": 1865.8861846172772,
              "speed2d": 12.141746043633056,
              "speed3d": 12.672657013503215,
              "msFromStart": 192000
            },
            {
              "lat": 43.781280268861984,
              "lng": -116.09332891012484,
              "alt": 1865.0413322059094,
              "speed2d": 12.092168835092812,
              "speed3d": 12.614657131688027,
              "msFromStart": 192500
            },
            {
              "lat": 43.78130781927586,
              "lng": -116.09338563438527,
              "alt": 1864.1964797945416,
              "speed2d": 12.04259162655257,
              "speed3d": 12.55665724987284,
              "msFromStart": 193000
            },
            {
              "lat": 43.781335369689735,
              "lng": -116.09344235864569,
              "alt": 1863.3516273831735,
              "speed2d": 11.993014418012326,
              "speed3d": 12.498657368057652,
              "msFromStart": 193500
            },
            {
              "lat": 43.78136292010362,
              "lng": -116.09349908290612,
              "alt": 1862.5067749718057,
              "speed2d": 11.943437209472084,
              "speed3d": 12.440657486242463,
              "msFromStart": 194000
            },
            {
              "lat": 43.78139047051749,
              "lng": -116.09355580716655,
              "alt": 1861.6619225604377,
              "speed2d": 11.89386000093184,
              "speed3d": 12.382657604427276,
              "msFromStart": 194500
            },
            {
              "lat": 43.78141802093137,
              "lng": -116.09361253142698,
              "alt": 1860.8170701490699,
              "speed2d": 11.844282792391596,
              "speed3d": 12.324657722612088,
              "msFromStart": 195000
            },
            {
              "lat": 43.78144557134525,
              "lng": -116.0936692556874,
              "alt": 1859.9722177377018,
              "speed2d": 11.794705583851353,
              "speed3d": 12.266657840796901,
              "msFromStart": 195500
            },
            {
              "lat": 43.78147312175913,
              "lng": -116.09372597994783,
              "alt": 1859.127365326334,
              "speed2d": 11.74512837531111,
              "speed3d": 12.208657958981712,
              "msFromStart": 196000
            },
            {
              "lat": 43.781500672173,
              "lng": -116.09378270420827,
              "alt": 1858.2825129149662,
              "speed2d": 11.695551166770867,
              "speed3d": 12.150658077166526,
              "msFromStart": 196500
            },
            {
              "lat": 43.78152822258688,
              "lng": -116.09383942846868,
              "alt": 1857.4376605035982,
              "speed2d": 11.645973958230623,
              "speed3d": 12.092658195351337,
              "msFromStart": 197000
            },
            {
              "lat": 43.78155577300076,
              "lng": -116.09389615272912,
              "alt": 1856.5928080922304,
              "speed2d": 11.596396749690381,
              "speed3d": 12.034658313536148,
              "msFromStart": 197500
            },
            {
              "lat": 43.78158332341464,
              "lng": -116.09395287698955,
              "alt": 1855.7479556808623,
              "speed2d": 11.546819541150137,
              "speed3d": 11.97665843172096,
              "msFromStart": 198000
            },
            {
              "lat": 43.78161087382851,
              "lng": -116.09400960124997,
              "alt": 1854.9031032694945,
              "speed2d": 11.497242332609893,
              "speed3d": 11.918658549905773,
              "msFromStart": 198500
            },
            {
              "lat": 43.78163842424239,
              "lng": -116.0940663255104,
              "alt": 1854.0582508581265,
              "speed2d": 11.447665124069651,
              "speed3d": 11.860658668090586,
              "msFromStart": 199000
            },
            {
              "lat": 43.78166597465627,
              "lng": -116.09412304977083,
              "alt": 1853.2133984467587,
              "speed2d": 11.398087915529407,
              "speed3d": 11.802658786275398,
              "msFromStart": 199500
            },
            {
              "lat": 43.781693525070146,
              "lng": -116.09417977403126,
              "alt": 1852.3685460353909,
              "speed2d": 11.348510706989163,
              "speed3d": 11.74465890446021,
              "msFromStart": 200000
            },
            {
              "lat": 43.78172107548402,
              "lng": -116.09423649829168,
              "alt": 1851.5236936240228,
              "speed2d": 11.298933498448921,
              "speed3d": 11.686659022645022,
              "msFromStart": 200500
            },
            {
              "lat": 43.7817486258979,
              "lng": -116.09429322255211,
              "alt": 1850.678841212655,
              "speed2d": 11.249356289908679,
              "speed3d": 11.628659140829834,
              "msFromStart": 201000
            },
            {
              "lat": 43.78177617631178,
              "lng": -116.09434994681254,
              "alt": 1849.833988801287,
              "speed2d": 11.199779081368433,
              "speed3d": 11.570659259014647,
              "msFromStart": 201500
            },
            {
              "lat": 43.781803726725656,
              "lng": -116.09440667107296,
              "alt": 1848.9891363899192,
              "speed2d": 11.150201872828191,
              "speed3d": 11.512659377199459,
              "msFromStart": 202000
            },
            {
              "lat": 43.78183127713953,
              "lng": -116.09446339533339,
              "alt": 1848.1442839785511,
              "speed2d": 11.100624664287949,
              "speed3d": 11.45465949538427,
              "msFromStart": 202500
            },
            {
              "lat": 43.78185882755341,
              "lng": -116.09452011959382,
              "alt": 1847.2994315671833,
              "speed2d": 11.051047455747705,
              "speed3d": 11.396659613569083,
              "msFromStart": 203000
            },
            {
              "lat": 43.78188637796729,
              "lng": -116.09457684385424,
              "alt": 1846.4545791558153,
              "speed2d": 11.001470247207461,
              "speed3d": 11.338659731753896,
              "msFromStart": 203500
            },
            {
              "lat": 43.781913928381165,
              "lng": -116.09463356811467,
              "alt": 1845.6097267444475,
              "speed2d": 10.951893038667219,
              "speed3d": 11.280659849938708,
              "msFromStart": 204000
            },
            {
              "lat": 43.78194147879504,
              "lng": -116.0946902923751,
              "alt": 1844.7648743330797,
              "speed2d": 10.902315830126977,
              "speed3d": 11.22265996812352,
              "msFromStart": 204500
            },
            {
              "lat": 43.781969029208916,
              "lng": -116.09474701663552,
              "alt": 1843.9200219217116,
              "speed2d": 10.852738621586731,
              "speed3d": 11.16466008630833,
              "msFromStart": 205000
            },
            {
              "lat": 43.7819965796228,
              "lng": -116.09480374089596,
              "alt": 1843.0751695103438,
              "speed2d": 10.803161413046489,
              "speed3d": 11.106660204493144,
              "msFromStart": 205500
            },
            {
              "lat": 43.782024130036675,
              "lng": -116.09486046515639,
              "alt": 1842.2303170989758,
              "speed2d": 10.753584204506247,
              "speed3d": 11.048660322677955,
              "msFromStart": 206000
            },
            {
              "lat": 43.78205168045055,
              "lng": -116.09491718941682,
              "alt": 1841.385464687608,
              "speed2d": 10.704006995966003,
              "speed3d": 10.990660440862769,
              "msFromStart": 206500
            },
            {
              "lat": 43.782079230864426,
              "lng": -116.09497391367724,
              "alt": 1840.54061227624,
              "speed2d": 10.654429787425759,
              "speed3d": 10.93266055904758,
              "msFromStart": 207000
            },
            {
              "lat": 43.78210678127831,
              "lng": -116.09503063793767,
              "alt": 1839.6957598648721,
              "speed2d": 10.604852578885517,
              "speed3d": 10.874660677232391,
              "msFromStart": 207500
            },
            {
              "lat": 43.782134331692184,
              "lng": -116.0950873621981,
              "alt": 1838.850907453504,
              "speed2d": 10.555275370345274,
              "speed3d": 10.816660795417205,
              "msFromStart": 208000
            },
            {
              "lat": 43.78216188210606,
              "lng": -116.09514408645852,
              "alt": 1838.0060550421363,
              "speed2d": 10.505698161805029,
              "speed3d": 10.758660913602018,
              "msFromStart": 208500
            },
            {
              "lat": 43.782189432519935,
              "lng": -116.09520081071895,
              "alt": 1837.1612026307685,
              "speed2d": 10.456120953264787,
              "speed3d": 10.70066103178683,
              "msFromStart": 209000
            },
            {
              "lat": 43.78221698293382,
              "lng": -116.09525753497938,
              "alt": 1836.3163502194004,
              "speed2d": 10.406543744724544,
              "speed3d": 10.64266114997164,
              "msFromStart": 209500
            },
            {
              "lat": 43.78224453334769,
              "lng": -116.0953142592398,
              "alt": 1835.4714978080326,
              "speed2d": 10.3569665361843,
              "speed3d": 10.584661268156454,
              "msFromStart": 210000
            },
            {
              "lat": 43.78227208376157,
              "lng": -116.09537098350023,
              "alt": 1834.6266453966646,
              "speed2d": 10.307389327644056,
              "speed3d": 10.526661386341265,
              "msFromStart": 210500
            },
            {
              "lat": 43.782299634175445,
              "lng": -116.09542770776066,
              "alt": 1833.7817929852968,
              "speed2d": 10.257812119103814,
              "speed3d": 10.468661504526079,
              "msFromStart": 211000
            },
            {
              "lat": 43.78232718458933,
              "lng": -116.09548443202108,
              "alt": 1832.9369405739287,
              "speed2d": 10.20823491056357,
              "speed3d": 10.41066162271089,
              "msFromStart": 211500
            },
            {
              "lat": 43.7823547350032,
              "lng": -116.09554115628151,
              "alt": 1832.092088162561,
              "speed2d": 10.158657702023326,
              "speed3d": 10.352661740895702,
              "msFromStart": 212000
            }
          ]
        }
      ]
    },
    {
      "id": "trail-nighthawk",
      "pathType": "trail",
      "name": "Night Hawk",
      "difficulty": "black",
      "videos": [
        {
          "videoId": "GX010088",
          "pathId": "trail-nighthawk",
          "startSec": 45,
          "endSec": 134,
          "id": "video-segment-55",
          "gps": [
            {
              "lat": 43.77260657777778,
              "lng": -116.09089513333333,
              "alt": 2270.772111111111,
              "speed2d": 3.559,
              "speed3d": 3.727777777777778,
              "msFromStart": 45244.68096296295
            },
            {
              "lat": 43.77261963333333,
              "lng": -116.09088772222222,
              "alt": 2268.865444444444,
              "speed2d": 3.501333333333333,
              "speed3d": 3.6855555555555553,
              "msFromStart": 45740.27111111108
            },
            {
              "lat": 43.77263427777778,
              "lng": -116.09087819999999,
              "alt": 2266.813777777778,
              "speed2d": 3.844888888888889,
              "speed3d": 4.04,
              "msFromStart": 46235.729037037025
            },
            {
              "lat": 43.772654133333326,
              "lng": -116.09085975555556,
              "alt": 2264.691333333333,
              "speed2d": 5.1482222222222225,
              "speed3d": 5.256666666666666,
              "msFromStart": 46730.53066666669
            },
            {
              "lat": 43.7726727,
              "lng": -116.0908355888889,
              "alt": 2263.103111111111,
              "speed2d": 5.713333333333333,
              "speed3d": 5.923333333333333,
              "msFromStart": 47225.25777777779
            },
            {
              "lat": 43.77268977,
              "lng": -116.09080285,
              "alt": 2261.8166,
              "speed2d": 6.2188,
              "speed3d": 6.422000000000001,
              "msFromStart": 47747.432
            },
            {
              "lat": 43.7727077111111,
              "lng": -116.09076639999998,
              "alt": 2259.9645555555558,
              "speed2d": 6.561888888888888,
              "speed3d": 6.817777777777778,
              "msFromStart": 48269.54994152046
            },
            {
              "lat": 43.772730700000004,
              "lng": -116.09073486666666,
              "alt": 2258.2614444444444,
              "speed2d": 6.9159999999999995,
              "speed3d": 7.171111111111111,
              "msFromStart": 48764.04210526312
            },
            {
              "lat": 43.77275374444445,
              "lng": -116.09070904444445,
              "alt": 2256.923222222222,
              "speed2d": 6.596444444444444,
              "speed3d": 7.004444444444444,
              "msFromStart": 49258.73143469784
            },
            {
              "lat": 43.77277581111112,
              "lng": -116.09069697777778,
              "alt": 2255.5894444444443,
              "speed2d": 5.670888888888888,
              "speed3d": 6.142222222222223,
              "msFromStart": 49754.23955555557
            },
            {
              "lat": 43.77280232222222,
              "lng": -116.09070320000001,
              "alt": 2254.2065555555555,
              "speed2d": 5.572,
              "speed3d": 5.914444444444445,
              "msFromStart": 50249.6002962963
            },
            {
              "lat": 43.77283255555555,
              "lng": -116.09070478888889,
              "alt": 2252.474222222222,
              "speed2d": 6.190444444444443,
              "speed3d": 6.49,
              "msFromStart": 50744.044
            },
            {
              "lat": 43.772868655555556,
              "lng": -116.09069572222225,
              "alt": 2250.469888888889,
              "speed2d": 7.330555555555556,
              "speed3d": 7.602222222222222,
              "msFromStart": 51238.44525925926
            },
            {
              "lat": 43.772897844444444,
              "lng": -116.09068096666668,
              "alt": 2248.4303333333332,
              "speed2d": 7.382555555555555,
              "speed3d": 7.791111111111111,
              "msFromStart": 51733.04355555557
            },
            {
              "lat": 43.772921499999995,
              "lng": -116.0906593888889,
              "alt": 2246.077222222222,
              "speed2d": 7.265777777777778,
              "speed3d": 7.745555555555556,
              "msFromStart": 52227.977851851865
            },
            {
              "lat": 43.772946499999996,
              "lng": -116.09063332,
              "alt": 2243.3638,
              "speed2d": 7.282,
              "speed3d": 7.779,
              "msFromStart": 52751.751000000004
            },
            {
              "lat": 43.77297891111111,
              "lng": -116.09061184444445,
              "alt": 2241.3816666666667,
              "speed2d": 7.377777777777779,
              "speed3d": 7.917777777777777,
              "msFromStart": 53275.39244444445
            },
            {
              "lat": 43.7730206,
              "lng": -116.09061047777779,
              "alt": 2240.099,
              "speed2d": 7.6209999999999996,
              "speed3d": 8.108888888888888,
              "msFromStart": 53770.759333333335
            },
            {
              "lat": 43.77306151111111,
              "lng": -116.09061494444444,
              "alt": 2238.5074444444444,
              "speed2d": 7.992555555555556,
              "speed3d": 8.447777777777777,
              "msFromStart": 54266.19879532165
            },
            {
              "lat": 43.7731038888889,
              "lng": -116.09061000000001,
              "alt": 2236.4727777777775,
              "speed2d": 8.690666666666667,
              "speed3d": 9.081111111111111,
              "msFromStart": 54761.93536842109
            },
            {
              "lat": 43.773148388888885,
              "lng": -116.0905928,
              "alt": 2234.6444444444446,
              "speed2d": 9.461777777777776,
              "speed3d": 9.866666666666667,
              "msFromStart": 55257.591614035104
            },
            {
              "lat": 43.773190955555556,
              "lng": -116.09056612222221,
              "alt": 2232.7273333333333,
              "speed2d": 10.108444444444444,
              "speed3d": 10.505555555555556,
              "msFromStart": 55752.86399999999
            },
            {
              "lat": 43.77323628888889,
              "lng": -116.09053863333334,
              "alt": 2230.573888888889,
              "speed2d": 10.437222222222221,
              "speed3d": 10.89,
              "msFromStart": 56248.18503703702
            },
            {
              "lat": 43.77328242222222,
              "lng": -116.09052196666666,
              "alt": 2228.627,
              "speed2d": 10.22611111111111,
              "speed3d": 10.774444444444445,
              "msFromStart": 56743.85422222219
            },
            {
              "lat": 43.77332303333333,
              "lng": -116.09052550000001,
              "alt": 2226.906111111111,
              "speed2d": 9.472777777777777,
              "speed3d": 10.046666666666665,
              "msFromStart": 57239.20748148146
            },
            {
              "lat": 43.77336932222222,
              "lng": -116.0905385111111,
              "alt": 2225.634888888889,
              "speed2d": 9.531777777777776,
              "speed3d": 10.016666666666666,
              "msFromStart": 57733.15777777778
            },
            {
              "lat": 43.773412199999996,
              "lng": -116.09055241111112,
              "alt": 2224.974111111111,
              "speed2d": 9.398222222222222,
              "speed3d": 9.891111111111112,
              "msFromStart": 58226.99551656919
            },
            {
              "lat": 43.773464270000005,
              "lng": -116.09055864,
              "alt": 2223.8464,
              "speed2d": 9.8861,
              "speed3d": 10.232,
              "msFromStart": 58748.38642105261
            },
            {
              "lat": 43.773517844444456,
              "lng": -116.09055614444446,
              "alt": 2222.307333333333,
              "speed2d": 10.397666666666666,
              "speed3d": 10.775555555555556,
              "msFromStart": 59270.08549707601
            },
            {
              "lat": 43.77356686666666,
              "lng": -116.09054390000001,
              "alt": 2220.709111111111,
              "speed2d": 10.923555555555556,
              "speed3d": 11.296666666666667,
              "msFromStart": 59765.53066666666
            },
            {
              "lat": 43.773601855555555,
              "lng": -116.09051954444446,
              "alt": 2218.7716666666665,
              "speed2d": 10.572333333333333,
              "speed3d": 11.085555555555555,
              "msFromStart": 60260.89503703702
            },
            {
              "lat": 43.77360856666667,
              "lng": -116.09048032222223,
              "alt": 2216.145,
              "speed2d": 9.162666666666665,
              "speed3d": 9.887777777777778,
              "msFromStart": 60755.46088888887
            },
            {
              "lat": 43.77359846666667,
              "lng": -116.09043785555556,
              "alt": 2212.6825555555556,
              "speed2d": 7.468999999999999,
              "speed3d": 8.301111111111112,
              "msFromStart": 61249.965407407384
            },
            {
              "lat": 43.7736009,
              "lng": -116.09041056666668,
              "alt": 2209.6676666666663,
              "speed2d": 6.1931111111111115,
              "speed3d": 7.262222222222222,
              "msFromStart": 61744.54399999997
            },
            {
              "lat": 43.773597233333334,
              "lng": -116.0903839,
              "alt": 2206.9566666666665,
              "speed2d": 5.147,
              "speed3d": 6.318888888888889,
              "msFromStart": 62239.23592592591
            },
            {
              "lat": 43.773597833333326,
              "lng": -116.09035804444444,
              "alt": 2205.0404444444443,
              "speed2d": 4.556555555555555,
              "speed3d": 5.844444444444444,
              "msFromStart": 62734.35155555555
            },
            {
              "lat": 43.77359747777778,
              "lng": -116.09032862222223,
              "alt": 2203.3977777777777,
              "speed2d": 4.485333333333333,
              "speed3d": 5.725555555555555,
              "msFromStart": 63229.4897699805
            },
            {
              "lat": 43.77360904000001,
              "lng": -116.09029981,
              "alt": 2202.1386,
              "speed2d": 4.3553,
              "speed3d": 5.6370000000000005,
              "msFromStart": 63752.05168421052
            },
            {
              "lat": 43.77361845555556,
              "lng": -116.09027827777777,
              "alt": 2200.911111111111,
              "speed2d": 3.7350000000000003,
              "speed3d": 5.183333333333334,
              "msFromStart": 64274.41398830409
            },
            {
              "lat": 43.77362748888889,
              "lng": -116.09026990000001,
              "alt": 2199.384222222222,
              "speed2d": 2.7384444444444442,
              "speed3d": 4.433333333333334,
              "msFromStart": 64768.50666666665
            },
            {
              "lat": 43.77364435555556,
              "lng": -116.09027130000001,
              "alt": 2198.6736666666666,
              "speed2d": 2.0592222222222225,
              "speed3d": 3.9199999999999995,
              "msFromStart": 65262.88155555555
            },
            {
              "lat": 43.773658,
              "lng": -116.09028118888888,
              "alt": 2197.5155555555552,
              "speed2d": 2.091222222222222,
              "speed3d": 3.781111111111111,
              "msFromStart": 65758.692
            },
            {
              "lat": 43.773676511111105,
              "lng": -116.09029622222221,
              "alt": 2196.0698888888887,
              "speed2d": 3.1096666666666666,
              "speed3d": 4.34,
              "msFromStart": 66254.49044444444
            },
            {
              "lat": 43.773694766666665,
              "lng": -116.09031211111112,
              "alt": 2194.936333333333,
              "speed2d": 3.660555555555556,
              "speed3d": 4.756666666666667,
              "msFromStart": 66749.66666666661
            },
            {
              "lat": 43.773708933333324,
              "lng": -116.09032126666668,
              "alt": 2195.0393333333336,
              "speed2d": 3.426888888888889,
              "speed3d": 4.5233333333333325,
              "msFromStart": 67244.79437037033
            },
            {
              "lat": 43.77371548888889,
              "lng": -116.09033295555557,
              "alt": 2194.5352222222223,
              "speed2d": 3.1042222222222224,
              "speed3d": 4.154444444444444,
              "msFromStart": 67739.97688888888
            },
            {
              "lat": 43.77372196666666,
              "lng": -116.09034392222222,
              "alt": 2194.7760000000003,
              "speed2d": 2.966222222222222,
              "speed3d": 3.9311111111111106,
              "msFromStart": 68235.10902144248
            },
            {
              "lat": 43.77372654444444,
              "lng": -116.09035406666666,
              "alt": 2195.7004444444447,
              "speed2d": 2.517,
              "speed3d": 3.4811111111111113,
              "msFromStart": 68730.01768421047
            },
            {
              "lat": 43.77373420000001,
              "lng": -116.09036071,
              "alt": 2198.0861999999997,
              "speed2d": 2.0584000000000002,
              "speed3d": 2.787,
              "msFromStart": 69252.35929824557
            },
            {
              "lat": 43.77373927777777,
              "lng": -116.09037331111112,
              "alt": 2198.278,
              "speed2d": 2.1558888888888887,
              "speed3d": 2.7744444444444447,
              "msFromStart": 69774.52177777774
            },
            {
              "lat": 43.773743933333336,
              "lng": -116.09037945555556,
              "alt": 2198.0337777777777,
              "speed2d": 1.9002222222222218,
              "speed3d": 2.5377777777777775,
              "msFromStart": 70269.43555555555
            },
            {
              "lat": 43.77374994444445,
              "lng": -116.09038312222219,
              "alt": 2197.798222222222,
              "speed2d": 1.6108888888888888,
              "speed3d": 2.2688888888888887,
              "msFromStart": 70765.38044444445
            },
            {
              "lat": 43.773756766666665,
              "lng": -116.0903839,
              "alt": 2197.534888888889,
              "speed2d": 1.558111111111111,
              "speed3d": 2.172222222222222,
              "msFromStart": 71261.1314074074
            },
            {
              "lat": 43.773763322222216,
              "lng": -116.09038612222223,
              "alt": 2197.3340000000003,
              "speed2d": 1.7286666666666668,
              "speed3d": 2.2288888888888887,
              "msFromStart": 71755.6942222222
            },
            {
              "lat": 43.7737684,
              "lng": -116.09039220000003,
              "alt": 2197.0897777777777,
              "speed2d": 1.8618888888888887,
              "speed3d": 2.3099999999999996,
              "msFromStart": 72250.19896296294
            },
            {
              "lat": 43.77377336666667,
              "lng": -116.09039827777778,
              "alt": 2196.749777777778,
              "speed2d": 1.9583333333333335,
              "speed3d": 2.3877777777777776,
              "msFromStart": 72744.94666666661
            },
            {
              "lat": 43.77377792222222,
              "lng": -116.09040167777778,
              "alt": 2195.9686666666666,
              "speed2d": 1.6528888888888889,
              "speed3d": 2.161111111111111,
              "msFromStart": 73239.82053801167
            },
            {
              "lat": 43.77378322222222,
              "lng": -116.090407,
              "alt": 2195.1067777777776,
              "speed2d": 1.628777777777778,
              "speed3d": 2.1644444444444444,
              "msFromStart": 73735.10189473681
            },
            {
              "lat": 43.773788277777776,
              "lng": -116.09040864444445,
              "alt": 2193.8280000000004,
              "speed2d": 1.4552222222222222,
              "speed3d": 2.132222222222222,
              "msFromStart": 74230.22571539959
            },
            {
              "lat": 43.77379655000001,
              "lng": -116.09040393,
              "alt": 2192.3199999999997,
              "speed2d": 1.7308,
              "speed3d": 2.3530000000000006,
              "msFromStart": 74751.56866666666
            },
            {
              "lat": 43.773811033333324,
              "lng": -116.09039235555554,
              "alt": 2190.7525555555553,
              "speed2d": 2.643777777777778,
              "speed3d": 3.0777777777777775,
              "msFromStart": 75273.07333333333
            },
            {
              "lat": 43.77382968888889,
              "lng": -116.09037727777779,
              "alt": 2189.3236666666667,
              "speed2d": 3.7791111111111113,
              "speed3d": 4.123333333333334,
              "msFromStart": 75768.58933333334
            },
            {
              "lat": 43.77384705555555,
              "lng": -116.09036418888888,
              "alt": 2188.2445555555555,
              "speed2d": 4.318888888888889,
              "speed3d": 4.684444444444445,
              "msFromStart": 76264.26044444446
            },
            {
              "lat": 43.77386081111111,
              "lng": -116.09035236666668,
              "alt": 2187.7957777777774,
              "speed2d": 4.427111111111111,
              "speed3d": 4.815555555555555,
              "msFromStart": 76759.97600000002
            },
            {
              "lat": 43.773871022222224,
              "lng": -116.09034,
              "alt": 2187.1959999999995,
              "speed2d": 4.1209999999999996,
              "speed3d": 4.553333333333334,
              "msFromStart": 77255.61896296297
            },
            {
              "lat": 43.77387793333334,
              "lng": -116.09032804444445,
              "alt": 2186.6144444444444,
              "speed2d": 3.4412222222222226,
              "speed3d": 4.011111111111111,
              "msFromStart": 77750.95377777776
            },
            {
              "lat": 43.77388365555555,
              "lng": -116.09031893333334,
              "alt": 2186.029,
              "speed2d": 2.7457777777777777,
              "speed3d": 3.347777777777778,
              "msFromStart": 78246.12779727092
            },
            {
              "lat": 43.77389664444444,
              "lng": -116.0903257,
              "alt": 2185.2636666666667,
              "speed2d": 2.513,
              "speed3d": 3.1577777777777776,
              "msFromStart": 78740.78189473679
            },
            {
              "lat": 43.77391282222222,
              "lng": -116.09034832222223,
              "alt": 2185.0802222222223,
              "speed2d": 2.8182222222222224,
              "speed3d": 3.2577777777777777,
              "msFromStart": 79235.43608576994
            },
            {
              "lat": 43.77393368888889,
              "lng": -116.09037603333333,
              "alt": 2184.8794444444447,
              "speed2d": 4.096111111111111,
              "speed3d": 4.365555555555556,
              "msFromStart": 79730.40800000002
            },
            {
              "lat": 43.77394601111112,
              "lng": -116.09039713333334,
              "alt": 2183.6715555555556,
              "speed2d": 4.523111111111111,
              "speed3d": 4.874444444444444,
              "msFromStart": 80225.39051851851
            },
            {
              "lat": 43.773959229999996,
              "lng": -116.09041675,
              "alt": 2183.0752,
              "speed2d": 4.3852,
              "speed3d": 4.755,
              "msFromStart": 80747.60266666659
            },
            {
              "lat": 43.77397265555556,
              "lng": -116.09043974444447,
              "alt": 2182.1233333333334,
              "speed2d": 4.486888888888889,
              "speed3d": 4.890000000000001,
              "msFromStart": 81269.72814814812
            },
            {
              "lat": 43.773983377777775,
              "lng": -116.09045296666666,
              "alt": 2180.880777777778,
              "speed2d": 3.7267777777777775,
              "speed3d": 4.253333333333334,
              "msFromStart": 81764.1902222222
            },
            {
              "lat": 43.77399514444444,
              "lng": -116.09045979999999,
              "alt": 2179.635111111111,
              "speed2d": 3.2846666666666664,
              "speed3d": 3.8633333333333333,
              "msFromStart": 82258.64088888887
            },
            {
              "lat": 43.77401086666667,
              "lng": -116.09046035555555,
              "alt": 2178.325333333333,
              "speed2d": 3.279777777777778,
              "speed3d": 3.8144444444444443,
              "msFromStart": 82753.11822222221
            },
            {
              "lat": 43.774034477777775,
              "lng": -116.0904537777778,
              "alt": 2177.219777777778,
              "speed2d": 4.040222222222223,
              "speed3d": 4.445555555555556,
              "msFromStart": 83247.77662378167
            },
            {
              "lat": 43.77405806666667,
              "lng": -116.09043883333334,
              "alt": 2176.1435555555554,
              "speed2d": 4.816666666666667,
              "speed3d": 5.194444444444444,
              "msFromStart": 83743.14863157895
            },
            {
              "lat": 43.7740764,
              "lng": -116.09041807777777,
              "alt": 2175.0537777777777,
              "speed2d": 5.154,
              "speed3d": 5.548888888888889,
              "msFromStart": 84238.53331254063
            },
            {
              "lat": 43.77408758888889,
              "lng": -116.09039525555556,
              "alt": 2173.8230000000003,
              "speed2d": 5.010777777777777,
              "speed3d": 5.475555555555555,
              "msFromStart": 84733.56000000004
            },
            {
              "lat": 43.77409563333333,
              "lng": -116.0903668777778,
              "alt": 2172.6885555555555,
              "speed2d": 4.976555555555556,
              "speed3d": 5.404444444444444,
              "msFromStart": 85228.47120987659
            },
            {
              "lat": 43.77410806,
              "lng": -116.09033643000001,
              "alt": 2171.4823,
              "speed2d": 5.019100000000001,
              "speed3d": 5.485,
              "msFromStart": 85750.50866666669
            },
            {
              "lat": 43.774121477777776,
              "lng": -116.09031576666668,
              "alt": 2170.0848888888886,
              "speed2d": 4.671111111111111,
              "speed3d": 5.247777777777777,
              "msFromStart": 86272.69970370372
            },
            {
              "lat": 43.774137744444445,
              "lng": -116.09032719999999,
              "alt": 2168.1666666666665,
              "speed2d": 3.936,
              "speed3d": 4.624444444444444,
              "msFromStart": 86768.2595555555
            },
            {
              "lat": 43.77415301111111,
              "lng": -116.09033556666665,
              "alt": 2166.5697777777777,
              "speed2d": 4.177444444444444,
              "speed3d": 4.786666666666667,
              "msFromStart": 87263.99318518514
            },
            {
              "lat": 43.77416977777777,
              "lng": -116.09035211111112,
              "alt": 2164.8138888888893,
              "speed2d": 4.974777777777778,
              "speed3d": 5.5122222222222215,
              "msFromStart": 87760.08533333329
            },
            {
              "lat": 43.77418641111111,
              "lng": -116.09037193333333,
              "alt": 2162.873,
              "speed2d": 5.632777777777777,
              "speed3d": 6.204444444444444,
              "msFromStart": 88255.96666666663
            },
            {
              "lat": 43.77419705555555,
              "lng": -116.09039407777777,
              "alt": 2160.759777777778,
              "speed2d": 5.516,
              "speed3d": 6.225555555555555,
              "msFromStart": 88750.86133333329
            },
            {
              "lat": 43.774204955555554,
              "lng": -116.0904106777778,
              "alt": 2158.159111111111,
              "speed2d": 4.786888888888888,
              "speed3d": 5.738888888888889,
              "msFromStart": 89245.74283040932
            },
            {
              "lat": 43.77421648888888,
              "lng": -116.09041114444445,
              "alt": 2155.5622222222223,
              "speed2d": 3.8156666666666665,
              "speed3d": 4.978888888888889,
              "msFromStart": 89740.96631578941
            },
            {
              "lat": 43.77423782222222,
              "lng": -116.09040204444446,
              "alt": 2153.1176666666665,
              "speed2d": 4.196,
              "speed3d": 5.2588888888888885,
              "msFromStart": 90236.13194022088
            },
            {
              "lat": 43.774259344444445,
              "lng": -116.09038657777779,
              "alt": 2150.7973333333334,
              "speed2d": 4.917,
              "speed3d": 5.91,
              "msFromStart": 90730.73599999995
            },
            {
              "lat": 43.774277,
              "lng": -116.09035949,
              "alt": 2147.841,
              "speed2d": 5.6103000000000005,
              "speed3d": 6.589,
              "msFromStart": 91252.69355555551
            },
            {
              "lat": 43.77429757777778,
              "lng": -116.09033105555557,
              "alt": 2144.6114444444443,
              "speed2d": 6.381222222222223,
              "speed3d": 7.325555555555555,
              "msFromStart": 91774.52355555551
            },
            {
              "lat": 43.77432128888889,
              "lng": -116.0903021888889,
              "alt": 2141.934888888889,
              "speed2d": 6.851222222222223,
              "speed3d": 7.897777777777778,
              "msFromStart": 92268.90266666663
            },
            {
              "lat": 43.774336266666666,
              "lng": -116.09027901111111,
              "alt": 2138.358888888889,
              "speed2d": 6.301555555555556,
              "speed3d": 7.582222222222221,
              "msFromStart": 92763.4062222222
            },
            {
              "lat": 43.7743478,
              "lng": -116.09027209999999,
              "alt": 2135.8635,
              "speed2d": 6.003,
              "speed3d": 7.42,
              "msFromStart": 93065.61044444444
            },
            {
              "lat": 43.77437748103503,
              "lng": -116.09027405493154,
              "alt": 2132.421061037962,
              "speed2d": 6.38483352217747,
              "speed3d": 7.79655692785744,
              "msFromStart": 93500
            },
            {
              "lat": 43.774436259999995,
              "lng": -116.09027544,
              "alt": 2126.6305999999995,
              "speed2d": 7.3786000000000005,
              "speed3d": 8.724,
              "msFromStart": 94293.96743859653
            },
            {
              "lat": 43.774477225,
              "lng": -116.0902689875,
              "alt": 2124.53275,
              "speed2d": 8.281375,
              "speed3d": 9.5275,
              "msFromStart": 94724.886
            },
            {
              "lat": 43.77452185,
              "lng": -116.090261,
              "alt": 2121.67525,
              "speed2d": 8.938500000000001,
              "speed3d": 10.147499999999999,
              "msFromStart": 95199.80355555555
            },
            {
              "lat": 43.77455063047461,
              "lng": -116.09025125098889,
              "alt": 2119.8261312539084,
              "speed2d": 9.273624713746615,
              "speed3d": 10.518114824797834,
              "msFromStart": 95500
            },
            {
              "lat": 43.774589619100674,
              "lng": -116.0902313358367,
              "alt": 2115.9754944277533,
              "speed2d": 9.277109409826496,
              "speed3d": 10.311791840294596,
              "msFromStart": 96000
            },
            {
              "lat": 43.77463454,
              "lng": -116.0902277,
              "alt": 2111.1684,
              "speed2d": 8.6182,
              "speed3d": 10.142000000000001,
              "msFromStart": 96648.26346666673
            },
            {
              "lat": 43.774688616666666,
              "lng": -116.0902136,
              "alt": 2105.0931666666665,
              "speed2d": 8.702,
              "speed3d": 10.318333333333333,
              "msFromStart": 97358.43177777778
            },
            {
              "lat": 43.77471718571428,
              "lng": -116.0902067,
              "alt": 2101.8142857142857,
              "speed2d": 8.751142857142858,
              "speed3d": 10.412857142857144,
              "msFromStart": 97748.23092063492
            },
            {
              "lat": 43.77473856018508,
              "lng": -116.09020029453897,
              "alt": 2099.89363039553,
              "speed2d": 9.132771542950797,
              "speed3d": 10.715757748155319,
              "msFromStart": 98000
            },
            {
              "lat": 43.77476100161306,
              "lng": -116.09017618458778,
              "alt": 2096.7012060830593,
              "speed2d": 8.78414516042432,
              "speed3d": 10.653064514891891,
              "msFromStart": 98500
            },
            {
              "lat": 43.774818933333336,
              "lng": -116.09017703333333,
              "alt": 2092.722333333333,
              "speed2d": 8.460333333333335,
              "speed3d": 10.243333333333334,
              "msFromStart": 99310.67103313842
            },
            {
              "lat": 43.774856424999996,
              "lng": -116.09019235,
              "alt": 2089.1805,
              "speed2d": 8.67775,
              "speed3d": 10.3325,
              "msFromStart": 99763.33244444449
            },
            {
              "lat": 43.77489448888888,
              "lng": -116.09022137777778,
              "alt": 2084.095222222222,
              "speed2d": 9.414111111111112,
              "speed3d": 11.20111111111111,
              "msFromStart": 100243.69688888892
            },
            {
              "lat": 43.7749192875,
              "lng": -116.0902397875,
              "alt": 2080.6459999999997,
              "speed2d": 8.900625,
              "speed3d": 10.83375,
              "msFromStart": 100718.86783333334
            },
            {
              "lat": 43.774957300000004,
              "lng": -116.09025661666666,
              "alt": 2077.9655,
              "speed2d": 9.206666666666667,
              "speed3d": 10.943333333333332,
              "msFromStart": 101189.6780740741
            },
            {
              "lat": 43.775011485714295,
              "lng": -116.09026314285714,
              "alt": 2075.305,
              "speed2d": 9.702571428571428,
              "speed3d": 11.367142857142857,
              "msFromStart": 101730.79999999999
            },
            {
              "lat": 43.775045500000004,
              "lng": -116.09026420000001,
              "alt": 2073.996,
              "speed2d": 9.8475,
              "speed3d": 11.455,
              "msFromStart": 102060.88533333331
            },
            {
              "lat": 43.77511148000001,
              "lng": -116.09025568999999,
              "alt": 2069.8677000000002,
              "speed2d": 10.0058,
              "speed3d": 11.669000000000002,
              "msFromStart": 102748.18733333329
            },
            {
              "lat": 43.77515055555556,
              "lng": -116.0902405,
              "alt": 2067.3102222222224,
              "speed2d": 9.529333333333334,
              "speed3d": 11.312222222222223,
              "msFromStart": 103270.66222222218
            },
            {
              "lat": 43.775189025,
              "lng": -116.09023142500001,
              "alt": 2064.963,
              "speed2d": 8.982,
              "speed3d": 10.802500000000002,
              "msFromStart": 103766.51200000006
            },
            {
              "lat": 43.7752404,
              "lng": -116.09023057499999,
              "alt": 2062.1445000000003,
              "speed2d": 9.1975,
              "speed3d": 10.927499999999998,
              "msFromStart": 104262.23022222228
            },
            {
              "lat": 43.775304059999996,
              "lng": -116.09024436000001,
              "alt": 2058.829,
              "speed2d": 9.585600000000001,
              "speed3d": 11.288,
              "msFromStart": 104789.77386666671
            },
            {
              "lat": 43.775355044444446,
              "lng": -116.09026325555556,
              "alt": 2056.9595555555557,
              "speed2d": 9.73788888888889,
              "speed3d": 11.440000000000001,
              "msFromStart": 105251.31140740745
            },
            {
              "lat": 43.775408066666664,
              "lng": -116.09029633333333,
              "alt": 2055.9373333333333,
              "speed2d": 9.692666666666668,
              "speed3d": 11.263333333333334,
              "msFromStart": 105801.62844444443
            },
            {
              "lat": 43.77544114,
              "lng": -116.09031682,
              "alt": 2054.9736000000003,
              "speed2d": 9.6144,
              "speed3d": 11.145999999999999,
              "msFromStart": 106164.95057777777
            },
            {
              "lat": 43.77547323956898,
              "lng": -116.09033564879113,
              "alt": 2053.377043060349,
              "speed2d": 9.842542532750283,
              "speed3d": 11.279749333013855,
              "msFromStart": 106500
            },
            {
              "lat": 43.775534487499996,
              "lng": -116.090345425,
              "alt": 2048.8857500000004,
              "speed2d": 9.254999999999999,
              "speed3d": 10.9225,
              "msFromStart": 107213.19627777778
            },
            {
              "lat": 43.77556333333333,
              "lng": -116.09033766666667,
              "alt": 2046.0726666666667,
              "speed2d": 8.830666666666668,
              "speed3d": 10.576666666666666,
              "msFromStart": 107618.17466666669
            },
            {
              "lat": 43.7756116,
              "lng": -116.0903174166667,
              "alt": 2041.8818333333334,
              "speed2d": 9.071,
              "speed3d": 10.75,
              "msFromStart": 108267.6690292398
            },
            {
              "lat": 43.77564832857143,
              "lng": -116.09029965714285,
              "alt": 2038.7622857142856,
              "speed2d": 8.962714285714284,
              "speed3d": 10.79857142857143,
              "msFromStart": 108771.73515789474
            },
            {
              "lat": 43.77566124379671,
              "lng": -116.09029233037593,
              "alt": 2037.5672244922741,
              "speed2d": 8.507577741193225,
              "speed3d": 10.48669284720026,
              "msFromStart": 109000
            },
            {
              "lat": 43.7757119,
              "lng": -116.0902815,
              "alt": 2033.0030000000002,
              "speed2d": 7.4670000000000005,
              "speed3d": 9.546666666666667,
              "msFromStart": 109872.10622222218
            },
            {
              "lat": 43.77574885999999,
              "lng": -116.0902959,
              "alt": 2030.6754,
              "speed2d": 8.0532,
              "speed3d": 9.886,
              "msFromStart": 110356.3023111111
            },
            {
              "lat": 43.775780411111114,
              "lng": -116.09031481111113,
              "alt": 2029.1115555555557,
              "speed2d": 8.447888888888889,
              "speed3d": 10.283333333333331,
              "msFromStart": 110752.15999999997
            },
            {
              "lat": 43.77581240000001,
              "lng": -116.09033761250001,
              "alt": 2027.699875,
              "speed2d": 8.416125000000001,
              "speed3d": 10.21,
              "msFromStart": 111240.08216666663
            },
            {
              "lat": 43.775843280000004,
              "lng": -116.09036538000001,
              "alt": 2026.112,
              "speed2d": 8.3076,
              "speed3d": 10.058,
              "msFromStart": 111697.69466666674
            },
            {
              "lat": 43.77587065,
              "lng": -116.09038645,
              "alt": 2024.7525,
              "speed2d": 8.355,
              "speed3d": 10.04,
              "msFromStart": 112043.98466666677
            },
            {
              "lat": 43.77593625,
              "lng": -116.0904094,
              "alt": 2020.1042499999999,
              "speed2d": 8.44325,
              "speed3d": 10.157499999999999,
              "msFromStart": 112759.68288888883
            },
            {
              "lat": 43.77598098,
              "lng": -116.0904138,
              "alt": 2017.6889999999999,
              "speed2d": 8.731,
              "speed3d": 10.414000000000001,
              "msFromStart": 113183.6602666666
            },
            {
              "lat": 43.77603124,
              "lng": -116.09041644000001,
              "alt": 2014.0657999999999,
              "speed2d": 9.056000000000001,
              "speed3d": 10.748,
              "msFromStart": 113689.09439999994
            },
            {
              "lat": 43.776082974999994,
              "lng": -116.090420075,
              "alt": 2009.896,
              "speed2d": 9.2665,
              "speed3d": 11.025,
              "msFromStart": 114243.88247368418
            },
            {
              "lat": 43.77613104285714,
              "lng": -116.09041655714285,
              "alt": 2005.3785714285714,
              "speed2d": 9.297142857142857,
              "speed3d": 11.129999999999999,
              "msFromStart": 114814.60114285717
            },
            {
              "lat": 43.776186433333336,
              "lng": -116.09040253333333,
              "alt": 2001.6909999999998,
              "speed2d": 9.446666666666667,
              "speed3d": 11.273333333333333,
              "msFromStart": 115428.43466666664
            },
            {
              "lat": 43.776224225,
              "lng": -116.090396375,
              "alt": 1999.7197500000002,
              "speed2d": 9.49925,
              "speed3d": 11.302499999999998,
              "msFromStart": 115854.6915555555
            },
            {
              "lat": 43.77625398333333,
              "lng": -116.09039981666668,
              "alt": 1997.9449999999997,
              "speed2d": 9.427666666666667,
              "speed3d": 11.246666666666666,
              "msFromStart": 116216.86340740735
            },
            {
              "lat": 43.77629055,
              "lng": -116.09040725,
              "alt": 1996.0117500000001,
              "speed2d": 9.334,
              "speed3d": 11.142500000000002,
              "msFromStart": 116652.80377777776
            },
            {
              "lat": 43.77635021666667,
              "lng": -116.09042688333332,
              "alt": 1993.4475,
              "speed2d": 9.418333333333333,
              "speed3d": 11.145000000000001,
              "msFromStart": 117244.9111111111
            },
            {
              "lat": 43.776419475,
              "lng": -116.0904598,
              "alt": 1993.5001249999998,
              "speed2d": 9.921625,
              "speed3d": 11.450000000000001,
              "msFromStart": 117767.85733333328
            },
            {
              "lat": 43.77646823333333,
              "lng": -116.09047665555555,
              "alt": 1993.8452222222227,
              "speed2d": 10.004111111111111,
              "speed3d": 11.461111111111109,
              "msFromStart": 118235.72148148145
            },
            {
              "lat": 43.776515614285714,
              "lng": -116.09047934285714,
              "alt": 1993.1118571428572,
              "speed2d": 9.993285714285713,
              "speed3d": 11.375714285714286,
              "msFromStart": 118723.25257142851
            },
            {
              "lat": 43.7765906,
              "lng": -116.09046824999999,
              "alt": 1991.0857500000002,
              "speed2d": 10.307250000000002,
              "speed3d": 11.61,
              "msFromStart": 119363.83936842108
            },
            {
              "lat": 43.776628259999995,
              "lng": -116.09045913,
              "alt": 1989.5099,
              "speed2d": 10.3844,
              "speed3d": 11.689999999999998,
              "msFromStart": 119748.35600000006
            },
            {
              "lat": 43.776671411111124,
              "lng": -116.09044427777778,
              "alt": 1986.8901111111113,
              "speed2d": 10.402444444444445,
              "speed3d": 11.711111111111109,
              "msFromStart": 120270.24116959068
            },
            {
              "lat": 43.77670921111112,
              "lng": -116.09042728888889,
              "alt": 1984.5568888888888,
              "speed2d": 10.498888888888889,
              "speed3d": 11.778888888888886,
              "msFromStart": 120764.91199999995
            },
            {
              "lat": 43.77674885555555,
              "lng": -116.09040717777778,
              "alt": 1982.4601111111108,
              "speed2d": 10.603222222222222,
              "speed3d": 11.851111111111111,
              "msFromStart": 121259.68572839502
            },
            {
              "lat": 43.77678747777777,
              "lng": -116.09039736666665,
              "alt": 1980.383111111111,
              "speed2d": 10.608888888888888,
              "speed3d": 11.854444444444445,
              "msFromStart": 121754.836
            },
            {
              "lat": 43.776838125000005,
              "lng": -116.0903984,
              "alt": 1979.0507499999999,
              "speed2d": 10.55975,
              "speed3d": 11.745000000000001,
              "msFromStart": 122359.8301111111
            },
            {
              "lat": 43.77686408333334,
              "lng": -116.09040103333334,
              "alt": 1978.377,
              "speed2d": 10.477166666666667,
              "speed3d": 11.651666666666667,
              "msFromStart": 122670.99229629629
            },
            {
              "lat": 43.776919975000006,
              "lng": -116.090397725,
              "alt": 1976.53325,
              "speed2d": 10.525749999999999,
              "speed3d": 11.645,
              "msFromStart": 123252.2825380117
            },
            {
              "lat": 43.77694397666164,
              "lng": -116.09039725278731,
              "alt": 1975.752192523836,
              "speed2d": 10.481421373357097,
              "speed3d": 11.61918785218941,
              "msFromStart": 123500
            },
            {
              "lat": 43.77699801834513,
              "lng": -116.09037779602434,
              "alt": 1974.5495549362154,
              "speed2d": 10.803527957332932,
              "speed3d": 11.805199909858308,
              "msFromStart": 124000
            },
            {
              "lat": 43.777044146657424,
              "lng": -116.09035733384857,
              "alt": 1972.0809576321185,
              "speed2d": 10.839399919795248,
              "speed3d": 11.880985746046298,
              "msFromStart": 124500
            },
            {
              "lat": 43.777123233333334,
              "lng": -116.09032606666668,
              "alt": 1968.0825000000002,
              "speed2d": 10.839666666666666,
              "speed3d": 11.946666666666665,
              "msFromStart": 125356.67985185186
            },
            {
              "lat": 43.77715625,
              "lng": -116.09032017499999,
              "alt": 1966.981,
              "speed2d": 10.76275,
              "speed3d": 11.865,
              "msFromStart": 125714.08266666665
            },
            {
              "lat": 43.777210275,
              "lng": -116.09031494999999,
              "alt": 1965.830375,
              "speed2d": 10.6785,
              "speed3d": 11.75,
              "msFromStart": 126284.42994444442
            },
            {
              "lat": 43.7772459,
              "lng": -116.09030901999998,
              "alt": 1965.0701999999999,
              "speed2d": 10.612,
              "speed3d": 11.678,
              "msFromStart": 126670.08417777778
            },
            {
              "lat": 43.777279220164914,
              "lng": -116.09030531761798,
              "alt": 1964.0709073202356,
              "speed2d": 10.657496462842753,
              "speed3d": 11.687106132559395,
              "msFromStart": 127000
            },
            {
              "lat": 43.77731912549238,
              "lng": -116.09029112374701,
              "alt": 1962.0460106439696,
              "speed2d": 10.526071731671374,
              "speed3d": 11.577187266488785,
              "msFromStart": 127500
            },
            {
              "lat": 43.7773786375,
              "lng": -116.09026593750002,
              "alt": 1958.81325,
              "speed2d": 10.293875,
              "speed3d": 11.391250000000001,
              "msFromStart": 128271.21199999998
            },
            {
              "lat": 43.7774086,
              "lng": -116.09025126,
              "alt": 1957.0505999999998,
              "speed2d": 10.0698,
              "speed3d": 11.192,
              "msFromStart": 128705.272
            },
            {
              "lat": 43.77743951666666,
              "lng": -116.09024063333334,
              "alt": 1954.8653333333334,
              "speed2d": 9.806166666666666,
              "speed3d": 10.961666666666668,
              "msFromStart": 129168.48439766081
            },
            {
              "lat": 43.77747463333333,
              "lng": -116.0902334,
              "alt": 1951.5716666666667,
              "speed2d": 9.533,
              "speed3d": 10.716666666666667,
              "msFromStart": 129726.8631578947
            },
            {
              "lat": 43.777507925,
              "lng": -116.09024292499998,
              "alt": 1948.8517500000003,
              "speed2d": 9.360625,
              "speed3d": 10.5725,
              "msFromStart": 130221.238119883
            },
            {
              "lat": 43.77752723276364,
              "lng": -116.09025115172525,
              "alt": 1947.3820834067167,
              "speed2d": 9.249172771633317,
              "speed3d": 10.453265739745842,
              "msFromStart": 130500
            },
            {
              "lat": 43.777569002148276,
              "lng": -116.09026340410003,
              "alt": 1943.5886930739225,
              "speed2d": 9.4234124085153,
              "speed3d": 10.62657969639817,
              "msFromStart": 131000
            },
            {
              "lat": 43.7776394,
              "lng": -116.09025135,
              "alt": 1939.027,
              "speed2d": 9.635,
              "speed3d": 10.835,
              "msFromStart": 131844.26466666654
            },
            {
              "lat": 43.77767635,
              "lng": -116.09023565000001,
              "alt": 1936.87,
              "speed2d": 9.7595,
              "speed3d": 10.955,
              "msFromStart": 132284.52333333332
            },
            {
              "lat": 43.77770515,
              "lng": -116.09022205,
              "alt": 1935.2240000000002,
              "speed2d": 9.889,
              "speed3d": 11.065,
              "msFromStart": 132613.74733333325
            },
            {
              "lat": 43.77774010813612,
              "lng": -116.0901877682672,
              "alt": 1933.2568584591857,
              "speed2d": 10.153326280143101,
              "speed3d": 11.279991861703577,
              "msFromStart": 133000
            },
            {
              "lat": 43.77777832831103,
              "lng": -116.09015722526381,
              "alt": 1930.6753845727396,
              "speed2d": 10.219240618428596,
              "speed3d": 11.37270808640795,
              "msFromStart": 133500
            }
          ]
        }
      ]
    },
    {
      "id": "trail-war-eagle-cat-track",
      "pathType": "trail",
      "name": "War Eagle Cat Track",
      "difficulty": "black",
      "isCatTrack": true,
      "videos": [
        {
          "videoId": "GX010084",
          "pathId": "trail-war-eagle-cat-track",
          "startSec": 6,
          "endSec": 125,
          "id": "video-segment-50",
          "gps": [
            {
              "lat": 43.772433766666666,
              "lng": -116.10042245555555,
              "alt": 2079.704,
              "speed2d": 2.3887777777777774,
              "speed3d": 2.3955555555555557,
              "msFromStart": 6267.970024691356
            },
            {
              "lat": 43.7724428,
              "lng": -116.10041815555557,
              "alt": 2079.423666666667,
              "speed2d": 2.4106666666666667,
              "speed3d": 2.4144444444444444,
              "msFromStart": 6763.098999999995
            },
            {
              "lat": 43.77245323333333,
              "lng": -116.1004114888889,
              "alt": 2079.215666666667,
              "speed2d": 2.6546666666666665,
              "speed3d": 2.6100000000000003,
              "msFromStart": 7258.355401234568
            },
            {
              "lat": 43.77246585555556,
              "lng": -116.10040373333334,
              "alt": 2079.019888888889,
              "speed2d": 2.937666666666667,
              "speed3d": 2.9455555555555555,
              "msFromStart": 7753.823333333335
            },
            {
              "lat": 43.772477988888895,
              "lng": -116.10039785555556,
              "alt": 2078.6902222222225,
              "speed2d": 2.932777777777778,
              "speed3d": 2.95,
              "msFromStart": 8249.325925925927
            },
            {
              "lat": 43.77249375555555,
              "lng": -116.10039003333333,
              "alt": 2078.615333333333,
              "speed2d": 3.336333333333333,
              "speed3d": 3.3077777777777784,
              "msFromStart": 8744.885333333328
            },
            {
              "lat": 43.772506122222225,
              "lng": -116.10038267777777,
              "alt": 2078.4192222222223,
              "speed2d": 3.1848888888888887,
              "speed3d": 3.215555555555555,
              "msFromStart": 9240.297764457438
            },
            {
              "lat": 43.772522788888885,
              "lng": -116.1003753,
              "alt": 2078.371111111111,
              "speed2d": 3.543222222222222,
              "speed3d": 3.507777777777778,
              "msFromStart": 9735.435210526313
            },
            {
              "lat": 43.77253842222222,
              "lng": -116.10036854444446,
              "alt": 2078.3019999999997,
              "speed2d": 3.554555555555556,
              "speed3d": 3.58,
              "msFromStart": 10230.446253411306
            },
            {
              "lat": 43.772552620000006,
              "lng": -116.10036324000001,
              "alt": 2077.8186,
              "speed2d": 3.4332,
              "speed3d": 3.4670000000000005,
              "msFromStart": 10752.609944444455
            },
            {
              "lat": 43.77256491111111,
              "lng": -116.1003572111111,
              "alt": 2077.539111111111,
              "speed2d": 3.08,
              "speed3d": 3.1377777777777776,
              "msFromStart": 11274.577444444445
            },
            {
              "lat": 43.77257892222222,
              "lng": -116.10035133333334,
              "alt": 2077.318666666667,
              "speed2d": 3.1590000000000003,
              "speed3d": 3.161111111111111,
              "msFromStart": 11768.731333333335
            },
            {
              "lat": 43.77259401111112,
              "lng": -116.10034594444444,
              "alt": 2077.1266666666666,
              "speed2d": 3.3166666666666673,
              "speed3d": 3.312222222222222,
              "msFromStart": 12263.439524691355
            },
            {
              "lat": 43.77261082222222,
              "lng": -116.10034139999999,
              "alt": 2076.935888888889,
              "speed2d": 3.570666666666667,
              "speed3d": 3.5733333333333333,
              "msFromStart": 12759.188999999991
            },
            {
              "lat": 43.77262958888889,
              "lng": -116.1003369111111,
              "alt": 2076.843,
              "speed2d": 3.7672222222222214,
              "speed3d": 3.7800000000000002,
              "msFromStart": 13254.673505847952
            },
            {
              "lat": 43.772644933333325,
              "lng": -116.10033227777778,
              "alt": 2076.7338888888885,
              "speed2d": 3.6287777777777777,
              "speed3d": 3.6466666666666665,
              "msFromStart": 13749.628736842113
            },
            {
              "lat": 43.77266603333333,
              "lng": -116.1003281,
              "alt": 2076.603111111111,
              "speed2d": 4.099111111111111,
              "speed3d": 4.031111111111111,
              "msFromStart": 14244.570146198832
            },
            {
              "lat": 43.772688522222225,
              "lng": -116.10032376666668,
              "alt": 2076.545,
              "speed2d": 4.457888888888889,
              "speed3d": 4.474444444444445,
              "msFromStart": 14739.501666666669
            },
            {
              "lat": 43.77270823333333,
              "lng": -116.10032093333331,
              "alt": 2076.318111111111,
              "speed2d": 4.493333333333333,
              "speed3d": 4.481111111111111,
              "msFromStart": 15234.505777777778
            },
            {
              "lat": 43.77273008888889,
              "lng": -116.1003183,
              "alt": 2076.031111111111,
              "speed2d": 4.657222222222223,
              "speed3d": 4.653333333333334,
              "msFromStart": 15729.708333333328
            },
            {
              "lat": 43.772757600000006,
              "lng": -116.10031562999998,
              "alt": 2076.0627,
              "speed2d": 5.031600000000001,
              "speed3d": 5.018000000000001,
              "msFromStart": 16252.207611111111
            },
            {
              "lat": 43.77278311111111,
              "lng": -116.10031335555556,
              "alt": 2075.9120000000003,
              "speed2d": 5.168888888888889,
              "speed3d": 5.147777777777779,
              "msFromStart": 16774.19133333334
            },
            {
              "lat": 43.7728096,
              "lng": -116.10031096666667,
              "alt": 2075.720888888889,
              "speed2d": 5.441222222222222,
              "speed3d": 5.406666666666667,
              "msFromStart": 17268.988370370367
            },
            {
              "lat": 43.772838400000005,
              "lng": -116.10030922222222,
              "alt": 2075.4465555555557,
              "speed2d": 5.837,
              "speed3d": 5.835555555555556,
              "msFromStart": 17764.35933333332
            },
            {
              "lat": 43.7728675,
              "lng": -116.10031106666666,
              "alt": 2075.790111111111,
              "speed2d": 5.991777777777777,
              "speed3d": 5.982222222222222,
              "msFromStart": 18259.831901234564
            },
            {
              "lat": 43.772893555555555,
              "lng": -116.10031074444446,
              "alt": 2075.690111111111,
              "speed2d": 5.921222222222223,
              "speed3d": 5.944444444444445,
              "msFromStart": 18755.472666666654
            },
            {
              "lat": 43.77291855555555,
              "lng": -116.10031077777776,
              "alt": 2075.4265555555558,
              "speed2d": 5.809333333333334,
              "speed3d": 5.795555555555556,
              "msFromStart": 19250.919593242364
            },
            {
              "lat": 43.77294663333333,
              "lng": -116.10031256666666,
              "alt": 2075.118111111111,
              "speed2d": 6.007333333333334,
              "speed3d": 6.044444444444444,
              "msFromStart": 19746.000526315802
            },
            {
              "lat": 43.772964755555556,
              "lng": -116.10031705555556,
              "alt": 2074.8105555555558,
              "speed2d": 5.525222222222222,
              "speed3d": 5.545555555555556,
              "msFromStart": 20240.934448343087
            },
            {
              "lat": 43.7729863,
              "lng": -116.10032243333333,
              "alt": 2074.469333333333,
              "speed2d": 5.558888888888888,
              "speed3d": 5.56111111111111,
              "msFromStart": 20735.488444444436
            },
            {
              "lat": 43.773008866666665,
              "lng": -116.10032041111111,
              "alt": 2073.6247777777776,
              "speed2d": 5.698,
              "speed3d": 5.699999999999999,
              "msFromStart": 21230.041370370363
            },
            {
              "lat": 43.77303216999999,
              "lng": -116.10031832,
              "alt": 2072.6585,
              "speed2d": 5.7954,
              "speed3d": 5.7829999999999995,
              "msFromStart": 21752.123833333313
            },
            {
              "lat": 43.773055377777766,
              "lng": -116.10031896666666,
              "alt": 2071.849444444444,
              "speed2d": 5.744111111111112,
              "speed3d": 5.836666666666667,
              "msFromStart": 22274.35522222222
            },
            {
              "lat": 43.773065333333335,
              "lng": -116.10032388888888,
              "alt": 2070.501111111111,
              "speed2d": 4.846888888888889,
              "speed3d": 4.967777777777778,
              "msFromStart": 22769.380000000012
            },
            {
              "lat": 43.77307660000001,
              "lng": -116.1003251888889,
              "alt": 2068.6773333333335,
              "speed2d": 4.437777777777778,
              "speed3d": 4.515555555555556,
              "msFromStart": 23264.616024691357
            },
            {
              "lat": 43.77309778888889,
              "lng": -116.10032382222222,
              "alt": 2067.7061111111116,
              "speed2d": 4.712,
              "speed3d": 4.742222222222223,
              "msFromStart": 23760.23466666665
            },
            {
              "lat": 43.77311548888889,
              "lng": -116.1003275222222,
              "alt": 2066.4594444444447,
              "speed2d": 4.654111111111111,
              "speed3d": 4.7811111111111115,
              "msFromStart": 24255.643616634174
            },
            {
              "lat": 43.773135666666676,
              "lng": -116.10033025555555,
              "alt": 2065.0602222222224,
              "speed2d": 5.070666666666667,
              "speed3d": 5.078888888888889,
              "msFromStart": 24750.649473684214
            },
            {
              "lat": 43.77316225555555,
              "lng": -116.10032998888889,
              "alt": 2064.0901111111107,
              "speed2d": 5.636333333333334,
              "speed3d": 5.694444444444445,
              "msFromStart": 25245.650662768036
            },
            {
              "lat": 43.77319037777778,
              "lng": -116.10032945555557,
              "alt": 2062.987888888889,
              "speed2d": 6.185333333333333,
              "speed3d": 6.236666666666667,
              "msFromStart": 25740.660222222235
            },
            {
              "lat": 43.77321982222222,
              "lng": -116.10032807777777,
              "alt": 2061.8199999999997,
              "speed2d": 6.709222222222222,
              "speed3d": 6.758888888888889,
              "msFromStart": 26235.698222222232
            },
            {
              "lat": 43.773252944444444,
              "lng": -116.10032625555556,
              "alt": 2061.0103333333336,
              "speed2d": 7.240111111111112,
              "speed3d": 7.298888888888889,
              "msFromStart": 26730.812222222226
            },
            {
              "lat": 43.773285888888886,
              "lng": -116.1003252111111,
              "alt": 2060.2294444444447,
              "speed2d": 7.5,
              "speed3d": 7.558888888888888,
              "msFromStart": 27226.008518518516
            },
            {
              "lat": 43.77332134,
              "lng": -116.10032414,
              "alt": 2059.1440000000002,
              "speed2d": 7.795299999999999,
              "speed3d": 7.844999999999999,
              "msFromStart": 27748.940666666655
            },
            {
              "lat": 43.77335877777778,
              "lng": -116.10032051111111,
              "alt": 2058.248222222222,
              "speed2d": 8.149999999999999,
              "speed3d": 8.22,
              "msFromStart": 28271.596358674462
            },
            {
              "lat": 43.77339052222223,
              "lng": -116.10031607777778,
              "alt": 2057.4928888888885,
              "speed2d": 7.902000000000001,
              "speed3d": 8.044444444444444,
              "msFromStart": 28766.194526315805
            },
            {
              "lat": 43.77342098888889,
              "lng": -116.10031323333332,
              "alt": 2057.0407777777777,
              "speed2d": 7.606222222222222,
              "speed3d": 7.70888888888889,
              "msFromStart": 29261.317300194933
            },
            {
              "lat": 43.77345131111111,
              "lng": -116.10031111111113,
              "alt": 2056.4154444444443,
              "speed2d": 7.379444444444444,
              "speed3d": 7.487777777777778,
              "msFromStart": 29757.89188888887
            },
            {
              "lat": 43.77348168888888,
              "lng": -116.10030783333333,
              "alt": 2056.086888888889,
              "speed2d": 7.242222222222223,
              "speed3d": 7.318888888888889,
              "msFromStart": 30253.896037037037
            },
            {
              "lat": 43.773511866666674,
              "lng": -116.10030414444444,
              "alt": 2055.682222222222,
              "speed2d": 7.152,
              "speed3d": 7.224444444444444,
              "msFromStart": 30748.14444444446
            },
            {
              "lat": 43.77354236666667,
              "lng": -116.10030223333334,
              "alt": 2055.1446666666666,
              "speed2d": 7.113666666666666,
              "speed3d": 7.2044444444444435,
              "msFromStart": 31242.31340740742
            },
            {
              "lat": 43.773572466666664,
              "lng": -116.10030122222223,
              "alt": 2054.5766666666664,
              "speed2d": 7.073333333333332,
              "speed3d": 7.105555555555554,
              "msFromStart": 31736.517555555576
            },
            {
              "lat": 43.77360544444444,
              "lng": -116.10030091111112,
              "alt": 2053.9828888888887,
              "speed2d": 7.279222222222222,
              "speed3d": 7.332222222222223,
              "msFromStart": 32231.046592592596
            },
            {
              "lat": 43.773637522222224,
              "lng": -116.1003032111111,
              "alt": 2053.750111111111,
              "speed2d": 7.242666666666667,
              "speed3d": 7.292222222222222,
              "msFromStart": 32726.452444444425
            },
            {
              "lat": 43.77367046999999,
              "lng": -116.10030285,
              "alt": 2053.1805000000004,
              "speed2d": 7.2234,
              "speed3d": 7.29,
              "msFromStart": 33249.215249122804
            },
            {
              "lat": 43.77370331111111,
              "lng": -116.10030286666665,
              "alt": 2052.6472222222224,
              "speed2d": 7.156888888888888,
              "speed3d": 7.190000000000001,
              "msFromStart": 33771.48821052632
            },
            {
              "lat": 43.773735877777774,
              "lng": -116.1003034,
              "alt": 2052.096,
              "speed2d": 7.299777777777778,
              "speed3d": 7.36,
              "msFromStart": 34266.32936452241
            },
            {
              "lat": 43.77376594444445,
              "lng": -116.10030677777777,
              "alt": 2052.0757777777776,
              "speed2d": 7.0906666666666665,
              "speed3d": 7.1177777777777775,
              "msFromStart": 34761.38222222221
            },
            {
              "lat": 43.77379952222223,
              "lng": -116.10030995555555,
              "alt": 2051.6811111111115,
              "speed2d": 7.309333333333332,
              "speed3d": 7.334444444444444,
              "msFromStart": 35256.298444444445
            },
            {
              "lat": 43.77383102222222,
              "lng": -116.10031296666668,
              "alt": 2051.5009999999997,
              "speed2d": 7.154999999999999,
              "speed3d": 7.194444444444445,
              "msFromStart": 35750.813555555564
            },
            {
              "lat": 43.77386412222222,
              "lng": -116.1003114888889,
              "alt": 2051.0137777777777,
              "speed2d": 7.315111111111111,
              "speed3d": 7.333333333333334,
              "msFromStart": 36245.79703703703
            },
            {
              "lat": 43.773896055555554,
              "lng": -116.1003124888889,
              "alt": 2050.7573333333335,
              "speed2d": 7.246444444444444,
              "speed3d": 7.283333333333334,
              "msFromStart": 36742.10911111108
            },
            {
              "lat": 43.773927,
              "lng": -116.10030976666667,
              "alt": 2050.4950000000003,
              "speed2d": 6.980777777777778,
              "speed3d": 7.048888888888889,
              "msFromStart": 37237.83362962962
            },
            {
              "lat": 43.773959077777775,
              "lng": -116.10030593333335,
              "alt": 2050.3374444444444,
              "speed2d": 6.862555555555556,
              "speed3d": 6.877777777777778,
              "msFromStart": 37731.77355555556
            },
            {
              "lat": 43.77399378888889,
              "lng": -116.1003025888889,
              "alt": 2050.076777777778,
              "speed2d": 7.0635555555555545,
              "speed3d": 7.048888888888888,
              "msFromStart": 38225.8303196881
            },
            {
              "lat": 43.774034279999995,
              "lng": -116.10029629999998,
              "alt": 2049.9994,
              "speed2d": 7.486400000000001,
              "speed3d": 7.476,
              "msFromStart": 38747.94021052629
            },
            {
              "lat": 43.774073377777775,
              "lng": -116.10029189999999,
              "alt": 2050.0965555555554,
              "speed2d": 7.547444444444446,
              "speed3d": 7.57,
              "msFromStart": 39270.42581676414
            },
            {
              "lat": 43.77410908888889,
              "lng": -116.10028791111111,
              "alt": 2049.9892222222225,
              "speed2d": 7.482222222222222,
              "speed3d": 7.504444444444443,
              "msFromStart": 39766.36844444449
            },
            {
              "lat": 43.77414554444445,
              "lng": -116.10028261111111,
              "alt": 2049.789777777778,
              "speed2d": 7.570444444444445,
              "speed3d": 7.582222222222223,
              "msFromStart": 40262.4511851852
            },
            {
              "lat": 43.77417845555555,
              "lng": -116.10027725555555,
              "alt": 2049.3327777777777,
              "speed2d": 7.322666666666667,
              "speed3d": 7.385555555555556,
              "msFromStart": 40758.78022222221
            },
            {
              "lat": 43.77421374444444,
              "lng": -116.10027122222222,
              "alt": 2049.206777777778,
              "speed2d": 7.264111111111112,
              "speed3d": 7.263333333333333,
              "msFromStart": 41254.77948148148
            },
            {
              "lat": 43.774252777777775,
              "lng": -116.10026416666666,
              "alt": 2049.0044444444447,
              "speed2d": 7.6083333333333325,
              "speed3d": 7.593333333333332,
              "msFromStart": 41749.84844444447
            },
            {
              "lat": 43.77429213333333,
              "lng": -116.10025863333334,
              "alt": 2049.0794444444446,
              "speed2d": 7.815333333333333,
              "speed3d": 7.8,
              "msFromStart": 42244.639259259275
            },
            {
              "lat": 43.77433248888889,
              "lng": -116.10025458888887,
              "alt": 2049.1785555555552,
              "speed2d": 8.004111111111111,
              "speed3d": 7.997777777777777,
              "msFromStart": 42738.80800000003
            },
            {
              "lat": 43.774374133333325,
              "lng": -116.10025266666668,
              "alt": 2049.1133333333332,
              "speed2d": 8.266333333333334,
              "speed3d": 8.247777777777777,
              "msFromStart": 43233.1336140351
            },
            {
              "lat": 43.77441631111111,
              "lng": -116.10025104444445,
              "alt": 2048.943222222222,
              "speed2d": 8.508222222222223,
              "speed3d": 8.487777777777778,
              "msFromStart": 43727.97389473688
            },
            {
              "lat": 43.77445845,
              "lng": -116.10024526999999,
              "alt": 2048.511,
              "speed2d": 8.5905,
              "speed3d": 8.617,
              "msFromStart": 44250.420375438625
            },
            {
              "lat": 43.77449946666667,
              "lng": -116.1002402888889,
              "alt": 2047.8794444444445,
              "speed2d": 8.574444444444445,
              "speed3d": 8.598888888888888,
              "msFromStart": 44773.14688888892
            },
            {
              "lat": 43.774538,
              "lng": -116.10023797777778,
              "alt": 2046.9076666666665,
              "speed2d": 8.560333333333334,
              "speed3d": 8.59,
              "msFromStart": 45268.269259259265
            },
            {
              "lat": 43.774575266666666,
              "lng": -116.10023531111113,
              "alt": 2045.9865555555557,
              "speed2d": 8.315222222222221,
              "speed3d": 8.375555555555556,
              "msFromStart": 45763.077777777755
            },
            {
              "lat": 43.7746111,
              "lng": -116.10023424444444,
              "alt": 2044.9204444444447,
              "speed2d": 8.118111111111112,
              "speed3d": 8.166666666666668,
              "msFromStart": 46257.97918518518
            },
            {
              "lat": 43.77464874444445,
              "lng": -116.10023044444443,
              "alt": 2044.2608888888885,
              "speed2d": 8.120777777777779,
              "speed3d": 8.166666666666666,
              "msFromStart": 46753.17755555558
            },
            {
              "lat": 43.77468801111111,
              "lng": -116.10022875555555,
              "alt": 2043.504777777778,
              "speed2d": 8.314444444444446,
              "speed3d": 8.353333333333333,
              "msFromStart": 47248.54037037037
            },
            {
              "lat": 43.77472595555555,
              "lng": -116.10023094444446,
              "alt": 2042.9190000000003,
              "speed2d": 8.212666666666667,
              "speed3d": 8.296666666666667,
              "msFromStart": 47744.30711111109
            },
            {
              "lat": 43.774763300000004,
              "lng": -116.10023447777778,
              "alt": 2042.5984444444446,
              "speed2d": 8.122111111111112,
              "speed3d": 8.186666666666664,
              "msFromStart": 48239.87733333333
            },
            {
              "lat": 43.774799622222226,
              "lng": -116.1002413222222,
              "alt": 2042.7741111111113,
              "speed2d": 7.875,
              "speed3d": 7.949999999999999,
              "msFromStart": 48734.85644444445
            },
            {
              "lat": 43.77483491111111,
              "lng": -116.10024726666667,
              "alt": 2042.3875555555558,
              "speed2d": 7.799666666666667,
              "speed3d": 7.855555555555555,
              "msFromStart": 49229.65139961015
            },
            {
              "lat": 43.77487028,
              "lng": -116.10025449000001,
              "alt": 2041.9965,
              "speed2d": 7.6301,
              "speed3d": 7.6930000000000005,
              "msFromStart": 49751.49526315793
            },
            {
              "lat": 43.774904400000004,
              "lng": -116.10026347777777,
              "alt": 2041.7748888888887,
              "speed2d": 7.424888888888889,
              "speed3d": 7.497777777777777,
              "msFromStart": 50273.777789473694
            },
            {
              "lat": 43.77493543333334,
              "lng": -116.10027177777778,
              "alt": 2041.3021111111111,
              "speed2d": 7.244111111111112,
              "speed3d": 7.304444444444445,
              "msFromStart": 50769.846000000034
            },
            {
              "lat": 43.774966211111106,
              "lng": -116.10027743333333,
              "alt": 2040.839666666667,
              "speed2d": 7.0586666666666655,
              "speed3d": 7.143333333333333,
              "msFromStart": 51265.542370370385
            },
            {
              "lat": 43.77499551111111,
              "lng": -116.10028045555555,
              "alt": 2040.2333333333333,
              "speed2d": 6.851111111111111,
              "speed3d": 6.906666666666666,
              "msFromStart": 51760.05088888889
            },
            {
              "lat": 43.77502864444445,
              "lng": -116.10027913333333,
              "alt": 2039.7035555555553,
              "speed2d": 7.063777777777777,
              "speed3d": 7.075555555555557,
              "msFromStart": 52254.85725925925
            },
            {
              "lat": 43.775062077777775,
              "lng": -116.10027684444444,
              "alt": 2038.803222222222,
              "speed2d": 7.236000000000001,
              "speed3d": 7.321111111111111,
              "msFromStart": 52750.64177777778
            },
            {
              "lat": 43.77509007777779,
              "lng": -116.10028231111113,
              "alt": 2038.9116666666666,
              "speed2d": 6.678999999999999,
              "speed3d": 6.792222222222222,
              "msFromStart": 53246.553481481475
            },
            {
              "lat": 43.7751188888889,
              "lng": -116.10028565555555,
              "alt": 2038.9036666666666,
              "speed2d": 6.497333333333334,
              "speed3d": 6.53,
              "msFromStart": 53742.670666666636
            },
            {
              "lat": 43.77514913333333,
              "lng": -116.1002885,
              "alt": 2038.9291111111108,
              "speed2d": 6.554222222222222,
              "speed3d": 6.592222222222222,
              "msFromStart": 54238.31644444443
            },
            {
              "lat": 43.775180166666665,
              "lng": -116.10028925555554,
              "alt": 2038.9825555555556,
              "speed2d": 6.681000000000001,
              "speed3d": 6.688888888888889,
              "msFromStart": 54732.65333333331
            },
            {
              "lat": 43.775209644444445,
              "lng": -116.10029123333334,
              "alt": 2039.0548888888889,
              "speed2d": 6.530333333333334,
              "speed3d": 6.613333333333333,
              "msFromStart": 55226.81391812865
            },
            {
              "lat": 43.77523569,
              "lng": -116.10029363999999,
              "alt": 2038.9764000000002,
              "speed2d": 5.932,
              "speed3d": 6.003,
              "msFromStart": 55748.11926315789
            },
            {
              "lat": 43.77526093333334,
              "lng": -116.10029586666667,
              "alt": 2038.9965555555557,
              "speed2d": 5.481111111111111,
              "speed3d": 5.53888888888889,
              "msFromStart": 56269.73497465885
            },
            {
              "lat": 43.77528496666666,
              "lng": -116.10029686666667,
              "alt": 2038.9283333333335,
              "speed2d": 5.3326666666666656,
              "speed3d": 5.364444444444445,
              "msFromStart": 56764.798444444416
            },
            {
              "lat": 43.77530918888889,
              "lng": -116.10029784444447,
              "alt": 2038.928666666667,
              "speed2d": 5.25911111111111,
              "speed3d": 5.273333333333333,
              "msFromStart": 57260.20918518517
            },
            {
              "lat": 43.775333455555554,
              "lng": -116.10029974444444,
              "alt": 2038.542222222222,
              "speed2d": 5.269888888888889,
              "speed3d": 5.295555555555556,
              "msFromStart": 57756.43622222218
            },
            {
              "lat": 43.775356277777774,
              "lng": -116.10030231111111,
              "alt": 2037.7954444444445,
              "speed2d": 5.145666666666667,
              "speed3d": 5.194444444444444,
              "msFromStart": 58252.55999999998
            },
            {
              "lat": 43.775379199999996,
              "lng": -116.10030615555556,
              "alt": 2037.5532222222223,
              "speed2d": 5.044,
              "speed3d": 5.058888888888888,
              "msFromStart": 58748.28688888888
            },
            {
              "lat": 43.77540677777779,
              "lng": -116.10030734444445,
              "alt": 2037.5761111111112,
              "speed2d": 5.416111111111111,
              "speed3d": 5.391111111111112,
              "msFromStart": 59243.72634307993
            },
            {
              "lat": 43.775436444444445,
              "lng": -116.10030822222222,
              "alt": 2037.4232222222222,
              "speed2d": 5.8133333333333335,
              "speed3d": 5.795555555555556,
              "msFromStart": 59738.442315789514
            },
            {
              "lat": 43.775466211111116,
              "lng": -116.10031012222223,
              "alt": 2037.1677777777777,
              "speed2d": 6.085444444444445,
              "speed3d": 6.084444444444443,
              "msFromStart": 60233.04245224174
            },
            {
              "lat": 43.77549326666667,
              "lng": -116.10031226666666,
              "alt": 2036.757888888889,
              "speed2d": 6.018666666666667,
              "speed3d": 6.061111111111111,
              "msFromStart": 60727.33155555557
            },
            {
              "lat": 43.775521360000006,
              "lng": -116.10031651,
              "alt": 2036.7305,
              "speed2d": 5.907800000000001,
              "speed3d": 5.927,
              "msFromStart": 61248.970866666656
            },
            {
              "lat": 43.77555118888889,
              "lng": -116.10032091111111,
              "alt": 2036.4066666666665,
              "speed2d": 6.045444444444445,
              "speed3d": 6.071111111111111,
              "msFromStart": 61770.3593333333
            },
            {
              "lat": 43.77557737777778,
              "lng": -116.10032469999999,
              "alt": 2036.244888888889,
              "speed2d": 5.952444444444445,
              "speed3d": 5.967777777777778,
              "msFromStart": 62264.74785185184
            },
            {
              "lat": 43.775604977777775,
              "lng": -116.10033118888889,
              "alt": 2036.218222222222,
              "speed2d": 5.996111111111111,
              "speed3d": 6.034444444444445,
              "msFromStart": 62760.391777777804
            },
            {
              "lat": 43.775630766666666,
              "lng": -116.10033391111112,
              "alt": 2036.012111111111,
              "speed2d": 5.8725555555555555,
              "speed3d": 5.885555555555555,
              "msFromStart": 63256.08674074075
            },
            {
              "lat": 43.775658122222225,
              "lng": -116.10033615555557,
              "alt": 2035.6886666666664,
              "speed2d": 5.957777777777777,
              "speed3d": 5.964444444444444,
              "msFromStart": 63751.740888888875
            },
            {
              "lat": 43.775687211111105,
              "lng": -116.10033925555555,
              "alt": 2035.623,
              "speed2d": 6.094777777777778,
              "speed3d": 6.106666666666667,
              "msFromStart": 64247.00230799219
            },
            {
              "lat": 43.7757170888889,
              "lng": -116.10034449999999,
              "alt": 2035.5276666666666,
              "speed2d": 6.304666666666666,
              "speed3d": 6.317777777777778,
              "msFromStart": 64741.203578947345
            },
            {
              "lat": 43.77574397777778,
              "lng": -116.1003503777778,
              "alt": 2035.3106666666667,
              "speed2d": 6.197000000000001,
              "speed3d": 6.232222222222222,
              "msFromStart": 65235.577298245604
            },
            {
              "lat": 43.775771600000006,
              "lng": -116.10035618888888,
              "alt": 2035.1555555555556,
              "speed2d": 6.1608888888888895,
              "speed3d": 6.174444444444444,
              "msFromStart": 65730.86400000002
            },
            {
              "lat": 43.775798522222225,
              "lng": -116.10036265555556,
              "alt": 2035.2763333333332,
              "speed2d": 6.045666666666667,
              "speed3d": 6.111111111111111,
              "msFromStart": 66226.13422222222
            },
            {
              "lat": 43.7758247,
              "lng": -116.10036672000001,
              "alt": 2035.1239,
              "speed2d": 5.802499999999999,
              "speed3d": 5.844,
              "msFromStart": 66748.46199999994
            },
            {
              "lat": 43.775851511111114,
              "lng": -116.1003716888889,
              "alt": 2034.7275555555552,
              "speed2d": 5.770444444444444,
              "speed3d": 5.745555555555555,
              "msFromStart": 67270.9333333333
            },
            {
              "lat": 43.77587977777778,
              "lng": -116.10037681111112,
              "alt": 2034.5325555555555,
              "speed2d": 5.894,
              "speed3d": 5.966666666666667,
              "msFromStart": 67766.42266666664
            },
            {
              "lat": 43.77590082222221,
              "lng": -116.10038401111112,
              "alt": 2034.4186666666667,
              "speed2d": 5.368666666666668,
              "speed3d": 5.445555555555556,
              "msFromStart": 68261.96208187133
            },
            {
              "lat": 43.77592712222222,
              "lng": -116.10038934444444,
              "alt": 2034.1242222222222,
              "speed2d": 5.533555555555555,
              "speed3d": 5.497777777777777,
              "msFromStart": 68757.56505263153
            },
            {
              "lat": 43.77595611111111,
              "lng": -116.10039597777777,
              "alt": 2033.905111111111,
              "speed2d": 5.8196666666666665,
              "speed3d": 5.857777777777777,
              "msFromStart": 69253.3830092879
            },
            {
              "lat": 43.775982344444444,
              "lng": -116.10040283333333,
              "alt": 2033.6844444444444,
              "speed2d": 5.804111111111112,
              "speed3d": 5.86,
              "msFromStart": 69750.04752941181
            },
            {
              "lat": 43.776004477777775,
              "lng": -116.10041186666666,
              "alt": 2033.3733333333332,
              "speed2d": 5.539222222222222,
              "speed3d": 5.561111111111112,
              "msFromStart": 70246.15949638805
            },
            {
              "lat": 43.77603156666667,
              "lng": -116.1004183888889,
              "alt": 2033.2094444444442,
              "speed2d": 5.693333333333333,
              "speed3d": 5.722222222222221,
              "msFromStart": 70740.54189473689
            },
            {
              "lat": 43.776057111111115,
              "lng": -116.10042583333335,
              "alt": 2033.1255555555554,
              "speed2d": 5.635333333333333,
              "speed3d": 5.669999999999999,
              "msFromStart": 71234.80745419106
            },
            {
              "lat": 43.77608647777778,
              "lng": -116.10043275555556,
              "alt": 2033.0233333333333,
              "speed2d": 5.964,
              "speed3d": 5.967777777777778,
              "msFromStart": 71728.96977777778
            },
            {
              "lat": 43.776114760000006,
              "lng": -116.10044112,
              "alt": 2033.0364000000002,
              "speed2d": 5.8943,
              "speed3d": 5.943,
              "msFromStart": 72250.52893333335
            },
            {
              "lat": 43.77614113333333,
              "lng": -116.1004487888889,
              "alt": 2033.1361111111112,
              "speed2d": 5.681555555555555,
              "speed3d": 5.735555555555555,
              "msFromStart": 72771.94000000006
            },
            {
              "lat": 43.77616814444445,
              "lng": -116.10045282222222,
              "alt": 2032.9122222222222,
              "speed2d": 5.783,
              "speed3d": 5.76,
              "msFromStart": 73266.14903703706
            },
            {
              "lat": 43.77619942222222,
              "lng": -116.1004557888889,
              "alt": 2032.6328888888888,
              "speed2d": 6.235222222222222,
              "speed3d": 6.221111111111111,
              "msFromStart": 73761.04488888892
            },
            {
              "lat": 43.77623188888889,
              "lng": -116.10046147777778,
              "alt": 2032.5534444444445,
              "speed2d": 6.611777777777778,
              "speed3d": 6.592222222222222,
              "msFromStart": 74256.16939571152
            },
            {
              "lat": 43.77626626666667,
              "lng": -116.10047094444445,
              "alt": 2032.7183333333337,
              "speed2d": 7.021222222222221,
              "speed3d": 6.996666666666667,
              "msFromStart": 74751.81642105269
            },
            {
              "lat": 43.77629846666667,
              "lng": -116.10047878888889,
              "alt": 2032.522111111111,
              "speed2d": 7.0181111111111125,
              "speed3d": 7.07888888888889,
              "msFromStart": 75247.23196101366
            },
            {
              "lat": 43.77632776666667,
              "lng": -116.10048877777777,
              "alt": 2032.5405555555558,
              "speed2d": 6.725333333333333,
              "speed3d": 6.769999999999999,
              "msFromStart": 75741.61155555549
            },
            {
              "lat": 43.776356488888894,
              "lng": -116.10049893333334,
              "alt": 2032.6381111111111,
              "speed2d": 6.542888888888889,
              "speed3d": 6.572222222222223,
              "msFromStart": 76236.24681481479
            },
            {
              "lat": 43.77638196666666,
              "lng": -116.10050985555556,
              "alt": 2032.991888888889,
              "speed2d": 6.126777777777778,
              "speed3d": 6.21,
              "msFromStart": 76732.31911111114
            },
            {
              "lat": 43.77640648888889,
              "lng": -116.10051763333333,
              "alt": 2033.0045555555555,
              "speed2d": 5.8327777777777765,
              "speed3d": 5.852222222222222,
              "msFromStart": 77228.54548148149
            },
            {
              "lat": 43.77643334444444,
              "lng": -116.10052536666666,
              "alt": 2032.7430000000002,
              "speed2d": 5.852333333333333,
              "speed3d": 5.8822222222222225,
              "msFromStart": 77724.81333333332
            },
            {
              "lat": 43.7764641,
              "lng": -116.10053457,
              "alt": 2032.8703999999998,
              "speed2d": 6.1343,
              "speed3d": 6.085999999999999,
              "msFromStart": 78248.50853333334
            },
            {
              "lat": 43.776493433333336,
              "lng": -116.10054344444445,
              "alt": 2033.1127777777779,
              "speed2d": 6.082888888888889,
              "speed3d": 6.156666666666666,
              "msFromStart": 78771.72622222228
            },
            {
              "lat": 43.77651873333334,
              "lng": -116.10055,
              "alt": 2032.9967777777774,
              "speed2d": 5.858111111111111,
              "speed3d": 5.8677777777777775,
              "msFromStart": 79266.58036647177
            },
            {
              "lat": 43.77654606666666,
              "lng": -116.10055631111112,
              "alt": 2032.858888888889,
              "speed2d": 5.84,
              "speed3d": 5.858888888888889,
              "msFromStart": 79759.30147368427
            },
            {
              "lat": 43.7765727,
              "lng": -116.10056042222222,
              "alt": 2032.7669999999996,
              "speed2d": 5.796222222222221,
              "speed3d": 5.808888888888888,
              "msFromStart": 80252.91608577002
            },
            {
              "lat": 43.77660177777778,
              "lng": -116.10056413333332,
              "alt": 2032.500777777778,
              "speed2d": 6.023888888888889,
              "speed3d": 6.010000000000001,
              "msFromStart": 80750.5537777778
            },
            {
              "lat": 43.77663196666667,
              "lng": -116.10056747777776,
              "alt": 2032.2441111111114,
              "speed2d": 6.211888888888888,
              "speed3d": 6.21,
              "msFromStart": 81247.87525925925
            },
            {
              "lat": 43.776662300000005,
              "lng": -116.10056801111111,
              "alt": 2031.9397777777776,
              "speed2d": 6.358555555555555,
              "speed3d": 6.38,
              "msFromStart": 81742.32266666662
            },
            {
              "lat": 43.776688333333325,
              "lng": -116.10056615555555,
              "alt": 2031.409,
              "speed2d": 6.052888888888889,
              "speed3d": 6.118888888888889,
              "msFromStart": 82236.13303703701
            },
            {
              "lat": 43.77671217777778,
              "lng": -116.10056315555556,
              "alt": 2030.8156666666669,
              "speed2d": 5.700666666666667,
              "speed3d": 5.741111111111111,
              "msFromStart": 82728.54488888895
            },
            {
              "lat": 43.776741490000006,
              "lng": -116.1005605,
              "alt": 2030.4891,
              "speed2d": 5.7697,
              "speed3d": 5.811,
              "msFromStart": 83249.12853333337
            },
            {
              "lat": 43.776773,
              "lng": -116.10056075555556,
              "alt": 2030.280111111111,
              "speed2d": 5.9783333333333335,
              "speed3d": 5.945555555555555,
              "msFromStart": 83772.83111111108
            },
            {
              "lat": 43.776806933333326,
              "lng": -116.10056002222221,
              "alt": 2030.610777777778,
              "speed2d": 6.4670000000000005,
              "speed3d": 6.467777777777777,
              "msFromStart": 84268.80592592592
            },
            {
              "lat": 43.77684006666666,
              "lng": -116.10055663333334,
              "alt": 2030.6757777777775,
              "speed2d": 6.750222222222222,
              "speed3d": 6.725555555555557,
              "msFromStart": 84763.6266666666
            },
            {
              "lat": 43.77687392222222,
              "lng": -116.10055137777778,
              "alt": 2030.4365555555555,
              "speed2d": 6.987444444444444,
              "speed3d": 6.992222222222223,
              "msFromStart": 85258.0500116959
            },
            {
              "lat": 43.776908000000006,
              "lng": -116.1005462777778,
              "alt": 2029.9899999999998,
              "speed2d": 7.156555555555555,
              "speed3d": 7.147777777777778,
              "msFromStart": 85751.55957894742
            },
            {
              "lat": 43.776942644444446,
              "lng": -116.10054117777779,
              "alt": 2029.7136666666665,
              "speed2d": 7.2796666666666665,
              "speed3d": 7.337777777777779,
              "msFromStart": 86245.35115009749
            },
            {
              "lat": 43.77697425555556,
              "lng": -116.10053493333335,
              "alt": 2029.5691111111112,
              "speed2d": 7.099333333333334,
              "speed3d": 7.094444444444444,
              "msFromStart": 86740.46311111106
            },
            {
              "lat": 43.777007922222225,
              "lng": -116.10052877777778,
              "alt": 2029.2382222222222,
              "speed2d": 7.208777777777779,
              "speed3d": 7.2588888888888885,
              "msFromStart": 87235.86637037032
            },
            {
              "lat": 43.77703901111111,
              "lng": -116.10052325555557,
              "alt": 2029.042222222222,
              "speed2d": 7.104666666666666,
              "speed3d": 7.126666666666666,
              "msFromStart": 87731.90666666662
            },
            {
              "lat": 43.77706994444444,
              "lng": -116.10051834444445,
              "alt": 2028.843888888889,
              "speed2d": 7.07988888888889,
              "speed3d": 7.1,
              "msFromStart": 88227.77955555552
            },
            {
              "lat": 43.77710480999999,
              "lng": -116.10051288000001,
              "alt": 2028.7755,
              "speed2d": 7.1908,
              "speed3d": 7.18,
              "msFromStart": 88750.21999999997
            },
            {
              "lat": 43.7771415,
              "lng": -116.10050703333334,
              "alt": 2028.7321111111114,
              "speed2d": 7.273888888888888,
              "speed3d": 7.2988888888888885,
              "msFromStart": 89272.60533333333
            },
            {
              "lat": 43.77717433333334,
              "lng": -116.10050076666667,
              "alt": 2028.387777777778,
              "speed2d": 7.2554444444444455,
              "speed3d": 7.279999999999999,
              "msFromStart": 89767.60000000002
            },
            {
              "lat": 43.77720844444445,
              "lng": -116.10049310000001,
              "alt": 2027.9952222222223,
              "speed2d": 7.432,
              "speed3d": 7.441111111111112,
              "msFromStart": 90262.57185185186
            },
            {
              "lat": 43.77723871111111,
              "lng": -116.10048619999999,
              "alt": 2027.5093333333334,
              "speed2d": 7.1835555555555555,
              "speed3d": 7.252222222222222,
              "msFromStart": 90757.43111111113
            },
            {
              "lat": 43.77727003333332,
              "lng": -116.10048006666668,
              "alt": 2027.6607777777779,
              "speed2d": 6.902555555555556,
              "speed3d": 6.938888888888888,
              "msFromStart": 91252.14651851852
            },
            {
              "lat": 43.77730256666666,
              "lng": -116.10047342222224,
              "alt": 2027.2315555555556,
              "speed2d": 7.136222222222221,
              "speed3d": 7.1433333333333335,
              "msFromStart": 91746.33155555559
            },
            {
              "lat": 43.777334644444444,
              "lng": -116.10046858888889,
              "alt": 2027.1583333333333,
              "speed2d": 7.0792222222222225,
              "speed3d": 7.095555555555556,
              "msFromStart": 92240.70651851853
            },
            {
              "lat": 43.77736814444444,
              "lng": -116.10046416666668,
              "alt": 2026.9924444444443,
              "speed2d": 7.259444444444444,
              "speed3d": 7.277777777777778,
              "msFromStart": 92736.05333333327
            },
            {
              "lat": 43.77739851111111,
              "lng": -116.10046102222222,
              "alt": 2026.5178888888886,
              "speed2d": 7.0360000000000005,
              "speed3d": 7.09,
              "msFromStart": 93231.56770370367
            },
            {
              "lat": 43.777431677777784,
              "lng": -116.10045661111111,
              "alt": 2026.6042222222222,
              "speed2d": 7.026111111111112,
              "speed3d": 7.032222222222221,
              "msFromStart": 93727.36355555554
            },
            {
              "lat": 43.77746641,
              "lng": -116.10045237,
              "alt": 2026.4594,
              "speed2d": 7.1093,
              "speed3d": 7.144,
              "msFromStart": 94250.6288
            },
            {
              "lat": 43.77749800000001,
              "lng": -116.10044693333333,
              "alt": 2026.2076666666667,
              "speed2d": 6.875333333333334,
              "speed3d": 6.914444444444445,
              "msFromStart": 94773.55555555555
            },
            {
              "lat": 43.77752635555555,
              "lng": -116.10043998888888,
              "alt": 2025.981888888889,
              "speed2d": 6.566333333333334,
              "speed3d": 6.6255555555555565,
              "msFromStart": 95268.66116179335
            },
            {
              "lat": 43.777555299999996,
              "lng": -116.10043477777778,
              "alt": 2025.7914444444443,
              "speed2d": 6.4432222222222215,
              "speed3d": 6.455555555555557,
              "msFromStart": 95763.0328421052
            },
            {
              "lat": 43.777588344444446,
              "lng": -116.10042951111112,
              "alt": 2025.7556666666665,
              "speed2d": 6.8071111111111104,
              "speed3d": 6.787777777777777,
              "msFromStart": 96257.5308849902
            },
            {
              "lat": 43.77761894444444,
              "lng": -116.1004244111111,
              "alt": 2025.7433333333336,
              "speed2d": 6.687333333333333,
              "speed3d": 6.742222222222223,
              "msFromStart": 96752.6888888889
            },
            {
              "lat": 43.77764757777778,
              "lng": -116.10041795555557,
              "alt": 2025.7703333333334,
              "speed2d": 6.438777777777778,
              "speed3d": 6.497777777777778,
              "msFromStart": 97247.85733333332
            },
            {
              "lat": 43.77767221111112,
              "lng": -116.10040751111111,
              "alt": 2025.7867777777776,
              "speed2d": 6.121222222222222,
              "speed3d": 6.147777777777778,
              "msFromStart": 97742.8035555555
            },
            {
              "lat": 43.777695666666666,
              "lng": -116.1003948111111,
              "alt": 2025.2645555555555,
              "speed2d": 6.0312222222222225,
              "speed3d": 6.044444444444445,
              "msFromStart": 98237.78977777774
            },
            {
              "lat": 43.77771925555555,
              "lng": -116.10037712222221,
              "alt": 2025.101222222222,
              "speed2d": 5.946000000000001,
              "speed3d": 5.995555555555557,
              "msFromStart": 98733.0248888889
            },
            {
              "lat": 43.77773712222223,
              "lng": -116.10035715555556,
              "alt": 2024.7624444444446,
              "speed2d": 5.503111111111112,
              "speed3d": 5.552222222222222,
              "msFromStart": 99228.1349863548
            },
            {
              "lat": 43.77775681,
              "lng": -116.10033339,
              "alt": 2024.433,
              "speed2d": 5.5535000000000005,
              "speed3d": 5.576,
              "msFromStart": 99750.11494736849
            },
            {
              "lat": 43.77777387777778,
              "lng": -116.10030774444444,
              "alt": 2024.123222222222,
              "speed2d": 5.475555555555555,
              "speed3d": 5.488888888888889,
              "msFromStart": 100272.27225730999
            },
            {
              "lat": 43.77778994444445,
              "lng": -116.10028047777779,
              "alt": 2023.8873333333333,
              "speed2d": 5.599,
              "speed3d": 5.619999999999999,
              "msFromStart": 100767.90933333336
            },
            {
              "lat": 43.77780387777777,
              "lng": -116.10025334444445,
              "alt": 2023.721888888889,
              "speed2d": 5.379333333333332,
              "speed3d": 5.422222222222222,
              "msFromStart": 101263.50133333336
            },
            {
              "lat": 43.77781662222222,
              "lng": -116.10022415555557,
              "alt": 2023.7688888888888,
              "speed2d": 5.323777777777779,
              "speed3d": 5.3422222222222215,
              "msFromStart": 101758.53333333334
            },
            {
              "lat": 43.77782817777778,
              "lng": -116.10019512222223,
              "alt": 2023.6315555555555,
              "speed2d": 5.257111111111112,
              "speed3d": 5.275555555555555,
              "msFromStart": 102253.46562962963
            },
            {
              "lat": 43.777840133333335,
              "lng": -116.10016868888889,
              "alt": 2023.3682222222224,
              "speed2d": 5.0985555555555555,
              "speed3d": 5.133333333333334,
              "msFromStart": 102748.2231111111
            },
            {
              "lat": 43.777851988888884,
              "lng": -116.10014087777779,
              "alt": 2023.2105555555559,
              "speed2d": 4.9754444444444434,
              "speed3d": 5.011111111111111,
              "msFromStart": 103243.11644444446
            },
            {
              "lat": 43.7778656,
              "lng": -116.10011286666665,
              "alt": 2023.5364444444444,
              "speed2d": 4.966222222222223,
              "speed3d": 4.984444444444445,
              "msFromStart": 103738.62311111114
            },
            {
              "lat": 43.77787984444445,
              "lng": -116.10008602222221,
              "alt": 2023.9165555555555,
              "speed2d": 4.876555555555555,
              "speed3d": 4.91,
              "msFromStart": 104234.13925925927
            },
            {
              "lat": 43.77789366666667,
              "lng": -116.10006007777777,
              "alt": 2024.1014444444443,
              "speed2d": 4.730333333333333,
              "speed3d": 4.747777777777777,
              "msFromStart": 104729.44800000006
            },
            {
              "lat": 43.77790855,
              "lng": -116.10002859,
              "alt": 2024.5029000000002,
              "speed2d": 4.9289000000000005,
              "speed3d": 4.911,
              "msFromStart": 105252.18568421058
            },
            {
              "lat": 43.777922177777775,
              "lng": -116.09999677777779,
              "alt": 2024.830111111111,
              "speed2d": 4.937111111111111,
              "speed3d": 4.947777777777777,
              "msFromStart": 105774.70863157902
            },
            {
              "lat": 43.777934099999996,
              "lng": -116.0999672111111,
              "alt": 2025.0835555555557,
              "speed2d": 5.001555555555555,
              "speed3d": 4.982222222222223,
              "msFromStart": 106269.66357115013
            },
            {
              "lat": 43.777945655555556,
              "lng": -116.09993783333334,
              "alt": 2024.7752222222223,
              "speed2d": 5.154111111111111,
              "speed3d": 5.159999999999999,
              "msFromStart": 106764.4231111111
            },
            {
              "lat": 43.77795617777778,
              "lng": -116.09990520000001,
              "alt": 2024.7155555555555,
              "speed2d": 5.354333333333335,
              "speed3d": 5.318888888888889,
              "msFromStart": 107259.29125925923
            },
            {
              "lat": 43.777966855555555,
              "lng": -116.09986956666665,
              "alt": 2024.9835555555555,
              "speed2d": 5.717555555555555,
              "speed3d": 5.675555555555556,
              "msFromStart": 107754.67199999993
            },
            {
              "lat": 43.777976688888884,
              "lng": -116.09983317777777,
              "alt": 2024.9984444444444,
              "speed2d": 5.936111111111112,
              "speed3d": 5.94888888888889,
              "msFromStart": 108249.92696296294
            },
            {
              "lat": 43.77798535555556,
              "lng": -116.09979552222222,
              "alt": 2024.9822222222224,
              "speed2d": 5.9768888888888885,
              "speed3d": 5.97,
              "msFromStart": 108744.4737777777
            },
            {
              "lat": 43.77799344444444,
              "lng": -116.09975763333335,
              "alt": 2025.0814444444445,
              "speed2d": 5.957333333333334,
              "speed3d": 5.951111111111111,
              "msFromStart": 109239.21593762179
            },
            {
              "lat": 43.778001599999996,
              "lng": -116.0997160888889,
              "alt": 2025.1334444444446,
              "speed2d": 6.3774444444444445,
              "speed3d": 6.321111111111112,
              "msFromStart": 109735.02273684206
            },
            {
              "lat": 43.77800684444445,
              "lng": -116.09967154444443,
              "alt": 2025.494222222222,
              "speed2d": 6.677555555555555,
              "speed3d": 6.651111111111111,
              "msFromStart": 110230.9664613385
            },
            {
              "lat": 43.77801162222222,
              "lng": -116.09962482222224,
              "alt": 2025.628111111111,
              "speed2d": 7.036111111111111,
              "speed3d": 7.038888888888888,
              "msFromStart": 110727.09600000006
            },
            {
              "lat": 43.77801592,
              "lng": -116.09958251,
              "alt": 2025.4708000000003,
              "speed2d": 6.658099999999999,
              "speed3d": 6.704,
              "msFromStart": 111250.19311111113
            },
            {
              "lat": 43.77801873333333,
              "lng": -116.09954532222223,
              "alt": 2025.0819999999999,
              "speed2d": 6.169777777777778,
              "speed3d": 6.221111111111112,
              "msFromStart": 111770.36266666665
            },
            {
              "lat": 43.77802063333334,
              "lng": -116.09950687777777,
              "alt": 2024.8853333333332,
              "speed2d": 6.117333333333335,
              "speed3d": 6.101111111111111,
              "msFromStart": 112263.2705185185
            },
            {
              "lat": 43.77802546666666,
              "lng": -116.09946738888891,
              "alt": 2024.9408888888888,
              "speed2d": 6.096666666666666,
              "speed3d": 6.131111111111112,
              "msFromStart": 112758.22577777771
            },
            {
              "lat": 43.77802919999999,
              "lng": -116.09942762222222,
              "alt": 2025.1923333333336,
              "speed2d": 6.061999999999999,
              "speed3d": 6.051111111111111,
              "msFromStart": 113253.47170370368
            },
            {
              "lat": 43.77803276666667,
              "lng": -116.09938593333335,
              "alt": 2025.1788888888887,
              "speed2d": 6.281000000000001,
              "speed3d": 6.268888888888888,
              "msFromStart": 113749.06133333335
            },
            {
              "lat": 43.77803714444445,
              "lng": -116.09934668888889,
              "alt": 2025.6736666666666,
              "speed2d": 6.118,
              "speed3d": 6.167777777777777,
              "msFromStart": 114244.49940740739
            },
            {
              "lat": 43.77804082222222,
              "lng": -116.09930987777777,
              "alt": 2026.074222222222,
              "speed2d": 5.8341111111111115,
              "speed3d": 5.847777777777777,
              "msFromStart": 114739.19377777775
            },
            {
              "lat": 43.778044466666664,
              "lng": -116.09927486666666,
              "alt": 2026.4647777777777,
              "speed2d": 5.488111111111111,
              "speed3d": 5.5344444444444445,
              "msFromStart": 115233.92835867446
            },
            {
              "lat": 43.77804667777777,
              "lng": -116.09924298888888,
              "alt": 2026.2616666666665,
              "speed2d": 5.283444444444444,
              "speed3d": 5.285555555555556,
              "msFromStart": 115729.12126315793
            },
            {
              "lat": 43.77804768,
              "lng": -116.09920692,
              "alt": 2026.5012,
              "speed2d": 5.2063,
              "speed3d": 5.2330000000000005,
              "msFromStart": 116251.84680701754
            },
            {
              "lat": 43.77804762222222,
              "lng": -116.09917106666667,
              "alt": 2026.4054444444444,
              "speed2d": 5.369666666666667,
              "speed3d": 5.304444444444445,
              "msFromStart": 116774.44888888884
            },
            {
              "lat": 43.77804954444444,
              "lng": -116.0991337,
              "alt": 2026.295111111111,
              "speed2d": 5.642555555555555,
              "speed3d": 5.647777777777779,
              "msFromStart": 117269.50148148146
            },
            {
              "lat": 43.77804953333333,
              "lng": -116.09909986666668,
              "alt": 2026.040222222222,
              "speed2d": 5.578333333333333,
              "speed3d": 5.587777777777777,
              "msFromStart": 117764.44444444448
            },
            {
              "lat": 43.77804966666667,
              "lng": -116.09906446666666,
              "alt": 2025.7805555555558,
              "speed2d": 5.705111111111111,
              "speed3d": 5.684444444444444,
              "msFromStart": 118259.57718518522
            },
            {
              "lat": 43.778049744444445,
              "lng": -116.09902728888889,
              "alt": 2025.4224444444446,
              "speed2d": 5.813111111111111,
              "speed3d": 5.8100000000000005,
              "msFromStart": 118755.51288888892
            },
            {
              "lat": 43.77804922222222,
              "lng": -116.09899113333334,
              "alt": 2025.2197777777774,
              "speed2d": 5.7283333333333335,
              "speed3d": 5.75,
              "msFromStart": 119251.27196881094
            },
            {
              "lat": 43.77804963333334,
              "lng": -116.09895256666667,
              "alt": 2025.355888888889,
              "speed2d": 5.759777777777778,
              "speed3d": 5.763333333333332,
              "msFromStart": 119746.00336842105
            },
            {
              "lat": 43.77805103333333,
              "lng": -116.09891245555556,
              "alt": 2025.437111111111,
              "speed2d": 5.917000000000001,
              "speed3d": 5.8933333333333335,
              "msFromStart": 120240.57809486678
            },
            {
              "lat": 43.7780539,
              "lng": -116.09887734444445,
              "alt": 2025.2571111111113,
              "speed2d": 5.746777777777778,
              "speed3d": 5.805555555555555,
              "msFromStart": 120734.82400000004
            },
            {
              "lat": 43.778053255555555,
              "lng": -116.09884278888889,
              "alt": 2024.9032222222222,
              "speed2d": 5.660666666666667,
              "speed3d": 5.6755555555555555,
              "msFromStart": 121229.1577283951
            },
            {
              "lat": 43.77805179,
              "lng": -116.09880378,
              "alt": 2024.767,
              "speed2d": 5.7063999999999995,
              "speed3d": 5.702999999999999,
              "msFromStart": 121751.87488888887
            },
            {
              "lat": 43.778051888888896,
              "lng": -116.09876547777776,
              "alt": 2024.3692222222223,
              "speed2d": 5.803333333333333,
              "speed3d": 5.816666666666666,
              "msFromStart": 122274.62933333332
            },
            {
              "lat": 43.778049055555556,
              "lng": -116.09872654444445,
              "alt": 2024.128444444444,
              "speed2d": 5.988555555555555,
              "speed3d": 5.996666666666666,
              "msFromStart": 122769.4471111111
            },
            {
              "lat": 43.77804571111111,
              "lng": -116.09869587777777,
              "alt": 2023.6145555555556,
              "speed2d": 5.787555555555556,
              "speed3d": 5.838888888888889,
              "msFromStart": 123264.41125925927
            },
            {
              "lat": 43.778043833333335,
              "lng": -116.0986649,
              "alt": 2022.7845555555555,
              "speed2d": 5.590777777777777,
              "speed3d": 5.642222222222221,
              "msFromStart": 123760.12800000004
            },
            {
              "lat": 43.77804288888889,
              "lng": -116.0986368111111,
              "alt": 2021.2569999999998,
              "speed2d": 5.3373333333333335,
              "speed3d": 5.444444444444445,
              "msFromStart": 124255.76982456142
            },
            {
              "lat": 43.778041277777774,
              "lng": -116.09860825555553,
              "alt": 2020.2884444444442,
              "speed2d": 5.114000000000001,
              "speed3d": 5.191111111111111,
              "msFromStart": 124750.81094736837
            }
          ]
        }
      ]
    },
    {
      "id": "trail-war-eagle",
      "pathType": "trail",
      "name": "War Eagle",
      "difficulty": "black",
      "videos": [
        {
          "videoId": "GX010089",
          "pathId": "trail-war-eagle",
          "startSec": 155,
          "endSec": 224,
          "id": "video-segment-56",
          "gps": [
            {
              "lat": 43.77585146,
              "lng": -116.09834975999999,
              "alt": 2111.1626,
              "speed2d": 4.3492,
              "speed3d": 4.492,
              "msFromStart": 155284.9178385965
            },
            {
              "lat": 43.77588196666667,
              "lng": -116.09834853333334,
              "alt": 2108.8683333333333,
              "speed2d": 4.821666666666666,
              "speed3d": 4.9799999999999995,
              "msFromStart": 155811.62105263147
            },
            {
              "lat": 43.775921679999996,
              "lng": -116.09835807999998,
              "alt": 2106.9512,
              "speed2d": 6.4568,
              "speed3d": 6.47,
              "msFromStart": 156349.19702456135
            },
            {
              "lat": 43.77593281750034,
              "lng": -116.0983643282571,
              "alt": 2106.414944952616,
              "speed2d": 6.882823156159862,
              "speed3d": 6.929414091470943,
              "msFromStart": 156500
            },
            {
              "lat": 43.77596314421694,
              "lng": -116.09839650305534,
              "alt": 2103.9945639448365,
              "speed2d": 7.263649157759489,
              "speed3d": 7.420216098305785,
              "msFromStart": 157000
            },
            {
              "lat": 43.775993470933535,
              "lng": -116.09842867785356,
              "alt": 2101.5741829370563,
              "speed2d": 7.644475159359117,
              "speed3d": 7.911018105140627,
              "msFromStart": 157500
            },
            {
              "lat": 43.776037,
              "lng": -116.0984845,
              "alt": 2097.8535,
              "speed2d": 8.268166666666666,
              "speed3d": 8.596666666666668,
              "msFromStart": 158285.57777777786
            },
            {
              "lat": 43.77605556,
              "lng": -116.09853207999998,
              "alt": 2095.7664000000004,
              "speed2d": 8.8304,
              "speed3d": 9.145999999999999,
              "msFromStart": 158758.84604444433
            },
            {
              "lat": 43.77606675,
              "lng": -116.09856049999999,
              "alt": 2094.4515,
              "speed2d": 9.2375,
              "speed3d": 9.555,
              "msFromStart": 159028.46799999982
            },
            {
              "lat": 43.77608947542205,
              "lng": -116.09859371608279,
              "alt": 2091.9817298351677,
              "speed2d": 8.694953772401345,
              "speed3d": 9.168491559106975,
              "msFromStart": 159500
            },
            {
              "lat": 43.7761353,
              "lng": -116.0986515,
              "alt": 2087.3334999999997,
              "speed2d": 7.5525,
              "speed3d": 8.285,
              "msFromStart": 160404.12336842105
            },
            {
              "lat": 43.77616535,
              "lng": -116.09865489999999,
              "alt": 2085.309166666667,
              "speed2d": 7.596166666666667,
              "speed3d": 8.301666666666666,
              "msFromStart": 160789.032
            },
            {
              "lat": 43.77620615,
              "lng": -116.0986498,
              "alt": 2083.3405000000002,
              "speed2d": 8.2585,
              "speed3d": 8.76,
              "msFromStart": 161256.32996491226
            },
            {
              "lat": 43.77625292222222,
              "lng": -116.0986355111111,
              "alt": 2081.514,
              "speed2d": 9.438444444444443,
              "speed3d": 9.97,
              "msFromStart": 161750.81777777773
            },
            {
              "lat": 43.776287599999996,
              "lng": -116.09862439999999,
              "alt": 2080.5019999999995,
              "speed2d": 9.897,
              "speed3d": 10.484000000000002,
              "msFromStart": 162135.30133333325
            },
            {
              "lat": 43.776325650000004,
              "lng": -116.09860925000001,
              "alt": 2079.4145,
              "speed2d": 10.3955,
              "speed3d": 10.91,
              "msFromStart": 162547.68800000002
            },
            {
              "lat": 43.77636418901062,
              "lng": -116.09859906701344,
              "alt": 2077.933605881712,
              "speed2d": 10.144658558659097,
              "speed3d": 10.650442506794386,
              "msFromStart": 163000
            },
            {
              "lat": 43.776443150000006,
              "lng": -116.09858125,
              "alt": 2074.8655,
              "speed2d": 9.590499999999999,
              "speed3d": 10.125,
              "msFromStart": 163924.78044444433
            },
            {
              "lat": 43.77647114285714,
              "lng": -116.09858711428573,
              "alt": 2073.837,
              "speed2d": 9.892285714285714,
              "speed3d": 10.488571428571428,
              "msFromStart": 164204.34920634914
            },
            {
              "lat": 43.7765051,
              "lng": -116.09859755,
              "alt": 2072.1825,
              "speed2d": 10.2355,
              "speed3d": 10.795,
              "msFromStart": 164557.82666666672
            },
            {
              "lat": 43.77655905,
              "lng": -116.09862421666666,
              "alt": 2068.1906666666664,
              "speed2d": 9.733500000000001,
              "speed3d": 10.595,
              "msFromStart": 165262.63901754396
            },
            {
              "lat": 43.77658855,
              "lng": -116.0986419125,
              "alt": 2064.707375,
              "speed2d": 9.107375000000001,
              "speed3d": 10.1225,
              "msFromStart": 165739.83157894746
            },
            {
              "lat": 43.776633125000004,
              "lng": -116.09864593750001,
              "alt": 2061.4916249999997,
              "speed2d": 9.103750000000002,
              "speed3d": 10.116249999999999,
              "msFromStart": 166290.60080701762
            },
            {
              "lat": 43.77666644999999,
              "lng": -116.09864110000001,
              "alt": 2060.00325,
              "speed2d": 9.468250000000001,
              "speed3d": 10.4325,
              "msFromStart": 166648.37866666666
            },
            {
              "lat": 43.77672465,
              "lng": -116.09861584999999,
              "alt": 2057.815375,
              "speed2d": 10.36925,
              "speed3d": 11.25875,
              "msFromStart": 167253.59155555555
            },
            {
              "lat": 43.7767694125,
              "lng": -116.0985872375,
              "alt": 2055.83975,
              "speed2d": 10.79775,
              "speed3d": 11.677499999999998,
              "msFromStart": 167754.21455555558
            },
            {
              "lat": 43.7768093,
              "lng": -116.09855759999999,
              "alt": 2054.1735,
              "speed2d": 11.035,
              "speed3d": 11.93,
              "msFromStart": 168213.35066666672
            },
            {
              "lat": 43.77684775,
              "lng": -116.09852935,
              "alt": 2052.7695000000003,
              "speed2d": 10.645,
              "speed3d": 11.56,
              "msFromStart": 168680.3288888889
            },
            {
              "lat": 43.77687117445124,
              "lng": -116.0985155908623,
              "alt": 2051.2092012697262,
              "speed2d": 10.07051434647433,
              "speed3d": 11.067715705097509,
              "msFromStart": 169000
            },
            {
              "lat": 43.7769186,
              "lng": -116.09849313333333,
              "alt": 2047.6376666666665,
              "speed2d": 8.707999999999998,
              "speed3d": 9.923333333333334,
              "msFromStart": 169688.63940740738
            },
            {
              "lat": 43.776955537499994,
              "lng": -116.098493675,
              "alt": 2044.449625,
              "speed2d": 8.224250000000001,
              "speed3d": 9.5025,
              "msFromStart": 170248.1334327485
            },
            {
              "lat": 43.77699365000001,
              "lng": -116.09850473750001,
              "alt": 2041.6177500000001,
              "speed2d": 7.93125,
              "speed3d": 9.265,
              "msFromStart": 170797.18400000007
            },
            {
              "lat": 43.77701368,
              "lng": -116.09851818,
              "alt": 2039.9180000000001,
              "speed2d": 7.5486,
              "speed3d": 8.962,
              "msFromStart": 171153.88210526324
            },
            {
              "lat": 43.77703398692946,
              "lng": -116.09852438995293,
              "alt": 2038.5022999199987,
              "speed2d": 7.210577297340592,
              "speed3d": 8.649593555135185,
              "msFromStart": 171500
            },
            {
              "lat": 43.77708176,
              "lng": -116.09852763999999,
              "alt": 2035.3982,
              "speed2d": 6.783799999999999,
              "speed3d": 8.216,
              "msFromStart": 172254.35715555545
            },
            {
              "lat": 43.777114159999996,
              "lng": -116.09851882000001,
              "alt": 2032.7314000000001,
              "speed2d": 7.3713999999999995,
              "speed3d": 8.692,
              "msFromStart": 172694.2533333333
            },
            {
              "lat": 43.777139622737565,
              "lng": -116.09850950279676,
              "alt": 2030.503272740114,
              "speed2d": 8.137596407668555,
              "speed3d": 9.346302865093035,
              "msFromStart": 173000
            },
            {
              "lat": 43.777174430342015,
              "lng": -116.09848454160233,
              "alt": 2028.3808445418415,
              "speed2d": 7.959198351458386,
              "speed3d": 9.215501846283134,
              "msFromStart": 173500
            },
            {
              "lat": 43.777209237946465,
              "lng": -116.09845958040792,
              "alt": 2026.2584163435693,
              "speed2d": 7.7808002952482145,
              "speed3d": 9.084700827473233,
              "msFromStart": 174000
            },
            {
              "lat": 43.777244045550916,
              "lng": -116.09843461921349,
              "alt": 2024.1359881452968,
              "speed2d": 7.602402239038044,
              "speed3d": 8.953899808663332,
              "msFromStart": 174500
            },
            {
              "lat": 43.777278853155366,
              "lng": -116.09840965801907,
              "alt": 2022.0135599470245,
              "speed2d": 7.424004182827874,
              "speed3d": 8.82309878985343,
              "msFromStart": 175000
            },
            {
              "lat": 43.77734006666667,
              "lng": -116.0983688,
              "alt": 2018.2690000000002,
              "speed2d": 7.224666666666666,
              "speed3d": 8.68,
              "msFromStart": 175868.0005614034
            },
            {
              "lat": 43.77737053333333,
              "lng": -116.09836686666667,
              "alt": 2016.4499999999998,
              "speed2d": 7.177444444444445,
              "speed3d": 8.733333333333333,
              "msFromStart": 176271.21231968803
            },
            {
              "lat": 43.77739371111112,
              "lng": -116.09838133333335,
              "alt": 2014.5628888888891,
              "speed2d": 6.020666666666666,
              "speed3d": 7.819999999999999,
              "msFromStart": 176766.23288888883
            },
            {
              "lat": 43.77741171111111,
              "lng": -116.09839525555557,
              "alt": 2013.1686666666667,
              "speed2d": 5.292666666666667,
              "speed3d": 7.163333333333332,
              "msFromStart": 177261.2968888888
            },
            {
              "lat": 43.777439,
              "lng": -116.0983923,
              "alt": 2010.729,
              "speed2d": 5.2925,
              "speed3d": 7.12,
              "msFromStart": 177838.8715555555
            },
            {
              "lat": 43.77746306666666,
              "lng": -116.09837830000001,
              "alt": 2008.6876666666667,
              "speed2d": 5.655666666666667,
              "speed3d": 7.416666666666666,
              "msFromStart": 178306.53688888886
            },
            {
              "lat": 43.77748881666667,
              "lng": -116.0983588,
              "alt": 2006.0693333333334,
              "speed2d": 6.544333333333333,
              "speed3d": 8.131666666666666,
              "msFromStart": 178765.69866666663
            },
            {
              "lat": 43.777519174999995,
              "lng": -116.09833975,
              "alt": 2003.32375,
              "speed2d": 7.534,
              "speed3d": 9,
              "msFromStart": 179188.1824444444
            },
            {
              "lat": 43.777550063557236,
              "lng": -116.09832041190143,
              "alt": 2001.1153267822167,
              "speed2d": 8.784398587365603,
              "speed3d": 10.191752820909532,
              "msFromStart": 179500
            },
            {
              "lat": 43.77758408943579,
              "lng": -116.09831049262672,
              "alt": 1999.1196161353425,
              "speed2d": 8.193194007618352,
              "speed3d": 9.693813134758773,
              "msFromStart": 180000
            },
            {
              "lat": 43.77761811531435,
              "lng": -116.09830057335203,
              "alt": 1997.1239054884686,
              "speed2d": 7.601989427871102,
              "speed3d": 9.195873448608014,
              "msFromStart": 180500
            },
            {
              "lat": 43.777678933333334,
              "lng": -116.09829878333333,
              "alt": 1994.3833333333332,
              "speed2d": 7.272666666666668,
              "speed3d": 8.825,
              "msFromStart": 181278.3270643275
            },
            {
              "lat": 43.77770216708963,
              "lng": -116.09830993247621,
              "alt": 1994.0750770343964,
              "speed2d": 7.834611068951403,
              "speed3d": 9.190341845648737,
              "msFromStart": 181500
            },
            {
              "lat": 43.7777953,
              "lng": -116.09836014999999,
              "alt": 1991.647,
              "speed2d": 8.725999999999999,
              "speed3d": 9.940000000000001,
              "msFromStart": 182459.88666666672
            },
            {
              "lat": 43.77782868888889,
              "lng": -116.09836349999999,
              "alt": 1989.9654444444445,
              "speed2d": 8.923666666666666,
              "speed3d": 10.186666666666667,
              "msFromStart": 182761.82933333341
            },
            {
              "lat": 43.777871960000006,
              "lng": -116.0983678,
              "alt": 1987.5954,
              "speed2d": 9.1848,
              "speed3d": 10.494000000000002,
              "msFromStart": 183190.03893333342
            },
            {
              "lat": 43.777928466666665,
              "lng": -116.09837240000002,
              "alt": 1985.0176666666666,
              "speed2d": 9.255333333333333,
              "speed3d": 10.563333333333333,
              "msFromStart": 183769.3641481481
            },
            {
              "lat": 43.77795026250522,
              "lng": -116.09837038077309,
              "alt": 1983.928345998833,
              "speed2d": 9.257898004613528,
              "speed3d": 10.596661930642775,
              "msFromStart": 184000
            },
            {
              "lat": 43.7780202,
              "lng": -116.09834834999998,
              "alt": 1979.7442500000002,
              "speed2d": 8.41375,
              "speed3d": 10.024999999999999,
              "msFromStart": 184879.59199999986
            },
            {
              "lat": 43.7780409,
              "lng": -116.09833395,
              "alt": 1978.2665,
              "speed2d": 8.167,
              "speed3d": 9.79,
              "msFromStart": 185182.52645614027
            },
            {
              "lat": 43.778092,
              "lng": -116.09829416666668,
              "alt": 1975.2849999999999,
              "speed2d": 8.323666666666666,
              "speed3d": 9.926666666666666,
              "msFromStart": 185861.9929824561
            },
            {
              "lat": 43.778103711769546,
              "lng": -116.0982909601743,
              "alt": 1974.6208557250059,
              "speed2d": 8.271695908980844,
              "speed3d": 9.89840426046959,
              "msFromStart": 186000
            },
            {
              "lat": 43.77814828092997,
              "lng": -116.09828828549679,
              "alt": 1971.8623241087485,
              "speed2d": 7.4495953504207,
              "speed3d": 8.909274840717867,
              "msFromStart": 186500
            },
            {
              "lat": 43.778213599999994,
              "lng": -116.09829688,
              "alt": 1970.253,
              "speed2d": 7.473000000000001,
              "speed3d": 9.114,
              "msFromStart": 187271.67199999996
            },
            {
              "lat": 43.77826018,
              "lng": -116.09832392,
              "alt": 1968.2993999999999,
              "speed2d": 7.871999999999999,
              "speed3d": 9.424,
              "msFromStart": 187821.16000000003
            },
            {
              "lat": 43.778275192381955,
              "lng": -116.09833125445934,
              "alt": 1967.769036857151,
              "speed2d": 8.048359031480507,
              "speed3d": 9.532345111252523,
              "msFromStart": 188000
            },
            {
              "lat": 43.77830573865278,
              "lng": -116.09835373570816,
              "alt": 1965.4906681422672,
              "speed2d": 7.654181081250656,
              "speed3d": 9.260150618766438,
              "msFromStart": 188500
            },
            {
              "lat": 43.778344100000005,
              "lng": -116.098378775,
              "alt": 1962.4015,
              "speed2d": 7.112,
              "speed3d": 8.8775,
              "msFromStart": 189129.51400000008
            },
            {
              "lat": 43.77837986666667,
              "lng": -116.09838250000001,
              "alt": 1958.491,
              "speed2d": 6.809666666666667,
              "speed3d": 8.703333333333333,
              "msFromStart": 189676.2151111111
            },
            {
              "lat": 43.77841072,
              "lng": -116.09838124000001,
              "alt": 1954.664,
              "speed2d": 6.5546,
              "speed3d": 8.598,
              "msFromStart": 190168.57279999994
            },
            {
              "lat": 43.7784494,
              "lng": -116.0983696142857,
              "alt": 1948.6674285714284,
              "speed2d": 6.797714285714286,
              "speed3d": 8.892857142857142,
              "msFromStart": 190739.72444444452
            },
            {
              "lat": 43.77848231666667,
              "lng": -116.09835336666667,
              "alt": 1944.4623333333334,
              "speed2d": 7.474333333333333,
              "speed3d": 9.57,
              "msFromStart": 191151.17111111124
            },
            {
              "lat": 43.778529925,
              "lng": -116.0983257,
              "alt": 1939.02225,
              "speed2d": 8.33075,
              "speed3d": 10.355,
              "msFromStart": 191740.14210526325
            },
            {
              "lat": 43.77857696666667,
              "lng": -116.09829889999999,
              "alt": 1935.3942222222222,
              "speed2d": 9.037888888888888,
              "speed3d": 10.993333333333334,
              "msFromStart": 192262.1904405459
            },
            {
              "lat": 43.778624844444444,
              "lng": -116.09828346666669,
              "alt": 1932.6656666666668,
              "speed2d": 9.228888888888887,
              "speed3d": 11.17,
              "msFromStart": 192769.97511111118
            },
            {
              "lat": 43.77867047777778,
              "lng": -116.09828581111111,
              "alt": 1931.336888888889,
              "speed2d": 9.092333333333332,
              "speed3d": 10.981111111111112,
              "msFromStart": 193266.1211851853
            },
            {
              "lat": 43.77871213333333,
              "lng": -116.09829518888887,
              "alt": 1929.346111111111,
              "speed2d": 8.99111111111111,
              "speed3d": 10.83,
              "msFromStart": 193761.40800000005
            },
            {
              "lat": 43.7787508,
              "lng": -116.09830249999999,
              "alt": 1926.2636666666667,
              "speed2d": 8.846,
              "speed3d": 10.71,
              "msFromStart": 194274.84888888893
            },
            {
              "lat": 43.7787848,
              "lng": -116.0983008,
              "alt": 1923.1293333333333,
              "speed2d": 8.833666666666668,
              "speed3d": 10.696666666666665,
              "msFromStart": 194715.1211851851
            },
            {
              "lat": 43.77884425,
              "lng": -116.09828555,
              "alt": 1917.6689999999999,
              "speed2d": 9.057500000000001,
              "speed3d": 10.95,
              "msFromStart": 195412.38652631576
            },
            {
              "lat": 43.77887246666667,
              "lng": -116.0982738,
              "alt": 1915.1253333333334,
              "speed2d": 9.184333333333333,
              "speed3d": 11.056666666666667,
              "msFromStart": 195724.51368421045
            },
            {
              "lat": 43.778895046698906,
              "lng": -116.09826368787141,
              "alt": 1913.048364308223,
              "speed2d": 9.209932001845655,
              "speed3d": 11.076654296801136,
              "msFromStart": 196000
            },
            {
              "lat": 43.77892854722021,
              "lng": -116.09824493446146,
              "alt": 1909.708635155568,
              "speed2d": 8.995361531558046,
              "speed3d": 10.929183183201406,
              "msFromStart": 196500
            },
            {
              "lat": 43.77896204774152,
              "lng": -116.09822618105152,
              "alt": 1906.3689060029126,
              "speed2d": 8.780791061270438,
              "speed3d": 10.781712069601674,
              "msFromStart": 197000
            },
            {
              "lat": 43.778993697252375,
              "lng": -116.09819301731116,
              "alt": 1902.6087373360472,
              "speed2d": 8.552504522840355,
              "speed3d": 10.660773405698787,
              "msFromStart": 197500
            },
            {
              "lat": 43.77905483999999,
              "lng": -116.0981945,
              "alt": 1897.6628,
              "speed2d": 8.223600000000001,
              "speed3d": 10.376,
              "msFromStart": 198349.59306666674
            },
            {
              "lat": 43.779095225,
              "lng": -116.09820102500001,
              "alt": 1893.9830000000002,
              "speed2d": 8.1005,
              "speed3d": 10.264999999999999,
              "msFromStart": 198905.5160000001
            },
            {
              "lat": 43.779121,
              "lng": -116.09819891428572,
              "alt": 1891.2482857142857,
              "speed2d": 8.162999999999998,
              "speed3d": 10.335714285714285,
              "msFromStart": 199247.57295238107
            },
            {
              "lat": 43.77916353333333,
              "lng": -116.09818763333334,
              "alt": 1885.9520000000002,
              "speed2d": 8.322,
              "speed3d": 10.54,
              "msFromStart": 199813.8160000001
            },
            {
              "lat": 43.77919343333333,
              "lng": -116.09817956666666,
              "alt": 1882.686333333333,
              "speed2d": 8.422333333333333,
              "speed3d": 10.656666666666666,
              "msFromStart": 200199.09904093578
            },
            {
              "lat": 43.779230575,
              "lng": -116.09817305,
              "alt": 1879.0185000000001,
              "speed2d": 8.47625,
              "speed3d": 10.745000000000001,
              "msFromStart": 200679.99178947363
            },
            {
              "lat": 43.7792643375,
              "lng": -116.09816071249999,
              "alt": 1873.8965,
              "speed2d": 8.283249999999999,
              "speed3d": 10.665000000000001,
              "msFromStart": 201263.5358771929
            },
            {
              "lat": 43.77927972222222,
              "lng": -116.09814303333333,
              "alt": 1869.4035555555556,
              "speed2d": 7.936444444444445,
              "speed3d": 10.473333333333333,
              "msFromStart": 201737.93511111112
            },
            {
              "lat": 43.779286799999994,
              "lng": -116.09812383333335,
              "alt": 1865.6736666666668,
              "speed2d": 7.428222222222222,
              "speed3d": 10.114444444444445,
              "msFromStart": 202233.13807407406
            },
            {
              "lat": 43.779296433333336,
              "lng": -116.09810882222222,
              "alt": 1862.9644444444443,
              "speed2d": 6.941333333333333,
              "speed3d": 9.731111111111112,
              "msFromStart": 202727.33333333334
            },
            {
              "lat": 43.7793055,
              "lng": -116.09809535,
              "alt": 1859.7825,
              "speed2d": 6.621,
              "speed3d": 9.48,
              "msFromStart": 203166.28800000003
            },
            {
              "lat": 43.7793126,
              "lng": -116.09805845,
              "alt": 1852.862,
              "speed2d": 6.441,
              "speed3d": 9.39,
              "msFromStart": 203854.66266666667
            },
            {
              "lat": 43.77932028,
              "lng": -116.0980203,
              "alt": 1846.7766,
              "speed2d": 6.6716,
              "speed3d": 9.668,
              "msFromStart": 204378.2992
            },
            {
              "lat": 43.77933108571429,
              "lng": -116.09799871428571,
              "alt": 1843.4085714285713,
              "speed2d": 6.836285714285714,
              "speed3d": 9.815714285714286,
              "msFromStart": 204708.47022222218
            },
            {
              "lat": 43.77936434,
              "lng": -116.09796004000002,
              "alt": 1839.0040000000001,
              "speed2d": 7.309799999999999,
              "speed3d": 10.174,
              "msFromStart": 205269.6823017543
            },
            {
              "lat": 43.77937779380533,
              "lng": -116.09794387283115,
              "alt": 1837.4694457048697,
              "speed2d": 7.452858393639386,
              "speed3d": 10.26752968996901,
              "msFromStart": 205500
            },
            {
              "lat": 43.77940261744919,
              "lng": -116.09790836175588,
              "alt": 1834.9859443578393,
              "speed2d": 7.536993491921204,
              "speed3d": 10.31679799076467,
              "msFromStart": 206000
            },
            {
              "lat": 43.77944183333334,
              "lng": -116.0978517,
              "alt": 1830.9603333333334,
              "speed2d": 7.658333333333333,
              "speed3d": 10.39,
              "msFromStart": 206797.98577777774
            },
            {
              "lat": 43.779462471428566,
              "lng": -116.09782111428572,
              "alt": 1827.8918571428571,
              "speed2d": 7.499,
              "speed3d": 10.297142857142855,
              "msFromStart": 207293.79453968254
            },
            {
              "lat": 43.77949028333333,
              "lng": -116.09780866666667,
              "alt": 1825.6646666666666,
              "speed2d": 7.314333333333334,
              "speed3d": 10.126666666666667,
              "msFromStart": 207706.44533333334
            },
            {
              "lat": 43.77954734000001,
              "lng": -116.09779682,
              "alt": 1823.3352,
              "speed2d": 7.5314,
              "speed3d": 10.208000000000002,
              "msFromStart": 208250.9546666667
            },
            {
              "lat": 43.779623400000006,
              "lng": -116.09777384,
              "alt": 1820.5953999999997,
              "speed2d": 8.0742,
              "speed3d": 10.57,
              "msFromStart": 208888.71822222226
            },
            {
              "lat": 43.779656775,
              "lng": -116.09775705000001,
              "alt": 1819.4045,
              "speed2d": 8.30725,
              "speed3d": 10.732499999999998,
              "msFromStart": 209191.0764444445
            },
            {
              "lat": 43.779722500000005,
              "lng": -116.09772586666666,
              "alt": 1817.1046666666666,
              "speed2d": 8.724166666666667,
              "speed3d": 11.02,
              "msFromStart": 209748.99288888884
            },
            {
              "lat": 43.77978981111111,
              "lng": -116.09769063333333,
              "alt": 1815.5884444444443,
              "speed2d": 9.278777777777778,
              "speed3d": 11.411111111111111,
              "msFromStart": 210261.05511111108
            },
            {
              "lat": 43.77984420000001,
              "lng": -116.09766172222221,
              "alt": 1814.692777777778,
              "speed2d": 9.291777777777778,
              "speed3d": 11.425555555555555,
              "msFromStart": 210756.55999999994
            },
            {
              "lat": 43.77988447777778,
              "lng": -116.09764184444444,
              "alt": 1812.9554444444445,
              "speed2d": 8.96411111111111,
              "speed3d": 11.142222222222225,
              "msFromStart": 211252.4583703703
            },
            {
              "lat": 43.77988931666667,
              "lng": -116.09763846666667,
              "alt": 1807.971,
              "speed2d": 8.131166666666665,
              "speed3d": 10.56,
              "msFromStart": 211747.6417777777
            },
            {
              "lat": 43.7798945375,
              "lng": -116.09763985,
              "alt": 1803.562,
              "speed2d": 7.378625,
              "speed3d": 10.01,
              "msFromStart": 212221.90166666653
            },
            {
              "lat": 43.779904099999996,
              "lng": -116.09764279999999,
              "alt": 1800.5293333333334,
              "speed2d": 6.830333333333334,
              "speed3d": 9.573333333333334,
              "msFromStart": 212626.73066666664
            },
            {
              "lat": 43.779947449999995,
              "lng": -116.09763751666667,
              "alt": 1798.286,
              "speed2d": 6.608333333333333,
              "speed3d": 9.375,
              "msFromStart": 213211.69822222218
            },
            {
              "lat": 43.77996784,
              "lng": -116.09762952,
              "alt": 1795.9024,
              "speed2d": 6.5398,
              "speed3d": 9.314,
              "msFromStart": 213614.88266666676
            },
            {
              "lat": 43.779991349999996,
              "lng": -116.097614425,
              "alt": 1793.1295,
              "speed2d": 6.504,
              "speed3d": 9.274999999999999,
              "msFromStart": 214096.5993333335
            },
            {
              "lat": 43.780050833333334,
              "lng": -116.09756206666668,
              "alt": 1787.51,
              "speed2d": 7.188666666666666,
              "speed3d": 9.726666666666667,
              "msFromStart": 214936.6417777778
            },
            {
              "lat": 43.780080033333334,
              "lng": -116.09753839999999,
              "alt": 1785.1470000000002,
              "speed2d": 7.545333333333334,
              "speed3d": 10.003333333333334,
              "msFromStart": 215285.55811306043
            },
            {
              "lat": 43.780142,
              "lng": -116.09750435,
              "alt": 1782.6035,
              "speed2d": 8.216999999999999,
              "speed3d": 10.481666666666667,
              "msFromStart": 215799.42821052618
            },
            {
              "lat": 43.78021814444445,
              "lng": -116.09747923333333,
              "alt": 1781.2124444444446,
              "speed2d": 8.934777777777779,
              "speed3d": 11.02888888888889,
              "msFromStart": 216258.16230799208
            },
            {
              "lat": 43.78029935555555,
              "lng": -116.09745283333334,
              "alt": 1779.9716666666666,
              "speed2d": 9.537777777777778,
              "speed3d": 11.498888888888889,
              "msFromStart": 216753.14488888896
            },
            {
              "lat": 43.78037492222222,
              "lng": -116.09743232222223,
              "alt": 1778.5333333333333,
              "speed2d": 9.892999999999997,
              "speed3d": 11.79111111111111,
              "msFromStart": 217247.96029629634
            },
            {
              "lat": 43.780444844444446,
              "lng": -116.09742783333334,
              "alt": 1777.3212222222223,
              "speed2d": 9.874888888888888,
              "speed3d": 11.78222222222222,
              "msFromStart": 217742.7644444444
            },
            {
              "lat": 43.78047786248254,
              "lng": -116.09743346362225,
              "alt": 1776.6525415884116,
              "speed2d": 9.719221458547654,
              "speed3d": 11.66263940665658,
              "msFromStart": 218000
            },
            {
              "lat": 43.78053764210694,
              "lng": -116.09744235353682,
              "alt": 1774.208320190028,
              "speed2d": 9.802564407655586,
              "speed3d": 11.697997021429643,
              "msFromStart": 218500
            },
            {
              "lat": 43.78059742173135,
              "lng": -116.09745124345139,
              "alt": 1771.7640987916445,
              "speed2d": 9.885907356763518,
              "speed3d": 11.733354636202705,
              "msFromStart": 219000
            },
            {
              "lat": 43.78065841028219,
              "lng": -116.09749156836875,
              "alt": 1769.6941706047028,
              "speed2d": 9.725184118481966,
              "speed3d": 11.532177627934244,
              "msFromStart": 219500
            },
            {
              "lat": 43.7807708,
              "lng": -116.0974408,
              "alt": 1764.183,
              "speed2d": 10.399,
              "speed3d": 12.120000000000001,
              "msFromStart": 220465.6412631579
            },
            {
              "lat": 43.78080128888889,
              "lng": -116.09742621111111,
              "alt": 1762.9365555555555,
              "speed2d": 10.432666666666666,
              "speed3d": 12.157777777777776,
              "msFromStart": 220769.12084210527
            },
            {
              "lat": 43.780824254943546,
              "lng": -116.09741761836776,
              "alt": 1762.1385256547114,
              "speed2d": 10.423191907534893,
              "speed3d": 12.135704651827252,
              "msFromStart": 221000
            },
            {
              "lat": 43.7809032,
              "lng": -116.0973790857143,
              "alt": 1758.7928571428572,
              "speed2d": 10.360857142857144,
              "speed3d": 12.057142857142857,
              "msFromStart": 221814.38755555562
            },
            {
              "lat": 43.780948249999994,
              "lng": -116.09735620000001,
              "alt": 1756.871,
              "speed2d": 10.3355,
              "speed3d": 12.015,
              "msFromStart": 222280.16088888893
            },
            {
              "lat": 43.78099653333334,
              "lng": -116.09733103333335,
              "alt": 1755.170333333333,
              "speed2d": 10.347333333333333,
              "speed3d": 12.003333333333332,
              "msFromStart": 222765.10340740735
            },
            {
              "lat": 43.78104544999999,
              "lng": -116.0973037,
              "alt": 1753.4423333333334,
              "speed2d": 10.319833333333333,
              "speed3d": 11.978333333333332,
              "msFromStart": 223268.68681481478
            },
            {
              "lat": 43.781086083333335,
              "lng": -116.09728368333334,
              "alt": 1752.0206666666663,
              "speed2d": 10.102,
              "speed3d": 11.76,
              "msFromStart": 223728.21570370373
            },
            {
              "lat": 43.781111165297304,
              "lng": -116.09727292277738,
              "alt": 1751.0983629432071,
              "speed2d": 10.012899437555811,
              "speed3d": 11.671481174787894,
              "msFromStart": 224000
            }
          ]
        }
      ]
    },
    {
      "id": "trail-majestic-cat-track",
      "pathType": "trail",
      "name": "Majestic Cat Track",
      "difficulty": "black",
      "isCatTrack": true,
      "videos": [
        {
          "videoId": "GX010085",
          "pathId": "trail-majestic-cat-track",
          "startSec": 60,
          "endSec": 140,
          "id": "video-segment-51",
          "gps": [
            {
              "lat": 43.77251312222222,
              "lng": -116.09063984444444,
              "alt": 2268.3575555555553,
              "speed2d": 2.048,
              "speed3d": 2.0533333333333332,
              "msFromStart": 60238.75585185185
            },
            {
              "lat": 43.77250901111111,
              "lng": -116.0906303,
              "alt": 2267.821888888889,
              "speed2d": 2.0468888888888888,
              "speed3d": 2.0766666666666667,
              "msFromStart": 60732.86666666665
            },
            {
              "lat": 43.77250686666666,
              "lng": -116.09062074444444,
              "alt": 2267.3995555555553,
              "speed2d": 1.9897777777777774,
              "speed3d": 1.9977777777777779,
              "msFromStart": 61227.26474853799
            },
            {
              "lat": 43.772505030000005,
              "lng": -116.09060493999999,
              "alt": 2267.4682000000003,
              "speed2d": 2.1556,
              "speed3d": 2.123,
              "msFromStart": 61749.83431578945
            },
            {
              "lat": 43.77250138888889,
              "lng": -116.0905849,
              "alt": 2267.9309999999996,
              "speed2d": 2.544,
              "speed3d": 2.551111111111111,
              "msFromStart": 62272.41719688108
            },
            {
              "lat": 43.77249668888889,
              "lng": -116.09057465555557,
              "alt": 2267.622222222222,
              "speed2d": 2.1833333333333336,
              "speed3d": 2.256666666666667,
              "msFromStart": 62767.280444444455
            },
            {
              "lat": 43.77249417777777,
              "lng": -116.09056741111112,
              "alt": 2267.380222222222,
              "speed2d": 1.8362222222222222,
              "speed3d": 1.871111111111111,
              "msFromStart": 63262.3817037037
            },
            {
              "lat": 43.772492288888884,
              "lng": -116.09056023333335,
              "alt": 2267.222111111111,
              "speed2d": 1.508888888888889,
              "speed3d": 1.5366666666666666,
              "msFromStart": 63758.51999999998
            },
            {
              "lat": 43.7724893,
              "lng": -116.09055465555555,
              "alt": 2266.8311111111116,
              "speed2d": 1.383,
              "speed3d": 1.4088888888888889,
              "msFromStart": 64254.39014814815
            },
            {
              "lat": 43.772487322222226,
              "lng": -116.09054984444445,
              "alt": 2266.6362222222224,
              "speed2d": 1.222888888888889,
              "speed3d": 1.2622222222222221,
              "msFromStart": 64748.77288888889
            },
            {
              "lat": 43.772485644444444,
              "lng": -116.09054335555555,
              "alt": 2266.422222222222,
              "speed2d": 0.9497777777777778,
              "speed3d": 0.9955555555555556,
              "msFromStart": 65243.08651851851
            },
            {
              "lat": 43.77248193333333,
              "lng": -116.09053797777777,
              "alt": 2265.920888888889,
              "speed2d": 1.0434444444444444,
              "speed3d": 1.037777777777778,
              "msFromStart": 65737.71866666668
            },
            {
              "lat": 43.77247922222222,
              "lng": -116.09052708888889,
              "alt": 2266.1313333333337,
              "speed2d": 1.0515555555555556,
              "speed3d": 1.097777777777778,
              "msFromStart": 66232.5694502924
            },
            {
              "lat": 43.772474522222225,
              "lng": -116.09051752222223,
              "alt": 2266.1673333333338,
              "speed2d": 1.1668888888888889,
              "speed3d": 1.1966666666666668,
              "msFromStart": 66728.167368421
            },
            {
              "lat": 43.77247164,
              "lng": -116.09050648,
              "alt": 2266.0996,
              "speed2d": 0.9672000000000001,
              "speed3d": 1.006,
              "msFromStart": 67251.19192982452
            },
            {
              "lat": 43.772466888888886,
              "lng": -116.09049104444443,
              "alt": 2266.280888888889,
              "speed2d": 1.3827777777777777,
              "speed3d": 1.3977777777777778,
              "msFromStart": 67773.35111111107
            },
            {
              "lat": 43.77246436666666,
              "lng": -116.09047588888889,
              "alt": 2267.0977777777775,
              "speed2d": 1.149,
              "speed3d": 1.2066666666666668,
              "msFromStart": 68267.90444444442
            },
            {
              "lat": 43.77246104444445,
              "lng": -116.09046517777779,
              "alt": 2267.3958888888887,
              "speed2d": 0.8837777777777778,
              "speed3d": 0.8966666666666667,
              "msFromStart": 68762.43111111106
            },
            {
              "lat": 43.77245776666667,
              "lng": -116.09045003333333,
              "alt": 2267.878333333333,
              "speed2d": 1.0781111111111112,
              "speed3d": 1.1122222222222222,
              "msFromStart": 69256.99881481477
            },
            {
              "lat": 43.7724551,
              "lng": -116.09043274444446,
              "alt": 2268.2685555555554,
              "speed2d": 1.479777777777778,
              "speed3d": 1.4477777777777778,
              "msFromStart": 69751.74133333328
            },
            {
              "lat": 43.77245217777778,
              "lng": -116.09041426666667,
              "alt": 2268.6944444444443,
              "speed2d": 1.784,
              "speed3d": 1.7733333333333332,
              "msFromStart": 70246.63392592591
            },
            {
              "lat": 43.77245005555555,
              "lng": -116.09039982222222,
              "alt": 2268.707777777778,
              "speed2d": 1.6911111111111112,
              "speed3d": 1.731111111111111,
              "msFromStart": 70742.05688888897
            },
            {
              "lat": 43.77244682222222,
              "lng": -116.09038923333335,
              "alt": 2268.6608888888886,
              "speed2d": 1.4806666666666668,
              "speed3d": 1.511111111111111,
              "msFromStart": 71237.39070565306
            },
            {
              "lat": 43.77244367777778,
              "lng": -116.09037304444445,
              "alt": 2268.8821111111115,
              "speed2d": 1.6032222222222225,
              "speed3d": 1.5877777777777777,
              "msFromStart": 71732.15578947369
            },
            {
              "lat": 43.772441155555555,
              "lng": -116.09034810000003,
              "alt": 2269.380222222222,
              "speed2d": 2.2023333333333333,
              "speed3d": 2.121111111111111,
              "msFromStart": 72227.09607277454
            },
            {
              "lat": 43.77244045,
              "lng": -116.09031679,
              "alt": 2269.8982,
              "speed2d": 2.822,
              "speed3d": 2.794,
              "msFromStart": 72751.04022222219
            },
            {
              "lat": 43.77244124444445,
              "lng": -116.09028648888889,
              "alt": 2270.4886666666666,
              "speed2d": 3.1466666666666665,
              "speed3d": 3.1277777777777778,
              "msFromStart": 73274.87199999997
            },
            {
              "lat": 43.77244187777778,
              "lng": -116.09026268888888,
              "alt": 2270.3825555555554,
              "speed2d": 3.2687777777777773,
              "speed3d": 3.267777777777778,
              "msFromStart": 73769.73777777776
            },
            {
              "lat": 43.77244474444444,
              "lng": -116.09023737777778,
              "alt": 2270.496222222222,
              "speed2d": 3.5412222222222223,
              "speed3d": 3.4877777777777776,
              "msFromStart": 74264.42874074072
            },
            {
              "lat": 43.77244918888889,
              "lng": -116.09020346666667,
              "alt": 2270.916222222222,
              "speed2d": 4.120333333333333,
              "speed3d": 4.086666666666667,
              "msFromStart": 74758.97155555554
            },
            {
              "lat": 43.772453911111114,
              "lng": -116.09016717777777,
              "alt": 2271.166444444444,
              "speed2d": 4.451777777777778,
              "speed3d": 4.41,
              "msFromStart": 75253.6914074074
            },
            {
              "lat": 43.77245985555556,
              "lng": -116.09013065555555,
              "alt": 2271.458666666667,
              "speed2d": 4.821222222222222,
              "speed3d": 4.793333333333333,
              "msFromStart": 75749.17866666664
            },
            {
              "lat": 43.772467133333336,
              "lng": -116.09009127777777,
              "alt": 2272.0069999999996,
              "speed2d": 5.019555555555556,
              "speed3d": 4.998888888888889,
              "msFromStart": 76244.70637037035
            },
            {
              "lat": 43.77247620000001,
              "lng": -116.09004886666666,
              "alt": 2272.7174444444445,
              "speed2d": 5.273,
              "speed3d": 5.2588888888888885,
              "msFromStart": 76740.08888888892
            },
            {
              "lat": 43.7724852,
              "lng": -116.09000686666667,
              "alt": 2273.1993333333335,
              "speed2d": 5.4815555555555555,
              "speed3d": 5.474444444444445,
              "msFromStart": 77235.27505653024
            },
            {
              "lat": 43.77249294444444,
              "lng": -116.08997526666667,
              "alt": 2272.707,
              "speed2d": 5.532222222222222,
              "speed3d": 5.533333333333334,
              "msFromStart": 77729.73389473688
            },
            {
              "lat": 43.77250201000001,
              "lng": -116.08994493000002,
              "alt": 2271.5135,
              "speed2d": 5.6842,
              "speed3d": 5.692,
              "msFromStart": 78251.81154385967
            },
            {
              "lat": 43.77251184444444,
              "lng": -116.08991625555555,
              "alt": 2269.8998888888887,
              "speed2d": 5.738888888888888,
              "speed3d": 5.764444444444445,
              "msFromStart": 78774.94222222219
            },
            {
              "lat": 43.772521222222224,
              "lng": -116.08989118888888,
              "alt": 2268.3255555555556,
              "speed2d": 5.624444444444444,
              "speed3d": 5.703333333333333,
              "msFromStart": 79270.35555555556
            },
            {
              "lat": 43.77253005555556,
              "lng": -116.08986620000002,
              "alt": 2266.3613333333333,
              "speed2d": 5.492000000000001,
              "speed3d": 5.598888888888888,
              "msFromStart": 79764.46222222225
            },
            {
              "lat": 43.7725379,
              "lng": -116.08983875555556,
              "alt": 2264.627444444445,
              "speed2d": 5.320333333333334,
              "speed3d": 5.496666666666666,
              "msFromStart": 80258.79081481481
            },
            {
              "lat": 43.77254505555556,
              "lng": -116.08981066666668,
              "alt": 2263.1496666666662,
              "speed2d": 5.036555555555555,
              "speed3d": 5.214444444444443,
              "msFromStart": 80754.52977777773
            },
            {
              "lat": 43.77255341111112,
              "lng": -116.08977143333334,
              "alt": 2263.182555555555,
              "speed2d": 5.117444444444445,
              "speed3d": 5.322222222222222,
              "msFromStart": 81250.29496296296
            },
            {
              "lat": 43.77256061111111,
              "lng": -116.08974045555556,
              "alt": 2262.8170000000005,
              "speed2d": 4.5521111111111106,
              "speed3d": 4.8100000000000005,
              "msFromStart": 81745.6008888889
            },
            {
              "lat": 43.7725637,
              "lng": -116.08971837777779,
              "alt": 2261.387222222222,
              "speed2d": 4.035222222222222,
              "speed3d": 4.334444444444444,
              "msFromStart": 82240.989582846
            },
            {
              "lat": 43.77256652222222,
              "lng": -116.08969983333333,
              "alt": 2259.446222222222,
              "speed2d": 3.609777777777778,
              "speed3d": 4.035555555555556,
              "msFromStart": 82736.89305263155
            },
            {
              "lat": 43.77256686666667,
              "lng": -116.08968574444444,
              "alt": 2257.103888888889,
              "speed2d": 3.146222222222222,
              "speed3d": 3.6688888888888886,
              "msFromStart": 83232.59282131252
            },
            {
              "lat": 43.772568722222225,
              "lng": -116.08966914444444,
              "alt": 2255.400555555556,
              "speed2d": 3.0313333333333334,
              "speed3d": 3.6088888888888886,
              "msFromStart": 83726.73599999995
            },
            {
              "lat": 43.77257388000001,
              "lng": -116.08964187,
              "alt": 2255.7960999999996,
              "speed2d": 3.0881,
              "speed3d": 3.5689999999999995,
              "msFromStart": 84248.1182222222
            },
            {
              "lat": 43.77258042222222,
              "lng": -116.08961423333331,
              "alt": 2256.3587777777775,
              "speed2d": 2.9787777777777777,
              "speed3d": 3.431111111111111,
              "msFromStart": 84769.7617777778
            },
            {
              "lat": 43.772587,
              "lng": -116.08958658888888,
              "alt": 2257.1871111111113,
              "speed2d": 2.9865555555555563,
              "speed3d": 3.3200000000000003,
              "msFromStart": 85264.34503703704
            },
            {
              "lat": 43.772594544444445,
              "lng": -116.08955893333334,
              "alt": 2257.7632222222223,
              "speed2d": 3.117,
              "speed3d": 3.4055555555555554,
              "msFromStart": 85760.3653333333
            },
            {
              "lat": 43.772605600000006,
              "lng": -116.08953173333333,
              "alt": 2258.6756666666665,
              "speed2d": 3.3551111111111114,
              "speed3d": 3.532222222222222,
              "msFromStart": 86256.20044444442
            },
            {
              "lat": 43.77261788888888,
              "lng": -116.08950364444445,
              "alt": 2259.5830000000005,
              "speed2d": 3.6686666666666663,
              "speed3d": 3.808888888888889,
              "msFromStart": 86750.71999999999
            },
            {
              "lat": 43.77262947777777,
              "lng": -116.08948123333334,
              "alt": 2259.710666666667,
              "speed2d": 3.640888888888889,
              "speed3d": 3.7488888888888887,
              "msFromStart": 87245.1125614035
            },
            {
              "lat": 43.772639588888886,
              "lng": -116.08945584444444,
              "alt": 2259.3511111111106,
              "speed2d": 4.141222222222223,
              "speed3d": 4.199999999999999,
              "msFromStart": 87739.52336842101
            },
            {
              "lat": 43.7726501,
              "lng": -116.0894275,
              "alt": 2259.185,
              "speed2d": 4.522,
              "speed3d": 4.6033333333333335,
              "msFromStart": 88234.09888499024
            },
            {
              "lat": 43.772658500000006,
              "lng": -116.08939912222222,
              "alt": 2258.8856666666666,
              "speed2d": 4.555,
              "speed3d": 4.681111111111111,
              "msFromStart": 88729.668
            },
            {
              "lat": 43.77266432222222,
              "lng": -116.08937412222222,
              "alt": 2258.032,
              "speed2d": 4.193444444444444,
              "speed3d": 4.362222222222222,
              "msFromStart": 89225.3313580247
            },
            {
              "lat": 43.77267019,
              "lng": -116.08934742,
              "alt": 2257.3123,
              "speed2d": 4.028900000000001,
              "speed3d": 4.188,
              "msFromStart": 89748.08577777776
            },
            {
              "lat": 43.772679366666665,
              "lng": -116.08931766666666,
              "alt": 2257.2375555555554,
              "speed2d": 4.1212222222222215,
              "speed3d": 4.237777777777778,
              "msFromStart": 90270.5145185185
            },
            {
              "lat": 43.77268960000001,
              "lng": -116.08928613333333,
              "alt": 2257.1892222222223,
              "speed2d": 4.432888888888889,
              "speed3d": 4.542222222222222,
              "msFromStart": 90764.40533333336
            },
            {
              "lat": 43.77269932222222,
              "lng": -116.0892511,
              "alt": 2257.143,
              "speed2d": 4.857777777777778,
              "speed3d": 4.904444444444445,
              "msFromStart": 91258.60023391811
            },
            {
              "lat": 43.772708888888886,
              "lng": -116.08921225555555,
              "alt": 2256.7575555555554,
              "speed2d": 5.440777777777779,
              "speed3d": 5.488888888888889,
              "msFromStart": 91754.42273684204
            },
            {
              "lat": 43.77271786666666,
              "lng": -116.08916776666666,
              "alt": 2256.8417777777777,
              "speed2d": 6.115111111111112,
              "speed3d": 6.168888888888889,
              "msFromStart": 92250.27599220269
            },
            {
              "lat": 43.77272591111111,
              "lng": -116.08912608888888,
              "alt": 2257.159,
              "speed2d": 6.201888888888889,
              "speed3d": 6.299999999999999,
              "msFromStart": 92745.324
            },
            {
              "lat": 43.772733555555554,
              "lng": -116.08908896666668,
              "alt": 2257.175888888889,
              "speed2d": 5.841777777777778,
              "speed3d": 5.97,
              "msFromStart": 93240.30567901234
            },
            {
              "lat": 43.77274032222222,
              "lng": -116.08905325555557,
              "alt": 2257.284777777778,
              "speed2d": 5.441333333333333,
              "speed3d": 5.57,
              "msFromStart": 93735.68800000001
            },
            {
              "lat": 43.772746544444445,
              "lng": -116.08902003333333,
              "alt": 2257.1477777777777,
              "speed2d": 5.143888888888888,
              "speed3d": 5.25,
              "msFromStart": 94231.05303703704
            },
            {
              "lat": 43.77275316666666,
              "lng": -116.08898411111112,
              "alt": 2257.115333333333,
              "speed2d": 5.245222222222222,
              "speed3d": 5.313333333333333,
              "msFromStart": 94725.91200000001
            },
            {
              "lat": 43.77275985000001,
              "lng": -116.08894482999999,
              "alt": 2256.9612,
              "speed2d": 5.4002,
              "speed3d": 5.462999999999999,
              "msFromStart": 95248.22288888888
            },
            {
              "lat": 43.77276797777778,
              "lng": -116.08890662222223,
              "alt": 2256.8137777777774,
              "speed2d": 5.4477777777777785,
              "speed3d": 5.526666666666666,
              "msFromStart": 95770.75466666669
            },
            {
              "lat": 43.77277643333333,
              "lng": -116.08886953333332,
              "alt": 2256.871222222222,
              "speed2d": 5.577222222222223,
              "speed3d": 5.648888888888889,
              "msFromStart": 96265.98281481484
            },
            {
              "lat": 43.772785811111106,
              "lng": -116.08883132222223,
              "alt": 2257.196,
              "speed2d": 5.695444444444445,
              "speed3d": 5.753333333333332,
              "msFromStart": 96761.88355555559
            },
            {
              "lat": 43.772794299999994,
              "lng": -116.08879426666665,
              "alt": 2257.3163333333337,
              "speed2d": 5.586555555555556,
              "speed3d": 5.653333333333332,
              "msFromStart": 97257.39229629631
            },
            {
              "lat": 43.77280301111111,
              "lng": -116.08875724444445,
              "alt": 2257.329222222222,
              "speed2d": 5.705555555555556,
              "speed3d": 5.765555555555555,
              "msFromStart": 97751.06399999995
            },
            {
              "lat": 43.772810744444435,
              "lng": -116.08872022222222,
              "alt": 2257.4327777777776,
              "speed2d": 5.709,
              "speed3d": 5.7555555555555555,
              "msFromStart": 98244.74459259256
            },
            {
              "lat": 43.77281816666667,
              "lng": -116.08868453333332,
              "alt": 2257.7635555555557,
              "speed2d": 5.4815555555555555,
              "speed3d": 5.565555555555556,
              "msFromStart": 98739.6
            },
            {
              "lat": 43.77282364444444,
              "lng": -116.08865137777778,
              "alt": 2258.051,
              "speed2d": 5.168111111111111,
              "speed3d": 5.222222222222221,
              "msFromStart": 99234.93738271606
            },
            {
              "lat": 43.77283093333334,
              "lng": -116.08861451111112,
              "alt": 2258.5737777777777,
              "speed2d": 5.446555555555556,
              "speed3d": 5.437777777777778,
              "msFromStart": 99732.04000000001
            },
            {
              "lat": 43.77283832222223,
              "lng": -116.08857634444443,
              "alt": 2259.0216666666665,
              "speed2d": 5.659222222222223,
              "speed3d": 5.673333333333333,
              "msFromStart": 100229.07595061729
            },
            {
              "lat": 43.77284598000001,
              "lng": -116.08853995,
              "alt": 2259.2920000000004,
              "speed2d": 5.5017000000000005,
              "speed3d": 5.534,
              "msFromStart": 100751.4126666667
            },
            {
              "lat": 43.77285337777778,
              "lng": -116.08850478888888,
              "alt": 2259.448888888889,
              "speed2d": 5.288333333333333,
              "speed3d": 5.336666666666667,
              "msFromStart": 101273.10592592596
            },
            {
              "lat": 43.772856822222224,
              "lng": -116.08847714444444,
              "alt": 2258.5389999999998,
              "speed2d": 5.144111111111111,
              "speed3d": 5.175555555555556,
              "msFromStart": 101766.17688888898
            },
            {
              "lat": 43.77286196666667,
              "lng": -116.08844712222222,
              "alt": 2258.172444444444,
              "speed2d": 5.045666666666666,
              "speed3d": 5.057777777777778,
              "msFromStart": 102259.85947758291
            },
            {
              "lat": 43.77287033333334,
              "lng": -116.0884128111111,
              "alt": 2257.8574444444444,
              "speed2d": 5.380888888888888,
              "speed3d": 5.38,
              "msFromStart": 102756.44842105263
            },
            {
              "lat": 43.77287715555555,
              "lng": -116.08837846666668,
              "alt": 2257.6322222222225,
              "speed2d": 5.5518888888888895,
              "speed3d": 5.554444444444444,
              "msFromStart": 103253.19373099414
            },
            {
              "lat": 43.7728846,
              "lng": -116.08834044444444,
              "alt": 2257.4956666666667,
              "speed2d": 6.026555555555555,
              "speed3d": 5.996666666666667,
              "msFromStart": 103749.12000000002
            },
            {
              "lat": 43.772898966666666,
              "lng": -116.08829036666667,
              "alt": 2258.282888888889,
              "speed2d": 7.215333333333334,
              "speed3d": 7.094444444444444,
              "msFromStart": 104244.45817283953
            },
            {
              "lat": 43.77290430000001,
              "lng": -116.08825421111112,
              "alt": 2257.8335555555554,
              "speed2d": 6.344777777777777,
              "speed3d": 6.595555555555555,
              "msFromStart": 104737.02800000002
            },
            {
              "lat": 43.772906022222216,
              "lng": -116.08823023333333,
              "alt": 2256.041888888889,
              "speed2d": 5.276111111111111,
              "speed3d": 5.337777777777777,
              "msFromStart": 105229.59832098767
            },
            {
              "lat": 43.77291345555555,
              "lng": -116.08819612222223,
              "alt": 2255.000666666667,
              "speed2d": 5.840333333333334,
              "speed3d": 5.8100000000000005,
              "msFromStart": 105724.93999999994
            },
            {
              "lat": 43.77292603,
              "lng": -116.08814702,
              "alt": 2255.6668999999997,
              "speed2d": 6.7942,
              "speed3d": 6.770999999999999,
              "msFromStart": 106248.07288888886
            },
            {
              "lat": 43.7729359,
              "lng": -116.08810506666666,
              "alt": 2255.127333333334,
              "speed2d": 6.798555555555557,
              "speed3d": 6.876666666666667,
              "msFromStart": 106770.23822222222
            },
            {
              "lat": 43.772943644444446,
              "lng": -116.08806587777777,
              "alt": 2254.753222222222,
              "speed2d": 6.711777777777778,
              "speed3d": 6.750000000000001,
              "msFromStart": 107264.81634307993
            },
            {
              "lat": 43.77295187777778,
              "lng": -116.08802589999999,
              "alt": 2254.334444444444,
              "speed2d": 6.731222222222222,
              "speed3d": 6.763333333333332,
              "msFromStart": 107759.49894736844
            },
            {
              "lat": 43.77296172222222,
              "lng": -116.0879867111111,
              "alt": 2253.874777777778,
              "speed2d": 6.704111111111111,
              "speed3d": 6.772222222222222,
              "msFromStart": 108254.34545029243
            },
            {
              "lat": 43.7729708,
              "lng": -116.08794725555558,
              "alt": 2253.4174444444448,
              "speed2d": 6.687666666666666,
              "speed3d": 6.732222222222222,
              "msFromStart": 108750.12800000006
            },
            {
              "lat": 43.77297727777778,
              "lng": -116.08790805555554,
              "alt": 2253.3985555555555,
              "speed2d": 6.332222222222223,
              "speed3d": 6.437777777777778,
              "msFromStart": 109245.86153086425
            },
            {
              "lat": 43.77298453333333,
              "lng": -116.08787013333333,
              "alt": 2253.7733333333335,
              "speed2d": 5.9672222222222215,
              "speed3d": 6.017777777777777,
              "msFromStart": 109740.36000000003
            },
            {
              "lat": 43.77299413333334,
              "lng": -116.08783223333333,
              "alt": 2254.5004444444444,
              "speed2d": 5.800222222222222,
              "speed3d": 5.837777777777777,
              "msFromStart": 110234.75130864198
            },
            {
              "lat": 43.773002633333334,
              "lng": -116.08779474444444,
              "alt": 2255.0384444444444,
              "speed2d": 5.745111111111112,
              "speed3d": 5.76,
              "msFromStart": 110729.72399999996
            },
            {
              "lat": 43.77301184,
              "lng": -116.08775807,
              "alt": 2254.9951,
              "speed2d": 5.770099999999999,
              "speed3d": 5.77,
              "msFromStart": 111252.21686549704
            },
            {
              "lat": 43.77301781111111,
              "lng": -116.08772001111112,
              "alt": 2254.7948888888886,
              "speed2d": 5.771888888888889,
              "speed3d": 5.775555555555556,
              "msFromStart": 111774.34147368415
            },
            {
              "lat": 43.77302357777778,
              "lng": -116.08768332222223,
              "alt": 2254.527888888889,
              "speed2d": 5.896444444444445,
              "speed3d": 5.914444444444445,
              "msFromStart": 112269.15544119554
            },
            {
              "lat": 43.77303206666666,
              "lng": -116.08764637777779,
              "alt": 2254.7166666666667,
              "speed2d": 5.8037777777777775,
              "speed3d": 5.837777777777777,
              "msFromStart": 112765.30800000005
            },
            {
              "lat": 43.773042144444446,
              "lng": -116.08761626666667,
              "alt": 2254.6295555555557,
              "speed2d": 5.390888888888888,
              "speed3d": 5.445555555555555,
              "msFromStart": 113261.46913580253
            },
            {
              "lat": 43.77305194444445,
              "lng": -116.08758837777776,
              "alt": 2253.973555555556,
              "speed2d": 5.320555555555555,
              "speed3d": 5.325555555555556,
              "msFromStart": 113756.34400000007
            },
            {
              "lat": 43.773061733333336,
              "lng": -116.08755894444444,
              "alt": 2253.324888888889,
              "speed2d": 5.2987777777777785,
              "speed3d": 5.346666666666666,
              "msFromStart": 114250.98627160498
            },
            {
              "lat": 43.77306806666667,
              "lng": -116.08752686666666,
              "alt": 2253.228777777778,
              "speed2d": 5.148777777777777,
              "speed3d": 5.143333333333333,
              "msFromStart": 114745.49599999994
            },
            {
              "lat": 43.77307516666667,
              "lng": -116.08748937777779,
              "alt": 2253.3109999999997,
              "speed2d": 5.539666666666666,
              "speed3d": 5.515555555555555,
              "msFromStart": 115240.04424691355
            },
            {
              "lat": 43.773082333333335,
              "lng": -116.08745231111111,
              "alt": 2252.8867777777778,
              "speed2d": 5.972666666666666,
              "speed3d": 5.944444444444444,
              "msFromStart": 115734.95999999999
            },
            {
              "lat": 43.77309148888889,
              "lng": -116.08741326666666,
              "alt": 2252.878111111111,
              "speed2d": 6.286888888888889,
              "speed3d": 6.274444444444445,
              "msFromStart": 116230.016
            },
            {
              "lat": 43.773098977777785,
              "lng": -116.0873766,
              "alt": 2252.929333333333,
              "speed2d": 6.177222222222222,
              "speed3d": 6.2333333333333325,
              "msFromStart": 116725.56000000001
            },
            {
              "lat": 43.7731052,
              "lng": -116.08733884,
              "alt": 2252.2791,
              "speed2d": 6.1107000000000005,
              "speed3d": 6.103,
              "msFromStart": 117248.50098245614
            },
            {
              "lat": 43.773112999999995,
              "lng": -116.08729872222222,
              "alt": 2251.6254444444444,
              "speed2d": 6.243888888888889,
              "speed3d": 6.266666666666667,
              "msFromStart": 117770.4261052631
            },
            {
              "lat": 43.773122022222225,
              "lng": -116.0872613,
              "alt": 2251.099,
              "speed2d": 6.328444444444444,
              "speed3d": 6.327777777777778,
              "msFromStart": 118264.91018583493
            },
            {
              "lat": 43.77313184444444,
              "lng": -116.0872215111111,
              "alt": 2250.7492222222227,
              "speed2d": 6.5184444444444445,
              "speed3d": 6.524444444444446,
              "msFromStart": 118760.40800000005
            },
            {
              "lat": 43.77314167777778,
              "lng": -116.08718473333333,
              "alt": 2250.1534444444446,
              "speed2d": 6.463888888888889,
              "speed3d": 6.508888888888889,
              "msFromStart": 119256.14014814819
            },
            {
              "lat": 43.77315035555556,
              "lng": -116.0871479,
              "alt": 2249.389,
              "speed2d": 6.418555555555557,
              "speed3d": 6.452222222222222,
              "msFromStart": 119752.28800000003
            },
            {
              "lat": 43.773158322222216,
              "lng": -116.08711265555557,
              "alt": 2248.4117777777774,
              "speed2d": 6.407555555555557,
              "speed3d": 6.456666666666668,
              "msFromStart": 120248.13955555558
            },
            {
              "lat": 43.7731656,
              "lng": -116.08707535555556,
              "alt": 2247.958,
              "speed2d": 6.3406666666666665,
              "speed3d": 6.388888888888889,
              "msFromStart": 120741.76799999995
            },
            {
              "lat": 43.773173099999994,
              "lng": -116.08703787777779,
              "alt": 2247.7741111111113,
              "speed2d": 6.154111111111111,
              "speed3d": 6.235555555555555,
              "msFromStart": 121235.25099935019
            },
            {
              "lat": 43.77318015555556,
              "lng": -116.08700627777779,
              "alt": 2247.0154444444443,
              "speed2d": 5.877111111111111,
              "speed3d": 5.953333333333334,
              "msFromStart": 121730.06989473684
            },
            {
              "lat": 43.773188555555556,
              "lng": -116.08697307777776,
              "alt": 2246.615222222222,
              "speed2d": 5.757777777777778,
              "speed3d": 5.801111111111111,
              "msFromStart": 122225.17638986351
            },
            {
              "lat": 43.77319865,
              "lng": -116.08693578,
              "alt": 2246.2786,
              "speed2d": 5.9147,
              "speed3d": 5.961,
              "msFromStart": 122748.52244444442
            },
            {
              "lat": 43.77320827777778,
              "lng": -116.08690293333333,
              "alt": 2245.458111111111,
              "speed2d": 5.7379999999999995,
              "speed3d": 5.817777777777779,
              "msFromStart": 123271.96395061727
            },
            {
              "lat": 43.77321684444445,
              "lng": -116.08687446666667,
              "alt": 2244.6393333333335,
              "speed2d": 5.485222222222221,
              "speed3d": 5.5744444444444445,
              "msFromStart": 123767.38799999995
            },
            {
              "lat": 43.77322792222222,
              "lng": -116.08684368888889,
              "alt": 2244.1588888888887,
              "speed2d": 5.597,
              "speed3d": 5.631111111111111,
              "msFromStart": 124262.66587654318
            },
            {
              "lat": 43.77323913333333,
              "lng": -116.0868126888889,
              "alt": 2243.492888888889,
              "speed2d": 5.690333333333334,
              "speed3d": 5.774444444444445,
              "msFromStart": 124757.51600000006
            },
            {
              "lat": 43.77324876666667,
              "lng": -116.08678496666667,
              "alt": 2242.5693333333334,
              "speed2d": 5.438555555555556,
              "speed3d": 5.5344444444444445,
              "msFromStart": 125252.41945679017
            },
            {
              "lat": 43.77325768888888,
              "lng": -116.0867553111111,
              "alt": 2242.078,
              "speed2d": 5.262777777777776,
              "speed3d": 5.3677777777777775,
              "msFromStart": 125748.07600000003
            },
            {
              "lat": 43.77326502222222,
              "lng": -116.08672542222223,
              "alt": 2241.880555555556,
              "speed2d": 5.134,
              "speed3d": 5.211111111111111,
              "msFromStart": 126243.86834567902
            },
            {
              "lat": 43.773271288888886,
              "lng": -116.08669396666667,
              "alt": 2241.5345555555555,
              "speed2d": 5.216222222222222,
              "speed3d": 5.2766666666666655,
              "msFromStart": 126739.73599999995
            },
            {
              "lat": 43.77327721111111,
              "lng": -116.08666536666667,
              "alt": 2240.9177777777777,
              "speed2d": 4.9398888888888886,
              "speed3d": 5.091111111111111,
              "msFromStart": 127235.3787030539
            },
            {
              "lat": 43.77328351111111,
              "lng": -116.08663846666668,
              "alt": 2240.328222222222,
              "speed2d": 4.76,
              "speed3d": 4.8277777777777775,
              "msFromStart": 127729.57389473684
            },
            {
              "lat": 43.77328972,
              "lng": -116.08660645,
              "alt": 2239.8825,
              "speed2d": 4.954000000000001,
              "speed3d": 4.998,
              "msFromStart": 128251.05368421055
            },
            {
              "lat": 43.77329688888889,
              "lng": -116.08657343333334,
              "alt": 2239.736777777778,
              "speed2d": 5.092444444444445,
              "speed3d": 5.1866666666666665,
              "msFromStart": 128773.09200000008
            },
            {
              "lat": 43.7733013,
              "lng": -116.08654584444444,
              "alt": 2239.686,
              "speed2d": 4.753777777777778,
              "speed3d": 4.848888888888889,
              "msFromStart": 129267.84404938277
            },
            {
              "lat": 43.77330243333334,
              "lng": -116.08651947777776,
              "alt": 2239.669111111111,
              "speed2d": 4.403,
              "speed3d": 4.483333333333333,
              "msFromStart": 129763.05999999997
            },
            {
              "lat": 43.77330264444444,
              "lng": -116.08649166666666,
              "alt": 2239.4006666666664,
              "speed2d": 4.443555555555555,
              "speed3d": 4.482222222222221,
              "msFromStart": 130258.43693827157
            },
            {
              "lat": 43.77329885555556,
              "lng": -116.0864670777778,
              "alt": 2239.4776666666667,
              "speed2d": 4.063111111111111,
              "speed3d": 4.148888888888889,
              "msFromStart": 130754.33200000001
            },
            {
              "lat": 43.77329506666666,
              "lng": -116.08644232222223,
              "alt": 2239.6084444444446,
              "speed2d": 3.972666666666667,
              "speed3d": 4.028888888888889,
              "msFromStart": 131250.0823703704
            },
            {
              "lat": 43.77329247777779,
              "lng": -116.08641981111113,
              "alt": 2239.6205555555557,
              "speed2d": 3.700111111111111,
              "speed3d": 3.7944444444444447,
              "msFromStart": 131744.43199999994
            },
            {
              "lat": 43.773291666666665,
              "lng": -116.08640382222222,
              "alt": 2239.7914444444446,
              "speed2d": 2.7964444444444445,
              "speed3d": 2.91,
              "msFromStart": 132238.53572969456
            },
            {
              "lat": 43.77329316666667,
              "lng": -116.0863902777778,
              "alt": 2240.1684444444445,
              "speed2d": 2.0918888888888887,
              "speed3d": 2.1966666666666663,
              "msFromStart": 132732.54021052644
            },
            {
              "lat": 43.77329345555556,
              "lng": -116.08638182222222,
              "alt": 2239.8662222222224,
              "speed2d": 1.5305555555555554,
              "speed3d": 1.6033333333333335,
              "msFromStart": 133226.70203508783
            },
            {
              "lat": 43.77329562,
              "lng": -116.08637363999999,
              "alt": 2239.6194,
              "speed2d": 1.3407000000000002,
              "speed3d": 1.4099999999999997,
              "msFromStart": 133750.17399999988
            },
            {
              "lat": 43.773297444444445,
              "lng": -116.08636870000001,
              "alt": 2239.2833333333333,
              "speed2d": 1.0372222222222223,
              "speed3d": 1.1277777777777778,
              "msFromStart": 134273.75061728386
            },
            {
              "lat": 43.773299933333334,
              "lng": -116.08636519999999,
              "alt": 2238.900666666667,
              "speed2d": 0.8901111111111111,
              "speed3d": 1.0055555555555555,
              "msFromStart": 134767.74400000006
            },
            {
              "lat": 43.77330076666667,
              "lng": -116.08635985555556,
              "alt": 2238.8747777777776,
              "speed2d": 0.7532222222222222,
              "speed3d": 0.8744444444444444,
              "msFromStart": 135261.5808395062
            },
            {
              "lat": 43.77330218888889,
              "lng": -116.08635475555556,
              "alt": 2238.9513333333334,
              "speed2d": 0.7116666666666667,
              "speed3d": 0.8177777777777777,
              "msFromStart": 135756.47199999992
            },
            {
              "lat": 43.77330155555556,
              "lng": -116.08634717777778,
              "alt": 2238.959111111111,
              "speed2d": 0.906,
              "speed3d": 0.9633333333333334,
              "msFromStart": 136251.50049642622
            },
            {
              "lat": 43.77330238888889,
              "lng": -116.08633833333336,
              "alt": 2239.404666666667,
              "speed2d": 0.9831111111111112,
              "speed3d": 1.04,
              "msFromStart": 136746.31242105275
            },
            {
              "lat": 43.77329838888889,
              "lng": -116.08633754444446,
              "alt": 2238.5514444444448,
              "speed2d": 0.82,
              "speed3d": 0.9488888888888889,
              "msFromStart": 137241.1421442496
            },
            {
              "lat": 43.77329814444445,
              "lng": -116.08633287777778,
              "alt": 2238.594888888889,
              "speed2d": 0.6414444444444445,
              "speed3d": 0.7966666666666666,
              "msFromStart": 137736.5048888889
            },
            {
              "lat": 43.77329847777777,
              "lng": -116.08632526666668,
              "alt": 2238.9155555555553,
              "speed2d": 0.7581111111111111,
              "speed3d": 0.84,
              "msFromStart": 138231.9866666667
            },
            {
              "lat": 43.773299433333335,
              "lng": -116.08631945555557,
              "alt": 2239.074222222222,
              "speed2d": 0.7182222222222223,
              "speed3d": 0.8222222222222222,
              "msFromStart": 138727.32622222224
            },
            {
              "lat": 43.773301710000005,
              "lng": -116.08631492,
              "alt": 2239.2803,
              "speed2d": 0.5603,
              "speed3d": 0.6459999999999999,
              "msFromStart": 139250.2262222222
            },
            {
              "lat": 43.77330303333333,
              "lng": -116.08630971111111,
              "alt": 2239.4063333333334,
              "speed2d": 0.6065555555555555,
              "speed3d": 0.6566666666666667,
              "msFromStart": 139773.68000000002
            }
          ]
        }
      ]
    },
    {
      "id": "trail-triumph",
      "pathType": "trail",
      "name": "Triumph",
      "difficulty": "black",
      "videos": [
        {
          "videoId": "GX010087",
          "pathId": "trail-triumph",
          "startSec": 30,
          "endSec": 114,
          "id": "video-segment-54",
          "gps": [
            {
              "lat": 43.77256021111112,
              "lng": -116.0909319111111,
              "alt": 2276.0134444444443,
              "speed2d": 3.394888888888889,
              "speed3d": 3.4266666666666667,
              "msFromStart": 30225.753333333338
            },
            {
              "lat": 43.7725767,
              "lng": -116.09092021,
              "alt": 2275.6690999999996,
              "speed2d": 3.6153000000000004,
              "speed3d": 3.685,
              "msFromStart": 30747.899
            },
            {
              "lat": 43.77259136666667,
              "lng": -116.09092127777777,
              "alt": 2274.9500000000003,
              "speed2d": 3.1284444444444444,
              "speed3d": 3.2411111111111115,
              "msFromStart": 31270.354888888887
            },
            {
              "lat": 43.77260338888889,
              "lng": -116.09093476666668,
              "alt": 2273.9252222222226,
              "speed2d": 2.9395555555555553,
              "speed3d": 3.055555555555556,
              "msFromStart": 31766.505333333316
            },
            {
              "lat": 43.77261511111111,
              "lng": -116.09095006666666,
              "alt": 2273.2315555555556,
              "speed2d": 3.2878888888888893,
              "speed3d": 3.29,
              "msFromStart": 32262.67714814814
            },
            {
              "lat": 43.772627122222225,
              "lng": -116.0909703222222,
              "alt": 2273.1349999999998,
              "speed2d": 3.7698888888888886,
              "speed3d": 3.8566666666666665,
              "msFromStart": 32758.73988888889
            },
            {
              "lat": 43.77263808888889,
              "lng": -116.09098506666668,
              "alt": 2272.9139999999998,
              "speed2d": 3.349444444444444,
              "speed3d": 3.513333333333334,
              "msFromStart": 33254.28386939572
            },
            {
              "lat": 43.77265106666666,
              "lng": -116.0909941111111,
              "alt": 2272.070111111111,
              "speed2d": 2.8307777777777776,
              "speed3d": 2.9833333333333334,
              "msFromStart": 33748.435368421066
            },
            {
              "lat": 43.77266801111111,
              "lng": -116.09099503333333,
              "alt": 2270.354777777778,
              "speed2d": 2.8897777777777773,
              "speed3d": 3.0533333333333337,
              "msFromStart": 34242.64391033139
            },
            {
              "lat": 43.77268266666667,
              "lng": -116.0909824,
              "alt": 2268.3395555555553,
              "speed2d": 3.6135555555555556,
              "speed3d": 3.7544444444444447,
              "msFromStart": 34737.37377777778
            },
            {
              "lat": 43.77270310000001,
              "lng": -116.09096992222221,
              "alt": 2266.8117777777775,
              "speed2d": 4.439,
              "speed3d": 4.62,
              "msFromStart": 35232.144296296305
            },
            {
              "lat": 43.77271815555555,
              "lng": -116.09095814444444,
              "alt": 2264.9081111111113,
              "speed2d": 4.207111111111112,
              "speed3d": 4.582222222222222,
              "msFromStart": 35726.86888888892
            },
            {
              "lat": 43.772728539999996,
              "lng": -116.09095161,
              "alt": 2262.241,
              "speed2d": 3.3022,
              "speed3d": 3.912,
              "msFromStart": 36249.23806666669
            },
            {
              "lat": 43.77274486666666,
              "lng": -116.09095854444445,
              "alt": 2259.554,
              "speed2d": 3.237111111111111,
              "speed3d": 3.8399999999999994,
              "msFromStart": 36772.140666666695
            },
            {
              "lat": 43.772765166666666,
              "lng": -116.09097287777777,
              "alt": 2256.9267777777777,
              "speed2d": 3.9708888888888887,
              "speed3d": 4.558888888888888,
              "msFromStart": 37267.518962962975
            },
            {
              "lat": 43.77278166666667,
              "lng": -116.0909934888889,
              "alt": 2254.8732222222225,
              "speed2d": 4.4044444444444455,
              "speed3d": 5.061111111111112,
              "msFromStart": 37762.767777777786
            },
            {
              "lat": 43.77279737777778,
              "lng": -116.09100755555556,
              "alt": 2252.461222222222,
              "speed2d": 4.188444444444444,
              "speed3d": 5.023333333333333,
              "msFromStart": 38258.00893177389
            },
            {
              "lat": 43.77281314444444,
              "lng": -116.09100783333334,
              "alt": 2250.104444444444,
              "speed2d": 3.8061111111111106,
              "speed3d": 4.736666666666666,
              "msFromStart": 38753.24778947372
            },
            {
              "lat": 43.77283286666667,
              "lng": -116.09099365555555,
              "alt": 2247.6413333333335,
              "speed2d": 4.478555555555555,
              "speed3d": 5.3100000000000005,
              "msFromStart": 39248.323426900606
            },
            {
              "lat": 43.77285046666666,
              "lng": -116.09096788888888,
              "alt": 2245.0023333333334,
              "speed2d": 5.466444444444445,
              "speed3d": 6.251111111111111,
              "msFromStart": 39742.74666666667
            },
            {
              "lat": 43.772870700000006,
              "lng": -116.09094254444445,
              "alt": 2242.1929999999998,
              "speed2d": 6.2074444444444445,
              "speed3d": 7.000000000000001,
              "msFromStart": 40237.051703703706
            },
            {
              "lat": 43.77289135555556,
              "lng": -116.09091463333333,
              "alt": 2239.3948888888885,
              "speed2d": 6.354666666666667,
              "speed3d": 7.286666666666668,
              "msFromStart": 40731.14488888892
            },
            {
              "lat": 43.77291636666667,
              "lng": -116.09089592222222,
              "alt": 2235.9572222222223,
              "speed2d": 5.978666666666666,
              "speed3d": 7.131111111111112,
              "msFromStart": 41225.62244444447
            },
            {
              "lat": 43.77295677,
              "lng": -116.0909014,
              "alt": 2232.4294000000004,
              "speed2d": 6.2196,
              "speed3d": 7.324000000000002,
              "msFromStart": 41749.29233333335
            },
            {
              "lat": 43.77299947777778,
              "lng": -116.09091817777778,
              "alt": 2229.586111111111,
              "speed2d": 7.113888888888889,
              "speed3d": 8.155555555555555,
              "msFromStart": 42272.846000000005
            },
            {
              "lat": 43.77303363333333,
              "lng": -116.09094247777779,
              "alt": 2227.3811111111113,
              "speed2d": 7.691,
              "speed3d": 8.7,
              "msFromStart": 42768.07377777777
            },
            {
              "lat": 43.77306474444444,
              "lng": -116.0909662111111,
              "alt": 2224.999333333333,
              "speed2d": 7.690222222222221,
              "speed3d": 8.78111111111111,
              "msFromStart": 43263.4375282651
            },
            {
              "lat": 43.77309356666666,
              "lng": -116.09098447777778,
              "alt": 2222.339111111111,
              "speed2d": 7.224888888888889,
              "speed3d": 8.45888888888889,
              "msFromStart": 43759.27831578949
            },
            {
              "lat": 43.77312306666667,
              "lng": -116.0909911111111,
              "alt": 2219.601222222222,
              "speed2d": 6.7331111111111115,
              "speed3d": 8.055555555555555,
              "msFromStart": 44254.75200779729
            },
            {
              "lat": 43.77315098888889,
              "lng": -116.09098692222221,
              "alt": 2216.865333333333,
              "speed2d": 6.435888888888888,
              "speed3d": 7.845555555555556,
              "msFromStart": 44748.65688888893
            },
            {
              "lat": 43.77317765555555,
              "lng": -116.09097417777777,
              "alt": 2214.1793333333335,
              "speed2d": 6.384222222222222,
              "speed3d": 7.8100000000000005,
              "msFromStart": 45242.57674074076
            },
            {
              "lat": 43.773206466666664,
              "lng": -116.09095248888889,
              "alt": 2211.6177777777775,
              "speed2d": 7.080444444444446,
              "speed3d": 8.346666666666668,
              "msFromStart": 45737.18400000001
            },
            {
              "lat": 43.77323811111111,
              "lng": -116.09092673333333,
              "alt": 2209.133666666666,
              "speed2d": 7.7282222222222225,
              "speed3d": 8.92222222222222,
              "msFromStart": 46231.98148148149
            },
            {
              "lat": 43.77327205555555,
              "lng": -116.09089502222223,
              "alt": 2206.6107777777775,
              "speed2d": 8.368,
              "speed3d": 9.561111111111112,
              "msFromStart": 46727.26488888891
            },
            {
              "lat": 43.77330551000001,
              "lng": -116.09086984,
              "alt": 2204.413,
              "speed2d": 7.806900000000001,
              "speed3d": 9.148,
              "msFromStart": 47250.326266666685
            },
            {
              "lat": 43.773344222222214,
              "lng": -116.0908589888889,
              "alt": 2201.620222222222,
              "speed2d": 7.521,
              "speed3d": 8.796666666666667,
              "msFromStart": 47774.07066666665
            },
            {
              "lat": 43.773381,
              "lng": -116.09085706666666,
              "alt": 2198.2438888888887,
              "speed2d": 7.584777777777776,
              "speed3d": 8.946666666666667,
              "msFromStart": 48269.88502923977
            },
            {
              "lat": 43.77342181111111,
              "lng": -116.09086752222223,
              "alt": 2195.175111111111,
              "speed2d": 7.9752222222222215,
              "speed3d": 9.293333333333333,
              "msFromStart": 48764.55494736843
            },
            {
              "lat": 43.77345924444445,
              "lng": -116.09087997777777,
              "alt": 2192.4666666666667,
              "speed2d": 8.072,
              "speed3d": 9.445555555555558,
              "msFromStart": 49259.0920974659
            },
            {
              "lat": 43.77348298888889,
              "lng": -116.09089465555556,
              "alt": 2188.616666666667,
              "speed2d": 7.319888888888889,
              "speed3d": 8.94888888888889,
              "msFromStart": 49753.339111111134
            },
            {
              "lat": 43.77350165555555,
              "lng": -116.09090962222224,
              "alt": 2184.1224444444447,
              "speed2d": 6.475111111111111,
              "speed3d": 8.41888888888889,
              "msFromStart": 50247.546370370386
            },
            {
              "lat": 43.77352536666666,
              "lng": -116.09092918888891,
              "alt": 2180.8572222222224,
              "speed2d": 6.3260000000000005,
              "speed3d": 8.274444444444443,
              "msFromStart": 50741.710666666695
            },
            {
              "lat": 43.773556400000004,
              "lng": -116.09094305555556,
              "alt": 2177.6715555555556,
              "speed2d": 6.609333333333334,
              "speed3d": 8.58,
              "msFromStart": 51236.15585185186
            },
            {
              "lat": 43.77357456666666,
              "lng": -116.09094221111113,
              "alt": 2173.220222222222,
              "speed2d": 5.820222222222222,
              "speed3d": 8.157777777777778,
              "msFromStart": 51731.74177777776
            },
            {
              "lat": 43.773586933333334,
              "lng": -116.09093448888888,
              "alt": 2168.1883333333335,
              "speed2d": 5.277444444444445,
              "speed3d": 7.944444444444443,
              "msFromStart": 52227.377333333316
            },
            {
              "lat": 43.77360231,
              "lng": -116.09092126999997,
              "alt": 2163.3212,
              "speed2d": 5.315100000000001,
              "speed3d": 8.126000000000001,
              "msFromStart": 52750.27466666666
            },
            {
              "lat": 43.773617055555555,
              "lng": -116.09091703333334,
              "alt": 2157.9343333333336,
              "speed2d": 5.103777777777778,
              "speed3d": 8.164444444444444,
              "msFromStart": 53273.19938401559
            },
            {
              "lat": 43.773630983333334,
              "lng": -116.09091913333333,
              "alt": 2153.3713333333335,
              "speed2d": 5.125666666666667,
              "speed3d": 8.35,
              "msFromStart": 53686.156
            },
            {
              "lat": 43.773644625465096,
              "lng": -116.09092498653627,
              "alt": 2149.7272498062616,
              "speed2d": 5.3370878352141835,
              "speed3d": 8.613119735993864,
              "msFromStart": 54000
            },
            {
              "lat": 43.77367080601926,
              "lng": -116.09093828625778,
              "alt": 2143.8621075249016,
              "speed2d": 5.890719287317344,
              "speed3d": 9.161166003208216,
              "msFromStart": 54500
            },
            {
              "lat": 43.77371473333333,
              "lng": -116.09096096666667,
              "alt": 2133.809666666667,
              "speed2d": 6.7763333333333335,
              "speed3d": 10.073333333333334,
              "msFromStart": 55347.70414814816
            },
            {
              "lat": 43.77372513333333,
              "lng": -116.0909691,
              "alt": 2130.704333333333,
              "speed2d": 6.827666666666666,
              "speed3d": 10.26,
              "msFromStart": 55585.68888888889
            },
            {
              "lat": 43.7737344364471,
              "lng": -116.09099103286954,
              "alt": 2126.033982715518,
              "speed2d": 6.324305204245754,
              "speed3d": 10.072367134758332,
              "msFromStart": 56000
            },
            {
              "lat": 43.7737555,
              "lng": -116.09102737142855,
              "alt": 2117.843714285714,
              "speed2d": 5.568,
              "speed3d": 9.784285714285714,
              "msFromStart": 56764.10542857143
            },
            {
              "lat": 43.7737855,
              "lng": -116.09103545,
              "alt": 2113.839,
              "speed2d": 6.24325,
              "speed3d": 10.1775,
              "msFromStart": 57208.40572222222
            },
            {
              "lat": 43.77382708,
              "lng": -116.09104062,
              "alt": 2109.7819999999997,
              "speed2d": 7.2758,
              "speed3d": 10.89,
              "msFromStart": 57675.70000000001
            },
            {
              "lat": 43.773855672680305,
              "lng": -116.09103137543201,
              "alt": 2106.3880860697454,
              "speed2d": 8.14313725911956,
              "speed3d": 11.345093010482163,
              "msFromStart": 58000
            },
            {
              "lat": 43.77389273859618,
              "lng": -116.0910249897237,
              "alt": 2102.865383676401,
              "speed2d": 8.086962231176084,
              "speed3d": 11.359496863801004,
              "msFromStart": 58500
            },
            {
              "lat": 43.773928781441164,
              "lng": -116.09101423454969,
              "alt": 2099.3716842410995,
              "speed2d": 7.954474259574502,
              "speed3d": 11.36718884379866,
              "msFromStart": 59000
            },
            {
              "lat": 43.77398648333334,
              "lng": -116.09102358333332,
              "alt": 2093.9584999999997,
              "speed2d": 8.190333333333333,
              "speed3d": 11.463333333333335,
              "msFromStart": 59725.88281481483
            },
            {
              "lat": 43.7740206,
              "lng": -116.09104199999999,
              "alt": 2090.3236666666667,
              "speed2d": 8.681999999999999,
              "speed3d": 11.803333333333333,
              "msFromStart": 60129.851259259274
            },
            {
              "lat": 43.77405149882181,
              "lng": -116.09106642186092,
              "alt": 2088.1081585525358,
              "speed2d": 9.039700733904953,
              "speed3d": 11.985676967633442,
              "msFromStart": 60500
            },
            {
              "lat": 43.77410665,
              "lng": -116.09111245,
              "alt": 2084.4205,
              "speed2d": 9.063500000000001,
              "speed3d": 11.934999999999999,
              "msFromStart": 61221.350666666694
            },
            {
              "lat": 43.77415544,
              "lng": -116.09113669999999,
              "alt": 2081.5712,
              "speed2d": 9.1208,
              "speed3d": 11.847999999999999,
              "msFromStart": 61720.94480000003
            },
            {
              "lat": 43.774195500000005,
              "lng": -116.09114876666666,
              "alt": 2079.2573333333335,
              "speed2d": 9.319666666666667,
              "speed3d": 12,
              "msFromStart": 62072.12133333338
            },
            {
              "lat": 43.7742485,
              "lng": -116.0911586,
              "alt": 2076.1760000000004,
              "speed2d": 9.82,
              "speed3d": 12.32,
              "msFromStart": 62539.863777777755
            },
            {
              "lat": 43.774297918149024,
              "lng": -116.0911624718596,
              "alt": 2072.9859893342536,
              "speed2d": 10.274031797879884,
              "speed3d": 12.689579926006862,
              "msFromStart": 63000
            },
            {
              "lat": 43.77434289999999,
              "lng": -116.0911539,
              "alt": 2068.508,
              "speed2d": 9.418,
              "speed3d": 12.065,
              "msFromStart": 63667.461684210524
            },
            {
              "lat": 43.774377486488795,
              "lng": -116.09114661845616,
              "alt": 2067.176124685134,
              "speed2d": 9.453070365668907,
              "speed3d": 11.971821473906965,
              "msFromStart": 64000
            },
            {
              "lat": 43.774470574999995,
              "lng": -116.09113207499999,
              "alt": 2063.24525,
              "speed2d": 9.516,
              "speed3d": 11.915,
              "msFromStart": 64904.357444444446
            },
            {
              "lat": 43.77449941249999,
              "lng": -116.09114132500001,
              "alt": 2060.829375,
              "speed2d": 9.65525,
              "speed3d": 12.085,
              "msFromStart": 65234.58344444444
            },
            {
              "lat": 43.774537699999996,
              "lng": -116.09116115999998,
              "alt": 2056.5245999999997,
              "speed2d": 9.8086,
              "speed3d": 12.286000000000001,
              "msFromStart": 65768.98466666664
            },
            {
              "lat": 43.77458721666667,
              "lng": -116.09118418333334,
              "alt": 2052.4795,
              "speed2d": 9.518666666666666,
              "speed3d": 12.111666666666666,
              "msFromStart": 66336.36211111111
            },
            {
              "lat": 43.774634080000006,
              "lng": -116.0911951,
              "alt": 2050.9865999999997,
              "speed2d": 9.5306,
              "speed3d": 11.974,
              "msFromStart": 66749.1804444445
            },
            {
              "lat": 43.7747083,
              "lng": -116.09120095,
              "alt": 2049.5948333333336,
              "speed2d": 10.494833333333332,
              "speed3d": 12.62833333333333,
              "msFromStart": 67280.98311111113
            },
            {
              "lat": 43.77475907499999,
              "lng": -116.09119281250001,
              "alt": 2047.8693750000002,
              "speed2d": 10.601625,
              "speed3d": 12.72625,
              "msFromStart": 67711.3064444444
            },
            {
              "lat": 43.77480166666667,
              "lng": -116.09118123333332,
              "alt": 2045.8486666666665,
              "speed2d": 10.998,
              "speed3d": 12.919999999999998,
              "msFromStart": 68086.62651851846
            },
            {
              "lat": 43.77484857316995,
              "lng": -116.09117308006206,
              "alt": 2043.785691994863,
              "speed2d": 11.124287136130139,
              "speed3d": 12.97236675941781,
              "msFromStart": 68500
            },
            {
              "lat": 43.77493928333334,
              "lng": -116.09116383333334,
              "alt": 2039.2623333333336,
              "speed2d": 10.896666666666665,
              "speed3d": 12.878333333333334,
              "msFromStart": 69362.16933333332
            },
            {
              "lat": 43.7749666,
              "lng": -116.09117106666667,
              "alt": 2038.0223333333333,
              "speed2d": 10.575333333333333,
              "speed3d": 12.68,
              "msFromStart": 69646.28088888887
            },
            {
              "lat": 43.775066100000004,
              "lng": -116.09119235,
              "alt": 2035.4875,
              "speed2d": 10.9225,
              "speed3d": 12.74,
              "msFromStart": 70407.03355555558
            },
            {
              "lat": 43.77511465,
              "lng": -116.09119563333331,
              "alt": 2033.6358333333333,
              "speed2d": 11.350833333333334,
              "speed3d": 13.116666666666667,
              "msFromStart": 70764.58844444451
            },
            {
              "lat": 43.775156550000005,
              "lng": -116.09119425,
              "alt": 2032.1405,
              "speed2d": 11.6455,
              "speed3d": 13.405000000000001,
              "msFromStart": 71067.13488888899
            },
            {
              "lat": 43.77523105611445,
              "lng": -116.09118847258381,
              "alt": 2030.5681730200909,
              "speed2d": 12.319596399792735,
              "speed3d": 13.8945729229583,
              "msFromStart": 71500
            },
            {
              "lat": 43.775271393148834,
              "lng": -116.09117090001438,
              "alt": 2028.9844230120787,
              "speed2d": 11.853324244870116,
              "speed3d": 13.46302434274203,
              "msFromStart": 72000
            },
            {
              "lat": 43.775311730183226,
              "lng": -116.09115332744494,
              "alt": 2027.4006730040664,
              "speed2d": 11.387052089947497,
              "speed3d": 13.031475762525758,
              "msFromStart": 72500
            },
            {
              "lat": 43.77535206721761,
              "lng": -116.0911357548755,
              "alt": 2025.816922996054,
              "speed2d": 10.920779935024878,
              "speed3d": 12.599927182309488,
              "msFromStart": 73000
            },
            {
              "lat": 43.775392404252,
              "lng": -116.09111818230606,
              "alt": 2024.2331729880418,
              "speed2d": 10.45450778010226,
              "speed3d": 12.168378602093217,
              "msFromStart": 73500
            },
            {
              "lat": 43.77543274128639,
              "lng": -116.09110060973663,
              "alt": 2022.6494229800296,
              "speed2d": 9.988235625179641,
              "speed3d": 11.736830021876946,
              "msFromStart": 74000
            },
            {
              "lat": 43.77547307832077,
              "lng": -116.0910830371672,
              "alt": 2021.0656729720174,
              "speed2d": 9.521963470257022,
              "speed3d": 11.305281441660675,
              "msFromStart": 74500
            },
            {
              "lat": 43.775513415355164,
              "lng": -116.09106546459775,
              "alt": 2019.4819229640052,
              "speed2d": 9.055691315334403,
              "speed3d": 10.873732861444404,
              "msFromStart": 75000
            },
            {
              "lat": 43.77555375238955,
              "lng": -116.09104789202831,
              "alt": 2017.898172955993,
              "speed2d": 8.589419160411785,
              "speed3d": 10.442184281228133,
              "msFromStart": 75500
            },
            {
              "lat": 43.77561872,
              "lng": -116.09102536,
              "alt": 2015.2776000000001,
              "speed2d": 8.008000000000001,
              "speed3d": 9.902000000000001,
              "msFromStart": 76283.26266666666
            },
            {
              "lat": 43.77564288074131,
              "lng": -116.09102631767355,
              "alt": 2014.5621034567416,
              "speed2d": 8.109652743181693,
              "speed3d": 9.94112039522751,
              "msFromStart": 76500
            },
            {
              "lat": 43.775699225000004,
              "lng": -116.09104007500001,
              "alt": 2005.3069999999998,
              "speed2d": 8.80775,
              "speed3d": 10.63,
              "msFromStart": 77390.42511111112
            },
            {
              "lat": 43.77570839999999,
              "lng": -116.0910419125,
              "alt": 2001.8034999999998,
              "speed2d": 8.775375,
              "speed3d": 10.64875,
              "msFromStart": 77728.35905555559
            },
            {
              "lat": 43.77572024,
              "lng": -116.09104969999999,
              "alt": 1996.4682000000003,
              "speed2d": 8.732800000000001,
              "speed3d": 10.66,
              "msFromStart": 78201.27275321638
            },
            {
              "lat": 43.775722515549695,
              "lng": -116.09106276085785,
              "alt": 1992.3477988855886,
              "speed2d": 8.644080427394911,
              "speed3d": 10.727453081468338,
              "msFromStart": 78500
            },
            {
              "lat": 43.775737666666664,
              "lng": -116.09105181666666,
              "alt": 1984.804,
              "speed2d": 8.308166666666667,
              "speed3d": 10.435,
              "msFromStart": 79290.59153216373
            },
            {
              "lat": 43.775742725,
              "lng": -116.09104075,
              "alt": 1980.938,
              "speed2d": 8.138000000000002,
              "speed3d": 10.32,
              "msFromStart": 79675.41333333329
            },
            {
              "lat": 43.77573341898098,
              "lng": -116.09102991519225,
              "alt": 1974.7964176931873,
              "speed2d": 8.130924038828022,
              "speed3d": 10.268386058242033,
              "msFromStart": 80000
            },
            {
              "lat": 43.775810725,
              "lng": -116.09100008749999,
              "alt": 1975.6118749999998,
              "speed2d": 8.09625,
              "speed3d": 10.2675,
              "msFromStart": 80793.72994444438
            },
            {
              "lat": 43.775848611111115,
              "lng": -116.09098969999998,
              "alt": 1975.3936666666666,
              "speed2d": 7.7508888888888885,
              "speed3d": 9.947777777777777,
              "msFromStart": 81266.90014814812
            },
            {
              "lat": 43.775876133333334,
              "lng": -116.09099136666667,
              "alt": 1974.3233333333333,
              "speed2d": 7.361999999999999,
              "speed3d": 9.643333333333333,
              "msFromStart": 81679.87200000003
            },
            {
              "lat": 43.77589185847389,
              "lng": -116.09100786668103,
              "alt": 1972.7284484394013,
              "speed2d": 7.155734505878313,
              "speed3d": 9.480923263754372,
              "msFromStart": 82000
            },
            {
              "lat": 43.775910658290485,
              "lng": -116.0910436838629,
              "alt": 1969.4211604898624,
              "speed2d": 6.957646992935625,
              "speed3d": 9.346398636968166,
              "msFromStart": 82500
            },
            {
              "lat": 43.775933486786435,
              "lng": -116.09108005616412,
              "alt": 1966.1291224571344,
              "speed2d": 6.66002254592774,
              "speed3d": 9.118935502143296,
              "msFromStart": 83000
            },
            {
              "lat": 43.775952775,
              "lng": -116.09112958749999,
              "alt": 1961.21225,
              "speed2d": 6.541000000000001,
              "speed3d": 9.10375,
              "msFromStart": 83771.8623157895
            },
            {
              "lat": 43.77596486,
              "lng": -116.09114339999999,
              "alt": 1958.9212000000002,
              "speed2d": 6.247400000000001,
              "speed3d": 8.906,
              "msFromStart": 84206.47236491232
            },
            {
              "lat": 43.775987,
              "lng": -116.09114936,
              "alt": 1954.9734,
              "speed2d": 5.9046,
              "speed3d": 8.68,
              "msFromStart": 84812.00302222226
            },
            {
              "lat": 43.7760107,
              "lng": -116.09114446,
              "alt": 1952.3426000000002,
              "speed2d": 5.9186,
              "speed3d": 8.696,
              "msFromStart": 85175.35031111113
            },
            {
              "lat": 43.7760618,
              "lng": -116.09113146666667,
              "alt": 1948.4953333333333,
              "speed2d": 6.257,
              "speed3d": 8.926666666666666,
              "msFromStart": 85816.35437037032
            },
            {
              "lat": 43.77609858333333,
              "lng": -116.09111495,
              "alt": 1945.7751666666668,
              "speed2d": 6.592166666666667,
              "speed3d": 9.165000000000001,
              "msFromStart": 86292.55496296292
            },
            {
              "lat": 43.776135485714285,
              "lng": -116.09109977142857,
              "alt": 1943.5905714285714,
              "speed2d": 6.930428571428571,
              "speed3d": 9.401428571428571,
              "msFromStart": 86745.6003174603
            },
            {
              "lat": 43.77615462024613,
              "lng": -116.09109470340677,
              "alt": 1942.1120485513009,
              "speed2d": 7.0782169802672055,
              "speed3d": 9.513216980267204,
              "msFromStart": 87000
            },
            {
              "lat": 43.776211599999996,
              "lng": -116.09108625,
              "alt": 1936.638,
              "speed2d": 7.316666666666666,
              "speed3d": 9.741666666666667,
              "msFromStart": 87797.33748148143
            },
            {
              "lat": 43.7762502125,
              "lng": -116.09109421250001,
              "alt": 1933.103,
              "speed2d": 7.405749999999999,
              "speed3d": 9.842500000000001,
              "msFromStart": 88256.64917543858
            },
            {
              "lat": 43.77629627142857,
              "lng": -116.09110162857142,
              "alt": 1929.259,
              "speed2d": 7.572,
              "speed3d": 9.987142857142857,
              "msFromStart": 88791.3009924812
            },
            {
              "lat": 43.77632905555555,
              "lng": -116.09111271111112,
              "alt": 1926.5976666666666,
              "speed2d": 7.532111111111111,
              "speed3d": 9.979999999999999,
              "msFromStart": 89247.06026250812
            },
            {
              "lat": 43.776361200000004,
              "lng": -116.09112106666666,
              "alt": 1924.255666666667,
              "speed2d": 7.527333333333334,
              "speed3d": 9.951666666666666,
              "msFromStart": 89713.5055555555
            },
            {
              "lat": 43.776405874999995,
              "lng": -116.0911266,
              "alt": 1921.3379999999997,
              "speed2d": 7.757250000000001,
              "speed3d": 10.1125,
              "msFromStart": 90262.1933333333
            },
            {
              "lat": 43.776448744444444,
              "lng": -116.09111832222223,
              "alt": 1918.973888888889,
              "speed2d": 8.014555555555555,
              "speed3d": 10.315555555555557,
              "msFromStart": 90729.42799999996
            },
            {
              "lat": 43.776489659999996,
              "lng": -116.09111304,
              "alt": 1916.7042999999999,
              "speed2d": 8.071100000000001,
              "speed3d": 10.349,
              "msFromStart": 91251.92199999999
            },
            {
              "lat": 43.7765281,
              "lng": -116.091115175,
              "alt": 1913.8314999999998,
              "speed2d": 8.17275,
              "speed3d": 10.4175,
              "msFromStart": 91775.27466666674
            },
            {
              "lat": 43.7765650625,
              "lng": -116.09112722500001,
              "alt": 1911.060625,
              "speed2d": 8.354375,
              "speed3d": 10.55875,
              "msFromStart": 92243.55066666672
            },
            {
              "lat": 43.776621,
              "lng": -116.091145975,
              "alt": 1906.89575,
              "speed2d": 8.7045,
              "speed3d": 10.8475,
              "msFromStart": 92903.91266666673
            },
            {
              "lat": 43.77665954,
              "lng": -116.09115652,
              "alt": 1903.8546000000001,
              "speed2d": 9.05,
              "speed3d": 11.123999999999999,
              "msFromStart": 93305.39835789477
            },
            {
              "lat": 43.776708760000005,
              "lng": -116.09117158000001,
              "alt": 1900.9073999999998,
              "speed2d": 9.543800000000001,
              "speed3d": 11.534,
              "msFromStart": 93777.95208421056
            },
            {
              "lat": 43.776749933333335,
              "lng": -116.0911922111111,
              "alt": 1898.6414444444445,
              "speed2d": 9.826222222222222,
              "speed3d": 11.774444444444445,
              "msFromStart": 94250.53046393764
            },
            {
              "lat": 43.77678413333334,
              "lng": -116.09121816666666,
              "alt": 1895.9351111111114,
              "speed2d": 9.923222222222222,
              "speed3d": 11.857777777777779,
              "msFromStart": 94745.54000000002
            },
            {
              "lat": 43.77681855714285,
              "lng": -116.09124074285714,
              "alt": 1893.8831428571427,
              "speed2d": 9.983428571428572,
              "speed3d": 11.891428571428573,
              "msFromStart": 95217.01701587305
            },
            {
              "lat": 43.776863166666665,
              "lng": -116.09125923333332,
              "alt": 1891.268,
              "speed2d": 10.116666666666667,
              "speed3d": 11.983333333333333,
              "msFromStart": 95735.424
            },
            {
              "lat": 43.776909200000006,
              "lng": -116.09126535,
              "alt": 1889.232,
              "speed2d": 10.3345,
              "speed3d": 12.16,
              "msFromStart": 96120.26844444445
            },
            {
              "lat": 43.77694972709075,
              "lng": -116.09126459919695,
              "alt": 1887.2741058426234,
              "speed2d": 10.302673442551782,
              "speed3d": 12.157869377020713,
              "msFromStart": 96500
            },
            {
              "lat": 43.7770234375,
              "lng": -116.09125639999998,
              "alt": 1883.20275,
              "speed2d": 10.10725,
              "speed3d": 12.021249999999998,
              "msFromStart": 97248.27366666665
            },
            {
              "lat": 43.77707885555556,
              "lng": -116.0912337,
              "alt": 1880.1297777777775,
              "speed2d": 10.215555555555556,
              "speed3d": 12.066666666666666,
              "msFromStart": 97770.728
            },
            {
              "lat": 43.777138,
              "lng": -116.0912077,
              "alt": 1877.8615714285716,
              "speed2d": 10.553285714285716,
              "speed3d": 12.34,
              "msFromStart": 98289.25557894736
            },
            {
              "lat": 43.77718903333333,
              "lng": -116.09118754444445,
              "alt": 1876.4371111111109,
              "speed2d": 10.703888888888889,
              "speed3d": 12.469999999999999,
              "msFromStart": 98760.73136842102
            },
            {
              "lat": 43.777236511111106,
              "lng": -116.09116879999999,
              "alt": 1874.9042222222222,
              "speed2d": 10.741666666666667,
              "speed3d": 12.472222222222223,
              "msFromStart": 99255.70056400257
            },
            {
              "lat": 43.777274299999995,
              "lng": -116.09115828,
              "alt": 1872.9474,
              "speed2d": 10.7532,
              "speed3d": 12.484000000000002,
              "msFromStart": 99662.23288888892
            },
            {
              "lat": 43.7773349,
              "lng": -116.0911725,
              "alt": 1869.4873333333333,
              "speed2d": 11.002333333333333,
              "speed3d": 12.666666666666664,
              "msFromStart": 100207.95659259264
            },
            {
              "lat": 43.77740795999999,
              "lng": -116.09118962,
              "alt": 1865.3368,
              "speed2d": 11.5466,
              "speed3d": 13.145999999999999,
              "msFromStart": 100784.07226666666
            },
            {
              "lat": 43.777483233333335,
              "lng": -116.09120683333333,
              "alt": 1861.6173333333331,
              "speed2d": 12.193999999999999,
              "speed3d": 13.713333333333335,
              "msFromStart": 101308.66548148147
            },
            {
              "lat": 43.777548100000004,
              "lng": -116.09122675714286,
              "alt": 1857.8275714285714,
              "speed2d": 12.669142857142857,
              "speed3d": 14.19142857142857,
              "msFromStart": 101784.70577777778
            },
            {
              "lat": 43.77759674000001,
              "lng": -116.09125134,
              "alt": 1854.3980000000001,
              "speed2d": 12.6536,
              "speed3d": 14.234,
              "msFromStart": 102251.48422222224
            },
            {
              "lat": 43.77763795555555,
              "lng": -116.09127413333334,
              "alt": 1850.1444444444442,
              "speed2d": 12.328222222222223,
              "speed3d": 13.966666666666665,
              "msFromStart": 102773.38400000003
            },
            {
              "lat": 43.77769238333333,
              "lng": -116.09129093333333,
              "alt": 1846.236,
              "speed2d": 12.206666666666667,
              "speed3d": 13.858333333333333,
              "msFromStart": 103323.12663157893
            },
            {
              "lat": 43.77772412,
              "lng": -116.09129686,
              "alt": 1843.8886,
              "speed2d": 12.0674,
              "speed3d": 13.748,
              "msFromStart": 103653.52042105258
            },
            {
              "lat": 43.77774649472102,
              "lng": -116.09130177105283,
              "alt": 1841.2014221232066,
              "speed2d": 11.610052595893052,
              "speed3d": 13.352894711352189,
              "msFromStart": 104000
            },
            {
              "lat": 43.77782998,
              "lng": -116.09130082,
              "alt": 1835.3247999999999,
              "speed2d": 11.8202,
              "speed3d": 13.516000000000002,
              "msFromStart": 104743.90773333333
            },
            {
              "lat": 43.7778649,
              "lng": -116.09129945,
              "alt": 1832.6335,
              "speed2d": 11.9215,
              "speed3d": 13.620000000000001,
              "msFromStart": 105057.75733333331
            },
            {
              "lat": 43.7779368,
              "lng": -116.09129466666667,
              "alt": 1827.641,
              "speed2d": 12.1875,
              "speed3d": 13.858333333333334,
              "msFromStart": 105681.25311111109
            },
            {
              "lat": 43.77799713333333,
              "lng": -116.0912855,
              "alt": 1823.6466666666665,
              "speed2d": 12.463999999999999,
              "speed3d": 14.11,
              "msFromStart": 106185.43777777776
            },
            {
              "lat": 43.7780591,
              "lng": -116.09127116666667,
              "alt": 1819.8179999999998,
              "speed2d": 12.807333333333332,
              "speed3d": 14.413333333333334,
              "msFromStart": 106681.24799999995
            },
            {
              "lat": 43.77809714111443,
              "lng": -116.09125961915575,
              "alt": 1817.178794024611,
              "speed2d": 13.01309210652443,
              "speed3d": 14.607331469123217,
              "msFromStart": 107000
            },
            {
              "lat": 43.7781533318456,
              "lng": -116.09124077873412,
              "alt": 1813.1107503544974,
              "speed2d": 13.267603065369643,
              "speed3d": 14.859363425122536,
              "msFromStart": 107500
            },
            {
              "lat": 43.77822041666666,
              "lng": -116.09121406666668,
              "alt": 1806.3723333333332,
              "speed2d": 13.543333333333333,
              "speed3d": 15.145000000000001,
              "msFromStart": 108212.41866666672
            },
            {
              "lat": 43.77825262857143,
              "lng": -116.09118958571428,
              "alt": 1798.6065714285714,
              "speed2d": 13.780142857142858,
              "speed3d": 15.371428571428572,
              "msFromStart": 108809.88114285709
            },
            {
              "lat": 43.778262082415694,
              "lng": -116.09117925208223,
              "alt": 1796.279922294662,
              "speed2d": 13.842373210219323,
              "speed3d": 15.45341111145774,
              "msFromStart": 109000
            },
            {
              "lat": 43.77832239999999,
              "lng": -116.0911125,
              "alt": 1788.15975,
              "speed2d": 14.128,
              "speed3d": 15.735000000000001,
              "msFromStart": 109883.36000000002
            },
            {
              "lat": 43.77836026666667,
              "lng": -116.09108839999999,
              "alt": 1786.9215000000002,
              "speed2d": 14.182666666666666,
              "speed3d": 15.775,
              "msFromStart": 110208.75674074076
            },
            {
              "lat": 43.77843,
              "lng": -116.0910478,
              "alt": 1784.9286000000002,
              "speed2d": 14.3018,
              "speed3d": 15.863999999999997,
              "msFromStart": 110772.46524444441
            },
            {
              "lat": 43.77846736666667,
              "lng": -116.09102826666667,
              "alt": 1783.5863333333332,
              "speed2d": 14.306999999999999,
              "speed3d": 15.866666666666667,
              "msFromStart": 111081.04266666662
            },
            {
              "lat": 43.778506799999995,
              "lng": -116.09096413333334,
              "alt": 1778.2723333333333,
              "speed2d": 13.507333333333332,
              "speed3d": 15.21,
              "msFromStart": 111906.50133333326
            },
            {
              "lat": 43.7785174,
              "lng": -116.0909429125,
              "alt": 1776.2697499999997,
              "speed2d": 13.129125,
              "speed3d": 14.868749999999999,
              "msFromStart": 112208.96611111105
            },
            {
              "lat": 43.77853476666667,
              "lng": -116.09090666666665,
              "alt": 1771.9796666666666,
              "speed2d": 12.412333333333335,
              "speed3d": 14.239999999999998,
              "msFromStart": 112785.31822222221
            },
            {
              "lat": 43.778559425,
              "lng": -116.0908838,
              "alt": 1769.2462500000001,
              "speed2d": 11.983,
              "speed3d": 13.85,
              "msFromStart": 113224.41290643273
            },
            {
              "lat": 43.77860078571428,
              "lng": -116.09086888571429,
              "alt": 1766.385857142857,
              "speed2d": 11.576999999999998,
              "speed3d": 13.5,
              "msFromStart": 113718.90652631575
            }
          ]
        }
      ]
    },
    {
      "id": "trail-superior",
      "pathType": "trail",
      "name": "Superior",
      "difficulty": "black",
      "videos": [
        {
          "videoId": "GX010086",
          "pathId": "trail-superior",
          "startSec": 10,
          "endSec": 110,
          "id": "video-segment-53",
          "gps": [
            {
              "lat": 43.77616592222222,
              "lng": -116.09475103333334,
              "alt": 2143.046,
              "speed2d": 1.2081111111111111,
              "speed3d": 1.2344444444444442,
              "msFromStart": 10231.218934697852
            },
            {
              "lat": 43.77616904444446,
              "lng": -116.09475545555557,
              "alt": 2143.026444444444,
              "speed2d": 1.0791111111111111,
              "speed3d": 1.1233333333333333,
              "msFromStart": 10727.477684210517
            },
            {
              "lat": 43.77617296,
              "lng": -116.09476079,
              "alt": 2143.0209000000004,
              "speed2d": 1.0924999999999998,
              "speed3d": 1.097,
              "msFromStart": 11250.876209649115
            },
            {
              "lat": 43.776177077777774,
              "lng": -116.09476675555555,
              "alt": 2142.986,
              "speed2d": 1.1797777777777774,
              "speed3d": 1.1755555555555557,
              "msFromStart": 11772.668722222215
            },
            {
              "lat": 43.77618463333334,
              "lng": -116.09477355555556,
              "alt": 2143.103111111111,
              "speed2d": 1.653,
              "speed3d": 1.59,
              "msFromStart": 12266.771148148146
            },
            {
              "lat": 43.776194555555556,
              "lng": -116.09478002222222,
              "alt": 2143.176,
              "speed2d": 2.0755555555555554,
              "speed3d": 2.0566666666666666,
              "msFromStart": 12760.620944444441
            },
            {
              "lat": 43.776206611111114,
              "lng": -116.09478573333334,
              "alt": 2143.2564444444442,
              "speed2d": 2.3724444444444446,
              "speed3d": 2.3266666666666667,
              "msFromStart": 13255.284092592594
            },
            {
              "lat": 43.77622305555555,
              "lng": -116.09479503333333,
              "alt": 2143.578111111111,
              "speed2d": 3.081,
              "speed3d": 3.0077777777777777,
              "msFromStart": 13752.191000000006
            },
            {
              "lat": 43.77623633333333,
              "lng": -116.09480636666666,
              "alt": 2143.655,
              "speed2d": 3.4505555555555554,
              "speed3d": 3.447777777777778,
              "msFromStart": 14248.314685185189
            },
            {
              "lat": 43.77624598888889,
              "lng": -116.09482196666666,
              "alt": 2143.6186666666667,
              "speed2d": 3.4626666666666663,
              "speed3d": 3.474444444444445,
              "msFromStart": 14741.99194444445
            },
            {
              "lat": 43.776255266666666,
              "lng": -116.09483776666666,
              "alt": 2143.380777777778,
              "speed2d": 3.522444444444444,
              "speed3d": 3.517777777777778,
              "msFromStart": 15235.882004873296
            },
            {
              "lat": 43.776268277777774,
              "lng": -116.09485109999997,
              "alt": 2143.219222222222,
              "speed2d": 3.5415555555555556,
              "speed3d": 3.5355555555555553,
              "msFromStart": 15730.699157894742
            },
            {
              "lat": 43.7762817,
              "lng": -116.09486023333334,
              "alt": 2142.8366666666666,
              "speed2d": 3.331666666666667,
              "speed3d": 3.3644444444444446,
              "msFromStart": 16225.747522417158
            },
            {
              "lat": 43.77629992,
              "lng": -116.09486205,
              "alt": 2142.5910999999996,
              "speed2d": 3.4102999999999994,
              "speed3d": 3.382,
              "msFromStart": 16749.07283333333
            },
            {
              "lat": 43.77632093333333,
              "lng": -116.0948571111111,
              "alt": 2142.3464444444444,
              "speed2d": 3.8536666666666677,
              "speed3d": 3.787777777777778,
              "msFromStart": 17272.197333333337
            },
            {
              "lat": 43.77634303333333,
              "lng": -116.0948403222222,
              "alt": 2142.1035555555554,
              "speed2d": 4.632777777777778,
              "speed3d": 4.532222222222222,
              "msFromStart": 17767.03144444446
            },
            {
              "lat": 43.77636814444444,
              "lng": -116.09481946666665,
              "alt": 2141.822111111111,
              "speed2d": 5.546777777777778,
              "speed3d": 5.4944444444444445,
              "msFromStart": 18261.73700000001
            },
            {
              "lat": 43.776395855555556,
              "lng": -116.09480764444444,
              "alt": 2141.385,
              "speed2d": 5.983888888888888,
              "speed3d": 5.931111111111111,
              "msFromStart": 18756.199777777787
            },
            {
              "lat": 43.77642683333333,
              "lng": -116.09481191111111,
              "alt": 2140.2166666666667,
              "speed2d": 6.5278888888888895,
              "speed3d": 6.496666666666667,
              "msFromStart": 19250.760740740738
            },
            {
              "lat": 43.776461233333336,
              "lng": -116.09483045555554,
              "alt": 2138.856777777778,
              "speed2d": 7.588222222222223,
              "speed3d": 7.521111111111112,
              "msFromStart": 19745.622888888873
            },
            {
              "lat": 43.776494911111115,
              "lng": -116.09484013333335,
              "alt": 2137.9930000000004,
              "speed2d": 7.504111111111111,
              "speed3d": 7.578888888888889,
              "msFromStart": 20240.69274074073
            },
            {
              "lat": 43.77652901111111,
              "lng": -116.09484218888888,
              "alt": 2137.203666666667,
              "speed2d": 7.656777777777776,
              "speed3d": 7.676666666666667,
              "msFromStart": 20736.283333333326
            },
            {
              "lat": 43.77656247777778,
              "lng": -116.09483484444445,
              "alt": 2136.385,
              "speed2d": 7.636666666666666,
              "speed3d": 7.694444444444444,
              "msFromStart": 21231.6374619883
            },
            {
              "lat": 43.776594277777775,
              "lng": -116.09481014444442,
              "alt": 2135.163,
              "speed2d": 7.873777777777779,
              "speed3d": 7.918888888888889,
              "msFromStart": 21726.27536842104
            },
            {
              "lat": 43.77662876,
              "lng": -116.09480021,
              "alt": 2133.2631,
              "speed2d": 8.0528,
              "speed3d": 8.14,
              "msFromStart": 22249.076185964903
            },
            {
              "lat": 43.776660722222225,
              "lng": -116.09480343333331,
              "alt": 2131.2905555555553,
              "speed2d": 7.840777777777777,
              "speed3d": 8.064444444444446,
              "msFromStart": 22774.217555555555
            },
            {
              "lat": 43.77668432222222,
              "lng": -116.0948121,
              "alt": 2129.8797777777777,
              "speed2d": 7.190555555555555,
              "speed3d": 7.401111111111112,
              "msFromStart": 23271.208296296296
            },
            {
              "lat": 43.77671562222221,
              "lng": -116.09480374444445,
              "alt": 2128.291888888889,
              "speed2d": 7.314888888888888,
              "speed3d": 7.4655555555555555,
              "msFromStart": 23766.279333333325
            },
            {
              "lat": 43.776750622222224,
              "lng": -116.0947846,
              "alt": 2127.1054444444444,
              "speed2d": 7.744555555555556,
              "speed3d": 7.917777777777777,
              "msFromStart": 24261.08133333333
            },
            {
              "lat": 43.776780622222226,
              "lng": -116.09476435555555,
              "alt": 2125.3287777777778,
              "speed2d": 7.965666666666666,
              "speed3d": 8.155555555555555,
              "msFromStart": 24755.427333333322
            },
            {
              "lat": 43.776817244444445,
              "lng": -116.09476203333332,
              "alt": 2123.942888888889,
              "speed2d": 8.159666666666666,
              "speed3d": 8.378888888888888,
              "msFromStart": 25249.502777777776
            },
            {
              "lat": 43.776846911111114,
              "lng": -116.0947657,
              "alt": 2122.714,
              "speed2d": 7.730666666666667,
              "speed3d": 8.036666666666667,
              "msFromStart": 25742.909
            },
            {
              "lat": 43.77687265555555,
              "lng": -116.09476674444443,
              "alt": 2120.8722222222223,
              "speed2d": 7.225333333333333,
              "speed3d": 7.5600000000000005,
              "msFromStart": 26236.773070175437
            },
            {
              "lat": 43.776900811111105,
              "lng": -116.09475685555554,
              "alt": 2119.4173333333333,
              "speed2d": 6.998333333333333,
              "speed3d": 7.287777777777778,
              "msFromStart": 26731.975473684215
            },
            {
              "lat": 43.77693772222223,
              "lng": -116.0947375222222,
              "alt": 2118.135888888889,
              "speed2d": 7.686888888888891,
              "speed3d": 7.878888888888889,
              "msFromStart": 27227.411863547757
            },
            {
              "lat": 43.77698337999999,
              "lng": -116.09471116999998,
              "alt": 2117.1342,
              "speed2d": 8.562100000000001,
              "speed3d": 8.775,
              "msFromStart": 27751.06666666665
            },
            {
              "lat": 43.777022966666664,
              "lng": -116.09469135555554,
              "alt": 2115.632111111111,
              "speed2d": 8.42,
              "speed3d": 8.758888888888889,
              "msFromStart": 28273.984222222214
            },
            {
              "lat": 43.77705853333333,
              "lng": -116.09468887777778,
              "alt": 2113.8638888888886,
              "speed2d": 8.001888888888887,
              "speed3d": 8.31111111111111,
              "msFromStart": 28767.1588888889
            },
            {
              "lat": 43.77709978888888,
              "lng": -116.09469,
              "alt": 2112.1675555555557,
              "speed2d": 8.493666666666666,
              "speed3d": 8.767777777777779,
              "msFromStart": 29260.799888888894
            },
            {
              "lat": 43.77713898888889,
              "lng": -116.0946882111111,
              "alt": 2110.218888888889,
              "speed2d": 8.726666666666667,
              "speed3d": 9.032222222222224,
              "msFromStart": 29756.017888888895
            },
            {
              "lat": 43.77717999999999,
              "lng": -116.09468023333335,
              "alt": 2107.9286666666667,
              "speed2d": 9.136777777777777,
              "speed3d": 9.439999999999998,
              "msFromStart": 30251.17966666667
            },
            {
              "lat": 43.777219711111115,
              "lng": -116.09466207777778,
              "alt": 2105.845444444444,
              "speed2d": 9.40311111111111,
              "speed3d": 9.782222222222224,
              "msFromStart": 30745.96355555555
            },
            {
              "lat": 43.77725253333334,
              "lng": -116.09464087777776,
              "alt": 2103.7061111111107,
              "speed2d": 9.068111111111111,
              "speed3d": 9.555555555555554,
              "msFromStart": 31240.789968810914
            },
            {
              "lat": 43.777266966666666,
              "lng": -116.09461404444443,
              "alt": 2101.610222222222,
              "speed2d": 7.6608888888888895,
              "speed3d": 8.332222222222223,
              "msFromStart": 31735.785789473695
            },
            {
              "lat": 43.777278833333334,
              "lng": -116.09458497777777,
              "alt": 2099.72,
              "speed2d": 6.489777777777778,
              "speed3d": 7.181111111111111,
              "msFromStart": 32230.889093567257
            },
            {
              "lat": 43.777299255555555,
              "lng": -116.09456637777778,
              "alt": 2097.957,
              "speed2d": 5.713111111111112,
              "speed3d": 6.488888888888889,
              "msFromStart": 32726.386666666676
            },
            {
              "lat": 43.77732291000001,
              "lng": -116.09457289999999,
              "alt": 2094.8516,
              "speed2d": 5.2768,
              "speed3d": 6.113,
              "msFromStart": 33249.49106666668
            },
            {
              "lat": 43.77734628888889,
              "lng": -116.09459532222222,
              "alt": 2091.8897777777775,
              "speed2d": 5.330222222222221,
              "speed3d": 6.224444444444445,
              "msFromStart": 33772.71711111115
            },
            {
              "lat": 43.77736184444444,
              "lng": -116.09461588888888,
              "alt": 2089.4776666666667,
              "speed2d": 5.2684444444444445,
              "speed3d": 6.199999999999999,
              "msFromStart": 34268.01170370373
            },
            {
              "lat": 43.777375733333336,
              "lng": -116.09463779999999,
              "alt": 2087.5505555555555,
              "speed2d": 5.233333333333333,
              "speed3d": 6.227777777777777,
              "msFromStart": 34762.21133333336
            },
            {
              "lat": 43.7773899111111,
              "lng": -116.09465384444444,
              "alt": 2085.911,
              "speed2d": 4.742111111111112,
              "speed3d": 5.8133333333333335,
              "msFromStart": 35256.64454970761
            },
            {
              "lat": 43.77740328888889,
              "lng": -116.09466250000001,
              "alt": 2083.688888888889,
              "speed2d": 4.325111111111111,
              "speed3d": 5.493333333333332,
              "msFromStart": 35751.86821052633
            },
            {
              "lat": 43.77740457777778,
              "lng": -116.09466403333334,
              "alt": 2079.7865555555554,
              "speed2d": 4.218555555555556,
              "speed3d": 5.420000000000001,
              "msFromStart": 36247.13509161794
            },
            {
              "lat": 43.777402800000004,
              "lng": -116.09466204444445,
              "alt": 2075.3198888888887,
              "speed2d": 4.206444444444445,
              "speed3d": 5.541111111111111,
              "msFromStart": 36742.408444444474
            },
            {
              "lat": 43.77740066666667,
              "lng": -116.09465590000002,
              "alt": 2071.746444444445,
              "speed2d": 3.921333333333333,
              "speed3d": 5.382222222222223,
              "msFromStart": 37237.56874074077
            },
            {
              "lat": 43.77740071111111,
              "lng": -116.09464805555555,
              "alt": 2068.622111111111,
              "speed2d": 3.6983333333333337,
              "speed3d": 5.2155555555555555,
              "msFromStart": 37732.274222222244
            },
            {
              "lat": 43.777402655555555,
              "lng": -116.09463674444444,
              "alt": 2066.076555555556,
              "speed2d": 3.7381111111111114,
              "speed3d": 5.194444444444445,
              "msFromStart": 38227.25125925927
            },
            {
              "lat": 43.77740389,
              "lng": -116.09462285000001,
              "alt": 2063.1607000000004,
              "speed2d": 3.635,
              "speed3d": 5.123000000000001,
              "msFromStart": 38751.07266666667
            },
            {
              "lat": 43.77740747777777,
              "lng": -116.09462078888888,
              "alt": 2059.439111111111,
              "speed2d": 3.118555555555556,
              "speed3d": 4.867777777777778,
              "msFromStart": 39274.42355555555
            },
            {
              "lat": 43.77740987777777,
              "lng": -116.09462031111111,
              "alt": 2057.076111111111,
              "speed2d": 2.462555555555556,
              "speed3d": 4.403333333333333,
              "msFromStart": 39768.57688888887
            },
            {
              "lat": 43.77741225555555,
              "lng": -116.09462808888888,
              "alt": 2054.8624444444445,
              "speed2d": 2.3211111111111116,
              "speed3d": 4.181111111111111,
              "msFromStart": 40263.04960623781
            },
            {
              "lat": 43.777414577777776,
              "lng": -116.09463887777778,
              "alt": 2052.146111111111,
              "speed2d": 2.6581111111111113,
              "speed3d": 4.372222222222223,
              "msFromStart": 40758.62084210528
            },
            {
              "lat": 43.77741538888889,
              "lng": -116.09464634444444,
              "alt": 2050.592888888889,
              "speed2d": 2.5454444444444446,
              "speed3d": 4.231111111111111,
              "msFromStart": 41254.02155165693
            },
            {
              "lat": 43.777413700000004,
              "lng": -116.09465714444444,
              "alt": 2048.3455555555556,
              "speed2d": 2.505,
              "speed3d": 4.171111111111111,
              "msFromStart": 41748.50888888892
            },
            {
              "lat": 43.777417566666664,
              "lng": -116.09466557777779,
              "alt": 2045.4796666666668,
              "speed2d": 2.5282222222222224,
              "speed3d": 4.204444444444444,
              "msFromStart": 42243.29859259262
            },
            {
              "lat": 43.777428722222226,
              "lng": -116.09466644444444,
              "alt": 2042.2992222222222,
              "speed2d": 2.950111111111111,
              "speed3d": 4.523333333333333,
              "msFromStart": 42739.66311111114
            },
            {
              "lat": 43.777439333333334,
              "lng": -116.09466411111113,
              "alt": 2039.4583333333335,
              "speed2d": 3.402222222222222,
              "speed3d": 4.858888888888889,
              "msFromStart": 43235.806962962975
            },
            {
              "lat": 43.777454311111114,
              "lng": -116.09467051111113,
              "alt": 2036.5681111111112,
              "speed2d": 4.362333333333334,
              "speed3d": 5.550000000000001,
              "msFromStart": 43730.438222222205
            },
            {
              "lat": 43.77747742,
              "lng": -116.09467807000001,
              "alt": 2034.0384999999999,
              "speed2d": 5.4796,
              "speed3d": 6.469,
              "msFromStart": 44252.09533333333
            },
            {
              "lat": 43.77750205555555,
              "lng": -116.09467416666668,
              "alt": 2031.5626666666667,
              "speed2d": 5.980555555555556,
              "speed3d": 6.996666666666666,
              "msFromStart": 44772.80044444444
            },
            {
              "lat": 43.777529277777774,
              "lng": -116.09465763333333,
              "alt": 2029.9115555555559,
              "speed2d": 6.317666666666668,
              "speed3d": 7.243333333333334,
              "msFromStart": 45266.98248732942
            },
            {
              "lat": 43.777563522222216,
              "lng": -116.09463242222223,
              "alt": 2028.9714444444446,
              "speed2d": 7.171444444444443,
              "speed3d": 7.998888888888889,
              "msFromStart": 45763.77578947366
            },
            {
              "lat": 43.777594577777776,
              "lng": -116.09460841111111,
              "alt": 2027.7681111111108,
              "speed2d": 6.902444444444445,
              "speed3d": 7.861111111111112,
              "msFromStart": 46259.941204678355
            },
            {
              "lat": 43.777622,
              "lng": -116.09459555555556,
              "alt": 2025.9066666666665,
              "speed2d": 6.477555555555555,
              "speed3d": 7.468888888888889,
              "msFromStart": 46753.04533333334
            },
            {
              "lat": 43.777648722222224,
              "lng": -116.09459795555556,
              "alt": 2023.634,
              "speed2d": 6.178111111111111,
              "speed3d": 7.345555555555556,
              "msFromStart": 47246.424074074064
            },
            {
              "lat": 43.7776684,
              "lng": -116.09460232222223,
              "alt": 2021.6564444444443,
              "speed2d": 5.462,
              "speed3d": 6.783333333333333,
              "msFromStart": 47742.12577777775
            },
            {
              "lat": 43.77769272222223,
              "lng": -116.09460183333334,
              "alt": 2019.0571111111112,
              "speed2d": 5.578111111111111,
              "speed3d": 6.838888888888889,
              "msFromStart": 48237.89718518517
            },
            {
              "lat": 43.77772293333333,
              "lng": -116.09459176666668,
              "alt": 2016.6158888888888,
              "speed2d": 6.258,
              "speed3d": 7.468888888888889,
              "msFromStart": 48733.01822222219
            },
            {
              "lat": 43.77775328888889,
              "lng": -116.09456851111112,
              "alt": 2016.0274444444442,
              "speed2d": 6.400666666666667,
              "speed3d": 7.527777777777779,
              "msFromStart": 49228.105703703695
            },
            {
              "lat": 43.77779338999999,
              "lng": -116.09454093000002,
              "alt": 2016.2389,
              "speed2d": 6.8721000000000005,
              "speed3d": 7.808,
              "msFromStart": 49750.831666666694
            },
            {
              "lat": 43.777826399999995,
              "lng": -116.09451215555556,
              "alt": 2015.5661111111112,
              "speed2d": 6.778888888888889,
              "speed3d": 7.748888888888889,
              "msFromStart": 50273.6562962963
            },
            {
              "lat": 43.77785474444444,
              "lng": -116.09449000000001,
              "alt": 2014.1845555555558,
              "speed2d": 6.135666666666667,
              "speed3d": 7.187777777777778,
              "msFromStart": 50769.201999999976
            },
            {
              "lat": 43.7778827,
              "lng": -116.09447647777777,
              "alt": 2011.9675555555555,
              "speed2d": 5.702222222222222,
              "speed3d": 6.82,
              "msFromStart": 51264.52936842104
            },
            {
              "lat": 43.777916033333334,
              "lng": -116.09446983333335,
              "alt": 2009.6363333333334,
              "speed2d": 6.044777777777778,
              "speed3d": 7.130000000000001,
              "msFromStart": 51759.23073684211
            },
            {
              "lat": 43.77795495555556,
              "lng": -116.0944708777778,
              "alt": 2008.7618888888887,
              "speed2d": 6.236555555555556,
              "speed3d": 7.32111111111111,
              "msFromStart": 52254.05352436648
            },
            {
              "lat": 43.77798308888889,
              "lng": -116.09447933333334,
              "alt": 2008.0713333333333,
              "speed2d": 5.615555555555555,
              "speed3d": 6.776666666666666,
              "msFromStart": 52749.493777777796
            },
            {
              "lat": 43.77800081111111,
              "lng": -116.0944924222222,
              "alt": 2007.5275555555554,
              "speed2d": 4.722444444444444,
              "speed3d": 5.988888888888888,
              "msFromStart": 53244.85911111112
            },
            {
              "lat": 43.77800886666667,
              "lng": -116.09450064444445,
              "alt": 2008.8613333333333,
              "speed2d": 3.1921111111111107,
              "speed3d": 4.580000000000001,
              "msFromStart": 53739.67777777777
            },
            {
              "lat": 43.77801067777777,
              "lng": -116.09451217777777,
              "alt": 2009.555,
              "speed2d": 2.794111111111111,
              "speed3d": 3.878888888888889,
              "msFromStart": 54234.392148148145
            },
            {
              "lat": 43.778013211111116,
              "lng": -116.09452514444443,
              "alt": 2008.5204444444448,
              "speed2d": 2.692,
              "speed3d": 3.697777777777778,
              "msFromStart": 54728.90800000003
            },
            {
              "lat": 43.778017639999995,
              "lng": -116.09453977,
              "alt": 2006.7143,
              "speed2d": 2.5456999999999996,
              "speed3d": 3.603,
              "msFromStart": 55251.18989473685
            },
            {
              "lat": 43.77803202222223,
              "lng": -116.09454862222222,
              "alt": 2006.5156666666667,
              "speed2d": 2.3633333333333333,
              "speed3d": 3.4188888888888886,
              "msFromStart": 55774.48821052632
            },
            {
              "lat": 43.77804474444445,
              "lng": -116.09455721111112,
              "alt": 2006.428111111111,
              "speed2d": 2.3004444444444445,
              "speed3d": 3.3144444444444447,
              "msFromStart": 56270.201461988305
            },
            {
              "lat": 43.77805224444444,
              "lng": -116.0945658,
              "alt": 2005.8113333333333,
              "speed2d": 2.3178888888888887,
              "speed3d": 3.261111111111111,
              "msFromStart": 56765.4026666667
            },
            {
              "lat": 43.77805225555555,
              "lng": -116.09457601111112,
              "alt": 2003.8264444444446,
              "speed2d": 2.151666666666667,
              "speed3d": 3.215555555555556,
              "msFromStart": 57260.30414814816
            },
            {
              "lat": 43.77805341111111,
              "lng": -116.09458752222221,
              "alt": 2001.1947777777777,
              "speed2d": 2.264666666666667,
              "speed3d": 3.333333333333333,
              "msFromStart": 57754.21155555552
            },
            {
              "lat": 43.77805457777777,
              "lng": -116.09460258888888,
              "alt": 1998.6801111111113,
              "speed2d": 2.7222222222222223,
              "speed3d": 3.7233333333333327,
              "msFromStart": 58248.45955555554
            },
            {
              "lat": 43.77806344444444,
              "lng": -116.09462254444443,
              "alt": 1996.2169999999999,
              "speed2d": 3.4651111111111113,
              "speed3d": 4.376666666666667,
              "msFromStart": 58744.46755555556
            },
            {
              "lat": 43.778076411111115,
              "lng": -116.09463774444447,
              "alt": 1993.9632222222224,
              "speed2d": 3.535888888888889,
              "speed3d": 4.596666666666667,
              "msFromStart": 59240.364888888886
            },
            {
              "lat": 43.77808905555556,
              "lng": -116.09464602222224,
              "alt": 1991.7258888888891,
              "speed2d": 3.2936666666666667,
              "speed3d": 4.507777777777777,
              "msFromStart": 59735.115555555574
            },
            {
              "lat": 43.77809925555556,
              "lng": -116.09465010000002,
              "alt": 1989.3168888888886,
              "speed2d": 3.040222222222222,
              "speed3d": 4.368888888888889,
              "msFromStart": 60229.66229629631
            },
            {
              "lat": 43.77810855,
              "lng": -116.09464766999999,
              "alt": 1986.6339999999998,
              "speed2d": 2.7484,
              "speed3d": 4.214,
              "msFromStart": 60751.30500000003
            },
            {
              "lat": 43.77812282222222,
              "lng": -116.09463707777776,
              "alt": 1984.424,
              "speed2d": 2.7362222222222226,
              "speed3d": 4.166666666666666,
              "msFromStart": 61273.2143508772
            },
            {
              "lat": 43.77814133333334,
              "lng": -116.09463771111112,
              "alt": 1982.086111111111,
              "speed2d": 3.1598888888888887,
              "speed3d": 4.45,
              "msFromStart": 61768.49136842106
            },
            {
              "lat": 43.77816978888889,
              "lng": -116.09464985555556,
              "alt": 1979.0426666666667,
              "speed2d": 4.035,
              "speed3d": 5.201111111111111,
              "msFromStart": 62264.03228070175
            },
            {
              "lat": 43.77819423333333,
              "lng": -116.0946590222222,
              "alt": 1976.4523333333336,
              "speed2d": 4.493,
              "speed3d": 5.647777777777778,
              "msFromStart": 62760.45599999998
            },
            {
              "lat": 43.778211,
              "lng": -116.09466887777776,
              "alt": 1973.525666666667,
              "speed2d": 4.633333333333332,
              "speed3d": 5.828888888888891,
              "msFromStart": 63256.39837037037
            },
            {
              "lat": 43.77822027777778,
              "lng": -116.0946775,
              "alt": 1969.8933333333334,
              "speed2d": 4.502000000000001,
              "speed3d": 5.806666666666667,
              "msFromStart": 63750.06222222226
            },
            {
              "lat": 43.77822915555556,
              "lng": -116.09468539999999,
              "alt": 1964.2215555555558,
              "speed2d": 4.536333333333333,
              "speed3d": 6.018888888888889,
              "msFromStart": 64243.71266666669
            },
            {
              "lat": 43.778235055555555,
              "lng": -116.09469364444445,
              "alt": 1959.6082222222221,
              "speed2d": 4.344777777777778,
              "speed3d": 6.028888888888888,
              "msFromStart": 64738.22088888889
            },
            {
              "lat": 43.778240044444445,
              "lng": -116.09469916666666,
              "alt": 1955.0788888888892,
              "speed2d": 4.114222222222223,
              "speed3d": 5.99,
              "msFromStart": 65233.22970370371
            },
            {
              "lat": 43.77824881111111,
              "lng": -116.09469465555556,
              "alt": 1950.8011111111111,
              "speed2d": 3.949333333333333,
              "speed3d": 5.972222222222222,
              "msFromStart": 65729.89777777778
            },
            {
              "lat": 43.77826764444445,
              "lng": -116.09467815555554,
              "alt": 1947.433,
              "speed2d": 4.121,
              "speed3d": 6.112222222222221,
              "msFromStart": 66226.32548148149
            },
            {
              "lat": 43.778297959999996,
              "lng": -116.09463350999998,
              "alt": 1944.5112,
              "speed2d": 4.9519,
              "speed3d": 6.688,
              "msFromStart": 66748.60800000007
            },
            {
              "lat": 43.778316722222215,
              "lng": -116.09461478888889,
              "alt": 1940.9427777777778,
              "speed2d": 5.276222222222222,
              "speed3d": 7.03,
              "msFromStart": 67270.7346666667
            },
            {
              "lat": 43.778332488888886,
              "lng": -116.0946128222222,
              "alt": 1937.005222222222,
              "speed2d": 5.301777777777778,
              "speed3d": 7.12,
              "msFromStart": 67765.40800000005
            },
            {
              "lat": 43.77836124444444,
              "lng": -116.09461676666668,
              "alt": 1933.3266666666668,
              "speed2d": 5.6866666666666665,
              "speed3d": 7.434444444444445,
              "msFromStart": 68260.22770370374
            },
            {
              "lat": 43.77839590000001,
              "lng": -116.0946228777778,
              "alt": 1930.536222222222,
              "speed2d": 6.116666666666666,
              "speed3d": 7.803333333333333,
              "msFromStart": 68755.62222222227
            },
            {
              "lat": 43.77841973333333,
              "lng": -116.09462864444444,
              "alt": 1927.734888888889,
              "speed2d": 6.058222222222223,
              "speed3d": 7.803333333333334,
              "msFromStart": 69251.12311111114
            },
            {
              "lat": 43.77843483333333,
              "lng": -116.09463343333334,
              "alt": 1925.581111111111,
              "speed2d": 5.616,
              "speed3d": 7.4688888888888885,
              "msFromStart": 69746.81955555553
            },
            {
              "lat": 43.77844871111111,
              "lng": -116.09464065555554,
              "alt": 1923.3231111111108,
              "speed2d": 5.189444444444444,
              "speed3d": 7.103333333333334,
              "msFromStart": 70242.19664717348
            },
            {
              "lat": 43.77847036666667,
              "lng": -116.09465456666668,
              "alt": 1920.5657777777778,
              "speed2d": 5.148999999999999,
              "speed3d": 7.040000000000001,
              "msFromStart": 70736.21810526316
            },
            {
              "lat": 43.7784933,
              "lng": -116.09469396666667,
              "alt": 1917.4378888888887,
              "speed2d": 5.331999999999999,
              "speed3d": 7.181111111111112,
              "msFromStart": 71230.19808706953
            },
            {
              "lat": 43.77851328,
              "lng": -116.09473634000001,
              "alt": 1914.0694,
              "speed2d": 5.4649,
              "speed3d": 7.324,
              "msFromStart": 71752.23244444447
            },
            {
              "lat": 43.77853177777778,
              "lng": -116.09476497777777,
              "alt": 1910.5906666666665,
              "speed2d": 5.363888888888889,
              "speed3d": 7.286666666666668,
              "msFromStart": 72274.59274074076
            },
            {
              "lat": 43.77854406666667,
              "lng": -116.09477132222223,
              "alt": 1905.8313333333335,
              "speed2d": 5.114333333333333,
              "speed3d": 7.205555555555555,
              "msFromStart": 72770.40000000005
            },
            {
              "lat": 43.778527966666665,
              "lng": -116.09475958888888,
              "alt": 1897.893222222222,
              "speed2d": 4.279111111111111,
              "speed3d": 6.846666666666667,
              "msFromStart": 73265.90370370372
            },
            {
              "lat": 43.77851453333333,
              "lng": -116.0947527,
              "alt": 1890.7866666666669,
              "speed2d": 3.6982222222222227,
              "speed3d": 6.617777777777778,
              "msFromStart": 73759.82222222217
            },
            {
              "lat": 43.778530688888885,
              "lng": -116.09475666666668,
              "alt": 1886.4907777777778,
              "speed2d": 3.8453333333333335,
              "speed3d": 6.736666666666666,
              "msFromStart": 74253.93703703702
            },
            {
              "lat": 43.778558644444445,
              "lng": -116.0947672111111,
              "alt": 1882.5576666666666,
              "speed2d": 4.2411111111111115,
              "speed3d": 6.993333333333334,
              "msFromStart": 74749.47111111117
            },
            {
              "lat": 43.77859243333333,
              "lng": -116.09478438888888,
              "alt": 1878.9194444444443,
              "speed2d": 4.748444444444443,
              "speed3d": 7.325555555555555,
              "msFromStart": 75245.04118518521
            },
            {
              "lat": 43.77862742222222,
              "lng": -116.09481314444444,
              "alt": 1874.9587777777779,
              "speed2d": 5.405666666666667,
              "speed3d": 7.801111111111111,
              "msFromStart": 75740.18755555555
            },
            {
              "lat": 43.77866428888889,
              "lng": -116.09484037777777,
              "alt": 1871.5054444444445,
              "speed2d": 5.9978888888888875,
              "speed3d": 8.244444444444444,
              "msFromStart": 76235.33826120856
            },
            {
              "lat": 43.77870393333333,
              "lng": -116.09487113333333,
              "alt": 1868.2545555555557,
              "speed2d": 6.511111111111112,
              "speed3d": 8.625555555555557,
              "msFromStart": 76730.6757894737
            },
            {
              "lat": 43.77874176666667,
              "lng": -116.09491398888889,
              "alt": 1864.971,
              "speed2d": 7.0248888888888885,
              "speed3d": 9.043333333333333,
              "msFromStart": 77225.99691228072
            },
            {
              "lat": 43.778777989999995,
              "lng": -116.09494839999999,
              "alt": 1861.9972000000002,
              "speed2d": 7.144399999999999,
              "speed3d": 9.174,
              "msFromStart": 77748.60800000002
            },
            {
              "lat": 43.778820599999996,
              "lng": -116.09496363333334,
              "alt": 1859.3323333333333,
              "speed2d": 7.1594444444444445,
              "speed3d": 9.161111111111111,
              "msFromStart": 78270.96844444446
            },
            {
              "lat": 43.77886572222222,
              "lng": -116.09496943333332,
              "alt": 1857.0454444444445,
              "speed2d": 7.220666666666666,
              "speed3d": 9.206666666666667,
              "msFromStart": 78764.9626666667
            },
            {
              "lat": 43.778913333333335,
              "lng": -116.09496881111112,
              "alt": 1855.0971111111112,
              "speed2d": 7.322333333333333,
              "speed3d": 9.252222222222223,
              "msFromStart": 79259.00548148152
            },
            {
              "lat": 43.778961188888886,
              "lng": -116.09496412222224,
              "alt": 1853.6441111111112,
              "speed2d": 7.482333333333332,
              "speed3d": 9.346666666666668,
              "msFromStart": 79753.58755555558
            },
            {
              "lat": 43.77900705555555,
              "lng": -116.09496827777777,
              "alt": 1851.9535555555556,
              "speed2d": 7.664222222222222,
              "speed3d": 9.47,
              "msFromStart": 80248.50948148148
            },
            {
              "lat": 43.77904735555555,
              "lng": -116.09497687777777,
              "alt": 1850.0241111111109,
              "speed2d": 7.684444444444445,
              "speed3d": 9.476666666666668,
              "msFromStart": 80744.57511111113
            },
            {
              "lat": 43.77908784444444,
              "lng": -116.09499506666666,
              "alt": 1847.5662222222222,
              "speed2d": 7.764444444444443,
              "speed3d": 9.529999999999998,
              "msFromStart": 81240.64396101364
            },
            {
              "lat": 43.77912490000001,
              "lng": -116.09501921111111,
              "alt": 1844.659666666667,
              "speed2d": 7.751444444444445,
              "speed3d": 9.545555555555556,
              "msFromStart": 81736.26821052635
            },
            {
              "lat": 43.77915949999999,
              "lng": -116.09504603333332,
              "alt": 1841.929222222222,
              "speed2d": 7.608666666666666,
              "speed3d": 9.427777777777777,
              "msFromStart": 82231.65543339835
            },
            {
              "lat": 43.77919495555555,
              "lng": -116.09507583333334,
              "alt": 1839.3283333333334,
              "speed2d": 7.526555555555555,
              "speed3d": 9.357777777777777,
              "msFromStart": 82725.86799999999
            },
            {
              "lat": 43.77923843999999,
              "lng": -116.09510836,
              "alt": 1836.7922999999998,
              "speed2d": 7.6589,
              "speed3d": 9.443,
              "msFromStart": 83247.53422222221
            },
            {
              "lat": 43.77928824444445,
              "lng": -116.09513522222221,
              "alt": 1835.494777777778,
              "speed2d": 7.797555555555556,
              "speed3d": 9.547777777777776,
              "msFromStart": 83770.13688888891
            },
            {
              "lat": 43.7793285,
              "lng": -116.0951467111111,
              "alt": 1833.9404444444444,
              "speed2d": 7.696,
              "speed3d": 9.47111111111111,
              "msFromStart": 84265.58162962965
            },
            {
              "lat": 43.7793721,
              "lng": -116.09514024444444,
              "alt": 1833.094222222222,
              "speed2d": 7.575777777777777,
              "speed3d": 9.316666666666666,
              "msFromStart": 84761.90933333337
            },
            {
              "lat": 43.779412311111116,
              "lng": -116.09515016666667,
              "alt": 1831.8715555555557,
              "speed2d": 7.607000000000001,
              "speed3d": 9.30888888888889,
              "msFromStart": 85258.04459259259
            },
            {
              "lat": 43.77945394444445,
              "lng": -116.09516767777777,
              "alt": 1830.362,
              "speed2d": 7.811000000000001,
              "speed3d": 9.433333333333334,
              "msFromStart": 85753.05688888881
            },
            {
              "lat": 43.77949926666666,
              "lng": -116.09518956666665,
              "alt": 1829.0756666666666,
              "speed2d": 8.01388888888889,
              "speed3d": 9.613333333333333,
              "msFromStart": 86247.70453021438
            },
            {
              "lat": 43.77954098888889,
              "lng": -116.09520715555556,
              "alt": 1827.5755555555556,
              "speed2d": 7.979999999999999,
              "speed3d": 9.585555555555556,
              "msFromStart": 86741.34273684208
            },
            {
              "lat": 43.779578711111114,
              "lng": -116.09522382222222,
              "alt": 1825.9662222222223,
              "speed2d": 7.841666666666667,
              "speed3d": 9.464444444444444,
              "msFromStart": 87235.04972059777
            },
            {
              "lat": 43.77960407777778,
              "lng": -116.09524104444445,
              "alt": 1823.9515555555558,
              "speed2d": 7.5042222222222215,
              "speed3d": 9.207777777777777,
              "msFromStart": 87729.79199999993
            },
            {
              "lat": 43.77962505555555,
              "lng": -116.09526144444445,
              "alt": 1821.8365555555556,
              "speed2d": 7.202999999999999,
              "speed3d": 8.905555555555557,
              "msFromStart": 88224.79683950613
            },
            {
              "lat": 43.779659020000004,
              "lng": -116.09529057,
              "alt": 1819.0719000000001,
              "speed2d": 7.367599999999999,
              "speed3d": 9.02,
              "msFromStart": 88747.90488888894
            },
            {
              "lat": 43.779693300000005,
              "lng": -116.09532764444444,
              "alt": 1816.1585555555557,
              "speed2d": 7.636333333333333,
              "speed3d": 9.24,
              "msFromStart": 89270.73762962964
            },
            {
              "lat": 43.77972704444445,
              "lng": -116.09535951111113,
              "alt": 1813.3713333333335,
              "speed2d": 7.811111111111112,
              "speed3d": 9.405555555555557,
              "msFromStart": 89764.56088888884
            },
            {
              "lat": 43.77976316666666,
              "lng": -116.09537764444444,
              "alt": 1810.9283333333333,
              "speed2d": 7.738111111111111,
              "speed3d": 9.364444444444445,
              "msFromStart": 90258.36628460037
            },
            {
              "lat": 43.77980226666666,
              "lng": -116.09538202222224,
              "alt": 1808.8061111111112,
              "speed2d": 7.566333333333333,
              "speed3d": 9.208888888888888,
              "msFromStart": 90752.6892631579
            },
            {
              "lat": 43.77984268888889,
              "lng": -116.0953928,
              "alt": 1806.3902222222223,
              "speed2d": 7.634666666666668,
              "speed3d": 9.223333333333333,
              "msFromStart": 91247.18020532811
            },
            {
              "lat": 43.77988260000001,
              "lng": -116.09541643333334,
              "alt": 1803.366888888889,
              "speed2d": 7.958777777777778,
              "speed3d": 9.509999999999998,
              "msFromStart": 91742.37999999999
            },
            {
              "lat": 43.77990704444444,
              "lng": -116.09544188888889,
              "alt": 1799.5927777777779,
              "speed2d": 7.977444444444444,
              "speed3d": 9.557777777777776,
              "msFromStart": 92237.67920987653
            },
            {
              "lat": 43.77992883333334,
              "lng": -116.09546758888891,
              "alt": 1795.929,
              "speed2d": 7.930666666666667,
              "speed3d": 9.54888888888889,
              "msFromStart": 92732.87600000003
            },
            {
              "lat": 43.77993307777777,
              "lng": -116.09549759999999,
              "alt": 1792.1482222222223,
              "speed2d": 7.454888888888887,
              "speed3d": 9.217777777777776,
              "msFromStart": 93227.94735802471
            },
            {
              "lat": 43.77993166,
              "lng": -116.09552876,
              "alt": 1788.5904000000003,
              "speed2d": 6.7538,
              "speed3d": 8.662,
              "msFromStart": 93749.81711111104
            },
            {
              "lat": 43.779939488888886,
              "lng": -116.09556187777775,
              "alt": 1784.9766666666665,
              "speed2d": 6.454555555555556,
              "speed3d": 8.402222222222223,
              "msFromStart": 94271.76266666663
            },
            {
              "lat": 43.77995484444444,
              "lng": -116.09559399999999,
              "alt": 1781.3628888888888,
              "speed2d": 6.439777777777778,
              "speed3d": 8.411111111111111,
              "msFromStart": 94766.98488888891
            },
            {
              "lat": 43.77996857777778,
              "lng": -116.09562206666666,
              "alt": 1777.4872222222223,
              "speed2d": 6.283444444444445,
              "speed3d": 8.324444444444444,
              "msFromStart": 95262.30311111113
            },
            {
              "lat": 43.77997945555555,
              "lng": -116.0956508,
              "alt": 1773.3473333333334,
              "speed2d": 6.0824444444444445,
              "speed3d": 8.217777777777778,
              "msFromStart": 95757.71022222227
            },
            {
              "lat": 43.779990233333336,
              "lng": -116.09566297777778,
              "alt": 1769.2665555555554,
              "speed2d": 5.542,
              "speed3d": 7.866666666666667,
              "msFromStart": 96253.210588694
            },
            {
              "lat": 43.779998,
              "lng": -116.09568618888888,
              "alt": 1765.519888888889,
              "speed2d": 5.196888888888889,
              "speed3d": 7.644444444444445,
              "msFromStart": 96749.04842105269
            },
            {
              "lat": 43.780004600000005,
              "lng": -116.09570946666666,
              "alt": 1762.3093333333334,
              "speed2d": 4.756333333333334,
              "speed3d": 7.362222222222223,
              "msFromStart": 97244.74918778431
            },
            {
              "lat": 43.78001935555555,
              "lng": -116.09573949999998,
              "alt": 1760.1605555555557,
              "speed2d": 4.542222222222223,
              "speed3d": 7.200000000000001,
              "msFromStart": 97739.40799999995
            },
            {
              "lat": 43.78003462222222,
              "lng": -116.09578141111112,
              "alt": 1758.209,
              "speed2d": 4.65211111111111,
              "speed3d": 7.235555555555556,
              "msFromStart": 98234.13204938266
            },
            {
              "lat": 43.78005100000001,
              "lng": -116.09582718888888,
              "alt": 1756.0517777777777,
              "speed2d": 4.966333333333333,
              "speed3d": 7.432222222222222,
              "msFromStart": 98730.29599999997
            },
            {
              "lat": 43.78005815555556,
              "lng": -116.09588204444444,
              "alt": 1753.5782222222222,
              "speed2d": 5.28,
              "speed3d": 7.636666666666667,
              "msFromStart": 99226.69303703701
            },
            {
              "lat": 43.78007495,
              "lng": -116.09593108,
              "alt": 1751.7837,
              "speed2d": 5.589300000000001,
              "speed3d": 7.843000000000001,
              "msFromStart": 99750.6484444444
            },
            {
              "lat": 43.78009991111111,
              "lng": -116.0959698111111,
              "alt": 1749.8462222222224,
              "speed2d": 5.857888888888889,
              "speed3d": 8.01,
              "msFromStart": 100274.09274074071
            },
            {
              "lat": 43.78013246666667,
              "lng": -116.09601475555554,
              "alt": 1747.2784444444444,
              "speed2d": 6.496666666666668,
              "speed3d": 8.446666666666665,
              "msFromStart": 100767.92799999999
            },
            {
              "lat": 43.780172755555554,
              "lng": -116.09607134444445,
              "alt": 1744.2593333333334,
              "speed2d": 7.467777777777777,
              "speed3d": 9.204444444444444,
              "msFromStart": 101261.7943859649
            },
            {
              "lat": 43.78021187777778,
              "lng": -116.09612254444443,
              "alt": 1741.2836666666665,
              "speed2d": 8.15,
              "speed3d": 9.818888888888889,
              "msFromStart": 101756.59831578948
            },
            {
              "lat": 43.78023021111111,
              "lng": -116.09617223333333,
              "alt": 1738.5338888888891,
              "speed2d": 8.198555555555556,
              "speed3d": 9.904444444444444,
              "msFromStart": 102251.47653281353
            },
            {
              "lat": 43.7802443,
              "lng": -116.09621525555556,
              "alt": 1735.3825555555557,
              "speed2d": 8.074,
              "speed3d": 9.813333333333333,
              "msFromStart": 102746.23599999999
            },
            {
              "lat": 43.78025607777777,
              "lng": -116.09624982222222,
              "alt": 1731.768,
              "speed2d": 7.811222222222222,
              "speed3d": 9.632222222222222,
              "msFromStart": 103240.9384691358
            },
            {
              "lat": 43.78027357777778,
              "lng": -116.09626718888887,
              "alt": 1728.5435555555553,
              "speed2d": 7.354,
              "speed3d": 9.295555555555556,
              "msFromStart": 103735.412
            },
            {
              "lat": 43.780300488888884,
              "lng": -116.09626443333332,
              "alt": 1726.5285555555558,
              "speed2d": 6.706666666666667,
              "speed3d": 8.774444444444445,
              "msFromStart": 104229.89639506175
            },
            {
              "lat": 43.78032423999999,
              "lng": -116.09627935,
              "alt": 1724.5301,
              "speed2d": 6.4014999999999995,
              "speed3d": 8.491,
              "msFromStart": 104752.1664444445
            },
            {
              "lat": 43.780350633333335,
              "lng": -116.09630547777778,
              "alt": 1722.4304444444444,
              "speed2d": 6.418666666666667,
              "speed3d": 8.483333333333334,
              "msFromStart": 105274.77155555556
            },
            {
              "lat": 43.780394611111106,
              "lng": -116.09632992222224,
              "alt": 1720.6327777777778,
              "speed2d": 6.8647777777777765,
              "speed3d": 8.765555555555554,
              "msFromStart": 105771.03288888885
            },
            {
              "lat": 43.780456522222224,
              "lng": -116.09636032222222,
              "alt": 1718.9407777777778,
              "speed2d": 7.603111111111111,
              "speed3d": 9.333333333333332,
              "msFromStart": 106267.1119688109
            },
            {
              "lat": 43.780516444444444,
              "lng": -116.09639543333333,
              "alt": 1717.322,
              "speed2d": 8.16,
              "speed3d": 9.79,
              "msFromStart": 106762.00336842108
            },
            {
              "lat": 43.78057315555556,
              "lng": -116.09643007777777,
              "alt": 1715.5655555555554,
              "speed2d": 8.591,
              "speed3d": 10.157777777777778,
              "msFromStart": 107256.80258869397
            },
            {
              "lat": 43.78064856666667,
              "lng": -116.09648720000001,
              "alt": 1715.6366666666665,
              "speed2d": 9.126333333333333,
              "speed3d": 10.540000000000001,
              "msFromStart": 107806.76266666666
            },
            {
              "lat": 43.7807064,
              "lng": -116.09653195555555,
              "alt": 1715.6932222222224,
              "speed2d": 9.361777777777778,
              "speed3d": 10.786666666666667,
              "msFromStart": 108246.68730864197
            },
            {
              "lat": 43.780750677777775,
              "lng": -116.0965612777778,
              "alt": 1714.3727777777776,
              "speed2d": 9.182444444444444,
              "speed3d": 10.630000000000003,
              "msFromStart": 108741.21600000004
            },
            {
              "lat": 43.780796344444454,
              "lng": -116.09658418888888,
              "alt": 1713.1442222222222,
              "speed2d": 8.98622222222222,
              "speed3d": 10.447777777777778,
              "msFromStart": 109235.77481481484
            },
            {
              "lat": 43.78083196666667,
              "lng": -116.09658857777778,
              "alt": 1711.929222222222,
              "speed2d": 8.653666666666668,
              "speed3d": 10.172222222222222,
              "msFromStart": 109730.91199999997
            }
          ]
        }
      ]
    },
    {
      "id": "trail-upper-nugget",
      "pathType": "trail",
      "name": "Upper Nugget",
      "difficulty": "blue",
      "videos": [
        {
          "videoId": "GH010023",
          "pathId": "trail-upper-nugget",
          "startSec": 141,
          "endSec": 334,
          "id": "video-segment-19",
          "gps": [
            {
              "lat": 43.76384180000001,
              "lng": -116.0858126125,
              "alt": 2168.292,
              "speed2d": 10.664875,
              "speed3d": 10.74875,
              "msFromStart": 141226.50822222212
            },
            {
              "lat": 43.76380772500001,
              "lng": -116.085787275,
              "alt": 2167.77175,
              "speed2d": 10.76275,
              "speed3d": 10.83,
              "msFromStart": 141610.88000000006
            },
            {
              "lat": 43.76374685,
              "lng": -116.08573947500001,
              "alt": 2167.247,
              "speed2d": 11.314249999999998,
              "speed3d": 11.3825,
              "msFromStart": 142256.2946666667
            },
            {
              "lat": 43.76369705,
              "lng": -116.08568704999999,
              "alt": 2166.374,
              "speed2d": 11.382000000000001,
              "speed3d": 11.425,
              "msFromStart": 142863.1742222221
            },
            {
              "lat": 43.763665700000004,
              "lng": -116.08564768,
              "alt": 2165.842,
              "speed2d": 11.4462,
              "speed3d": 11.524,
              "msFromStart": 143265.58899649116
            },
            {
              "lat": 43.76362761428572,
              "lng": -116.08560041428572,
              "alt": 2165.2752857142855,
              "speed2d": 11.546857142857144,
              "speed3d": 11.64142857142857,
              "msFromStart": 143740.28824060148
            },
            {
              "lat": 43.7635774,
              "lng": -116.08554615,
              "alt": 2164.7155000000002,
              "speed2d": 11.790500000000002,
              "speed3d": 11.82,
              "msFromStart": 144320.10114619884
            },
            {
              "lat": 43.76354425,
              "lng": -116.08551423333333,
              "alt": 2164.5986666666668,
              "speed2d": 12.054833333333335,
              "speed3d": 12.125,
              "msFromStart": 144673.41466666668
            },
            {
              "lat": 43.76349845,
              "lng": -116.08547145,
              "alt": 2164.1695,
              "speed2d": 11.9525,
              "speed3d": 11.975,
              "msFromStart": 145161.58266666668
            },
            {
              "lat": 43.76343965,
              "lng": -116.08539504999999,
              "alt": 2163.0240000000003,
              "speed2d": 11.5415,
              "speed3d": 11.620000000000001,
              "msFromStart": 145934.755111111
            },
            {
              "lat": 43.763428399999995,
              "lng": -116.0853785,
              "alt": 2162.7396666666664,
              "speed2d": 11.599666666666668,
              "speed3d": 11.64,
              "msFromStart": 146091.5656296295
            },
            {
              "lat": 43.76339888184515,
              "lng": -116.08532834774108,
              "alt": 2162.005946845629,
              "speed2d": 12.750452417278225,
              "speed3d": 12.6467969132226,
              "msFromStart": 146500
            },
            {
              "lat": 43.7633321,
              "lng": -116.08524194285714,
              "alt": 2160.444285714286,
              "speed2d": 12.849428571428572,
              "speed3d": 12.994285714285715,
              "msFromStart": 147286.86918394026
            },
            {
              "lat": 43.763300075,
              "lng": -116.08520412499999,
              "alt": 2159.728,
              "speed2d": 12.802999999999999,
              "speed3d": 12.875,
              "msFromStart": 147659.75711111107
            },
            {
              "lat": 43.76325675,
              "lng": -116.08514588333334,
              "alt": 2158.6433333333334,
              "speed2d": 12.762,
              "speed3d": 12.906666666666668,
              "msFromStart": 148186.99670175437
            },
            {
              "lat": 43.76320628333333,
              "lng": -116.08507403333333,
              "alt": 2157.3215,
              "speed2d": 13.148,
              "speed3d": 13.246666666666668,
              "msFromStart": 148800.6648421053
            },
            {
              "lat": 43.76316488888888,
              "lng": -116.08502376666668,
              "alt": 2156.3676666666665,
              "speed2d": 13.213111111111111,
              "speed3d": 13.363333333333332,
              "msFromStart": 149267.91728654972
            },
            {
              "lat": 43.763124559999994,
              "lng": -116.08497742,
              "alt": 2155.8612000000003,
              "speed2d": 13.2322,
              "speed3d": 13.314,
              "msFromStart": 149697.8192
            },
            {
              "lat": 43.76306173333333,
              "lng": -116.0849091,
              "alt": 2155.654,
              "speed2d": 13.758,
              "speed3d": 13.716666666666667,
              "msFromStart": 150314.82429629625
            },
            {
              "lat": 43.76302158,
              "lng": -116.08485816,
              "alt": 2156.2194,
              "speed2d": 14.1726,
              "speed3d": 14.172,
              "msFromStart": 150699.35644444433
            },
            {
              "lat": 43.762991955277336,
              "lng": -116.08481962153004,
              "alt": 2156.5764311522066,
              "speed2d": 14.242839660738404,
              "speed3d": 14.237583999819842,
              "msFromStart": 151000
            },
            {
              "lat": 43.762946200228065,
              "lng": -116.0847689627417,
              "alt": 2156.2066734074624,
              "speed2d": 13.61721746588809,
              "speed3d": 13.64043512862896,
              "msFromStart": 151500
            },
            {
              "lat": 43.76287965,
              "lng": -116.084698675,
              "alt": 2155.70925,
              "speed2d": 12.6585,
              "speed3d": 12.7825,
              "msFromStart": 152224.55822222217
            },
            {
              "lat": 43.7628318,
              "lng": -116.08465595000001,
              "alt": 2155.224,
              "speed2d": 12.3865,
              "speed3d": 12.43,
              "msFromStart": 152732.42399999997
            },
            {
              "lat": 43.7627827,
              "lng": -116.08459188571427,
              "alt": 2154.778142857143,
              "speed2d": 13.324285714285713,
              "speed3d": 13.312857142857144,
              "msFromStart": 153258.20719799498
            },
            {
              "lat": 43.76273788571428,
              "lng": -116.08454417142855,
              "alt": 2153.7015714285712,
              "speed2d": 13.363999999999999,
              "speed3d": 13.437142857142858,
              "msFromStart": 153738.55097744355
            },
            {
              "lat": 43.7626857375,
              "lng": -116.08449184999999,
              "alt": 2152.651,
              "speed2d": 13.558374999999998,
              "speed3d": 13.567499999999999,
              "msFromStart": 154274.0611461988
            },
            {
              "lat": 43.76263437777777,
              "lng": -116.0844383,
              "alt": 2152.744,
              "speed2d": 13.822777777777777,
              "speed3d": 13.929999999999998,
              "msFromStart": 154769.16800000003
            },
            {
              "lat": 43.76259338333334,
              "lng": -116.08441123333334,
              "alt": 2151.9078333333337,
              "speed2d": 12.913166666666665,
              "speed3d": 13.103333333333333,
              "msFromStart": 155190.54607407414
            },
            {
              "lat": 43.762527999999996,
              "lng": -116.08436699999999,
              "alt": 2152.195,
              "speed2d": 11.795666666666666,
              "speed3d": 11.926666666666666,
              "msFromStart": 155861.9226666668
            },
            {
              "lat": 43.76249983333334,
              "lng": -116.08434806666665,
              "alt": 2152.0223333333333,
              "speed2d": 11.4345,
              "speed3d": 11.53,
              "msFromStart": 156170.30933333345
            },
            {
              "lat": 43.76244667500001,
              "lng": -116.0843148875,
              "alt": 2151.5118749999997,
              "speed2d": 10.933375000000002,
              "speed3d": 11.01875,
              "msFromStart": 156770.28933333332
            },
            {
              "lat": 43.762407044444444,
              "lng": -116.08429094444445,
              "alt": 2151.339,
              "speed2d": 10.346444444444444,
              "speed3d": 10.463333333333333,
              "msFromStart": 157245.89037037035
            },
            {
              "lat": 43.76237005,
              "lng": -116.08426857499998,
              "alt": 2151.02625,
              "speed2d": 9.751,
              "speed3d": 9.852500000000001,
              "msFromStart": 157739.8559999999
            },
            {
              "lat": 43.7623502,
              "lng": -116.08425435,
              "alt": 2150.5935,
              "speed2d": 9.4185,
              "speed3d": 9.535,
              "msFromStart": 158032.3359999998
            },
            {
              "lat": 43.762299266666666,
              "lng": -116.08419348333335,
              "alt": 2150.0045,
              "speed2d": 9.2625,
              "speed3d": 9.301666666666666,
              "msFromStart": 158786.35200000013
            },
            {
              "lat": 43.762281975,
              "lng": -116.08416752500001,
              "alt": 2149.75325,
              "speed2d": 9.23825,
              "speed3d": 9.29,
              "msFromStart": 159088.4560000002
            },
            {
              "lat": 43.762234825,
              "lng": -116.08411095,
              "alt": 2148.8105,
              "speed2d": 8.51775,
              "speed3d": 8.615,
              "msFromStart": 159914.15466666652
            },
            {
              "lat": 43.76222883174772,
              "lng": -116.0841051406409,
              "alt": 2149.1131794202374,
              "speed2d": 8.318971845917412,
              "speed3d": 8.466283496662275,
              "msFromStart": 160000
            },
            {
              "lat": 43.76217615,
              "lng": -116.08405694999999,
              "alt": 2148.0119999999997,
              "speed2d": 7.922499999999999,
              "speed3d": 7.955,
              "msFromStart": 160905.01911111106
            },
            {
              "lat": 43.7621588,
              "lng": -116.0840332,
              "alt": 2147.4790000000003,
              "speed2d": 7.835666666666667,
              "speed3d": 7.8999999999999995,
              "msFromStart": 161262.63259259256
            },
            {
              "lat": 43.76214185,
              "lng": -116.0839996,
              "alt": 2146.0502500000002,
              "speed2d": 7.57925,
              "speed3d": 7.68,
              "msFromStart": 161756.032
            },
            {
              "lat": 43.762131577931946,
              "lng": -116.08398767724547,
              "alt": 2145.4312829454225,
              "speed2d": 6.8410206903235204,
              "speed3d": 7.140620709705556,
              "msFromStart": 162000
            },
            {
              "lat": 43.7621177,
              "lng": -116.08392776666668,
              "alt": 2143.0686666666666,
              "speed2d": 7.1033333333333335,
              "speed3d": 7.1483333333333325,
              "msFromStart": 162772.18088888883
            },
            {
              "lat": 43.762106966666664,
              "lng": -116.08388616666667,
              "alt": 2142.255333333333,
              "speed2d": 7.927333333333333,
              "speed3d": 7.943333333333333,
              "msFromStart": 163203.2424327485
            },
            {
              "lat": 43.76208721999999,
              "lng": -116.0838311,
              "alt": 2141.2684,
              "speed2d": 8.624400000000001,
              "speed3d": 8.674,
              "msFromStart": 163767.24446315778
            },
            {
              "lat": 43.76207516666667,
              "lng": -116.08380120000001,
              "alt": 2140.7863333333335,
              "speed2d": 8.963,
              "speed3d": 9.09,
              "msFromStart": 164063.83747368402
            },
            {
              "lat": 43.76205209281169,
              "lng": -116.08375243250764,
              "alt": 2140.408783622323,
              "speed2d": 9.641023070571732,
              "speed3d": 9.726697751226446,
              "msFromStart": 164500
            },
            {
              "lat": 43.76202815,
              "lng": -116.083685725,
              "alt": 2138.3007500000003,
              "speed2d": 8.32775,
              "speed3d": 8.395,
              "msFromStart": 165327.25733333337
            },
            {
              "lat": 43.762018960000006,
              "lng": -116.08362448000001,
              "alt": 2136.9148,
              "speed2d": 9.6664,
              "speed3d": 9.661999999999999,
              "msFromStart": 165813.4300444445
            },
            {
              "lat": 43.76201669858575,
              "lng": -116.08359931837035,
              "alt": 2136.385096401805,
              "speed2d": 10.134851235253617,
              "speed3d": 10.086850011951821,
              "msFromStart": 166000
            },
            {
              "lat": 43.76201147846173,
              "lng": -116.08353849601626,
              "alt": 2135.5664860445168,
              "speed2d": 9.921300707265306,
              "speed3d": 9.924709796256993,
              "msFromStart": 166500
            },
            {
              "lat": 43.762002249999995,
              "lng": -116.083432075,
              "alt": 2134.067,
              "speed2d": 9.786999999999999,
              "speed3d": 9.8575,
              "msFromStart": 167364.624
            },
            {
              "lat": 43.761997066666666,
              "lng": -116.08340073333333,
              "alt": 2133.693333333333,
              "speed2d": 9.828333333333333,
              "speed3d": 10.06,
              "msFromStart": 167621.29066666667
            },
            {
              "lat": 43.7619729,
              "lng": -116.0833236,
              "alt": 2132.0885,
              "speed2d": 9.271999999999998,
              "speed3d": 9.45,
              "msFromStart": 168382.40084210524
            },
            {
              "lat": 43.761960224999996,
              "lng": -116.083295425,
              "alt": 2131.2082499999997,
              "speed2d": 9.283,
              "speed3d": 9.4575,
              "msFromStart": 168685.50989473675
            },
            {
              "lat": 43.76193695,
              "lng": -116.08324805,
              "alt": 2129.6235,
              "speed2d": 9.645999999999999,
              "speed3d": 9.7125,
              "msFromStart": 169181.2648070174
            },
            {
              "lat": 43.761915275,
              "lng": -116.083176425,
              "alt": 2128.0125,
              "speed2d": 10.80875,
              "speed3d": 10.932500000000001,
              "msFromStart": 169743.73266666674
            },
            {
              "lat": 43.761899650000004,
              "lng": -116.08309059999999,
              "alt": 2125.875,
              "speed2d": 10.205,
              "speed3d": 10.405000000000001,
              "msFromStart": 170470.84311111114
            },
            {
              "lat": 43.76189232,
              "lng": -116.08305336000001,
              "alt": 2125.1334,
              "speed2d": 10.035,
              "speed3d": 10.274000000000001,
              "msFromStart": 170796.05635555566
            },
            {
              "lat": 43.76187424,
              "lng": -116.08298818,
              "alt": 2124.0570000000002,
              "speed2d": 9.8174,
              "speed3d": 10.068,
              "msFromStart": 171379.94666666668
            },
            {
              "lat": 43.761858866666664,
              "lng": -116.08294810000001,
              "alt": 2123.958666666667,
              "speed2d": 9.977666666666668,
              "speed3d": 10.08,
              "msFromStart": 171727.80711111124
            },
            {
              "lat": 43.76183095,
              "lng": -116.08288604999998,
              "alt": 2123.8914999999997,
              "speed2d": 10.436666666666667,
              "speed3d": 10.566666666666666,
              "msFromStart": 172258.93866666677
            },
            {
              "lat": 43.7618041,
              "lng": -116.08282299999998,
              "alt": 2123.246166666667,
              "speed2d": 10.574499999999999,
              "speed3d": 10.738333333333335,
              "msFromStart": 172791.5013333333
            },
            {
              "lat": 43.761788124999995,
              "lng": -116.08276781250001,
              "alt": 2122.0135,
              "speed2d": 10.47075,
              "speed3d": 10.72,
              "msFromStart": 173257.45033333328
            },
            {
              "lat": 43.7617807,
              "lng": -116.08271103999999,
              "alt": 2119.9358,
              "speed2d": 9.8504,
              "speed3d": 10.111999999999998,
              "msFromStart": 173778.25599999994
            },
            {
              "lat": 43.761780800000004,
              "lng": -116.08266426,
              "alt": 2117.9706,
              "speed2d": 9.6382,
              "speed3d": 9.967999999999998,
              "msFromStart": 174206.9116631578
            },
            {
              "lat": 43.76178472,
              "lng": -116.08259917999999,
              "alt": 2115.1306,
              "speed2d": 9.4304,
              "speed3d": 9.768,
              "msFromStart": 174823.5087157895
            },
            {
              "lat": 43.761784033333335,
              "lng": -116.08255826666667,
              "alt": 2113.1266666666666,
              "speed2d": 9.444666666666667,
              "speed3d": 9.783333333333333,
              "msFromStart": 175194.28715789478
            },
            {
              "lat": 43.761782787033,
              "lng": -116.08252710202267,
              "alt": 2111.4321472885426,
              "speed2d": 9.264246160675901,
              "speed3d": 9.639345060555206,
              "msFromStart": 175500
            },
            {
              "lat": 43.7617779,
              "lng": -116.0824760131686,
              "alt": 2109.3532927325564,
              "speed2d": 8.411873546511625,
              "speed3d": 8.690421511627932,
              "msFromStart": 176000
            },
            {
              "lat": 43.76177788,
              "lng": -116.08239856,
              "alt": 2104.0389999999998,
              "speed2d": 8.270000000000001,
              "speed3d": 8.914,
              "msFromStart": 176835.68000000005
            },
            {
              "lat": 43.76177634285714,
              "lng": -116.08236667142856,
              "alt": 2101.7491428571425,
              "speed2d": 7.8,
              "speed3d": 8.588571428571427,
              "msFromStart": 177225.63517460323
            },
            {
              "lat": 43.76177405,
              "lng": -116.08231115000001,
              "alt": 2099.14825,
              "speed2d": 8.13625,
              "speed3d": 8.7775,
              "msFromStart": 177787.30622222234
            },
            {
              "lat": 43.761766900000005,
              "lng": -116.0822494,
              "alt": 2095.992333333333,
              "speed2d": 8.509333333333334,
              "speed3d": 9.273333333333333,
              "msFromStart": 178382.79354385962
            },
            {
              "lat": 43.76176518788274,
              "lng": -116.08223896293832,
              "alt": 2095.2709540485425,
              "speed2d": 8.43367501264927,
              "speed3d": 9.198756842121373,
              "msFromStart": 178500
            },
            {
              "lat": 43.761754583333335,
              "lng": -116.08217208333332,
              "alt": 2090.4515,
              "speed2d": 8.006166666666667,
              "speed3d": 8.818333333333333,
              "msFromStart": 179279.98007017537
            },
            {
              "lat": 43.761755550000004,
              "lng": -116.08213612499999,
              "alt": 2088.261375,
              "speed2d": 7.593375,
              "speed3d": 8.682500000000001,
              "msFromStart": 179710.93600000005
            },
            {
              "lat": 43.761763200000004,
              "lng": -116.08211080000001,
              "alt": 2085.8476666666666,
              "speed2d": 6.632333333333333,
              "speed3d": 7.473333333333333,
              "msFromStart": 180160.38577777788
            },
            {
              "lat": 43.76177425,
              "lng": -116.08205015,
              "alt": 2082.027,
              "speed2d": 7.2225,
              "speed3d": 8.135000000000002,
              "msFromStart": 180867.3079999999
            },
            {
              "lat": 43.76177115,
              "lng": -116.08200009999999,
              "alt": 2081.068,
              "speed2d": 8.017,
              "speed3d": 8.675,
              "msFromStart": 181307.75777777768
            },
            {
              "lat": 43.761768592393345,
              "lng": -116.08197031369552,
              "alt": 2080.968781370143,
              "speed2d": 9.022277259065127,
              "speed3d": 9.47461966735958,
              "msFromStart": 181500
            },
            {
              "lat": 43.761751925,
              "lng": -116.08188800000002,
              "alt": 2077.13,
              "speed2d": 7.435249999999999,
              "speed3d": 8.4625,
              "msFromStart": 182404.1035555555
            },
            {
              "lat": 43.76175322,
              "lng": -116.08188104,
              "alt": 2074.3186,
              "speed2d": 5.820199999999999,
              "speed3d": 7.078,
              "msFromStart": 182717.7758222221
            },
            {
              "lat": 43.761760525,
              "lng": -116.0818458,
              "alt": 2070.5347500000003,
              "speed2d": 6.319,
              "speed3d": 7.340000000000001,
              "msFromStart": 183257.21398830396
            },
            {
              "lat": 43.761763707290164,
              "lng": -116.08182690069235,
              "alt": 2069.0247584413205,
              "speed2d": 6.670163757498104,
              "speed3d": 7.672898759100732,
              "msFromStart": 183500
            },
            {
              "lat": 43.76175635714286,
              "lng": -116.08175507142857,
              "alt": 2066,
              "speed2d": 7.329142857142857,
              "speed3d": 8.317142857142857,
              "msFromStart": 184265.89890726807
            },
            {
              "lat": 43.7617418,
              "lng": -116.08171151666667,
              "alt": 2064.3481666666667,
              "speed2d": 7.0405,
              "speed3d": 8.033333333333333,
              "msFromStart": 184792.3237037036
            },
            {
              "lat": 43.76173478867358,
              "lng": -116.08169226508468,
              "alt": 2063.9909099494826,
              "speed2d": 7.159080314056471,
              "speed3d": 8.073213516532942,
              "msFromStart": 185000
            },
            {
              "lat": 43.7617152,
              "lng": -116.08164171428572,
              "alt": 2061.3588571428572,
              "speed2d": 5.398,
              "speed3d": 6.88,
              "msFromStart": 185745.03047619053
            },
            {
              "lat": 43.76171481428572,
              "lng": -116.0816139285714,
              "alt": 2058.096857142857,
              "speed2d": 5.087142857142857,
              "speed3d": 6.3914285714285715,
              "msFromStart": 186263.0502857143
            },
            {
              "lat": 43.761716771428574,
              "lng": -116.08157215714286,
              "alt": 2055.43,
              "speed2d": 6.231285714285714,
              "speed3d": 7.321428571428573,
              "msFromStart": 186757.80571428567
            },
            {
              "lat": 43.76171378,
              "lng": -116.08151728,
              "alt": 2052.8724,
              "speed2d": 7.418800000000001,
              "speed3d": 8.374,
              "msFromStart": 187276.21333333332
            },
            {
              "lat": 43.7617121,
              "lng": -116.08148464000001,
              "alt": 2049.454,
              "speed2d": 7.6994,
              "speed3d": 8.796,
              "msFromStart": 187661.21333333332
            },
            {
              "lat": 43.761702050000004,
              "lng": -116.08145145,
              "alt": 2044.50675,
              "speed2d": 6.943250000000001,
              "speed3d": 8.245,
              "msFromStart": 188279.89463157894
            },
            {
              "lat": 43.7616955,
              "lng": -116.0814061,
              "alt": 2039.157,
              "speed2d": 7.723,
              "speed3d": 8.945,
              "msFromStart": 188828.75747368427
            },
            {
              "lat": 43.7616938,
              "lng": -116.08137473333332,
              "alt": 2034.9946666666665,
              "speed2d": 7.563666666666667,
              "speed3d": 8.933333333333334,
              "msFromStart": 189254.05136842112
            },
            {
              "lat": 43.76169965,
              "lng": -116.0813295,
              "alt": 2030.458,
              "speed2d": 7.9455,
              "speed3d": 9.21,
              "msFromStart": 189803.88
            },
            {
              "lat": 43.76170193333333,
              "lng": -116.08128611111111,
              "alt": 2027.017111111111,
              "speed2d": 8.575888888888889,
              "speed3d": 9.871111111111112,
              "msFromStart": 190243.94518518518
            },
            {
              "lat": 43.761697033333334,
              "lng": -116.08122895000001,
              "alt": 2023.8653333333332,
              "speed2d": 9.4545,
              "speed3d": 10.501666666666667,
              "msFromStart": 190749.27629629624
            },
            {
              "lat": 43.761686,
              "lng": -116.08116220000001,
              "alt": 2021.8446666666666,
              "speed2d": 10.829,
              "speed3d": 11.803333333333333,
              "msFromStart": 191199.6485925925
            },
            {
              "lat": 43.76166956666667,
              "lng": -116.0810696,
              "alt": 2020.6453333333332,
              "speed2d": 11.202,
              "speed3d": 12.138333333333332,
              "msFromStart": 191814.26622222213
            },
            {
              "lat": 43.76166363333333,
              "lng": -116.0810162,
              "alt": 2019.695111111111,
              "speed2d": 10.528222222222222,
              "speed3d": 11.584444444444443,
              "msFromStart": 192226.82281481478
            },
            {
              "lat": 43.76165852222222,
              "lng": -116.0809689,
              "alt": 2017.313,
              "speed2d": 9.287666666666667,
              "speed3d": 10.324444444444445,
              "msFromStart": 192727.637037037
            },
            {
              "lat": 43.76164702857143,
              "lng": -116.08091085714285,
              "alt": 2015.4822857142856,
              "speed2d": 9.702285714285715,
              "speed3d": 10.57,
              "msFromStart": 193239.64651629064
            },
            {
              "lat": 43.761638996883235,
              "lng": -116.08088358006401,
              "alt": 2014.7588118931521,
              "speed2d": 9.288045099988295,
              "speed3d": 10.308635112517095,
              "msFromStart": 193500
            },
            {
              "lat": 43.76161976666667,
              "lng": -116.08082626666666,
              "alt": 2010.1256666666668,
              "speed2d": 4.578333333333333,
              "speed3d": 6.553333333333333,
              "msFromStart": 194424.58844444444
            },
            {
              "lat": 43.76161828000001,
              "lng": -116.08081786000001,
              "alt": 2008.5488,
              "speed2d": 3.4908000000000006,
              "speed3d": 5.8100000000000005,
              "msFromStart": 194689.7319111111
            },
            {
              "lat": 43.7616137,
              "lng": -116.08078954999999,
              "alt": 2005.603,
              "speed2d": 3.72,
              "speed3d": 5.795,
              "msFromStart": 195225.54266666668
            },
            {
              "lat": 43.76160086,
              "lng": -116.08072376,
              "alt": 2004.3962,
              "speed2d": 7.1562,
              "speed3d": 8.207999999999998,
              "msFromStart": 195748.91200000013
            },
            {
              "lat": 43.76159270335977,
              "lng": -116.08069203844512,
              "alt": 2004.0780862068325,
              "speed2d": 8.062629746823681,
              "speed3d": 9.003557641000803,
              "msFromStart": 196000
            },
            {
              "lat": 43.76156565,
              "lng": -116.08061335,
              "alt": 2002.2569999999998,
              "speed2d": 7.975250000000001,
              "speed3d": 8.915000000000001,
              "msFromStart": 196779.64311111122
            },
            {
              "lat": 43.761552175,
              "lng": -116.080557225,
              "alt": 2001.33225,
              "speed2d": 8.629750000000001,
              "speed3d": 9.4575,
              "msFromStart": 197218.6542222223
            },
            {
              "lat": 43.76154374000001,
              "lng": -116.08048958,
              "alt": 2000.4746000000002,
              "speed2d": 9.3428,
              "speed3d": 10.080000000000002,
              "msFromStart": 197704.8775111112
            },
            {
              "lat": 43.76153816,
              "lng": -116.08043056,
              "alt": 1999.5506,
              "speed2d": 9.7146,
              "speed3d": 10.381999999999998,
              "msFromStart": 198122.58702222237
            },
            {
              "lat": 43.761526,
              "lng": -116.0803262,
              "alt": 1997.0903333333335,
              "speed2d": 11.038333333333334,
              "speed3d": 11.596666666666668,
              "msFromStart": 198826.32421052622
            },
            {
              "lat": 43.761521736896334,
              "lng": -116.080300979184,
              "alt": 1996.3277163893245,
              "speed2d": 11.376080134055453,
              "speed3d": 11.918948495245532,
              "msFromStart": 199000
            },
            {
              "lat": 43.76149912000001,
              "lng": -116.08019827999999,
              "alt": 1994.2696,
              "speed2d": 11.072399999999998,
              "speed3d": 11.58,
              "msFromStart": 199815.69600000008
            },
            {
              "lat": 43.76149326,
              "lng": -116.08013452,
              "alt": 1993.0852,
              "speed2d": 11.6476,
              "speed3d": 11.994,
              "msFromStart": 200233.28604444454
            },
            {
              "lat": 43.76149173333334,
              "lng": -116.08006073333334,
              "alt": 1991.1223333333332,
              "speed2d": 12.363,
              "speed3d": 12.713333333333335,
              "msFromStart": 200694.85333333327
            },
            {
              "lat": 43.76148738571428,
              "lng": -116.0799816,
              "alt": 1988.694857142857,
              "speed2d": 12.649142857142857,
              "speed3d": 13.045714285714286,
              "msFromStart": 201189.41092063484
            },
            {
              "lat": 43.76148119168295,
              "lng": -116.07993404055578,
              "alt": 1987.5169535755617,
              "speed2d": 12.569404220158795,
              "speed3d": 12.863537828017764,
              "msFromStart": 201500
            },
            {
              "lat": 43.761453980000006,
              "lng": -116.0798208,
              "alt": 1985.0218,
              "speed2d": 12.2528,
              "speed3d": 12.65,
              "msFromStart": 202345.11697777777
            },
            {
              "lat": 43.7614413,
              "lng": -116.07976004999999,
              "alt": 1984.12625,
              "speed2d": 12.14,
              "speed3d": 12.445,
              "msFromStart": 202771.5637777777
            },
            {
              "lat": 43.761433499999995,
              "lng": -116.07968551666666,
              "alt": 1983.0678333333333,
              "speed2d": 12.366000000000001,
              "speed3d": 12.658333333333333,
              "msFromStart": 203271.36542690048
            },
            {
              "lat": 43.76143164285714,
              "lng": -116.0796254857143,
              "alt": 1982.4878571428571,
              "speed2d": 12.200000000000003,
              "speed3d": 12.462857142857143,
              "msFromStart": 203689.2311578948
            },
            {
              "lat": 43.7614237,
              "lng": -116.07954492,
              "alt": 1981.1894,
              "speed2d": 12.107800000000001,
              "speed3d": 12.394,
              "msFromStart": 204237.77577543876
            },
            {
              "lat": 43.76141669298579,
              "lng": -116.07950728985931,
              "alt": 1980.4314428396656,
              "speed2d": 12.031071841228876,
              "speed3d": 12.2704587779515,
              "msFromStart": 204500
            },
            {
              "lat": 43.76139876059364,
              "lng": -116.07944502713733,
              "alt": 1979.8830029274402,
              "speed2d": 11.215466594168431,
              "speed3d": 11.496726121699963,
              "msFromStart": 205000
            },
            {
              "lat": 43.761375725,
              "lng": -116.079349325,
              "alt": 1978.5359999999998,
              "speed2d": 10.7865,
              "speed3d": 10.9725,
              "msFromStart": 205712.90866666663
            },
            {
              "lat": 43.76137123868258,
              "lng": -116.07930139525183,
              "alt": 1977.6398285925245,
              "speed2d": 11.441326014331363,
              "speed3d": 11.571940453423071,
              "msFromStart": 206000
            },
            {
              "lat": 43.76136363333334,
              "lng": -116.07916656666667,
              "alt": 1975.4936666666665,
              "speed2d": 11.580666666666666,
              "speed3d": 11.816666666666666,
              "msFromStart": 206892.22637037054
            },
            {
              "lat": 43.76136053333334,
              "lng": -116.0791362,
              "alt": 1974.851,
              "speed2d": 11.829666666666666,
              "speed3d": 12.086666666666666,
              "msFromStart": 207076.08711111132
            },
            {
              "lat": 43.76134366666667,
              "lng": -116.07904113333333,
              "alt": 1973.1926666666668,
              "speed2d": 12.115000000000002,
              "speed3d": 12.419999999999998,
              "msFromStart": 207681.44622222224
            },
            {
              "lat": 43.761330185714286,
              "lng": -116.07896224285714,
              "alt": 1972.0537142857142,
              "speed2d": 12.298285714285717,
              "speed3d": 12.612857142857145,
              "msFromStart": 208183.9885914787
            },
            {
              "lat": 43.76132385,
              "lng": -116.07886468333334,
              "alt": 1970.5873333333334,
              "speed2d": 12.511333333333333,
              "speed3d": 12.843333333333332,
              "msFromStart": 208791.19775438588
            },
            {
              "lat": 43.761320212499996,
              "lng": -116.07878487500001,
              "alt": 1969.21525,
              "speed2d": 12.987374999999998,
              "speed3d": 13.247499999999999,
              "msFromStart": 209271.08136842097
            },
            {
              "lat": 43.761309177777775,
              "lng": -116.07870878888889,
              "alt": 1967.2863333333332,
              "speed2d": 13.077666666666666,
              "speed3d": 13.453333333333333,
              "msFromStart": 209773.58933333342
            },
            {
              "lat": 43.761290849999995,
              "lng": -116.07863938333332,
              "alt": 1965.9074999999998,
              "speed2d": 13.072666666666667,
              "speed3d": 13.406666666666666,
              "msFromStart": 210259.80459259273
            },
            {
              "lat": 43.76127257499999,
              "lng": -116.078583925,
              "alt": 1965.30575,
              "speed2d": 13.03125,
              "speed3d": 13.415000000000001,
              "msFromStart": 210639.58777777784
            },
            {
              "lat": 43.76124025,
              "lng": -116.07847885000001,
              "alt": 1963.94875,
              "speed2d": 12.1795,
              "speed3d": 12.61,
              "msFromStart": 211352.82600000006
            },
            {
              "lat": 43.7612305875,
              "lng": -116.0784228875,
              "alt": 1963.0465,
              "speed2d": 11.700999999999999,
              "speed3d": 12.17125,
              "msFromStart": 211737.70555555559
            },
            {
              "lat": 43.7612182,
              "lng": -116.0783299,
              "alt": 1961.2956666666669,
              "speed2d": 10.866666666666667,
              "speed3d": 11.39,
              "msFromStart": 212411.26577777782
            },
            {
              "lat": 43.76120726,
              "lng": -116.07826904000001,
              "alt": 1960.3484,
              "speed2d": 11.3094,
              "speed3d": 11.670000000000002,
              "msFromStart": 212807.04177777783
            },
            {
              "lat": 43.76119387999999,
              "lng": -116.07820794,
              "alt": 1959.607,
              "speed2d": 12.015,
              "speed3d": 12.404,
              "msFromStart": 213180.8302222223
            },
            {
              "lat": 43.76117233333333,
              "lng": -116.07811646666667,
              "alt": 1958.369333333333,
              "speed2d": 11.241666666666667,
              "speed3d": 11.786666666666667,
              "msFromStart": 213841.05010526304
            },
            {
              "lat": 43.7611672,
              "lng": -116.078076725,
              "alt": 1957.5775,
              "speed2d": 10.90375,
              "speed3d": 11.3975,
              "msFromStart": 214129.94821052614
            },
            {
              "lat": 43.76116204,
              "lng": -116.07797679000001,
              "alt": 1955.7034999999998,
              "speed2d": 11.636000000000001,
              "speed3d": 12.117,
              "msFromStart": 214750.84399999998
            },
            {
              "lat": 43.761153433333334,
              "lng": -116.07788806666667,
              "alt": 1954.154,
              "speed2d": 12.033000000000001,
              "speed3d": 12.36,
              "msFromStart": 215330.7226666666
            },
            {
              "lat": 43.76114153333334,
              "lng": -116.07781664444444,
              "alt": 1953.1218888888889,
              "speed2d": 12.618444444444444,
              "speed3d": 13.082222222222224,
              "msFromStart": 215769.55733333324
            },
            {
              "lat": 43.76112761428571,
              "lng": -116.07776955714286,
              "alt": 1952.8524285714288,
              "speed2d": 10.810142857142857,
              "speed3d": 11.588571428571427,
              "msFromStart": 216207.93168253955
            },
            {
              "lat": 43.761117475,
              "lng": -116.077698325,
              "alt": 1952.26125,
              "speed2d": 9.96325,
              "speed3d": 10.3125,
              "msFromStart": 216811.72799999997
            },
            {
              "lat": 43.761116380000004,
              "lng": -116.07763526,
              "alt": 1951.3208,
              "speed2d": 10.9404,
              "speed3d": 11.193999999999999,
              "msFromStart": 217218.18808888888
            },
            {
              "lat": 43.76111495,
              "lng": -116.07757285,
              "alt": 1950.117,
              "speed2d": 12.089500000000001,
              "speed3d": 12.245000000000001,
              "msFromStart": 217581.3386666666
            },
            {
              "lat": 43.76110313333333,
              "lng": -116.07745113333334,
              "alt": 1948.3215,
              "speed2d": 13.4115,
              "speed3d": 13.68,
              "msFromStart": 218260.67770760224
            },
            {
              "lat": 43.76109193333333,
              "lng": -116.07738751666668,
              "alt": 1948.0211666666664,
              "speed2d": 12.975333333333335,
              "speed3d": 13.436666666666667,
              "msFromStart": 218664.87242105269
            },
            {
              "lat": 43.761083299999996,
              "lng": -116.0772853,
              "alt": 1946.7818333333332,
              "speed2d": 13.253833333333334,
              "speed3d": 13.463333333333333,
              "msFromStart": 219271.21857309944
            },
            {
              "lat": 43.761083933333325,
              "lng": -116.07720429999999,
              "alt": 1945.6630000000002,
              "speed2d": 13.599111111111112,
              "speed3d": 13.92111111111111,
              "msFromStart": 219729.64444444433
            },
            {
              "lat": 43.761083316666664,
              "lng": -116.07713053333333,
              "alt": 1944.8315000000002,
              "speed2d": 13.311833333333333,
              "speed3d": 13.601666666666667,
              "msFromStart": 220169.48118518502
            },
            {
              "lat": 43.76108291291567,
              "lng": -116.07706468779945,
              "alt": 1943.510775150126,
              "speed2d": 14.107073400271107,
              "speed3d": 14.349312972170118,
              "msFromStart": 220500
            },
            {
              "lat": 43.76105665,
              "lng": -116.07692745,
              "alt": 1943.5234999999998,
              "speed2d": 12.861999999999998,
              "speed3d": 13.04,
              "msFromStart": 221380.5368888889
            },
            {
              "lat": 43.76104335,
              "lng": -116.07682564999999,
              "alt": 1942.8605,
              "speed2d": 13.608,
              "speed3d": 13.79,
              "msFromStart": 221930.2257777778
            },
            {
              "lat": 43.761041266666666,
              "lng": -116.07679139999999,
              "alt": 1942.6023333333333,
              "speed2d": 13.788,
              "speed3d": 13.98,
              "msFromStart": 222113.45540740743
            },
            {
              "lat": 43.76103788333333,
              "lng": -116.07667048333333,
              "alt": 1941.2611666666667,
              "speed2d": 14.701,
              "speed3d": 14.861666666666666,
              "msFromStart": 222726.72800000006
            },
            {
              "lat": 43.761028775,
              "lng": -116.07655005,
              "alt": 1940.1547500000001,
              "speed2d": 15.104,
              "speed3d": 15.3425,
              "msFromStart": 223344.33494736845
            },
            {
              "lat": 43.7610166,
              "lng": -116.07646668000001,
              "alt": 1939.9106,
              "speed2d": 15.1172,
              "speed3d": 15.346,
              "msFromStart": 223775.4733473683
            },
            {
              "lat": 43.7610064,
              "lng": -116.07637446666666,
              "alt": 1939.1626666666666,
              "speed2d": 15.112,
              "speed3d": 15.336666666666666,
              "msFromStart": 224255.09327095508
            },
            {
              "lat": 43.76100281428571,
              "lng": -116.07626764285715,
              "alt": 1937.3157142857142,
              "speed2d": 15.705285714285713,
              "speed3d": 15.851428571428572,
              "msFromStart": 224777.73231746038
            },
            {
              "lat": 43.76099889,
              "lng": -116.07617463000001,
              "alt": 1935.1601999999998,
              "speed2d": 15.698700000000002,
              "speed3d": 16.031,
              "msFromStart": 225252.0320000001
            },
            {
              "lat": 43.760986655555556,
              "lng": -116.07608656666666,
              "alt": 1933.2616666666665,
              "speed2d": 14.808111111111112,
              "speed3d": 15.26,
              "msFromStart": 225774.86755555565
            },
            {
              "lat": 43.760970037499995,
              "lng": -116.07602200000001,
              "alt": 1932.6151249999998,
              "speed2d": 13.355375,
              "speed3d": 13.798750000000002,
              "msFromStart": 226243.09933333343
            },
            {
              "lat": 43.7609619625,
              "lng": -116.0759306375,
              "alt": 1929.64425,
              "speed2d": 14.164875,
              "speed3d": 14.347500000000002,
              "msFromStart": 226766.68622222237
            },
            {
              "lat": 43.760959875000005,
              "lng": -116.07584931250001,
              "alt": 1927.191,
              "speed2d": 14.5275,
              "speed3d": 14.907499999999999,
              "msFromStart": 227235.15300000014
            },
            {
              "lat": 43.7609611,
              "lng": -116.0757452375,
              "alt": 1924.4908750000002,
              "speed2d": 15.7375,
              "speed3d": 15.9375,
              "msFromStart": 227771.1553333333
            },
            {
              "lat": 43.760959266666674,
              "lng": -116.07566916666667,
              "alt": 1923.0956666666666,
              "speed2d": 15.793,
              "speed3d": 16.201666666666668,
              "msFromStart": 228169.46711111107
            },
            {
              "lat": 43.76095418442373,
              "lng": -116.07560525509406,
              "alt": 1922.2014229266872,
              "speed2d": 15.765617176250721,
              "speed3d": 16.019287016329155,
              "msFromStart": 228500
            },
            {
              "lat": 43.760946866666664,
              "lng": -116.0754857,
              "alt": 1920.2236666666668,
              "speed2d": 15.626666666666665,
              "speed3d": 15.953333333333333,
              "msFromStart": 229113.10596491222
            },
            {
              "lat": 43.760945755555554,
              "lng": -116.07536007777779,
              "alt": 1918.1297777777777,
              "speed2d": 15.803777777777778,
              "speed3d": 16.13888888888889,
              "msFromStart": 229736.9066666666
            },
            {
              "lat": 43.76095158333333,
              "lng": -116.07527893333335,
              "alt": 1916.1885,
              "speed2d": 15.343666666666666,
              "speed3d": 15.783333333333333,
              "msFromStart": 230177.57303703693
            },
            {
              "lat": 43.76096078571428,
              "lng": -116.07518934285714,
              "alt": 1913.8911428571428,
              "speed2d": 14.909142857142859,
              "speed3d": 15.415714285714285,
              "msFromStart": 230673.40266666672
            },
            {
              "lat": 43.76097558,
              "lng": -116.07510818,
              "alt": 1910.1456,
              "speed2d": 14.2714,
              "speed3d": 14.78,
              "msFromStart": 231235.3184000001
            },
            {
              "lat": 43.76099361428572,
              "lng": -116.07504161428571,
              "alt": 1904.7457142857143,
              "speed2d": 13.607142857142858,
              "speed3d": 14.240000000000002,
              "msFromStart": 231813.52863492072
            },
            {
              "lat": 43.76100768571428,
              "lng": -116.07498792857143,
              "alt": 1901.5537142857142,
              "speed2d": 13.121285714285714,
              "speed3d": 13.764285714285714,
              "msFromStart": 232268.91733333343
            },
            {
              "lat": 43.76101995,
              "lng": -116.07493595,
              "alt": 1898.6815,
              "speed2d": 13.317499999999999,
              "speed3d": 13.795000000000002,
              "msFromStart": 232681.05866666662
            },
            {
              "lat": 43.76103545,
              "lng": -116.07487047500001,
              "alt": 1894.6682500000002,
              "speed2d": 12.878499999999999,
              "speed3d": 13.515,
              "msFromStart": 233230.55199999988
            },
            {
              "lat": 43.76104832,
              "lng": -116.07480774000001,
              "alt": 1890.9694,
              "speed2d": 12.605,
              "speed3d": 13.236,
              "msFromStart": 233764.2694736842
            },
            {
              "lat": 43.7610588875,
              "lng": -116.07475231249998,
              "alt": 1887.2517500000001,
              "speed2d": 12.5295,
              "speed3d": 13.235,
              "msFromStart": 234234.8577192982
            },
            {
              "lat": 43.76107064285714,
              "lng": -116.07469915714285,
              "alt": 1882.4392857142855,
              "speed2d": 11.809428571428572,
              "speed3d": 12.567142857142859,
              "msFromStart": 234752.38298412703
            },
            {
              "lat": 43.76108146666667,
              "lng": -116.07465349999998,
              "alt": 1878.5138333333332,
              "speed2d": 11.7875,
              "speed3d": 12.558333333333334,
              "msFromStart": 235176.2882962964
            },
            {
              "lat": 43.76110044,
              "lng": -116.07458110000002,
              "alt": 1872.163,
              "speed2d": 11.9248,
              "speed3d": 12.638,
              "msFromStart": 235846.54399999988
            },
            {
              "lat": 43.76110528491782,
              "lng": -116.07455967093416,
              "alt": 1870.9981569340032,
              "speed2d": 12.492165854464133,
              "speed3d": 13.114018336499386,
              "msFromStart": 236000
            },
            {
              "lat": 43.76112196666667,
              "lng": -116.07448959999999,
              "alt": 1865.4376666666667,
              "speed2d": 12.109333333333334,
              "speed3d": 12.813333333333334,
              "msFromStart": 236635.11348148147
            },
            {
              "lat": 43.761131746763546,
              "lng": -116.07444909777672,
              "alt": 1861.910103279989,
              "speed2d": 12.057707230582709,
              "speed3d": 12.74591303153314,
              "msFromStart": 237000
            },
            {
              "lat": 43.76114426994633,
              "lng": -116.07439806276726,
              "alt": 1857.0211986222723,
              "speed2d": 11.29810932336845,
              "speed3d": 12.171427219354289,
              "msFromStart": 237500
            },
            {
              "lat": 43.7611623858776,
              "lng": -116.07435122152448,
              "alt": 1851.0592120999952,
              "speed2d": 10.27577244502087,
              "speed3d": 11.402132650016055,
              "msFromStart": 238000
            },
            {
              "lat": 43.76116738354376,
              "lng": -116.07429454344688,
              "alt": 1847.61422994812,
              "speed2d": 9.8697120693877,
              "speed3d": 11.089778514913617,
              "msFromStart": 238500
            },
            {
              "lat": 43.76117643333334,
              "lng": -116.07419273333333,
              "alt": 1841.6313333333333,
              "speed2d": 9.531333333333334,
              "speed3d": 10.853333333333332,
              "msFromStart": 239380.47709941523
            },
            {
              "lat": 43.76117770721215,
              "lng": -116.0741777539814,
              "alt": 1840.8658099214026,
              "speed2d": 9.692918726191543,
              "speed3d": 11.023191600820713,
              "msFromStart": 239500
            },
            {
              "lat": 43.76118106972281,
              "lng": -116.0741303981553,
              "alt": 1836.690959863835,
              "speed2d": 8.206523034527619,
              "speed3d": 9.890230831577348,
              "msFromStart": 240000
            },
            {
              "lat": 43.76118723333333,
              "lng": -116.07403563333335,
              "alt": 1832.5366666666666,
              "speed2d": 10.386333333333333,
              "speed3d": 11.533333333333333,
              "msFromStart": 240737.9288888889
            },
            {
              "lat": 43.761191866666664,
              "lng": -116.07396245000001,
              "alt": 1830.204833333333,
              "speed2d": 11.583,
              "speed3d": 12.584999999999999,
              "msFromStart": 241304.68651851852
            },
            {
              "lat": 43.761195459999996,
              "lng": -116.07390776,
              "alt": 1828.8432,
              "speed2d": 11.524000000000001,
              "speed3d": 12.504,
              "msFromStart": 241726.69866666658
            },
            {
              "lat": 43.76119943,
              "lng": -116.07384158,
              "alt": 1826.8619000000003,
              "speed2d": 10.399100000000002,
              "speed3d": 11.549999999999999,
              "msFromStart": 242249.90933333323
            },
            {
              "lat": 43.761203957142854,
              "lng": -116.07378712857142,
              "alt": 1824.1545714285714,
              "speed2d": 9.347142857142858,
              "speed3d": 10.502857142857144,
              "msFromStart": 242748.72025396826
            },
            {
              "lat": 43.76121143333334,
              "lng": -116.07370203333333,
              "alt": 1821.5053333333333,
              "speed2d": 9.115,
              "speed3d": 10.28,
              "msFromStart": 243432.03536842103
            },
            {
              "lat": 43.76121723333334,
              "lng": -116.07366203333333,
              "alt": 1820.0144999999998,
              "speed2d": 8.781166666666666,
              "speed3d": 9.88,
              "msFromStart": 243799.29992982448
            },
            {
              "lat": 43.761226666666666,
              "lng": -116.07362256666667,
              "alt": 1818.2576666666669,
              "speed2d": 9.050333333333333,
              "speed3d": 10.113333333333333,
              "msFromStart": 244166.56449122788
            },
            {
              "lat": 43.7612421,
              "lng": -116.07356624999998,
              "alt": 1816.28575,
              "speed2d": 8.0985,
              "speed3d": 9.3,
              "msFromStart": 244753.72800000003
            },
            {
              "lat": 43.7612561125,
              "lng": -116.07352858750001,
              "alt": 1814.377875,
              "speed2d": 7.368500000000001,
              "speed3d": 8.655,
              "msFromStart": 245221.4353333334
            },
            {
              "lat": 43.7612709,
              "lng": -116.07348036666667,
              "alt": 1811.1020000000003,
              "speed2d": 5.862333333333334,
              "speed3d": 7.403333333333333,
              "msFromStart": 245909.4986666668
            },
            {
              "lat": 43.76127816666667,
              "lng": -116.07345728333334,
              "alt": 1810.0911666666668,
              "speed2d": 5.940333333333333,
              "speed3d": 7.411666666666667,
              "msFromStart": 246212.26103703718
            },
            {
              "lat": 43.7612917,
              "lng": -116.07340875,
              "alt": 1808.90775,
              "speed2d": 6.5184999999999995,
              "speed3d": 7.785,
              "msFromStart": 246720.836
            },
            {
              "lat": 43.7612984598721,
              "lng": -116.07337538175825,
              "alt": 1808.7778792230308,
              "speed2d": 6.867082791005629,
              "speed3d": 8.045059136432592,
              "msFromStart": 247000
            },
            {
              "lat": 43.761319,
              "lng": -116.0732881,
              "alt": 1808.258,
              "speed2d": 7.273999999999999,
              "speed3d": 8.355,
              "msFromStart": 247750.92933333336
            },
            {
              "lat": 43.761331580000004,
              "lng": -116.07323592,
              "alt": 1807.8039999999999,
              "speed2d": 7.3312,
              "speed3d": 8.374,
              "msFromStart": 248184.79378713452
            },
            {
              "lat": 43.7613592,
              "lng": -116.0731755,
              "alt": 1805.62425,
              "speed2d": 7.338000000000001,
              "speed3d": 8.295,
              "msFromStart": 248891.703368421
            },
            {
              "lat": 43.761369333333334,
              "lng": -116.0731604,
              "alt": 1804.8963333333331,
              "speed2d": 7.443333333333333,
              "speed3d": 8.423333333333334,
              "msFromStart": 249098.10021052623
            },
            {
              "lat": 43.761399250000004,
              "lng": -116.07310519999999,
              "alt": 1802.488833333333,
              "speed2d": 7.198333333333334,
              "speed3d": 8.165,
              "msFromStart": 249832.7997037036
            },
            {
              "lat": 43.761412633333336,
              "lng": -116.07307873333333,
              "alt": 1801.5660000000003,
              "speed2d": 7.002000000000001,
              "speed3d": 8.03,
              "msFromStart": 250181.93422222216
            },
            {
              "lat": 43.76142574202209,
              "lng": -116.07305772693664,
              "alt": 1800.8467290299939,
              "speed2d": 6.737286957695531,
              "speed3d": 7.751098080505394,
              "msFromStart": 250500
            },
            {
              "lat": 43.761454042857146,
              "lng": -116.07299777142859,
              "alt": 1798.9614285714283,
              "speed2d": 6.553,
              "speed3d": 7.522857142857143,
              "msFromStart": 251290.91898412709
            },
            {
              "lat": 43.76146119186791,
              "lng": -116.07298249638929,
              "alt": 1798.4763679371545,
              "speed2d": 6.479116293279022,
              "speed3d": 7.44476258364853,
              "msFromStart": 251500
            },
            {
              "lat": 43.76148246666667,
              "lng": -116.07294086666667,
              "alt": 1796.8866666666668,
              "speed2d": 6.325333333333333,
              "speed3d": 7.2666666666666675,
              "msFromStart": 252105.1413333333
            },
            {
              "lat": 43.761497066666664,
              "lng": -116.07292319999999,
              "alt": 1792.3326666666667,
              "speed2d": 5.877999999999999,
              "speed3d": 7.036666666666667,
              "msFromStart": 252600.33896296297
            },
            {
              "lat": 43.761517166666664,
              "lng": -116.0728921,
              "alt": 1785.9043333333334,
              "speed2d": 5.844,
              "speed3d": 7.1,
              "msFromStart": 253297.60615984403
            },
            {
              "lat": 43.76153408571429,
              "lng": -116.07283765714286,
              "alt": 1786.1050000000002,
              "speed2d": 6.9358571428571425,
              "speed3d": 7.814285714285715,
              "msFromStart": 253829.5141052631
            },
            {
              "lat": 43.76154676666667,
              "lng": -116.07278987777778,
              "alt": 1786.342111111111,
              "speed2d": 7.519555555555556,
              "speed3d": 8.238888888888889,
              "msFromStart": 254269.71678752435
            },
            {
              "lat": 43.76155911428571,
              "lng": -116.07274737142858,
              "alt": 1785.4485714285715,
              "speed2d": 7.583285714285715,
              "speed3d": 8.284285714285716,
              "msFromStart": 254726.38730158738
            },
            {
              "lat": 43.761574725,
              "lng": -116.072717075,
              "alt": 1780.839125,
              "speed2d": 6.940249999999999,
              "speed3d": 7.727499999999999,
              "msFromStart": 255289.65500000014
            },
            {
              "lat": 43.7615897,
              "lng": -116.07268912,
              "alt": 1776.33,
              "speed2d": 6.8136,
              "speed3d": 7.572,
              "msFromStart": 255822.97564444452
            },
            {
              "lat": 43.76160089999999,
              "lng": -116.07266116666668,
              "alt": 1773.7606666666668,
              "speed2d": 6.748777777777779,
              "speed3d": 7.504444444444444,
              "msFromStart": 256251.55259259266
            },
            {
              "lat": 43.7616102,
              "lng": -116.072636025,
              "alt": 1772.38,
              "speed2d": 6.597250000000001,
              "speed3d": 7.35,
              "msFromStart": 256609.18399999995
            },
            {
              "lat": 43.761629639999995,
              "lng": -116.07258826,
              "alt": 1771.7608,
              "speed2d": 6.453600000000001,
              "speed3d": 7.116,
              "msFromStart": 257297.42755555545
            },
            {
              "lat": 43.76163976,
              "lng": -116.0725641,
              "alt": 1769.7038,
              "speed2d": 6.0283999999999995,
              "speed3d": 6.736000000000001,
              "msFromStart": 257713.75893333327
            },
            {
              "lat": 43.7616515,
              "lng": -116.07253468,
              "alt": 1766.8692,
              "speed2d": 5.8111999999999995,
              "speed3d": 6.57,
              "msFromStart": 258217.35084912274
            },
            {
              "lat": 43.76166052,
              "lng": -116.07251052000001,
              "alt": 1764.6346000000003,
              "speed2d": 5.5738,
              "speed3d": 6.37,
              "msFromStart": 258635.94172631577
            },
            {
              "lat": 43.761669170285145,
              "lng": -116.07248789508735,
              "alt": 1762.1262401107263,
              "speed2d": 6.161377185038404,
              "speed3d": 5.9491085556105965,
              "msFromStart": 259000
            },
            {
              "lat": 43.7616828,
              "lng": -116.07244155000001,
              "alt": 1759.4850000000001,
              "speed2d": 5.4655,
              "speed3d": 6.2575,
              "msFromStart": 259745.22305882355
            },
            {
              "lat": 43.761692716666666,
              "lng": -116.07240206666668,
              "alt": 1758.6223333333335,
              "speed2d": 5.673333333333333,
              "speed3d": 6.385000000000001,
              "msFromStart": 260259.07863777087
            },
            {
              "lat": 43.761702,
              "lng": -116.0723621,
              "alt": 1758.3559999999998,
              "speed2d": 5.760857142857142,
              "speed3d": 6.398571428571429,
              "msFromStart": 260769.84457142858
            },
            {
              "lat": 43.761706412008515,
              "lng": -116.072344298141,
              "alt": 1758.1089560384642,
              "speed2d": 5.770261273694765,
              "speed3d": 6.399397060704387,
              "msFromStart": 261000
            },
            {
              "lat": 43.761727199999996,
              "lng": -116.072284,
              "alt": 1755.5885,
              "speed2d": 5.7895,
              "speed3d": 6.375,
              "msFromStart": 261942.2213333334
            },
            {
              "lat": 43.76173856,
              "lng": -116.07225772,
              "alt": 1754.7849999999999,
              "speed2d": 5.9026,
              "speed3d": 6.452,
              "msFromStart": 262354.64320000005
            },
            {
              "lat": 43.76174715,
              "lng": -116.07223694999999,
              "alt": 1754.278,
              "speed2d": 6.01,
              "speed3d": 6.525,
              "msFromStart": 262657.34755555564
            },
            {
              "lat": 43.76176683333333,
              "lng": -116.07219006666666,
              "alt": 1753.4546666666665,
              "speed2d": 6.239333333333334,
              "speed3d": 6.6899999999999995,
              "msFromStart": 263308.62031968817
            },
            {
              "lat": 43.7617777,
              "lng": -116.07216936666666,
              "alt": 1752.9563333333333,
              "speed2d": 6.353666666666667,
              "speed3d": 6.776666666666666,
              "msFromStart": 263620.22989473684
            },
            {
              "lat": 43.7617894736036,
              "lng": -116.07214405677065,
              "alt": 1752.494304803417,
              "speed2d": 6.213333510147736,
              "speed3d": 6.642636987456759,
              "msFromStart": 264000
            },
            {
              "lat": 43.761818600000005,
              "lng": -116.0720907,
              "alt": 1750.85875,
              "speed2d": 6.02175,
              "speed3d": 6.455,
              "msFromStart": 264885.07200000016
            },
            {
              "lat": 43.76183332857143,
              "lng": -116.0720709,
              "alt": 1749.185714285714,
              "speed2d": 5.878714285714285,
              "speed3d": 6.344285714285714,
              "msFromStart": 265309.6106666668
            },
            {
              "lat": 43.76184833333333,
              "lng": -116.07205151666666,
              "alt": 1747.2163333333333,
              "speed2d": 5.788833333333334,
              "speed3d": 6.286666666666666,
              "msFromStart": 265738.0702222222
            },
            {
              "lat": 43.761867249999995,
              "lng": -116.072029875,
              "alt": 1744.26,
              "speed2d": 5.503,
              "speed3d": 6.057500000000001,
              "msFromStart": 266302.02644444443
            },
            {
              "lat": 43.761878749999994,
              "lng": -116.07201576666668,
              "alt": 1741.9134999999999,
              "speed2d": 5.308166666666667,
              "speed3d": 5.935,
              "msFromStart": 266681.91970370384
            },
            {
              "lat": 43.76189785,
              "lng": -116.0720013,
              "alt": 1737.4105,
              "speed2d": 5.01775,
              "speed3d": 5.7725,
              "msFromStart": 267276.46266666683
            },
            {
              "lat": 43.76191232000001,
              "lng": -116.07198890000001,
              "alt": 1733.3773999999999,
              "speed2d": 4.7616,
              "speed3d": 5.648,
              "msFromStart": 267800.2346666665
            },
            {
              "lat": 43.76192291428571,
              "lng": -116.07197865714285,
              "alt": 1729.9237142857144,
              "speed2d": 4.502428571428571,
              "speed3d": 5.531428571428572,
              "msFromStart": 268249.48609523795
            },
            {
              "lat": 43.761932825,
              "lng": -116.0719695375,
              "alt": 1726.607875,
              "speed2d": 4.201624999999999,
              "speed3d": 5.37875,
              "msFromStart": 268709.47866666666
            },
            {
              "lat": 43.76194181666666,
              "lng": -116.07195708333332,
              "alt": 1723.1446666666666,
              "speed2d": 3.928166666666667,
              "speed3d": 5.23,
              "msFromStart": 269204.38266666664
            },
            {
              "lat": 43.761950600000006,
              "lng": -116.07194129999999,
              "alt": 1719.2455,
              "speed2d": 3.665833333333333,
              "speed3d": 5.139999999999999,
              "msFromStart": 269763.2999298246
            },
            {
              "lat": 43.76195428652459,
              "lng": -116.0719361827639,
              "alt": 1717.7652826969538,
              "speed2d": 3.518196540347943,
              "speed3d": 5.081916662678522,
              "msFromStart": 270000
            },
            {
              "lat": 43.76196257744528,
              "lng": -116.07192199332749,
              "alt": 1716.6363150387567,
              "speed2d": 3.332991396566255,
              "speed3d": 4.912798379938006,
              "msFromStart": 270500
            },
            {
              "lat": 43.76197505,
              "lng": -116.0718981,
              "alt": 1715.223,
              "speed2d": 3.0615,
              "speed3d": 4.657500000000001,
              "msFromStart": 271268.9853333332
            },
            {
              "lat": 43.76198105,
              "lng": -116.07187875,
              "alt": 1715.5529999999999,
              "speed2d": 2.9859999999999998,
              "speed3d": 4.53,
              "msFromStart": 271681.43733333336
            },
            {
              "lat": 43.761989220000004,
              "lng": -116.07184878,
              "alt": 1716.1686,
              "speed2d": 2.873,
              "speed3d": 4.346,
              "msFromStart": 272312.95537777786
            },
            {
              "lat": 43.761992574999994,
              "lng": -116.07183497499999,
              "alt": 1716.68575,
              "speed2d": 2.77325,
              "speed3d": 4.2175,
              "msFromStart": 272614.32800000004
            },
            {
              "lat": 43.762001514285714,
              "lng": -116.07180595714286,
              "alt": 1717.6845714285714,
              "speed2d": 2.7525714285714287,
              "speed3d": 4.074285714285715,
              "msFromStart": 273244.1671111113
            },
            {
              "lat": 43.76200715,
              "lng": -116.07178834999999,
              "alt": 1718.5695,
              "speed2d": 2.798,
              "speed3d": 4.04,
              "msFromStart": 273573.8879999999
            },
            {
              "lat": 43.76202126,
              "lng": -116.07174302000001,
              "alt": 1721.6634,
              "speed2d": 3.0232,
              "speed3d": 4.0360000000000005,
              "msFromStart": 274344.8753777776
            },
            {
              "lat": 43.762027999999994,
              "lng": -116.0717225625,
              "alt": 1723.109375,
              "speed2d": 3.1042499999999995,
              "speed3d": 4.025,
              "msFromStart": 274702.2968888889
            },
            {
              "lat": 43.76203889,
              "lng": -116.07168642999999,
              "alt": 1726.0708,
              "speed2d": 3.3519,
              "speed3d": 4.091,
              "msFromStart": 275252.01777777786
            },
            {
              "lat": 43.76204858888889,
              "lng": -116.07164985555555,
              "alt": 1728.5664444444444,
              "speed2d": 3.5226666666666664,
              "speed3d": 4.162222222222223,
              "msFromStart": 275774.93688888906
            },
            {
              "lat": 43.762059099999995,
              "lng": -116.07162383333335,
              "alt": 1730.5914444444445,
              "speed2d": 3.5280000000000005,
              "speed3d": 4.087777777777778,
              "msFromStart": 276270.6020740743
            },
            {
              "lat": 43.762069200000006,
              "lng": -116.07159826666665,
              "alt": 1731.9723333333332,
              "speed2d": 3.6114444444444445,
              "speed3d": 4.111111111111111,
              "msFromStart": 276765.98577777774
            },
            {
              "lat": 43.76207948888889,
              "lng": -116.07158096666666,
              "alt": 1732.1503333333333,
              "speed2d": 3.569,
              "speed3d": 4.053333333333333,
              "msFromStart": 277261.2752592592
            },
            {
              "lat": 43.762089466666666,
              "lng": -116.07156098888889,
              "alt": 1732.2204444444442,
              "speed2d": 3.6056666666666666,
              "speed3d": 4.0522222222222215,
              "msFromStart": 277756.744888889
            },
            {
              "lat": 43.7620991,
              "lng": -116.07153708888887,
              "alt": 1732.1775555555555,
              "speed2d": 3.815,
              "speed3d": 4.193333333333333,
              "msFromStart": 278252.270814815
            },
            {
              "lat": 43.7621096,
              "lng": -116.07151065,
              "alt": 1731.6021666666666,
              "speed2d": 4.137333333333333,
              "speed3d": 4.468333333333334,
              "msFromStart": 278756.77688888874
            },
            {
              "lat": 43.762115550000004,
              "lng": -116.0714947,
              "alt": 1731.257,
              "speed2d": 4.301,
              "speed3d": 4.62,
              "msFromStart": 279050.2666666664
            },
            {
              "lat": 43.7621261,
              "lng": -116.07146716666668,
              "alt": 1730.719,
              "speed2d": 4.238333333333333,
              "speed3d": 4.58,
              "msFromStart": 279682.3393684212
            },
            {
              "lat": 43.7621336,
              "lng": -116.07144605,
              "alt": 1730.0660000000003,
              "speed2d": 4.0415,
              "speed3d": 4.395,
              "msFromStart": 280203.93389473704
            },
            {
              "lat": 43.7621415,
              "lng": -116.0714227,
              "alt": 1728.8235,
              "speed2d": 3.973,
              "speed3d": 4.305,
              "msFromStart": 280753.6159999998
            },
            {
              "lat": 43.76214516760121,
              "lng": -116.0714127647479,
              "alt": 1728.1957695195647,
              "speed2d": 3.9684172651414884,
              "speed3d": 4.31139419408749,
              "msFromStart": 281000
            },
            {
              "lat": 43.76215916666667,
              "lng": -116.07138179999998,
              "alt": 1726.3523333333333,
              "speed2d": 3.8516666666666666,
              "speed3d": 4.206666666666667,
              "msFromStart": 281882.22399999975
            },
            {
              "lat": 43.762165,
              "lng": -116.07137118333333,
              "alt": 1725.5365000000002,
              "speed2d": 3.7975,
              "speed3d": 4.166666666666667,
              "msFromStart": 282240.4168888886
            },
            {
              "lat": 43.7621749,
              "lng": -116.07135403999999,
              "alt": 1724.1308,
              "speed2d": 3.7034000000000002,
              "speed3d": 4.074,
              "msFromStart": 282829.1598222223
            },
            {
              "lat": 43.76217975,
              "lng": -116.07134605,
              "alt": 1723.634,
              "speed2d": 3.6795,
              "speed3d": 4.05,
              "msFromStart": 283093.112888889
            },
            {
              "lat": 43.76219471428572,
              "lng": -116.07131667142858,
              "alt": 1723.0024285714285,
              "speed2d": 3.9737142857142858,
              "speed3d": 4.3028571428571425,
              "msFromStart": 283753.6053333333
            },
            {
              "lat": 43.76220805,
              "lng": -116.0713016,
              "alt": 1721.5929999999998,
              "speed2d": 3.9215,
              "speed3d": 4.27,
              "msFromStart": 284331.7890526315
            },
            {
              "lat": 43.76221149755147,
              "lng": -116.07129878927324,
              "alt": 1720.9790985279049,
              "speed2d": 3.839746599994728,
              "speed3d": 4.215260397054044,
              "msFromStart": 284500
            },
            {
              "lat": 43.7622272,
              "lng": -116.07128983333334,
              "alt": 1718.2060000000001,
              "speed2d": 3.4499999999999997,
              "speed3d": 3.87,
              "msFromStart": 285293.5898947366
            },
            {
              "lat": 43.76223745714286,
              "lng": -116.07127135714286,
              "alt": 1717.9822857142856,
              "speed2d": 3.6195714285714287,
              "speed3d": 3.9885714285714284,
              "msFromStart": 285718.693079365
            },
            {
              "lat": 43.76225061999999,
              "lng": -116.07123338,
              "alt": 1717.6085999999998,
              "speed2d": 4.0245999999999995,
              "speed3d": 4.342,
              "msFromStart": 286297.034311111
            },
            {
              "lat": 43.762266600000004,
              "lng": -116.07119847999999,
              "alt": 1717.9746,
              "speed2d": 4.4766,
              "speed3d": 4.736,
              "msFromStart": 286846.23964444466
            },
            {
              "lat": 43.762270977379416,
              "lng": -116.07118844388798,
              "alt": 1718.4880913402392,
              "speed2d": 4.613196212366195,
              "speed3d": 4.840202978309857,
              "msFromStart": 287000
            },
            {
              "lat": 43.762293959999994,
              "lng": -116.07114103999999,
              "alt": 1718.1141999999998,
              "speed2d": 4.9572,
              "speed3d": 5.204000000000001,
              "msFromStart": 287834.6314666668
            },
            {
              "lat": 43.762300075,
              "lng": -116.0711185,
              "alt": 1716.844,
              "speed2d": 4.9205,
              "speed3d": 5.187500000000001,
              "msFromStart": 288290.86533333355
            },
            {
              "lat": 43.762303016666664,
              "lng": -116.07109361666667,
              "alt": 1715.2930000000001,
              "speed2d": 4.934833333333333,
              "speed3d": 5.215,
              "msFromStart": 288730.064
            },
            {
              "lat": 43.76230666666667,
              "lng": -116.07106963333334,
              "alt": 1714.1729999999998,
              "speed2d": 4.937333333333334,
              "speed3d": 5.196666666666667,
              "msFromStart": 289178.2506666667
            },
            {
              "lat": 43.76231101645595,
              "lng": -116.07105014460703,
              "alt": 1713.6864175730504,
              "speed2d": 5.025184643243154,
              "speed3d": 5.2724975687175775,
              "msFromStart": 289500
            },
            {
              "lat": 43.76232244285713,
              "lng": -116.0710033,
              "alt": 1713.3965714285716,
              "speed2d": 5.219714285714286,
              "speed3d": 5.452857142857143,
              "msFromStart": 290233.8079331661
            },
            {
              "lat": 43.76232553333334,
              "lng": -116.07097093333331,
              "alt": 1713.272,
              "speed2d": 5.21488888888889,
              "speed3d": 5.486666666666667,
              "msFromStart": 290737.2266666667
            },
            {
              "lat": 43.762323499999994,
              "lng": -116.0709444,
              "alt": 1712.24025,
              "speed2d": 5.132499999999999,
              "speed3d": 5.3775,
              "msFromStart": 291219.1097777778
            },
            {
              "lat": 43.762327428571425,
              "lng": -116.070903,
              "alt": 1711.586,
              "speed2d": 5.506571428571428,
              "speed3d": 5.701428571428571,
              "msFromStart": 291774.23999999993
            },
            {
              "lat": 43.7623356625,
              "lng": -116.07086723750001,
              "alt": 1712.195,
              "speed2d": 5.7685,
              "speed3d": 5.96,
              "msFromStart": 292234.6
            },
            {
              "lat": 43.762342524999994,
              "lng": -116.070819125,
              "alt": 1712.7404999999999,
              "speed2d": 6.185,
              "speed3d": 6.335,
              "msFromStart": 292756.8520000001
            },
            {
              "lat": 43.76234112857143,
              "lng": -116.07077942857143,
              "alt": 1711.761714285714,
              "speed2d": 6.338857142857143,
              "speed3d": 6.541428571428572,
              "msFromStart": 293242.3570793653
            },
            {
              "lat": 43.76234062,
              "lng": -116.07074531999999,
              "alt": 1710.5324,
              "speed2d": 6.2982,
              "speed3d": 6.486000000000001,
              "msFromStart": 293727.7987555556
            },
            {
              "lat": 43.762348825000004,
              "lng": -116.070698775,
              "alt": 1711.11075,
              "speed2d": 6.549250000000001,
              "speed3d": 6.6975,
              "msFromStart": 294214.22622222226
            },
            {
              "lat": 43.762357566666665,
              "lng": -116.07066010000001,
              "alt": 1712.1693333333333,
              "speed2d": 6.798,
              "speed3d": 6.9366666666666665,
              "msFromStart": 294585.5359999999
            },
            {
              "lat": 43.7623648,
              "lng": -116.0705916,
              "alt": 1712.7133333333334,
              "speed2d": 6.925666666666666,
              "speed3d": 7.06,
              "msFromStart": 295337.70844444435
            },
            {
              "lat": 43.76236423333333,
              "lng": -116.07056703333333,
              "alt": 1712.2383333333332,
              "speed2d": 6.992666666666667,
              "speed3d": 7.119999999999999,
              "msFromStart": 295612.4995555556
            },
            {
              "lat": 43.76236362857143,
              "lng": -116.07049025714286,
              "alt": 1712.328142857143,
              "speed2d": 7.6254285714285714,
              "speed3d": 7.691428571428571,
              "msFromStart": 296266.2826666668
            },
            {
              "lat": 43.762363725,
              "lng": -116.0704341,
              "alt": 1712.8525000000002,
              "speed2d": 8.020249999999999,
              "speed3d": 8.10875,
              "msFromStart": 296722.50666666677
            },
            {
              "lat": 43.76236322,
              "lng": -116.07037968999998,
              "alt": 1712.4083,
              "speed2d": 8.100800000000001,
              "speed3d": 8.236,
              "msFromStart": 297251.5482666669
            },
            {
              "lat": 43.7623631,
              "lng": -116.07035665000001,
              "alt": 1711.2065,
              "speed2d": 7.8925,
              "speed3d": 8.059999999999999,
              "msFromStart": 297582.3742222222
            },
            {
              "lat": 43.762367028571425,
              "lng": -116.07031694285715,
              "alt": 1707.6632857142858,
              "speed2d": 7.557714285714287,
              "speed3d": 7.712857142857143,
              "msFromStart": 298234.01473015867
            },
            {
              "lat": 43.76237343333333,
              "lng": -116.07027756666668,
              "alt": 1705.4946666666665,
              "speed2d": 7.543,
              "speed3d": 7.666666666666666,
              "msFromStart": 298750.9001481482
            },
            {
              "lat": 43.76238203333333,
              "lng": -116.07023950000001,
              "alt": 1703.2216666666664,
              "speed2d": 7.552333333333333,
              "speed3d": 7.713333333333333,
              "msFromStart": 299264.13330214436
            },
            {
              "lat": 43.7623903,
              "lng": -116.07019414999999,
              "alt": 1701.81575,
              "speed2d": 7.691,
              "speed3d": 7.8325,
              "msFromStart": 299785.07031578966
            },
            {
              "lat": 43.76240098571429,
              "lng": -116.07014198571429,
              "alt": 1702.079857142857,
              "speed2d": 7.911142857142856,
              "speed3d": 8.034285714285714,
              "msFromStart": 300289.7780183796
            },
            {
              "lat": 43.76241178333333,
              "lng": -116.07010753333333,
              "alt": 1702.028,
              "speed2d": 7.977833333333333,
              "speed3d": 8.126666666666667,
              "msFromStart": 300663.0080000001
            },
            {
              "lat": 43.762429775,
              "lng": -116.070049775,
              "alt": 1702.249,
              "speed2d": 7.99325,
              "speed3d": 8.13,
              "msFromStart": 301296.9720000002
            },
            {
              "lat": 43.76243505653975,
              "lng": -116.07002863566296,
              "alt": 1702.6780860161273,
              "speed2d": 8.022566397881716,
              "speed3d": 8.138853118305374,
              "msFromStart": 301500
            },
            {
              "lat": 43.762454318861906,
              "lng": -116.06999075562392,
              "alt": 1702.679223554837,
              "speed2d": 8.003607419381368,
              "speed3d": 8.123685935505094,
              "msFromStart": 302000
            },
            {
              "lat": 43.762486249999995,
              "lng": -116.06993015,
              "alt": 1702.375,
              "speed2d": 7.9345,
              "speed3d": 8.08,
              "msFromStart": 302835.88622222224
            },
            {
              "lat": 43.76250376666667,
              "lng": -116.06991550000001,
              "alt": 1700.183,
              "speed2d": 7.892333333333333,
              "speed3d": 8.02,
              "msFromStart": 303257.9958518519
            },
            {
              "lat": 43.76251530988582,
              "lng": -116.06990146411677,
              "alt": 1699.6102597390025,
              "speed2d": 7.955121454615855,
              "speed3d": 8.06168883131969,
              "msFromStart": 303500
            },
            {
              "lat": 43.7625661,
              "lng": -116.06984754999999,
              "alt": 1698.974,
              "speed2d": 8.002500000000001,
              "speed3d": 8.13,
              "msFromStart": 304433.09810526314
            },
            {
              "lat": 43.762579425,
              "lng": -116.069832025,
              "alt": 1699.05925,
              "speed2d": 7.99375,
              "speed3d": 8.135,
              "msFromStart": 304680.24757894734
            },
            {
              "lat": 43.76259500351188,
              "lng": -116.06981023684175,
              "alt": 1699.269279659097,
              "speed2d": 7.918655239972828,
              "speed3d": 8.046991997844879,
              "msFromStart": 305000
            },
            {
              "lat": 43.762616044924556,
              "lng": -116.0697838710177,
              "alt": 1699.0661449761385,
              "speed2d": 7.457195633948143,
              "speed3d": 7.654202889204178,
              "msFromStart": 305500
            },
            {
              "lat": 43.7626559,
              "lng": -116.06974087142856,
              "alt": 1698.4214285714286,
              "speed2d": 7.611428571428572,
              "speed3d": 7.750000000000001,
              "msFromStart": 306209.26501587324
            },
            {
              "lat": 43.762681519999994,
              "lng": -116.06971057999999,
              "alt": 1697.9193999999998,
              "speed2d": 7.4302,
              "speed3d": 7.584,
              "msFromStart": 306743.3066666668
            },
            {
              "lat": 43.762713166666664,
              "lng": -116.06968270000002,
              "alt": 1697.4686666666666,
              "speed2d": 7.3598333333333334,
              "speed3d": 7.513333333333333,
              "msFromStart": 307319.49155555584
            },
            {
              "lat": 43.76273677142857,
              "lng": -116.0696632,
              "alt": 1697.080857142857,
              "speed2d": 7.308,
              "speed3d": 7.432857142857143,
              "msFromStart": 307748.1056507937
            },
            {
              "lat": 43.76275925,
              "lng": -116.06964952499999,
              "alt": 1696.4555,
              "speed2d": 7.3797500000000005,
              "speed3d": 7.5175,
              "msFromStart": 308104.2906666668
            },
            {
              "lat": 43.76277823183656,
              "lng": -116.0696386859502,
              "alt": 1693.9976833787755,
              "speed2d": 7.379030223933626,
              "speed3d": 7.529750868986541,
              "msFromStart": 308500
            },
            {
              "lat": 43.76281764,
              "lng": -116.06962304,
              "alt": 1688.4611999999997,
              "speed2d": 7.426,
              "speed3d": 7.584,
              "msFromStart": 309295.8345356725
            },
            {
              "lat": 43.762838828571425,
              "lng": -116.06962058571428,
              "alt": 1684.7321428571431,
              "speed2d": 7.338285714285714,
              "speed3d": 7.522857142857143,
              "msFromStart": 309753.5412932331
            },
            {
              "lat": 43.762856525,
              "lng": -116.069617075,
              "alt": 1681.1779999999999,
              "speed2d": 7.23825,
              "speed3d": 7.4425,
              "msFromStart": 310168.47199999995
            },
            {
              "lat": 43.76288375,
              "lng": -116.06961580000001,
              "alt": 1676.599,
              "speed2d": 7.154249999999999,
              "speed3d": 7.3525,
              "msFromStart": 310732.1102222222
            },
            {
              "lat": 43.76291396,
              "lng": -116.06961220000001,
              "alt": 1672.1527999999998,
              "speed2d": 7.318999999999999,
              "speed3d": 7.526,
              "msFromStart": 311264.90808888886
            },
            {
              "lat": 43.762927003648095,
              "lng": -116.06961221804163,
              "alt": 1670.182385627465,
              "speed2d": 7.331092778172882,
              "speed3d": 7.539571345445139,
              "msFromStart": 311500
            },
            {
              "lat": 43.7629748,
              "lng": -116.0695868,
              "alt": 1665.2385,
              "speed2d": 7.6530000000000005,
              "speed3d": 7.8625,
              "msFromStart": 312312.2959999999
            },
            {
              "lat": 43.7629975,
              "lng": -116.06957413333333,
              "alt": 1664.8636666666666,
              "speed2d": 7.757666666666667,
              "speed3d": 7.976666666666667,
              "msFromStart": 312627.8968888888
            },
            {
              "lat": 43.763044699999995,
              "lng": -116.0695445,
              "alt": 1664.2195000000002,
              "speed2d": 8.059,
              "speed3d": 8.24,
              "msFromStart": 313258.44444444403
            },
            {
              "lat": 43.763079675,
              "lng": -116.06952022499999,
              "alt": 1663.7582499999999,
              "speed2d": 8.268500000000001,
              "speed3d": 8.455,
              "msFromStart": 313725.8986666667
            },
            {
              "lat": 43.76312033333333,
              "lng": -116.06949666666667,
              "alt": 1663.4298333333334,
              "speed2d": 8.5065,
              "speed3d": 8.683333333333334,
              "msFromStart": 314239.82103703707
            },
            {
              "lat": 43.763168525000005,
              "lng": -116.06946495,
              "alt": 1662.9425,
              "speed2d": 8.696,
              "speed3d": 8.879999999999999,
              "msFromStart": 314882.45557894715
            },
            {
              "lat": 43.7631884,
              "lng": -116.06945379999999,
              "alt": 1660.4719999999998,
              "speed2d": 8.700333333333333,
              "speed3d": 8.9,
              "msFromStart": 315231.351578947
            },
            {
              "lat": 43.76320431740006,
              "lng": -116.06944532251701,
              "alt": 1658.3280445348194,
              "speed2d": 8.769386496818939,
              "speed3d": 8.963397097956516,
              "msFromStart": 315500
            },
            {
              "lat": 43.76323685,
              "lng": -116.0694047,
              "alt": 1651.3438333333334,
              "speed2d": 8.5315,
              "speed3d": 8.781666666666666,
              "msFromStart": 316332.95318518527
            },
            {
              "lat": 43.76325116666666,
              "lng": -116.06938026666666,
              "alt": 1647.0410000000002,
              "speed2d": 8.173333333333334,
              "speed3d": 8.5,
              "msFromStart": 316827.9644444446
            },
            {
              "lat": 43.763255671428574,
              "lng": -116.06935738571428,
              "alt": 1643.875857142857,
              "speed2d": 7.677142857142856,
              "speed3d": 8.012857142857143,
              "msFromStart": 317225.9245714288
            },
            {
              "lat": 43.76326828571429,
              "lng": -116.06932982857143,
              "alt": 1639.7047142857143,
              "speed2d": 7.317714285714285,
              "speed3d": 7.664285714285714,
              "msFromStart": 317759.9842539682
            },
            {
              "lat": 43.763280083333335,
              "lng": -116.06930883333334,
              "alt": 1636.0883333333334,
              "speed2d": 7.085833333333333,
              "speed3d": 7.453333333333333,
              "msFromStart": 318211.55822222226
            },
            {
              "lat": 43.763294639883185,
              "lng": -116.06930527859816,
              "alt": 1633.9221380211695,
              "speed2d": 6.764250968116693,
              "speed3d": 7.066284045436947,
              "msFromStart": 318500
            },
            {
              "lat": 43.76329210000001,
              "lng": -116.06925865000001,
              "alt": 1628.559,
              "speed2d": 6.46425,
              "speed3d": 6.9175,
              "msFromStart": 319161.4484210527
            },
            {
              "lat": 43.76329418333333,
              "lng": -116.06922771666666,
              "alt": 1622.7541666666666,
              "speed2d": 5.9605,
              "speed3d": 6.465,
              "msFromStart": 319799.45768421056
            },
            {
              "lat": 43.7632972,
              "lng": -116.06921030000001,
              "alt": 1617.9453333333336,
              "speed2d": 5.6,
              "speed3d": 6.153333333333333,
              "msFromStart": 320276.76294736855
            },
            {
              "lat": 43.763302857142854,
              "lng": -116.06919978571429,
              "alt": 1612.2222857142858,
              "speed2d": 5.238857142857142,
              "speed3d": 5.844285714285714,
              "msFromStart": 320826.0195555554
            },
            {
              "lat": 43.7633090625,
              "lng": -116.06919365,
              "alt": 1607.7917499999999,
              "speed2d": 5.0573749999999995,
              "speed3d": 5.7075000000000005,
              "msFromStart": 321264.92799999984
            },
            {
              "lat": 43.76331564,
              "lng": -116.06918542,
              "alt": 1602.1806000000001,
              "speed2d": 4.824199999999999,
              "speed3d": 5.537999999999999,
              "msFromStart": 321814.1368888889
            },
            {
              "lat": 43.763318857142856,
              "lng": -116.0691746,
              "alt": 1597.9427142857141,
              "speed2d": 4.623857142857144,
              "speed3d": 5.39,
              "msFromStart": 322253.7346031746
            },
            {
              "lat": 43.76332050406081,
              "lng": -116.06916565206288,
              "alt": 1595.7117794905732,
              "speed2d": 4.577760556559918,
              "speed3d": 5.34,
              "msFromStart": 322500
            },
            {
              "lat": 43.76333425,
              "lng": -116.06910479999999,
              "alt": 1593.0925000000002,
              "speed2d": 4.583500000000001,
              "speed3d": 5.35,
              "msFromStart": 323409.7386666667
            },
            {
              "lat": 43.7633372,
              "lng": -116.06909085000001,
              "alt": 1592.683,
              "speed2d": 4.6165,
              "speed3d": 5.38,
              "msFromStart": 323602.1582222222
            },
            {
              "lat": 43.76334368519108,
              "lng": -116.0690678553463,
              "alt": 1591.9890455800253,
              "speed2d": 4.506264769032562,
              "speed3d": 5.332685111342843,
              "msFromStart": 324000
            },
            {
              "lat": 43.763356333333334,
              "lng": -116.06901593333333,
              "alt": 1590.4893333333334,
              "speed2d": 4.452,
              "speed3d": 5.220000000000001,
              "msFromStart": 324856.67592982465
            },
            {
              "lat": 43.76335764943871,
              "lng": -116.06900427319263,
              "alt": 1590.431169286239,
              "speed2d": 4.448671755702836,
              "speed3d": 5.192279299485023,
              "msFromStart": 325000
            },
            {
              "lat": 43.76337045,
              "lng": -116.0689642,
              "alt": 1588.7269999999999,
              "speed2d": 4.606,
              "speed3d": 5.35,
              "msFromStart": 325717.4702222222
            },
            {
              "lat": 43.763382825,
              "lng": -116.0689299,
              "alt": 1586.01375,
              "speed2d": 4.7945,
              "speed3d": 5.5125,
              "msFromStart": 326405.2106666666
            },
            {
              "lat": 43.76338962,
              "lng": -116.06891404,
              "alt": 1585.4236,
              "speed2d": 4.8326,
              "speed3d": 5.554,
              "msFromStart": 326707.5360000001
            },
            {
              "lat": 43.76340542,
              "lng": -116.06887706,
              "alt": 1584.158,
              "speed2d": 5.1,
              "speed3d": 5.760000000000001,
              "msFromStart": 327301.1040000002
            },
            {
              "lat": 43.7634171,
              "lng": -116.06883961666666,
              "alt": 1583.5915,
              "speed2d": 5.436333333333334,
              "speed3d": 6.043333333333334,
              "msFromStart": 327734.3324444444
            },
            {
              "lat": 43.76343045,
              "lng": -116.068790525,
              "alt": 1583.26325,
              "speed2d": 5.9245,
              "speed3d": 6.46,
              "msFromStart": 328193.62133333326
            },
            {
              "lat": 43.7634489,
              "lng": -116.06872946666667,
              "alt": 1582.6173333333334,
              "speed2d": 6.475333333333333,
              "speed3d": 6.986666666666667,
              "msFromStart": 328762.1374814814
            },
            {
              "lat": 43.76345705491574,
              "lng": -116.06870473024034,
              "alt": 1582.1920038675219,
              "speed2d": 6.684448632554781,
              "speed3d": 7.169498756867981,
              "msFromStart": 329000
            },
            {
              "lat": 43.763475329732934,
              "lng": -116.0686515923483,
              "alt": 1581.310821579262,
              "speed2d": 6.990776673033835,
              "speed3d": 7.446680636653629,
              "msFromStart": 329500
            },
            {
              "lat": 43.7635083,
              "lng": -116.06856955,
              "alt": 1580.3265000000001,
              "speed2d": 7.4075,
              "speed3d": 7.845000000000001,
              "msFromStart": 330308.6492631581
            },
            {
              "lat": 43.763533675,
              "lng": -116.068507725,
              "alt": 1580.08025,
              "speed2d": 7.715999999999999,
              "speed3d": 8.12,
              "msFromStart": 330900.62266666687
            },
            {
              "lat": 43.76354811428572,
              "lng": -116.06846997142858,
              "alt": 1579.7824285714285,
              "speed2d": 7.883714285714286,
              "speed3d": 8.295714285714286,
              "msFromStart": 331249.0872380954
            },
            {
              "lat": 43.76356965,
              "lng": -116.06841636666667,
              "alt": 1579.3205,
              "speed2d": 8.110666666666667,
              "speed3d": 8.498333333333333,
              "msFromStart": 331740.6364444444
            },
            {
              "lat": 43.76359815,
              "lng": -116.0683523,
              "alt": 1578.37,
              "speed2d": 8.432500000000001,
              "speed3d": 8.815000000000001,
              "msFromStart": 332345.6853333332
            },
            {
              "lat": 43.7636189,
              "lng": -116.06830571666666,
              "alt": 1577.6193333333333,
              "speed2d": 8.477666666666666,
              "speed3d": 8.870000000000001,
              "msFromStart": 332813.09866666683
            },
            {
              "lat": 43.763638542857144,
              "lng": -116.06826204285714,
              "alt": 1576.5045714285714,
              "speed2d": 8.535714285714286,
              "speed3d": 8.917142857142856,
              "msFromStart": 333276.5422222225
            },
            {
              "lat": 43.76365252,
              "lng": -116.0682229,
              "alt": 1575.6172000000001,
              "speed2d": 8.563600000000001,
              "speed3d": 8.954,
              "msFromStart": 333659.4161777777
            },
            {
              "lat": 43.76366262641653,
              "lng": -116.06818577629403,
              "alt": 1575.1136353751908,
              "speed2d": 8.433916232771836,
              "speed3d": 8.86,
              "msFromStart": 334000
            }
          ]
        }
      ]
    },
    {
      "id": "trail-lower-nugget",
      "pathType": "trail",
      "name": "Lower Nugget",
      "difficulty": "black",
      "videos": [
        {
          "videoId": "GX010072",
          "pathId": "trail-lower-nugget",
          "startSec": 305,
          "endSec": 437,
          "id": "video-segment-35",
          "gps": [
            {
              "lat": 43.76193241111111,
              "lng": -116.07085518888887,
              "alt": 1933.1337777777778,
              "speed2d": 3.2263333333333337,
              "speed3d": 3.285555555555556,
              "msFromStart": 305255.672888889
            },
            {
              "lat": 43.761940555555555,
              "lng": -116.0708421111111,
              "alt": 1932.9495555555554,
              "speed2d": 2.932111111111111,
              "speed3d": 2.992222222222222,
              "msFromStart": 305751.5306666667
            },
            {
              "lat": 43.76194848888889,
              "lng": -116.07083001111113,
              "alt": 1932.8745555555554,
              "speed2d": 2.788666666666667,
              "speed3d": 2.8077777777777775,
              "msFromStart": 306247.33425860957
            },
            {
              "lat": 43.7619536,
              "lng": -116.07081969999999,
              "alt": 1932.712111111111,
              "speed2d": 2.4151111111111114,
              "speed3d": 2.4855555555555555,
              "msFromStart": 306742.9086315788
            },
            {
              "lat": 43.761956544444445,
              "lng": -116.07081050000001,
              "alt": 1932.305111111111,
              "speed2d": 2.2382222222222223,
              "speed3d": 2.262222222222222,
              "msFromStart": 307238.359578947
            },
            {
              "lat": 43.76195845555556,
              "lng": -116.07080175555556,
              "alt": 1931.5935555555557,
              "speed2d": 2.163,
              "speed3d": 2.203333333333333,
              "msFromStart": 307732.9955555557
            },
            {
              "lat": 43.76196174444445,
              "lng": -116.07079359999999,
              "alt": 1931.008,
              "speed2d": 2.0732222222222223,
              "speed3d": 2.101111111111111,
              "msFromStart": 308226.97955555585
            },
            {
              "lat": 43.761968870000004,
              "lng": -116.07078125,
              "alt": 1930.6217000000001,
              "speed2d": 2.4206999999999996,
              "speed3d": 2.457,
              "msFromStart": 308749.32622222236
            },
            {
              "lat": 43.76197136666667,
              "lng": -116.07076807777776,
              "alt": 1930.3911111111115,
              "speed2d": 2.337777777777778,
              "speed3d": 2.354444444444445,
              "msFromStart": 309272.32296296314
            },
            {
              "lat": 43.76197403333333,
              "lng": -116.07075548888888,
              "alt": 1930.1243333333332,
              "speed2d": 2.3374444444444444,
              "speed3d": 2.3833333333333333,
              "msFromStart": 309766.83733333333
            },
            {
              "lat": 43.761976822222216,
              "lng": -116.0707462,
              "alt": 1930.0277777777776,
              "speed2d": 2.0172222222222222,
              "speed3d": 2.1055555555555556,
              "msFromStart": 310260.86874074064
            },
            {
              "lat": 43.76198054444444,
              "lng": -116.07074291111111,
              "alt": 1929.4255555555555,
              "speed2d": 1.5436666666666665,
              "speed3d": 1.6344444444444444,
              "msFromStart": 310756.53333333327
            },
            {
              "lat": 43.761984033333334,
              "lng": -116.07073666666668,
              "alt": 1928.7323333333334,
              "speed2d": 1.5203333333333335,
              "speed3d": 1.5722222222222222,
              "msFromStart": 311253.0520493826
            },
            {
              "lat": 43.76198817777778,
              "lng": -116.07072606666667,
              "alt": 1928.4775555555555,
              "speed2d": 1.7686666666666666,
              "speed3d": 1.8122222222222224,
              "msFromStart": 311748.25600000017
            },
            {
              "lat": 43.761987955555554,
              "lng": -116.07071771111112,
              "alt": 1927.9667777777777,
              "speed2d": 1.5814444444444442,
              "speed3d": 1.6922222222222223,
              "msFromStart": 312242.75200000027
            },
            {
              "lat": 43.76198848888889,
              "lng": -116.07071166666668,
              "alt": 1927.3694444444443,
              "speed2d": 1.3002222222222222,
              "speed3d": 1.4455555555555557,
              "msFromStart": 312737.5057777778
            },
            {
              "lat": 43.761988655555555,
              "lng": -116.0707083,
              "alt": 1926.9220000000003,
              "speed2d": 0.9265555555555555,
              "speed3d": 1.0966666666666667,
              "msFromStart": 313232.46577777783
            },
            {
              "lat": 43.76198594444444,
              "lng": -116.07070217777778,
              "alt": 1926.529888888889,
              "speed2d": 1.0296666666666665,
              "speed3d": 1.1455555555555554,
              "msFromStart": 313727.8968888889
            },
            {
              "lat": 43.76198601,
              "lng": -116.07069303,
              "alt": 1926.3322,
              "speed2d": 1.2456,
              "speed3d": 1.358,
              "msFromStart": 314251.2600888889
            },
            {
              "lat": 43.761989911111115,
              "lng": -116.07068674444443,
              "alt": 1926.1115555555557,
              "speed2d": 1.1275555555555554,
              "speed3d": 1.2177777777777776,
              "msFromStart": 314775.2213333333
            },
            {
              "lat": 43.761992477777774,
              "lng": -116.07067827777777,
              "alt": 1926.1485555555555,
              "speed2d": 1.3328888888888888,
              "speed3d": 1.4211111111111112,
              "msFromStart": 315271.9320493827
            },
            {
              "lat": 43.76199652222222,
              "lng": -116.07067417777779,
              "alt": 1925.9485555555557,
              "speed2d": 1.1683333333333334,
              "speed3d": 1.2522222222222221,
              "msFromStart": 315767.3280000001
            },
            {
              "lat": 43.761997944444445,
              "lng": -116.07066975555554,
              "alt": 1925.6852222222224,
              "speed2d": 1.0405555555555555,
              "speed3d": 1.1266666666666665,
              "msFromStart": 316262.0134424954
            },
            {
              "lat": 43.76200257777778,
              "lng": -116.07066494444445,
              "alt": 1925.782777777778,
              "speed2d": 1.1389999999999998,
              "speed3d": 1.192222222222222,
              "msFromStart": 316756.5658947368
            },
            {
              "lat": 43.76200608888889,
              "lng": -116.0706603111111,
              "alt": 1925.7006666666664,
              "speed2d": 1.1733333333333333,
              "speed3d": 1.2133333333333334,
              "msFromStart": 317251.04673684196
            },
            {
              "lat": 43.76200955555556,
              "lng": -116.07065555555556,
              "alt": 1925.4886666666669,
              "speed2d": 1.1857777777777778,
              "speed3d": 1.212222222222222,
              "msFromStart": 317745.42044444446
            },
            {
              "lat": 43.76201474444444,
              "lng": -116.07065183333334,
              "alt": 1925.546888888889,
              "speed2d": 1.2101111111111111,
              "speed3d": 1.248888888888889,
              "msFromStart": 318239.7084444444
            },
            {
              "lat": 43.762018,
              "lng": -116.07065007777778,
              "alt": 1925.5722222222225,
              "speed2d": 1.016333333333333,
              "speed3d": 1.0444444444444445,
              "msFromStart": 318734.2986666668
            },
            {
              "lat": 43.762021866666664,
              "lng": -116.07064752222223,
              "alt": 1925.6167777777775,
              "speed2d": 1.1134444444444445,
              "speed3d": 1.1044444444444446,
              "msFromStart": 319229.13066666696
            },
            {
              "lat": 43.76202525000001,
              "lng": -116.07064508,
              "alt": 1925.5158,
              "speed2d": 1.0971000000000002,
              "speed3d": 1.131,
              "msFromStart": 319751.1404444443
            },
            {
              "lat": 43.76202674444444,
              "lng": -116.07064446666669,
              "alt": 1925.2513333333334,
              "speed2d": 0.881888888888889,
              "speed3d": 0.9033333333333333,
              "msFromStart": 320272.93195061706
            },
            {
              "lat": 43.76202886666667,
              "lng": -116.07064381111113,
              "alt": 1925.120333333333,
              "speed2d": 0.741,
              "speed3d": 0.76,
              "msFromStart": 320767.74400000024
            },
            {
              "lat": 43.762031855555556,
              "lng": -116.07064526666666,
              "alt": 1924.8216666666667,
              "speed2d": 0.7253333333333333,
              "speed3d": 0.7522222222222223,
              "msFromStart": 321262.83171929856
            },
            {
              "lat": 43.76203588888888,
              "lng": -116.07064419999999,
              "alt": 1924.6885555555555,
              "speed2d": 0.8381111111111111,
              "speed3d": 0.82,
              "msFromStart": 321758.7368421052
            },
            {
              "lat": 43.76204346666666,
              "lng": -116.07064266666669,
              "alt": 1925.0308888888887,
              "speed2d": 1.111888888888889,
              "speed3d": 1.0888888888888888,
              "msFromStart": 322255.082105263
            },
            {
              "lat": 43.76205045555556,
              "lng": -116.07064085555555,
              "alt": 1925.3525555555552,
              "speed2d": 1.263,
              "speed3d": 1.2622222222222224,
              "msFromStart": 322750.62222222215
            },
            {
              "lat": 43.76205416666666,
              "lng": -116.07063835555557,
              "alt": 1925.471,
              "speed2d": 1.1394444444444445,
              "speed3d": 1.1644444444444444,
              "msFromStart": 323245.51822222205
            },
            {
              "lat": 43.76205503333334,
              "lng": -116.07063734444445,
              "alt": 1925.4644444444443,
              "speed2d": 0.7374444444444443,
              "speed3d": 0.7966666666666666,
              "msFromStart": 323740.05866666674
            },
            {
              "lat": 43.762055411111106,
              "lng": -116.07063823333333,
              "alt": 1925.5550000000003,
              "speed2d": 0.3101111111111111,
              "speed3d": 0.3522222222222222,
              "msFromStart": 324234.3146666668
            },
            {
              "lat": 43.762057733333336,
              "lng": -116.07063975555555,
              "alt": 1925.6226666666666,
              "speed2d": 0.41744444444444445,
              "speed3d": 0.4088888888888889,
              "msFromStart": 324729.1217777778
            },
            {
              "lat": 43.76206227,
              "lng": -116.07064161,
              "alt": 1925.8663000000001,
              "speed2d": 0.5565,
              "speed3d": 0.5810000000000001,
              "msFromStart": 325251.88213333354
            },
            {
              "lat": 43.76206538888889,
              "lng": -116.07064194444445,
              "alt": 1926.1677777777777,
              "speed2d": 0.38988888888888884,
              "speed3d": 0.43777777777777777,
              "msFromStart": 325774.5599999998
            },
            {
              "lat": 43.76206698888889,
              "lng": -116.07064324444445,
              "alt": 1926.1956666666667,
              "speed2d": 0.4227777777777778,
              "speed3d": 0.45111111111111113,
              "msFromStart": 326269.6859259256
            },
            {
              "lat": 43.76206873333334,
              "lng": -116.07064502222221,
              "alt": 1926.254,
              "speed2d": 0.42022222222222216,
              "speed3d": 0.4566666666666668,
              "msFromStart": 326765.11999999994
            },
            {
              "lat": 43.762069044444445,
              "lng": -116.07064702222223,
              "alt": 1926.2463333333335,
              "speed2d": 0.3142222222222222,
              "speed3d": 0.3711111111111111,
              "msFromStart": 327260.7138557503
            },
            {
              "lat": 43.76207198888889,
              "lng": -116.07064815555556,
              "alt": 1926.505777777778,
              "speed2d": 0.34888888888888886,
              "speed3d": 0.3744444444444444,
              "msFromStart": 327755.9882105264
            },
            {
              "lat": 43.76207527777778,
              "lng": -116.0706499111111,
              "alt": 1926.7008888888888,
              "speed2d": 0.4762222222222222,
              "speed3d": 0.5144444444444445,
              "msFromStart": 328251.0905263161
            },
            {
              "lat": 43.76207993333333,
              "lng": -116.07064828888889,
              "alt": 1927.0713333333333,
              "speed2d": 0.6204444444444445,
              "speed3d": 0.6444444444444445,
              "msFromStart": 328745.51822222234
            },
            {
              "lat": 43.76208453333333,
              "lng": -116.0706469,
              "alt": 1927.3190000000004,
              "speed2d": 0.7594444444444444,
              "speed3d": 0.7933333333333332,
              "msFromStart": 329239.40622222255
            },
            {
              "lat": 43.762088233333344,
              "lng": -116.07064985555554,
              "alt": 1927.2714444444446,
              "speed2d": 0.8286666666666668,
              "speed3d": 0.8611111111111112,
              "msFromStart": 329733.6497777778
            },
            {
              "lat": 43.7620863,
              "lng": -116.07065037777777,
              "alt": 1926.7655555555555,
              "speed2d": 0.6897777777777778,
              "speed3d": 0.7166666666666666,
              "msFromStart": 330228.1777777778
            },
            {
              "lat": 43.762089960000004,
              "lng": -116.07064961999998,
              "alt": 1926.8729000000003,
              "speed2d": 0.7254,
              "speed3d": 0.7479999999999998,
              "msFromStart": 330751.3235555554
            },
            {
              "lat": 43.7620909,
              "lng": -116.0706495111111,
              "alt": 1926.813444444445,
              "speed2d": 0.6462222222222223,
              "speed3d": 0.6488888888888888,
              "msFromStart": 331275.2800727744
            },
            {
              "lat": 43.76209292222222,
              "lng": -116.07064854444445,
              "alt": 1926.6985555555557,
              "speed2d": 0.7384444444444445,
              "speed3d": 0.7577777777777778,
              "msFromStart": 331770.5532631579
            },
            {
              "lat": 43.76209311111111,
              "lng": -116.07064771111112,
              "alt": 1926.8354444444449,
              "speed2d": 0.4627777777777778,
              "speed3d": 0.4955555555555555,
              "msFromStart": 332265.2311578947
            },
            {
              "lat": 43.76209401111111,
              "lng": -116.07064722222222,
              "alt": 1926.666777777778,
              "speed2d": 0.6133333333333333,
              "speed3d": 0.5855555555555556,
              "msFromStart": 332760.59022222226
            },
            {
              "lat": 43.762097555555556,
              "lng": -116.07064665555556,
              "alt": 1926.6192222222226,
              "speed2d": 0.7777777777777778,
              "speed3d": 0.8022222222222223,
              "msFromStart": 333256.49422222236
            },
            {
              "lat": 43.76209645555556,
              "lng": -116.07064631111112,
              "alt": 1926.5228888888892,
              "speed2d": 0.4438888888888889,
              "speed3d": 0.4655555555555556,
              "msFromStart": 333751.88266666676
            },
            {
              "lat": 43.762099633333335,
              "lng": -116.07064671111112,
              "alt": 1926.3275555555556,
              "speed2d": 0.5984444444444443,
              "speed3d": 0.5611111111111111,
              "msFromStart": 334246.85866666696
            },
            {
              "lat": 43.76210392222222,
              "lng": -116.07064925555555,
              "alt": 1925.4586666666664,
              "speed2d": 1.058111111111111,
              "speed3d": 1.0355555555555556,
              "msFromStart": 334741.8168888888
            },
            {
              "lat": 43.76210741111111,
              "lng": -116.0706538,
              "alt": 1924.3797777777777,
              "speed2d": 1.2647777777777776,
              "speed3d": 1.2588888888888887,
              "msFromStart": 335236.76088888873
            },
            {
              "lat": 43.762113388888885,
              "lng": -116.0706638111111,
              "alt": 1923.4246666666668,
              "speed2d": 1.935,
              "speed3d": 1.9066666666666665,
              "msFromStart": 335731.0737777777
            },
            {
              "lat": 43.76210868999999,
              "lng": -116.07068081000001,
              "alt": 1922.2620000000002,
              "speed2d": 2.3633,
              "speed3d": 2.4,
              "msFromStart": 336252.327242105
            },
            {
              "lat": 43.76209414444445,
              "lng": -116.07069648888888,
              "alt": 1921.1972222222223,
              "speed2d": 2.6472222222222226,
              "speed3d": 2.724444444444444,
              "msFromStart": 336774.25852631574
            },
            {
              "lat": 43.762092688888885,
              "lng": -116.0707129888889,
              "alt": 1920.578222222222,
              "speed2d": 2.5568888888888885,
              "speed3d": 2.677777777777778,
              "msFromStart": 337269.1183157893
            },
            {
              "lat": 43.76209892222222,
              "lng": -116.0707359111111,
              "alt": 1919.977111111111,
              "speed2d": 2.8563333333333336,
              "speed3d": 2.908888888888889,
              "msFromStart": 337764.46933333325
            },
            {
              "lat": 43.76211096666666,
              "lng": -116.07075966666666,
              "alt": 1919.193888888889,
              "speed2d": 3.434555555555556,
              "speed3d": 3.4899999999999998,
              "msFromStart": 338260.2133333332
            },
            {
              "lat": 43.76212398888889,
              "lng": -116.07077344444443,
              "alt": 1918.246555555556,
              "speed2d": 3.532666666666666,
              "speed3d": 3.6733333333333333,
              "msFromStart": 338754.9973333334
            },
            {
              "lat": 43.76214416666667,
              "lng": -116.07077553333333,
              "alt": 1916.8881111111114,
              "speed2d": 4.0617777777777775,
              "speed3d": 4.07,
              "msFromStart": 339249.0133333334
            },
            {
              "lat": 43.76217632222222,
              "lng": -116.07077139999998,
              "alt": 1915.408,
              "speed2d": 5.605333333333332,
              "speed3d": 5.592222222222222,
              "msFromStart": 339744.02488888893
            },
            {
              "lat": 43.762208611111106,
              "lng": -116.07076715555556,
              "alt": 1913.8784444444443,
              "speed2d": 6.574666666666666,
              "speed3d": 6.665555555555556,
              "msFromStart": 340239.83288888895
            },
            {
              "lat": 43.76223803333334,
              "lng": -116.07076683333332,
              "alt": 1912.8973333333333,
              "speed2d": 6.558000000000001,
              "speed3d": 6.742222222222221,
              "msFromStart": 340735.45422222215
            },
            {
              "lat": 43.76226526666667,
              "lng": -116.0707661888889,
              "alt": 1912.3264444444446,
              "speed2d": 6.081888888888889,
              "speed3d": 6.313333333333333,
              "msFromStart": 341230.9262222222
            },
            {
              "lat": 43.762291499999996,
              "lng": -116.07076933333333,
              "alt": 1910.7123333333332,
              "speed2d": 5.725777777777778,
              "speed3d": 5.955555555555556,
              "msFromStart": 341725.7802105263
            },
            {
              "lat": 43.76231831,
              "lng": -116.07077394,
              "alt": 1908.8902,
              "speed2d": 5.4935,
              "speed3d": 5.808999999999999,
              "msFromStart": 342247.60421052633
            },
            {
              "lat": 43.76233973333333,
              "lng": -116.07077922222223,
              "alt": 1907.068888888889,
              "speed2d": 4.913222222222222,
              "speed3d": 5.363333333333334,
              "msFromStart": 342769.80622222216
            },
            {
              "lat": 43.76235394444444,
              "lng": -116.07078927777778,
              "alt": 1905.5175555555556,
              "speed2d": 4.238,
              "speed3d": 4.724444444444445,
              "msFromStart": 343264.84622222214
            },
            {
              "lat": 43.76236496666667,
              "lng": -116.0708011,
              "alt": 1903.9342222222222,
              "speed2d": 3.8617777777777773,
              "speed3d": 4.456666666666667,
              "msFromStart": 343759.80622222216
            },
            {
              "lat": 43.76236795555556,
              "lng": -116.0708021111111,
              "alt": 1901.8321111111113,
              "speed2d": 2.9878888888888886,
              "speed3d": 3.8322222222222218,
              "msFromStart": 344254.70222222205
            },
            {
              "lat": 43.76236711111112,
              "lng": -116.07080498888888,
              "alt": 1899.8923333333337,
              "speed2d": 2.159222222222222,
              "speed3d": 3.174444444444445,
              "msFromStart": 344748.736
            },
            {
              "lat": 43.76238267777778,
              "lng": -116.07082367777777,
              "alt": 1899.0737777777777,
              "speed2d": 2.6755555555555555,
              "speed3d": 3.477777777777778,
              "msFromStart": 345242.07999999996
            },
            {
              "lat": 43.762396011111115,
              "lng": -116.07084202222224,
              "alt": 1898.2644444444443,
              "speed2d": 2.8161111111111117,
              "speed3d": 3.666666666666667,
              "msFromStart": 345736.8373333332
            },
            {
              "lat": 43.76240281111111,
              "lng": -116.07085298888889,
              "alt": 1896.4107777777779,
              "speed2d": 2.450222222222222,
              "speed3d": 3.4299999999999997,
              "msFromStart": 346232.7253333332
            },
            {
              "lat": 43.76241017777778,
              "lng": -116.07086343333333,
              "alt": 1894.618888888889,
              "speed2d": 2.7242222222222225,
              "speed3d": 3.651111111111111,
              "msFromStart": 346728.90947368427
            },
            {
              "lat": 43.76242337777778,
              "lng": -116.07086557777778,
              "alt": 1892.5066666666667,
              "speed2d": 3.1927777777777777,
              "speed3d": 4.067777777777778,
              "msFromStart": 347225.33052631596
            },
            {
              "lat": 43.76244274,
              "lng": -116.07085645999999,
              "alt": 1890.9255,
              "speed2d": 4.1063,
              "speed3d": 4.8180000000000005,
              "msFromStart": 347748.47199999983
            },
            {
              "lat": 43.76246583333334,
              "lng": -116.07084914444444,
              "alt": 1889.8127777777777,
              "speed2d": 5.281555555555556,
              "speed3d": 5.817777777777778,
              "msFromStart": 348270.65955555544
            },
            {
              "lat": 43.76249121111111,
              "lng": -116.07084366666668,
              "alt": 1888.983777777778,
              "speed2d": 6.181666666666667,
              "speed3d": 6.695555555555556,
              "msFromStart": 348765.0613333334
            },
            {
              "lat": 43.76251468888889,
              "lng": -116.07083676666666,
              "alt": 1888.8172222222224,
              "speed2d": 6.473777777777778,
              "speed3d": 6.992222222222222,
              "msFromStart": 349259.22133333347
            },
            {
              "lat": 43.762528999999994,
              "lng": -116.07082242222224,
              "alt": 1888.278888888889,
              "speed2d": 6.332777777777778,
              "speed3d": 6.9222222222222225,
              "msFromStart": 349754.0924444445
            },
            {
              "lat": 43.76254142222223,
              "lng": -116.07081087777779,
              "alt": 1888.0577777777776,
              "speed2d": 5.780111111111111,
              "speed3d": 6.415555555555555,
              "msFromStart": 350249.5324444446
            },
            {
              "lat": 43.76254802222222,
              "lng": -116.07080242222222,
              "alt": 1888.2588888888888,
              "speed2d": 4.8293333333333335,
              "speed3d": 5.495555555555556,
              "msFromStart": 350745.37244444434
            },
            {
              "lat": 43.7625544,
              "lng": -116.07078594444445,
              "alt": 1888.3151111111113,
              "speed2d": 4.522666666666666,
              "speed3d": 5.124444444444445,
              "msFromStart": 351241.53244444414
            },
            {
              "lat": 43.76256105555555,
              "lng": -116.07077080000002,
              "alt": 1888.46,
              "speed2d": 3.905666666666667,
              "speed3d": 4.571111111111111,
              "msFromStart": 351736.54905263154
            },
            {
              "lat": 43.76257733333333,
              "lng": -116.07076202222221,
              "alt": 1888.7949999999998,
              "speed2d": 3.4102222222222225,
              "speed3d": 4.095555555555555,
              "msFromStart": 352230.6509473683
            },
            {
              "lat": 43.76259719,
              "lng": -116.0707669,
              "alt": 1887.9750000000001,
              "speed2d": 2.8186999999999998,
              "speed3d": 3.585,
              "msFromStart": 352752.56000000006
            },
            {
              "lat": 43.76261118888888,
              "lng": -116.07077194444443,
              "alt": 1886.2143333333333,
              "speed2d": 2.8662222222222224,
              "speed3d": 3.6711111111111108,
              "msFromStart": 353274.8657777778
            },
            {
              "lat": 43.762618777777774,
              "lng": -116.07077592222221,
              "alt": 1884.1749999999997,
              "speed2d": 2.7138888888888886,
              "speed3d": 3.6711111111111108,
              "msFromStart": 353770.4195555556
            },
            {
              "lat": 43.7626211,
              "lng": -116.07078816666666,
              "alt": 1881.405888888889,
              "speed2d": 2.6363333333333334,
              "speed3d": 3.7311111111111113,
              "msFromStart": 354266.56355555577
            },
            {
              "lat": 43.76261752222222,
              "lng": -116.07080877777778,
              "alt": 1878.322222222222,
              "speed2d": 3.067555555555556,
              "speed3d": 4.217777777777777,
              "msFromStart": 354762.1386666666
            },
            {
              "lat": 43.762613377777775,
              "lng": -116.07082847777778,
              "alt": 1876.572777777778,
              "speed2d": 3.092222222222222,
              "speed3d": 4.316666666666666,
              "msFromStart": 355257.25866666634
            },
            {
              "lat": 43.762609866666665,
              "lng": -116.07084516666669,
              "alt": 1875.4855555555555,
              "speed2d": 2.867777777777778,
              "speed3d": 4.164444444444444,
              "msFromStart": 355752.1031111111
            },
            {
              "lat": 43.76260352222222,
              "lng": -116.0708565,
              "alt": 1874.4717777777778,
              "speed2d": 2.1675555555555555,
              "speed3d": 3.71,
              "msFromStart": 356246.72711111105
            },
            {
              "lat": 43.762604966666665,
              "lng": -116.0708671111111,
              "alt": 1873.966111111111,
              "speed2d": 2.0692222222222223,
              "speed3d": 3.5544444444444445,
              "msFromStart": 356739.4610526315
            },
            {
              "lat": 43.7626039,
              "lng": -116.07087804444444,
              "alt": 1873.067,
              "speed2d": 1.861,
              "speed3d": 3.408888888888889,
              "msFromStart": 357230.6829473681
            },
            {
              "lat": 43.7626059,
              "lng": -116.07088973333333,
              "alt": 1872.0173333333332,
              "speed2d": 1.866888888888889,
              "speed3d": 3.425555555555556,
              "msFromStart": 357724.34844444436
            },
            {
              "lat": 43.762614,
              "lng": -116.07089722000002,
              "alt": 1871.0995,
              "speed2d": 1.7112999999999998,
              "speed3d": 3.2840000000000003,
              "msFromStart": 358248.6639999997
            },
            {
              "lat": 43.76261764444445,
              "lng": -116.07091063333334,
              "alt": 1869.9433333333334,
              "speed2d": 1.8805555555555553,
              "speed3d": 3.406666666666667,
              "msFromStart": 358772.53511111124
            },
            {
              "lat": 43.76262685555556,
              "lng": -116.07094276666666,
              "alt": 1869.7668888888888,
              "speed2d": 2.6547777777777775,
              "speed3d": 3.76,
              "msFromStart": 359268.4551111114
            },
            {
              "lat": 43.76264038888888,
              "lng": -116.0709797,
              "alt": 1869.5666666666666,
              "speed2d": 3.391555555555555,
              "speed3d": 4.2666666666666675,
              "msFromStart": 359764.0995555555
            },
            {
              "lat": 43.76265487777778,
              "lng": -116.0710157888889,
              "alt": 1868.8748888888888,
              "speed2d": 3.8901111111111115,
              "speed3d": 4.667777777777778,
              "msFromStart": 360259.5235555555
            },
            {
              "lat": 43.76266475555556,
              "lng": -116.07104343333334,
              "alt": 1867.4478888888889,
              "speed2d": 4.045999999999999,
              "speed3d": 4.863333333333333,
              "msFromStart": 360754.1742222223
            },
            {
              "lat": 43.76268195555555,
              "lng": -116.07106326666667,
              "alt": 1866.419888888889,
              "speed2d": 4.008555555555556,
              "speed3d": 4.824444444444444,
              "msFromStart": 361248.20622222254
            },
            {
              "lat": 43.76270021111111,
              "lng": -116.07108236666667,
              "alt": 1865.3523333333333,
              "speed2d": 4.016111111111111,
              "speed3d": 4.81,
              "msFromStart": 361743.9528421054
            },
            {
              "lat": 43.7627187,
              "lng": -116.07109845555554,
              "alt": 1864.2835555555555,
              "speed2d": 3.978666666666667,
              "speed3d": 4.788888888888889,
              "msFromStart": 362241.071157895
            },
            {
              "lat": 43.76273744444444,
              "lng": -116.07110964444442,
              "alt": 1863.2583333333334,
              "speed2d": 3.732222222222222,
              "speed3d": 4.584444444444445,
              "msFromStart": 362737.6782222222
            },
            {
              "lat": 43.76275603333333,
              "lng": -116.07112559999999,
              "alt": 1863.1133333333332,
              "speed2d": 4.012333333333333,
              "speed3d": 4.706666666666666,
              "msFromStart": 363068.3235555553
            },
            {
              "lat": 43.76277812879939,
              "lng": -116.0711244589683,
              "alt": 1862.666510974507,
              "speed2d": 4.072077444043613,
              "speed3d": 4.804279890171558,
              "msFromStart": 363500
            },
            {
              "lat": 43.76280299649285,
              "lng": -116.07111989004107,
              "alt": 1862.1400614493446,
              "speed2d": 4.080146942378354,
              "speed3d": 4.809962635477714,
              "msFromStart": 364000
            },
            {
              "lat": 43.76282786418631,
              "lng": -116.07111532111385,
              "alt": 1861.6136119241824,
              "speed2d": 4.0882164407130945,
              "speed3d": 4.815645380783869,
              "msFromStart": 364500
            },
            {
              "lat": 43.76285273187977,
              "lng": -116.07111075218663,
              "alt": 1861.0871623990201,
              "speed2d": 4.096285939047836,
              "speed3d": 4.821328126090025,
              "msFromStart": 365000
            },
            {
              "lat": 43.76287759957323,
              "lng": -116.0711061832594,
              "alt": 1860.560712873858,
              "speed2d": 4.104355437382577,
              "speed3d": 4.8270108713961815,
              "msFromStart": 365500
            },
            {
              "lat": 43.76290246726669,
              "lng": -116.07110161433218,
              "alt": 1860.0342633486955,
              "speed2d": 4.112424935717319,
              "speed3d": 4.832693616702337,
              "msFromStart": 366000
            },
            {
              "lat": 43.762927334960146,
              "lng": -116.07109704540495,
              "alt": 1859.5078138235333,
              "speed2d": 4.120494434052059,
              "speed3d": 4.838376362008493,
              "msFromStart": 366500
            },
            {
              "lat": 43.76295220265361,
              "lng": -116.07109247647773,
              "alt": 1858.981364298371,
              "speed2d": 4.1285639323868,
              "speed3d": 4.844059107314648,
              "msFromStart": 367000
            },
            {
              "lat": 43.76298085,
              "lng": -116.07109225,
              "alt": 1857.7825,
              "speed2d": 4.0545,
              "speed3d": 4.8,
              "msFromStart": 367632.33178947365
            },
            {
              "lat": 43.762985665532476,
              "lng": -116.07110823199704,
              "alt": 1855.6359215618866,
              "speed2d": 3.7888530282404904,
              "speed3d": 4.635847900278216,
              "msFromStart": 368000
            },
            {
              "lat": 43.76298986149281,
              "lng": -116.07113038276441,
              "alt": 1852.7332277595349,
              "speed2d": 3.433985374905048,
              "speed3d": 4.414665494899571,
              "msFromStart": 368500
            },
            {
              "lat": 43.76299348712849,
              "lng": -116.07116088633403,
              "alt": 1849.462534748977,
              "speed2d": 2.9670313213196797,
              "speed3d": 4.110644718733707,
              "msFromStart": 369000
            },
            {
              "lat": 43.762998704652034,
              "lng": -116.07116807559612,
              "alt": 1847.2189996246625,
              "speed2d": 2.8129323701093205,
              "speed3d": 4.037842064618577,
              "msFromStart": 369500
            },
            {
              "lat": 43.76300392217558,
              "lng": -116.07117526485821,
              "alt": 1844.975464500348,
              "speed2d": 2.6588334188989613,
              "speed3d": 3.9650394105034463,
              "msFromStart": 370000
            },
            {
              "lat": 43.762993191310684,
              "lng": -116.07116795191257,
              "alt": 1841.259878989449,
              "speed2d": 2.1796860220242786,
              "speed3d": 3.734240753943915,
              "msFromStart": 370500
            },
            {
              "lat": 43.76301706477125,
              "lng": -116.07119210541373,
              "alt": 1840.738303384777,
              "speed2d": 2.405818800962426,
              "speed3d": 3.846256991188818,
              "msFromStart": 371000
            },
            {
              "lat": 43.76304440436647,
              "lng": -116.0712153758589,
              "alt": 1840.270342611305,
              "speed2d": 2.672827576704022,
              "speed3d": 3.9771264315022345,
              "msFromStart": 371500
            },
            {
              "lat": 43.7630570801791,
              "lng": -116.07124558412244,
              "alt": 1839.7420100688694,
              "speed2d": 2.80667349357495,
              "speed3d": 4.053524715422864,
              "msFromStart": 372000
            },
            {
              "lat": 43.76306600239836,
              "lng": -116.0712651928416,
              "alt": 1838.554901235092,
              "speed2d": 2.7527369138661806,
              "speed3d": 4.018239102529277,
              "msFromStart": 372500
            },
            {
              "lat": 43.76308203333333,
              "lng": -116.07129386666668,
              "alt": 1836.6686666666667,
              "speed2d": 2.6756666666666664,
              "speed3d": 3.976666666666667,
              "msFromStart": 373275.4808888889
            },
            {
              "lat": 43.76310655,
              "lng": -116.0713001,
              "alt": 1835.087,
              "speed2d": 2.5355,
              "speed3d": 3.88,
              "msFromStart": 373927.41599999985
            },
            {
              "lat": 43.76311268571428,
              "lng": -116.07130461428571,
              "alt": 1834.0914285714284,
              "speed2d": 2.4965714285714284,
              "speed3d": 3.855714285714286,
              "msFromStart": 374253.8194285712
            },
            {
              "lat": 43.76312106,
              "lng": -116.07130710000001,
              "alt": 1833.2529999999997,
              "speed2d": 2.4604,
              "speed3d": 3.8280000000000003,
              "msFromStart": 374615.3422222222
            },
            {
              "lat": 43.76313222583267,
              "lng": -116.0713244526791,
              "alt": 1832.2191165212485,
              "speed2d": 2.3771279936583856,
              "speed3d": 3.7397808287092396,
              "msFromStart": 375000
            },
            {
              "lat": 43.7631431191984,
              "lng": -116.07130281119251,
              "alt": 1830.9525811989859,
              "speed2d": 2.4817043046708855,
              "speed3d": 3.8160343888225206,
              "msFromStart": 375500
            },
            {
              "lat": 43.76315401256414,
              "lng": -116.07128116970593,
              "alt": 1829.6860458767235,
              "speed2d": 2.586280615683385,
              "speed3d": 3.8922879489358015,
              "msFromStart": 376000
            },
            {
              "lat": 43.76317051958445,
              "lng": -116.07125713090926,
              "alt": 1827.6892548861942,
              "speed2d": 2.619553589999244,
              "speed3d": 3.937800864130024,
              "msFromStart": 376500
            },
            {
              "lat": 43.76316497777778,
              "lng": -116.07123721111111,
              "alt": 1828.0124444444446,
              "speed2d": 3.0206666666666666,
              "speed3d": 4.182222222222222,
              "msFromStart": 377259.00444444455
            },
            {
              "lat": 43.763162699999995,
              "lng": -116.0712427,
              "alt": 1827.733,
              "speed2d": 3.117,
              "speed3d": 4.25,
              "msFromStart": 377699.8989473685
            },
            {
              "lat": 43.76316343341296,
              "lng": -116.07125106460487,
              "alt": 1827.464764978659,
              "speed2d": 3.123547090222243,
              "speed3d": 4.24589682333318,
              "msFromStart": 378000
            },
            {
              "lat": 43.76315649599171,
              "lng": -116.07125787438841,
              "alt": 1827.4039929676878,
              "speed2d": 3.169504034190253,
              "speed3d": 4.239320015541024,
              "msFromStart": 378500
            },
            {
              "lat": 43.7631837,
              "lng": -116.07129555,
              "alt": 1824.2635,
              "speed2d": 3.069,
              "speed3d": 4.195,
              "msFromStart": 379430.1493333329
            },
            {
              "lat": 43.763198233333334,
              "lng": -116.07130416666666,
              "alt": 1823.2383333333332,
              "speed2d": 3.205,
              "speed3d": 4.3,
              "msFromStart": 379786.7164444443
            },
            {
              "lat": 43.76321275,
              "lng": -116.071312,
              "alt": 1821.649,
              "speed2d": 3.3255,
              "speed3d": 4.405,
              "msFromStart": 380170.6026666664
            },
            {
              "lat": 43.7632482,
              "lng": -116.07132693333334,
              "alt": 1818.0366666666669,
              "speed2d": 3.6900000000000004,
              "speed3d": 4.723333333333334,
              "msFromStart": 380884.5297777777
            },
            {
              "lat": 43.763256399510254,
              "lng": -116.07133499731029,
              "alt": 1816.1973570024707,
              "speed2d": 3.877249391985699,
              "speed3d": 4.862982886715479,
              "msFromStart": 381000
            },
            {
              "lat": 43.76327907706968,
              "lng": -116.07134167688234,
              "alt": 1815.1574878227411,
              "speed2d": 3.779117407538599,
              "speed3d": 4.813504575229546,
              "msFromStart": 381500
            },
            {
              "lat": 43.76331236,
              "lng": -116.07136032,
              "alt": 1812.4192,
              "speed2d": 3.4436,
              "speed3d": 4.617999999999999,
              "msFromStart": 382327.38560000045
            },
            {
              "lat": 43.76332493333334,
              "lng": -116.07137130000001,
              "alt": 1810.7896666666666,
              "speed2d": 3.3073333333333332,
              "speed3d": 4.526666666666666,
              "msFromStart": 382646.6346666667
            },
            {
              "lat": 43.763334758102694,
              "lng": -116.07137720611607,
              "alt": 1808.6361841454486,
              "speed2d": 3.1807272313527983,
              "speed3d": 4.45358482100971,
              "msFromStart": 383000
            },
            {
              "lat": 43.76333994492817,
              "lng": -116.0713777520977,
              "alt": 1805.9745237034222,
              "speed2d": 2.8488917300904673,
              "speed3d": 4.277657407177761,
              "msFromStart": 383500
            },
            {
              "lat": 43.76332904752221,
              "lng": -116.07137327409339,
              "alt": 1802.4747686615622,
              "speed2d": 2.15048519051706,
              "speed3d": 3.9191475222091725,
              "msFromStart": 384000
            },
            {
              "lat": 43.763370800000004,
              "lng": -116.07138405,
              "alt": 1799.205,
              "speed2d": 2.268,
              "speed3d": 3.975,
              "msFromStart": 384928.06933333346
            },
            {
              "lat": 43.76337365,
              "lng": -116.07138269999999,
              "alt": 1798.771,
              "speed2d": 2.2344999999999997,
              "speed3d": 3.9850000000000003,
              "msFromStart": 385038.31288888905
            },
            {
              "lat": 43.76338314464751,
              "lng": -116.07137670026664,
              "alt": 1796.7544301104633,
              "speed2d": 2.102136379643671,
              "speed3d": 3.9106323801036362,
              "msFromStart": 385500
            },
            {
              "lat": 43.76340219569106,
              "lng": -116.07138235999311,
              "alt": 1793.9442748542845,
              "speed2d": 2.3436854198259263,
              "speed3d": 4.06728552331807,
              "msFromStart": 386000
            },
            {
              "lat": 43.76343285555555,
              "lng": -116.07139120000001,
              "alt": 1790.081111111111,
              "speed2d": 2.700333333333333,
              "speed3d": 4.307777777777778,
              "msFromStart": 386771.1680000002
            },
            {
              "lat": 43.763446699999996,
              "lng": -116.07138671111112,
              "alt": 1788.244777777778,
              "speed2d": 2.846111111111111,
              "speed3d": 4.407777777777778,
              "msFromStart": 387265.9520000004
            },
            {
              "lat": 43.76345678571428,
              "lng": -116.0713829,
              "alt": 1786.9935714285716,
              "speed2d": 2.8907142857142856,
              "speed3d": 4.43,
              "msFromStart": 387705.8133333333
            },
            {
              "lat": 43.763471966666664,
              "lng": -116.07138626666668,
              "alt": 1784.650333333333,
              "speed2d": 2.8600000000000003,
              "speed3d": 4.42,
              "msFromStart": 388329.076148148
            },
            {
              "lat": 43.76347883151003,
              "lng": -116.07138995438845,
              "alt": 1784.2206025845817,
              "speed2d": 2.8474512081048857,
              "speed3d": 4.397248773187127,
              "msFromStart": 388500
            },
            {
              "lat": 43.763503330360955,
              "lng": -116.07141049375284,
              "alt": 1782.5269819197147,
              "speed2d": 2.9767321625121794,
              "speed3d": 4.481607154418929,
              "msFromStart": 389000
            },
            {
              "lat": 43.76353535,
              "lng": -116.07143485,
              "alt": 1780.539,
              "speed2d": 3.1835,
              "speed3d": 4.6,
              "msFromStart": 389683.97866666666
            },
            {
              "lat": 43.763547075998844,
              "lng": -116.07144675110604,
              "alt": 1779.3075535453975,
              "speed2d": 3.2155256284409135,
              "speed3d": 4.616958682467174,
              "msFromStart": 390000
            },
            {
              "lat": 43.7635606072908,
              "lng": -116.07146313403389,
              "alt": 1777.4718828232365,
              "speed2d": 3.1148796552490223,
              "speed3d": 4.5610442529161235,
              "msFromStart": 390500
            },
            {
              "lat": 43.76357413858275,
              "lng": -116.07147951696174,
              "alt": 1775.6362121010754,
              "speed2d": 3.0142336820571316,
              "speed3d": 4.505129823365073,
              "msFromStart": 391000
            },
            {
              "lat": 43.763587669874696,
              "lng": -116.07149589988961,
              "alt": 1773.8005413789144,
              "speed2d": 2.913587708865241,
              "speed3d": 4.449215393814023,
              "msFromStart": 391500
            },
            {
              "lat": 43.763601201166644,
              "lng": -116.07151228281747,
              "alt": 1771.9648706567534,
              "speed2d": 2.81294173567335,
              "speed3d": 4.393300964262973,
              "msFromStart": 392000
            },
            {
              "lat": 43.7636147324586,
              "lng": -116.07152866574533,
              "alt": 1770.1291999345924,
              "speed2d": 2.712295762481459,
              "speed3d": 4.337386534711921,
              "msFromStart": 392500
            },
            {
              "lat": 43.76362405286363,
              "lng": -116.07155607817825,
              "alt": 1768.3488608834532,
              "speed2d": 2.375457666494634,
              "speed3d": 4.139036041559147,
              "msFromStart": 393000
            },
            {
              "lat": 43.76364267710905,
              "lng": -116.07155642026231,
              "alt": 1766.512281593451,
              "speed2d": 2.56793431149261,
              "speed3d": 4.25671668337484,
              "msFromStart": 393500
            },
            {
              "lat": 43.76367235,
              "lng": -116.0715623,
              "alt": 1763.6105,
              "speed2d": 2.9764999999999997,
              "speed3d": 4.515,
              "msFromStart": 394196.2026666667
            },
            {
              "lat": 43.76370745,
              "lng": -116.0715704,
              "alt": 1760.449,
              "speed2d": 3.459,
              "speed3d": 4.87,
              "msFromStart": 394937.2746666665
            },
            {
              "lat": 43.76371198333333,
              "lng": -116.07157841666668,
              "alt": 1759.2138333333332,
              "speed2d": 3.4466666666666663,
              "speed3d": 4.881666666666667,
              "msFromStart": 395184.0106666664
            },
            {
              "lat": 43.763714925625365,
              "lng": -116.0715871115474,
              "alt": 1757.596985456661,
              "speed2d": 3.360556500872594,
              "speed3d": 4.845364310645722,
              "msFromStart": 395500
            },
            {
              "lat": 43.763728775000004,
              "lng": -116.071642575,
              "alt": 1755.6209999999999,
              "speed2d": 3.6225,
              "speed3d": 4.995,
              "msFromStart": 396214.3439999997
            },
            {
              "lat": 43.76373838429357,
              "lng": -116.07166300450602,
              "alt": 1754.8594814907055,
              "speed2d": 3.7681638241811597,
              "speed3d": 5.110202768346631,
              "msFromStart": 396500
            },
            {
              "lat": 43.76375066706832,
              "lng": -116.07171354247659,
              "alt": 1754.1825287785289,
              "speed2d": 4.135357697717715,
              "speed3d": 5.390223561073574,
              "msFromStart": 397000
            },
            {
              "lat": 43.76379335,
              "lng": -116.07173795,
              "alt": 1751.145,
              "speed2d": 4.364,
              "speed3d": 5.545,
              "msFromStart": 397769.32622222224
            },
            {
              "lat": 43.763806639049044,
              "lng": -116.0717436362125,
              "alt": 1750.0536679272418,
              "speed2d": 4.43525613224094,
              "speed3d": 5.601817019254448,
              "msFromStart": 398000
            },
            {
              "lat": 43.763844866666666,
              "lng": -116.07176000000001,
              "alt": 1746.7326666666668,
              "speed2d": 4.625333333333333,
              "speed3d": 5.75,
              "msFromStart": 398758.69866666675
            },
            {
              "lat": 43.7638709,
              "lng": -116.071771,
              "alt": 1745.837,
              "speed2d": 4.9575,
              "speed3d": 6.025,
              "msFromStart": 399335.890666667
            },
            {
              "lat": 43.763888050000006,
              "lng": -116.0717779,
              "alt": 1745.3964999999998,
              "speed2d": 5.2035,
              "speed3d": 6.205,
              "msFromStart": 399693.7813333334
            },
            {
              "lat": 43.76390591486371,
              "lng": -116.07178638844077,
              "alt": 1745.6305467382392,
              "speed2d": 5.4989578154964995,
              "speed3d": 6.410440382351844,
              "msFromStart": 400000
            },
            {
              "lat": 43.763960350000005,
              "lng": -116.0718024,
              "alt": 1743.2175,
              "speed2d": 6.220000000000001,
              "speed3d": 7.025,
              "msFromStart": 400796.8657777777
            },
            {
              "lat": 43.76401586666667,
              "lng": -116.07180546666666,
              "alt": 1739.2396666666668,
              "speed2d": 7.397333333333334,
              "speed3d": 8.05,
              "msFromStart": 401292.9777777775
            },
            {
              "lat": 43.76403587221493,
              "lng": -116.07180006206009,
              "alt": 1738.4343063376587,
              "speed2d": 7.788516257756159,
              "speed3d": 8.45491051047431,
              "msFromStart": 401500
            },
            {
              "lat": 43.764122799999996,
              "lng": -116.071769375,
              "alt": 1735.26,
              "speed2d": 9.47925,
              "speed3d": 10.02,
              "msFromStart": 402364.22222222266
            },
            {
              "lat": 43.7641826,
              "lng": -116.07174939999999,
              "alt": 1734.5729999999999,
              "speed2d": 10.3005,
              "speed3d": 10.795,
              "msFromStart": 402915.26315789495
            },
            {
              "lat": 43.76422065,
              "lng": -116.07172605,
              "alt": 1733.9859999999999,
              "speed2d": 10.861,
              "speed3d": 11.325,
              "msFromStart": 403273.90989473724
            },
            {
              "lat": 43.76424377490593,
              "lng": -116.0717092940834,
              "alt": 1733.5241857701244,
              "speed2d": 11.213636278813913,
              "speed3d": 11.656556537145923,
              "msFromStart": 403500
            },
            {
              "lat": 43.764289033236025,
              "lng": -116.07166334484022,
              "alt": 1732.038225660637,
              "speed2d": 11.89195732895832,
              "speed3d": 12.327893968645713,
              "msFromStart": 404000
            },
            {
              "lat": 43.76432747319862,
              "lng": -116.07161133015398,
              "alt": 1731.2015527905921,
              "speed2d": 12.204893866545275,
              "speed3d": 12.631367357546512,
              "msFromStart": 404500
            },
            {
              "lat": 43.76436591316122,
              "lng": -116.07155931546777,
              "alt": 1730.3648799205475,
              "speed2d": 12.517830404132228,
              "speed3d": 12.934840746447312,
              "msFromStart": 405000
            },
            {
              "lat": 43.76442762857143,
              "lng": -116.07147837142858,
              "alt": 1728.8058571428571,
              "speed2d": 12.93142857142857,
              "speed3d": 13.364285714285716,
              "msFromStart": 405799.5982222222
            },
            {
              "lat": 43.764466299999995,
              "lng": -116.07143004999999,
              "alt": 1727.2939999999999,
              "speed2d": 12.91375,
              "speed3d": 13.362499999999999,
              "msFromStart": 406322.14044444426
            },
            {
              "lat": 43.764496525000006,
              "lng": -116.07139289999999,
              "alt": 1725.78225,
              "speed2d": 12.67,
              "speed3d": 13.197500000000002,
              "msFromStart": 406762.4193684211
            },
            {
              "lat": 43.764531757142855,
              "lng": -116.07134740000001,
              "alt": 1724.1651428571429,
              "speed2d": 12.418285714285714,
              "speed3d": 12.889999999999999,
              "msFromStart": 407285.475368421
            },
            {
              "lat": 43.76454611089878,
              "lng": -116.07132853166569,
              "alt": 1723.5736232397296,
              "speed2d": 12.319671569411673,
              "speed3d": 12.804886825195709,
              "msFromStart": 407500
            },
            {
              "lat": 43.76457864342582,
              "lng": -116.0712873116817,
              "alt": 1722.4856919163888,
              "speed2d": 12.06304627229213,
              "speed3d": 12.550442479609762,
              "msFromStart": 408000
            },
            {
              "lat": 43.76461819834553,
              "lng": -116.07124039323821,
              "alt": 1721.849726548068,
              "speed2d": 12.049755544668773,
              "speed3d": 12.504549128877304,
              "msFromStart": 408500
            },
            {
              "lat": 43.76463996684733,
              "lng": -116.07120790793553,
              "alt": 1720.0690152586835,
              "speed2d": 11.42014349286069,
              "speed3d": 11.930434795921602,
              "msFromStart": 409000
            },
            {
              "lat": 43.76464653435179,
              "lng": -116.07116681419157,
              "alt": 1717.663014404754,
              "speed2d": 10.669258768813348,
              "speed3d": 11.289749370195686,
              "msFromStart": 409500
            },
            {
              "lat": 43.76468840747045,
              "lng": -116.07114067337382,
              "alt": 1716.4112982688457,
              "speed2d": 10.289008623474356,
              "speed3d": 10.866310961824563,
              "msFromStart": 410000
            },
            {
              "lat": 43.7647399,
              "lng": -116.07113025000001,
              "alt": 1716.12,
              "speed2d": 9.4525,
              "speed3d": 10.08,
              "msFromStart": 410614.36
            },
            {
              "lat": 43.76476844055944,
              "lng": -116.07111244056242,
              "alt": 1715.6531888012241,
              "speed2d": 9.241367356032114,
              "speed3d": 9.843647969034791,
              "msFromStart": 411000
            },
            {
              "lat": 43.76482875,
              "lng": -116.07107035,
              "alt": 1714.821,
              "speed2d": 8.9695,
              "speed3d": 9.55,
              "msFromStart": 411797.4214736841
            },
            {
              "lat": 43.7648732,
              "lng": -116.07103235,
              "alt": 1713.955,
              "speed2d": 8.846499999999999,
              "speed3d": 9.42,
              "msFromStart": 412429.89726315753
            },
            {
              "lat": 43.764891649999996,
              "lng": -116.07101465,
              "alt": 1713.269,
              "speed2d": 8.73,
              "speed3d": 9.315000000000001,
              "msFromStart": 412732.3463859648
            },
            {
              "lat": 43.764915020000004,
              "lng": -116.07099114000002,
              "alt": 1712.3798,
              "speed2d": 8.5866,
              "speed3d": 9.184,
              "msFromStart": 413117.2586666669
            },
            {
              "lat": 43.7649562,
              "lng": -116.0709442,
              "alt": 1711.3495,
              "speed2d": 8.3025,
              "speed3d": 8.91,
              "msFromStart": 413803.95200000005
            },
            {
              "lat": 43.76497722650721,
              "lng": -116.07093452431491,
              "alt": 1711.6962501963194,
              "speed2d": 8.178714397318792,
              "speed3d": 8.78932579357698,
              "msFromStart": 414000
            },
            {
              "lat": 43.7650290952116,
              "lng": -116.07090165704467,
              "alt": 1713.0970222523558,
              "speed2d": 7.800527068378561,
              "speed3d": 8.377558446962775,
              "msFromStart": 414500
            },
            {
              "lat": 43.765117233333335,
              "lng": -116.0708918,
              "alt": 1713.186333333333,
              "speed2d": 7.065666666666667,
              "speed3d": 7.736666666666666,
              "msFromStart": 415351.94429629634
            },
            {
              "lat": 43.76515302857143,
              "lng": -116.07089057142856,
              "alt": 1713.0867142857142,
              "speed2d": 6.824285714285714,
              "speed3d": 7.498571428571428,
              "msFromStart": 415732.09117460327
            },
            {
              "lat": 43.76520703333334,
              "lng": -116.07088566666667,
              "alt": 1712.9723333333332,
              "speed2d": 6.675333333333334,
              "speed3d": 7.3100000000000005,
              "msFromStart": 416324.52207407437
            },
            {
              "lat": 43.765242,
              "lng": -116.0708788,
              "alt": 1712.8674999999998,
              "speed2d": 6.8149999999999995,
              "speed3d": 7.4,
              "msFromStart": 416691.66989473684
            },
            {
              "lat": 43.76527567528166,
              "lng": -116.07087793979262,
              "alt": 1713.0640693563428,
              "speed2d": 6.93847931277899,
              "speed3d": 7.4967993752536275,
              "msFromStart": 417000
            },
            {
              "lat": 43.765311184966905,
              "lng": -116.07088305731068,
              "alt": 1711.2040029065076,
              "speed2d": 6.9794979412239595,
              "speed3d": 7.570064589052155,
              "msFromStart": 417500
            },
            {
              "lat": 43.7654445,
              "lng": -116.07087383333334,
              "alt": 1716.4196666666667,
              "speed2d": 7.549666666666667,
              "speed3d": 8.023333333333333,
              "msFromStart": 418343.96444444475
            },
            {
              "lat": 43.765490879999994,
              "lng": -116.07086988,
              "alt": 1718.3975999999998,
              "speed2d": 7.7508,
              "speed3d": 8.19,
              "msFromStart": 418619.1427555556
            },
            {
              "lat": 43.765515765012935,
              "lng": -116.07086468992863,
              "alt": 1719.365107107028,
              "speed2d": 7.737709675034382,
              "speed3d": 8.190937400365431,
              "msFromStart": 419000
            },
            {
              "lat": 43.765528806893826,
              "lng": -116.07085765660857,
              "alt": 1719.7123492856576,
              "speed2d": 7.600571133595301,
              "speed3d": 8.06344914148032,
              "msFromStart": 419500
            },
            {
              "lat": 43.76554184877472,
              "lng": -116.07085062328852,
              "alt": 1720.059591464287,
              "speed2d": 7.463432592156219,
              "speed3d": 7.9359608825952055,
              "msFromStart": 420000
            },
            {
              "lat": 43.76555489065561,
              "lng": -116.07084358996846,
              "alt": 1720.4068336429166,
              "speed2d": 7.326294050717138,
              "speed3d": 7.808472623710092,
              "msFromStart": 420500
            },
            {
              "lat": 43.7655679325365,
              "lng": -116.07083655664842,
              "alt": 1720.7540758215462,
              "speed2d": 7.189155509278056,
              "speed3d": 7.680984364824979,
              "msFromStart": 421000
            },
            {
              "lat": 43.76558097441739,
              "lng": -116.07082952332837,
              "alt": 1721.1013180001758,
              "speed2d": 7.052016967838974,
              "speed3d": 7.553496105939866,
              "msFromStart": 421500
            },
            {
              "lat": 43.76559401629828,
              "lng": -116.07082249000831,
              "alt": 1721.4485601788053,
              "speed2d": 6.914878426399893,
              "speed3d": 7.426007847054752,
              "msFromStart": 422000
            },
            {
              "lat": 43.76560705817917,
              "lng": -116.07081545668827,
              "alt": 1721.7958023574347,
              "speed2d": 6.777739884960812,
              "speed3d": 7.298519588169639,
              "msFromStart": 422500
            },
            {
              "lat": 43.76562010006006,
              "lng": -116.07080842336822,
              "alt": 1722.1430445360643,
              "speed2d": 6.6406013435217295,
              "speed3d": 7.171031329284526,
              "msFromStart": 423000
            },
            {
              "lat": 43.76563314194095,
              "lng": -116.07080139004816,
              "alt": 1722.4902867146939,
              "speed2d": 6.503462802082648,
              "speed3d": 7.043543070399412,
              "msFromStart": 423500
            },
            {
              "lat": 43.76564618382184,
              "lng": -116.07079435672811,
              "alt": 1722.8375288933235,
              "speed2d": 6.366324260643567,
              "speed3d": 6.916054811514299,
              "msFromStart": 424000
            },
            {
              "lat": 43.765659225702734,
              "lng": -116.07078732340806,
              "alt": 1723.184771071953,
              "speed2d": 6.2291857192044855,
              "speed3d": 6.788566552629186,
              "msFromStart": 424500
            },
            {
              "lat": 43.765672267583625,
              "lng": -116.07078029008801,
              "alt": 1723.5320132505824,
              "speed2d": 6.092047177765403,
              "speed3d": 6.6610782937440725,
              "msFromStart": 425000
            },
            {
              "lat": 43.765685309464516,
              "lng": -116.07077325676796,
              "alt": 1723.879255429212,
              "speed2d": 5.954908636326322,
              "speed3d": 6.5335900348589595,
              "msFromStart": 425500
            },
            {
              "lat": 43.76569835134541,
              "lng": -116.0707662234479,
              "alt": 1724.2264976078416,
              "speed2d": 5.817770094887241,
              "speed3d": 6.406101775973847,
              "msFromStart": 426000
            },
            {
              "lat": 43.7657113932263,
              "lng": -116.07075919012786,
              "alt": 1724.5737397864712,
              "speed2d": 5.680631553448159,
              "speed3d": 6.278613517088734,
              "msFromStart": 426500
            },
            {
              "lat": 43.76572443510719,
              "lng": -116.0707521568078,
              "alt": 1724.9209819651007,
              "speed2d": 5.543493012009078,
              "speed3d": 6.15112525820362,
              "msFromStart": 427000
            },
            {
              "lat": 43.76573747698808,
              "lng": -116.07074512348775,
              "alt": 1725.2682241437303,
              "speed2d": 5.406354470569996,
              "speed3d": 6.023636999318507,
              "msFromStart": 427500
            },
            {
              "lat": 43.76575051886897,
              "lng": -116.07073809016771,
              "alt": 1725.6154663223597,
              "speed2d": 5.269215929130914,
              "speed3d": 5.896148740433393,
              "msFromStart": 428000
            },
            {
              "lat": 43.76576356074986,
              "lng": -116.07073105684765,
              "alt": 1725.9627085009893,
              "speed2d": 5.132077387691833,
              "speed3d": 5.768660481548281,
              "msFromStart": 428500
            },
            {
              "lat": 43.76577660263075,
              "lng": -116.0707240235276,
              "alt": 1726.3099506796189,
              "speed2d": 4.994938846252751,
              "speed3d": 5.641172222663167,
              "msFromStart": 429000
            },
            {
              "lat": 43.76578964451164,
              "lng": -116.07071699020756,
              "alt": 1726.6571928582484,
              "speed2d": 4.85780030481367,
              "speed3d": 5.513683963778054,
              "msFromStart": 429500
            },
            {
              "lat": 43.76580268639253,
              "lng": -116.0707099568875,
              "alt": 1727.004435036878,
              "speed2d": 4.720661763374588,
              "speed3d": 5.386195704892941,
              "msFromStart": 430000
            },
            {
              "lat": 43.765815728273424,
              "lng": -116.07070292356745,
              "alt": 1727.3516772155074,
              "speed2d": 4.583523221935506,
              "speed3d": 5.258707446007827,
              "msFromStart": 430500
            },
            {
              "lat": 43.765828770154314,
              "lng": -116.07069589024739,
              "alt": 1727.698919394137,
              "speed2d": 4.4463846804964255,
              "speed3d": 5.131219187122714,
              "msFromStart": 431000
            },
            {
              "lat": 43.765841812035205,
              "lng": -116.07068885692735,
              "alt": 1728.0461615727666,
              "speed2d": 4.309246139057343,
              "speed3d": 5.0037309282376015,
              "msFromStart": 431500
            },
            {
              "lat": 43.765854853916096,
              "lng": -116.0706818236073,
              "alt": 1728.3934037513961,
              "speed2d": 4.172107597618261,
              "speed3d": 4.876242669352488,
              "msFromStart": 432000
            },
            {
              "lat": 43.76586789579699,
              "lng": -116.07067479028724,
              "alt": 1728.7406459300257,
              "speed2d": 4.034969056179181,
              "speed3d": 4.748754410467374,
              "msFromStart": 432500
            },
            {
              "lat": 43.76588093767787,
              "lng": -116.0706677569672,
              "alt": 1729.087888108655,
              "speed2d": 3.897830514740099,
              "speed3d": 4.621266151582262,
              "msFromStart": 433000
            },
            {
              "lat": 43.76589397955876,
              "lng": -116.07066072364715,
              "alt": 1729.4351302872847,
              "speed2d": 3.760691973301017,
              "speed3d": 4.493777892697148,
              "msFromStart": 433500
            },
            {
              "lat": 43.76590702143965,
              "lng": -116.07065369032709,
              "alt": 1729.7823724659142,
              "speed2d": 3.623553431861936,
              "speed3d": 4.366289633812034,
              "msFromStart": 434000
            },
            {
              "lat": 43.76592006332054,
              "lng": -116.07064665700705,
              "alt": 1730.1296146445438,
              "speed2d": 3.4864148904228545,
              "speed3d": 4.238801374926921,
              "msFromStart": 434500
            },
            {
              "lat": 43.765933105201434,
              "lng": -116.07063962368699,
              "alt": 1730.4768568231734,
              "speed2d": 3.349276348983773,
              "speed3d": 4.111313116041808,
              "msFromStart": 435000
            },
            {
              "lat": 43.765946147082325,
              "lng": -116.07063259036694,
              "alt": 1730.8240990018028,
              "speed2d": 3.212137807544691,
              "speed3d": 3.9838248571566943,
              "msFromStart": 435500
            },
            {
              "lat": 43.765959188963215,
              "lng": -116.0706255570469,
              "alt": 1731.1713411804324,
              "speed2d": 3.0749992661056096,
              "speed3d": 3.8563365982715814,
              "msFromStart": 436000
            },
            {
              "lat": 43.765972230844106,
              "lng": -116.07061852372684,
              "alt": 1731.518583359062,
              "speed2d": 2.9378607246665283,
              "speed3d": 3.7288483393864684,
              "msFromStart": 436500
            },
            {
              "lat": 43.765985272725,
              "lng": -116.07061149040679,
              "alt": 1731.8658255376915,
              "speed2d": 2.800722183227446,
              "speed3d": 3.6013600805013546,
              "msFromStart": 437000
            }
          ]
        }
      ]
    },
    {
      "id": "trail-second-chance",
      "pathType": "trail",
      "name": "Second Chance",
      "difficulty": "black",
      "videos": [
        {
          "videoId": "GX010075",
          "pathId": "trail-second-chance",
          "startSec": 1,
          "endSec": 78,
          "id": "video-segment-36",
          "gps": [
            {
              "lat": 43.76353927777778,
              "lng": -116.06802250000001,
              "alt": 1890.8918888888888,
              "speed2d": 0.06966666666666667,
              "speed3d": 0.1322222222222222,
              "msFromStart": 1267.219093567252
            },
            {
              "lat": 43.763539755555556,
              "lng": -116.06802264444447,
              "alt": 1890.9226666666666,
              "speed2d": 0.07355555555555555,
              "speed3d": 0.08777777777777776,
              "msFromStart": 1762.4416666666677
            },
            {
              "lat": 43.763539522222224,
              "lng": -116.06802250000001,
              "alt": 1890.8300000000002,
              "speed2d": 0.08444444444444445,
              "speed3d": 0.12777777777777777,
              "msFromStart": 2257.374537037037
            },
            {
              "lat": 43.76353994444444,
              "lng": -116.06802296666666,
              "alt": 1890.7982222222222,
              "speed2d": 0.11533333333333334,
              "speed3d": 0.14,
              "msFromStart": 2751.4330555555534
            },
            {
              "lat": 43.763539833333326,
              "lng": -116.06802296666666,
              "alt": 1890.6899999999998,
              "speed2d": 0.05922222222222222,
              "speed3d": 0.13,
              "msFromStart": 3245.674222222222
            },
            {
              "lat": 43.76353976666666,
              "lng": -116.06802355555556,
              "alt": 1890.6864444444445,
              "speed2d": 0.13255555555555554,
              "speed3d": 0.14222222222222222,
              "msFromStart": 3740.536055555558
            },
            {
              "lat": 43.76353972222222,
              "lng": -116.0680254111111,
              "alt": 1890.8147777777779,
              "speed2d": 0.34255555555555556,
              "speed3d": 0.3411111111111111,
              "msFromStart": 4235.589092592593
            },
            {
              "lat": 43.7635399,
              "lng": -116.06802798888887,
              "alt": 1890.8662222222222,
              "speed2d": 0.3882222222222222,
              "speed3d": 0.3822222222222222,
              "msFromStart": 4731.072444444447
            },
            {
              "lat": 43.76354172222223,
              "lng": -116.06802961111111,
              "alt": 1890.8984444444443,
              "speed2d": 0.38988888888888884,
              "speed3d": 0.3844444444444445,
              "msFromStart": 5226.155224171542
            },
            {
              "lat": 43.76354263,
              "lng": -116.06803164000002,
              "alt": 1890.8777,
              "speed2d": 0.3759,
              "speed3d": 0.383,
              "msFromStart": 5747.526684210528
            },
            {
              "lat": 43.763542433333335,
              "lng": -116.06803474444446,
              "alt": 1890.8582222222221,
              "speed2d": 0.45666666666666667,
              "speed3d": 0.4411111111111111,
              "msFromStart": 6269.464423001949
            },
            {
              "lat": 43.763543644444454,
              "lng": -116.06803691111111,
              "alt": 1890.8406666666667,
              "speed2d": 0.4478888888888889,
              "speed3d": 0.46444444444444444,
              "msFromStart": 6765.665888888892
            },
            {
              "lat": 43.76354357777778,
              "lng": -116.06803684444442,
              "alt": 1890.6463333333331,
              "speed2d": 0.1852222222222222,
              "speed3d": 0.20222222222222222,
              "msFromStart": 7261.531055555555
            },
            {
              "lat": 43.763544077777766,
              "lng": -116.06803887777778,
              "alt": 1890.5397777777778,
              "speed2d": 0.38711111111111113,
              "speed3d": 0.3555555555555556,
              "msFromStart": 7756.240388888886
            },
            {
              "lat": 43.763545566666664,
              "lng": -116.06804355555556,
              "alt": 1890.7182222222223,
              "speed2d": 0.7796666666666667,
              "speed3d": 0.741111111111111,
              "msFromStart": 8251.063888888886
            },
            {
              "lat": 43.763547200000005,
              "lng": -116.06805128888888,
              "alt": 1891.0878888888888,
              "speed2d": 1.087,
              "speed3d": 1.0611111111111111,
              "msFromStart": 8746.362388888883
            },
            {
              "lat": 43.763548244444436,
              "lng": -116.06805727777778,
              "alt": 1891.1924444444448,
              "speed2d": 1.1174444444444447,
              "speed3d": 1.1744444444444444,
              "msFromStart": 9241.613166666666
            },
            {
              "lat": 43.76354953333333,
              "lng": -116.0680618777778,
              "alt": 1891.269,
              "speed2d": 0.9940000000000001,
              "speed3d": 1.0144444444444445,
              "msFromStart": 9736.66655555556
            },
            {
              "lat": 43.76355234444445,
              "lng": -116.06807154444445,
              "alt": 1891.7141111111112,
              "speed2d": 1.3036666666666665,
              "speed3d": 1.3044444444444445,
              "msFromStart": 10231.607898635477
            },
            {
              "lat": 43.763556333333334,
              "lng": -116.06808547777779,
              "alt": 1892.5498888888887,
              "speed2d": 1.869,
              "speed3d": 1.8766666666666667,
              "msFromStart": 10726.273315789465
            },
            {
              "lat": 43.76356103,
              "lng": -116.06810034,
              "alt": 1893.3294,
              "speed2d": 2.1155,
              "speed3d": 2.184,
              "msFromStart": 11248.677023684206
            },
            {
              "lat": 43.763567388888895,
              "lng": -116.06811748888889,
              "alt": 1894.0046666666667,
              "speed2d": 2.7031111111111112,
              "speed3d": 2.7455555555555557,
              "msFromStart": 11771.96216666667
            },
            {
              "lat": 43.76357212222222,
              "lng": -116.06813522222222,
              "alt": 1893.8864444444446,
              "speed2d": 3.4071111111111114,
              "speed3d": 3.3855555555555554,
              "msFromStart": 12267.566037037035
            },
            {
              "lat": 43.763575233333334,
              "lng": -116.06815916666667,
              "alt": 1893.5587777777775,
              "speed2d": 4.159222222222223,
              "speed3d": 4.125555555555556,
              "msFromStart": 12762.590055555545
            },
            {
              "lat": 43.763577222222224,
              "lng": -116.06818743333332,
              "alt": 1893.3525555555557,
              "speed2d": 4.742222222222222,
              "speed3d": 4.704444444444444,
              "msFromStart": 13257.624518518514
            },
            {
              "lat": 43.76357635555555,
              "lng": -116.06821495555555,
              "alt": 1892.8226666666667,
              "speed2d": 5.126444444444445,
              "speed3d": 5.104444444444445,
              "msFromStart": 13752.770166666662
            },
            {
              "lat": 43.76357457777778,
              "lng": -116.0682389,
              "alt": 1891.6793333333333,
              "speed2d": 5.221111111111112,
              "speed3d": 5.225555555555555,
              "msFromStart": 14247.724759259261
            },
            {
              "lat": 43.76357646666667,
              "lng": -116.06825704444442,
              "alt": 1889.595111111111,
              "speed2d": 5.203111111111111,
              "speed3d": 5.196666666666667,
              "msFromStart": 14742.144888888892
            },
            {
              "lat": 43.76358805555555,
              "lng": -116.06827092222223,
              "alt": 1887.5415555555553,
              "speed2d": 5.469,
              "speed3d": 5.482222222222222,
              "msFromStart": 15236.719448343083
            },
            {
              "lat": 43.76360251111111,
              "lng": -116.06827757777779,
              "alt": 1885.5241111111109,
              "speed2d": 5.190777777777778,
              "speed3d": 5.366666666666666,
              "msFromStart": 15731.789526315799
            },
            {
              "lat": 43.763619677777776,
              "lng": -116.06827484444445,
              "alt": 1883.9095555555555,
              "speed2d": 4.690999999999999,
              "speed3d": 4.838888888888889,
              "msFromStart": 16226.87722904484
            },
            {
              "lat": 43.76364340000001,
              "lng": -116.06828078000001,
              "alt": 1883.4739000000002,
              "speed2d": 4.9486,
              "speed3d": 5.108,
              "msFromStart": 16749.433916666672
            },
            {
              "lat": 43.76366747777778,
              "lng": -116.06829228888888,
              "alt": 1882.929111111111,
              "speed2d": 5.363888888888889,
              "speed3d": 5.352222222222222,
              "msFromStart": 17272.107129629632
            },
            {
              "lat": 43.7637,
              "lng": -116.06831255555555,
              "alt": 1882.3811111111108,
              "speed2d": 6.859222222222222,
              "speed3d": 6.868888888888889,
              "msFromStart": 17767.603000000006
            },
            {
              "lat": 43.763725933333326,
              "lng": -116.06833128888888,
              "alt": 1881.6785555555557,
              "speed2d": 6.957333333333334,
              "speed3d": 7.130000000000001,
              "msFromStart": 18263.039259259258
            },
            {
              "lat": 43.76374006666667,
              "lng": -116.06834152222223,
              "alt": 1880.977222222222,
              "speed2d": 5.536777777777778,
              "speed3d": 5.934444444444444,
              "msFromStart": 18758.26722222221
            },
            {
              "lat": 43.76373751111112,
              "lng": -116.06832961111111,
              "alt": 1878.5735555555557,
              "speed2d": 3.2084444444444444,
              "speed3d": 3.7166666666666663,
              "msFromStart": 19253.355111111105
            },
            {
              "lat": 43.76373907777778,
              "lng": -116.06831343333333,
              "alt": 1875.9275555555555,
              "speed2d": 2.381555555555556,
              "speed3d": 2.92,
              "msFromStart": 19748.092555555544
            },
            {
              "lat": 43.76374631111111,
              "lng": -116.06831173333333,
              "alt": 1872.5259999999998,
              "speed2d": 3.172111111111111,
              "speed3d": 3.6788888888888893,
              "msFromStart": 20243.258777777774
            },
            {
              "lat": 43.763755644444444,
              "lng": -116.06831714444444,
              "alt": 1869.0405555555558,
              "speed2d": 3.910555555555555,
              "speed3d": 4.567777777777778,
              "msFromStart": 20739.638888888905
            },
            {
              "lat": 43.76376274444444,
              "lng": -116.06831622222224,
              "alt": 1865.7611111111112,
              "speed2d": 3.6604444444444444,
              "speed3d": 4.571111111111112,
              "msFromStart": 21235.65202729045
            },
            {
              "lat": 43.76377144444444,
              "lng": -116.06831454444443,
              "alt": 1862.604111111111,
              "speed2d": 3.5434444444444444,
              "speed3d": 4.594444444444444,
              "msFromStart": 21730.495684210513
            },
            {
              "lat": 43.76378553333334,
              "lng": -116.0683188888889,
              "alt": 1859.5972222222222,
              "speed2d": 4.173222222222222,
              "speed3d": 5.092222222222222,
              "msFromStart": 22225.369621832353
            },
            {
              "lat": 43.763809699999996,
              "lng": -116.06833021000001,
              "alt": 1855.7486999999996,
              "speed2d": 5.7598,
              "speed3d": 6.652,
              "msFromStart": 22748.126333333345
            },
            {
              "lat": 43.76382212222222,
              "lng": -116.0683404888889,
              "alt": 1851.745111111111,
              "speed2d": 5.520555555555556,
              "speed3d": 6.753333333333333,
              "msFromStart": 23270.72274074075
            },
            {
              "lat": 43.76382221111111,
              "lng": -116.06834460000002,
              "alt": 1848.0384444444446,
              "speed2d": 4.135888888888888,
              "speed3d": 5.784444444444444,
              "msFromStart": 23765.268888888902
            },
            {
              "lat": 43.76382927777778,
              "lng": -116.0683453888889,
              "alt": 1844.089111111111,
              "speed2d": 3.9665555555555554,
              "speed3d": 5.647777777777778,
              "msFromStart": 24259.728037037043
            },
            {
              "lat": 43.76384151111111,
              "lng": -116.06834504444444,
              "alt": 1839.5391111111107,
              "speed2d": 4.563000000000001,
              "speed3d": 6.272222222222222,
              "msFromStart": 24754.02744444446
            },
            {
              "lat": 43.76385640000001,
              "lng": -116.06835733333334,
              "alt": 1834.7793333333336,
              "speed2d": 5.534222222222222,
              "speed3d": 7.1688888888888895,
              "msFromStart": 25248.260083820667
            },
            {
              "lat": 43.763875166666665,
              "lng": -116.06836712222223,
              "alt": 1830.7402222222222,
              "speed2d": 5.799222222222222,
              "speed3d": 7.573333333333333,
              "msFromStart": 25742.334315789478
            },
            {
              "lat": 43.763895833333336,
              "lng": -116.0683736888889,
              "alt": 1827.2144444444446,
              "speed2d": 5.878666666666666,
              "speed3d": 7.673333333333332,
              "msFromStart": 26236.590600389864
            },
            {
              "lat": 43.763918966666665,
              "lng": -116.0683846,
              "alt": 1822.792222222222,
              "speed2d": 6.757333333333333,
              "speed3d": 8.425555555555556,
              "msFromStart": 26731.608444444442
            },
            {
              "lat": 43.76394388888889,
              "lng": -116.06839873333334,
              "alt": 1818.4605555555559,
              "speed2d": 7.2748888888888885,
              "speed3d": 9.035555555555558,
              "msFromStart": 27226.779481481484
            },
            {
              "lat": 43.76396736,
              "lng": -116.06842087000001,
              "alt": 1814.5116,
              "speed2d": 7.1001,
              "speed3d": 9.034,
              "msFromStart": 27749.787000000008
            },
            {
              "lat": 43.76399144444444,
              "lng": -116.06845446666668,
              "alt": 1811.4257777777777,
              "speed2d": 6.853,
              "speed3d": 8.815555555555557,
              "msFromStart": 28272.749111111116
            },
            {
              "lat": 43.76401911111111,
              "lng": -116.06849382222224,
              "alt": 1808.9372222222223,
              "speed2d": 7.5361111111111105,
              "speed3d": 9.347777777777777,
              "msFromStart": 28767.976000000013
            },
            {
              "lat": 43.76404521111112,
              "lng": -116.0685306,
              "alt": 1806.5637777777777,
              "speed2d": 7.423444444444445,
              "speed3d": 9.317777777777778,
              "msFromStart": 29262.95788888889
            },
            {
              "lat": 43.764082088888884,
              "lng": -116.06855453333334,
              "alt": 1805.2601111111112,
              "speed2d": 7.259555555555555,
              "speed3d": 9.073333333333334,
              "msFromStart": 29757.304333333323
            },
            {
              "lat": 43.76412534444445,
              "lng": -116.06857407777777,
              "alt": 1803.7664444444445,
              "speed2d": 7.910555555555555,
              "speed3d": 9.476666666666667,
              "msFromStart": 30251.969093567248
            },
            {
              "lat": 43.7641694,
              "lng": -116.06859806666668,
              "alt": 1801.8956666666668,
              "speed2d": 8.96488888888889,
              "speed3d": 10.376666666666665,
              "msFromStart": 30747.588631578947
            },
            {
              "lat": 43.76420363333334,
              "lng": -116.0686256222222,
              "alt": 1799.4225555555556,
              "speed2d": 8.783444444444443,
              "speed3d": 10.343333333333334,
              "msFromStart": 31243.277904483435
            },
            {
              "lat": 43.764227633333334,
              "lng": -116.06865718888888,
              "alt": 1796.6273333333334,
              "speed2d": 8.025666666666666,
              "speed3d": 9.69888888888889,
              "msFromStart": 31739.045111111118
            },
            {
              "lat": 43.76425686666667,
              "lng": -116.06869382222223,
              "alt": 1794.187111111111,
              "speed2d": 7.96988888888889,
              "speed3d": 9.566666666666666,
              "msFromStart": 32234.563444444444
            },
            {
              "lat": 43.76429103333333,
              "lng": -116.06872686666667,
              "alt": 1792.6286666666665,
              "speed2d": 7.736,
              "speed3d": 9.412222222222223,
              "msFromStart": 32729.05511111112
            },
            {
              "lat": 43.76432234,
              "lng": -116.06875575,
              "alt": 1790.6617,
              "speed2d": 6.911899999999999,
              "speed3d": 8.745000000000001,
              "msFromStart": 33250.82686666667
            },
            {
              "lat": 43.7643495,
              "lng": -116.06878123333333,
              "alt": 1789.5176666666669,
              "speed2d": 5.936555555555556,
              "speed3d": 7.924444444444445,
              "msFromStart": 33772.328888888864
            },
            {
              "lat": 43.76437993333332,
              "lng": -116.06880241111112,
              "alt": 1788.4754444444445,
              "speed2d": 5.942555555555556,
              "speed3d": 7.722222222222222,
              "msFromStart": 34266.479111111104
            },
            {
              "lat": 43.76441596666666,
              "lng": -116.06882394444445,
              "alt": 1787.415777777778,
              "speed2d": 6.556111111111111,
              "speed3d": 8.167777777777777,
              "msFromStart": 34760.95022222223
            },
            {
              "lat": 43.76443975555556,
              "lng": -116.06884845555557,
              "alt": 1785.088888888889,
              "speed2d": 6.2858888888888895,
              "speed3d": 8.049999999999999,
              "msFromStart": 35255.491875243664
            },
            {
              "lat": 43.764448599999994,
              "lng": -116.06887005555555,
              "alt": 1781.9336666666666,
              "speed2d": 5.175888888888888,
              "speed3d": 7.262222222222222,
              "msFromStart": 35750.17915789472
            },
            {
              "lat": 43.764469677777775,
              "lng": -116.06889054444444,
              "alt": 1779.958,
              "speed2d": 5.130444444444444,
              "speed3d": 7.061111111111111,
              "msFromStart": 36244.985040935666
            },
            {
              "lat": 43.7645036111111,
              "lng": -116.06892364444444,
              "alt": 1779.257111111111,
              "speed2d": 6.441555555555555,
              "speed3d": 7.942222222222223,
              "msFromStart": 36740.23466666666
            },
            {
              "lat": 43.76453508888889,
              "lng": -116.0689576222222,
              "alt": 1777.795111111111,
              "speed2d": 7.081777777777777,
              "speed3d": 8.518888888888888,
              "msFromStart": 37235.78088888889
            },
            {
              "lat": 43.76456926666667,
              "lng": -116.06898115555556,
              "alt": 1776.8248888888888,
              "speed2d": 6.836444444444445,
              "speed3d": 8.334444444444445,
              "msFromStart": 37732.33600000001
            },
            {
              "lat": 43.76460342222222,
              "lng": -116.06899584444444,
              "alt": 1775.7623333333336,
              "speed2d": 6.610333333333334,
              "speed3d": 8.084444444444443,
              "msFromStart": 38228.70977777778
            },
            {
              "lat": 43.76464059999999,
              "lng": -116.06900521,
              "alt": 1774.7465,
              "speed2d": 6.655500000000001,
              "speed3d": 8.028,
              "msFromStart": 38751.46200000003
            },
            {
              "lat": 43.76468462222222,
              "lng": -116.06901978888888,
              "alt": 1773.5266666666669,
              "speed2d": 7.546222222222221,
              "speed3d": 8.677777777777777,
              "msFromStart": 39274.0494814815
            },
            {
              "lat": 43.76472255555556,
              "lng": -116.06904033333333,
              "alt": 1771.2875555555556,
              "speed2d": 8.057777777777776,
              "speed3d": 9.212222222222222,
              "msFromStart": 39768.988888888925
            },
            {
              "lat": 43.76474893333332,
              "lng": -116.06905758888888,
              "alt": 1767.9628888888888,
              "speed2d": 7.625333333333334,
              "speed3d": 9.018888888888888,
              "msFromStart": 40263.94992592594
            },
            {
              "lat": 43.76476482222222,
              "lng": -116.06907895555554,
              "alt": 1764.8548888888888,
              "speed2d": 6.271444444444445,
              "speed3d": 7.936666666666666,
              "msFromStart": 40758.989777777744
            },
            {
              "lat": 43.76477392222222,
              "lng": -116.0690996888889,
              "alt": 1762.5412222222221,
              "speed2d": 4.331777777777778,
              "speed3d": 6.554444444444444,
              "msFromStart": 41253.61858089666
            },
            {
              "lat": 43.76478916666666,
              "lng": -116.06912262222221,
              "alt": 1760.4099999999999,
              "speed2d": 3.9972222222222222,
              "speed3d": 6.125555555555556,
              "msFromStart": 41747.12042105261
            },
            {
              "lat": 43.7648138,
              "lng": -116.06915418888889,
              "alt": 1757.8122222222223,
              "speed2d": 5.454444444444444,
              "speed3d": 7.120000000000001,
              "msFromStart": 42240.936923976595
            },
            {
              "lat": 43.764839011111114,
              "lng": -116.0691939,
              "alt": 1755.0533333333333,
              "speed2d": 6.708555555555556,
              "speed3d": 8.25,
              "msFromStart": 42736.249333333355
            },
            {
              "lat": 43.7648655,
              "lng": -116.06923342222223,
              "alt": 1753.426,
              "speed2d": 6.800222222222221,
              "speed3d": 8.38,
              "msFromStart": 43231.65614814816
            },
            {
              "lat": 43.764893199999996,
              "lng": -116.0692640111111,
              "alt": 1751.7148888888887,
              "speed2d": 6.381555555555556,
              "speed3d": 8.065555555555555,
              "msFromStart": 43726.842222222214
            },
            {
              "lat": 43.764930379999996,
              "lng": -116.06928221,
              "alt": 1749.409,
              "speed2d": 6.811100000000001,
              "speed3d": 8.395,
              "msFromStart": 44249.67586666666
            },
            {
              "lat": 43.76496853333334,
              "lng": -116.06929507777778,
              "alt": 1748.0932222222223,
              "speed2d": 7.057666666666667,
              "speed3d": 8.598888888888888,
              "msFromStart": 44773.02933333331
            },
            {
              "lat": 43.76500297777778,
              "lng": -116.0693101,
              "alt": 1745.9594444444447,
              "speed2d": 7.222555555555554,
              "speed3d": 8.706666666666667,
              "msFromStart": 45269.01822222222
            },
            {
              "lat": 43.76504296666666,
              "lng": -116.06933505555556,
              "alt": 1743.8853333333332,
              "speed2d": 7.875333333333335,
              "speed3d": 9.238888888888889,
              "msFromStart": 45765.37866666669
            },
            {
              "lat": 43.765069677777774,
              "lng": -116.06936747777777,
              "alt": 1741.2702222222222,
              "speed2d": 7.661888888888888,
              "speed3d": 9.163333333333332,
              "msFromStart": 46261.16608187135
            },
            {
              "lat": 43.76507683333334,
              "lng": -116.06940354444446,
              "alt": 1737.9101111111113,
              "speed2d": 6.861777777777777,
              "speed3d": 8.597777777777779,
              "msFromStart": 46755.34505263156
            },
            {
              "lat": 43.76506246666668,
              "lng": -116.06944267777779,
              "alt": 1734.0012222222222,
              "speed2d": 5.781,
              "speed3d": 7.837777777777778,
              "msFromStart": 47249.53745808966
            },
            {
              "lat": 43.76504088888888,
              "lng": -116.06947824444445,
              "alt": 1729.5921111111109,
              "speed2d": 5.014666666666667,
              "speed3d": 7.323333333333334,
              "msFromStart": 47744.122222222235
            },
            {
              "lat": 43.76503591111111,
              "lng": -116.06951080000002,
              "alt": 1724.7683333333332,
              "speed2d": 4.900666666666667,
              "speed3d": 7.336666666666667,
              "msFromStart": 48238.91585185185
            },
            {
              "lat": 43.765055944444455,
              "lng": -116.06954878888888,
              "alt": 1720.8063333333334,
              "speed2d": 5.455,
              "speed3d": 7.7622222222222215,
              "msFromStart": 48734.38799999998
            },
            {
              "lat": 43.76508701111111,
              "lng": -116.06960079999999,
              "alt": 1718.4963333333333,
              "speed2d": 6.3551111111111105,
              "speed3d": 8.394444444444444,
              "msFromStart": 49229.64207407405
            },
            {
              "lat": 43.76512676,
              "lng": -116.06965961,
              "alt": 1717.4461000000003,
              "speed2d": 7.120699999999999,
              "speed3d": 8.963000000000001,
              "msFromStart": 49751.19666666663
            },
            {
              "lat": 43.765179466666666,
              "lng": -116.06971082222222,
              "alt": 1717.3124444444443,
              "speed2d": 7.705,
              "speed3d": 9.356666666666667,
              "msFromStart": 50273.15262378167
            },
            {
              "lat": 43.765229833333336,
              "lng": -116.06976283333333,
              "alt": 1717.121111111111,
              "speed2d": 8.323666666666666,
              "speed3d": 9.822222222222223,
              "msFromStart": 50769.064210526354
            },
            {
              "lat": 43.76526583333334,
              "lng": -116.06981346666667,
              "alt": 1716.1626666666668,
              "speed2d": 8.437888888888889,
              "speed3d": 9.940000000000001,
              "msFromStart": 51264.98775828462
            },
            {
              "lat": 43.765280555555556,
              "lng": -116.06986276666667,
              "alt": 1714.2796666666668,
              "speed2d": 8.041777777777778,
              "speed3d": 9.63111111111111,
              "msFromStart": 51760.66444444442
            },
            {
              "lat": 43.76529402222222,
              "lng": -116.0699123222222,
              "alt": 1712.669111111111,
              "speed2d": 7.639555555555556,
              "speed3d": 9.266666666666666,
              "msFromStart": 52256.01681481481
            },
            {
              "lat": 43.765316922222226,
              "lng": -116.06996932222224,
              "alt": 1712.978888888889,
              "speed2d": 7.563555555555556,
              "speed3d": 9.092222222222222,
              "msFromStart": 52750.17066666667
            },
            {
              "lat": 43.76535118888889,
              "lng": -116.07004235555556,
              "alt": 1714.7498888888888,
              "speed2d": 8.19511111111111,
              "speed3d": 9.475555555555555,
              "msFromStart": 53244.51503703704
            },
            {
              "lat": 43.765392444444444,
              "lng": -116.07010783333332,
              "alt": 1716.0926666666664,
              "speed2d": 8.646222222222223,
              "speed3d": 9.817777777777778,
              "msFromStart": 53740.10088888892
            },
            {
              "lat": 43.7654334,
              "lng": -116.07016365555556,
              "alt": 1716.7186666666669,
              "speed2d": 8.730444444444444,
              "speed3d": 9.866666666666667,
              "msFromStart": 54235.79866666669
            },
            {
              "lat": 43.76547528888889,
              "lng": -116.07020654444443,
              "alt": 1717.275,
              "speed2d": 8.432666666666668,
              "speed3d": 9.588888888888889,
              "msFromStart": 54731.44755555559
            },
            {
              "lat": 43.76551724444444,
              "lng": -116.07023215555556,
              "alt": 1717.7871111111112,
              "speed2d": 7.827888888888889,
              "speed3d": 9.011111111111113,
              "msFromStart": 55226.5974814815
            },
            {
              "lat": 43.765561,
              "lng": -116.07025774,
              "alt": 1717.6100000000001,
              "speed2d": 7.5335,
              "speed3d": 8.651,
              "msFromStart": 55747.1586666667
            },
            {
              "lat": 43.765605111111114,
              "lng": -116.07028912222222,
              "alt": 1717.3075555555556,
              "speed2d": 7.629111111111111,
              "speed3d": 8.666666666666666,
              "msFromStart": 56268.08576998051
            },
            {
              "lat": 43.76564224444444,
              "lng": -116.07032012222223,
              "alt": 1716.650666666667,
              "speed2d": 7.671888888888888,
              "speed3d": 8.69222222222222,
              "msFromStart": 56763.15494736841
            },
            {
              "lat": 43.76567374444444,
              "lng": -116.07035127777777,
              "alt": 1715.7986666666666,
              "speed2d": 7.490000000000001,
              "speed3d": 8.52111111111111,
              "msFromStart": 57258.46876413255
            },
            {
              "lat": 43.7657172,
              "lng": -116.07038206666665,
              "alt": 1718.9821111111112,
              "speed2d": 7.1641111111111115,
              "speed3d": 8.123333333333333,
              "msFromStart": 57754.439111111096
            },
            {
              "lat": 43.765760722222225,
              "lng": -116.07040673333333,
              "alt": 1722.8766666666666,
              "speed2d": 6.600999999999999,
              "speed3d": 7.518888888888889,
              "msFromStart": 58250.16288888888
            },
            {
              "lat": 43.765800455555556,
              "lng": -116.07042924444443,
              "alt": 1726.1602222222225,
              "speed2d": 6.043555555555555,
              "speed3d": 6.848888888888889,
              "msFromStart": 58744.63777777775
            },
            {
              "lat": 43.76583386666666,
              "lng": -116.07045340000002,
              "alt": 1726.2688888888888,
              "speed2d": 5.985222222222223,
              "speed3d": 6.751111111111111,
              "msFromStart": 59239.22629629629
            },
            {
              "lat": 43.76586243333333,
              "lng": -116.07047194444445,
              "alt": 1725.3534444444444,
              "speed2d": 5.833666666666666,
              "speed3d": 6.628888888888889,
              "msFromStart": 59734.76888888892
            },
            {
              "lat": 43.76588047777777,
              "lng": -116.07048473333333,
              "alt": 1724.3405555555555,
              "speed2d": 5.226555555555556,
              "speed3d": 6.1544444444444455,
              "msFromStart": 60230.278000000006
            },
            {
              "lat": 43.7658845,
              "lng": -116.07048755,
              "alt": 1723.5810000000001,
              "speed2d": 4.5725,
              "speed3d": 5.615,
              "msFromStart": 60532.79411111112
            },
            {
              "lat": 43.765882010753884,
              "lng": -116.07048806851135,
              "alt": 1721.9476604034248,
              "speed2d": 3.803696202594485,
              "speed3d": 5.099555322185845,
              "msFromStart": 61000
            },
            {
              "lat": 43.765879180193814,
              "lng": -116.07048814641668,
              "alt": 1720.2018021100916,
              "speed2d": 3.011918434280763,
              "speed3d": 4.564605436083428,
              "msFromStart": 61500
            },
            {
              "lat": 43.76585873304819,
              "lng": -116.07049148577536,
              "alt": 1716.0290415359575,
              "speed2d": 1.3341882357704524,
              "speed3d": 3.5630962564679756,
              "msFromStart": 62000
            },
            {
              "lat": 43.76588422499999,
              "lng": -116.070485975,
              "alt": 1717.2205000000001,
              "speed2d": 1.53425,
              "speed3d": 3.4775,
              "msFromStart": 62842.462444444485
            },
            {
              "lat": 43.765894749999994,
              "lng": -116.07048093333333,
              "alt": 1717.5181666666667,
              "speed2d": 1.689,
              "speed3d": 3.4899999999999998,
              "msFromStart": 63226.86311111113
            },
            {
              "lat": 43.7659098,
              "lng": -116.0704748,
              "alt": 1717.788,
              "speed2d": 1.8265,
              "speed3d": 3.495,
              "msFromStart": 63805.166000000034
            },
            {
              "lat": 43.7659266,
              "lng": -116.07047065,
              "alt": 1719.154,
              "speed2d": 1.9487500000000002,
              "speed3d": 3.4775,
              "msFromStart": 64287.08916666666
            },
            {
              "lat": 43.76594743333333,
              "lng": -116.07046471666668,
              "alt": 1721.4193333333335,
              "speed2d": 2.1166666666666667,
              "speed3d": 3.475,
              "msFromStart": 64805.388777777785
            },
            {
              "lat": 43.76596207777777,
              "lng": -116.07045968888889,
              "alt": 1722.951111111111,
              "speed2d": 2.197222222222222,
              "speed3d": 3.4577777777777774,
              "msFromStart": 65236.41733333334
            },
            {
              "lat": 43.7659761875,
              "lng": -116.07045178749999,
              "alt": 1724.4196249999998,
              "speed2d": 2.321625,
              "speed3d": 3.46625,
              "msFromStart": 65717.42499999999
            },
            {
              "lat": 43.7659884,
              "lng": -116.07044155,
              "alt": 1725.9161666666669,
              "speed2d": 2.4598333333333335,
              "speed3d": 3.4916666666666663,
              "msFromStart": 66198.36796491224
            },
            {
              "lat": 43.7660014,
              "lng": -116.07042855,
              "alt": 1727.399,
              "speed2d": 2.537,
              "speed3d": 3.4850000000000003,
              "msFromStart": 66802.89884210523
            },
            {
              "lat": 43.766005392223555,
              "lng": -116.07042480767363,
              "alt": 1727.692112719937,
              "speed2d": 2.5358280214691002,
              "speed3d": 3.4691606694005976,
              "msFromStart": 67000
            },
            {
              "lat": 43.766017475,
              "lng": -116.070410475,
              "alt": 1727.8125,
              "speed2d": 2.3655,
              "speed3d": 3.3049999999999997,
              "msFromStart": 67764.07199999994
            },
            {
              "lat": 43.766023049999994,
              "lng": -116.07039947499999,
              "alt": 1727.3952499999998,
              "speed2d": 2.28,
              "speed3d": 3.2162500000000005,
              "msFromStart": 68279.0428333333
            },
            {
              "lat": 43.766029,
              "lng": -116.07039176666667,
              "alt": 1727.5466666666666,
              "speed2d": 2.1376666666666666,
              "speed3d": 3.0900000000000003,
              "msFromStart": 68753.78311111112
            },
            {
              "lat": 43.7660347,
              "lng": -116.07039014285715,
              "alt": 1727.9921428571427,
              "speed2d": 1.780857142857143,
              "speed3d": 2.83,
              "msFromStart": 69249.15720634922
            },
            {
              "lat": 43.76603994,
              "lng": -116.07039,
              "alt": 1727.9444,
              "speed2d": 1.4552,
              "speed3d": 2.592,
              "msFromStart": 69744.15644444448
            },
            {
              "lat": 43.766044699999995,
              "lng": -116.07038594000001,
              "alt": 1728.3467999999998,
              "speed2d": 1.3552,
              "speed3d": 2.48,
              "msFromStart": 70195.13653333337
            },
            {
              "lat": 43.7660475,
              "lng": -116.07038179999999,
              "alt": 1729.0457999999999,
              "speed2d": 1.2621999999999998,
              "speed3d": 2.38,
              "msFromStart": 70668.19840000001
            },
            {
              "lat": 43.766051239999996,
              "lng": -116.07037732,
              "alt": 1729.8732000000002,
              "speed2d": 1.1692,
              "speed3d": 2.272,
              "msFromStart": 71229.31253333334
            },
            {
              "lat": 43.76605523333334,
              "lng": -116.07037729999999,
              "alt": 1730.1305,
              "speed2d": 0.9695,
              "speed3d": 2.15,
              "msFromStart": 71770.45859259256
            },
            {
              "lat": 43.766059299999995,
              "lng": -116.07037981666667,
              "alt": 1730.4451666666666,
              "speed2d": 0.7260000000000001,
              "speed3d": 2.0116666666666667,
              "msFromStart": 72265.67859259258
            },
            {
              "lat": 43.766062675,
              "lng": -116.07038218749999,
              "alt": 1730.1827500000002,
              "speed2d": 0.546,
              "speed3d": 1.9237499999999998,
              "msFromStart": 72783.36588888887
            },
            {
              "lat": 43.766063974999994,
              "lng": -116.070384225,
              "alt": 1730.0167500000002,
              "speed2d": 0.41300000000000003,
              "speed3d": 1.8775000000000002,
              "msFromStart": 73181.79777777777
            },
            {
              "lat": 43.766064775000004,
              "lng": -116.07038385000001,
              "alt": 1730.03475,
              "speed2d": 0.407,
              "speed3d": 1.8425,
              "msFromStart": 73757.65422222226
            },
            {
              "lat": 43.76606615,
              "lng": -116.07038506666667,
              "alt": 1729.9075,
              "speed2d": 0.3445,
              "speed3d": 1.8066666666666669,
              "msFromStart": 74233.24933333336
            },
            {
              "lat": 43.76606755,
              "lng": -116.07038410000001,
              "alt": 1729.7785,
              "speed2d": 0.357,
              "speed3d": 1.79,
              "msFromStart": 74693.05199999997
            },
            {
              "lat": 43.76606841666667,
              "lng": -116.07038171666666,
              "alt": 1729.6458333333333,
              "speed2d": 0.4026666666666667,
              "speed3d": 1.7733333333333332,
              "msFromStart": 75199.02511111108
            },
            {
              "lat": 43.766060124999996,
              "lng": -116.0703842,
              "alt": 1727.92525,
              "speed2d": 0.33799999999999997,
              "speed3d": 1.7625000000000002,
              "msFromStart": 75741.67999999993
            },
            {
              "lat": 43.766044425000004,
              "lng": -116.070385525,
              "alt": 1725.52825,
              "speed2d": 0.469,
              "speed3d": 1.81,
              "msFromStart": 76169.02884210521
            },
            {
              "lat": 43.766020399999995,
              "lng": -116.07039065000001,
              "alt": 1721.7916666666667,
              "speed2d": 0.7170000000000001,
              "speed3d": 1.935,
              "msFromStart": 76750.00603508772
            },
            {
              "lat": 43.765998399999994,
              "lng": -116.07039583333332,
              "alt": 1718.8396666666667,
              "speed2d": 1.0421666666666667,
              "speed3d": 2.1,
              "msFromStart": 77243.84168421052
            },
            {
              "lat": 43.7659822,
              "lng": -116.07039704444446,
              "alt": 1717.0057777777777,
              "speed2d": 1.2338888888888888,
              "speed3d": 2.2433333333333336,
              "msFromStart": 77725.99239506171
            }
          ]
        }
      ]
    },
    {
      "id": "trail-shindig",
      "pathType": "trail",
      "name": "Shindig",
      "difficulty": "blue",
      "videos": [
        {
          "videoId": "GX010068",
          "pathId": "trail-shindig",
          "startSec": 175,
          "endSec": 234,
          "id": "video-segment-30",
          "gps": [
            {
              "lat": 43.76279594444445,
              "lng": -116.08636474444444,
              "alt": 2158.9035555555556,
              "speed2d": 5.082777777777777,
              "speed3d": 5.091111111111111,
              "msFromStart": 175271.41246003905
            },
            {
              "lat": 43.76277332222222,
              "lng": -116.0863529,
              "alt": 2158.6981111111113,
              "speed2d": 5.2330000000000005,
              "speed3d": 5.263333333333333,
              "msFromStart": 175766.12088888878
            },
            {
              "lat": 43.762748511111106,
              "lng": -116.0863488111111,
              "alt": 2158.445333333333,
              "speed2d": 5.619555555555555,
              "speed3d": 5.594444444444445,
              "msFromStart": 176261.02933333322
            },
            {
              "lat": 43.762720433333335,
              "lng": -116.08635260000001,
              "alt": 2157.9655555555555,
              "speed2d": 6.084333333333332,
              "speed3d": 6.108888888888888,
              "msFromStart": 176756.54222222226
            },
            {
              "lat": 43.762695022222225,
              "lng": -116.08636550000001,
              "alt": 2157.691888888889,
              "speed2d": 6.135666666666667,
              "speed3d": 6.174444444444445,
              "msFromStart": 177252.20740740743
            },
            {
              "lat": 43.7626731888889,
              "lng": -116.08638664444445,
              "alt": 2157.349,
              "speed2d": 6.017111111111111,
              "speed3d": 6.117777777777778,
              "msFromStart": 177747.88444444438
            },
            {
              "lat": 43.76266084444445,
              "lng": -116.0864093888889,
              "alt": 2155.933111111111,
              "speed2d": 5.276,
              "speed3d": 5.457777777777777,
              "msFromStart": 178243.4127407407
            },
            {
              "lat": 43.76265531111111,
              "lng": -116.08643333333333,
              "alt": 2154.984222222222,
              "speed2d": 4.519777777777778,
              "speed3d": 4.7,
              "msFromStart": 178737.424
            },
            {
              "lat": 43.762654222222224,
              "lng": -116.08646081111112,
              "alt": 2154.5942222222225,
              "speed2d": 4.352555555555556,
              "speed3d": 4.457777777777777,
              "msFromStart": 179231.18680701754
            },
            {
              "lat": 43.762648,
              "lng": -116.08648493333334,
              "alt": 2153.692,
              "speed2d": 4.358444444444444,
              "speed3d": 4.488888888888888,
              "msFromStart": 179726.25768421046
            },
            {
              "lat": 43.7626285,
              "lng": -116.08650275,
              "alt": 2152.5525,
              "speed2d": 4.7846,
              "speed3d": 4.807999999999999,
              "msFromStart": 180249.24022456133
            },
            {
              "lat": 43.76260108888889,
              "lng": -116.08652866666668,
              "alt": 2151.761111111111,
              "speed2d": 6.3309999999999995,
              "speed3d": 6.265555555555555,
              "msFromStart": 180772.9315555555
            },
            {
              "lat": 43.762572377777786,
              "lng": -116.08656239999999,
              "alt": 2150.6692222222223,
              "speed2d": 7.862111111111111,
              "speed3d": 7.81111111111111,
              "msFromStart": 181269.09511111103
            },
            {
              "lat": 43.76254784444445,
              "lng": -116.08660428888889,
              "alt": 2149.1347777777783,
              "speed2d": 8.808222222222222,
              "speed3d": 8.885555555555555,
              "msFromStart": 181763.5342222222
            },
            {
              "lat": 43.76252932222222,
              "lng": -116.08665206666666,
              "alt": 2147.773666666667,
              "speed2d": 9.154444444444444,
              "speed3d": 9.223333333333333,
              "msFromStart": 182257.53037037034
            },
            {
              "lat": 43.762508744444446,
              "lng": -116.08670335555556,
              "alt": 2146.5054444444445,
              "speed2d": 9.550111111111113,
              "speed3d": 9.656666666666666,
              "msFromStart": 182751.40800000002
            },
            {
              "lat": 43.76248268888889,
              "lng": -116.0867514,
              "alt": 2145.5042222222223,
              "speed2d": 9.74,
              "speed3d": 9.892222222222221,
              "msFromStart": 183245.4017777778
            },
            {
              "lat": 43.76245174444445,
              "lng": -116.08678814444444,
              "alt": 2144.5552222222223,
              "speed2d": 9.492777777777778,
              "speed3d": 9.635555555555555,
              "msFromStart": 183740.85333333325
            },
            {
              "lat": 43.76241585555555,
              "lng": -116.08682846666667,
              "alt": 2143.257111111111,
              "speed2d": 10.063333333333334,
              "speed3d": 10.127777777777776,
              "msFromStart": 184236.59681871335
            },
            {
              "lat": 43.76238171111111,
              "lng": -116.08687653333332,
              "alt": 2141.854888888889,
              "speed2d": 10.874777777777776,
              "speed3d": 10.97888888888889,
              "msFromStart": 184731.6151578948
            },
            {
              "lat": 43.76235482222223,
              "lng": -116.08693089999998,
              "alt": 2140.0253333333335,
              "speed2d": 11.199555555555555,
              "speed3d": 11.355555555555556,
              "msFromStart": 185226.40757894743
            },
            {
              "lat": 43.76232959000001,
              "lng": -116.08699274,
              "alt": 2138.2223999999997,
              "speed2d": 11.4742,
              "speed3d": 11.646,
              "msFromStart": 185747.776
            },
            {
              "lat": 43.76229841111111,
              "lng": -116.08705104444444,
              "alt": 2136.568,
              "speed2d": 11.541444444444444,
              "speed3d": 11.762222222222222,
              "msFromStart": 186268.93925925926
            },
            {
              "lat": 43.76226527777778,
              "lng": -116.08710465555558,
              "alt": 2135.9908888888885,
              "speed2d": 11.23177777777778,
              "speed3d": 11.522222222222222,
              "msFromStart": 186763.7511111112
            },
            {
              "lat": 43.76223951111112,
              "lng": -116.08715488888889,
              "alt": 2134.9955555555557,
              "speed2d": 10.451666666666664,
              "speed3d": 10.74777777777778,
              "msFromStart": 187258.82637037046
            },
            {
              "lat": 43.762223177777784,
              "lng": -116.08720297777776,
              "alt": 2133.5204444444444,
              "speed2d": 9.454444444444444,
              "speed3d": 9.84888888888889,
              "msFromStart": 187753.85422222217
            },
            {
              "lat": 43.76221636666667,
              "lng": -116.08725057777778,
              "alt": 2131.9661111111113,
              "speed2d": 8.653333333333334,
              "speed3d": 8.994444444444444,
              "msFromStart": 188248.88029629624
            },
            {
              "lat": 43.76220858888889,
              "lng": -116.08729742222222,
              "alt": 2129.3849999999998,
              "speed2d": 8.649666666666665,
              "speed3d": 8.97,
              "msFromStart": 188744.00711111122
            },
            {
              "lat": 43.76219495555555,
              "lng": -116.08734887777777,
              "alt": 2126.8536666666664,
              "speed2d": 9.105333333333332,
              "speed3d": 9.472222222222221,
              "msFromStart": 189239.19529044844
            },
            {
              "lat": 43.76218234444444,
              "lng": -116.0873991888889,
              "alt": 2124.587777777778,
              "speed2d": 8.900222222222222,
              "speed3d": 9.436666666666667,
              "msFromStart": 189734.74526315788
            },
            {
              "lat": 43.76217944444445,
              "lng": -116.08744373333333,
              "alt": 2122.058666666667,
              "speed2d": 8.220222222222223,
              "speed3d": 8.80888888888889,
              "msFromStart": 190230.36937231972
            },
            {
              "lat": 43.762182011111115,
              "lng": -116.08749408888889,
              "alt": 2118.9934444444443,
              "speed2d": 8.475222222222222,
              "speed3d": 8.965555555555556,
              "msFromStart": 190725.68355555565
            },
            {
              "lat": 43.76217762,
              "lng": -116.08755933999998,
              "alt": 2116.0322,
              "speed2d": 9.6471,
              "speed3d": 10.22,
              "msFromStart": 191248.28320000012
            },
            {
              "lat": 43.76216128888889,
              "lng": -116.08761137777779,
              "alt": 2113.8327777777777,
              "speed2d": 9.419222222222222,
              "speed3d": 10.123333333333333,
              "msFromStart": 191769.61955555543
            },
            {
              "lat": 43.76214445555556,
              "lng": -116.08765306666668,
              "alt": 2111.4932222222224,
              "speed2d": 8.579999999999998,
              "speed3d": 9.462222222222222,
              "msFromStart": 192263.31466666656
            },
            {
              "lat": 43.762141666666665,
              "lng": -116.08767335555555,
              "alt": 2107.8546666666666,
              "speed2d": 6.39711111111111,
              "speed3d": 7.637777777777778,
              "msFromStart": 192758.68088888892
            },
            {
              "lat": 43.76214582222222,
              "lng": -116.08771057777777,
              "alt": 2104.6393333333335,
              "speed2d": 6.643666666666666,
              "speed3d": 7.631111111111111,
              "msFromStart": 193254.39022222228
            },
            {
              "lat": 43.762142733333334,
              "lng": -116.08777817777779,
              "alt": 2102.733888888889,
              "speed2d": 8.43288888888889,
              "speed3d": 9.258888888888889,
              "msFromStart": 193749.35288888897
            },
            {
              "lat": 43.762131688888886,
              "lng": -116.08784455555556,
              "alt": 2101.096777777778,
              "speed2d": 9.32911111111111,
              "speed3d": 10.096666666666666,
              "msFromStart": 194244.1738323588
            },
            {
              "lat": 43.76212418888888,
              "lng": -116.08790266666665,
              "alt": 2098.7237777777777,
              "speed2d": 9.561333333333334,
              "speed3d": 10.37,
              "msFromStart": 194739.44421052624
            },
            {
              "lat": 43.762118933333326,
              "lng": -116.08796286666666,
              "alt": 2096.1531111111112,
              "speed2d": 9.833777777777776,
              "speed3d": 10.676666666666666,
              "msFromStart": 195234.82788304082
            },
            {
              "lat": 43.76210561111111,
              "lng": -116.08801844444444,
              "alt": 2093.5583333333334,
              "speed2d": 9.882777777777777,
              "speed3d": 10.75111111111111,
              "msFromStart": 195730.22933333338
            },
            {
              "lat": 43.7620799,
              "lng": -116.08805516666666,
              "alt": 2090.571888888889,
              "speed2d": 9.781333333333333,
              "speed3d": 10.687777777777777,
              "msFromStart": 196225.57925925936
            },
            {
              "lat": 43.76204846,
              "lng": -116.08808888,
              "alt": 2087.041,
              "speed2d": 9.6325,
              "speed3d": 10.665000000000001,
              "msFromStart": 196747.26133333327
            },
            {
              "lat": 43.762023411111116,
              "lng": -116.08812536666667,
              "alt": 2082.9217777777776,
              "speed2d": 8.880777777777777,
              "speed3d": 10.121111111111112,
              "msFromStart": 197268.70459259255
            },
            {
              "lat": 43.762004055555565,
              "lng": -116.08817151111111,
              "alt": 2079.464888888889,
              "speed2d": 8.767111111111111,
              "speed3d": 10.046666666666667,
              "msFromStart": 197764.3893333333
            },
            {
              "lat": 43.76198155555556,
              "lng": -116.08820633333333,
              "alt": 2075.269444444444,
              "speed2d": 8.884888888888888,
              "speed3d": 10.193333333333333,
              "msFromStart": 198260.59940740737
            },
            {
              "lat": 43.76194933333333,
              "lng": -116.08826531111112,
              "alt": 2072.0968888888888,
              "speed2d": 10.430444444444444,
              "speed3d": 11.482222222222223,
              "msFromStart": 198757.87022222212
            },
            {
              "lat": 43.76191531111112,
              "lng": -116.08834076666668,
              "alt": 2070.012333333333,
              "speed2d": 11.574333333333334,
              "speed3d": 12.633333333333333,
              "msFromStart": 199255.06314230012
            },
            {
              "lat": 43.76189224444444,
              "lng": -116.08842554444443,
              "alt": 2068.2238888888887,
              "speed2d": 11.899333333333333,
              "speed3d": 12.92888888888889,
              "msFromStart": 199748.82526315787
            },
            {
              "lat": 43.761870300000005,
              "lng": -116.08851404444442,
              "alt": 2066.743888888889,
              "speed2d": 12.601333333333333,
              "speed3d": 13.477777777777778,
              "msFromStart": 200241.76048343076
            },
            {
              "lat": 43.7618410888889,
              "lng": -116.08860668888887,
              "alt": 2065.6756666666665,
              "speed2d": 13.635777777777777,
              "speed3d": 14.47888888888889,
              "msFromStart": 200735.28088888898
            },
            {
              "lat": 43.76181472222222,
              "lng": -116.08866548888888,
              "alt": 2063.661,
              "speed2d": 12.014222222222221,
              "speed3d": 13.179999999999998,
              "msFromStart": 201229.08770370382
            },
            {
              "lat": 43.76179801,
              "lng": -116.08872228000001,
              "alt": 2060.6101000000003,
              "speed2d": 10.391899999999998,
              "speed3d": 11.546000000000001,
              "msFromStart": 201751.77599999993
            },
            {
              "lat": 43.761774244444446,
              "lng": -116.08880016666666,
              "alt": 2058.501111111111,
              "speed2d": 11.155444444444445,
              "speed3d": 12.026666666666667,
              "msFromStart": 202274.9985185184
            },
            {
              "lat": 43.76174272222222,
              "lng": -116.08888125555556,
              "alt": 2056.583888888889,
              "speed2d": 12.392111111111111,
              "speed3d": 13.243333333333332,
              "msFromStart": 202771.07022222213
            },
            {
              "lat": 43.76170805555555,
              "lng": -116.08894570000001,
              "alt": 2054.346666666667,
              "speed2d": 12.23166666666667,
              "speed3d": 13.164444444444445,
              "msFromStart": 203267.1102222221
            },
            {
              "lat": 43.76166804444445,
              "lng": -116.08900006666666,
              "alt": 2051.9214444444447,
              "speed2d": 12.348111111111113,
              "speed3d": 13.22777777777778,
              "msFromStart": 203761.87022222223
            },
            {
              "lat": 43.761624122222216,
              "lng": -116.08904331111111,
              "alt": 2049.1137777777776,
              "speed2d": 12.614444444444445,
              "speed3d": 13.485555555555557,
              "msFromStart": 204256.25317738796
            },
            {
              "lat": 43.761583611111114,
              "lng": -116.08908833333334,
              "alt": 2046.8054444444444,
              "speed2d": 12.30488888888889,
              "speed3d": 13.262222222222222,
              "msFromStart": 204750.06568421057
            },
            {
              "lat": 43.76155007777777,
              "lng": -116.08913310000001,
              "alt": 2043.7183333333332,
              "speed2d": 11.703777777777777,
              "speed3d": 12.671111111111111,
              "msFromStart": 205243.84024951275
            },
            {
              "lat": 43.76151387777778,
              "lng": -116.08917677777777,
              "alt": 2040.744222222222,
              "speed2d": 11.874666666666668,
              "speed3d": 12.790000000000001,
              "msFromStart": 205739.60355555546
            },
            {
              "lat": 43.761475177777776,
              "lng": -116.08924953333333,
              "alt": 2038.7497777777776,
              "speed2d": 12.754555555555555,
              "speed3d": 13.53,
              "msFromStart": 206236.06548148132
            },
            {
              "lat": 43.76143324444445,
              "lng": -116.08933055555555,
              "alt": 2036.480222222222,
              "speed2d": 13.885777777777777,
              "speed3d": 14.608888888888888,
              "msFromStart": 206731.8800000001
            },
            {
              "lat": 43.76138418888888,
              "lng": -116.08940888888888,
              "alt": 2034.9208888888888,
              "speed2d": 14.736777777777778,
              "speed3d": 15.451111111111112,
              "msFromStart": 207227.37096296315
            },
            {
              "lat": 43.76133593,
              "lng": -116.0894923,
              "alt": 2033.7747,
              "speed2d": 14.8502,
              "speed3d": 15.57,
              "msFromStart": 207748.65066666668
            },
            {
              "lat": 43.76129274444445,
              "lng": -116.0895761,
              "alt": 2032.3943333333332,
              "speed2d": 14.63588888888889,
              "speed3d": 15.378888888888888,
              "msFromStart": 208269.55767641327
            },
            {
              "lat": 43.76126447777778,
              "lng": -116.08965853333333,
              "alt": 2030.727777777778,
              "speed2d": 13.804444444444446,
              "speed3d": 14.632222222222223,
              "msFromStart": 208765.28589473682
            },
            {
              "lat": 43.7612502,
              "lng": -116.0897379888889,
              "alt": 2027.940222222222,
              "speed2d": 13.22711111111111,
              "speed3d": 14.043333333333333,
              "msFromStart": 209261.53035477575
            },
            {
              "lat": 43.76123487777778,
              "lng": -116.08982252222222,
              "alt": 2025.9287777777777,
              "speed2d": 13.197000000000001,
              "speed3d": 13.962222222222223,
              "msFromStart": 209757.0115555556
            },
            {
              "lat": 43.761220566666665,
              "lng": -116.08991182222222,
              "alt": 2024.8022222222223,
              "speed2d": 13.614444444444446,
              "speed3d": 14.332222222222224,
              "msFromStart": 210252.12592592603
            },
            {
              "lat": 43.76121413333333,
              "lng": -116.08999847777778,
              "alt": 2024.0626666666665,
              "speed2d": 13.439222222222222,
              "speed3d": 14.156666666666666,
              "msFromStart": 210745.61333333337
            },
            {
              "lat": 43.7612163,
              "lng": -116.09008750000001,
              "alt": 2023.8336666666664,
              "speed2d": 13.380333333333333,
              "speed3d": 14.045555555555557,
              "msFromStart": 211238.5985185186
            },
            {
              "lat": 43.761219422222226,
              "lng": -116.09017577777776,
              "alt": 2023.2805555555556,
              "speed2d": 13.398333333333332,
              "speed3d": 14.04111111111111,
              "msFromStart": 211733.43022222217
            },
            {
              "lat": 43.7612185,
              "lng": -116.09025243333332,
              "alt": 2022.3474444444444,
              "speed2d": 12.765444444444444,
              "speed3d": 13.44111111111111,
              "msFromStart": 212228.89807407404
            },
            {
              "lat": 43.76122391,
              "lng": -116.09031537999998,
              "alt": 2020.308,
              "speed2d": 11.9601,
              "speed3d": 12.717,
              "msFromStart": 212750.98933333336
            },
            {
              "lat": 43.76125385555555,
              "lng": -116.09036341111113,
              "alt": 2018.7966666666666,
              "speed2d": 11.29288888888889,
              "speed3d": 12,
              "msFromStart": 213272.82332943476
            },
            {
              "lat": 43.761284588888884,
              "lng": -116.0904225888889,
              "alt": 2018.464888888889,
              "speed2d": 11.549555555555557,
              "speed3d": 12.11111111111111,
              "msFromStart": 213767.7128421052
            },
            {
              "lat": 43.76130403333333,
              "lng": -116.09051023333333,
              "alt": 2019.1095555555555,
              "speed2d": 12.110777777777779,
              "speed3d": 12.597777777777775,
              "msFromStart": 214262.7476803118
            },
            {
              "lat": 43.76132191111111,
              "lng": -116.09058541111112,
              "alt": 2018.674,
              "speed2d": 11.969666666666665,
              "speed3d": 12.473333333333331,
              "msFromStart": 214758.08444444445
            },
            {
              "lat": 43.76133895555555,
              "lng": -116.09067246666667,
              "alt": 2018.8745555555554,
              "speed2d": 12.402111111111111,
              "speed3d": 12.755555555555553,
              "msFromStart": 215253.47911111108
            },
            {
              "lat": 43.76135374444444,
              "lng": -116.0907625111111,
              "alt": 2019.0491111111112,
              "speed2d": 12.841888888888889,
              "speed3d": 13.217777777777778,
              "msFromStart": 215747.8604444444
            },
            {
              "lat": 43.761363744444445,
              "lng": -116.09084610000001,
              "alt": 2018.1325555555554,
              "speed2d": 12.983333333333333,
              "speed3d": 13.346666666666668,
              "msFromStart": 216241.94281481483
            },
            {
              "lat": 43.761366177777774,
              "lng": -116.09093938888887,
              "alt": 2017.322111111111,
              "speed2d": 12.77511111111111,
              "speed3d": 13.201111111111112,
              "msFromStart": 216737.43822222223
            },
            {
              "lat": 43.76136523333332,
              "lng": -116.09103726666666,
              "alt": 2016.7563333333337,
              "speed2d": 12.837333333333333,
              "speed3d": 13.165555555555557,
              "msFromStart": 217233.42074074075
            },
            {
              "lat": 43.76136052222222,
              "lng": -116.0911337,
              "alt": 2015.7943333333333,
              "speed2d": 13.223333333333333,
              "speed3d": 13.526666666666667,
              "msFromStart": 217728.76711111108
            },
            {
              "lat": 43.76134957,
              "lng": -116.09122348999998,
              "alt": 2013.5996,
              "speed2d": 13.561499999999999,
              "speed3d": 13.896999999999998,
              "msFromStart": 218251.56239999994
            },
            {
              "lat": 43.7613355,
              "lng": -116.09131582222221,
              "alt": 2011.4297777777776,
              "speed2d": 13.937111111111113,
              "speed3d": 14.278888888888888,
              "msFromStart": 218776.36444444436
            },
            {
              "lat": 43.76131923333334,
              "lng": -116.09140436666667,
              "alt": 2009.675111111111,
              "speed2d": 14.061666666666667,
              "speed3d": 14.446666666666669,
              "msFromStart": 219273.91365302133
            },
            {
              "lat": 43.76129986666667,
              "lng": -116.09149146666667,
              "alt": 2007.7086666666664,
              "speed2d": 14.22111111111111,
              "speed3d": 14.543333333333333,
              "msFromStart": 219769.27494736837
            },
            {
              "lat": 43.76127814444445,
              "lng": -116.09158515555555,
              "alt": 2006.4687777777776,
              "speed2d": 14.494888888888887,
              "speed3d": 14.879999999999999,
              "msFromStart": 220264.078362573
            },
            {
              "lat": 43.761256066666675,
              "lng": -116.09169524444444,
              "alt": 2006.1846666666665,
              "speed2d": 14.879888888888887,
              "speed3d": 15.17111111111111,
              "msFromStart": 220758.6746666666
            },
            {
              "lat": 43.7612299,
              "lng": -116.09179913333332,
              "alt": 2005.728,
              "speed2d": 14.759333333333334,
              "speed3d": 15.148888888888887,
              "msFromStart": 221253.18014814807
            },
            {
              "lat": 43.76120336666666,
              "lng": -116.09189462222221,
              "alt": 2004.755111111111,
              "speed2d": 14.435555555555556,
              "speed3d": 14.806666666666667,
              "msFromStart": 221747.40977777776
            },
            {
              "lat": 43.76117512222223,
              "lng": -116.09199244444446,
              "alt": 2004.1167777777778,
              "speed2d": 14.629111111111111,
              "speed3d": 14.924444444444445,
              "msFromStart": 222241.56
            },
            {
              "lat": 43.7611442,
              "lng": -116.0920931,
              "alt": 2003.789777777778,
              "speed2d": 15.173666666666666,
              "speed3d": 15.427777777777777,
              "msFromStart": 222736.13244444452
            },
            {
              "lat": 43.76111505555556,
              "lng": -116.09219365555556,
              "alt": 2003.0433333333333,
              "speed2d": 15.368444444444446,
              "speed3d": 15.630000000000003,
              "msFromStart": 223230.90429629642
            },
            {
              "lat": 43.761085933333334,
              "lng": -116.09229145555558,
              "alt": 2001.6645555555556,
              "speed2d": 15.68888888888889,
              "speed3d": 15.985555555555557,
              "msFromStart": 223726.5093333333
            },
            {
              "lat": 43.76104767999999,
              "lng": -116.09238472,
              "alt": 2001.0932,
              "speed2d": 15.1006,
              "speed3d": 15.44,
              "msFromStart": 224249.90538947363
            },
            {
              "lat": 43.76100598888889,
              "lng": -116.09247276666666,
              "alt": 2000.194888888889,
              "speed2d": 14.692666666666666,
              "speed3d": 15.037777777777777,
              "msFromStart": 224772.5465263158
            },
            {
              "lat": 43.760982511111116,
              "lng": -116.09255332222223,
              "alt": 1998.3273333333334,
              "speed2d": 13.596555555555556,
              "speed3d": 14.045555555555556,
              "msFromStart": 225267.47326315788
            },
            {
              "lat": 43.76097855555556,
              "lng": -116.09263933333332,
              "alt": 1997.3678888888887,
              "speed2d": 13.003888888888888,
              "speed3d": 13.355555555555556,
              "msFromStart": 225762.66400000005
            },
            {
              "lat": 43.76097964444445,
              "lng": -116.09271756666666,
              "alt": 1994.3852222222222,
              "speed2d": 13.02688888888889,
              "speed3d": 13.385555555555555,
              "msFromStart": 226257.88859259267
            },
            {
              "lat": 43.76098867777779,
              "lng": -116.09279118888888,
              "alt": 1990.8332222222223,
              "speed2d": 13.043444444444445,
              "speed3d": 13.492222222222225,
              "msFromStart": 226751.90844444436
            },
            {
              "lat": 43.761002866666665,
              "lng": -116.09287315555552,
              "alt": 1987.4593333333335,
              "speed2d": 13.878333333333334,
              "speed3d": 14.239999999999998,
              "msFromStart": 227245.55140740727
            },
            {
              "lat": 43.76102252222222,
              "lng": -116.09295876666667,
              "alt": 1984.4876666666664,
              "speed2d": 14.589555555555556,
              "speed3d": 15.06111111111111,
              "msFromStart": 227740.46666666673
            },
            {
              "lat": 43.76104282222222,
              "lng": -116.09304036666666,
              "alt": 1981.4812222222224,
              "speed2d": 14.591555555555557,
              "speed3d": 15.15222222222222,
              "msFromStart": 228235.8877037038
            },
            {
              "lat": 43.761062333333335,
              "lng": -116.09312903333333,
              "alt": 1978.8543333333332,
              "speed2d": 14.976444444444445,
              "speed3d": 15.464444444444444,
              "msFromStart": 228732.01244444435
            },
            {
              "lat": 43.761082844444445,
              "lng": -116.09322653333334,
              "alt": 1977.3076666666668,
              "speed2d": 15.68288888888889,
              "speed3d": 16.136666666666667,
              "msFromStart": 229228.33999220267
            },
            {
              "lat": 43.761105388888886,
              "lng": -116.09332595555556,
              "alt": 1976.3719999999998,
              "speed2d": 15.824555555555557,
              "speed3d": 16.28333333333333,
              "msFromStart": 229723.59494736834
            },
            {
              "lat": 43.761122639740904,
              "lng": -116.09338017062197,
              "alt": 1976.1294446232876,
              "speed2d": 15.875318877821858,
              "speed3d": 16.1858960512237,
              "msFromStart": 230000
            },
            {
              "lat": 43.76116018125403,
              "lng": -116.09345163332861,
              "alt": 1973.902004388813,
              "speed2d": 15.550519589279242,
              "speed3d": 15.883916786651591,
              "msFromStart": 230500
            },
            {
              "lat": 43.76119772276714,
              "lng": -116.09352309603526,
              "alt": 1971.6745641543384,
              "speed2d": 15.225720300736624,
              "speed3d": 15.58193752207948,
              "msFromStart": 231000
            },
            {
              "lat": 43.76123526428026,
              "lng": -116.0935945587419,
              "alt": 1969.447123919864,
              "speed2d": 14.900921012194008,
              "speed3d": 15.27995825750737,
              "msFromStart": 231500
            },
            {
              "lat": 43.761272805793375,
              "lng": -116.09366602144854,
              "alt": 1967.2196836853896,
              "speed2d": 14.57612172365139,
              "speed3d": 14.97797899293526,
              "msFromStart": 232000
            },
            {
              "lat": 43.761305157954475,
              "lng": -116.09375414319162,
              "alt": 1965.3261665062357,
              "speed2d": 14.33291917065435,
              "speed3d": 14.664326087408158,
              "msFromStart": 232500
            },
            {
              "lat": 43.761379866666665,
              "lng": -116.09383586666667,
              "alt": 1960.912,
              "speed2d": 13.688666666666666,
              "speed3d": 14.21,
              "msFromStart": 233334.3931851852
            },
            {
              "lat": 43.76139684742796,
              "lng": -116.0938566162936,
              "alt": 1960.397083762949,
              "speed2d": 14.160344990868998,
              "speed3d": 14.466022431598187,
              "msFromStart": 233500
            }
          ]
        }
      ]
    },
    {
      "id": "trail-easy-way-down",
      "pathType": "trail",
      "name": "Easy Way Down",
      "difficulty": "blue",
      "isCatTrack": true,
      "videos": [
        {
          "videoId": "GH010028",
          "pathId": "trail-easy-way-down",
          "startSec": 346,
          "endSec": 497,
          "id": "video-segment-22",
          "gps": [
            {
              "lat": 43.76354771999999,
              "lng": -116.06805109000001,
              "alt": 1890.3825,
              "speed2d": 6.543399999999999,
              "speed3d": 6.587000000000001,
              "msFromStart": 346248.1625263161
            },
            {
              "lat": 43.763562666666665,
              "lng": -116.06801246666667,
              "alt": 1890.1782222222218,
              "speed2d": 6.575333333333334,
              "speed3d": 6.623333333333334,
              "msFromStart": 346770.81955555547
            },
            {
              "lat": 43.763575333333335,
              "lng": -116.06797597777778,
              "alt": 1890.1092222222223,
              "speed2d": 6.457444444444443,
              "speed3d": 6.504444444444444,
              "msFromStart": 347265.9555555554
            },
            {
              "lat": 43.763586011111116,
              "lng": -116.06794178888889,
              "alt": 1889.9404444444447,
              "speed2d": 6.079666666666666,
              "speed3d": 6.164444444444445,
              "msFromStart": 347761.06488888885
            },
            {
              "lat": 43.76359496666667,
              "lng": -116.06791008888888,
              "alt": 1889.7546666666665,
              "speed2d": 5.603444444444445,
              "speed3d": 5.663333333333333,
              "msFromStart": 348256.1528888888
            },
            {
              "lat": 43.76360483333334,
              "lng": -116.06787998888888,
              "alt": 1889.350111111111,
              "speed2d": 5.387888888888888,
              "speed3d": 5.445555555555556,
              "msFromStart": 348751.13422222214
            },
            {
              "lat": 43.763613288888884,
              "lng": -116.0678495888889,
              "alt": 1888.8428888888889,
              "speed2d": 5.320333333333332,
              "speed3d": 5.349999999999999,
              "msFromStart": 349246.0302222221
            },
            {
              "lat": 43.76362168888889,
              "lng": -116.06781752222224,
              "alt": 1888.3563333333332,
              "speed2d": 5.523555555555556,
              "speed3d": 5.515555555555554,
              "msFromStart": 349741.1839999999
            },
            {
              "lat": 43.76363027777778,
              "lng": -116.06778325555555,
              "alt": 1887.9462222222223,
              "speed2d": 5.706444444444444,
              "speed3d": 5.732222222222222,
              "msFromStart": 350236.5439999997
            },
            {
              "lat": 43.7636394,
              "lng": -116.06774899999999,
              "alt": 1887.4425555555558,
              "speed2d": 5.900222222222222,
              "speed3d": 5.887777777777778,
              "msFromStart": 350731.9208421052
            },
            {
              "lat": 43.763650077777775,
              "lng": -116.06771083333334,
              "alt": 1887.0467777777776,
              "speed2d": 6.402777777777778,
              "speed3d": 6.414444444444445,
              "msFromStart": 351227.31115789444
            },
            {
              "lat": 43.76366305,
              "lng": -116.06767009999999,
              "alt": 1886.6541999999997,
              "speed2d": 6.7969,
              "speed3d": 6.77,
              "msFromStart": 351749.83999999997
            },
            {
              "lat": 43.7636771,
              "lng": -116.06762711111112,
              "alt": 1886.2524444444443,
              "speed2d": 7.130555555555555,
              "speed3d": 7.123333333333333,
              "msFromStart": 352271.9431111111
            },
            {
              "lat": 43.76369111111111,
              "lng": -116.0675816888889,
              "alt": 1885.9114444444442,
              "speed2d": 7.721888888888889,
              "speed3d": 7.691111111111112,
              "msFromStart": 352766.37155555544
            },
            {
              "lat": 43.76370675555556,
              "lng": -116.06753436666666,
              "alt": 1885.6263333333334,
              "speed2d": 8.177,
              "speed3d": 8.181111111111111,
              "msFromStart": 353260.64355555526
            },
            {
              "lat": 43.7637232,
              "lng": -116.06748947777778,
              "alt": 1884.995111111111,
              "speed2d": 8.252222222222223,
              "speed3d": 8.283333333333335,
              "msFromStart": 353755.2711111111
            },
            {
              "lat": 43.763742,
              "lng": -116.06744483333331,
              "alt": 1884.5063333333335,
              "speed2d": 8.422444444444444,
              "speed3d": 8.432222222222222,
              "msFromStart": 354250.1831111113
            },
            {
              "lat": 43.763761755555564,
              "lng": -116.06739955555555,
              "alt": 1884.0185555555554,
              "speed2d": 8.566888888888888,
              "speed3d": 8.588888888888889,
              "msFromStart": 354745.815111111
            },
            {
              "lat": 43.763783999999994,
              "lng": -116.06735329999998,
              "alt": 1883.550888888889,
              "speed2d": 8.880666666666666,
              "speed3d": 8.907777777777778,
              "msFromStart": 355242.0231111108
            },
            {
              "lat": 43.76380658888888,
              "lng": -116.06730904444443,
              "alt": 1882.9103333333333,
              "speed2d": 8.853777777777777,
              "speed3d": 8.895555555555557,
              "msFromStart": 355737.2378947368
            },
            {
              "lat": 43.763830077777776,
              "lng": -116.06726386666666,
              "alt": 1882.5200000000002,
              "speed2d": 8.943666666666667,
              "speed3d": 8.964444444444444,
              "msFromStart": 356231.65810526314
            },
            {
              "lat": 43.763851833333334,
              "lng": -116.0672161,
              "alt": 1882.000888888889,
              "speed2d": 9.138222222222222,
              "speed3d": 9.178888888888888,
              "msFromStart": 356726.09066666674
            },
            {
              "lat": 43.76387351,
              "lng": -116.06716300000001,
              "alt": 1881.6087999999997,
              "speed2d": 9.374399999999998,
              "speed3d": 9.363,
              "msFromStart": 357248.0080000002
            },
            {
              "lat": 43.76389537777778,
              "lng": -116.06710840000002,
              "alt": 1880.9208888888888,
              "speed2d": 9.634777777777778,
              "speed3d": 9.666666666666668,
              "msFromStart": 357769.9608888888
            },
            {
              "lat": 43.76391357777778,
              "lng": -116.06705477777777,
              "alt": 1880.2042222222221,
              "speed2d": 9.647777777777776,
              "speed3d": 9.736666666666666,
              "msFromStart": 358264.4728888887
            },
            {
              "lat": 43.763929911111106,
              "lng": -116.06700524444445,
              "alt": 1879.1238888888888,
              "speed2d": 9.288222222222224,
              "speed3d": 9.34888888888889,
              "msFromStart": 358759.40266666654
            },
            {
              "lat": 43.76394421111111,
              "lng": -116.0669514888889,
              "alt": 1878.4263333333329,
              "speed2d": 9.299222222222221,
              "speed3d": 9.344444444444443,
              "msFromStart": 359254.66666666634
            },
            {
              "lat": 43.763958099999996,
              "lng": -116.06689551111111,
              "alt": 1878.1231111111108,
              "speed2d": 9.395888888888887,
              "speed3d": 9.442222222222224,
              "msFromStart": 359749.8417777778
            },
            {
              "lat": 43.76397086666666,
              "lng": -116.06683816666666,
              "alt": 1877.7404444444448,
              "speed2d": 9.501000000000001,
              "speed3d": 9.547777777777776,
              "msFromStart": 360244.94577777793
            },
            {
              "lat": 43.76398297777777,
              "lng": -116.06677976666667,
              "alt": 1877.1633333333332,
              "speed2d": 9.662333333333333,
              "speed3d": 9.685555555555556,
              "msFromStart": 360740.309894737
            },
            {
              "lat": 43.76399536666666,
              "lng": -116.0667191,
              "alt": 1876.764111111111,
              "speed2d": 9.909444444444444,
              "speed3d": 9.95888888888889,
              "msFromStart": 361235.8821052635
            },
            {
              "lat": 43.76400774444444,
              "lng": -116.06665972222221,
              "alt": 1876.4855555555555,
              "speed2d": 9.935333333333334,
              "speed3d": 9.984444444444446,
              "msFromStart": 361731.1751111111
            },
            {
              "lat": 43.7640199,
              "lng": -116.06660078888889,
              "alt": 1876.253333333333,
              "speed2d": 9.879555555555555,
              "speed3d": 9.918888888888889,
              "msFromStart": 362226.119111111
            },
            {
              "lat": 43.764031200000005,
              "lng": -116.06653628000001,
              "alt": 1876.0160999999998,
              "speed2d": 9.940299999999999,
              "speed3d": 9.979000000000001,
              "msFromStart": 362748.856
            },
            {
              "lat": 43.764042655555556,
              "lng": -116.06647358888888,
              "alt": 1875.5428888888891,
              "speed2d": 9.861333333333333,
              "speed3d": 9.904444444444444,
              "msFromStart": 363271.9217777781
            },
            {
              "lat": 43.76405318888889,
              "lng": -116.06641493333335,
              "alt": 1874.928888888889,
              "speed2d": 9.765666666666668,
              "speed3d": 9.818888888888889,
              "msFromStart": 363766.28444444435
            },
            {
              "lat": 43.76406363333334,
              "lng": -116.06635396666667,
              "alt": 1874.772333333333,
              "speed2d": 9.946333333333333,
              "speed3d": 9.957777777777778,
              "msFromStart": 364259.7084444441
            },
            {
              "lat": 43.76407384444445,
              "lng": -116.06629354444445,
              "alt": 1874.8016666666665,
              "speed2d": 9.891444444444442,
              "speed3d": 9.94111111111111,
              "msFromStart": 364754.03957894736
            },
            {
              "lat": 43.764083166666666,
              "lng": -116.06623434444445,
              "alt": 1874.738777777778,
              "speed2d": 9.674,
              "speed3d": 9.726666666666667,
              "msFromStart": 365249.0964210526
            },
            {
              "lat": 43.764092388888876,
              "lng": -116.06617797777777,
              "alt": 1874.5557777777778,
              "speed2d": 9.370666666666667,
              "speed3d": 9.424444444444445,
              "msFromStart": 365745.4826666666
            },
            {
              "lat": 43.76410057777778,
              "lng": -116.06612176666665,
              "alt": 1874.3397777777777,
              "speed2d": 9.244777777777777,
              "speed3d": 9.280000000000001,
              "msFromStart": 366243.53066666645
            },
            {
              "lat": 43.764109588888886,
              "lng": -116.06606931111111,
              "alt": 1874.081222222222,
              "speed2d": 8.803444444444445,
              "speed3d": 8.872222222222222,
              "msFromStart": 366740.568888889
            },
            {
              "lat": 43.764119566666665,
              "lng": -116.0660172111111,
              "alt": 1873.7693333333334,
              "speed2d": 8.667,
              "speed3d": 8.690000000000001,
              "msFromStart": 367236.34488888923
            },
            {
              "lat": 43.7641328,
              "lng": -116.06596777777777,
              "alt": 1873.3782222222221,
              "speed2d": 8.524111111111111,
              "speed3d": 8.569999999999999,
              "msFromStart": 367731.04
            },
            {
              "lat": 43.76415168,
              "lng": -116.06591777,
              "alt": 1872.9458,
              "speed2d": 8.5907,
              "speed3d": 8.596,
              "msFromStart": 368251.7919999999
            },
            {
              "lat": 43.76417475555556,
              "lng": -116.06587225555556,
              "alt": 1872.5832222222223,
              "speed2d": 8.451444444444444,
              "speed3d": 8.53,
              "msFromStart": 368774.2595555557
            },
            {
              "lat": 43.764199999999995,
              "lng": -116.06583557777779,
              "alt": 1872.2541111111111,
              "speed2d": 8.263,
              "speed3d": 8.28888888888889,
              "msFromStart": 369270.69155555585
            },
            {
              "lat": 43.764228700000004,
              "lng": -116.06580273333333,
              "alt": 1871.8840000000002,
              "speed2d": 8.330666666666668,
              "speed3d": 8.37,
              "msFromStart": 369766.54577777785
            },
            {
              "lat": 43.76425879999999,
              "lng": -116.0657707,
              "alt": 1871.1447777777776,
              "speed2d": 8.626888888888889,
              "speed3d": 8.618888888888888,
              "msFromStart": 370261.93777777796
            },
            {
              "lat": 43.76429238888889,
              "lng": -116.0657392,
              "alt": 1870.2596666666666,
              "speed2d": 9.102666666666668,
              "speed3d": 9.086666666666668,
              "msFromStart": 370756.31831578945
            },
            {
              "lat": 43.764329522222226,
              "lng": -116.0657085,
              "alt": 1869.4136666666668,
              "speed2d": 9.583,
              "speed3d": 9.60222222222222,
              "msFromStart": 371249.88968421053
            },
            {
              "lat": 43.76436938888889,
              "lng": -116.06567839999998,
              "alt": 1868.661888888889,
              "speed2d": 10.105444444444446,
              "speed3d": 10.123333333333333,
              "msFromStart": 371744.08533333347
            },
            {
              "lat": 43.76440956666667,
              "lng": -116.06564477777778,
              "alt": 1867.9046666666666,
              "speed2d": 10.685,
              "speed3d": 10.695555555555556,
              "msFromStart": 372239.0613333337
            },
            {
              "lat": 43.76445281111111,
              "lng": -116.06561122222223,
              "alt": 1867.221111111111,
              "speed2d": 11.179888888888888,
              "speed3d": 11.214444444444446,
              "msFromStart": 372733.7884444443
            },
            {
              "lat": 43.76449566666666,
              "lng": -116.06557878888889,
              "alt": 1866.289,
              "speed2d": 11.267666666666667,
              "speed3d": 11.342222222222222,
              "msFromStart": 373228.20444444416
            },
            {
              "lat": 43.76454235999999,
              "lng": -116.06554313000001,
              "alt": 1865.5318,
              "speed2d": 11.568199999999997,
              "speed3d": 11.643,
              "msFromStart": 373749.84
            },
            {
              "lat": 43.764589766666674,
              "lng": -116.06550671111111,
              "alt": 1864.745333333333,
              "speed2d": 11.638555555555556,
              "speed3d": 11.738888888888887,
              "msFromStart": 374271.2000000001
            },
            {
              "lat": 43.76463476666666,
              "lng": -116.06547184444443,
              "alt": 1863.9655555555555,
              "speed2d": 11.635000000000002,
              "speed3d": 11.69888888888889,
              "msFromStart": 374766.8622222221
            },
            {
              "lat": 43.76468095555556,
              "lng": -116.06543574444444,
              "alt": 1863.412111111111,
              "speed2d": 11.826555555555556,
              "speed3d": 11.906666666666668,
              "msFromStart": 375263.9182222219
            },
            {
              "lat": 43.76472457777778,
              "lng": -116.06539801111111,
              "alt": 1862.8253333333332,
              "speed2d": 11.705222222222222,
              "speed3d": 11.82,
              "msFromStart": 375759.3583157895
            },
            {
              "lat": 43.76476756666666,
              "lng": -116.0653608,
              "alt": 1862.1275555555553,
              "speed2d": 11.612333333333332,
              "speed3d": 11.724444444444444,
              "msFromStart": 376253.5056842107
            },
            {
              "lat": 43.76480956666666,
              "lng": -116.0653225111111,
              "alt": 1861.1293333333333,
              "speed2d": 11.75311111111111,
              "speed3d": 11.807777777777778,
              "msFromStart": 376748.22044444445
            },
            {
              "lat": 43.76485345555555,
              "lng": -116.06528109999999,
              "alt": 1860.5991111111114,
              "speed2d": 11.906333333333334,
              "speed3d": 12.006666666666668,
              "msFromStart": 377243.64444444445
            },
            {
              "lat": 43.76488957777777,
              "lng": -116.06523731111112,
              "alt": 1859.5088888888888,
              "speed2d": 11.723333333333333,
              "speed3d": 11.84222222222222,
              "msFromStart": 377738.98311111116
            },
            {
              "lat": 43.76492396666668,
              "lng": -116.06518948888889,
              "alt": 1858.3233333333333,
              "speed2d": 11.614,
              "speed3d": 11.755555555555556,
              "msFromStart": 378234.2151111111
            },
            {
              "lat": 43.7649572,
              "lng": -116.06514273333335,
              "alt": 1857.2961111111108,
              "speed2d": 11.23788888888889,
              "speed3d": 11.364444444444446,
              "msFromStart": 378729.42577777774
            },
            {
              "lat": 43.76499218000001,
              "lng": -116.06508855999999,
              "alt": 1856.4352000000001,
              "speed2d": 11.1823,
              "speed3d": 11.293999999999999,
              "msFromStart": 379252.1199999999
            },
            {
              "lat": 43.76502475555556,
              "lng": -116.06502975555556,
              "alt": 1855.414,
              "speed2d": 11.306222222222223,
              "speed3d": 11.423333333333334,
              "msFromStart": 379774.6986666668
            },
            {
              "lat": 43.76505555555556,
              "lng": -116.06497267777779,
              "alt": 1854.6907777777776,
              "speed2d": 11.359444444444446,
              "speed3d": 11.454444444444444,
              "msFromStart": 380269.674666667
            },
            {
              "lat": 43.76508270000001,
              "lng": -116.06491545555556,
              "alt": 1853.677888888889,
              "speed2d": 11.168666666666667,
              "speed3d": 11.334444444444443,
              "msFromStart": 380765.2345263157
            },
            {
              "lat": 43.76510787777778,
              "lng": -116.06485842222223,
              "alt": 1852.4011111111113,
              "speed2d": 10.98522222222222,
              "speed3d": 11.153333333333332,
              "msFromStart": 381261.261473684
            },
            {
              "lat": 43.76513304444445,
              "lng": -116.06479943333335,
              "alt": 1851.4916666666666,
              "speed2d": 10.976666666666667,
              "speed3d": 11.087777777777777,
              "msFromStart": 381756.8853333332
            },
            {
              "lat": 43.76515888888889,
              "lng": -116.06473653333332,
              "alt": 1850.7596666666668,
              "speed2d": 11.297444444444446,
              "speed3d": 11.426666666666666,
              "msFromStart": 382252.005333333
            },
            {
              "lat": 43.76518127777778,
              "lng": -116.06467053333333,
              "alt": 1849.9638888888892,
              "speed2d": 11.543777777777779,
              "speed3d": 11.642222222222223,
              "msFromStart": 382746.6275555555
            },
            {
              "lat": 43.76520406666667,
              "lng": -116.06460411111111,
              "alt": 1849.1942222222224,
              "speed2d": 11.619333333333334,
              "speed3d": 11.774444444444445,
              "msFromStart": 383240.6275555554
            },
            {
              "lat": 43.765224411111106,
              "lng": -116.06454299999999,
              "alt": 1847.7295555555556,
              "speed2d": 11.224111111111112,
              "speed3d": 11.383333333333333,
              "msFromStart": 383735.6373333333
            },
            {
              "lat": 43.76524717777778,
              "lng": -116.06447679999998,
              "alt": 1846.7905555555558,
              "speed2d": 11.545333333333334,
              "speed3d": 11.678888888888892,
              "msFromStart": 384231.9093333333
            },
            {
              "lat": 43.76526722222222,
              "lng": -116.06441055555555,
              "alt": 1845.7596666666664,
              "speed2d": 11.587222222222222,
              "speed3d": 11.745555555555557,
              "msFromStart": 384727.28533333336
            },
            {
              "lat": 43.76528702,
              "lng": -116.06434537999999,
              "alt": 1844.6447,
              "speed2d": 11.0509,
              "speed3d": 11.274,
              "msFromStart": 385249.0000000001
            },
            {
              "lat": 43.76530897777778,
              "lng": -116.06428066666668,
              "alt": 1843.716111111111,
              "speed2d": 10.930888888888887,
              "speed3d": 11.11,
              "msFromStart": 385770.49431578955
            },
            {
              "lat": 43.76532896666667,
              "lng": -116.06422118888888,
              "alt": 1842.6348888888888,
              "speed2d": 10.626777777777779,
              "speed3d": 10.818888888888887,
              "msFromStart": 386264.3536842107
            },
            {
              "lat": 43.76534895555556,
              "lng": -116.06415988888888,
              "alt": 1841.4937777777777,
              "speed2d": 10.661777777777779,
              "speed3d": 10.826666666666668,
              "msFromStart": 386759.6622222222
            },
            {
              "lat": 43.76537215555555,
              "lng": -116.06409825555556,
              "alt": 1840.5762222222222,
              "speed2d": 10.961222222222222,
              "speed3d": 11.116666666666665,
              "msFromStart": 387256.7822222222
            },
            {
              "lat": 43.76539678888889,
              "lng": -116.06403841111111,
              "alt": 1839.9704444444444,
              "speed2d": 10.959666666666667,
              "speed3d": 11.122222222222222,
              "msFromStart": 387752.551111111
            },
            {
              "lat": 43.76542057777778,
              "lng": -116.06397994444445,
              "alt": 1839.0801111111111,
              "speed2d": 10.797777777777778,
              "speed3d": 11.005555555555556,
              "msFromStart": 388246.63111111074
            },
            {
              "lat": 43.765444888888894,
              "lng": -116.06392394444444,
              "alt": 1838.2565555555555,
              "speed2d": 10.555333333333333,
              "speed3d": 10.74,
              "msFromStart": 388741.3013333332
            },
            {
              "lat": 43.76546811111111,
              "lng": -116.06387252222223,
              "alt": 1837.1514444444447,
              "speed2d": 10.07988888888889,
              "speed3d": 10.328888888888887,
              "msFromStart": 389236.709333333
            },
            {
              "lat": 43.765490344444444,
              "lng": -116.06382344444445,
              "alt": 1835.9124444444444,
              "speed2d": 9.699111111111112,
              "speed3d": 9.891111111111112,
              "msFromStart": 389732.48
            },
            {
              "lat": 43.76551425555556,
              "lng": -116.06377417777776,
              "alt": 1834.9463333333333,
              "speed2d": 9.546888888888887,
              "speed3d": 9.77111111111111,
              "msFromStart": 390228.7039999999
            },
            {
              "lat": 43.76554094,
              "lng": -116.06372557,
              "alt": 1834.2758999999996,
              "speed2d": 9.196,
              "speed3d": 9.426000000000002,
              "msFromStart": 390751.0479999998
            },
            {
              "lat": 43.76556917777778,
              "lng": -116.06367823333332,
              "alt": 1833.9067777777777,
              "speed2d": 9.019444444444446,
              "speed3d": 9.21888888888889,
              "msFromStart": 391271.7831111108
            },
            {
              "lat": 43.765593711111116,
              "lng": -116.06363465555556,
              "alt": 1833.2311111111112,
              "speed2d": 8.833111111111112,
              "speed3d": 9.030000000000001,
              "msFromStart": 391765.0694736842
            },
            {
              "lat": 43.765617311111114,
              "lng": -116.06358784444444,
              "alt": 1832.7652222222225,
              "speed2d": 8.893444444444444,
              "speed3d": 9.041111111111112,
              "msFromStart": 392258.32252631575
            },
            {
              "lat": 43.76564022222222,
              "lng": -116.06354001111112,
              "alt": 1832.7706666666666,
              "speed2d": 8.719777777777779,
              "speed3d": 8.917777777777777,
              "msFromStart": 392753.088
            },
            {
              "lat": 43.76566376666666,
              "lng": -116.06349537777777,
              "alt": 1833.3827777777778,
              "speed2d": 8.317555555555554,
              "speed3d": 8.466666666666667,
              "msFromStart": 393249.74400000006
            },
            {
              "lat": 43.76568524444445,
              "lng": -116.0634499,
              "alt": 1833.5417777777777,
              "speed2d": 8.216111111111111,
              "speed3d": 8.336666666666666,
              "msFromStart": 393745.49688888894
            },
            {
              "lat": 43.76570498888889,
              "lng": -116.06340943333335,
              "alt": 1833.0298888888885,
              "speed2d": 7.825111111111111,
              "speed3d": 7.975555555555555,
              "msFromStart": 394240.12088888895
            },
            {
              "lat": 43.76572868888889,
              "lng": -116.06337928888888,
              "alt": 1832.4195555555552,
              "speed2d": 7.1723333333333334,
              "speed3d": 7.384444444444445,
              "msFromStart": 394734.9013333335
            },
            {
              "lat": 43.76575687777777,
              "lng": -116.06336789999999,
              "alt": 1831.8274444444446,
              "speed2d": 6.434111111111112,
              "speed3d": 6.635555555555555,
              "msFromStart": 395229.8773333338
            },
            {
              "lat": 43.76578596,
              "lng": -116.06336519,
              "alt": 1831.6118999999999,
              "speed2d": 6.111599999999998,
              "speed3d": 6.286,
              "msFromStart": 395752.38484210515
            },
            {
              "lat": 43.765809688888886,
              "lng": -116.06337007777778,
              "alt": 1831.7334444444446,
              "speed2d": 5.320333333333333,
              "speed3d": 5.572222222222223,
              "msFromStart": 396274.9288421049
            },
            {
              "lat": 43.7658248111111,
              "lng": -116.06338332222222,
              "alt": 1832.1912222222222,
              "speed2d": 4.385777777777777,
              "speed3d": 4.602222222222221,
              "msFromStart": 396769.9128888889
            },
            {
              "lat": 43.76582937777778,
              "lng": -116.06340427777778,
              "alt": 1832.5894444444443,
              "speed2d": 3.514555555555556,
              "speed3d": 3.6855555555555553,
              "msFromStart": 397264.824888889
            },
            {
              "lat": 43.76582438888889,
              "lng": -116.0634298888889,
              "alt": 1832.5576666666666,
              "speed2d": 3.376666666666667,
              "speed3d": 3.4700000000000006,
              "msFromStart": 397759.928888889
            },
            {
              "lat": 43.76581853333333,
              "lng": -116.06345787777776,
              "alt": 1832.798222222222,
              "speed2d": 3.8158888888888884,
              "speed3d": 3.852222222222222,
              "msFromStart": 398255.2728888891
            },
            {
              "lat": 43.7658127111111,
              "lng": -116.06348547777779,
              "alt": 1832.765888888889,
              "speed2d": 4.2427777777777775,
              "speed3d": 4.252222222222223,
              "msFromStart": 398749.8631111111
            },
            {
              "lat": 43.765809088888886,
              "lng": -116.06351182222222,
              "alt": 1833.0832222222225,
              "speed2d": 4.302777777777778,
              "speed3d": 4.371111111111111,
              "msFromStart": 399243.51111111126
            },
            {
              "lat": 43.76580516666667,
              "lng": -116.06353662222223,
              "alt": 1833.193777777778,
              "speed2d": 4.306555555555556,
              "speed3d": 4.303333333333334,
              "msFromStart": 399737.8062222222
            },
            {
              "lat": 43.76579677777778,
              "lng": -116.0635677,
              "alt": 1832.8407777777777,
              "speed2d": 4.902555555555556,
              "speed3d": 4.888888888888888,
              "msFromStart": 400232.91022222233
            },
            {
              "lat": 43.76578911111111,
              "lng": -116.06360187777778,
              "alt": 1832.5687777777778,
              "speed2d": 5.4783333333333335,
              "speed3d": 5.436666666666667,
              "msFromStart": 400728.12799999985
            },
            {
              "lat": 43.76578091000001,
              "lng": -116.063643,
              "alt": 1832.3020000000004,
              "speed2d": 6.0713,
              "speed3d": 6.0600000000000005,
              "msFromStart": 401251.00799999974
            },
            {
              "lat": 43.765772122222224,
              "lng": -116.06368610000001,
              "alt": 1831.426,
              "speed2d": 6.573444444444443,
              "speed3d": 6.597777777777777,
              "msFromStart": 401773.4968888889
            },
            {
              "lat": 43.76576453333333,
              "lng": -116.06372617777778,
              "alt": 1830.611111111111,
              "speed2d": 6.771222222222223,
              "speed3d": 6.761111111111112,
              "msFromStart": 402267.97688888886
            },
            {
              "lat": 43.765755966666674,
              "lng": -116.06377181111111,
              "alt": 1829.8206666666667,
              "speed2d": 7.2603333333333335,
              "speed3d": 7.24,
              "msFromStart": 402761.92355555564
            },
            {
              "lat": 43.765746422222215,
              "lng": -116.06382027777778,
              "alt": 1829.253666666667,
              "speed2d": 7.654999999999999,
              "speed3d": 7.699999999999999,
              "msFromStart": 403255.2035555559
            },
            {
              "lat": 43.76573577777778,
              "lng": -116.06387127777776,
              "alt": 1828.6987777777777,
              "speed2d": 8.15311111111111,
              "speed3d": 8.139999999999999,
              "msFromStart": 403749.9555555555
            },
            {
              "lat": 43.76572677777777,
              "lng": -116.06392463333333,
              "alt": 1828.5662222222222,
              "speed2d": 8.363666666666667,
              "speed3d": 8.425555555555556,
              "msFromStart": 404246.54755555536
            },
            {
              "lat": 43.76571841111111,
              "lng": -116.06397634444444,
              "alt": 1828.3792222222223,
              "speed2d": 8.494888888888887,
              "speed3d": 8.51,
              "msFromStart": 404742.54933333327
            },
            {
              "lat": 43.76571141111111,
              "lng": -116.06402636666667,
              "alt": 1828.1774444444445,
              "speed2d": 8.375333333333332,
              "speed3d": 8.425555555555556,
              "msFromStart": 405237.813333333
            },
            {
              "lat": 43.765700977777776,
              "lng": -116.06408338888889,
              "alt": 1828.053111111111,
              "speed2d": 8.780666666666667,
              "speed3d": 8.77111111111111,
              "msFromStart": 405733.4770526316
            },
            {
              "lat": 43.76569137777778,
              "lng": -116.06413915555554,
              "alt": 1827.6235555555556,
              "speed2d": 8.920222222222222,
              "speed3d": 8.957777777777778,
              "msFromStart": 406229.64042105264
            },
            {
              "lat": 43.765684944444445,
              "lng": -116.06418813333332,
              "alt": 1826.7018888888888,
              "speed2d": 8.643,
              "speed3d": 8.761111111111111,
              "msFromStart": 406725.5094892787
            },
            {
              "lat": 43.765681480000005,
              "lng": -116.06423371,
              "alt": 1825.7169000000001,
              "speed2d": 8.0973,
              "speed3d": 8.190000000000001,
              "msFromStart": 407248.34933333314
            },
            {
              "lat": 43.76567536666667,
              "lng": -116.064283,
              "alt": 1824.8695555555555,
              "speed2d": 7.966111111111111,
              "speed3d": 8.042222222222222,
              "msFromStart": 407770.8728888889
            },
            {
              "lat": 43.76566846666667,
              "lng": -116.06433295555556,
              "alt": 1824.3915555555554,
              "speed2d": 8.036222222222221,
              "speed3d": 8.093333333333334,
              "msFromStart": 408265.49688888894
            },
            {
              "lat": 43.76566381111111,
              "lng": -116.06438333333332,
              "alt": 1824.1956666666665,
              "speed2d": 8.099777777777778,
              "speed3d": 8.166666666666666,
              "msFromStart": 408760.2488888889
            },
            {
              "lat": 43.76566095555555,
              "lng": -116.0644331111111,
              "alt": 1823.7101111111112,
              "speed2d": 8.183222222222224,
              "speed3d": 8.219999999999999,
              "msFromStart": 409255.16088888905
            },
            {
              "lat": 43.76566064444445,
              "lng": -116.06448234444446,
              "alt": 1822.9417777777776,
              "speed2d": 8.21588888888889,
              "speed3d": 8.3,
              "msFromStart": 409749.85955555557
            },
            {
              "lat": 43.76566197777778,
              "lng": -116.06453244444447,
              "alt": 1822.2445555555557,
              "speed2d": 8.292111111111112,
              "speed3d": 8.34,
              "msFromStart": 410244.29155555554
            },
            {
              "lat": 43.76566488888889,
              "lng": -116.06458445555555,
              "alt": 1821.8428888888889,
              "speed2d": 8.272333333333332,
              "speed3d": 8.356666666666666,
              "msFromStart": 410739.07536842115
            },
            {
              "lat": 43.76566855555556,
              "lng": -116.06463781111111,
              "alt": 1821.7431111111111,
              "speed2d": 8.215,
              "speed3d": 8.303333333333335,
              "msFromStart": 411234.2989473687
            },
            {
              "lat": 43.76567577777777,
              "lng": -116.06468633333334,
              "alt": 1821.5205555555556,
              "speed2d": 7.884777777777778,
              "speed3d": 7.987777777777778,
              "msFromStart": 411729.7245484081
            },
            {
              "lat": 43.76568375555556,
              "lng": -116.0647365222222,
              "alt": 1821.2903333333331,
              "speed2d": 7.943666666666667,
              "speed3d": 7.978888888888889,
              "msFromStart": 412225.52533333347
            },
            {
              "lat": 43.765692,
              "lng": -116.06478959,
              "alt": 1820.8736,
              "speed2d": 8.0502,
              "speed3d": 8.123000000000001,
              "msFromStart": 412748.5326222222
            },
            {
              "lat": 43.76570058888889,
              "lng": -116.06484183333333,
              "alt": 1820.5462222222222,
              "speed2d": 7.979666666666667,
              "speed3d": 8.072222222222223,
              "msFromStart": 413270.98311111104
            },
            {
              "lat": 43.7657094,
              "lng": -116.06488875555556,
              "alt": 1820.2863333333335,
              "speed2d": 7.598555555555555,
              "speed3d": 7.703333333333333,
              "msFromStart": 413765.6853333334
            },
            {
              "lat": 43.765717655555555,
              "lng": -116.06493478888889,
              "alt": 1819.8280000000002,
              "speed2d": 7.392444444444444,
              "speed3d": 7.492222222222222,
              "msFromStart": 414260.0853333335
            },
            {
              "lat": 43.76572617777778,
              "lng": -116.06497561111112,
              "alt": 1818.8011111111114,
              "speed2d": 7.006666666666666,
              "speed3d": 7.122222222222222,
              "msFromStart": 414755.03288888896
            },
            {
              "lat": 43.765732822222226,
              "lng": -116.06502035555556,
              "alt": 1818.1517777777779,
              "speed2d": 7.0263333333333335,
              "speed3d": 7.115555555555555,
              "msFromStart": 415250.6648888892
            },
            {
              "lat": 43.76573671111111,
              "lng": -116.06506646666666,
              "alt": 1817.5212222222224,
              "speed2d": 6.991444444444445,
              "speed3d": 7.083333333333334,
              "msFromStart": 415745.99410526315
            },
            {
              "lat": 43.76574008888889,
              "lng": -116.06511206666667,
              "alt": 1816.824,
              "speed2d": 7.013444444444445,
              "speed3d": 7.1033333333333335,
              "msFromStart": 416240.94484210503
            },
            {
              "lat": 43.76574134444445,
              "lng": -116.06515908888889,
              "alt": 1816.2075555555552,
              "speed2d": 7.158777777777777,
              "speed3d": 7.250000000000001,
              "msFromStart": 416736.0038674463
            },
            {
              "lat": 43.76574226666666,
              "lng": -116.06520432222221,
              "alt": 1815.368888888889,
              "speed2d": 7.197777777777778,
              "speed3d": 7.303333333333333,
              "msFromStart": 417231.26399999973
            },
            {
              "lat": 43.7657415,
              "lng": -116.06524802222224,
              "alt": 1814.393222222222,
              "speed2d": 7.084333333333333,
              "speed3d": 7.224444444444444,
              "msFromStart": 417726.2182716048
            },
            {
              "lat": 43.76574061,
              "lng": -116.06528963000001,
              "alt": 1813.2957,
              "speed2d": 6.6362,
              "speed3d": 6.825,
              "msFromStart": 418248.0622222219
            },
            {
              "lat": 43.76574,
              "lng": -116.06533106666666,
              "alt": 1812.4816666666666,
              "speed2d": 6.322555555555555,
              "speed3d": 6.497777777777777,
              "msFromStart": 418770.0444444444
            },
            {
              "lat": 43.7657383,
              "lng": -116.06536784444445,
              "alt": 1811.6846666666665,
              "speed2d": 5.947555555555557,
              "speed3d": 6.17,
              "msFromStart": 419264.7484444442
            },
            {
              "lat": 43.76573634444444,
              "lng": -116.06540097777777,
              "alt": 1810.8827777777778,
              "speed2d": 5.569333333333333,
              "speed3d": 5.766666666666667,
              "msFromStart": 419760.0995555554
            },
            {
              "lat": 43.76573312222223,
              "lng": -116.06543843333334,
              "alt": 1810.1929999999998,
              "speed2d": 5.782333333333334,
              "speed3d": 5.917777777777778,
              "msFromStart": 420256.2595555552
            },
            {
              "lat": 43.76572618888889,
              "lng": -116.06547888888889,
              "alt": 1809.3058888888886,
              "speed2d": 6.1243333333333325,
              "speed3d": 6.25,
              "msFromStart": 420751.37684210524
            },
            {
              "lat": 43.76571606666667,
              "lng": -116.06552114444445,
              "alt": 1808.336888888889,
              "speed2d": 6.480666666666666,
              "speed3d": 6.632222222222222,
              "msFromStart": 421245.19073684193
            },
            {
              "lat": 43.7657083,
              "lng": -116.06555961111113,
              "alt": 1807.350777777778,
              "speed2d": 6.457222222222223,
              "speed3d": 6.674444444444443,
              "msFromStart": 421739.727137102
            },
            {
              "lat": 43.76570151111112,
              "lng": -116.06559594444442,
              "alt": 1806.478777777778,
              "speed2d": 6.258111111111112,
              "speed3d": 6.471111111111111,
              "msFromStart": 422235.6053333334
            },
            {
              "lat": 43.76569073333334,
              "lng": -116.06563676666667,
              "alt": 1805.7976666666666,
              "speed2d": 6.478888888888889,
              "speed3d": 6.65,
              "msFromStart": 422731.22172839515
            },
            {
              "lat": 43.76568261111111,
              "lng": -116.06567108888888,
              "alt": 1804.888111111111,
              "speed2d": 6.219777777777779,
              "speed3d": 6.444444444444445,
              "msFromStart": 423226.30400000035
            },
            {
              "lat": 43.7656742,
              "lng": -116.06570731,
              "alt": 1804.0228,
              "speed2d": 6.1177,
              "speed3d": 6.319,
              "msFromStart": 423748.68160000007
            },
            {
              "lat": 43.765664833333325,
              "lng": -116.06574497777777,
              "alt": 1803.174777777778,
              "speed2d": 6.199222222222222,
              "speed3d": 6.404444444444445,
              "msFromStart": 424270.73955555557
            },
            {
              "lat": 43.76565336666666,
              "lng": -116.06578554444445,
              "alt": 1802.3506666666667,
              "speed2d": 6.671333333333333,
              "speed3d": 6.8,
              "msFromStart": 424765.4506666668
            },
            {
              "lat": 43.76564006666667,
              "lng": -116.06583094444443,
              "alt": 1801.5127777777775,
              "speed2d": 7.338666666666667,
              "speed3d": 7.467777777777778,
              "msFromStart": 425260.330666667
            },
            {
              "lat": 43.76562828888889,
              "lng": -116.06587394444445,
              "alt": 1800.7404444444446,
              "speed2d": 7.458666666666667,
              "speed3d": 7.653333333333332,
              "msFromStart": 425755.3903157895
            },
            {
              "lat": 43.765618499999995,
              "lng": -116.06591441111112,
              "alt": 1800.642,
              "speed2d": 7.189444444444445,
              "speed3d": 7.370000000000001,
              "msFromStart": 426250.674526316
            },
            {
              "lat": 43.765606766666664,
              "lng": -116.06595855555555,
              "alt": 1800.0084444444446,
              "speed2d": 7.427999999999999,
              "speed3d": 7.530000000000001,
              "msFromStart": 426746.08449122804
            },
            {
              "lat": 43.76559013333333,
              "lng": -116.06601077777779,
              "alt": 1799.3347777777776,
              "speed2d": 7.902666666666667,
              "speed3d": 7.968888888888889,
              "msFromStart": 427241.7279999999
            },
            {
              "lat": 43.765569166666666,
              "lng": -116.06606851111111,
              "alt": 1798.988333333333,
              "speed2d": 8.667,
              "speed3d": 8.727777777777778,
              "msFromStart": 427736.9999012344
            },
            {
              "lat": 43.76554984444444,
              "lng": -116.06612089999999,
              "alt": 1798.6485555555555,
              "speed2d": 8.75077777777778,
              "speed3d": 8.865555555555556,
              "msFromStart": 428231.573333333
            },
            {
              "lat": 43.76552792222221,
              "lng": -116.06617543333333,
              "alt": 1798.1697777777779,
              "speed2d": 8.996555555555556,
              "speed3d": 9.06,
              "msFromStart": 428726.5039012347
            },
            {
              "lat": 43.76550541,
              "lng": -116.06623115,
              "alt": 1797.6439,
              "speed2d": 8.9419,
              "speed3d": 9.051,
              "msFromStart": 429249.6577777781
            },
            {
              "lat": 43.76548323333333,
              "lng": -116.06628456666667,
              "alt": 1797.150888888889,
              "speed2d": 8.786666666666665,
              "speed3d": 8.92222222222222,
              "msFromStart": 429771.9928888888
            },
            {
              "lat": 43.76546401111111,
              "lng": -116.06633192222222,
              "alt": 1796.6939999999997,
              "speed2d": 8.502,
              "speed3d": 8.616666666666667,
              "msFromStart": 430265.75288888864
            },
            {
              "lat": 43.765445911111115,
              "lng": -116.06637901111111,
              "alt": 1796.1501111111113,
              "speed2d": 8.367555555555557,
              "speed3d": 8.442222222222222,
              "msFromStart": 430760.156631579
            },
            {
              "lat": 43.765427522222225,
              "lng": -116.06643048888887,
              "alt": 1795.4815555555556,
              "speed2d": 8.540222222222221,
              "speed3d": 8.621111111111112,
              "msFromStart": 431255.36505263153
            },
            {
              "lat": 43.76541434444445,
              "lng": -116.06647835555555,
              "alt": 1794.7014444444444,
              "speed2d": 8.310444444444444,
              "speed3d": 8.437777777777779,
              "msFromStart": 431750.70330344385
            },
            {
              "lat": 43.765403455555564,
              "lng": -116.06652941111112,
              "alt": 1793.96,
              "speed2d": 8.427999999999999,
              "speed3d": 8.47888888888889,
              "msFromStart": 432246.28266666696
            },
            {
              "lat": 43.76539586666666,
              "lng": -116.06658193333334,
              "alt": 1793.264777777778,
              "speed2d": 8.508111111111111,
              "speed3d": 8.62111111111111,
              "msFromStart": 432741.650962963
            },
            {
              "lat": 43.76539361111111,
              "lng": -116.06663127777779,
              "alt": 1792.3635555555554,
              "speed2d": 8.365666666666666,
              "speed3d": 8.444444444444445,
              "msFromStart": 433236.6186666668
            },
            {
              "lat": 43.76539287777777,
              "lng": -116.06668842222223,
              "alt": 1791.5442222222218,
              "speed2d": 8.854444444444443,
              "speed3d": 8.882222222222222,
              "msFromStart": 433731.4569876543
            },
            {
              "lat": 43.76539765555556,
              "lng": -116.06674222222222,
              "alt": 1790.551111111111,
              "speed2d": 8.941555555555556,
              "speed3d": 9.095555555555555,
              "msFromStart": 434226.0693333331
            },
            {
              "lat": 43.76540616,
              "lng": -116.06679229,
              "alt": 1789.4174999999998,
              "speed2d": 8.478,
              "speed3d": 8.63,
              "msFromStart": 434748.3539555556
            },
            {
              "lat": 43.76541773333334,
              "lng": -116.0668445,
              "alt": 1788.6893333333333,
              "speed2d": 8.270333333333333,
              "speed3d": 8.397777777777778,
              "msFromStart": 435270.9582222224
            },
            {
              "lat": 43.76543106666667,
              "lng": -116.06689549999999,
              "alt": 1788.1046666666664,
              "speed2d": 8.266444444444444,
              "speed3d": 8.362222222222222,
              "msFromStart": 435766.1333333332
            },
            {
              "lat": 43.765443833333336,
              "lng": -116.06694900000001,
              "alt": 1787.6964444444445,
              "speed2d": 8.200333333333331,
              "speed3d": 8.333333333333334,
              "msFromStart": 436261.397333333
            },
            {
              "lat": 43.76545431111111,
              "lng": -116.06700336666668,
              "alt": 1787.3193333333334,
              "speed2d": 8.015777777777778,
              "speed3d": 8.171111111111111,
              "msFromStart": 436757.06105263164
            },
            {
              "lat": 43.76546357777777,
              "lng": -116.06705684444445,
              "alt": 1786.770888888889,
              "speed2d": 7.888111111111111,
              "speed3d": 7.988888888888889,
              "msFromStart": 437253.2244210527
            },
            {
              "lat": 43.76546814444444,
              "lng": -116.06711766666666,
              "alt": 1786.5057777777781,
              "speed2d": 8.125888888888888,
              "speed3d": 8.203333333333333,
              "msFromStart": 437748.6842053282
            },
            {
              "lat": 43.76547124444444,
              "lng": -116.06717995555556,
              "alt": 1786.387,
              "speed2d": 8.434888888888889,
              "speed3d": 8.49888888888889,
              "msFromStart": 438242.8373333336
            },
            {
              "lat": 43.76547374444444,
              "lng": -116.06724125555556,
              "alt": 1786.1276666666665,
              "speed2d": 8.58688888888889,
              "speed3d": 8.65,
              "msFromStart": 438737.50735802465
            },
            {
              "lat": 43.765475788888885,
              "lng": -116.06730414444445,
              "alt": 1785.9921111111112,
              "speed2d": 8.862666666666666,
              "speed3d": 8.944444444444445,
              "msFromStart": 439233.1839999998
            },
            {
              "lat": 43.76548004444444,
              "lng": -116.06736347777777,
              "alt": 1785.606111111111,
              "speed2d": 8.762777777777778,
              "speed3d": 8.833333333333334,
              "msFromStart": 439728.3711604938
            },
            {
              "lat": 43.76548687000001,
              "lng": -116.06742564000001,
              "alt": 1785.1912999999997,
              "speed2d": 8.9711,
              "speed3d": 9.02,
              "msFromStart": 440250.07022222254
            },
            {
              "lat": 43.76549581111111,
              "lng": -116.06748886666668,
              "alt": 1784.9276666666665,
              "speed2d": 9.25588888888889,
              "speed3d": 9.322222222222223,
              "msFromStart": 440771.77094736847
            },
            {
              "lat": 43.765506411111105,
              "lng": -116.06754807777777,
              "alt": 1784.6107777777777,
              "speed2d": 9.301333333333334,
              "speed3d": 9.376666666666665,
              "msFromStart": 441266.0395789475
            },
            {
              "lat": 43.765519999999995,
              "lng": -116.06760425555554,
              "alt": 1784.666666666667,
              "speed2d": 9.078444444444445,
              "speed3d": 9.18888888888889,
              "msFromStart": 441760.624634178
            },
            {
              "lat": 43.765535122222225,
              "lng": -116.06765605555556,
              "alt": 1784.5605555555553,
              "speed2d": 8.529333333333335,
              "speed3d": 8.644444444444446,
              "msFromStart": 442255.79733333323
            },
            {
              "lat": 43.765551522222225,
              "lng": -116.06770606666666,
              "alt": 1784.6946666666668,
              "speed2d": 8.211444444444446,
              "speed3d": 8.273333333333333,
              "msFromStart": 442750.8541234568
            },
            {
              "lat": 43.76556853333333,
              "lng": -116.06775412222223,
              "alt": 1784.7479999999998,
              "speed2d": 7.9110000000000005,
              "speed3d": 7.993333333333332,
              "msFromStart": 443245.6746666668
            },
            {
              "lat": 43.765586466666676,
              "lng": -116.06779895555555,
              "alt": 1784.6797777777779,
              "speed2d": 7.6897777777777785,
              "speed3d": 7.767777777777778,
              "msFromStart": 443740.9054814814
            },
            {
              "lat": 43.76560273333334,
              "lng": -116.06784475555554,
              "alt": 1784.7691111111112,
              "speed2d": 7.404,
              "speed3d": 7.503333333333333,
              "msFromStart": 444236.9066666664
            },
            {
              "lat": 43.765614933333325,
              "lng": -116.06789014444443,
              "alt": 1784.822666666667,
              "speed2d": 6.926777777777778,
              "speed3d": 7.011111111111111,
              "msFromStart": 444733.14390123443
            },
            {
              "lat": 43.76562508888889,
              "lng": -116.0679346111111,
              "alt": 1784.783,
              "speed2d": 6.646888888888888,
              "speed3d": 6.714444444444443,
              "msFromStart": 445229.7920000001
            },
            {
              "lat": 43.76563505,
              "lng": -116.06797868,
              "alt": 1784.6134,
              "speed2d": 6.328799999999999,
              "speed3d": 6.409,
              "msFromStart": 445752.87890526315
            },
            {
              "lat": 43.765643355555554,
              "lng": -116.06802055555555,
              "alt": 1784.1383333333333,
              "speed2d": 6.1095555555555565,
              "speed3d": 6.165555555555556,
              "msFromStart": 446274.09515789454
            },
            {
              "lat": 43.765648899999995,
              "lng": -116.06806105555555,
              "alt": 1783.5985555555555,
              "speed2d": 6.094222222222222,
              "speed3d": 6.158888888888889,
              "msFromStart": 446768.60847823264
            },
            {
              "lat": 43.76565362222223,
              "lng": -116.06810308888888,
              "alt": 1783.0702222222224,
              "speed2d": 6.325111111111111,
              "speed3d": 6.335555555555555,
              "msFromStart": 447264.53333333344
            },
            {
              "lat": 43.76565692222222,
              "lng": -116.06814934444444,
              "alt": 1782.7873333333332,
              "speed2d": 6.623666666666668,
              "speed3d": 6.686666666666666,
              "msFromStart": 447760.30834567914
            },
            {
              "lat": 43.765660644444445,
              "lng": -116.06819420000001,
              "alt": 1782.4783333333335,
              "speed2d": 6.599222222222222,
              "speed3d": 6.666666666666666,
              "msFromStart": 448255.7546666669
            },
            {
              "lat": 43.76566355555556,
              "lng": -116.06823814444444,
              "alt": 1781.9174444444445,
              "speed2d": 6.599111111111112,
              "speed3d": 6.677777777777777,
              "msFromStart": 448750.8572839506
            },
            {
              "lat": 43.76566682222222,
              "lng": -116.06828144444444,
              "alt": 1781.3354444444444,
              "speed2d": 6.5840000000000005,
              "speed3d": 6.659999999999999,
              "msFromStart": 449245.33333333314
            },
            {
              "lat": 43.76567121111111,
              "lng": -116.0683243111111,
              "alt": 1780.9598888888888,
              "speed2d": 6.494666666666666,
              "speed3d": 6.58,
              "msFromStart": 449739.95219753083
            },
            {
              "lat": 43.76567607777778,
              "lng": -116.06836840000001,
              "alt": 1780.938111111111,
              "speed2d": 6.495111111111111,
              "speed3d": 6.541111111111111,
              "msFromStart": 450234.8586666668
            },
            {
              "lat": 43.76567904444444,
              "lng": -116.06841410000001,
              "alt": 1781.0363333333335,
              "speed2d": 6.560222222222222,
              "speed3d": 6.635555555555555,
              "msFromStart": 450729.5604938272
            },
            {
              "lat": 43.76568398,
              "lng": -116.06845676999998,
              "alt": 1780.9489,
              "speed2d": 6.127600000000001,
              "speed3d": 6.214000000000001,
              "msFromStart": 451251.3333333334
            },
            {
              "lat": 43.76568925555554,
              "lng": -116.06849892222223,
              "alt": 1780.7893333333332,
              "speed2d": 6.078777777777777,
              "speed3d": 6.126666666666667,
              "msFromStart": 451773.8105263157
            },
            {
              "lat": 43.765694777777775,
              "lng": -116.06853772222223,
              "alt": 1780.6319999999998,
              "speed2d": 5.9912222222222224,
              "speed3d": 6.034444444444443,
              "msFromStart": 452269.7162105262
            },
            {
              "lat": 43.76569915555555,
              "lng": -116.0685796,
              "alt": 1780.6337777777776,
              "speed2d": 6.238,
              "speed3d": 6.2588888888888885,
              "msFromStart": 452765.29499155295
            },
            {
              "lat": 43.76570484444445,
              "lng": -116.06861765555556,
              "alt": 1780.435,
              "speed2d": 6.081,
              "speed3d": 6.138888888888888,
              "msFromStart": 453260.2666666667
            },
            {
              "lat": 43.76570961111111,
              "lng": -116.06865827777776,
              "alt": 1780.2607777777775,
              "speed2d": 6.244222222222222,
              "speed3d": 6.251111111111111,
              "msFromStart": 453755.33728395065
            },
            {
              "lat": 43.76571427777778,
              "lng": -116.06870009999999,
              "alt": 1780.0284444444442,
              "speed2d": 6.432555555555556,
              "speed3d": 6.44888888888889,
              "msFromStart": 454250.61333333334
            },
            {
              "lat": 43.76571894444444,
              "lng": -116.06874199999999,
              "alt": 1779.6733333333332,
              "speed2d": 6.559444444444445,
              "speed3d": 6.581111111111111,
              "msFromStart": 454746.06479012355
            },
            {
              "lat": 43.76572356666667,
              "lng": -116.06878394444446,
              "alt": 1779.1674444444443,
              "speed2d": 6.708,
              "speed3d": 6.732222222222223,
              "msFromStart": 455241.83466666704
            },
            {
              "lat": 43.76572887777778,
              "lng": -116.06882581111111,
              "alt": 1778.5576666666666,
              "speed2d": 6.793333333333331,
              "speed3d": 6.844444444444444,
              "msFromStart": 455737.505580247
            },
            {
              "lat": 43.76573565555556,
              "lng": -116.0688681,
              "alt": 1777.8836666666668,
              "speed2d": 7.061999999999999,
              "speed3d": 7.068888888888889,
              "msFromStart": 456232.98133333336
            },
            {
              "lat": 43.765743022222225,
              "lng": -116.0689122111111,
              "alt": 1777.222,
              "speed2d": 7.239888888888888,
              "speed3d": 7.317777777777779,
              "msFromStart": 456728.05307342415
            },
            {
              "lat": 43.76575278,
              "lng": -116.06895807,
              "alt": 1776.5698,
              "speed2d": 7.308,
              "speed3d": 7.356,
              "msFromStart": 457249.8433684208
            },
            {
              "lat": 43.76576215555555,
              "lng": -116.06900932222223,
              "alt": 1776.0492222222222,
              "speed2d": 7.735111111111111,
              "speed3d": 7.784444444444445,
              "msFromStart": 457771.8758258609
            },
            {
              "lat": 43.765772766666664,
              "lng": -116.06905713333335,
              "alt": 1775.5734444444447,
              "speed2d": 7.89288888888889,
              "speed3d": 7.934444444444445,
              "msFromStart": 458266.92266666674
            },
            {
              "lat": 43.76578401111111,
              "lng": -116.06910575555555,
              "alt": 1775.0746666666669,
              "speed2d": 8.028444444444444,
              "speed3d": 8.083333333333332,
              "msFromStart": 458762.321580247
            },
            {
              "lat": 43.765796200000004,
              "lng": -116.06915602222222,
              "alt": 1774.7474444444442,
              "speed2d": 8.294333333333334,
              "speed3d": 8.333333333333334,
              "msFromStart": 459258.3573333336
            },
            {
              "lat": 43.76580925555555,
              "lng": -116.0692066,
              "alt": 1774.6115555555557,
              "speed2d": 8.341111111111111,
              "speed3d": 8.411111111111111,
              "msFromStart": 459753.4263703704
            },
            {
              "lat": 43.76582491111111,
              "lng": -116.06925062222221,
              "alt": 1774.2022222222222,
              "speed2d": 8.042666666666666,
              "speed3d": 8.124444444444444,
              "msFromStart": 460246.6773333334
            },
            {
              "lat": 43.76584178888889,
              "lng": -116.06929020000001,
              "alt": 1773.7706666666668,
              "speed2d": 7.515777777777778,
              "speed3d": 7.617777777777778,
              "msFromStart": 460740.2572059779
            },
            {
              "lat": 43.76585886666666,
              "lng": -116.06932803333333,
              "alt": 1773.6273333333334,
              "speed2d": 7.028555555555556,
              "speed3d": 7.124444444444445,
              "msFromStart": 461234.51284210535
            },
            {
              "lat": 43.76587675555555,
              "lng": -116.06936232222222,
              "alt": 1773.5774444444444,
              "speed2d": 6.616222222222222,
              "speed3d": 6.68111111111111,
              "msFromStart": 461728.9939025341
            },
            {
              "lat": 43.765895889999996,
              "lng": -116.06939593,
              "alt": 1773.5366,
              "speed2d": 6.299,
              "speed3d": 6.372999999999999,
              "msFromStart": 462251.55644444434
            },
            {
              "lat": 43.76591161111111,
              "lng": -116.06942903333334,
              "alt": 1773.2931111111113,
              "speed2d": 5.978111111111112,
              "speed3d": 6.036666666666667,
              "msFromStart": 462774.2710123457
            },
            {
              "lat": 43.765926311111116,
              "lng": -116.0694574,
              "alt": 1772.806111111111,
              "speed2d": 5.761555555555556,
              "speed3d": 5.812222222222221,
              "msFromStart": 463269.7066666669
            },
            {
              "lat": 43.76593957777778,
              "lng": -116.06948843333333,
              "alt": 1772.3039999999999,
              "speed2d": 5.7956666666666665,
              "speed3d": 5.828888888888889,
              "msFromStart": 463764.9973333335
            },
            {
              "lat": 43.76595168888889,
              "lng": -116.06952365555554,
              "alt": 1772.0256666666667,
              "speed2d": 5.9272222222222215,
              "speed3d": 5.973333333333334,
              "msFromStart": 464260.01066666684
            },
            {
              "lat": 43.765961100000006,
              "lng": -116.06955924444443,
              "alt": 1771.5414444444446,
              "speed2d": 5.871888888888889,
              "speed3d": 5.932222222222222,
              "msFromStart": 464755.07397530857
            },
            {
              "lat": 43.76596742222222,
              "lng": -116.06959499999999,
              "alt": 1770.8372222222222,
              "speed2d": 5.770555555555556,
              "speed3d": 5.862222222222222,
              "msFromStart": 465250.23999999976
            },
            {
              "lat": 43.76597161111111,
              "lng": -116.0696283111111,
              "alt": 1769.9446666666668,
              "speed2d": 5.495888888888889,
              "speed3d": 5.624444444444444,
              "msFromStart": 465745.3416296296
            },
            {
              "lat": 43.765972566666676,
              "lng": -116.06966178888888,
              "alt": 1769.1322222222223,
              "speed2d": 5.387666666666667,
              "speed3d": 5.48,
              "msFromStart": 466240.3200000003
            },
            {
              "lat": 43.76597075555555,
              "lng": -116.06969735555555,
              "alt": 1768.741111111111,
              "speed2d": 5.484333333333334,
              "speed3d": 5.587777777777777,
              "msFromStart": 466734.96764132567
            },
            {
              "lat": 43.76596801111111,
              "lng": -116.06972896666667,
              "alt": 1768.0275555555554,
              "speed2d": 5.378222222222222,
              "speed3d": 5.474444444444445,
              "msFromStart": 467229.0054736845
            },
            {
              "lat": 43.765960299999996,
              "lng": -116.06976440999999,
              "alt": 1767.1881,
              "speed2d": 5.604000000000001,
              "speed3d": 5.713,
              "msFromStart": 467750.4842853802
            },
            {
              "lat": 43.7659459888889,
              "lng": -116.06979648888891,
              "alt": 1766.2603333333332,
              "speed2d": 5.732555555555555,
              "speed3d": 5.82,
              "msFromStart": 468271.9786666666
            },
            {
              "lat": 43.76593244444444,
              "lng": -116.06982184444442,
              "alt": 1765.3352222222225,
              "speed2d": 5.4896666666666665,
              "speed3d": 5.672222222222222,
              "msFromStart": 468766.90054321
            },
            {
              "lat": 43.765918277777786,
              "lng": -116.06984563333334,
              "alt": 1764.083,
              "speed2d": 5.3148888888888886,
              "speed3d": 5.468888888888889,
              "msFromStart": 469263.4453333337
            },
            {
              "lat": 43.76590285555555,
              "lng": -116.06987131111111,
              "alt": 1762.7850000000003,
              "speed2d": 5.527,
              "speed3d": 5.684444444444444,
              "msFromStart": 469759.6618271606
            },
            {
              "lat": 43.765886355555566,
              "lng": -116.06989522222223,
              "alt": 1761.163111111111,
              "speed2d": 5.733444444444444,
              "speed3d": 5.914444444444445,
              "msFromStart": 470255.21066666686
            },
            {
              "lat": 43.765872155555556,
              "lng": -116.0699152,
              "alt": 1759.511111111111,
              "speed2d": 5.378111111111111,
              "speed3d": 5.714444444444444,
              "msFromStart": 470750.49719558173
            },
            {
              "lat": 43.765860499999995,
              "lng": -116.06993726666668,
              "alt": 1758.2835555555555,
              "speed2d": 5.101222222222223,
              "speed3d": 5.388888888888889,
              "msFromStart": 471245.3204210529
            },
            {
              "lat": 43.765848911111114,
              "lng": -116.06996284444446,
              "alt": 1756.9707777777778,
              "speed2d": 5.154,
              "speed3d": 5.461111111111111,
              "msFromStart": 471740.0729512671
            },
            {
              "lat": 43.76583788888889,
              "lng": -116.06998923333336,
              "alt": 1755.7151111111111,
              "speed2d": 5.137555555555556,
              "speed3d": 5.451111111111111,
              "msFromStart": 472234.6577777779
            },
            {
              "lat": 43.76582518888889,
              "lng": -116.07002093333335,
              "alt": 1754.8185555555553,
              "speed2d": 5.367111111111112,
              "speed3d": 5.617777777777778,
              "msFromStart": 472729.11348148144
            },
            {
              "lat": 43.76580754,
              "lng": -116.07006314999998,
              "alt": 1754.5627000000002,
              "speed2d": 6.0985,
              "speed3d": 6.284,
              "msFromStart": 473250.6933333334
            },
            {
              "lat": 43.76578976666667,
              "lng": -116.07010383333333,
              "alt": 1754.520888888889,
              "speed2d": 6.461777777777777,
              "speed3d": 6.614444444444443,
              "msFromStart": 473773.22390123457
            },
            {
              "lat": 43.76577395555556,
              "lng": -116.07013697777778,
              "alt": 1754.3944444444444,
              "speed2d": 6.0777777777777775,
              "speed3d": 6.282222222222224,
              "msFromStart": 474270.10133333306
            },
            {
              "lat": 43.76575869999999,
              "lng": -116.07017155555555,
              "alt": 1754.2264444444445,
              "speed2d": 5.933777777777778,
              "speed3d": 6.057777777777778,
              "msFromStart": 474766.22241975303
            },
            {
              "lat": 43.765745800000005,
              "lng": -116.07020685555555,
              "alt": 1753.662777777778,
              "speed2d": 6.079000000000001,
              "speed3d": 6.227777777777778,
              "msFromStart": 475260.8746666667
            },
            {
              "lat": 43.76573816666667,
              "lng": -116.07024234444445,
              "alt": 1753.0266666666666,
              "speed2d": 6.047333333333334,
              "speed3d": 6.16,
              "msFromStart": 475755.7696790123
            },
            {
              "lat": 43.765734588888876,
              "lng": -116.07027894444445,
              "alt": 1752.1052222222222,
              "speed2d": 6.248444444444444,
              "speed3d": 6.386666666666667,
              "msFromStart": 476251.1679999998
            },
            {
              "lat": 43.76573891111111,
              "lng": -116.070315,
              "alt": 1751.3182222222222,
              "speed2d": 6.405444444444444,
              "speed3d": 6.51,
              "msFromStart": 476746.150705653
            },
            {
              "lat": 43.76574605555555,
              "lng": -116.07035722222223,
              "alt": 1750.682,
              "speed2d": 7.031555555555556,
              "speed3d": 7.101111111111111,
              "msFromStart": 477240.3435789475
            },
            {
              "lat": 43.7657585,
              "lng": -116.07040143333333,
              "alt": 1750.47,
              "speed2d": 7.693666666666667,
              "speed3d": 7.743333333333333,
              "msFromStart": 477734.7614191034
            },
            {
              "lat": 43.765776411111105,
              "lng": -116.07044275555555,
              "alt": 1750.148777777778,
              "speed2d": 7.930444444444444,
              "speed3d": 8.026666666666666,
              "msFromStart": 478229.83111111127
            },
            {
              "lat": 43.7657964,
              "lng": -116.07048538888888,
              "alt": 1749.760888888889,
              "speed2d": 8.224555555555556,
              "speed3d": 8.305555555555555,
              "msFromStart": 478725.5117037038
            },
            {
              "lat": 43.765822369999995,
              "lng": -116.07052219,
              "alt": 1749.2887999999998,
              "speed2d": 8.08,
              "speed3d": 8.224,
              "msFromStart": 479250.38666666695
            },
            {
              "lat": 43.765851833333336,
              "lng": -116.07055713333334,
              "alt": 1749.2688888888888,
              "speed2d": 8.017888888888889,
              "speed3d": 8.075555555555557,
              "msFromStart": 479774.4645925928
            },
            {
              "lat": 43.76587587777778,
              "lng": -116.07060400000002,
              "alt": 1749.9232222222222,
              "speed2d": 8.322888888888889,
              "speed3d": 8.38111111111111,
              "msFromStart": 480269.2800000004
            },
            {
              "lat": 43.765897966666664,
              "lng": -116.07064902222223,
              "alt": 1750.7589999999998,
              "speed2d": 7.965777777777779,
              "speed3d": 8.132222222222222,
              "msFromStart": 480763.9976296297
            },
            {
              "lat": 43.76591947777778,
              "lng": -116.0706843,
              "alt": 1751.4364444444445,
              "speed2d": 7.2025555555555565,
              "speed3d": 7.302222222222222,
              "msFromStart": 481258.5920000002
            },
            {
              "lat": 43.76594677777778,
              "lng": -116.07071372222222,
              "alt": 1752.2145555555558,
              "speed2d": 7.322111111111112,
              "speed3d": 7.378888888888888,
              "msFromStart": 481753.55951656913
            },
            {
              "lat": 43.765969088888895,
              "lng": -116.07073954444445,
              "alt": 1753.3525555555552,
              "speed2d": 6.981444444444444,
              "speed3d": 7.0811111111111105,
              "msFromStart": 482249.2244210524
            },
            {
              "lat": 43.76599161111112,
              "lng": -116.07075099999999,
              "alt": 1753.7076666666667,
              "speed2d": 6.482222222222223,
              "speed3d": 6.633333333333334,
              "msFromStart": 482745.0773957114
            },
            {
              "lat": 43.76600537777778,
              "lng": -116.07075317777779,
              "alt": 1753.298888888889,
              "speed2d": 5.062555555555556,
              "speed3d": 5.337777777777778,
              "msFromStart": 483241.4044444443
            },
            {
              "lat": 43.76600967777779,
              "lng": -116.07075164444446,
              "alt": 1753.0028888888887,
              "speed2d": 3.1822222222222223,
              "speed3d": 3.4655555555555555,
              "msFromStart": 483736.69037037034
            },
            {
              "lat": 43.76601563333333,
              "lng": -116.0707484888889,
              "alt": 1752.680333333333,
              "speed2d": 2.469444444444444,
              "speed3d": 2.6855555555555557,
              "msFromStart": 484229.082666667
            },
            {
              "lat": 43.76602356,
              "lng": -116.07074552000002,
              "alt": 1752.3841,
              "speed2d": 2.2844,
              "speed3d": 2.4779999999999998,
              "msFromStart": 484749.4608000001
            },
            {
              "lat": 43.766031955555555,
              "lng": -116.07074187777778,
              "alt": 1752.6499999999996,
              "speed2d": 2.1376666666666666,
              "speed3d": 2.3177777777777777,
              "msFromStart": 485271.64800000034
            },
            {
              "lat": 43.766040188888894,
              "lng": -116.07073907777779,
              "alt": 1753.3718888888889,
              "speed2d": 1.8248888888888888,
              "speed3d": 1.9666666666666668,
              "msFromStart": 485766.3167251462
            },
            {
              "lat": 43.766047377777774,
              "lng": -116.07073715555556,
              "alt": 1753.8482222222224,
              "speed2d": 1.6856666666666666,
              "speed3d": 1.7922222222222224,
              "msFromStart": 486260.771368421
            },
            {
              "lat": 43.7660534,
              "lng": -116.07073636666667,
              "alt": 1754.330333333333,
              "speed2d": 1.4108888888888889,
              "speed3d": 1.5066666666666666,
              "msFromStart": 486755.5282027291
            },
            {
              "lat": 43.76605762222223,
              "lng": -116.07073626666667,
              "alt": 1754.525,
              "speed2d": 1.1934444444444445,
              "speed3d": 1.248888888888889,
              "msFromStart": 487251.11644444475
            },
            {
              "lat": 43.76606031111111,
              "lng": -116.07073564444445,
              "alt": 1754.4893333333332,
              "speed2d": 1.2151111111111108,
              "speed3d": 1.2433333333333332,
              "msFromStart": 487746.5534814816
            },
            {
              "lat": 43.76606422222223,
              "lng": -116.0707351111111,
              "alt": 1754.552777777778,
              "speed2d": 1.3137777777777777,
              "speed3d": 1.3644444444444446,
              "msFromStart": 488241.46133333363
            },
            {
              "lat": 43.76606827777777,
              "lng": -116.07073436666667,
              "alt": 1754.3066666666664,
              "speed2d": 1.375888888888889,
              "speed3d": 1.4455555555555557,
              "msFromStart": 488736.7063703706
            },
            {
              "lat": 43.76607327777778,
              "lng": -116.07073237777777,
              "alt": 1754.254888888889,
              "speed2d": 1.6231111111111112,
              "speed3d": 1.6688888888888889,
              "msFromStart": 489232.94222222245
            },
            {
              "lat": 43.7660791,
              "lng": -116.07072936666665,
              "alt": 1754.3155555555556,
              "speed2d": 1.6121111111111113,
              "speed3d": 1.6644444444444444,
              "msFromStart": 489728.5374814815
            },
            {
              "lat": 43.76608689,
              "lng": -116.07072505999999,
              "alt": 1754.4477,
              "speed2d": 1.849,
              "speed3d": 1.8750000000000002,
              "msFromStart": 490249.67555555527
            },
            {
              "lat": 43.76609478888889,
              "lng": -116.07072015555556,
              "alt": 1754.6196666666665,
              "speed2d": 1.9180000000000004,
              "speed3d": 1.95,
              "msFromStart": 490771.0686419753
            },
            {
              "lat": 43.76610257777778,
              "lng": -116.07071374444443,
              "alt": 1754.6967777777775,
              "speed2d": 2.032111111111111,
              "speed3d": 2.0477777777777777,
              "msFromStart": 491265.69600000023
            },
            {
              "lat": 43.76610936666667,
              "lng": -116.07070574444447,
              "alt": 1754.6215555555555,
              "speed2d": 2.1025555555555555,
              "speed3d": 2.1222222222222222,
              "msFromStart": 491760.6634853801
            },
            {
              "lat": 43.766114844444445,
              "lng": -116.07069662222221,
              "alt": 1754.5819999999999,
              "speed2d": 1.9463333333333332,
              "speed3d": 2.008888888888889,
              "msFromStart": 492256.2391578946
            },
            {
              "lat": 43.766118644444454,
              "lng": -116.07068784444444,
              "alt": 1754.5943333333332,
              "speed2d": 1.653,
              "speed3d": 1.6877777777777778,
              "msFromStart": 492751.80491228064
            },
            {
              "lat": 43.7661219,
              "lng": -116.07067726666668,
              "alt": 1754.674,
              "speed2d": 1.7505555555555556,
              "speed3d": 1.736666666666667,
              "msFromStart": 493247.3119999998
            },
            {
              "lat": 43.76612462222222,
              "lng": -116.07066651111111,
              "alt": 1754.8375555555554,
              "speed2d": 1.6917777777777778,
              "speed3d": 1.7244444444444444,
              "msFromStart": 493742.43437037035
            },
            {
              "lat": 43.76612663333333,
              "lng": -116.07065822222222,
              "alt": 1754.790111111111,
              "speed2d": 1.4104444444444444,
              "speed3d": 1.4466666666666668,
              "msFromStart": 494236.5208888892
            },
            {
              "lat": 43.76612835555556,
              "lng": -116.07065123333334,
              "alt": 1754.707777777778,
              "speed2d": 1.2216666666666667,
              "speed3d": 1.2722222222222221,
              "msFromStart": 494730.9677037039
            },
            {
              "lat": 43.76613061111111,
              "lng": -116.07064676666667,
              "alt": 1754.523,
              "speed2d": 0.9116666666666667,
              "speed3d": 0.9322222222222223,
              "msFromStart": 495226.54044444475
            },
            {
              "lat": 43.76613312,
              "lng": -116.07064029,
              "alt": 1754.5478999999998,
              "speed2d": 0.8592000000000001,
              "speed3d": 0.8959999999999998,
              "msFromStart": 495749.4238222224
            },
            {
              "lat": 43.76613611111111,
              "lng": -116.07063515555556,
              "alt": 1754.7372222222223,
              "speed2d": 0.6862222222222221,
              "speed3d": 0.6911111111111111,
              "msFromStart": 496271.6586666667
            },
            {
              "lat": 43.76613873333333,
              "lng": -116.07063098888888,
              "alt": 1754.8366666666668,
              "speed2d": 0.657,
              "speed3d": 0.651111111111111,
              "msFromStart": 496766.7099363223
            }
          ]
        }
      ]
    },
    {
      "id": "trail-nugget-cat-track",
      "pathType": "trail",
      "name": "Nugget Cat Track",
      "difficulty": "blue",
      "isCatTrack": true,
      "videos": [
        {
          "videoId": "GH010028",
          "pathId": "trail-nugget-cat-track",
          "startSec": 0,
          "endSec": 264,
          "id": "video-segment-21",
          "gps": [
            {
              "lat": 43.75522605714286,
              "lng": -116.09426598571429,
              "alt": 2140.0345714285713,
              "speed2d": 0.11757142857142858,
              "speed3d": 0.11285714285714286,
              "msFromStart": 303.177
            },
            {
              "lat": 43.75522723333333,
              "lng": -116.09426596666667,
              "alt": 2140.0988888888887,
              "speed2d": 0.36600000000000005,
              "speed3d": 0.3433333333333334,
              "msFromStart": 742.3343333333335
            },
            {
              "lat": 43.75522779999999,
              "lng": -116.09426673333334,
              "alt": 2140.0994444444445,
              "speed2d": 0.3231111111111111,
              "speed3d": 0.29444444444444445,
              "msFromStart": 1236.1937037037037
            },
            {
              "lat": 43.75522681111111,
              "lng": -116.09427114444445,
              "alt": 2139.9864444444447,
              "speed2d": 0.6866666666666666,
              "speed3d": 0.7033333333333334,
              "msFromStart": 1729.5302222222224
            },
            {
              "lat": 43.755227520000005,
              "lng": -116.09427325,
              "alt": 2139.9036,
              "speed2d": 0.3919,
              "speed3d": 0.41100000000000003,
              "msFromStart": 2250.658335087719
            },
            {
              "lat": 43.75522636666666,
              "lng": -116.09427332222224,
              "alt": 2139.857555555556,
              "speed2d": 0.18377777777777776,
              "speed3d": 0.22666666666666668,
              "msFromStart": 2772.7305789473667
            },
            {
              "lat": 43.7552291,
              "lng": -116.09427444444444,
              "alt": 2139.757,
              "speed2d": 0.47600000000000003,
              "speed3d": 0.4511111111111111,
              "msFromStart": 3267.950419103313
            },
            {
              "lat": 43.75522906666666,
              "lng": -116.0942757,
              "alt": 2139.6765555555558,
              "speed2d": 0.1824444444444444,
              "speed3d": 0.24333333333333335,
              "msFromStart": 3764.753111111112
            },
            {
              "lat": 43.755231555555554,
              "lng": -116.09427183333332,
              "alt": 2139.6548888888888,
              "speed2d": 0.7218888888888889,
              "speed3d": 0.6111111111111112,
              "msFromStart": 4261.07625925926
            },
            {
              "lat": 43.75523417777777,
              "lng": -116.09426641111111,
              "alt": 2139.681777777778,
              "speed2d": 0.8147777777777778,
              "speed3d": 0.8722222222222222,
              "msFromStart": 4755.871666666666
            },
            {
              "lat": 43.75523556666668,
              "lng": -116.09427057777776,
              "alt": 2139.471444444445,
              "speed2d": 0.3805555555555556,
              "speed3d": 0.3822222222222223,
              "msFromStart": 5250.756092592593
            },
            {
              "lat": 43.7552380111111,
              "lng": -116.0942720888889,
              "alt": 2139.2968888888886,
              "speed2d": 0.32022222222222224,
              "speed3d": 0.4022222222222223,
              "msFromStart": 5746.1003888888945
            },
            {
              "lat": 43.7552389,
              "lng": -116.09427353333334,
              "alt": 2139.0814444444445,
              "speed2d": 0.2883333333333334,
              "speed3d": 0.2677777777777778,
              "msFromStart": 6241.154796296298
            },
            {
              "lat": 43.75524195555556,
              "lng": -116.09428103333335,
              "alt": 2138.806666666667,
              "speed2d": 0.7198888888888889,
              "speed3d": 0.7277777777777777,
              "msFromStart": 6735.356666666666
            },
            {
              "lat": 43.75524372222222,
              "lng": -116.09428106666667,
              "alt": 2138.563666666667,
              "speed2d": 0.4488888888888889,
              "speed3d": 0.48333333333333334,
              "msFromStart": 7229.936
            },
            {
              "lat": 43.755248933333334,
              "lng": -116.09428202222222,
              "alt": 2138.5744444444445,
              "speed2d": 0.9512222222222222,
              "speed3d": 0.9533333333333333,
              "msFromStart": 7725.661666666663
            },
            {
              "lat": 43.755252469999995,
              "lng": -116.09428016000003,
              "alt": 2138.5321999999996,
              "speed2d": 0.8133,
              "speed3d": 0.7939999999999999,
              "msFromStart": 8248.840231578946
            },
            {
              "lat": 43.75525735555556,
              "lng": -116.09427852222224,
              "alt": 2138.336666666667,
              "speed2d": 0.9728888888888889,
              "speed3d": 1.0222222222222224,
              "msFromStart": 8771.673421052632
            },
            {
              "lat": 43.75526035555556,
              "lng": -116.09427524444445,
              "alt": 2138.0736666666667,
              "speed2d": 0.9685555555555555,
              "speed3d": 0.9177777777777778,
              "msFromStart": 9266.965229044832
            },
            {
              "lat": 43.75526485555555,
              "lng": -116.09427295555555,
              "alt": 2137.848444444445,
              "speed2d": 1.0430000000000001,
              "speed3d": 1.1022222222222224,
              "msFromStart": 9762.233944444435
            },
            {
              "lat": 43.755267922222224,
              "lng": -116.0942731888889,
              "alt": 2137.802444444444,
              "speed2d": 0.6771111111111111,
              "speed3d": 0.7033333333333334,
              "msFromStart": 10257.25774074074
            },
            {
              "lat": 43.7552725,
              "lng": -116.09427203333331,
              "alt": 2137.8525555555557,
              "speed2d": 0.9605555555555555,
              "speed3d": 0.9444444444444445,
              "msFromStart": 10751.620055555562
            },
            {
              "lat": 43.75527567777778,
              "lng": -116.09426895555555,
              "alt": 2137.784777777778,
              "speed2d": 0.8327777777777778,
              "speed3d": 0.8455555555555556,
              "msFromStart": 11245.877814814816
            },
            {
              "lat": 43.755278144444446,
              "lng": -116.09426237777778,
              "alt": 2137.5692222222224,
              "speed2d": 1.0927777777777778,
              "speed3d": 1.0755555555555556,
              "msFromStart": 11739.946277777772
            },
            {
              "lat": 43.75528241111111,
              "lng": -116.09426216666667,
              "alt": 2137.4754444444443,
              "speed2d": 0.9157777777777778,
              "speed3d": 0.971111111111111,
              "msFromStart": 12234.48155555555
            },
            {
              "lat": 43.75528504444445,
              "lng": -116.09425983333333,
              "alt": 2137.1266666666666,
              "speed2d": 0.8512222222222222,
              "speed3d": 0.8477777777777777,
              "msFromStart": 12730.310944444434
            },
            {
              "lat": 43.75528854444445,
              "lng": -116.09425895555556,
              "alt": 2136.627444444445,
              "speed2d": 0.91,
              "speed3d": 0.9377777777777778,
              "msFromStart": 13226.083092592587
            },
            {
              "lat": 43.755291910000004,
              "lng": -116.09425488999999,
              "alt": 2136.1716,
              "speed2d": 1.0324,
              "speed3d": 1.057,
              "msFromStart": 13749.038000000004
            },
            {
              "lat": 43.75529705555555,
              "lng": -116.09425374444444,
              "alt": 2135.7966666666666,
              "speed2d": 1.1016666666666666,
              "speed3d": 1.1566666666666665,
              "msFromStart": 14271.90124074074
            },
            {
              "lat": 43.75530119999999,
              "lng": -116.09425016666667,
              "alt": 2135.3053333333332,
              "speed2d": 1.230111111111111,
              "speed3d": 1.25,
              "msFromStart": 14767.041277777776
            },
            {
              "lat": 43.75530653333334,
              "lng": -116.09424777777778,
              "alt": 2134.848111111111,
              "speed2d": 1.311111111111111,
              "speed3d": 1.3655555555555554,
              "msFromStart": 15261.82298148148
            },
            {
              "lat": 43.7553103,
              "lng": -116.09423847777778,
              "alt": 2134.4303333333332,
              "speed2d": 1.610111111111111,
              "speed3d": 1.638888888888889,
              "msFromStart": 15755.660666666665
            },
            {
              "lat": 43.75531697777778,
              "lng": -116.0942336111111,
              "alt": 2134.3304444444448,
              "speed2d": 1.5781111111111112,
              "speed3d": 1.626666666666667,
              "msFromStart": 16249.637148148144
            },
            {
              "lat": 43.755324011111114,
              "lng": -116.09422873333335,
              "alt": 2134.358111111111,
              "speed2d": 1.6055555555555556,
              "speed3d": 1.641111111111111,
              "msFromStart": 16744.12522222223
            },
            {
              "lat": 43.7553298,
              "lng": -116.09422347777777,
              "alt": 2134.2383333333337,
              "speed2d": 1.4743333333333333,
              "speed3d": 1.5366666666666666,
              "msFromStart": 17239.13217348928
            },
            {
              "lat": 43.75533668888889,
              "lng": -116.09421648888889,
              "alt": 2133.9990000000003,
              "speed2d": 1.716888888888889,
              "speed3d": 1.7311111111111113,
              "msFromStart": 17735.474421052633
            },
            {
              "lat": 43.75534318888889,
              "lng": -116.09420803333333,
              "alt": 2133.6705555555554,
              "speed2d": 1.8566666666666667,
              "speed3d": 1.8888888888888888,
              "msFromStart": 18231.39240545809
            },
            {
              "lat": 43.755350140000004,
              "lng": -116.09420126999999,
              "alt": 2133.3611,
              "speed2d": 1.8002999999999998,
              "speed3d": 1.8370000000000002,
              "msFromStart": 18752.76233333333
            },
            {
              "lat": 43.75535855555556,
              "lng": -116.09419423333334,
              "alt": 2133.2258888888887,
              "speed2d": 1.9807777777777777,
              "speed3d": 2.0044444444444443,
              "msFromStart": 19274.493148148144
            },
            {
              "lat": 43.75536817777778,
              "lng": -116.09419050000001,
              "alt": 2132.971,
              "speed2d": 2.135111111111111,
              "speed3d": 2.163333333333333,
              "msFromStart": 19770.310333333317
            },
            {
              "lat": 43.75537782222222,
              "lng": -116.09418173333333,
              "alt": 2132.8274444444446,
              "speed2d": 2.350666666666667,
              "speed3d": 2.351111111111111,
              "msFromStart": 20265.977629629626
            },
            {
              "lat": 43.75538806666667,
              "lng": -116.09417146666667,
              "alt": 2132.539777777778,
              "speed2d": 2.7224444444444447,
              "speed3d": 2.7255555555555557,
              "msFromStart": 20761.021444444435
            },
            {
              "lat": 43.75539856666667,
              "lng": -116.09416327777777,
              "alt": 2132.3865555555553,
              "speed2d": 2.655444444444444,
              "speed3d": 2.6977777777777776,
              "msFromStart": 21255.72988888889
            },
            {
              "lat": 43.75540995555555,
              "lng": -116.09415494444444,
              "alt": 2132.298222222222,
              "speed2d": 2.7238888888888892,
              "speed3d": 2.738888888888889,
              "msFromStart": 21749.61711111113
            },
            {
              "lat": 43.7554243888889,
              "lng": -116.09414600000001,
              "alt": 2132.4446666666663,
              "speed2d": 3.0593333333333335,
              "speed3d": 3.01,
              "msFromStart": 22243.964428849904
            },
            {
              "lat": 43.75544004444445,
              "lng": -116.0941356,
              "alt": 2132.6153333333336,
              "speed2d": 3.2716666666666665,
              "speed3d": 3.317777777777778,
              "msFromStart": 22739.677894736822
            },
            {
              "lat": 43.755451011111106,
              "lng": -116.09412544444444,
              "alt": 2132.3195555555553,
              "speed2d": 3.1708888888888884,
              "speed3d": 3.178888888888889,
              "msFromStart": 23235.338935672502
            },
            {
              "lat": 43.755461822222216,
              "lng": -116.09411713333334,
              "alt": 2131.7992222222224,
              "speed2d": 2.9939999999999998,
              "speed3d": 3.0199999999999996,
              "msFromStart": 23730.50266666666
            },
            {
              "lat": 43.75547563333333,
              "lng": -116.0941091222222,
              "alt": 2131.882222222222,
              "speed2d": 3.074666666666667,
              "speed3d": 3.081111111111111,
              "msFromStart": 24225.814999999995
            },
            {
              "lat": 43.755489620000006,
              "lng": -116.09409987000001,
              "alt": 2131.6706000000004,
              "speed2d": 3.1279,
              "speed3d": 3.1289999999999996,
              "msFromStart": 24749.486500000014
            },
            {
              "lat": 43.75550726666667,
              "lng": -116.09409353333334,
              "alt": 2131.7824444444445,
              "speed2d": 3.4703333333333335,
              "speed3d": 3.4333333333333336,
              "msFromStart": 25272.541222222222
            },
            {
              "lat": 43.755522077777776,
              "lng": -116.09408695555555,
              "alt": 2131.381111111111,
              "speed2d": 3.5306666666666664,
              "speed3d": 3.5522222222222224,
              "msFromStart": 25766.14066666665
            },
            {
              "lat": 43.75553822222222,
              "lng": -116.09408103333334,
              "alt": 2131.0654444444444,
              "speed2d": 3.631888888888889,
              "speed3d": 3.6133333333333337,
              "msFromStart": 26260.121074074068
            },
            {
              "lat": 43.75555657777778,
              "lng": -116.0940758888889,
              "alt": 2130.8254444444447,
              "speed2d": 3.8631111111111105,
              "speed3d": 3.852222222222222,
              "msFromStart": 26755.405444444437
            },
            {
              "lat": 43.75557501111111,
              "lng": -116.09407148888889,
              "alt": 2130.8006666666665,
              "speed2d": 4.010888888888888,
              "speed3d": 4.004444444444445,
              "msFromStart": 27250.8637037037
            },
            {
              "lat": 43.75558601111111,
              "lng": -116.0940682111111,
              "alt": 2130.7315555555556,
              "speed2d": 3.256888888888889,
              "speed3d": 3.3933333333333335,
              "msFromStart": 27746.60766666666
            },
            {
              "lat": 43.75559896666667,
              "lng": -116.09406378888889,
              "alt": 2131.0110000000004,
              "speed2d": 2.820222222222222,
              "speed3d": 2.848888888888889,
              "msFromStart": 28241.959152046773
            },
            {
              "lat": 43.7556138,
              "lng": -116.09405772222222,
              "alt": 2131.2696666666666,
              "speed2d": 2.8844444444444446,
              "speed3d": 2.8277777777777775,
              "msFromStart": 28736.20452631577
            },
            {
              "lat": 43.75562977777778,
              "lng": -116.09405187777779,
              "alt": 2130.8245555555554,
              "speed2d": 3.373222222222222,
              "speed3d": 3.3433333333333337,
              "msFromStart": 29230.48702534112
            },
            {
              "lat": 43.755649299999995,
              "lng": -116.09404411,
              "alt": 2130.6364000000003,
              "speed2d": 3.7555999999999994,
              "speed3d": 3.7569999999999997,
              "msFromStart": 29752.634333333324
            },
            {
              "lat": 43.7556642,
              "lng": -116.09403473333333,
              "alt": 2129.990111111111,
              "speed2d": 3.6408888888888886,
              "speed3d": 3.648888888888889,
              "msFromStart": 30274.946296296293
            },
            {
              "lat": 43.75567981111111,
              "lng": -116.09402448888889,
              "alt": 2129.3363333333336,
              "speed2d": 3.852,
              "speed3d": 3.8400000000000003,
              "msFromStart": 30770.11822222224
            },
            {
              "lat": 43.755700211111105,
              "lng": -116.09401105555555,
              "alt": 2129.306111111111,
              "speed2d": 4.269444444444445,
              "speed3d": 4.261111111111111,
              "msFromStart": 31265.459629629637
            },
            {
              "lat": 43.75571914444444,
              "lng": -116.09399651111111,
              "alt": 2128.8372222222224,
              "speed2d": 4.604666666666667,
              "speed3d": 4.613333333333333,
              "msFromStart": 31761.211999999996
            },
            {
              "lat": 43.75573746666666,
              "lng": -116.09397994444444,
              "alt": 2128.027222222222,
              "speed2d": 5.016444444444444,
              "speed3d": 5.0233333333333325,
              "msFromStart": 32256.94348148148
            },
            {
              "lat": 43.75575928888889,
              "lng": -116.09396135555558,
              "alt": 2127.209222222222,
              "speed2d": 5.706444444444444,
              "speed3d": 5.7011111111111115,
              "msFromStart": 32752.559555555556
            },
            {
              "lat": 43.75578133333333,
              "lng": -116.09394076666666,
              "alt": 2126.1094444444448,
              "speed2d": 6.164777777777779,
              "speed3d": 6.21111111111111,
              "msFromStart": 33247.82695126706
            },
            {
              "lat": 43.75580464444445,
              "lng": -116.09391812222222,
              "alt": 2124.8487777777777,
              "speed2d": 6.632333333333333,
              "speed3d": 6.683333333333333,
              "msFromStart": 33742.164421052665
            },
            {
              "lat": 43.7558305,
              "lng": -116.09389702222222,
              "alt": 2123.1674444444443,
              "speed2d": 7.138888888888889,
              "speed3d": 7.235555555555555,
              "msFromStart": 34236.50914619885
            },
            {
              "lat": 43.755860199999994,
              "lng": -116.09387881111113,
              "alt": 2121.610777777778,
              "speed2d": 7.601555555555555,
              "speed3d": 7.744444444444445,
              "msFromStart": 34731.078666666646
            },
            {
              "lat": 43.755891088888895,
              "lng": -116.0938645,
              "alt": 2119.8535555555554,
              "speed2d": 7.918111111111112,
              "speed3d": 8.091111111111111,
              "msFromStart": 35225.72696296296
            },
            {
              "lat": 43.75593044000001,
              "lng": -116.09384813999999,
              "alt": 2118.661,
              "speed2d": 8.539,
              "speed3d": 8.664,
              "msFromStart": 35748.09466666669
            },
            {
              "lat": 43.75597685555556,
              "lng": -116.09383276666668,
              "alt": 2117.507777777778,
              "speed2d": 9.464111111111112,
              "speed3d": 9.606666666666666,
              "msFromStart": 36270.60881481481
            },
            {
              "lat": 43.75602097777778,
              "lng": -116.0938166888889,
              "alt": 2116.1791111111106,
              "speed2d": 10.11311111111111,
              "speed3d": 10.26222222222222,
              "msFromStart": 36765.96511111107
            },
            {
              "lat": 43.75606644444444,
              "lng": -116.0937979,
              "alt": 2114.774888888889,
              "speed2d": 10.69688888888889,
              "speed3d": 10.853333333333333,
              "msFromStart": 37261.54081481479
            },
            {
              "lat": 43.75611602222222,
              "lng": -116.09377534444444,
              "alt": 2113.666111111111,
              "speed2d": 11.41211111111111,
              "speed3d": 11.554444444444446,
              "msFromStart": 37757.66399999997
            },
            {
              "lat": 43.75616653333334,
              "lng": -116.0937505111111,
              "alt": 2112.463777777778,
              "speed2d": 11.944111111111113,
              "speed3d": 12.113333333333333,
              "msFromStart": 38253.240771929806
            },
            {
              "lat": 43.756218833333335,
              "lng": -116.09372043333332,
              "alt": 2111.653222222222,
              "speed2d": 12.318666666666665,
              "speed3d": 12.498888888888887,
              "msFromStart": 38747.256210526306
            },
            {
              "lat": 43.756269833333334,
              "lng": -116.09368857777778,
              "alt": 2110.5737777777777,
              "speed2d": 12.523000000000001,
              "speed3d": 12.714444444444444,
              "msFromStart": 39241.42109161793
            },
            {
              "lat": 43.75632197777777,
              "lng": -116.09365550000001,
              "alt": 2109.523,
              "speed2d": 12.722999999999999,
              "speed3d": 12.893333333333334,
              "msFromStart": 39736.51244444446
            },
            {
              "lat": 43.75637429999999,
              "lng": -116.09362048888889,
              "alt": 2108.426,
              "speed2d": 12.996777777777778,
              "speed3d": 13.164444444444444,
              "msFromStart": 40231.75162962963
            },
            {
              "lat": 43.756428855555555,
              "lng": -116.09358481111113,
              "alt": 2107.3922222222222,
              "speed2d": 13.389111111111111,
              "speed3d": 13.54,
              "msFromStart": 40727.21155555554
            },
            {
              "lat": 43.756487390000004,
              "lng": -116.09354843999999,
              "alt": 2106.2235,
              "speed2d": 13.684500000000002,
              "speed3d": 13.856999999999998,
              "msFromStart": 41250.241333333324
            },
            {
              "lat": 43.7565472,
              "lng": -116.09351111111111,
              "alt": 2105.241,
              "speed2d": 13.821333333333332,
              "speed3d": 14.008888888888887,
              "msFromStart": 41773.33911111112
            },
            {
              "lat": 43.7566037,
              "lng": -116.09347602222223,
              "alt": 2104.254888888889,
              "speed2d": 13.82411111111111,
              "speed3d": 14.012222222222222,
              "msFromStart": 42268.89451851853
            },
            {
              "lat": 43.75666142222222,
              "lng": -116.09344007777779,
              "alt": 2103.189111111111,
              "speed2d": 14.088888888888889,
              "speed3d": 14.25111111111111,
              "msFromStart": 42764.40488888891
            },
            {
              "lat": 43.75671952222223,
              "lng": -116.0934051888889,
              "alt": 2102.226333333333,
              "speed2d": 14.109777777777778,
              "speed3d": 14.306666666666665,
              "msFromStart": 43259.68244834308
            },
            {
              "lat": 43.75677826666666,
              "lng": -116.09337175555557,
              "alt": 2101.483888888889,
              "speed2d": 14.06911111111111,
              "speed3d": 14.263333333333332,
              "msFromStart": 43754.33452631575
            },
            {
              "lat": 43.75683694444445,
              "lng": -116.09334092222223,
              "alt": 2100.8031111111113,
              "speed2d": 13.943555555555555,
              "speed3d": 14.133333333333335,
              "msFromStart": 44248.964580896674
            },
            {
              "lat": 43.75689657777778,
              "lng": -116.09331194444442,
              "alt": 2100.271111111111,
              "speed2d": 13.908666666666665,
              "speed3d": 14.077777777777778,
              "msFromStart": 44743.638222222246
            },
            {
              "lat": 43.75695654444445,
              "lng": -116.0932844888889,
              "alt": 2099.734,
              "speed2d": 13.79111111111111,
              "speed3d": 13.969999999999997,
              "msFromStart": 45238.2691851852
            },
            {
              "lat": 43.75701701111112,
              "lng": -116.09325831111111,
              "alt": 2099.564,
              "speed2d": 13.615444444444442,
              "speed3d": 13.776666666666667,
              "msFromStart": 45732.712000000014
            },
            {
              "lat": 43.75707548888889,
              "lng": -116.09323458888889,
              "alt": 2099.1213333333335,
              "speed2d": 13.362555555555556,
              "speed3d": 13.557777777777778,
              "msFromStart": 46227.62711111112
            },
            {
              "lat": 43.75713011111111,
              "lng": -116.09321211111111,
              "alt": 2098.4320000000002,
              "speed2d": 12.874666666666663,
              "speed3d": 13.044444444444444,
              "msFromStart": 46724.5066666667
            },
            {
              "lat": 43.75718854000001,
              "lng": -116.09318607,
              "alt": 2098.1103000000003,
              "speed2d": 12.6988,
              "speed3d": 12.834000000000001,
              "msFromStart": 47248.33162105264
            },
            {
              "lat": 43.75724493333333,
              "lng": -116.09315795555557,
              "alt": 2097.249222222222,
              "speed2d": 12.630111111111113,
              "speed3d": 12.781111111111109,
              "msFromStart": 47769.35115789471
            },
            {
              "lat": 43.757295733333336,
              "lng": -116.09312532222222,
              "alt": 2096.5898888888887,
              "speed2d": 12.42088888888889,
              "speed3d": 12.574444444444445,
              "msFromStart": 48263.26022612084
            },
            {
              "lat": 43.757343677777776,
              "lng": -116.0930893,
              "alt": 2095.965222222222,
              "speed2d": 12.08711111111111,
              "speed3d": 12.25888888888889,
              "msFromStart": 48759.35377777775
            },
            {
              "lat": 43.757390744444436,
              "lng": -116.09305029999999,
              "alt": 2095.4714444444444,
              "speed2d": 11.994222222222222,
              "speed3d": 12.12111111111111,
              "msFromStart": 49255.5591111111
            },
            {
              "lat": 43.75743467777778,
              "lng": -116.09300944444445,
              "alt": 2094.958777777778,
              "speed2d": 11.636555555555555,
              "speed3d": 11.812222222222221,
              "msFromStart": 49751.33777777778
            },
            {
              "lat": 43.7574769888889,
              "lng": -116.09297143333333,
              "alt": 2094.293888888889,
              "speed2d": 11.217666666666666,
              "speed3d": 11.376666666666665,
              "msFromStart": 50246.948222222214
            },
            {
              "lat": 43.75751857777779,
              "lng": -116.09293252222221,
              "alt": 2093.461555555555,
              "speed2d": 11.122222222222222,
              "speed3d": 11.243333333333334,
              "msFromStart": 50742.05644444443
            },
            {
              "lat": 43.75756052222222,
              "lng": -116.09289285555555,
              "alt": 2092.8821111111115,
              "speed2d": 11.103444444444444,
              "speed3d": 11.233333333333334,
              "msFromStart": 51237.25407407407
            },
            {
              "lat": 43.75760222222223,
              "lng": -116.09285294444443,
              "alt": 2092.1073333333334,
              "speed2d": 11.214555555555556,
              "speed3d": 11.336666666666666,
              "msFromStart": 51733.01022222221
            },
            {
              "lat": 43.757643666666674,
              "lng": -116.09281399999998,
              "alt": 2091.322888888889,
              "speed2d": 11.091222222222221,
              "speed3d": 11.23888888888889,
              "msFromStart": 52228.867407407415
            },
            {
              "lat": 43.75768359999999,
              "lng": -116.09277463333335,
              "alt": 2090.5995555555555,
              "speed2d": 10.888666666666667,
              "speed3d": 11.026666666666666,
              "msFromStart": 52724.905333333365
            },
            {
              "lat": 43.757724870000004,
              "lng": -116.09273075,
              "alt": 2090.1187,
              "speed2d": 10.7407,
              "speed3d": 10.884,
              "msFromStart": 53247.75677894738
            },
            {
              "lat": 43.75776195555556,
              "lng": -116.09268837777778,
              "alt": 2089.479666666666,
              "speed2d": 10.357444444444443,
              "speed3d": 10.54,
              "msFromStart": 53768.13684210529
            },
            {
              "lat": 43.75779577777777,
              "lng": -116.09264861111112,
              "alt": 2089.2135555555556,
              "speed2d": 9.877666666666666,
              "speed3d": 10.03888888888889,
              "msFromStart": 54261.414662768046
            },
            {
              "lat": 43.75782715555555,
              "lng": -116.09260472222223,
              "alt": 2088.9687777777776,
              "speed2d": 9.514444444444445,
              "speed3d": 9.67222222222222,
              "msFromStart": 54756.66355555554
            },
            {
              "lat": 43.757856344444434,
              "lng": -116.09256042222222,
              "alt": 2088.6424444444447,
              "speed2d": 9.173555555555556,
              "speed3d": 9.314444444444444,
              "msFromStart": 55251.70955555554
            },
            {
              "lat": 43.75788441111112,
              "lng": -116.09251785555557,
              "alt": 2088.038111111111,
              "speed2d": 9.095222222222223,
              "speed3d": 9.17888888888889,
              "msFromStart": 55745.15555555556
            },
            {
              "lat": 43.75791021111111,
              "lng": -116.09247091111112,
              "alt": 2086.6233333333334,
              "speed2d": 9.46,
              "speed3d": 9.562222222222221,
              "msFromStart": 56238.85377777778
            },
            {
              "lat": 43.75793493333334,
              "lng": -116.0924212111111,
              "alt": 2085.0698888888887,
              "speed2d": 9.793555555555557,
              "speed3d": 9.914444444444445,
              "msFromStart": 56734.20088888888
            },
            {
              "lat": 43.75795937777778,
              "lng": -116.09236923333334,
              "alt": 2084.117,
              "speed2d": 9.857666666666667,
              "speed3d": 10.02888888888889,
              "msFromStart": 57229.68066666667
            },
            {
              "lat": 43.75798268888889,
              "lng": -116.0923162,
              "alt": 2083.657444444444,
              "speed2d": 9.691,
              "speed3d": 9.854444444444445,
              "msFromStart": 57725.031555555586
            },
            {
              "lat": 43.75800488,
              "lng": -116.0922561,
              "alt": 2082.8931000000002,
              "speed2d": 9.9251,
              "speed3d": 10.026,
              "msFromStart": 58247.75774035089
            },
            {
              "lat": 43.75802708888889,
              "lng": -116.09219458888889,
              "alt": 2082.6612222222225,
              "speed2d": 10.054111111111112,
              "speed3d": 10.212222222222222,
              "msFromStart": 58770.061052631565
            },
            {
              "lat": 43.758050611111116,
              "lng": -116.09214004444443,
              "alt": 2083.378,
              "speed2d": 9.626,
              "speed3d": 9.783333333333335,
              "msFromStart": 59264.76412475634
            },
            {
              "lat": 43.758073844444446,
              "lng": -116.09209056666666,
              "alt": 2083.6009999999997,
              "speed2d": 9.123555555555555,
              "speed3d": 9.27888888888889,
              "msFromStart": 59759.16622222226
            },
            {
              "lat": 43.758097066666664,
              "lng": -116.09204231111113,
              "alt": 2083.8584444444446,
              "speed2d": 8.876777777777779,
              "speed3d": 8.973333333333334,
              "msFromStart": 60253.66674074075
            },
            {
              "lat": 43.758121422222224,
              "lng": -116.09199744444446,
              "alt": 2083.985222222222,
              "speed2d": 8.651333333333334,
              "speed3d": 8.762222222222222,
              "msFromStart": 60748.681333333356
            },
            {
              "lat": 43.75814555555556,
              "lng": -116.09195452222224,
              "alt": 2083.761888888889,
              "speed2d": 8.387888888888888,
              "speed3d": 8.485555555555557,
              "msFromStart": 61243.65177777778
            },
            {
              "lat": 43.75817118888889,
              "lng": -116.0919145888889,
              "alt": 2083.881111111111,
              "speed2d": 8.142666666666667,
              "speed3d": 8.202222222222222,
              "msFromStart": 61738.23999999997
            },
            {
              "lat": 43.758196277777785,
              "lng": -116.09187267777779,
              "alt": 2083.320222222222,
              "speed2d": 8.420666666666666,
              "speed3d": 8.475555555555555,
              "msFromStart": 62232.986296296265
            },
            {
              "lat": 43.75822054444444,
              "lng": -116.09182937777778,
              "alt": 2082.965666666667,
              "speed2d": 8.557111111111112,
              "speed3d": 8.6,
              "msFromStart": 62728.51777777774
            },
            {
              "lat": 43.75824414,
              "lng": -116.09178124000002,
              "alt": 2082.6561,
              "speed2d": 8.633799999999999,
              "speed3d": 8.715,
              "msFromStart": 63251.582996491205
            },
            {
              "lat": 43.758266688888895,
              "lng": -116.09173466666667,
              "alt": 2082.6587777777777,
              "speed2d": 8.491666666666667,
              "speed3d": 8.532222222222222,
              "msFromStart": 63774.39347368421
            },
            {
              "lat": 43.75828806666667,
              "lng": -116.0916867222222,
              "alt": 2082.6156666666666,
              "speed2d": 8.781888888888886,
              "speed3d": 8.79888888888889,
              "msFromStart": 64269.367270955176
            },
            {
              "lat": 43.75830526666667,
              "lng": -116.09163511111112,
              "alt": 2082.3427777777774,
              "speed2d": 8.997222222222224,
              "speed3d": 9.022222222222222,
              "msFromStart": 64763.1448888889
            },
            {
              "lat": 43.758311722222224,
              "lng": -116.09158176666666,
              "alt": 2081.5608888888887,
              "speed2d": 8.751555555555555,
              "speed3d": 8.889999999999999,
              "msFromStart": 65257.1677037037
            },
            {
              "lat": 43.75831002222222,
              "lng": -116.09153446666667,
              "alt": 2080.69,
              "speed2d": 7.9672222222222215,
              "speed3d": 8.117777777777777,
              "msFromStart": 65752.64977777777
            },
            {
              "lat": 43.758306366666666,
              "lng": -116.09149287777777,
              "alt": 2079.882444444445,
              "speed2d": 7.259555555555556,
              "speed3d": 7.42111111111111,
              "msFromStart": 66248.20814814816
            },
            {
              "lat": 43.75829993333333,
              "lng": -116.09145136666667,
              "alt": 2079.520666666667,
              "speed2d": 7.0122222222222215,
              "speed3d": 7.0777777777777775,
              "msFromStart": 66743.48800000006
            },
            {
              "lat": 43.75829474444444,
              "lng": -116.09140406666666,
              "alt": 2079.7146666666667,
              "speed2d": 7.272222222222222,
              "speed3d": 7.331111111111111,
              "msFromStart": 67238.68222222225
            },
            {
              "lat": 43.75828274444444,
              "lng": -116.0913529,
              "alt": 2080.686666666667,
              "speed2d": 7.642444444444444,
              "speed3d": 7.656666666666666,
              "msFromStart": 67733.64533333332
            },
            {
              "lat": 43.75827029999999,
              "lng": -116.09130387777779,
              "alt": 2081.280222222222,
              "speed2d": 7.85188888888889,
              "speed3d": 7.873333333333333,
              "msFromStart": 68228.31363742688
            },
            {
              "lat": 43.758256,
              "lng": -116.09124907999998,
              "alt": 2082.0623,
              "speed2d": 8.273399999999999,
              "speed3d": 8.239,
              "msFromStart": 68749.31031578944
            },
            {
              "lat": 43.75824785555556,
              "lng": -116.09119742222221,
              "alt": 2082.050222222222,
              "speed2d": 7.981,
              "speed3d": 8.142222222222221,
              "msFromStart": 69270.52630799217
            },
            {
              "lat": 43.7582442,
              "lng": -116.09115472222223,
              "alt": 2081.2613333333334,
              "speed2d": 7.244777777777778,
              "speed3d": 7.301111111111112,
              "msFromStart": 69765.63911111107
            },
            {
              "lat": 43.75824323333334,
              "lng": -116.09110863333332,
              "alt": 2081.0541111111106,
              "speed2d": 6.972999999999999,
              "speed3d": 7.043333333333333,
              "msFromStart": 70260.9631111111
            },
            {
              "lat": 43.75824448888888,
              "lng": -116.09106246666667,
              "alt": 2080.541111111111,
              "speed2d": 7.0362222222222215,
              "speed3d": 7.043333333333333,
              "msFromStart": 70756.60711111117
            },
            {
              "lat": 43.75824537777778,
              "lng": -116.09101521111111,
              "alt": 2080.429222222222,
              "speed2d": 7.201222222222222,
              "speed3d": 7.247777777777777,
              "msFromStart": 71251.98014814818
            },
            {
              "lat": 43.75824813333333,
              "lng": -116.09097117777776,
              "alt": 2080.1542222222224,
              "speed2d": 7.209777777777777,
              "speed3d": 7.252222222222222,
              "msFromStart": 71746.14133333339
            },
            {
              "lat": 43.758248888888886,
              "lng": -116.0909266111111,
              "alt": 2079.9504444444447,
              "speed2d": 6.979222222222222,
              "speed3d": 7.0566666666666675,
              "msFromStart": 72240.54437426903
            },
            {
              "lat": 43.75824787777778,
              "lng": -116.09088161111112,
              "alt": 2079.6184444444443,
              "speed2d": 7.017666666666667,
              "speed3d": 7.029999999999999,
              "msFromStart": 72736.39957894731
            },
            {
              "lat": 43.75824313333333,
              "lng": -116.09083374444444,
              "alt": 2079.1627777777776,
              "speed2d": 7.206333333333333,
              "speed3d": 7.216666666666667,
              "msFromStart": 73232.26315789469
            },
            {
              "lat": 43.758231888888886,
              "lng": -116.09078145555556,
              "alt": 2078.394111111111,
              "speed2d": 7.562888888888889,
              "speed3d": 7.577777777777776,
              "msFromStart": 73727.29199999997
            },
            {
              "lat": 43.758220249999994,
              "lng": -116.09072893999999,
              "alt": 2078.4146,
              "speed2d": 7.7006,
              "speed3d": 7.7620000000000005,
              "msFromStart": 74249.81266666665
            },
            {
              "lat": 43.75820812222222,
              "lng": -116.09069190000002,
              "alt": 2078.171444444445,
              "speed2d": 7.104222222222221,
              "speed3d": 7.194444444444444,
              "msFromStart": 74772.96177777785
            },
            {
              "lat": 43.758191922222224,
              "lng": -116.09065676666665,
              "alt": 2076.864888888889,
              "speed2d": 6.899444444444445,
              "speed3d": 6.954444444444445,
              "msFromStart": 75268.65511111116
            },
            {
              "lat": 43.75817793333333,
              "lng": -116.0906230111111,
              "alt": 2074.797555555556,
              "speed2d": 6.802888888888889,
              "speed3d": 6.902222222222223,
              "msFromStart": 75764.32177777785
            },
            {
              "lat": 43.758157422222226,
              "lng": -116.09059025555555,
              "alt": 2073.6981111111113,
              "speed2d": 6.837555555555555,
              "speed3d": 6.935555555555556,
              "msFromStart": 76259.56651851855
            },
            {
              "lat": 43.75813804444445,
              "lng": -116.09056108888888,
              "alt": 2072.415555555556,
              "speed2d": 6.7090000000000005,
              "speed3d": 6.825555555555557,
              "msFromStart": 76753.13422222224
            },
            {
              "lat": 43.75811946666668,
              "lng": -116.09053325555556,
              "alt": 2070.970888888889,
              "speed2d": 6.487333333333333,
              "speed3d": 6.65,
              "msFromStart": 77246.93496296296
            },
            {
              "lat": 43.75809803333333,
              "lng": -116.09050455555555,
              "alt": 2070.434888888889,
              "speed2d": 6.39711111111111,
              "speed3d": 6.511111111111112,
              "msFromStart": 77742.33866666665
            },
            {
              "lat": 43.758074577777776,
              "lng": -116.09046562222223,
              "alt": 2069.715222222222,
              "speed2d": 6.795999999999999,
              "speed3d": 6.862222222222222,
              "msFromStart": 78237.77716959063
            },
            {
              "lat": 43.75806198888889,
              "lng": -116.09042194444444,
              "alt": 2067.780444444444,
              "speed2d": 6.910888888888888,
              "speed3d": 7.054444444444445,
              "msFromStart": 78732.71368421047
            },
            {
              "lat": 43.75805731111111,
              "lng": -116.09038376666669,
              "alt": 2065.422111111111,
              "speed2d": 6.799888888888889,
              "speed3d": 6.954444444444444,
              "msFromStart": 79227.7513190383
            },
            {
              "lat": 43.75805472,
              "lng": -116.09034167000002,
              "alt": 2063.1295,
              "speed2d": 6.8722,
              "speed3d": 7.041,
              "msFromStart": 79751.27377777784
            },
            {
              "lat": 43.75805254444445,
              "lng": -116.09029619999998,
              "alt": 2061.2206666666666,
              "speed2d": 7.013888888888888,
              "speed3d": 7.217777777777779,
              "msFromStart": 80274.7134814815
            },
            {
              "lat": 43.75804864444445,
              "lng": -116.09025412222222,
              "alt": 2059.7927777777777,
              "speed2d": 6.935777777777778,
              "speed3d": 7.141111111111111,
              "msFromStart": 80769.65511111112
            },
            {
              "lat": 43.758047,
              "lng": -116.09021421111112,
              "alt": 2058.3687777777777,
              "speed2d": 6.8151111111111105,
              "speed3d": 7.039999999999999,
              "msFromStart": 81264.612
            },
            {
              "lat": 43.758042,
              "lng": -116.09017216666666,
              "alt": 2057.260333333333,
              "speed2d": 6.687888888888888,
              "speed3d": 6.902222222222222,
              "msFromStart": 81760.00444444444
            },
            {
              "lat": 43.75803604444444,
              "lng": -116.09012612222222,
              "alt": 2056.479888888889,
              "speed2d": 6.784777777777777,
              "speed3d": 6.9799999999999995,
              "msFromStart": 82255.30118518519
            },
            {
              "lat": 43.75802965555555,
              "lng": -116.09007784444444,
              "alt": 2056.0028888888887,
              "speed2d": 6.9718888888888895,
              "speed3d": 7.115555555555555,
              "msFromStart": 82750.04088888892
            },
            {
              "lat": 43.75802555555556,
              "lng": -116.09002492222221,
              "alt": 2055.4301111111113,
              "speed2d": 7.514333333333333,
              "speed3d": 7.633333333333334,
              "msFromStart": 83244.84586354776
            },
            {
              "lat": 43.758025833333335,
              "lng": -116.08997513333333,
              "alt": 2054.8234444444447,
              "speed2d": 7.683222222222221,
              "speed3d": 7.83,
              "msFromStart": 83740.13473684205
            },
            {
              "lat": 43.7580271,
              "lng": -116.0899281,
              "alt": 2054.302222222222,
              "speed2d": 7.55411111111111,
              "speed3d": 7.703333333333333,
              "msFromStart": 84235.56962183231
            },
            {
              "lat": 43.75802596666666,
              "lng": -116.08988043333335,
              "alt": 2053.208666666667,
              "speed2d": 7.511444444444445,
              "speed3d": 7.651111111111112,
              "msFromStart": 84731.59999999998
            },
            {
              "lat": 43.75802757777778,
              "lng": -116.08983145555554,
              "alt": 2053.071888888889,
              "speed2d": 7.621888888888889,
              "speed3d": 7.7233333333333345,
              "msFromStart": 85227.63417283948
            },
            {
              "lat": 43.75803028,
              "lng": -116.08978372,
              "alt": 2051.9569,
              "speed2d": 7.4953,
              "speed3d": 7.675,
              "msFromStart": 85750.61644444447
            },
            {
              "lat": 43.75803582222222,
              "lng": -116.08974697777778,
              "alt": 2048.993111111111,
              "speed2d": 6.817,
              "speed3d": 7.038888888888889,
              "msFromStart": 86273.54933333336
            },
            {
              "lat": 43.75805131111111,
              "lng": -116.08971833333334,
              "alt": 2045.4351111111112,
              "speed2d": 6.309888888888889,
              "speed3d": 6.633333333333333,
              "msFromStart": 86769.1395555555
            },
            {
              "lat": 43.75806417777777,
              "lng": -116.0896888111111,
              "alt": 2043.542777777778,
              "speed2d": 5.779222222222221,
              "speed3d": 6.102222222222222,
              "msFromStart": 87265.05570370366
            },
            {
              "lat": 43.75807687777778,
              "lng": -116.0896547222222,
              "alt": 2042.6208888888889,
              "speed2d": 5.807222222222221,
              "speed3d": 6.114444444444445,
              "msFromStart": 87762.20444444443
            },
            {
              "lat": 43.75809094444444,
              "lng": -116.08962170000001,
              "alt": 2041.896777777778,
              "speed2d": 5.899777777777778,
              "speed3d": 6.165555555555555,
              "msFromStart": 88258.77125925924
            },
            {
              "lat": 43.758102944444445,
              "lng": -116.08957825555555,
              "alt": 2042.1986666666667,
              "speed2d": 6.548222222222222,
              "speed3d": 6.716666666666669,
              "msFromStart": 88752.51733333328
            },
            {
              "lat": 43.758117,
              "lng": -116.08953893333333,
              "alt": 2042.807,
              "speed2d": 6.816555555555555,
              "speed3d": 7.037777777777778,
              "msFromStart": 89245.75981286546
            },
            {
              "lat": 43.75813235555555,
              "lng": -116.0895045888889,
              "alt": 2043.7538888888887,
              "speed2d": 6.714222222222221,
              "speed3d": 6.898888888888889,
              "msFromStart": 89738.11621052634
            },
            {
              "lat": 43.758149844444446,
              "lng": -116.08947936666667,
              "alt": 2044.3253333333334,
              "speed2d": 6.353222222222222,
              "speed3d": 6.555555555555555,
              "msFromStart": 90230.66797660822
            },
            {
              "lat": 43.75816899,
              "lng": -116.08945585,
              "alt": 2044.7854000000002,
              "speed2d": 6.0758,
              "speed3d": 6.228,
              "msFromStart": 90752.42800000007
            },
            {
              "lat": 43.75818885555555,
              "lng": -116.08943334444446,
              "alt": 2044.8137777777777,
              "speed2d": 6.143888888888888,
              "speed3d": 6.263333333333334,
              "msFromStart": 91274.62237037042
            },
            {
              "lat": 43.75820757777778,
              "lng": -116.08941013333333,
              "alt": 2045.132111111111,
              "speed2d": 6.111777777777778,
              "speed3d": 6.232222222222222,
              "msFromStart": 91769.94222222226
            },
            {
              "lat": 43.75822843333333,
              "lng": -116.08938586666667,
              "alt": 2045.311888888889,
              "speed2d": 6.334666666666666,
              "speed3d": 6.4,
              "msFromStart": 92265.3888888889
            },
            {
              "lat": 43.75825247777779,
              "lng": -116.08936721111111,
              "alt": 2044.8723333333335,
              "speed2d": 6.5971111111111105,
              "speed3d": 6.685555555555556,
              "msFromStart": 92761.1022222222
            },
            {
              "lat": 43.75827612222222,
              "lng": -116.08934971111111,
              "alt": 2044.2818888888887,
              "speed2d": 6.653111111111111,
              "speed3d": 6.731111111111111,
              "msFromStart": 93256.61676413252
            },
            {
              "lat": 43.75830104444444,
              "lng": -116.08933585555557,
              "alt": 2043.5534444444445,
              "speed2d": 6.733444444444444,
              "speed3d": 6.793333333333334,
              "msFromStart": 93751.22947368413
            },
            {
              "lat": 43.75832643333333,
              "lng": -116.08931845555557,
              "alt": 2042.9934444444445,
              "speed2d": 6.9605555555555565,
              "speed3d": 7.015555555555556,
              "msFromStart": 94245.89242884985
            },
            {
              "lat": 43.75835211111111,
              "lng": -116.08929350000001,
              "alt": 2042.6354444444446,
              "speed2d": 7.344333333333334,
              "speed3d": 7.367777777777777,
              "msFromStart": 94741.41200000001
            },
            {
              "lat": 43.75837844444444,
              "lng": -116.08926863333335,
              "alt": 2042.2250000000001,
              "speed2d": 7.660333333333334,
              "speed3d": 7.711111111111111,
              "msFromStart": 95237.0107654321
            },
            {
              "lat": 43.75840446666667,
              "lng": -116.08924425555556,
              "alt": 2041.7272222222223,
              "speed2d": 7.699333333333334,
              "speed3d": 7.746666666666667,
              "msFromStart": 95732.23599999999
            },
            {
              "lat": 43.75843038888889,
              "lng": -116.08921566666666,
              "alt": 2041.4525555555556,
              "speed2d": 7.854888888888889,
              "speed3d": 7.871111111111111,
              "msFromStart": 96227.33777777776
            },
            {
              "lat": 43.758458929999996,
              "lng": -116.08918292999998,
              "alt": 2041.7664,
              "speed2d": 8.007,
              "speed3d": 8.064,
              "msFromStart": 96749.54111111112
            },
            {
              "lat": 43.75848695555556,
              "lng": -116.08915068888889,
              "alt": 2041.9974444444445,
              "speed2d": 8.060777777777778,
              "speed3d": 8.044444444444444,
              "msFromStart": 97271.8177777778
            },
            {
              "lat": 43.758514077777775,
              "lng": -116.0891178888889,
              "alt": 2042.2103333333334,
              "speed2d": 8.270999999999999,
              "speed3d": 8.297777777777778,
              "msFromStart": 97767.1555555556
            },
            {
              "lat": 43.75853975555556,
              "lng": -116.0890864888889,
              "alt": 2042.0322222222221,
              "speed2d": 8.184777777777777,
              "speed3d": 8.197777777777777,
              "msFromStart": 98262.35355945423
            },
            {
              "lat": 43.75856644444445,
              "lng": -116.08905534444443,
              "alt": 2042.0774444444444,
              "speed2d": 8.096222222222222,
              "speed3d": 8.12,
              "msFromStart": 98756.77557894736
            },
            {
              "lat": 43.758591833333334,
              "lng": -116.0890214,
              "alt": 2042.0215555555556,
              "speed2d": 8.143888888888888,
              "speed3d": 8.136666666666667,
              "msFromStart": 99251.25772579596
            },
            {
              "lat": 43.75861851111111,
              "lng": -116.08898748888888,
              "alt": 2041.6004444444443,
              "speed2d": 8.377777777777778,
              "speed3d": 8.383333333333333,
              "msFromStart": 99746.57999999999
            },
            {
              "lat": 43.75864676666667,
              "lng": -116.0889568111111,
              "alt": 2040.678777777778,
              "speed2d": 8.588777777777777,
              "speed3d": 8.54,
              "msFromStart": 100242.04049382717
            },
            {
              "lat": 43.75867623333333,
              "lng": -116.0889298,
              "alt": 2039.4815555555554,
              "speed2d": 8.595555555555555,
              "speed3d": 8.603333333333333,
              "msFromStart": 100737.50400000003
            },
            {
              "lat": 43.758708166666665,
              "lng": -116.0889011,
              "alt": 2038.7123333333336,
              "speed2d": 8.75088888888889,
              "speed3d": 8.807777777777778,
              "msFromStart": 101232.93935802474
            },
            {
              "lat": 43.758735066666674,
              "lng": -116.08886303333333,
              "alt": 2038.3046666666667,
              "speed2d": 8.822111111111111,
              "speed3d": 8.772222222222222,
              "msFromStart": 101728.19999999995
            },
            {
              "lat": 43.75876442,
              "lng": -116.08881195999999,
              "alt": 2038.6283,
              "speed2d": 9.246,
              "speed3d": 9.228,
              "msFromStart": 102250.72755555552
            },
            {
              "lat": 43.758794211111116,
              "lng": -116.08876822222221,
              "alt": 2038.639333333333,
              "speed2d": 9.319555555555555,
              "speed3d": 9.352222222222222,
              "msFromStart": 102772.2408888889
            },
            {
              "lat": 43.75882081111111,
              "lng": -116.08873635555557,
              "alt": 2037.7235555555555,
              "speed2d": 9.103333333333333,
              "speed3d": 9.152222222222221,
              "msFromStart": 103266.46074074073
            },
            {
              "lat": 43.75884485555556,
              "lng": -116.08871024444444,
              "alt": 2036.3232222222223,
              "speed2d": 8.560555555555554,
              "speed3d": 8.668888888888889,
              "msFromStart": 103761.84799999997
            },
            {
              "lat": 43.75885927777777,
              "lng": -116.08868773333333,
              "alt": 2034.0119999999997,
              "speed2d": 7.480888888888891,
              "speed3d": 7.640000000000001,
              "msFromStart": 104257.26311111107
            },
            {
              "lat": 43.75887091111112,
              "lng": -116.08866057777777,
              "alt": 2031.6118888888889,
              "speed2d": 6.879555555555556,
              "speed3d": 7.01,
              "msFromStart": 104752.13599999994
            },
            {
              "lat": 43.7588792,
              "lng": -116.08862174444445,
              "alt": 2030.2287777777779,
              "speed2d": 6.836888888888889,
              "speed3d": 6.895555555555556,
              "msFromStart": 105246.8607407407
            },
            {
              "lat": 43.75888556666667,
              "lng": -116.08857116666668,
              "alt": 2029.3113333333336,
              "speed2d": 7.329888888888888,
              "speed3d": 7.407777777777777,
              "msFromStart": 105741.22399999999
            },
            {
              "lat": 43.75888971111112,
              "lng": -116.08851674444443,
              "alt": 2029.4418888888888,
              "speed2d": 7.583999999999999,
              "speed3d": 7.6433333333333335,
              "msFromStart": 106235.69738271605
            },
            {
              "lat": 43.75889272222222,
              "lng": -116.08845678888889,
              "alt": 2030.3410000000003,
              "speed2d": 8.10588888888889,
              "speed3d": 8.117777777777777,
              "msFromStart": 106731.20400000007
            },
            {
              "lat": 43.758891799999994,
              "lng": -116.08839378888888,
              "alt": 2030.7458888888887,
              "speed2d": 8.712444444444445,
              "speed3d": 8.705555555555554,
              "msFromStart": 107226.90074074079
            },
            {
              "lat": 43.75888805,
              "lng": -116.08832503000001,
              "alt": 2031.1319,
              "speed2d": 9.266400000000003,
              "speed3d": 9.276,
              "msFromStart": 107750.27088888884
            },
            {
              "lat": 43.75888547777778,
              "lng": -116.08825713333334,
              "alt": 2031.6784444444443,
              "speed2d": 9.551222222222222,
              "speed3d": 9.55888888888889,
              "msFromStart": 108273.65776218323
            },
            {
              "lat": 43.75889106666667,
              "lng": -116.08821006666666,
              "alt": 2031.4936666666667,
              "speed2d": 8.774222222222221,
              "speed3d": 8.99,
              "msFromStart": 108769.48168421054
            },
            {
              "lat": 43.75890887777778,
              "lng": -116.08819006666666,
              "alt": 2029.6770000000001,
              "speed2d": 7.309666666666668,
              "speed3d": 7.53,
              "msFromStart": 109265.1733437297
            },
            {
              "lat": 43.75891683333333,
              "lng": -116.08816184444444,
              "alt": 2027.6344444444444,
              "speed2d": 6.413888888888889,
              "speed3d": 6.514444444444445,
              "msFromStart": 109760.068
            },
            {
              "lat": 43.758930577777775,
              "lng": -116.08813067777778,
              "alt": 2025.6381111111111,
              "speed2d": 6.895777777777778,
              "speed3d": 6.9799999999999995,
              "msFromStart": 110254.84879012343
            },
            {
              "lat": 43.75893955555556,
              "lng": -116.0880972,
              "alt": 2023.5776666666668,
              "speed2d": 6.862333333333334,
              "speed3d": 6.9366666666666665,
              "msFromStart": 110749.73199999992
            },
            {
              "lat": 43.758944533333334,
              "lng": -116.0880412,
              "alt": 2023.206333333333,
              "speed2d": 7.792999999999999,
              "speed3d": 7.787777777777778,
              "msFromStart": 111244.41965432094
            },
            {
              "lat": 43.75895341111111,
              "lng": -116.08797983333332,
              "alt": 2023.5502222222221,
              "speed2d": 8.791333333333334,
              "speed3d": 8.876666666666665,
              "msFromStart": 111737.81199999995
            },
            {
              "lat": 43.758962188888894,
              "lng": -116.08792703333336,
              "alt": 2023.7026666666666,
              "speed2d": 8.693333333333333,
              "speed3d": 8.66,
              "msFromStart": 112231.3761975308
            },
            {
              "lat": 43.75897147777778,
              "lng": -116.08784757777778,
              "alt": 2025.1763333333333,
              "speed2d": 10.964555555555556,
              "speed3d": 10.751111111111111,
              "msFromStart": 112727.28399999996
            },
            {
              "lat": 43.758983459999996,
              "lng": -116.08776077,
              "alt": 2026.3053,
              "speed2d": 12.3746,
              "speed3d": 12.389,
              "msFromStart": 113251.28444444442
            },
            {
              "lat": 43.75898541111111,
              "lng": -116.08769386666667,
              "alt": 2026.388,
              "speed2d": 10.948777777777778,
              "speed3d": 11.125555555555556,
              "msFromStart": 113775.92266666671
            },
            {
              "lat": 43.75898692222223,
              "lng": -116.0876476777778,
              "alt": 2025.614111111111,
              "speed2d": 9.149000000000001,
              "speed3d": 9.417777777777777,
              "msFromStart": 114272.81057309944
            },
            {
              "lat": 43.75899741111111,
              "lng": -116.08761348888889,
              "alt": 2024.5642222222223,
              "speed2d": 7.520777777777779,
              "speed3d": 7.648888888888889,
              "msFromStart": 114768.80210526318
            },
            {
              "lat": 43.75901096666667,
              "lng": -116.08755886666665,
              "alt": 2025.2157777777777,
              "speed2d": 8.451888888888888,
              "speed3d": 8.273333333333333,
              "msFromStart": 115264.3437660819
            },
            {
              "lat": 43.759025022222225,
              "lng": -116.08749465555557,
              "alt": 2025.4517777777778,
              "speed2d": 9.382333333333333,
              "speed3d": 9.444444444444443,
              "msFromStart": 115757.688
            },
            {
              "lat": 43.75903702222222,
              "lng": -116.08745812222222,
              "alt": 2022.7603333333332,
              "speed2d": 7.828555555555556,
              "speed3d": 8.031111111111112,
              "msFromStart": 116251.16879012344
            },
            {
              "lat": 43.75905203333333,
              "lng": -116.0874088888889,
              "alt": 2021.7010000000002,
              "speed2d": 8.357222222222223,
              "speed3d": 8.253333333333334,
              "msFromStart": 116747.68000000004
            },
            {
              "lat": 43.759062944444445,
              "lng": -116.08734594444442,
              "alt": 2022.2471111111108,
              "speed2d": 8.984333333333332,
              "speed3d": 9.043333333333335,
              "msFromStart": 117244.38034567903
            },
            {
              "lat": 43.759067477777776,
              "lng": -116.08728546666666,
              "alt": 2022.3063333333334,
              "speed2d": 8.812,
              "speed3d": 8.896666666666668,
              "msFromStart": 117739.20399999997
            },
            {
              "lat": 43.75906924444445,
              "lng": -116.08723066666667,
              "alt": 2021.698777777778,
              "speed2d": 8.311888888888888,
              "speed3d": 8.406666666666666,
              "msFromStart": 118233.51916829108
            },
            {
              "lat": 43.759065,
              "lng": -116.08717676666667,
              "alt": 2021.086,
              "speed2d": 8.006444444444444,
              "speed3d": 8.08222222222222,
              "msFromStart": 118726.60926315786
            },
            {
              "lat": 43.75905051000001,
              "lng": -116.08711647000001,
              "alt": 2020.6153000000002,
              "speed2d": 8.4056,
              "speed3d": 8.406,
              "msFromStart": 119247.23845614033
            },
            {
              "lat": 43.75903192222222,
              "lng": -116.08705634444445,
              "alt": 2021.8446666666666,
              "speed2d": 8.681444444444443,
              "speed3d": 8.767777777777779,
              "msFromStart": 119770.36799999996
            },
            {
              "lat": 43.7590168,
              "lng": -116.08701376666667,
              "alt": 2021.0030000000002,
              "speed2d": 8.004888888888889,
              "speed3d": 8.153333333333334,
              "msFromStart": 120266.42804938267
            },
            {
              "lat": 43.75900218888889,
              "lng": -116.0869762,
              "alt": 2019.7512222222224,
              "speed2d": 7.344555555555555,
              "speed3d": 7.44,
              "msFromStart": 120762.21999999993
            },
            {
              "lat": 43.758978955555556,
              "lng": -116.0869297,
              "alt": 2020.177222222222,
              "speed2d": 7.883,
              "speed3d": 7.892222222222221,
              "msFromStart": 121257.83120987649
            },
            {
              "lat": 43.75895535555555,
              "lng": -116.08688561111111,
              "alt": 2020.506333333333,
              "speed2d": 7.978777777777778,
              "speed3d": 8.093333333333334,
              "msFromStart": 121752.60800000005
            },
            {
              "lat": 43.75893486666667,
              "lng": -116.08684840000001,
              "alt": 2020.0401111111114,
              "speed2d": 7.701666666666667,
              "speed3d": 7.727777777777778,
              "msFromStart": 122247.34133333336
            },
            {
              "lat": 43.75890934444445,
              "lng": -116.08681751111112,
              "alt": 2018.880777777778,
              "speed2d": 8.378444444444446,
              "speed3d": 8.382222222222222,
              "msFromStart": 122742.64399999996
            },
            {
              "lat": 43.75888468888889,
              "lng": -116.0868000222222,
              "alt": 2017.377888888889,
              "speed2d": 8.070777777777778,
              "speed3d": 8.197777777777777,
              "msFromStart": 123237.76444444439
            },
            {
              "lat": 43.75886668888889,
              "lng": -116.08676876666667,
              "alt": 2016.2803333333334,
              "speed2d": 8.146333333333333,
              "speed3d": 8.12111111111111,
              "msFromStart": 123731.204
            },
            {
              "lat": 43.75884988999999,
              "lng": -116.08673285,
              "alt": 2014.8799999999999,
              "speed2d": 8.38,
              "speed3d": 8.426,
              "msFromStart": 124251.88757894738
            },
            {
              "lat": 43.758836188888885,
              "lng": -116.08669476666667,
              "alt": 2013.7318888888888,
              "speed2d": 8.439111111111112,
              "speed3d": 8.45111111111111,
              "msFromStart": 124773.13852631579
            },
            {
              "lat": 43.75882645555556,
              "lng": -116.0866558,
              "alt": 2012.6065555555554,
              "speed2d": 8.128444444444446,
              "speed3d": 8.227777777777778,
              "msFromStart": 125267.21058349579
            },
            {
              "lat": 43.75882697777778,
              "lng": -116.08660543333332,
              "alt": 2012.0125555555558,
              "speed2d": 7.773777777777779,
              "speed3d": 7.833333333333334,
              "msFromStart": 125762.37199999997
            },
            {
              "lat": 43.758833200000005,
              "lng": -116.08655431111113,
              "alt": 2012.317888888889,
              "speed2d": 7.519333333333334,
              "speed3d": 7.517777777777777,
              "msFromStart": 126257.58360493826
            },
            {
              "lat": 43.758849955555554,
              "lng": -116.08650066666667,
              "alt": 2012.5209999999997,
              "speed2d": 8.254666666666667,
              "speed3d": 8.222222222222223,
              "msFromStart": 126752.01199999996
            },
            {
              "lat": 43.758869288888896,
              "lng": -116.08645714444447,
              "alt": 2012.7055555555557,
              "speed2d": 8.511555555555557,
              "speed3d": 8.512222222222224,
              "msFromStart": 127246.55249382713
            },
            {
              "lat": 43.75888702222222,
              "lng": -116.0864152,
              "alt": 2013.506111111111,
              "speed2d": 8.404222222222222,
              "speed3d": 8.427777777777777,
              "msFromStart": 127742.56
            },
            {
              "lat": 43.75890652222222,
              "lng": -116.08637838888887,
              "alt": 2014.104111111111,
              "speed2d": 8.211222222222222,
              "speed3d": 8.259999999999998,
              "msFromStart": 128238.76553086418
            },
            {
              "lat": 43.75892745555555,
              "lng": -116.0863538,
              "alt": 2014.4281111111109,
              "speed2d": 7.598666666666666,
              "speed3d": 7.692222222222222,
              "msFromStart": 128734.71199999997
            },
            {
              "lat": 43.758950444444444,
              "lng": -116.08634126666666,
              "alt": 2014.993888888889,
              "speed2d": 7.033666666666667,
              "speed3d": 7.0600000000000005,
              "msFromStart": 129230.48661728392
            },
            {
              "lat": 43.758973,
              "lng": -116.08631075555556,
              "alt": 2015.0934444444445,
              "speed2d": 7.223222222222223,
              "speed3d": 7.187777777777778,
              "msFromStart": 129725.47199999995
            },
            {
              "lat": 43.75899721,
              "lng": -116.08627196,
              "alt": 2015.0072,
              "speed2d": 7.636800000000001,
              "speed3d": 7.615,
              "msFromStart": 130247.8973333333
            },
            {
              "lat": 43.7590195,
              "lng": -116.08623426666666,
              "alt": 2015.2032222222224,
              "speed2d": 7.563999999999999,
              "speed3d": 7.586666666666666,
              "msFromStart": 130770.86399999997
            },
            {
              "lat": 43.759040999999996,
              "lng": -116.0861985777778,
              "alt": 2015.345777777778,
              "speed2d": 7.684777777777777,
              "speed3d": 7.643333333333333,
              "msFromStart": 131266.3409382716
            },
            {
              "lat": 43.759060922222226,
              "lng": -116.0861583888889,
              "alt": 2014.9791111111113,
              "speed2d": 7.783555555555555,
              "speed3d": 7.793333333333333,
              "msFromStart": 131761.35999999993
            },
            {
              "lat": 43.75907581111112,
              "lng": -116.08611545555554,
              "alt": 2015.693888888889,
              "speed2d": 7.523555555555555,
              "speed3d": 7.5633333333333335,
              "msFromStart": 132256.23387654312
            },
            {
              "lat": 43.759090022222225,
              "lng": -116.08607733333335,
              "alt": 2015.9352222222226,
              "speed2d": 7.245111111111111,
              "speed3d": 7.293333333333333,
              "msFromStart": 132750.67999999988
            },
            {
              "lat": 43.75909997777778,
              "lng": -116.0860431,
              "alt": 2015.4751111111111,
              "speed2d": 6.810444444444444,
              "speed3d": 6.84,
              "msFromStart": 133245.0095802468
            },
            {
              "lat": 43.75911021111111,
              "lng": -116.0859968,
              "alt": 2015.4483333333335,
              "speed2d": 6.9446666666666665,
              "speed3d": 6.912222222222223,
              "msFromStart": 133739.0559999999
            },
            {
              "lat": 43.75911596666666,
              "lng": -116.08594903333332,
              "alt": 2015.6155555555551,
              "speed2d": 6.947666666666667,
              "speed3d": 6.960000000000001,
              "msFromStart": 134233.3352462637
            },
            {
              "lat": 43.75912102222222,
              "lng": -116.08590311111111,
              "alt": 2015.6217777777779,
              "speed2d": 6.898222222222222,
              "speed3d": 6.902222222222223,
              "msFromStart": 134729.31789473692
            },
            {
              "lat": 43.759123844444446,
              "lng": -116.08586012222223,
              "alt": 2015.651,
              "speed2d": 6.724888888888889,
              "speed3d": 6.7700000000000005,
              "msFromStart": 135225.57579727104
            },
            {
              "lat": 43.75912013,
              "lng": -116.08582016,
              "alt": 2015.4254,
              "speed2d": 6.3126,
              "speed3d": 6.341,
              "msFromStart": 135749.36088888897
            },
            {
              "lat": 43.75911458888889,
              "lng": -116.08578025555553,
              "alt": 2015.2601111111114,
              "speed2d": 6.001777777777778,
              "speed3d": 6.0311111111111115,
              "msFromStart": 136272.75970370375
            },
            {
              "lat": 43.759106244444446,
              "lng": -116.08573198888888,
              "alt": 2016.0694444444443,
              "speed2d": 6.160888888888889,
              "speed3d": 6.116666666666667,
              "msFromStart": 136766.29599999997
            },
            {
              "lat": 43.75909721111111,
              "lng": -116.08567973333334,
              "alt": 2016.9873333333337,
              "speed2d": 6.469222222222223,
              "speed3d": 6.463333333333333,
              "msFromStart": 137259.78093827158
            },
            {
              "lat": 43.75908907777777,
              "lng": -116.08563888888887,
              "alt": 2016.6835555555556,
              "speed2d": 6.387222222222222,
              "speed3d": 6.383333333333333,
              "msFromStart": 137755.2479999999
            },
            {
              "lat": 43.75907965555556,
              "lng": -116.08559562222224,
              "alt": 2016.5015555555556,
              "speed2d": 6.496444444444444,
              "speed3d": 6.496666666666667,
              "msFromStart": 138250.87703703696
            },
            {
              "lat": 43.75907974444444,
              "lng": -116.08557014444446,
              "alt": 2014.063111111111,
              "speed2d": 6.401777777777778,
              "speed3d": 6.415555555555556,
              "msFromStart": 138745.5119999999
            },
            {
              "lat": 43.759081288888886,
              "lng": -116.0855436111111,
              "alt": 2011.518111111111,
              "speed2d": 6.439,
              "speed3d": 6.437777777777778,
              "msFromStart": 139239.98976998043
            },
            {
              "lat": 43.75907955555555,
              "lng": -116.0855171888889,
              "alt": 2008.5676666666666,
              "speed2d": 6.448666666666666,
              "speed3d": 6.481111111111112,
              "msFromStart": 139734.5027368421
            },
            {
              "lat": 43.759077700000006,
              "lng": -116.08550188888888,
              "alt": 2004.0635555555555,
              "speed2d": 6.147666666666668,
              "speed3d": 6.222222222222221,
              "msFromStart": 140229.12112280703
            },
            {
              "lat": 43.75908057777779,
              "lng": -116.08548540000001,
              "alt": 2000.7007777777778,
              "speed2d": 5.934333333333333,
              "speed3d": 6.028888888888888,
              "msFromStart": 140724.73599999992
            },
            {
              "lat": 43.759084810000004,
              "lng": -116.08547136,
              "alt": 1996.3228,
              "speed2d": 5.649900000000001,
              "speed3d": 5.769,
              "msFromStart": 141248.29288888883
            },
            {
              "lat": 43.759082477777774,
              "lng": -116.08543411111113,
              "alt": 1994.6067777777776,
              "speed2d": 5.784222222222222,
              "speed3d": 5.876666666666667,
              "msFromStart": 141772.864
            },
            {
              "lat": 43.75907848888888,
              "lng": -116.08538942222224,
              "alt": 1994.123111111111,
              "speed2d": 6.090000000000001,
              "speed3d": 6.17,
              "msFromStart": 142269.52632098767
            },
            {
              "lat": 43.759079533333335,
              "lng": -116.08534983333334,
              "alt": 1993.9544444444446,
              "speed2d": 6.2332222222222216,
              "speed3d": 6.35111111111111,
              "msFromStart": 142763.10400000005
            },
            {
              "lat": 43.75908657777777,
              "lng": -116.08534062222222,
              "alt": 1990.1758888888887,
              "speed2d": 5.7606666666666655,
              "speed3d": 5.964444444444445,
              "msFromStart": 143256.3911007148
            },
            {
              "lat": 43.75909806666667,
              "lng": -116.0853335,
              "alt": 1986.6165555555554,
              "speed2d": 5.413888888888889,
              "speed3d": 5.602222222222222,
              "msFromStart": 143750.99031578942
            },
            {
              "lat": 43.759100000000004,
              "lng": -116.08529588888888,
              "alt": 1985.368,
              "speed2d": 5.6194444444444445,
              "speed3d": 5.774444444444446,
              "msFromStart": 144245.9321637426
            },
            {
              "lat": 43.75910203333333,
              "lng": -116.0852505,
              "alt": 1984.7910000000002,
              "speed2d": 5.931555555555556,
              "speed3d": 6.071111111111111,
              "msFromStart": 144742.14933333336
            },
            {
              "lat": 43.7591061,
              "lng": -116.08520248888888,
              "alt": 1984.0825555555555,
              "speed2d": 6.269888888888889,
              "speed3d": 6.402222222222222,
              "msFromStart": 145238.6296296297
            },
            {
              "lat": 43.7591122,
              "lng": -116.08515294444445,
              "alt": 1983.6116666666667,
              "speed2d": 6.574777777777777,
              "speed3d": 6.693333333333333,
              "msFromStart": 145734.55288888895
            },
            {
              "lat": 43.759119966666674,
              "lng": -116.08509999999998,
              "alt": 1983.6083333333336,
              "speed2d": 6.9383333333333335,
              "speed3d": 7.04111111111111,
              "msFromStart": 146230.25985185194
            },
            {
              "lat": 43.75912917777778,
              "lng": -116.08504967777779,
              "alt": 1983.4601111111108,
              "speed2d": 7.149111111111111,
              "speed3d": 7.275555555555557,
              "msFromStart": 146725.19644444447
            },
            {
              "lat": 43.75914175,
              "lng": -116.08500946000001,
              "alt": 1981.7707,
              "speed2d": 7.1809,
              "speed3d": 7.302999999999999,
              "msFromStart": 147247.39422222227
            },
            {
              "lat": 43.759160888888886,
              "lng": -116.08497093333334,
              "alt": 1979.6742222222222,
              "speed2d": 7.40088888888889,
              "speed3d": 7.5344444444444445,
              "msFromStart": 147769.368
            },
            {
              "lat": 43.759171755555556,
              "lng": -116.08493567777776,
              "alt": 1976.9686666666666,
              "speed2d": 7.283333333333334,
              "speed3d": 7.454444444444444,
              "msFromStart": 148263.81313580245
            },
            {
              "lat": 43.75918016666667,
              "lng": -116.08488852222223,
              "alt": 1975.8507777777777,
              "speed2d": 7.303111111111111,
              "speed3d": 7.452222222222222,
              "msFromStart": 148758.19200000004
            },
            {
              "lat": 43.759193922222224,
              "lng": -116.08484335555555,
              "alt": 1975.2945555555555,
              "speed2d": 7.377111111111111,
              "speed3d": 7.529999999999999,
              "msFromStart": 149252.42890188438
            },
            {
              "lat": 43.759209866666666,
              "lng": -116.08480193333332,
              "alt": 1974.3864444444444,
              "speed2d": 7.3981111111111115,
              "speed3d": 7.5555555555555545,
              "msFromStart": 149745.86610526324
            },
            {
              "lat": 43.759233744444444,
              "lng": -116.08477084444445,
              "alt": 1973.095,
              "speed2d": 7.378444444444444,
              "speed3d": 7.547777777777778,
              "msFromStart": 150239.30242495134
            },
            {
              "lat": 43.75927554444444,
              "lng": -116.08476855555556,
              "alt": 1970.5116666666665,
              "speed2d": 7.279777777777778,
              "speed3d": 7.484444444444444,
              "msFromStart": 150734.04088888882
            },
            {
              "lat": 43.75931048888889,
              "lng": -116.08475464444444,
              "alt": 1968.531777777778,
              "speed2d": 7.347333333333333,
              "speed3d": 7.5233333333333325,
              "msFromStart": 151229.22874074065
            },
            {
              "lat": 43.75933473333333,
              "lng": -116.08472236666667,
              "alt": 1967.5402222222222,
              "speed2d": 7.465222222222222,
              "speed3d": 7.654444444444444,
              "msFromStart": 151725.65511111103
            },
            {
              "lat": 43.75935769,
              "lng": -116.08468882000001,
              "alt": 1967.2008,
              "speed2d": 7.469499999999999,
              "speed3d": 7.6610000000000005,
              "msFromStart": 152249.78755555552
            },
            {
              "lat": 43.75937935555555,
              "lng": -116.08465502222222,
              "alt": 1966.9902222222222,
              "speed2d": 7.413333333333334,
              "speed3d": 7.607777777777777,
              "msFromStart": 152772.5200000001
            },
            {
              "lat": 43.759400477777774,
              "lng": -116.0846256,
              "alt": 1966.5277777777776,
              "speed2d": 7.327555555555556,
              "speed3d": 7.5200000000000005,
              "msFromStart": 153267.40355035744
            },
            {
              "lat": 43.75942377777777,
              "lng": -116.08460424444444,
              "alt": 1965.889888888889,
              "speed2d": 7.197999999999999,
              "speed3d": 7.382222222222222,
              "msFromStart": 153761.96715789463
            },
            {
              "lat": 43.759448500000005,
              "lng": -116.08458016666667,
              "alt": 1964.5201111111112,
              "speed2d": 7.259222222222222,
              "speed3d": 7.437777777777777,
              "msFromStart": 154256.44993372308
            },
            {
              "lat": 43.75947106666666,
              "lng": -116.08454915555556,
              "alt": 1963.323222222222,
              "speed2d": 7.302555555555555,
              "speed3d": 7.484444444444445,
              "msFromStart": 154750.64888888889
            },
            {
              "lat": 43.75949077777778,
              "lng": -116.08451077777781,
              "alt": 1962.850777777778,
              "speed2d": 7.286,
              "speed3d": 7.466666666666667,
              "msFromStart": 155244.88177777777
            },
            {
              "lat": 43.75951056666666,
              "lng": -116.08446763333333,
              "alt": 1962.060222222222,
              "speed2d": 7.420444444444445,
              "speed3d": 7.582222222222221,
              "msFromStart": 155740.16355555557
            },
            {
              "lat": 43.75952851111111,
              "lng": -116.08442094444442,
              "alt": 1960.9009999999998,
              "speed2d": 7.520777777777777,
              "speed3d": 7.7,
              "msFromStart": 156235.67259259263
            },
            {
              "lat": 43.7595454,
              "lng": -116.08438278888892,
              "alt": 1958.9331111111112,
              "speed2d": 7.4191111111111105,
              "speed3d": 7.6211111111111105,
              "msFromStart": 156730.83200000002
            },
            {
              "lat": 43.75955907777778,
              "lng": -116.08434075555554,
              "alt": 1957.0448888888889,
              "speed2d": 7.265000000000001,
              "speed3d": 7.493333333333332,
              "msFromStart": 157225.9235555556
            },
            {
              "lat": 43.75957039,
              "lng": -116.08429223,
              "alt": 1955.3605000000002,
              "speed2d": 7.127699999999999,
              "speed3d": 7.3469999999999995,
              "msFromStart": 157748.7293333334
            },
            {
              "lat": 43.75958251111111,
              "lng": -116.08423933333333,
              "alt": 1954.2565555555555,
              "speed2d": 7.191333333333333,
              "speed3d": 7.396666666666666,
              "msFromStart": 158271.52572839512
            },
            {
              "lat": 43.75959642222222,
              "lng": -116.08419195555555,
              "alt": 1953.4946666666665,
              "speed2d": 7.242666666666667,
              "speed3d": 7.445555555555556,
              "msFromStart": 158766.44800000006
            },
            {
              "lat": 43.75960815555556,
              "lng": -116.08415553333333,
              "alt": 1952.5044444444443,
              "speed2d": 6.846,
              "speed3d": 7.148888888888888,
              "msFromStart": 159261.31615854453
            },
            {
              "lat": 43.75961557777779,
              "lng": -116.08412707777778,
              "alt": 1951.1838888888888,
              "speed2d": 6.185444444444444,
              "speed3d": 6.488888888888889,
              "msFromStart": 159756.20968421054
            },
            {
              "lat": 43.75961794444444,
              "lng": -116.08409102222224,
              "alt": 1950.264,
              "speed2d": 5.692222222222222,
              "speed3d": 6.022222222222222,
              "msFromStart": 160251.08828070172
            },
            {
              "lat": 43.759618366666665,
              "lng": -116.08404845555555,
              "alt": 1949.9208888888888,
              "speed2d": 5.486666666666666,
              "speed3d": 5.773333333333333,
              "msFromStart": 160745.7759999999
            },
            {
              "lat": 43.759612677777774,
              "lng": -116.08399414444443,
              "alt": 1949.7184444444447,
              "speed2d": 5.641444444444445,
              "speed3d": 5.883333333333334,
              "msFromStart": 161240.51674074068
            },
            {
              "lat": 43.759603999999996,
              "lng": -116.08393001111111,
              "alt": 1949.8023333333333,
              "speed2d": 6.079,
              "speed3d": 6.288888888888889,
              "msFromStart": 161736.26488888898
            },
            {
              "lat": 43.75959227777777,
              "lng": -116.08388556666665,
              "alt": 1947.766,
              "speed2d": 5.917777777777777,
              "speed3d": 6.196666666666666,
              "msFromStart": 162232.09185185196
            },
            {
              "lat": 43.75957520000001,
              "lng": -116.08384302222221,
              "alt": 1945.8558888888886,
              "speed2d": 5.782666666666666,
              "speed3d": 6.085555555555556,
              "msFromStart": 162726.1884444445
            },
            {
              "lat": 43.759559530000004,
              "lng": -116.08378886999999,
              "alt": 1944.5518,
              "speed2d": 6.0639,
              "speed3d": 6.279,
              "msFromStart": 163247.53777777788
            },
            {
              "lat": 43.75954325555556,
              "lng": -116.08373546666667,
              "alt": 1943.410222222222,
              "speed2d": 6.3164444444444445,
              "speed3d": 6.591111111111111,
              "msFromStart": 163770.7040000001
            },
            {
              "lat": 43.75952608888889,
              "lng": -116.08368920000001,
              "alt": 1942.1105555555555,
              "speed2d": 6.443444444444444,
              "speed3d": 6.676666666666667,
              "msFromStart": 164266.4968005199
            },
            {
              "lat": 43.759503288888894,
              "lng": -116.08363711111113,
              "alt": 1941.2982222222222,
              "speed2d": 6.755888888888888,
              "speed3d": 6.979999999999999,
              "msFromStart": 164761.0256842104
            },
            {
              "lat": 43.75948353333334,
              "lng": -116.08358637777778,
              "alt": 1940.7310000000002,
              "speed2d": 6.970444444444444,
              "speed3d": 7.197777777777778,
              "msFromStart": 165255.45628070165
            },
            {
              "lat": 43.7594699,
              "lng": -116.08354114444444,
              "alt": 1939.8561111111112,
              "speed2d": 6.952777777777778,
              "speed3d": 7.2266666666666675,
              "msFromStart": 165750.9759999999
            },
            {
              "lat": 43.759464344444446,
              "lng": -116.08350841111113,
              "alt": 1938.5608888888887,
              "speed2d": 6.549888888888889,
              "speed3d": 6.854444444444444,
              "msFromStart": 166246.64711111103
            },
            {
              "lat": 43.75945973333333,
              "lng": -116.08345852222222,
              "alt": 1938.533222222222,
              "speed2d": 6.742333333333333,
              "speed3d": 6.942222222222223,
              "msFromStart": 166741.1093333334
            },
            {
              "lat": 43.7594508,
              "lng": -116.0834091888889,
              "alt": 1938.1503333333335,
              "speed2d": 6.934888888888889,
              "speed3d": 7.173333333333334,
              "msFromStart": 167235.38607407414
            },
            {
              "lat": 43.75944037777777,
              "lng": -116.08335628888888,
              "alt": 1937.892777777778,
              "speed2d": 7.184333333333333,
              "speed3d": 7.391111111111112,
              "msFromStart": 167730.8302222223
            },
            {
              "lat": 43.7594275,
              "lng": -116.08331884444445,
              "alt": 1937.7065555555555,
              "speed2d": 6.8952222222222215,
              "speed3d": 7.182222222222222,
              "msFromStart": 168226.52711111118
            },
            {
              "lat": 43.75942854,
              "lng": -116.08327956,
              "alt": 1936.1895,
              "speed2d": 6.644,
              "speed3d": 6.9030000000000005,
              "msFromStart": 168749.34444444437
            },
            {
              "lat": 43.75945263333334,
              "lng": -116.08323618888889,
              "alt": 1934.1859999999997,
              "speed2d": 6.766111111111112,
              "speed3d": 6.993333333333333,
              "msFromStart": 169272.03878882385
            },
            {
              "lat": 43.75947851111111,
              "lng": -116.08319617777777,
              "alt": 1933.6309999999999,
              "speed2d": 7.041555555555557,
              "speed3d": 7.260000000000001,
              "msFromStart": 169767.06778947363
            },
            {
              "lat": 43.7595019,
              "lng": -116.08315715555557,
              "alt": 1933.812111111111,
              "speed2d": 7.293999999999999,
              "speed3d": 7.5088888888888885,
              "msFromStart": 170262.07189083815
            },
            {
              "lat": 43.75952136666666,
              "lng": -116.0831158111111,
              "alt": 1934.4654444444445,
              "speed2d": 7.500555555555555,
              "speed3d": 7.705555555555556,
              "msFromStart": 170757.07911111118
            },
            {
              "lat": 43.75953778888889,
              "lng": -116.08308063333332,
              "alt": 1934.6082222222221,
              "speed2d": 7.452555555555555,
              "speed3d": 7.693333333333333,
              "msFromStart": 171252.19911111117
            },
            {
              "lat": 43.75955287777778,
              "lng": -116.08305150000001,
              "alt": 1935.6415555555554,
              "speed2d": 7.176444444444445,
              "speed3d": 7.427777777777777,
              "msFromStart": 171748.43911111122
            },
            {
              "lat": 43.75956876666667,
              "lng": -116.08302134444445,
              "alt": 1936.920333333333,
              "speed2d": 7.037111111111111,
              "speed3d": 7.244444444444445,
              "msFromStart": 172244.89155555566
            },
            {
              "lat": 43.759585344444446,
              "lng": -116.08298564444445,
              "alt": 1938.0777777777778,
              "speed2d": 7.095111111111111,
              "speed3d": 7.285555555555556,
              "msFromStart": 172740.6684444444
            },
            {
              "lat": 43.759600822222225,
              "lng": -116.08294732222222,
              "alt": 1939.0717777777782,
              "speed2d": 7.115444444444445,
              "speed3d": 7.313333333333334,
              "msFromStart": 173236.1839688109
            },
            {
              "lat": 43.75961847777778,
              "lng": -116.08291332222223,
              "alt": 1940.0016666666666,
              "speed2d": 7.107777777777779,
              "speed3d": 7.28888888888889,
              "msFromStart": 173730.7747368421
            },
            {
              "lat": 43.75963808888889,
              "lng": -116.0828823111111,
              "alt": 1940.7671111111115,
              "speed2d": 7.087888888888889,
              "speed3d": 7.261111111111111,
              "msFromStart": 174225.19329434697
            },
            {
              "lat": 43.75965942,
              "lng": -116.08284457999999,
              "alt": 1941.2359000000004,
              "speed2d": 7.1803,
              "speed3d": 7.336,
              "msFromStart": 174748.28533333336
            },
            {
              "lat": 43.75968086666666,
              "lng": -116.08280579999999,
              "alt": 1941.4504444444442,
              "speed2d": 7.299777777777779,
              "speed3d": 7.453333333333334,
              "msFromStart": 175271.53362962973
            },
            {
              "lat": 43.759699866666665,
              "lng": -116.08276554444444,
              "alt": 1941.5725555555555,
              "speed2d": 7.383666666666667,
              "speed3d": 7.526666666666666,
              "msFromStart": 175764.6631111112
            },
            {
              "lat": 43.759716299999994,
              "lng": -116.08272024444445,
              "alt": 1941.328333333333,
              "speed2d": 7.4512222222222215,
              "speed3d": 7.609999999999999,
              "msFromStart": 176257.32948148155
            },
            {
              "lat": 43.75972132222222,
              "lng": -116.08266737777777,
              "alt": 1940.847666666667,
              "speed2d": 7.296444444444445,
              "speed3d": 7.466666666666667,
              "msFromStart": 176751.77955555546
            },
            {
              "lat": 43.759723666666666,
              "lng": -116.08261085555554,
              "alt": 1940.178222222222,
              "speed2d": 7.184444444444444,
              "speed3d": 7.361111111111112,
              "msFromStart": 177246.77037037027
            },
            {
              "lat": 43.759722522222226,
              "lng": -116.08255864444445,
              "alt": 1939.4231111111112,
              "speed2d": 6.945444444444444,
              "speed3d": 7.135555555555555,
              "msFromStart": 177742.7093333333
            },
            {
              "lat": 43.75971772222222,
              "lng": -116.08252244444445,
              "alt": 1938.0502222222226,
              "speed2d": 6.350222222222221,
              "speed3d": 6.6288888888888895,
              "msFromStart": 178238.7468538011
            },
            {
              "lat": 43.75971201111111,
              "lng": -116.08248703333334,
              "alt": 1936.9084444444445,
              "speed2d": 5.8467777777777785,
              "speed3d": 6.073333333333333,
              "msFromStart": 178733.39957894725
            },
            {
              "lat": 43.75970579999999,
              "lng": -116.0824429,
              "alt": 1935.9212222222222,
              "speed2d": 5.694666666666667,
              "speed3d": 5.927777777777777,
              "msFromStart": 179227.67667836245
            },
            {
              "lat": 43.75969566,
              "lng": -116.08240362000001,
              "alt": 1934.5119,
              "speed2d": 5.4697000000000005,
              "speed3d": 5.6690000000000005,
              "msFromStart": 179748.81199999998
            },
            {
              "lat": 43.75968585555555,
              "lng": -116.08233299999999,
              "alt": 1934.2495555555554,
              "speed2d": 6.082555555555556,
              "speed3d": 6.252222222222222,
              "msFromStart": 180269.98844444443
            },
            {
              "lat": 43.7596705,
              "lng": -116.08230921111111,
              "alt": 1932.9812222222222,
              "speed2d": 5.576555555555556,
              "speed3d": 5.91,
              "msFromStart": 180766.21866666677
            },
            {
              "lat": 43.75966003333333,
              "lng": -116.08230389999999,
              "alt": 1931.6774444444445,
              "speed2d": 4.736111111111111,
              "speed3d": 5.013333333333334,
              "msFromStart": 181263.04711111123
            },
            {
              "lat": 43.75964584444444,
              "lng": -116.08225679999997,
              "alt": 1930.8824444444447,
              "speed2d": 5.0425555555555555,
              "speed3d": 5.248888888888889,
              "msFromStart": 181759.68000000005
            },
            {
              "lat": 43.75963159999999,
              "lng": -116.0822111111111,
              "alt": 1930.0687777777775,
              "speed2d": 5.283999999999999,
              "speed3d": 5.49888888888889,
              "msFromStart": 182256.19644444453
            },
            {
              "lat": 43.7596181,
              "lng": -116.08216322222223,
              "alt": 1929.3177777777778,
              "speed2d": 5.546444444444444,
              "speed3d": 5.745555555555555,
              "msFromStart": 182752.03733333346
            },
            {
              "lat": 43.75960566666667,
              "lng": -116.08212275555556,
              "alt": 1928.638666666667,
              "speed2d": 5.630777777777778,
              "speed3d": 5.865555555555555,
              "msFromStart": 183247.62577777792
            },
            {
              "lat": 43.75959674444445,
              "lng": -116.08209276666665,
              "alt": 1927.755,
              "speed2d": 5.470999999999999,
              "speed3d": 5.703333333333333,
              "msFromStart": 183742.37866666657
            },
            {
              "lat": 43.75958535555555,
              "lng": -116.08205447777777,
              "alt": 1927.241111111111,
              "speed2d": 5.518000000000001,
              "speed3d": 5.738888888888888,
              "msFromStart": 184236.93651461983
            },
            {
              "lat": 43.759576933333335,
              "lng": -116.08200778888889,
              "alt": 1927.6324444444447,
              "speed2d": 5.711888888888889,
              "speed3d": 5.8966666666666665,
              "msFromStart": 184731.63284210538
            },
            {
              "lat": 43.75956114444445,
              "lng": -116.08195917777778,
              "alt": 1928.0586666666668,
              "speed2d": 5.973222222222223,
              "speed3d": 6.158888888888888,
              "msFromStart": 185226.29523586755
            },
            {
              "lat": 43.75954552,
              "lng": -116.08191438,
              "alt": 1927.9177,
              "speed2d": 5.9365999999999985,
              "speed3d": 6.1419999999999995,
              "msFromStart": 185747.0373333333
            },
            {
              "lat": 43.75953225555556,
              "lng": -116.08186528888888,
              "alt": 1927.7452222222223,
              "speed2d": 6.059111111111111,
              "speed3d": 6.220000000000001,
              "msFromStart": 186267.40444444446
            },
            {
              "lat": 43.75951626666667,
              "lng": -116.0818094,
              "alt": 1928.2816666666665,
              "speed2d": 6.482333333333332,
              "speed3d": 6.620000000000001,
              "msFromStart": 186761.43822222226
            },
            {
              "lat": 43.759508933333336,
              "lng": -116.08176667777776,
              "alt": 1927.764888888889,
              "speed2d": 6.421555555555556,
              "speed3d": 6.641111111111111,
              "msFromStart": 187255.85037037038
            },
            {
              "lat": 43.759501533333335,
              "lng": -116.08173212222222,
              "alt": 1927.0120000000002,
              "speed2d": 6.105333333333333,
              "speed3d": 6.294444444444443,
              "msFromStart": 187751.42399999994
            },
            {
              "lat": 43.75949347777778,
              "lng": -116.0816905,
              "alt": 1926.8165555555556,
              "speed2d": 6.0906666666666665,
              "speed3d": 6.282222222222222,
              "msFromStart": 188247.2103703703
            },
            {
              "lat": 43.75948686666668,
              "lng": -116.08164793333331,
              "alt": 1926.6121111111108,
              "speed2d": 6.088222222222222,
              "speed3d": 6.268888888888888,
              "msFromStart": 188742.22044444445
            },
            {
              "lat": 43.7594835,
              "lng": -116.08160584444443,
              "alt": 1926.4784444444447,
              "speed2d": 6.064888888888889,
              "speed3d": 6.252222222222222,
              "msFromStart": 189237.03688109166
            },
            {
              "lat": 43.75948194444445,
              "lng": -116.08155991111111,
              "alt": 1926.5164444444445,
              "speed2d": 6.165333333333333,
              "speed3d": 6.3133333333333335,
              "msFromStart": 189731.85768421058
            },
            {
              "lat": 43.759479822222225,
              "lng": -116.0815091,
              "alt": 1926.6812222222225,
              "speed2d": 6.373888888888889,
              "speed3d": 6.524444444444445,
              "msFromStart": 190226.66187914234
            },
            {
              "lat": 43.75947983999999,
              "lng": -116.08146020999999,
              "alt": 1926.8690000000001,
              "speed2d": 6.3919,
              "speed3d": 6.559,
              "msFromStart": 190748.59333333338
            },
            {
              "lat": 43.7594799888889,
              "lng": -116.08141247777777,
              "alt": 1927.0766666666668,
              "speed2d": 6.3885555555555555,
              "speed3d": 6.543333333333335,
              "msFromStart": 191270.53896296304
            },
            {
              "lat": 43.75947884444445,
              "lng": -116.08136837777778,
              "alt": 1927.8327777777781,
              "speed2d": 6.375555555555556,
              "speed3d": 6.531111111111111,
              "msFromStart": 191766.40177777788
            },
            {
              "lat": 43.75947492222222,
              "lng": -116.08132369999998,
              "alt": 1929.4982222222225,
              "speed2d": 6.344444444444444,
              "speed3d": 6.494444444444444,
              "msFromStart": 192262.67318518524
            },
            {
              "lat": 43.75947082222223,
              "lng": -116.08127806666666,
              "alt": 1931.129888888889,
              "speed2d": 6.356111111111112,
              "speed3d": 6.4877777777777785,
              "msFromStart": 192759.57866666658
            },
            {
              "lat": 43.7594651,
              "lng": -116.08122817777777,
              "alt": 1932.6405555555557,
              "speed2d": 6.403666666666666,
              "speed3d": 6.529999999999999,
              "msFromStart": 193256.5223703703
            },
            {
              "lat": 43.7594591888889,
              "lng": -116.08117822222223,
              "alt": 1934.0677777777778,
              "speed2d": 6.373333333333334,
              "speed3d": 6.503333333333334,
              "msFromStart": 193752.26311111107
            },
            {
              "lat": 43.75945248888889,
              "lng": -116.08112714444445,
              "alt": 1935.518888888889,
              "speed2d": 6.430888888888889,
              "speed3d": 6.533333333333334,
              "msFromStart": 194247.64778167632
            },
            {
              "lat": 43.759450533333336,
              "lng": -116.08108104444445,
              "alt": 1937.0223333333336,
              "speed2d": 6.439333333333334,
              "speed3d": 6.5555555555555545,
              "msFromStart": 194742.47915789473
            },
            {
              "lat": 43.75945155555555,
              "lng": -116.08104107777777,
              "alt": 1938.2302222222222,
              "speed2d": 6.293666666666667,
              "speed3d": 6.417777777777777,
              "msFromStart": 195237.15246783628
            },
            {
              "lat": 43.75944777777778,
              "lng": -116.08098936666667,
              "alt": 1940.0594444444446,
              "speed2d": 6.523333333333333,
              "speed3d": 6.582222222222222,
              "msFromStart": 195731.45066666673
            },
            {
              "lat": 43.75944314444445,
              "lng": -116.08093814444445,
              "alt": 1941.688222222222,
              "speed2d": 6.565333333333333,
              "speed3d": 6.662222222222221,
              "msFromStart": 196225.64355555564
            },
            {
              "lat": 43.759436089999994,
              "lng": -116.08088384999998,
              "alt": 1943.2281000000003,
              "speed2d": 6.5443,
              "speed3d": 6.625,
              "msFromStart": 196747.964
            },
            {
              "lat": 43.759433200000004,
              "lng": -116.08083477777778,
              "alt": 1944.6661111111111,
              "speed2d": 6.450000000000001,
              "speed3d": 6.5488888888888885,
              "msFromStart": 197270.458962963
            },
            {
              "lat": 43.759432611111116,
              "lng": -116.08078926666664,
              "alt": 1946.1743333333336,
              "speed2d": 6.4221111111111115,
              "speed3d": 6.49,
              "msFromStart": 197764.89422222224
            },
            {
              "lat": 43.75943337777778,
              "lng": -116.08074334444446,
              "alt": 1947.561888888889,
              "speed2d": 6.475777777777778,
              "speed3d": 6.540000000000001,
              "msFromStart": 198259.28325925927
            },
            {
              "lat": 43.7594371,
              "lng": -116.08069975555556,
              "alt": 1948.5217777777777,
              "speed2d": 6.511666666666666,
              "speed3d": 6.5777777777777775,
              "msFromStart": 198754.60266666676
            },
            {
              "lat": 43.75944174444444,
              "lng": -116.08065345555556,
              "alt": 1949.4097777777777,
              "speed2d": 6.5921111111111115,
              "speed3d": 6.635555555555557,
              "msFromStart": 199250.06409356731
            },
            {
              "lat": 43.759445988888885,
              "lng": -116.08060457777778,
              "alt": 1950.3410000000001,
              "speed2d": 6.712888888888889,
              "speed3d": 6.772222222222222,
              "msFromStart": 199744.61978947365
            },
            {
              "lat": 43.75945074444444,
              "lng": -116.0805609777778,
              "alt": 1950.9377777777781,
              "speed2d": 6.645777777777778,
              "speed3d": 6.720000000000001,
              "msFromStart": 200239.02307992204
            },
            {
              "lat": 43.75946078888889,
              "lng": -116.0805238111111,
              "alt": 1951.427888888889,
              "speed2d": 6.495,
              "speed3d": 6.564444444444444,
              "msFromStart": 200734.83288888898
            },
            {
              "lat": 43.75947226666666,
              "lng": -116.08048307777779,
              "alt": 1952.1184444444443,
              "speed2d": 6.540444444444445,
              "speed3d": 6.583333333333332,
              "msFromStart": 201231.09274074083
            },
            {
              "lat": 43.759483966666664,
              "lng": -116.08044076666667,
              "alt": 1953.0292222222222,
              "speed2d": 6.646999999999999,
              "speed3d": 6.697777777777778,
              "msFromStart": 201726.05777777784
            },
            {
              "lat": 43.7594992,
              "lng": -116.08040485999997,
              "alt": 1953.8087000000003,
              "speed2d": 6.5719,
              "speed3d": 6.627000000000001,
              "msFromStart": 202247.9901333334
            },
            {
              "lat": 43.75951554444444,
              "lng": -116.08036665555555,
              "alt": 1954.550777777778,
              "speed2d": 6.659777777777778,
              "speed3d": 6.692222222222222,
              "msFromStart": 202769.61422222224
            },
            {
              "lat": 43.75953264444444,
              "lng": -116.08033449999999,
              "alt": 1955.446111111111,
              "speed2d": 6.673777777777778,
              "speed3d": 6.722222222222223,
              "msFromStart": 203263.81807407408
            },
            {
              "lat": 43.75955057777777,
              "lng": -116.08030617777777,
              "alt": 1956.2507777777778,
              "speed2d": 6.605777777777778,
              "speed3d": 6.659999999999999,
              "msFromStart": 203759.26044444446
            },
            {
              "lat": 43.75956854444444,
              "lng": -116.0802785,
              "alt": 1957.0766666666668,
              "speed2d": 6.55088888888889,
              "speed3d": 6.598888888888888,
              "msFromStart": 204254.9963196881
            },
            {
              "lat": 43.75958773333333,
              "lng": -116.0802517111111,
              "alt": 1957.7904444444446,
              "speed2d": 6.545888888888889,
              "speed3d": 6.578888888888889,
              "msFromStart": 204750.5709473685
            },
            {
              "lat": 43.75960741111111,
              "lng": -116.08021885555557,
              "alt": 1958.6664444444443,
              "speed2d": 6.700333333333334,
              "speed3d": 6.722222222222223,
              "msFromStart": 205246.08073294355
            },
            {
              "lat": 43.759625344444444,
              "lng": -116.08018322222222,
              "alt": 1959.4463333333335,
              "speed2d": 6.743555555555556,
              "speed3d": 6.782222222222222,
              "msFromStart": 205741.12444444434
            },
            {
              "lat": 43.75964383333333,
              "lng": -116.08015144444445,
              "alt": 1960.001888888889,
              "speed2d": 6.739111111111111,
              "speed3d": 6.768888888888888,
              "msFromStart": 206235.9659259258
            },
            {
              "lat": 43.75966441111111,
              "lng": -116.08011606666665,
              "alt": 1960.4711111111112,
              "speed2d": 6.899222222222222,
              "speed3d": 6.914444444444445,
              "msFromStart": 206730.22755555547
            },
            {
              "lat": 43.759685160000004,
              "lng": -116.08007489,
              "alt": 1961.0904,
              "speed2d": 7.035200000000001,
              "speed3d": 7.052,
              "msFromStart": 207251.81493333323
            },
            {
              "lat": 43.75970243333333,
              "lng": -116.08002527777779,
              "alt": 1961.9341111111112,
              "speed2d": 7.127888888888889,
              "speed3d": 7.161111111111112,
              "msFromStart": 207774.4480000001
            },
            {
              "lat": 43.759718477777774,
              "lng": -116.07997967777779,
              "alt": 1962.459888888889,
              "speed2d": 7.190333333333334,
              "speed3d": 7.192222222222222,
              "msFromStart": 208269.92148148158
            },
            {
              "lat": 43.75973353333334,
              "lng": -116.07992929999999,
              "alt": 1962.7308888888888,
              "speed2d": 7.349666666666666,
              "speed3d": 7.376666666666667,
              "msFromStart": 208765.8097777778
            },
            {
              "lat": 43.759744588888886,
              "lng": -116.07988436666668,
              "alt": 1962.575888888889,
              "speed2d": 7.213222222222223,
              "speed3d": 7.257777777777778,
              "msFromStart": 209261.753840156
            },
            {
              "lat": 43.759751111111115,
              "lng": -116.07983602222222,
              "alt": 1962.4623333333332,
              "speed2d": 7.037222222222223,
              "speed3d": 7.085555555555556,
              "msFromStart": 209757.21852631588
            },
            {
              "lat": 43.759754900000004,
              "lng": -116.07977015555555,
              "alt": 1963.2202222222222,
              "speed2d": 7.288666666666667,
              "speed3d": 7.2333333333333325,
              "msFromStart": 210252.5888187136
            },
            {
              "lat": 43.75976263333334,
              "lng": -116.07968627777778,
              "alt": 1964.9143333333334,
              "speed2d": 8.198666666666668,
              "speed3d": 8.142222222222223,
              "msFromStart": 210748.44266666667
            },
            {
              "lat": 43.75976638888888,
              "lng": -116.07961846666669,
              "alt": 1965.9593333333337,
              "speed2d": 8.311111111111112,
              "speed3d": 8.363333333333333,
              "msFromStart": 211244.4533333333
            },
            {
              "lat": 43.7597645,
              "lng": -116.07956932222221,
              "alt": 1966.3443333333335,
              "speed2d": 7.689777777777778,
              "speed3d": 7.775555555555555,
              "msFromStart": 211740.05866666674
            },
            {
              "lat": 43.75976405555555,
              "lng": -116.07951275555557,
              "alt": 1966.8366666666664,
              "speed2d": 7.659111111111111,
              "speed3d": 7.6466666666666665,
              "msFromStart": 212235.47525925937
            },
            {
              "lat": 43.7597643,
              "lng": -116.07945263333333,
              "alt": 1967.1786666666665,
              "speed2d": 7.868333333333333,
              "speed3d": 7.880000000000001,
              "msFromStart": 212730.14311111116
            },
            {
              "lat": 43.75976516,
              "lng": -116.07939203,
              "alt": 1967.2269000000001,
              "speed2d": 7.9981,
              "speed3d": 7.983,
              "msFromStart": 213252.05200000005
            },
            {
              "lat": 43.75977035555556,
              "lng": -116.07933074444445,
              "alt": 1967.6696666666667,
              "speed2d": 8.193222222222222,
              "speed3d": 8.226666666666667,
              "msFromStart": 213774.53511111118
            },
            {
              "lat": 43.75977511111111,
              "lng": -116.07928324444445,
              "alt": 1967.7036666666668,
              "speed2d": 7.9912222222222224,
              "speed3d": 8.004444444444445,
              "msFromStart": 214269.62223781686
            },
            {
              "lat": 43.75978148888889,
              "lng": -116.07922272222223,
              "alt": 1968.0125555555555,
              "speed2d": 8.350222222222222,
              "speed3d": 8.312222222222221,
              "msFromStart": 214764.06063157885
            },
            {
              "lat": 43.75979063333333,
              "lng": -116.07915817777777,
              "alt": 1968.3434444444442,
              "speed2d": 8.842,
              "speed3d": 8.813333333333334,
              "msFromStart": 215258.36601949306
            },
            {
              "lat": 43.759798777777775,
              "lng": -116.07910092222221,
              "alt": 1968.294555555556,
              "speed2d": 8.930555555555555,
              "speed3d": 8.961111111111112,
              "msFromStart": 215753.2257777777
            },
            {
              "lat": 43.75980925555556,
              "lng": -116.07904313333333,
              "alt": 1968.2303333333336,
              "speed2d": 9.078555555555557,
              "speed3d": 9.038888888888888,
              "msFromStart": 216248.2669629629
            },
            {
              "lat": 43.75982126666667,
              "lng": -116.07897824444447,
              "alt": 1968.5295555555554,
              "speed2d": 9.453,
              "speed3d": 9.445555555555556,
              "msFromStart": 216742.87466666655
            },
            {
              "lat": 43.75982944444445,
              "lng": -116.07892314444445,
              "alt": 1968.5052222222223,
              "speed2d": 9.27811111111111,
              "speed3d": 9.333333333333334,
              "msFromStart": 217237.35555555543
            },
            {
              "lat": 43.759835977777776,
              "lng": -116.07886761111111,
              "alt": 1968.3814444444445,
              "speed2d": 9.12811111111111,
              "speed3d": 9.135555555555554,
              "msFromStart": 217732.4613333334
            },
            {
              "lat": 43.75984358888889,
              "lng": -116.0788092111111,
              "alt": 1968.2672222222225,
              "speed2d": 9.141333333333334,
              "speed3d": 9.161111111111113,
              "msFromStart": 218227.79377777787
            },
            {
              "lat": 43.75985319,
              "lng": -116.07875257999999,
              "alt": 1968.2998000000002,
              "speed2d": 9.003,
              "speed3d": 9.033999999999999,
              "msFromStart": 218750.572
            },
            {
              "lat": 43.75986251111111,
              "lng": -116.0786987,
              "alt": 1967.9414444444444,
              "speed2d": 8.844777777777779,
              "speed3d": 8.872222222222224,
              "msFromStart": 219273.3083976609
            },
            {
              "lat": 43.759872377777775,
              "lng": -116.07864282222224,
              "alt": 1967.5853333333332,
              "speed2d": 8.943222222222223,
              "speed3d": 8.93888888888889,
              "msFromStart": 219768.36210526322
            },
            {
              "lat": 43.75988248888889,
              "lng": -116.07858928888888,
              "alt": 1967.4142222222222,
              "speed2d": 8.911999999999999,
              "speed3d": 8.944444444444446,
              "msFromStart": 220263.36771929835
            },
            {
              "lat": 43.75988931111111,
              "lng": -116.0785341,
              "alt": 1966.9843333333333,
              "speed2d": 8.889888888888889,
              "speed3d": 8.879999999999999,
              "msFromStart": 220758.26399999997
            },
            {
              "lat": 43.75989295555556,
              "lng": -116.07847332222224,
              "alt": 1966.742,
              "speed2d": 8.965333333333334,
              "speed3d": 9.001111111111111,
              "msFromStart": 221253.1736296296
            },
            {
              "lat": 43.75989011111112,
              "lng": -116.07841924444445,
              "alt": 1966.5618888888891,
              "speed2d": 8.65622222222222,
              "speed3d": 8.704444444444444,
              "msFromStart": 221749.1022222223
            },
            {
              "lat": 43.75988275555555,
              "lng": -116.07836632222222,
              "alt": 1966.5348888888889,
              "speed2d": 8.345222222222223,
              "speed3d": 8.396666666666667,
              "msFromStart": 222245.35644444454
            },
            {
              "lat": 43.75987218888889,
              "lng": -116.07831963333335,
              "alt": 1966.0765555555554,
              "speed2d": 7.973777777777779,
              "speed3d": 8.032222222222222,
              "msFromStart": 222740.66488888898
            },
            {
              "lat": 43.759857966666665,
              "lng": -116.07827704444443,
              "alt": 1965.3772222222224,
              "speed2d": 7.660666666666667,
              "speed3d": 7.717777777777779,
              "msFromStart": 223235.57451851864
            },
            {
              "lat": 43.759844799999996,
              "lng": -116.07824289999999,
              "alt": 1964.569,
              "speed2d": 7.231,
              "speed3d": 7.287777777777777,
              "msFromStart": 223729.52711111103
            },
            {
              "lat": 43.75982595,
              "lng": -116.07819796,
              "alt": 1964.0801000000001,
              "speed2d": 7.2796,
              "speed3d": 7.276,
              "msFromStart": 224250.66060350864
            },
            {
              "lat": 43.759805377777774,
              "lng": -116.0781481111111,
              "alt": 1963.9274444444443,
              "speed2d": 7.525444444444446,
              "speed3d": 7.518888888888889,
              "msFromStart": 224773.05515789462
            },
            {
              "lat": 43.759784866666664,
              "lng": -116.07809372222222,
              "alt": 1964.1021111111108,
              "speed2d": 7.973222222222223,
              "speed3d": 7.937777777777779,
              "msFromStart": 225268.3240233917
            },
            {
              "lat": 43.759767777777775,
              "lng": -116.0780482,
              "alt": 1963.7553333333335,
              "speed2d": 8.02011111111111,
              "speed3d": 8.057777777777778,
              "msFromStart": 225763.5893333334
            },
            {
              "lat": 43.75975128888889,
              "lng": -116.0780014111111,
              "alt": 1963.3370000000002,
              "speed2d": 8.113222222222223,
              "speed3d": 8.106666666666667,
              "msFromStart": 226258.82607407414
            },
            {
              "lat": 43.759739644444444,
              "lng": -116.07795016666667,
              "alt": 1963.2525555555555,
              "speed2d": 8.259888888888888,
              "speed3d": 8.25,
              "msFromStart": 226753.5448888889
            },
            {
              "lat": 43.75972688888889,
              "lng": -116.07788503333335,
              "alt": 1963.6195555555555,
              "speed2d": 8.88811111111111,
              "speed3d": 8.833333333333332,
              "msFromStart": 227248.09333333332
            },
            {
              "lat": 43.75972108888889,
              "lng": -116.07783067777777,
              "alt": 1963.7715555555556,
              "speed2d": 8.948333333333332,
              "speed3d": 8.99,
              "msFromStart": 227743.0302222223
            },
            {
              "lat": 43.7597202,
              "lng": -116.07778019999999,
              "alt": 1963.7974444444442,
              "speed2d": 8.873222222222221,
              "speed3d": 8.884444444444446,
              "msFromStart": 228238.112888889
            },
            {
              "lat": 43.759723622222225,
              "lng": -116.0777344,
              "alt": 1963.6105555555557,
              "speed2d": 8.633777777777777,
              "speed3d": 8.692222222222222,
              "msFromStart": 228733.2462222223
            },
            {
              "lat": 43.75973306666666,
              "lng": -116.07768948888888,
              "alt": 1963.327111111111,
              "speed2d": 8.515777777777778,
              "speed3d": 8.537777777777777,
              "msFromStart": 229228.33406627696
            },
            {
              "lat": 43.759743099999994,
              "lng": -116.07764571000001,
              "alt": 1963.0323,
              "speed2d": 8.2158,
              "speed3d": 8.275,
              "msFromStart": 229749.61515789462
            },
            {
              "lat": 43.75975712222222,
              "lng": -116.07760047777779,
              "alt": 1962.7857777777776,
              "speed2d": 8.13311111111111,
              "speed3d": 8.133333333333335,
              "msFromStart": 230270.54035087707
            },
            {
              "lat": 43.75977276666667,
              "lng": -116.0775557222222,
              "alt": 1962.612111111111,
              "speed2d": 8.185888888888888,
              "speed3d": 8.209999999999999,
              "msFromStart": 230765.82399999996
            },
            {
              "lat": 43.759790333333335,
              "lng": -116.07751506666668,
              "alt": 1962.5095555555558,
              "speed2d": 8.125555555555556,
              "speed3d": 8.153333333333334,
              "msFromStart": 231261.76474074068
            },
            {
              "lat": 43.75980927777778,
              "lng": -116.07747424444445,
              "alt": 1962.5083333333332,
              "speed2d": 8.084777777777779,
              "speed3d": 8.102222222222222,
              "msFromStart": 231757.79555555558
            },
            {
              "lat": 43.759828166666665,
              "lng": -116.07743374444443,
              "alt": 1962.5711111111113,
              "speed2d": 8.006222222222222,
              "speed3d": 8.032222222222222,
              "msFromStart": 232253.82429629634
            },
            {
              "lat": 43.759845500000004,
              "lng": -116.07738591111112,
              "alt": 1962.7422222222224,
              "speed2d": 8.110666666666667,
              "speed3d": 8.11,
              "msFromStart": 232749.18311111105
            },
            {
              "lat": 43.75985831111112,
              "lng": -116.07733296666666,
              "alt": 1962.8542222222222,
              "speed2d": 8.162333333333333,
              "speed3d": 8.181111111111111,
              "msFromStart": 233244.2666666666
            },
            {
              "lat": 43.75986814444445,
              "lng": -116.07728373333335,
              "alt": 1962.8538888888893,
              "speed2d": 8.02188888888889,
              "speed3d": 8.064444444444446,
              "msFromStart": 233738.80977777782
            },
            {
              "lat": 43.75987793333333,
              "lng": -116.07723946666667,
              "alt": 1962.797,
              "speed2d": 7.791777777777778,
              "speed3d": 7.834444444444445,
              "msFromStart": 234233.16333723202
            },
            {
              "lat": 43.75988458888889,
              "lng": -116.07719435555553,
              "alt": 1962.8064444444447,
              "speed2d": 7.559555555555555,
              "speed3d": 7.603333333333333,
              "msFromStart": 234727.69852631583
            },
            {
              "lat": 43.75989033,
              "lng": -116.07714687,
              "alt": 1962.8805,
              "speed2d": 7.379300000000001,
              "speed3d": 7.415,
              "msFromStart": 235249.80565614047
            },
            {
              "lat": 43.7598908,
              "lng": -116.07710413333335,
              "alt": 1962.8431111111113,
              "speed2d": 7.049555555555555,
              "speed3d": 7.125555555555555,
              "msFromStart": 235772.49155555546
            },
            {
              "lat": 43.75988118888888,
              "lng": -116.07707923333334,
              "alt": 1962.5927777777779,
              "speed2d": 6.242333333333333,
              "speed3d": 6.347777777777778,
              "msFromStart": 236267.90222222212
            },
            {
              "lat": 43.759874888888895,
              "lng": -116.07705037777778,
              "alt": 1962.3456666666666,
              "speed2d": 5.795666666666666,
              "speed3d": 5.851111111111111,
              "msFromStart": 236763.21155555546
            },
            {
              "lat": 43.75987647777777,
              "lng": -116.07701454444444,
              "alt": 1962.3056666666666,
              "speed2d": 5.775777777777778,
              "speed3d": 5.789999999999999,
              "msFromStart": 237258.46785185172
            },
            {
              "lat": 43.75987652222222,
              "lng": -116.07697722222224,
              "alt": 1962.183,
              "speed2d": 5.7713333333333345,
              "speed3d": 5.795555555555556,
              "msFromStart": 237753.42577777768
            },
            {
              "lat": 43.75986421111111,
              "lng": -116.07693526666668,
              "alt": 1961.7228888888887,
              "speed2d": 5.776,
              "speed3d": 5.793333333333333,
              "msFromStart": 238248.30933333316
            },
            {
              "lat": 43.7598608,
              "lng": -116.07689535555555,
              "alt": 1961.444777777778,
              "speed2d": 5.874666666666666,
              "speed3d": 5.86888888888889,
              "msFromStart": 238743.8684444445
            },
            {
              "lat": 43.759862355555555,
              "lng": -116.07685063333334,
              "alt": 1961.325444444444,
              "speed2d": 6.171777777777778,
              "speed3d": 6.17111111111111,
              "msFromStart": 239239.65748148155
            },
            {
              "lat": 43.75985706666667,
              "lng": -116.07680466666665,
              "alt": 1960.9098888888886,
              "speed2d": 6.325777777777779,
              "speed3d": 6.3277777777777775,
              "msFromStart": 239735.08622222213
            },
            {
              "lat": 43.759857966666665,
              "lng": -116.07676187777777,
              "alt": 1960.3854444444444,
              "speed2d": 6.4684444444444456,
              "speed3d": 6.475555555555555,
              "msFromStart": 240230.40368031175
            },
            {
              "lat": 43.7598668111111,
              "lng": -116.07672253333332,
              "alt": 1959.7706666666668,
              "speed2d": 6.614111111111111,
              "speed3d": 6.614444444444445,
              "msFromStart": 240726.12884210522
            },
            {
              "lat": 43.75987886,
              "lng": -116.07667946000002,
              "alt": 1959.1987999999997,
              "speed2d": 6.8006,
              "speed3d": 6.800999999999999,
              "msFromStart": 241249.45486315785
            },
            {
              "lat": 43.75989601111111,
              "lng": -116.07663762222222,
              "alt": 1958.6596666666665,
              "speed2d": 7.015888888888889,
              "speed3d": 7.012222222222223,
              "msFromStart": 241770.69066666675
            },
            {
              "lat": 43.75991443333333,
              "lng": -116.07659975555558,
              "alt": 1957.9449999999997,
              "speed2d": 7.203,
              "speed3d": 7.206666666666667,
              "msFromStart": 242263.680888889
            },
            {
              "lat": 43.75993871111111,
              "lng": -116.07657031111114,
              "alt": 1956.4154444444443,
              "speed2d": 7.3532222222222225,
              "speed3d": 7.363333333333333,
              "msFromStart": 242757.7013333332
            },
            {
              "lat": 43.759967177777774,
              "lng": -116.07654542222222,
              "alt": 1955.1408888888889,
              "speed2d": 7.474444444444444,
              "speed3d": 7.473333333333333,
              "msFromStart": 243252.1422222221
            },
            {
              "lat": 43.76000016666667,
              "lng": -116.07652277777778,
              "alt": 1954.0901111111111,
              "speed2d": 7.726888888888889,
              "speed3d": 7.72,
              "msFromStart": 243747.36000000007
            },
            {
              "lat": 43.76003673333334,
              "lng": -116.07650231111111,
              "alt": 1953.375444444444,
              "speed2d": 8.068777777777777,
              "speed3d": 8.055555555555555,
              "msFromStart": 244242.8137543861
            },
            {
              "lat": 43.760074688888885,
              "lng": -116.07647982222221,
              "alt": 1952.859333333333,
              "speed2d": 8.468666666666666,
              "speed3d": 8.443333333333333,
              "msFromStart": 244737.3128421052
            },
            {
              "lat": 43.76011251111111,
              "lng": -116.07645484444444,
              "alt": 1952.3445555555554,
              "speed2d": 8.825222222222223,
              "speed3d": 8.825555555555555,
              "msFromStart": 245231.47567511356
            },
            {
              "lat": 43.76014956666667,
              "lng": -116.07643668888889,
              "alt": 1951.895777777778,
              "speed2d": 8.903777777777778,
              "speed3d": 8.917777777777777,
              "msFromStart": 245726.4426666667
            },
            {
              "lat": 43.76018586,
              "lng": -116.07640552000001,
              "alt": 1951.6071,
              "speed2d": 9.0264,
              "speed3d": 9.038,
              "msFromStart": 246249.37262222217
            },
            {
              "lat": 43.760221533333336,
              "lng": -116.07636827777776,
              "alt": 1951.1981111111113,
              "speed2d": 9.186111111111112,
              "speed3d": 9.193333333333333,
              "msFromStart": 246772.46755555551
            },
            {
              "lat": 43.760254466666666,
              "lng": -116.07633390000001,
              "alt": 1950.8856666666668,
              "speed2d": 9.236111111111112,
              "speed3d": 9.25222222222222,
              "msFromStart": 247268.08474074068
            },
            {
              "lat": 43.7602876,
              "lng": -116.07629863333331,
              "alt": 1950.4245555555553,
              "speed2d": 9.313222222222223,
              "speed3d": 9.333333333333334,
              "msFromStart": 247763.4204444444
            },
            {
              "lat": 43.760320311111116,
              "lng": -116.07626597777778,
              "alt": 1950.0511111111111,
              "speed2d": 9.268666666666668,
              "speed3d": 9.295555555555556,
              "msFromStart": 248258.63851851845
            },
            {
              "lat": 43.760352288888896,
              "lng": -116.07622814444446,
              "alt": 1949.9082222222223,
              "speed2d": 9.273,
              "speed3d": 9.293333333333333,
              "msFromStart": 248753.59199999998
            },
            {
              "lat": 43.76038331111111,
              "lng": -116.07618445555556,
              "alt": 1950.0782222222222,
              "speed2d": 9.299222222222223,
              "speed3d": 9.31888888888889,
              "msFromStart": 249248.43943859646
            },
            {
              "lat": 43.76041467777778,
              "lng": -116.07614371111111,
              "alt": 1950.2923333333333,
              "speed2d": 9.254777777777779,
              "speed3d": 9.283333333333335,
              "msFromStart": 249743.12421052626
            },
            {
              "lat": 43.76044587777778,
              "lng": -116.07610617777777,
              "alt": 1950.4405555555559,
              "speed2d": 9.158888888888889,
              "speed3d": 9.194444444444445,
              "msFromStart": 250237.7556595191
            },
            {
              "lat": 43.76047596666666,
              "lng": -116.07606997777776,
              "alt": 1950.5621111111113,
              "speed2d": 9.020333333333333,
              "speed3d": 9.056666666666667,
              "msFromStart": 250732.73066666664
            },
            {
              "lat": 43.76050595555555,
              "lng": -116.07603576666665,
              "alt": 1950.5271111111115,
              "speed2d": 8.901222222222222,
              "speed3d": 8.935555555555556,
              "msFromStart": 251227.89441975302
            },
            {
              "lat": 43.76053849,
              "lng": -116.07600299,
              "alt": 1950.266,
              "speed2d": 8.7861,
              "speed3d": 8.818,
              "msFromStart": 251750.77911111107
            },
            {
              "lat": 43.7605681,
              "lng": -116.0759634,
              "alt": 1949.9975555555554,
              "speed2d": 8.725777777777779,
              "speed3d": 8.758888888888889,
              "msFromStart": 252273.7365925925
            },
            {
              "lat": 43.760591688888894,
              "lng": -116.07592416666668,
              "alt": 1949.736111111111,
              "speed2d": 8.553444444444445,
              "speed3d": 8.597777777777779,
              "msFromStart": 252768.55466666658
            },
            {
              "lat": 43.760612055555555,
              "lng": -116.07587887777778,
              "alt": 1949.6956666666665,
              "speed2d": 8.426333333333334,
              "speed3d": 8.46,
              "msFromStart": 253263.18251851847
            },
            {
              "lat": 43.760629433333335,
              "lng": -116.07583471111111,
              "alt": 1949.3114444444443,
              "speed2d": 8.255888888888888,
              "speed3d": 8.312222222222221,
              "msFromStart": 253758.4915555556
            },
            {
              "lat": 43.76063587777777,
              "lng": -116.0757885888889,
              "alt": 1948.6512222222225,
              "speed2d": 7.841555555555554,
              "speed3d": 7.906666666666666,
              "msFromStart": 254254.03292007794
            },
            {
              "lat": 43.760644388888885,
              "lng": -116.07574293333333,
              "alt": 1948.0565555555554,
              "speed2d": 7.601444444444446,
              "speed3d": 7.644444444444445,
              "msFromStart": 254749.22021052634
            },
            {
              "lat": 43.76065375555555,
              "lng": -116.07569341111113,
              "alt": 1947.713,
              "speed2d": 7.545333333333334,
              "speed3d": 7.5666666666666655,
              "msFromStart": 255244.25491877846
            },
            {
              "lat": 43.76066464444445,
              "lng": -116.07563841111111,
              "alt": 1947.6126666666664,
              "speed2d": 7.658666666666666,
              "speed3d": 7.672222222222222,
              "msFromStart": 255738.13866666672
            },
            {
              "lat": 43.76067505555556,
              "lng": -116.07558267777777,
              "alt": 1947.5938888888886,
              "speed2d": 7.768888888888888,
              "speed3d": 7.7844444444444445,
              "msFromStart": 256231.43822222232
            },
            {
              "lat": 43.76068812222222,
              "lng": -116.0755305888889,
              "alt": 1947.4456666666665,
              "speed2d": 7.860222222222222,
              "speed3d": 7.881111111111111,
              "msFromStart": 256726.58666666658
            },
            {
              "lat": 43.76070227,
              "lng": -116.07548045000001,
              "alt": 1947.3120000000004,
              "speed2d": 7.853000000000001,
              "speed3d": 7.872999999999999,
              "msFromStart": 257250.2758222221
            },
            {
              "lat": 43.760716011111114,
              "lng": -116.07542535555557,
              "alt": 1947.2498888888888,
              "speed2d": 7.970888888888889,
              "speed3d": 7.994444444444445,
              "msFromStart": 257773.57244444446
            },
            {
              "lat": 43.76073013333334,
              "lng": -116.07537805555555,
              "alt": 1947.1705555555557,
              "speed2d": 7.959888888888889,
              "speed3d": 7.976666666666667,
              "msFromStart": 258269.12622222226
            },
            {
              "lat": 43.76074226666667,
              "lng": -116.0753244111111,
              "alt": 1947.1955555555555,
              "speed2d": 8.049999999999999,
              "speed3d": 8.05888888888889,
              "msFromStart": 258763.95377777785
            },
            {
              "lat": 43.76075276666667,
              "lng": -116.07526675555556,
              "alt": 1947.0996666666667,
              "speed2d": 8.222777777777779,
              "speed3d": 8.233333333333333,
              "msFromStart": 259258.54172319698
            },
            {
              "lat": 43.76076295555556,
              "lng": -116.07521360000001,
              "alt": 1947.026888888889,
              "speed2d": 8.184555555555557,
              "speed3d": 8.218888888888888,
              "msFromStart": 259753.66063157906
            },
            {
              "lat": 43.76077448888889,
              "lng": -116.07516298888888,
              "alt": 1946.858,
              "speed2d": 8.137,
              "speed3d": 8.163333333333332,
              "msFromStart": 260248.9797686811
            },
            {
              "lat": 43.760783566666674,
              "lng": -116.0751138222222,
              "alt": 1946.6933333333334,
              "speed2d": 8.004666666666669,
              "speed3d": 8.064444444444446,
              "msFromStart": 260744.53866666666
            },
            {
              "lat": 43.760791833333336,
              "lng": -116.0750682,
              "alt": 1946.6135555555556,
              "speed2d": 7.770222222222223,
              "speed3d": 7.7988888888888885,
              "msFromStart": 261240.22162962973
            },
            {
              "lat": 43.76079857777778,
              "lng": -116.07501648888889,
              "alt": 1946.696,
              "speed2d": 7.701555555555555,
              "speed3d": 7.72888888888889,
              "msFromStart": 261735.64266666665
            },
            {
              "lat": 43.760802388888884,
              "lng": -116.07496356666665,
              "alt": 1947.0607777777777,
              "speed2d": 7.499777777777777,
              "speed3d": 7.554444444444444,
              "msFromStart": 262230.9193086419
            },
            {
              "lat": 43.760807433333326,
              "lng": -116.07490844444446,
              "alt": 1947.3085555555554,
              "speed2d": 7.502000000000001,
              "speed3d": 7.514444444444443,
              "msFromStart": 262726.0213333334
            },
            {
              "lat": 43.76081416,
              "lng": -116.07485572,
              "alt": 1947.4837999999997,
              "speed2d": 7.4127,
              "speed3d": 7.4559999999999995,
              "msFromStart": 263248.52017777786
            },
            {
              "lat": 43.76082137777777,
              "lng": -116.07480825555555,
              "alt": 1947.5157777777777,
              "speed2d": 7.198555555555556,
              "speed3d": 7.238888888888889,
              "msFromStart": 263770.8142222222
            }
          ]
        }
      ]
    },
    {
      "id": "trail-lodge-cat-track",
      "pathType": "trail",
      "name": "Lodge Cat Track",
      "difficulty": "blue",
      "isCatTrack": true,
      "videos": [
        {
          "videoId": "GX010077",
          "pathId": "trail-lodge-cat-track",
          "startSec": 74,
          "endSec": 240,
          "id": "video-segment-37",
          "gps": [
            {
              "lat": 43.774157888888894,
              "lng": -116.09098613333335,
              "alt": 2148.8716666666664,
              "speed2d": 4.009111111111111,
              "speed3d": 5.075555555555556,
              "msFromStart": 74252.37777777776
            },
            {
              "lat": 43.77417133333333,
              "lng": -116.09100147777778,
              "alt": 2146.3348888888886,
              "speed2d": 3.9412222222222226,
              "speed3d": 5.04111111111111,
              "msFromStart": 74746.54755555559
            },
            {
              "lat": 43.77418637777778,
              "lng": -116.0910169111111,
              "alt": 2143.9593333333332,
              "speed2d": 3.958444444444444,
              "speed3d": 5.093333333333333,
              "msFromStart": 75241.07061208581
            },
            {
              "lat": 43.774194466666664,
              "lng": -116.09104537777776,
              "alt": 2141.701,
              "speed2d": 4.078777777777778,
              "speed3d": 5.19,
              "msFromStart": 75737.30189473691
            },
            {
              "lat": 43.77420218888889,
              "lng": -116.09107309999999,
              "alt": 2139.6146666666664,
              "speed2d": 4.234555555555556,
              "speed3d": 5.311111111111111,
              "msFromStart": 76233.52941910336
            },
            {
              "lat": 43.77421104444445,
              "lng": -116.09111081111111,
              "alt": 2136.719,
              "speed2d": 4.661444444444445,
              "speed3d": 5.673333333333333,
              "msFromStart": 76728.692
            },
            {
              "lat": 43.77421834,
              "lng": -116.09115780000002,
              "alt": 2133.2033,
              "speed2d": 5.146300000000001,
              "speed3d": 6.151,
              "msFromStart": 77251.08466666665
            },
            {
              "lat": 43.77422402222223,
              "lng": -116.09121505555557,
              "alt": 2129.483888888889,
              "speed2d": 5.769,
              "speed3d": 6.716666666666667,
              "msFromStart": 77773.03555555551
            },
            {
              "lat": 43.77426215555555,
              "lng": -116.0912708,
              "alt": 2130.6273333333334,
              "speed2d": 6.875,
              "speed3d": 7.575555555555555,
              "msFromStart": 78267.67333333331
            },
            {
              "lat": 43.77431953333333,
              "lng": -116.0913236,
              "alt": 2132.0116666666663,
              "speed2d": 8.062555555555557,
              "speed3d": 8.596666666666666,
              "msFromStart": 78763.18222222215
            },
            {
              "lat": 43.77436443333334,
              "lng": -116.09137995555555,
              "alt": 2130.4667777777777,
              "speed2d": 8.941444444444446,
              "speed3d": 9.452222222222222,
              "msFromStart": 79258.4493333333
            },
            {
              "lat": 43.77439005555556,
              "lng": -116.09145353333334,
              "alt": 2125.7275555555557,
              "speed2d": 9.77311111111111,
              "speed3d": 10.295555555555556,
              "msFromStart": 79752.40088888886
            },
            {
              "lat": 43.77442176666667,
              "lng": -116.09152712222223,
              "alt": 2121.400111111111,
              "speed2d": 10.569555555555555,
              "speed3d": 11.09888888888889,
              "msFromStart": 80246.45765302144
            },
            {
              "lat": 43.774453066666666,
              "lng": -116.09159600000001,
              "alt": 2117.826777777778,
              "speed2d": 11.099444444444444,
              "speed3d": 11.694444444444443,
              "msFromStart": 80741.46147368426
            },
            {
              "lat": 43.774477966666666,
              "lng": -116.09166064444443,
              "alt": 2115.246111111111,
              "speed2d": 11.28111111111111,
              "speed3d": 11.886666666666667,
              "msFromStart": 81236.47524366477
            },
            {
              "lat": 43.774501066666666,
              "lng": -116.09173146666666,
              "alt": 2112.813555555556,
              "speed2d": 11.500222222222224,
              "speed3d": 12.095555555555553,
              "msFromStart": 81730.97200000001
            },
            {
              "lat": 43.774518109999995,
              "lng": -116.09181290000001,
              "alt": 2110.4669,
              "speed2d": 11.675099999999999,
              "speed3d": 12.290000000000001,
              "msFromStart": 82252.81066666669
            },
            {
              "lat": 43.77453037777778,
              "lng": -116.09188981111112,
              "alt": 2108.039111111111,
              "speed2d": 11.625666666666667,
              "speed3d": 12.245555555555555,
              "msFromStart": 82774.45955555562
            },
            {
              "lat": 43.77454011111111,
              "lng": -116.09196665555555,
              "alt": 2105.319333333333,
              "speed2d": 11.659333333333334,
              "speed3d": 12.281111111111112,
              "msFromStart": 83269.10162962966
            },
            {
              "lat": 43.77455223333334,
              "lng": -116.09203515555556,
              "alt": 2102.926333333333,
              "speed2d": 11.555111111111112,
              "speed3d": 12.213333333333335,
              "msFromStart": 83765.63999999996
            },
            {
              "lat": 43.77456726666667,
              "lng": -116.09209551111111,
              "alt": 2100.8475555555556,
              "speed2d": 11.270444444444443,
              "speed3d": 11.94,
              "msFromStart": 84262.03837037033
            },
            {
              "lat": 43.774587177777775,
              "lng": -116.09215393333335,
              "alt": 2098.5733333333333,
              "speed2d": 11.000444444444444,
              "speed3d": 11.69,
              "msFromStart": 84757.11822222215
            },
            {
              "lat": 43.77460925555555,
              "lng": -116.09221334444445,
              "alt": 2096.5249999999996,
              "speed2d": 10.841333333333335,
              "speed3d": 11.506666666666668,
              "msFromStart": 85251.92918518514
            },
            {
              "lat": 43.77463193333334,
              "lng": -116.09227139999999,
              "alt": 2094.427333333333,
              "speed2d": 10.689222222222224,
              "speed3d": 11.370000000000001,
              "msFromStart": 85746.19199999997
            },
            {
              "lat": 43.774654266666666,
              "lng": -116.0923371111111,
              "alt": 2093.190888888889,
              "speed2d": 10.634777777777778,
              "speed3d": 11.295555555555556,
              "msFromStart": 86240.5995061728
            },
            {
              "lat": 43.77467006666666,
              "lng": -116.09240124444443,
              "alt": 2091.8396666666667,
              "speed2d": 10.481777777777776,
              "speed3d": 11.15111111111111,
              "msFromStart": 86736.22399999999
            },
            {
              "lat": 43.77468365555556,
              "lng": -116.09246313333333,
              "alt": 2090.0885555555556,
              "speed2d": 10.276555555555555,
              "speed3d": 10.955555555555556,
              "msFromStart": 87231.99022222222
            },
            {
              "lat": 43.774697644444444,
              "lng": -116.09251865555555,
              "alt": 2087.8378888888888,
              "speed2d": 9.975333333333333,
              "speed3d": 10.667777777777776,
              "msFromStart": 87727.40400000002
            },
            {
              "lat": 43.77469892,
              "lng": -116.09258010999999,
              "alt": 2083.9622,
              "speed2d": 9.7727,
              "speed3d": 10.492,
              "msFromStart": 88250.17044444446
            },
            {
              "lat": 43.77469586666666,
              "lng": -116.09264025555557,
              "alt": 2079.1141111111115,
              "speed2d": 9.618666666666666,
              "speed3d": 10.36888888888889,
              "msFromStart": 88772.42666666671
            },
            {
              "lat": 43.774696588888894,
              "lng": -116.09269717777778,
              "alt": 2074.6212222222225,
              "speed2d": 9.553999999999998,
              "speed3d": 10.333333333333332,
              "msFromStart": 89267.22740740742
            },
            {
              "lat": 43.774715388888886,
              "lng": -116.0927495,
              "alt": 2072.2135555555556,
              "speed2d": 9.527333333333333,
              "speed3d": 10.31,
              "msFromStart": 89762.43111111106
            },
            {
              "lat": 43.774738111111105,
              "lng": -116.09279921111113,
              "alt": 2070.0757777777776,
              "speed2d": 9.507444444444443,
              "speed3d": 10.295555555555556,
              "msFromStart": 90257.59904873291
            },
            {
              "lat": 43.774761811111105,
              "lng": -116.09284464444444,
              "alt": 2068.154888888889,
              "speed2d": 9.348444444444443,
              "speed3d": 10.162222222222223,
              "msFromStart": 90752.46273684208
            },
            {
              "lat": 43.77478687777778,
              "lng": -116.09288682222221,
              "alt": 2066.0495555555553,
              "speed2d": 9.12411111111111,
              "speed3d": 9.962222222222223,
              "msFromStart": 91247.26720207927
            },
            {
              "lat": 43.774812222222224,
              "lng": -116.09293093333332,
              "alt": 2064.1254444444444,
              "speed2d": 8.947222222222223,
              "speed3d": 9.772222222222224,
              "msFromStart": 91741.89600000001
            },
            {
              "lat": 43.77483748888889,
              "lng": -116.09297855555556,
              "alt": 2062.5903333333335,
              "speed2d": 8.899666666666667,
              "speed3d": 9.727777777777778,
              "msFromStart": 92236.47180246914
            },
            {
              "lat": 43.77486422222222,
              "lng": -116.0930186,
              "alt": 2061.8037777777777,
              "speed2d": 8.605222222222222,
              "speed3d": 9.45888888888889,
              "msFromStart": 92730.90000000005
            },
            {
              "lat": 43.774899511111116,
              "lng": -116.09305767777776,
              "alt": 2062.1694444444443,
              "speed2d": 8.441444444444445,
              "speed3d": 9.245555555555555,
              "msFromStart": 93225.50202469139
            },
            {
              "lat": 43.77493522,
              "lng": -116.09309990999999,
              "alt": 2061.9139999999998,
              "speed2d": 8.3881,
              "speed3d": 9.166,
              "msFromStart": 93748.86799999994
            },
            {
              "lat": 43.77497041111111,
              "lng": -116.09314368888889,
              "alt": 2061.9377777777777,
              "speed2d": 8.350555555555555,
              "speed3d": 9.096666666666666,
              "msFromStart": 94272.28459259258
            },
            {
              "lat": 43.7750023,
              "lng": -116.09318646666667,
              "alt": 2061.8262222222224,
              "speed2d": 8.318888888888889,
              "speed3d": 9.042222222222222,
              "msFromStart": 94767.55555555558
            },
            {
              "lat": 43.77503682222222,
              "lng": -116.09323364444444,
              "alt": 2062.8577777777778,
              "speed2d": 8.242999999999999,
              "speed3d": 8.937777777777777,
              "msFromStart": 95262.61644444446
            },
            {
              "lat": 43.77506981111111,
              "lng": -116.09327727777779,
              "alt": 2063.141555555556,
              "speed2d": 8.133111111111111,
              "speed3d": 8.81,
              "msFromStart": 95757.07288888886
            },
            {
              "lat": 43.77511787777778,
              "lng": -116.09332064444445,
              "alt": 2065.6173333333336,
              "speed2d": 8.135111111111112,
              "speed3d": 8.746666666666666,
              "msFromStart": 96251.71267056528
            },
            {
              "lat": 43.775171533333335,
              "lng": -116.09336463333332,
              "alt": 2069.006111111111,
              "speed2d": 8.199444444444445,
              "speed3d": 8.764444444444443,
              "msFromStart": 96747.32757894738
            },
            {
              "lat": 43.7752169,
              "lng": -116.09341044444446,
              "alt": 2071.5855555555554,
              "speed2d": 8.19111111111111,
              "speed3d": 8.71888888888889,
              "msFromStart": 97243.06414554907
            },
            {
              "lat": 43.77524953333333,
              "lng": -116.09346583333334,
              "alt": 2073.3717777777774,
              "speed2d": 8.273888888888889,
              "speed3d": 8.75,
              "msFromStart": 97738.948
            },
            {
              "lat": 43.775290677777775,
              "lng": -116.09352835555556,
              "alt": 2077.288888888889,
              "speed2d": 8.44,
              "speed3d": 8.845555555555556,
              "msFromStart": 98234.85896296297
            },
            {
              "lat": 43.77534515555555,
              "lng": -116.09359314444445,
              "alt": 2083.093555555555,
              "speed2d": 8.667111111111112,
              "speed3d": 9.014444444444443,
              "msFromStart": 98730.78800000002
            },
            {
              "lat": 43.775394522222214,
              "lng": -116.09365028888888,
              "alt": 2087.710222222222,
              "speed2d": 8.604888888888889,
              "speed3d": 8.924444444444445,
              "msFromStart": 99226.3679012346
            },
            {
              "lat": 43.77544081,
              "lng": -116.09370860000001,
              "alt": 2091.5803,
              "speed2d": 8.5181,
              "speed3d": 8.805,
              "msFromStart": 99747.19333333331
            },
            {
              "lat": 43.77548262222222,
              "lng": -116.09376598888889,
              "alt": 2094.6803333333332,
              "speed2d": 8.498666666666667,
              "speed3d": 8.745555555555557,
              "msFromStart": 100267.90222222223
            },
            {
              "lat": 43.7755211,
              "lng": -116.09381807777777,
              "alt": 2097.621222222222,
              "speed2d": 8.444555555555555,
              "speed3d": 8.671111111111111,
              "msFromStart": 100762.16
            },
            {
              "lat": 43.775558544444436,
              "lng": -116.09387094444442,
              "alt": 2100.4148888888885,
              "speed2d": 8.417777777777777,
              "speed3d": 8.626666666666667,
              "msFromStart": 101256.75002469136
            },
            {
              "lat": 43.77559382222223,
              "lng": -116.09392424444444,
              "alt": 2102.9124444444446,
              "speed2d": 8.457444444444445,
              "speed3d": 8.634444444444444,
              "msFromStart": 101752.79199999996
            },
            {
              "lat": 43.775626244444446,
              "lng": -116.09397157777776,
              "alt": 2104.554777777778,
              "speed2d": 8.373777777777777,
              "speed3d": 8.563333333333334,
              "msFromStart": 102248.95199999998
            },
            {
              "lat": 43.77565486666667,
              "lng": -116.09401497777779,
              "alt": 2105.5886666666665,
              "speed2d": 8.170444444444444,
              "speed3d": 8.357777777777779,
              "msFromStart": 102744.38000000002
            },
            {
              "lat": 43.77567573333333,
              "lng": -116.09405644444445,
              "alt": 2105.3294444444446,
              "speed2d": 7.973777777777777,
              "speed3d": 8.154444444444444,
              "msFromStart": 103239.63071604937
            },
            {
              "lat": 43.77569782222222,
              "lng": -116.09410153333334,
              "alt": 2105.470111111111,
              "speed2d": 7.908444444444444,
              "speed3d": 8.078888888888889,
              "msFromStart": 103734.53199999995
            },
            {
              "lat": 43.77572187777778,
              "lng": -116.09414262222222,
              "alt": 2105.5186666666664,
              "speed2d": 7.772333333333334,
              "speed3d": 7.9366666666666665,
              "msFromStart": 104229.35377777775
            },
            {
              "lat": 43.77575357999999,
              "lng": -116.09418904,
              "alt": 2106.3981000000003,
              "speed2d": 7.7905,
              "speed3d": 7.941000000000001,
              "msFromStart": 104751.52133333328
            },
            {
              "lat": 43.77578476666666,
              "lng": -116.09423427777776,
              "alt": 2106.7515555555556,
              "speed2d": 7.816888888888888,
              "speed3d": 7.959999999999999,
              "msFromStart": 105273.84666666662
            },
            {
              "lat": 43.77581623333333,
              "lng": -116.0942776888889,
              "alt": 2107.3214444444443,
              "speed2d": 7.891777777777778,
              "speed3d": 8.015555555555556,
              "msFromStart": 105769.41066666666
            },
            {
              "lat": 43.775848744444446,
              "lng": -116.09432643333332,
              "alt": 2108.960555555556,
              "speed2d": 7.996666666666666,
              "speed3d": 8.124444444444444,
              "msFromStart": 106265.0081871345
            },
            {
              "lat": 43.77587764444445,
              "lng": -116.09437423333334,
              "alt": 2110.252111111111,
              "speed2d": 7.953444444444443,
              "speed3d": 8.078888888888889,
              "msFromStart": 106760.4517894737
            },
            {
              "lat": 43.775904733333334,
              "lng": -116.09441965555554,
              "alt": 2111.153666666667,
              "speed2d": 7.846222222222223,
              "speed3d": 7.971111111111111,
              "msFromStart": 107255.87651721899
            },
            {
              "lat": 43.77593165555555,
              "lng": -116.09446650000001,
              "alt": 2112.2908888888887,
              "speed2d": 7.846777777777778,
              "speed3d": 7.948888888888888,
              "msFromStart": 107751.28000000001
            },
            {
              "lat": 43.77595706666667,
              "lng": -116.0945121,
              "alt": 2113.266888888889,
              "speed2d": 7.7940000000000005,
              "speed3d": 7.904444444444444,
              "msFromStart": 108246.60740740743
            },
            {
              "lat": 43.77597996666667,
              "lng": -116.09455341111112,
              "alt": 2114.195222222222,
              "speed2d": 7.526111111111112,
              "speed3d": 7.662222222222223,
              "msFromStart": 108741.492
            },
            {
              "lat": 43.77599978888888,
              "lng": -116.09458883333333,
              "alt": 2114.8885555555553,
              "speed2d": 7.059,
              "speed3d": 7.213333333333332,
              "msFromStart": 109236.27190123458
            },
            {
              "lat": 43.776016799999994,
              "lng": -116.09462346666666,
              "alt": 2115.095888888889,
              "speed2d": 6.687111111111111,
              "speed3d": 6.817777777777777,
              "msFromStart": 109730.85600000001
            },
            {
              "lat": 43.776033222222225,
              "lng": -116.09465678888887,
              "alt": 2115.4106666666667,
              "speed2d": 6.3434444444444456,
              "speed3d": 6.482222222222223,
              "msFromStart": 110225.52701234569
            },
            {
              "lat": 43.776048960000004,
              "lng": -116.09468964000001,
              "alt": 2115.8086999999996,
              "speed2d": 5.969899999999999,
              "speed3d": 6.111000000000001,
              "msFromStart": 110748.45266666664
            },
            {
              "lat": 43.77606206666667,
              "lng": -116.09472121111112,
              "alt": 2116.1046666666666,
              "speed2d": 5.595555555555556,
              "speed3d": 5.731111111111112,
              "msFromStart": 111271.28949707602
            },
            {
              "lat": 43.77607505555556,
              "lng": -116.09474857777778,
              "alt": 2116.4225555555554,
              "speed2d": 5.251555555555556,
              "speed3d": 5.398888888888888,
              "msFromStart": 111765.76631578951
            },
            {
              "lat": 43.776086522222215,
              "lng": -116.09477596666667,
              "alt": 2116.962666666667,
              "speed2d": 4.968,
              "speed3d": 5.102222222222222,
              "msFromStart": 112260.3010266407
            },
            {
              "lat": 43.776094288888885,
              "lng": -116.09480554444445,
              "alt": 2117.612111111111,
              "speed2d": 4.743444444444444,
              "speed3d": 4.873333333333333,
              "msFromStart": 112755.69599999994
            },
            {
              "lat": 43.77609808888889,
              "lng": -116.09483174444445,
              "alt": 2117.7091111111113,
              "speed2d": 4.455777777777777,
              "speed3d": 4.605555555555555,
              "msFromStart": 113251.23249382712
            },
            {
              "lat": 43.77610108888889,
              "lng": -116.09485187777778,
              "alt": 2117.409222222222,
              "speed2d": 4.075333333333333,
              "speed3d": 4.225555555555556,
              "msFromStart": 113746.772
            },
            {
              "lat": 43.776104733333334,
              "lng": -116.0948745,
              "alt": 2117.3182222222226,
              "speed2d": 3.908444444444444,
              "speed3d": 4.036666666666667,
              "msFromStart": 114242.2794074074
            },
            {
              "lat": 43.776106544444445,
              "lng": -116.09489877777776,
              "alt": 2117.307888888889,
              "speed2d": 3.803222222222222,
              "speed3d": 3.9333333333333336,
              "msFromStart": 114737.58800000006
            },
            {
              "lat": 43.77611042222222,
              "lng": -116.09491875555555,
              "alt": 2117.246888888889,
              "speed2d": 3.621666666666666,
              "speed3d": 3.77,
              "msFromStart": 115232.8121481482
            },
            {
              "lat": 43.77612084444444,
              "lng": -116.09493475555554,
              "alt": 2118.0303333333336,
              "speed2d": 3.3829999999999996,
              "speed3d": 3.533333333333333,
              "msFromStart": 115727.71999999997
            },
            {
              "lat": 43.77613212,
              "lng": -116.09495124000001,
              "alt": 2118.7824,
              "speed2d": 3.1658,
              "speed3d": 3.303,
              "msFromStart": 116250.06466666664
            },
            {
              "lat": 43.77614462222222,
              "lng": -116.09497027777779,
              "alt": 2119.7655555555557,
              "speed2d": 3.1211111111111114,
              "speed3d": 3.236666666666667,
              "msFromStart": 116772.39377777778
            },
            {
              "lat": 43.77615459999999,
              "lng": -116.09498881111111,
              "alt": 2120.4283333333333,
              "speed2d": 3.0785555555555555,
              "speed3d": 3.1922222222222225,
              "msFromStart": 117266.95067446392
            },
            {
              "lat": 43.77616452222222,
              "lng": -116.09500998888889,
              "alt": 2120.9154444444443,
              "speed2d": 3.177111111111111,
              "speed3d": 3.261111111111111,
              "msFromStart": 117760.39115789467
            },
            {
              "lat": 43.776173388888886,
              "lng": -116.09503367777779,
              "alt": 2121.467333333333,
              "speed2d": 3.315444444444444,
              "speed3d": 3.396666666666667,
              "msFromStart": 118253.96680961658
            },
            {
              "lat": 43.77618038888888,
              "lng": -116.09505928888888,
              "alt": 2121.974888888889,
              "speed2d": 3.445777777777778,
              "speed3d": 3.516666666666666,
              "msFromStart": 118749.04799999997
            },
            {
              "lat": 43.776188966666666,
              "lng": -116.0950838,
              "alt": 2122.509333333334,
              "speed2d": 3.5074444444444444,
              "speed3d": 3.5822222222222218,
              "msFromStart": 119244.37748148147
            },
            {
              "lat": 43.776196733333336,
              "lng": -116.09510887777778,
              "alt": 2123.0447777777777,
              "speed2d": 3.589888888888889,
              "speed3d": 3.6555555555555554,
              "msFromStart": 119739.716
            },
            {
              "lat": 43.77620448888888,
              "lng": -116.09513050000001,
              "alt": 2123.3313333333335,
              "speed2d": 3.570666666666667,
              "speed3d": 3.6411111111111114,
              "msFromStart": 120235.03130864196
            },
            {
              "lat": 43.776210899999995,
              "lng": -116.0951532777778,
              "alt": 2123.521333333333,
              "speed2d": 3.5804444444444443,
              "speed3d": 3.6466666666666674,
              "msFromStart": 120730.19599999998
            },
            {
              "lat": 43.77621578888889,
              "lng": -116.09517621111112,
              "alt": 2123.6765555555558,
              "speed2d": 3.5453333333333332,
              "speed3d": 3.618888888888889,
              "msFromStart": 121225.35240025989
            },
            {
              "lat": 43.776221310000004,
              "lng": -116.09519966999999,
              "alt": 2123.881,
              "speed2d": 3.5157000000000003,
              "speed3d": 3.578,
              "msFromStart": 121748.12399999992
            },
            {
              "lat": 43.7762266,
              "lng": -116.09522464444443,
              "alt": 2124.126888888889,
              "speed2d": 3.5272222222222225,
              "speed3d": 3.581111111111111,
              "msFromStart": 122270.75535282648
            },
            {
              "lat": 43.77622943333333,
              "lng": -116.09524985555556,
              "alt": 2124.089222222222,
              "speed2d": 3.595666666666667,
              "speed3d": 3.6588888888888893,
              "msFromStart": 122764.91600000007
            },
            {
              "lat": 43.77623255555555,
              "lng": -116.09526926666666,
              "alt": 2123.951666666667,
              "speed2d": 3.433111111111111,
              "speed3d": 3.5133333333333328,
              "msFromStart": 123259.04592592598
            },
            {
              "lat": 43.776233455555555,
              "lng": -116.09528579999998,
              "alt": 2123.4734444444443,
              "speed2d": 3.2605555555555554,
              "speed3d": 3.3466666666666662,
              "msFromStart": 123753.94399999999
            },
            {
              "lat": 43.77623543333333,
              "lng": -116.09530268888888,
              "alt": 2123.289666666667,
              "speed2d": 3.1115555555555554,
              "speed3d": 3.187777777777778,
              "msFromStart": 124249.07812345677
            },
            {
              "lat": 43.77623833333333,
              "lng": -116.09532472222223,
              "alt": 2123.3106666666663,
              "speed2d": 3.1482222222222225,
              "speed3d": 3.206666666666667,
              "msFromStart": 124744.88399999996
            },
            {
              "lat": 43.7762414,
              "lng": -116.09534886666665,
              "alt": 2123.440888888889,
              "speed2d": 3.229333333333333,
              "speed3d": 3.2855555555555553,
              "msFromStart": 125240.57481481478
            },
            {
              "lat": 43.7762435,
              "lng": -116.09537246666667,
              "alt": 2123.5967777777773,
              "speed2d": 3.280666666666666,
              "speed3d": 3.3344444444444443,
              "msFromStart": 125734.892
            },
            {
              "lat": 43.776243422222215,
              "lng": -116.09539457777778,
              "alt": 2123.569,
              "speed2d": 3.293,
              "speed3d": 3.347777777777778,
              "msFromStart": 126229.14756075373
            },
            {
              "lat": 43.77624297,
              "lng": -116.09541798,
              "alt": 2123.5636999999997,
              "speed2d": 3.2982000000000005,
              "speed3d": 3.3610000000000007,
              "msFromStart": 126751.92399999997
            },
            {
              "lat": 43.776242255555566,
              "lng": -116.0954417777778,
              "alt": 2123.5539999999996,
              "speed2d": 3.335222222222222,
              "speed3d": 3.374444444444445,
              "msFromStart": 127274.76661208575
            },
            {
              "lat": 43.77624114444445,
              "lng": -116.09546825555553,
              "alt": 2123.494111111111,
              "speed2d": 3.501888888888889,
              "speed3d": 3.537777777777778,
              "msFromStart": 127769.49200000006
            },
            {
              "lat": 43.77623951111111,
              "lng": -116.09549527777776,
              "alt": 2123.469111111111,
              "speed2d": 3.641222222222223,
              "speed3d": 3.6677777777777782,
              "msFromStart": 128264.03703703707
            },
            {
              "lat": 43.77623632222222,
              "lng": -116.09552652222222,
              "alt": 2123.430888888889,
              "speed2d": 3.8851111111111107,
              "speed3d": 3.908888888888889,
              "msFromStart": 128758.07600000003
            },
            {
              "lat": 43.77623445555556,
              "lng": -116.09555621111112,
              "alt": 2123.527444444445,
              "speed2d": 4.016444444444445,
              "speed3d": 4.045555555555556,
              "msFromStart": 129252.34409876549
            },
            {
              "lat": 43.77622996666667,
              "lng": -116.09558565555555,
              "alt": 2123.3953333333334,
              "speed2d": 4.160666666666667,
              "speed3d": 4.187777777777778,
              "msFromStart": 129748.51600000002
            },
            {
              "lat": 43.776227455555556,
              "lng": -116.0956133,
              "alt": 2123.2511111111107,
              "speed2d": 4.2235555555555555,
              "speed3d": 4.261111111111111,
              "msFromStart": 130244.8049382716
            },
            {
              "lat": 43.77622493333333,
              "lng": -116.0956426888889,
              "alt": 2123.1476666666667,
              "speed2d": 4.347333333333333,
              "speed3d": 4.36888888888889,
              "msFromStart": 130739.90399999998
            },
            {
              "lat": 43.77621981111112,
              "lng": -116.09566922222223,
              "alt": 2122.8482222222224,
              "speed2d": 4.4479999999999995,
              "speed3d": 4.474444444444444,
              "msFromStart": 131234.92511500974
            },
            {
              "lat": 43.77621507777778,
              "lng": -116.09569394444443,
              "alt": 2122.645666666667,
              "speed2d": 4.471,
              "speed3d": 4.507777777777777,
              "msFromStart": 131730.66063157906
            },
            {
              "lat": 43.77621090000001,
              "lng": -116.09571751111112,
              "alt": 2122.441,
              "speed2d": 4.472666666666667,
              "speed3d": 4.512222222222222,
              "msFromStart": 132226.52299415215
            },
            {
              "lat": 43.77620517999999,
              "lng": -116.09573796999999,
              "alt": 2121.8064,
              "speed2d": 4.392799999999999,
              "speed3d": 4.438,
              "msFromStart": 132750.03733333328
            },
            {
              "lat": 43.77619857777778,
              "lng": -116.09576108888889,
              "alt": 2121.1695555555557,
              "speed2d": 4.42188888888889,
              "speed3d": 4.455555555555556,
              "msFromStart": 133273.3276049382
            },
            {
              "lat": 43.77619122222222,
              "lng": -116.09578396666667,
              "alt": 2120.555777777778,
              "speed2d": 4.462111111111111,
              "speed3d": 4.496666666666666,
              "msFromStart": 133767.54800000007
            },
            {
              "lat": 43.77618382222222,
              "lng": -116.09580694444445,
              "alt": 2119.9682222222223,
              "speed2d": 4.468555555555556,
              "speed3d": 4.51,
              "msFromStart": 134261.66567901243
            },
            {
              "lat": 43.77617705555556,
              "lng": -116.09583153333332,
              "alt": 2119.524222222222,
              "speed2d": 4.5377777777777775,
              "speed3d": 4.558888888888889,
              "msFromStart": 134756.6720000001
            },
            {
              "lat": 43.77617061111111,
              "lng": -116.0958602888889,
              "alt": 2119.1615555555554,
              "speed2d": 4.730666666666666,
              "speed3d": 4.756666666666666,
              "msFromStart": 135251.62054320998
            },
            {
              "lat": 43.776165111111105,
              "lng": -116.09588730000002,
              "alt": 2118.796,
              "speed2d": 4.828777777777777,
              "speed3d": 4.864444444444445,
              "msFromStart": 135745.3280000001
            },
            {
              "lat": 43.776160511111115,
              "lng": -116.09591588888888,
              "alt": 2118.584,
              "speed2d": 4.8919999999999995,
              "speed3d": 4.912222222222223,
              "msFromStart": 136239.19587264466
            },
            {
              "lat": 43.77615691111111,
              "lng": -116.09594910000001,
              "alt": 2118.4533333333334,
              "speed2d": 4.986333333333333,
              "speed3d": 5.018888888888888,
              "msFromStart": 136735.28336842114
            },
            {
              "lat": 43.776153511111104,
              "lng": -116.09598393333333,
              "alt": 2118.146,
              "speed2d": 5.124888888888888,
              "speed3d": 5.136666666666667,
              "msFromStart": 137231.6633762184
            },
            {
              "lat": 43.77614989999999,
              "lng": -116.09602147777778,
              "alt": 2117.905777777778,
              "speed2d": 5.335777777777777,
              "speed3d": 5.346666666666667,
              "msFromStart": 137727.32977777772
            },
            {
              "lat": 43.7761456,
              "lng": -116.09606270000002,
              "alt": 2117.6859,
              "speed2d": 5.526099999999999,
              "speed3d": 5.542,
              "msFromStart": 138250.25511111107
            },
            {
              "lat": 43.77614298888889,
              "lng": -116.0961085888889,
              "alt": 2118.0054444444445,
              "speed2d": 5.690444444444444,
              "speed3d": 5.708888888888889,
              "msFromStart": 138772.5520000001
            },
            {
              "lat": 43.776140088888894,
              "lng": -116.09615246666667,
              "alt": 2118.4167777777775,
              "speed2d": 5.853111111111112,
              "speed3d": 5.863333333333333,
              "msFromStart": 139267.24582716057
            },
            {
              "lat": 43.77613652222223,
              "lng": -116.09619326666667,
              "alt": 2118.5875555555554,
              "speed2d": 5.9833333333333325,
              "speed3d": 6.004444444444445,
              "msFromStart": 139762.02400000003
            },
            {
              "lat": 43.77613375555555,
              "lng": -116.09623616666666,
              "alt": 2118.9122222222227,
              "speed2d": 6.140444444444444,
              "speed3d": 6.142222222222222,
              "msFromStart": 140256.82390123457
            },
            {
              "lat": 43.77612990000001,
              "lng": -116.09627862222223,
              "alt": 2119.068888888889,
              "speed2d": 6.291777777777777,
              "speed3d": 6.305555555555555,
              "msFromStart": 140751.67199999996
            },
            {
              "lat": 43.776126077777775,
              "lng": -116.09631696666668,
              "alt": 2118.989888888889,
              "speed2d": 6.314,
              "speed3d": 6.341111111111111,
              "msFromStart": 141246.59396491226
            },
            {
              "lat": 43.77612164444444,
              "lng": -116.09635374444444,
              "alt": 2118.8003333333336,
              "speed2d": 6.27,
              "speed3d": 6.29111111111111,
              "msFromStart": 141741.91831578952
            },
            {
              "lat": 43.77611594444444,
              "lng": -116.0963925888889,
              "alt": 2118.486111111111,
              "speed2d": 6.3084444444444445,
              "speed3d": 6.331111111111111,
              "msFromStart": 142237.28386744644
            },
            {
              "lat": 43.77611026666666,
              "lng": -116.09643131111112,
              "alt": 2118.2451111111113,
              "speed2d": 6.327888888888888,
              "speed3d": 6.353333333333335,
              "msFromStart": 142732.40177777788
            },
            {
              "lat": 43.7761041,
              "lng": -116.09646973333332,
              "alt": 2117.9725555555556,
              "speed2d": 6.296666666666667,
              "speed3d": 6.322222222222221,
              "msFromStart": 143227.44711111122
            },
            {
              "lat": 43.776097809999996,
              "lng": -116.09651047000001,
              "alt": 2117.7543,
              "speed2d": 6.2684,
              "speed3d": 6.297,
              "msFromStart": 143749.88088888896
            },
            {
              "lat": 43.776092855555554,
              "lng": -116.09655122222222,
              "alt": 2117.718111111111,
              "speed2d": 6.231,
              "speed3d": 6.263333333333334,
              "msFromStart": 144272.19516049387
            },
            {
              "lat": 43.77608944444445,
              "lng": -116.09658759999999,
              "alt": 2117.662333333333,
              "speed2d": 6.1128888888888895,
              "speed3d": 6.144444444444444,
              "msFromStart": 144766.44800000006
            },
            {
              "lat": 43.77608457777778,
              "lng": -116.09662453333333,
              "alt": 2117.586666666667,
              "speed2d": 6.0745555555555555,
              "speed3d": 6.098888888888888,
              "msFromStart": 145260.8302222223
            },
            {
              "lat": 43.77608012222222,
              "lng": -116.09666212222223,
              "alt": 2117.435444444445,
              "speed2d": 6.058999999999999,
              "speed3d": 6.092222222222222,
              "msFromStart": 145756.56799999997
            },
            {
              "lat": 43.77607726666667,
              "lng": -116.09670034444444,
              "alt": 2117.382777777778,
              "speed2d": 6.049666666666667,
              "speed3d": 6.063333333333334,
              "msFromStart": 146252.57837037032
            },
            {
              "lat": 43.77607384444444,
              "lng": -116.0967401,
              "alt": 2117.325777777778,
              "speed2d": 6.089,
              "speed3d": 6.111111111111111,
              "msFromStart": 146748.896
            },
            {
              "lat": 43.776068844444445,
              "lng": -116.09678024444443,
              "alt": 2117.2247777777775,
              "speed2d": 6.120444444444445,
              "speed3d": 6.1466666666666665,
              "msFromStart": 147245.09807407408
            },
            {
              "lat": 43.77606484444445,
              "lng": -116.09681702222221,
              "alt": 2117.0063333333333,
              "speed2d": 6.043666666666667,
              "speed3d": 6.067777777777778,
              "msFromStart": 147739.64088888888
            },
            {
              "lat": 43.77606105555556,
              "lng": -116.09685507777778,
              "alt": 2116.807444444444,
              "speed2d": 6.027555555555555,
              "speed3d": 6.056666666666667,
              "msFromStart": 148233.75407407407
            },
            {
              "lat": 43.776057566666665,
              "lng": -116.09689186666667,
              "alt": 2116.6914444444446,
              "speed2d": 5.962666666666667,
              "speed3d": 5.996666666666668,
              "msFromStart": 148727.7191111112
            },
            {
              "lat": 43.776054189999996,
              "lng": -116.09692818,
              "alt": 2116.5908999999997,
              "speed2d": 5.8211,
              "speed3d": 5.86,
              "msFromStart": 149249.1711111112
            },
            {
              "lat": 43.77605013333333,
              "lng": -116.09696449999998,
              "alt": 2116.4848888888887,
              "speed2d": 5.725555555555555,
              "speed3d": 5.754444444444444,
              "msFromStart": 149771.2080000001
            },
            {
              "lat": 43.77604512222223,
              "lng": -116.09699901111112,
              "alt": 2116.335222222222,
              "speed2d": 5.675888888888889,
              "speed3d": 5.702222222222222,
              "msFromStart": 150266.04009876552
            },
            {
              "lat": 43.776040788888885,
              "lng": -116.0970335888889,
              "alt": 2116.280111111111,
              "speed2d": 5.617222222222223,
              "speed3d": 5.647777777777778,
              "msFromStart": 150761.79999999993
            },
            {
              "lat": 43.77603602222222,
              "lng": -116.0970701777778,
              "alt": 2116.199222222222,
              "speed2d": 5.6404444444444435,
              "speed3d": 5.657777777777778,
              "msFromStart": 151257.6045536062
            },
            {
              "lat": 43.776032055555554,
              "lng": -116.0971058222222,
              "alt": 2116.0536666666667,
              "speed2d": 5.651000000000001,
              "speed3d": 5.671111111111111,
              "msFromStart": 151752.75368421056
            },
            {
              "lat": 43.77602862222223,
              "lng": -116.09714336666667,
              "alt": 2115.9947777777775,
              "speed2d": 5.702333333333335,
              "speed3d": 5.7155555555555555,
              "msFromStart": 152247.7756881092
            },
            {
              "lat": 43.77602456666666,
              "lng": -116.09718435555556,
              "alt": 2115.9766666666665,
              "speed2d": 5.842888888888888,
              "speed3d": 5.841111111111111,
              "msFromStart": 152742.600888889
            },
            {
              "lat": 43.77601995555556,
              "lng": -116.09722762222222,
              "alt": 2115.9434444444446,
              "speed2d": 6.055666666666665,
              "speed3d": 6.053333333333334,
              "msFromStart": 153237.44325925937
            },
            {
              "lat": 43.77601414444445,
              "lng": -116.09726994444445,
              "alt": 2115.7396666666664,
              "speed2d": 6.208111111111111,
              "speed3d": 6.213333333333333,
              "msFromStart": 153732.94933333324
            },
            {
              "lat": 43.77600877777778,
              "lng": -116.09731313333333,
              "alt": 2115.609888888889,
              "speed2d": 6.379333333333332,
              "speed3d": 6.38,
              "msFromStart": 154228.53066666657
            },
            {
              "lat": 43.7760038,
              "lng": -116.09735809,
              "alt": 2115.4232,
              "speed2d": 6.484300000000001,
              "speed3d": 6.489000000000001,
              "msFromStart": 154750.67466666657
            },
            {
              "lat": 43.77599928888889,
              "lng": -116.09740450000001,
              "alt": 2115.3462222222224,
              "speed2d": 6.577444444444445,
              "speed3d": 6.587777777777777,
              "msFromStart": 155272.78755555546
            },
            {
              "lat": 43.77599462222222,
              "lng": -116.09744934444441,
              "alt": 2115.2283333333335,
              "speed2d": 6.694333333333333,
              "speed3d": 6.703333333333331,
              "msFromStart": 155768.62399999995
            },
            {
              "lat": 43.77598811111111,
              "lng": -116.09749538888889,
              "alt": 2115.001333333333,
              "speed2d": 6.86511111111111,
              "speed3d": 6.852222222222222,
              "msFromStart": 156264.52625341128
            },
            {
              "lat": 43.77598093333334,
              "lng": -116.09754173333334,
              "alt": 2114.858111111111,
              "speed2d": 6.997333333333334,
              "speed3d": 7.007777777777777,
              "msFromStart": 156759.63705263165
            },
            {
              "lat": 43.775973111111114,
              "lng": -116.09758702222223,
              "alt": 2114.721777777778,
              "speed2d": 7.0425555555555555,
              "speed3d": 7.056666666666667,
              "msFromStart": 157254.59165692015
            },
            {
              "lat": 43.775964633333324,
              "lng": -116.09763389999999,
              "alt": 2114.554888888889,
              "speed2d": 7.138111111111111,
              "speed3d": 7.136666666666667,
              "msFromStart": 157749.2817777778
            },
            {
              "lat": 43.775956900000004,
              "lng": -116.09768074444446,
              "alt": 2114.3063333333334,
              "speed2d": 7.260222222222223,
              "speed3d": 7.273333333333333,
              "msFromStart": 158243.91525925926
            },
            {
              "lat": 43.77594892222223,
              "lng": -116.09772443333334,
              "alt": 2114.056333333333,
              "speed2d": 7.236999999999999,
              "speed3d": 7.258888888888889,
              "msFromStart": 158738.6435555554
            },
            {
              "lat": 43.775942066666666,
              "lng": -116.09776763333335,
              "alt": 2114.085222222222,
              "speed2d": 7.133222222222223,
              "speed3d": 7.162222222222222,
              "msFromStart": 159233.37007407393
            },
            {
              "lat": 43.77593507777778,
              "lng": -116.09780774444445,
              "alt": 2114.023333333333,
              "speed2d": 6.943555555555556,
              "speed3d": 6.984444444444445,
              "msFromStart": 159727.84177777774
            },
            {
              "lat": 43.77592680000001,
              "lng": -116.09784626999999,
              "alt": 2113.8075999999996,
              "speed2d": 6.692899999999999,
              "speed3d": 6.738000000000001,
              "msFromStart": 160250.04488888886
            },
            {
              "lat": 43.77591822222222,
              "lng": -116.09788347777778,
              "alt": 2113.5201111111114,
              "speed2d": 6.453666666666667,
              "speed3d": 6.495555555555556,
              "msFromStart": 160774.54400000005
            },
            {
              "lat": 43.7759083,
              "lng": -116.09791770000001,
              "alt": 2113.346111111111,
              "speed2d": 6.216111111111111,
              "speed3d": 6.261111111111109,
              "msFromStart": 161271.66301234573
            },
            {
              "lat": 43.775896255555566,
              "lng": -116.09795007777777,
              "alt": 2113.141888888889,
              "speed2d": 6.012333333333334,
              "speed3d": 6.050000000000001,
              "msFromStart": 161767.3120000001
            },
            {
              "lat": 43.775884788888895,
              "lng": -116.0979832888889,
              "alt": 2113.1168888888888,
              "speed2d": 5.869999999999999,
              "speed3d": 5.904444444444445,
              "msFromStart": 162262.65044834313
            },
            {
              "lat": 43.77587501111112,
              "lng": -116.09801462222224,
              "alt": 2113.1495555555557,
              "speed2d": 5.661444444444444,
              "speed3d": 5.71111111111111,
              "msFromStart": 162757.56463157886
            },
            {
              "lat": 43.775867455555556,
              "lng": -116.09804396666668,
              "alt": 2113.105444444445,
              "speed2d": 5.408777777777779,
              "speed3d": 5.451111111111111,
              "msFromStart": 163252.5262534112
            },
            {
              "lat": 43.77585994444445,
              "lng": -116.09807428888888,
              "alt": 2113.0227777777777,
              "speed2d": 5.285888888888888,
              "speed3d": 5.321111111111112,
              "msFromStart": 163748.65777777787
            },
            {
              "lat": 43.77585318888889,
              "lng": -116.09810484444445,
              "alt": 2113.1093333333333,
              "speed2d": 5.157666666666667,
              "speed3d": 5.1944444444444455,
              "msFromStart": 164244.8868148149
            },
            {
              "lat": 43.77584516666666,
              "lng": -116.09813554444445,
              "alt": 2113.157,
              "speed2d": 5.097666666666668,
              "speed3d": 5.1177777777777775,
              "msFromStart": 164739.16622222215
            },
            {
              "lat": 43.77583506666667,
              "lng": -116.09816428888887,
              "alt": 2113.2076666666667,
              "speed2d": 5.0038888888888895,
              "speed3d": 5.044444444444444,
              "msFromStart": 165233.00385185174
            },
            {
              "lat": 43.7758257111111,
              "lng": -116.09818904444445,
              "alt": 2113.281,
              "speed2d": 4.788888888888889,
              "speed3d": 4.8277777777777775,
              "msFromStart": 165727.2977777777
            },
            {
              "lat": 43.77581586000001,
              "lng": -116.09821483,
              "alt": 2113.2756,
              "speed2d": 4.6253,
              "speed3d": 4.6770000000000005,
              "msFromStart": 166249.20961403503
            },
            {
              "lat": 43.775807988888886,
              "lng": -116.09823242222222,
              "alt": 2113.1266666666666,
              "speed2d": 4.078777777777778,
              "speed3d": 4.18,
              "msFromStart": 166771.3785263158
            },
            {
              "lat": 43.77580186666667,
              "lng": -116.09824614444445,
              "alt": 2113.006,
              "speed2d": 3.419666666666666,
              "speed3d": 3.5,
              "msFromStart": 167266.2176062378
            },
            {
              "lat": 43.7757958,
              "lng": -116.09826397777776,
              "alt": 2112.980444444444,
              "speed2d": 3.2123333333333335,
              "speed3d": 3.2411111111111106,
              "msFromStart": 167762.0622222223
            },
            {
              "lat": 43.775788666666664,
              "lng": -116.09828230000001,
              "alt": 2112.9905555555556,
              "speed2d": 3.1825555555555556,
              "speed3d": 3.2266666666666666,
              "msFromStart": 168258.08829629642
            },
            {
              "lat": 43.77578058888889,
              "lng": -116.09830151111112,
              "alt": 2112.968,
              "speed2d": 3.395555555555555,
              "speed3d": 3.382222222222222,
              "msFromStart": 168753.41511111122
            },
            {
              "lat": 43.77577207777778,
              "lng": -116.09831673333333,
              "alt": 2112.6856666666663,
              "speed2d": 3.257444444444444,
              "speed3d": 3.326666666666666,
              "msFromStart": 169248.52444444457
            },
            {
              "lat": 43.775763555555564,
              "lng": -116.09832966666664,
              "alt": 2112.519888888889,
              "speed2d": 2.9624444444444444,
              "speed3d": 3.004444444444445,
              "msFromStart": 169743.20711111114
            },
            {
              "lat": 43.77575616666667,
              "lng": -116.09834306666667,
              "alt": 2112.526777777778,
              "speed2d": 2.7876666666666665,
              "speed3d": 2.8422222222222224,
              "msFromStart": 170237.77481481485
            },
            {
              "lat": 43.77574934444445,
              "lng": -116.09835598888888,
              "alt": 2112.6077777777773,
              "speed2d": 2.6378888888888885,
              "speed3d": 2.6644444444444444,
              "msFromStart": 170732.2595555556
            },
            {
              "lat": 43.77574338888889,
              "lng": -116.09836838888889,
              "alt": 2112.6122222222225,
              "speed2d": 2.501777777777778,
              "speed3d": 2.5566666666666666,
              "msFromStart": 171226.855048733
            },
            {
              "lat": 43.775739970000004,
              "lng": -116.09837921999998,
              "alt": 2112.7208,
              "speed2d": 2.0992,
              "speed3d": 2.157,
              "msFromStart": 171750.33431578946
            },
            {
              "lat": 43.77573126666667,
              "lng": -116.0983910111111,
              "alt": 2112.6936666666666,
              "speed2d": 2.2067777777777775,
              "speed3d": 2.1988888888888884,
              "msFromStart": 172274.061785575
            },
            {
              "lat": 43.77572549999999,
              "lng": -116.09839945555555,
              "alt": 2112.9523333333336,
              "speed2d": 2.011111111111111,
              "speed3d": 2.0766666666666667,
              "msFromStart": 172769.4151111112
            },
            {
              "lat": 43.775720155555554,
              "lng": -116.09840733333334,
              "alt": 2113.1413333333335,
              "speed2d": 1.8641111111111113,
              "speed3d": 1.8466666666666667,
              "msFromStart": 173264.5102222223
            },
            {
              "lat": 43.775710677777774,
              "lng": -116.09841743333332,
              "alt": 2113.0942222222225,
              "speed2d": 2.0700000000000003,
              "speed3d": 2.0933333333333333,
              "msFromStart": 173759.0364444444
            },
            {
              "lat": 43.77570641111111,
              "lng": -116.09842313333334,
              "alt": 2113.1333333333337,
              "speed2d": 1.7428888888888887,
              "speed3d": 1.7800000000000002,
              "msFromStart": 174253.5804444444
            },
            {
              "lat": 43.77570068888889,
              "lng": -116.09843047777778,
              "alt": 2112.8705555555553,
              "speed2d": 1.7368888888888887,
              "speed3d": 1.7655555555555558,
              "msFromStart": 174749.72444444435
            },
            {
              "lat": 43.77569597777778,
              "lng": -116.09843468888889,
              "alt": 2112.7702222222224,
              "speed2d": 1.49,
              "speed3d": 1.5699999999999998,
              "msFromStart": 175246.1125925925
            },
            {
              "lat": 43.77569398888889,
              "lng": -116.09843922222223,
              "alt": 2112.994,
              "speed2d": 1.1468888888888888,
              "speed3d": 1.181111111111111,
              "msFromStart": 175740.94222222228
            },
            {
              "lat": 43.77569154444444,
              "lng": -116.09844853333333,
              "alt": 2113.1522222222225,
              "speed2d": 1.2624444444444445,
              "speed3d": 1.2844444444444443,
              "msFromStart": 176235.29629629635
            },
            {
              "lat": 43.77568782222222,
              "lng": -116.09845521111112,
              "alt": 2113.221666666667,
              "speed2d": 1.2585555555555556,
              "speed3d": 1.2822222222222222,
              "msFromStart": 176728.79111111115
            },
            {
              "lat": 43.77568557,
              "lng": -116.09846148,
              "alt": 2113.2042,
              "speed2d": 1.1481,
              "speed3d": 1.174,
              "msFromStart": 177249.65529824563
            },
            {
              "lat": 43.77568045555556,
              "lng": -116.09846674444444,
              "alt": 2113.1646666666666,
              "speed2d": 1.197888888888889,
              "speed3d": 1.238888888888889,
              "msFromStart": 177771.77768421057
            },
            {
              "lat": 43.77567783333333,
              "lng": -116.09847273333332,
              "alt": 2113.462777777778,
              "speed2d": 1.196222222222222,
              "speed3d": 1.21,
              "msFromStart": 178266.70487329437
            },
            {
              "lat": 43.77567388888889,
              "lng": -116.09847648888889,
              "alt": 2113.7709999999997,
              "speed2d": 1.1235555555555554,
              "speed3d": 1.1344444444444446,
              "msFromStart": 178761.92711111103
            },
            {
              "lat": 43.77567115555556,
              "lng": -116.09848303333331,
              "alt": 2114.3755555555554,
              "speed2d": 1.120111111111111,
              "speed3d": 1.1311111111111112,
              "msFromStart": 179257.18755555543
            },
            {
              "lat": 43.775666622222225,
              "lng": -116.09848764444443,
              "alt": 2114.5643333333333,
              "speed2d": 1.1645555555555556,
              "speed3d": 1.1433333333333333,
              "msFromStart": 179752.09244444448
            },
            {
              "lat": 43.77566060000001,
              "lng": -116.0984952111111,
              "alt": 2114.7547777777777,
              "speed2d": 1.430777777777778,
              "speed3d": 1.4366666666666665,
              "msFromStart": 180246.9546666667
            },
            {
              "lat": 43.77566054444445,
              "lng": -116.09850031111111,
              "alt": 2114.329111111111,
              "speed2d": 1.2161111111111111,
              "speed3d": 1.24,
              "msFromStart": 180742.279111111
            },
            {
              "lat": 43.775655699999994,
              "lng": -116.09850407777778,
              "alt": 2114.061777777778,
              "speed2d": 1.1003333333333332,
              "speed3d": 1.1288888888888888,
              "msFromStart": 181237.738448343
            },
            {
              "lat": 43.77565336666667,
              "lng": -116.09851095555555,
              "alt": 2113.916666666667,
              "speed2d": 1.2925555555555557,
              "speed3d": 1.2544444444444445,
              "msFromStart": 181733.39115789474
            },
            {
              "lat": 43.77564324444444,
              "lng": -116.0985186,
              "alt": 2113.7698888888885,
              "speed2d": 1.7174444444444443,
              "speed3d": 1.643333333333333,
              "msFromStart": 182229.04106042883
            },
            {
              "lat": 43.7756305,
              "lng": -116.0985259,
              "alt": 2113.3273,
              "speed2d": 2.1384000000000003,
              "speed3d": 2.136,
              "msFromStart": 182751.18133333337
            },
            {
              "lat": 43.77561743333334,
              "lng": -116.09852995555555,
              "alt": 2112.8004444444446,
              "speed2d": 2.3957777777777776,
              "speed3d": 2.3944444444444444,
              "msFromStart": 183272.97540740744
            },
            {
              "lat": 43.775609655555556,
              "lng": -116.09853252222223,
              "alt": 2112.8952222222224,
              "speed2d": 2.1915555555555555,
              "speed3d": 2.2511111111111113,
              "msFromStart": 183767.42577777777
            },
            {
              "lat": 43.775601466666664,
              "lng": -116.09853726666667,
              "alt": 2112.6104444444445,
              "speed2d": 2.144222222222222,
              "speed3d": 2.1411111111111114,
              "msFromStart": 184261.92177777778
            },
            {
              "lat": 43.775593400000005,
              "lng": -116.09854279999999,
              "alt": 2112.5406666666663,
              "speed2d": 2.122333333333333,
              "speed3d": 2.125555555555555,
              "msFromStart": 184756.5777777778
            },
            {
              "lat": 43.77558211111111,
              "lng": -116.09855059999998,
              "alt": 2112.867777777778,
              "speed2d": 2.359111111111111,
              "speed3d": 2.348888888888889,
              "msFromStart": 185251.32474074076
            },
            {
              "lat": 43.77557072222223,
              "lng": -116.09855594444446,
              "alt": 2113.1266666666666,
              "speed2d": 2.4745555555555554,
              "speed3d": 2.4555555555555557,
              "msFromStart": 185746.5813333334
            },
            {
              "lat": 43.77555358888889,
              "lng": -116.0985675111111,
              "alt": 2113.1605555555557,
              "speed2d": 3.1145555555555555,
              "speed3d": 3.0199999999999996,
              "msFromStart": 186241.96580116966
            },
            {
              "lat": 43.77553643333333,
              "lng": -116.09857764444445,
              "alt": 2113.25,
              "speed2d": 3.421222222222222,
              "speed3d": 3.4466666666666668,
              "msFromStart": 186737.35494736847
            },
            {
              "lat": 43.77552517777777,
              "lng": -116.09858516666667,
              "alt": 2112.8096666666665,
              "speed2d": 3.251777777777778,
              "speed3d": 3.253333333333333,
              "msFromStart": 187232.70147368425
            },
            {
              "lat": 43.77551094444445,
              "lng": -116.09859422222223,
              "alt": 2112.878777777778,
              "speed2d": 3.3097777777777777,
              "speed3d": 3.3033333333333337,
              "msFromStart": 187727.21600000004
            },
            {
              "lat": 43.77549333,
              "lng": -116.09860497999999,
              "alt": 2112.983,
              "speed2d": 3.6421,
              "speed3d": 3.609,
              "msFromStart": 188248.95253333342
            },
            {
              "lat": 43.77548092222222,
              "lng": -116.09861333333333,
              "alt": 2113.184,
              "speed2d": 3.406,
              "speed3d": 3.4755555555555557,
              "msFromStart": 188771.51111111097
            },
            {
              "lat": 43.77547204444444,
              "lng": -116.09862027777777,
              "alt": 2113.360777777778,
              "speed2d": 3.1032222222222225,
              "speed3d": 3.062222222222222,
              "msFromStart": 189266.7099259258
            },
            {
              "lat": 43.775454288888895,
              "lng": -116.09863128888888,
              "alt": 2113.624,
              "speed2d": 3.5677777777777777,
              "speed3d": 3.5311111111111115,
              "msFromStart": 189760.93688888883
            },
            {
              "lat": 43.775435699999996,
              "lng": -116.09864424444446,
              "alt": 2114.1665555555555,
              "speed2d": 3.9636666666666667,
              "speed3d": 3.945555555555556,
              "msFromStart": 190255.0589629629
            },
            {
              "lat": 43.775417355555554,
              "lng": -116.09865424444443,
              "alt": 2114.552111111111,
              "speed2d": 4.127666666666666,
              "speed3d": 4.097777777777778,
              "msFromStart": 190750.56177777777
            },
            {
              "lat": 43.77539608888888,
              "lng": -116.09866673333335,
              "alt": 2115.133777777778,
              "speed2d": 4.500555555555556,
              "speed3d": 4.467777777777777,
              "msFromStart": 191246.4465185185
            },
            {
              "lat": 43.77537927777777,
              "lng": -116.09867658888888,
              "alt": 2115.7077777777777,
              "speed2d": 4.3792222222222215,
              "speed3d": 4.405555555555556,
              "msFromStart": 191742.6986666667
            },
            {
              "lat": 43.77535713333333,
              "lng": -116.09868524444444,
              "alt": 2115.8754444444444,
              "speed2d": 4.696888888888889,
              "speed3d": 4.677777777777778,
              "msFromStart": 192238.93272514624
            },
            {
              "lat": 43.77534033333333,
              "lng": -116.09869373333335,
              "alt": 2116.3251111111113,
              "speed2d": 4.531777777777777,
              "speed3d": 4.558888888888889,
              "msFromStart": 192734.067368421
            },
            {
              "lat": 43.7753219,
              "lng": -116.09870454444446,
              "alt": 2116.4087777777777,
              "speed2d": 4.571777777777778,
              "speed3d": 4.554444444444445,
              "msFromStart": 193228.877239766
            },
            {
              "lat": 43.77530108,
              "lng": -116.09871586000001,
              "alt": 2116.2432,
              "speed2d": 4.5571,
              "speed3d": 4.619,
              "msFromStart": 193750.15599999996
            },
            {
              "lat": 43.77528761111111,
              "lng": -116.09872371111112,
              "alt": 2115.797777777778,
              "speed2d": 3.9263333333333335,
              "speed3d": 3.966666666666667,
              "msFromStart": 194271.20977777775
            },
            {
              "lat": 43.77526821111111,
              "lng": -116.09873257777777,
              "alt": 2115.9043333333334,
              "speed2d": 3.9567777777777775,
              "speed3d": 3.938888888888889,
              "msFromStart": 194766.0906666667
            },
            {
              "lat": 43.77524603333333,
              "lng": -116.09874062222221,
              "alt": 2116.493,
              "speed2d": 4.1530000000000005,
              "speed3d": 4.134444444444444,
              "msFromStart": 195261.26192592597
            },
            {
              "lat": 43.77522647777778,
              "lng": -116.09874665555556,
              "alt": 2116.9390000000003,
              "speed2d": 4.326777777777777,
              "speed3d": 4.307777777777778,
              "msFromStart": 195756.2257777778
            },
            {
              "lat": 43.77520801111111,
              "lng": -116.09874915555557,
              "alt": 2117.197888888889,
              "speed2d": 4.316222222222222,
              "speed3d": 4.345555555555555,
              "msFromStart": 196251.19791033142
            },
            {
              "lat": 43.77518762222222,
              "lng": -116.09875533333334,
              "alt": 2117.4098888888893,
              "speed2d": 4.518555555555556,
              "speed3d": 4.471111111111111,
              "msFromStart": 196746.7713684211
            },
            {
              "lat": 43.775167777777774,
              "lng": -116.09876358888887,
              "alt": 2117.5415555555555,
              "speed2d": 4.614444444444445,
              "speed3d": 4.645555555555555,
              "msFromStart": 197242.489091618
            },
            {
              "lat": 43.77515211111111,
              "lng": -116.09876845555554,
              "alt": 2117.3109999999997,
              "speed2d": 4.261333333333333,
              "speed3d": 4.29,
              "msFromStart": 197738.0915555555
            },
            {
              "lat": 43.775131755555556,
              "lng": -116.09877625555556,
              "alt": 2116.71,
              "speed2d": 4.366666666666666,
              "speed3d": 4.348888888888888,
              "msFromStart": 198233.63674074068
            },
            {
              "lat": 43.77511188888889,
              "lng": -116.09878234444444,
              "alt": 2116.3676666666665,
              "speed2d": 4.300777777777777,
              "speed3d": 4.317777777777778,
              "msFromStart": 198728.9004444444
            },
            {
              "lat": 43.775091890000006,
              "lng": -116.09878913,
              "alt": 2116.0085,
              "speed2d": 4.1499,
              "speed3d": 4.164,
              "msFromStart": 199251.53466666662
            },
            {
              "lat": 43.77507548888889,
              "lng": -116.09879579999999,
              "alt": 2115.9582222222225,
              "speed2d": 3.764666666666666,
              "speed3d": 3.837777777777778,
              "msFromStart": 199773.77599999995
            },
            {
              "lat": 43.775061988888886,
              "lng": -116.09879976666667,
              "alt": 2115.7753333333335,
              "speed2d": 3.4218888888888888,
              "speed3d": 3.425555555555556,
              "msFromStart": 200268.39288888883
            },
            {
              "lat": 43.77504138888889,
              "lng": -116.09880727777778,
              "alt": 2116.2503333333334,
              "speed2d": 3.7895555555555553,
              "speed3d": 3.751111111111111,
              "msFromStart": 200762.77866666674
            },
            {
              "lat": 43.77502282222221,
              "lng": -116.09881397777777,
              "alt": 2116.093888888889,
              "speed2d": 3.867666666666667,
              "speed3d": 3.8633333333333337,
              "msFromStart": 201257.17244444453
            },
            {
              "lat": 43.77500653333334,
              "lng": -116.09881863333334,
              "alt": 2115.4895555555554,
              "speed2d": 3.7823333333333333,
              "speed3d": 3.79,
              "msFromStart": 201752.22400000005
            },
            {
              "lat": 43.77498555555557,
              "lng": -116.09882652222221,
              "alt": 2115.211333333333,
              "speed2d": 4.152444444444445,
              "speed3d": 4.043333333333333,
              "msFromStart": 202247.4601481482
            },
            {
              "lat": 43.774959466666665,
              "lng": -116.09884024444445,
              "alt": 2115.5836666666664,
              "speed2d": 4.869555555555555,
              "speed3d": 4.868888888888888,
              "msFromStart": 202743.07911111115
            },
            {
              "lat": 43.77493797777778,
              "lng": -116.09884742222222,
              "alt": 2115.5453333333335,
              "speed2d": 4.927555555555555,
              "speed3d": 4.898888888888889,
              "msFromStart": 203238.80059259266
            },
            {
              "lat": 43.77491148888889,
              "lng": -116.09885394444443,
              "alt": 2115.5737777777777,
              "speed2d": 5.2987777777777785,
              "speed3d": 5.2633333333333345,
              "msFromStart": 203733.79022222225
            },
            {
              "lat": 43.77488707777778,
              "lng": -116.09885883333331,
              "alt": 2115.364777777778,
              "speed2d": 5.27888888888889,
              "speed3d": 5.333333333333333,
              "msFromStart": 204228.51200000005
            },
            {
              "lat": 43.77486627,
              "lng": -116.09886014,
              "alt": 2114.9597000000003,
              "speed2d": 4.8965,
              "speed3d": 4.921,
              "msFromStart": 204750.75466666665
            },
            {
              "lat": 43.77484198888889,
              "lng": -116.09886264444445,
              "alt": 2114.2431111111114,
              "speed2d": 4.946555555555555,
              "speed3d": 4.8999999999999995,
              "msFromStart": 205273.04592592595
            },
            {
              "lat": 43.77481363333334,
              "lng": -116.09886845555556,
              "alt": 2113.405333333333,
              "speed2d": 5.491333333333334,
              "speed3d": 5.462222222222222,
              "msFromStart": 205768.21155555552
            },
            {
              "lat": 43.77478297777778,
              "lng": -116.09887424444445,
              "alt": 2112.751888888889,
              "speed2d": 6.008333333333333,
              "speed3d": 5.949999999999999,
              "msFromStart": 206263.42080311885
            },
            {
              "lat": 43.77474994444445,
              "lng": -116.09888072222222,
              "alt": 2112.2472222222223,
              "speed2d": 6.546555555555555,
              "speed3d": 6.544444444444444,
              "msFromStart": 206758.16252631578
            },
            {
              "lat": 43.774720333333335,
              "lng": -116.09888576666667,
              "alt": 2111.7768888888886,
              "speed2d": 6.565333333333334,
              "speed3d": 6.557777777777778,
              "msFromStart": 207252.79000389855
            },
            {
              "lat": 43.77468698888889,
              "lng": -116.09889155555555,
              "alt": 2112.2095555555557,
              "speed2d": 6.912777777777778,
              "speed3d": 6.911111111111111,
              "msFromStart": 207747.46755555557
            },
            {
              "lat": 43.774655100000004,
              "lng": -116.09889837777779,
              "alt": 2112.451333333333,
              "speed2d": 7.0824444444444445,
              "speed3d": 7.073333333333333,
              "msFromStart": 208242.13540740742
            },
            {
              "lat": 43.77462513333333,
              "lng": -116.0989061111111,
              "alt": 2112.791888888889,
              "speed2d": 7.0104444444444445,
              "speed3d": 7.058888888888888,
              "msFromStart": 208736.26844444437
            },
            {
              "lat": 43.77460107777779,
              "lng": -116.09890986666667,
              "alt": 2112.806777777778,
              "speed2d": 6.403333333333333,
              "speed3d": 6.472222222222222,
              "msFromStart": 209230.24681481475
            },
            {
              "lat": 43.77457846666667,
              "lng": -116.09891521111112,
              "alt": 2111.5733333333333,
              "speed2d": 5.984666666666666,
              "speed3d": 6.055555555555555,
              "msFromStart": 209725.0302222223
            },
            {
              "lat": 43.774552799999995,
              "lng": -116.09892128,
              "alt": 2111.2776,
              "speed2d": 5.83,
              "speed3d": 5.8149999999999995,
              "msFromStart": 210247.6642666668
            },
            {
              "lat": 43.77452361111111,
              "lng": -116.09892895555556,
              "alt": 2111.0686666666666,
              "speed2d": 6.012888888888889,
              "speed3d": 6.05,
              "msFromStart": 210770.8604444444
            },
            {
              "lat": 43.77449994444445,
              "lng": -116.09893381111111,
              "alt": 2110.463777777778,
              "speed2d": 5.749666666666667,
              "speed3d": 5.7622222222222215,
              "msFromStart": 211266.52712670562
            },
            {
              "lat": 43.774472933333335,
              "lng": -116.09894056666667,
              "alt": 2109.6735555555556,
              "speed2d": 5.869111111111111,
              "speed3d": 5.911111111111111,
              "msFromStart": 211760.62063157902
            },
            {
              "lat": 43.774448199999995,
              "lng": -116.09894566666667,
              "alt": 2108.671888888889,
              "speed2d": 5.716555555555555,
              "speed3d": 5.753333333333334,
              "msFromStart": 212254.39446393773
            },
            {
              "lat": 43.77441932222222,
              "lng": -116.09895466666666,
              "alt": 2107.694888888889,
              "speed2d": 6.026111111111112,
              "speed3d": 6.0088888888888885,
              "msFromStart": 212749.5671111112
            },
            {
              "lat": 43.77438758888889,
              "lng": -116.09896682222221,
              "alt": 2107.0606666666667,
              "speed2d": 6.404,
              "speed3d": 6.416666666666667,
              "msFromStart": 213245.25125925933
            },
            {
              "lat": 43.77435473333333,
              "lng": -116.09897895555555,
              "alt": 2106.0124444444446,
              "speed2d": 6.758333333333333,
              "speed3d": 6.779999999999999,
              "msFromStart": 213740.8622222222
            },
            {
              "lat": 43.774320177777774,
              "lng": -116.09899630000001,
              "alt": 2105.6787777777777,
              "speed2d": 7.223333333333333,
              "speed3d": 7.217777777777777,
              "msFromStart": 214236.4373333333
            },
            {
              "lat": 43.77428317777777,
              "lng": -116.0990184111111,
              "alt": 2105.408111111111,
              "speed2d": 7.761555555555556,
              "speed3d": 7.743333333333333,
              "msFromStart": 214731.8435555555
            },
            {
              "lat": 43.77424694444445,
              "lng": -116.09904224444445,
              "alt": 2104.8199999999997,
              "speed2d": 8.090666666666666,
              "speed3d": 8.126666666666665,
              "msFromStart": 215227.16325925913
            },
            {
              "lat": 43.774205349999995,
              "lng": -116.09906624999999,
              "alt": 2104.1436000000003,
              "speed2d": 8.4668,
              "speed3d": 8.464,
              "msFromStart": 215749.50399999996
            },
            {
              "lat": 43.77416332222222,
              "lng": -116.09908922222222,
              "alt": 2103.664888888889,
              "speed2d": 8.722222222222223,
              "speed3d": 8.755555555555555,
              "msFromStart": 216271.7581286549
            },
            {
              "lat": 43.77412024444444,
              "lng": -116.09911560000002,
              "alt": 2103.3172222222224,
              "speed2d": 9.157555555555554,
              "speed3d": 9.141111111111112,
              "msFromStart": 216767.36421052634
            },
            {
              "lat": 43.77408088888889,
              "lng": -116.09913821111111,
              "alt": 2102.729777777778,
              "speed2d": 9.137777777777778,
              "speed3d": 9.203333333333333,
              "msFromStart": 217263.1034385965
            },
            {
              "lat": 43.774039411111104,
              "lng": -116.0991601111111,
              "alt": 2102.647444444445,
              "speed2d": 9.260111111111112,
              "speed3d": 9.282222222222224,
              "msFromStart": 217757.40800000002
            },
            {
              "lat": 43.77399753333333,
              "lng": -116.099183,
              "alt": 2102.231333333333,
              "speed2d": 9.423444444444444,
              "speed3d": 9.454444444444444,
              "msFromStart": 218251.23229629634
            },
            {
              "lat": 43.77395342222222,
              "lng": -116.09920441111112,
              "alt": 2101.628777777778,
              "speed2d": 9.660777777777776,
              "speed3d": 9.656666666666666,
              "msFromStart": 218745.97422222228
            },
            {
              "lat": 43.77390608888889,
              "lng": -116.09922842222221,
              "alt": 2101.190888888889,
              "speed2d": 10.062,
              "speed3d": 10.07,
              "msFromStart": 219241.1087407408
            },
            {
              "lat": 43.77386088888889,
              "lng": -116.09924800000002,
              "alt": 2100.376111111111,
              "speed2d": 10.078666666666667,
              "speed3d": 10.113333333333335,
              "msFromStart": 219737.27911111107
            },
            {
              "lat": 43.773815455555564,
              "lng": -116.09926788888887,
              "alt": 2100.1384444444448,
              "speed2d": 10.110777777777779,
              "speed3d": 10.17,
              "msFromStart": 220233.82637037034
            },
            {
              "lat": 43.77377546666667,
              "lng": -116.09928291111112,
              "alt": 2100.1767777777777,
              "speed2d": 9.691333333333333,
              "speed3d": 9.743333333333332,
              "msFromStart": 220730.28355555565
            },
            {
              "lat": 43.773726933333336,
              "lng": -116.09930027777777,
              "alt": 2099.0055555555555,
              "speed2d": 10.118,
              "speed3d": 10.105555555555554,
              "msFromStart": 221226.62518518532
            },
            {
              "lat": 43.77367452000001,
              "lng": -116.09931934,
              "alt": 2098.078,
              "speed2d": 10.472800000000001,
              "speed3d": 10.491999999999999,
              "msFromStart": 221748.85733333335
            },
            {
              "lat": 43.773625311111104,
              "lng": -116.09933586666666,
              "alt": 2097.589111111111,
              "speed2d": 10.368555555555554,
              "speed3d": 10.463333333333335,
              "msFromStart": 222270.706042885
            },
            {
              "lat": 43.77358667777778,
              "lng": -116.09934948888889,
              "alt": 2097.2595555555554,
              "speed2d": 9.726222222222223,
              "speed3d": 9.833333333333334,
              "msFromStart": 222767.03073684205
            },
            {
              "lat": 43.77354697777778,
              "lng": -116.09936400000001,
              "alt": 2096.857555555556,
              "speed2d": 9.447666666666667,
              "speed3d": 9.545555555555556,
              "msFromStart": 223263.75447953213
            },
            {
              "lat": 43.77351212222223,
              "lng": -116.09937671111112,
              "alt": 2096.286111111111,
              "speed2d": 8.933111111111112,
              "speed3d": 9.014444444444445,
              "msFromStart": 223758.88533333328
            },
            {
              "lat": 43.77347490000001,
              "lng": -116.09939127777777,
              "alt": 2096.331,
              "speed2d": 8.79577777777778,
              "speed3d": 8.841111111111113,
              "msFromStart": 224253.4183703703
            },
            {
              "lat": 43.77343597777777,
              "lng": -116.09940785555554,
              "alt": 2096.1085555555555,
              "speed2d": 8.82611111111111,
              "speed3d": 8.886666666666667,
              "msFromStart": 224747.7431111111
            },
            {
              "lat": 43.77339825555556,
              "lng": -116.09942828888889,
              "alt": 2095.923444444444,
              "speed2d": 8.864666666666666,
              "speed3d": 8.856666666666666,
              "msFromStart": 225242.0562962963
            },
            {
              "lat": 43.77335386666667,
              "lng": -116.09945091111112,
              "alt": 2095.9334444444444,
              "speed2d": 9.356222222222222,
              "speed3d": 9.363333333333333,
              "msFromStart": 225737.60799999995
            },
            {
              "lat": 43.77331174444445,
              "lng": -116.0994728111111,
              "alt": 2096.645555555556,
              "speed2d": 9.460111111111111,
              "speed3d": 9.486666666666668,
              "msFromStart": 226233.55703703698
            },
            {
              "lat": 43.77327176666667,
              "lng": -116.09949345555556,
              "alt": 2096.809555555556,
              "speed2d": 9.371333333333334,
              "speed3d": 9.416666666666668,
              "msFromStart": 226728.38577777782
            },
            {
              "lat": 43.77323176,
              "lng": -116.09951398000001,
              "alt": 2096.8225,
              "speed2d": 9.1246,
              "speed3d": 9.144999999999998,
              "msFromStart": 227250.33630877198
            },
            {
              "lat": 43.77318752222222,
              "lng": -116.09953762222223,
              "alt": 2097.2745555555557,
              "speed2d": 9.345555555555556,
              "speed3d": 9.334444444444445,
              "msFromStart": 227773.04589473683
            },
            {
              "lat": 43.77314362222222,
              "lng": -116.09956054444446,
              "alt": 2097.754,
              "speed2d": 9.655666666666667,
              "speed3d": 9.646666666666668,
              "msFromStart": 228268.42131773883
            },
            {
              "lat": 43.77310356666666,
              "lng": -116.09958335555555,
              "alt": 2098.1484444444445,
              "speed2d": 9.512666666666668,
              "speed3d": 9.53888888888889,
              "msFromStart": 228762.9377777778
            },
            {
              "lat": 43.77306652222222,
              "lng": -116.09960464444444,
              "alt": 2098.557,
              "speed2d": 9.124333333333333,
              "speed3d": 9.214444444444446,
              "msFromStart": 229257.09303703706
            },
            {
              "lat": 43.773031222222215,
              "lng": -116.09962354444444,
              "alt": 2099.255888888889,
              "speed2d": 8.718111111111112,
              "speed3d": 8.735555555555557,
              "msFromStart": 229750.39822222217
            },
            {
              "lat": 43.77299868888889,
              "lng": -116.0996400111111,
              "alt": 2099.781222222222,
              "speed2d": 8.267222222222223,
              "speed3d": 8.318888888888889,
              "msFromStart": 230243.50755555546
            },
            {
              "lat": 43.77295681111111,
              "lng": -116.09965432222222,
              "alt": 2096.5706666666665,
              "speed2d": 8.306888888888889,
              "speed3d": 8.28111111111111,
              "msFromStart": 230738.84622222226
            },
            {
              "lat": 43.77291056666667,
              "lng": -116.09966866666669,
              "alt": 2092.6620000000003,
              "speed2d": 8.449666666666667,
              "speed3d": 8.484444444444446,
              "msFromStart": 231234.98162962968
            },
            {
              "lat": 43.77288202222222,
              "lng": -116.09968324444445,
              "alt": 2092.0374444444446,
              "speed2d": 7.774333333333333,
              "speed3d": 7.8822222222222225,
              "msFromStart": 231730.64977777775
            },
            {
              "lat": 43.77285457777777,
              "lng": -116.09969950000001,
              "alt": 2091.5632222222225,
              "speed2d": 7.432444444444444,
              "speed3d": 7.449999999999999,
              "msFromStart": 232226.10284600392
            },
            {
              "lat": 43.772819979999994,
              "lng": -116.09971915999999,
              "alt": 2090.7814,
              "speed2d": 7.5642000000000005,
              "speed3d": 7.609,
              "msFromStart": 232748.20336842095
            },
            {
              "lat": 43.77278873333333,
              "lng": -116.09973723333333,
              "alt": 2090.8450000000003,
              "speed2d": 7.250111111111111,
              "speed3d": 7.327777777777778,
              "msFromStart": 233270.0288187133
            },
            {
              "lat": 43.772759444444446,
              "lng": -116.09975403333333,
              "alt": 2090.7733333333335,
              "speed2d": 7.034,
              "speed3d": 7.09888888888889,
              "msFromStart": 233764.87466666667
            },
            {
              "lat": 43.77273236666667,
              "lng": -116.09976966666667,
              "alt": 2090.603888888889,
              "speed2d": 6.71,
              "speed3d": 6.767777777777777,
              "msFromStart": 234259.90933333331
            },
            {
              "lat": 43.77270195555556,
              "lng": -116.09978541111113,
              "alt": 2090.1933333333336,
              "speed2d": 6.854555555555555,
              "speed3d": 6.8788888888888895,
              "msFromStart": 234755.14666666673
            },
            {
              "lat": 43.77267091111111,
              "lng": -116.09980274444445,
              "alt": 2090.2762222222223,
              "speed2d": 6.991555555555555,
              "speed3d": 7.014444444444443,
              "msFromStart": 235250.45925925934
            },
            {
              "lat": 43.77263874444445,
              "lng": -116.09982134444444,
              "alt": 2090.709888888889,
              "speed2d": 7.214555555555556,
              "speed3d": 7.21,
              "msFromStart": 235745.7831111111
            },
            {
              "lat": 43.77260554444445,
              "lng": -116.09984216666666,
              "alt": 2091.0788888888887,
              "speed2d": 7.501666666666666,
              "speed3d": 7.503333333333334,
              "msFromStart": 236241.10724366468
            },
            {
              "lat": 43.772571033333335,
              "lng": -116.09986406666667,
              "alt": 2091.619222222222,
              "speed2d": 7.725333333333334,
              "speed3d": 7.742222222222223,
              "msFromStart": 236736.35789473678
            },
            {
              "lat": 43.772535633333334,
              "lng": -116.09988763333334,
              "alt": 2092.022,
              "speed2d": 8.011666666666667,
              "speed3d": 7.978888888888889,
              "msFromStart": 237231.5750591292
            },
            {
              "lat": 43.77249625555556,
              "lng": -116.09991474444445,
              "alt": 2092.6037777777774,
              "speed2d": 8.610222222222223,
              "speed3d": 8.588888888888889,
              "msFromStart": 237726.45866666673
            },
            {
              "lat": 43.77245803,
              "lng": -116.09994554000001,
              "alt": 2093.1391999999996,
              "speed2d": 8.7505,
              "speed3d": 8.736,
              "msFromStart": 238248.66702222233
            },
            {
              "lat": 43.77242182222221,
              "lng": -116.09997807777776,
              "alt": 2092.6496666666662,
              "speed2d": 8.870333333333335,
              "speed3d": 8.89,
              "msFromStart": 238771.3191111111
            },
            {
              "lat": 43.77238844444444,
              "lng": -116.10001027777777,
              "alt": 2091.8144444444442,
              "speed2d": 9.040111111111111,
              "speed3d": 9.02888888888889,
              "msFromStart": 239266.59644444444
            },
            {
              "lat": 43.77235653333334,
              "lng": -116.10004336666668,
              "alt": 2090.640111111111,
              "speed2d": 9.204,
              "speed3d": 9.23,
              "msFromStart": 239760.91111111105
            }
          ]
        },
        {
          "videoId": "GX010085",
          "pathId": "trail-lodge-cat-track",
          "startSec": 305,
          "endSec": 400,
          "id": "video-segment-52",
          "gps": [
            {
              "lat": 43.77421537000001,
              "lng": -116.08934983,
              "alt": 2177.4402,
              "speed2d": 4.2247,
              "speed3d": 4.906,
              "msFromStart": 305251.6663111114
            },
            {
              "lat": 43.77423156666667,
              "lng": -116.08933506666668,
              "alt": 2175.1382222222223,
              "speed2d": 4.3262222222222215,
              "speed3d": 5.062222222222222,
              "msFromStart": 305773.54666666687
            },
            {
              "lat": 43.77424578888889,
              "lng": -116.08932251111109,
              "alt": 2172.4848888888887,
              "speed2d": 4.221666666666667,
              "speed3d": 5.131111111111112,
              "msFromStart": 306267.68434567936
            },
            {
              "lat": 43.77425524444444,
              "lng": -116.0893233111111,
              "alt": 2169.769333333333,
              "speed2d": 3.2705555555555557,
              "speed3d": 4.51,
              "msFromStart": 306762.32533333334
            },
            {
              "lat": 43.77426033333333,
              "lng": -116.08933477777778,
              "alt": 2166.953888888889,
              "speed2d": 2.734555555555555,
              "speed3d": 4.16,
              "msFromStart": 307257.2491851853
            },
            {
              "lat": 43.77426869999999,
              "lng": -116.08934918888889,
              "alt": 2165.0714444444448,
              "speed2d": 3.1068888888888884,
              "speed3d": 4.407777777777778,
              "msFromStart": 307752.78933333314
            },
            {
              "lat": 43.77428016666667,
              "lng": -116.08936466666667,
              "alt": 2164.282666666667,
              "speed2d": 3.7421111111111114,
              "speed3d": 4.802222222222222,
              "msFromStart": 308248.64471994765
            },
            {
              "lat": 43.77429412222222,
              "lng": -116.08938133333334,
              "alt": 2164.2021111111108,
              "speed2d": 4.294444444444443,
              "speed3d": 5.1499999999999995,
              "msFromStart": 308743.6362105264
            },
            {
              "lat": 43.77430708888888,
              "lng": -116.0893992,
              "alt": 2164.3805555555555,
              "speed2d": 4.591555555555555,
              "speed3d": 5.304444444444444,
              "msFromStart": 309238.162526316
            },
            {
              "lat": 43.774320133333326,
              "lng": -116.08942067777778,
              "alt": 2163.868,
              "speed2d": 4.884222222222222,
              "speed3d": 5.508888888888889,
              "msFromStart": 309733.0808888889
            },
            {
              "lat": 43.77433407777777,
              "lng": -116.08944074444446,
              "alt": 2162.7526666666668,
              "speed2d": 4.876666666666667,
              "speed3d": 5.510000000000001,
              "msFromStart": 310228.3128888889
            },
            {
              "lat": 43.77434728,
              "lng": -116.08946521,
              "alt": 2161.8322000000003,
              "speed2d": 4.991,
              "speed3d": 5.582,
              "msFromStart": 310751.06755555567
            },
            {
              "lat": 43.774362122222215,
              "lng": -116.08949014444444,
              "alt": 2160.6126666666664,
              "speed2d": 5.087555555555555,
              "speed3d": 5.628888888888889,
              "msFromStart": 311273.8145185187
            },
            {
              "lat": 43.77437688888888,
              "lng": -116.08951059999998,
              "alt": 2158.9553333333333,
              "speed2d": 4.943666666666666,
              "speed3d": 5.582222222222223,
              "msFromStart": 311768.2773333333
            },
            {
              "lat": 43.774390866666664,
              "lng": -116.0895244,
              "alt": 2157.404555555556,
              "speed2d": 4.495333333333333,
              "speed3d": 5.1722222222222225,
              "msFromStart": 312262.33777777763
            },
            {
              "lat": 43.77440444444444,
              "lng": -116.08954611111112,
              "alt": 2156.0778888888885,
              "speed2d": 4.5600000000000005,
              "speed3d": 5.153333333333333,
              "msFromStart": 312757.0453333334
            },
            {
              "lat": 43.77441594444444,
              "lng": -116.08957478888888,
              "alt": 2155.334111111111,
              "speed2d": 4.861555555555555,
              "speed3d": 5.42,
              "msFromStart": 313252.1179987006
            },
            {
              "lat": 43.77442643333333,
              "lng": -116.08960280000001,
              "alt": 2154.4876666666664,
              "speed2d": 4.941000000000001,
              "speed3d": 5.475555555555556,
              "msFromStart": 313748.0572631578
            },
            {
              "lat": 43.77443605555556,
              "lng": -116.08963385555555,
              "alt": 2153.9932222222224,
              "speed2d": 5.257333333333333,
              "speed3d": 5.697777777777777,
              "msFromStart": 314244.4631578945
            },
            {
              "lat": 43.774448066666665,
              "lng": -116.08966264444445,
              "alt": 2153.584111111111,
              "speed2d": 5.291777777777778,
              "speed3d": 5.772222222222222,
              "msFromStart": 314739.7724444444
            },
            {
              "lat": 43.77445841111111,
              "lng": -116.08968754444444,
              "alt": 2152.466777777778,
              "speed2d": 5.0008888888888885,
              "speed3d": 5.488888888888889,
              "msFromStart": 315234.2044444444
            },
            {
              "lat": 43.77447107777779,
              "lng": -116.08970472222222,
              "alt": 2150.501444444444,
              "speed2d": 4.603777777777777,
              "speed3d": 5.183333333333334,
              "msFromStart": 315728.7608888888
            },
            {
              "lat": 43.77448366,
              "lng": -116.08972391999998,
              "alt": 2148.2304000000004,
              "speed2d": 4.5003,
              "speed3d": 5.130000000000001,
              "msFromStart": 316250.89422222204
            },
            {
              "lat": 43.774498400000006,
              "lng": -116.08974633333335,
              "alt": 2146.8486666666668,
              "speed2d": 4.664555555555555,
              "speed3d": 5.266666666666666,
              "msFromStart": 316772.8213333334
            },
            {
              "lat": 43.77450948888888,
              "lng": -116.08977027777776,
              "alt": 2146.1285555555555,
              "speed2d": 4.676111111111111,
              "speed3d": 5.275555555555555,
              "msFromStart": 317267.1617413905
            },
            {
              "lat": 43.774520566666666,
              "lng": -116.08979842222222,
              "alt": 2145.5555555555557,
              "speed2d": 4.8821111111111115,
              "speed3d": 5.395555555555555,
              "msFromStart": 317761.7313684209
            },
            {
              "lat": 43.77453052222223,
              "lng": -116.0898228111111,
              "alt": 2145.2147777777777,
              "speed2d": 4.629222222222222,
              "speed3d": 5.246666666666667,
              "msFromStart": 318256.4244210522
            },
            {
              "lat": 43.774538155555554,
              "lng": -116.08984442222223,
              "alt": 2145.2381111111113,
              "speed2d": 4.095666666666666,
              "speed3d": 4.671111111111111,
              "msFromStart": 318751.92355555564
            },
            {
              "lat": 43.774547644444446,
              "lng": -116.08986562222222,
              "alt": 2144.8406666666665,
              "speed2d": 3.872444444444444,
              "speed3d": 4.457777777777778,
              "msFromStart": 319248.0675555557
            },
            {
              "lat": 43.7745578,
              "lng": -116.08988723333331,
              "alt": 2144.5403333333334,
              "speed2d": 3.8561111111111117,
              "speed3d": 4.377777777777777,
              "msFromStart": 319743.4648888888
            },
            {
              "lat": 43.77456701111111,
              "lng": -116.08990803333333,
              "alt": 2144.208888888889,
              "speed2d": 3.7653333333333334,
              "speed3d": 4.288888888888889,
              "msFromStart": 320238.2648888887
            },
            {
              "lat": 43.77457643333333,
              "lng": -116.08992756666669,
              "alt": 2144.153333333333,
              "speed2d": 3.609555555555556,
              "speed3d": 4.137777777777778,
              "msFromStart": 320733.51822222216
            },
            {
              "lat": 43.774583522222215,
              "lng": -116.08994686666668,
              "alt": 2144.3325555555552,
              "speed2d": 3.302777777777778,
              "speed3d": 3.8277777777777775,
              "msFromStart": 321229.1342222222
            },
            {
              "lat": 43.77459285,
              "lng": -116.08996192,
              "alt": 2145.0679,
              "speed2d": 2.6065,
              "speed3d": 3.179,
              "msFromStart": 321751.84444444434
            },
            {
              "lat": 43.77460206666667,
              "lng": -116.08997755555555,
              "alt": 2147.481444444444,
              "speed2d": 2.1605555555555553,
              "speed3d": 2.591111111111111,
              "msFromStart": 322274.2248628978
            },
            {
              "lat": 43.7746084111111,
              "lng": -116.08999184444444,
              "alt": 2149.1185555555558,
              "speed2d": 1.8495555555555554,
              "speed3d": 2.2222222222222223,
              "msFromStart": 322768.60126315785
            },
            {
              "lat": 43.77461389999999,
              "lng": -116.09000375555554,
              "alt": 2150.2309999999998,
              "speed2d": 1.660111111111111,
              "speed3d": 1.9644444444444442,
              "msFromStart": 323262.70315789466
            },
            {
              "lat": 43.774623166666665,
              "lng": -116.09000972222222,
              "alt": 2151.762333333333,
              "speed2d": 1.404,
              "speed3d": 1.7088888888888887,
              "msFromStart": 323757.5395555556
            },
            {
              "lat": 43.77463156666667,
              "lng": -116.09001415555556,
              "alt": 2153.236111111111,
              "speed2d": 1.160111111111111,
              "speed3d": 1.432222222222222,
              "msFromStart": 324252.9635555556
            },
            {
              "lat": 43.7746388,
              "lng": -116.09001535555555,
              "alt": 2154.166444444444,
              "speed2d": 0.8306666666666667,
              "speed3d": 1.18,
              "msFromStart": 324748.672
            },
            {
              "lat": 43.77464508888889,
              "lng": -116.09001950000001,
              "alt": 2155.2344444444443,
              "speed2d": 0.7509999999999999,
              "speed3d": 1.041111111111111,
              "msFromStart": 325244.60799999995
            },
            {
              "lat": 43.77465184444444,
              "lng": -116.09002426666666,
              "alt": 2156.310111111111,
              "speed2d": 0.8215555555555556,
              "speed3d": 1.0299999999999998,
              "msFromStart": 325739.9128888888
            },
            {
              "lat": 43.77465437777778,
              "lng": -116.09003187777778,
              "alt": 2156.9245555555553,
              "speed2d": 1.0498888888888889,
              "speed3d": 1.2077777777777778,
              "msFromStart": 326234.71288888867
            },
            {
              "lat": 43.774659344444444,
              "lng": -116.0900392,
              "alt": 2158.048,
              "speed2d": 1.0212222222222223,
              "speed3d": 1.1833333333333333,
              "msFromStart": 326729.6639999999
            },
            {
              "lat": 43.77466649,
              "lng": -116.09004617,
              "alt": 2159.4179,
              "speed2d": 0.9587,
              "speed3d": 1.036,
              "msFromStart": 327252.23946666636
            },
            {
              "lat": 43.7746703,
              "lng": -116.09005862222223,
              "alt": 2160.4759999999997,
              "speed2d": 1.3856666666666668,
              "speed3d": 1.3688888888888888,
              "msFromStart": 327774.78399999987
            },
            {
              "lat": 43.77467033333333,
              "lng": -116.09007109999999,
              "alt": 2160.8933333333334,
              "speed2d": 1.5513333333333332,
              "speed3d": 1.5811111111111111,
              "msFromStart": 328269.79249902506
            },
            {
              "lat": 43.77466865555556,
              "lng": -116.09008511111111,
              "alt": 2161.0316666666668,
              "speed2d": 1.792,
              "speed3d": 1.791111111111111,
              "msFromStart": 328763.9949473685
            },
            {
              "lat": 43.774667988888886,
              "lng": -116.09009743333334,
              "alt": 2161.360333333333,
              "speed2d": 1.680222222222222,
              "speed3d": 1.73,
              "msFromStart": 329257.76336842123
            },
            {
              "lat": 43.774669966666664,
              "lng": -116.09010582222223,
              "alt": 2162.417111111111,
              "speed2d": 1.3231111111111113,
              "speed3d": 1.3977777777777778,
              "msFromStart": 329752.6648888889
            },
            {
              "lat": 43.774672377777776,
              "lng": -116.09011022222222,
              "alt": 2163.3837777777776,
              "speed2d": 0.9333333333333333,
              "speed3d": 0.9822222222222222,
              "msFromStart": 330248.47288888897
            },
            {
              "lat": 43.77467567777778,
              "lng": -116.0901116,
              "alt": 2164.436777777778,
              "speed2d": 0.5716666666666668,
              "speed3d": 0.6211111111111112,
              "msFromStart": 330744.4142222223
            },
            {
              "lat": 43.77467735555556,
              "lng": -116.09011373333333,
              "alt": 2165.2753333333335,
              "speed2d": 0.5123333333333333,
              "speed3d": 0.5122222222222222,
              "msFromStart": 331240.46222222236
            },
            {
              "lat": 43.77467831111111,
              "lng": -116.09011872222223,
              "alt": 2166.0735555555557,
              "speed2d": 0.602111111111111,
              "speed3d": 0.6266666666666667,
              "msFromStart": 331736.608
            },
            {
              "lat": 43.77468325555556,
              "lng": -116.09011998888889,
              "alt": 2167.4593333333332,
              "speed2d": 0.26611111111111113,
              "speed3d": 0.2911111111111111,
              "msFromStart": 332232.83200000005
            },
            {
              "lat": 43.77468566666667,
              "lng": -116.09012055555557,
              "alt": 2168.421888888889,
              "speed2d": 0.14711111111111114,
              "speed3d": 0.16,
              "msFromStart": 332727.0026666665
            },
            {
              "lat": 43.77468644,
              "lng": -116.09011785999999,
              "alt": 2169.1371999999997,
              "speed2d": 0.2851,
              "speed3d": 0.291,
              "msFromStart": 333246.92126315756
            },
            {
              "lat": 43.77468752222222,
              "lng": -116.09011154444445,
              "alt": 2169.4371111111113,
              "speed2d": 0.4371111111111112,
              "speed3d": 0.4077777777777778,
              "msFromStart": 333768.45978947356
            },
            {
              "lat": 43.77468867777778,
              "lng": -116.09010863333333,
              "alt": 2169.746888888889,
              "speed2d": 0.4234444444444444,
              "speed3d": 0.5011111111111111,
              "msFromStart": 334263.50147368386
            },
            {
              "lat": 43.77468992222222,
              "lng": -116.09011364444446,
              "alt": 2170.5164444444445,
              "speed2d": 0.1592222222222222,
              "speed3d": 0.21,
              "msFromStart": 334758.9066666666
            },
            {
              "lat": 43.774689755555556,
              "lng": -116.09011287777778,
              "alt": 2170.780444444444,
              "speed2d": 0.20044444444444443,
              "speed3d": 0.20111111111111113,
              "msFromStart": 335254.6026666665
            },
            {
              "lat": 43.77469148888889,
              "lng": -116.09011226666665,
              "alt": 2171.5066666666667,
              "speed2d": 0.2776666666666667,
              "speed3d": 0.3555555555555555,
              "msFromStart": 335750.2186666666
            },
            {
              "lat": 43.774691377777785,
              "lng": -116.09011673333333,
              "alt": 2172.2093333333332,
              "speed2d": 0.17188888888888887,
              "speed3d": 0.21666666666666667,
              "msFromStart": 336245.77066666645
            },
            {
              "lat": 43.774694644444445,
              "lng": -116.0901198,
              "alt": 2173.2575555555554,
              "speed2d": 0.3428888888888889,
              "speed3d": 0.40444444444444444,
              "msFromStart": 336741.3937777779
            },
            {
              "lat": 43.77469532222222,
              "lng": -116.09012134444441,
              "alt": 2173.8574444444444,
              "speed2d": 0.19866666666666666,
              "speed3d": 0.3422222222222222,
              "msFromStart": 337237.0737777781
            },
            {
              "lat": 43.7746968,
              "lng": -116.09011824444445,
              "alt": 2174.253888888889,
              "speed2d": 0.42444444444444446,
              "speed3d": 0.4744444444444444,
              "msFromStart": 337731.9360000001
            },
            {
              "lat": 43.77469617777777,
              "lng": -116.09011509999999,
              "alt": 2174.2787777777776,
              "speed2d": 0.4718888888888889,
              "speed3d": 0.5455555555555556,
              "msFromStart": 338226.14400000015
            },
            {
              "lat": 43.77469438,
              "lng": -116.09011779000001,
              "alt": 2174.547,
              "speed2d": 0.20179999999999998,
              "speed3d": 0.31300000000000006,
              "msFromStart": 338747.900631579
            },
            {
              "lat": 43.77469367777778,
              "lng": -116.09012047777779,
              "alt": 2174.569111111111,
              "speed2d": 0.1708888888888889,
              "speed3d": 0.2388888888888889,
              "msFromStart": 339269.72463157895
            },
            {
              "lat": 43.77469456666666,
              "lng": -116.09012235555555,
              "alt": 2174.7495555555556,
              "speed2d": 0.3158888888888889,
              "speed3d": 0.3155555555555556,
              "msFromStart": 339764.76444444456
            },
            {
              "lat": 43.77469516666666,
              "lng": -116.09013411111113,
              "alt": 2175.0603333333333,
              "speed2d": 1.156111111111111,
              "speed3d": 1.0877777777777777,
              "msFromStart": 340260.3484444447
            },
            {
              "lat": 43.77469590000001,
              "lng": -116.09014616666667,
              "alt": 2175.5003333333334,
              "speed2d": 1.3372222222222223,
              "speed3d": 1.3344444444444443,
              "msFromStart": 340755.36355555546
            },
            {
              "lat": 43.774697222222215,
              "lng": -116.09015763333332,
              "alt": 2175.7672222222222,
              "speed2d": 1.5557777777777777,
              "speed3d": 1.5122222222222221,
              "msFromStart": 341249.9235555553
            },
            {
              "lat": 43.774699399999996,
              "lng": -116.09017088888888,
              "alt": 2176.005777777778,
              "speed2d": 1.915777777777778,
              "speed3d": 1.9188888888888889,
              "msFromStart": 341744.9368888888
            },
            {
              "lat": 43.77470195555555,
              "lng": -116.09018216666666,
              "alt": 2176.1185555555558,
              "speed2d": 1.918111111111111,
              "speed3d": 1.9133333333333336,
              "msFromStart": 342240.3128888889
            },
            {
              "lat": 43.77470477777778,
              "lng": -116.09019618888888,
              "alt": 2176.1102222222225,
              "speed2d": 2.302,
              "speed3d": 2.2688888888888887,
              "msFromStart": 342736.08888888895
            },
            {
              "lat": 43.77470686666667,
              "lng": -116.0902120111111,
              "alt": 2176.2483333333334,
              "speed2d": 2.3845555555555555,
              "speed3d": 2.3677777777777775,
              "msFromStart": 343232.1848888891
            },
            {
              "lat": 43.77470931111111,
              "lng": -116.0902313,
              "alt": 2176.2365555555552,
              "speed2d": 2.780111111111111,
              "speed3d": 2.7355555555555555,
              "msFromStart": 343727.829894737
            },
            {
              "lat": 43.77471183,
              "lng": -116.09025118000001,
              "alt": 2176.378,
              "speed2d": 2.8388,
              "speed3d": 2.8480000000000003,
              "msFromStart": 344250.62989473704
            },
            {
              "lat": 43.77471432222222,
              "lng": -116.09027162222223,
              "alt": 2176.3908888888886,
              "speed2d": 2.9015555555555554,
              "speed3d": 2.9099999999999997,
              "msFromStart": 344773.07200000004
            },
            {
              "lat": 43.77471788888889,
              "lng": -116.0902864,
              "alt": 2176.599,
              "speed2d": 2.5796666666666668,
              "speed3d": 2.6033333333333335,
              "msFromStart": 345267.7120000002
            },
            {
              "lat": 43.77472287777778,
              "lng": -116.09030044444445,
              "alt": 2176.793777777778,
              "speed2d": 2.588,
              "speed3d": 2.59,
              "msFromStart": 345762.15644444444
            },
            {
              "lat": 43.774726266666676,
              "lng": -116.090315,
              "alt": 2177.011444444445,
              "speed2d": 2.4221111111111115,
              "speed3d": 2.425555555555556,
              "msFromStart": 346256.44444444444
            },
            {
              "lat": 43.77472915555556,
              "lng": -116.09033393333333,
              "alt": 2177.084111111111,
              "speed2d": 2.657333333333333,
              "speed3d": 2.627777777777778,
              "msFromStart": 346750.9102222222
            },
            {
              "lat": 43.77473205555555,
              "lng": -116.0903524,
              "alt": 2177.163666666667,
              "speed2d": 2.7663333333333338,
              "speed3d": 2.8033333333333337,
              "msFromStart": 347245.51822222205
            },
            {
              "lat": 43.77473523333334,
              "lng": -116.09037044444445,
              "alt": 2177.3465555555554,
              "speed2d": 2.663888888888889,
              "speed3d": 2.6355555555555554,
              "msFromStart": 347740.17955555546
            },
            {
              "lat": 43.774740988888894,
              "lng": -116.09038645555557,
              "alt": 2177.6255555555554,
              "speed2d": 2.8353333333333337,
              "speed3d": 2.8422222222222224,
              "msFromStart": 348234.88355555537
            },
            {
              "lat": 43.7747445,
              "lng": -116.09039981111111,
              "alt": 2177.6067777777776,
              "speed2d": 2.3713333333333337,
              "speed3d": 2.4488888888888893,
              "msFromStart": 348730.3477894738
            },
            {
              "lat": 43.774748522222225,
              "lng": -116.09041327777778,
              "alt": 2177.616555555556,
              "speed2d": 2.308222222222222,
              "speed3d": 2.2866666666666666,
              "msFromStart": 349226.4202105265
            },
            {
              "lat": 43.774751110000004,
              "lng": -116.09042434,
              "alt": 2177.5175,
              "speed2d": 1.9530000000000003,
              "speed3d": 2.0380000000000003,
              "msFromStart": 349749.19999999995
            },
            {
              "lat": 43.774753088888886,
              "lng": -116.09043592222224,
              "alt": 2177.375,
              "speed2d": 1.7403333333333335,
              "speed3d": 1.7155555555555555,
              "msFromStart": 350271.0328888886
            },
            {
              "lat": 43.77475468888889,
              "lng": -116.09045097777779,
              "alt": 2177.4616666666666,
              "speed2d": 1.9748888888888887,
              "speed3d": 1.9866666666666666,
              "msFromStart": 350765.5786666667
            },
            {
              "lat": 43.774757155555555,
              "lng": -116.09046088888891,
              "alt": 2177.6288888888885,
              "speed2d": 1.606222222222222,
              "speed3d": 1.617777777777778,
              "msFromStart": 351260.2666666669
            },
            {
              "lat": 43.77476007777778,
              "lng": -116.09047252222223,
              "alt": 2177.679888888889,
              "speed2d": 1.8288888888888892,
              "speed3d": 1.8322222222222222,
              "msFromStart": 351755.49688888894
            },
            {
              "lat": 43.77476124444445,
              "lng": -116.0904819888889,
              "alt": 2177.7709999999997,
              "speed2d": 1.519,
              "speed3d": 1.5644444444444443,
              "msFromStart": 352251.160888889
            },
            {
              "lat": 43.77476137777778,
              "lng": -116.09049206666667,
              "alt": 2177.6099999999997,
              "speed2d": 1.4852222222222222,
              "speed3d": 1.4744444444444444,
              "msFromStart": 352746.7448888889
            },
            {
              "lat": 43.77476176666667,
              "lng": -116.09050413333333,
              "alt": 2177.647,
              "speed2d": 1.7765555555555557,
              "speed3d": 1.7644444444444445,
              "msFromStart": 353242.2648888889
            },
            {
              "lat": 43.774761955555554,
              "lng": -116.09051241111112,
              "alt": 2177.565888888889,
              "speed2d": 1.3904444444444444,
              "speed3d": 1.4455555555555555,
              "msFromStart": 353737.0138947367
            },
            {
              "lat": 43.77476185555555,
              "lng": -116.09051976666666,
              "alt": 2177.273,
              "speed2d": 1.3071111111111111,
              "speed3d": 1.298888888888889,
              "msFromStart": 354231.1461052629
            },
            {
              "lat": 43.77476165555556,
              "lng": -116.0905252888889,
              "alt": 2177.2136666666665,
              "speed2d": 1.0267777777777778,
              "speed3d": 1.0844444444444443,
              "msFromStart": 354725.64622222225
            },
            {
              "lat": 43.77476093,
              "lng": -116.09052989,
              "alt": 2177.0528000000004,
              "speed2d": 0.69,
              "speed3d": 0.7040000000000002,
              "msFromStart": 355248.10400000005
            },
            {
              "lat": 43.774758788888896,
              "lng": -116.09053758888888,
              "alt": 2176.8444444444444,
              "speed2d": 0.9035555555555557,
              "speed3d": 0.8955555555555554,
              "msFromStart": 355770.79288888886
            },
            {
              "lat": 43.77475568888889,
              "lng": -116.09054358888889,
              "alt": 2176.5073333333335,
              "speed2d": 0.8942222222222223,
              "speed3d": 0.8711111111111112,
              "msFromStart": 356266.16888888885
            },
            {
              "lat": 43.77475026666667,
              "lng": -116.09055541111113,
              "alt": 2176.019,
              "speed2d": 1.263,
              "speed3d": 1.2144444444444444,
              "msFromStart": 356760.6737777777
            },
            {
              "lat": 43.774746488888894,
              "lng": -116.09056932222222,
              "alt": 2175.583777777778,
              "speed2d": 1.5694444444444446,
              "speed3d": 1.568888888888889,
              "msFromStart": 357254.48177777755
            },
            {
              "lat": 43.77474431111111,
              "lng": -116.09057905555555,
              "alt": 2175.044888888889,
              "speed2d": 1.6269999999999998,
              "speed3d": 1.6366666666666667,
              "msFromStart": 357748.6897777777
            },
            {
              "lat": 43.7747449,
              "lng": -116.09059236666667,
              "alt": 2174.492888888889,
              "speed2d": 1.9505555555555552,
              "speed3d": 1.9255555555555557,
              "msFromStart": 358243.2177777777
            },
            {
              "lat": 43.77474921111111,
              "lng": -116.09060795555556,
              "alt": 2174.4751111111113,
              "speed2d": 2.3073333333333332,
              "speed3d": 2.324444444444444,
              "msFromStart": 358738.0732631578
            },
            {
              "lat": 43.77475024444444,
              "lng": -116.09062399999999,
              "alt": 2174.4444444444443,
              "speed2d": 2.376888888888889,
              "speed3d": 2.3777777777777773,
              "msFromStart": 359233.19073684205
            },
            {
              "lat": 43.774749577777776,
              "lng": -116.09063835555555,
              "alt": 2174.0694444444443,
              "speed2d": 2.326888888888889,
              "speed3d": 2.338888888888889,
              "msFromStart": 359729.0204444444
            },
            {
              "lat": 43.77474696666667,
              "lng": -116.09066195555556,
              "alt": 2173.7400000000002,
              "speed2d": 2.881888888888889,
              "speed3d": 2.827777777777778,
              "msFromStart": 360225.7404444441
            },
            {
              "lat": 43.77474631000001,
              "lng": -116.09068934000001,
              "alt": 2173.6958,
              "speed2d": 3.4704,
              "speed3d": 3.442,
              "msFromStart": 360749.008
            },
            {
              "lat": 43.77474484444445,
              "lng": -116.09071542222222,
              "alt": 2173.440777777778,
              "speed2d": 3.6734444444444443,
              "speed3d": 3.6511111111111116,
              "msFromStart": 361271.1111111112
            },
            {
              "lat": 43.77474072222222,
              "lng": -116.09074503333333,
              "alt": 2173.2345555555553,
              "speed2d": 4.146222222222222,
              "speed3d": 4.1033333333333335,
              "msFromStart": 361766.4817777777
            },
            {
              "lat": 43.774738444444445,
              "lng": -116.09077242222222,
              "alt": 2172.8966666666665,
              "speed2d": 4.287444444444445,
              "speed3d": 4.28888888888889,
              "msFromStart": 362262.44977777754
            },
            {
              "lat": 43.77473612222222,
              "lng": -116.09080004444444,
              "alt": 2172.911555555556,
              "speed2d": 4.222333333333333,
              "speed3d": 4.268888888888888,
              "msFromStart": 362757.49333333346
            },
            {
              "lat": 43.77473397777778,
              "lng": -116.09082411111112,
              "alt": 2172.374888888889,
              "speed2d": 3.9861111111111107,
              "speed3d": 3.9911111111111115,
              "msFromStart": 363251.7973333335
            },
            {
              "lat": 43.774730633333334,
              "lng": -116.09085540000001,
              "alt": 2172.1202222222223,
              "speed2d": 4.322444444444445,
              "speed3d": 4.278888888888889,
              "msFromStart": 363746.3343157893
            },
            {
              "lat": 43.77472586666667,
              "lng": -116.09089321111108,
              "alt": 2171.9457777777775,
              "speed2d": 4.713,
              "speed3d": 4.705555555555555,
              "msFromStart": 364241.05768421007
            },
            {
              "lat": 43.77472238888888,
              "lng": -116.09092672222224,
              "alt": 2171.9666666666667,
              "speed2d": 4.845000000000001,
              "speed3d": 4.858888888888888,
              "msFromStart": 364736.2631111112
            },
            {
              "lat": 43.774718755555554,
              "lng": -116.09095787777778,
              "alt": 2171.8407777777775,
              "speed2d": 4.782333333333333,
              "speed3d": 4.801111111111111,
              "msFromStart": 365232.07111111126
            },
            {
              "lat": 43.77471468888889,
              "lng": -116.09098830000002,
              "alt": 2171.847333333333,
              "speed2d": 4.812777777777777,
              "speed3d": 4.821111111111112,
              "msFromStart": 365727.52355555556
            },
            {
              "lat": 43.774707340000006,
              "lng": -116.09102217,
              "alt": 2172.0094,
              "speed2d": 4.886500000000001,
              "speed3d": 4.905000000000001,
              "msFromStart": 366250.0320000001
            },
            {
              "lat": 43.774703144444445,
              "lng": -116.0910532,
              "alt": 2172.119111111111,
              "speed2d": 4.671333333333333,
              "speed3d": 4.7,
              "msFromStart": 366773.2426666667
            },
            {
              "lat": 43.77469861111112,
              "lng": -116.09108554444445,
              "alt": 2172.135333333333,
              "speed2d": 4.854555555555556,
              "speed3d": 4.824444444444444,
              "msFromStart": 367269.5146666667
            },
            {
              "lat": 43.77469602222222,
              "lng": -116.09111502222223,
              "alt": 2171.470888888889,
              "speed2d": 4.886777777777777,
              "speed3d": 4.902222222222222,
              "msFromStart": 367764.1422222223
            },
            {
              "lat": 43.774696355555555,
              "lng": -116.09114221111112,
              "alt": 2170.9391111111113,
              "speed2d": 4.8212222222222225,
              "speed3d": 4.848888888888888,
              "msFromStart": 368257.4542222223
            },
            {
              "lat": 43.77469933333333,
              "lng": -116.09116863333333,
              "alt": 2170.681111111111,
              "speed2d": 4.765888888888889,
              "speed3d": 4.775555555555555,
              "msFromStart": 368751.8871578947
            },
            {
              "lat": 43.77470103333333,
              "lng": -116.0911958111111,
              "alt": 2170.310111111111,
              "speed2d": 4.6721111111111115,
              "speed3d": 4.724444444444445,
              "msFromStart": 369247.21684210503
            },
            {
              "lat": 43.77470143333334,
              "lng": -116.0912235,
              "alt": 2170.161666666667,
              "speed2d": 4.570888888888889,
              "speed3d": 4.58,
              "msFromStart": 369742.4106666665
            },
            {
              "lat": 43.774702166666664,
              "lng": -116.09125288888887,
              "alt": 2170.197111111111,
              "speed2d": 4.665444444444445,
              "speed3d": 4.682222222222223,
              "msFromStart": 370237.43466666626
            },
            {
              "lat": 43.77470216666666,
              "lng": -116.09128220000001,
              "alt": 2170.2432222222224,
              "speed2d": 4.714888888888889,
              "speed3d": 4.724444444444444,
              "msFromStart": 370732.16000000003
            },
            {
              "lat": 43.77470311111111,
              "lng": -116.0913099,
              "alt": 2170.2836666666667,
              "speed2d": 4.573777777777778,
              "speed3d": 4.636666666666667,
              "msFromStart": 371226.5120000002
            },
            {
              "lat": 43.77470601,
              "lng": -116.09133676000002,
              "alt": 2170.4590000000003,
              "speed2d": 4.356,
              "speed3d": 4.3420000000000005,
              "msFromStart": 371749.42399999994
            },
            {
              "lat": 43.774707866666674,
              "lng": -116.09136573333332,
              "alt": 2170.5288888888886,
              "speed2d": 4.529111111111111,
              "speed3d": 4.555555555555555,
              "msFromStart": 372273.5537777776
            },
            {
              "lat": 43.774710544444446,
              "lng": -116.09139053333334,
              "alt": 2170.5723333333335,
              "speed2d": 4.261444444444445,
              "speed3d": 4.3,
              "msFromStart": 372769.3422222221
            },
            {
              "lat": 43.774713322222226,
              "lng": -116.09141345555555,
              "alt": 2170.3198888888887,
              "speed2d": 3.9954444444444444,
              "speed3d": 4.011111111111111,
              "msFromStart": 373264.52622222214
            },
            {
              "lat": 43.77471766666666,
              "lng": -116.0914351,
              "alt": 2170.475888888889,
              "speed2d": 3.7697777777777772,
              "speed3d": 3.8133333333333335,
              "msFromStart": 373759.39536842116
            },
            {
              "lat": 43.77471855555556,
              "lng": -116.0914588,
              "alt": 2170.1167777777778,
              "speed2d": 3.7250000000000005,
              "speed3d": 3.7233333333333336,
              "msFromStart": 374254.0126315791
            },
            {
              "lat": 43.774718511111104,
              "lng": -116.09148325555556,
              "alt": 2169.785111111111,
              "speed2d": 3.736888888888889,
              "speed3d": 3.7622222222222224,
              "msFromStart": 374748.647111111
            },
            {
              "lat": 43.774720233333326,
              "lng": -116.09150555555556,
              "alt": 2170.0315555555558,
              "speed2d": 3.552333333333334,
              "speed3d": 3.5788888888888892,
              "msFromStart": 375243.3031111108
            },
            {
              "lat": 43.77471961111111,
              "lng": -116.09152922222222,
              "alt": 2170.048,
              "speed2d": 3.655888888888889,
              "speed3d": 3.646666666666667,
              "msFromStart": 375738.0586666667
            },
            {
              "lat": 43.77471785555556,
              "lng": -116.09155248888891,
              "alt": 2170.0866666666666,
              "speed2d": 3.658444444444445,
              "speed3d": 3.681111111111111,
              "msFromStart": 376232.9386666669
            },
            {
              "lat": 43.77471853333334,
              "lng": -116.09157255555556,
              "alt": 2169.795222222222,
              "speed2d": 3.5526666666666666,
              "speed3d": 3.552222222222222,
              "msFromStart": 376728.4444444445
            },
            {
              "lat": 43.7747187,
              "lng": -116.09159342222222,
              "alt": 2169.653333333333,
              "speed2d": 3.548333333333334,
              "speed3d": 3.581111111111111,
              "msFromStart": 377224.7324444447
            },
            {
              "lat": 43.77472253,
              "lng": -116.09161113,
              "alt": 2169.5252,
              "speed2d": 3.2228999999999997,
              "speed3d": 3.255,
              "msFromStart": 377747.6799999999
            },
            {
              "lat": 43.77472427777778,
              "lng": -116.09162733333332,
              "alt": 2168.634222222222,
              "speed2d": 2.925222222222222,
              "speed3d": 2.98,
              "msFromStart": 378269.6142222219
            },
            {
              "lat": 43.77472681111111,
              "lng": -116.09164156666667,
              "alt": 2168.2710000000006,
              "speed2d": 2.648111111111111,
              "speed3d": 2.711111111111111,
              "msFromStart": 378764.4871111111
            },
            {
              "lat": 43.77472651111111,
              "lng": -116.09165698888887,
              "alt": 2168.161,
              "speed2d": 2.4897777777777783,
              "speed3d": 2.511111111111111,
              "msFromStart": 379259.68711111124
            },
            {
              "lat": 43.77472733333334,
              "lng": -116.0916709888889,
              "alt": 2168.046555555555,
              "speed2d": 2.3125555555555555,
              "speed3d": 2.352222222222222,
              "msFromStart": 379754.64757894736
            },
            {
              "lat": 43.774727177777784,
              "lng": -116.09168493333334,
              "alt": 2167.988888888889,
              "speed2d": 2.274888888888889,
              "speed3d": 2.2755555555555556,
              "msFromStart": 380249.4164210526
            },
            {
              "lat": 43.77472593333333,
              "lng": -116.09170048888889,
              "alt": 2167.9224444444444,
              "speed2d": 2.343111111111111,
              "speed3d": 2.3699999999999997,
              "msFromStart": 380744.0497777777
            },
            {
              "lat": 43.77472608888889,
              "lng": -116.09171416666665,
              "alt": 2167.493111111111,
              "speed2d": 2.304222222222222,
              "speed3d": 2.3299999999999996,
              "msFromStart": 381238.5137777775
            },
            {
              "lat": 43.77472635555556,
              "lng": -116.09172695555556,
              "alt": 2167.0256666666664,
              "speed2d": 2.2607777777777773,
              "speed3d": 2.298888888888889,
              "msFromStart": 381733.5253333333
            },
            {
              "lat": 43.77472594444444,
              "lng": -116.09173857777779,
              "alt": 2166.4877777777774,
              "speed2d": 2.0336666666666665,
              "speed3d": 2.0577777777777784,
              "msFromStart": 382229.2213333332
            },
            {
              "lat": 43.77472474444445,
              "lng": -116.09175296666668,
              "alt": 2166.0542222222225,
              "speed2d": 2.1891111111111115,
              "speed3d": 2.23,
              "msFromStart": 382724.90311111114
            },
            {
              "lat": 43.77472444,
              "lng": -116.09176647999999,
              "alt": 2165.7560000000003,
              "speed2d": 2.0570999999999997,
              "speed3d": 2.128,
              "msFromStart": 383248.1040000001
            },
            {
              "lat": 43.77472293333334,
              "lng": -116.09177758888887,
              "alt": 2165.501444444444,
              "speed2d": 1.9010000000000002,
              "speed3d": 1.922222222222222,
              "msFromStart": 383769.97389473696
            },
            {
              "lat": 43.77472391111111,
              "lng": -116.0917896888889,
              "alt": 2165.612,
              "speed2d": 1.943333333333333,
              "speed3d": 1.992222222222222,
              "msFromStart": 384263.2421052635
            },
            {
              "lat": 43.77472565555555,
              "lng": -116.09179635555554,
              "alt": 2165.8288888888887,
              "speed2d": 1.3997777777777778,
              "speed3d": 1.4855555555555553,
              "msFromStart": 384757.2124444444
            },
            {
              "lat": 43.77472747777777,
              "lng": -116.0918022111111,
              "alt": 2165.9988888888893,
              "speed2d": 1.1544444444444444,
              "speed3d": 1.1733333333333333,
              "msFromStart": 385252.0604444443
            },
            {
              "lat": 43.77473186666667,
              "lng": -116.09180762222222,
              "alt": 2165.971666666667,
              "speed2d": 1.1773333333333333,
              "speed3d": 1.202222222222222,
              "msFromStart": 385746.83733333345
            },
            {
              "lat": 43.774733111111104,
              "lng": -116.091815,
              "alt": 2166.0026666666668,
              "speed2d": 1.1572222222222224,
              "speed3d": 1.1444444444444444,
              "msFromStart": 386241.52533333364
            },
            {
              "lat": 43.774734844444446,
              "lng": -116.09182695555555,
              "alt": 2166.1875555555557,
              "speed2d": 1.5313333333333334,
              "speed3d": 1.508888888888889,
              "msFromStart": 386736.4053333332
            },
            {
              "lat": 43.77473606666666,
              "lng": -116.0918384888889,
              "alt": 2166.193222222222,
              "speed2d": 1.5875555555555558,
              "speed3d": 1.5933333333333333,
              "msFromStart": 387231.52533333295
            },
            {
              "lat": 43.77473611111111,
              "lng": -116.09185134444445,
              "alt": 2166.1284444444445,
              "speed2d": 1.7758888888888889,
              "speed3d": 1.75,
              "msFromStart": 387726.5244444444
            },
            {
              "lat": 43.774739790000005,
              "lng": -116.09186678,
              "alt": 2166.0789000000004,
              "speed2d": 2.1625,
              "speed3d": 2.153,
              "msFromStart": 388248.8639999999
            },
            {
              "lat": 43.77474055555555,
              "lng": -116.09188462222224,
              "alt": 2165.938888888889,
              "speed2d": 2.4751111111111106,
              "speed3d": 2.421111111111111,
              "msFromStart": 388771.5216842104
            },
            {
              "lat": 43.77474554444444,
              "lng": -116.09190228888889,
              "alt": 2166.100666666667,
              "speed2d": 2.7185555555555556,
              "speed3d": 2.7422222222222223,
              "msFromStart": 389266.9423157892
            },
            {
              "lat": 43.774750444444436,
              "lng": -116.09191532222222,
              "alt": 2166.1506666666664,
              "speed2d": 2.4161111111111113,
              "speed3d": 2.407777777777778,
              "msFromStart": 389762.42844444443
            },
            {
              "lat": 43.77475658888889,
              "lng": -116.09192843333334,
              "alt": 2166.2817777777777,
              "speed2d": 2.4635555555555557,
              "speed3d": 2.471111111111111,
              "msFromStart": 390257.99644444446
            },
            {
              "lat": 43.77476078888889,
              "lng": -116.09194147777777,
              "alt": 2166.254111111111,
              "speed2d": 2.3292222222222225,
              "speed3d": 2.345555555555556,
              "msFromStart": 390753.06666666677
            },
            {
              "lat": 43.774763866666675,
              "lng": -116.09195648888888,
              "alt": 2166.178,
              "speed2d": 2.304777777777778,
              "speed3d": 2.3,
              "msFromStart": 391247.51466666686
            },
            {
              "lat": 43.774767422222226,
              "lng": -116.0919712888889,
              "alt": 2166.179555555556,
              "speed2d": 2.328444444444444,
              "speed3d": 2.344444444444444,
              "msFromStart": 391742.28977777774
            },
            {
              "lat": 43.774770777777775,
              "lng": -116.0919857111111,
              "alt": 2166.447666666667,
              "speed2d": 2.3164444444444445,
              "speed3d": 2.327777777777778,
              "msFromStart": 392237.4737777777
            },
            {
              "lat": 43.77477631111111,
              "lng": -116.09199969999999,
              "alt": 2166.3844444444444,
              "speed2d": 2.4192222222222224,
              "speed3d": 2.3844444444444446,
              "msFromStart": 392732.8782222223
            },
            {
              "lat": 43.774777722222225,
              "lng": -116.09201639999999,
              "alt": 2166.3957777777778,
              "speed2d": 2.3537777777777773,
              "speed3d": 2.3566666666666665,
              "msFromStart": 393228.5582222226
            },
            {
              "lat": 43.77477655,
              "lng": -116.09202985,
              "alt": 2166.4384999999997,
              "speed2d": 2.0132999999999996,
              "speed3d": 2.0719999999999996,
              "msFromStart": 393751.7599999999
            },
            {
              "lat": 43.77477897777777,
              "lng": -116.09204114444444,
              "alt": 2166.2595555555554,
              "speed2d": 1.6911111111111112,
              "speed3d": 1.6933333333333334,
              "msFromStart": 394274.94399999984
            },
            {
              "lat": 43.77478308888889,
              "lng": -116.09205174444445,
              "alt": 2166.1450000000004,
              "speed2d": 1.7704444444444445,
              "speed3d": 1.7744444444444445,
              "msFromStart": 394769.8524444446
            },
            {
              "lat": 43.77478774444444,
              "lng": -116.09206115555557,
              "alt": 2166.431444444444,
              "speed2d": 1.581111111111111,
              "speed3d": 1.6222222222222222,
              "msFromStart": 395263.83644444484
            },
            {
              "lat": 43.77478757777778,
              "lng": -116.09207129999999,
              "alt": 2166.7479999999996,
              "speed2d": 1.3709999999999998,
              "speed3d": 1.3844444444444446,
              "msFromStart": 395758.85866666667
            },
            {
              "lat": 43.7747910888889,
              "lng": -116.09208297777779,
              "alt": 2166.7632222222223,
              "speed2d": 1.596888888888889,
              "speed3d": 1.5933333333333333,
              "msFromStart": 396255.1786666666
            },
            {
              "lat": 43.77479315555556,
              "lng": -116.09209480000001,
              "alt": 2167.1217777777774,
              "speed2d": 1.604,
              "speed3d": 1.6022222222222222,
              "msFromStart": 396750.73777777766
            },
            {
              "lat": 43.774795100000006,
              "lng": -116.09210341111113,
              "alt": 2167.367111111111,
              "speed2d": 1.4841111111111114,
              "speed3d": 1.461111111111111,
              "msFromStart": 397245.34577777755
            },
            {
              "lat": 43.77479835555555,
              "lng": -116.09211314444445,
              "alt": 2167.3542222222222,
              "speed2d": 1.448888888888889,
              "speed3d": 1.4922222222222223,
              "msFromStart": 397739.8257777777
            },
            {
              "lat": 43.774793700000004,
              "lng": -116.09212204444444,
              "alt": 2166.7331111111107,
              "speed2d": 1.4661111111111111,
              "speed3d": 1.4500000000000002,
              "msFromStart": 398234.1457777775
            },
            {
              "lat": 43.774790244444446,
              "lng": -116.09213314444446,
              "alt": 2166.434333333333,
              "speed2d": 1.5762222222222224,
              "speed3d": 1.6033333333333333,
              "msFromStart": 398728.6046315788
            },
            {
              "lat": 43.77478953999999,
              "lng": -116.0921405,
              "alt": 2166.2201999999997,
              "speed2d": 1.3147,
              "speed3d": 1.341,
              "msFromStart": 399250.7166315787
            },
            {
              "lat": 43.77479486666667,
              "lng": -116.09215003333334,
              "alt": 2166.4338888888888,
              "speed2d": 1.3725555555555555,
              "speed3d": 1.35,
              "msFromStart": 399773.1306666666
            }
          ]
        }
      ]
    },
    {
      "id": "trail-pioneer-cat-track",
      "pathType": "trail",
      "name": "Pioneer Cat Track",
      "difficulty": "green",
      "isCatTrack": true,
      "videos": [
        {
          "videoId": "GX010081",
          "pathId": "trail-pioneer-cat-track",
          "startSec": 35,
          "endSec": 165,
          "id": "video-segment-41",
          "gps": [
            {
              "lat": 43.77107212222222,
              "lng": -116.10200106666665,
              "alt": 2047.1016666666665,
              "speed2d": 2.654,
              "speed3d": 2.7133333333333334,
              "msFromStart": 35256.147169590644
            },
            {
              "lat": 43.77106131111111,
              "lng": -116.10199401111112,
              "alt": 2046.4446666666668,
              "speed2d": 2.802111111111111,
              "speed3d": 2.7688888888888887,
              "msFromStart": 35751.32189473681
            },
            {
              "lat": 43.77105063333333,
              "lng": -116.10198032222223,
              "alt": 2045.6933333333334,
              "speed2d": 3.315444444444444,
              "speed3d": 3.2988888888888894,
              "msFromStart": 36246.63567641323
            },
            {
              "lat": 43.77104265555556,
              "lng": -116.10195972222223,
              "alt": 2045.2331111111112,
              "speed2d": 3.7678888888888897,
              "speed3d": 3.771111111111111,
              "msFromStart": 36742.295111111096
            },
            {
              "lat": 43.771040022222216,
              "lng": -116.10193282222221,
              "alt": 2045.189,
              "speed2d": 4.345777777777778,
              "speed3d": 4.316666666666666,
              "msFromStart": 37237.76244444443
            },
            {
              "lat": 43.77104052222222,
              "lng": -116.1019027,
              "alt": 2044.9254444444443,
              "speed2d": 4.913888888888889,
              "speed3d": 4.858888888888889,
              "msFromStart": 37732.32311111111
            },
            {
              "lat": 43.771042200000004,
              "lng": -116.10186390000001,
              "alt": 2045.5493333333334,
              "speed2d": 5.94511111111111,
              "speed3d": 5.8566666666666665,
              "msFromStart": 38226.941259259256
            },
            {
              "lat": 43.77104724,
              "lng": -116.10182361999999,
              "alt": 2045.6039999999998,
              "speed2d": 6.296900000000001,
              "speed3d": 6.313999999999999,
              "msFromStart": 38749.66699999999
            },
            {
              "lat": 43.77105288888889,
              "lng": -116.10178512222224,
              "alt": 2045.7206666666664,
              "speed2d": 6.3406666666666665,
              "speed3d": 6.346666666666668,
              "msFromStart": 39272.3094074074
            },
            {
              "lat": 43.771059722222226,
              "lng": -116.10174465555555,
              "alt": 2045.9411111111112,
              "speed2d": 6.740444444444445,
              "speed3d": 6.681111111111111,
              "msFromStart": 39767.049777777815
            },
            {
              "lat": 43.77107006666667,
              "lng": -116.10170295555557,
              "alt": 2045.5904444444448,
              "speed2d": 7.173,
              "speed3d": 7.17111111111111,
              "msFromStart": 40261.81903703704
            },
            {
              "lat": 43.77107958888888,
              "lng": -116.10166512222222,
              "alt": 2045.1589999999999,
              "speed2d": 7.088333333333334,
              "speed3d": 7.116666666666666,
              "msFromStart": 40756.72199999998
            },
            {
              "lat": 43.77108525555555,
              "lng": -116.10162412222222,
              "alt": 2045.3616666666667,
              "speed2d": 7.060555555555555,
              "speed3d": 7.0777777777777775,
              "msFromStart": 41251.80810526316
            },
            {
              "lat": 43.77108966666667,
              "lng": -116.10157865555554,
              "alt": 2045.4995555555558,
              "speed2d": 7.2844444444444445,
              "speed3d": 7.25,
              "msFromStart": 41747.37221052633
            },
            {
              "lat": 43.77109497777777,
              "lng": -116.1015298888889,
              "alt": 2045.5175555555556,
              "speed2d": 7.7811111111111115,
              "speed3d": 7.737777777777778,
              "msFromStart": 42242.75827680312
            },
            {
              "lat": 43.77109982222221,
              "lng": -116.10148068888888,
              "alt": 2045.1510000000003,
              "speed2d": 8.024888888888889,
              "speed3d": 8.04888888888889,
              "msFromStart": 42737.33155555554
            },
            {
              "lat": 43.771102066666664,
              "lng": -116.10143307777777,
              "alt": 2044.3515555555555,
              "speed2d": 7.991777777777777,
              "speed3d": 8.001111111111113,
              "msFromStart": 43231.80429629628
            },
            {
              "lat": 43.77109987777778,
              "lng": -116.10138483333334,
              "alt": 2043.3065555555559,
              "speed2d": 8.17988888888889,
              "speed3d": 8.181111111111111,
              "msFromStart": 43726.2
            },
            {
              "lat": 43.77109081,
              "lng": -116.10132996,
              "alt": 2042.9495000000002,
              "speed2d": 8.5774,
              "speed3d": 8.595,
              "msFromStart": 44248.334933333324
            },
            {
              "lat": 43.77107734444445,
              "lng": -116.1012742,
              "alt": 2042.9304444444442,
              "speed2d": 8.819444444444445,
              "speed3d": 8.807777777777776,
              "msFromStart": 44771.37955555551
            },
            {
              "lat": 43.771059588888896,
              "lng": -116.10122127777778,
              "alt": 2042.3025555555555,
              "speed2d": 9.378555555555554,
              "speed3d": 9.363333333333333,
              "msFromStart": 45266.872600389856
            },
            {
              "lat": 43.77103958888888,
              "lng": -116.10116738888891,
              "alt": 2041.677222222222,
              "speed2d": 9.750222222222222,
              "speed3d": 9.736666666666666,
              "msFromStart": 45762.097052631594
            },
            {
              "lat": 43.77101962222222,
              "lng": -116.10111073333331,
              "alt": 2041.2703333333334,
              "speed2d": 10.091888888888889,
              "speed3d": 10.116666666666665,
              "msFromStart": 46257.16656920078
            },
            {
              "lat": 43.77100382222222,
              "lng": -116.1010533,
              "alt": 2040.844,
              "speed2d": 10.091777777777777,
              "speed3d": 10.146666666666667,
              "msFromStart": 46751.67288888886
            },
            {
              "lat": 43.770991188888885,
              "lng": -116.10099633333333,
              "alt": 2040.2857777777779,
              "speed2d": 9.929777777777778,
              "speed3d": 10.002222222222223,
              "msFromStart": 47246.18362962962
            },
            {
              "lat": 43.770979688888886,
              "lng": -116.1009401111111,
              "alt": 2039.6925555555556,
              "speed2d": 9.738222222222223,
              "speed3d": 9.813333333333333,
              "msFromStart": 47740.93733333336
            },
            {
              "lat": 43.77096908888889,
              "lng": -116.1008827,
              "alt": 2039.0048888888887,
              "speed2d": 9.687888888888889,
              "speed3d": 9.73,
              "msFromStart": 48235.93274074075
            },
            {
              "lat": 43.77096095555555,
              "lng": -116.10082392222222,
              "alt": 2038.3297777777777,
              "speed2d": 9.655111111111111,
              "speed3d": 9.735555555555557,
              "msFromStart": 48731.79777777775
            },
            {
              "lat": 43.77096031111111,
              "lng": -116.10077046666667,
              "alt": 2037.3228888888889,
              "speed2d": 9.30411111111111,
              "speed3d": 9.431111111111111,
              "msFromStart": 49227.4394074074
            },
            {
              "lat": 43.770962680000004,
              "lng": -116.1007159,
              "alt": 2036.2281999999998,
              "speed2d": 9.0922,
              "speed3d": 9.161,
              "msFromStart": 49749.29933333334
            },
            {
              "lat": 43.77096221111111,
              "lng": -116.10065668888889,
              "alt": 2035.4467777777777,
              "speed2d": 9.342555555555556,
              "speed3d": 9.379999999999999,
              "msFromStart": 50271.347095516576
            },
            {
              "lat": 43.77095728888889,
              "lng": -116.10059861111114,
              "alt": 2034.7883333333334,
              "speed2d": 9.46788888888889,
              "speed3d": 9.549999999999999,
              "msFromStart": 50766.781894736865
            },
            {
              "lat": 43.770947944444444,
              "lng": -116.10054323333333,
              "alt": 2034.128777777778,
              "speed2d": 9.367222222222221,
              "speed3d": 9.475555555555555,
              "msFromStart": 51262.21138011696
            },
            {
              "lat": 43.77093298888888,
              "lng": -116.10048536666669,
              "alt": 2033.8867777777778,
              "speed2d": 9.412333333333335,
              "speed3d": 9.43888888888889,
              "msFromStart": 51757.44133333331
            },
            {
              "lat": 43.77091499999999,
              "lng": -116.10043086666667,
              "alt": 2033.711111111111,
              "speed2d": 9.275,
              "speed3d": 9.44111111111111,
              "msFromStart": 52252.370962962945
            },
            {
              "lat": 43.77089786666666,
              "lng": -116.10039564444443,
              "alt": 2032.280777777778,
              "speed2d": 8.514555555555555,
              "speed3d": 8.662222222222223,
              "msFromStart": 52746.17911111109
            },
            {
              "lat": 43.7708765,
              "lng": -116.10035634444446,
              "alt": 2031.6923333333334,
              "speed2d": 8.163222222222224,
              "speed3d": 8.256666666666668,
              "msFromStart": 53240.15399999999
            },
            {
              "lat": 43.77085185555555,
              "lng": -116.10031391111113,
              "alt": 2031.5707777777782,
              "speed2d": 8.170222222222222,
              "speed3d": 8.251111111111111,
              "msFromStart": 53735.24444444446
            },
            {
              "lat": 43.77082632222222,
              "lng": -116.10027303333334,
              "alt": 2031.513333333333,
              "speed2d": 8.143777777777778,
              "speed3d": 8.219999999999999,
              "msFromStart": 54230.542370370385
            },
            {
              "lat": 43.770801500000005,
              "lng": -116.10023263333332,
              "alt": 2031.3072222222222,
              "speed2d": 8.180111111111112,
              "speed3d": 8.241111111111111,
              "msFromStart": 54726.22400000002
            },
            {
              "lat": 43.77077764,
              "lng": -116.10018957,
              "alt": 2031.0207,
              "speed2d": 8.060000000000002,
              "speed3d": 8.149000000000001,
              "msFromStart": 55249.24261052633
            },
            {
              "lat": 43.77075518888888,
              "lng": -116.1001458111111,
              "alt": 2030.9801111111115,
              "speed2d": 7.911,
              "speed3d": 7.981111111111112,
              "msFromStart": 55771.4795789474
            },
            {
              "lat": 43.770736633333335,
              "lng": -116.10010693333331,
              "alt": 2030.9925555555556,
              "speed2d": 7.374555555555556,
              "speed3d": 7.501111111111111,
              "msFromStart": 56266.178483430805
            },
            {
              "lat": 43.7707223,
              "lng": -116.10007108888891,
              "alt": 2030.7835555555555,
              "speed2d": 6.7250000000000005,
              "speed3d": 6.857777777777778,
              "msFromStart": 56760.931111111095
            },
            {
              "lat": 43.770717511111116,
              "lng": -116.10003751111111,
              "alt": 2030.1262222222224,
              "speed2d": 6.002777777777777,
              "speed3d": 6.123333333333334,
              "msFromStart": 57255.74874074073
            },
            {
              "lat": 43.77071872222223,
              "lng": -116.10000524444443,
              "alt": 2029.5101111111112,
              "speed2d": 5.408111111111112,
              "speed3d": 5.542222222222223,
              "msFromStart": 57750.80488888886
            },
            {
              "lat": 43.770722199999994,
              "lng": -116.09997291111111,
              "alt": 2029.1675555555553,
              "speed2d": 5.278777777777778,
              "speed3d": 5.3455555555555545,
              "msFromStart": 58246.135999999984
            },
            {
              "lat": 43.77072728888889,
              "lng": -116.0999415888889,
              "alt": 2028.8823333333335,
              "speed2d": 5.026777777777777,
              "speed3d": 5.135555555555555,
              "msFromStart": 58742.47155555553
            },
            {
              "lat": 43.77073384444445,
              "lng": -116.09991231111111,
              "alt": 2028.6547777777778,
              "speed2d": 4.933,
              "speed3d": 5.002222222222223,
              "msFromStart": 59238.44496296295
            },
            {
              "lat": 43.77074205555556,
              "lng": -116.09988282222221,
              "alt": 2028.8473333333336,
              "speed2d": 4.83,
              "speed3d": 4.942222222222222,
              "msFromStart": 59732.567999999985
            },
            {
              "lat": 43.770750022222224,
              "lng": -116.09985704444445,
              "alt": 2028.873222222222,
              "speed2d": 4.462,
              "speed3d": 4.541111111111111,
              "msFromStart": 60226.98828070174
            },
            {
              "lat": 43.77075702,
              "lng": -116.09983331000001,
              "alt": 2028.7502,
              "speed2d": 3.9846,
              "speed3d": 4.098,
              "msFromStart": 60750.923368421085
            },
            {
              "lat": 43.77076436666666,
              "lng": -116.09980875555556,
              "alt": 2028.9021111111113,
              "speed2d": 3.7905555555555552,
              "speed3d": 3.8466666666666667,
              "msFromStart": 61274.687606237836
            },
            {
              "lat": 43.77077046666667,
              "lng": -116.09978475555556,
              "alt": 2029.0607777777777,
              "speed2d": 3.7723333333333335,
              "speed3d": 3.8388888888888886,
              "msFromStart": 61769.411111111134
            },
            {
              "lat": 43.770776266666665,
              "lng": -116.0997634888889,
              "alt": 2028.7223333333336,
              "speed2d": 3.7983333333333333,
              "speed3d": 3.856666666666667,
              "msFromStart": 62264.05911111112
            },
            {
              "lat": 43.770782866666664,
              "lng": -116.0997508888889,
              "alt": 2027.3364444444444,
              "speed2d": 3.5941111111111113,
              "speed3d": 3.6933333333333334,
              "msFromStart": 62758.98711111107
            },
            {
              "lat": 43.77078728888888,
              "lng": -116.09973105555557,
              "alt": 2026.2483333333334,
              "speed2d": 3.739555555555556,
              "speed3d": 3.8066666666666666,
              "msFromStart": 63254.00162962961
            },
            {
              "lat": 43.7707866,
              "lng": -116.09970254444444,
              "alt": 2025.9708888888886,
              "speed2d": 3.9608888888888885,
              "speed3d": 4.058888888888889,
              "msFromStart": 63749.25022222224
            },
            {
              "lat": 43.77078334444445,
              "lng": -116.09967322222222,
              "alt": 2025.6896666666667,
              "speed2d": 4.253,
              "speed3d": 4.304444444444444,
              "msFromStart": 64244.58592592593
            },
            {
              "lat": 43.770777200000005,
              "lng": -116.0996432,
              "alt": 2025.6158888888885,
              "speed2d": 4.364888888888889,
              "speed3d": 4.452222222222222,
              "msFromStart": 64740.17644444442
            },
            {
              "lat": 43.770770122222224,
              "lng": -116.09961474444445,
              "alt": 2025.424111111111,
              "speed2d": 4.492888888888889,
              "speed3d": 4.5777777777777775,
              "msFromStart": 65235.518740740714
            },
            {
              "lat": 43.770762733333335,
              "lng": -116.09958566666666,
              "alt": 2025.1974444444445,
              "speed2d": 4.550777777777777,
              "speed3d": 4.635555555555555,
              "msFromStart": 65729.76622222218
            },
            {
              "lat": 43.77075326,
              "lng": -116.09955378,
              "alt": 2024.9507999999998,
              "speed2d": 4.7761000000000005,
              "speed3d": 4.841,
              "msFromStart": 66251.33729824559
            },
            {
              "lat": 43.77073967777778,
              "lng": -116.09952134444445,
              "alt": 2025.2504444444444,
              "speed2d": 4.854111111111111,
              "speed3d": 4.941111111111112,
              "msFromStart": 66772.84673684204
            },
            {
              "lat": 43.770728000000005,
              "lng": -116.09949106666667,
              "alt": 2025.2314444444444,
              "speed2d": 4.920111111111111,
              "speed3d": 5,
              "msFromStart": 67267.05863547756
            },
            {
              "lat": 43.770714911111114,
              "lng": -116.09946316666667,
              "alt": 2025.1963333333333,
              "speed2d": 4.851333333333334,
              "speed3d": 4.915555555555556,
              "msFromStart": 67761.89244444447
            },
            {
              "lat": 43.77069898888889,
              "lng": -116.09943582222222,
              "alt": 2025.4875555555557,
              "speed2d": 4.940222222222221,
              "speed3d": 4.993333333333333,
              "msFromStart": 68256.88548148148
            },
            {
              "lat": 43.770684655555556,
              "lng": -116.09940883333334,
              "alt": 2025.5475555555556,
              "speed2d": 4.852111111111112,
              "speed3d": 4.93,
              "msFromStart": 68752.26666666662
            },
            {
              "lat": 43.77067045555555,
              "lng": -116.09938066666666,
              "alt": 2025.6862222222223,
              "speed2d": 4.789888888888889,
              "speed3d": 4.843333333333334,
              "msFromStart": 69247.66888888887
            },
            {
              "lat": 43.77065682222222,
              "lng": -116.09935505555555,
              "alt": 2025.6267777777775,
              "speed2d": 4.711777777777778,
              "speed3d": 4.775555555555555,
              "msFromStart": 69742.99999999996
            },
            {
              "lat": 43.770643722222225,
              "lng": -116.0993312777778,
              "alt": 2025.320777777778,
              "speed2d": 4.782111111111111,
              "speed3d": 4.799999999999999,
              "msFromStart": 70238.20177777774
            },
            {
              "lat": 43.77062975555555,
              "lng": -116.09930851111109,
              "alt": 2024.6138888888893,
              "speed2d": 5.029555555555556,
              "speed3d": 5.0633333333333335,
              "msFromStart": 70732.91466666659
            },
            {
              "lat": 43.77061376666667,
              "lng": -116.09928127777778,
              "alt": 2024.201111111111,
              "speed2d": 5.354333333333334,
              "speed3d": 5.389999999999999,
              "msFromStart": 71227.52509941516
            },
            {
              "lat": 43.770595719999996,
              "lng": -116.09924487,
              "alt": 2024.3550000000005,
              "speed2d": 5.7798,
              "speed3d": 5.768,
              "msFromStart": 71749.38652631576
            },
            {
              "lat": 43.77057703333334,
              "lng": -116.09920753333336,
              "alt": 2024.4155555555558,
              "speed2d": 6.224222222222222,
              "speed3d": 6.242222222222222,
              "msFromStart": 72271.43787134501
            },
            {
              "lat": 43.77056238888888,
              "lng": -116.09917084444444,
              "alt": 2024.359777777778,
              "speed2d": 6.306444444444445,
              "speed3d": 6.343333333333334,
              "msFromStart": 72766.8693333333
            },
            {
              "lat": 43.77054694444445,
              "lng": -116.09913308888889,
              "alt": 2024.2914444444443,
              "speed2d": 6.465333333333334,
              "speed3d": 6.5,
              "msFromStart": 73262.33348148146
            },
            {
              "lat": 43.770532711111116,
              "lng": -116.0990970777778,
              "alt": 2024.1113333333335,
              "speed2d": 6.450111111111111,
              "speed3d": 6.497777777777777,
              "msFromStart": 73757.59022222222
            },
            {
              "lat": 43.770519744444435,
              "lng": -116.09906217777778,
              "alt": 2023.603222222222,
              "speed2d": 6.491333333333334,
              "speed3d": 6.525555555555555,
              "msFromStart": 74252.82029629631
            },
            {
              "lat": 43.770506822222224,
              "lng": -116.09902733333335,
              "alt": 2023.2234444444443,
              "speed2d": 6.395444444444444,
              "speed3d": 6.446666666666667,
              "msFromStart": 74748.02666666673
            },
            {
              "lat": 43.77049116666666,
              "lng": -116.09898786666668,
              "alt": 2022.6712222222222,
              "speed2d": 6.793444444444444,
              "speed3d": 6.805555555555555,
              "msFromStart": 75243.12992592595
            },
            {
              "lat": 43.770475000000005,
              "lng": -116.0989467111111,
              "alt": 2022.1968888888887,
              "speed2d": 7.092888888888889,
              "speed3d": 7.094444444444444,
              "msFromStart": 75737.83022222224
            },
            {
              "lat": 43.77045714444444,
              "lng": -116.09890294444443,
              "alt": 2022.0439999999999,
              "speed2d": 7.408444444444445,
              "speed3d": 7.445555555555556,
              "msFromStart": 76232.50967641328
            },
            {
              "lat": 43.77043958888889,
              "lng": -116.0988607111111,
              "alt": 2021.6480000000001,
              "speed2d": 7.574333333333334,
              "speed3d": 7.605555555555554,
              "msFromStart": 76727.26694736847
            },
            {
              "lat": 43.77042111,
              "lng": -116.09881625,
              "alt": 2021.3043999999998,
              "speed2d": 7.5656,
              "speed3d": 7.637,
              "msFromStart": 77249.6361052632
            },
            {
              "lat": 43.770400833333326,
              "lng": -116.09876921111112,
              "alt": 2020.9724444444446,
              "speed2d": 7.803444444444444,
              "speed3d": 7.79,
              "msFromStart": 77772.55199999997
            },
            {
              "lat": 43.7703796,
              "lng": -116.09872146666667,
              "alt": 2020.439222222222,
              "speed2d": 8.300555555555555,
              "speed3d": 8.344444444444445,
              "msFromStart": 78267.96814814812
            },
            {
              "lat": 43.7703601111111,
              "lng": -116.09867542222221,
              "alt": 2020.1072222222224,
              "speed2d": 8.350000000000001,
              "speed3d": 8.382222222222222,
              "msFromStart": 78763.17688888888
            },
            {
              "lat": 43.77034101111112,
              "lng": -116.09863187777779,
              "alt": 2019.5515555555555,
              "speed2d": 8.267333333333333,
              "speed3d": 8.36,
              "msFromStart": 79258.32933333333
            },
            {
              "lat": 43.77031875555556,
              "lng": -116.09859463333333,
              "alt": 2019.1174444444443,
              "speed2d": 8.106666666666667,
              "speed3d": 8.185555555555556,
              "msFromStart": 79753.33955555549
            },
            {
              "lat": 43.77029483333334,
              "lng": -116.0985681111111,
              "alt": 2018.2995555555553,
              "speed2d": 7.754111111111111,
              "speed3d": 7.843333333333335,
              "msFromStart": 80248.49111111107
            },
            {
              "lat": 43.770267477777786,
              "lng": -116.0985564,
              "alt": 2016.6802222222223,
              "speed2d": 7.61888888888889,
              "speed3d": 7.746666666666667,
              "msFromStart": 80744.26488888887
            },
            {
              "lat": 43.77024086666667,
              "lng": -116.09854887777777,
              "alt": 2014.7298888888888,
              "speed2d": 7.3373333333333335,
              "speed3d": 7.48888888888889,
              "msFromStart": 81240.00221442497
            },
            {
              "lat": 43.770212388888886,
              "lng": -116.09854478888887,
              "alt": 2012.6420000000003,
              "speed2d": 7.226333333333334,
              "speed3d": 7.420000000000001,
              "msFromStart": 81735.34484210533
            },
            {
              "lat": 43.7701878,
              "lng": -116.098547,
              "alt": 2010.2822222222226,
              "speed2d": 6.7024444444444455,
              "speed3d": 7.067777777777778,
              "msFromStart": 82230.55477063032
            },
            {
              "lat": 43.77017238,
              "lng": -116.09856409000001,
              "alt": 2007.8191,
              "speed2d": 5.6145000000000005,
              "speed3d": 6.142,
              "msFromStart": 82752.67088888885
            },
            {
              "lat": 43.770177544444444,
              "lng": -116.09861126666667,
              "alt": 2004.9715555555556,
              "speed2d": 4.558222222222223,
              "speed3d": 5.116666666666666,
              "msFromStart": 83274.87896296292
            },
            {
              "lat": 43.770179033333335,
              "lng": -116.0986607777778,
              "alt": 2003.6764444444443,
              "speed2d": 5.4478888888888894,
              "speed3d": 5.845555555555556,
              "msFromStart": 83770.35288888881
            },
            {
              "lat": 43.77017133333333,
              "lng": -116.09870219999999,
              "alt": 2002.8862222222224,
              "speed2d": 6.335777777777777,
              "speed3d": 6.717777777777777,
              "msFromStart": 84265.72162962961
            },
            {
              "lat": 43.77015676666666,
              "lng": -116.09874142222222,
              "alt": 2002.590111111111,
              "speed2d": 6.952333333333334,
              "speed3d": 7.267777777777778,
              "msFromStart": 84760.37333333338
            },
            {
              "lat": 43.770143911111106,
              "lng": -116.09877996666667,
              "alt": 2002.061777777778,
              "speed2d": 7.048777777777778,
              "speed3d": 7.446666666666666,
              "msFromStart": 85254.94888888893
            },
            {
              "lat": 43.77012956666667,
              "lng": -116.09882167777778,
              "alt": 2001.6773333333333,
              "speed2d": 7.520888888888889,
              "speed3d": 7.779999999999999,
              "msFromStart": 85749.50666666671
            },
            {
              "lat": 43.77011638888889,
              "lng": -116.09886468888887,
              "alt": 2001.0975555555556,
              "speed2d": 7.773777777777779,
              "speed3d": 8.117777777777777,
              "msFromStart": 86244.18699415209
            },
            {
              "lat": 43.7701033,
              "lng": -116.0989095111111,
              "alt": 2000.629222222222,
              "speed2d": 7.945555555555555,
              "speed3d": 8.258888888888889,
              "msFromStart": 86739.36463157891
            },
            {
              "lat": 43.77008964444445,
              "lng": -116.0989521888889,
              "alt": 2000.1499999999999,
              "speed2d": 7.779222222222223,
              "speed3d": 8.136666666666667,
              "msFromStart": 87234.47652241713
            },
            {
              "lat": 43.770074244444444,
              "lng": -116.09898738888887,
              "alt": 2000.1094444444443,
              "speed2d": 7.1176666666666675,
              "speed3d": 7.5088888888888885,
              "msFromStart": 87728.88399999999
            },
            {
              "lat": 43.77005478,
              "lng": -116.09901251000001,
              "alt": 1999.6242,
              "speed2d": 6.1656,
              "speed3d": 6.596,
              "msFromStart": 88250.732
            },
            {
              "lat": 43.77003208888889,
              "lng": -116.09902784444444,
              "alt": 1998.8903333333335,
              "speed2d": 5.589111111111111,
              "speed3d": 5.982222222222222,
              "msFromStart": 88773.02488888896
            },
            {
              "lat": 43.770006611111114,
              "lng": -116.09903702222223,
              "alt": 1998.0867777777776,
              "speed2d": 5.632333333333333,
              "speed3d": 5.961111111111111,
              "msFromStart": 89267.85111111114
            },
            {
              "lat": 43.76997812222223,
              "lng": -116.09903976666668,
              "alt": 1996.9981111111108,
              "speed2d": 6.210333333333334,
              "speed3d": 6.478888888888888,
              "msFromStart": 89762.52622222222
            },
            {
              "lat": 43.769947822222214,
              "lng": -116.09903924444444,
              "alt": 1995.9007777777776,
              "speed2d": 6.661555555555555,
              "speed3d": 6.99111111111111,
              "msFromStart": 90257.43733333335
            },
            {
              "lat": 43.76992111111111,
              "lng": -116.0990336111111,
              "alt": 1994.8294444444446,
              "speed2d": 6.359555555555556,
              "speed3d": 6.734444444444444,
              "msFromStart": 90753.35288888893
            },
            {
              "lat": 43.76988952222223,
              "lng": -116.09902294444444,
              "alt": 1994.214111111111,
              "speed2d": 6.696666666666666,
              "speed3d": 6.914444444444445,
              "msFromStart": 91249.15688109162
            },
            {
              "lat": 43.76985545555555,
              "lng": -116.0990066888889,
              "alt": 1993.4964444444443,
              "speed2d": 7.314222222222222,
              "speed3d": 7.538888888888889,
              "msFromStart": 91744.11284210521
            },
            {
              "lat": 43.76982252222223,
              "lng": -116.09898884444446,
              "alt": 1993.0323333333333,
              "speed2d": 7.616,
              "speed3d": 7.867777777777777,
              "msFromStart": 92239.14361013642
            },
            {
              "lat": 43.76979087777778,
              "lng": -116.0989696,
              "alt": 1992.0743333333332,
              "speed2d": 7.669777777777778,
              "speed3d": 7.872222222222223,
              "msFromStart": 92735.14799999996
            },
            {
              "lat": 43.769752,
              "lng": -116.09894936666667,
              "alt": 1992.0047777777777,
              "speed2d": 8.049777777777779,
              "speed3d": 8.227777777777778,
              "msFromStart": 93231.19101234566
            },
            {
              "lat": 43.76971322222222,
              "lng": -116.09892893333333,
              "alt": 1992.0494444444444,
              "speed2d": 8.290333333333335,
              "speed3d": 8.475555555555555,
              "msFromStart": 93726.49599999997
            },
            {
              "lat": 43.769672619999994,
              "lng": -116.09890649000002,
              "alt": 1992.0099,
              "speed2d": 8.5713,
              "speed3d": 8.694,
              "msFromStart": 94249.05355555553
            },
            {
              "lat": 43.769633355555555,
              "lng": -116.09888531111112,
              "alt": 1991.9685555555557,
              "speed2d": 8.513222222222222,
              "speed3d": 8.737777777777778,
              "msFromStart": 94770.97333333333
            },
            {
              "lat": 43.76960205555555,
              "lng": -116.09886367777777,
              "alt": 1991.8476666666663,
              "speed2d": 7.908888888888888,
              "speed3d": 8.107777777777777,
              "msFromStart": 95265.55125146196
            },
            {
              "lat": 43.7695675,
              "lng": -116.0988369,
              "alt": 1991.924222222222,
              "speed2d": 8.039,
              "speed3d": 8.147777777777778,
              "msFromStart": 95760.98484210522
            },
            {
              "lat": 43.76953383333334,
              "lng": -116.0988072,
              "alt": 1992.1048888888888,
              "speed2d": 8.318777777777779,
              "speed3d": 8.424444444444445,
              "msFromStart": 96256.37126445741
            },
            {
              "lat": 43.76950548888889,
              "lng": -116.09877844444445,
              "alt": 1991.9481111111108,
              "speed2d": 8.038333333333334,
              "speed3d": 8.22,
              "msFromStart": 96750.94799999997
            },
            {
              "lat": 43.76947890000001,
              "lng": -116.09874484444445,
              "alt": 1991.7115555555556,
              "speed2d": 8.003333333333334,
              "speed3d": 8.082222222222223,
              "msFromStart": 97245.3692839506
            },
            {
              "lat": 43.76945312222222,
              "lng": -116.09870752222223,
              "alt": 1991.677888888889,
              "speed2d": 8.069777777777778,
              "speed3d": 8.2,
              "msFromStart": 97739.65200000002
            },
            {
              "lat": 43.76943463333333,
              "lng": -116.09867187777778,
              "alt": 1990.7968888888888,
              "speed2d": 7.878444444444444,
              "speed3d": 8.042222222222222,
              "msFromStart": 98234.01965432102
            },
            {
              "lat": 43.76942842222222,
              "lng": -116.09864175555555,
              "alt": 1988.132,
              "speed2d": 7.328111111111112,
              "speed3d": 7.5455555555555565,
              "msFromStart": 98729.04400000002
            },
            {
              "lat": 43.769424640000004,
              "lng": -116.09860873000001,
              "alt": 1985.6080000000002,
              "speed2d": 6.8617,
              "speed3d": 7.1080000000000005,
              "msFromStart": 99251.8666666667
            },
            {
              "lat": 43.76941976666667,
              "lng": -116.0985711888889,
              "alt": 1982.8478888888887,
              "speed2d": 6.926,
              "speed3d": 7.208888888888889,
              "msFromStart": 99775.54488888883
            },
            {
              "lat": 43.76941897777778,
              "lng": -116.09854090000002,
              "alt": 1979.4833333333333,
              "speed2d": 6.5986666666666665,
              "speed3d": 7.005555555555555,
              "msFromStart": 100271.54059259259
            },
            {
              "lat": 43.76941728888889,
              "lng": -116.09851611111111,
              "alt": 1975.8078888888888,
              "speed2d": 5.9945555555555545,
              "speed3d": 6.56888888888889,
              "msFromStart": 100766.59111111116
            },
            {
              "lat": 43.769404977777775,
              "lng": -116.09849061111112,
              "alt": 1973.7924444444445,
              "speed2d": 5.728,
              "speed3d": 6.273333333333333,
              "msFromStart": 101261.37420662772
            },
            {
              "lat": 43.76937943333334,
              "lng": -116.09845765555556,
              "alt": 1973.1901111111113,
              "speed2d": 6.471444444444444,
              "speed3d": 6.822222222222222,
              "msFromStart": 101755.46568421058
            },
            {
              "lat": 43.76934703333333,
              "lng": -116.09842045555554,
              "alt": 1972.4515555555556,
              "speed2d": 7.561222222222222,
              "speed3d": 7.898888888888889,
              "msFromStart": 102249.66894866801
            },
            {
              "lat": 43.769317244444444,
              "lng": -116.09838974444445,
              "alt": 1971.1694444444443,
              "speed2d": 7.867111111111111,
              "speed3d": 8.25,
              "msFromStart": 102744.97600000007
            },
            {
              "lat": 43.76928193333333,
              "lng": -116.09836192222221,
              "alt": 1970.1614444444442,
              "speed2d": 8.288666666666668,
              "speed3d": 8.613333333333333,
              "msFromStart": 103240.37758024698
            },
            {
              "lat": 43.7692416,
              "lng": -116.09833345555555,
              "alt": 1969.4505555555556,
              "speed2d": 8.873777777777779,
              "speed3d": 9.156666666666666,
              "msFromStart": 103735.25200000001
            },
            {
              "lat": 43.76920039999999,
              "lng": -116.09830715555556,
              "alt": 1968.718222222222,
              "speed2d": 9.240666666666668,
              "speed3d": 9.565555555555555,
              "msFromStart": 104230.20790123456
            },
            {
              "lat": 43.76916007777777,
              "lng": -116.09828425555555,
              "alt": 1968.2054444444443,
              "speed2d": 9.346333333333334,
              "speed3d": 9.644444444444444,
              "msFromStart": 104726.18800000005
            },
            {
              "lat": 43.769117060000006,
              "lng": -116.09826469999999,
              "alt": 1967.2291000000002,
              "speed2d": 9.3031,
              "speed3d": 9.597,
              "msFromStart": 105249.75511111112
            },
            {
              "lat": 43.76907102222223,
              "lng": -116.09824627777776,
              "alt": 1966.0773333333332,
              "speed2d": 9.453222222222221,
              "speed3d": 9.759999999999998,
              "msFromStart": 105772.64711111109
            },
            {
              "lat": 43.769026988888896,
              "lng": -116.09823252222222,
              "alt": 1964.6943333333331,
              "speed2d": 9.643111111111113,
              "speed3d": 9.944444444444443,
              "msFromStart": 106267.72859259255
            },
            {
              "lat": 43.768986088888894,
              "lng": -116.09822361111111,
              "alt": 1963.345,
              "speed2d": 9.422333333333334,
              "speed3d": 9.808888888888891,
              "msFromStart": 106762.01599999996
            },
            {
              "lat": 43.76894585555556,
              "lng": -116.0982185111111,
              "alt": 1961.728111111111,
              "speed2d": 9.154,
              "speed3d": 9.530000000000001,
              "msFromStart": 107256.37708641971
            },
            {
              "lat": 43.76890421111112,
              "lng": -116.09821138888888,
              "alt": 1961.1321111111113,
              "speed2d": 8.97,
              "speed3d": 9.354444444444443,
              "msFromStart": 107751.672
            },
            {
              "lat": 43.76886024444444,
              "lng": -116.09819683333333,
              "alt": 1960.7357777777777,
              "speed2d": 9.054333333333332,
              "speed3d": 9.382222222222222,
              "msFromStart": 108247.15851851852
            },
            {
              "lat": 43.76881502222222,
              "lng": -116.0981805888889,
              "alt": 1960.6524444444444,
              "speed2d": 9.048222222222222,
              "speed3d": 9.395555555555555,
              "msFromStart": 108742.88000000006
            },
            {
              "lat": 43.76876558888889,
              "lng": -116.0981719888889,
              "alt": 1960.7459999999999,
              "speed2d": 9.079333333333333,
              "speed3d": 9.364444444444445,
              "msFromStart": 109238.55111111117
            },
            {
              "lat": 43.7687147,
              "lng": -116.09817068888889,
              "alt": 1961.0447777777777,
              "speed2d": 9.514,
              "speed3d": 9.76222222222222,
              "msFromStart": 109733.68000000005
            },
            {
              "lat": 43.76866599999999,
              "lng": -116.09817498888889,
              "alt": 1961.3904444444447,
              "speed2d": 9.727888888888888,
              "speed3d": 9.945555555555554,
              "msFromStart": 110228.49975308648
            },
            {
              "lat": 43.76861229,
              "lng": -116.09817894,
              "alt": 1961.7108999999998,
              "speed2d": 9.9319,
              "speed3d": 10.187999999999997,
              "msFromStart": 110749.3791111111
            },
            {
              "lat": 43.76856165555555,
              "lng": -116.09818297777778,
              "alt": 1962.241,
              "speed2d": 9.51611111111111,
              "speed3d": 9.769999999999998,
              "msFromStart": 111270.40258869395
            },
            {
              "lat": 43.768516211111105,
              "lng": -116.09818651111112,
              "alt": 1962.496888888889,
              "speed2d": 9.056111111111111,
              "speed3d": 9.322222222222223,
              "msFromStart": 111765.48042105266
            },
            {
              "lat": 43.76847270000001,
              "lng": -116.0981883,
              "alt": 1962.3671111111112,
              "speed2d": 8.787333333333333,
              "speed3d": 8.98,
              "msFromStart": 112260.72973099418
            },
            {
              "lat": 43.76843108888889,
              "lng": -116.09819396666667,
              "alt": 1961.8371111111114,
              "speed2d": 8.907555555555556,
              "speed3d": 9.081111111111113,
              "msFromStart": 112756.13599999994
            },
            {
              "lat": 43.76838338888889,
              "lng": -116.09819542222222,
              "alt": 1962.2323333333334,
              "speed2d": 9.102888888888888,
              "speed3d": 9.256666666666666,
              "msFromStart": 113251.69441975305
            },
            {
              "lat": 43.76833376666667,
              "lng": -116.0981961,
              "alt": 1962.8733333333334,
              "speed2d": 9.225777777777779,
              "speed3d": 9.39111111111111,
              "msFromStart": 113748.02399999996
            },
            {
              "lat": 43.76828557777778,
              "lng": -116.09819823333333,
              "alt": 1963.4208888888888,
              "speed2d": 9.144333333333332,
              "speed3d": 9.284444444444443,
              "msFromStart": 114244.18271604934
            },
            {
              "lat": 43.768236599999994,
              "lng": -116.09819982222221,
              "alt": 1963.8772222222224,
              "speed2d": 9.28988888888889,
              "speed3d": 9.425555555555556,
              "msFromStart": 114738.52800000002
            },
            {
              "lat": 43.768187811111105,
              "lng": -116.09820134444443,
              "alt": 1964.2452222222219,
              "speed2d": 9.244444444444445,
              "speed3d": 9.373333333333335,
              "msFromStart": 115232.64760493829
            },
            {
              "lat": 43.76813827777778,
              "lng": -116.09820142222222,
              "alt": 1964.6657777777777,
              "speed2d": 9.415222222222223,
              "speed3d": 9.54111111111111,
              "msFromStart": 115727.20400000006
            },
            {
              "lat": 43.76808801,
              "lng": -116.09820442,
              "alt": 1965.3721,
              "speed2d": 9.2497,
              "speed3d": 9.386999999999999,
              "msFromStart": 116249.39164912284
            },
            {
              "lat": 43.768041277777776,
              "lng": -116.09820843333331,
              "alt": 1965.765222222222,
              "speed2d": 8.745111111111111,
              "speed3d": 8.93,
              "msFromStart": 116771.95410526315
            },
            {
              "lat": 43.767998122222224,
              "lng": -116.09821086666668,
              "alt": 1966.078,
              "speed2d": 8.50211111111111,
              "speed3d": 8.629999999999999,
              "msFromStart": 117267.12105003248
            },
            {
              "lat": 43.76795758888889,
              "lng": -116.09821616666667,
              "alt": 1966.2437777777775,
              "speed2d": 8.127,
              "speed3d": 8.275555555555556,
              "msFromStart": 117762.63599999994
            },
            {
              "lat": 43.767918144444444,
              "lng": -116.09822082222222,
              "alt": 1966.6877777777777,
              "speed2d": 7.841888888888889,
              "speed3d": 7.975555555555555,
              "msFromStart": 118258.165037037
            },
            {
              "lat": 43.76787950000001,
              "lng": -116.09822518888889,
              "alt": 1967.2517777777775,
              "speed2d": 7.610444444444445,
              "speed3d": 7.732222222222222,
              "msFromStart": 118753.43200000002
            },
            {
              "lat": 43.76784084444445,
              "lng": -116.09822637777779,
              "alt": 1967.5614444444445,
              "speed2d": 7.476111111111111,
              "speed3d": 7.58111111111111,
              "msFromStart": 119248.56612345681
            },
            {
              "lat": 43.76780373333333,
              "lng": -116.0982261111111,
              "alt": 1967.8723333333335,
              "speed2d": 7.245333333333334,
              "speed3d": 7.369999999999998,
              "msFromStart": 119743.15200000005
            },
            {
              "lat": 43.767765144444446,
              "lng": -116.09822227777778,
              "alt": 1968.687777777778,
              "speed2d": 7.334222222222222,
              "speed3d": 7.42,
              "msFromStart": 120237.7008395062
            },
            {
              "lat": 43.76772943333333,
              "lng": -116.09821865555556,
              "alt": 1969.5673333333334,
              "speed2d": 6.958333333333333,
              "speed3d": 7.1066666666666665,
              "msFromStart": 120732.57199999994
            },
            {
              "lat": 43.767696300000004,
              "lng": -116.09821348888889,
              "alt": 1970.470888888889,
              "speed2d": 6.5664444444444445,
              "speed3d": 6.663333333333333,
              "msFromStart": 121227.50117218969
            },
            {
              "lat": 43.76766236,
              "lng": -116.09820114000001,
              "alt": 1970.6238,
              "speed2d": 6.549300000000001,
              "speed3d": 6.601,
              "msFromStart": 121749.95999999995
            },
            {
              "lat": 43.767626911111115,
              "lng": -116.09818808888888,
              "alt": 1970.6129999999998,
              "speed2d": 6.881666666666666,
              "speed3d": 6.91,
              "msFromStart": 122272.62080311887
            },
            {
              "lat": 43.7675935,
              "lng": -116.09817723333333,
              "alt": 1970.8244444444445,
              "speed2d": 7.005333333333334,
              "speed3d": 7.082222222222223,
              "msFromStart": 122768.98400000004
            },
            {
              "lat": 43.76756143333333,
              "lng": -116.09816716666666,
              "alt": 1971.0911111111109,
              "speed2d": 6.760111111111111,
              "speed3d": 6.832222222222223,
              "msFromStart": 123265.32276543214
            },
            {
              "lat": 43.7675298111111,
              "lng": -116.09815514444445,
              "alt": 1971.2321111111112,
              "speed2d": 6.629000000000001,
              "speed3d": 6.697777777777777,
              "msFromStart": 123760.31200000005
            },
            {
              "lat": 43.76750055555555,
              "lng": -116.09814521111112,
              "alt": 1971.5873333333334,
              "speed2d": 6.27288888888889,
              "speed3d": 6.376666666666667,
              "msFromStart": 124254.92691358029
            },
            {
              "lat": 43.76747066666667,
              "lng": -116.09813351111112,
              "alt": 1971.9204444444445,
              "speed2d": 6.274444444444446,
              "speed3d": 6.3100000000000005,
              "msFromStart": 124748.60800000001
            },
            {
              "lat": 43.76743937777778,
              "lng": -116.09811894444445,
              "alt": 1972.3663333333334,
              "speed2d": 6.505888888888888,
              "speed3d": 6.514444444444445,
              "msFromStart": 125242.34533073424
            },
            {
              "lat": 43.7674059,
              "lng": -116.09810538888888,
              "alt": 1973.1035555555557,
              "speed2d": 6.834333333333333,
              "speed3d": 6.846666666666668,
              "msFromStart": 125737.35957894739
            },
            {
              "lat": 43.767372644444436,
              "lng": -116.09809194444445,
              "alt": 1973.7815555555558,
              "speed2d": 7.0905555555555555,
              "speed3d": 7.088888888888889,
              "msFromStart": 126232.59859649124
            },
            {
              "lat": 43.7673407,
              "lng": -116.09807751111111,
              "alt": 1974.1275555555555,
              "speed2d": 7.235444444444445,
              "speed3d": 7.2555555555555555,
              "msFromStart": 126727.99199999994
            },
            {
              "lat": 43.767308799999995,
              "lng": -116.09806031,
              "alt": 1974.0298000000003,
              "speed2d": 7.1377,
              "speed3d": 7.185,
              "msFromStart": 127250.97955555552
            },
            {
              "lat": 43.76727647777777,
              "lng": -116.09803774444444,
              "alt": 1974.2165555555555,
              "speed2d": 7.231111111111112,
              "speed3d": 7.23888888888889,
              "msFromStart": 127774.18799999997
            },
            {
              "lat": 43.7672473,
              "lng": -116.09801481111111,
              "alt": 1974.3810000000003,
              "speed2d": 7.296777777777778,
              "speed3d": 7.324444444444445,
              "msFromStart": 128269.72325925923
            },
            {
              "lat": 43.76721965555556,
              "lng": -116.09799115555555,
              "alt": 1974.5077777777776,
              "speed2d": 7.212444444444444,
              "speed3d": 7.2555555555555555,
              "msFromStart": 128764.15600000006
            },
            {
              "lat": 43.76719357777778,
              "lng": -116.09796541111112,
              "alt": 1974.3016666666667,
              "speed2d": 7.154444444444444,
              "speed3d": 7.194444444444445,
              "msFromStart": 129258.4983703704
            },
            {
              "lat": 43.76716736666667,
              "lng": -116.09793536666668,
              "alt": 1974.120888888889,
              "speed2d": 7.378888888888889,
              "speed3d": 7.341111111111111,
              "msFromStart": 129753.39199999995
            },
            {
              "lat": 43.76714074444444,
              "lng": -116.09790050000001,
              "alt": 1973.676777777778,
              "speed2d": 7.796444444444444,
              "speed3d": 7.8022222222222215,
              "msFromStart": 130248.35723456785
            },
            {
              "lat": 43.767117299999995,
              "lng": -116.09786404444445,
              "alt": 1973.4492222222223,
              "speed2d": 7.763777777777778,
              "speed3d": 7.817777777777778,
              "msFromStart": 130743.20799999997
            },
            {
              "lat": 43.76709465555555,
              "lng": -116.09782669999998,
              "alt": 1973.3604444444443,
              "speed2d": 7.796888888888889,
              "speed3d": 7.797777777777778,
              "msFromStart": 131238.12764132553
            },
            {
              "lat": 43.767070000000004,
              "lng": -116.09778387777779,
              "alt": 1972.833888888889,
              "speed2d": 8.288777777777778,
              "speed3d": 8.27111111111111,
              "msFromStart": 131733.5818947368
            },
            {
              "lat": 43.76704515555555,
              "lng": -116.09774256666668,
              "alt": 1972.5684444444444,
              "speed2d": 8.428444444444443,
              "speed3d": 8.451111111111112,
              "msFromStart": 132229.13194541904
            },
            {
              "lat": 43.76702036666667,
              "lng": -116.09770373333336,
              "alt": 1971.851111111111,
              "speed2d": 8.437555555555555,
              "speed3d": 8.474444444444444,
              "msFromStart": 132724.7635555555
            },
            {
              "lat": 43.766995730000005,
              "lng": -116.09766939000002,
              "alt": 1970.9043000000001,
              "speed2d": 7.908899999999999,
              "speed3d": 8.067999999999998,
              "msFromStart": 133247.84911111108
            },
            {
              "lat": 43.76696827777778,
              "lng": -116.09764397777779,
              "alt": 1970.3462222222222,
              "speed2d": 7.491555555555555,
              "speed3d": 7.514444444444443,
              "msFromStart": 133770.21600000013
            },
            {
              "lat": 43.76693651111112,
              "lng": -116.09762065555557,
              "alt": 1969.8566666666663,
              "speed2d": 7.764555555555555,
              "speed3d": 7.783333333333333,
              "msFromStart": 134265.04671604946
            },
            {
              "lat": 43.7669,
              "lng": -116.09759811111111,
              "alt": 1969.461888888889,
              "speed2d": 8.213444444444443,
              "speed3d": 8.193333333333333,
              "msFromStart": 134760.50399999993
            },
            {
              "lat": 43.766861822222225,
              "lng": -116.09757753333335,
              "alt": 1968.8132222222223,
              "speed2d": 8.531777777777778,
              "speed3d": 8.556666666666667,
              "msFromStart": 135255.98795061724
            },
            {
              "lat": 43.76682257777777,
              "lng": -116.09755779999999,
              "alt": 1968.244,
              "speed2d": 8.818666666666667,
              "speed3d": 8.841111111111111,
              "msFromStart": 135751.0079999999
            },
            {
              "lat": 43.76678361111111,
              "lng": -116.09754083333334,
              "alt": 1967.6526666666666,
              "speed2d": 8.988444444444443,
              "speed3d": 9.037777777777777,
              "msFromStart": 136245.82464457434
            },
            {
              "lat": 43.76674468888889,
              "lng": -116.09752566666668,
              "alt": 1966.8685555555555,
              "speed2d": 9.018444444444444,
              "speed3d": 9.053333333333335,
              "msFromStart": 136739.86442105257
            },
            {
              "lat": 43.76670417777778,
              "lng": -116.09750988888887,
              "alt": 1965.8604444444445,
              "speed2d": 9.286333333333332,
              "speed3d": 9.334444444444445,
              "msFromStart": 137233.89592202718
            },
            {
              "lat": 43.76666258888889,
              "lng": -116.09749823333334,
              "alt": 1965.1285555555553,
              "speed2d": 9.330333333333334,
              "speed3d": 9.393333333333334,
              "msFromStart": 137729.1182222222
            },
            {
              "lat": 43.766618109999996,
              "lng": -116.09748764999999,
              "alt": 1964.8083,
              "speed2d": 9.3428,
              "speed3d": 9.399000000000001,
              "msFromStart": 138252.0986666667
            },
            {
              "lat": 43.76657374444444,
              "lng": -116.09748052222221,
              "alt": 1964.8717777777777,
              "speed2d": 9.205777777777778,
              "speed3d": 9.283333333333331,
              "msFromStart": 138774.59999999998
            },
            {
              "lat": 43.76653236666667,
              "lng": -116.09747238888887,
              "alt": 1964.4712222222222,
              "speed2d": 9.080666666666666,
              "speed3d": 9.143333333333334,
              "msFromStart": 139269.62943209877
            },
            {
              "lat": 43.766492166666666,
              "lng": -116.0974636111111,
              "alt": 1963.743,
              "speed2d": 8.926555555555556,
              "speed3d": 8.99,
              "msFromStart": 139765.42400000003
            },
            {
              "lat": 43.76645078888889,
              "lng": -116.09745506666667,
              "alt": 1963.3067777777778,
              "speed2d": 8.959222222222222,
              "speed3d": 9,
              "msFromStart": 140261.22844444448
            },
            {
              "lat": 43.76640874444445,
              "lng": -116.0974469111111,
              "alt": 1962.8914444444442,
              "speed2d": 9.072555555555555,
              "speed3d": 9.10888888888889,
              "msFromStart": 140756.3280000001
            },
            {
              "lat": 43.76636837777777,
              "lng": -116.09744013333334,
              "alt": 1962.483888888889,
              "speed2d": 8.926444444444444,
              "speed3d": 9.022222222222222,
              "msFromStart": 141251.29947238474
            },
            {
              "lat": 43.76633057777778,
              "lng": -116.09743484444444,
              "alt": 1961.864111111111,
              "speed2d": 8.544333333333332,
              "speed3d": 8.615555555555556,
              "msFromStart": 141746.19452631578
            },
            {
              "lat": 43.76629052222222,
              "lng": -116.09742876666667,
              "alt": 1961.529,
              "speed2d": 8.563444444444444,
              "speed3d": 8.635555555555557,
              "msFromStart": 142241.07568031191
            },
            {
              "lat": 43.766252377777775,
              "lng": -116.09742155555556,
              "alt": 1960.7664444444445,
              "speed2d": 8.60511111111111,
              "speed3d": 8.635555555555555,
              "msFromStart": 142735.94311111118
            },
            {
              "lat": 43.766213855555556,
              "lng": -116.09741366666667,
              "alt": 1960.1132222222222,
              "speed2d": 8.567777777777778,
              "speed3d": 8.658888888888889,
              "msFromStart": 143230.75733333337
            },
            {
              "lat": 43.766172819999994,
              "lng": -116.09740614,
              "alt": 1959.8823000000002,
              "speed2d": 8.3614,
              "speed3d": 8.427,
              "msFromStart": 143752.5288888888
            },
            {
              "lat": 43.76613185555555,
              "lng": -116.09739656666667,
              "alt": 1959.6925555555554,
              "speed2d": 8.281888888888888,
              "speed3d": 8.331111111111111,
              "msFromStart": 144274.3439012345
            },
            {
              "lat": 43.76609489999999,
              "lng": -116.09738553333334,
              "alt": 1959.2912222222224,
              "speed2d": 8.216777777777779,
              "speed3d": 8.307777777777778,
              "msFromStart": 144769.7280000001
            },
            {
              "lat": 43.766060744444445,
              "lng": -116.0973754111111,
              "alt": 1959.0688888888888,
              "speed2d": 7.756777777777777,
              "speed3d": 7.841111111111111,
              "msFromStart": 145265.20296296303
            },
            {
              "lat": 43.76602682222222,
              "lng": -116.09736167777777,
              "alt": 1958.6153333333334,
              "speed2d": 7.768555555555555,
              "speed3d": 7.796666666666668,
              "msFromStart": 145760.20799999996
            },
            {
              "lat": 43.7659928,
              "lng": -116.09734342222222,
              "alt": 1957.8081111111114,
              "speed2d": 7.894,
              "speed3d": 7.93,
              "msFromStart": 146255.23330994148
            },
            {
              "lat": 43.765958455555555,
              "lng": -116.09732227777778,
              "alt": 1957.2208888888888,
              "speed2d": 8.132333333333333,
              "speed3d": 8.17,
              "msFromStart": 146750.85221052627
            },
            {
              "lat": 43.76592672222222,
              "lng": -116.09730118888888,
              "alt": 1956.7074444444445,
              "speed2d": 7.809444444444444,
              "speed3d": 7.956666666666666,
              "msFromStart": 147246.46084990248
            },
            {
              "lat": 43.765899233333336,
              "lng": -116.0972820111111,
              "alt": 1956.371666666667,
              "speed2d": 7.267888888888888,
              "speed3d": 7.335555555555556,
              "msFromStart": 147740.99377777774
            },
            {
              "lat": 43.765867911111116,
              "lng": -116.09725491111112,
              "alt": 1955.8473333333332,
              "speed2d": 7.737444444444444,
              "speed3d": 7.7155555555555555,
              "msFromStart": 148235.30044444438
            },
            {
              "lat": 43.765837299999994,
              "lng": -116.09722688888891,
              "alt": 1954.9247777777778,
              "speed2d": 7.840222222222222,
              "speed3d": 7.9255555555555555,
              "msFromStart": 148730.03377777783
            },
            {
              "lat": 43.7658068,
              "lng": -116.09719997999998,
              "alt": 1954.2138,
              "speed2d": 7.591900000000001,
              "speed3d": 7.670999999999999,
              "msFromStart": 149252.3675555556
            },
            {
              "lat": 43.76577392222222,
              "lng": -116.09716762222222,
              "alt": 1953.3596666666665,
              "speed2d": 8.069111111111111,
              "speed3d": 8.105555555555556,
              "msFromStart": 149774.72000000003
            },
            {
              "lat": 43.765742388888896,
              "lng": -116.09713599999999,
              "alt": 1952.620111111111,
              "speed2d": 8.325666666666667,
              "speed3d": 8.373333333333333,
              "msFromStart": 150269.56622222226
            },
            {
              "lat": 43.76571047777778,
              "lng": -116.09710224444444,
              "alt": 1952.0106666666666,
              "speed2d": 8.637666666666666,
              "speed3d": 8.705555555555556,
              "msFromStart": 150764.304
            },
            {
              "lat": 43.765677922222224,
              "lng": -116.09706984444445,
              "alt": 1951.505777777778,
              "speed2d": 8.611777777777778,
              "speed3d": 8.687777777777777,
              "msFromStart": 151259.185351527
            },
            {
              "lat": 43.76564667777778,
              "lng": -116.09703688888888,
              "alt": 1950.699555555556,
              "speed2d": 8.673555555555556,
              "speed3d": 8.736666666666668,
              "msFromStart": 151755.05094736855
            },
            {
              "lat": 43.765613944444446,
              "lng": -116.09700114444443,
              "alt": 1949.900222222222,
              "speed2d": 9.025,
              "speed3d": 9.076666666666666,
              "msFromStart": 152251.04804678375
            },
            {
              "lat": 43.76558156666667,
              "lng": -116.09696627777778,
              "alt": 1949.2129999999997,
              "speed2d": 9.105333333333334,
              "speed3d": 9.205555555555556,
              "msFromStart": 152746.74666666673
            },
            {
              "lat": 43.76554954444444,
              "lng": -116.09693072222223,
              "alt": 1948.5477777777778,
              "speed2d": 9.135777777777779,
              "speed3d": 9.216666666666665,
              "msFromStart": 153242.2557037038
            },
            {
              "lat": 43.76551702222221,
              "lng": -116.09689578888887,
              "alt": 1948.1938888888888,
              "speed2d": 9.076,
              "speed3d": 9.197777777777778,
              "msFromStart": 153736.615111111
            },
            {
              "lat": 43.76548652222222,
              "lng": -116.09686427777778,
              "alt": 1947.914333333333,
              "speed2d": 8.781666666666665,
              "speed3d": 8.886666666666667,
              "msFromStart": 154230.67466666654
            },
            {
              "lat": 43.76545485,
              "lng": -116.09683147,
              "alt": 1947.0925999999997,
              "speed2d": 8.6481,
              "speed3d": 8.73,
              "msFromStart": 154752.0488888888
            },
            {
              "lat": 43.76542128888889,
              "lng": -116.09679402222223,
              "alt": 1946.2824444444443,
              "speed2d": 8.834333333333333,
              "speed3d": 8.894444444444444,
              "msFromStart": 155273.71002469125
            },
            {
              "lat": 43.76539208888889,
              "lng": -116.09675455555558,
              "alt": 1945.5527777777777,
              "speed2d": 8.980777777777778,
              "speed3d": 9.076666666666668,
              "msFromStart": 155769.87199999994
            },
            {
              "lat": 43.765368055555555,
              "lng": -116.09671601111111,
              "alt": 1944.8217777777777,
              "speed2d": 8.685333333333334,
              "speed3d": 8.806666666666667,
              "msFromStart": 156266.13513190378
            },
            {
              "lat": 43.76534746666667,
              "lng": -116.09667177777777,
              "alt": 1944.1815555555559,
              "speed2d": 8.59911111111111,
              "speed3d": 8.7,
              "msFromStart": 156761.0753684211
            },
            {
              "lat": 43.76533167777778,
              "lng": -116.0966228888889,
              "alt": 1943.2485555555554,
              "speed2d": 8.629222222222223,
              "speed3d": 8.716666666666667,
              "msFromStart": 157255.80500584797
            },
            {
              "lat": 43.765321033333336,
              "lng": -116.0965710888889,
              "alt": 1942.607111111111,
              "speed2d": 8.643777777777778,
              "speed3d": 8.751111111111111,
              "msFromStart": 157750.5973333333
            },
            {
              "lat": 43.765315799999996,
              "lng": -116.09651848888888,
              "alt": 1942.052555555556,
              "speed2d": 8.626111111111111,
              "speed3d": 8.718888888888888,
              "msFromStart": 158245.36681481477
            },
            {
              "lat": 43.76531393333334,
              "lng": -116.09646395555555,
              "alt": 1941.4164444444443,
              "speed2d": 8.759555555555556,
              "speed3d": 8.843333333333334,
              "msFromStart": 158739.75111111105
            },
            {
              "lat": 43.76531554444445,
              "lng": -116.09641095555557,
              "alt": 1940.6787777777777,
              "speed2d": 8.761333333333333,
              "speed3d": 8.868888888888888,
              "msFromStart": 159234.1072592592
            },
            {
              "lat": 43.76531886666667,
              "lng": -116.09635665555558,
              "alt": 1940.1937777777778,
              "speed2d": 8.791555555555556,
              "speed3d": 8.881111111111112,
              "msFromStart": 159729.14488888884
            },
            {
              "lat": 43.7653175,
              "lng": -116.09629699999999,
              "alt": 1939.0289000000002,
              "speed2d": 8.939800000000002,
              "speed3d": 9.038,
              "msFromStart": 160251.84755555552
            },
            {
              "lat": 43.76531103333333,
              "lng": -116.09624004444444,
              "alt": 1938.2564444444447,
              "speed2d": 8.688777777777778,
              "speed3d": 8.853333333333333,
              "msFromStart": 160774.4320000001
            },
            {
              "lat": 43.76529902222222,
              "lng": -116.09619242222223,
              "alt": 1937.4072222222221,
              "speed2d": 8.293444444444445,
              "speed3d": 8.45111111111111,
              "msFromStart": 161269.57231449
            },
            {
              "lat": 43.7652828,
              "lng": -116.09614718888888,
              "alt": 1936.2169999999999,
              "speed2d": 8.156444444444444,
              "speed3d": 8.283333333333331,
              "msFromStart": 161765.2269473685
            },
            {
              "lat": 43.76526101111111,
              "lng": -116.09609777777777,
              "alt": 1935.2664444444445,
              "speed2d": 8.86588888888889,
              "speed3d": 8.91,
              "msFromStart": 162260.93367641332
            },
            {
              "lat": 43.76523396666667,
              "lng": -116.09604728888888,
              "alt": 1934.2618888888887,
              "speed2d": 9.509444444444446,
              "speed3d": 9.6,
              "msFromStart": 162756.3182222222
            },
            {
              "lat": 43.76520217777778,
              "lng": -116.09600423333333,
              "alt": 1933.1617777777778,
              "speed2d": 9.899999999999999,
              "speed3d": 10,
              "msFromStart": 163251.63881481477
            },
            {
              "lat": 43.765166400000005,
              "lng": -116.09597400000001,
              "alt": 1932.181,
              "speed2d": 9.94888888888889,
              "speed3d": 10.157777777777778,
              "msFromStart": 163747.12533333342
            },
            {
              "lat": 43.765133666666664,
              "lng": -116.09596298888889,
              "alt": 1930.9108888888888,
              "speed2d": 9.052,
              "speed3d": 9.332222222222223,
              "msFromStart": 164242.59940740748
            },
            {
              "lat": 43.76510448888889,
              "lng": -116.09596518888888,
              "alt": 1928.4774444444445,
              "speed2d": 8.154333333333334,
              "speed3d": 8.48,
              "msFromStart": 164737.53422222214
            }
          ]
        }
      ]
    },
    {
      "id": "trail-wildcat",
      "pathType": "trail",
      "name": "Wildcat",
      "difficulty": "black",
      "videos": [
        {
          "videoId": "GX010066",
          "pathId": "trail-wildcat",
          "startSec": 129,
          "endSec": 320,
          "id": "video-segment-26",
          "gps": [
            {
              "lat": 43.76555593333334,
              "lng": -116.0865595,
              "alt": 2184.2464444444445,
              "speed2d": 6.629666666666667,
              "speed3d": 6.746666666666667,
              "msFromStart": 129273.60761013642
            },
            {
              "lat": 43.76552988888889,
              "lng": -116.08655877777778,
              "alt": 2183.800777777778,
              "speed2d": 6.057555555555555,
              "speed3d": 6.2122222222222225,
              "msFromStart": 129768.76800000003
            },
            {
              "lat": 43.76550346666667,
              "lng": -116.08655923333333,
              "alt": 2183.4066666666668,
              "speed2d": 6.087666666666666,
              "speed3d": 6.1722222222222225,
              "msFromStart": 130264.01787654322
            },
            {
              "lat": 43.7654769,
              "lng": -116.08655478888888,
              "alt": 2182.6874444444443,
              "speed2d": 6.000555555555556,
              "speed3d": 6.1322222222222225,
              "msFromStart": 130760.05999999994
            },
            {
              "lat": 43.7654574888889,
              "lng": -116.08654364444445,
              "alt": 2181.771111111111,
              "speed2d": 5.1739999999999995,
              "speed3d": 5.433333333333334,
              "msFromStart": 131255.89817283943
            },
            {
              "lat": 43.76544485555555,
              "lng": -116.08653132222221,
              "alt": 2180.852888888889,
              "speed2d": 4.036,
              "speed3d": 4.365555555555555,
              "msFromStart": 131749.69999999998
            },
            {
              "lat": 43.76544157777778,
              "lng": -116.08652774444442,
              "alt": 2180.6115555555552,
              "speed2d": 2.4549999999999996,
              "speed3d": 2.9000000000000004,
              "msFromStart": 132243.3206445744
            },
            {
              "lat": 43.765440988888884,
              "lng": -116.0865257777778,
              "alt": 2180.328,
              "speed2d": 1.2527777777777778,
              "speed3d": 1.81,
              "msFromStart": 132737.8724210527
            },
            {
              "lat": 43.76544134444444,
              "lng": -116.08652424444446,
              "alt": 2180.1255555555554,
              "speed2d": 0.511888888888889,
              "speed3d": 1.2600000000000002,
              "msFromStart": 133232.63103313846
            },
            {
              "lat": 43.76544122222222,
              "lng": -116.0865216888889,
              "alt": 2180.1679999999997,
              "speed2d": 0.31577777777777777,
              "speed3d": 1.0933333333333333,
              "msFromStart": 133727.93155555543
            },
            {
              "lat": 43.765439570000005,
              "lng": -116.08652269,
              "alt": 2179.8369000000002,
              "speed2d": 0.2069,
              "speed3d": 1.031,
              "msFromStart": 134251.00244444434
            },
            {
              "lat": 43.7654419,
              "lng": -116.08652141111111,
              "alt": 2179.376444444445,
              "speed2d": 0.215,
              "speed3d": 1.04,
              "msFromStart": 134774.84799999997
            },
            {
              "lat": 43.765444966666664,
              "lng": -116.08651952222223,
              "alt": 2179.2352222222225,
              "speed2d": 0.3525555555555556,
              "speed3d": 1.0300000000000002,
              "msFromStart": 135271.06666666662
            },
            {
              "lat": 43.7654463,
              "lng": -116.08651910000002,
              "alt": 2178.9501111111113,
              "speed2d": 0.27366666666666667,
              "speed3d": 0.9744444444444444,
              "msFromStart": 135765.9840000001
            },
            {
              "lat": 43.765445355555556,
              "lng": -116.08651935555557,
              "alt": 2178.205111111111,
              "speed2d": 0.29966666666666664,
              "speed3d": 1.008888888888889,
              "msFromStart": 136260.5902222223
            },
            {
              "lat": 43.76544501111111,
              "lng": -116.0865181,
              "alt": 2177.636888888889,
              "speed2d": 0.24855555555555553,
              "speed3d": 1.03,
              "msFromStart": 136754.59999999995
            },
            {
              "lat": 43.76544929999999,
              "lng": -116.08651225555558,
              "alt": 2177.366555555556,
              "speed2d": 0.6191111111111112,
              "speed3d": 1.1388888888888888,
              "msFromStart": 137248.76000519813
            },
            {
              "lat": 43.76545532222222,
              "lng": -116.08650201111111,
              "alt": 2177.260222222222,
              "speed2d": 1.288111111111111,
              "speed3d": 1.561111111111111,
              "msFromStart": 137744.4328421052
            },
            {
              "lat": 43.76546185555556,
              "lng": -116.08648907777778,
              "alt": 2177.406,
              "speed2d": 1.8745555555555558,
              "speed3d": 2.0144444444444445,
              "msFromStart": 138240.21376998044
            },
            {
              "lat": 43.765468222222225,
              "lng": -116.08647424444445,
              "alt": 2177.3736666666664,
              "speed2d": 2.203888888888889,
              "speed3d": 2.3655555555555554,
              "msFromStart": 138734.59555555554
            },
            {
              "lat": 43.765475388888895,
              "lng": -116.0864622222222,
              "alt": 2177.0730000000003,
              "speed2d": 2.2413333333333334,
              "speed3d": 2.365555555555556,
              "msFromStart": 139228.701037037
            },
            {
              "lat": 43.76548633,
              "lng": -116.08644699999999,
              "alt": 2176.8921,
              "speed2d": 2.7345,
              "speed3d": 2.8200000000000003,
              "msFromStart": 139751.04266666668
            },
            {
              "lat": 43.76549991111111,
              "lng": -116.0864342888889,
              "alt": 2177.228888888889,
              "speed2d": 2.900666666666667,
              "speed3d": 2.9777777777777774,
              "msFromStart": 140273.69738271605
            },
            {
              "lat": 43.765515444444446,
              "lng": -116.08642333333333,
              "alt": 2177.5994444444445,
              "speed2d": 3.1527777777777777,
              "speed3d": 3.193333333333333,
              "msFromStart": 140769.6399999999
            },
            {
              "lat": 43.76553181111112,
              "lng": -116.08641589999999,
              "alt": 2178.037111111111,
              "speed2d": 3.317555555555556,
              "speed3d": 3.3688888888888884,
              "msFromStart": 141265.45817283943
            },
            {
              "lat": 43.765547311111106,
              "lng": -116.08640855555555,
              "alt": 2178.3348888888886,
              "speed2d": 3.4814444444444446,
              "speed3d": 3.4644444444444447,
              "msFromStart": 141759.72800000006
            },
            {
              "lat": 43.765565422222224,
              "lng": -116.08639697777778,
              "alt": 2177.9436666666666,
              "speed2d": 4.017,
              "speed3d": 3.971111111111111,
              "msFromStart": 142253.9267680312
            },
            {
              "lat": 43.76558705555556,
              "lng": -116.08637866666666,
              "alt": 2177.3723333333332,
              "speed2d": 4.882,
              "speed3d": 4.819999999999999,
              "msFromStart": 142749.2404210526
            },
            {
              "lat": 43.76560816666667,
              "lng": -116.08635664444444,
              "alt": 2176.981888888889,
              "speed2d": 5.380555555555555,
              "speed3d": 5.379999999999999,
              "msFromStart": 143244.80792202728
            },
            {
              "lat": 43.765626633333326,
              "lng": -116.08633418888888,
              "alt": 2176.6091111111114,
              "speed2d": 5.353555555555555,
              "speed3d": 5.402222222222222,
              "msFromStart": 143741.08622222216
            },
            {
              "lat": 43.765649511111114,
              "lng": -116.08631364444443,
              "alt": 2176.5055555555555,
              "speed2d": 5.497444444444445,
              "speed3d": 5.49,
              "msFromStart": 144237.40888888884
            },
            {
              "lat": 43.765678755555555,
              "lng": -116.08629556666668,
              "alt": 2176.503444444445,
              "speed2d": 6.110555555555556,
              "speed3d": 6.036666666666667,
              "msFromStart": 144732.3982222223
            },
            {
              "lat": 43.765708700000005,
              "lng": -116.08627307777776,
              "alt": 2176.139666666667,
              "speed2d": 6.821555555555555,
              "speed3d": 6.794444444444444,
              "msFromStart": 145226.9042962964
            },
            {
              "lat": 43.76573716,
              "lng": -116.08624695,
              "alt": 2175.4744,
              "speed2d": 6.913,
              "speed3d": 6.944000000000001,
              "msFromStart": 145747.27955555543
            },
            {
              "lat": 43.76576323333333,
              "lng": -116.08622602222223,
              "alt": 2175.224444444444,
              "speed2d": 6.704555555555555,
              "speed3d": 6.777777777777778,
              "msFromStart": 146267.6049382715
            },
            {
              "lat": 43.76578431111111,
              "lng": -116.08621204444444,
              "alt": 2175.145555555556,
              "speed2d": 6.156555555555555,
              "speed3d": 6.23111111111111,
              "msFromStart": 146762.54400000005
            },
            {
              "lat": 43.76580944444444,
              "lng": -116.08619861111112,
              "alt": 2174.750222222222,
              "speed2d": 6.158666666666667,
              "speed3d": 6.138888888888889,
              "msFromStart": 147257.82546588697
            },
            {
              "lat": 43.765835666666675,
              "lng": -116.08617911111111,
              "alt": 2174.1314444444442,
              "speed2d": 6.3374444444444435,
              "speed3d": 6.364444444444445,
              "msFromStart": 147753.21347368424
            },
            {
              "lat": 43.765860777777775,
              "lng": -116.0861562888889,
              "alt": 2173.256,
              "speed2d": 6.582,
              "speed3d": 6.535555555555558,
              "msFromStart": 148248.63335672521
            },
            {
              "lat": 43.76589114444444,
              "lng": -116.08613050000001,
              "alt": 2172.875888888889,
              "speed2d": 7.0713333333333335,
              "speed3d": 7.067777777777778,
              "msFromStart": 148744.19733333346
            },
            {
              "lat": 43.76591811111111,
              "lng": -116.08610698888887,
              "alt": 2172.34,
              "speed2d": 7.0811111111111105,
              "speed3d": 7.124444444444444,
              "msFromStart": 149239.7303703705
            },
            {
              "lat": 43.765947411111114,
              "lng": -116.0860814,
              "alt": 2171.7833333333333,
              "speed2d": 7.254555555555555,
              "speed3d": 7.276666666666667,
              "msFromStart": 149734.5937777778
            },
            {
              "lat": 43.76597394444444,
              "lng": -116.08605265555556,
              "alt": 2170.8992222222223,
              "speed2d": 7.433,
              "speed3d": 7.418888888888889,
              "msFromStart": 150229.2808888889
            },
            {
              "lat": 43.766003059999996,
              "lng": -116.0860103,
              "alt": 2169.9561000000003,
              "speed2d": 8.1553,
              "speed3d": 8.142,
              "msFromStart": 150751.35555555555
            },
            {
              "lat": 43.766035144444444,
              "lng": -116.08597028888889,
              "alt": 2169.4087777777777,
              "speed2d": 8.553666666666667,
              "speed3d": 8.565555555555555,
              "msFromStart": 151273.40701234568
            },
            {
              "lat": 43.76607302222222,
              "lng": -116.08593463333335,
              "alt": 2168.865555555555,
              "speed2d": 9.062333333333333,
              "speed3d": 9.048888888888888,
              "msFromStart": 151767.9759999999
            },
            {
              "lat": 43.76610573333333,
              "lng": -116.08589923333334,
              "alt": 2167.8233333333333,
              "speed2d": 9.264777777777777,
              "speed3d": 9.31,
              "msFromStart": 152262.45464327477
            },
            {
              "lat": 43.766138266666665,
              "lng": -116.08585723333334,
              "alt": 2167.2458888888887,
              "speed2d": 9.518888888888888,
              "speed3d": 9.535555555555556,
              "msFromStart": 152756.38821052638
            },
            {
              "lat": 43.766172366666666,
              "lng": -116.08581146666666,
              "alt": 2167.1485555555555,
              "speed2d": 9.963666666666667,
              "speed3d": 9.948888888888888,
              "msFromStart": 153250.37729434707
            },
            {
              "lat": 43.766211855555554,
              "lng": -116.08576483333334,
              "alt": 2166.3251111111113,
              "speed2d": 10.676333333333334,
              "speed3d": 10.648888888888889,
              "msFromStart": 153745.81511111112
            },
            {
              "lat": 43.76625373333333,
              "lng": -116.08572504444443,
              "alt": 2164.852555555555,
              "speed2d": 11.096444444444444,
              "speed3d": 11.085555555555556,
              "msFromStart": 154241.57244444443
            },
            {
              "lat": 43.76630367777778,
              "lng": -116.08568858888889,
              "alt": 2163.1820000000002,
              "speed2d": 11.590666666666667,
              "speed3d": 11.628888888888891,
              "msFromStart": 154736.90311111102
            },
            {
              "lat": 43.766353755555556,
              "lng": -116.08566254444445,
              "alt": 2161.6382222222223,
              "speed2d": 11.491555555555555,
              "speed3d": 11.59888888888889,
              "msFromStart": 155232.07496296283
            },
            {
              "lat": 43.766397833333336,
              "lng": -116.08563566666666,
              "alt": 2160.242,
              "speed2d": 11.148,
              "speed3d": 11.22888888888889,
              "msFromStart": 155726.72533333325
            },
            {
              "lat": 43.766443,
              "lng": -116.08560430000001,
              "alt": 2159.0055,
              "speed2d": 10.913899999999998,
              "speed3d": 11.031000000000002,
              "msFromStart": 156248.90888888884
            },
            {
              "lat": 43.76648483333333,
              "lng": -116.08557556666668,
              "alt": 2157.922777777778,
              "speed2d": 10.482444444444443,
              "speed3d": 10.619999999999997,
              "msFromStart": 156772.4240000001
            },
            {
              "lat": 43.766529388888884,
              "lng": -116.08555695555556,
              "alt": 2156.8814444444447,
              "speed2d": 10.244777777777779,
              "speed3d": 10.314444444444444,
              "msFromStart": 157268.5062404159
            },
            {
              "lat": 43.76658251111112,
              "lng": -116.08553864444445,
              "alt": 2155.8814444444447,
              "speed2d": 10.531444444444444,
              "speed3d": 10.583333333333332,
              "msFromStart": 157763.67494736833
            },
            {
              "lat": 43.76663631111111,
              "lng": -116.08552445555557,
              "alt": 2154.6623333333337,
              "speed2d": 10.873888888888889,
              "speed3d": 10.924444444444445,
              "msFromStart": 158258.67886159837
            },
            {
              "lat": 43.76668723333333,
              "lng": -116.08550821111112,
              "alt": 2153.5829999999996,
              "speed2d": 10.98488888888889,
              "speed3d": 11.07111111111111,
              "msFromStart": 158753.53244444449
            },
            {
              "lat": 43.76673317777777,
              "lng": -116.08548604444445,
              "alt": 2152.800111111111,
              "speed2d": 10.67811111111111,
              "speed3d": 10.818888888888889,
              "msFromStart": 159248.32770370378
            },
            {
              "lat": 43.7667804,
              "lng": -116.0854673888889,
              "alt": 2152.143111111111,
              "speed2d": 10.493444444444446,
              "speed3d": 10.615555555555556,
              "msFromStart": 159742.91555555566
            },
            {
              "lat": 43.766826433333335,
              "lng": -116.08544897777777,
              "alt": 2152.2208888888886,
              "speed2d": 10.211000000000002,
              "speed3d": 10.29,
              "msFromStart": 160237.5084444445
            },
            {
              "lat": 43.766877300000004,
              "lng": -116.08542711111112,
              "alt": 2152.133333333333,
              "speed2d": 10.334888888888887,
              "speed3d": 10.408888888888889,
              "msFromStart": 160732.6702222222
            },
            {
              "lat": 43.766926822222224,
              "lng": -116.08540578888888,
              "alt": 2151.534333333333,
              "speed2d": 10.37811111111111,
              "speed3d": 10.441111111111113,
              "msFromStart": 161227.9197037037
            },
            {
              "lat": 43.76697852000001,
              "lng": -116.08538262,
              "alt": 2150.8664000000003,
              "speed2d": 10.3566,
              "speed3d": 10.457,
              "msFromStart": 161750.09999999992
            },
            {
              "lat": 43.767026233333326,
              "lng": -116.08535873333334,
              "alt": 2151.006666666666,
              "speed2d": 10.029555555555557,
              "speed3d": 10.143333333333333,
              "msFromStart": 162272.2286783625
            },
            {
              "lat": 43.76706947777778,
              "lng": -116.08533674444443,
              "alt": 2150.7663333333335,
              "speed2d": 9.68888888888889,
              "speed3d": 9.784444444444446,
              "msFromStart": 162767.39789473673
            },
            {
              "lat": 43.76711532222222,
              "lng": -116.08531109999998,
              "alt": 2149.927222222222,
              "speed2d": 9.883444444444445,
              "speed3d": 9.913333333333334,
              "msFromStart": 163262.69461208564
            },
            {
              "lat": 43.76715962222222,
              "lng": -116.08528794444443,
              "alt": 2149.5823333333333,
              "speed2d": 9.537666666666667,
              "speed3d": 9.69,
              "msFromStart": 163758.41955555562
            },
            {
              "lat": 43.7672008,
              "lng": -116.08526783333333,
              "alt": 2149.3017777777777,
              "speed2d": 9.129222222222221,
              "speed3d": 9.274444444444443,
              "msFromStart": 164254.12829629637
            },
            {
              "lat": 43.767229144444435,
              "lng": -116.08524972222223,
              "alt": 2148.4296666666664,
              "speed2d": 8.145333333333333,
              "speed3d": 8.35,
              "msFromStart": 164748.60444444438
            },
            {
              "lat": 43.76725723333334,
              "lng": -116.08523203333334,
              "alt": 2148.347888888889,
              "speed2d": 7.139666666666667,
              "speed3d": 7.30111111111111,
              "msFromStart": 165242.78014814807
            },
            {
              "lat": 43.767294711111106,
              "lng": -116.08521567777777,
              "alt": 2148.1644444444446,
              "speed2d": 7.07988888888889,
              "speed3d": 7.147777777777778,
              "msFromStart": 165737.0328888889
            },
            {
              "lat": 43.76733404444445,
              "lng": -116.08519733333333,
              "alt": 2147.702222222222,
              "speed2d": 7.522333333333333,
              "speed3d": 7.5444444444444425,
              "msFromStart": 166231.34933333332
            },
            {
              "lat": 43.76737355555555,
              "lng": -116.08517832222223,
              "alt": 2147.445333333333,
              "speed2d": 7.636111111111111,
              "speed3d": 7.742222222222223,
              "msFromStart": 166726.1102222221
            },
            {
              "lat": 43.76741671,
              "lng": -116.08516149,
              "alt": 2147.1204000000002,
              "speed2d": 7.697399999999999,
              "speed3d": 7.76,
              "msFromStart": 167248.66533333325
            },
            {
              "lat": 43.76745798888889,
              "lng": -116.08515068888889,
              "alt": 2146.804333333333,
              "speed2d": 7.424888888888889,
              "speed3d": 7.56,
              "msFromStart": 167772.55200000005
            },
            {
              "lat": 43.767493244444445,
              "lng": -116.08514537777776,
              "alt": 2146.577888888889,
              "speed2d": 7.019222222222222,
              "speed3d": 7.135555555555555,
              "msFromStart": 168268.8436023392
            },
            {
              "lat": 43.76752977777777,
              "lng": -116.08514288888888,
              "alt": 2146.732888888889,
              "speed2d": 6.773888888888889,
              "speed3d": 6.862222222222222,
              "msFromStart": 168763.35157894733
            },
            {
              "lat": 43.767558066666666,
              "lng": -116.08514248888886,
              "alt": 2146.3676666666665,
              "speed2d": 6.255111111111111,
              "speed3d": 6.41,
              "msFromStart": 169257.66496686154
            },
            {
              "lat": 43.76757835555556,
              "lng": -116.08514094444448,
              "alt": 2145.8022222222226,
              "speed2d": 5.462888888888888,
              "speed3d": 5.612222222222221,
              "msFromStart": 169752.9564444444
            },
            {
              "lat": 43.767600366666656,
              "lng": -116.08514009999999,
              "alt": 2145.661222222222,
              "speed2d": 5.029333333333334,
              "speed3d": 5.18,
              "msFromStart": 170248.39525925918
            },
            {
              "lat": 43.76761676666667,
              "lng": -116.08513377777778,
              "alt": 2145.806777777778,
              "speed2d": 4.3742222222222225,
              "speed3d": 4.513333333333334,
              "msFromStart": 170742.86222222226
            },
            {
              "lat": 43.767633555555555,
              "lng": -116.08512991111111,
              "alt": 2145.5216666666665,
              "speed2d": 4.039777777777778,
              "speed3d": 4.156666666666666,
              "msFromStart": 171237.14074074075
            },
            {
              "lat": 43.76764571111111,
              "lng": -116.085125,
              "alt": 2145.2862222222225,
              "speed2d": 3.275777777777778,
              "speed3d": 3.4611111111111112,
              "msFromStart": 171731.96444444451
            },
            {
              "lat": 43.76765613333334,
              "lng": -116.08511874444444,
              "alt": 2144.754111111111,
              "speed2d": 2.960222222222222,
              "speed3d": 3.0966666666666667,
              "msFromStart": 172227.0669161794
            },
            {
              "lat": 43.76766958,
              "lng": -116.08511064000001,
              "alt": 2144.1681,
              "speed2d": 2.9088000000000003,
              "speed3d": 3.045,
              "msFromStart": 172751.19873684205
            },
            {
              "lat": 43.767693,
              "lng": -116.08510152222222,
              "alt": 2144.7015555555554,
              "speed2d": 2.7782222222222224,
              "speed3d": 2.9099999999999997,
              "msFromStart": 173275.5667836257
            },
            {
              "lat": 43.767720322222225,
              "lng": -116.08509568888888,
              "alt": 2145.190111111111,
              "speed2d": 3.064,
              "speed3d": 3.1444444444444444,
              "msFromStart": 173771.13066666672
            },
            {
              "lat": 43.76774067777778,
              "lng": -116.08508866666666,
              "alt": 2145.726222222222,
              "speed2d": 2.9376666666666664,
              "speed3d": 3.088888888888889,
              "msFromStart": 174266.32829629636
            },
            {
              "lat": 43.76775408888889,
              "lng": -116.08508220000002,
              "alt": 2145.9262222222223,
              "speed2d": 2.395888888888889,
              "speed3d": 2.5611111111111104,
              "msFromStart": 174760.86222222223
            },
            {
              "lat": 43.76776578888889,
              "lng": -116.08506833333333,
              "alt": 2146.273777777778,
              "speed2d": 2.2506666666666666,
              "speed3d": 2.386666666666667,
              "msFromStart": 175255.410962963
            },
            {
              "lat": 43.7677713,
              "lng": -116.08504662222222,
              "alt": 2146.6924444444444,
              "speed2d": 2.6157777777777778,
              "speed3d": 2.6522222222222225,
              "msFromStart": 175751.76711111105
            },
            {
              "lat": 43.76777484444444,
              "lng": -116.08502116666666,
              "alt": 2147.37,
              "speed2d": 3.3973333333333335,
              "speed3d": 3.412222222222222,
              "msFromStart": 176248.44059259252
            },
            {
              "lat": 43.767778344444444,
              "lng": -116.08499745555555,
              "alt": 2147.878888888889,
              "speed2d": 3.545444444444444,
              "speed3d": 3.612222222222222,
              "msFromStart": 176743.76888888882
            },
            {
              "lat": 43.76777462222222,
              "lng": -116.08497326666668,
              "alt": 2147.632,
              "speed2d": 3.843555555555555,
              "speed3d": 3.8577777777777778,
              "msFromStart": 177238.57762183232
            },
            {
              "lat": 43.76776355555555,
              "lng": -116.08494714444444,
              "alt": 2146.513777777778,
              "speed2d": 4.407111111111111,
              "speed3d": 4.4222222222222225,
              "msFromStart": 177731.55368421064
            },
            {
              "lat": 43.76775861,
              "lng": -116.08491612000002,
              "alt": 2146.3221,
              "speed2d": 4.7406,
              "speed3d": 4.834000000000001,
              "msFromStart": 178251.67809122818
            },
            {
              "lat": 43.76776686666667,
              "lng": -116.08489104444443,
              "alt": 2146.1105555555555,
              "speed2d": 4.395444444444444,
              "speed3d": 4.5,
              "msFromStart": 178774.57244444446
            },
            {
              "lat": 43.76777884444444,
              "lng": -116.08486633333335,
              "alt": 2145.2511111111107,
              "speed2d": 4.452333333333334,
              "speed3d": 4.546666666666667,
              "msFromStart": 179270.71081481487
            },
            {
              "lat": 43.76779235555556,
              "lng": -116.08484302222222,
              "alt": 2144.0257777777774,
              "speed2d": 4.651888888888888,
              "speed3d": 4.77888888888889,
              "msFromStart": 179766.39288888892
            },
            {
              "lat": 43.767803811111115,
              "lng": -116.08482015555555,
              "alt": 2143.178,
              "speed2d": 4.543333333333333,
              "speed3d": 4.6688888888888895,
              "msFromStart": 180261.890962963
            },
            {
              "lat": 43.767811288888886,
              "lng": -116.08479111111112,
              "alt": 2142.686111111111,
              "speed2d": 4.541666666666666,
              "speed3d": 4.6977777777777785,
              "msFromStart": 180756.68977777776
            },
            {
              "lat": 43.76781214444445,
              "lng": -116.08475302222222,
              "alt": 2143.0246666666667,
              "speed2d": 4.766333333333334,
              "speed3d": 4.852222222222224,
              "msFromStart": 181251.29303703704
            },
            {
              "lat": 43.76780806666667,
              "lng": -116.08472231111111,
              "alt": 2142.4404444444444,
              "speed2d": 4.973777777777777,
              "speed3d": 5.0855555555555565,
              "msFromStart": 181745.68888888895
            },
            {
              "lat": 43.76781287777778,
              "lng": -116.0847033111111,
              "alt": 2140.759666666667,
              "speed2d": 4.918333333333333,
              "speed3d": 5.066666666666666,
              "msFromStart": 182239.98520077977
            },
            {
              "lat": 43.76782571111111,
              "lng": -116.08468418888889,
              "alt": 2139.371111111111,
              "speed2d": 5.098777777777778,
              "speed3d": 5.203333333333333,
              "msFromStart": 182733.804631579
            },
            {
              "lat": 43.76783913333334,
              "lng": -116.08465913333335,
              "alt": 2137.692666666667,
              "speed2d": 5.605333333333333,
              "speed3d": 5.706666666666667,
              "msFromStart": 183227.5852787525
            },
            {
              "lat": 43.76785197,
              "lng": -116.0846121,
              "alt": 2137.1171,
              "speed2d": 6.4704,
              "speed3d": 6.528,
              "msFromStart": 183750.44266666667
            },
            {
              "lat": 43.76787086666667,
              "lng": -116.08457286666666,
              "alt": 2136.443333333333,
              "speed2d": 6.800444444444444,
              "speed3d": 6.928888888888888,
              "msFromStart": 184273.71496296293
            },
            {
              "lat": 43.76788763333332,
              "lng": -116.08453094444445,
              "alt": 2135.4675555555555,
              "speed2d": 7.324777777777778,
              "speed3d": 7.36888888888889,
              "msFromStart": 184767.95733333332
            },
            {
              "lat": 43.76789728888888,
              "lng": -116.08446365555554,
              "alt": 2134.552111111111,
              "speed2d": 8.699666666666667,
              "speed3d": 8.633333333333335,
              "msFromStart": 185261.89096296296
            },
            {
              "lat": 43.76790054444445,
              "lng": -116.08438340000001,
              "alt": 2134.1916666666666,
              "speed2d": 10.139555555555557,
              "speed3d": 10.127777777777778,
              "msFromStart": 185756.44088888902
            },
            {
              "lat": 43.767899911111115,
              "lng": -116.08431184444441,
              "alt": 2133.683777777778,
              "speed2d": 10.677000000000001,
              "speed3d": 10.756666666666668,
              "msFromStart": 186251.20651851862
            },
            {
              "lat": 43.76789623333333,
              "lng": -116.08424156666666,
              "alt": 2132.989,
              "speed2d": 10.931666666666665,
              "speed3d": 10.964444444444442,
              "msFromStart": 186746.58844444438
            },
            {
              "lat": 43.76789137777778,
              "lng": -116.08417378888888,
              "alt": 2132.2375555555554,
              "speed2d": 10.874,
              "speed3d": 11.067777777777778,
              "msFromStart": 187242.14677582838
            },
            {
              "lat": 43.767880355555555,
              "lng": -116.08412041111112,
              "alt": 2131.8144444444442,
              "speed2d": 9.97888888888889,
              "speed3d": 10.173333333333334,
              "msFromStart": 187737.92842105252
            },
            {
              "lat": 43.76787206666667,
              "lng": -116.0840675777778,
              "alt": 2131.4466666666667,
              "speed2d": 9.274666666666665,
              "speed3d": 9.461111111111112,
              "msFromStart": 188233.71769200766
            },
            {
              "lat": 43.7678615,
              "lng": -116.0840199,
              "alt": 2130.038,
              "speed2d": 8.731555555555556,
              "speed3d": 8.936666666666667,
              "msFromStart": 188728.59111111116
            },
            {
              "lat": 43.767848920000006,
              "lng": -116.08398621999999,
              "alt": 2128.1886,
              "speed2d": 7.7181,
              "speed3d": 8.032,
              "msFromStart": 189250.71600000007
            },
            {
              "lat": 43.76783265555556,
              "lng": -116.08396467777779,
              "alt": 2126.2733333333335,
              "speed2d": 6.479666666666667,
              "speed3d": 6.8311111111111105,
              "msFromStart": 189774.14044444452
            },
            {
              "lat": 43.76781345555556,
              "lng": -116.08393977777777,
              "alt": 2124.9705555555556,
              "speed2d": 5.999555555555555,
              "speed3d": 6.30111111111111,
              "msFromStart": 190270.19377777784
            },
            {
              "lat": 43.76778654444444,
              "lng": -116.08390774444443,
              "alt": 2123.0406666666663,
              "speed2d": 6.530777777777778,
              "speed3d": 6.764444444444445,
              "msFromStart": 190764.22044444448
            },
            {
              "lat": 43.76776183333333,
              "lng": -116.08386816666669,
              "alt": 2121.3431111111113,
              "speed2d": 6.9832222222222216,
              "speed3d": 7.2588888888888885,
              "msFromStart": 191257.90281481485
            },
            {
              "lat": 43.767737644444445,
              "lng": -116.08382218888887,
              "alt": 2120.2293333333337,
              "speed2d": 7.462111111111112,
              "speed3d": 7.68888888888889,
              "msFromStart": 191753.2088888889
            },
            {
              "lat": 43.767711122222224,
              "lng": -116.08377018888889,
              "alt": 2118.8972222222224,
              "speed2d": 8.217555555555556,
              "speed3d": 8.421111111111111,
              "msFromStart": 192248.92266666668
            },
            {
              "lat": 43.76768343333333,
              "lng": -116.0837208111111,
              "alt": 2117.3726666666666,
              "speed2d": 8.673777777777778,
              "speed3d": 8.911111111111111,
              "msFromStart": 192744.65422222228
            },
            {
              "lat": 43.76765156666667,
              "lng": -116.08367573333332,
              "alt": 2115.7283333333335,
              "speed2d": 8.951333333333332,
              "speed3d": 9.19888888888889,
              "msFromStart": 193240.3926861599
            },
            {
              "lat": 43.767616633333326,
              "lng": -116.08364282222223,
              "alt": 2113.9253333333336,
              "speed2d": 9.108666666666666,
              "speed3d": 9.401111111111112,
              "msFromStart": 193736.15578947373
            },
            {
              "lat": 43.76758224444444,
              "lng": -116.08361953333333,
              "alt": 2112.0504444444446,
              "speed2d": 8.905777777777779,
              "speed3d": 9.207777777777777,
              "msFromStart": 194231.8927095517
            },
            {
              "lat": 43.76754658888889,
              "lng": -116.08358294444444,
              "alt": 2110.7400000000002,
              "speed2d": 9.358111111111112,
              "speed3d": 9.575555555555557,
              "msFromStart": 194727.01511111102
            },
            {
              "lat": 43.767513810000004,
              "lng": -116.08353106,
              "alt": 2109.3885,
              "speed2d": 9.680299999999999,
              "speed3d": 9.953,
              "msFromStart": 195249.33973333324
            },
            {
              "lat": 43.767487499999994,
              "lng": -116.08347573333336,
              "alt": 2108.0433333333335,
              "speed2d": 9.462777777777777,
              "speed3d": 9.744444444444444,
              "msFromStart": 195770.92088888885
            },
            {
              "lat": 43.76746208888889,
              "lng": -116.08342587777776,
              "alt": 2106.536,
              "speed2d": 9.241333333333333,
              "speed3d": 9.566666666666666,
              "msFromStart": 196264.91111111105
            },
            {
              "lat": 43.76743907777778,
              "lng": -116.08338981111112,
              "alt": 2104.6093333333333,
              "speed2d": 8.692777777777778,
              "speed3d": 9.057777777777776,
              "msFromStart": 196759.68355555547
            },
            {
              "lat": 43.76741224444444,
              "lng": -116.08335163333332,
              "alt": 2102.996666666667,
              "speed2d": 8.417888888888887,
              "speed3d": 8.75111111111111,
              "msFromStart": 197254.67348148138
            },
            {
              "lat": 43.767386888888886,
              "lng": -116.08330538888889,
              "alt": 2101.742888888889,
              "speed2d": 8.415555555555557,
              "speed3d": 8.767777777777779,
              "msFromStart": 197749.8826666667
            },
            {
              "lat": 43.76736425555556,
              "lng": -116.08326621111112,
              "alt": 2100.055111111111,
              "speed2d": 8.117333333333333,
              "speed3d": 8.532222222222222,
              "msFromStart": 198245.06788304096
            },
            {
              "lat": 43.76734536666667,
              "lng": -116.08322846666667,
              "alt": 2099.4872222222225,
              "speed2d": 7.347333333333332,
              "speed3d": 7.834444444444443,
              "msFromStart": 198739.46526315785
            },
            {
              "lat": 43.76733278888889,
              "lng": -116.08319442222222,
              "alt": 2098.1985555555557,
              "speed2d": 6.736111111111111,
              "speed3d": 7.188888888888889,
              "msFromStart": 199233.75248343073
            },
            {
              "lat": 43.7673146,
              "lng": -116.08316643333333,
              "alt": 2096.826222222222,
              "speed2d": 6.508444444444445,
              "speed3d": 7.039999999999999,
              "msFromStart": 199729.6888888888
            },
            {
              "lat": 43.76729582222222,
              "lng": -116.0831444111111,
              "alt": 2095.4076666666665,
              "speed2d": 6.043333333333333,
              "speed3d": 6.578888888888889,
              "msFromStart": 200226.15733333322
            },
            {
              "lat": 43.767273599999996,
              "lng": -116.08311699000001,
              "alt": 2093.5876,
              "speed2d": 6.0798000000000005,
              "speed3d": 6.609,
              "msFromStart": 200748.66266666667
            },
            {
              "lat": 43.76725915555555,
              "lng": -116.08308778888889,
              "alt": 2091.3654444444446,
              "speed2d": 5.805,
              "speed3d": 6.446666666666667,
              "msFromStart": 201270.7025185185
            },
            {
              "lat": 43.76725902222222,
              "lng": -116.08306386666668,
              "alt": 2088.865222222222,
              "speed2d": 5.261111111111112,
              "speed3d": 6.053333333333335,
              "msFromStart": 201765.90400000004
            },
            {
              "lat": 43.76726305555556,
              "lng": -116.083045,
              "alt": 2087.064888888889,
              "speed2d": 4.500444444444444,
              "speed3d": 5.45,
              "msFromStart": 202261.25807407417
            },
            {
              "lat": 43.767274099999995,
              "lng": -116.08303414444444,
              "alt": 2085.7592222222224,
              "speed2d": 3.791888888888889,
              "speed3d": 4.846666666666667,
              "msFromStart": 202756.55288888887
            },
            {
              "lat": 43.76728507777778,
              "lng": -116.08302555555557,
              "alt": 2084.717333333333,
              "speed2d": 3.550777777777778,
              "speed3d": 4.6033333333333335,
              "msFromStart": 203251.78548148143
            },
            {
              "lat": 43.76729631111111,
              "lng": -116.08300895555556,
              "alt": 2084.3052222222223,
              "speed2d": 3.617666666666666,
              "speed3d": 4.627777777777777,
              "msFromStart": 203746.54399999994
            },
            {
              "lat": 43.76730397777777,
              "lng": -116.08299176666668,
              "alt": 2083.5605555555558,
              "speed2d": 3.357777777777778,
              "speed3d": 4.420000000000001,
              "msFromStart": 204241.18192592586
            },
            {
              "lat": 43.76730484444444,
              "lng": -116.08297614444443,
              "alt": 2082.3946666666666,
              "speed2d": 2.9487777777777775,
              "speed3d": 4.105555555555556,
              "msFromStart": 204735.7804444445
            },
            {
              "lat": 43.76729888888889,
              "lng": -116.08295043333334,
              "alt": 2080.5286666666666,
              "speed2d": 3.154,
              "speed3d": 4.226666666666667,
              "msFromStart": 205230.4302222223
            },
            {
              "lat": 43.76728191111111,
              "lng": -116.08292265555556,
              "alt": 2077.809,
              "speed2d": 4.345000000000001,
              "speed3d": 5.207777777777778,
              "msFromStart": 205726.32977777772
            },
            {
              "lat": 43.76726575,
              "lng": -116.08290485,
              "alt": 2074.8547,
              "speed2d": 5.091,
              "speed3d": 5.991,
              "msFromStart": 206250.10959999994
            },
            {
              "lat": 43.76725118888888,
              "lng": -116.08288524444444,
              "alt": 2071.5287777777776,
              "speed2d": 5.465666666666666,
              "speed3d": 6.417777777777778,
              "msFromStart": 206772.12444444437
            },
            {
              "lat": 43.767236688888886,
              "lng": -116.08286465555555,
              "alt": 2068.092888888889,
              "speed2d": 5.747222222222223,
              "speed3d": 6.754444444444444,
              "msFromStart": 207266.09919688097
            },
            {
              "lat": 43.76722253333333,
              "lng": -116.08284943333332,
              "alt": 2065.4295555555555,
              "speed2d": 5.559444444444445,
              "speed3d": 6.763333333333333,
              "msFromStart": 207759.5814736841
            },
            {
              "lat": 43.767213311111114,
              "lng": -116.08283842222224,
              "alt": 2063.224888888889,
              "speed2d": 4.8054444444444435,
              "speed3d": 6.182222222222222,
              "msFromStart": 208253.05858869385
            },
            {
              "lat": 43.76720917777778,
              "lng": -116.08282393333333,
              "alt": 2060.5494444444444,
              "speed2d": 4.333444444444444,
              "speed3d": 5.806666666666667,
              "msFromStart": 208748.77688888877
            },
            {
              "lat": 43.76720819999999,
              "lng": -116.08280664444445,
              "alt": 2057.6093333333333,
              "speed2d": 3.978777777777778,
              "speed3d": 5.66,
              "msFromStart": 209245.29748148134
            },
            {
              "lat": 43.76721646666666,
              "lng": -116.0827891888889,
              "alt": 2055.442,
              "speed2d": 3.8490000000000006,
              "speed3d": 5.517777777777778,
              "msFromStart": 209741.37333333326
            },
            {
              "lat": 43.76722558888889,
              "lng": -116.08276703333334,
              "alt": 2053.465111111111,
              "speed2d": 4.296888888888889,
              "speed3d": 5.8677777777777775,
              "msFromStart": 210237.20474074065
            },
            {
              "lat": 43.76722381111111,
              "lng": -116.08273732222223,
              "alt": 2050.8635555555556,
              "speed2d": 4.640666666666666,
              "speed3d": 6.152222222222221,
              "msFromStart": 210731.50488888897
            },
            {
              "lat": 43.76721453333333,
              "lng": -116.08270251111111,
              "alt": 2048.726666666667,
              "speed2d": 5.0442222222222215,
              "speed3d": 6.489999999999999,
              "msFromStart": 211225.30637037044
            },
            {
              "lat": 43.76720025,
              "lng": -116.08265905,
              "alt": 2046.7402,
              "speed2d": 5.810099999999999,
              "speed3d": 7.081,
              "msFromStart": 211747.8800000001
            },
            {
              "lat": 43.767183522222226,
              "lng": -116.0826130111111,
              "alt": 2044.1875555555555,
              "speed2d": 6.528666666666666,
              "speed3d": 7.775555555555557,
              "msFromStart": 212270.93511111118
            },
            {
              "lat": 43.7671713,
              "lng": -116.08257018888888,
              "alt": 2042.2451111111109,
              "speed2d": 6.729333333333334,
              "speed3d": 7.971111111111111,
              "msFromStart": 212766.6933333332
            },
            {
              "lat": 43.76717036666667,
              "lng": -116.08253097777778,
              "alt": 2040.1934444444444,
              "speed2d": 7.019222222222222,
              "speed3d": 8.229999999999999,
              "msFromStart": 213262.46152046777
            },
            {
              "lat": 43.76717647777778,
              "lng": -116.0824929,
              "alt": 2038.084,
              "speed2d": 6.9510000000000005,
              "speed3d": 8.22111111111111,
              "msFromStart": 213757.75157894747
            },
            {
              "lat": 43.767178155555555,
              "lng": -116.08245033333334,
              "alt": 2035.6640000000002,
              "speed2d": 7.145555555555555,
              "speed3d": 8.35111111111111,
              "msFromStart": 214252.88023391823
            },
            {
              "lat": 43.767166888888895,
              "lng": -116.08239573333333,
              "alt": 2033.2362222222223,
              "speed2d": 7.980222222222222,
              "speed3d": 9.05777777777778,
              "msFromStart": 214747.21333333338
            },
            {
              "lat": 43.767147011111106,
              "lng": -116.08234242222224,
              "alt": 2030.8405555555555,
              "speed2d": 8.560333333333332,
              "speed3d": 9.67,
              "msFromStart": 215241.27881481487
            },
            {
              "lat": 43.76713176666666,
              "lng": -116.08229770000001,
              "alt": 2028.5836666666664,
              "speed2d": 8.184888888888889,
              "speed3d": 9.37111111111111,
              "msFromStart": 215735.82844444452
            },
            {
              "lat": 43.767131,
              "lng": -116.08225223333332,
              "alt": 2025.7897777777778,
              "speed2d": 8.317222222222222,
              "speed3d": 9.526666666666667,
              "msFromStart": 216230.5860740742
            },
            {
              "lat": 43.76713514444444,
              "lng": -116.0822071,
              "alt": 2023.1991111111113,
              "speed2d": 7.963111111111111,
              "speed3d": 9.22888888888889,
              "msFromStart": 216725.90666666676
            },
            {
              "lat": 43.76712929000001,
              "lng": -116.08214595000001,
              "alt": 2020.6923,
              "speed2d": 8.311,
              "speed3d": 9.506,
              "msFromStart": 217248.9310736843
            },
            {
              "lat": 43.76711294444445,
              "lng": -116.0820873888889,
              "alt": 2017.922111111111,
              "speed2d": 8.649222222222221,
              "speed3d": 9.837777777777777,
              "msFromStart": 217771.58231578936
            },
            {
              "lat": 43.76709007777778,
              "lng": -116.08202733333334,
              "alt": 2015.1334444444444,
              "speed2d": 9.353777777777779,
              "speed3d": 10.427777777777777,
              "msFromStart": 218266.6074541909
            },
            {
              "lat": 43.76707025555555,
              "lng": -116.0819559111111,
              "alt": 2012.5677777777778,
              "speed2d": 10.293444444444445,
              "speed3d": 11.311111111111114,
              "msFromStart": 218761.46222222224
            },
            {
              "lat": 43.767059655555556,
              "lng": -116.08187888888888,
              "alt": 2010.4944444444443,
              "speed2d": 11.011000000000001,
              "speed3d": 11.95111111111111,
              "msFromStart": 219256.27200000006
            },
            {
              "lat": 43.76705043333334,
              "lng": -116.08179947777778,
              "alt": 2008.570777777778,
              "speed2d": 11.144777777777778,
              "speed3d": 12.150000000000002,
              "msFromStart": 219751.41955555545
            },
            {
              "lat": 43.76703446666667,
              "lng": -116.08171568888889,
              "alt": 2006.2303333333334,
              "speed2d": 11.254888888888889,
              "speed3d": 12.197777777777775,
              "msFromStart": 220246.69185185173
            },
            {
              "lat": 43.767006744444444,
              "lng": -116.08162667777779,
              "alt": 2004.6130000000003,
              "speed2d": 12.224666666666668,
              "speed3d": 13.006666666666668,
              "msFromStart": 220741.9697777778
            },
            {
              "lat": 43.766983422222225,
              "lng": -116.08156999999999,
              "alt": 2002.075888888889,
              "speed2d": 11.97311111111111,
              "speed3d": 13.013333333333334,
              "msFromStart": 221237.21037037036
            },
            {
              "lat": 43.766981666666666,
              "lng": -116.08160525555556,
              "alt": 1996.3327777777777,
              "speed2d": 9.675222222222223,
              "speed3d": 11.11888888888889,
              "msFromStart": 221731.70222222217
            },
            {
              "lat": 43.76698840000001,
              "lng": -116.08163119999999,
              "alt": 1991.1242222222222,
              "speed2d": 7.627,
              "speed3d": 9.32,
              "msFromStart": 222225.95076803112
            },
            {
              "lat": 43.766989220000006,
              "lng": -116.0816013,
              "alt": 1988.1025999999997,
              "speed2d": 7.5161999999999995,
              "speed3d": 9.017,
              "msFromStart": 222748.32294736832
            },
            {
              "lat": 43.766979855555554,
              "lng": -116.08152700000002,
              "alt": 1986.1009999999999,
              "speed2d": 8.33211111111111,
              "speed3d": 9.722222222222223,
              "msFromStart": 223270.95203118894
            },
            {
              "lat": 43.766964666666674,
              "lng": -116.08145716666668,
              "alt": 1984.2068888888894,
              "speed2d": 8.951666666666666,
              "speed3d": 10.170000000000002,
              "msFromStart": 223766.6284444445
            },
            {
              "lat": 43.766943955555554,
              "lng": -116.08138245555557,
              "alt": 1982.0929999999996,
              "speed2d": 9.725777777777779,
              "speed3d": 10.858888888888888,
              "msFromStart": 224262.4634074075
            },
            {
              "lat": 43.76692552222222,
              "lng": -116.08131350000001,
              "alt": 1979.5882222222222,
              "speed2d": 9.933666666666669,
              "speed3d": 11.081111111111111,
              "msFromStart": 224757.4426666666
            },
            {
              "lat": 43.766915355555554,
              "lng": -116.08124968888887,
              "alt": 1976.9428888888888,
              "speed2d": 9.607888888888889,
              "speed3d": 10.84222222222222,
              "msFromStart": 225252.09274074069
            },
            {
              "lat": 43.766913611111114,
              "lng": -116.08119763333335,
              "alt": 1974.7701111111112,
              "speed2d": 8.969222222222223,
              "speed3d": 10.284444444444444,
              "msFromStart": 225746.47822222218
            },
            {
              "lat": 43.766921999999994,
              "lng": -116.0811537888889,
              "alt": 1972.897,
              "speed2d": 8.213666666666667,
              "speed3d": 9.592222222222222,
              "msFromStart": 226240.82103703695
            },
            {
              "lat": 43.76693642222222,
              "lng": -116.08112010000002,
              "alt": 1971.733888888889,
              "speed2d": 7.390666666666666,
              "speed3d": 8.822222222222223,
              "msFromStart": 226736.20533333326
            },
            {
              "lat": 43.76694661111111,
              "lng": -116.08108132222222,
              "alt": 1969.9815555555556,
              "speed2d": 6.919444444444444,
              "speed3d": 8.295555555555556,
              "msFromStart": 227231.91134502913
            },
            {
              "lat": 43.76694794444445,
              "lng": -116.0810276888889,
              "alt": 1967.6554444444448,
              "speed2d": 6.806222222222223,
              "speed3d": 8.222222222222221,
              "msFromStart": 227726.4395789474
            },
            {
              "lat": 43.766939470000004,
              "lng": -116.0809687,
              "alt": 1965.4231000000004,
              "speed2d": 6.938300000000001,
              "speed3d": 8.255,
              "msFromStart": 228248.03563508776
            },
            {
              "lat": 43.766920822222225,
              "lng": -116.08088914444446,
              "alt": 1962.9745555555558,
              "speed2d": 8.112777777777776,
              "speed3d": 9.223333333333333,
              "msFromStart": 228770.7422222222
            },
            {
              "lat": 43.766905066666666,
              "lng": -116.0808324,
              "alt": 1960.6742222222224,
              "speed2d": 8.20288888888889,
              "speed3d": 9.46,
              "msFromStart": 229266.42192592585
            },
            {
              "lat": 43.7668908,
              "lng": -116.08079358888887,
              "alt": 1958.1786666666667,
              "speed2d": 7.72722222222222,
              "speed3d": 9.057777777777778,
              "msFromStart": 229762.55199999997
            },
            {
              "lat": 43.76687316666666,
              "lng": -116.08075204444445,
              "alt": 1956.4565555555557,
              "speed2d": 7.3486666666666665,
              "speed3d": 8.723333333333333,
              "msFromStart": 230258.73362962963
            },
            {
              "lat": 43.766862366666665,
              "lng": -116.08071461111112,
              "alt": 1953.9473333333335,
              "speed2d": 6.768333333333333,
              "speed3d": 8.234444444444444,
              "msFromStart": 230752.74222222218
            },
            {
              "lat": 43.76685744444444,
              "lng": -116.08068310000002,
              "alt": 1950.708333333333,
              "speed2d": 6.074888888888889,
              "speed3d": 7.782222222222223,
              "msFromStart": 231246.03081481476
            },
            {
              "lat": 43.76686007777778,
              "lng": -116.08066328888889,
              "alt": 1948.481888888889,
              "speed2d": 4.885888888888889,
              "speed3d": 6.916666666666666,
              "msFromStart": 231740.8506666667
            },
            {
              "lat": 43.76687088888889,
              "lng": -116.08064824444446,
              "alt": 1947.6005555555555,
              "speed2d": 4.2252222222222215,
              "speed3d": 6.314444444444444,
              "msFromStart": 232236.25540740747
            },
            {
              "lat": 43.7668872,
              "lng": -116.08063242222222,
              "alt": 1947.096,
              "speed2d": 4.323555555555556,
              "speed3d": 6.217777777777777,
              "msFromStart": 232732.05422222224
            },
            {
              "lat": 43.7669092,
              "lng": -116.08062546666667,
              "alt": 1946.5663333333332,
              "speed2d": 4.574,
              "speed3d": 6.253333333333333,
              "msFromStart": 233227.96031189087
            },
            {
              "lat": 43.76693408,
              "lng": -116.08060161,
              "alt": 1945.2285,
              "speed2d": 5.3432,
              "speed3d": 6.79,
              "msFromStart": 233750.64168421068
            },
            {
              "lat": 43.76694573333333,
              "lng": -116.08055347777778,
              "alt": 1943.686888888889,
              "speed2d": 5.581111111111111,
              "speed3d": 7.003333333333334,
              "msFromStart": 234273.0630019495
            },
            {
              "lat": 43.76694488888889,
              "lng": -116.08049045555555,
              "alt": 1942.1513333333332,
              "speed2d": 5.914555555555555,
              "speed3d": 7.236666666666666,
              "msFromStart": 234768.08977777776
            },
            {
              "lat": 43.76693768888889,
              "lng": -116.08042186666668,
              "alt": 1940.634222222222,
              "speed2d": 6.746777777777778,
              "speed3d": 7.884444444444444,
              "msFromStart": 235263.12533333327
            },
            {
              "lat": 43.76693138888889,
              "lng": -116.08035484444443,
              "alt": 1938.8181111111112,
              "speed2d": 7.530333333333333,
              "speed3d": 8.596666666666666,
              "msFromStart": 235757.62044444447
            },
            {
              "lat": 43.76693506666667,
              "lng": -116.08029402222222,
              "alt": 1937.018111111111,
              "speed2d": 8.225333333333332,
              "speed3d": 9.215555555555557,
              "msFromStart": 236251.97244444452
            },
            {
              "lat": 43.76694578888889,
              "lng": -116.08023681111112,
              "alt": 1935.271777777778,
              "speed2d": 8.750888888888888,
              "speed3d": 9.716666666666665,
              "msFromStart": 236747.38844444454
            },
            {
              "lat": 43.76695506666667,
              "lng": -116.08018612222223,
              "alt": 1933.6483333333335,
              "speed2d": 8.846444444444444,
              "speed3d": 9.824444444444445,
              "msFromStart": 237243.1383703705
            },
            {
              "lat": 43.76695458888889,
              "lng": -116.08012873333334,
              "alt": 1931.7045555555555,
              "speed2d": 9.051444444444444,
              "speed3d": 9.983333333333334,
              "msFromStart": 237737.78488888883
            },
            {
              "lat": 43.76695238888888,
              "lng": -116.08006314444444,
              "alt": 1928.774,
              "speed2d": 9.379555555555555,
              "speed3d": 10.321111111111112,
              "msFromStart": 238232.05149317728
            },
            {
              "lat": 43.76695815555556,
              "lng": -116.0800025,
              "alt": 1925.538111111111,
              "speed2d": 9.807777777777776,
              "speed3d": 10.733333333333333,
              "msFromStart": 238726.8235789473
            },
            {
              "lat": 43.76697563,
              "lng": -116.07993977999999,
              "alt": 1922.8641,
              "speed2d": 10.4013,
              "speed3d": 11.31,
              "msFromStart": 239249.310568421
            },
            {
              "lat": 43.76699273333333,
              "lng": -116.07987485555556,
              "alt": 1920.6027777777776,
              "speed2d": 10.387111111111114,
              "speed3d": 11.296666666666667,
              "msFromStart": 239772.45866666661
            },
            {
              "lat": 43.76699326666667,
              "lng": -116.07979282222223,
              "alt": 1918.7453333333333,
              "speed2d": 10.727000000000002,
              "speed3d": 11.577777777777778,
              "msFromStart": 240268.3202962962
            },
            {
              "lat": 43.766986866666656,
              "lng": -116.07970513333333,
              "alt": 1916.7225555555556,
              "speed2d": 11.430222222222222,
              "speed3d": 12.21888888888889,
              "msFromStart": 240763.68088888898
            },
            {
              "lat": 43.76698004444445,
              "lng": -116.0796190111111,
              "alt": 1914.2487777777778,
              "speed2d": 11.607000000000001,
              "speed3d": 12.424444444444443,
              "msFromStart": 241258.83140740753
            },
            {
              "lat": 43.76698063333333,
              "lng": -116.07954278888889,
              "alt": 1912.7333333333333,
              "speed2d": 11.591666666666665,
              "speed3d": 12.396666666666665,
              "msFromStart": 241753.49777777784
            },
            {
              "lat": 43.766988188888895,
              "lng": -116.07947529999998,
              "alt": 1911.061444444444,
              "speed2d": 11.42588888888889,
              "speed3d": 12.276666666666667,
              "msFromStart": 242248.01096296305
            },
            {
              "lat": 43.76699087777778,
              "lng": -116.07940816666667,
              "alt": 1909.4142222222224,
              "speed2d": 11.131777777777778,
              "speed3d": 11.918888888888887,
              "msFromStart": 242743.00266666673
            },
            {
              "lat": 43.76698436666667,
              "lng": -116.07933305555557,
              "alt": 1906.8435555555557,
              "speed2d": 11.055888888888887,
              "speed3d": 11.865555555555554,
              "msFromStart": 243238.14212865505
            },
            {
              "lat": 43.766965911111114,
              "lng": -116.0792548111111,
              "alt": 1905.3317777777777,
              "speed2d": 11.11122222222222,
              "speed3d": 11.880000000000003,
              "msFromStart": 243732.73936842114
            },
            {
              "lat": 43.76694985555555,
              "lng": -116.07918312222222,
              "alt": 1903.9778888888889,
              "speed2d": 10.989999999999998,
              "speed3d": 11.741111111111111,
              "msFromStart": 244227.13647823274
            },
            {
              "lat": 43.76693951,
              "lng": -116.07910372,
              "alt": 1902.7576,
              "speed2d": 10.738299999999999,
              "speed3d": 11.478,
              "msFromStart": 244748.9795555556
            },
            {
              "lat": 43.7669407,
              "lng": -116.07902492222222,
              "alt": 1901.307888888889,
              "speed2d": 10.603333333333333,
              "speed3d": 11.305555555555554,
              "msFromStart": 245270.84740740748
            },
            {
              "lat": 43.76694739999999,
              "lng": -116.07895631111113,
              "alt": 1899.599,
              "speed2d": 10.413999999999998,
              "speed3d": 11.137777777777778,
              "msFromStart": 245765.9146666667
            },
            {
              "lat": 43.76694548888889,
              "lng": -116.0788848777778,
              "alt": 1897.569777777778,
              "speed2d": 10.618333333333334,
              "speed3d": 11.280000000000001,
              "msFromStart": 246261.20740740746
            },
            {
              "lat": 43.76693238888889,
              "lng": -116.07881572222222,
              "alt": 1894.611777777778,
              "speed2d": 10.952111111111112,
              "speed3d": 11.588888888888889,
              "msFromStart": 246756.13422222223
            },
            {
              "lat": 43.76691721111112,
              "lng": -116.07874674444443,
              "alt": 1891.641111111111,
              "speed2d": 10.81311111111111,
              "speed3d": 11.612222222222222,
              "msFromStart": 247250.93925925932
            },
            {
              "lat": 43.76690584444445,
              "lng": -116.07868904444445,
              "alt": 1889.8045555555555,
              "speed2d": 10.338444444444443,
              "speed3d": 11.160000000000002,
              "msFromStart": 247745.99200000006
            },
            {
              "lat": 43.76690452222222,
              "lng": -116.07863615555556,
              "alt": 1887.7718888888887,
              "speed2d": 9.799222222222223,
              "speed3d": 10.60222222222222,
              "msFromStart": 248241.15185964917
            },
            {
              "lat": 43.766912677777775,
              "lng": -116.07857595555555,
              "alt": 1885.549222222222,
              "speed2d": 9.941333333333333,
              "speed3d": 10.675555555555558,
              "msFromStart": 248736.61305263152
            },
            {
              "lat": 43.76691974444444,
              "lng": -116.07850433333333,
              "alt": 1883.4901111111112,
              "speed2d": 10.185222222222222,
              "speed3d": 10.896666666666667,
              "msFromStart": 249232.18047043527
            },
            {
              "lat": 43.766917255555555,
              "lng": -116.07842883333332,
              "alt": 1881.3837777777776,
              "speed2d": 10.647111111111112,
              "speed3d": 11.312222222222221,
              "msFromStart": 249727.49866666662
            },
            {
              "lat": 43.766908449999995,
              "lng": -116.07837085,
              "alt": 1879.81525,
              "speed2d": 11.0155,
              "speed3d": 11.637500000000001,
              "msFromStart": 250085.1315555555
            },
            {
              "lat": 43.7668887990815,
              "lng": -116.07831056496009,
              "alt": 1878.0761544498966,
              "speed2d": 11.099971593863096,
              "speed3d": 11.737120258551302,
              "msFromStart": 250500
            },
            {
              "lat": 43.766846,
              "lng": -116.07818345000001,
              "alt": 1874.3692500000002,
              "speed2d": 10.9775,
              "speed3d": 11.705,
              "msFromStart": 251404.20444444445
            },
            {
              "lat": 43.766842325,
              "lng": -116.07815054999999,
              "alt": 1873.541,
              "speed2d": 10.86175,
              "speed3d": 11.61,
              "msFromStart": 251624.356
            },
            {
              "lat": 43.76683916,
              "lng": -116.0780592,
              "alt": 1871.758,
              "speed2d": 10.9148,
              "speed3d": 11.57,
              "msFromStart": 252191.31377777772
            },
            {
              "lat": 43.76683626778994,
              "lng": -116.07801582745525,
              "alt": 1870.4974242283504,
              "speed2d": 10.914262798249748,
              "speed3d": 11.590013060202901,
              "msFromStart": 252500
            },
            {
              "lat": 43.76682928,
              "lng": -116.07793722,
              "alt": 1867.3688,
              "speed2d": 10.3058,
              "speed3d": 11.15,
              "msFromStart": 253193.91494736838
            },
            {
              "lat": 43.76681361428572,
              "lng": -116.07787515714286,
              "alt": 1865.3005714285714,
              "speed2d": 9.381142857142859,
              "speed3d": 10.299999999999999,
              "msFromStart": 253799.11111111098
            },
            {
              "lat": 43.76680285714286,
              "lng": -116.07783602857144,
              "alt": 1864.1804285714286,
              "speed2d": 8.678,
              "speed3d": 9.61142857142857,
              "msFromStart": 254223.18425396807
            },
            {
              "lat": 43.76678804,
              "lng": -116.07778427999999,
              "alt": 1862.8870000000002,
              "speed2d": 7.890600000000001,
              "speed3d": 8.84,
              "msFromStart": 254788.77688888894
            },
            {
              "lat": 43.76677434444445,
              "lng": -116.07774568888888,
              "alt": 1861.609777777778,
              "speed2d": 7.336222222222222,
              "speed3d": 8.342222222222222,
              "msFromStart": 255228.74301234575
            },
            {
              "lat": 43.7667569875,
              "lng": -116.0777003,
              "alt": 1860.0327499999999,
              "speed2d": 7.016125000000001,
              "speed3d": 8.003749999999998,
              "msFromStart": 255778.80266666666
            },
            {
              "lat": 43.7667478,
              "lng": -116.0776647,
              "alt": 1858.8716666666664,
              "speed2d": 6.693,
              "speed3d": 7.723333333333334,
              "msFromStart": 256218.88800000004
            },
            {
              "lat": 43.766744499999994,
              "lng": -116.07763126666667,
              "alt": 1857.5906666666665,
              "speed2d": 6.148,
              "speed3d": 7.21,
              "msFromStart": 256659.46666666665
            },
            {
              "lat": 43.76674507142857,
              "lng": -116.07759539999999,
              "alt": 1855.754142857143,
              "speed2d": 5.428000000000001,
              "speed3d": 6.5842857142857145,
              "msFromStart": 257226.2402539682
            },
            {
              "lat": 43.76674845,
              "lng": -116.0775695,
              "alt": 1854.1655,
              "speed2d": 4.836,
              "speed3d": 6.075,
              "msFromStart": 257732.61377777785
            },
            {
              "lat": 43.76675335,
              "lng": -116.077531725,
              "alt": 1853.055,
              "speed2d": 4.3315,
              "speed3d": 5.609999999999999,
              "msFromStart": 258267.70244444459
            },
            {
              "lat": 43.7667503,
              "lng": -116.0774869,
              "alt": 1852.9848,
              "speed2d": 4.01,
              "speed3d": 5.290000000000001,
              "msFromStart": 258781.45920000004
            },
            {
              "lat": 43.76674575714286,
              "lng": -116.0774457142857,
              "alt": 1853.9821428571431,
              "speed2d": 3.726571428571429,
              "speed3d": 4.9399999999999995,
              "msFromStart": 259290.27308270682
            },
            {
              "lat": 43.766740899999995,
              "lng": -116.07741183333334,
              "alt": 1854.5526666666665,
              "speed2d": 3.5398888888888886,
              "speed3d": 4.688888888888889,
              "msFromStart": 259738.55999999994
            },
            {
              "lat": 43.766740266666666,
              "lng": -116.07739232222222,
              "alt": 1855.0093333333334,
              "speed2d": 3.384333333333333,
              "speed3d": 4.4944444444444445,
              "msFromStart": 260234.34143209865
            },
            {
              "lat": 43.76674054285714,
              "lng": -116.0773879857143,
              "alt": 1856.1337142857142,
              "speed2d": 2.972142857142857,
              "speed3d": 4.09,
              "msFromStart": 260690.29269841276
            },
            {
              "lat": 43.7667369,
              "lng": -116.0773629,
              "alt": 1856.568,
              "speed2d": 2.4565,
              "speed3d": 3.575,
              "msFromStart": 261389.4391111112
            },
            {
              "lat": 43.76673156,
              "lng": -116.07734122,
              "alt": 1857.0776,
              "speed2d": 2.2046,
              "speed3d": 3.334,
              "msFromStart": 261795.71182222222
            },
            {
              "lat": 43.7667156,
              "lng": -116.07729431111109,
              "alt": 1859.1533333333332,
              "speed2d": 2.0021111111111107,
              "speed3d": 3.0533333333333337,
              "msFromStart": 262267.904
            },
            {
              "lat": 43.766700725,
              "lng": -116.0772514,
              "alt": 1860.6885,
              "speed2d": 1.69075,
              "speed3d": 2.7275,
              "msFromStart": 262736.2555555555
            },
            {
              "lat": 43.766674800000004,
              "lng": -116.0771974,
              "alt": 1863.6945,
              "speed2d": 1.1075,
              "speed3d": 2.145,
              "msFromStart": 263425.96355555556
            },
            {
              "lat": 43.7666624125,
              "lng": -116.07717011250001,
              "alt": 1864.85525,
              "speed2d": 0.9277500000000001,
              "speed3d": 1.9537499999999999,
              "msFromStart": 263770.73577777785
            },
            {
              "lat": 43.76665062000001,
              "lng": -116.07713404,
              "alt": 1866.2856,
              "speed2d": 0.7516,
              "speed3d": 1.762,
              "msFromStart": 264242.2670970761
            },
            {
              "lat": 43.7666428,
              "lng": -116.0771096,
              "alt": 1867.703,
              "speed2d": 0.608,
              "speed3d": 1.5950000000000002,
              "msFromStart": 264583.6176842105
            },
            {
              "lat": 43.76663085,
              "lng": -116.0770608125,
              "alt": 1869.33175,
              "speed2d": 0.5595,
              "speed3d": 1.43875,
              "msFromStart": 265270.71467251447
            },
            {
              "lat": 43.766623949999996,
              "lng": -116.07703132500001,
              "alt": 1871.0695,
              "speed2d": 0.452,
              "speed3d": 1.2825,
              "msFromStart": 265778.7340000001
            },
            {
              "lat": 43.76661171666667,
              "lng": -116.07700208333334,
              "alt": 1872.8275,
              "speed2d": 0.35416666666666663,
              "speed3d": 1.1216666666666666,
              "msFromStart": 266277.44074074086
            },
            {
              "lat": 43.766603777777775,
              "lng": -116.07697926666667,
              "alt": 1873.6658888888887,
              "speed2d": 0.28755555555555556,
              "speed3d": 1.0400000000000003,
              "msFromStart": 266726.2133333332
            },
            {
              "lat": 43.76659123333333,
              "lng": -116.07695271666668,
              "alt": 1874.7979999999998,
              "speed2d": 0.22516666666666665,
              "speed3d": 0.9416666666666667,
              "msFromStart": 267321.7496296295
            },
            {
              "lat": 43.7665858625,
              "lng": -116.07693660000001,
              "alt": 1875.615125,
              "speed2d": 0.16550000000000004,
              "speed3d": 0.8724999999999999,
              "msFromStart": 267763.9542222221
            },
            {
              "lat": 43.766578024999994,
              "lng": -116.07691887499999,
              "alt": 1876.554,
              "speed2d": 0.16575,
              "speed3d": 0.8075000000000001,
              "msFromStart": 268307.06152046775
            },
            {
              "lat": 43.766573,
              "lng": -116.076907275,
              "alt": 1877.11175,
              "speed2d": 0.18124999999999997,
              "speed3d": 0.7775000000000001,
              "msFromStart": 268692.47726315784
            },
            {
              "lat": 43.76656586666667,
              "lng": -116.07689166666668,
              "alt": 1877.7743333333333,
              "speed2d": 0.2416666666666667,
              "speed3d": 0.75,
              "msFromStart": 269238.6997894736
            },
            {
              "lat": 43.766563325,
              "lng": -116.07688157499999,
              "alt": 1878.1007499999998,
              "speed2d": 0.2145,
              "speed3d": 0.71,
              "msFromStart": 269655.98222222214
            },
            {
              "lat": 43.76655989722422,
              "lng": -116.07687403160138,
              "alt": 1878.3438733113935,
              "speed2d": 0.21821992843180021,
              "speed3d": 0.7021921706139221,
              "msFromStart": 270000
            },
            {
              "lat": 43.766565699999994,
              "lng": -116.076860775,
              "alt": 1878.848,
              "speed2d": 0.17575,
              "speed3d": 0.6575000000000001,
              "msFromStart": 270686.84444444446
            },
            {
              "lat": 43.7665695,
              "lng": -116.07684953333333,
              "alt": 1879.288166666667,
              "speed2d": 0.31533333333333335,
              "speed3d": 0.6883333333333332,
              "msFromStart": 271273.194962963
            },
            {
              "lat": 43.766553820000006,
              "lng": -116.07683721999999,
              "alt": 1878.1850000000002,
              "speed2d": 0.35739999999999994,
              "speed3d": 0.724,
              "msFromStart": 271841.944888889
            },
            {
              "lat": 43.766545025,
              "lng": -116.07682637500001,
              "alt": 1877.822,
              "speed2d": 0.6174999999999999,
              "speed3d": 0.8800000000000001,
              "msFromStart": 272172.32355555554
            },
            {
              "lat": 43.76652517777777,
              "lng": -116.07680884444446,
              "alt": 1876.8317777777777,
              "speed2d": 0.9904444444444445,
              "speed3d": 1.1877777777777778,
              "msFromStart": 272723.7866666665
            },
            {
              "lat": 43.76651701666666,
              "lng": -116.07679251666667,
              "alt": 1875.7941666666668,
              "speed2d": 1.1595,
              "speed3d": 1.37,
              "msFromStart": 273275.7357037034
            },
            {
              "lat": 43.7665297,
              "lng": -116.07677774999999,
              "alt": 1875.4565,
              "speed2d": 1.3079999999999998,
              "speed3d": 1.495,
              "msFromStart": 273769.7333333332
            },
            {
              "lat": 43.766541275,
              "lng": -116.07676277499999,
              "alt": 1874.32275,
              "speed2d": 1.6520000000000001,
              "speed3d": 1.8050000000000002,
              "msFromStart": 274317.7830877192
            },
            {
              "lat": 43.766547360000004,
              "lng": -116.07675952,
              "alt": 1872.8442000000002,
              "speed2d": 1.5734,
              "speed3d": 1.814,
              "msFromStart": 274812.3991578946
            },
            {
              "lat": 43.76654972,
              "lng": -116.07675650000002,
              "alt": 1871.7782,
              "speed2d": 1.4615999999999998,
              "speed3d": 1.722,
              "msFromStart": 275186.4206409354
            },
            {
              "lat": 43.76655775,
              "lng": -116.07674936666666,
              "alt": 1870.3366666666666,
              "speed2d": 1.3739999999999999,
              "speed3d": 1.6666666666666665,
              "msFromStart": 275822.00651851844
            },
            {
              "lat": 43.766565033333336,
              "lng": -116.07674223333333,
              "alt": 1869.429333333333,
              "speed2d": 1.4803333333333333,
              "speed3d": 1.7533333333333334,
              "msFromStart": 276245.02933333325
            },
            {
              "lat": 43.76658442857143,
              "lng": -116.0767334,
              "alt": 1868.6564285714287,
              "speed2d": 1.8177142857142858,
              "speed3d": 2.0357142857142856,
              "msFromStart": 276779.76812698407
            },
            {
              "lat": 43.76660488,
              "lng": -116.07672752,
              "alt": 1868.0794,
              "speed2d": 2.1069999999999998,
              "speed3d": 2.2939999999999996,
              "msFromStart": 277290.375111111
            },
            {
              "lat": 43.766629171428576,
              "lng": -116.07671555714288,
              "alt": 1868.3115714285714,
              "speed2d": 2.5369999999999995,
              "speed3d": 2.6742857142857144,
              "msFromStart": 277775.4895238096
            },
            {
              "lat": 43.766657683333335,
              "lng": -116.07670596666668,
              "alt": 1867.8443333333332,
              "speed2d": 2.990333333333333,
              "speed3d": 3.103333333333333,
              "msFromStart": 278303.1895204679
            },
            {
              "lat": 43.76667845000001,
              "lng": -116.07669520000002,
              "alt": 1867.33475,
              "speed2d": 3.281375,
              "speed3d": 3.405,
              "msFromStart": 278770.32084210514
            },
            {
              "lat": 43.76669496,
              "lng": -116.07668265999999,
              "alt": 1866.8157999999999,
              "speed2d": 3.4034,
              "speed3d": 3.5359999999999996,
              "msFromStart": 279243.6174970759
            },
            {
              "lat": 43.76670956,
              "lng": -116.07666696000001,
              "alt": 1865.9378,
              "speed2d": 3.463,
              "speed3d": 3.6000000000000005,
              "msFromStart": 279772.25457777793
            },
            {
              "lat": 43.76671336,
              "lng": -116.07664672000001,
              "alt": 1864.602,
              "speed2d": 3.3739999999999997,
              "speed3d": 3.5460000000000003,
              "msFromStart": 280301.070577778
            },
            {
              "lat": 43.76671141428571,
              "lng": -116.07662615714285,
              "alt": 1863.0439999999999,
              "speed2d": 3.363428571428571,
              "speed3d": 3.512857142857143,
              "msFromStart": 280744.57625396835
            },
            {
              "lat": 43.766727425,
              "lng": -116.0765989,
              "alt": 1861.85175,
              "speed2d": 3.7538750000000003,
              "speed3d": 3.88125,
              "msFromStart": 281268.3286666668
            },
            {
              "lat": 43.766733300000006,
              "lng": -116.07657060000001,
              "alt": 1860.09275,
              "speed2d": 3.9055,
              "speed3d": 4.0625,
              "msFromStart": 281757.0902222224
            },
            {
              "lat": 43.76672421428571,
              "lng": -116.07653392857142,
              "alt": 1857.6977142857145,
              "speed2d": 3.8525714285714283,
              "speed3d": 4.055714285714285,
              "msFromStart": 282278.45892063517
            },
            {
              "lat": 43.76671528333333,
              "lng": -116.07651795,
              "alt": 1856.3256666666666,
              "speed2d": 3.6225,
              "speed3d": 3.873333333333333,
              "msFromStart": 282678.7004444444
            },
            {
              "lat": 43.766696800000005,
              "lng": -116.07650283999999,
              "alt": 1854.9002,
              "speed2d": 3.363600000000001,
              "speed3d": 3.6259999999999994,
              "msFromStart": 283161.44106666645
            },
            {
              "lat": 43.766675250000006,
              "lng": -116.0764482,
              "alt": 1852.425,
              "speed2d": 3.759,
              "speed3d": 3.96,
              "msFromStart": 283914.032
            },
            {
              "lat": 43.76668755714285,
              "lng": -116.07640771428571,
              "alt": 1851.2151428571428,
              "speed2d": 4.208714285714286,
              "speed3d": 4.377142857142857,
              "msFromStart": 284318.44185129495
            },
            {
              "lat": 43.76670494285713,
              "lng": -116.07636997142856,
              "alt": 1850.1648571428573,
              "speed2d": 4.559428571428571,
              "speed3d": 4.724285714285714,
              "msFromStart": 284734.8292063492
            },
            {
              "lat": 43.766731685714284,
              "lng": -116.07631405714285,
              "alt": 1848.4484285714286,
              "speed2d": 5.195857142857143,
              "speed3d": 5.334285714285715,
              "msFromStart": 285308.45561904775
            },
            {
              "lat": 43.76674335,
              "lng": -116.07629205,
              "alt": 1847.674,
              "speed2d": 5.423500000000001,
              "speed3d": 5.57,
              "msFromStart": 285563.67822222225
            },
            {
              "lat": 43.7667690676959,
              "lng": -116.0762653949315,
              "alt": 1846.3264180017477,
              "speed2d": 5.837297698805669,
              "speed3d": 5.978384794640227,
              "msFromStart": 286000
            },
            {
              "lat": 43.766764540000004,
              "lng": -116.07619954,
              "alt": 1843.1648,
              "speed2d": 5.5616,
              "speed3d": 5.786,
              "msFromStart": 286733.7824000001
            },
            {
              "lat": 43.766755225000004,
              "lng": -116.07616197499999,
              "alt": 1841.32075,
              "speed2d": 5.4805,
              "speed3d": 5.710000000000001,
              "msFromStart": 287115.53200000024
            },
            {
              "lat": 43.766736359999996,
              "lng": -116.07606892000001,
              "alt": 1838.6802000000002,
              "speed2d": 5.9384,
              "speed3d": 6.113999999999999,
              "msFromStart": 287800.1408000001
            },
            {
              "lat": 43.76673425,
              "lng": -116.07599272499999,
              "alt": 1837.1522499999999,
              "speed2d": 6.45875,
              "speed3d": 6.6175,
              "msFromStart": 288284.4475789475
            },
            {
              "lat": 43.76673870270938,
              "lng": -116.07596288408429,
              "alt": 1836.6405079753208,
              "speed2d": 6.641598544225565,
              "speed3d": 6.811814521939205,
              "msFromStart": 288500
            },
            {
              "lat": 43.76675607439572,
              "lng": -116.07589820292809,
              "alt": 1834.8630929524518,
              "speed2d": 7.138691883615628,
              "speed3d": 7.295399881240437,
              "msFromStart": 289000
            },
            {
              "lat": 43.76677098333334,
              "lng": -116.07579588333334,
              "alt": 1831.5665000000001,
              "speed2d": 7.6015,
              "speed3d": 7.756666666666667,
              "msFromStart": 289799.6622222223
            },
            {
              "lat": 43.766774925,
              "lng": -116.0757417,
              "alt": 1830.0710000000001,
              "speed2d": 7.88575,
              "speed3d": 8.0525,
              "msFromStart": 290144.10666666686
            },
            {
              "lat": 43.76677545986366,
              "lng": -116.075694073481,
              "alt": 1828.4822412599942,
              "speed2d": 8.073310166271016,
              "speed3d": 8.222380911910875,
              "msFromStart": 290500
            },
            {
              "lat": 43.7667739756633,
              "lng": -116.07563320322494,
              "alt": 1826.2105193188333,
              "speed2d": 8.262315057131513,
              "speed3d": 8.421763444655227,
              "msFromStart": 291000
            },
            {
              "lat": 43.766792499999994,
              "lng": -116.07555238,
              "alt": 1823.0084,
              "speed2d": 8.5552,
              "speed3d": 8.752,
              "msFromStart": 291861.1697777779
            },
            {
              "lat": 43.76679455,
              "lng": -116.07548202500001,
              "alt": 1821.3017499999999,
              "speed2d": 8.795,
              "speed3d": 8.965,
              "msFromStart": 292328.864888889
            },
            {
              "lat": 43.766789700000004,
              "lng": -116.07541212,
              "alt": 1819.7561999999998,
              "speed2d": 9.0828,
              "speed3d": 9.254,
              "msFromStart": 292708.64639999997
            },
            {
              "lat": 43.76677545,
              "lng": -116.07531535000001,
              "alt": 1817.75,
              "speed2d": 9.419,
              "speed3d": 9.595,
              "msFromStart": 293237.08163742686
            },
            {
              "lat": 43.76676246666666,
              "lng": -116.0752425,
              "alt": 1816.198,
              "speed2d": 9.682,
              "speed3d": 9.846666666666668,
              "msFromStart": 293640.6298947368
            },
            {
              "lat": 43.7667536748745,
              "lng": -116.07516719248002,
              "alt": 1814.861122449049,
              "speed2d": 10.091640876848563,
              "speed3d": 10.231842032218518,
              "msFromStart": 294000
            },
            {
              "lat": 43.76675160699325,
              "lng": -116.07506316566953,
              "alt": 1813.087345270519,
              "speed2d": 10.652009154655646,
              "speed3d": 10.772402672316758,
              "msFromStart": 294500
            },
            {
              "lat": 43.76673760549296,
              "lng": -116.07494398566365,
              "alt": 1811.0635789317187,
              "speed2d": 11.235006777639457,
              "speed3d": 11.36017896434142,
              "msFromStart": 295000
            },
            {
              "lat": 43.76671356666667,
              "lng": -116.07475226666668,
              "alt": 1807.6336666666666,
              "speed2d": 12.155333333333331,
              "speed3d": 12.286666666666669,
              "msFromStart": 295805.8097777777
            },
            {
              "lat": 43.76670629691903,
              "lng": -116.07470155257178,
              "alt": 1806.7278959866258,
              "speed2d": 12.365367254918157,
              "speed3d": 12.486763282749243,
              "msFromStart": 296000
            },
            {
              "lat": 43.766686703736994,
              "lng": -116.074601856738,
              "alt": 1804.5071300834031,
              "speed2d": 12.637397649475977,
              "speed3d": 12.793340517086587,
              "msFromStart": 296500
            },
            {
              "lat": 43.76668775052544,
              "lng": -116.07447251816006,
              "alt": 1802.7814013722807,
              "speed2d": 13.258120799991035,
              "speed3d": 13.358403973570919,
              "msFromStart": 297000
            },
            {
              "lat": 43.76667545657702,
              "lng": -116.07433864735715,
              "alt": 1801.66010849019,
              "speed2d": 13.716570009681973,
              "speed3d": 13.818670022930384,
              "msFromStart": 297500
            },
            {
              "lat": 43.766663162628596,
              "lng": -116.07420477655424,
              "alt": 1800.5388156080992,
              "speed2d": 14.175019219372913,
              "speed3d": 14.278936072289847,
              "msFromStart": 298000
            },
            {
              "lat": 43.766640233333334,
              "lng": -116.07396463333333,
              "alt": 1798.4846666666667,
              "speed2d": 14.978,
              "speed3d": 15.103333333333333,
              "msFromStart": 298903.5132631581
            },
            {
              "lat": 43.766634550000006,
              "lng": -116.07393210000001,
              "alt": 1798.0259999999998,
              "speed2d": 15.054,
              "speed3d": 15.18,
              "msFromStart": 299041.0669473687
            },
            {
              "lat": 43.76662765982933,
              "lng": -116.07385571370885,
              "alt": 1796.3720140391565,
              "speed2d": 14.902626374896705,
              "speed3d": 15.038654662241527,
              "msFromStart": 299500
            },
            {
              "lat": 43.766621007217196,
              "lng": -116.07377483438414,
              "alt": 1794.5594212459646,
              "speed2d": 14.708647506653517,
              "speed3d": 14.85166232103252,
              "msFromStart": 300000
            },
            {
              "lat": 43.76661435460505,
              "lng": -116.07369395505941,
              "alt": 1792.7468284527727,
              "speed2d": 14.514668638410328,
              "speed3d": 14.664669979823515,
              "msFromStart": 300500
            },
            {
              "lat": 43.76660770199292,
              "lng": -116.07361307573468,
              "alt": 1790.9342356595807,
              "speed2d": 14.320689770167139,
              "speed3d": 14.477677638614507,
              "msFromStart": 301000
            },
            {
              "lat": 43.766601049380775,
              "lng": -116.07353219640996,
              "alt": 1789.1216428663888,
              "speed2d": 14.12671090192395,
              "speed3d": 14.290685297405501,
              "msFromStart": 301500
            },
            {
              "lat": 43.76659439676863,
              "lng": -116.07345131708523,
              "alt": 1787.3090500731969,
              "speed2d": 13.93273203368076,
              "speed3d": 14.103692956196495,
              "msFromStart": 302000
            },
            {
              "lat": 43.766587744156496,
              "lng": -116.0733704377605,
              "alt": 1785.496457280005,
              "speed2d": 13.73875316543757,
              "speed3d": 13.916700614987487,
              "msFromStart": 302500
            },
            {
              "lat": 43.76658109154435,
              "lng": -116.07328955843579,
              "alt": 1783.683864486813,
              "speed2d": 13.544774297194381,
              "speed3d": 13.729708273778481,
              "msFromStart": 303000
            },
            {
              "lat": 43.76657443893222,
              "lng": -116.07320867911106,
              "alt": 1781.8712716936213,
              "speed2d": 13.350795428951193,
              "speed3d": 13.542715932569475,
              "msFromStart": 303500
            },
            {
              "lat": 43.766567786320074,
              "lng": -116.07312779978633,
              "alt": 1780.0586789004294,
              "speed2d": 13.156816560708002,
              "speed3d": 13.355723591360467,
              "msFromStart": 304000
            },
            {
              "lat": 43.76656113370794,
              "lng": -116.0730469204616,
              "alt": 1778.2460861072375,
              "speed2d": 12.962837692464813,
              "speed3d": 13.168731250151462,
              "msFromStart": 304500
            },
            {
              "lat": 43.766554481095795,
              "lng": -116.07296604113688,
              "alt": 1776.4334933140456,
              "speed2d": 12.768858824221624,
              "speed3d": 12.981738908942456,
              "msFromStart": 305000
            },
            {
              "lat": 43.76654782848366,
              "lng": -116.07288516181215,
              "alt": 1774.6209005208536,
              "speed2d": 12.574879955978435,
              "speed3d": 12.79474656773345,
              "msFromStart": 305500
            },
            {
              "lat": 43.76654117587152,
              "lng": -116.07280428248744,
              "alt": 1772.8083077276617,
              "speed2d": 12.380901087735246,
              "speed3d": 12.607754226524442,
              "msFromStart": 306000
            },
            {
              "lat": 43.76653452325938,
              "lng": -116.07272340316271,
              "alt": 1770.9957149344698,
              "speed2d": 12.186922219492057,
              "speed3d": 12.420761885315436,
              "msFromStart": 306500
            },
            {
              "lat": 43.76652787064724,
              "lng": -116.07264252383798,
              "alt": 1769.1831221412779,
              "speed2d": 11.992943351248867,
              "speed3d": 12.23376954410643,
              "msFromStart": 307000
            },
            {
              "lat": 43.7665212180351,
              "lng": -116.07256164451326,
              "alt": 1767.370529348086,
              "speed2d": 11.798964483005678,
              "speed3d": 12.046777202897424,
              "msFromStart": 307500
            },
            {
              "lat": 43.76651456542296,
              "lng": -116.07248076518853,
              "alt": 1765.557936554894,
              "speed2d": 11.604985614762489,
              "speed3d": 11.859784861688416,
              "msFromStart": 308000
            },
            {
              "lat": 43.76651514610454,
              "lng": -116.07237681097577,
              "alt": 1763.430612613752,
              "speed2d": 11.532157408813045,
              "speed3d": 11.790247544199392,
              "msFromStart": 308500
            },
            {
              "lat": 43.766496501823795,
              "lng": -116.07233418620302,
              "alt": 1762.139794792486,
              "speed2d": 11.137329708705469,
              "speed3d": 11.408533161326563,
              "msFromStart": 309000
            },
            {
              "lat": 43.76647785754305,
              "lng": -116.07229156143026,
              "alt": 1760.84897697122,
              "speed2d": 10.742502008597894,
              "speed3d": 11.026818778453734,
              "msFromStart": 309500
            },
            {
              "lat": 43.766459213262316,
              "lng": -116.07224893665752,
              "alt": 1759.5581591499542,
              "speed2d": 10.347674308490317,
              "speed3d": 10.645104395580905,
              "msFromStart": 310000
            },
            {
              "lat": 43.76644056898157,
              "lng": -116.07220631188476,
              "alt": 1758.2673413286884,
              "speed2d": 9.95284660838274,
              "speed3d": 10.263390012708076,
              "msFromStart": 310500
            },
            {
              "lat": 43.76642192470083,
              "lng": -116.072163687112,
              "alt": 1756.9765235074226,
              "speed2d": 9.558018908275166,
              "speed3d": 9.881675629835247,
              "msFromStart": 311000
            },
            {
              "lat": 43.76640328042009,
              "lng": -116.07212106233925,
              "alt": 1755.6857056861566,
              "speed2d": 9.16319120816759,
              "speed3d": 9.499961246962418,
              "msFromStart": 311500
            },
            {
              "lat": 43.76638463613935,
              "lng": -116.07207843756649,
              "alt": 1754.3948878648907,
              "speed2d": 8.768363508060013,
              "speed3d": 9.118246864089587,
              "msFromStart": 312000
            },
            {
              "lat": 43.766366830603445,
              "lng": -116.07203960287889,
              "alt": 1753.2114879834164,
              "speed2d": 8.259582571112203,
              "speed3d": 8.630393533335056,
              "msFromStart": 312500
            },
            {
              "lat": 43.76634543892588,
              "lng": -116.07198456328409,
              "alt": 1751.5688114171132,
              "speed2d": 8.238020667996649,
              "speed3d": 8.596348423152604,
              "msFromStart": 313000
            },
            {
              "lat": 43.76632437297707,
              "lng": -116.07192861186435,
              "alt": 1749.9153399126826,
              "speed2d": 8.225446111845152,
              "speed3d": 8.569158235042325,
              "msFromStart": 313500
            },
            {
              "lat": 43.76629816672533,
              "lng": -116.07188704989126,
              "alt": 1748.432222582454,
              "speed2d": 8.071042852993623,
              "speed3d": 8.433790994405369,
              "msFromStart": 314000
            },
            {
              "lat": 43.76627196047358,
              "lng": -116.07184548791815,
              "alt": 1746.9491052522253,
              "speed2d": 7.916639594142095,
              "speed3d": 8.298423753768411,
              "msFromStart": 314500
            },
            {
              "lat": 43.766245754221835,
              "lng": -116.07180392594505,
              "alt": 1745.4659879219967,
              "speed2d": 7.762236335290566,
              "speed3d": 8.163056513131455,
              "msFromStart": 315000
            },
            {
              "lat": 43.76621709532569,
              "lng": -116.0717696043653,
              "alt": 1744.160947076946,
              "speed2d": 7.621414774852999,
              "speed3d": 8.07501678960568,
              "msFromStart": 315500
            },
            {
              "lat": 43.76619438223925,
              "lng": -116.07171953594272,
              "alt": 1742.496504291623,
              "speed2d": 7.422975752618186,
              "speed3d": 7.832282083482155,
              "msFromStart": 316000
            },
            {
              "lat": 43.76617053348872,
              "lng": -116.07166782044564,
              "alt": 1740.7143286403912,
              "speed2d": 7.299195552841715,
              "speed3d": 7.722111225042364,
              "msFromStart": 316500
            },
            {
              "lat": 43.76614598400229,
              "lng": -116.07161595488795,
              "alt": 1738.913601903079,
              "speed2d": 7.189141763483402,
              "speed3d": 7.625398018902065,
              "msFromStart": 317000
            },
            {
              "lat": 43.76612860077133,
              "lng": -116.07156562396368,
              "alt": 1737.302592611012,
              "speed2d": 6.938711313392219,
              "speed3d": 7.391056675939646,
              "msFromStart": 317500
            },
            {
              "lat": 43.76610670753882,
              "lng": -116.07151143460557,
              "alt": 1735.3901271770071,
              "speed2d": 6.806435064736216,
              "speed3d": 7.017910991399076,
              "msFromStart": 318000
            },
            {
              "lat": 43.76609015,
              "lng": -116.0714463,
              "alt": 1733.3647500000002,
              "speed2d": 6.222250000000001,
              "speed3d": 6.7625,
              "msFromStart": 318731.3406315791
            },
            {
              "lat": 43.766087020779636,
              "lng": -116.07142525975782,
              "alt": 1732.4471634136742,
              "speed2d": 5.964435472330085,
              "speed3d": 6.5046050637383255,
              "msFromStart": 319000
            },
            {
              "lat": 43.76607434,
              "lng": -116.07136033999998,
              "alt": 1730.0484,
              "speed2d": 5.2612000000000005,
              "speed3d": 5.8759999999999994,
              "msFromStart": 319860.1155555554
            }
          ]
        }
      ]
    },
    {
      "id": "lift-superior",
      "pathType": "lift",
      "name": "Superior lift",
      "speed": "fast",
      "seats": 4,
      "videos": [
        {
          "videoId": "GX010065",
          "pathId": "lift-superior",
          "startSec": 2,
          "endSec": 281,
          "id": "video-segment-25",
          "gps": [
            {
              "lat": 43.78248128888888,
              "lng": -116.09701065555555,
              "alt": 1814.7205555555554,
              "speed2d": 0.7675555555555555,
              "speed3d": 1.0033333333333332,
              "msFromStart": 2240.281518518519
            },
            {
              "lat": 43.782478044444446,
              "lng": -116.09701751111112,
              "alt": 1814.5504444444446,
              "speed2d": 0.8032222222222223,
              "speed3d": 1.04,
              "msFromStart": 2734.774000000002
            },
            {
              "lat": 43.782474866666675,
              "lng": -116.09702362222224,
              "alt": 1814.317333333333,
              "speed2d": 0.8454444444444443,
              "speed3d": 1.0655555555555556,
              "msFromStart": 3229.364280701755
            },
            {
              "lat": 43.78247132999999,
              "lng": -116.09703131,
              "alt": 1814.0181,
              "speed2d": 0.965,
              "speed3d": 1.165,
              "msFromStart": 3751.7285263157883
            },
            {
              "lat": 43.78247004444444,
              "lng": -116.09703913333333,
              "alt": 1813.6791111111113,
              "speed2d": 0.9933333333333335,
              "speed3d": 1.1866666666666665,
              "msFromStart": 4274.054153021441
            },
            {
              "lat": 43.782468699999995,
              "lng": -116.09704901111112,
              "alt": 1813.3063333333334,
              "speed2d": 1.1053333333333333,
              "speed3d": 1.2844444444444445,
              "msFromStart": 4768.7412777777745
            },
            {
              "lat": 43.78246851111111,
              "lng": -116.09705791111111,
              "alt": 1812.935777777778,
              "speed2d": 1.1482222222222225,
              "speed3d": 1.328888888888889,
              "msFromStart": 5263.273907407406
            },
            {
              "lat": 43.78246646666666,
              "lng": -116.09706474444445,
              "alt": 1812.6157777777776,
              "speed2d": 1.123888888888889,
              "speed3d": 1.3144444444444445,
              "msFromStart": 5757.407888888891
            },
            {
              "lat": 43.782463722222225,
              "lng": -116.09707037777778,
              "alt": 1812.2747777777781,
              "speed2d": 1.072888888888889,
              "speed3d": 1.2711111111111109,
              "msFromStart": 6253.176777777779
            },
            {
              "lat": 43.78246353333333,
              "lng": -116.09707394444443,
              "alt": 1812.0096666666664,
              "speed2d": 0.9583333333333333,
              "speed3d": 1.1844444444444444,
              "msFromStart": 6753.440222222223
            },
            {
              "lat": 43.78246325,
              "lng": -116.09707470000001,
              "alt": 1811.8635,
              "speed2d": 0.863,
              "speed3d": 1.1,
              "msFromStart": 7059.3013333333365
            },
            {
              "lat": 43.78245668561517,
              "lng": -116.09708095545213,
              "alt": 1814.4921012343182,
              "speed2d": 0.9148029601684661,
              "speed3d": 1.0979671600280778,
              "msFromStart": 7500
            },
            {
              "lat": 43.78244916019921,
              "lng": -116.0970881673091,
              "alt": 1817.6940210086673,
              "speed2d": 0.9726907752979398,
              "speed3d": 1.1076151292163232,
              "msFromStart": 8000
            },
            {
              "lat": 43.78244163478324,
              "lng": -116.09709537916606,
              "alt": 1820.8959407830164,
              "speed2d": 1.0305785904274134,
              "speed3d": 1.1172630984045688,
              "msFromStart": 8500
            },
            {
              "lat": 43.78243004285714,
              "lng": -116.09710584285713,
              "alt": 1825.412,
              "speed2d": 1.1038571428571429,
              "speed3d": 1.125714285714286,
              "msFromStart": 9325.776833333332
            },
            {
              "lat": 43.782425599999996,
              "lng": -116.09710817777778,
              "alt": 1826.0950000000003,
              "speed2d": 1.096,
              "speed3d": 1.1099999999999999,
              "msFromStart": 9767.629722222215
            },
            {
              "lat": 43.782421533333334,
              "lng": -116.09711141111111,
              "alt": 1826.014111111111,
              "speed2d": 1.0903333333333334,
              "speed3d": 1.1077777777777778,
              "msFromStart": 10264.080981481478
            },
            {
              "lat": 43.78242031111112,
              "lng": -116.09711031111112,
              "alt": 1825.9407777777778,
              "speed2d": 0.9728888888888889,
              "speed3d": 1.0055555555555555,
              "msFromStart": 10758.813444444442
            },
            {
              "lat": 43.78241911111111,
              "lng": -116.0971075777778,
              "alt": 1825.8884444444443,
              "speed2d": 0.8673333333333333,
              "speed3d": 0.8877777777777777,
              "msFromStart": 11253.680092592593
            },
            {
              "lat": 43.78241745555555,
              "lng": -116.09710687777778,
              "alt": 1825.849888888889,
              "speed2d": 0.8193333333333334,
              "speed3d": 0.8366666666666666,
              "msFromStart": 11749.156500000006
            },
            {
              "lat": 43.78241431111111,
              "lng": -116.09710897777778,
              "alt": 1825.7862222222222,
              "speed2d": 0.825111111111111,
              "speed3d": 0.8355555555555555,
              "msFromStart": 12244.517740740743
            },
            {
              "lat": 43.78240916666666,
              "lng": -116.09711353333333,
              "alt": 1825.7563333333333,
              "speed2d": 0.8925555555555555,
              "speed3d": 0.8944444444444445,
              "msFromStart": 12739.47927777777
            },
            {
              "lat": 43.78239935555555,
              "lng": -116.09712132222224,
              "alt": 1825.7294444444444,
              "speed2d": 1.0831111111111111,
              "speed3d": 1.0733333333333333,
              "msFromStart": 13234.718028265104
            },
            {
              "lat": 43.78238916666666,
              "lng": -116.0971269,
              "alt": 1825.683222222222,
              "speed2d": 1.2016666666666667,
              "speed3d": 1.211111111111111,
              "msFromStart": 13730.766315789482
            },
            {
              "lat": 43.782381511111105,
              "lng": -116.09712548888889,
              "alt": 1825.651444444444,
              "speed2d": 1.212222222222222,
              "speed3d": 1.2244444444444444,
              "msFromStart": 14226.79547076024
            },
            {
              "lat": 43.78237744,
              "lng": -116.09712912,
              "alt": 1825.6273000000003,
              "speed2d": 1.2029999999999998,
              "speed3d": 1.2000000000000002,
              "msFromStart": 14750.119750000009
            },
            {
              "lat": 43.78235765555556,
              "lng": -116.09713489999999,
              "alt": 1825.6204444444443,
              "speed2d": 1.495111111111111,
              "speed3d": 1.4766666666666668,
              "msFromStart": 15273.005944444449
            },
            {
              "lat": 43.78235044444445,
              "lng": -116.09714489999999,
              "alt": 1825.9574444444447,
              "speed2d": 1.5545555555555555,
              "speed3d": 1.5633333333333335,
              "msFromStart": 15767.227999999994
            },
            {
              "lat": 43.78234233333333,
              "lng": -116.09714593333332,
              "alt": 1826.8222222222223,
              "speed2d": 1.5677777777777777,
              "speed3d": 1.5744444444444443,
              "msFromStart": 16261.580648148145
            },
            {
              "lat": 43.78234175555555,
              "lng": -116.0971367888889,
              "alt": 1826.9386666666667,
              "speed2d": 1.4497777777777778,
              "speed3d": 1.4644444444444442,
              "msFromStart": 16756.44805555556
            },
            {
              "lat": 43.782340077777775,
              "lng": -116.09713043333332,
              "alt": 1826.9405555555556,
              "speed2d": 1.4002222222222223,
              "speed3d": 1.4133333333333333,
              "msFromStart": 17251.311574074072
            },
            {
              "lat": 43.78233788888889,
              "lng": -116.09712908888889,
              "alt": 1826.946888888889,
              "speed2d": 1.3661111111111113,
              "speed3d": 1.3666666666666667,
              "msFromStart": 17746.090999999986
            },
            {
              "lat": 43.782334,
              "lng": -116.09712858888889,
              "alt": 1826.9614444444442,
              "speed2d": 1.3509999999999998,
              "speed3d": 1.3633333333333335,
              "msFromStart": 18240.87040740741
            },
            {
              "lat": 43.78233137777777,
              "lng": -116.09712534444444,
              "alt": 1826.9695555555552,
              "speed2d": 1.298111111111111,
              "speed3d": 1.3055555555555554,
              "msFromStart": 18735.661777777794
            },
            {
              "lat": 43.78232765555556,
              "lng": -116.09712436666666,
              "alt": 1826.9708888888886,
              "speed2d": 1.2955555555555553,
              "speed3d": 1.29,
              "msFromStart": 19230.610370370377
            },
            {
              "lat": 43.78231976666667,
              "lng": -116.09712907777778,
              "alt": 1826.9868888888889,
              "speed2d": 1.3924444444444442,
              "speed3d": 1.3922222222222222,
              "msFromStart": 19725.98400000002
            },
            {
              "lat": 43.78231434,
              "lng": -116.09712676999999,
              "alt": 1826.9703999999997,
              "speed2d": 1.3947,
              "speed3d": 1.403,
              "msFromStart": 20248.988800000006
            },
            {
              "lat": 43.78231164444445,
              "lng": -116.09712065555557,
              "alt": 1826.9613333333334,
              "speed2d": 1.3504444444444446,
              "speed3d": 1.351111111111111,
              "msFromStart": 20772.274666666657
            },
            {
              "lat": 43.782296125,
              "lng": -116.09710962500002,
              "alt": 1826.9766249999998,
              "speed2d": 1.5127499999999998,
              "speed3d": 1.48625,
              "msFromStart": 21240.38875
            },
            {
              "lat": 43.7822791012359,
              "lng": -116.09709747598997,
              "alt": 1826.975578323186,
              "speed2d": 1.7362620467803338,
              "speed3d": 1.6946123788504588,
              "msFromStart": 21500
            },
            {
              "lat": 43.7821926432442,
              "lng": -116.09702590561965,
              "alt": 1826.9330195291025,
              "speed2d": 3.2781925077687446,
              "speed3d": 3.1267158240709243,
              "msFromStart": 22000
            },
            {
              "lat": 43.782163914498746,
              "lng": -116.09699346116894,
              "alt": 1826.7880163974376,
              "speed2d": 3.4730404659433938,
              "speed3d": 3.3804713044844203,
              "msFromStart": 22500
            },
            {
              "lat": 43.7821306625,
              "lng": -116.09695119999999,
              "alt": 1826.564,
              "speed2d": 3.596125,
              "speed3d": 3.6262499999999998,
              "msFromStart": 23230.814125
            },
            {
              "lat": 43.782144304034354,
              "lng": -116.09694389812115,
              "alt": 1826.4680605152557,
              "speed2d": 3.192466428458965,
              "speed3d": 3.1678226038928856,
              "msFromStart": 23500
            },
            {
              "lat": 43.782128144733804,
              "lng": -116.09693173678286,
              "alt": 1826.3354749907153,
              "speed2d": 3.1278638842041007,
              "speed3d": 3.1351474536540747,
              "msFromStart": 24000
            },
            {
              "lat": 43.782159050000004,
              "lng": -116.09690570000001,
              "alt": 1826.034,
              "speed2d": 2.2904999999999998,
              "speed3d": 2.365,
              "msFromStart": 24764.503894736823
            },
            {
              "lat": 43.782177520000005,
              "lng": -116.09689791999999,
              "alt": 1825.94,
              "speed2d": 1.7906,
              "speed3d": 1.8320000000000003,
              "msFromStart": 25143.954263157873
            },
            {
              "lat": 43.78214661666667,
              "lng": -116.0968858,
              "alt": 1826.0096666666666,
              "speed2d": 2.081166666666667,
              "speed3d": 2.03,
              "msFromStart": 25736.625055555553
            },
            {
              "lat": 43.7821255,
              "lng": -116.09687086666665,
              "alt": 1826.1036666666669,
              "speed2d": 2.3970000000000002,
              "speed3d": 2.36,
              "msFromStart": 26112.829666666665
            },
            {
              "lat": 43.782097160039115,
              "lng": -116.0968658800335,
              "alt": 1826.2484998603404,
              "speed2d": 2.7685744204121776,
              "speed3d": 2.659874336616348,
              "msFromStart": 26500
            },
            {
              "lat": 43.782059966666665,
              "lng": -116.09683375000002,
              "alt": 1826.3841666666667,
              "speed2d": 3.3006666666666664,
              "speed3d": 3.27,
              "msFromStart": 27247.797370370372
            },
            {
              "lat": 43.78203155,
              "lng": -116.0968081,
              "alt": 1826.4755,
              "speed2d": 3.734,
              "speed3d": 3.6900000000000004,
              "msFromStart": 27789.210444444434
            },
            {
              "lat": 43.78202355577311,
              "lng": -116.09679832200335,
              "alt": 1826.4742202596758,
              "speed2d": 3.8164621334698925,
              "speed3d": 3.77733465040042,
              "msFromStart": 28000
            },
            {
              "lat": 43.78199855518476,
              "lng": -116.09677921410135,
              "alt": 1826.5448458385683,
              "speed2d": 4.063001730953806,
              "speed3d": 4.02430753364441,
              "msFromStart": 28500
            },
            {
              "lat": 43.78197394881654,
              "lng": -116.09676022079118,
              "alt": 1826.5951110123297,
              "speed2d": 4.32538626513027,
              "speed3d": 4.283284809412648,
              "msFromStart": 29000
            },
            {
              "lat": 43.781943,
              "lng": -116.09673712499999,
              "alt": 1826.72275,
              "speed2d": 4.5634999999999994,
              "speed3d": 4.555,
              "msFromStart": 29603.898052631594
            },
            {
              "lat": 43.78192268754803,
              "lng": -116.09672129134734,
              "alt": 1826.8273486602113,
              "speed2d": 4.682077376099358,
              "speed3d": 4.655045753247748,
              "msFromStart": 30000
            },
            {
              "lat": 43.78189631192527,
              "lng": -116.09670371804047,
              "alt": 1827.0250092061112,
              "speed2d": 4.828364975045868,
              "speed3d": 4.802273101223892,
              "msFromStart": 30500
            },
            {
              "lat": 43.781869936302506,
              "lng": -116.09668614473361,
              "alt": 1827.2226697520111,
              "speed2d": 4.974652573992376,
              "speed3d": 4.949500449200036,
              "msFromStart": 31000
            },
            {
              "lat": 43.781829625,
              "lng": -116.09665899999999,
              "alt": 1827.5643750000002,
              "speed2d": 5.09875,
              "speed3d": 5.109999999999999,
              "msFromStart": 31805.838999999978
            },
            {
              "lat": 43.7818118,
              "lng": -116.0966463,
              "alt": 1827.7865000000002,
              "speed2d": 4.9399999999999995,
              "speed3d": 4.955,
              "msFromStart": 32273.80199999998
            },
            {
              "lat": 43.781803552846156,
              "lng": -116.09664024169898,
              "alt": 1827.8884315574558,
              "speed2d": 4.856336157039825,
              "speed3d": 4.868468054707289,
              "msFromStart": 32500
            },
            {
              "lat": 43.78177025,
              "lng": -116.0966193,
              "alt": 1828.2269999999999,
              "speed2d": 4.9935,
              "speed3d": 4.89,
              "msFromStart": 33236.182
            },
            {
              "lat": 43.78175043732953,
              "lng": -116.09660801044562,
              "alt": 1828.220971747446,
              "speed2d": 5.439765393243918,
              "speed3d": 5.300565051082165,
              "msFromStart": 33500
            },
            {
              "lat": 43.781697414285716,
              "lng": -116.0965778,
              "alt": 1828.2638571428574,
              "speed2d": 6.481000000000001,
              "speed3d": 6.4628571428571435,
              "msFromStart": 34199.90688721805
            },
            {
              "lat": 43.781672566666664,
              "lng": -116.09656203333333,
              "alt": 1828.5240000000001,
              "speed2d": 6.424666666666667,
              "speed3d": 6.4399999999999995,
              "msFromStart": 34695.38821052635
            },
            {
              "lat": 43.781659627200376,
              "lng": -116.09655139188169,
              "alt": 1828.7000254161776,
              "speed2d": 6.311708270889871,
              "speed3d": 6.326968893029287,
              "msFromStart": 35000
            },
            {
              "lat": 43.78162878571429,
              "lng": -116.09653485714284,
              "alt": 1829.2437142857145,
              "speed2d": 5.854571428571429,
              "speed3d": 5.91,
              "msFromStart": 35794.36888888888
            },
            {
              "lat": 43.7816172,
              "lng": -116.096525275,
              "alt": 1829.589,
              "speed2d": 5.5455,
              "speed3d": 5.5575,
              "msFromStart": 36178.51316666666
            },
            {
              "lat": 43.7815906,
              "lng": -116.09650865,
              "alt": 1829.92,
              "speed2d": 5.652,
              "speed3d": 5.635,
              "msFromStart": 36755.80099999998
            },
            {
              "lat": 43.781562837500005,
              "lng": -116.09649116249999,
              "alt": 1830.2955000000002,
              "speed2d": 5.867875000000001,
              "speed3d": 5.8825,
              "msFromStart": 37305.388999999996
            },
            {
              "lat": 43.7815499,
              "lng": -116.0964817,
              "alt": 1830.7150000000001,
              "speed2d": 5.581200000000001,
              "speed3d": 5.619999999999999,
              "msFromStart": 37705.9899111111
            },
            {
              "lat": 43.78153236666667,
              "lng": -116.09646863333334,
              "alt": 1831.1488333333334,
              "speed2d": 5.445,
              "speed3d": 5.4833333333333325,
              "msFromStart": 38183.5961111111
            },
            {
              "lat": 43.78151908493341,
              "lng": -116.09646050483073,
              "alt": 1831.507154179618,
              "speed2d": 5.440727450589413,
              "speed3d": 5.418685223740548,
              "msFromStart": 38500
            },
            {
              "lat": 43.781497714181384,
              "lng": -116.09645160875021,
              "alt": 1831.8960426652013,
              "speed2d": 5.327096554119969,
              "speed3d": 5.313413795099974,
              "msFromStart": 39000
            },
            {
              "lat": 43.781471275,
              "lng": -116.096435325,
              "alt": 1832.4367499999998,
              "speed2d": 5.146,
              "speed3d": 5.1625,
              "msFromStart": 39725.35263157896
            },
            {
              "lat": 43.78145008333333,
              "lng": -116.09642308333333,
              "alt": 1832.8574999999998,
              "speed2d": 5.119999999999999,
              "speed3d": 5.114999999999999,
              "msFromStart": 40275.98702923977
            },
            {
              "lat": 43.78143,
              "lng": -116.09641060000001,
              "alt": 1833.2108,
              "speed2d": 5.2358,
              "speed3d": 5.224,
              "msFromStart": 40733.08013333334
            },
            {
              "lat": 43.781418150662674,
              "lng": -116.09640251990987,
              "alt": 1833.423328292137,
              "speed2d": 5.290783141396328,
              "speed3d": 5.277249874823692,
              "msFromStart": 41000
            },
            {
              "lat": 43.78137985,
              "lng": -116.0963824,
              "alt": 1834.1660000000002,
              "speed2d": 5.25,
              "speed3d": 5.25,
              "msFromStart": 41926.041999999965
            },
            {
              "lat": 43.78137595067052,
              "lng": -116.09638036145019,
              "alt": 1834.2400943862624,
              "speed2d": 5.297620519158745,
              "speed3d": 5.274476059424994,
              "msFromStart": 42000
            },
            {
              "lat": 43.7813435875,
              "lng": -116.096360675,
              "alt": 1835.024625,
              "speed2d": 5.327999999999999,
              "speed3d": 5.344999999999999,
              "msFromStart": 42806.46388888891
            },
            {
              "lat": 43.78132785,
              "lng": -116.09635038749998,
              "alt": 1835.437875,
              "speed2d": 5.186999999999999,
              "speed3d": 5.1937500000000005,
              "msFromStart": 43294.66777777778
            },
            {
              "lat": 43.7813184,
              "lng": -116.0963449,
              "alt": 1835.7175000000002,
              "speed2d": 5.1365,
              "speed3d": 5.155,
              "msFromStart": 43575.89933333332
            },
            {
              "lat": 43.7813037878885,
              "lng": -116.09634054582943,
              "alt": 1836.1020986473518,
              "speed2d": 4.998770852853104,
              "speed3d": 5.001435826637653,
              "msFromStart": 44000
            },
            {
              "lat": 43.78128423504896,
              "lng": -116.0963269249083,
              "alt": 1836.4775032349114,
              "speed2d": 5.0711085405653185,
              "speed3d": 5.06,
              "msFromStart": 44500
            },
            {
              "lat": 43.781266351063145,
              "lng": -116.09631587464064,
              "alt": 1836.966404879545,
              "speed2d": 5.069654557979048,
              "speed3d": 5.06,
              "msFromStart": 45000
            },
            {
              "lat": 43.78123275,
              "lng": -116.0962952,
              "alt": 1837.8865,
              "speed2d": 5.064500000000001,
              "speed3d": 5.0649999999999995,
              "msFromStart": 45940.35500000002
            },
            {
              "lat": 43.78122365,
              "lng": -116.09629047500002,
              "alt": 1838.21475,
              "speed2d": 5.0024999999999995,
              "speed3d": 5.022499999999999,
              "msFromStart": 46229.224833333334
            },
            {
              "lat": 43.78121528656831,
              "lng": -116.09628671797373,
              "alt": 1838.5237712520775,
              "speed2d": 4.932652613590038,
              "speed3d": 4.9178660123545805,
              "msFromStart": 46500
            },
            {
              "lat": 43.78119877079441,
              "lng": -116.09627976958988,
              "alt": 1839.058262316812,
              "speed2d": 4.891496801605467,
              "speed3d": 4.8804516378231515,
              "msFromStart": 47000
            },
            {
              "lat": 43.78116693333333,
              "lng": -116.09626610000001,
              "alt": 1839.942,
              "speed2d": 5.0249999999999995,
              "speed3d": 4.97,
              "msFromStart": 47839.13733333332
            },
            {
              "lat": 43.78114836,
              "lng": -116.09625554,
              "alt": 1840.3912999999998,
              "speed2d": 5.207399999999999,
              "speed3d": 5.2219999999999995,
              "msFromStart": 48251.26693333333
            },
            {
              "lat": 43.781136088888886,
              "lng": -116.09624739999998,
              "alt": 1841.0672222222224,
              "speed2d": 4.895444444444445,
              "speed3d": 4.933333333333334,
              "msFromStart": 48772.870666666684
            },
            {
              "lat": 43.78112726666667,
              "lng": -116.09624133333334,
              "alt": 1841.8336666666667,
              "speed2d": 4.538333333333334,
              "speed3d": 4.566666666666666,
              "msFromStart": 49267.65512280703
            },
            {
              "lat": 43.7811054,
              "lng": -116.09623135000001,
              "alt": 1842.6085,
              "speed2d": 4.696,
              "speed3d": 4.64,
              "msFromStart": 49874.394105263156
            },
            {
              "lat": 43.781080966666664,
              "lng": -116.09622189999999,
              "alt": 1842.8126666666667,
              "speed2d": 5.300333333333334,
              "speed3d": 5.203333333333333,
              "msFromStart": 50241.768276803115
            },
            {
              "lat": 43.781064398090734,
              "lng": -116.09621400457969,
              "alt": 1842.942356892558,
              "speed2d": 5.645874042590015,
              "speed3d": 5.55,
              "msFromStart": 50500
            },
            {
              "lat": 43.781033814285706,
              "lng": -116.09619752857144,
              "alt": 1843.819857142857,
              "speed2d": 5.476714285714286,
              "speed3d": 5.501428571428572,
              "msFromStart": 51303.81853968253
            },
            {
              "lat": 43.781018275,
              "lng": -116.096189075,
              "alt": 1844.5885,
              "speed2d": 5.3035,
              "speed3d": 5.32,
              "msFromStart": 51798.58377777775
            },
            {
              "lat": 43.78101221679571,
              "lng": -116.09618328498823,
              "alt": 1844.8976987307776,
              "speed2d": 5.238124231325883,
              "speed3d": 5.238963694622203,
              "msFromStart": 52000
            },
            {
              "lat": 43.78099666036252,
              "lng": -116.09617513638037,
              "alt": 1845.6639035731607,
              "speed2d": 5.135088115408831,
              "speed3d": 5.136264298038213,
              "msFromStart": 52500
            },
            {
              "lat": 43.780981103929335,
              "lng": -116.0961669877725,
              "alt": 1846.430108415544,
              "speed2d": 5.032051999491778,
              "speed3d": 5.033564901454223,
              "msFromStart": 53000
            },
            {
              "lat": 43.780965547496145,
              "lng": -116.09615883916464,
              "alt": 1847.1963132579272,
              "speed2d": 4.929015883574726,
              "speed3d": 4.930865504870234,
              "msFromStart": 53500
            },
            {
              "lat": 43.78094999106296,
              "lng": -116.09615069055678,
              "alt": 1847.9625181003103,
              "speed2d": 4.825979767657675,
              "speed3d": 4.828166108286244,
              "msFromStart": 54000
            },
            {
              "lat": 43.78093443462977,
              "lng": -116.09614254194892,
              "alt": 1848.7287229426936,
              "speed2d": 4.722943651740622,
              "speed3d": 4.725466711702254,
              "msFromStart": 54500
            },
            {
              "lat": 43.78090911666666,
              "lng": -116.09612871666667,
              "alt": 1849.9978333333333,
              "speed2d": 4.527,
              "speed3d": 4.556666666666667,
              "msFromStart": 55346.10988888888
            },
            {
              "lat": 43.780899766666664,
              "lng": -116.09612233333334,
              "alt": 1850.5516666666667,
              "speed2d": 4.322666666666667,
              "speed3d": 4.3566666666666665,
              "msFromStart": 55778.17740740739
            },
            {
              "lat": 43.7808902,
              "lng": -116.0961136,
              "alt": 1851.2525,
              "speed2d": 4.091,
              "speed3d": 4.12,
              "msFromStart": 56283.07444444444
            },
            {
              "lat": 43.780881050000005,
              "lng": -116.0961058,
              "alt": 1851.8145,
              "speed2d": 4.0065,
              "speed3d": 4.015000000000001,
              "msFromStart": 56694.90000000003
            },
            {
              "lat": 43.78087132817677,
              "lng": -116.09609901704842,
              "alt": 1852.2440482761883,
              "speed2d": 4.085230037221667,
              "speed3d": 4.045616322350774,
              "msFromStart": 57000
            },
            {
              "lat": 43.78085479994774,
              "lng": -116.09608957237954,
              "alt": 1852.9061399314744,
              "speed2d": 4.189202678449772,
              "speed3d": 4.182118895988875,
              "msFromStart": 57500
            },
            {
              "lat": 43.78082344285714,
              "lng": -116.09606914285715,
              "alt": 1854.1464285714287,
              "speed2d": 4.561571428571429,
              "speed3d": 4.564285714285714,
              "msFromStart": 58290.44387301587
            },
            {
              "lat": 43.78081666684873,
              "lng": -116.09606471206537,
              "alt": 1854.4637794400303,
              "speed2d": 4.566073737419598,
              "speed3d": 4.5795124933787354,
              "msFromStart": 58500
            },
            {
              "lat": 43.78080225604054,
              "lng": -116.09605532575476,
              "alt": 1855.2525008311763,
              "speed2d": 4.514868050928561,
              "speed3d": 4.485540547270712,
              "msFromStart": 59000
            },
            {
              "lat": 43.780764899999994,
              "lng": -116.09603615,
              "alt": 1856.9450000000002,
              "speed2d": 4.8100000000000005,
              "speed3d": 4.805,
              "msFromStart": 59914.53499999999
            },
            {
              "lat": 43.78076232661027,
              "lng": -116.09603547367743,
              "alt": 1857.1723617741445,
              "speed2d": 4.788471015117635,
              "speed3d": 4.783816987291373,
              "msFromStart": 60000
            },
            {
              "lat": 43.780744096118205,
              "lng": -116.09602909446832,
              "alt": 1857.9603989352777,
              "speed2d": 4.775888171475331,
              "speed3d": 4.775038259168836,
              "msFromStart": 60500
            },
            {
              "lat": 43.78072586562614,
              "lng": -116.09602271525922,
              "alt": 1858.7484360964106,
              "speed2d": 4.763305327833028,
              "speed3d": 4.766259531046298,
              "msFromStart": 61000
            },
            {
              "lat": 43.78070041276827,
              "lng": -116.09601688208556,
              "alt": 1859.2811651833201,
              "speed2d": 5.081056567076732,
              "speed3d": 5.0685266343996815,
              "msFromStart": 61500
            },
            {
              "lat": 43.78069831147604,
              "lng": -116.09601360706218,
              "alt": 1860.6811781993642,
              "speed2d": 4.144894067331943,
              "speed3d": 4.253274481851079,
              "msFromStart": 62000
            },
            {
              "lat": 43.7806719,
              "lng": -116.09599756666668,
              "alt": 1861.8246666666669,
              "speed2d": 4.368,
              "speed3d": 4.39,
              "msFromStart": 62748.08
            },
            {
              "lat": 43.78066158298339,
              "lng": -116.09599129801103,
              "alt": 1862.2324033174264,
              "speed2d": 4.523803314278643,
              "speed3d": 4.477535911055648,
              "msFromStart": 63000
            },
            {
              "lat": 43.780650428632576,
              "lng": -116.09598441553925,
              "alt": 1863.0314029149704,
              "speed2d": 4.320493515695659,
              "speed3d": 4.3034963947589295,
              "msFromStart": 63500
            },
            {
              "lat": 43.780643803500396,
              "lng": -116.09597788351799,
              "alt": 1864.0650358847968,
              "speed2d": 3.7465849907612623,
              "speed3d": 3.8612553510151226,
              "msFromStart": 64000
            },
            {
              "lat": 43.78062467663532,
              "lng": -116.09597035745969,
              "alt": 1864.4501258953621,
              "speed2d": 4.196609841662088,
              "speed3d": 4.159361559083417,
              "msFromStart": 64500
            },
            {
              "lat": 43.78061028217649,
              "lng": -116.09596746586166,
              "alt": 1865.0113231974299,
              "speed2d": 4.1842960645523135,
              "speed3d": 4.1597280860697525,
              "msFromStart": 65000
            },
            {
              "lat": 43.780591599999994,
              "lng": -116.0959582,
              "alt": 1865.9765000000002,
              "speed2d": 4.3045,
              "speed3d": 4.32,
              "msFromStart": 65579.19588888888
            },
            {
              "lat": 43.7805672,
              "lng": -116.09594413333333,
              "alt": 1866.8816666666667,
              "speed2d": 4.526666666666666,
              "speed3d": 4.529999999999999,
              "msFromStart": 66267.55088888889
            },
            {
              "lat": 43.78055035,
              "lng": -116.09593559999999,
              "alt": 1868.1015,
              "speed2d": 4.1585,
              "speed3d": 4.234999999999999,
              "msFromStart": 66956.055
            },
            {
              "lat": 43.780545599999996,
              "lng": -116.09593236666666,
              "alt": 1868.6013333333335,
              "speed2d": 4.032666666666667,
              "speed3d": 4.093333333333333,
              "msFromStart": 67185.3434074074
            },
            {
              "lat": 43.78052343333333,
              "lng": -116.09591990000001,
              "alt": 1869.8613333333333,
              "speed2d": 4.202,
              "speed3d": 4.186666666666667,
              "msFromStart": 67862.29866666663
            },
            {
              "lat": 43.78050958749999,
              "lng": -116.09591263750002,
              "alt": 1870.5126249999998,
              "speed2d": 4.366625000000001,
              "speed3d": 4.3925,
              "msFromStart": 68218.9932222222
            },
            {
              "lat": 43.78049879663281,
              "lng": -116.09590653027976,
              "alt": 1871.0935852014393,
              "speed2d": 4.4949700729191155,
              "speed3d": 4.5007102623369,
              "msFromStart": 68500
            },
            {
              "lat": 43.78048123519265,
              "lng": -116.09590259409491,
              "alt": 1872.0999616969727,
              "speed2d": 4.361366875129244,
              "speed3d": 4.383381675042679,
              "msFromStart": 69000
            },
            {
              "lat": 43.78046173594142,
              "lng": -116.09590001856763,
              "alt": 1873.1880411263355,
              "speed2d": 4.090604774778904,
              "speed3d": 4.16,
              "msFromStart": 69500
            },
            {
              "lat": 43.780441350000004,
              "lng": -116.0958928,
              "alt": 1874.402,
              "speed2d": 4.161,
              "speed3d": 4.1899999999999995,
              "msFromStart": 70144.80975438598
            },
            {
              "lat": 43.78042947700974,
              "lng": -116.09588804728989,
              "alt": 1875.1775064459005,
              "speed2d": 4.201128349561377,
              "speed3d": 4.227537555834046,
              "msFromStart": 70500
            },
            {
              "lat": 43.78041575113657,
              "lng": -116.09588233470902,
              "alt": 1876.1549677556739,
              "speed2d": 3.8020556176781666,
              "speed3d": 4.051828147328817,
              "msFromStart": 71000
            },
            {
              "lat": 43.78037953333333,
              "lng": -116.0958664,
              "alt": 1878.5809999999997,
              "speed2d": 4.546333333333333,
              "speed3d": 4.556666666666667,
              "msFromStart": 71931.98977777778
            },
            {
              "lat": 43.78037057499999,
              "lng": -116.095862575,
              "alt": 1879.3177500000002,
              "speed2d": 4.5385,
              "speed3d": 4.635,
              "msFromStart": 72193.39877777777
            },
            {
              "lat": 43.7803543,
              "lng": -116.09585505,
              "alt": 1880.6805,
              "speed2d": 4.4955,
              "speed3d": 4.595000000000001,
              "msFromStart": 72674.66022222217
            },
            {
              "lat": 43.780339,
              "lng": -116.09584865,
              "alt": 1881.5839999999998,
              "speed2d": 4.6905,
              "speed3d": 4.725,
              "msFromStart": 73059.60111111101
            },
            {
              "lat": 43.7803136,
              "lng": -116.0958372,
              "alt": 1883.0844000000002,
              "speed2d": 4.828200000000001,
              "speed3d": 4.934,
              "msFromStart": 73691.00000000004
            },
            {
              "lat": 43.780294850000004,
              "lng": -116.0958266,
              "alt": 1884.576,
              "speed2d": 4.6,
              "speed3d": 4.735,
              "msFromStart": 74240.16463157898
            },
            {
              "lat": 43.7802795,
              "lng": -116.0958172,
              "alt": 1885.7649999999999,
              "speed2d": 4.695,
              "speed3d": 4.8,
              "msFromStart": 74652.94968421053
            },
            {
              "lat": 43.78026185,
              "lng": -116.0958076,
              "alt": 1887.154,
              "speed2d": 4.7905,
              "speed3d": 4.98,
              "msFromStart": 75093.44821052632
            },
            {
              "lat": 43.78024842849667,
              "lng": -116.09579392176161,
              "alt": 1888.4973523828771,
              "speed2d": 4.853025894336897,
              "speed3d": 4.78,
              "msFromStart": 75500
            },
            {
              "lat": 43.780220199999995,
              "lng": -116.0957878,
              "alt": 1890.7333333333333,
              "speed2d": 4.535,
              "speed3d": 4.79,
              "msFromStart": 76248.26881481484
            },
            {
              "lat": 43.7802023,
              "lng": -116.09577895,
              "alt": 1892.214,
              "speed2d": 4.495,
              "speed3d": 4.755,
              "msFromStart": 76743.5988888889
            },
            {
              "lat": 43.78019341640911,
              "lng": -116.09577443270882,
              "alt": 1892.9259816135034,
              "speed2d": 4.462664544896993,
              "speed3d": 4.736050573711435,
              "msFromStart": 77000
            },
            {
              "lat": 43.78017597571332,
              "lng": -116.09576620280573,
              "alt": 1894.3437259650677,
              "speed2d": 4.396394685736793,
              "speed3d": 4.6618857493805255,
              "msFromStart": 77500
            },
            {
              "lat": 43.780158535017534,
              "lng": -116.09575797290265,
              "alt": 1895.761470316632,
              "speed2d": 4.330124826576593,
              "speed3d": 4.587720925049616,
              "msFromStart": 78000
            },
            {
              "lat": 43.780143474148545,
              "lng": -116.09575309053011,
              "alt": 1896.9394879033232,
              "speed2d": 3.9558651565665497,
              "speed3d": 4.306979503862676,
              "msFromStart": 78500
            },
            {
              "lat": 43.78012162085222,
              "lng": -116.09573989363517,
              "alt": 1898.5951341522232,
              "speed2d": 4.499936351665842,
              "speed3d": 4.575639813510538,
              "msFromStart": 79000
            },
            {
              "lat": 43.78009183333333,
              "lng": -116.09571783333334,
              "alt": 1902.2799999999997,
              "speed2d": 4.245666666666667,
              "speed3d": 4.6466666666666665,
              "msFromStart": 79869.72814035097
            },
            {
              "lat": 43.78007946666666,
              "lng": -116.09570415,
              "alt": 1904.482166666667,
              "speed2d": 4.059,
              "speed3d": 4.588333333333334,
              "msFromStart": 80318.8802183236
            },
            {
              "lat": 43.780066149999996,
              "lng": -116.09569755000001,
              "alt": 1905.283,
              "speed2d": 4.4825,
              "speed3d": 4.85,
              "msFromStart": 80621.34222222219
            },
            {
              "lat": 43.78003045999999,
              "lng": -116.09568303999998,
              "alt": 1905.4168000000002,
              "speed2d": 5.9596,
              "speed3d": 6.286,
              "msFromStart": 81171.29039999994
            },
            {
              "lat": 43.78001792923087,
              "lng": -116.09567546806055,
              "alt": 1906.749390129537,
              "speed2d": 5.120563007729494,
              "speed3d": 5.507801009225526,
              "msFromStart": 81500
            },
            {
              "lat": 43.77999917544628,
              "lng": -116.09566360669251,
              "alt": 1908.2593102226233,
              "speed2d": 4.9549312919273865,
              "speed3d": 5.310111541977848,
              "msFromStart": 82000
            },
            {
              "lat": 43.7799687,
              "lng": -116.095644175,
              "alt": 1910.5245,
              "speed2d": 4.836,
              "speed3d": 5.165,
              "msFromStart": 82781.63111111107
            },
            {
              "lat": 43.7799532,
              "lng": -116.09563305,
              "alt": 1911.1325,
              "speed2d": 5.389,
              "speed3d": 5.65,
              "msFromStart": 83070.3857777777
            },
            {
              "lat": 43.77993420735853,
              "lng": -116.09562230682754,
              "alt": 1912.2875503504074,
              "speed2d": 5.305917585690652,
              "speed3d": 5.558164137359871,
              "msFromStart": 83500
            },
            {
              "lat": 43.77989528,
              "lng": -116.09559986,
              "alt": 1914.6490000000001,
              "speed2d": 5.642199999999999,
              "speed3d": 5.908,
              "msFromStart": 84318.30575438599
            },
            {
              "lat": 43.77987978888889,
              "lng": -116.09558926666668,
              "alt": 1916.1043333333332,
              "speed2d": 4.860666666666667,
              "speed3d": 5.3966666666666665,
              "msFromStart": 84746.25978947376
            },
            {
              "lat": 43.77987435,
              "lng": -116.09558523333332,
              "alt": 1917.7858333333334,
              "speed2d": 3.1601666666666666,
              "speed3d": 3.808333333333333,
              "msFromStart": 85185.32929434706
            },
            {
              "lat": 43.77986733475166,
              "lng": -116.09558252988926,
              "alt": 1919.0379985585905,
              "speed2d": 3.084965671906153,
              "speed3d": 3.6443224838411408,
              "msFromStart": 85500
            },
            {
              "lat": 43.77984585,
              "lng": -116.0955699,
              "alt": 1920.791,
              "speed2d": 3.9255,
              "speed3d": 4.355,
              "msFromStart": 86040.25688888898
            },
            {
              "lat": 43.77982754482018,
              "lng": -116.09556704135032,
              "alt": 1922.084026062003,
              "speed2d": 4.276096956198261,
              "speed3d": 4.696720531221256,
              "msFromStart": 86500
            },
            {
              "lat": 43.77980902215967,
              "lng": -116.09555488905049,
              "alt": 1923.9636976078475,
              "speed2d": 4.262534605250399,
              "speed3d": 4.641731394070677,
              "msFromStart": 87000
            },
            {
              "lat": 43.77979034399294,
              "lng": -116.09554257585742,
              "alt": 1925.8000682796653,
              "speed2d": 4.400063500210143,
              "speed3d": 4.789489710969575,
              "msFromStart": 87500
            },
            {
              "lat": 43.77977166582622,
              "lng": -116.09553026266434,
              "alt": 1927.6364389514833,
              "speed2d": 4.537592395169887,
              "speed3d": 4.937248027868474,
              "msFromStart": 88000
            },
            {
              "lat": 43.77974363333333,
              "lng": -116.09551183333332,
              "alt": 1930.2926666666667,
              "speed2d": 4.717333333333333,
              "speed3d": 5.176666666666667,
              "msFromStart": 88743.92385185191
            },
            {
              "lat": 43.779733934825245,
              "lng": -116.09550660757203,
              "alt": 1931.1965426140225,
              "speed2d": 4.658584610407967,
              "speed3d": 5.1458369096523455,
              "msFromStart": 89000
            },
            {
              "lat": 43.77970895,
              "lng": -116.09549555000001,
              "alt": 1934.3295,
              "speed2d": 4.278499999999999,
              "speed3d": 4.859999999999999,
              "msFromStart": 89725.31600000002
            },
            {
              "lat": 43.779694,
              "lng": -116.09548825,
              "alt": 1936.4070000000002,
              "speed2d": 4.214499999999999,
              "speed3d": 4.8149999999999995,
              "msFromStart": 90165.7741052632
            },
            {
              "lat": 43.779684144110945,
              "lng": -116.09548360633717,
              "alt": 1937.7845900725388,
              "speed2d": 4.003501988617972,
              "speed3d": 4.6401940923494625,
              "msFromStart": 90500
            },
            {
              "lat": 43.7796539,
              "lng": -116.09546495000001,
              "alt": 1939.903,
              "speed2d": 4.432,
              "speed3d": 5.029999999999999,
              "msFromStart": 91237.94044444448
            },
            {
              "lat": 43.779641025000004,
              "lng": -116.09545697499999,
              "alt": 1941.33025,
              "speed2d": 4.45475,
              "speed3d": 5.069999999999999,
              "msFromStart": 91595.19155555553
            },
            {
              "lat": 43.7796257927545,
              "lng": -116.09544944840724,
              "alt": 1942.8173085604276,
              "speed2d": 4.6437451592289705,
              "speed3d": 5.0994628357883,
              "msFromStart": 92000
            },
            {
              "lat": 43.77960595485501,
              "lng": -116.09543713313629,
              "alt": 1944.7841119286238,
              "speed2d": 4.6158386339553665,
              "speed3d": 5.135862651362565,
              "msFromStart": 92500
            },
            {
              "lat": 43.7795759,
              "lng": -116.09542,
              "alt": 1947.6750000000002,
              "speed2d": 4.9345,
              "speed3d": 5.404999999999999,
              "msFromStart": 93216.26000000004
            },
            {
              "lat": 43.7795415,
              "lng": -116.09540733333333,
              "alt": 1949.6873333333333,
              "speed2d": 6.496666666666666,
              "speed3d": 6.89,
              "msFromStart": 93748.12622222229
            },
            {
              "lat": 43.7795214,
              "lng": -116.09539756666668,
              "alt": 1951.1454999999999,
              "speed2d": 5.6191666666666675,
              "speed3d": 6.325,
              "msFromStart": 94206.73200000003
            },
            {
              "lat": 43.779505799999995,
              "lng": -116.09539125,
              "alt": 1952.4225000000001,
              "speed2d": 4.5,
              "speed3d": 5.15,
              "msFromStart": 94715.98433333331
            },
            {
              "lat": 43.779488,
              "lng": -116.09538325,
              "alt": 1954.1915,
              "speed2d": 4.2375,
              "speed3d": 4.835,
              "msFromStart": 95252.8555087719
            },
            {
              "lat": 43.779468375,
              "lng": -116.09537325,
              "alt": 1955.97625,
              "speed2d": 4.45525,
              "speed3d": 5.045,
              "msFromStart": 95789.93652631575
            },
            {
              "lat": 43.77946057632525,
              "lng": -116.09536835365907,
              "alt": 1956.920935116051,
              "speed2d": 4.541102055903251,
              "speed3d": 5.155191850312926,
              "msFromStart": 96000
            },
            {
              "lat": 43.779435219999996,
              "lng": -116.09535242000001,
              "alt": 1960.2642,
              "speed2d": 4.458200000000001,
              "speed3d": 5.216,
              "msFromStart": 96717.64533333338
            },
            {
              "lat": 43.77943075015397,
              "lng": -116.09534612025129,
              "alt": 1962.2574546079916,
              "speed2d": 3.777580746585676,
              "speed3d": 4.563576004046469,
              "msFromStart": 97000
            },
            {
              "lat": 43.77941421291038,
              "lng": -116.095338806674,
              "alt": 1964.1874835806757,
              "speed2d": 3.9777680527555783,
              "speed3d": 4.780568927967095,
              "msFromStart": 97500
            },
            {
              "lat": 43.7793871,
              "lng": -116.09532855,
              "alt": 1966.5505,
              "speed2d": 3.994,
              "speed3d": 4.76,
              "msFromStart": 98305.40155555551
            },
            {
              "lat": 43.77936764285714,
              "lng": -116.09531617142855,
              "alt": 1968.7545714285716,
              "speed2d": 4.275142857142857,
              "speed3d": 5.064285714285714,
              "msFromStart": 98827.16888888893
            },
            {
              "lat": 43.77936261072803,
              "lng": -116.09531467320457,
              "alt": 1969.4690100287266,
              "speed2d": 4.066057344664735,
              "speed3d": 4.899681418529249,
              "msFromStart": 99000
            },
            {
              "lat": 43.779344660867444,
              "lng": -116.09530680531414,
              "alt": 1971.2691359149735,
              "speed2d": 4.06961568707111,
              "speed3d": 4.879912849604946,
              "msFromStart": 99500
            },
            {
              "lat": 43.77932501476957,
              "lng": -116.09529712851787,
              "alt": 1972.892215823153,
              "speed2d": 4.243952750307022,
              "speed3d": 4.953651994053387,
              "msFromStart": 100000
            },
            {
              "lat": 43.77930133333333,
              "lng": -116.0952896,
              "alt": 1976.339,
              "speed2d": 3.740333333333333,
              "speed3d": 4.64,
              "msFromStart": 100806.65600000002
            },
            {
              "lat": 43.7792876,
              "lng": -116.09528256666667,
              "alt": 1978.0303333333334,
              "speed2d": 3.6983333333333333,
              "speed3d": 4.623333333333333,
              "msFromStart": 101246.75200000001
            },
            {
              "lat": 43.779269174999996,
              "lng": -116.0952772,
              "alt": 1980.469,
              "speed2d": 3.78275,
              "speed3d": 4.68,
              "msFromStart": 101811.53600000007
            },
            {
              "lat": 43.7792622085892,
              "lng": -116.09527594231965,
              "alt": 1981.1355705506944,
              "speed2d": 3.8980322861847836,
              "speed3d": 4.747213164153763,
              "msFromStart": 102000
            },
            {
              "lat": 43.77924821790915,
              "lng": -116.09526652551577,
              "alt": 1982.9079475672359,
              "speed2d": 3.677073709359794,
              "speed3d": 4.5487876537629806,
              "msFromStart": 102500
            },
            {
              "lat": 43.7792342272291,
              "lng": -116.09525710871188,
              "alt": 1984.680324583777,
              "speed2d": 3.456115132534804,
              "speed3d": 4.350362143372198,
              "msFromStart": 103000
            },
            {
              "lat": 43.77921685,
              "lng": -116.09524529999999,
              "alt": 1986.8555000000001,
              "speed2d": 3.327,
              "speed3d": 4.23,
              "msFromStart": 103614.736
            },
            {
              "lat": 43.77919285,
              "lng": -116.09523025,
              "alt": 1988.5394999999999,
              "speed2d": 4.42,
              "speed3d": 5.085,
              "msFromStart": 104191.61305263158
            },
            {
              "lat": 43.77917135,
              "lng": -116.0952153,
              "alt": 1990.167,
              "speed2d": 4.635999999999999,
              "speed3d": 5.34,
              "msFromStart": 104742.02399999995
            },
            {
              "lat": 43.779154525,
              "lng": -116.0952048,
              "alt": 1992.48675,
              "speed2d": 4.1895,
              "speed3d": 5.0649999999999995,
              "msFromStart": 105292.48173684209
            },
            {
              "lat": 43.7791489369957,
              "lng": -116.095202204842,
              "alt": 1993.2264150055034,
              "speed2d": 3.9677092301762844,
              "speed3d": 4.85718056534526,
              "msFromStart": 105500
            },
            {
              "lat": 43.7791204,
              "lng": -116.09518907500001,
              "alt": 1995.97725,
              "speed2d": 4.215,
              "speed3d": 4.9275,
              "msFromStart": 106349.11633333334
            },
            {
              "lat": 43.779110225,
              "lng": -116.09518340000001,
              "alt": 1996.7582500000003,
              "speed2d": 4.34925,
              "speed3d": 5.085,
              "msFromStart": 106610.7842222222
            },
            {
              "lat": 43.779081999999995,
              "lng": -116.09516839999999,
              "alt": 1998.8962000000001,
              "speed2d": 4.616,
              "speed3d": 5.278,
              "msFromStart": 107321.79759999999
            },
            {
              "lat": 43.7790686,
              "lng": -116.095160225,
              "alt": 2000.1057500000002,
              "speed2d": 4.57775,
              "speed3d": 5.272499999999999,
              "msFromStart": 107685.64266666664
            },
            {
              "lat": 43.779048,
              "lng": -116.09514873333333,
              "alt": 2002.125,
              "speed2d": 4.716666666666667,
              "speed3d": 5.393333333333333,
              "msFromStart": 108218.4653333333
            },
            {
              "lat": 43.779033049999995,
              "lng": -116.09514025,
              "alt": 2003.1005,
              "speed2d": 4.808,
              "speed3d": 5.455,
              "msFromStart": 108594.81733333331
            },
            {
              "lat": 43.779002750000004,
              "lng": -116.09511805,
              "alt": 2006.243,
              "speed2d": 4.7675,
              "speed3d": 5.485,
              "msFromStart": 109420.52622222225
            },
            {
              "lat": 43.778994000000004,
              "lng": -116.09511484000001,
              "alt": 2006.7654,
              "speed2d": 4.6666,
              "speed3d": 5.368,
              "msFromStart": 109646.06631111116
            },
            {
              "lat": 43.77898741012935,
              "lng": -116.09511405097967,
              "alt": 2008.0383838952594,
              "speed2d": 3.3863809883494635,
              "speed3d": 4.281867441842716,
              "msFromStart": 110000
            },
            {
              "lat": 43.7789603,
              "lng": -116.0950998,
              "alt": 2010.746,
              "speed2d": 4.0445,
              "speed3d": 4.83,
              "msFromStart": 110770.02905263158
            },
            {
              "lat": 43.778953100867945,
              "lng": -116.09509301622671,
              "alt": 2011.2610490749503,
              "speed2d": 4.152148627924445,
              "speed3d": 4.825780337390296,
              "msFromStart": 111000
            },
            {
              "lat": 43.77893288190641,
              "lng": -116.09508309425625,
              "alt": 2012.757582998103,
              "speed2d": 4.395401139239908,
              "speed3d": 5.102819525565304,
              "msFromStart": 111500
            },
            {
              "lat": 43.77891159365642,
              "lng": -116.09507208277326,
              "alt": 2013.696846244098,
              "speed2d": 4.667703336961093,
              "speed3d": 5.27828557961901,
              "msFromStart": 112000
            },
            {
              "lat": 43.77889355831853,
              "lng": -116.09506167120597,
              "alt": 2014.7664498788286,
              "speed2d": 4.452075795704232,
              "speed3d": 5.067778481294369,
              "msFromStart": 112500
            },
            {
              "lat": 43.7788672,
              "lng": -116.09504695000001,
              "alt": 2016.461,
              "speed2d": 4.198,
              "speed3d": 4.824999999999999,
              "msFromStart": 113237.58400000006
            },
            {
              "lat": 43.77885779958316,
              "lng": -116.09504168716819,
              "alt": 2017.03835885512,
              "speed2d": 4.397223734014902,
              "speed3d": 4.832853697155911,
              "msFromStart": 113500
            },
            {
              "lat": 43.77883644963017,
              "lng": -116.09502378121611,
              "alt": 2017.4647226026016,
              "speed2d": 4.57600345170444,
              "speed3d": 5.427498767248415,
              "msFromStart": 114000
            },
            {
              "lat": 43.77881259113967,
              "lng": -116.09502459098388,
              "alt": 2019.59229565367,
              "speed2d": 5.084972834427936,
              "speed3d": 5.466987975042435,
              "msFromStart": 114500
            },
            {
              "lat": 43.77879095,
              "lng": -116.09500990000001,
              "alt": 2021.9675000000002,
              "speed2d": 4.3445,
              "speed3d": 5.039999999999999,
              "msFromStart": 115164.9644561404
            },
            {
              "lat": 43.77877672504536,
              "lng": -116.0950033841088,
              "alt": 2022.4448042249217,
              "speed2d": 4.710174320994735,
              "speed3d": 5.036353474291389,
              "msFromStart": 115500
            },
            {
              "lat": 43.77874785,
              "lng": -116.094984,
              "alt": 2025.162,
              "speed2d": 4.5035,
              "speed3d": 5.115,
              "msFromStart": 116294.16463157895
            },
            {
              "lat": 43.77873827670138,
              "lng": -116.09498302763978,
              "alt": 2025.7373702381824,
              "speed2d": 4.963099715929789,
              "speed3d": 5.51404739359507,
              "msFromStart": 116500
            },
            {
              "lat": 43.77870226666667,
              "lng": -116.09496113333333,
              "alt": 2026.936333333333,
              "speed2d": 5.204333333333333,
              "speed3d": 5.706666666666666,
              "msFromStart": 117273.77496296295
            },
            {
              "lat": 43.77867745,
              "lng": -116.09494484999999,
              "alt": 2027.884,
              "speed2d": 5.0895,
              "speed3d": 5.545,
              "msFromStart": 117831.96133333337
            },
            {
              "lat": 43.77867118494092,
              "lng": -116.09494144663422,
              "alt": 2028.2249202000996,
              "speed2d": 4.892840939866933,
              "speed3d": 5.384798053715608,
              "msFromStart": 118000
            },
            {
              "lat": 43.77865293161003,
              "lng": -116.09493195233807,
              "alt": 2029.207967663859,
              "speed2d": 4.049220931358308,
              "speed3d": 4.7294704230806515,
              "msFromStart": 118500
            },
            {
              "lat": 43.77863847935134,
              "lng": -116.09491968768081,
              "alt": 2030.8968968757947,
              "speed2d": 4.181864208694835,
              "speed3d": 4.588483434324685,
              "msFromStart": 119000
            },
            {
              "lat": 43.77861935424184,
              "lng": -116.0949112100597,
              "alt": 2032.103948641764,
              "speed2d": 3.990108493365248,
              "speed3d": 4.527928997904815,
              "msFromStart": 119500
            },
            {
              "lat": 43.7785949,
              "lng": -116.09489983333333,
              "alt": 2033.9519999999998,
              "speed2d": 3.7170000000000005,
              "speed3d": 4.4366666666666665,
              "msFromStart": 120170.91492397652
            },
            {
              "lat": 43.77858998771373,
              "lng": -116.09489797760976,
              "alt": 2036.0965099223893,
              "speed2d": 3.2221879883238906,
              "speed3d": 3.999422630494238,
              "msFromStart": 120500
            },
            {
              "lat": 43.77856176666666,
              "lng": -116.0948808,
              "alt": 2038.2683333333334,
              "speed2d": 3.7993333333333332,
              "speed3d": 4.55,
              "msFromStart": 121306.9045380117
            },
            {
              "lat": 43.778540449999994,
              "lng": -116.0948672,
              "alt": 2040.016,
              "speed2d": 3.8775,
              "speed3d": 4.654999999999999,
              "msFromStart": 121930.57066666668
            },
            {
              "lat": 43.77853811571554,
              "lng": -116.09486607090135,
              "alt": 2040.2170738545271,
              "speed2d": 3.909695642980899,
              "speed3d": 4.677959846420397,
              "msFromStart": 122000
            },
            {
              "lat": 43.778507999999995,
              "lng": -116.09485156000001,
              "alt": 2042.5628000000002,
              "speed2d": 3.8606,
              "speed3d": 4.606,
              "msFromStart": 122865.21244444445
            },
            {
              "lat": 43.7785016,
              "lng": -116.09484785000001,
              "alt": 2043.0345,
              "speed2d": 3.8715,
              "speed3d": 4.655,
              "msFromStart": 123057.53822222221
            },
            {
              "lat": 43.77848415,
              "lng": -116.09483755,
              "alt": 2044.3375,
              "speed2d": 3.902,
              "speed3d": 4.654999999999999,
              "msFromStart": 123552.10733333336
            },
            {
              "lat": 43.77845726666666,
              "lng": -116.09481836666666,
              "alt": 2045.6163333333334,
              "speed2d": 3.9996666666666663,
              "speed3d": 4.676666666666667,
              "msFromStart": 124294.16044444448
            },
            {
              "lat": 43.77845236573728,
              "lng": -116.09482050294625,
              "alt": 2046.5772100456618,
              "speed2d": 3.855101537667184,
              "speed3d": 4.4286123884666715,
              "msFromStart": 124500
            },
            {
              "lat": 43.77841708,
              "lng": -116.09480167999999,
              "alt": 2048.5306,
              "speed2d": 4.4338,
              "speed3d": 5.052,
              "msFromStart": 125296.68788771932
            },
            {
              "lat": 43.778409284181244,
              "lng": -116.0947982313358,
              "alt": 2049.2024834539075,
              "speed2d": 4.482334629065106,
              "speed3d": 5.101851733651995,
              "msFromStart": 125500
            },
            {
              "lat": 43.77839284158881,
              "lng": -116.09479040939902,
              "alt": 2050.5302847625658,
              "speed2d": 4.170283419210365,
              "speed3d": 4.815414612514368,
              "msFromStart": 126000
            },
            {
              "lat": 43.77837639899638,
              "lng": -116.09478258746226,
              "alt": 2051.8580860712236,
              "speed2d": 3.8582322093556227,
              "speed3d": 4.528977491376741,
              "msFromStart": 126500
            },
            {
              "lat": 43.77834712,
              "lng": -116.09476898,
              "alt": 2054.1135999999997,
              "speed2d": 3.4364,
              "speed3d": 4.1899999999999995,
              "msFromStart": 127376.06399999998
            },
            {
              "lat": 43.7783342,
              "lng": -116.0947622,
              "alt": 2055.0315,
              "speed2d": 3.57,
              "speed3d": 4.28,
              "msFromStart": 127760.33599999994
            },
            {
              "lat": 43.778326057183996,
              "lng": -116.09475674597529,
              "alt": 2055.608341095506,
              "speed2d": 3.709995976838312,
              "speed3d": 4.335656024866741,
              "msFromStart": 128000
            },
            {
              "lat": 43.77828856666667,
              "lng": -116.0947359,
              "alt": 2057.427666666667,
              "speed2d": 4.582333333333334,
              "speed3d": 5.086666666666666,
              "msFromStart": 128877.81392592599
            },
            {
              "lat": 43.77827768,
              "lng": -116.09472733999999,
              "alt": 2057.843,
              "speed2d": 4.4132,
              "speed3d": 5.02,
              "msFromStart": 129178.50773333339
            },
            {
              "lat": 43.77826631065219,
              "lng": -116.09471883714461,
              "alt": 2058.5996621484087,
              "speed2d": 4.305384425117189,
              "speed3d": 4.802103904515166,
              "msFromStart": 129500
            },
            {
              "lat": 43.77824977659036,
              "lng": -116.09470761300621,
              "alt": 2059.8640404195057,
              "speed2d": 3.9136329327107235,
              "speed3d": 4.4392918931332925,
              "msFromStart": 130000
            },
            {
              "lat": 43.778228540648456,
              "lng": -116.09469607308625,
              "alt": 2061.039524265697,
              "speed2d": 4.33950636932854,
              "speed3d": 4.8400920671506436,
              "msFromStart": 130500
            },
            {
              "lat": 43.778209018014756,
              "lng": -116.09468728172271,
              "alt": 2062.251827859545,
              "speed2d": 4.230545955734757,
              "speed3d": 4.813030462138018,
              "msFromStart": 131000
            },
            {
              "lat": 43.77818894611274,
              "lng": -116.09467581206442,
              "alt": 2063.4145644690375,
              "speed2d": 4.290761661775065,
              "speed3d": 4.832146559293672,
              "msFromStart": 131500
            },
            {
              "lat": 43.77816977835593,
              "lng": -116.09466437852201,
              "alt": 2064.7533789098566,
              "speed2d": 4.236864793865336,
              "speed3d": 4.682024633339507,
              "msFromStart": 132000
            },
            {
              "lat": 43.778146922029535,
              "lng": -116.09465052001403,
              "alt": 2065.4914780891895,
              "speed2d": 4.9113405198517395,
              "speed3d": 4.971799859499523,
              "msFromStart": 132500
            },
            {
              "lat": 43.778122275,
              "lng": -116.09463865000001,
              "alt": 2067.0625,
              "speed2d": 4.46725,
              "speed3d": 4.9975000000000005,
              "msFromStart": 133150.5725555555
            },
            {
              "lat": 43.778103175,
              "lng": -116.09462745,
              "alt": 2068.3175,
              "speed2d": 4.11725,
              "speed3d": 4.665,
              "msFromStart": 133700.4657777778
            },
            {
              "lat": 43.778080079999995,
              "lng": -116.09461936,
              "alt": 2069.7448,
              "speed2d": 4.3532,
              "speed3d": 4.8420000000000005,
              "msFromStart": 134261.19040000005
            },
            {
              "lat": 43.77807092941329,
              "lng": -116.09461479198957,
              "alt": 2070.376807771094,
              "speed2d": 4.4272649804860205,
              "speed3d": 4.950901955710807,
              "msFromStart": 134500
            },
            {
              "lat": 43.7780419,
              "lng": -116.09460023333332,
              "alt": 2071.757,
              "speed2d": 4.5296666666666665,
              "speed3d": 5.01,
              "msFromStart": 135184.61866666662
            },
            {
              "lat": 43.77801676666667,
              "lng": -116.09458566666666,
              "alt": 2072.8446666666664,
              "speed2d": 4.604666666666667,
              "speed3d": 5.036666666666666,
              "msFromStart": 135790.4218947369
            },
            {
              "lat": 43.77800439990169,
              "lng": -116.09458509990175,
              "alt": 2073.273694810111,
              "speed2d": 5.17118154133708,
              "speed3d": 4.931874385704852,
              "msFromStart": 136000
            },
            {
              "lat": 43.77798526666666,
              "lng": -116.09456713333333,
              "alt": 2074.7030000000004,
              "speed2d": 3.9479999999999995,
              "speed3d": 4.546666666666666,
              "msFromStart": 136672.34577777775
            },
            {
              "lat": 43.77797015,
              "lng": -116.09455807500001,
              "alt": 2075.93525,
              "speed2d": 3.3514999999999997,
              "speed3d": 3.925,
              "msFromStart": 137223.82577777776
            },
            {
              "lat": 43.77795790299264,
              "lng": -116.09455223922514,
              "alt": 2076.9726843619583,
              "speed2d": 4.730252493863634,
              "speed3d": 5.0912271185203055,
              "msFromStart": 137500
            },
            {
              "lat": 43.77793562883053,
              "lng": -116.09454137859645,
              "alt": 2077.777950612794,
              "speed2d": 4.711690692103325,
              "speed3d": 5.063581881856017,
              "msFromStart": 138000
            },
            {
              "lat": 43.777913354668414,
              "lng": -116.09453051796775,
              "alt": 2078.5832168636293,
              "speed2d": 4.693128890343017,
              "speed3d": 5.035936645191727,
              "msFromStart": 138500
            },
            {
              "lat": 43.777889835556515,
              "lng": -116.09451911777826,
              "alt": 2079.2696807187904,
              "speed2d": 4.738226427367165,
              "speed3d": 5.063243896607039,
              "msFromStart": 139000
            },
            {
              "lat": 43.77787171230932,
              "lng": -116.09451005615466,
              "alt": 2080.471058237554,
              "speed2d": 4.507411486617903,
              "speed3d": 4.852375926045986,
              "msFromStart": 139500
            },
            {
              "lat": 43.77783986666667,
              "lng": -116.0944926,
              "alt": 2082.205,
              "speed2d": 4.597333333333333,
              "speed3d": 4.983333333333333,
              "msFromStart": 140301.44545808967
            },
            {
              "lat": 43.777830957713974,
              "lng": -116.09448749089542,
              "alt": 2082.5848234529212,
              "speed2d": 4.742106478238946,
              "speed3d": 5.151952456825262,
              "msFromStart": 140500
            },
            {
              "lat": 43.77780353333333,
              "lng": -116.09447540000001,
              "alt": 2084.351333333333,
              "speed2d": 4.422000000000001,
              "speed3d": 4.846666666666667,
              "msFromStart": 141197.55817543872
            },
            {
              "lat": 43.77779163155787,
              "lng": -116.09446972294108,
              "alt": 2084.8949740918906,
              "speed2d": 4.398808028166381,
              "speed3d": 4.817565976723993,
              "msFromStart": 141500
            },
            {
              "lat": 43.7777578,
              "lng": -116.09445120000001,
              "alt": 2086.1445000000003,
              "speed2d": 4.318,
              "speed3d": 4.655,
              "msFromStart": 142362.74933333334
            },
            {
              "lat": 43.777752399620226,
              "lng": -116.09444648089756,
              "alt": 2086.0317380877354,
              "speed2d": 4.541943398055986,
              "speed3d": 4.799237469598252,
              "msFromStart": 142500
            },
            {
              "lat": 43.7777273509369,
              "lng": -116.09443653212615,
              "alt": 2086.8417801856835,
              "speed2d": 4.797985382881277,
              "speed3d": 5.03659394468284,
              "msFromStart": 143000
            },
            {
              "lat": 43.777684779999994,
              "lng": -116.09441910000001,
              "alt": 2088.287,
              "speed2d": 5.062,
              "speed3d": 5.388,
              "msFromStart": 143875.48888888903
            },
            {
              "lat": 43.77768009701406,
              "lng": -116.09441662317303,
              "alt": 2088.463674014175,
              "speed2d": 4.906707749729893,
              "speed3d": 5.2498350043649245,
              "msFromStart": 144000
            },
            {
              "lat": 43.777655885714296,
              "lng": -116.09440138571428,
              "alt": 2089.0607142857143,
              "speed2d": 4.242571428571429,
              "speed3d": 4.555714285714285,
              "msFromStart": 144700.288
            },
            {
              "lat": 43.777638950000004,
              "lng": -116.09439515,
              "alt": 2089.74775,
              "speed2d": 4.1125,
              "speed3d": 4.4375,
              "msFromStart": 145167.70610526315
            },
            {
              "lat": 43.77761515,
              "lng": -116.09438005,
              "alt": 2090.4825,
              "speed2d": 4.329000000000001,
              "speed3d": 4.550000000000001,
              "msFromStart": 145772.45600000012
            },
            {
              "lat": 43.777596474999996,
              "lng": -116.09436804999999,
              "alt": 2091.1485000000002,
              "speed2d": 4.687250000000001,
              "speed3d": 4.89,
              "msFromStart": 146212.2956842107
            },
            {
              "lat": 43.77756875714286,
              "lng": -116.09435001428571,
              "alt": 2092.066714285714,
              "speed2d": 5.2984285714285715,
              "speed3d": 5.491428571428571,
              "msFromStart": 146790.0880000001
            },
            {
              "lat": 43.77755825,
              "lng": -116.09434475,
              "alt": 2092.4855,
              "speed2d": 5.1675,
              "speed3d": 5.4399999999999995,
              "msFromStart": 147037.78000000012
            },
            {
              "lat": 43.7775326,
              "lng": -116.09433420000002,
              "alt": 2093.241,
              "speed2d": 4.664,
              "speed3d": 4.906666666666667,
              "msFromStart": 147688.9291851853
            },
            {
              "lat": 43.77752263599327,
              "lng": -116.0943319700832,
              "alt": 2093.669770757889,
              "speed2d": 4.220262653043607,
              "speed3d": 4.3196348508363185,
              "msFromStart": 148000
            },
            {
              "lat": 43.777487199999996,
              "lng": -116.0943122,
              "alt": 2095.3385,
              "speed2d": 4.401,
              "speed3d": 4.68,
              "msFromStart": 148853.09866666654
            },
            {
              "lat": 43.77748323396676,
              "lng": -116.09431220260069,
              "alt": 2095.1919811407415,
              "speed2d": 4.0971899130237315,
              "speed3d": 4.325555874501441,
              "msFromStart": 149000
            },
            {
              "lat": 43.77744945,
              "lng": -116.09428989999999,
              "alt": 2096.4100000000003,
              "speed2d": 4.61425,
              "speed3d": 4.8375,
              "msFromStart": 149788.53777777773
            },
            {
              "lat": 43.777433349999995,
              "lng": -116.0942815,
              "alt": 2097.48,
              "speed2d": 4.6419999999999995,
              "speed3d": 4.79,
              "msFromStart": 150201.47761403501
            },
            {
              "lat": 43.777407100000005,
              "lng": -116.09426913333333,
              "alt": 2098.939166666667,
              "speed2d": 4.826333333333333,
              "speed3d": 5.1066666666666665,
              "msFromStart": 150807.0029473683
            },
            {
              "lat": 43.777397699999995,
              "lng": -116.09426479999999,
              "alt": 2099.4725,
              "speed2d": 4.644,
              "speed3d": 4.9399999999999995,
              "msFromStart": 151054.70947368405
            },
            {
              "lat": 43.7773728,
              "lng": -116.09425034,
              "alt": 2100.6098,
              "speed2d": 4.7016,
              "speed3d": 4.956,
              "msFromStart": 151676.1681777778
            },
            {
              "lat": 43.777341449999994,
              "lng": -116.09423265,
              "alt": 2101.9375,
              "speed2d": 4.7805,
              "speed3d": 5.02,
              "msFromStart": 152429.3977777778
            },
            {
              "lat": 43.777336968019945,
              "lng": -116.0942304671694,
              "alt": 2102.1364285359705,
              "speed2d": 5.025078217438585,
              "speed3d": 5.2591160989137125,
              "msFromStart": 152500
            },
            {
              "lat": 43.77731765711207,
              "lng": -116.09421934066292,
              "alt": 2103.0442016845723,
              "speed2d": 4.712680150978138,
              "speed3d": 4.959556309157119,
              "msFromStart": 153000
            },
            {
              "lat": 43.777289700000004,
              "lng": -116.0942034,
              "alt": 2104.357,
              "speed2d": 4.296,
              "speed3d": 4.569999999999999,
              "msFromStart": 153721.78488888885
            },
            {
              "lat": 43.77727816664461,
              "lng": -116.09419722634169,
              "alt": 2105.1480619026097,
              "speed2d": 4.5942090867992915,
              "speed3d": 4.761007572332742,
              "msFromStart": 154000
            },
            {
              "lat": 43.777259078266816,
              "lng": -116.09418410841621,
              "alt": 2104.920274272367,
              "speed2d": 4.305333387152349,
              "speed3d": 4.523162727116179,
              "msFromStart": 154500
            },
            {
              "lat": 43.77722108,
              "lng": -116.09416114,
              "alt": 2105.6852,
              "speed2d": 4.9682,
              "speed3d": 5.114000000000001,
              "msFromStart": 155338.63988771924
            },
            {
              "lat": 43.777213726724014,
              "lng": -116.09415665645125,
              "alt": 2105.7986800452236,
              "speed2d": 5.0113735823396,
              "speed3d": 5.22,
              "msFromStart": 155500
            },
            {
              "lat": 43.777178733333336,
              "lng": -116.09412671666668,
              "alt": 2106.7725,
              "speed2d": 5.041666666666667,
              "speed3d": 5.203333333333333,
              "msFromStart": 156335.23967251458
            },
            {
              "lat": 43.7771658,
              "lng": -116.0941188,
              "alt": 2107.128,
              "speed2d": 4.932,
              "speed3d": 5.115,
              "msFromStart": 156638.1386666667
            },
            {
              "lat": 43.777127050000004,
              "lng": -116.09410145000001,
              "alt": 2107.225,
              "speed2d": 5.1225000000000005,
              "speed3d": 5.25,
              "msFromStart": 157436.74622222222
            },
            {
              "lat": 43.77712516720186,
              "lng": -116.09409964812097,
              "alt": 2107.193675825985,
              "speed2d": 4.958246591351772,
              "speed3d": 5.217977022428744,
              "msFromStart": 157500
            },
            {
              "lat": 43.77710633297708,
              "lng": -116.09409019170832,
              "alt": 2108.1477346146353,
              "speed2d": 4.625408765664561,
              "speed3d": 4.756468281280432,
              "msFromStart": 158000
            },
            {
              "lat": 43.7770789,
              "lng": -116.09407145,
              "alt": 2108.498,
              "speed2d": 4.8870000000000005,
              "speed3d": 4.984999999999999,
              "msFromStart": 158646.55822222223
            },
            {
              "lat": 43.77706381269558,
              "lng": -116.09406403370865,
              "alt": 2108.4697861127433,
              "speed2d": 4.7149824886680305,
              "speed3d": 4.8132082549572335,
              "msFromStart": 159000
            },
            {
              "lat": 43.77704188310741,
              "lng": -116.09405316997257,
              "alt": 2109.006404145804,
              "speed2d": 4.773090844413773,
              "speed3d": 4.843525657955013,
              "msFromStart": 159500
            },
            {
              "lat": 43.777007749999996,
              "lng": -116.09403505,
              "alt": 2109.7650000000003,
              "speed2d": 4.9475,
              "speed3d": 5.015000000000001,
              "msFromStart": 160266.8792982457
            },
            {
              "lat": 43.77699747615009,
              "lng": -116.09402878113484,
              "alt": 2110.1513941039434,
              "speed2d": 4.972744700205232,
              "speed3d": 5.113833656886138,
              "msFromStart": 160500
            },
            {
              "lat": 43.77696215,
              "lng": -116.09400825,
              "alt": 2110.3785,
              "speed2d": 4.774,
              "speed3d": 4.859999999999999,
              "msFromStart": 161342.71957894744
            },
            {
              "lat": 43.776949466666665,
              "lng": -116.09400323333332,
              "alt": 2110.3813333333333,
              "speed2d": 4.684666666666667,
              "speed3d": 4.8,
              "msFromStart": 161645.05600000007
            },
            {
              "lat": 43.77692486,
              "lng": -116.09399414000002,
              "alt": 2110.7356,
              "speed2d": 4.6724,
              "speed3d": 4.748,
              "msFromStart": 162227.14933333348
            },
            {
              "lat": 43.776913086400974,
              "lng": -116.09398731464398,
              "alt": 2110.8191736536123,
              "speed2d": 4.723387026824939,
              "speed3d": 4.729560669221423,
              "msFromStart": 162500
            },
            {
              "lat": 43.77689219314551,
              "lng": -116.0939768876682,
              "alt": 2111.1812891220047,
              "speed2d": 4.604387551203101,
              "speed3d": 4.707591700802067,
              "msFromStart": 163000
            },
            {
              "lat": 43.77687105007588,
              "lng": -116.09396455421093,
              "alt": 2111.691678275736,
              "speed2d": 4.655540138993582,
              "speed3d": 4.741693425995722,
              "msFromStart": 163500
            },
            {
              "lat": 43.7768462,
              "lng": -116.09394995,
              "alt": 2112.2709999999997,
              "speed2d": 4.7145,
              "speed3d": 4.79,
              "msFromStart": 164089.15111111096
            },
            {
              "lat": 43.7768226,
              "lng": -116.0939384,
              "alt": 2112.6305,
              "speed2d": 4.497999999999999,
              "speed3d": 4.535,
              "msFromStart": 164694.74133333337
            },
            {
              "lat": 43.776801600000006,
              "lng": -116.09392614999999,
              "alt": 2113.13025,
              "speed2d": 4.7085,
              "speed3d": 4.795,
              "msFromStart": 165190.4533333334
            },
            {
              "lat": 43.77678962140417,
              "lng": -116.0939192091642,
              "alt": 2113.562340731955,
              "speed2d": 4.6559706288207074,
              "speed3d": 4.7793848478891,
              "msFromStart": 165500
            },
            {
              "lat": 43.7767696181524,
              "lng": -116.09390909398275,
              "alt": 2114.2747709564196,
              "speed2d": 4.614042415512321,
              "speed3d": 4.733962616805014,
              "msFromStart": 166000
            },
            {
              "lat": 43.77674961490064,
              "lng": -116.09389897880128,
              "alt": 2114.9872011808848,
              "speed2d": 4.5721142022039345,
              "speed3d": 4.688540385720929,
              "msFromStart": 166500
            },
            {
              "lat": 43.77672961164887,
              "lng": -116.09388886361982,
              "alt": 2115.69963140535,
              "speed2d": 4.530185988895547,
              "speed3d": 4.643118154636843,
              "msFromStart": 167000
            },
            {
              "lat": 43.7767096083971,
              "lng": -116.09387874843836,
              "alt": 2116.4120616298146,
              "speed2d": 4.488257775587161,
              "speed3d": 4.597695923552758,
              "msFromStart": 167500
            },
            {
              "lat": 43.7766756,
              "lng": -116.09386163333332,
              "alt": 2117.6586666666667,
              "speed2d": 4.516666666666667,
              "speed3d": 4.623333333333333,
              "msFromStart": 168336.82903703704
            },
            {
              "lat": 43.7766563,
              "lng": -116.09385305,
              "alt": 2118.359,
              "speed2d": 4.32025,
              "speed3d": 4.5024999999999995,
              "msFromStart": 168849.8968888889
            },
            {
              "lat": 43.776645933333334,
              "lng": -116.09384776666667,
              "alt": 2118.4446666666668,
              "speed2d": 4.237666666666667,
              "speed3d": 4.373333333333333,
              "msFromStart": 169124.70133333333
            },
            {
              "lat": 43.77662555643572,
              "lng": -116.0938447665451,
              "alt": 2118.5939131043665,
              "speed2d": 4.765857087394787,
              "speed3d": 4.791916362779217,
              "msFromStart": 169500
            },
            {
              "lat": 43.77659333333333,
              "lng": -116.09382866666667,
              "alt": 2119.2459999999996,
              "speed2d": 4.568333333333333,
              "speed3d": 4.713333333333333,
              "msFromStart": 170315.5584561403
            },
            {
              "lat": 43.7765704,
              "lng": -116.093819725,
              "alt": 2119.70925,
              "speed2d": 4.798,
              "speed3d": 4.8625,
              "msFromStart": 170829.68673684218
            },
            {
              "lat": 43.7765594,
              "lng": -116.09381275000001,
              "alt": 2120.0699999999997,
              "speed2d": 4.888,
              "speed3d": 5.015000000000001,
              "msFromStart": 171077.64336842118
            },
            {
              "lat": 43.776539194984956,
              "lng": -116.09380266122426,
              "alt": 2120.1292233517875,
              "speed2d": 4.956725364500706,
              "speed3d": 5.193731774964649,
              "msFromStart": 171500
            },
            {
              "lat": 43.77651843594422,
              "lng": -116.09379210998975,
              "alt": 2121.4173785730995,
              "speed2d": 4.9389265533064055,
              "speed3d": 5.009245847953278,
              "msFromStart": 172000
            },
            {
              "lat": 43.77648463333333,
              "lng": -116.0937754,
              "alt": 2122.262,
              "speed2d": 5.168666666666667,
              "speed3d": 5.266666666666667,
              "msFromStart": 172734.59437037044
            },
            {
              "lat": 43.7764655,
              "lng": -116.09376424999999,
              "alt": 2122.8345,
              "speed2d": 5.0095,
              "speed3d": 5.14,
              "msFromStart": 173191.88133333338
            },
            {
              "lat": 43.776443425,
              "lng": -116.093755075,
              "alt": 2123.3190000000004,
              "speed2d": 4.86825,
              "speed3d": 4.9825,
              "msFromStart": 173714.49733333336
            },
            {
              "lat": 43.776419966666666,
              "lng": -116.09374176666665,
              "alt": 2123.6810000000005,
              "speed2d": 4.951333333333333,
              "speed3d": 5.076666666666666,
              "msFromStart": 174237.62222222227
            },
            {
              "lat": 43.7764031,
              "lng": -116.09373020000001,
              "alt": 2124.6986666666667,
              "speed2d": 4.848666666666667,
              "speed3d": 4.976666666666667,
              "msFromStart": 174659.970962963
            },
            {
              "lat": 43.77638978523681,
              "lng": -116.09372334077301,
              "alt": 2125.2227888221537,
              "speed2d": 4.6570369079822145,
              "speed3d": 4.8479012883407435,
              "msFromStart": 175000
            },
            {
              "lat": 43.77636771705224,
              "lng": -116.09371035845611,
              "alt": 2125.7398790940133,
              "speed2d": 4.7018858516837225,
              "speed3d": 4.803865213674819,
              "msFromStart": 175500
            },
            {
              "lat": 43.77634720114373,
              "lng": -116.09369401177649,
              "alt": 2126.42759546374,
              "speed2d": 4.798067072659892,
              "speed3d": 4.874040353442839,
              "msFromStart": 176000
            },
            {
              "lat": 43.776309775,
              "lng": -116.09366532499999,
              "alt": 2127.63775,
              "speed2d": 4.91925,
              "speed3d": 5.05,
              "msFromStart": 176908.70533333343
            },
            {
              "lat": 43.77630625183553,
              "lng": -116.0936635486332,
              "alt": 2127.8295636524063,
              "speed2d": 4.857555584362392,
              "speed3d": 5.0322962770917385,
              "msFromStart": 177000
            },
            {
              "lat": 43.776273366666665,
              "lng": -116.09364536666666,
              "alt": 2129.2603333333336,
              "speed2d": 4.922666666666666,
              "speed3d": 5.043333333333334,
              "msFromStart": 177777.34459259256
            },
            {
              "lat": 43.776263902105036,
              "lng": -116.09363911342095,
              "alt": 2129.76225969592,
              "speed2d": 4.964943420413707,
              "speed3d": 5.11517822882608,
              "msFromStart": 178000
            },
            {
              "lat": 43.77622465,
              "lng": -116.09362175000001,
              "alt": 2130.9395000000004,
              "speed2d": 4.885,
              "speed3d": 5.01,
              "msFromStart": 178911.57688888878
            },
            {
              "lat": 43.77620538,
              "lng": -116.0936106,
              "alt": 2131.5643999999998,
              "speed2d": 4.9416,
              "speed3d": 5.1000000000000005,
              "msFromStart": 179351.7500444444
            },
            {
              "lat": 43.776195825,
              "lng": -116.0936064,
              "alt": 2132.04175,
              "speed2d": 4.78325,
              "speed3d": 5,
              "msFromStart": 179599.763111111
            },
            {
              "lat": 43.77617095,
              "lng": -116.09359135,
              "alt": 2133.4970000000003,
              "speed2d": 4.8004999999999995,
              "speed3d": 4.915,
              "msFromStart": 180233.6152748537
            },
            {
              "lat": 43.77615374,
              "lng": -116.0935825,
              "alt": 2133.9622,
              "speed2d": 4.9286,
              "speed3d": 5.122000000000001,
              "msFromStart": 180619.39621052626
            },
            {
              "lat": 43.77613821690898,
              "lng": -116.09356911119207,
              "alt": 2134.4852406965947,
              "speed2d": 4.817574074695855,
              "speed3d": 4.963599033005406,
              "msFromStart": 181000
            },
            {
              "lat": 43.77611849089078,
              "lng": -116.09355770927374,
              "alt": 2135.6275310411984,
              "speed2d": 4.801485478292985,
              "speed3d": 4.98458415874828,
              "msFromStart": 181500
            },
            {
              "lat": 43.7760907,
              "lng": -116.09354115,
              "alt": 2137.261,
              "speed2d": 4.7605,
              "speed3d": 4.98,
              "msFromStart": 182215.53599999996
            },
            {
              "lat": 43.77606832,
              "lng": -116.09352764,
              "alt": 2138.6515999999997,
              "speed2d": 4.7174,
              "speed3d": 4.973999999999999,
              "msFromStart": 182798.50400000002
            },
            {
              "lat": 43.77606019683141,
              "lng": -116.09352407094599,
              "alt": 2138.9129400006723,
              "speed2d": 4.666320130866878,
              "speed3d": 4.912480196300317,
              "msFromStart": 183000
            },
            {
              "lat": 43.7760276,
              "lng": -116.09350989999999,
              "alt": 2140.2335000000003,
              "speed2d": 4.5515,
              "speed3d": 4.765000000000001,
              "msFromStart": 183809.01599999995
            },
            {
              "lat": 43.77601805,
              "lng": -116.09350375,
              "alt": 2140.55,
              "speed2d": 4.492,
              "speed3d": 4.76,
              "msFromStart": 184055.77199999994
            },
            {
              "lat": 43.77599143333333,
              "lng": -116.09348700000001,
              "alt": 2142.1276666666663,
              "speed2d": 4.9206666666666665,
              "speed3d": 5.123333333333333,
              "msFromStart": 184687.62755555563
            },
            {
              "lat": 43.77597939843854,
              "lng": -116.09348471007709,
              "alt": 2142.6299987483785,
              "speed2d": 4.540693108448445,
              "speed3d": 4.8873016866366585,
              "msFromStart": 185000
            },
            {
              "lat": 43.77595635,
              "lng": -116.09346325,
              "alt": 2143.737,
              "speed2d": 4.54925,
              "speed3d": 4.8125,
              "msFromStart": 185651.39242105256
            },
            {
              "lat": 43.77593589999999,
              "lng": -116.09344970000001,
              "alt": 2144.0916666666667,
              "speed2d": 4.726833333333333,
              "speed3d": 4.965000000000001,
              "msFromStart": 186147.92715789456
            },
            {
              "lat": 43.7759129,
              "lng": -116.09343799999999,
              "alt": 2144.7525,
              "speed2d": 4.437,
              "speed3d": 4.65,
              "msFromStart": 186753.69066666675
            },
            {
              "lat": 43.77588835999999,
              "lng": -116.0934255,
              "alt": 2145.4316,
              "speed2d": 4.5968,
              "speed3d": 4.82,
              "msFromStart": 187342.26844444446
            },
            {
              "lat": 43.775884007698785,
              "lng": -116.09342473589959,
              "alt": 2145.5821188016193,
              "speed2d": 4.224976647773326,
              "speed3d": 4.620504615384636,
              "msFromStart": 187500
            },
            {
              "lat": 43.7758523,
              "lng": -116.09341295,
              "alt": 2145.8954999999996,
              "speed2d": 4.9315,
              "speed3d": 4.994999999999999,
              "msFromStart": 188154.75733333314
            },
            {
              "lat": 43.77583747102518,
              "lng": -116.09340479841777,
              "alt": 2146.3890947144237,
              "speed2d": 4.992048456228468,
              "speed3d": 5.096935501597043,
              "msFromStart": 188500
            },
            {
              "lat": 43.7758127,
              "lng": -116.09339159999999,
              "alt": 2147.2536666666665,
              "speed2d": 4.934666666666667,
              "speed3d": 5.113333333333333,
              "msFromStart": 189087.7813333334
            },
            {
              "lat": 43.77579611348326,
              "lng": -116.0933824466814,
              "alt": 2147.8461049417874,
              "speed2d": 4.870124754128922,
              "speed3d": 5.062869223135891,
              "msFromStart": 189500
            },
            {
              "lat": 43.77577618408335,
              "lng": -116.0933734536057,
              "alt": 2148.5411619304223,
              "speed2d": 4.76461254063046,
              "speed3d": 4.91516077232923,
              "msFromStart": 190000
            },
            {
              "lat": 43.775756108159314,
              "lng": -116.09335932351283,
              "alt": 2149.3518934966523,
              "speed2d": 4.798888508486595,
              "speed3d": 4.964126440695136,
              "msFromStart": 190500
            },
            {
              "lat": 43.77573123333334,
              "lng": -116.09334176666667,
              "alt": 2150.3546666666666,
              "speed2d": 4.774333333333334,
              "speed3d": 5.01,
              "msFromStart": 191125.5216842106
            },
            {
              "lat": 43.775710419999996,
              "lng": -116.09332946,
              "alt": 2151.11,
              "speed2d": 4.6762,
              "speed3d": 4.87,
              "msFromStart": 191664.61582222214
            },
            {
              "lat": 43.775692,
              "lng": -116.09332144999999,
              "alt": 2151.3109999999997,
              "speed2d": 4.7895,
              "speed3d": 4.99,
              "msFromStart": 192088.3279999998
            },
            {
              "lat": 43.775678079535936,
              "lng": -116.09331229228654,
              "alt": 2152.57123069206,
              "speed2d": 4.638347308295977,
              "speed3d": 4.805068688208925,
              "msFromStart": 192500
            },
            {
              "lat": 43.775662178080935,
              "lng": -116.09330195791615,
              "alt": 2152.990006203053,
              "speed2d": 4.045084080445876,
              "speed3d": 4.346369275502812,
              "msFromStart": 193000
            },
            {
              "lat": 43.775631425,
              "lng": -116.093286125,
              "alt": 2153.7365,
              "speed2d": 4.7989999999999995,
              "speed3d": 5.0024999999999995,
              "msFromStart": 193627.4146666667
            },
            {
              "lat": 43.775606450000005,
              "lng": -116.09327490000001,
              "alt": 2155.26175,
              "speed2d": 4.38075,
              "speed3d": 4.605,
              "msFromStart": 194356.36200000005
            },
            {
              "lat": 43.7755987,
              "lng": -116.0932705,
              "alt": 2155.5985,
              "speed2d": 4.4945,
              "speed3d": 4.710000000000001,
              "msFromStart": 194562.40000000005
            },
            {
              "lat": 43.77557518,
              "lng": -116.09325745999999,
              "alt": 2156.3544,
              "speed2d": 4.7144,
              "speed3d": 4.932,
              "msFromStart": 195139.0880000002
            },
            {
              "lat": 43.775562605414976,
              "lng": -116.09324785717845,
              "alt": 2157.053991394727,
              "speed2d": 4.569341570500585,
              "speed3d": 4.8295306839893195,
              "msFromStart": 195500
            },
            {
              "lat": 43.775530425,
              "lng": -116.093228625,
              "alt": 2157.652,
              "speed2d": 4.40175,
              "speed3d": 4.609999999999999,
              "msFromStart": 196350.67361403516
            },
            {
              "lat": 43.775515275,
              "lng": -116.09322045,
              "alt": 2158.18925,
              "speed2d": 4.52525,
              "speed3d": 4.699999999999999,
              "msFromStart": 196750.26311111113
            },
            {
              "lat": 43.775500050000005,
              "lng": -116.09321349999999,
              "alt": 2158.812,
              "speed2d": 4.5335,
              "speed3d": 4.75,
              "msFromStart": 197149.8702222223
            },
            {
              "lat": 43.77548652957207,
              "lng": -116.0932103303001,
              "alt": 2158.7002793565757,
              "speed2d": 4.276395814823165,
              "speed3d": 4.515568698696441,
              "msFromStart": 197500
            },
            {
              "lat": 43.775468951049845,
              "lng": -116.09319787118933,
              "alt": 2159.681488084859,
              "speed2d": 4.176441950014521,
              "speed3d": 4.3454335585585495,
              "msFromStart": 198000
            },
            {
              "lat": 43.77544908491355,
              "lng": -116.0931861785491,
              "alt": 2160.2854186283057,
              "speed2d": 4.355804780587033,
              "speed3d": 4.521390765765761,
              "msFromStart": 198500
            },
            {
              "lat": 43.775428745854505,
              "lng": -116.09317294415564,
              "alt": 2160.859462175112,
              "speed2d": 4.696480615652686,
              "speed3d": 4.859162189632648,
              "msFromStart": 199000
            },
            {
              "lat": 43.77540961840145,
              "lng": -116.09316365966201,
              "alt": 2161.5100748120763,
              "speed2d": 4.62388006388848,
              "speed3d": 4.782373144497431,
              "msFromStart": 199500
            },
            {
              "lat": 43.7753814,
              "lng": -116.09314925000001,
              "alt": 2162.3665,
              "speed2d": 4.7245,
              "speed3d": 4.85,
              "msFromStart": 200201.52694736834
            },
            {
              "lat": 43.77536804109849,
              "lng": -116.0931418316805,
              "alt": 2162.618215647637,
              "speed2d": 4.901611312511925,
              "speed3d": 4.993056562559627,
              "msFromStart": 200500
            },
            {
              "lat": 43.775346801209274,
              "lng": -116.09313074297673,
              "alt": 2162.892362791465,
              "speed2d": 4.90269488368612,
              "speed3d": 4.956502325597218,
              "msFromStart": 201000
            },
            {
              "lat": 43.77532569690117,
              "lng": -116.0931194128878,
              "alt": 2163.6777732323644,
              "speed2d": 4.823073408360648,
              "speed3d": 4.921207175496104,
              "msFromStart": 201500
            },
            {
              "lat": 43.77530501047586,
              "lng": -116.09310741681476,
              "alt": 2164.1615332747447,
              "speed2d": 4.823238349405018,
              "speed3d": 4.965625987680252,
              "msFromStart": 202000
            },
            {
              "lat": 43.775272900000004,
              "lng": -116.09309329999999,
              "alt": 2164.5240000000003,
              "speed2d": 5.0095,
              "speed3d": 5.13,
              "msFromStart": 202703.76311111107
            },
            {
              "lat": 43.77525902515891,
              "lng": -116.09308462260394,
              "alt": 2164.772118865333,
              "speed2d": 5.183990364066509,
              "speed3d": 5.246537139747029,
              "msFromStart": 203000
            },
            {
              "lat": 43.77524043406728,
              "lng": -116.09307484712505,
              "alt": 2165.330861999764,
              "speed2d": 4.960189994669625,
              "speed3d": 5.034356202621314,
              "msFromStart": 203500
            },
            {
              "lat": 43.77522184297564,
              "lng": -116.09306507164615,
              "alt": 2165.889605134195,
              "speed2d": 4.73638962527274,
              "speed3d": 4.8221752654956,
              "msFromStart": 204000
            },
            {
              "lat": 43.77520325188401,
              "lng": -116.09305529616726,
              "alt": 2166.448348268626,
              "speed2d": 4.512589255875856,
              "speed3d": 4.609994328369886,
              "msFromStart": 204500
            },
            {
              "lat": 43.775178249999996,
              "lng": -116.09304039999999,
              "alt": 2167.31225,
              "speed2d": 4.163,
              "speed3d": 4.345,
              "msFromStart": 205206.94755555567
            },
            {
              "lat": 43.77515865,
              "lng": -116.09302675,
              "alt": 2167.5275,
              "speed2d": 4.314,
              "speed3d": 4.4525,
              "msFromStart": 205741.97933333332
            },
            {
              "lat": 43.77513506666667,
              "lng": -116.09301520000001,
              "alt": 2168.6406666666667,
              "speed2d": 4.541666666666667,
              "speed3d": 4.640000000000001,
              "msFromStart": 206341.1512982456
            },
            {
              "lat": 43.775127849107925,
              "lng": -116.09301285217855,
              "alt": 2168.860245371471,
              "speed2d": 4.638316516628732,
              "speed3d": 4.736803512586202,
              "msFromStart": 206500
            },
            {
              "lat": 43.77510735696662,
              "lng": -116.09300223955341,
              "alt": 2168.978939205239,
              "speed2d": 4.610737655253331,
              "speed3d": 4.684438585924046,
              "msFromStart": 207000
            },
            {
              "lat": 43.77508597798329,
              "lng": -116.09299377235371,
              "alt": 2168.580057240348,
              "speed2d": 4.502289007696172,
              "speed3d": 4.527374941542583,
              "msFromStart": 207500
            },
            {
              "lat": 43.775066589606645,
              "lng": -116.09298048952951,
              "alt": 2169.3429265324507,
              "speed2d": 4.575360778970342,
              "speed3d": 4.605318164235031,
              "msFromStart": 208000
            },
            {
              "lat": 43.77504720123,
              "lng": -116.09296720670531,
              "alt": 2170.105795824553,
              "speed2d": 4.648432550244513,
              "speed3d": 4.68326138692748,
              "msFromStart": 208500
            },
            {
              "lat": 43.775009600000004,
              "lng": -116.09294105000001,
              "alt": 2171.563,
              "speed2d": 4.802,
              "speed3d": 4.865,
              "msFromStart": 209468.78266666664
            },
            {
              "lat": 43.774996439999995,
              "lng": -116.09293186000001,
              "alt": 2172.1192,
              "speed2d": 4.8546,
              "speed3d": 4.962,
              "msFromStart": 209792.87359999988
            },
            {
              "lat": 43.77498613065703,
              "lng": -116.09292552732819,
              "alt": 2172.3314533566395,
              "speed2d": 5.169546563909125,
              "speed3d": 5.198866608402081,
              "msFromStart": 210000
            },
            {
              "lat": 43.77495076,
              "lng": -116.09291026,
              "alt": 2172.6014,
              "speed2d": 4.8914,
              "speed3d": 5.012,
              "msFromStart": 210837.3306947368
            },
            {
              "lat": 43.774936100000005,
              "lng": -116.0929060375,
              "alt": 2173.0978750000004,
              "speed2d": 4.6286249999999995,
              "speed3d": 4.7875,
              "msFromStart": 211227.91029239763
            },
            {
              "lat": 43.774923610718126,
              "lng": -116.0928998041711,
              "alt": 2173.5610137256676,
              "speed2d": 4.853262408754431,
              "speed3d": 4.89125660074552,
              "msFromStart": 211500
            },
            {
              "lat": 43.7749088608047,
              "lng": -116.09289728266673,
              "alt": 2174.02684114675,
              "speed2d": 4.113445056686126,
              "speed3d": 4.2545080452080635,
              "msFromStart": 212000
            },
            {
              "lat": 43.7748886432584,
              "lng": -116.09288771027745,
              "alt": 2174.5723022938523,
              "speed2d": 4.273535015139792,
              "speed3d": 4.411297179776086,
              "msFromStart": 212500
            },
            {
              "lat": 43.7748604,
              "lng": -116.09287412500001,
              "alt": 2175.285,
              "speed2d": 4.4254999999999995,
              "speed3d": 4.5525,
              "msFromStart": 213207.5893333332
            },
            {
              "lat": 43.774849218135785,
              "lng": -116.09287047659731,
              "alt": 2175.3111594552042,
              "speed2d": 4.4525183709760014,
              "speed3d": 4.530647963720002,
              "msFromStart": 213500
            },
            {
              "lat": 43.7748327454809,
              "lng": -116.09285841746109,
              "alt": 2176.0170897576913,
              "speed2d": 4.283522183041629,
              "speed3d": 4.431170910032023,
              "msFromStart": 214000
            },
            {
              "lat": 43.774813268752204,
              "lng": -116.09284242989226,
              "alt": 2177.5883096955763,
              "speed2d": 4.549163016401916,
              "speed3d": 3.8367118501504,
              "msFromStart": 214500
            },
            {
              "lat": 43.7747805,
              "lng": -116.09282752499999,
              "alt": 2178.68975,
              "speed2d": 5.02475,
              "speed3d": 5.1450000000000005,
              "msFromStart": 215257.2696140351
            },
            {
              "lat": 43.774759425,
              "lng": -116.0928159,
              "alt": 2179.4565000000002,
              "speed2d": 5.13525,
              "speed3d": 5.255000000000001,
              "msFromStart": 215738.82442105265
            },
            {
              "lat": 43.77474858721683,
              "lng": -116.09281042678266,
              "alt": 2179.8690389328826,
              "speed2d": 5.068668537674309,
              "speed3d": 5.225446377716935,
              "msFromStart": 216000
            },
            {
              "lat": 43.774728114458206,
              "lng": -116.09280126313226,
              "alt": 2180.5970400475253,
              "speed2d": 4.941759252454344,
              "speed3d": 5.072718871148495,
              "msFromStart": 216500
            },
            {
              "lat": 43.77470775482781,
              "lng": -116.09279308337037,
              "alt": 2181.3490931213682,
              "speed2d": 4.726904454017209,
              "speed3d": 4.812380567521512,
              "msFromStart": 217000
            },
            {
              "lat": 43.77468235,
              "lng": -116.09277745,
              "alt": 2182.3999999999996,
              "speed2d": 4.7375,
              "speed3d": 4.9,
              "msFromStart": 217636.38488888886
            },
            {
              "lat": 43.774664400000006,
              "lng": -116.09276565,
              "alt": 2183.8135,
              "speed2d": 4.593500000000001,
              "speed3d": 4.755,
              "msFromStart": 218131.5448888888
            },
            {
              "lat": 43.774649292888405,
              "lng": -116.0927569503199,
              "alt": 2184.3872906029637,
              "speed2d": 4.663746995224475,
              "speed3d": 4.782964953583846,
              "msFromStart": 218500
            },
            {
              "lat": 43.77462993074768,
              "lng": -116.09274524516837,
              "alt": 2185.2314422810855,
              "speed2d": 4.552971700660525,
              "speed3d": 4.691959754083717,
              "msFromStart": 219000
            },
            {
              "lat": 43.77459675,
              "lng": -116.092725,
              "alt": 2186.6885,
              "speed2d": 4.354,
              "speed3d": 4.555,
              "msFromStart": 219862.3173333333
            },
            {
              "lat": 43.77457625,
              "lng": -116.0927112,
              "alt": 2187.8575,
              "speed2d": 4.2695,
              "speed3d": 4.505,
              "msFromStart": 220466.28421052633
            },
            {
              "lat": 43.77456144999999,
              "lng": -116.0927082,
              "alt": 2188.0805,
              "speed2d": 4.2355,
              "speed3d": 4.445,
              "msFromStart": 220852.13599999994
            },
            {
              "lat": 43.774539250000004,
              "lng": -116.092694425,
              "alt": 2189.31525,
              "speed2d": 4.54025,
              "speed3d": 4.765,
              "msFromStart": 221403.32824561402
            },
            {
              "lat": 43.77453543266425,
              "lng": -116.0926924376598,
              "alt": 2189.4545044614765,
              "speed2d": 4.547487659002274,
              "speed3d": 4.743187629420456,
              "msFromStart": 221500
            },
            {
              "lat": 43.774508133333335,
              "lng": -116.09267936666666,
              "alt": 2190.5919999999996,
              "speed2d": 4.271333333333334,
              "speed3d": 4.473333333333334,
              "msFromStart": 222257.18755555555
            },
            {
              "lat": 43.774497725371226,
              "lng": -116.0926731865519,
              "alt": 2191.173643868884,
              "speed2d": 4.538569585015336,
              "speed3d": 4.731137606975613,
              "msFromStart": 222500
            },
            {
              "lat": 43.774468299999995,
              "lng": -116.0926589,
              "alt": 2192.359,
              "speed2d": 4.577999999999999,
              "speed3d": 4.755000000000001,
              "msFromStart": 223248.17422222215
            },
            {
              "lat": 43.77445745080183,
              "lng": -116.0926539143021,
              "alt": 2192.9067061657342,
              "speed2d": 4.689668143166609,
              "speed3d": 4.857852015907401,
              "msFromStart": 223500
            },
            {
              "lat": 43.774427599999996,
              "lng": -116.09263899999999,
              "alt": 2195.1033333333335,
              "speed2d": 4.342666666666666,
              "speed3d": 4.656666666666666,
              "msFromStart": 224292.97333333333
            },
            {
              "lat": 43.7744154,
              "lng": -116.09263430000001,
              "alt": 2196.003333333333,
              "speed2d": 4.269,
              "speed3d": 4.54,
              "msFromStart": 224622.7155555556
            },
            {
              "lat": 43.77438263333333,
              "lng": -116.09261973333334,
              "alt": 2197.0556666666666,
              "speed2d": 4.8726666666666665,
              "speed3d": 5.093333333333334,
              "msFromStart": 225336.9678752437
            },
            {
              "lat": 43.77437634307847,
              "lng": -116.09261650101635,
              "alt": 2197.338729654037,
              "speed2d": 4.8166204531186905,
              "speed3d": 5.082946660509155,
              "msFromStart": 225500
            },
            {
              "lat": 43.77435998982405,
              "lng": -116.09261097739737,
              "alt": 2198.4214355683384,
              "speed2d": 4.210011887929609,
              "speed3d": 4.553368204253768,
              "msFromStart": 226000
            },
            {
              "lat": 43.7743267,
              "lng": -116.09259106666667,
              "alt": 2199.0313333333334,
              "speed2d": 4.936,
              "speed3d": 5.136666666666667,
              "msFromStart": 226710.47999999998
            },
            {
              "lat": 43.7743156221111,
              "lng": -116.09258347080237,
              "alt": 2199.671182682083,
              "speed2d": 4.833054556956923,
              "speed3d": 5.101874577685661,
              "msFromStart": 227000
            },
            {
              "lat": 43.77428355,
              "lng": -116.092564125,
              "alt": 2201.40725,
              "speed2d": 4.615,
              "speed3d": 4.87,
              "msFromStart": 227838.66533333345
            },
            {
              "lat": 43.77427734069633,
              "lng": -116.09256296712977,
              "alt": 2201.5849078459078,
              "speed2d": 4.569370381620314,
              "speed3d": 4.825810761435691,
              "msFromStart": 228000
            },
            {
              "lat": 43.77425715,
              "lng": -116.0925593,
              "alt": 2201.893,
              "speed2d": 4.2775,
              "speed3d": 4.535,
              "msFromStart": 228554.57288888888
            },
            {
              "lat": 43.77423663333334,
              "lng": -116.09255683333333,
              "alt": 2201.804333333333,
              "speed2d": 4.217333333333333,
              "speed3d": 4.47,
              "msFromStart": 229077.7484444445
            },
            {
              "lat": 43.7742095,
              "lng": -116.09255049999999,
              "alt": 2203.5293333333334,
              "speed2d": 4.162999999999999,
              "speed3d": 4.39,
              "msFromStart": 229829.80148148155
            },
            {
              "lat": 43.774203035488206,
              "lng": -116.09254747276636,
              "alt": 2203.809397380544,
              "speed2d": 4.209100495316647,
              "speed3d": 4.436294685563967,
              "msFromStart": 230000
            },
            {
              "lat": 43.7741772,
              "lng": -116.092534,
              "alt": 2204.3133333333335,
              "speed2d": 4.6,
              "speed3d": 4.82,
              "msFromStart": 230618.0101052631
            },
            {
              "lat": 43.774160706116156,
              "lng": -116.09252964771221,
              "alt": 2204.4421673088136,
              "speed2d": 4.666558773654963,
              "speed3d": 4.780252657503495,
              "msFromStart": 231000
            },
            {
              "lat": 43.77414198163916,
              "lng": -116.09251570283432,
              "alt": 2204.9152643201073,
              "speed2d": 4.577776340002234,
              "speed3d": 4.711940850005584,
              "msFromStart": 231500
            },
            {
              "lat": 43.77412049920429,
              "lng": -116.09251042317084,
              "alt": 2205.2798281057735,
              "speed2d": 4.707866882872326,
              "speed3d": 4.836284982439305,
              "msFromStart": 232000
            },
            {
              "lat": 43.77410253537554,
              "lng": -116.09250237295824,
              "alt": 2206.0774539113904,
              "speed2d": 4.392191777383323,
              "speed3d": 4.605902318326073,
              "msFromStart": 232500
            },
            {
              "lat": 43.774084102839254,
              "lng": -116.09248917896318,
              "alt": 2206.0111679988904,
              "speed2d": 4.452420089636449,
              "speed3d": 4.727993115557129,
              "msFromStart": 233000
            },
            {
              "lat": 43.774068375994865,
              "lng": -116.09247952623082,
              "alt": 2207.7724895406222,
              "speed2d": 4.068573830693303,
              "speed3d": 4.159224538535678,
              "msFromStart": 233500
            },
            {
              "lat": 43.774032275,
              "lng": -116.092451875,
              "alt": 2208.89825,
              "speed2d": 4.823,
              "speed3d": 4.98,
              "msFromStart": 234343.54822222222
            },
            {
              "lat": 43.77402597979581,
              "lng": -116.09244724634448,
              "alt": 2209.1676553191487,
              "speed2d": 4.8586446565811885,
              "speed3d": 5.0262427958903055,
              "msFromStart": 234500
            },
            {
              "lat": 43.774000171428575,
              "lng": -116.09243294285716,
              "alt": 2210.7245714285714,
              "speed2d": 4.632,
              "speed3d": 4.841428571428572,
              "msFromStart": 235181.33066666676
            },
            {
              "lat": 43.77397795,
              "lng": -116.0924158,
              "alt": 2211.509,
              "speed2d": 4.82,
              "speed3d": 4.975,
              "msFromStart": 235730.7932631579
            },
            {
              "lat": 43.7739675991909,
              "lng": -116.0924091227563,
              "alt": 2212.0476427135945,
              "speed2d": 4.7514073086214195,
              "speed3d": 4.944107406423803,
              "msFromStart": 236000
            },
            {
              "lat": 43.7739410375,
              "lng": -116.09240253749999,
              "alt": 2212.766375,
              "speed2d": 4.37975,
              "speed3d": 4.641249999999999,
              "msFromStart": 236692.82400000005
            },
            {
              "lat": 43.773922400000004,
              "lng": -116.0923983,
              "alt": 2213.1495,
              "speed2d": 4.3004999999999995,
              "speed3d": 4.475,
              "msFromStart": 237215.55200000008
            },
            {
              "lat": 43.773899400000005,
              "lng": -116.0923829,
              "alt": 2214.4845,
              "speed2d": 4.318,
              "speed3d": 4.52,
              "msFromStart": 237847.98044444434
            },
            {
              "lat": 43.773893645924964,
              "lng": -116.09237872299913,
              "alt": 2214.91137019874,
              "speed2d": 4.362969876878329,
              "speed3d": 4.6023512166747365,
              "msFromStart": 238000
            },
            {
              "lat": 43.77387452328803,
              "lng": -116.09236977971264,
              "alt": 2215.6984521182744,
              "speed2d": 4.350972785264176,
              "speed3d": 4.573267358216184,
              "msFromStart": 238500
            },
            {
              "lat": 43.773860621656674,
              "lng": -116.09236490375942,
              "alt": 2216.342280288368,
              "speed2d": 3.578426843429362,
              "speed3d": 4.029675827365914,
              "msFromStart": 239000
            },
            {
              "lat": 43.77381796,
              "lng": -116.09234147999999,
              "alt": 2217.8302,
              "speed2d": 4.9912,
              "speed3d": 5.128,
              "msFromStart": 239834.6261333333
            },
            {
              "lat": 43.773805225,
              "lng": -116.09233275,
              "alt": 2218.35675,
              "speed2d": 5.0435,
              "speed3d": 5.2375,
              "msFromStart": 240132.24533333327
            },
            {
              "lat": 43.77379086321298,
              "lng": -116.09232545794153,
              "alt": 2218.866130765888,
              "speed2d": 4.898189865284033,
              "speed3d": 5.063299860638655,
              "msFromStart": 240500
            },
            {
              "lat": 43.77375893333333,
              "lng": -116.09231463333333,
              "alt": 2219.588333333333,
              "speed2d": 4.526666666666666,
              "speed3d": 4.706666666666667,
              "msFromStart": 241326.01459649135
            },
            {
              "lat": 43.77374795,
              "lng": -116.092308,
              "alt": 2220.4545,
              "speed2d": 4.2965,
              "speed3d": 4.609999999999999,
              "msFromStart": 241646.95200000005
            },
            {
              "lat": 43.77372195,
              "lng": -116.09229285,
              "alt": 2221.4295,
              "speed2d": 4.5375,
              "speed3d": 4.734999999999999,
              "msFromStart": 242279.40088888895
            },
            {
              "lat": 43.77371966604249,
              "lng": -116.0922896565053,
              "alt": 2222.1020032987285,
              "speed2d": 3.8224641505773067,
              "speed3d": 4.365424468807619,
              "msFromStart": 242500
            },
            {
              "lat": 43.7736802,
              "lng": -116.0922685125,
              "alt": 2222.751,
              "speed2d": 4.7725,
              "speed3d": 4.942500000000001,
              "msFromStart": 243296.40799999994
            },
            {
              "lat": 43.77366325,
              "lng": -116.09226172500001,
              "alt": 2223.4075,
              "speed2d": 4.599,
              "speed3d": 4.84,
              "msFromStart": 243736.1733333333
            },
            {
              "lat": 43.77365333359741,
              "lng": -116.09226050349555,
              "alt": 2224.100973678254,
              "speed2d": 4.528098075990337,
              "speed3d": 4.7265539207761025,
              "msFromStart": 244000
            },
            {
              "lat": 43.773630911025805,
              "lng": -116.09224624485529,
              "alt": 2224.2119015154044,
              "speed2d": 4.830744398663252,
              "speed3d": 4.990593817828939,
              "msFromStart": 244500
            },
            {
              "lat": 43.77361272194223,
              "lng": -116.09223629435662,
              "alt": 2225.092467149727,
              "speed2d": 4.611940422698541,
              "speed3d": 4.787304060208913,
              "msFromStart": 245000
            },
            {
              "lat": 43.77358735,
              "lng": -116.09222270000001,
              "alt": 2226.4245,
              "speed2d": 4.234,
              "speed3d": 4.505000000000001,
              "msFromStart": 245715.30568421062
            },
            {
              "lat": 43.77356926666667,
              "lng": -116.09221426666667,
              "alt": 2227.476333333333,
              "speed2d": 4.186333333333334,
              "speed3d": 4.4,
              "msFromStart": 246237.5094269007
            },
            {
              "lat": 43.7735531,
              "lng": -116.09220565000001,
              "alt": 2228.237,
              "speed2d": 4.273,
              "speed3d": 4.48,
              "msFromStart": 246677.0986666666
            },
            {
              "lat": 43.77354014521633,
              "lng": -116.09219753014422,
              "alt": 2228.7578539461488,
              "speed2d": 4.426328572660455,
              "speed3d": 4.567929376765067,
              "msFromStart": 247000
            },
            {
              "lat": 43.77352036295265,
              "lng": -116.09218433849622,
              "alt": 2229.499997294512,
              "speed2d": 4.493651269498347,
              "speed3d": 4.71995471032446,
              "msFromStart": 247500
            },
            {
              "lat": 43.77350157684216,
              "lng": -116.09217259482459,
              "alt": 2230.5785906266196,
              "speed2d": 4.579525576754478,
              "speed3d": 4.656397506502562,
              "msFromStart": 248000
            },
            {
              "lat": 43.773479586934464,
              "lng": -116.09216188690573,
              "alt": 2230.8195002630196,
              "speed2d": 4.874717767058025,
              "speed3d": 5.0028309475043,
              "msFromStart": 248500
            },
            {
              "lat": 43.77346185074293,
              "lng": -116.09215250943964,
              "alt": 2231.9289697970285,
              "speed2d": 4.491492570651681,
              "speed3d": 4.580615713314599,
              "msFromStart": 249000
            },
            {
              "lat": 43.773430766666664,
              "lng": -116.09212033333333,
              "alt": 2233.979333333333,
              "speed2d": 4.8276666666666666,
              "speed3d": 5.003333333333334,
              "msFromStart": 249814.01066666679
            },
            {
              "lat": 43.77342324842688,
              "lng": -116.09211471370249,
              "alt": 2234.482516386218,
              "speed2d": 4.9291118047076194,
              "speed3d": 5.057362194855655,
              "msFromStart": 250000
            },
            {
              "lat": 43.7733997,
              "lng": -116.0920908,
              "alt": 2236.584,
              "speed2d": 5,
              "speed3d": 5.23,
              "msFromStart": 250610.74
            },
            {
              "lat": 43.77338501013776,
              "lng": -116.0920789853704,
              "alt": 2237.530856928089,
              "speed2d": 4.948876163255955,
              "speed3d": 5.100553418314745,
              "msFromStart": 251000
            },
            {
              "lat": 43.77336223333333,
              "lng": -116.092063,
              "alt": 2239.2213333333334,
              "speed2d": 4.949999999999999,
              "speed3d": 5.253333333333333,
              "msFromStart": 251574.32589473686
            },
            {
              "lat": 43.77333335,
              "lng": -116.092042975,
              "alt": 2240.746125,
              "speed2d": 5.09875,
              "speed3d": 5.390000000000001,
              "msFromStart": 252264.65196491234
            },
            {
              "lat": 43.773323250000004,
              "lng": -116.09203835,
              "alt": 2241.419,
              "speed2d": 4.897,
              "speed3d": 5.215,
              "msFromStart": 252539.9946666667
            },
            {
              "lat": 43.77330314640174,
              "lng": -116.0920245153772,
              "alt": 2242.7826052469363,
              "speed2d": 5.1687688605087505,
              "speed3d": 5.346299441093147,
              "msFromStart": 253000
            },
            {
              "lat": 43.773286280581274,
              "lng": -116.09202207468608,
              "alt": 2243.021629369274,
              "speed2d": 4.496385780772775,
              "speed3d": 4.895110534736023,
              "msFromStart": 253500
            },
            {
              "lat": 43.77326065,
              "lng": -116.09200085,
              "alt": 2244.191,
              "speed2d": 4.7055,
              "speed3d": 4.9,
              "msFromStart": 254160.87466666667
            },
            {
              "lat": 43.773230766666664,
              "lng": -116.09197540000001,
              "alt": 2246.374666666667,
              "speed2d": 5.140000000000001,
              "speed3d": 5.433333333333334,
              "msFromStart": 254875.80800000008
            },
            {
              "lat": 43.77322183333334,
              "lng": -116.09197126666666,
              "alt": 2247.1016666666665,
              "speed2d": 5.064333333333333,
              "speed3d": 5.383333333333333,
              "msFromStart": 255095.768888889
            },
            {
              "lat": 43.7732021086325,
              "lng": -116.09196990627234,
              "alt": 2248.164743279096,
              "speed2d": 5.226203367736585,
              "speed3d": 5.543857101231456,
              "msFromStart": 255500
            },
            {
              "lat": 43.77317865,
              "lng": -116.09195030000001,
              "alt": 2250.462,
              "speed2d": 4.4365000000000006,
              "speed3d": 4.83,
              "msFromStart": 256249.330502924
            },
            {
              "lat": 43.77315518,
              "lng": -116.09193006000001,
              "alt": 2252.225,
              "speed2d": 4.7244,
              "speed3d": 5.038,
              "msFromStart": 256853.832888889
            },
            {
              "lat": 43.77314440000001,
              "lng": -116.09192153333333,
              "alt": 2252.8636666666666,
              "speed2d": 4.825333333333334,
              "speed3d": 5.16,
              "msFromStart": 257128.70844444464
            },
            {
              "lat": 43.773119900000005,
              "lng": -116.0919066142857,
              "alt": 2254.199857142857,
              "speed2d": 4.829000000000001,
              "speed3d": 5.235714285714287,
              "msFromStart": 257741.7523809524
            },
            {
              "lat": 43.773107075,
              "lng": -116.09190170000001,
              "alt": 2255.06,
              "speed2d": 4.4045000000000005,
              "speed3d": 4.8149999999999995,
              "msFromStart": 258146.7680000001
            },
            {
              "lat": 43.77309244681381,
              "lng": -116.09189315926429,
              "alt": 2256.13345795133,
              "speed2d": 5.0286366723259786,
              "speed3d": 5.232559139784948,
              "msFromStart": 258500
            },
            {
              "lat": 43.773066075,
              "lng": -116.09188245000001,
              "alt": 2256.1425,
              "speed2d": 4.4875,
              "speed3d": 4.835,
              "msFromStart": 259287.81266666664
            },
            {
              "lat": 43.77305673209314,
              "lng": -116.09187786306491,
              "alt": 2255.481696396637,
              "speed2d": 5.011181787423271,
              "speed3d": 5.077755691430226,
              "msFromStart": 259500
            },
            {
              "lat": 43.77302495,
              "lng": -116.09186650000001,
              "alt": 2256.728,
              "speed2d": 4.902,
              "speed3d": 5.15,
              "msFromStart": 260239.1613333334
            },
            {
              "lat": 43.77301530275543,
              "lng": -116.09186212371762,
              "alt": 2256.616408245289,
              "speed2d": 4.658874226853745,
              "speed3d": 5.019580756179148,
              "msFromStart": 260500
            },
            {
              "lat": 43.77299724636419,
              "lng": -116.09184555334885,
              "alt": 2256.9208760895203,
              "speed2d": 4.634346364179467,
              "speed3d": 4.7788128756197725,
              "msFromStart": 261000
            },
            {
              "lat": 43.77296603333333,
              "lng": -116.09182416666667,
              "alt": 2259.6360000000004,
              "speed2d": 4.580333333333334,
              "speed3d": 4.9,
              "msFromStart": 261861.609263158
            },
            {
              "lat": 43.772944949999996,
              "lng": -116.09181245,
              "alt": 2260.375666666667,
              "speed2d": 5.0195,
              "speed3d": 5.276666666666667,
              "msFromStart": 262328.8779883042
            },
            {
              "lat": 43.77293605,
              "lng": -116.09180835000001,
              "alt": 2260.8415,
              "speed2d": 4.963,
              "speed3d": 5.234999999999999,
              "msFromStart": 262548.6906666667
            },
            {
              "lat": 43.7729220139457,
              "lng": -116.09179847371715,
              "alt": 2261.2983231676253,
              "speed2d": 4.593202634835175,
              "speed3d": 4.846876774146622,
              "msFromStart": 263000
            },
            {
              "lat": 43.77290485995668,
              "lng": -116.09178843873573,
              "alt": 2261.468458192265,
              "speed2d": 3.941952402507126,
              "speed3d": 4.346896809743978,
              "msFromStart": 263500
            },
            {
              "lat": 43.77289628702664,
              "lng": -116.09177766810785,
              "alt": 2262.430124339936,
              "speed2d": 3.2496216350193325,
              "speed3d": 3.412648662628868,
              "msFromStart": 264000
            },
            {
              "lat": 43.772866300000004,
              "lng": -116.09175669999999,
              "alt": 2263.7856666666667,
              "speed2d": 4.208333333333333,
              "speed3d": 4.406666666666666,
              "msFromStart": 264756.57540740754
            },
            {
              "lat": 43.77285711778817,
              "lng": -116.09174906943876,
              "alt": 2264.3833372928907,
              "speed2d": 4.386373032082099,
              "speed3d": 4.5806456324497855,
              "msFromStart": 265000
            },
            {
              "lat": 43.772827275,
              "lng": -116.091728125,
              "alt": 2266.0022500000005,
              "speed2d": 4.381499999999999,
              "speed3d": 4.6274999999999995,
              "msFromStart": 265847.5119999998
            },
            {
              "lat": 43.77282103057078,
              "lng": -116.09172786031513,
              "alt": 2265.6183307338606,
              "speed2d": 4.250306480289368,
              "speed3d": 4.687387039421259,
              "msFromStart": 266000
            },
            {
              "lat": 43.77280695479554,
              "lng": -116.09171593696034,
              "alt": 2267.7417616271223,
              "speed2d": 4.331987100729568,
              "speed3d": 4.55,
              "msFromStart": 266500
            },
            {
              "lat": 43.77278295,
              "lng": -116.09170055,
              "alt": 2268.812,
              "speed2d": 4.335,
              "speed3d": 4.574999999999999,
              "msFromStart": 267139.4324210525
            },
            {
              "lat": 43.77276850298876,
              "lng": -116.09169135065395,
              "alt": 2269.591465801201,
              "speed2d": 4.542821061809774,
              "speed3d": 4.721580120131808,
              "msFromStart": 267500
            },
            {
              "lat": 43.77274059999999,
              "lng": -116.09167264285713,
              "alt": 2271.0814285714287,
              "speed2d": 4.816714285714286,
              "speed3d": 5.108571428571428,
              "msFromStart": 268184.8533333331
            },
            {
              "lat": 43.772730921428106,
              "lng": -116.09166898367322,
              "alt": 2271.6518170749446,
              "speed2d": 4.374379019487477,
              "speed3d": 4.695852777688031,
              "msFromStart": 268500
            },
            {
              "lat": 43.77270695,
              "lng": -116.0916553,
              "alt": 2272.627,
              "speed2d": 4.922499999999999,
              "speed3d": 5.12,
              "msFromStart": 269037.6684444443
            },
            {
              "lat": 43.77268105,
              "lng": -116.09163995,
              "alt": 2273.4785,
              "speed2d": 4.731,
              "speed3d": 4.975,
              "msFromStart": 269698.1279999999
            },
            {
              "lat": 43.77266850657398,
              "lng": -116.09163238698541,
              "alt": 2273.89388266357,
              "speed2d": 4.736506464533718,
              "speed3d": 4.956095383323098,
              "msFromStart": 270000
            },
            {
              "lat": 43.772651835526666,
              "lng": -116.09162434966618,
              "alt": 2274.524088012368,
              "speed2d": 4.540827561729923,
              "speed3d": 4.748772307783518,
              "msFromStart": 270500
            },
            {
              "lat": 43.77263516447936,
              "lng": -116.09161631234696,
              "alt": 2275.1542933611654,
              "speed2d": 4.345148658926129,
              "speed3d": 4.54144923224394,
              "msFromStart": 271000
            },
            {
              "lat": 43.77262073721978,
              "lng": -116.0916097312039,
              "alt": 2276.0052932035474,
              "speed2d": 3.6279360453844753,
              "speed3d": 3.8422180048363557,
              "msFromStart": 271500
            },
            {
              "lat": 43.77260104075613,
              "lng": -116.09159985776976,
              "alt": 2276.304738495731,
              "speed2d": 4.147569146466668,
              "speed3d": 4.307348937154782,
              "msFromStart": 272000
            },
            {
              "lat": 43.772581449125695,
              "lng": -116.09158819368051,
              "alt": 2277.096987674907,
              "speed2d": 4.466185610494793,
              "speed3d": 4.615361683679594,
              "msFromStart": 272500
            },
            {
              "lat": 43.772561933897165,
              "lng": -116.0915764771823,
              "alt": 2277.887872160967,
              "speed2d": 4.796228176898349,
              "speed3d": 4.931813016877092,
              "msFromStart": 273000
            },
            {
              "lat": 43.772528216666664,
              "lng": -116.09155835000001,
              "alt": 2279.2955,
              "speed2d": 4.884666666666666,
              "speed3d": 5.106666666666667,
              "msFromStart": 273823.4186666665
            },
            {
              "lat": 43.772515411111115,
              "lng": -116.09154723333333,
              "alt": 2279.746666666667,
              "speed2d": 4.528444444444444,
              "speed3d": 4.784444444444445,
              "msFromStart": 274234.8282469134
            },
            {
              "lat": 43.77250118,
              "lng": -116.09153629999999,
              "alt": 2280.2862,
              "speed2d": 4.0888,
              "speed3d": 4.3340000000000005,
              "msFromStart": 274707.6835555556
            },
            {
              "lat": 43.772486,
              "lng": -116.09153045,
              "alt": 2280.955,
              "speed2d": 4.018,
              "speed3d": 4.155,
              "msFromStart": 275142.64
            },
            {
              "lat": 43.77247242153904,
              "lng": -116.09152366076952,
              "alt": 2281.5551594478734,
              "speed2d": 4.129684772164525,
              "speed3d": 4.205674267341729,
              "msFromStart": 275500
            },
            {
              "lat": 43.7724408,
              "lng": -116.09150826666666,
              "alt": 2282.583666666667,
              "speed2d": 4.433,
              "speed3d": 4.590000000000001,
              "msFromStart": 276324.95457309944
            },
            {
              "lat": 43.7724254,
              "lng": -116.0914989,
              "alt": 2283.0445,
              "speed2d": 4.378500000000001,
              "speed3d": 4.575,
              "msFromStart": 276793.27663157915
            },
            {
              "lat": 43.772410533333336,
              "lng": -116.091489,
              "alt": 2283.9438888888885,
              "speed2d": 4.348666666666667,
              "speed3d": 4.594444444444445,
              "msFromStart": 277261.9309577651
            },
            {
              "lat": 43.77240067142857,
              "lng": -116.09148374285715,
              "alt": 2284.8912857142855,
              "speed2d": 3.702714285714286,
              "speed3d": 4.001428571428571,
              "msFromStart": 277749.4664126983
            },
            {
              "lat": 43.77239166666667,
              "lng": -116.09147568333333,
              "alt": 2285.3961666666664,
              "speed2d": 3.5068333333333332,
              "speed3d": 3.798333333333334,
              "msFromStart": 278169.77599999984
            },
            {
              "lat": 43.77238710136693,
              "lng": -116.09147177848119,
              "alt": 2285.6053008629615,
              "speed2d": 3.2376059371762493,
              "speed3d": 3.5574414911976575,
              "msFromStart": 278500
            },
            {
              "lat": 43.772381397729546,
              "lng": -116.09146835023107,
              "alt": 2286.0367142863306,
              "speed2d": 2.7955740399550577,
              "speed3d": 3.135736386779362,
              "msFromStart": 279000
            },
            {
              "lat": 43.77237546765476,
              "lng": -116.09146759559724,
              "alt": 2286.395282366252,
              "speed2d": 1.9841176983998712,
              "speed3d": 2.3713971229998387,
              "msFromStart": 279500
            },
            {
              "lat": 43.77236969344491,
              "lng": -116.09146165574151,
              "alt": 2286.8517635991393,
              "speed2d": 1.9477475592304876,
              "speed3d": 2.3259344490381095,
              "msFromStart": 280000
            },
            {
              "lat": 43.7723645,
              "lng": -116.0914515,
              "alt": 2287.7465,
              "speed2d": 1.9245,
              "speed3d": 2.295,
              "msFromStart": 280615.4627368421
            },
            {
              "lat": 43.77236260921508,
              "lng": -116.09144348482808,
              "alt": 2288.3661433247744,
              "speed2d": 1.8975162911885264,
              "speed3d": 2.2982655916488484,
              "msFromStart": 281000
            }
          ]
        }
      ]
    },
    {
      "id": "x",
      "pathType": "trail",
      "name": "X",
      "difficulty": "red",
      "videos": []
    }
  ],
  "videos": [
    {
      "id": "GH010028",
      "description": "Top of showcase-lift through nugget-cat-track and easy-way-down to pine-creek-lift",
      "time": "day"
    },
    {
      "id": "GH010026",
      "description": "Showcase-lift",
      "time": "day"
    },
    {
      "id": "GH010023",
      "description": "Top of superior-lift through nugget and easy-way-down to pine-creek-lift",
      "time": "day"
    },
    {
      "id": "GH010029",
      "description": "Top of pine-creek-lift through top of bonanza, paradise-cut-off, paradise, lower-paradise to pine-creek-lift",
      "time": "day"
    },
    {
      "id": "GH010044",
      "description": "Bitterroot-lift",
      "time": "day"
    },
    {
      "id": "GH010024",
      "description": "Pine-creek-lift",
      "time": "day"
    },
    {
      "id": "GH010025",
      "description": "Top of pine-creek-lift through cabin traverse to alpine",
      "time": "day"
    },
    {
      "id": "GH010033",
      "description": "Deer-point-lift",
      "time": "day"
    },
    {
      "id": "GH010035",
      "description": "Coach-lift",
      "time": "day"
    },
    {
      "id": "GH010039",
      "description": "Top of morning-star-lift through sunshine-cat-track to morning-star-lift",
      "time": "day"
    },
    {
      "id": "GH010021",
      "description": "Top of bitterroot-lift through smuggler to superior-lift",
      "time": "day"
    },
    {
      "id": "GH010034",
      "description": "Top of deer-point-lift across ridge and sourdough to coach-lift",
      "time": "day"
    },
    {
      "id": "GH010043",
      "description": "Top of morning-star-lift down butter-cup and lazy-mary to bitterroot-lift",
      "time": "day"
    },
    {
      "id": "GH010038",
      "description": "Top of showcase-lift down shaker-ridge to morning-star-lift",
      "time": "day"
    },
    {
      "id": "GH010031",
      "description": "Buttercup carpark down buttercup to bitterroot-lift",
      "time": "day"
    },
    {
      "id": "GH010036",
      "description": "Top of coach-lift to coach to coach-lift",
      "time": "day"
    },
    {
      "id": "GH010037",
      "description": "Top of deer-point-lift to showcase to showcase-lift",
      "time": "day"
    },
    {
      "id": "GH010027",
      "description": "Top of showcase-lift to alpine to showcase-lift",
      "time": "day"
    },
    {
      "id": "GX010054",
      "description": "top of bitterroot-lift through smuggler to superior-lift",
      "time": "twilight",
      "visibility": "low"
    },
    {
      "id": "GX010055",
      "description": "Superior-lift",
      "time": "twilight",
      "visibility": "low"
    },
    {
      "id": "GX010056",
      "description": "Top of superior-lift through cabin traverse to alpine",
      "time": "twilight",
      "visibility": "extra-low"
    },
    {
      "id": "GX010057",
      "description": "From cabin-traverse meeting alpine through alpine to deer-point-lift",
      "time": "twilight",
      "visibility": "extra-low"
    },
    {
      "id": "GX010058",
      "description": "Top of deer-point-lift down showcase to bogus creek trail",
      "time": "twilight",
      "visibility": "extra-low"
    },
    {
      "id": "GX010059",
      "description": "Top of morning-star-lift down lulu to deer-point-lift",
      "time": "twilight",
      "visibility": "low"
    },
    {
      "id": "GX010061",
      "description": "Top of morning-star-lift down silver-queen to deer-point-lift",
      "time": "twilight",
      "visibility": "low"
    },
    {
      "id": "GX010062",
      "description": "Top of deer-point-lift down alpine to morning-star-lift",
      "time": "twilight",
      "visibility": "low"
    },
    {
      "id": "GX010063",
      "description": "Top of bitterroot-lift down sunshine to bitterroot-lift",
      "time": "day"
    },
    {
      "id": "GX010064",
      "description": "Top of bitterroot-lift down smuggler to superior-lift",
      "time": "day"
    },
    {
      "id": "GX010065",
      "description": "Superior-lift",
      "time": "day"
    },
    {
      "id": "GX010066",
      "description": "Top of superior-lift down cabin-traverse to wildcat to pine-creek lift",
      "time": "day"
    },
    {
      "id": "GX010067",
      "description": "Top of pine-creek-lift down bonanza to superior-lift",
      "time": "day"
    },
    {
      "id": "GX010068",
      "description": "Top of superior-lift down cabin-traverse to shindig through bogus-creek to morning-star lift",
      "time": "day"
    },
    {
      "id": "GX010069",
      "description": "Top of morning-star lift down morning-star to deer-point-lift",
      "time": "day"
    },
    {
      "id": "GX010070",
      "description": "Top of pine-creek-lift down showcase to showcase-lift",
      "time": "day"
    },
    {
      "id": "GX010071",
      "description": "Top of showcase-lift down alpine to showcase-lift",
      "time": "day"
    },
    {
      "id": "GX010072",
      "description": "Top of showcase-lift down nugget-cat-track through lower-nugget to pine-creek-lift",
      "time": "day"
    },
    {
      "id": "GX010074",
      "description": "Top of pine-creek-lift through nugget to the top of second-chance",
      "time": "day"
    },
    {
      "id": "GX010075",
      "description": "Down second-chance to pine-creek-lift",
      "time": "day"
    },
    {
      "id": "GX010076",
      "description": "Top of pine-creek-lift down nighthawk to superior-lift",
      "time": "day"
    },
    {
      "id": "GX010077",
      "description": "Top of superior-lift down nighthawk to lodge-cat-track through sleepy-hollow to bitterroot-lift",
      "time": "twilight"
    },
    {
      "id": "GX010078",
      "description": "Top of bitterroot-lift down sunshine through lazy-mary to butterroot-lift",
      "time": "twilight"
    },
    {
      "id": "GX010079",
      "description": "Top of bitterroot-lift down sunshine through bitterroot-basin to bitterroot-lift",
      "time": "twilight"
    },
    {
      "id": "GX010080",
      "description": "Top of bitterroot-lift down pioneer (aborted)",
      "time": "twilight"
    },
    {
      "id": "GX010081",
      "description": "Pioneer lodge down pioneer-trail through bogus-creek-trail to deer-point-lift",
      "time": "twilight"
    },
    {
      "id": "GX010082",
      "description": "Top of deer-point-lift through upper-ridge and lower-ridge to morning-star-lift",
      "time": "day"
    },
    {
      "id": "GX010083",
      "description": "Top of morning-star-lift through morning-star to morning-star-lift",
      "time": "day"
    },
    {
      "id": "GX010084",
      "description": "Top of morning-star-lift through war-eagle-cat-track and war-eagle to superior-lift",
      "time": "day"
    },
    {
      "id": "GX010085",
      "description": "Top of morning-star lift through majestic-cat-track back through lodge-cat-track to top of superior",
      "time": "day"
    },
    {
      "id": "GX010086",
      "description": "Top of superior down superior to superior-lift",
      "time": "day"
    },
    {
      "id": "GX010087",
      "description": "Top of superior-lift down triumph to superior-lift",
      "time": "day"
    },
    {
      "id": "GX010088",
      "description": "Top of superior-lift down nighthawk to superior-lift",
      "time": "day"
    },
    {
      "id": "GX010089",
      "description": "Top of superior-lift down triumph to lodge-cat-track to war-eagle to superior-lift",
      "time": "twilight"
    }
  ]
}
