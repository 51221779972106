import { BasePath } from "../types";

export const AVOCADO = "#676232";
export const BURNT_SIENNA = "#C65D52";
export const CARAFE = "#5D473A";
export const HARVEST_GOLD = "#B68A3A";
export const RUST = "#B55A30";
export const CORSAIR = "#18576C";

// Pantone's colors of the 00s
export const CERULEAN = "#9BB7D4";
export const CHILI_PEPPER = "#9B1B30";
export const SATELLITE = "#978D89";
export const LAVENDER_AURA = "#9F99AA";
export const MIMOSA = "#F0C05A";
export const ASPEN_GREEN = "#7E9B76";

// Base colors
export const OFF_WHITE = "#f5f5dc";
export const WHITE = OFF_WHITE;

export const RED = BURNT_SIENNA;
export const BLUE = CERULEAN;
export const GREEN = ASPEN_GREEN;
export const BLACK = "black";

export const color = (path: BasePath): string => {
  if (path.pathType === "lift") {
    return CARAFE;
  }
  switch (path.difficulty) {
    case "green":
      return GREEN;
    case "blue":
      return BLUE;
    case "black":
    case "double-black":
      return BLACK;
    case "red":
      return CHILI_PEPPER;
  }
};

export const loadImage = (path: BasePath | null): string => {
  if (!path) {
    return "/img/load-black.gif";
  }

  if (path.pathType === "lift") {
    return "/img/load-brown.gif";
  }
  switch (path.difficulty) {
    case "green":
      return "/img/load-green.gif";
    case "blue":
      return "/img/load-blue.gif";
    case "black":
    case "double-black":
    case "red":
      return "/img/load-black.gif";
  }
};
