import React, { useMemo } from "react";
import styled from "styled-components";
import { useStore } from "../store";
import { ResponsiveLineCanvas as ResponsiveLine } from "@nivo/line";
import { Directory, GPS } from "../types";
import { color } from "../util/color";

const MAX_STEPS = 100;

const getData = (directory: Directory) => {
  let maxY = 0;
  let minY = Infinity;
  let maxX = 0;

  const unsteppedData = directory.paths.flatMap((path, pathIndex) => {
    return path.videos.map((videoSegment) => {
      const initialMsFromStart = videoSegment.gps[0].msFromStart;
      let metersFromStart = 0;
      const data = videoSegment.gps.flatMap((gps, i) => {
        const elapsedTimeSinceLastMs =
          i > 0
            ? gps.msFromStart - videoSegment.gps[i - 1].msFromStart
            : 0; /* This isn't technically correct, but :shrug: */
        const elapsedTimeSinceLastS = elapsedTimeSinceLastMs / 1000;

        // speed is m/s
        metersFromStart += (gps.speed2d * elapsedTimeSinceLastS) | 0;
        const timeElapsed = ((gps.msFromStart - initialMsFromStart) / 1000) | 0;

        const x = metersFromStart;
        const y = gps.alt | 0;

        maxX = Math.max(maxX, x);

        maxY = Math.max(y, maxY);
        minY = Math.min(y, minY);

        return [{ x: metersFromStart, y: y, gps }];
      });

      return {
        id: videoSegment.id,
        meta: {
          color: color(path),
          path,
          videoSegment,
        },
        data,
      };
    });
  });

  const metersPerStep = (maxX / MAX_STEPS) | 0;
  const steppedData = unsteppedData.map((d) => ({
    ...d,
    data: d.data
      .reduce((memo, { x, ...rest }) => {
        const stepNumber = x - (x % metersPerStep);
        memo[stepNumber] = { x: stepNumber, ...rest };
        return memo;
      }, new Array(MAX_STEPS))
      .filter(Boolean),
  }));

  return { steppedData, unsteppedData, maxY, minY, maxX };
};

export const UnstyledTrailIncline: React.FC<{ className?: string }> = ({
  className,
}) => {
  const {
    state: { activeVideoSegment, activeGps, directory },
    dispatch,
  } = useStore();
  const { steppedData, minY, maxY } = useMemo(() => getData(directory), [
    directory,
  ]);

  const selectGps = (activeGps: GPS) => {
    dispatch({ type: "update-state", state: { activeGps } });
  };

  const selectedData = useMemo(
    () =>
      activeVideoSegment &&
      steppedData.find(
        (d) => d.meta.videoSegment.videoId === activeVideoSegment.videoId
      ),
    [activeVideoSegment]
  );

  const closestPoint = useMemo(
    () =>
      activeGps &&
      selectedData &&
      selectedData.data.find((p) => p.gps.msFromStart >= activeGps.msFromStart),
    [activeGps]
  );

  const data = selectedData ? [selectedData] : steppedData;
  const colors = selectedData
    ? [selectedData.meta.color]
    : steppedData.map(({ meta: { color } }) => color);
  const opacity = selectedData ? 0.9 : 0.1;

  return (
    <div className={className}>
      <ResponsiveLine
        areaOpacity={opacity}
        data={data}
        colors={colors}
        gridXValues={closestPoint ? [closestPoint.x] : []}
        enableGridX={true}
        enableGridY={false}
        enablePoints={false}
        enableArea={true}
        isInteractive={true}
        enableCrosshair={false}
        tooltip={({ point }: any) => {
          selectGps(point.data.gps);
          return null;
        }}
        yScale={{ type: "linear", max: maxY, min: minY }}
        theme={{
          grid: {
            line: {
              stroke: selectedData ? selectedData.meta.color : "transparent",
              strokeWidth: 2,
            },
          },
        }}
        curve="natural"
      />
    </div>
  );
};

export const TrailIncline = styled(UnstyledTrailIncline)`
  align-items: center;
  display: flex;
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  font-size: 30px;
  justify-content: center;
`;
