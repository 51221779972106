import React from "react";
import styled from "styled-components";
import { useStore } from "../store";
import { color, OFF_WHITE } from "../util/color";

const SHOW_EXTENDED = false;

const UnstyledTrailInfo: React.FC<{ className?: string }> = ({ className }) => {
  const {
    state: { activePath, activeGps },
  } = useStore();

  return (
    <div
      className={className}
      style={{ backgroundColor: activePath ? color(activePath) : undefined }}
    >
      <h1>{activePath?.name}</h1>
      {SHOW_EXTENDED && (
        <p>{((activeGps?.msFromStart ?? 0) / 1000).toFixed(2)}</p>
      )}
    </div>
  );
};

export const TrailInfo = styled(UnstyledTrailInfo)`
  align-items: center;
  color: ${OFF_WHITE};
  display: flex;
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  justify-content: center;

  h1 {
    font-size: 30px;
  }
`;
