import React from "react";
import styled from "styled-components";
import { useStore } from "../store";
import { Path, Trail } from "../types";
import { color } from "../util/color";

const getIndex = (path: Path) =>
  path.pathType === "trail"
    ? `${path.difficulty}${path.isCatTrack ? "-cat" : ""}`
    : path.pathType;

const PathListItem = styled.div<{ path: Path, isActive: boolean, isCurious: boolean}>`
  align-items: center;
  background-color: ${({ path }) => color(path)};
  border-bottom: solid 1px white;
  color: white;
  cursor: pointer;
  display: flex;
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  font-size: 18px;
  justify-content: center;
  opacity: ${({ isActive, isCurious }) => (isActive || isCurious ? 1 : 0.8)};
  padding: 18px 0;
`;

const UnstyledTrailList: React.FC<{ className?: string }> = ({ className }) => {
  const {
    state: { directory, curiousPath, activePath },
    setCuriousPath,
    unsetCuriousPath,
    selectPath,
  } = useStore();

  const segregatedPaths = directory.paths.reduce<{ [type: string]: Path[] }>(
    (memo, path) => ({
      ...memo,
      [getIndex(path)]: [...(memo[getIndex(path)] ?? []), path],
    }),
    {}
  );

  const paths = [
    ...(segregatedPaths["green"] ?? []),
    ...(segregatedPaths["green-cat"] ?? []),
    ...(segregatedPaths["blue"] ?? []),
    ...(segregatedPaths["blue-cat"] ?? []),
    ...(segregatedPaths["black"] ?? []),
    ...(segregatedPaths["black-cat"] ?? []),
    ...(segregatedPaths["double-black"] ?? []),
    ...(segregatedPaths["double-black-cat"] ?? []),
    ...(segregatedPaths["lift"] ?? []),
  ];

  return (
    <div className={className}>
      {paths.map((p) => (
        <PathListItem
          key={p.id}
          path={p}
          isCurious={p.id === curiousPath?.id}
          isActive={p.id === activePath?.id}
          onMouseEnter={() => setCuriousPath(p)}
          onMouseLeave={() => unsetCuriousPath(p)}
          onClick={() => selectPath(p)}
        >
          {p.name}
        </PathListItem>
      ))}
    </div>
  );
};

export const TrailList = styled(UnstyledTrailList)`
  overflow-y: auto;
`;
