import React, { useRef } from "react";
import styled from "styled-components";
import { useMap } from "./useMap";

const UnstyledTrailMap: React.FC<{ className?: string }> = ({ className }) => {
  const ref = useRef<HTMLDivElement>(null);
  useMap(ref);

  return <div className={className} ref={ref} />;
};

export const TrailMap = styled(UnstyledTrailMap)`
  backgroundcolor: "rgba(0, 0, 0, 0)";
`;
