export const videoTypes = (videoId: string) => [
  {
    src: `/video/processed/${videoId}.vp9.1280x720.webm`,
    type: "video/webm; codecs=vp9",
    preload: false,
    res: "high",
  },
  {
    src: `/video/processed/${videoId}.vp8.1280x720.webm`,
    type: "video/webm; codecs=vp8",
    preload: false,
    res: "high",
  },
  {
    src: `/video/processed/${videoId}.h264.1280x720.mp4`,
    type: "video/mp4; codecs=avc1",
    preload: false,
    res: "high",
  },
  {
    src: `/video/processed/${videoId}.h264.854x480.mp4`,
    type: "video/mp4; codecs=avc1",
    preload: false,
    res: "low",
  },
];
