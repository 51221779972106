import React, {
  createContext,
  Dispatch,
  Reducer,
  useContext,
  useMemo,
  useReducer,
} from "react";
import { directory } from "./data/generated-directory";
import { Path, GPS, VideoSegment, Directory } from "./types";

type State = {
  activeGps: GPS | null;
  activePath: Path | null;
  curiousPath: Path | null;
  activeVideoSegment: VideoSegment | null;
  directory: Directory;
};

const defaultState: State = {
  activeGps: null,
  activePath: null,
  curiousPath: null,
  activeVideoSegment: null,
  directory,
};

type Action =
  | { type: "update-state"; state: Partial<State> }
  | { type: "unset-curious-path"; id: string };

const createActionDispatchers = (dispatch: Dispatch<Action>) => ({
  setCuriousPath: (curiousPath: Path) =>
    dispatch({ type: "update-state", state: { curiousPath } }),
  unsetCuriousPath: ({ id }: Path) =>
    dispatch({ type: "unset-curious-path", id }),
  selectPath: (
    activePath: Path,
    activeVideoSegment = activePath.videos[0] || null,
    activeGps = activeVideoSegment?.gps[0] || null
  ) =>
    dispatch({
      type: "update-state",
      state: { activePath, activeVideoSegment, activeGps },
    }),
  clearSelected: () =>
    dispatch({
      type: "update-state",
      state: { activePath: null, activeVideoSegment: null, activeGps: null },
    }),
});
type ActionDispatchers = ReturnType<typeof createActionDispatchers>;

const reducer: Reducer<State, Action> = (state, action) => {
  switch (action.type) {
    case "update-state":
      return { ...state, ...action.state };
    case "unset-curious-path":
      return {
        ...state,
        curiousPath:
          state.curiousPath?.id === action.id ? null : state.curiousPath,
      };
    default:
      return state;
  }
};

const context = createContext<
  ({ state: State; dispatch: Dispatch<Action> } & ActionDispatchers) | null
>(null);

const useSideEffects = (
  state: State,
  actionDispatchers: ActionDispatchers,
  dispatch: Dispatch<Action>
) => {};

export const useStore = () => {
  const store = useContext(context);
  if (!store) {
    throw new Error("Store not provided.");
  }
  return store;
};

export const ProvideStore: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState);
  const actionDispatchers = useMemo(() => createActionDispatchers(dispatch), [
    dispatch,
  ]);

  useSideEffects(state, actionDispatchers, dispatch);

  return (
    <context.Provider value={{ state, ...actionDispatchers, dispatch }}>
      {children}
    </context.Provider>
  );
};
